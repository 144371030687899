import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { BASE_URL_IMAGE } from '@/config/config';
import { FRONT_PAGE_HERO_LG, FRONT_PAGE_HERO_MD } from '@/consts/defaultConsts';
import { ResponsiveImage } from '@/interfaces/globalInterface';

export const HomeContainer = styled(Box)(() => ({
  backgroundColor: '#fafafa',
}));

export const Section = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  textAlign: 'center',
  padding: '16px',
  gap: '24px',
  width: '352px',
  height: '650px',
  '@media only screen and (max-width: 899px)': {
    padding: '0px',
    width: '328px',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.h6.fontFamily,
  fontSize: theme.typography.h6.fontSize,
  fontWeight: theme.typography.h6.fontWeight,
  lineHeight: theme.typography.h6.lineHeight,
  textAlign: 'center',
  height: '64px',
  '@media only screen and (max-width: 899px)': {
    height: 'auto',
  },
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.body1.fontFamily,
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.body1.fontWeight,
  lineHeight: theme.typography.body1.lineHeight,
  color: 'rgba(0, 0, 0, 0.87)',
  textAlign: 'center',
}));

export const TextLink = styled(Link)(({ theme }) => ({
  fontFamily: theme.typography.h6.fontFamily,
  color: theme.palette.org.main,
}));

export const ButtonAccionModal = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.button.fontFamily,
  fontSize: theme.typography.button.fontSize,
  width: '260px',
  height: '36px',
  backgroundColor: '#FF6700',
  textDecoration: 'none',
  borderRadius: '36px',
  padding: '0px 16px',
  color: 'white',
  '@media only screen and (max-width: 899px)': {
    height: '44px',
    width: '289px',
    alignSelf: 'center',
  },
}));

export const ButtonAccionModal2 = styled(ButtonAccionModal)(() => ({
  backgroundColor: 'transparent',
  color: 'white',
}));

export const LinkButton = styled(Link)(() => ({
  textDecoration: 'none',
  color: 'inherit',
}));

export const SubContainerHome = styled(Box)(
  ({ matches }: { matches: boolean }) => ({
    position: 'absolute',
    top: !matches ? -150 : '-1px',
    right: 0,
    left: 0,
  })
);

export const responsiveImagesHome: ResponsiveImage[] = [
  {
    cssProperties: [
      {
        property: 'backgroundImage',
        value: `url(${BASE_URL_IMAGE}${FRONT_PAGE_HERO_MD})`,
      },
      { property: 'minHeight', value: '240px' },
      { property: 'maxHeight', value: '260px' },
    ],

    mediaMinWidth: '961px',
  },
  {
    cssProperties: [
      {
        property: 'backgroundImage',
        value: `url(${BASE_URL_IMAGE}${FRONT_PAGE_HERO_LG})`,
      },
      { property: 'minHeight', value: '320px' },
      { property: 'maxHeight', value: '340px' },
    ],
    mediaMinWidth: '1280px',
  },
];
