import { INoticeCreateListItem } from "@/pages/noticeV2/NoticeList/types";
import { AppDispatch, RootState } from "../store";
import { getTypeNoticeV2Service } from "@/services/noticeServices/NoticeListServices/NoticeListServices";
import { fillSendData, resetSendData, setCitiesSuggested, setDistrictsSuggested, setEchoResponse, setError, setInputsPlace, setIsLoading, setNoticeList, setNoticesDuplicate } from "./noticeV2Slice";
import { RestructureNoticeListData } from "@/utils/helpersActions";
import { INoticeSend } from "@/services/noticeServices/noticeSendData/noticeSendDataTypes";
import { noticeSendDataForSave, noticeSendDataForSaveRegister } from "@/services/noticeServices/noticeSendData/noticeSendDataService";
import { getMessaggeError, getNoticeNumberDuplicateError } from "../auth/utils";
import { ISendDataPayload } from "./noticeV2types";
import { noticeListDataCitySuggested, noticeListDataDistrictSuggested } from "@/services/noticeServices/noticeListSuggested/noticeListSuggestedService";
import { UsuarioAPI } from '../../models/userAuth/user';
import { getUserByIdTunhk } from "../userRedux/userThunks";



export const getListNoticeV2Thunk = (
    listData: INoticeCreateListItem[],
    flgregistro? : boolean
  ) => {
    return async (dispatch: AppDispatch) => {
      try {
        const {
            avisotipoData: {
                avisotipos
            }
        } = await getTypeNoticeV2Service(flgregistro);
        const listServices = RestructureNoticeListData(listData, avisotipos);
  
        dispatch(setNoticeList(avisotipos));
        // autoSelected && dispatch(setSelectNotice(listServices[0]));
        return listServices;
      } catch (error) {
        return `Error: ${error}`
      }
    };
  };

// llenar el sendData dependiendo del tipo de aviso
export const fillSendDataThunk = (data : ISendDataPayload) => {
    return async (dispatch: AppDispatch) => {
        dispatch(fillSendData(data));
    }
}

// resetear el sendData
export const resetSendDataThunk = () => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(setNoticeList({}));
        dispatch(resetSendData());
        dispatch(setDistrictsSuggested({}));
        dispatch(setCitiesSuggested({}));
        dispatch(setEchoResponse({}));
    }
}

// guardar los datos del flujo de guardado dependiendo a su tipo de aviso.
export const onSaveFlowNoticeV2Thunk = (
  body: INoticeSend
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setError(null));
    dispatch(setIsLoading(true));
    try {
      const state = getState();

      const {
        user: {
          user
        }
      } = state;
      const isRegister = (user as UsuarioAPI)?.flgRegistroAviso === null;
      // const result = await noticeSendDataForSave(body);
      const result = isRegister ? await noticeSendDataForSaveRegister(body) : await noticeSendDataForSave(body);

      // cargar la data del usuario
      if(isRegister) {
        dispatch(getUserByIdTunhk(`${(user as UsuarioAPI).id}`));
      }

      dispatch(setEchoResponse(result?.avisoCreadoData));
      dispatch(setNoticesDuplicate([]));
      dispatch(setIsLoading(false));
      return result?.avisoCreadoData;
    } catch (err) {
      const error = getMessaggeError(err);
      dispatch(setError(error));
      dispatch(setNoticesDuplicate(getNoticeNumberDuplicateError(err)));
      throw new Error(error.message || undefined);
    }
  }
}

// suggested district

export const noticeGetDistrictSuggestedThunk = (
  id: number,
  idTypeNotice?: number
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setDistrictsSuggested({}));
    try {
      const result = await noticeListDataDistrictSuggested(id, idTypeNotice);
      dispatch(setDistrictsSuggested(result.distritoSugeridoData));
      return result.distritoSugeridoData;
    } catch (err) {
      const error = getMessaggeError(err);
      throw new Error(error.message || undefined);
    }
  }
}

// suggested city
export const noticeGetCitiesSuggestedThunk = (
  id: number
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setCitiesSuggested({}));
    try {
      const result = await noticeListDataCitySuggested(id);
      dispatch(setCitiesSuggested(result.ciudadSugeridoData));
      return result.ciudadSugeridoData;
    } catch (err) {
      const error = getMessaggeError(err);
      return error
    }
  }
}