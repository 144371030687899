import { IMG_PATH } from "@/consts/defaultConsts";
import useResize from "@/hooks/useResize";
import { resizePhoto, stringDateToSpanish } from "@/utils/helpers";
import { Avatar, Badge, Grid, styled, Typography } from "@mui/material";
import React from "react";
import { INotificaciones } from "../../models/userAuth/notification";
import { NotificationCardContent } from "./notificationStyle";
const Description = styled("div")(
  (theme) =>
    `
    display: -webkit-box;
    
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    `
);
interface NotiProps extends INotificaciones {
  children?: React.ReactNode;
}

const NotificationCard = ({
  urlIcon,
  flgVisto,
  perfilUrl,
  fechaRegistro,
  notificacion,
  id,
  children,
  link,
}: NotiProps) => {
  const [_, isMovil] = useResize();
  

  const handleResizeImg = () => {
    const resizedImg = resizePhoto({ photo: IMG_PATH + perfilUrl });

    return resizedImg;
  };

  // poner en mayuscula la primera letra y si esta dentro de un strong tambien le ponemos mayuscula
  const capitalizeFirstLetter = (string: string) => {
    const firstLetter = string.charAt(0).toUpperCase();
    const restOfString = string.slice(1);
    const newString = firstLetter + restOfString;
    return newString.replace(/<strong>(.*?)<\/strong>/g, (match, p1) => {
      return `<strong>${p1.charAt(0).toUpperCase() + p1.slice(1)}</strong>`;
    });
  };

  return (
    <>
      <NotificationCardContent
        container
        sx={{
          background: flgVisto ? "#FFFFFF" : "#E8F0FE",
        }}
      >
        <Grid item xs={isMovil ? 2.5 : 1}>
          <Badge
          sx={{ zIndex: 0}}
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <Avatar
                src={urlIcon}
                sx={{
                  width: 20,
                  height: 20,
                  zIndex: "none"
                }}
              />
            }
          >
            <Avatar
              src={handleResizeImg()}
              sx={{
                width: 56,
                height: 56,
              }}
            />
          </Badge>
        </Grid>
        <Grid
          item
          xs={isMovil ? 9.5 : 11}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body2" fontWeight="320" fontSize={16}>
            <div>
              <Description
                dangerouslySetInnerHTML={{ __html: capitalizeFirstLetter(notificacion) }}
                sx={{
                  WebkitLineClamp: isMovil ? 3 : 2,
                }}
              />
            </div>
          </Typography>
          <Typography
            variant="body2"
            fontSize={14}
            mt="2px"
            color="GrayText"
            fontWeight="305"
            lineHeight="20px"
            sx={{
              bottom: 0,
            }}
          >
            {
              fechaRegistro && stringDateToSpanish(fechaRegistro)
            }
          </Typography>
        </Grid>
      </NotificationCardContent>
    </>
  );
};

export default NotificationCard;
