import { BASE_URL_IMAGE } from "@/config/config";
import { US_HERO_LG, US_HERO_MD, US_HERO_SM } from "@/consts/defaultConsts";
import { ADS_LANDING_PATH } from "@/consts/typeServicesPaths";
import useResize from "@/hooks/useResize";
import imagenAnuncio from "@/img/anuncios_landing.svg";
import imageCuidamosPlanetaAbout from "@/img/img-cuidamos-planeta-about.svg";
import imageMejorPrecioAbout from "@/img/img-mejor-precio-about.svg";
import imagePegacargoAbout from "@/img/img-pegacargo-about.svg";
import imageSeguridadAbout from "@/img/img-seguridad-about.svg";
import { ResponsiveImage } from "@/interfaces/globalInterface";
import Footer from "@/pages/footer/Footer";
import { secondFontFamily, theme } from "@/styles/theme";
import { Stack, Typography } from "@mui/material";
import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { SubHeading } from "../ads/styleAds";
import BannerLand from "../components/bannerLand/BannerLand";
import InfoLand from "../components/infoLand/InfoLand";
import SectionLand from "../components/sectionLand/sectionLand";
import {
  ButtonAccionModal,
  LinkButton,
  Section,
  SubTitle,
  Title
} from "../home/stylesHome";
import {
  ContentHelp,
  ContentSecurity,
  ContentSecurityItem,
  DescriptionSecurity,
  ImageIcon,
  ImgSecurity,
  SecurityContent,
  SubtitleSecurity,
  TitleSecurity,
} from "./styleAbout";

const responsiveImages: ResponsiveImage[] = [
  {
    cssProperties: [
      {
        property: "backgroundImage",
        value: `url(${BASE_URL_IMAGE}${US_HERO_MD})`,
      },
      { property: "minHeight", value: "240px" },
      { property: "maxHeight", value: "260px" },
    ],
    mediaMinWidth: "961px",
  },
  {
    cssProperties: [
      {
        property: "backgroundImage",
        value: `url(${BASE_URL_IMAGE}${US_HERO_LG})`,
      },
      { property: "minHeight", value: "320px" },
      { property: "maxHeight", value: "340px" },
    ],
    mediaMinWidth: "1280px",
  },
];

const About = () => {
  const [, ismobil] = useResize();
  const { t } = useTranslation();

  const handleOnError = ({
    target,
  }: SyntheticEvent<HTMLImageElement, Event>) => {
    (target as HTMLInputElement).src = imagenAnuncio;
  };

  return (
    <>
      <BannerLand
        urlImg={`${BASE_URL_IMAGE}${US_HERO_SM}`}
        responsive={responsiveImages}
        positionXY={{ xs: "center", md: "center" }}
        minHeight="166px"
      />
      <SectionLand
        backgroundColor="#382E2B"
        sx={{
          padding: {
            xs: "0 16px",
            sm: "32px",
          },
        }}
      >
        <InfoLand
          title={t("about_title")}
          subtitle=""
          sx={{
            textAlign: "center",
            justifyContent: "center",
            padding: "8px 0",
            alignItems: "center",
          }}
        />
      </SectionLand>
      <ContentHelp>
        <Typography variant="body1">{t("about_paragraph1")}</Typography>
        <Typography variant="body1">{t("about_paragraph2")}</Typography>
        <Typography variant="body1">{t("about_paragraph3")}</Typography>

        <SectionLand
          title={t("home_subheading_section1_title") || ""}
          gap="24px"
        >
          <SubHeading>
            <Section>
              <Title>{t("home_subheading_title_item1")}</Title>
                <i className="icon-column-1" />
              <SubTitle>{t("home_subheading_description_item1")}</SubTitle>
            </Section>
            <Section>
              <Title>{t("home_subheading_title_item2")}</Title>
              <i className="icon-column-2" />
              <SubTitle>{t("home_subheading_description_item2")}</SubTitle>
            </Section>
            <Section>
              <Title>{t("home_subheading_title_item3")}</Title>
              <i className="icon-column-3" />
              <SubTitle>{t("home_subheading_description_item3")}</SubTitle>
            </Section>
          </SubHeading>
        </SectionLand>

        <div style={{ padding: "75% 0 0 0", position: "relative" }}>
          <iframe
            src="https://player.vimeo.com/video/801439928?h=0d6b22984d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              border: 0,
            }}
            title="PegaCargo-v1.mp4"
          ></iframe>
        </div>
        {/* <script src="https://player.vimeo.com/api/player.js"></script> */}
      </ContentHelp>
      <ContentSecurity>
        <ContentSecurityItem>
          <ImgSecurity>
            <ImageIcon src={imageSeguridadAbout} alt="imageSeguridadAbout" />
          </ImgSecurity>
          <SecurityContent>
            <TitleSecurity>{t("about_item1_title")}</TitleSecurity>
            <DescriptionSecurity>
              {t("about_item1_paragraph1")}
            </DescriptionSecurity>
            <DescriptionSecurity>
              {t("about_item1_paragraph2")}
            </DescriptionSecurity>
            <DescriptionSecurity>
              {t("about_item1_paragraph3")}
            </DescriptionSecurity>
          </SecurityContent>
        </ContentSecurityItem>

        <ContentSecurityItem>
          <ImgSecurity>
            <ImageIcon src={imageMejorPrecioAbout} alt="imageMejorPrecioAbout" />
          </ImgSecurity>
          <SecurityContent>
            <TitleSecurity>{t("about_item2_title")}</TitleSecurity>
            <SubtitleSecurity>{t("about_item2_subtitle1")}</SubtitleSecurity>
            <DescriptionSecurity>
              {t("about_item2_paragraph1")}
            </DescriptionSecurity>
            <SubtitleSecurity>{t("about_item2_subtitle2")}</SubtitleSecurity>
            <DescriptionSecurity>
              {t("about_item2_paragraph2")}
            </DescriptionSecurity>
          </SecurityContent>
        </ContentSecurityItem>
        <ContentSecurityItem>
          <ImgSecurity>
            <ImageIcon src={imagePegacargoAbout} alt="imagePegacargoAbout" />
          </ImgSecurity>
          <SecurityContent>
            <TitleSecurity>{t("about_item3_title")}</TitleSecurity>
            <SubtitleSecurity>{t("about_item3_subtitle1")}</SubtitleSecurity>
            <DescriptionSecurity>
              {t("about_item3_paragraph1")}
            </DescriptionSecurity>
            <SubtitleSecurity>{t("about_item3_subtitle2")}</SubtitleSecurity>
            <DescriptionSecurity>
              {t("about_item3_paragraph2")}
            </DescriptionSecurity>
            <DescriptionSecurity>
              {t("about_item3_paragraph3")}
            </DescriptionSecurity>
          </SecurityContent>
        </ContentSecurityItem>
        <ContentSecurityItem>
          <ImgSecurity>
            <ImageIcon src={imageCuidamosPlanetaAbout} alt="imageCuidamosPlanetaAbout" />
          </ImgSecurity>
          <SecurityContent>
            <TitleSecurity>{t("about_item4_title")}</TitleSecurity>
            <SubtitleSecurity>{t("about_item4_subtitle1")}</SubtitleSecurity>
            <DescriptionSecurity>
              {t("about_item4_paragraph1")}
            </DescriptionSecurity>
            <SubtitleSecurity>{t("about_item4_subtitle2")}</SubtitleSecurity>
            <DescriptionSecurity>
              {t("about_item4_paragraph2")}
            </DescriptionSecurity>
          </SecurityContent>
        </ContentSecurityItem>
      </ContentSecurity>
      <SectionLand alignItems="flex-start" gap="24px">
        <Typography variant="h5low"
          sx={{
            fontFamily: secondFontFamily,
            fontSize: theme.typography.h5.fontSize,
            lineHeight: theme.typography.h5.lineHeight,
            fontWeight: 'bold',
          }}
        >
          {t("home_subheading_section3_title")}
        </Typography>
        <SubHeading>
          {ismobil && (
            <Stack sx={{ width: "100%", maxWidth: "500px" }}>
              <img
                src={`${BASE_URL_IMAGE}/anuncios_landing.svg`}
                alt="Anuncios"
                onError={handleOnError}
                width={"100%"}
              />
            </Stack>
          )}

          <Stack gap="24px">
            <Typography>{t("home_subheading_section3_paragraph1")}</Typography>
            <LinkButton
              to={ADS_LANDING_PATH}
              sx={{ alignSelf: { xs: "center", md: "start" } }}
            >
              <ButtonAccionModal
                variant="contained"
                color="org"
                sx={{ width: "260px !important" }}
              >
                {t("home_subheading_section3_text_button")}
              </ButtonAccionModal>
            </LinkButton>
          </Stack>
          {!ismobil && (
            <img
              src={`${BASE_URL_IMAGE}/anuncios_landing.svg`}
              alt="Anuncios"
              onError={handleOnError}
            />
          )}
        </SubHeading>
      </SectionLand>
      <Footer />
    </>
  );
};

export default About;
