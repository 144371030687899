import { NOTICE_CLIENT_PATH } from "@/consts/acceptanceOffertPath";
import { actionsToNoticeNegotation } from "@/consts/actions";
import {
  DialogType,
  declineCounterOfferMsg,
  nottifficationDeclineMsg,
  nottifficationDeclineQuotationMsg,
  nottifficationPriceChanged,
  snackbarAlreadyAnswered,
  snackbarErrorMesasage,
} from "@/consts/notifficationsMessage";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import {
  Negotiation,
  TypeNoticeNegotiation,
} from "@/models/offert/offertModels";
import { saveOffertByIdThunk } from "@/store/offertRedux/offert.thunk";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { nextUrlFormat } from "../adapters/AcceptanceAdapter";

import {
  acceptCounterOfferClient,
  acceptNoticeClient,
  cocounterNoticeDriver,
  cocounterOfferNoticeDataLayer,
} from "@/services/dataLayer/createNoticeNeg";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { actionButton } from "../globalButtons/config";
import {
  getCo_counterOffertURL,
  getFlagsDriverState,
} from "../utils/utilsGlobalButton";
import {
  optionFour,
  optionOne,
  optionThree,
  optionTwo,
} from "@/consts/noticeConsts";

interface HandleDisabledButtons {
  disableAcceptButton: boolean;
  disableDeclineButton: boolean;
  disableCounterOfferButton: boolean;
}

interface UseButtonsFunctions {
  handleManageSnackbar: () => void;
  handleManageModalOffer: (textModal?: DialogType) => void;
  handleManageModalCounterOffer: () => void;
  handleAcceptButton: () => void;
  handleDriverCounterOfferButton: () => void;
  handleQuoteButton: () => void;
  handleDeclineButton: () => void;
  handleDisabledButtons: () => HandleDisabledButtons;
  handleManageSnackbarAlreadyAnswered: () => void;
  handleManageModalPriceChanged: (functionOptional?: ()=> void) => void
}

export const useGlobalButtons = (isQuote?: boolean): UseButtonsFunctions => {
  const {
    user: { user },
    offert: { savedOffert },
  } = useAppSelector((state) => state);

  const { pathname } = useLocation();
  const validPathName = pathname?.startsWith(NOTICE_CLIENT_PATH) as boolean;
  const textOfferModal = nottifficationDeclineMsg;

  const navigate: NavigateFunction = useNavigate();
  const dispatch = useAppDispatch();
  const { addNotiffication } = useNotiffication();
  const { counterOfferButtonStateDriver } = getFlagsDriverState(savedOffert);
  const userId = (user as IUser).id;
  const savedDataOffer = savedOffert as Negotiation;
  const actionId: number = savedDataOffer?.tipoAvisoNegociacion?.id as number;
  const idOffer: number = savedDataOffer?.id;
  const actionsNegotation = savedDataOffer?.ultimaAvisoNegociacionAcciones
    .tipoAvisoNegociacionAccion as TypeNoticeNegotiation;
  const typeNoticeId = actionsNegotation.id;
  const typeNotice = savedDataOffer?.avisoNegociacionAviso?.tipoaviso?.nombre;
  const precioactual = !!savedOffert?.preciocontraoferta ? savedOffert?.preciocontraoferta : savedOffert?.precio

  const handleManageSnackbar = (): void => {
    addNotiffication({
      title: snackbarErrorMesasage.TITLE,
      message: snackbarErrorMesasage.MESSAGE,
      hiddenModal: true,
      hiddenSnackbar: false,
      onSubmit: async () => {},
    });
  };

  const handleManageSnackbarAlreadyAnswered = (): void => {
    addNotiffication({
      title: snackbarAlreadyAnswered.TITLE,
      message: snackbarAlreadyAnswered.MESSAGE,
      hiddenModal: true,
      hiddenSnackbar: false,
      onSubmit: async () => {},
    });
  };

  const handleManageModalOffer = (): void => {
    let messageText = textOfferModal;

    if (isQuote) {
      messageText = nottifficationDeclineQuotationMsg;
    }

    addNotiffication({
      title: messageText.TITLE,
      message: messageText.MESSAGE,
      leftBtnText: messageText.LEFT_BTN_TEXT,
      RightBtnText: messageText.RIGHT_BTN_TEXT,
      hiddenSnackbar: true,
      hiddenModal: false,
      onSubmit: async () => {
        handleDeclineButton();
      },
    });
  };



  const handleManageModalPriceChanged = (functionOptional?: ()=> void) => {


    addNotiffication({
      title: nottifficationPriceChanged.TITLE,
      message: nottifficationPriceChanged.MESSAGE,
      leftBtnText: nottifficationPriceChanged.LEFT_BTN_TEXT,
      RightBtnText: nottifficationPriceChanged.RIGHT_BTN_TEXT,
      hiddenSnackbar: true,
      hiddenModal: false,
      onSubmit: async () => {
        functionOptional && functionOptional()
      },
    });
  };

  const handleManageModalCounterOffer = (): void => {
    addNotiffication({
      title: declineCounterOfferMsg.TITLE,
      message: declineCounterOfferMsg.MESSAGE,
      leftBtnText: declineCounterOfferMsg.LEFT_BTN_TEXT,
      RightBtnText: declineCounterOfferMsg.RIGHT_BTN_TEXT,
      hiddenSnackbar: true,
      hiddenModal: false,
      onSubmit: async () => {
        handleDeclineButton();
      },
    });
  };

  const handleAcceptButton = (): void => {
    const driverCounterOffer = 5; // Id de Transportista contraoferta
    let newOption = optionOne; // opcion para la ruta a escoger dependiendo del usuario
    let action = actionId;
    

    let actionsButton = actionButton[action][optionOne];

    if (actionsNegotation.id === driverCounterOffer) {
      actionsButton = actionButton[optionThree][optionOne];
    }

    if (validPathName) newOption = 3;

    const data = {
      idOffer,
      idAccion: actionsButton,
      precioactual,
    };

    dispatch(saveOffertByIdThunk(data)).then((value) => {
      const dataLayerFunction = ActionDataLayer[typeNoticeId][optionOne];
      dataLayerFunction(userId, typeNotice as string);

      if (!!value.flgpreciocambiado) {
        handleManageModalPriceChanged();
      } else {
        navigate(
          nextUrlFormat(savedDataOffer?.id.toString(), action, newOption),
          {
            replace: true,
          }
        );
      }
    });
  };

  const handleDriverCounterOfferButton = (): void => {
    let nextRoute = "";

    if (
      !!counterOfferButtonStateDriver &&
      actionsNegotation.id === actionsToNoticeNegotation[optionTwo].id
    ) {
      nextRoute = getCo_counterOffertURL(`${idOffer}`);
    }

    navigate(nextRoute);

  };

  //============================================================================================

  const handleQuoteButton = () => {
    navigate(getCo_counterOffertURL(idOffer.toString()));
  };

  //============================================================================================

  const handleDeclineButton = (): void => {
    const driverCounterOffer = 5; // Id de Transportista contraoferta
    let newOption = optionTwo;
    let action = actionId;

    let actionsButton = actionButton[action][optionThree];

    if (actionsNegotation.id === driverCounterOffer) {
      actionsButton = actionButton[optionThree][optionThree];
    }

    if (validPathName) {
      newOption = optionFour;
    }

    const data = {
      idOffer,
      idAccion: actionsButton,
      precioactual,
    };

    dispatch(saveOffertByIdThunk(data)).then((value) => {
      if (!!value.flgpreciocambiado) {
        handleManageModalPriceChanged();
      } else {
        navigate(
          nextUrlFormat(savedDataOffer?.id.toString(), action, newOption),
          { replace: true }
        );
      }
    });
  };

  const handleDisabledButtons = (): HandleDisabledButtons => {
    const {
      flgPermitirAceptarTransportista,
      flgPermitirRechazarTransportista,
      flgPermitirContraofertarTransportista,
      flgPermitirAceptarCliente,
      flgPermitirRechazarCliente,
      flgPermitirContraofertarCliente,
    } = actionsNegotation;

    let disableAcceptButton = !!flgPermitirAceptarTransportista;
    let disableDeclineButton = !!flgPermitirRechazarTransportista;
    let disableCounterOfferButton = !!flgPermitirContraofertarTransportista;

    if (validPathName) {
      disableAcceptButton = !!flgPermitirAceptarCliente;
      disableDeclineButton = !!flgPermitirRechazarCliente;
      disableCounterOfferButton = !!flgPermitirContraofertarCliente;
    }

    return {
      disableAcceptButton,
      disableDeclineButton,
      disableCounterOfferButton,
    };
  };

  return {
    handleManageSnackbar,
    handleManageModalOffer,
    handleManageModalCounterOffer,
    handleAcceptButton,
    handleDriverCounterOfferButton,
    handleQuoteButton,
    handleDeclineButton,
    handleDisabledButtons,
    handleManageSnackbarAlreadyAnswered,
    handleManageModalPriceChanged
  };
};

export const ActionDataLayer: Record<number, any> = {
  1: {
    1: acceptNoticeClient,
  },
  2: {
    1: acceptCounterOfferClient,
    2: cocounterOfferNoticeDataLayer,
  },
  5: {
    1: cocounterNoticeDriver,
  },
};
