import { IMG_PATH } from "@/consts/defaultConsts";
import {
  NOTICE_IN_CITY_PATHS,
  NOTICE_MOVING_PATHS_DISTRICT,
} from "@/consts/typeServicesPaths";
import {
  ListAviso,
  NoticeResponseSaveFlowAPI,
} from "@/models/notice/noticeModels";
import { currencyFormat, formatDate, resizePhoto } from "@/utils/helpers";
import { IconButton, Stack, styled } from "@mui/material";
import { MouseEvent, SyntheticEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomChip from "../customChip/CustomChip";
import StateNotice from "./StateNotice";
import TypeNoticeCard from "./TypeNoticeCard";
import {
  ContainerInfoCard,
  ContentInfo,
  ImgContent,
  MinTitlePrice,
  PriceContainer,
  StackInfo,
  SubTitleInfo,
  TextPrice,
  TitleBody,
} from "./styleInfoCard";

const CustomLink = styled(Link)(
  ({ theme }) => `
    text-decoration: none;
    font-family: ${theme.typography.h1.fontFamily};
    font-size: ${theme.typography.label2.fontSize};
    font-weight: ${theme.typography.label2.fontWeight};
    line-height: ${theme.typography.label2.lineHeight};
		color: ${theme.palette.warning.main};
  `
);

type MiniPriceProps = { data: NoticeResponseSaveFlowAPI | ListAviso };
const MiniPrice = ({ data }: MiniPriceProps) => {
  const priceTitle = () => {
    if (data.tipoaviso.id === 1) {
      return "De punto a punto";
    }
    if (data.tipoaviso.id === 2) {
      if (data.precioxpunto) {
        // return "Precio por punto";
        return `Por punto (min. ${data?.puntosminimo} puntos)`;
      }
      if (data.precioxhora) {
        // return "Precio por hora";
        return `Por hora (min. ${data?.horasminimo} horas)`;
      }
    }
    if (data.tipoaviso.id === 5) {
      // return "Precio por viaje";
      return `Por viaje (incluye ${data?.ayudantes} ${
        data?.ayudantes === 1 ? "ayudante" : "ayudantes"
      })`;
    }

    if (data.tipoaviso.id === 4 && data?.precioregular) {
      // texto tachado
      return `<del>${currencyFormat(data.precioregular as number)}</del>`;
    }

    return "";
  };

  const price = () => {
    if (data.tipoaviso.id === 2) {
      if (data.precioxpunto) {
        return currencyFormat(data.precioxpunto);
      }
      if (data.precioxhora) {
        return currencyFormat(data.precioxhora);
      }
    }
    return currencyFormat(data.precio);
  };

  if (!data) return <></>;

  return (
    <PriceContainer>
      <MinTitlePrice dangerouslySetInnerHTML={{ __html: priceTitle() }} />
      {price()}
    </PriceContainer>
  );
};

type Props = {
  data: NoticeResponseSaveFlowAPI | ListAviso;
  disabled?: boolean;
  link?: string;
  navigateBody?: (idDetail: number, idNotice: number) => void;
  linkFooter?: string;
  showState?: boolean;
  onClickFooter?: (data: NoticeResponseSaveFlowAPI | ListAviso) => void;
};

const InfoCard = ({
  data,
  disabled = false,
  link = "",
  navigateBody,
  linkFooter,
  showState,
  onClickFooter,
}: Props) => {
  const navigate = useNavigate();

  const handleValidateImg = (imgURL: string) => {
    const photoResized = resizePhoto({
      photo: IMG_PATH + imgURL,
    });

    if (photoResized?.startsWith("http")) return photoResized;

    return IMG_PATH + photoResized;
  };

  const changeFechaValidezOrFechaFija = (): string => {
    if (data.tipoaviso?.flgFechahora === 1 && data?.fechafija) {
      return `Salida ${data?.fechafija} ${data?.hora ? `(${data?.hora})` : ""}`;
    }
    if (data?.fechafija) return `Salida ${data?.fechafija}`;
    return `Válido hasta el ${formatDate(data.fvalidez)}`;
  };

  const handleNavigate = () => {
    const currentId = data.id.toString();
    let path = link;
    if (!disabled) {
      if (navigateBody) {
        navigateBody(data.id, data.tipoaviso.id);
        return;
      } else if (data.tipoaviso.id === 5) {
        path = NOTICE_MOVING_PATHS_DISTRICT[7].replace(":id", currentId);
      } else if (data.tipoaviso.id === 1) {
        path = NOTICE_IN_CITY_PATHS[6].replace(":id", currentId);
      }

      navigate(path);
    }
  };

  const handleCLickButtonArrow = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    event.stopPropagation();
    handleNavigate();
  };

  const handleOnclickFooter = () => {
    onClickFooter && onClickFooter(data);
  };

  const handleErrorImg = (
    e: SyntheticEvent<HTMLImageElement, Event>,
    imageUrl: string
  ) => {
    (e.target as HTMLInputElement).src = IMG_PATH + imageUrl;
  };

  return (
    <div>
      <ContainerInfoCard
        onClick={handleNavigate}
        sx={{
          borderRadius: `8px 8px ${linkFooter ? "0 0" : "8px"}`,
          position: "relative",
          paddingTop: !!data?.flgpatrocinio ? "" : "28px",
          paddingBottom: !!data?.flgpatrocinio ? "" : "24px",
        }}
      >
        <StateNotice data={data} showState={showState} />

        <CustomChip
          show={!!data?.flgpatrocinio}
          label="Patrocinado"
          sx={{ marginBottom: "6px" }}
        />

        <TypeNoticeCard data={data} />

        <TitleBody>
          De {data.origentexto} a {data.destinotexto}
        </TitleBody>
        <ContentInfo>
          <StackInfo>
            <SubTitleInfo>
              {/* Válido hasta el {formatDate(data.fvalidez)} */}
              {changeFechaValidezOrFechaFija()}
            </SubTitleInfo>
            <SubTitleInfo
              sx={{
                display:
                  data?.vehiculo?.estado === "DESACTIVADO" ? "none" : "flex",
              }}
            >
              <strong>{data.vehiculo.tipovehiculo?.nombre}</strong>
              &nbsp; | Hasta {data.pesomaximo} kilos{" "}
              {!!data?.flgcargarefrigerada && (
                <>
                  | <i className="icon-snow-flake"></i>
                </>
              )}
            </SubTitleInfo>
          </StackInfo>
          <ImgContent>
            <img
              src={
                handleValidateImg(data.vehiculo?.vehiculofotos[0]?.direccion) ||
                ""
              }
              onError={(e) => {
                handleErrorImg(e, data.vehiculo?.vehiculofotos[0]?.direccion);
              }}
              alt=""
              width="40"
              height="40"
              style={{ objectFit: "fill" }}
            />
          </ImgContent>
        </ContentInfo>
        <TextPrice>
          <MiniPrice data={data} />
          <IconButton
            disabled={disabled}
            aria-label="back"
            onClick={handleCLickButtonArrow}
          >
            <i
              className={
                !disabled
                  ? "icon-arrow-right--primary"
                  : "icon-arrow-right--disabled"
              }
            />
          </IconButton>
        </TextPrice>
      </ContainerInfoCard>
      {linkFooter && (
        <Stack
          sx={{
            border: "1px solid #CFD8DC",
            borderRadius: "0 0 8px 8px",
            borderTop: "none",
          }}
        >
          <CustomLink
            to={linkFooter}
            onClick={handleOnclickFooter}
            style={{ padding: "10px", textAlign: "center" }}
          >
            VER OFERTAS
          </CustomLink>
        </Stack>
      )}
    </div>
  );
};

export default InfoCard;
