import { Stack, styled } from "@mui/material";

export const HeaderContainer = styled(Stack)(
  () => `
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      `
);

export const NoticeOffertStyles = {
  containerStyles: {
    // marginTop: "41px",
    marginTop: {
      xs: "16px",
      sm: "41px",
    }
  },
  currentInfoStyles: {
    marginTop: "15px",
  },
};
