import CurrentBlueMarker from "@/img/sharedRider/CurrentBlueMarker.svg";
import CurrentOrangeMarker from "@/img/sharedRider/CurrentOrangeMarker.svg";
import TruckMarkert from "@/img/sharedRider/TruckMarkert.svg";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";

type Coords = {
  lat: number;
  lng: number;
};

type Props = {
  height: number;
  vehicleCoords: Coords;
  originCoords: Coords;
  destinyCoords: Coords;
  onLoad: (map: any) => void;
  isLoaded: boolean;
};

const CustomGoogleMap: React.FC<Props> = (props) => {
  const {
    destinyCoords,
    height,
    originCoords,
    vehicleCoords,
    onLoad,
    isLoaded,
  } = props;
  const [centerCoords, setCenterCoords] = useState({ lat: 0, lng: 0 })

  useEffect(() => {
    if ( !!vehicleCoords.lat && !!vehicleCoords.lng ) {
      setCenterCoords(vehicleCoords)
    }
  }, [vehicleCoords])

  if (!isLoaded || !vehicleCoords.lat || !vehicleCoords.lng) return <></>;

  return (
    <GoogleMap
      id="map-id"
      mapContainerStyle={{
        width: "100vw",
        height: `calc(100vh - ${height}px)`,
      }}
      center={centerCoords}
      zoom={18}
      options={{
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        zoomControl: false,
      }}
      onLoad={onLoad}
    >
      <MarkerF
        position={vehicleCoords}
        options={{
          icon: TruckMarkert,
        }}
      />

      <MarkerF
        position={originCoords}
        options={{
          icon: CurrentOrangeMarker,
        }}
      />
      <MarkerF
        position={destinyCoords}
        options={{
          icon: CurrentBlueMarker,
        }}
      />
    </GoogleMap>
  );
};

export default CustomGoogleMap;
