import { CSSProperties } from "react";
import ImgCarousel from "@/components/viewService/imgCarousel";
import { Photo } from "@/store/noticeV2ClientAcceptCounterOffer/interfaces/dataResponseEnglishInterfaces";
import { Box } from "@mui/material";
import Img from "./Img";

export interface Foto {
  id: number;
  idAvisoNegociacion: number;
  urlImagen: string;
}

type Props = {
  images: Foto[];
  style?: CSSProperties;
  alt?: string;
};

export const ImagesNegV2 = ({ images }: Props) => {


  if (!images.length || !images) return <></>;
  


  return (
    <Box sx={{ mt: 1, mb: 1, width: "100%" }}>
      <Img
        isSuccessDetails
        listCarrousel={
          images.map((item) => {
            return {
              id: item?.id as number,
              direccion: item?.urlImagen,
              idvehiculo: item?.id,
              tipo: "",
            };
          }) || []
        }
      />
    </Box>
  );
};
