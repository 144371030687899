import { useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const useRecaptcha = (action = "") => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleGetTokenRecaptcha = useCallback(async (): Promise<{ tokenCaptcha: string; error: string }> => {
    if (!executeRecaptcha) {
      return { tokenCaptcha: "", error: "Error: en Recaptcha" };
    }
    try {
      const tokenCaptcha = await executeRecaptcha("addSoom");

      return { tokenCaptcha, error: "" };
    } catch (error: any) {
      let messageError = error;
      if (typeof messageError !== "string") {
        messageError = error?.message || "Error: en Recaptcha";
      }
      return { tokenCaptcha: "", error: messageError };
    }
  }, [action, executeRecaptcha]);

  return {
    handleGetTokenRecaptcha,
  };
};

export default useRecaptcha;
