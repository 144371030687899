import { Typography } from "@mui/material";
import { FC } from "react";
import { CardBox } from "../StyleFilter";

type Props = {
  show: boolean;
  children: JSX.Element | JSX.Element[] | string;
};
const ItemOption: FC<Props> = ({ show, children }) => {
  if (!show) return <></>;

  return (
    <>
      <Typography sx={{ fontSize: "10px" }}>|</Typography>
      <CardBox>
        <Typography sx={{ color: "#424242", p: 1 }}>{children}</Typography>
      </CardBox>
    </>
  );
};

export default ItemOption;
