import { ID_BETWEEN_CITIES } from '@/consts/defaultConsts';
import { optionTwo } from '@/consts/noticeConsts';
import {
  CustomerListTypeRequestDTO,
  MockListCustomerTypesRequest,
} from '@/interfaces/customerRequestInterface';
import { GetListNoticeFilterThunkProps } from '@/interfaces/noticeInterface';
import { TypeRequest } from '@/models/customerRequest/customerRequestTypes';
import {
  RequestConditions,
  RequestTypeConditionsData,
} from '@/models/customerRequest/customerRequestTypesConditions';
import { ISolicitudViewDetailsResponse } from '@/models/customerRequest/requestDetailsVewResponse';
import { Cuantas_Negociaciones } from '@/models/noticeNegotation/noticeNegotationModel';
import {
  ICustomerPhotosProps,
  ISaveCustomerRequestExpressDTO,
  ISaveCustomerRequestMovingDTO,
  saveCustomerRequestDTO,
} from '@/services/interfaces/customerRequestInterface';
import {
  IOrdenamiento,
  TiposFiltro,
} from '@/store/FilterServices/filterReduxInterface';
import { InitialStateCustomerRequest } from '@/store/customerRequestRedux/customerRequestSlice';
import { multiSelectCity } from '@/store/locationRedux/selectLocation';
import { IFile } from '@/store/offertRedux/offert.interface';
import {
  blobToFile,
  getMultiPromises,
  stringBase64ToBlob,
} from '@/utils/helpers';

export const prepareListCustomerRequestAdapter = (
  listRequest: TypeRequest[],
  mockList: MockListCustomerTypesRequest[]
): CustomerListTypeRequestDTO[] => {
  let newList = listRequest.map((itemRequest) => {
    const mockItem = mockList.find((item) => item.id === itemRequest.id);

    return {
      ...mockItem,
      ...itemRequest,
    };
  });
  try{
    newList = newList.sort((a, b) => a.orden - b.orden);
  }catch(e){
    
  }

  return newList;
};

export const prepareSaveInCityAdapter = (
  customerRequest: InitialStateCustomerRequest,
  price: string
): saveCustomerRequestDTO => {
  const {
    selectedTypeRequest,
    selectedTypevehicle,
    destinyReference,
    originReference,
    descriptionLoad,
    dateServices,
    selectedTypeLoadRequest,
    selectedEstimatedWeightRequest,
  } = customerRequest;
  const locations = getLocationByInitialState(customerRequest);
  const condiciones = getListConditions(customerRequest);

  let customerBody: saveCustomerRequestDTO = {
    condiciones,
    ...locations,
    fecha: dateServices,
    idTipoSolicitud: selectedTypeRequest.id,
    precio: Number(price),
    referencia: originReference,
    referenciaDestino: destinyReference,
    descripcionCarga: descriptionLoad,
    idRangoPeso: selectedEstimatedWeightRequest.id,
    idTipoVehiculo: selectedTypevehicle.id,

    idTipoCarga: selectedTypeLoadRequest.id,
  };

  return customerBody;
};

export const prepareSaveMultidestinyAdapter = (
  customerRequest: InitialStateCustomerRequest,
  multiSelectDestiny: multiSelectCity[],
  price: string,
  firstInputValue: string,
  secondInputValue: string,
  flaghoraopunto: number
): any => {
  const { selectedCity } = customerRequest;
  const {
    selectedTypeRequest,
    selectedTypevehicle,
    descriptionLoad,
    dateServices,
    selectedEstimatedWeightRequest,
    selectedTypeLoadRequest
  } = customerRequest;
  const newFirstInputValue = Number(firstInputValue);
  const condiciones = getListConditionsV2(customerRequest);
  const destinos = multiSelectDestiny.map((item) => ({
    idDistrito: item.id,
  }));

  let horasOrPuntos =
    flaghoraopunto === optionTwo
      ? {
          puntosRequeridos: newFirstInputValue,
        }
      : {
          horasRequeridas: newFirstInputValue,
        };
  let customerBody: any = {
    condiciones,
    idCiudad: selectedCity.id,
    idCiudadDestino: selectedCity.id,
    fecha: dateServices,
    idTipoSolicitud: selectedTypeRequest.id,
    precio: Number(secondInputValue),
    ...horasOrPuntos,
    descripcionCarga: descriptionLoad,
    idRangoPeso: selectedEstimatedWeightRequest.id,
    flgHoraoPunto: flaghoraopunto,
    idTipoVehiculo: selectedTypevehicle.id,
    destinos: destinos,

    idTipoCarga: selectedTypeLoadRequest.id,
  };

  return customerBody;
};

export const prepareSaveBetweenCitiesAdapter = (
  customerRequest: InitialStateCustomerRequest,
  price: string
): saveCustomerRequestDTO => {
  const {
    selectedTypeRequest,
    selectedTypevehicle,
    destinyReference,
    originReference,
    descriptionLoad,
    dateServices,
    selectedCityDestiny,
    selectedDistrictDestiny,
    selectedEstimatedWeightRequest,
    selectedTypeLoadRequest,
  } = customerRequest;
  const locations = getLocationByInitialState(customerRequest);
  const condiciones = getListConditions(customerRequest);

  let customerBody: saveCustomerRequestDTO = {
    condiciones,
    idCiudad: locations.idCiudad,
    idOrigen: locations.idOrigen,
    idCiudadDestino: selectedCityDestiny.id,
    idDestino: selectedDistrictDestiny.id,
    fecha: dateServices,
    idTipoSolicitud: selectedTypeRequest.id,
    precio: Number(price),
    referencia: originReference,
    referenciaDestino: destinyReference,
    descripcionCarga: descriptionLoad,
    idRangoPeso: selectedEstimatedWeightRequest.id,
    idTipoVehiculo: selectedTypevehicle.id,
    idTipoCarga: selectedTypeLoadRequest.id,
  };

  return customerBody;
};

const getLocationByInitialState = (
  customerRequest: InitialStateCustomerRequest
) => {
  const { selectedCity, selectedDestiny, selectedOrigin } = customerRequest;

  return {
    idCiudad: selectedCity.id,
    idOrigen: selectedOrigin.id,
    idDestino: selectedDestiny.id,
    idCiudadDestino: selectedCity.id,
  };
};

export const getListConditions = ({
  lisConditions,
}: InitialStateCustomerRequest) => {
  const prepareList = lisConditions.map((list) => ({
    idCondicion: list.id,
    flgSeleccion: Number(list.isActive),
  }));

  return prepareList;
};

export const getListConditionsV2 = ({
  lisConditions,
}: InitialStateCustomerRequest) => {
  const prepareList = lisConditions.map((list) => ({
    idCondicion: list.id,
    flgSeleccion: Number(list.isActive),
  }));

  return prepareList;
};

export const getListAmbientes = ({
  ambientes,
}: InitialStateCustomerRequest) => {
  const prepareList = ambientes.map((list) => ({
    idAmbiente: list.id,
    cantidad: list.cantidad,
  }));

  return prepareList;
};

// ........
// photos
// ........
export const getBodyCustomerRequestPhotoAdapter = async (
  data: IFile[]
): Promise<ICustomerPhotosProps> => {
  const files = await setStructureBodyCustomerRequestPhotoAdapter(data);
  const buffert = await getMultiPromises(files);
  const result = getBodyCustomerRequestPhotoByBuffert(buffert);

  return result;
};

const setStructureBodyCustomerRequestPhotoAdapter = async (data: IFile[]) => {
  const files = data.map(async (currentFile, i) => {
    const photoBlob = await stringBase64ToBlob(currentFile.blob);
    const photo = blobToFile(photoBlob, currentFile.name);

    return {
      [`foto${i + 1}`]: photo,
    };
  });

  return files;
};

const getBodyCustomerRequestPhotoByBuffert = (
  buffert: PromiseSettledResult<any>[]
) => {
  const defaultResponse = {
    foto1: undefined,
    foto2: undefined,
    foto3: undefined,
  };
  const result = buffert.reduce((acc, el) => {
    if (el.status === 'fulfilled') {
      acc = { ...acc, ...el.value };
    }
    return acc;
  }, {});

  return { ...defaultResponse, ...result };
};

export const prepareSaveMovingAdapter = (
  customerRequest: InitialStateCustomerRequest
): ISaveCustomerRequestMovingDTO => {
  const {
    selectedTypeRequest,
    dateServices,
    originReference: referencia,
    idInmueble,
    piso,
    flgAscensor,
    selectedCityDestiny,
    destinyReference: referenciaDestino,
    idInmuebleDestino,
    pisoDestino,
    flgAscensorDestino,
    flgCiudadoEntreciudades,
    metraje,
  } = customerRequest;
  const locations = getLocationByInitialState(customerRequest);
  const condiciones = getListConditions(customerRequest);
  const ambientes = getListAmbientes(customerRequest);

  return {
    condiciones,
    ambientes,
    idCiudad: locations.idCiudad,
    idOrigen: locations.idOrigen,
    referencia,
    idInmueble: idInmueble,
    piso: piso,
    flgAscensor: flgAscensor,
    idCiudadDestino:
      flgCiudadoEntreciudades === 1
        ? locations.idCiudadDestino
        : selectedCityDestiny.id,
    idDestino: locations.idDestino,
    referenciaDestino,
    idInmuebleDestino: idInmuebleDestino,
    pisoDestino: pisoDestino,
    flgAscensorDestino: flgAscensorDestino,
    fecha: dateServices,
    idTipoSolicitud: selectedTypeRequest.id,
    metraje: Number(metraje),
    flgCiudadoEntreciudades,
  };
};

// express
export const prepareSaveExpressAdapter = (
  customerRequest: InitialStateCustomerRequest
): ISaveCustomerRequestExpressDTO => {
  const {
    selectedTypeRequest,
    selectedTypevehicle,
    destinyReference,
    originReference,
    descriptionLoad,
    weightOfTheLoad,
    idVerifiedImages,
  } = customerRequest;
  const locations = getLocationByInitialState(customerRequest);
  const condiciones = getListConditions(customerRequest);

  return {
    condiciones,
    ...locations,
    idTipoSolicitud: selectedTypeRequest.id,
    referencia: originReference,
    referenciaDestino: destinyReference,
    descripcionCarga: descriptionLoad,
    idRangoPeso: weightOfTheLoad,
    idTipoVehiculo: selectedTypevehicle.id,
    idFotosVerificadas: idVerifiedImages,
    idTipoCarga: 1,
  };
};

export const defaultTipoSolicitud = (tipoSolicitud: string) => {
  return [
    {
      nombre: 'Tipo de solicitud',
      opciones: [tipoSolicitud],
    },
  ];
};

interface FilterCustomerRequestServiceSend
  extends Omit<GetListNoticeFilterThunkProps, 'date' | 'urlSolicitud'> {}
export const filterCustomerRequestServiceSend = (
  data: FilterCustomerRequestServiceSend
) => {
  const { page, size, ordenamiento, tiposFiltro } = data;

  const ordenamientoArray: string | string[] =
    ordenamiento.opciones[0].split(',');
  let newFilter = prepareDateRequest(structuredClone(tiposFiltro));
  let defaultFilter: TiposFiltro[] = [
    {
      nombre: 'OrigenCiudad',
      opciones: ['Lima'],
    },
  ];

  defaultFilter.forEach((item) => {
    const index = newFilter.findIndex(
      (item2: any) => item2.nombre === item.nombre
    );
    if (index !== -1) {
      defaultFilter.splice(index, 1);
    } else {
      newFilter.push(item);
    }
  });

  return {
    page,
    size,
    ordenamiento: ordenamientoArray,
    tiposFiltro: newFilter,
  };
};

const prepareDateRequest = (tiposFiltro: TiposFiltro[]) => {
  if (!tiposFiltro.length) return [];

  return tiposFiltro.map((filter) => {
    if (filter.nombre === 'Fecha de solicitud') {
      const opciones = filter.opciones.map((date) =>
        date?.replaceAll('/', '-')
      );
      return {
        ...filter,
        opciones,
      };
    }
    return filter;
  });
};

export const getMyCustomerRequestServiceSend = (
  page: number,
  size: number,
  ordenamiento: IOrdenamiento,
  tiposFiltro: TiposFiltro[]
) => {
  const ordenamientoArray = ordenamiento.opciones[0].split(',') as
    | string[]
    | [];
  let newFilter = structuredClone(tiposFiltro);
  let defaultFilter: TiposFiltro[] = [];
  defaultFilter.forEach((item) => {
    const index = newFilter.findIndex(
      (item2: any) => item2.nombre === item.nombre
    );
    if (index !== -1) {
      defaultFilter.splice(index, 1);
    } else {
      newFilter.push(item);
    }
  });

  return {
    page,
    size,
    ordenamiento: ordenamientoArray,
    tiposFiltro: newFilter,
  };
};

export const amountNegotationAdapter = (
  data: ISolicitudViewDetailsResponse
): Cuantas_Negociaciones => {
  const result = {
    tiposFiltro: [
      {
        nombre: 'Id de solicitudes',
        opcionesnumero: [data.id],
      },
      {
        nombre: 'Abierto o Cerrado',
        opcionesnumero: [0, 1],
      },
    ],
  };

  return result;
};

export const defaultListClientOffertAdapter = (
  CustomerId: number,
  option: number
): TiposFiltro[] => {
  return [
    {
      nombre: 'Id de solicitudes',
      opciones: [],
      opcionesnumero: [CustomerId],
    },
    {
      nombre: 'Abierto o Cerrado',
      opciones: [],
      opcionesnumero: [option],
    },
  ];
};

export const prepareListConditionsAdapter = (
  listConditions: RequestTypeConditionsData,
  typeRequest: CustomerListTypeRequestDTO
): RequestConditions[] => {
  const { solicitudTiposCondiciones } = listConditions;

  const conditions: RequestConditions[] = solicitudTiposCondiciones?.map(
    ({ condicionesSolicitud, porDefecto, flgVer }) => ({
      ...condicionesSolicitud,
      flgVer,
      idcondicion: condicionesSolicitud.id,
      isActive: !!porDefecto,
      flgorigendestino: condicionesSolicitud?.flgorigendestino || 0,
    })
  );

  if (typeRequest.id === ID_BETWEEN_CITIES) {
    conditions.forEach((condition) => {
      if (condition.id === 1) {
        condition.nombre = condition.creadorNegativo;
      }
    });
  }

  return conditions;
};
