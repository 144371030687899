import { FORMAT_DATE_FILTER } from "@/components/Filter/consts/filtersConst";
import DatePicker from "@/components/form/datePicker.tsx/DatePicker";
import Input from "@/components/inputCustom/Input";
import LabelRequired from "@/components/utils/LabelRequired";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { InputAdornment } from "@mui/material";
import dayjs from "dayjs";
import { renderInputTimeService, resizeInputsProps } from "./callbacks";

const initDate = dayjs().add(1, "day");
const maxDate = dayjs().add(1, "month");

export const configAuctionBetweenCities = [
  [
    {
      styleRow: {
        marginTop: "16px",
      },
      columns: [
        {
          columnsProps: {
            lg: 4,
            md: 4,
            xs: 12,
            sx: { paddingRight: "15px" },
            callback: resizeInputsProps,
          },
          component: Input,
          props: {
            fullWidth: true,
            label: <LabelRequired str="Origen" />,
            placeholder: "Origen",
            name: "origin",
          },
        },
        {
          columnsProps: {
            lg: 4,
            md: 4,
            xs: 12,
            sx: { paddingRight: "15px" },
            callback: resizeInputsProps,
          },
          component: Input,
          usage: [4],
          props: {
            fullWidth: true,
            label: <LabelRequired str="Destino" />,
            placeholder: "Destino",
            name: "destiny",
          },
        },
        {
          columnsProps: {
            lg: 4,
            md: 4,
            xs: 12,
            sx: { paddingRight: "15px" },
            callback: renderInputTimeService,
          },
          component: DatePicker,
          props: {
            fullWidth: true,
            initValue: initDate,
            label: <LabelRequired str="Fecha del servicio" />,
            name: "timeService",
            minDate: initDate,
            renderInput: Input,
            inputFormat: FORMAT_DATE_FILTER,
            maxDate: maxDate,
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarMonthOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: null,
            },
          },
        },
      ],
    },
  ],
];
