import { MY_PENDING_AGREEMENTS } from '@/consts/typeServicesPaths';
import { LocationState } from '@/interfaces/globalInterface';
import { useMediaQuery, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const useFinishedLayoutHook = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const states = (state as LocationState);

  const handleNavigationBack = () => {
    navigate(-1);
  };

  const navigateNegociations = () => {
    navigate(MY_PENDING_AGREEMENTS, {
      replace: true,
    });
  };

  return {
    theme,
    matches,
    navigateNegociations,
    handleNavigationBack,
    hasBackNavigate: states?.hasBackNavigate || false,
    showBtnCalification: states?.showCalification || false,
  };
};

export default useFinishedLayoutHook;
