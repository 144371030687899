import FormConditionsBetweenCities from "./formConditionsBetweenCities/FormConditionsBetweenCities";
import FormConditionsInCity from "./formConditionsInCity/FormConditionsInCity";
import FormConditionsMoving from "./formConditionsMoving/FormConditionsMoving";
import FormConditionsMultidestiny from "./formConditionsMultiDestiny/FormConditionsMultidestiny";


export const objectRenderComponent: Record<number, () => JSX.Element> = {
    1: FormConditionsInCity,
    2: FormConditionsMultidestiny,
    3: FormConditionsBetweenCities,
    5: FormConditionsMoving
  };
  