import ShareButton from "@/components/shareButton/ShareButton";
import { NEGOTATION_CLIENT_PATH } from "@/consts/negotationFlowPath";
import Icon, { IconTypes } from "@/utils/Icon";
import { Stack, styled, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";

const Title = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.text.secondary};
        font-weight: bold;
        margin-left: 8px;
      `
);

const ConatinerDetails = styled(Stack)(
  () => `
      justify-content: space-between;
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      margin-top: 10px;
    `
);
export type DetailsTextProps = {
  show: boolean;
  title: string;
  origen: string;
  originDescription: string;
  icon: IconTypes;
  share?: boolean;
};

const DetailTextTitle: React.FC<DetailsTextProps> = ({ show, title, icon, share, originDescription }) => {
  const {
    pathname,
  } = useLocation();
  if (!show) return <></>;

  const isOwner = share || pathname?.includes(NEGOTATION_CLIENT_PATH);

  return (
    <ConatinerDetails
      sx={{
        alignItems: 'center',
      }}
    >
      <Stack flexDirection="row">
          <Icon name={icon} size="sm"/>
        <Title
          variant="caption"
          sx={{
            color: "#757575",
            lineHeight: "20px",
            fontSize: "12px",
            fontWeight: "305",
          }}
        >
          {title}
        </Title>
      </Stack>
      <Stack>
      <ShareButton  
        show={!isOwner}
      />
      </Stack>
    </ConatinerDetails>
  );
};

export default DetailTextTitle;
