import {
  FORM_CONTACT_US,
  FREQUENT_QUESTIONS,
  TYPES_MOTIVATIONS,
} from '@/consts/pathServices';
import {
  IFormContact,
  ValidateContactResponseAPI,
} from '@/models/landing/contactFormApi';

import { connection, instanceAuth } from '@/services/connection';

// ==================================================
//     @OJO : Definicion del API de conexion aqui!!
// ==================================================
const API = connection(instanceAuth);

export const getTypesMotivationServices = async () => {
  const { data } = await API.get(TYPES_MOTIVATIONS);
  return data;
};

export const postFormContact = async (
  body: IFormContact
): Promise<ValidateContactResponseAPI> => {
  const { data } = await API.post(FORM_CONTACT_US, body);

  return data;
};

export const postFormSponsoredContact = async (
  body: IFormContact
): Promise<ValidateContactResponseAPI> => {
  const { data } = await API.post(FORM_CONTACT_US, body);

  return data;
};

export const getPreguntasFrecuentesServices = async () => {
  const { data } = await API.get(FREQUENT_QUESTIONS);
  return data;
};
