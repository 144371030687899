import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { FC, useEffect, useState } from "react";

type Props = {
  initVisibility?: boolean;
  onChangeVisible: (isVisible: boolean) => void;
};

const VisibilityIcons: FC<Props> = ({ initVisibility, onChangeVisible }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (initVisibility) {
      setIsVisible(initVisibility);
    }
  }, [initVisibility]);

  const handleChange = () => {
    setIsVisible(!isVisible);
    onChangeVisible(!isVisible);
  };

  return (
    <>
      {isVisible ? (
        <VisibilityIcon color="action" onClick={handleChange} />
      ) : (
        <VisibilityOffIcon color="action" onClick={handleChange} />
      )}
    </>
  );
};

export default VisibilityIcons;
