import { DRIVER_TYPE_NOTICE, NOTICE_AUCTION, NOTICE_BETWEEN_CITIES, NOTICE_IN_CITY, NOTICE_MOVING, NOTICE_MULTI_DESTINY } from "@/consts/noticeV2Paths";
import { HOME_PAHT } from "@/consts/typeServicesPaths";


const noticePathsById: Record<number, string> = {
    1: NOTICE_IN_CITY,
    2: NOTICE_MULTI_DESTINY,
    3: NOTICE_BETWEEN_CITIES,
    4: NOTICE_AUCTION,
    5: NOTICE_MOVING,
};


/**
 * Devuelve la ruta del aviso con la identificación dada, o la ruta de inicio si no se encuentra la
 * identificación
 * @param {number} noticeId - número - la identificación del aviso
 * @returns El camino hacia el aviso.
 */
export const getNamePathNoticeByIdNoticeV2Adapter = (
    noticeId: number,
    defaultPath = HOME_PAHT
  ) => {
    if (!noticeId) return defaultPath;
  
    return noticePathsById[noticeId] || defaultPath;
  };

/**
 * Toma un enlace, un id de aviso y un id, y devuelve un nuevo enlace con el id de aviso y el id
 * Esta {@function} al momento de crearse que necesita que el link tenga una estructura similar {/mis-avisos/:type-notice/:id/ofertas}
 * Se puede verificar un link asi, en esta constante -> PROFILE_NOTICE_OFFERT;
 * @param {string} link - cadena - el enlace a la oferta
 * @param {number} noticeId - número - la identificación del aviso
 * @param {number} id - numero - el id de la oferta
 * @returns El link con el tipo de aviso y id, a donde se apunta!!
 */
export const prepareLinkNoticeV2CreatedAdapter = (
    link: string,
    noticeId: number,
    id: number
  ) => {
    const noticePath = getNamePathNoticeByIdNoticeV2Adapter(noticeId);
    const newLink = link
      ?.replace(":id", id?.toString())
      ?.replace(DRIVER_TYPE_NOTICE, noticePath);
    return newLink;
  };