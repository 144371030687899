import { DetailsNegotationV2 } from "@/models/notice/noticeV2NegotationModels";
import { DataProfileDriverFormatReturn } from "@/pages/acceptanceOffert/adapters/AcceptanceAdapter";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { validUserNoticeNegV2 } from "../utils/utils";

export const dataProfileFormat = (
  savedOffert: DetailsNegotationV2
): DataProfileDriverFormatReturn => {
  const client = savedOffert?.cliente;

  const dataProfile = {
    idUser: client?.id,
    userName: client?.nombre as string,
    urlProfile: client?.perfilUrl,
    rating: client?.calificacion,
    ratingText: client?.calificacionTexto || "",
    showPrice: false,
    showTwoAvatars: false,
    sx: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  return dataProfile;
};

export const dataDriverFormat = (
  savedOffert: DetailsNegotationV2
): DataProfileDriverFormatReturn => {
  const driver = savedOffert?.aviso.transportista;

  return {
    idUser: driver?.id,
    userName: driver?.nombre as string,
    urlProfile: driver?.perfilUrl,
    rating: driver?.calificacion,
    ratingText: driver?.calificacionTexto || "",
    showPrice: false,
    showTwoAvatars: false,
    sx: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
};

export const dataTransportistaFormat = (
  savedOffert: DetailsNegotationV2
): DataProfileDriverFormatReturn => {
  const driver = savedOffert?.aviso.transportista;

  return {
    idUser: driver?.id,
    userName: driver?.nombre,
    urlProfile: driver?.perfilUrl,
    rating: driver?.calificacion,
    ratingText: driver?.calificacionTexto || "",
    showPrice: false,
    showTwoAvatars: false,
    sx: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
};

export const getUserNameRating = (offert: DetailsNegotationV2, user: IUser) => {
  const isClient = validUserNoticeNegV2(offert, user);
  let userName = offert?.cliente.nombre;
  if (isClient) {
    userName = offert?.aviso?.transportista?.nombre;
  }

  return userName;
};

export const getNumberPhoneInRating = (
  offert: DetailsNegotationV2,
  user: IUser
) => {
  const isClient = validUserNoticeNegV2(offert, user);
  let phone = offert?.cliente?.username;
  if (isClient) {
    phone = offert?.aviso?.transportista?.username;
  }

  return phone;
};

export const getEmailInRating = (offert: DetailsNegotationV2, user: IUser) => {
  const isClient = validUserNoticeNegV2(offert, user);
  let email = offert?.cliente?.email;
  if (isClient) {
    email = offert?.aviso?.transportista?.email;
  }

  return email;
};

export const getWhatsappInRating = (
  offert: DetailsNegotationV2,
  user: IUser
) => {
  let whatsapp = offert?.whatsapp;

  return whatsapp;
};
