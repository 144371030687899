import { ISolicitudNegociacion } from '@/services/interfaces/RequestNegResponseSuccess';
import { Box, Card, Grid, Typography, styled } from '@mui/material';
import { handleDataToRenderNegRequest, handleTypeMultiDestinyNegRequestCounterOffert } from '../../utils/bodyUtils';

export const CustomCardV2 = styled(Card)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minWidth: '275px',
    padding: '8px',
    boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
    minHeight: '100px',
  }));
interface IMultiDestinyNegOwnerPriceProps {
    data: ISolicitudNegociacion;
}
const MultiDestinyNegOwnerPrice = ({ data }: IMultiDestinyNegOwnerPriceProps) => {

    const requestData = handleTypeMultiDestinyNegRequestCounterOffert(data);

    const dataToRender = handleDataToRenderNegRequest({
        noticeData: requestData,
        data: data,
    });

    return (
        <Box mt={0}>
        {dataToRender.map((item, i) => (
          <CustomCardV2 sx={{ mt: 2 }}>
            <Typography sx={item.title.sx}>{item.title.name}</Typography>
            <Grid container>
              <Grid xs={4}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={item.columnTitleSx}>
                    {item.columnOne.title}
                  </Typography>
                  <Typography sx={item.clumnSubtitleSx}>
                    {item.columnOne.subtitle}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={4}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={item.columnTitleSx}>
                    {item.columnTwo.title}
                  </Typography>
                  <Typography sx={item.clumnSubtitleSx}>
                    {item.columnTwo.subtitle}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={4}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={item.columnTitleSx}>
                    {item.columnThree.title}
                  </Typography>
                  <Typography sx={item.clumnSubtitleSx}>
                    {item.columnThree.subtitle}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CustomCardV2>
        ))}
      </Box>
      )
}

export default MultiDestinyNegOwnerPrice
