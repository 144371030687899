import {
  DestinisAPI,
  MergeDetailNoticeModel,
} from '@/models/notice/noticeModels';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataResponseEnglishTypeTwo } from './interfaces/dataResponseEnglishInterfaces';
import { SendDataPayload } from './interfaces/sendDataInterfacesEnglish';
import { TypeProperty, TypePropertyAPI } from '@/models/offert/offertModels';
import { NoticeConditions } from '@/models/offert/offertConditionsModels';

// interface SelectTypeNotice  null | NoticeDetailInCityModel
export type SelectTypeNotice = null | MergeDetailNoticeModel;

type DataLocationAction = PayloadAction<
  Pick<InitialState, 'selectDate' | 'originReference' | 'destinyReference'>
>;

export interface PhotosPreLoaded {
  name: string;
  url: string;
}

export interface SendDataInitialState {
  inTheCity: any;
  multiDestiny: any;
  betweenCities: any;
  auctionBetweenCities: any;
  movingInTheCity: any;
  movingBetweenCities: any;
}

interface InitialState {
  sendData: SendDataInitialState;
  typeFlowId: number;
  photosPreLoaded: PhotosPreLoaded[];
  imageUploadError: string;
  isLoadingImage: boolean;
  idVerifiedImages: number;
  dataResponse: any | DataResponseEnglishTypeTwo | null;
  selectedUbications: SelectedUbications | null;
  avisoorigenes: DestinisAPI[];
  avisodestinos: DestinisAPI[];
  selectedOrigin: DestinisAPI;
  selectedDestiny: DestinisAPI;
  isLoading: boolean;
  error: string;
  idTypeNoticeNegotiation: number;
  selectTypeNotice: SelectTypeNotice;
  selectDate: string;
  originReference: string;
  destinyReference: string;
  price: string;
  puntos: number;
  horas: number;
  listTypeProperty: TypeProperty[]; // Ej: [{id: 1, nombre: 'Casa'}]
  listOfOfferConditions: NoticeConditions[];
}
const initSendData: SendDataInitialState = {
  inTheCity: {},
  multiDestiny: {},
  betweenCities: {},
  auctionBetweenCities: {},
  movingInTheCity: {},
  movingBetweenCities: {},
};

const initialState: InitialState = {
  sendData: initSendData,
  selectedUbications: null,
  idTypeNoticeNegotiation: 0,
  selectTypeNotice: null,
  typeFlowId: 0,
  photosPreLoaded: [],
  listTypeProperty: [],
  listOfOfferConditions: [],
  price: '',
  puntos: 0,
  horas: 0,
  selectDate: '',
  originReference: '',
  destinyReference: '',
  avisoorigenes: [], // TODO: Verificar si se esta usando!!
  avisodestinos: [],
  selectedOrigin: {
    iddistrito: 0,
    precio: 0,
    nombre: '',
  },
  selectedDestiny: {
    iddistrito: 0,
    precio: 0,
    nombre: '',
  },
  imageUploadError: '',
  isLoadingImage: false,
  idVerifiedImages: 0,
  dataResponse: null,
  isLoading: false,
  error: '',
};

export const noticeV2ClientAorCOSlice = createSlice({
  name: 'noticeV2ClientAorCO',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload?.message || payload;
      state.isLoading = false;
    },
    setIsLoadingImage: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingImage = payload;
    },
    fillSendNoticeData: (state, { payload }: { payload: SendDataPayload }) => {
      if (state.sendData[payload.key] === undefined) return;
      state.sendData[payload.key] = payload.value;
    },
    setSelectOrigin: (state, { payload }: PayloadAction<DestinisAPI>) => {
      state.selectedOrigin = payload;
    },
    setSelectDestiny: (state, { payload }: PayloadAction<DestinisAPI>) => {
      state.selectedDestiny = payload;
    },
    setDataResponse: (state, { payload }: PayloadAction<any>) => {
      state.dataResponse = payload;
      state.isLoading = false;
      state.isLoadingImage = false;
      state.sendData = initSendData;
      //state.photosPreLoaded = [];
      state.error = '';
    },
    setIdVerifiedImages: (state, { payload }: PayloadAction<number>) => {
      state.idVerifiedImages = payload;
      state.imageUploadError = '';
    },
    setPhotosPreLoaded: (
      state,
      { payload }: PayloadAction<PhotosPreLoaded[]>
    ) => {
      state.photosPreLoaded = payload;
      state.isLoadingImage = false;
    },
    setImageUploadError: (state, { payload }: PayloadAction<string>) => {
      state.imageUploadError = payload;
      state.isLoadingImage = false;
    },
    setAvisoOrigenes: (state, { payload }: PayloadAction<DestinisAPI[]>) => {
      state.avisoorigenes = payload;
      state.isLoading = false;
    },
    setAvisoDestinos: (state, { payload }: PayloadAction<DestinisAPI[]>) => {
      state.avisodestinos = payload;
      state.isLoading = false;
    },
    setSelectedUbications: (
      state,
      { payload }: PayloadAction<SelectedUbications>
    ) => {
      state.selectedUbications = payload;
    },
    setIdTypeNoticeNegotiation: (state, { payload }: PayloadAction<number>) => {
      state.idTypeNoticeNegotiation = payload;
    },
    setSelectTypeNotice: (
      state,
      { payload }: PayloadAction<SelectTypeNotice>
    ) => {
      state.selectTypeNotice = payload;
    },
    setSelectData: (state, { payload }: PayloadAction<string>) => {
      state.selectDate = payload;
    },
    setDataLocation: (state, { payload }: DataLocationAction) => {
      state = {
        ...state,
        ...payload,
      };
      return state;
    },
    setPrice: (state, { payload }: PayloadAction<string>) => {
      state.price = payload;
    },
    setPuntos: (state, { payload }: PayloadAction<number>) => {
      state.puntos = payload;
    },
    setHoras: (state, { payload }: PayloadAction<number>) => {
      state.horas = payload;
    },
    setListTypeProperty: (
      state,
      { payload }: PayloadAction<TypeProperty[]>
    ) => {
      state.listTypeProperty = payload;
    },
    setListOfOfferConditions: (
      state,
      { payload }: PayloadAction<NoticeConditions[]>
    ) => {
      state.listOfOfferConditions = payload;
    },
    resetSendData: () => initialState,
  },
});

export interface SelectedUbications {
  origin: DestinisAPI;
  destiny: DestinisAPI;
}

export const {
  setIsLoading,
  setError,
  fillSendNoticeData,
  setSelectedUbications,
  setIdVerifiedImages,
  setIsLoadingImage,
  setSelectOrigin,
  setSelectDestiny,
  setPhotosPreLoaded,
  setImageUploadError,
  setDataResponse,
  setAvisoOrigenes,
  setAvisoDestinos,
  resetSendData,
  setIdTypeNoticeNegotiation,
  setSelectTypeNotice,
  setSelectData,
  setDataLocation,
  setPrice,
  setPuntos,
  setHoras,
  setListTypeProperty,
  setListOfOfferConditions,
} = noticeV2ClientAorCOSlice.actions;
