import { Button, Container, Grid, styled, experimental_sx as sx, Box } from "@mui/material";

export const ContainerArea = styled(Container)(
    () => `
      padding: 16px 0;
    `
  );
  
  export const SubContainer = styled(Grid)(
    () => `
          display: flex;
          flex-direction: column; 
          justify-content: center; 
          margin-top: 24px;
        `
  );
  
  export const ButtonStyled = styled(Button)(
    sx({
      display: "flex",
      justifyContent: "start",
      color: "#1D1D1D",
      heigth: "44px",
      backgroundColor: "#FFFFFF",
      borderRadius: "10px",
      boxShadow: "0px 4px 10px #ECEFF1",
      mt: "5px",
      textTransform: 'none',
      gap: "8px",
      "&:hover": {
        backgroundColor: "#eeee",
      },
    })
  );


  export const IconStyled = styled(Box)(
    sx({
      dwidth: "24px", ml: 2, mr: 2
    })
  );