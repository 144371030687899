interface Props {
    style: {
        color?: string
        height: string
        width: string
    }
}
const MovingIcon = (props: Props) => {

    const { color = "#757575", height, width } = props?.style;
    return (
        <svg width={width} height={height} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.0983 7.33579C20.412 7.33579 18.7331 9.35052 18.3973 11.701L14.0321 51.324C13.6963 54.0103 16.0468 56.025 18.7331 56.025H34.5151C35.1867 56.025 35.8583 55.3535 35.8583 54.6819C35.8583 54.0103 35.1867 53.3387 34.5151 53.3387H18.7331C17.7257 53.3387 16.7184 52.3314 16.7184 51.324L21.0836 11.701C21.0836 10.3579 22.091 9.68631 23.0983 9.68631H40.8951C41.9025 9.68631 42.9098 10.6937 42.9098 11.701L44.9246 31.5125C44.9246 32.1841 45.5961 32.8557 46.2677 32.5199C46.9393 32.5199 47.6108 31.8483 47.2751 31.1767L45.2604 11.3652C44.9246 9.01473 43.2456 7 40.5593 7C40.8951 7.33579 23.0983 7.33579 23.0983 7.33579Z" fill={color} />
            <path d="M47.2751 35.542C41.5667 35.542 36.8657 40.243 36.8657 45.9514C36.8657 55.6893 46.2677 63.7482 46.2677 63.7482C46.9393 64.084 47.6109 64.084 47.9467 63.7482C47.9467 63.7482 57.3488 55.6893 57.3488 45.9514C57.3488 40.243 52.9835 35.542 47.2751 35.542ZM47.2751 37.8925C51.6404 37.8925 54.9982 41.2504 54.9982 45.9514C54.9982 53.003 48.6183 59.3829 47.2751 60.7261C45.932 59.3829 39.552 53.003 39.552 45.9514C39.552 41.2504 42.9099 37.8925 47.2751 37.8925Z" fill={color} />
            <path d="M47.2757 41.5861C44.5894 41.5861 42.5747 43.6008 42.5747 46.2871C42.5747 48.9734 44.5894 50.9881 47.2757 50.9881C49.962 50.9881 51.9768 48.9734 51.9768 46.2871C51.9768 43.9366 49.962 41.5861 47.2757 41.5861ZM47.2757 44.2724C48.2831 44.2724 49.2905 45.2797 49.2905 46.2871C49.2905 47.6302 48.2831 48.3018 47.2757 48.3018C46.2684 48.3018 45.261 47.2945 45.261 46.2871C44.9252 44.9439 45.9326 44.2724 47.2757 44.2724Z" fill={color} />
            <path d="M31.829 14.0515C31.1574 14.0515 30.4858 14.7231 30.4858 15.3947V19.7599C30.4858 20.4315 31.1574 21.1031 31.829 21.1031C32.5006 21.1031 33.1722 20.4315 33.1722 19.7599V15.3947C33.1722 14.3873 32.5006 14.0515 31.829 14.0515Z" fill={color} />
            <path d="M31.829 23.4537C31.1574 23.4537 30.4858 24.1253 30.4858 24.7969V29.1621C30.4858 29.8337 31.1574 30.5053 31.829 30.5053C32.5006 30.5053 33.1722 29.8337 33.1722 29.1621V24.7969C33.1722 24.1253 32.5006 23.4537 31.829 23.4537Z" fill={color} />
            <path d="M31.829 33.1914C31.1574 33.1914 30.4858 33.863 30.4858 34.5346V38.8998C30.4858 39.5714 31.1574 40.243 31.829 40.243C32.5006 40.243 33.1722 39.5714 33.1722 38.8998V34.5346C33.1722 33.863 32.5006 33.1914 31.829 33.1914Z" fill={color} />
            <path d="M31.829 42.9294C31.1574 42.9294 30.4858 43.601 30.4858 44.2726V48.6378C30.4858 49.3094 31.1574 49.981 31.829 49.981C32.5006 49.981 33.1722 49.3094 33.1722 48.6378V44.2726C33.1722 43.601 32.5006 42.9294 31.829 42.9294Z" fill={color} />
        </svg>
    )
}

export default MovingIcon