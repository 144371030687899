import { ID_COUNTEROFFER } from '@/consts/defaultConsts';
import { descriptionLoad } from '@/consts/noticeConsts';
import { ResponseNoticeOffer } from '@/models/offert/offertModels';
import { currencyFormat } from '@/utils/helpers';

export const showOrHiden = (
  tipoAvisoNegociacion: number,
  value: any,
  response: ResponseNoticeOffer
) => {
  return response ? '' : descriptionLoad;
};

export const getTypeAcount = (
  tipoAvisoNegociacion: number,
  value: any,
  response: ResponseNoticeOffer
) => {
  if (response.horas) {
    return 'Cantidad de horas: ';
  }

  return 'Cantidad de puntos: ';
};

export const getValueTypeAcount = (
  _: number,
  value: any,
  response: ResponseNoticeOffer
) => {
  return response?.horas || response?.puntos;
};

export const getTotalPrice = (
  _: number,
  value: any,
  response: ResponseNoticeOffer
) => {
  return currencyFormat(response.preciocalculado);
};

export const getPriceTitle = (
  tipoAvisoNegociacion: number,
  _: any,
  response: ResponseNoticeOffer
) => {
  const price = currencyFormat(response.precio);
  if (tipoAvisoNegociacion === ID_COUNTEROFFER) {
    return `Contraoferta: ${price}`;
  }

  return price;
};

export const getPriceType = (
  _: number,
  value: any,
  response: ResponseNoticeOffer
) => {
  if (response?.horas) {
    return 'Por hora';
  }

  return 'Por punto';
};
