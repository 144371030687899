import { Box, MenuItem, OutlinedInput } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useId, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { ISelectedVehicle } from "@/store/userRedux/userReduxInterface";
import { setSelectedVehicle } from "@/store/userRedux/userSlice";
import { postVehiclesByUserThunk } from "@/store/userRedux/userThunks";
import { FormControl, InputLabel, styled } from "@mui/material";
import LabelRequired from "@/components/utils/LabelRequired";
import { MaxLoadItem } from "@/pages/noticeV2/components/selectControl/SelectControl";

export const FormContainer = styled(FormControl)(() => ({
  minWidth: "120px",
}));

export const Label = styled(InputLabel)(({ theme }) => ({
  color: "rgba(0, 0, 0, 0.6)",
  fontSize: theme.typography.label1.fontSize,
  lineHeight: theme.typography.label3.lineHeight,
  fontFamily: theme.typography.label3.fontFamily,
  fontWeight: theme.typography.label1.fontWeight,
}));

interface ISelectCarProps {
  idAvisoOrSolicitud?: number;
}

const SelectCar = ({ idAvisoOrSolicitud }: ISelectCarProps) => {
  const [select, setselect] = useState("");
  const selectId = useId();
  const { vehicles, selectedVehicle } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      postVehiclesByUserThunk({
        idsolicitud: idAvisoOrSolicitud,
      })
    ).then((res) => {
      const validate = res?.some(
        (vehi: any) => vehi?.id === (selectedVehicle as ISelectedVehicle)?.id
      );
      if (res?.length === 0) {
        dispatch(setSelectedVehicle({}));
        return;
      }

      if (!validate && res?.length > 0) {
        dispatch(setSelectedVehicle(res[0]));
        return;
      }

      dispatch(
        setSelectedVehicle(
          !!Object.keys(selectedVehicle).length ? selectedVehicle : res[0]
        )
      );
    });
  }, []);

  useEffect(() => {
    setselect((selectedVehicle as ISelectedVehicle)?.id?.toString() || "");
  }, [selectedVehicle]);

  const handleSelectChange = ({ target }: SelectChangeEvent) => {
    const { value } = target;
    const selected = handleGetSelectedVehicle(value);
    setselect(value as string);

    dispatch(setSelectedVehicle(selected));
  };

  const handleGetSelectedVehicle = (
    value: string
  ): ISelectedVehicle | Object => {
    const selectedVehicle = vehicles.find(
      (vehicle) => vehicle?.id === Number(value)
    );

    return selectedVehicle || {};
  };

  return (
    <FormContainer >
      <InputLabel id={selectId}>
        <LabelRequired str="Tu vehículo" />
      </InputLabel>
      <Select
        labelId={selectId}
        id={selectId + "input"}
        value={select}
        onChange={handleSelectChange}
        name="vehicle"
        input={<OutlinedInput label="Tu vehículo d" />}
      >
        {vehicles.map(({ id, placa, tipovehiculo, cargamaxima }) => (
          <MenuItem value={id} key={`${id}-MenuItem`}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
            {tipovehiculo?.nombre} ({placa})
            {<MaxLoadItem maxLoad={cargamaxima} />}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormContainer>
  );
};

export default SelectCar;
