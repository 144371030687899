import DatePicker from "@/components/form/datePicker.tsx/DatePicker";
import {
  FORMAT_DATE_DDMMYYYY,
  FORMAT_DATE_YYYYMMDD,
} from "@/consts/formatsDate";
import { TiposFiltro } from "@/store/FilterServices/filterReduxInterface";
import { Stack, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { ButtonSelectDateFilter, ContainerListButtons } from "../styleServices";
import { handleDate, handleDateRange } from "../utils/handleCheck";

interface ListDate {
  title: string;
  type: number;
}

const List: ListDate[] = [
  {
    title: "Hoy",
    type: 1,
  },
  {
    title: "Mañana",
    type: 2,
  },
  {
    title: "Esta semana",
    type: 3,
  },
  {
    title: "La próxima semana",
    type: 4,
  },
  {
    title: "Este mes",
    type: 5,
  },
];

// {
//   "nombre":"Fecha de aviso",
//   "opciones": ["2022/12/01", "2022/12/02"]
// }
type IProps = {
  data: TiposFiltro;
  handleCheck: (
    value: string | string[],
    tipo: number,
    nombre: string | string[]
  ) => void;
  checkArrFilters: TiposFiltro[];
};

const FilterDate = ({ data, handleCheck, checkArrFilters }: IProps) => {
  const [fechaStart, setFechaStart] = useState<Dayjs | null>(
    dayjs().startOf("day")
  );
  const [fechaFin, setDateFin] = useState<Dayjs | null>(dayjs().endOf("day"));

  const [selected, setSelected] = useState(1);

  const handleInitDate = () => {
    const filter = checkArrFilters.find((item) => item.nombre === data.nombre);
    if (filter) {
      const [dateStart, dateFin] = handleDateRange(filter.opciones);
      const type = List.map((item) => item.type)?.find((item) => {
        const [fechaStart, fechaFin] = handleDateRange(handleDate(item));
        if (dateStart === fechaStart && dateFin === fechaFin) {
          return item;
        }
      });
      setSelected(typeof type === "number" ? type : 0);
      setFechaStart(dayjs(dateStart));
      setDateFin(dayjs(dateFin));
    }
  };

  useEffect(() => {
    handleInitDate();
  }, []);

  const handleDateChange = (date: Dayjs | null | any) => {
    setFechaStart(date);
    // cambiar la fecha fin solo si es menor a la fecha de inicio
    if (date && fechaFin && date.isAfter(fechaFin)) {
      setDateFin(date);
    }
    setSelected(0);
  };
  const handleDateChange2 = (date: Dayjs | null | any) => {
    setDateFin(date);
    setSelected(0);
  };

  const handleSelect = (type: number) => {
    const [fechaStart, fechaFin] = handleDate(type);
    setFechaStart(dayjs(fechaStart));
    setDateFin(dayjs(fechaFin));
    setSelected(type);
  };

  useEffect(() => {
    if (fechaStart && fechaFin) {
      handleCheck(
        [
          dayjs(fechaStart).format(FORMAT_DATE_YYYYMMDD),
          dayjs(fechaFin).format(FORMAT_DATE_YYYYMMDD),
        ],
        3,
        data?.nombre
      );
    }
  }, [fechaStart, fechaFin, selected]);

  return (
    <div>
      <ContainerListButtons>
        {List.map((value, index) => (
          <ButtonSelectDateFilter
            className={selected === value.type ? "active" : ""}
            onClick={() => handleSelect(value.type)}
            key={index}
          >
            {value.title}
          </ButtonSelectDateFilter>
        ))}
      </ContainerListButtons>
      <Typography variant="body1" sx={{ m: "20px 0" }}>
        Busca avisos:
      </Typography>
      <Stack spacing={2} sx={{ mt: "20px", gap: "20px" }}>
        <DatePicker
          initValue={fechaStart}
          inputFormat={FORMAT_DATE_DDMMYYYY}
          label="Desde"
          onChange={handleDateChange}
          maxDate={dayjs(new Date()).add(30, "day")}
        />

        <DatePicker
          initValue={fechaFin}
          inputFormat={FORMAT_DATE_DDMMYYYY}
          label="Hasta"
          onChange={handleDateChange2}
          minDate={dayjs(fechaStart)}
          maxDate={dayjs(new Date()).add(30, "day")}
        />
      </Stack>
    </div>
  );
};

export default FilterDate;
