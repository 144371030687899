import { FC } from "react";
import { DetailsTextProps } from "./interfaces";
import { useManageComponent } from "./useManageComponent";

const DetailsTextV2: FC<DetailsTextProps> = (props) => {
  const { show, origen } = props;
  const { ComponentToRender } = useManageComponent(props);

  if (!show || !origen) return <></>;

  return <ComponentToRender />;
};

export default DetailsTextV2;
