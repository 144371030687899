import { styled } from "@mui/material/styles";
import { Container, Grid, Stack, Typography } from "@mui/material";


export const ContainerNotice = styled(Container)(({ theme }) => ({
  maxWidth: "1152px !important",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  padding: "16px",
  " @media only screen and (min-width: 768px)": {
    padding: "30px 16px",
  },
}));

export const ContainerHeader = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  maxWidth: "1120px",
}));


export const Title = styled(Typography)(
  ({ theme }) => {
    return {
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "32px",
    };
  }
);

export const SubTitle = styled(Typography)(
  ({ theme }) => ({
    fontSize: "16px",
    fontWeight: "400",
  })
);

export const ContainerItems = styled(Stack)(() => ({
  gap: "16px",
  width: "100%",
  "@media only screen and (min-width: 899px)": {
    maxWidth: "1120px",
    padding: "32px 0px",
    flexDirection: "row",
    alignSelf: "center",
  },
}));


export const ContainerNoticeRegister = styled(Container)(({ theme }) => ({
  maxWidth: "1152px !important",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
}));

export const ContainerItemsRegister = styled(Grid)(() => ({
  gap: "16px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  // "@media only screen and (min-width: 899px)": {
  //   maxWidth: "1120px",
  //   padding: "32px 0px",
  //   flexDirection: "row",
  //   alignSelf: "center",
  // },
}));