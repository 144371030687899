import LabelRequired from "@/components/utils/LabelRequired";
import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import * as React from "react";

const radioStyles = {
  "&:hover": {
    backgroundColor: "transparent !important",
  },
};

interface Porps {
  value: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hiddenLabel?: boolean
}

export default function RadioButtonsGroup({hiddenLabel, value, handleChange}: Porps) {

  return (
    <Box sx={{mt: 1, mb: 1}}>
      {!hiddenLabel && <LabelRequired str="Tipo de cobro" />}
      <FormControl sx={{ width: "100%" }}>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <FormControlLabel
            value="2"
            control={<Radio color="warning" disableRipple sx={radioStyles} />}
            label=" Por punto"
          />
          <FormControlLabel
            value="1"
            control={<Radio color="warning" sx={radioStyles} />}
            label="Por hora"
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
}
