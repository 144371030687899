import { SolicitudNegociacion } from '@/models/noticeNegotation/noticeNegotationModel';

export const dataNogotationAdapter = (
  savedDataNogotation: SolicitudNegociacion
) => {
  const {
    ultimaSolicitudNegociacionAcciones: {
      tipoSolicitudNegociacionAccion: {
        flgPermitirAceptarTransportista,
        flgPermitirRechazarTransportista,
        flgPermitirContraofertarTransportista,
        flgPermitirAceptarCliente,
        flgPermitirRechazarCliente,
        flgPermitirContraofertarCliente,
        estado,
      },
    },
    solicitud,
    tipoSolicitudNegociacion,
    flgIsOwner,
    redirect,
  } = savedDataNogotation;

  const {
    tipoSolicitud: { id: idTipoSolicitud },
  } = solicitud;

  const tipoUsuario = estado.tipoUsuario as string;
  const tipoSolicitud = idTipoSolicitud as number;
  const tipoSolicitudNegociacionId = tipoSolicitudNegociacion.id;

  const flgActivo = estado.flgEstado;

  const validSnackbarDriver = () => {
    const activeBtnDriver =
      !!flgPermitirAceptarTransportista &&
      !!flgPermitirRechazarTransportista &&
      !!flgPermitirContraofertarTransportista;

    return tipoUsuario === 'C' && !activeBtnDriver && flgActivo;
  };
  const urlRedirect = redirect;

  const validSnackbarClient = () => {
    const activeBtnClient =
      !!flgPermitirAceptarCliente &&
      !!flgPermitirRechazarCliente &&
      !!flgPermitirContraofertarCliente;

    return tipoUsuario === 'T' && !activeBtnClient && flgActivo;
  };

  const flgAbierto = estado?.flgAbierto as number;
  const typeRequest = idTipoSolicitud as number;
  const isOwner = flgIsOwner;

  return {
    validSnackbarDriver,
    validSnackbarClient,
    isOwner,
    typeRequest,
    flgActivo,
    flgAbierto,
    urlRedirect,
    tipoSolicitud,
    tipoSolicitudNegociacionId,
  };
};
