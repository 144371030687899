import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  ButtonAccionModal,
  ContainerAds,
  ImageContainer,
  ImageContainertwo,
  SubContainerAds,
  TypographText,
  TypographTitle,
} from "./styles";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  text: string;
  btnTxt: string;
  urlBtn?: string;
  imgOne: string;
  imgTwo: string;
}

const AdsSection = (props: Props) => {
  const { title, text, urlBtn = "/", btnTxt, imgOne, imgTwo } = props;
  const desktopSx = useMediaQuery("(max-width:950px)");
  const mobile = useMediaQuery("(max-width:770px)");

  return (
    <ContainerAds maxWidth="lg" mobile={mobile}>
      <SubContainerAds mobile={mobile}>
        <TypographTitle>{title}</TypographTitle>
        <TypographText>{text}</TypographText>

       
          <ButtonAccionModal
            variant="contained"
            color="inherit"
            sx={{ marginTop: "22px", marginBottom: "22px" }}
            href={urlBtn}
          >
            {btnTxt}
          </ButtonAccionModal>
    
      </SubContainerAds>
      <ImageContainer mobile={mobile}>
        <img
          width={desktopSx ? "120px" : "146px"}
          height={desktopSx ? "230px" : "280px"}
          src={imgOne}
          alt="img2"
        />
      </ImageContainer>
      <ImageContainertwo mobile={mobile}>
        <img
          width={desktopSx ? "333px" : "405px"}
          height={desktopSx ? "230px" : "280px"}
          src={imgTwo}
          alt="img2"
        />
      </ImageContainertwo>
    </ContainerAds>
  );
};

export default AdsSection;
