import { DetailsNegotationV2 } from "@/models/notice/noticeV2NegotationModels";
import { useGetComponentNoticeNeg } from "@/pages/noticeV2Negotation/shared/hooks/useGetComponentNoticeNeg";
import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import useNoticeConfigDataV2 from "@/pages/noticeV2Negotation/shared/hooks/useNoticeConfigDataV2";
import DynamicComponent from "@/pages/offerts/offertConfirmation/components/DynamicComponent";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { Grid } from "@mui/material";
import { coCounterOfferDataMultidestinyV2 } from "./config";

export const MultidestinyCoCounterOfferV2 = () => {
  const { notice } = useGetData();
  const { handleInitConfig } = useNoticeConfigDataV2(
      notice as DetailsNegotationV2
    );
    const { selectdConfigDataV2 } = useGetComponentNoticeNeg({
      notice: notice as NoticeV2NegType,
      detailsConfigToRender: coCounterOfferDataMultidestinyV2,
    });



  return (
    <>
      {selectdConfigDataV2.map((detail, i) => {
        return (
          <Grid
            key={`${i}-detail`}
            container
            gap={1}
            alignItems="center"
            marginBottom="6px"
          >
            <DynamicComponent
              key={`detail-${i}`}
              {...detail}
              {...handleInitConfig(detail)}
            />
          </Grid>
        );
      })}
    </>
  );
}
