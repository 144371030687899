import {
  ContainerText,
  Item,
  ItemSubTitle,
  ItemTitle,
} from "@/components/noticeListItem/styleNoticeListItem";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { CustomerListTypeRequestDTO } from "@/interfaces/customerRequestInterface";
import SkeletonNoticeList from "@/pages/loading/SkeletonNoticeList";
import {
  ContainerHeader,
  ContainerItems,
  ContainerNotice,
  SubTitle,
  Title,
} from "@/pages/notice/noticeList/styleNoticeList";
import {
  resetState,
  setSelectTypeRequest,
} from "@/store/customerRequestRedux/customerRequestSlice";
import {
  getCustomerRequestTypesThunk,
  initFlowCustomerRequestThunk,
} from "@/store/customerRequestRedux/customerRequestThunk";
import { setMultiSelectDestinyV2 } from "@/store/locationRedux/selectLocation";
import { initRouteFlowByIdNoticeThunk } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import Icon, { IconTypes } from "@/utils/Icon";
import { replaceValue } from "@/utils/helpersActions";
import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { customerRequestObjectTypes } from "../../shared/consts/customerRequestConsts";
import customerRequestData from "./customerRequestListData.json";

const ListCustomerRequest = () => {
  const { isLoading, listCustomerTypeRequest } = useAppSelector(
    (state) => state.customerRequest
  );
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    dispatch(resetState());
    dispatch(setMultiSelectDestinyV2([]));

    dispatch(getCustomerRequestTypesThunk(customerRequestData.listItems));

    return () => {};
  }, []);

  const handleClick = (item: CustomerListTypeRequestDTO) => {
    dispatch(setSelectTypeRequest(item));
    let route = dispatch(
      initRouteFlowByIdNoticeThunk(item.id, "customerRequest")
    );
    dispatch(initFlowCustomerRequestThunk());

    const nextRoute = replaceValue(
      route,
      ":typeRequest",
      customerRequestObjectTypes[item.id]
    );

    navigate(nextRoute);
  };

  if (isLoading) {
    return <SkeletonNoticeList />;
  }

  return (
    <ContainerNotice>
       
      <ContainerHeader>
        <Title sx={{fontWeight: 700}}>{customerRequestData?.title}</Title>
        <SubTitle sx={{fontSize: "16px"}}>{customerRequestData?.subTitle}</SubTitle>
      </ContainerHeader>
      <ContainerItems>
        {listCustomerTypeRequest.map((item, i) => (
          <Item key={i} onClick={() => handleClick(item)}>
            <Icon color="info" name={item.iconDesk as IconTypes} size={matches ? 72 : 40} />
            <ContainerText>
              <ItemTitle>{item.nombre}</ItemTitle>
              <ItemSubTitle>{item.descripcion}</ItemSubTitle>
            </ContainerText>
          </Item>
        ))}
      </ContainerItems>
    </ContainerNotice>
  );
};

export default ListCustomerRequest;
