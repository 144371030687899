import { secondFontFamily } from "@/styles/theme";
import {
  Stack,
  styled,
  experimental_sx as sx,
  Typography,
} from "@mui/material";

export const Section = styled(Stack)(
  sx({
    alignItems: "center",
    padding: { xs: "32px 16px", sm: "32px 24px", lg: "64px" },
  })
);

export const SectionContainer = styled(Stack)(() => ({
  width: "100%",
  maxWidth: "1152px",
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: secondFontFamily,
  fontSize: theme.typography.h5.fontSize,
  lineHeight: theme.typography.h5.lineHeight,
  fontWeight: "bold",
  textAlign: "center",
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.body1.fontFamily,
  fontSize: theme.typography.body1.fontSize,
  lineHeight: theme.typography.body1.lineHeight,
  textAlign: "center",
}));
