import LayoutFlow from "@/components/LayoutFlow/LayoutFlow";
import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import BannerModal from "@/components/modals/BannerModal/BannerModal";
import { BASE_URL_IMAGE } from "@/config/config";
import {
  USER_BACK_CAR_DEFAULT,
  USER_FRONT_CAR_DEFAULT,
  USER_SIDE_CAR_DEFAULT,
} from "@/consts/defaultConsts";
import { TEXT_ERROR_IMAGE_GENERIC } from "@/consts/textErrors";
import { REGISTER_DRIVER_STEPS } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { DirtyTypeProps } from "@/interfaces/globalInterface";
import { UsuarioAPI } from "@/models/userAuth/user";
import { ISelectedVehicle } from "@/store/userRedux/userReduxInterface";
import { getUserByIdTunhk } from "@/store/userRedux/userThunks";
import { setError } from "@/store/vehicle/vehicleSlice";
import { updateVehiclePhotosThunk } from "@/store/vehicle/vehicleThunk";
import { getNextPage } from "@/utils/helpers";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { To, useLocation, useNavigate } from "react-router-dom";
import HeaderFlow from "../../components/HeaderFlow";
import SectionImagens from "./components/SectionImagens";
import { objectNextPage } from "./configDataVehiclePhotos";

type TypeImage = "FRONTAL" | "LATERAL" | "POSTERIOR";

const VehiclePhotos = () => {
  const [imageFront, setImageFront] = useState<string | File>("");
  const [imageErrorFront, setImageErrorFront] = useState<boolean>(false);

  const [imageSide, setImageSide] = useState<string | File>("");
  const [imageErrorSide, setImageErrorSide] = useState<boolean>(false);

  const [imageBack, setImageBack] = useState<string | File>("");
  const [imageErrorBack, setImageErrorBack] = useState<boolean>(false);

  const [errorApi, setErrorApi] = useState(false);

  const [dirtyFields, setDirtyFields] = useState<DirtyTypeProps>({
    imageFront: { dirty: false },
  });
  const [showAlert, setShowAlert] = useState(false);

  const {
    user: { isLoading: isUserLoading, vehicles, user },
    vehicle: { isLoading, savedVehicle, error },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const currentUser = user as UsuarioAPI;
  const [currentVehicle] = vehicles as ISelectedVehicle[];

  useEffect(() => {
    if (errorApi || error) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [errorApi, error]);

  useEffect(() => {
    if (error) {
      setErrorApi(true);
    } else if (!error && errorApi) {
      setErrorApi(false);
    }
  }, [error]);

  useEffect(() => {
    if (imageErrorFront) {
      setImageErrorFront(() => false);
    }
    errorApi && setErrorApi(() => false);
  }, [imageFront]);

  useEffect(() => {
    if (imageErrorSide) {
      setImageErrorSide(false);
    }
    errorApi && setErrorApi(false);
  }, [imageSide]);

  useEffect(() => {
    if (imageErrorBack) {
      setImageErrorBack(false);
    }
    errorApi && setErrorApi(false);
  }, [imageBack]);

  // ============== IMAGE FRONT =================
  const handleSelectedImageFront = (img: string | File) => {
    setImageFront(() => img);

    setDirtyFields((prev) => ({ ...prev, imageFront: { dirty: true } }));
  };

  const handleErrorImageFront = () => {
    setImageErrorFront(true);
    setImageFront("");
  };

  // ============== IMAGE SIDE =================
  const handleSelectedImageSide = (img: string | File) => {
    setImageSide(() => img);

    setDirtyFields((prev) => ({ ...prev, imageSide: { dirty: true } }));
  };

  const handleErrorImageSide = () => {
    setImageErrorSide(true);
    setImageSide("");
  };

  // ============== IMAGE BACK =================
  const handleSelectedImageBack = (img: string | File) => {
    setImageBack(img);

    setDirtyFields((prev) => ({ ...prev, imageBack: { dirty: true } }));
  };

  const handleErrorImageBack = () => {
    setImageErrorBack(true);
    setImageBack("");
  };

  const handleSubmit = async () => {
    if (!savedVehicle || isUserLoading || isLoading) return;
    const body = handleGetBody();

    dispatch(updateVehiclePhotosThunk(body)).then(() => {
      dispatch(getUserByIdTunhk(currentUser.id?.toString() || "0", true)).then(
        () => {
          handleGoNextPage();
        }
      );
    });
  };

  const handleGetBody = () => {
    const { FRONTAL, LATERAL, POSTERIOR } = handleGetPreloadImage();
    const body = {
      fotoFrontal: imageFront || FRONTAL,
      fotoLateral: imageSide || LATERAL,
      fotoPosterior: imageBack || POSTERIOR,
    };

    return body;
  };

  const handleGetPreloadImage = () => {
    const typePhotos: TypeImage[] = ["FRONTAL", "LATERAL", "POSTERIOR"];
    const photos = typePhotos.reduce(
      (acc, el) => {
        acc = {
          ...acc,
          [el]: handleImageDefault(el, false),
        };
        return acc;
      },
      { FRONTAL: "", LATERAL: "", POSTERIOR: "" }
    );
    return photos;
  };

  const handleGoNextPage = () => {
    let nextPage = getNextPage(location.pathname, objectNextPage);

    if (!savedVehicle?.tipovehiculo?.flgPlacaObligatoria) {
      nextPage = REGISTER_DRIVER_STEPS;
    }

    navigate(nextPage as To, { replace: true });
  };

  const handleDisabled = () => {
    const isNewVehicle = currentVehicle?.vehiculofotos?.length === 3;
    if (isNewVehicle) {
      return isLoading || isUserLoading;
    }

    return (
      !imageFront || !imageSide || !imageBack || isLoading || isUserLoading
    );
  };

  const handleGetDefaultImage = (type: TypeImage) => {
    switch (type) {
      case "FRONTAL":
        return BASE_URL_IMAGE + USER_FRONT_CAR_DEFAULT;
      case "LATERAL":
        return BASE_URL_IMAGE + USER_SIDE_CAR_DEFAULT;
      default:
        return BASE_URL_IMAGE + USER_BACK_CAR_DEFAULT;
    }
  };

  const handleImageDefault = (type: TypeImage, setImageDefault = true) => {
    const vehiculofotos = currentVehicle?.vehiculofotos;
    const imageDefault = setImageDefault ? handleGetDefaultImage(type) : "";
    if (!!vehicles && !!vehiculofotos?.length) {
      const imageFront = vehiculofotos.find((photo) => photo.tipo === type);
      return imageFront?.direccion || imageDefault;
    }

    return imageDefault;
  };

  const handleBackNavigation = () => {
    const isDirtyForm = Object.values(dirtyFields).some(({ dirty }) => !!dirty);
    if (isDirtyForm) {
      setShowAlert(true);
    } else {
      navigate(REGISTER_DRIVER_STEPS, { replace: true });
    }
  };

  return (
    <LayoutFlow>
      <HeaderFlow title="Tu vehículo" customGoBack={handleBackNavigation} />

      <Typography>
        Para comenzar vas a crear un vehículo. Luego vas a poder crear más
        vehículos en tu perfil.
      </Typography>
      <>
        {errorApi && (
          <StandardAlert
            sx={{ marginBottom: "16px" }}
            severity="error"
            color="error"
            onCloseAlert={() => dispatch(setError(false))}
          >
            {error}
          </StandardAlert>
        )}
      </>

      <BannerModal
        open={showAlert}
        onClose={() => setShowAlert(false)}
        title="Cambios sin guardar"
        content="Aún no has guardado los cambios realizados. ¿Deseas salir?"
        option1={{
          title: "No, quedarme",
          onClick: () => setShowAlert(false),
        }}
        option2={{
          title: "Sí, salir",
          onClick: () => navigate(-1),
        }}
        color="org"
        sx={{
          width: "322px",
          height: "268px",
        }}
        sxAccion={{
          padding: "0px",
        }}
      />

      <SectionImagens
        title="Foto frontal"
        textError={TEXT_ERROR_IMAGE_GENERIC}
        showTextError={imageErrorFront}
        imageDefault={handleImageDefault("FRONTAL")}
        onError={handleErrorImageFront}
        onSelect={handleSelectedImageFront}
      />

      <SectionImagens
        title="Foto lateral"
        textError={TEXT_ERROR_IMAGE_GENERIC}
        showTextError={imageErrorSide}
        imageDefault={handleImageDefault("LATERAL")}
        onSelect={handleSelectedImageSide}
        onError={handleErrorImageSide}
      />

      <SectionImagens
        title="Foto posterior"
        textError={TEXT_ERROR_IMAGE_GENERIC}
        showTextError={imageErrorBack}
        imageDefault={handleImageDefault("POSTERIOR")}
        onSelect={handleSelectedImageBack}
        onError={handleErrorImageBack}
      />

      <ButtomLoader
        type="submit"
        onClick={handleSubmit}
        isLoading={isUserLoading || isLoading}
        disabled={handleDisabled()}
        sx={{ marginBottom: "16px" }}
      >
        SIGUIENTE
      </ButtomLoader>
    </LayoutFlow>
  );
};

export default VehiclePhotos;
