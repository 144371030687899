import { NoticeConditions } from "@/models/notice/noticeModels";
import { IListOfServices } from "@/store/includedServices/includedServicesSlice";
import { EditNoticeConditionsServices } from "../interfaces/noticeInterfaces";

export const editNoticeConditionsListAdapter = (
  list: IListOfServices[] | NoticeConditions[]
): EditNoticeConditionsServices => {
  const newList = list.map((item) => ({
    id: item.id,
    idcondiciones: item.idcondiciones,
    flgseleccion: item.isActive || 0,
  }));

  return { avisocondiciones: newList };
};
