import { FormControl, InputLabel, styled } from "@mui/material";

export const FormContainer = styled(FormControl)(
    () => ({
        minWidth: "120px"
    })
);

export const Label = styled(InputLabel)(
    ({ theme }) => ({
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: theme.typography.label1.fontSize,
        lineHeight: theme.typography.label3.lineHeight,
        fontFamily: theme.typography.label3.fontFamily,
        fontWeight: theme.typography.label1.fontWeight
    })
);