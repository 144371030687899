import DetailsTextV2 from '@/components/DetailsTextV2/Index';
import ConditionsListOffert from '@/components/conditionsList/ConditionsListOffert';
import UserRating from '@/components/userRating/UserRating';
import { districtsDeliveryText } from '@/consts/noticeConsts';
import { ImagesNegV2 } from '@/pages/noticeV2Negotation/components/ImagesNegV2';
import { Typography } from '@mui/material';

import {
  getDescriptionLoadRequestNeg,
  getFormatSendDateRequestNeg,
  getImagesRequestNeg,
  getMaxWeightVehicleRequestNeg,
  getProfileDataRequestNeg,
  getValueInNegRequestNeg,
  getValueInOffertRequestNeg,
  getVehicleValueNotificationRequestNeg,
  getVehicleValuesRequestNeg,
} from '../../../../shared/utils/RequestNegotiationsUtils';

import { ConfigDataNegotation } from '@/pages/noticeRequestFlow/interface/noticeNegRequestInterface';

// ==================================================
//          CONFIGURACION MULTIDESTINO
// ==================================================

export const configDataOneCoCounterOfferMultidestiny: ConfigDataNegotation[] = [
  {
    Component: UserRating,
    props: {},
    valid: [
      {
        property: 'solicitud',
        componentProp: 'data',
        defaultResult: '',
        callback: getProfileDataRequestNeg,
        callbakcParams: ['solicitud'],
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'body2',
      sx: { fontSize: '16px', mb: 1, mt: 1, fontWeight: 600 },
      title: 'Detalles del servicio',
    },
  },

  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha',
      originDescription: '',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getValueInOffertRequestNeg,
        callbakcParams: ['solicitud', 'fecha'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getFormatSendDateRequestNeg,
        callbakcParams: ['solicitud', 'fecha'],
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-light',
      title: districtsDeliveryText,
      isMultidestiny: true,
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getValueInOffertRequestNeg,
        callbakcParams: ['solicitud', 'origenTexto'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getValueInOffertRequestNeg,
        callbakcParams: ['solicitud', 'origenTexto'],
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getVehicleValuesRequestNeg,
        callbakcParams: ['vehiculo'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getVehicleValueNotificationRequestNeg,
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getMaxWeightVehicleRequestNeg,
        callbakcParams: ['solicitud', 'referenciaDestino'],
      },
    ],
  },
  {
    title: 'Descripción de la carga',
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
      sx: { marginTop: '10px', display: 'block' },
    },
  },
  {
    Component: ImagesNegV2,
    props: {
      style: { marginTop: '13px' },
    },
    valid: [
      {
        property: 'fotos',
        componentProp: 'images',
        defaultResult: [],
        callback: getImagesRequestNeg,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      sx: {
        marginTop: '10px',
        display: 'block',
        wordWrap: 'break-word',
        maxWidth: '320px',
        fontWeight: 'none',
      },
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
        callback: getDescriptionLoadRequestNeg,
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-kilos',
      title: 'Peso estimado',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffertRequestNeg,
        callbakcParams: ['solicitud', 'pesoEstimado'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getValueInOffertRequestNeg,
        callbakcParams: ['solicitud', 'pesoEstimado'],
      },
    ],
  },

  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '22px' },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInNegRequestNeg,
        callbakcParams: ['condiciones'],
      },
      {
        property: 'condiciones',
        componentProp: 'listServices',
        defaultResult: [],
        callback: getValueInNegRequestNeg,
        callbakcParams: ['condiciones'],
      },
    ],
  },
];
