import { CSSProperties } from "react";
import ImgCarousel from "@/components/viewService/imgCarousel";
import { Photo } from "@/store/noticeV2ClientAcceptCounterOffer/interfaces/dataResponseEnglishInterfaces";
import { Box } from "@mui/material";
import { Photos } from "@/models/noticeNegotation/noticeNegotationModel";

type Props = {
  images: Photo[] | Photos[];
  style?: CSSProperties;
  alt?: string;
};

export const ImagesThumbnailV2 = ({ images }: Props) => {
  if (!images.length) return <></>;

  const handleGetImage = (item: Photo | Photos) => {
    if (item.hasOwnProperty("imageUrl")) {
      return (item as Photo)?.imageUrl;
    } else if (item.hasOwnProperty("urlImagen")) {
      return (item as Photos)?.urlImagen;
    }
    return "";
  };

  return (
    <Box sx={{ mt: 1, mb: 1 }}>
      <ImgCarousel
        isSuccessDetails
        listCarrousel={
          images.map((item) => {
            return {
              id: item?.id as number,
              direccion: handleGetImage(item),
              idvehiculo: item?.id,
              tipo: "",
            };
          }) || []
        }
      />
    </Box>
  );
};
