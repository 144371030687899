import { Container, List, styled, Typography } from "@mui/material";

export const Title = styled(Typography)(
    ({ theme }) => `
        font-family:${theme.typography.h6.fontFamily};
        font-size: ${theme.typography.h6.fontSize};
        line-height: ${theme.typography.h6.fontSize};
        font-weight: ${theme.typography.h6.fontWeight};
        color: ${theme.palette.text.secondary};
        margin-bottom: 16px;
        @media only screen and (min-width: 768px){
          font-family:${theme.typography.h5low.fontFamily};
          font-size: ${theme.typography.h5low.fontSize};
          line-height: ${theme.typography.h5low.fontSize};
          margin-bottom: 32px;
        }
       
      `
  );
  
export const ContainerProfile = styled(Container)(
    () => `
        padding: 16px;
        @media only screen and (min-width: 768px){
          padding: 32px 64px;
        }
      `
  );
  

export const CustomListLinks = styled(List)(
    ({ theme }) => `
        width: 100%;
        height: 100%;
        padding: 0px;
      `
  );

