import ButtomLoader from '@/components/form/buttonLoader/ButtomLoader';
import useNotiffication from '@/hooks/useNotiffication';
import { useAppDispatch, useAppSelector } from '@/hooks/useReducer';
import { defaultError } from '@/pages/noticeRequestFlow/consts/noticeRequestFlowConsts';
import { useButtonsFunctions } from "@/pages/noticeRequestFlow/hooks/useHandleGlobalButtons";
import { setError } from "@/store/noticeNegotationRequestRedux/noticeNegRequestSlice";
import { useEffect } from 'react';
import { ContainerButtons } from './StylesGlobalButtonsNotifications';


const CounterOfferGlobalButtons = () => {
  const {handleManageSnackbar, handleManageModalCounterOffer, handleAcceptButton, handleClientCounterOfferButton, handleDisabledButtons} = useButtonsFunctions()
  const {notiffication } = useNotiffication();
  const dispatch = useAppDispatch();
  const { error } = useAppSelector((state) => state.noticeNegotationRequest);
  const disableButtons = handleDisabledButtons()


  useEffect(() => {
    if (error) {
      handleManageSnackbar();
    } 
  }, [error])

  useEffect(() => {
    if(!notiffication){
      dispatch(setError(defaultError));
    }
  }, [notiffication])
  

  return (
    <ContainerButtons>
    <ButtomLoader
      fullWidth
      variant="contained"
      onClick={handleAcceptButton}
      disabled={!disableButtons.disableAcceptButton}
    >
      ACEPTAR
    </ButtomLoader>


        <ButtomLoader
          fullWidth
          variant='outlined'
          disabled={!disableButtons.disableCounterOfferButton}
          onClick={handleClientCounterOfferButton}
          sx={{ borderColor:  "#E0E0E0"  }}
        >
          CONTRAOFERTAR
        </ButtomLoader>
 

    <ButtomLoader
      fullWidth
      variant="text"
      onClick={handleManageModalCounterOffer}
      disabled={!disableButtons.disableDeclineButton}
    >
      RECHAZAR
    </ButtomLoader>
  </ContainerButtons>
  )
}

export default CounterOfferGlobalButtons