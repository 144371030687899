declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

// Carga de la pantalla de vehiculo !!
export const createServiceVehicleDataLayer = (idUsuario: number, noticeType: string) => {
  window.dataLayer.push({
    event: "create_service_vehicle",
    idusuario: idUsuario,
    tipoaviso: noticeType,
  });
};

// Carga de la pantalla de lugar !! 
// DEPRECATED!!!
export const createServiceLocationDataLayer = (idUsuario: number, noticeType: string) => {
  window.dataLayer.push({
    event: "create_service_location",
    idusuario: idUsuario,
    tipoaviso: noticeType,
  });
};

// Carga de la pantalla de condiciones !!
export const createServiceConditionsDataLayer = (idUsuario: number, noticeType: string) => {
  window.dataLayer.push({
    event: "create_service_conditions",
    idusuario: idUsuario,
    tipoaviso: noticeType,
  });
};

// Carga de la pantalla de lugar !!
export const createServiceFeeDataLayer = (idUsuario: number, noticeType: string) => {
  window.dataLayer.push({
    event: "create_service_fee",
    idusuario: idUsuario,
    tipoaviso: noticeType,
  });
};

// Carga de la pantalla de tipo de aviso (mudanza) !!
export const createServiceTypeDataLayer = (idUsuario: number, noticeType: string) => {
  window.dataLayer.push({
    event: "create_service_type",
    idusuario: idUsuario,
    tipoaviso: noticeType,
  });
};

// Carga de la pantalla de success !!
export const createServiceDataLayer = (idUsuario: number, noticeType: string) => {
  window.dataLayer.push({
    event: "create_service",
    idusuario: idUsuario,
    tipoaviso: noticeType,
  });
};
