import { FORMAT_DATE_DDMMYYYY, FORMAT_TIME_HHMM } from '@/consts/formatsDate';
import { MetaDataResponseAPI } from '@/models/mainAPI/mainAPIModels';
import { EditNoticeDate } from '@/services/noticeServices';
import { EditNoticeDateThunk } from '@/store/noticeRedux/noticeThunks';

export const editNoticeDateAdapter = (
  data: EditNoticeDateThunk
): EditNoticeDate => {
  const { timePicker, datePicker } = data;

  return {
    fechafija: datePicker.format(FORMAT_DATE_DDMMYYYY),
    hora: timePicker?.format(FORMAT_TIME_HHMM),
  };
};

export const getDataByMetadata = (metadata: MetaDataResponseAPI[]) => {
  if (!metadata.length) return { codigo: '', dato: '' };

  const [{ codigo, dato }] = metadata;

  return {
    codigo,
    dato,
  };
};
