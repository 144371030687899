import {
  ContainerListZone,
  ListZoneText,
  ListZoneTitle,
  ShowButton,
} from "./styleViewListZone";

const ViewListZone = (props: any) => {
  const { data, title, text, setText, display, setVisible } = props;

  const handleViewZone = () => {
    if (display === "block") {
      setText("Ver más");
      setVisible("none");
    }
    if (display === "none") {
      setText("Ver menos");
      setVisible("block");
    }
  };

  const alwaysVisible = (id: number) => {
    if (id === 0) {
      return "block";
    }
    return display;
  };

  return (
    <ContainerListZone>
      {data.map((item: any, index: number) => (
        <ListZoneText key={index} sx={{ display: alwaysVisible(index) }}>
          {index === 0 && (
            <ListZoneTitle component="span">{title} </ListZoneTitle>
          )}
          {item.zone} {item.districts.join(", ")}.
        </ListZoneText>
      ))}
      {data.length > 1 && (
        <ShowButton onClick={handleViewZone}>{text}</ShowButton>
      )}
    </ContainerListZone>
  );
};

export default ViewListZone;
