import { INoticeResponseCreated } from "@/services/noticeServices/noticeSendData/noticeSendDataTypesResponse";
import {
  BodyBox,
  ContainerBody,
  SectionBody,
  SectionBodyMultiNotice,
  SectionHeaderFooter,
  SubTitleBody,
  TitleBody,
} from "./styleBodySuccess";
import Icon from "@/utils/Icon";

type Props = {
  createdData: INoticeResponseCreated[];
};

const BodySuccessMultiNotice = ({ createdData }: Props) => {
  return (
    <ContainerBody
      container
      columns={{ xs: 2 }}
      rowSpacing={{ xs: 0 }}
      columnSpacing={{ xs: 0 }}
      justifyContent="center"
      justifyItems="center"
    >
      <SectionHeaderFooter
        item
        xs={2}
        borderBottom={() => ({
          borderBottom: `1px solid #eeeeee`,
        })}
      >
        <TitleBody> Origen </TitleBody>
        <SubTitleBody>{createdData[0].origentexto} </SubTitleBody>
      </SectionHeaderFooter>
      {createdData.map((noticeData, i) => (
        <SectionBodyMultiNotice key={i}
          sx={{
            borderTop: i !== 0 ? `1px solid #eeeeee` : "none",
          }}
        >
          <SectionBody item xs={1} className="multi">
            <BodyBox>
              <TitleBody>Destino</TitleBody>
            </BodyBox>
            <SubTitleBody>{noticeData?.destinotexto}</SubTitleBody>
          </SectionBody>

          <SectionBody item xs={1} className="multi">
            <BodyBox>
              <Icon name="icon-money" size="md" />
              <TitleBody>Precio</TitleBody>
            </BodyBox>
            <SubTitleBody>S/ {noticeData?.precio}</SubTitleBody>
          </SectionBody>
        </SectionBodyMultiNotice>
      ))}

      <SectionHeaderFooter item xs={2}
      borderTop={({ palette }) => ({
        borderTop: `1px solid ${palette.borderGray.main}`,
      })}
      >
        <BodyBox>
          <Icon name="icon-truck" size="md" />
          <TitleBody>Vehículo</TitleBody>
        </BodyBox>
        <SubTitleBody>{createdData[0]?.placa}</SubTitleBody>
      </SectionHeaderFooter>
    </ContainerBody>
  );
};

export default BodySuccessMultiNotice;
