import { requestEnvironmentAdapter } from "@/adapters/store/editNegotationRequestAdapter";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import { maxLengthMeters } from "@/consts/noticeConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { ITiposAmbientes } from "@/models/customerRequest/customerRequestResponse";
import {
  ISolicitudMovingBetweenCities,
  ISolicitudMovingInCity,
} from "@/models/customerRequest/requestDetailsVewResponse";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import {
  setActiveButton,
  setListAmbientes,
  setUpdatelistAmbientes,
} from "@/store/customerRequestRedux/customerRequestSlice";
import { getListCustomerRequestTypesAmbientesThunk } from "@/store/customerRequestRedux/customerRequestThunk";
import { uploadEnvironmentInMovingRequestEdit } from "@/store/noticeNegotationRequestRedux/editNegRequestThunk";
import { Box, FormControl, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Ambientes from "./Ambientes";

const FormDetallesMoving = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { ambientes, isLoading, requestDetailsView } =
    useAppSelector((state) => state.customerRequest);
  const idRequest = requestDetailsView?.id.toString();

  const [metraje, setMetraje] = useState<number | string>("");
  const data = requestDetailsView as ISolicitudMovingInCity &
    ISolicitudMovingBetweenCities;
  const metter = data.metraje;
  const nuevosAmbientes = data.ambientes;

  const enviroment = nuevosAmbientes.map((item) => ({
    id: item.idAmbiente,
    nombre: item.ambiente,
    cantidad: item.cantidad,
  }));

  useEffect(() => {
    if (ambientes.length === 0) {
      dispatch(getListCustomerRequestTypesAmbientesThunk());
    }
    if (data) {
      dispatch(setListAmbientes(enviroment as ITiposAmbientes[]));
      setMetraje(metter);
    }
  }, []);

  const handleUpdatedAmbientes = (idAmbiente: number, cantidad: number) => {
    const newAmbientes = ambientes.map((ambiente) => {
      if (ambiente.id === idAmbiente) {
        return {
          ...ambiente,
          cantidad,
        };
      }
      return ambiente;
    });
    dispatch(setUpdatelistAmbientes(newAmbientes));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (+value >= 1 && +value.length <= maxLengthMeters) {
      setMetraje(value);
      return;
    }
  };

  const handleSubmit = () => {
    const body = requestEnvironmentAdapter(ambientes, metraje);

    dispatch(
      uploadEnvironmentInMovingRequestEdit(idRequest as string, body)
    ).then(() => navigate(-1));
  };

  const handleCompareinputs = () => {
    const currentInputsValue = { ambientes, metraje };
    const dataValue = { ambientes: enviroment, metraje: data?.metraje };

    const result =
      JSON.stringify(currentInputsValue) === JSON.stringify(dataValue);

    return !result;
  };

  const handleDisabledButton = () => {
    const conditions = [metraje];
    let result = true;

    const conditionsResult = conditions.some((condition) => !condition);
    const sameData = handleCompareinputs();
    dispatch(setActiveButton(sameData));

    if (sameData) {
      result = false;
      if (conditionsResult) {
        result = true;
      }
    }

    return result;
  };

  return (
    <>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant="label1">¿Qué ambientes vas a mudar?</Typography>
      </Box>

      <Stack
        gap="16px"
        sx={{
          marginBottom: "16px",
        }}
      >
        {ambientes.map((ambiente) => (
          <Ambientes
            key={ambiente.id}
            title={ambiente.nombre}
            idAmbiente={ambiente.id}
            cantidad={ambiente.cantidad}
            onChange={handleUpdatedAmbientes}
          />
        ))}
      </Stack>
      <Typography variant="label1">Area</Typography>
      <FormControl sx={{ marginTop: "16px" }} fullWidth>
        <Input
          name="metraje"
          label={<LabelInputRequired str="Metraje de tu inmueble (m²)" />}
          sx={{ marginBottom: "16px" }}
          variant="standard"
          value={metraje}
          placeholder="Metros cuadrados de tu casa/oficina"
          type="number"
          onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
          InputProps={{
            inputProps: { min: 0 },
          }}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
      <Grid item sx={{ marginTop: "16px" }}>
        <ButtomLoader
          isLoading={isLoading}
          buttomProps={{
            disabled: handleDisabledButton() || isLoading,
          }}
          onClick={handleSubmit}
        >
          GUARDAR
        </ButtomLoader>
      </Grid>
    </>
  );
};

export default FormDetallesMoving;
