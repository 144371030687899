import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import { maxLengthPrice } from "@/consts/noticeConsts";
import { SUCCESS_TEXT_EDIT_PRICE } from "@/consts/textSuccess";
import { PROFILE_NOTICE_LIST_PATH } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { DetailsCoreModel } from "@/models/notice/noticeModels";
import useDetailNotice from "@/pages/detailNotice/hook";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { priceInfoText } from "@/pages/noticeV2/NoticePlace/placeConfig";
import { onSaveFlowBetweenCitiesThunk } from "@/store/includedServices/inclodedServicesThunks";
import { SelectCity } from "@/store/locationRedux/selectLocation";
import { setDirtyPrice } from "@/store/noticeRedux/noticeSlice";
import { editNoticePriceThunk } from "@/store/noticeRedux/noticeThunks";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { setMessageEdit } from "@/store/saveFlow/saveFlowSlice";
import Icon from "@/utils/Icon";
import { cleanValueNumber } from "@/utils/helpers";
import { Box, FormControl, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import SectionOriginDestiny from "../components/SectionOriginDestiny";
import { BoxContainerInfo } from "./BodyFeeInCity";
import { configText } from "./configDataBodyFee";

const BodyFeeIntercity = () => {
  const [price, setPrice] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [messageErrorApi, setMessageErrorApi] = useState("");

  const {
    selectLocation: { selectedCity, selectedDestiny },
    detailsNoticeSlice: { isLoading },
  } = useAppSelector((state) => state);
  const currentSelectedCity = (selectedCity as SelectCity)
  const currentSelectedDestiny = selectedDestiny as SelectCity
  const { handleGetCurrentDetailNotice } = useDetailNotice();

  const notice: DetailsCoreModel = useMemo(
    () => handleGetCurrentDetailNotice(),
    [isLoading]
  );

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith(PROFILE_NOTICE_LIST_PATH)) {
      setIsEdit(true);
      setPrice(notice?.precio?.toString());
    }
    dispatch(setDirtyPrice(false));
    return () => {
      dispatch(setDirtyPrice(false));
      setMessageErrorApi("");
    };
  }, []);

  const handleOnchange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const { value } = currentTarget;

    if (value.length >= maxLengthPrice) return;

    const clenaValue = cleanValueNumber(value);
    setPrice(clenaValue);

    dispatch(setDirtyPrice(true));
  };

  const handleSubmit = () => {
    if (isLoading) return;
    setMessageErrorApi("");
    let currentPrice = !price.trim().length ? 0 : Number(price);

    if (isEdit && !!notice) {
      
      dispatch(editNoticePriceThunk(notice.id, { precio: currentPrice })).then(handleSuccessEdit);
    } else {
      dispatch(onSaveFlowBetweenCitiesThunk(currentPrice))
        .then(() => handleNavigate())
        .catch((err) => {
          setMessageErrorApi(err.message);
        });
    }
  };

  const handleSuccessEdit = () => {
    dispatch(setMessageEdit(SUCCESS_TEXT_EDIT_PRICE));
    navigate(-1);
  };

  const handleNavigate = () => {
    dispatch(getNextRoute(navigate));
  };

  const handleDisabled = () => {
    return !price.length || Number(price) <= 0 || isLoading;
  };

  const handleShowOrigin = () => {


    if (isEdit) {
      return notice?.origentexto;
    } else {
      return currentSelectedCity.nombre2 || "";
    }
  };

  const handleShowDestiny = () => {

    if (isEdit) {
      return notice?.destinotexto;
    } else {
      return currentSelectedDestiny.nombre2 || "";
    }
  };

  const textButton = isEdit ? configText.button.edit : configText.button.flow;

  return (
    <>
      <>
        {!!messageErrorApi && (
          <StandardAlert
            sx={{ marginBottom: "16px" }}
            severity="error"
            color="error"
          >
            {messageErrorApi}
          </StandardAlert>
        )}
      </>
      
      <BoxContainerInfo>
        <Icon name="icon-handsShake" size="md" />
        <Typography variant="body2" sx={{ fontSize: "16px" }}>
          {priceInfoText}
        </Typography>
      </BoxContainerInfo>

      <Box sx={{ marginBottom: 2 }}>
        <SectionOriginDestiny
          origin={handleShowOrigin()}
          destiny={handleShowDestiny()}
        />
      </Box>

      <FormControl sx={{ marginTop: "16px", width: "100%" }}>
        <Input
          name="price"
          label={<LabelInputRequired str="Precio (S/)" />}
          value={price}
          variant="standard"
          placeholder="Tu precio"
          onChange={handleOnchange}
          onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            inputMode: "numeric",
          }}
        />
      </FormControl>
      <ButtomLoader
        onClick={handleSubmit}
        sx={{ marginTop: "24px" }}
        buttomProps={{ disabled: handleDisabled() }}
        isLoading={isLoading}
      >
        {textButton}
      </ButtomLoader>
    </>
  );
};

export default BodyFeeIntercity;
