import { ServiceRejectedSvg } from "@/assets/svg";
import ButtomLoader from "@/pages/noticeV2/components/buttonLoader/ButtomLoader";
import { Box, Container, Stack, Typography } from "@mui/material";
import {
  showOtherNegTextBtn
} from "../../../consts/noticeConsts";
import useRejectedMessageHook from "./hook";

const RejectedMessage = () => {
  
  const {
    handleRedirection,
    handleIsDriverText,
    handleRejectText,
  } = useRejectedMessageHook();

  return (
    <Container maxWidth="xs">
      <Stack gap={2} alignItems={"center"} justifyContent={"center"} mt={5}>
        <ServiceRejectedSvg />
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <Typography variant="h5low" sx={{ fontWeight: 700 }}>
            {handleRejectText()}
          </Typography>
        </Box>
        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            fontWeight: 400,
          }}
        >
          {handleIsDriverText()}
        </Typography>

        <ButtomLoader
          fullWidth
          variant="contained"
          type="submit"
          onClick={() => handleRedirection()}
        >
          {showOtherNegTextBtn}
        </ButtomLoader>
      </Stack>
    </Container>
  );
};

export default RejectedMessage;
