export const translateDataToEnglish: Record<string, string> = {
  id: 'id',
  idtipoavisonegociacion: 'negotiationTypeId',
  fecha: 'date',
  referenciaOrigen: 'originReference',
  destinoTexto: 'destinyText',
  referenciaDestino: 'destinyReference',
  descripcionCarga: 'cargoDescription',
  fotos: 'photos',
  idtipoaviso: 'noticeTypeId',
  origenTexto: 'originText',
  precio: 'price',
  horas: 'hours',
  preciocalculado: 'calculatedPrice',
  puntos: 'points',
  inmuebleOrigen: 'originProperty',
  flgAscensor: 'elevatorFlag',
  piso: 'floor',
  inmuebleDestino: 'destinyProperty',
  pisoDestino: 'destinyFloor',
  flgAscensorDestino: 'destinyElevatorFlag',
  ayudantes: 'assistants',
  metraje: 'squareMeters',
  flgenlaciudadoentreciudades: 'betweenCitiesFlag',
  avisoNegociacionCondiciones: 'negotiationConditions',
  pesoEstimado: 'estimatedWeight',
  idAvisoNegociacion: 'negotiationNoticeId',
  urlImagen: 'urlImage',
};

export const translateDataToSpanish: Record<string, string> = {
  id: 'id',
  negotiationTypeId: 'idTipoAvisoNegociacion',
  date: 'fecha',
  originReference: 'referenciaOrigen',
  destinyText: 'destinoTexto',
  destinyReference: 'referenciaDestino',
  cargoDescription: 'descripcionCarga',
  photos: 'fotos',
  noticeTypeId: 'idtipoaviso',
  originText: 'origenTexto',
  price: 'precio',
  hours: 'horas',
  calculatedPrice: 'preciocalculado',
  points: 'puntos',
  originProperty: 'inmuebleOrigen',
  elevatorFlag: 'flgAscensor',
  floor: 'piso',
  destinyId: 'idDestino',
  originId: 'idOrigen',
  destinyProperty: 'inmuebleDestino',
  destinyFloor: 'pisoDestino',
  destinyElevatorFlag: 'flgAscensorDestino',
  assistants: 'ayudantes',
  squareMeters: 'metraje',
  betweenCitiesFlag: 'flgenlaciudadoentreciudades',
  negotiationConditions: 'avisoNegociacionCondiciones',
  estimatedWeight: 'pesoEstimado',
  negotiationNoticeId: 'idAvisoNegociacion',
  urlImage: 'urlImagen',
  verifiedPhotosId: 'idfotosverificadas',
};

export const validateSpanishData: Record<string, string> = { fotos: 'fotos' };
export const validateEnglishData: Record<string, string> = { photos: 'photos' };

// ========== Moving Flow ========
export const ID_DEPARTAMENT = 2;
export const ID_HOUSE = 1;
