import { getTemplateDeclined } from '@/adapters/store/negotationAdapter';
import {
  ID_AUCTION_FLOW,
  ID_EXPRESS_FLOW,
  ID_MOVING_FLOW,
} from '@/consts/defaultConsts';
import { SolicitudNegociacion } from '@/models/noticeNegotation/noticeNegotationModel';
import { Negotiation } from '@/models/offert/offertModels';
import {
  dataProfileFormat,
  dataTransportistaFormat,
} from '@/pages/acceptanceOffert/adapters/AcceptanceAdapter';
import { IUser } from '@/store/userRedux/userReduxInterface';
import {
  currencyFormat,
  formatDate,
  searchPropertyInObject,
  stringDDMMYYYToDate,
  stringHoursToFormatLT,
} from '@/utils/helpers';

const priceText = 'Acordado en S/ :precio';

export const validIsClient = (clientId: any, user: IUser) => {
  const { id } = user;
  let userId = !!id && id;

  return userId === clientId;
};

export const getTypeUser = (savedDataNegotation: any, user: IUser) => {
  const isClient = validIsClient(
    savedDataNegotation.solicitud.cliente.id,
    user
  );
  if (isClient) {
    return 'Datos del transportista';
  }

  return 'Datos del cliente';
};

export const getUrlAvatarProfile = (savedDataNegotation: any, user: IUser) => {
  const isClient = validIsClient(
    savedDataNegotation?.solicitud?.cliente?.id || 0,
    user
  );
  let avatarUrl = savedDataNegotation?.cliente.perfilUrl;
  if (isClient) {
    avatarUrl =
      savedDataNegotation?.avisoNegociacionAviso?.transportista?.perfilUrl;
  }

  return avatarUrl;
};

export const getUserNameRating = (savedDataNegotation: any, user: IUser) => {
  const isClient = validIsClient(
    savedDataNegotation?.solicitud?.cliente?.id || 0,
    user
  );
  let userName = savedDataNegotation?.cliente.nombreyapellidos;
  if (isClient) {
    userName =
      savedDataNegotation?.avisoNegociacionAviso?.transportista
        ?.nombreyapellidos;
  }

  return userName;
};

export const getNumberPhoneInRating = (
  savedDataNegotation: any,
  user: IUser
) => {
  const isClient = validIsClient(
    savedDataNegotation?.solicitud?.cliente?.id || 0,
    user
  );
  let phone = savedDataNegotation?.solicitud?.cliente?.username;

  if (isClient) {
    phone = savedDataNegotation?.transportista?.username;
  }

  return phone;
};

export const getEmailInRating = (savedDataNegotation: any, user: IUser) => {
  const isClient = validIsClient(
    savedDataNegotation?.solicitud?.cliente?.id || 0,
    user
  );
  let email = savedDataNegotation?.solicitud?.cliente?.email;
  if (isClient) {
    email = savedDataNegotation?.transportista?.email;
  }

  return email;
};

export const getImageTruckInRating = (
  savedDataNegotation: any,
  user: IUser
) => {
  const isClient = validIsClient(
    savedDataNegotation?.solicitud?.cliente?.id || 0,
    user
  );
  if (!isClient) return '';
  const { vehiculo } = savedDataNegotation.avisoNegociacionAviso;
  const [imageTruck] = vehiculo?.vehiculofotos;

  return imageTruck.direccion;
};

export const getInfoVehicle = (offert: Negotiation) => {
  const {
    avisoNegociacionAviso: { vehiculo },
  } = offert;
  const typeVehicle = vehiculo?.tipovehiculo?.nombre;

  return `${typeVehicle} ${vehiculo?.placa}`;
};

export const showVehicleInOffert = (offert: Negotiation, user: IUser) => {
  const isClient = validIsClient(offert, user);

  return /* !isClient */ true;
};

export const getTypeByPrice = (offert: Negotiation) => {
  const isHour = !!offert?.horas && offert?.horas > 0;
  const isPoint = !!offert?.puntos && offert?.puntos > 0;
  const typeNoticeId = offert.avisoNegociacionAviso?.tipoaviso?.id;

  if (typeNoticeId === ID_AUCTION_FLOW) {
    return '';
  }

  if (isHour) {
    return 'Precio por hora';
  } else if (isPoint) {
    return 'Precio por punto';
  } else if (typeNoticeId === ID_MOVING_FLOW) {
    return 'Precio por viaje';
  } else {
    return 'Precio de punto a punto';
  }
};

export const getFormatPrice = (offert: Negotiation) => {
  const { precio, ultimaAvisoNegociacionAcciones } = offert;
  const formatPrice = currencyFormat(precio);
  const { estado } = ultimaAvisoNegociacionAcciones?.tipoAvisoNegociacionAccion;
  if (estado.id === 2 || estado.id === 5) {
    const textPrice = priceText.replace(':precio', `${formatPrice}`);

    return textPrice;
  }

  return formatPrice;
};

export const getFormatSendDate = (offert: any) => {
  const {
    solicitud: { fecha },
  } = offert;
  const dateHour = fecha;

  const result = `${formatDate(dateHour)}`;

  return result;
};

export const getFormatSendDateExpress = (offert: any) => {
  const {
    solicitud: { fecha },
  } = offert;
  const dateDivider = fecha.split(' ');
  const itemOne = dateDivider[0].split('-').reverse().join('/');

  const result = `${itemOne} ${dateDivider[1]}`;

  return result;
};

export const getFormatDateDateRegister = (offert: Negotiation) => {
  const template = `Enviada el :date a las :dateTime`;
  let { fechaRegistro, horaRegistro } = offert;
  horaRegistro = stringHoursToFormatLT(horaRegistro);

  const result = template
    .replace(':date', fechaRegistro)
    .replace(':dateTime', horaRegistro);

  return result;
};

export const getValueInOffert = (
  offert: SolicitudNegociacion,
  aditionalsParams?: any
) => {
  const value = searchPropertyInObject(aditionalsParams, offert);

  return value;
};

export const getImages = (data: SolicitudNegociacion) => {
  const {
    solicitud: { fotos },
  } = data;

  if (fotos.length === 0) return [];

  const value = fotos.map((item, i) => ({
    id: i + 1,
    urlImagen: item,
  }));

  return value;
};

export const getDescriptionLoad = (data: SolicitudNegociacion) => {
  const {
    solicitud: { descripcionCarga },
  } = data;

  return !!descripcionCarga ? descripcionCarga : '';
};

export const getValueInNegRequestV2 = (data: SolicitudNegociacion) => {
  const {
    solicitud: { condiciones },
  } = data;

  if (condiciones.length === 0) return [];

  return condiciones;
};

export const getValue = (offert: any, aditionalsParams?: any) => {
  return 'ambiente';
};

export const getDateReset = (offert: Negotiation, aditionalsParams?: any) => {
  const value = searchPropertyInObject(aditionalsParams, offert);
  const newValue = stringDDMMYYYToDate(value.toString());
  return value;
};

export const getTitleOriginAndDestiny = (offert: any) => {
  const {
    solicitud: { origentexto, destinotexto },
  } = offert;
  const title = `De ${origentexto} a ${destinotexto}`;

  return title;
};

export const getTimeAproxToArrival = (savedDataNegotation: any) => {
  const tiempoestimado = savedDataNegotation?.tiempoestimado;

  return `Llegada en aprox. ${tiempoestimado} min. `;
};

export const getTitlePrice = (savedDataNegotation: any) => {
  const precio = savedDataNegotation?.solicitud?.precio;
  const tipoSolicitud = savedDataNegotation?.solicitud?.tipoSolicitud;
  const precioExpress = savedDataNegotation?.precio;
  let title = `S/ ${precio}`;

  if (tipoSolicitud?.id === ID_EXPRESS_FLOW) {
    title = `S/ ${precioExpress}`;
  }

  return title;
};

export const getTitleCounterOfferPrice = (savedDataNegotation: any) => {
  const { precio } = savedDataNegotation;
  const title = `S/ ${precio}`;

  return title;
};

export const getTitlePriceConfirmation = (savedDataNegotation: any) => {
  const { solicitud, precio, preciocontraoferta } = savedDataNegotation;
  let title = '';
  if (!!precio) {
    title = priceText.replace(':precio', `${precio}`);
    if (!!preciocontraoferta) {
      title = priceText.replace(':precio', `${preciocontraoferta}`);
    }
  } else {
    title = priceText.replace(':precio', `${solicitud.precio}`);
  }

  return title;
};

export const getTitlePriceCounterOfferConfirmation = (
  savedDataNegotation: any
) => {
  const { precio } = savedDataNegotation;

  const title = priceText.replace(':precio', `${precio}`);

  return title;
};

export const getTitlePriceCoCounterOfferConfirmation = (
  savedDataNegotation: SolicitudNegociacion
) => {
  const { precioContraoferta, precio } = savedDataNegotation;
  let counterOfferPrice = !!precioContraoferta;

  let title = priceText.replace(':precio', `${precioContraoferta}`);

  if (!counterOfferPrice) {
    title = priceText.replace(':precio', `${precio}`);
  }

  return title;
};

export const getTitlePriceMoving = (savedDataNegotation: any) => {
  const { precio } = savedDataNegotation;
  const title = `S/ ${precio}`;

  return title;
};

export const getHourPointPriceText = (savedDataNegotation: any) => {
  const {
    solicitud: { flghoraopunto },
  } = savedDataNegotation;
  const type = flghoraopunto === 1 ? 'hora' : 'punto';

  const title = `Precio por ${type}`;

  return title;
};

export const getJourney = (savedDataNegotation: any) => {
  const {
    solicitud: { flghoraopunto },
  } = savedDataNegotation;
  const type = flghoraopunto === 1 ? 'hora' : 'punto';

  const title = `Precio por viaje`;

  return title;
};

export const getTitlePricePerHourOrPoint = (savedDataNegotation: any) => {
  const {
    solicitud: { precio },
  } = savedDataNegotation;
  const title = `S/ ${precio}`;

  return title;
};

export const getTitlePriceCounterOffer = (savedDataNegotation: any) => {
  const { precio } = savedDataNegotation;
  const title = `Contraoferta de S/ ${precio}`;

  return title;
};

export const getTitlePriceCoCounterOffer = (
  savedDataNegotation: SolicitudNegociacion
) => {
  const { precioContraoferta } = savedDataNegotation;
  const existCounterOfferPrice = !!precioContraoferta;
  if (!existCounterOfferPrice) return '';
  const title = `Contraoferta de S/ ${precioContraoferta}`;

  return title;
};

export const getVehicleValues = (savedDataNegotation: SolicitudNegociacion) => {
  const {
    vehiculo: { tipoVehiculo },
  } = savedDataNegotation;
  const title = `${tipoVehiculo}`;

  return title;
};

export const getVehicleName = (savedDataNegotation: SolicitudNegociacion) => {
  const {
    vehiculo: { tipoVehiculo },
  } = savedDataNegotation;
  const title = `${tipoVehiculo}`;

  return title;
};

export const getHelpers = (savedDataNegotation: any) => {
  const { ayudantes } = savedDataNegotation;
  const title = `${ayudantes}`;

  return title;
};

export const getVehicleValueNotification = (
  savedDataNegotation: SolicitudNegociacion
) => {
  const {
    vehiculo: { tipoVehiculo },
  } = savedDataNegotation;

  const title = !!tipoVehiculo && `${tipoVehiculo}  `;

  return title;
};

export const getMaxWeightVehicle = (
  savedDataNegotation: SolicitudNegociacion
) => {
  const {
    vehiculo: { cargaMaxima },
  } = savedDataNegotation;

  const title = !!cargaMaxima && ` Capacidad máxima de ${cargaMaxima} kilos   `;

  return title;
};

export const getVehicleValueConfirmation = (savedDataNegotation: any) => {
  const { vehiculo } = savedDataNegotation;
  const title =
    !!vehiculo.id && `${vehiculo.tipovehiculo.nombre} ${vehiculo.placa}`;

  return title;
};

/* 
export const getDriverVehicle = (savedDataNegotation: any) => {
  const {
   vehiculo
  } = savedDataNegotation;
  const title = !!vehiculo && `${vehiculo.tipoVehiculo.nombre} ${vehiculo.placa}`;

  return title;
}; */

export const getVehicleVaMovinglueNotification = (savedDataNegotation: any) => {
  const {
    vehiculo: { tipovehiculo, placa },
  } = savedDataNegotation;
  const title = !!tipovehiculo && `${tipovehiculo.nombre} ${placa}  `;

  return title;
};

export const getDetailsCharge = (offert: any) => {
  const {
    solicitud: { descripcionCarga },
  } = offert;
  const title = `${descripcionCarga}`;

  return title;
};

export const getMaxWeigth = ({ solicitud: { pesoestimado } }: any) => {
  const title = `${pesoestimado} kilos`;

  return title;
};

export const getRefrigeratedLoad = ({
  solicitud: { flgcargarefrigerada },
}: any) => {
  const title = !!flgcargarefrigerada ? 'Si' : 'No';

  return title;
};

export const getOffertFormatSendDate = (savedDataNegotation: any) => {
  const template = `Enviada el :date a las :dateTime`;
  const { fechaRegistro = '', horaRegistro = '' } = savedDataNegotation;
  const hourLT = stringHoursToFormatLT(horaRegistro);

  const result = template
    .replace(':date', fechaRegistro)
    .replace(':dateTime', hourLT);

  return result;
};

export const getDeclineOffertFormatDate = (
  savedDataNegotation: SolicitudNegociacion
) => {
  // const template = `Rechazada el :date a las :dateTime`;
  const {
    // rechazo
    fechaRechazo = '',
    fecha_rechazo = '',
    horaRechazo = '',
    // anulado
    fechaAnulada = '',
    horaAnulada = '',
    // retiro
    fechaRetiro = '',
    horaRetiro = '',
    ultimaSolicitudNegociacionAcciones: {
      tipoSolicitudNegociacionAccion: {
        estado: { id: estadoId },
      },
    },
  } = savedDataNegotation;
  const hourLT = stringHoursToFormatLT(
    horaRechazo || horaAnulada || horaRetiro
  );
  return getTemplateDeclined(
    estadoId,
    fechaRechazo || fecha_rechazo || fechaAnulada || fechaRetiro,
    hourLT,
    'solicitud'
  );
};

export const getCounterOffertFormatSendDate = (savedDataNegotation: any) => {
  const template = `Enviada el :date a las :dateTime`;
  const { fechaRegistro = '', horaRegistro = '' } = savedDataNegotation;
  const hourLT = stringHoursToFormatLT(horaRegistro);

  const result = template
    .replace(':date', fechaRegistro)
    .replace(':dateTime', hourLT);

  return result;
};

export const getCoCounterOffertFormatSendDate = (savedDataNegotation: any) => {
  const template = `Enviada el :date a las :dateTime`;
  const {
    fechaContracliente = '',
    fecha_contracliente = '',
    horaContracliente = '',
  } = savedDataNegotation;
  const hourLT = stringHoursToFormatLT(horaContracliente);

  if (!hourLT || !fechaContracliente || !fecha_contracliente) return '';

  const result = template
    .replace(':date', fechaContracliente || fecha_contracliente)
    .replace(':dateTime', hourLT);
};

export const getDeclineCounterOffertFormatDate = (
  savedDataNegotation: SolicitudNegociacion
) => {
  const ultimaSolicitudNegociacionAcciones =
    !!savedDataNegotation.ultimaSolicitudNegociacionAcciones;

  if (!ultimaSolicitudNegociacionAcciones) return '';

  const {
    // rechazo
    fechaRechazo = '',
    fecha_rechazo = '',
    horaRechazo = '',
    // anulado
    fechaAnulada = '',
    horaAnulada = '',
    // retiro
    fechaRetiro = '',
    horaRetiro = '',
    ultimaSolicitudNegociacionAcciones: {
      tipoSolicitudNegociacionAccion: {
        estado: { id: estadoId },
      },
    },
  } = savedDataNegotation;
  const hourLT = stringHoursToFormatLT(
    horaRechazo || horaAnulada || horaRetiro
  );
  return getTemplateDeclined(
    estadoId,
    fechaRechazo || fecha_rechazo || fechaAnulada || fechaRetiro,
    hourLT,
    'solicitud'
  );
};

export const showRequestDetail = (
  _: number,
  value: any,
  savedDataNegotation: Negotiation
) => {
  const { origenTexto = '', destinoTexto = '' } = savedDataNegotation;
  if (!!origenTexto || !!destinoTexto) {
    return 'Detalles de la solicitud';
  }
  return '';
};

export const showDetailTitle = (savedDataNegotation: Negotiation) => {
  const { fechaAcuerdo, fecha, origenTexto, destinoTexto } =
    savedDataNegotation;
  if (fechaAcuerdo || fecha || origenTexto || destinoTexto) {
    return 'Detalles del acuerdo';
  }

  return '';
};

export const showByTypeNotice = (offert: Negotiation, idTypeNotice: number) => {
  const { tipoaviso } = offert?.avisoNegociacionAviso;

  return tipoaviso.id === idTypeNotice;
};

export const isDifferentFromAuction = (offert: Negotiation) => {
  const { tipoaviso } = offert?.avisoNegociacionAviso;

  return tipoaviso.id !== ID_AUCTION_FLOW;
};

export const resetMarginTopInAuctionFlow = (offert: Negotiation) => {
  const isDifferent = isDifferentFromAuction(offert);

  return !isDifferent ? { marginTop: 0 } : {};
};

export const resetMarginTopByFun = (
  offert: Negotiation,
  validFunc: (offert: Negotiation) => any
) => {
  const isValid = validFunc(offert);

  return !!isValid ? {} : { marginTop: 0 };
};

export const getNoticeNumberOFHelpers = ({
  avisoNegociacionAviso,
}: Negotiation) => {
  if (!avisoNegociacionAviso?.ayudantes) return;

  const quantity = avisoNegociacionAviso?.ayudantes || 0;
  const template = ':quantity (incluido en el precio)';

  return template.replace(':quantity', quantity.toString());
};

export const getNumberOFHelpers = (offert: Negotiation) => {
  const { ayudantes } = offert;
  if (ayudantes === undefined) return '';

  const template = ':quantity (incluido en el precio)';

  return template.replace(':quantity', ayudantes.toString());
};

export const getFormatMetro = (savedDataNegotation: any) => {
  const {
    solicitud: { metraje },
  } = savedDataNegotation;
  if (!metraje) return '';

  return `${metraje} m².`;
};

export const getTitleTypeSolicitud = (offert: Negotiation) => {
  const { flgIsOwner } = offert;
  if (flgIsOwner === undefined) return '';
  return flgIsOwner === 1 ? 'Tu solicitud' : 'Solicitud de cliente';
};

export const getTitleTypeCounterOffert =
  (type?: number) => (offert: Negotiation) => {
    const { flgIsOwner, preciocontraoferta } = offert;
    const existCounterOfferPrice = !!preciocontraoferta;

    if (flgIsOwner === undefined || !existCounterOfferPrice) return '';

    if (type === 1) {
      return flgIsOwner === 1 ? 'Tu contraoferta' : 'Contraoferta de cliente';
    }
    return flgIsOwner === 1
      ? 'Contraoferta del transportista'
      : 'Tu contraoferta';
  };

export const renderRatingTransporter = (offert: any) => {
  const { flgIsOwner } = offert;

  return {
    ...dataTransportistaFormat(offert || {}),
    urlVehicle: offert?.vehiculo?.vehiculofotos,
    showTwoAvatars: true,
    show: flgIsOwner === 0,
  };
};

export const renderRatingClient = (offert: any) => {
  return {
    ...dataProfileFormat(offert?.solicitud || {}),
  };
};

export const renderTitleAmbienteOffert = (offert: Negotiation) => {
  const { metraje, tipoInmueble } = offert?.solicitud || {};
  return `En ${tipoInmueble?.nombre.toLowerCase()} de ${metraje} m².`;
};

export const renderAmbientesOffert = (offert: Negotiation) => {
  const { ambientes } = offert?.solicitud || {};
  return ambientes
    ?.map((ambiente) => {
      return !!ambiente.cantidad
        ? ` - ${ambiente.ambiente.nombre}: ${ambiente.cantidad}</br>`
        : '';
    })
    .join('');
};

export const renderTitleLongOriginOffert = (application: Negotiation) => {
  const { tipoInmueble, piso, flgAscensor, origentexto2, origentexto } =
    application.solicitud || {};

  const showAscensor =
    flgAscensor === 1
      ? 'con ascensor'
      : flgAscensor === 2
      ? 'sin ascensor'
      : 'ascensor disponible para algunas cosas';

  return flgAscensor
    ? `${tipoInmueble?.nombre} en piso ${piso} (${showAscensor}) en ${
        origentexto2 || origentexto
      }.`
    : `${tipoInmueble?.nombre} en ${origentexto2 || origentexto}.`;
};

export const renderTitleLongDestinyOffert = (application: Negotiation) => {
  const {
    tipoInmuebleDestino,
    pisoDestino,
    flgAscensorDestino,
    destinotexto2,
    destinotexto,
  } = application?.solicitud || {};
  const showAscensor =
    flgAscensorDestino === 1
      ? 'con ascensor'
      : flgAscensorDestino === 2
      ? 'sin ascensor'
      : 'ascensor disponible para algunas cosas';

  return flgAscensorDestino
    ? `${
        tipoInmuebleDestino?.nombre
      } en piso ${pisoDestino} (${showAscensor}) en ${
        destinotexto2 || destinotexto
      }.`
    : `${tipoInmuebleDestino?.nombre} en ${destinotexto2 || destinotexto}.`;
};
