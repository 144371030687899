import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { StyleCustomInput } from "@/components/form/customInput/styleCustomInput";
import LabelRequired from "@/components/utils/LabelRequired";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import HeaderProfile from "@/pages/profile/headerProfile/HeaderProfile";
import React, { useEffect, useRef, useState } from "react";

import StandardAlert from "@/components/alerts/StandardAlert";
import { PROFILE_DATOS_VALIDACION } from "@/consts/typeServicesPaths";
import useCountdown from "@/hooks/useCountdown";
import {
  setCellPhoneChange,
  setErrorSendCode,
} from "@/store/auth/authUserSlice";
import {
  EnumTypeCodeSend,
  sendCodeBySMSThunk,
} from "@/store/auth/authUserThunks";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Input from "@/components/inputCustom/Input";

const MAX_LENGTH_PHONE = 9;

const Telefono = () => {
  const { handleIsEnabled } = useCountdown();

  const [phone, setPhone] = useState("");

  const { errorSendCode, isLoading } = useAppSelector((state) => state.auth);
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const timerClean = useRef<any>(null);

  useEffect(() => {
    if (errorSendCode) {
      timerClean.current = setTimeout(() => {
        dispatch(setErrorSendCode(""));
      }, 15000);
    }
    return () => {
      clearTimeout(timerClean.current);
    };
  }, [errorSendCode]);

  const handleOnchange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const value = target.value.replace(/\D/g, "");
    if (value.length > MAX_LENGTH_PHONE) return;

    setPhone(value);
  };

  const handleShowError = () => {
    if (typeof errorSendCode !== "string") return "Error inesperado";

    return errorSendCode;
  };

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLoading) return;
    if (!handleIsEnabled) {
      await dispatch(
        sendCodeBySMSThunk(phone, EnumTypeCodeSend.CAMBIARNUMERO)
      ).then(() => {
        dispatch(setCellPhoneChange(phone));
        navigate(PROFILE_DATOS_VALIDACION, { state: { success: true } });
      });
    }
  };

  return (
    <HeaderProfile title="Cambiar celular">
      {errorSendCode && !isLoading && (
        <StandardAlert
          sx={{ marginBottom: "16px" }}
          severity="error"
          color="error"
          onClick={() => {
            dispatch(setErrorSendCode(""));
          }}
        >
          {handleShowError()}
        </StandardAlert>
      )}
      <Typography variant="label2">
        Número actual: {(user as IUser)?.username}
      </Typography>
      <form
        onSubmit={handleOnSubmit}
        style={{
          marginTop: "16px",
        }}
      >
        <Input
          label={<LabelRequired str="Tu nuevo número de celular" />}
          name="phone"
          variant="standard"
          placeholder="Ingresa tu celular"
          type="number"
          autoComplete="off"
          value={phone}
          onChange={handleOnchange}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <ButtomLoader
          sx={{ marginTop: "16px" }}
          type="submit"
          isLoading={isLoading}
          disabled={phone.trim().length < 9 || isLoading}
        >
          ENVIAR VERIFICACIÓN
        </ButtomLoader>
      </form>
    </HeaderProfile>
  );
};

export default Telefono;
