import { BASE_URL_IMAGE_S3 } from "@/config/config";
import { ID_MOVING_FLOW, IMG_PATH, SM_IMG } from "@/consts/defaultConsts";
import { characterCut, deleteDomainUrl, resizePhoto } from "@/utils/helpers";
import { Stack, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { IAcuerdosporaceptar } from "../../models/userAuth/agrementToAccept";
import MiniPriceCustom from "./components/MiniPriceCustom";
import StateAviso from "./components/StateAviso";
import TypeAviso from "./components/TypeAviso";
import {
  ContainerInfoCard,
  ContentInfo,
  ImgContent,
  StackInfo,
  SubTitleInfo,
  TextPrice,
  TitleBody,
} from "./styleInfoCard";
import { SyntheticEvent } from "react";

const CustomLink = styled(Link)(
  ({ theme }) => `
    text-decoration: none;
    font-family: ${theme.typography.h1.fontFamily};
    font-size: ${theme.typography.label2.fontSize};
    font-weight: ${theme.typography.label2.fontWeight};
    line-height: ${theme.typography.label2.lineHeight};
		color: ${theme.palette.warning.main};
  `
);
type typeInfoCard = "aviso" | "solicitud";
interface IProps {
  data: IAcuerdosporaceptar;
  flgavisosolicitud: typeInfoCard;
  showStatus?: boolean;
  textButton?: string;
}
const InfoCustomCard = ({
  data,
  flgavisosolicitud,
  showStatus = false,
  textButton,
}: IProps) => {
  const handleValidateImg = (imgURL: string) => {
    const imageResized = resizePhoto({
      photo: IMG_PATH + imgURL,
      newFormat: SM_IMG,
    });
    if (imgURL?.startsWith("http")) return imgURL;

    return imageResized;
  };

  const handleErrorImg = (
    e: SyntheticEvent<HTMLImageElement, Event>,
    imageUrl: string
  ) => {
    (e.target as HTMLInputElement).src = imageUrl;
  };

  return (
    <div>
      {flgavisosolicitud === "aviso" ? (
        <ContainerInfoCard
          sx={{
            borderRadius: `8px 8px ${data.link ? "0 0" : "8px"}`,
            position: "relative",
          }}
        >
          <StateAviso
            data={data}
            type={flgavisosolicitud}
            showState={showStatus}
          />

          <TypeAviso data={data} type={flgavisosolicitud} />

          <TitleBody>{data.titulo1}</TitleBody>
          <ContentInfo>
            <StackInfo>
              <SubTitleInfo>{data.titulo2}</SubTitleInfo>
              <SubTitleInfo
                sx={{ display: !data.tipovehiculo ? "none" : "flex" }}
              >
                <strong>{data.tipovehiculo}</strong>
                {data.pesoestimado && (
                  <>&nbsp; | Hasta {data.pesoestimado} kilos </>
                )}
                {!!+data?.flgcargarefrigerada && (
                  <>
                    | <i className="icon-snow-flake"></i>
                  </>
                )}
              </SubTitleInfo>
            </StackInfo>
            <ImgContent>
              <img
                src={handleValidateImg(data.imagen)}
                alt=""
                width="40"
                height="40"
                style={{ objectFit: "fill" }}
                onError={(e: SyntheticEvent<HTMLImageElement, Event>) =>
                  handleErrorImg(e, IMG_PATH + data.imagen)
                }
              />
            </ImgContent>
          </ContentInfo>
          <TextPrice>
            {data.precio !== 0 && (
              <MiniPriceCustom data={data} type={flgavisosolicitud} />
            )}
          </TextPrice>
        </ContainerInfoCard>
      ) : (
        <ContainerInfoCard
          sx={{
            borderRadius: `8px 8px ${data.link ? "0 0" : "8px"}`,
            position: "relative",
            height: "214px",
          }}
        >
          <StateAviso
            data={data}
            type={flgavisosolicitud}
            showState={showStatus}
          />
          <TypeAviso data={data} type={flgavisosolicitud} />
          <TitleBody>{data.titulo1}</TitleBody>
          <ContentInfo>
            <StackInfo>
              <SubTitleInfo>{data.titulo2}</SubTitleInfo>
              <SubTitleInfo sx={{ display: "flex" }}>
                {data.tipovehiculo && !(data.idtipo === ID_MOVING_FLOW) && (
                  <>
                    <strong>{characterCut(data.tipovehiculo, 15)}</strong>
                    {data.pesoestimado && (
                      <> &nbsp; | {data.pesoestimado} kilos aprox </>
                    )}
                  </>
                )}
                {data.titulo3 && data.idtipo === ID_MOVING_FLOW && (
                  <> {data.titulo3} </>
                )}
                {!!data?.flgcargarefrigerada && (
                  <>
                    | <i className="icon-snow-flake"></i>
                  </>
                )}
              </SubTitleInfo>
              <SubTitleInfo
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {data.titulo3 &&
                  !(data.idtipo === ID_MOVING_FLOW) &&
                  data.titulo3}
              </SubTitleInfo>
            </StackInfo>
            <ImgContent>
              {data.imagen && (
                <img
                  src={handleValidateImg(data.imagen)}
                  alt=""
                  width="40"
                  height="40"
                  style={{ objectFit: "fill" }}
                />
              )}
            </ImgContent>
          </ContentInfo>
          <TextPrice>
            {data.precio !== 0 && (
              <MiniPriceCustom data={data} type={flgavisosolicitud} />
            )}
          </TextPrice>
        </ContainerInfoCard>
      )}
      {data.link && (
        <Stack
          sx={{
            border: "1px solid #CFD8DC",
            borderRadius: "0 0 8px 8px",
            borderTop: "none",
          }}
        >
          <CustomLink
            style={{ padding: "10px", textAlign: "center" }}
            to={deleteDomainUrl(data.link)}
            state={{
              hasBackLinkOffert: true,
              showCalification: true,
            }}
          >
            {!!textButton ? textButton : "VER"}
          </CustomLink>
        </Stack>
      )}
    </div>
  );
};

export default InfoCustomCard;
