export const currentRuleConditions: Record<number, any> = {
  1: {
    header: {
      title: 'Condiciones del servicio',
      subTitle: 'Crear transporte en la ciudad',
    },
  },
  2: {
    header: {
      title: 'Condiciones del servicio',
      subTitle: 'Crear multidestino en la ciudad',
    },
  },
  3: {
    header: {
      title: 'Condiciones del servicio',
      subTitle: 'Crear transporte entre ciudades',
    },
  },
  4: {
    header: {
      title: 'Condiciones del servicio',
      subTitle: 'Crear remate entre ciudades',
    },
  },
  5: {
    header: {
      title: 'Condiciones del servicio',
      subTitle: 'Crear mudanza en la ciudad',
    },
  },
};

export const headerEditText = {
  title: 'Condiciones del servicio',
  subTitle: 'Editar',
};

export const buttonText = {
  edit: 'GUARDAR',
  flow: 'SIGUIENTE',
};
