import { AUTH_LOGIN } from "@/consts/typeServicesPaths";
import { useLocation } from "react-router-dom";
import { Enter, RedirectDisplay } from "./styleHeader";
import { Box, styled } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useTranslation } from "react-i18next";
import HeaderListItemUser from "./HeaderListItemUser";

type Props = {
  hasUser: boolean;
  onClose: (value: boolean) => void;
  onStatus: (value: boolean) => void;
};

const ContainerIcon = styled(Box)(
  () => `
    border: 1px solid #fff;
    border-radius: 50%;
    padding: 5px;
  `
);

const HeaderItemLastSection = (props: Props) => {
  const { hasUser, onClose, onStatus } = props;
  const location = useLocation();
  const { t } = useTranslation();

  const handleGeToPathLogin = () => {
    const { pathname } = location;
    return `${AUTH_LOGIN}?returnPath=${pathname}`;
  };

  if (hasUser) {
    return (
      <HeaderListItemUser
        onClose={() => onClose(false)}
        onStatus={() => onStatus(false)}
      />
    );
  }
  return (
    <RedirectDisplay to={handleGeToPathLogin()}>
      <Enter
        sx={{ display: { xs: "none", lg: "block" } }}
        onClick={() => onClose(false)}
      >
        {t("Ingresa")}
      </Enter>
      <ContainerIcon
        sx={{
          display: { xs: "flex", lg: "none" },
        }}
      >
        <PersonOutlineOutlinedIcon
          sx={{
            color: "#fff",
          }}
        />
      </ContainerIcon>
    </RedirectDisplay>
  );
};

export default HeaderItemLastSection;
