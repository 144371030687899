import React from 'react'

interface Props {
  style: {
    color?: string
    height: string
    width: string
  }
}

const KilosIcon = (props: Props) => {

  const { color = "#757575", height, width } = props?.style;

  // convertir a un componente que recibe parametros
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_30232_29100)">
    <mask id="mask0_30232_29100" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
    <rect width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_30232_29100)">
    <path d="M7.93848 16.6117V12.1387H8.96726V16.6117H7.93848ZM8.86502 15.5893L8.80751 14.3943L10.9482 12.1387H12.0983L10.1686 14.2154L9.59348 14.8289L8.86502 15.5893ZM10.9993 16.6117L9.40817 14.6627L10.0855 13.9279L12.207 16.6117H10.9993Z" fill="#757575"/>
    <path d="M14.731 16.6884C14.3773 16.6884 14.0527 16.6318 13.7565 16.519C13.4604 16.4062 13.2028 16.2452 12.9834 16.0366C12.7639 15.8279 12.5936 15.5829 12.4722 15.3017C12.3507 15.0206 12.29 14.7116 12.29 14.3752C12.29 14.0388 12.3507 13.7298 12.4722 13.4486C12.5936 13.1675 12.7651 12.9224 12.9865 12.7138C13.208 12.5052 13.468 12.3441 13.7661 12.2313C14.0642 12.1186 14.3923 12.062 14.7502 12.062C15.1464 12.062 15.5032 12.1281 15.8205 12.2601C16.1378 12.3921 16.4052 12.5838 16.6224 12.8352L15.9579 13.4486C15.7959 13.2783 15.6192 13.1515 15.4275 13.0684C15.2358 12.9854 15.0272 12.9438 14.8013 12.9438C14.5754 12.9438 14.3859 12.978 14.207 13.0461C14.0281 13.1141 13.8738 13.2122 13.7438 13.34C13.6137 13.4678 13.5137 13.6189 13.4434 13.7937C13.3731 13.9685 13.338 14.1624 13.338 14.3752C13.338 14.588 13.3731 14.7755 13.4434 14.9503C13.5137 15.125 13.6137 15.2771 13.7438 15.4072C13.8738 15.5372 14.0272 15.6362 14.2038 15.7043C14.3805 15.7723 14.5754 15.8065 14.7885 15.8065C15.0016 15.8065 15.192 15.7736 15.386 15.7075C15.5799 15.6414 15.7684 15.5295 15.9515 15.372L16.5394 16.1196C16.2966 16.3027 16.0144 16.4433 15.6927 16.5414C15.371 16.6395 15.0505 16.6884 14.731 16.6884ZM15.5937 15.9855V14.3049H16.5394V16.1196L15.5937 15.9855Z" fill="#757575"/>
    <path d="M9.89984 7.4392C8.94997 5.96376 9.4043 4.46083 10.3727 3.6442C11.3756 2.79848 12.8926 2.78315 13.9067 3.61193C14.8709 4.39981 15.3978 5.90433 14.4112 7.45422C14.5444 7.45422 14.6485 7.45422 14.7524 7.45422C15.5153 7.45422 16.278 7.44942 17.0406 7.45549C18.2512 7.4654 19.0634 8.13347 19.3544 9.3105C20.0915 12.293 20.8328 15.2746 21.5877 18.2526C21.9676 19.7508 20.9417 21.1077 19.3695 21.1182C17.3585 21.1316 15.3473 21.122 13.3364 21.122C10.5625 21.122 7.78859 21.1246 5.01471 21.1208C3.88336 21.1192 3.03765 20.5454 2.75553 19.5169C2.65265 19.1418 2.63508 18.6986 2.72582 18.322C3.33638 15.7867 3.98656 13.2608 4.62396 10.732C4.74217 10.2629 4.86422 9.79454 4.97669 9.32392C5.26168 8.13251 6.09301 7.46316 7.31574 7.45518C8.06847 7.45006 8.82153 7.45454 9.57427 7.45358C9.67044 7.45358 9.76693 7.44527 9.89952 7.43888L9.89984 7.4392ZM12.1523 20.0191C12.598 20.0191 13.0437 20.0191 13.4894 20.0191C15.4307 20.0191 17.3723 20.0233 19.3135 20.0172C20.1832 20.0143 20.7066 19.3587 20.5027 18.5389C19.7525 15.5193 19.0001 12.5004 18.2464 9.48175C18.102 8.90378 17.6908 8.56224 17.0914 8.56096C13.8127 8.55489 10.5344 8.55521 7.25567 8.56096C6.65949 8.56192 6.23775 8.90218 6.0927 9.47568C5.32845 12.502 4.56613 15.5286 3.80892 18.5565C3.68847 19.0383 3.78719 19.4926 4.21852 19.7645C4.46229 19.9182 4.78914 20.0064 5.07957 20.0089C7.43683 20.0278 9.79441 20.0195 12.1517 20.0195L12.1523 20.0191ZM10.4947 5.77749C10.4932 6.71138 11.2401 7.45869 12.1686 7.45198C13.0878 7.44527 13.8204 6.70691 13.822 5.78643C13.8236 4.85669 13.0638 4.09245 12.1478 4.10268C11.235 4.1129 10.4963 4.86117 10.4947 5.77749Z" fill="#757575"/>
    <path d="M14.7646 14.0562V14.8345H16.5388V14.0562H14.7646Z" fill={color}/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_30232_29100">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default KilosIcon