import { TypeFilterData } from "@/store/FilterServices/filterReduxInterface";
import Icon, { IconTypes } from "@/utils/Icon";
import { Box, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { StyledTab, StyledTabs } from "../inputCustom/inputTheme";
import SkeletonHeader from "../skeletons/SkeletonHeader";
dayjs.locale("es");

interface Props {
  openInputs: boolean;
  show: boolean;
  tabsStyled?: any;
  value: number;
  change: (_: React.SyntheticEvent, index: number) => void;
  typeNotice: TypeFilterData[];
}

const FilterTabs = (props: Props) => {
  const { value, change, typeNotice, show, tabsStyled } = props;

  if (!typeNotice.length)
    return (
      <Box
        sx={{
          borderRadius: "10px 10px 0 0",
          backgroundColor: "#382E2B",
          display: "flex",
          justifyContent: "space-between",
          p: 1,
        }}
      >
        {!typeNotice.length &&
          [...Array(6)].map((_, index) => (
            <Box sx={{ width: "16%" }} key={index}>
              <SkeletonHeader
                sx={{
                  background: "#ECECEC",
                  height: "45px",
                  marginRight: Array(6).length - 1 === index ? "0px" : "10px",
                  overflowX: "hidden",
                }}
              />
            </Box>
          ))}
      </Box>
    );

  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        whiteSpace: "nowrap",
        overflowX: "auto !important",
        backgroundColor: "#382E2B",
        padding: "10px",
        paddingLeft: 0,
        borderRadius: !show ? "0px" : "24px 24px 0 0",
      
        zIndex: 99,
        ...tabsStyled?.StyledTabs,
      }}
    >
      <StyledTabs value={value} onChange={change} >
        {typeNotice.map((item, index) => (
          <StyledTab
            key={index}
            label={
              <Typography color={ value === index ? "#382e2b" : "white"} sx={{ lineHeight: "18px" , fontSize:"14px"}}>{item.nombre}</Typography>
            }
            icon={
              <Icon name={item?.icono as IconTypes} size={45} color={ value === index ? "#382e2b" : "white"}/>
            }
          />
        ))}
      </StyledTabs>
    </Grid>
  );
};

export default FilterTabs;
