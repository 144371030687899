import { TEXT_ERROR_IMAGE_GENERIC } from "@/consts/textErrors";
import useResizeImage from "@/hooks/useResizeImage";
import { PhotosPreLoaded } from "@/store/offertRedux/offert.interface";
import { convertImageHeic, validatorExtensionImage } from "@/utils/helpers";
import { CircularProgress, Stack, styled } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

const ItemImage = styled("div")(
  () => `
    position: relative;
    border-radius: 4px;
    cursor: pointer;
  `
);

const ButtonRemove = styled("div")(
  () => `
    position: absolute;
    top: 0;
    right: 1px;
    background-color: rgba(0, 0, 0, 0.38);
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

  `
);

type Props = {
  imageDefault?: string;
  onError?: (e: Error) => void;
  setFiles?: (files: any) => void; 
  files: (string | PhotosPreLoaded)[];
  maxFiles?: number;
  sx?: { width: string; height: string };
  onChangeFiles?: (files: File[], name?: string, deleted?: boolean) => void;
};

const UploadMultiPhotos = ({
  onError,
  setFiles,
  files,
  sx,
  onChangeFiles,
  maxFiles = 5,
}: Props) => {
  const [imagesPath, setImagesPath] = useState<any[]>([]);
  const [imagesFiles, setImagesFiles] = useState<File[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const { handleImageFileResizer } = useResizeImage();

  useEffect(() => {
    setImagesPath(files);
    return () => {
      setImagesPath([]);
    };
  }, []);

  const handlePrepareImage = async (acceptedFiles: File) => {
    if (!acceptedFiles.name.toLowerCase().includes(".heic")) {
      const currentFile = await handleImageFileResizer({
        file: acceptedFiles,
        maxWidth: 960,
        maxHeight: 720,
      });
      return currentFile;
    }

    const imagePng = await convertImageHeic(acceptedFiles);
    const currentFile = await handleImageFileResizer({
      file: imagePng,
      maxWidth: 960,
      maxHeight: 720,
    });

    return currentFile;
  };

  const onDrop = useCallback(async ([acceptedFiles]: File[]) => {
    setIsLoading(true);
    let reader = new FileReader();

    if (!acceptedFiles) {
      handleOnError({
        message: TEXT_ERROR_IMAGE_GENERIC,
        name: "undefined",
      });
      return;
    }

    const currentFile = await handlePrepareImage(acceptedFiles);

    setImagesFiles((prev) => {
      if (onChangeFiles) onChangeFiles([...prev, currentFile]);
      return [...prev, currentFile];
    });

    reader.readAsDataURL(currentFile);

    reader.onload = function () {
      const result = reader.result;
      setIsLoading(false);

      setImagesPath((prev) => [...prev, result]);
      setFiles &&
        setFiles((prev: any) => [
          { blob: result, file: currentFile.name },
          ...prev,
        ]);
    };
  }, []);

  const handleOnError = (e: Error) => {
    setIsLoading(false);
    !!onError && onError(e);
  };

  const onRemove = (index: number, name?: string, deleted?: boolean) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles && setFiles(newFiles);
    setImagesFiles((prev) => {
      if (onChangeFiles)
        onChangeFiles(
          prev.filter((_, i) => i !== index),
          name,
          deleted
        );
      return prev.filter((_, i) => i !== index);
    });

    setImagesPath((prev) => {
      return prev.filter((_, i) => i !== index);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
      "image/heic": [],
    },
    validator: validatorExtensionImage,
    onDrop,
    onError: handleOnError,
    maxFiles: 1,
    maxSize: 10000000,
  });

  return (
    <Stack direction="row" spacing={1}>
      {files?.length < maxFiles && imagesPath.length < maxFiles && (
        <ItemImage
          {...getRootProps()}
          sx={{
            background: `${
              isLoading ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0.38)"
            }`,
            ...sx,
          }}
        >
          <i
            className="icon-plus--white"
            style={{
              position: "absolute",
              // center 60px por 60px
              top: "calc(50% - ( 15px / 2 ))",
              left: "calc(50% - ( 15px / 2 ))",
            }}
          />
          <input {...getInputProps()} multiple={false} />
          {isLoading && !imagesPath && (
            <CircularProgress
              color="warning"
              size="20px"
              sx={{
                position: "absolute",
                top: "calc(50% - ( 15px / 2 ))",
                left: "calc(50% - ( 15px / 2 ))",
              }}
            />
          )}
        </ItemImage>
      )}
      {imagesPath.map((image, index) => (
        <ItemImage
          key={index}
          sx={{
            backgroundImage: `url(${
              typeof image === "string" ? image : image.url
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            ...sx,
          }}
        >
          <ButtonRemove onClick={() => onRemove(index, image.name, true)}>
            <i className="icon-tash--white" />
          </ButtonRemove>
        </ItemImage>
      ))}
    </Stack>
  );
};

export default UploadMultiPhotos;
