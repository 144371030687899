import BasicAlert from "@/components/alerts/BasicAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import AutoCompleteModal from "@/components/form/formServicesArea/AutoCompleteModal";
import Input from "@/components/inputCustom/Input";
import HeaderModal from "@/components/modals/headerModal/HeaderModal";
import { ERROR_EQUAL_DESTINY } from "@/consts/textErrors";
import useDebounce from "@/hooks/useDebounse";
import { useAppSelector } from "@/hooks/useReducer";
import { FormControl, Typography, styled } from "@mui/material";
import useId from "@mui/material/utils/useId";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ContainerButtom,
  ContainerModal,
  CustomDrawer,
} from "../stylesContainerFlow";

const FormControlInput = styled(FormControl)(
  () => `
  padding: 16px 16px 0 16px;
  `
);

const CustomFormControl = styled(FormControl)(
  () => `
    width: 100%;
    height: 100%;
  `
);

type InputProps = {
  label: string;
  name: string;
  placeholder: string;
};

type Props = {
  onChange: (value: string) => void;
  onSubmit: (value: Record<string, any> | null) => void;
  open: boolean;
  onClose: (value: boolean) => void;
  list: any[];
  inputProps: InputProps;
  title: string;
  nameTwo?: boolean;
};

const SearchModal: FC<Props> = ({
  onChange,
  onSubmit,
  open,
  onClose,
  list,
  inputProps,
  title,
  nameTwo,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState(null);
  const {
    customerRequest: { selectedCity, selectedCityDestiny },
  } = useAppSelector((state) => state);

  const id = useId();
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const inputValueDebounse = useDebounce(inputValue, 500);
  const [error, setError] = useState("");
  const emptyInputModal = !!inputValue;

  useEffect(() => {
    if (!open) {
      handleCleanValues();
    } else {
      timer.current = setTimeout(() => {
        inputSearchRef.current?.focus();
      }, 100);
    }
    return () => {
      handleCleanValues();
      clearTimeout(timer.current as NodeJS.Timeout);
    };
  }, [open]);

  useEffect(() => {
    onChange(inputValueDebounse);
  }, [inputValueDebounse]);

  const handleCleanValues = () => {
    setInputValue("");
    setSelected(null);
  };

  const handleOnInputChange = async ({
    currentTarget,
  }: ChangeEvent<HTMLInputElement>) => {
    const { value } = currentTarget;

    setInputValue(value.replace(/[^A-Za-z ÁÉÍÓÚáéíóúÑñ,]/g, ""));
    if (selected) {
      setSelected(null);
    }
  };

  const handleSelected = (value: any) => {
    setSelected(value);
    setInputValue(nameTwo ? value.nombre2 : value.nombre);
  
    if (inputProps?.name === "city-destiny") {
      if (value.id === selectedCity.id) {
        setError(ERROR_EQUAL_DESTINY);

        return;
      }
    }
    if (inputProps?.name === "city-origin") {
      if (value.id === selectedCityDestiny.id) {
        setError(ERROR_EQUAL_DESTINY);

        return;
      }
    }

    onSubmit(value);
  };

  const handleOnSubmit = () => {
    onSubmit(selected);
  };

  const handleOnclickInput = () => {
    setInputValue("");
    setError("");
  };

  const handleOncloseDrawer = () => {
    setError("");
    onClose(false)
  }

  const minValue = inputValue.length < 3;

  return (
    <CustomDrawer open={open} onClose={handleOncloseDrawer} anchor="right">
      <HeaderModal title={title} onClick={() => onClose(false)} />
      <FormControlInput fullWidth>
        <Input
          id={id}
          inputRef={inputSearchRef}
          label={inputProps.label}
          name={inputProps.name}
          value={inputValue}
          variant="standard"
          placeholder={inputProps.placeholder}
          onChange={handleOnInputChange}
          onClick={handleOnclickInput}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!error}
        />
      </FormControlInput>
      {!!error && emptyInputModal && (
        <Typography
          variant="body2"
          sx={{ fontSize: "10px", ml: 2, color: "#D32F2F" }}
        >
          {error}
        </Typography>
      )}

      <ContainerModal>
        <CustomFormControl>
          {minValue || selected ? (
            <BasicAlert
              color="warning"
              action={false}
              open={inputValue.length > 0 && !selected}
            >
              Ingresa al menos 3 letras para ver resultados
            </BasicAlert>
          ) : (
            <>
              <AutoCompleteModal
                title=""
                icon=""
                list={list}
                onClick={handleSelected}
                onliItems={true}
                primaryShowKey={nameTwo ? "nombre2" : "nombre"}
              />
            </>
          )}
        </CustomFormControl>
        <ContainerButtom>
          <ButtomLoader
            onClick={handleOnSubmit}
            disabled={!selected || !!error || !inputValue}
            type="submit"
          >
            Guardar
          </ButtomLoader>
        </ContainerButtom>
      </ContainerModal>
    </CustomDrawer>
  );
};

export default SearchModal;
