import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { useAppSelector } from "@/hooks/useReducer";
import { TipoVehiculo } from "@/models/vehicle/vehicleModel";
import { FC, useState, useEffect } from "react";

import { Drawer, Grid, Stack, styled } from "@mui/material";
import HeaderModal from "@/components/modals/headerModal/HeaderModal";
import VehicleList from "@/components/modals/vehicleListModal/components/VehicleList";

export const CustomDrawer = styled(Drawer)(
  () => `
  & .MuiDrawer-paper {
    width: 414px;
  }
  @media only screen and (max-width: 768px) {
    .MuiDrawer-paper {
      width: 100%;
    }
  }
  `
);

export const ContainerModal = styled(Grid)(
  ({ theme }) => `
    &::-webkit-scrollbar {
        width: .4rem;
    }
    &::-webkit-scrollbar-track {
        background-color: ${theme.palette.bgScrollBar.primary};
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${theme.palette.bgScrollBar.secondary};
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:active {
      background-color: ${theme.palette.bgScrollBar.disabled};
    }
    overflow-y: auto;
    padding: 16px;
    height: 100%;
    margin-bottom: 92px;
    & .MuiFormControl-root {
      width: 100%;
    }
  `
);

export const ContainerButtom = styled(Stack)(
  () => `
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 16px;
    z-index: 100;
  `
);

export const ContainerModalMultiShop = styled(ContainerModal)(
  () => `
    margin-bottom: 0;
    flex: 8;
    flex-direction: column;
    display: flex;
  `
);

export const ContainerModalButtomMultiShop = styled(ContainerModal)(
  () => `
    margin-bottom: 0;
    align-items: flex-end;
    display: flex;
    flex: 1;
  `
);

type Props = {
  title: string;
  onSelect: (selected: TipoVehiculo) => void;
  open: boolean;
  onClose: () => void;
  btnTittle?: string;
  defaultSelected?: any;
  component: (props: any) => JSX.Element;
  data: any;
};

const defaultInitSelected: any = {
  id: 0,
  nombre: "",
};

const ListGenericCreateRequest: FC<Props> = ({
  title,
  onSelect,
  open,
  onClose,
  btnTittle = "SIGUIENTE",
  defaultSelected,
  component,
  data,
}) => {
  const [selected, setSelected] = useState<any>(defaultInitSelected);

  const { selectedVehicle } = useAppSelector((state) => state.vehicle);

  useEffect(() => {
    if (open && defaultSelected && defaultSelected?.id > 0) {
      setSelected(defaultSelected);
    }

    if (open && selectedVehicle?.id > 0) {
      setSelected(selectedVehicle);
    }

    return () => {
      setSelected(defaultInitSelected);
    };
  }, [open]);

  const handleOnSelect = (selected: any) => {
    setSelected(selected);
    onSelect(selected);
  };

  const handleSubmit = () => {
    onSelect(selected);
  };

  const handleDisabled = () => {
    return !selected || selected.id === 0;
  };

  const Component = component;

  return (
    <CustomDrawer open={open} onClose={onClose} anchor="right">
      <HeaderModal title={title} onClick={onClose} />

      <ContainerModal>
        {!Component ? (
          <></>
        ) : (
          <Component
            onSelect={handleOnSelect}
            selected={selected}
            data={data}
          />
        )}
      </ContainerModal>

      <ContainerButtom>
        <ButtomLoader onClick={handleSubmit} disabled={handleDisabled()}>
          {btnTittle}
        </ButtomLoader>
      </ContainerButtom>
    </CustomDrawer>
  );
};

export default ListGenericCreateRequest;
