import { Alert, AlertProps, Box, styled, useMediaQuery } from "@mui/material";
import { FC, useState } from "react";
import { ButtonIcon } from "./styleAlert";

interface Props extends AlertProps {
  children: JSX.Element | string;
  icon?: string;
  action?: string | boolean;
  onCloseAlert?: (open: boolean) => void
}

const CustomAlert = styled(Alert)(
  () => `
        padding: 16px 18px;
        line-height: 24px;
    `
);

const StandardAlert: FC<Props> = (props) => {
  const [open, setOpen] = useState(true);
  const sm  = useMediaQuery("(max-width:768px)");

  let {
    icon = "",
    severity = "success",
    variant = "standard",
    color = undefined,
    action = true,
    sx,
    children,
    onCloseAlert
  } = props;

  switch (severity) {
    case "error":
      icon = "icon-alert-error";
      action = "icon-alert-close-error";
      break;
    case "info":
      icon = "icon-alert-info";
      action = "icon-alert-close-info";
      break;
    case "success":
      icon = "icon-alert-success";
      action = "icon-alert-close-success";
      break;
    case "warning":
      icon = "icon-alert-warning";
      action = "icon-alert-close-warning";
      break;
  }

  return (
    <Box sx={{ pr: sm ? 2 : 0, pl: sm ? 2 : 0 }}>
      {open && (
        <CustomAlert
          icon={<i className={icon} />}
          severity={severity}
          variant={variant}
          color={color}
          sx={sx}
          action={
            action && (
              <ButtonIcon
                sx={{ padding: 0 }}
                onClick={() => {
                  onCloseAlert && onCloseAlert(false)
                  setOpen(false);
                }}
              >
                <i className={action} />
              </ButtonIcon>
            )
          }
        >
          {children}
        </CustomAlert>
      )}
    </Box>
  );
};

export default StandardAlert;
