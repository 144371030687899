import { Grid, SxProps, Theme } from "@mui/material";
import MediaList from "./MediaList";
import { firtsList, secondList } from "./condigData";

function SocialMediaList({ sx }: { sx?: SxProps<Theme> }) {
  return (
    <Grid item xs={12} sm={12} md={12} lg={3} sx={sx}>
      <MediaList list={firtsList} />

      <MediaList list={secondList} />
    </Grid>
  );
}

export default SocialMediaList;
