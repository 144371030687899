import { FORMAT_DATE_DDMMYYYY } from '@/consts/formatsDate';
import { REQUEST_TYPE_MOVE_BETWEEN_CITIES, REQUEST_TYPE_MOVE_CITY } from '@/consts/requestConsts';
import { ModalActive } from '@/interfaces/filterInterface';
import dayjs, { Dayjs } from 'dayjs';

export const FORMAT_DATE_FILTER = `ddd ${FORMAT_DATE_DDMMYYYY}`;

export type FormDataFilter = {
  destiny?: string;
  origin?: string;
  city: string | string[];
  weightOfTheLoad: string;
  typeVehicle: string;
  destinyCity?: string;
  timeService: Dayjs | string;
  typeServiceList?: string;
  typeNotice?: string;
  betweenDate?: string;
};

export const INITIAL_STATE: FormDataFilter = {
  destiny: '',
  origin: '',
  city: 'Lima, Peru',
  weightOfTheLoad: '',
  typeVehicle: '',
  timeService: dayjs().add(1, 'day'),
  betweenDate: `${dayjs()
    .add(1, 'day')
    .format(FORMAT_DATE_DDMMYYYY)} - ${dayjs()
    .add(1, 'day')
    .format(FORMAT_DATE_DDMMYYYY)}`,
};

export const MODAL_ACTIVE: Record<string, ModalActive['modalActiveWith']> = {
  name: 'city',
};

export const typePositions = [
  {
    type: 'Transporte en la ciudad',
    position: 'absolute',
  },
  {
    type: 'Transporte entre ciudades',
    position: 'relative',
  },
  {
    type: 'Multidestino en la ciudad',
    position: 'absolute',
  },
  {
    type: REQUEST_TYPE_MOVE_CITY,
    position: 'absolute',
  },
  {
    type: REQUEST_TYPE_MOVE_BETWEEN_CITIES,
    position: 'relative',
  },
  {
    type: 'Remate entre ciudades',
    position: 'relative',
  },
];
