import { UsuarioAPI } from '@/models/userAuth/user';
import {
  TipoVehiculo,
  TypeVehicleAPI,
  VehicleAPI,
  VehicleRegister,
} from '@/models/vehicle/vehicleModel';
import { CreateVehicleDTO } from '@/services/interfaces/vehicleInterface';
import {
  createVehicleRegisterServices,
  updatePhotoVehicleServices,
  updatePhotosVehicleServices,
  updateVehiclePhotosService,
  updateVehicleServices,
} from '@/services/userServices';
import {
  editVehicleServices,
  getVehicleListServices,
  registerVehicleServices,
} from '@/services/vehicleServices';
import { getMessaggeError } from '../auth/utils';
import { setLoadingDetailsNotice } from '../detailsNotice/detailsNoticeSlice';
import {
  convertTypeNotice,
  getSaveDataToStore,
  saveDataToStore,
} from '../noticeRedux/noticeThunks';
import { AppDispatch, RootState } from '../store';
import { ISelectedVehicle } from '../userRedux/userReduxInterface';
import { setSelectedVehicle, setVehicles } from '../userRedux/userSlice';
import {
  editVehicleAdapter,
  registerVehicleAdapter,
} from './adapters/vehicleAdapter';
import { DataFormVehicle, RegisterVehicleImages } from './vehicleInterface';
import {
  selectedVehicle,
  setDataFormVehicle,
  setError,
  setLoading,
  setSaveVehicle,
  setVehicleList,
} from './vehicleSlice';

export const getVehicleListThunk = (viewhand?: 0 | 1) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const { tipoVehiculoData } = await getVehicleListServices(viewhand);

      dispatch(setVehicleList(tipoVehiculoData.tipoVehiculos));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

export const selectedVehicleThunk = (selectVehicle: TipoVehiculo) => {
  return (dispatch: AppDispatch) => {
    try {
      dispatch(selectedVehicle(selectVehicle));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

export const setDataFormVehicleThunk = (dataVehicle: DataFormVehicle) => {
  return (dispatch: AppDispatch) => {
    try {
      dispatch(setDataFormVehicle(dataVehicle));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

export const registerVehicleThunk = (vehicleImages: RegisterVehicleImages) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      const {
        vehicle: { selectedVehicle, maximumLoad, vehiclePlate, isRefrigerate },
        user: { user },
      } = getState();
      const body = {
        selectedVehicle,
        maximumLoad,
        vehiclePlate,
        isRefrigerate,
        ...vehicleImages,
        userId: (user as UsuarioAPI)?.id,
      };
      const registerVehicle: VehicleRegister = registerVehicleAdapter(body);
      const result = await registerVehicleServices(registerVehicle);
      dispatch(setLoading(false));

      return result;
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

export const editVehicleThunk = (idNotice: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoadingDetailsNotice(true));
    try {
      const { user } = getState();
      const body = editVehicleAdapter(user.selectedVehicle as ISelectedVehicle);
      const result = await editVehicleServices(idNotice, body);
      const { avisos } = result?.avisoData;

      const typeOfFlow = convertTypeNotice(avisos[0].tipoaviso.nombre);

      const setDataToStore = getSaveDataToStore(typeOfFlow);

      dispatch(setDataToStore(avisos[0]));

      dispatch(setLoadingDetailsNotice(false));
      dispatch(setSelectedVehicle(avisos[0].vehiculo));

      return result;
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

export const createVehicleThunk = (body: CreateVehicleDTO) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setError(''));
    dispatch(setLoading(true));
    try {
      const {
        user: { vehicles: vehiclesStorage },
      } = getState();
      const { vehiculoData } = await createVehicleRegisterServices(body);
      const { vehiculo } = vehiculoData;

      const bodyVehicle = {
        vehiclePlate: vehiculo.placa,
        maximumLoad: vehiculo.cargamaxima?.toString(),
        isRefrigerate: !!vehiculo.flgrefrigerado,
      };

      dispatch(setDataFormVehicle(bodyVehicle));
      dispatch(
        selectedVehicle(vehiculo.tipovehiculo as Required<TypeVehicleAPI>)
      );
      dispatch(setSaveVehicle(vehiculo));
      dispatch(
        setVehicles([...vehiclesStorage, { ...vehiculo, vehiculofotos: [] }])
      );
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

export const updateVehicleThunk = (body: CreateVehicleDTO) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      const {
        user: { vehicles },
      } = getState();

      const { vehiculoData } = await updateVehicleServices(
        body,
        vehicles[0]?.id?.toString()
      );
      const { vehiculo } = vehiculoData;

      const bodyVehicle = {
        vehiclePlate: vehiculo.placa,
        maximumLoad: vehiculo.cargamaxima?.toString(),
        isRefrigerate: !!vehiculo.flgrefrigerado,
      };

      dispatch(setDataFormVehicle(bodyVehicle));
      dispatch(
        selectedVehicle(vehiculo.tipovehiculo as Required<TypeVehicleAPI>)
      );
      dispatch(setSaveVehicle(vehiculo));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

type IUpdateVehicleImage = {
  fotoFrontal: string | File;
  fotoLateral: string | File;
  fotoPosterior: string | File;
};
export const updateVehicleImagesThunk = (
  body: IUpdateVehicleImage | {},
  idVehicle: string
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setError(''));
    dispatch(setLoading(true));
    try {
      const { vehiculoData } = await updatePhotosVehicleServices(
        body,
        idVehicle
      );
      const { vehiculo } = vehiculoData;

      dispatch(setSaveVehicle(vehiculo));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

export const updateVehiclePhotosThunk = (body: IUpdateVehicleImage | {}) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setError(''));
    dispatch(setLoading(true));
    try {
      const { vehiculoData } = await updateVehiclePhotosService(body);
      const { vehiculo } = vehiculoData;

      dispatch(setSaveVehicle(vehiculo));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

type IUVehicleImage = {
  foto: string | File;
  tipo: string;
};
export const updateVehicleImageThunk = (body: IUVehicleImage, id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const {
        vehiculoData,
        metadata: [result],
      } = await updatePhotoVehicleServices(body, id);
      const { vehiculo } = vehiculoData;

      dispatch(setSaveVehicle(vehiculo));
      return result?.dato;
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setError(error.message));

      throw new Error(error.message || undefined);
    }
  };
};

export const hydrateVehicleThunk = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      const {
        user: {
          vehicles: [vehicle],
        },
      } = getState();

      const bodyVehicle = {
        vehiclePlate: vehicle.placa,
        maximumLoad: vehicle.cargamaxima?.toString(),
        isRefrigerate: !!vehicle.flgrefrigerado,
      };

      dispatch(setDataFormVehicle(bodyVehicle));
      dispatch(
        selectedVehicle(vehicle.tipovehiculo as Required<TypeVehicleAPI>)
      );
      dispatch(setSaveVehicle(vehicle as VehicleAPI));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error.message));
      throw new Error(error.message || undefined);
    }
  };
};
