import { Title } from "@/pages/flowNoticeNegotation/styles/styles";
import Icon, { IconTypes } from "@/utils/Icon";
import { Box, styled } from "@mui/material";

export type Props = {
  title: string;
  icon: string;
  capitalizeText: string;
  optionalSign?: string
};

export const CustomSpan = styled("span")(({ theme }) => ({
  fontFamily: "Source Sans Pro, Roboto",
  fontWeight: 400,
  maxWidth: "320px",
  letterSpacing: "0.00938em",
  wordWrap: "break-word",
}));

const MultidestinyFormat = ({ title, icon, capitalizeText, optionalSign=":" }: Props) => {
  return (
    <Box sx={{ marginTop: "10px", display: "flex" }}>
      <Icon name={icon as IconTypes} size="md" />
      <Title variant="label1">
       { `${title}${optionalSign} `}
        <CustomSpan>{capitalizeText}</CustomSpan>
      </Title>
    </Box>
  );
};

export default MultidestinyFormat;
