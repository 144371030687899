import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import SearchModal from "@/components/modals/searchModal/SearchModal";
import LabelRequired from "@/components/utils/LabelRequired";
import { FORMAT_DATE_DDMMYYYY } from "@/consts/formatsDate";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { customerRequestObjectTypes } from "@/pages/customerRequest/shared/consts/customerRequestConsts";
import {
  setDateService,
  setSelectDestiny,
  setSelectOrigin,
} from "@/store/customerRequestRedux/customerRequestSlice";
import { getCitiesByTermThunk } from "@/store/locationRedux/citiesThunks";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { FormControl, Grid, styled } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useId, useState } from "react";
import { useNavigate } from "react-router-dom";

import DatePicker from "@/components/form/datePicker.tsx/DatePicker";
import Input from "@/components/inputCustom/Input";
import {
  MODAL_TYPE,
  ModalType,
  configFormRute,
  functionSaveObjectByTypeModal,
} from "../../../Rute/FormRute/FormRuteInCity/configFormInCity";
const Container = styled(Grid)(
  () => `
      display: flex;
      flex-direction: column;  
      margin-top: 24px;
    `
);
const Form = styled("form")(
  () => `
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 16px;
    `
);
const FormFechaMoving = () => {
  const [datePicker, setDatePicker] = useState<Dayjs | null>(
    dayjs().add(1, "day")
  );
  const [openModal, setOpenModal] = useState(false);
  const [currentModal, setCurrentModal] = useState({
    name: "search-city",
    label: "Ciudad",
    placeholder: "Ingresa la ciudad",
    title: "Ciudad de origen",
    type: "city",
  });
  const [currentTypeModal, setCurrentTypeModal] = useState<ModalType["type"]>(
    MODAL_TYPE.city
  );
  const id = useId();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    cities: { citiesByTerm },
    customerRequest: {
      selectedCity,
      flgCiudadoEntreciudades,
      selectedTypeRequest,
      dateServices,
    },
  } = useAppSelector((state) => state);

  useEffect(() => {
    if (dateServices) {
      const date = dayjs(dateServices, FORMAT_DATE_DDMMYYYY);
      setDatePicker(date);
    }
  }, []);

  const handleOnClickSearchInput = (typeModal: ModalType["type"]) => {
    setCurrentTypeModal(typeModal);
    setCurrentModal(configFormRute[typeModal].modal);
    setOpenModal(true);
  };

  const handleChangeModal = (term: string) => {
    if (term.length >= 3) {
      dispatch(getCitiesByTermThunk(term));
    }
  };
  const handleSubmitModal = (selected: Record<string, any> | null) => {
    if (!selected) return;
    const reduxAction = functionSaveObjectByTypeModal[currentTypeModal];

    dispatch(reduxAction(selected));
    dispatch(
      setSelectOrigin({
        id: 0,
        nombre: "",
      })
    );
    dispatch(
      setSelectDestiny({
        id: 0,
        nombre: "",
      })
    );

    setOpenModal(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (handleDisabledButton()) return;
    const stringDate =
      (datePicker as Dayjs)?.format(FORMAT_DATE_DDMMYYYY) || "";
    dispatch(setDateService(stringDate));
    handleNextRute();
  };

  const handleNextRute = () => {
    const valueToReplace = customerRequestObjectTypes[selectedTypeRequest.id];
    dispatch(
      getNextRoute(navigate, { valueToSearch: ":typeRequest", valueToReplace })
    );
  };

  const handleDisabledButton = () => {
    if (flgCiudadoEntreciudades === 1 && !selectedCity?.id) return true;
    if (!datePicker) return true;
    return false;
  };

  const { city } = configFormRute;

  return (
    <Container>
      <SearchModal
        open={openModal}
        onClose={setOpenModal}
        onSubmit={handleSubmitModal}
        onChange={handleChangeModal}
        list={citiesByTerm}
        title={currentModal.title}
        inputProps={currentModal}
      />
      <Form onSubmit={handleSubmit}>
        <DatePicker
          initValue={datePicker}
          inputFormat={FORMAT_DATE_DDMMYYYY}
          label={<LabelRequired str="¿Para cuándo necesitas la mudanza?" />}
          onChange={setDatePicker}
          minDate={dayjs().add(1, "day")}
          renderInput={Input}
        />
        {flgCiudadoEntreciudades === 1 && (
          <FormControl sx={{ marginTop: "16px" }} fullWidth>
            <Input
              id={`${id}-city`}
              fullWidth
              name={city.input1.name}
              label={<LabelRequired str={"¿Cuál es tu ciudad?"} />}
              value={selectedCity?.nombre2 || selectedCity?.nombre}
              variant="standard"
              placeholder={city.input1.placeholder}
              onClick={() => handleOnClickSearchInput(MODAL_TYPE.city)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        )}
        <ButtomLoader
          type="submit"
          buttomProps={{
            disabled: handleDisabledButton(),
          }}
        >
          SIGUIENTE
        </ButtomLoader>
      </Form>
    </Container>
  );
};

export default FormFechaMoving;
