import DetailTypeFlow from '@/components/viewService/detailsService/detailTypeFlow';
import { Typography } from '@mui/material';
import { ConfigDataAcceptance } from '../interfaces/acceptanceInterface';
import {
  getCounterOffertFormatSendDate,
  getFormatEditionPrice,
  getTitleOriginAndDestiny,
  getTypeByPrice,
  getValueInOffert,
} from '../utils/utilsAcceptance';

export const editCounterOfferConfig: ConfigDataAcceptance[] = [
  {
    Component: DetailTypeFlow,
    props: {},
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'nombre',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'tipoaviso', 'nombre'],
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'id',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'tipoaviso', 'id'],
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'title',
        defaultResult: '',
        callback: getTitleOriginAndDestiny,
      },
    ],
  },
  {
    Component: Typography,
    title: 'Tu contraoferta',
    props: {
      variant: 'body2',
      sx: {
        color: 'rgba(0, 0, 0, 0.6)',
        marginTop: '18px',
      },
    },
  },
  {
    Component: Typography,
    props: {
      title: 'Precio de punto a punto',
      variant: 'body2',
      marginTop: '9px',
    },
    valid: [
      {
        property: 'precio',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'precio',
        componentProp: 'title',
        defaultResult: '',
        callback: getTypeByPrice,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'h5',
      sx: { textTransform: 'inherit' },
    },
    valid: [
      {
        property: 'precio',
        componentProp: 'title',
        defaultResult: 0,
        callback: getFormatEditionPrice,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      sx: {
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: '12px',
        lineHeight: '20px',
      },
    },
    valid: [
      {
        property: 'fechaContraTransportista',
        componentProp: 'title',
        defaultResult: '',
        callback: getCounterOffertFormatSendDate,
      },
    ],
  },
];
