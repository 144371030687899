import { NoticeDetailAuctionModel } from "@/models/notice/noticeV2NegotationModels";
import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import AuctionCounterOfferPrice from "../../../counterOfferDetails/containers/AuctionNoticeV2Neg/AuctionCounterOfferPrice";
import PriceCounterOfferAuctionDriver from "./PriceCounterOfferAuctionDriver";

export const AuctionNoticeDriver = () => {
  const { notice } = useGetData();

  return (
    <>
    <AuctionCounterOfferPrice data={notice as NoticeDetailAuctionModel}/>
    <PriceCounterOfferAuctionDriver />
    </>
  )
}
