import StandardAlert from "@/components/alerts/StandardAlert";
import FormDatosVehicle from "@/components/form/formDatosVehicle/FormDatosVehicle";
import BannerModal from "@/components/modals/BannerModal/BannerModal";
import { PROFILE_CREAR_VEHICULO_PATH } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import HeaderProfile from "@/pages/profile/headerProfile/HeaderProfile";
import { IVehicleList } from "@/store/userRedux/userReduxInterface";
import { setErrorMsg, setSuccessMsg } from "@/store/userRedux/userSlice";
import {
  createVehicleThunk,
  updateVehicleThunk,
} from "@/store/userRedux/userThunks";
import { useEffect, useRef, useState } from "react";
import { To, useLocation, useNavigate } from "react-router-dom";
import {
  changePathName,
  objButtonTitle,
  objTitle,
} from "../profileConfigDataVehicle";

const VehicleCandE = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [dataIsEmpty, setDataIsEmpty] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState({
    title: "",
    message: "",
    ok: "",
    cancel: "",
    onCancel: () => {},
    onOk: () => {},
  });

  const {
    user: { selectedVehicleList, errorMsg, isLoading },
  } = useAppSelector((state) => state);

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (errorMsg && errorMsg !== "") {
      timer.current = setTimeout(() => {
        dispatch(setErrorMsg(""));
      }, 15000);
    }
    return () => {
      clearTimeout(timer.current as NodeJS.Timeout);
      errorMsg && dispatch(setErrorMsg(""));
    };
  }, [errorMsg]);

  const onSubmit = (data: any) => {
    const nextPage =
      pathname === PROFILE_CREAR_VEHICULO_PATH[0]
        ? PROFILE_CREAR_VEHICULO_PATH[1]
        : (-1 as To);
    const send =
      pathname === PROFILE_CREAR_VEHICULO_PATH[0] &&
      Object.keys(selectedVehicleList).length === 0
        ? createVehicleThunk
        : updateVehicleThunk;
    const message =
      pathname === PROFILE_CREAR_VEHICULO_PATH[0]
        ? ""
        : "Cambios guardados con éxito.";

    if (
      pathname !== PROFILE_CREAR_VEHICULO_PATH[0] &&
      (selectedVehicleList as IVehicleList).estado === "COMPLETO"
    ) {
      setShowAlert(true);
      setMessageAlert({
        title: "Edición de vehículo",
        message:
          "Se desactivarán los avisos activos creados con este vehículo y las ofertas de dicho aviso ya no estarán disponibles. ¿Deseas continuar?",
        ok: "Si, CONTINUAR",
        cancel: "NO",
        onCancel: () => {
          setShowAlert(false);
        },
        onOk: () => {
          dispatch(
            send(data, (selectedVehicleList as IVehicleList)?.id?.toString())
          )
            .then(() => {
              dispatch(setSuccessMsg(message));
              navigate(nextPage);
            })
            .catch(() => setShowAlert(false));
        },
      });
      return;
    }

    dispatch(send(data, (selectedVehicleList as IVehicleList)?.id?.toString()))
      .then((res: any) => {
        dispatch(setSuccessMsg(message));
        navigate(nextPage);
      })
      .catch(() => setShowAlert(false));
  };

  // dataIsEmpty
  const handleDataIsEmpty = (isEmpty: boolean) => {
    setDataIsEmpty(isEmpty);
  };

  const navigateBack = () => {
    if (
      Object.keys(selectedVehicleList).length > 0 &&
      pathname === PROFILE_CREAR_VEHICULO_PATH[0]
    ) {
      setShowAlert(true);
      setMessageAlert({
        title: "Vehículo incompleto",
        message:
          "Al salir el vehículo se guardará como incompleto y podrás continuar en cualquier momento ¿Deseas salir? ",
        ok: "Si, SALIR",
        cancel: "NO, QUEDARME",
        onCancel: () => setShowAlert(false),
        onOk: () => navigate(-1),
      });
      return;
    } else if (dataIsEmpty) {
      setShowAlert(true);
      setMessageAlert({
        title: "Cambios sin guardar",
        message: "Aún no has guardado los cambios ¿Deseas salir?",
        ok: "Si, REGRESAR",
        cancel: "NO, QUEDARME",
        onCancel: () => setShowAlert(false),
        onOk: () => navigate(-1),
      });
      return;
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <HeaderProfile
        title={objTitle[changePathName(pathname)]}
        sx={{
          width: "360px",
        }}
        viewIcon={true}
        backAction={navigateBack}
      >
        <BannerModal
          open={showAlert}
          onClose={() => setShowAlert(false)}
          title={messageAlert.title}
          content={messageAlert.message}
          option1={{
            title: messageAlert.cancel,
            onClick: messageAlert.onCancel,
          }}
          option2={{
            title: messageAlert.ok,
            onClick: messageAlert.onOk,
          }}
          color="org"
          sx={{
            width: "322px",
            height: "292px",
          }}
          sxAccion={{
            padding: "0px",
          }}
        />
        {errorMsg && !isLoading && (
          <StandardAlert
            sx={{ marginBottom: "16px" }}
            severity="error"
            color="error"
            onClick={() => {
              dispatch(setErrorMsg(""));
            }}
          >
            {errorMsg}
          </StandardAlert>
        )}
        <FormDatosVehicle
          vehicle={(selectedVehicleList as IVehicleList) || {}}
          onSubmit={onSubmit}
          titleButton={objButtonTitle[changePathName(pathname)]}
          dataIsEmpty={handleDataIsEmpty}
        />
      </HeaderProfile>
    </>
  );
};

export default VehicleCandE;
