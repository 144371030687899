export const configText = {
  button: {
    edit: 'GUARDAR',
    flow: 'PUBLICAR',
  },
};

export const currentRuleFee: Record<number, any> = {
  1: {
    header: {
      title: 'Precio del servicio',
      subTitle: 'Crear avisos en la ciudad',
    },
  },
  2: {
    header: {
      title: 'Precio del servicio',
      subTitle: 'Crear aviso multidestino en la ciudad',
    },
  },
  3: {
    header: {
      title: 'Precio del servicio',
      subTitle: 'Crear avisos entre ciudades',
    },
  },
  4: {
    header: {
      title: 'Precio del servicio',
      subTitle: 'Crear aviso de remate entre ciudades',
    },
  },
  5: {
    header: {
      title: 'Precio del servicio',
      subTitle: 'Crear avisos de mudanza',
    },
  },
};

export const headerEditText = {
  title: 'Precio',
  subTitle: 'Editar',
};

export const priceInfoText =
  'Cumplir tus precios hará que te contacten de nuevo y recomienden.';
