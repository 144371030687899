import { Box, Button, Typography, styled } from '@mui/material';

export const BtnStyled = styled(Button)(() => ({
  color: 'white',
  width: '248px',
  fontWeight: '700',
  backgroundColor: '#FF6700',
  borderRadius: '44px',
  marginTop: '16px',
  marginBottom: '16px',
}));

export const BoxStyled = styled(Box)(({ matches }: { matches: boolean }) => ({
  widows: '100%',
  height: matches ? '500px' : '350px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const BoxStyledContainer = styled(Box)(
  ({ matches }: { matches: boolean }) => ({
    width: matches ? '100%' : '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: matches ? 'center' : 'space-around',
    alignItems: matches ? 'center' : 'start',
  })
);

export const TypographySectionStyled = styled(Typography)(
  ({ matches }: { matches: boolean }) => ({
    fontWeight: '700',
    fontSize: '22px',
    marginTop: matches ? '22px' : '0px',
    marginBottom: '22px',
  })
);

export const TypographyTextStyled = styled(Typography)(
  ({ matches }: { matches: boolean }) => ({
    fontSize: '16px',
    textAlign: matches ? 'center' : 'start',
    paddingLeft: '16px',
    paddingRight: '16px',
  })
);

export const ImgBox = styled(Box)(({ matches }: { matches: boolean }) => ({
  width: matches ? '100%' : '40%',
  display: 'flex',
  justifyContent: 'center',
}));

export const BoxContainer = styled(Box)(
  ({ matches }: { matches: boolean }) => ({
    display: 'flex',
    flexDirection: matches ? 'column' : 'row',
    justifyContent: 'space-around',
  })
);
