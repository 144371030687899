import useResize from '@/hooks/useResize';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC } from 'react';
import { BarTitleTable, CellTable, ContainerTable, TitleTable } from './styleTableAds';

interface Props {
    title?: string;
    rows?:  {
        desde: string;
        hasta: string;
        cpm: string;
        cpi: string;
    }[]
}

export function createData(
    desde: string,
    hasta: string,
    cpm: string,
    cpi: string
) {
    return { desde, hasta, cpm, cpi };
}

const TableAds: FC<Props> = (props) => {
    const { title, rows } = props;
    const [, isMobil] = useResize();

    return (
        <ContainerTable>
            <BarTitleTable sx={{width: "100%", justifyContent: "center"}}>
                <TitleTable variant="h6">{title}</TitleTable>
            </BarTitleTable>
            <Table>
                <TableHead>
                    <TableRow>
                        <CellTable>
                            <Typography variant="h6">Desde</Typography>
                        </CellTable>
                        <CellTable>
                            <Typography variant="h6">Hasta</Typography>
                        </CellTable>
                        <CellTable>
                            <Typography variant="h6">CPM</Typography>
                        </CellTable>
                        {/* NO MOBILE */}
                        {!isMobil && (
                            <CellTable>
                                <Typography variant="h6">Costo por impresión</Typography>
                            </CellTable>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.map((row, i) => (
                        <TableRow key={i}>
                            <CellTable>S/ {row.desde}</CellTable>
                            <CellTable>S/ {row.hasta}</CellTable>
                            <CellTable>S/ {row.cpm}</CellTable>
                            {/* NO MOBILE */}
                            {!isMobil && (
                                <CellTable>S/ {row.cpi}</CellTable>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </ContainerTable >
    );
}


export default TableAds;
