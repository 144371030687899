import {
  ID_AUCTION_FLOW,
  ID_BETWEEN_CITIES,
  ID_IN_CITY,
  ID_MOVING_FLOW,
  ID_MULTI_DESTINY,
} from '@/consts/defaultConsts';
import { useAppDispatch } from '@/hooks/useReducer';
import { resetDetailsNoticeSlice } from '@/store/detailsNotice/detailsNoticeSlice';
import { NoticeV2NegType } from '@/store/noticeV2Negotiation/noticeV2NegotiationSlice';
import { getNegotiationByIdThunk } from '@/store/noticeV2Negotiation/noticeV2NegotiationThunk';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useGetData from '../../shared/hooks/useGetData';
import AuctionAccept from './views/AuctionAccept';
import BtnCitiesAccept from './views/BtnCitiesAccept';
import InTheCityAccept from './views/InTheCityAccept';
import MovingAccept from './views/MovingAccept';
import MultidestinyAccept from './views/MultidestinyAccept';

const useNegotationFinishedAcceptHook = () => {
  const { notice, isLoadingNoticeV2 } = useGetData();
  const dispatch = useAppDispatch();
  const { idOffert = '' } = useParams();
  useEffect(() => {
    dispatch(resetDetailsNoticeSlice());
    dispatch(getNegotiationByIdThunk(Number(idOffert)));
  }, []);

  const Component =
    componentToRender[(notice as NoticeV2NegType)?.idtipoaviso as number];

  return {
    Component,
    isLoadingNoticeV2,
    notice,
  };
};

export const componentToRender: Record<
  number,
  ({ notice }: { notice: NoticeV2NegType }) => JSX.Element
> = {
  [ID_IN_CITY]: InTheCityAccept,
  [ID_BETWEEN_CITIES]: BtnCitiesAccept,
  [ID_MULTI_DESTINY]: MultidestinyAccept,
  [ID_MOVING_FLOW]: MovingAccept,
  [ID_AUCTION_FLOW]: AuctionAccept,
};

export default useNegotationFinishedAcceptHook;
