import Footer from "@/pages/footer/Footer";
import MessageLand from "../components/messageLand/MessageLand";

const ContactConfirmation = () => {
  return (
    <>
      <MessageLand
        title="Gracias"
        text="Hemos recibido tu mensaje. De ser necesario nos comunicaremos contigo lo más pronto."
      />
      <Footer />
    </>
  );
};

export default ContactConfirmation;
