import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import LabelRequired from "@/components/utils/LabelRequired";
import { ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import {
  districtsDeliveryText,
  textCannotLowerPrice,
  textCannotUpperPrice,
  textContraofertar,
  textSiguiente,
} from "@/consts/noticeConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { BodyOfferMultiDestinationDTO } from "@/interfaces/offerInterface";
import { NoticeDetailMultiDestinyModel } from "@/models/notice/noticeModels";
import AlertOffert from "@/pages/flowNoticeNegotation/components/AlertOffert";
import CardCounterOffer from "@/pages/flowNoticeNegotation/components/CardCounterOffer";
import {
  flagPoint,
  maxHours,
  maxPoints,
  minHours,
  minPoints,
  textHours,
  textPoint,
} from "@/pages/flowNoticeNegotation/shared/const/offertConst";
import { buidTextQuantityError } from "@/pages/flowNoticeNegotation/shared/utils/utilsMultidestiny";
import { setError } from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferSlice";
import { saveOfferMultiDestinyThunk } from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferThunk";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { theme } from "@/styles/theme";
import { cleanValueNumber, currencyFormat } from "@/utils/helpers";
import { FormControl, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const errorStyle = {
  border: "1px solid " + theme.palette.error.main,
  boxShadow: "none",
};

const FormPriceMyltidestinyCounterOfferDetails = () => {
  const { selectTypeNotice, idTypeNoticeNegotiation, isLoading, error } =
    useAppSelector((state) => state.noticeV2ClientAorCO);

  const notice = selectTypeNotice as NoticeDetailMultiDestinyModel;
  const isCounterOffer = idTypeNoticeNegotiation === ID_MULTI_DESTINY;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isPoint = notice.flghoraopunto === flagPoint;

  const [form, setForm] = useState({
    offerPrice: "",
    pointsOrHours: "",
  });

  const [errorQuantity, setErrorQuantity] = useState("");
  const [errorPrice, setErrorPrice] = useState("");

  useEffect(() => {
    return () => {
      dispatch(setError(""));
    };
  }, []);

  const handleGoTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleChange = ({ target }: EventType["change"]) => {
    const { name, value } = target;

    const cleanValue = cleanValueNumber(value);
    if (cleanValue.length <= 4) {
      setForm({ ...form, [name]: cleanValue });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const hasError = handleValidFields();
    if (hasError) return;

    const typeNotice = notice.precioxpunto ? "puntos" : "horas";
    let body: BodyOfferMultiDestinationDTO = {
      [typeNotice]: Number(form.pointsOrHours),
      precio: Number(form.offerPrice),
    };

    dispatch(saveOfferMultiDestinyThunk(body)).then(() => {
      dispatch(
        getNextRoute(navigate, {
          valueToReplace: notice.id?.toString(),
          valueToSearch: ":id",
        })
      );
    });
  };

  const handleValidFields = () => {
    const hasErrorPrice = handleValidErrorPrice();
    const hasErrorQuantity = handleValidErrorQuantity();

    return (hasErrorPrice && isCounterOffer) || hasErrorQuantity;
  };

  const handleValidErrorPrice = () => {
    const offerPrice = Number(form.offerPrice);
    const minimumPriceAccepted = 5;
    const maximumPriceAccepted = 99;
    const hasErrorMinPrice = offerPrice < minimumPriceAccepted;
    const hasErrorMaxPrice = offerPrice > maximumPriceAccepted;
    const messageErrorMin = textCannotLowerPrice;
    const messageErrorMax = textCannotUpperPrice;
    if (hasErrorMinPrice || hasErrorMaxPrice) {
      const errorText = hasErrorMinPrice ? messageErrorMin : messageErrorMax;
      handleGoTop();
      setErrorPrice(errorText);
    } else if (!hasErrorMinPrice && errorPrice) {
      setErrorPrice("");
    }

    return hasErrorMinPrice;
  };

  const handleValidErrorQuantity = () => {
    const { minimumQuantity, defaultMaxQuantity, messageErrorQuantity } =
      handleGetVariablesErrorQuantity();
    const pointsOrHours = Number(form.pointsOrHours);
    const hasErrorMinQuantity = pointsOrHours < minimumQuantity;
    const hasErrorMaxQuantity = pointsOrHours > defaultMaxQuantity;
    if (hasErrorMinQuantity || hasErrorMaxQuantity) {
      handleGoTop();
      setErrorQuantity(messageErrorQuantity);
    } else if (errorQuantity) {
      setErrorQuantity("");
    }

    return hasErrorMinQuantity || hasErrorMaxQuantity;
  };

  const handleGetVariablesErrorQuantity = () => {
    const typeFLow = isPoint ? textPoint : textHours;

    let defaultMaxQuantity = maxHours;
    let minimumQuantity = minHours;
    if (isPoint) {
      defaultMaxQuantity = maxPoints;
      minimumQuantity = minPoints;
    }

    const messageErrorQuantity = buidTextQuantityError({
      typeFLow,
      max: defaultMaxQuantity,
      min: minimumQuantity,
    });

    return {
      minimumQuantity,
      defaultMaxQuantity,
      messageErrorQuantity,
    };
  };

  const handleGetTotalCounterOffer = () => {
    const price = form.offerPrice || 0;
    const quantity = form.pointsOrHours || 0;

    return currencyFormat(Number(price) * Number(quantity));
  };

  const handleGetTotalNotice = () => {
    const price = isPoint ? notice.precioxpunto : notice.precioxhora;
    const quantity = isPoint ? notice.puntosminimo : notice.horasminimo;
    const sum = (price || 0) * (quantity || 0);

    return currencyFormat(sum);
  };

  const handleShowErrorAlert = () => {
    if (isLoading) return false;

    return !!error;
  };

  const handleGetStyleError = (message: string) => {
    if (!!message) {
      return errorStyle;
    }

    return {};
  };

  return (
    <>
      <Typography variant="body2" color="black">
        <strong>{districtsDeliveryText}:</strong>{" "}
        {notice.destinos
          .filter((destiny) => destiny.selected)
          .map((destiny) => destiny.nombre)
          .join(", ")
          .replace(/,(?!.*,)/gim, " y") || "-"}
      </Typography>

      <AlertOffert
        show={handleShowErrorAlert()}
        textAlert={error}
        severity="error"
      />

      <CardCounterOffer
        containerSx={{
          marginTop: "16px",
          ...handleGetStyleError(errorPrice),
        }}
        title={`Precio por ${isPoint ? "punto" : "hora"}`}
        row1={{
          title1: "Del aviso",
          title2: <LabelRequired str="Contraoferta (S/)" />,
        }}
        row2={{
          title1: isPoint
            ? currencyFormat(notice.precioxpunto || 0)
            : currencyFormat(notice.precioxhora || 0),
          input: {
            name: "offerPrice",
            value: form.offerPrice,
            inputMode: {
              onChange: handleChange,
            },
          },
        }}
      />
      <Typography
        variant="caption"
        sx={{ display: !!errorPrice ? "block" : "none" }}
        color={theme.palette.error.main}
      >
        {errorPrice}
      </Typography>

      <CardCounterOffer
        containerSx={{
          marginTop: "16px",
          ...handleGetStyleError(errorQuantity),
        }}
        title={`Cantidad de ${isPoint ? "puntos" : "horas"}`}
        row1={{
          title1: "Del aviso",
          title2: <LabelRequired str="Contraoferta" />,
        }}
        row2={{
          title1: isPoint ? notice.puntosminimo : notice.horasminimo,
          input: {
            name: "pointsOrHours",
            value: form.pointsOrHours,
            inputMode: {
              onChange: handleChange,
            },
          },
        }}
      />
      <Typography
        variant="caption"
        sx={{ display: !!errorQuantity ? "block" : "none" }}
        color={theme.palette.error.main}
      >
        {errorQuantity}
      </Typography>

      <CardCounterOffer
        containerSx={{ marginTop: "16px" }}
        title="Precio del servicio"
        row1={{
          title1: "Del aviso",
          title2: <LabelRequired str="Contraoferta" />,
        }}
        row2={{
          title1: handleGetTotalNotice(),
          title2: handleGetTotalCounterOffer(),
        }}
      />

      <FormControl fullWidth>
        <ButtomLoader
          onClick={handleSubmit}
          disabled={isLoading}
          isLoading={isLoading}
        >
          {isCounterOffer ? textContraofertar : textSiguiente}
        </ButtomLoader>
      </FormControl>
    </>
  );
};

export default FormPriceMyltidestinyCounterOfferDetails;
