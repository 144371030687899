import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import LoadMultiPhotos from "@/components/uploadMultiPhotos/LoadMultiPhotos";
import { maxLengthReference } from "@/consts/noticeConsts";
import {
  ERROR_IMAGE_INAPPROPIATE,
  MAX_CHARRACTERS,
  REQUIRED_DESCRIPTION_LOAD,
} from "@/consts/textErrors";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { Form } from "@/pages/flowNoticeNegotation/styles/styles";
import { fillSendDataNoticeThunk } from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferThunk";

import {
  getFirstStepNoticeType,
  getFirstStepNoticeTypeInterface,
} from "@/pages/flowNoticeNegotation/shared/adapters/clientAcceptOrCounterOfferAdapter";
import {
  setImageUploadError,
  setPhotosPreLoaded,
} from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferSlice";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { validateValueInput } from "@/utils/helpers";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { inputDescribe, textImages } from "./configDataOfferCreate";
import { NoticeDetailAuctionModel } from "@/models/notice/noticeModels";
interface IFormDatos {
  cargoDescription: string;
}

export const textAlert = "El transportista lleva sólo carga refrigerada.";

const AuctionBtnCitiesCargoDetails = () => {
  const {
    noticeV2ClientAorCO: {
      isLoading,
      sendData,
      isLoadingImage,
      photosPreLoaded,
      imageUploadError,
      selectTypeNotice,
    },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const notice = selectTypeNotice as NoticeDetailAuctionModel;
  const auctionBetweenCitiesData = sendData.auctionBetweenCities;
  const tipoAvisoId = notice?.tipoaviso?.id;
  const inappropiateImage = imageUploadError === ERROR_IMAGE_INAPPROPIATE;

  const [images, setImages] = useState<File[]>([]);
  const [errorSendCode, setErrorSendCode] = useState<IFormDatos>({
    cargoDescription: "",
  });
  const [form, setForm] = useState<IFormDatos>({
    cargoDescription: auctionBetweenCitiesData.cargoDescription || "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "cargoDescription") {
      const newValue = validateValueInput(e.target.value);

      if (e.target.value.length <= maxLengthReference) {
        setForm({
          ...form,
          [e.target.name]: newValue,
        });
      }
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) return;

    const data = { ...auctionBetweenCitiesData, ...form };

    const ruteToSendData = {
      key: getFirstStepNoticeType(tipoAvisoId),
      value: getFirstStepNoticeTypeInterface(tipoAvisoId, data),
    };

    const values = { data: ruteToSendData, tipoAvisoId, images };

    dispatch(fillSendDataNoticeThunk(values)).then(() => {
      handleNextRute();
    });
  };

  const handleNextRute = () => {
    dispatch(
      getNextRoute(navigate, {
        valueToReplace: `${pathname.split("/")?.[3]}`,
        valueToSearch: ":id",
      })
    );
  };

  const handleOnChangeImages = (
    imgs: File[],
    name?: string,
    deleted?: boolean
  ) => {
    if (!!name && !!deleted) {
      const newArrayPhotos = photosPreLoaded.filter(
        (item) => item.name !== name
      );

      dispatch(setPhotosPreLoaded(newArrayPhotos));
    }

    setImages(imgs);
  };

  const validateForm = () => {
    let error = false;
    const errorForm: IFormDatos = {
      cargoDescription: "",
    };

    if (!form.cargoDescription) {
      errorForm.cargoDescription = REQUIRED_DESCRIPTION_LOAD;
      error = true;
    }

    setErrorSendCode(errorForm);
    return error;
  };

  const disabledValidate = () => {
    const maxLengthDescription =
      form?.cargoDescription?.length > MAX_CHARRACTERS;
    const conditions = [form.cargoDescription, !maxLengthDescription];
    return conditions.some((condition) => !condition);
  };

  const handleCloseAlert = () => {
    dispatch(setImageUploadError(""));
  };

  const handleShowAlert = () => inappropiateImage;

  return (
    <Form onSubmit={handleSubmit}>
      {handleShowAlert() && (
        <Alert
          color={inappropiateImage ? "error" : "warning"}
          sx={{ mb: 3, p: 2 }}
          severity={inappropiateImage ? "error" : "warning"}
          action={
            inappropiateImage && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  handleCloseAlert();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )
          }
        >
          {inappropiateImage ? ERROR_IMAGE_INAPPROPIATE : textAlert}
        </Alert>
      )}
      <Input
        {...inputDescribe}
        value={form.cargoDescription}
        onChange={handleChange}
        label={<LabelInputRequired str={inputDescribe.label} />}
        error={!!errorSendCode.cargoDescription}
        helperText={errorSendCode.cargoDescription}
      />

      <LoadMultiPhotos
        initialPhotos={photosPreLoaded}
        title={textImages}
        onChangeFiles={handleOnChangeImages}
      />

      <ButtomLoader
        sx={{ marginTop: "16px" }}
        type="submit"
        isLoading={isLoading || isLoadingImage}
        disabled={disabledValidate() || isLoading || isLoadingImage}
      >
        Siguiente
      </ButtomLoader>
    </Form>
  );
};

export default AuctionBtnCitiesCargoDetails;
