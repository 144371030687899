import {
  myOffertsToDirversAdapter,
  responseMyRequestsToClientsAdapter,
} from '@/adapters/store/myNoticeAdapter';
import { USER_TOKEN_STORAGE } from '@/consts/defaultConsts';
import { BodyNoticesActivesAndHistoryDTO } from '@/interfaces/noticeInterface';
import { PhotoProfileDTO } from '@/interfaces/userInterface';
import { TAgreementToAcceptBody } from '@/models/userAuth/agrementToAccept';
import { UsuarioAPI } from '@/models/userAuth/user';

import { getV2OffertsSolicitudService } from '@/services/customerRequestServices';
import { CreateVehicleDTO } from '@/services/interfaces/vehicleInterface';
import { removeLocalrStorage } from '@/services/localstorageServices';
import { getV2OffertsAvisoServices } from '@/services/negotiationServices';
import { TNotificacionesBody } from '../../models/userAuth/notification';
import {
  changeProfilePhoneServices,
  createVehicleServices,
  deleteVehicleServices,
  endUserRegistrationServices,
  getAgreementsToAccept,
  getProfileVehicleByIdServices,
  getUserByIdServices,
  postNotificationsServices,
  postVehiclesByUserServices,
  saveFormDataUserServices,
  savePhotoProfileServices,
  updatePhotoVehicleServices,
  updatePhotoVehicleTarjetaServices,
  updatePhotosVehicleServices,
  updateProfileDataServices,
  updateVehicleServices,
  uploadDocumentIdentificationServices,
  uploadPropertyCardServices,
} from '../../services/userServices';
import { getMessaggeError } from '../auth/utils';
import { resetLogout } from '../noticeRedux/noticeSlice';
import { AppDispatch, RootState } from '../store';
import {
  createFormDataClientAdapter,
  createFormDataUserAdapter,
} from './adapters/userAdapter';
import {
  logout,
  setAgreementToAccepts,
  setError,
  setErrorMsg,
  setListVehicles,
  setLoading,
  setLoadingPagination,
  setNotifications,
  setSelectedVehicleList,
  setSuccessMsg,
  setUser,
  setVehicles,
} from './userSlice';
import {
  ClientFormDataRegister,
  UserFormDataRegisterThunk,
} from '@/components/form/formPerfilTransportista/FormPerfilTransportista';
import { UserFormDataUpdateThunk } from '@/pages/user/interfaces/interfaces';

export const getUserByIdTunhk = (id: string, isSavedVehicle?: boolean) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      const {
        user: { selectedVehicle },
        vehicle: { savedVehicle },
      } = getState();

      const { usuarioData } = await getUserByIdServices(id);
      const [{ vehiculos, ...usuario }] = usuarioData?.usuario;
      dispatch(setUser(usuario));
      if (!selectedVehicle) {
        dispatch(
          postVehiclesByUserThunk({
            status: ['COMPLETO', 'INCOMPLETO'],
          })
        );
      }
      if (isSavedVehicle) {
        dispatch(setVehicles([savedVehicle]));
      }
      return usuario as UsuarioAPI;
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

export const savePhotoProfileThunk = ({
  image,
  description,
}: PhotoProfileDTO) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const { usuarioData } = await savePhotoProfileServices({
        image,
        description,
      });
      const { usuario } = usuarioData;

      dispatch(setUser(usuario));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const uploadDocumentIdentificationThunk = (image: string | File) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const {
        usuarioData,
        metadata: [result],
      } = await uploadDocumentIdentificationServices(image);

      const { usuario } = usuarioData;

      dispatch(setUser(usuario));
      dispatch(setSuccessMsg(result?.dato));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const uploadPropertyCardThunk = (
  image: string | File,
  imagePosterior: string | File
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const {
        metadata: [data],
      } = await uploadPropertyCardServices(image, imagePosterior);

      if (data.codigo !== '00') {
        throw new Error(data.dato);
      } else {
        dispatch(setError(''));
        dispatch(setErrorMsg(''));
        dispatch(setLoading(false));
      }
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const saveFormDataUserThunk = (body: UserFormDataRegisterThunk) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const adapterBody = createFormDataUserAdapter(body, 'register');
      const { usuarioData } = await saveFormDataUserServices(adapterBody);
      const { usuario } = usuarioData;

      dispatch(setUser(usuario));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

// Guarda los datos el usuario tipo cliente!!
export const saveFormDataClientThunk = (body: ClientFormDataRegister) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const adapterBody = createFormDataClientAdapter(body);

      const { usuarioData } = await saveFormDataUserServices(adapterBody);
      const { usuario } = usuarioData;

      dispatch(setUser(usuario));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const endUserRegistrationThunk = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const { usuarioData } = await endUserRegistrationServices();
      const { usuario } = usuarioData;

      dispatch(setLoading(false));
      dispatch(setUser(usuario));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const logoutThunk = () => {
  return (dispatch: AppDispatch) => {
    dispatch(logout());
    dispatch(resetLogout());
    removeLocalrStorage(USER_TOKEN_STORAGE);
  };
};

export const updateUserThunk = (body: UserFormDataUpdateThunk) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const adapterBody = createFormDataUserAdapter(body, 'update');
      const {
        usuarioData,
        metadata: [result],
      } = await updateProfileDataServices(adapterBody);
      const { usuario } = usuarioData;

      dispatch(setLoading(false));
      dispatch(setUser(usuario));
      dispatch(setSuccessMsg(result.dato));
      return result;
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const changeUserPhoneThunk = (nuevoNumero: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const body = {
        nuevoNumero,
      };
      const {
        metadata: [result],
      } = await changeProfilePhoneServices(body);
      return result;
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const getUserVehiclesThunk = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const body = {
        estados: ['COMPLETO', 'INCOMPLETO'],
      };
      const { vehiculosData } = await postVehiclesByUserServices(body);
      const { vehiculos } = vehiculosData;

      dispatch(setListVehicles(vehiculos));

      return vehiculos;
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const getUserVehiclesByIdThunk = (idVehicle: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const { vehiculoData } = await getProfileVehicleByIdServices(idVehicle);
      const { vehiculo } = vehiculoData;
      dispatch(setSelectedVehicleList(vehiculo));
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const deleteUserVehicleThunk = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const { vehiculoData } = await deleteVehicleServices(id);
      const { vehiculo } = vehiculoData;
      dispatch(setSelectedVehicleList(vehiculo));
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

// vehiculo
// {
//   "tipoVehiculo":19,
//   "placa":"AA411",
//   "capacidad":340,
// }

export const createVehicleThunk = (body: CreateVehicleDTO) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const { vehiculoData } = await createVehicleServices(body);
      const { vehiculo } = vehiculoData;
      dispatch(setSelectedVehicleList(vehiculo));
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setError(error));
      dispatch(setErrorMsg(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

export const updateVehicleThunk = (body: CreateVehicleDTO, id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const { vehiculoData } = await updateVehicleServices(body, id);
      const { vehiculo } = vehiculoData;
      dispatch(setSelectedVehicleList(vehiculo));
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setError(error));
      dispatch(setErrorMsg(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

type IUpdateVehicleImage = {
  fotoFrontal: string | File;
  fotoLateral: string | File;
  fotoPosterior: string | File;
};
export const updateVehicleImagesThunk = (
  body: IUpdateVehicleImage | {},
  idVehicle: string
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const { vehiculoData } = await updatePhotosVehicleServices(
        body,
        idVehicle
      );
      const { vehiculo } = vehiculoData;
      dispatch(setSelectedVehicleList(vehiculo));
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setError(error));
      dispatch(setErrorMsg(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

type IUVehicleImage = {
  foto: string | File;
  tipo: string;
};

export const updateVehicleImageThunk = (body: IUVehicleImage, id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const {
        vehiculoData,
        metadata: [result],
      } = await updatePhotoVehicleServices(body, id);
      const { vehiculo } = vehiculoData;
      dispatch(setSelectedVehicleList(vehiculo));

      return result?.dato;
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setError(error));
      dispatch(setErrorMsg(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

export const updateVehicleImageTarjetaThunk = (body: any, id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const { vehiculoData } = await updatePhotoVehicleTarjetaServices(
        body,
        id
      );
      const { vehiculo } = vehiculoData;
      dispatch(setSelectedVehicleList(vehiculo));
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

type TStatusVehicle = 'COMPLETO' | 'INCOMPLETO' | 'DESACTIVADO';

interface IPostVehiclesByUser {
  ordenamiento?: string[];
  status?: TStatusVehicle[];
  idsolicitud?: number;
  paginate?: boolean;
}

export const postVehiclesByUserThunk = ({
  ordenamiento,
  status,
  idsolicitud,
  paginate = true,
}: IPostVehiclesByUser) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const body = {
        page: 0,
        size: idsolicitud ? 0 : 12,
        ordenamiento: ordenamiento || ['id', 'desc'],
        estados: status || ['COMPLETO'],
        idsolicitud,
      };
      const { vehiculosData } = await postVehiclesByUserServices(
        paginate ? body : { estados: ['COMPLETO'] }
      );
      const { vehiculos } = vehiculosData;
      dispatch(setVehicles(vehiculos || []));

      return vehiculos;
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      dispatch(setErrorMsg(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

// notificaciones
export const getListaNotificacionesThunk = ({
  page,
  size,
  ordenamiento,
}: TNotificacionesBody) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    if (page === 0) {
      dispatch(setLoading(true));
    } else {
      dispatch(setLoadingPagination(true));
    }
    try {
      const {
        user: { notifications },
      } = getState();
      const body = {
        page,
        size,
        ordenamiento,
      };
      const { notificaciones, currentPage, totalPages, totalItems } =
        await postNotificationsServices(body);
      dispatch(
        setNotifications({
          currentPage,
          totalPages,
          totalItems,
          notificaciones:
            currentPage === 0
              ? notificaciones
              : [...notifications, ...notificaciones],
        })
      );
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setLoading(false));
      dispatch(setLoadingPagination(false));
      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

// agreement to accetp
export const getAgreementsToAcceptThunk = ({
  page,
  size,
  ordenamiento,
}: TAgreementToAcceptBody) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    if (page === 0) {
      dispatch(setLoading(true));
    } else {
      dispatch(setLoadingPagination(true));
    }
    try {
      const {
        user: { agreementToAccepts },
      } = getState();
      const body = {
        page,
        size,
        ordenamiento,
      };
      const {
        acuerdosporaceptarData: {
          acuerdosporaceptar,
          currentPage,
          totalPages,
          totalItems,
        },
      } = await getAgreementsToAccept(body);
      dispatch(
        setAgreementToAccepts({
          currentPage,
          totalPages,
          totalItems,
          acuerdosporaceptar:
            currentPage === 0
              ? acuerdosporaceptar
              : [...agreementToAccepts, ...acuerdosporaceptar],
        })
      );
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setLoading(false));
      dispatch(setLoadingPagination(false));
      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};
// getV2OffertsAvisoServices
export const getV2OffertsAvisoThunk = (
  body: BodyNoticesActivesAndHistoryDTO
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    if (body.page === 0) {
      dispatch(setLoading(true));
    } else {
      dispatch(setLoadingPagination(true));
    }
    try {
      const {
        user: { agreementToAccepts },
      } = getState();

      const response = await getV2OffertsAvisoServices(body);
      const { error, ...agreementData } = myOffertsToDirversAdapter(
        response,
        agreementToAccepts,
        body.page
      );
      if (!error) {
        dispatch(setAgreementToAccepts(agreementData));
      }
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setError(error));
      throw new Error(error.message || '');
    } finally {
      dispatch(setLoading(false));
      dispatch(setLoadingPagination(false));
    }
  };
};

export const getMyRequestsToClientsThunk = (
  body: BodyNoticesActivesAndHistoryDTO
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    if (body.page === 0) {
      dispatch(setLoading(true));
    } else {
      dispatch(setLoadingPagination(true));
    }
    try {
      const {
        user: { agreementToAccepts },
      } = getState();

      const response = await getV2OffertsSolicitudService(body);
      const { error, ...agreementData } = responseMyRequestsToClientsAdapter(
        response,
        agreementToAccepts,
        body.page
      );

      if (!error) {
        dispatch(setAgreementToAccepts(agreementData));
      }
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setError(error));
      throw new Error(error.message || '');
    } finally {
      dispatch(setLoading(false));
      dispatch(setLoadingPagination(false));
    }
  };
};
