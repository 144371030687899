import { Box, Grid, GridProps, Skeleton } from '@mui/material';
import { FC } from 'react';
interface Props extends GridProps {
  quantity?: number;
}
const defaultQuantity = 6;

const SkeletonCardRequest: FC<Props> = ({ quantity, ...props }) => {
  return (
    <>
      {[...Array(quantity || defaultQuantity)].map((_, index) => (
        <Grid item key={index} {...props}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Skeleton variant="rounded" height="334px"
              sx={{
                width:{
                  xs: "328px",
                  sm: "385px",
                }
              }}
            />
          </Box>
        </Grid>
      ))}
    </>
  );
};

export default SkeletonCardRequest
