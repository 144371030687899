import { Typography, styled } from "@mui/material";
import React from "react";

const Text = styled(Typography)(
  ({ theme }) => `
    font-weight: ${theme.typography.placeholder.fontWeight};
    line-height: ${theme.typography.label2.lineHeight};
    position: absolute;
    right: 0px;
    top: 0px;
  `
);

const StateText = ({ state, color }: { state?: string; color?: string }) => {
  if (!state) return <></>;

  return <Text variant="caption" color={color}>{state}</Text>;
};

export default StateText;
