import useDebounce from "@/hooks/useDebounse";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { UsuarioAPI } from "@/models/userAuth/user";
import { ISelectCity } from "@/store/FilterServices/filterReduxInterface";
import { setSelectCity } from "@/store/FilterServices/filterSlice";
import { setEmptyCitiesByTerm } from "@/store/locationRedux/citiesSlice";
import { getCitiesByPaisAndTermThunk } from "@/store/locationRedux/citiesThunks";
import { Autocomplete, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, TextField } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useEffect, useRef, useState } from "react";
import { ButtonDropdown } from "./styleAutoCompleteSelectCity";

const AutoCompleteSelectCity = () => {
  const {
    cities: { citiesByTerm, isLoading },
    filter: { selectedCity },
    user: { user },
  } = useAppSelector((state) => state);

  const [showCities, setShowCities] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const anchorRef = useRef<HTMLDivElement>(null);
  const inputValueDebounse = useDebounce(inputValue, 500);

  const dispatch = useAppDispatch();

  useEffect(() => {
    handleFilterByTerm();
  }, [inputValueDebounse]);

  const handleFilterByTerm = () => {
    if (inputValue?.length >= 3) {
      // dispatch(getCitiesByTermThunk(inputValue));
      dispatch(getCitiesByPaisAndTermThunk((user as UsuarioAPI)?.ciudad?.idpais.toString() || "1", inputValue));
    }
  };
  const handleCloseCities = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setShowCities(false);
  };

  useEffect(() => {
    dispatch(setEmptyCitiesByTerm());
  }, []);

  const toggleShowCities = () => {
    setShowCities(!showCities);
  };

  const handleSelectCity = (city: ISelectCity) => {
    dispatch(setSelectCity(city));
  };

  const handleOnInputChange = async (value: string) => {
    setInputValue(value.replace(/[^A-Za-z ÁÉÍÓÚáéíóúÑñ,]/g, ""));
  };
  return (
    <>
      <ButtonGroup variant="text" aria-label="split button" ref={anchorRef}>
        <ButtonDropdown variant="h6" onClick={toggleShowCities}>
          <>
            {(selectedCity as ISelectCity)?.nombre || "Lima"}
            <i className="icon-small-arrow-down" />
          </>
        </ButtonDropdown>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
          width: "300px",
          padding: "10px",
          backgroundColor: "#fff",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
          height: "auto",
        }}
        open={showCities}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseCities}>
                <Autocomplete
                  id="combo-box-demo"
                  options={citiesByTerm}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cambiar ciudad"
                      autoFocus
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                  loading={isLoading}
                  loadingText="Cargando..."
                  onInputChange={(event, value) => handleOnInputChange(value)}
                  getOptionLabel={(option) => option.nombre}
                  renderOption={(props, option, { inputValue }) => {
                    const matches = match(option.nombre, inputValue);
                    const parts = parse(option.nombre, matches);

                    return (
                      <li {...props} onClick={() => handleSelectCity(option)}>
                        <div>
                          {parts.map((part, index) => (
                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                              {part.text}
                            </span>
                          ))}
                        </div>
                      </li>
                    );
                  }}
                  noOptionsText={inputValue?.length >= 3 ? "No se encontraron resultados" : "Ingresa al menos 3 caracteres"}
                />
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default AutoCompleteSelectCity;
