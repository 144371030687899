import axios, { AxiosInstance } from "axios";
import { BASE_URL_CUSTOMER_REQUES } from "@/config/config";
import { getLocalStorage } from "../localstorageServices";
import { USER_TOKEN_STORAGE } from "@/consts/defaultConsts";

export const controllerInstanceCustomerRequest = new AbortController();

const instanceCustomerRequest: AxiosInstance = axios.create({
  baseURL: BASE_URL_CUSTOMER_REQUES,
  signal: controllerInstanceCustomerRequest.signal,
});

instanceCustomerRequest.interceptors.request.use((request) => {
  const token = getLocalStorage(USER_TOKEN_STORAGE);
  if (!token) return request;

  request.headers = {
    Authorization: `Bearer ${token}`,
  };

  return request;
});

export { instanceCustomerRequest };
