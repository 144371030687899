import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import SkeletonEnvironment from "@/components/skeletons/SkeletonEnvironment";
import { maxLengthMeters } from "@/consts/noticeConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { customerRequestObjectTypes } from "@/pages/customerRequest/shared/consts/customerRequestConsts";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import {
  setMetraje,
  setUpdatelistAmbientes,
} from "@/store/customerRequestRedux/customerRequestSlice";
import {
  getListCustomerRequestTypesAmbientesThunk
} from "@/store/customerRequestRedux/customerRequestThunk";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { FormControl, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Ambientes from "./Ambientes";

const FormDetallesMoving = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { ambientes, isLoading, selectedTypeRequest, metraje } = useAppSelector(
    (state) => state.customerRequest
  );

  const [metros, setMetros] = useState<number | string>(metraje);

  useEffect(() => {
    if (ambientes.length === 0) {
      dispatch(getListCustomerRequestTypesAmbientesThunk());
    }
  }, []);

  const handleUpdatedAmbientes = (idAmbiente: number, cantidad: number) => {
    const newAmbientes = ambientes.map((ambiente) => {
      if (ambiente.id === idAmbiente) {
        return {
          ...ambiente,
          cantidad,
        };
      }
      return ambiente;
    });
    dispatch(setUpdatelistAmbientes(newAmbientes));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (+value >= 1 && +value.length <= maxLengthMeters) {
      setMetros(value);
      dispatch(setMetraje(value));
    } else if (value === "") {
      setMetros(value);
      dispatch(setMetraje(value));
    }
  };

  const handleDisabledButton = () => {

    // validar que ambientes tenga al menos un ambiente con cantidad mayor a 0
    const isAmbientes = ambientes && ambientes?.some((ambiente) => ambiente.cantidad > 0);
    if (!isAmbientes) {
      return true;
    }
    if (metros === "") {
      return true;
    }
    return false;
  };

  const handleOnSubmit = () => {
    if (isLoading) return;
      handleNextRute();
  };

  const handleNextRute = () => {
    const valueToReplace = customerRequestObjectTypes[selectedTypeRequest.id];
    dispatch(
      getNextRoute(navigate, { valueToSearch: ":typeRequest", valueToReplace })
    );
  };

  if(isLoading) return <SkeletonEnvironment/>

  return (
    <>
      <Typography variant="label1">
      ¿Qué ambientes vas a mudar?
      </Typography>
      <Stack
        gap="16px"
        sx={{
          marginBottom: "16px",
          marginTop: "10px",
        }}
      >
        {ambientes.map((ambiente) => (
          <Ambientes
            key={ambiente.id}
            title={ambiente.nombre}
            idAmbiente={ambiente.id}
            cantidad={ambiente.cantidad}
            onChange={handleUpdatedAmbientes}
          />
        ))}
      </Stack>
      <Typography variant="label1">Area</Typography>
      <FormControl sx={{ marginTop: "16px" }} fullWidth>
        <Input
          name="metraje"
          label={<LabelInputRequired str="Área de tu inmueble (m²)" />}
          sx={{ marginBottom: "16px" }}
          variant="standard"
          value={metros}
          placeholder="Metros cuadrados de tu casa/oficina"
          // type="number"
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
          InputProps={{
            inputProps: { 
              min: 0,
              inputMode: "numeric",
              maxLength: 5,
             },
          }}
        />
      </FormControl>
      <Grid item sx={{ marginTop: "16px" }}>
        <ButtomLoader
          buttomProps={{
            disabled: handleDisabledButton(),
          }}
          onClick={handleOnSubmit}
        >
          siguiente
        </ButtomLoader>
      </Grid>
    </>
  );
};

export default FormDetallesMoving;
