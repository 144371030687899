import { secondFontFamily } from "@/styles/theme";
import { Box, styled, Typography } from "@mui/material";

export const SpanRequired = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.error.main};
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
    padding-left: 5px;
  `
);

export const TextStyles = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.error.main};
    font-family: ${secondFontFamily};
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
  `
);

export const labelTextStyle = {
    fontFamily: secondFontFamily,
    fontWeight: 600,
}

const LabelInputRequired = ({
  str,
  color,
  sx,
  fontSize,
  disabled,
}: {
  str: string;
  color?: string;
  fontSize?: string;
  sx?: any;
  disabled?: boolean;
}) => {
  return (
    <Box sx={{ display: "flex" }}>
      <span style={{ ...labelTextStyle, ...sx, }}>
        {str}
      </span>
      <SpanRequired
        sx={{ color: color && color, fontSize: fontSize && fontSize }}
        variant="overline"
      >
        *
      </SpanRequired>
    </Box>
  );
};

export default LabelInputRequired