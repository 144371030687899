import HeaderSection from "@/components/headerSection/HeaderSection";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import LabelRequired from "@/components/utils/LabelRequired";
import {
  COUNTER_OFFERT_CONFIRMATION,
  EDIT_CO_COUNTER_OFFER_DRIVER,
} from "@/consts/acceptanceOffertPath";
import { ID_MOVING_FLOW, ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { ContainerArea } from "@/pages/createServices/suggestions/styleSuggestions";
import { cocounterOfferNoticeDataLayer } from "@/services/dataLayer/createNoticeNeg";
import { saveOffertByIdThunk } from "@/store/offertRedux/offert.thunk";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { theme } from "@/styles/theme";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Box,
  Button,
  Container,
  FormControl,
  TextField,
  TextFieldProps,
  Typography,
  styled,
  experimental_sx as sx,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  actionAcceptance,
  coCounterOfferFlow,
  editCounterOfferTitle,
} from "../consts/actionAcceptance";
import { actionButton } from "../globalButtons/config";
import { maxLengthPrice, withoutPriceDefault } from "@/consts/noticeConsts";
import { useGlobalButtons } from "../hooks/useGlobalButtons";
import BasicModal from "@/components/dialog/Notiffication";

const StyleCustomInput = styled(TextField)<TextFieldProps>(
  sx({
    width: "100%",
    "& .MuiFormLabel-root": {
      fontFamily: theme.typography.label3.fontFamily,
      fontSize: "16px",
      fontWeight: theme.typography.label3.fontWeight,
      lineHeight: theme.typography.label3.lineHeight,
      color: "#707070",
    },
    "& .MuiInputBase-input::placeholder": {
      fontFamily: theme.typography.placeholder.fontFamily,
      fontStyle: theme.typography.placeholder.fontStyle,
      fontSize: theme.typography.placeholder.fontSize,
      lineHeight: theme.typography.placeholder.lineHeight,
      fontWeight: theme.typography.placeholder.fontWeight,
      letterSpacing: theme.typography.placeholder.letterSpacing,
    },
    "& .MuiFormHelperText-root": {
      letterSpacing: 0,
    },
  })
);

const ButtonAccept = styled(Button)(
  sx({
    heigth: "44px",
    backgroundColor: "#FF6700",
    borderRadius: "44px",
    mt: "5px",
    "&:hover": {
      backgroundColor: "#FF6700",
    },
  })
);

interface TypeOfFlow {
  id?: number;
  item?: string;
  placeholder: string;
  label: string;
  headerSectionTitle?: string;
}

const emptyTypeOfFlow = {
  id: 0,
  item: "",
  label: "",
  placeholder: "",
  HeaderSectionTitle: "",
};

const servicePriceText = "S/ ______";

const FeeCounterOffert = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [assistant, setAssistant] = useState("");

  const [disableButton, setDisableButton] = useState(false);
  const [editCounterOfferState, setEditCounterOfferState] = useState(false);
  const [hiddenItem, setHiddenItem] = useState(false);
  const [servicePrice, setServicePrice] = useState<any>(servicePriceText);
  const [valuePointOrTime, setValuePointOrTime] = useState<number>(0);
  const [typeOfFlow, setTypeOfFlow] = useState<TypeOfFlow>(emptyTypeOfFlow);
  const { idOffert = "" } = useParams();
  const { handleManageModalPriceChanged } = useGlobalButtons()

  const currentURL = pathname
    .replace(/[0-9]/g, "")
    .replace("//", "/:idOffert/");

  const {
    user: { user },
    offert: { savedOffert, isLoading },
  } = useAppSelector((state) => state);

  const counter_offert_confirmation_url = COUNTER_OFFERT_CONFIRMATION.replace(
    ":idOffert",
    idOffert
  );

  const noticeType = savedOffert?.avisoNegociacionAviso.tipoaviso.id;
  const isMoving = noticeType === ID_MOVING_FLOW;
  const typeOfFlowCounterOffer = coCounterOfferFlow[noticeType as number];
  const secondFlow = actionAcceptance[2].id;
  const toPoint = savedOffert?.puntos;
  const counterOfferPrice = savedOffert?.preciocontraoferta as number;
  const userId = (user as IUser).id;
  const typeNotice = savedOffert?.avisoNegociacionAviso?.tipoaviso
    ?.nombre as string;

  useEffect(() => {
    let data: any = coCounterOfferFlow[typeOfFlowCounterOffer.id];

    if (typeOfFlowCounterOffer.id === secondFlow) {
      let valueSecondtFlow = savedOffert?.horas || 0;
      setHiddenItem(true);
      if (!!toPoint) {
        data = coCounterOfferFlow[typeOfFlowCounterOffer.id].point;
        valueSecondtFlow = savedOffert?.puntos || 0;
      } else {
        data = coCounterOfferFlow[typeOfFlowCounterOffer.id].time;
      }
      setValuePointOrTime(valueSecondtFlow);
    }
    setTypeOfFlow(data);

    if (currentURL === EDIT_CO_COUNTER_OFFER_DRIVER) {
      setEditCounterOfferState(true);
      setAssistant(counterOfferPrice.toString() as string);
    }
  }, []);

  const handleOnChangeAssistant = ({
    currentTarget,
  }: ChangeEvent<HTMLInputElement>) => {
    const { value } = currentTarget;

    if (value.length >= 1) setDisableButton(true);
    if (value.length < 1) {
      setDisableButton(false);
      setServicePrice(servicePriceText);
    }
    if (value.length >= maxLengthPrice) {
      return "";
    }

    if (value.length === 1) {
      setAssistant(value.replace(/[^0-9]/g, ""));
      setServicePrice(valuePointOrTime * Number(assistant));
    } else if (value.length === 2 && value === "00") {
      setAssistant("0");
      setServicePrice(valuePointOrTime * Number(assistant));
    } else {
      setAssistant(value.replace(/^(0+)/g, "").replace(/[^0-9]/g, ""));
      setServicePrice(valuePointOrTime * Number(assistant));
    }

    setServicePrice(valuePointOrTime * Number(value));
  };

  const data = {
    idOffer: Number(idOffert),
    idAccion: actionButton[2][2] as number,
    precioContraoferta: Number(assistant)
  }
  

  // En esta funcion se le envia al thunk el ID, LA ACCIÓN ID Y EL PRECIO DE LA CO-CONTRAOFERTA
  const handleSubmit = () => {
    if (editCounterOfferState) {
      dispatch(
        saveOffertByIdThunk(data)
      ).then(() =>
        navigate(counter_offert_confirmation_url, { replace: true })
      );
    } else {
      let total = Number(assistant);
      if (typeOfFlowCounterOffer.id === secondFlow && !hiddenItem) {
        total = Number(servicePrice);
      }

      const data = {
        idOffer: Number(idOffert),
        idAccion: actionButton[2][2] as number,
        preciocontraoferta: total,
        precioactual: savedOffert?.precio,
      }

      dispatch(
        saveOffertByIdThunk(data)
      ).then((value) => {
        if (!!value.flgpreciocambiado) {
          handleManageModalPriceChanged(() => handleGoBackPage());
        }else{
          cocounterOfferNoticeDataLayer(userId, typeNotice);
        navigate(counter_offert_confirmation_url, { replace: true });
        }

      });
    }
  };

  const handleGoBackPage = () => {
    navigate(-1);
  };

  if (editCounterOfferState)
    return (
      <Container maxWidth="xs" sx={{ mt: "35px" }}>
        <HeaderSection
          icon="icon-arrow-left--primary"
          title={editCounterOfferTitle.title}
          subTitle=""
          onClick={handleGoBackPage}
        />
        <ContainerArea>
          <form onSubmit={() => {}}>
            <SectionIcon icon="icon-money" />

            <Box sx={{ mt: 2 }}>
              <FormControl sx={{ marginY: "16px", width: "100%" }}>
                <StyleCustomInput
                  id="standard-basic"
                  name="assistant"
                  label={editCounterOfferTitle.label || ""}
                  value={assistant}
                  variant="standard"
                  placeholder={editCounterOfferTitle.placeholder || ""}
                  onChange={handleOnChangeAssistant}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    required: true,
                  }}
                />
              </FormControl>
            </Box>

            <ButtonAccept
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              disabled={!disableButton}
            >
              ENVIAR
            </ButtonAccept>
          </form>
        </ContainerArea>
      </Container>
    );

  return (
    <Container maxWidth="xs">
      <HeaderSection
        icon="icon-arrow-left--primary"
        title={typeOfFlow.headerSectionTitle || "Contraoferta"}
        subTitle=""
        onClick={handleGoBackPage}
      />
      <ContainerArea>
        <form onSubmit={() => {}}>
          <SectionIcon icon="icon-money" />
          {!isMoving && (
            <Box sx={{ display: "flex" }}>
              <ErrorOutlineIcon sx={{ color: "#0288D1", mr: 2 }} />
              <Typography>Puedes contraofertar una vez</Typography>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Box sx={{ display: "flex", mt: 2 }}>
              <Typography sx={{ fontWeight: "900", mr: 1, fontSize: "14px" }}>
                Origen:
              </Typography>
              <Typography sx={{ fontSize: "14px" }}>
                {savedOffert?.origenTexto2 || savedOffert?.origenTexto}
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ fontWeight: "900", mr: 1, fontSize: "14px" }}>
                Destino:
              </Typography>
              <Typography sx={{ fontSize: "14px" }}>
                {savedOffert?.avisoNegociacionAviso?.tipoaviso?.id ===
                ID_MULTI_DESTINY
                  ? savedOffert?.destinoTexto || savedOffert?.destinoTexto2
                  : savedOffert?.destinoTexto2 || savedOffert?.destinoTexto}
              </Typography>
            </Box>
            {hiddenItem && (
              <Box sx={{ display: "flex", mb: 2 }}>
                <Typography sx={{ fontWeight: "900", mr: 1, fontSize: "14px" }}>
                  {typeOfFlow.item}:
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {valuePointOrTime}
                </Typography>
              </Box>
            )}

            <FormControl sx={{ marginY: "16px", width: "100%" }}>
              <StyleCustomInput
                id="standard-basic"
                name="assistant"
                label={<LabelRequired str={typeOfFlow.label} />}
                value={assistant}
                variant="standard"
                placeholder={typeOfFlow.placeholder || ""}
                onChange={handleOnChangeAssistant}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  required: true,
                }}
              />
            </FormControl>

            {hiddenItem && (
              <Box sx={{ display: "flex", mb: 2, mt: 2 }}>
                <Typography sx={{ fontWeight: 900, mr: 1, fontSize: "14px" }}>
                  Precio del servicio:
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {valuePointOrTime * Number(assistant) === 0
                    ? withoutPriceDefault
                    : `S/ ${valuePointOrTime * Number(assistant)}`}
                </Typography>
              </Box>
            )}
          </Box>

          <ButtonAccept
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            disabled={!disableButton || isLoading}
          >
            ENVIAR
          </ButtonAccept>
        </form>
      </ContainerArea>
      <BasicModal goBack/>
    </Container>
  );
};

export default FeeCounterOffert;
