import {
  Box,
  Typography,
  styled,
  TextField,
  TextFieldProps,
  experimental_sx as sx,
  Button,
  IconButton,
  Card,
} from '@mui/material';
import { theme } from '@/styles/theme';

export const TitleV2 = styled(Typography)(
  ({ theme }) => `
      font-weigth: 700;
      font-size: 18px;
      color: ${theme.palette.text.secondary};
      
    `
);

export const SubTitleV2 = styled(Typography)(
  ({ theme }) => `
      font-weight: ${theme.typography.body1.fontWeight};
      font-size: ${theme.typography.body1.fontSize};
      line-height: ${theme.typography.body1.lineHeight};
      color: ${theme.palette.text.secondary};
      font-family: ${theme.typography.body1.fontFamily};
    `
);

export const ContainerHeaderV2 = styled(Box)(
  ({ theme }) => `
    box-shadow: ${theme.palette.customShadow.contrastText};
    padding: 14px 0;
    box-sizing: border-box;
  `
);

export const TitleSectionV2 = styled(TitleV2)(
  ({ theme }) => `
    margin-top: 0;
    color: ${theme.palette.colorText.main};
    font-weight: bold 
  `
);

export const SubTitleSectionV2 = styled(SubTitleV2)(
  ({ theme }) => `
    margin-top: 0;
    margin-bottom: 0;
    font-size: ${theme.typography.body2.fontSize};
    line-height: ${theme.typography.body2.lineHeight};
    color: ${theme.palette.colorText.main};

  `
);

export const StyleCustomInputV2 = styled(TextField)<TextFieldProps>(
  sx({
    width: '100%',
    '& .MuiFormLabel-root': {
      fontFamily: theme.typography.label3.fontFamily,
      fontSize: '16px',
      fontWeight: theme.typography.label3.fontWeight,
      lineHeight: theme.typography.label3.lineHeight,
      color: '#707070',
    },
    '& .MuiInputBase-input::placeholder': {
      fontFamily: theme.typography.placeholder.fontFamily,
      fontStyle: theme.typography.placeholder.fontStyle,
      fontSize: theme.typography.placeholder.fontSize,
      lineHeight: theme.typography.placeholder.lineHeight,
      fontWeight: theme.typography.placeholder.fontWeight,
      letterSpacing: theme.typography.placeholder.letterSpacing,
    },
    '& .MuiFormHelperText-root': {
      letterSpacing: 0,
    },
  })
);

export const ButtonAcceptV2 = styled(Button)(
  sx({
    heigth: '44px',
    backgroundColor: '#FF6700',
    borderRadius: '44px',
    mt: '5px',
    '&:hover': {
      backgroundColor: '#FF6700',
    },
  })
);

export const PeoplesContentV2 = styled('div')(
  () => `
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: space-between;
        height: 64px;
        border-bottom: 1px solid #E2E2E2;
        padding: 14px 14px 14px 0;
    `
);

export const ButtonsCountV2 = styled('div')(
  () => `
        display: flex;
        gap: 14px;
        align-items: center;
    `
);

export const ButtonPeoplesV2 = styled(IconButton)(
  () => `
        width: 36px;
        height: 36px;
        border: 1px solid #000000;
        color: #000000;
        font-size: 24px;
        align-items: center;
        justify-content: center;
    `
);

export const CustomCardV2 = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  minWidth: '275px',
  padding: '8px',
  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: '8px',
  minHeight: '100px',
}));
