import { theme } from "@/styles/theme";
import {
  Box,
  IconButton,
  experimental_sx as sx,
  styled,
  Typography,
} from "@mui/material";

export const ContainerTotal = styled(Box)(() => ({
  "@media only screen and (min-width: 899px)": {
    padding: "32px 0",
  },
}));

export const Container = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  width: "100%",
  maxWidth: "328px",
  margin: "0 auto",
  "@media only screen and (min-width: 899px)": {
    padding: "16px 0",
  },
}));

export const Title = styled(Typography)(
  sx({
    fontFamily: theme.typography.h6.fontFamily,
    fontWeight: theme.typography.h6.fontWeight,
    fontSize: theme.typography.h6.fontSize,
    lineHeight: theme.typography.h6.lineHeight,
  })
);

export const HeaderSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "20px",
  width: "100%",
  maxWidth: "360px",
  margin: "0 auto",
  marginBottom: "25px",
}));

export const Icon = styled(IconButton)(() => ({}));

export const Form = styled("form")(
  sx({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    margin: "0 auto",
  })
);
