import Input from "@/components/inputCustom/Input";
import VehicleListModal from "@/components/modals/vehicleListModal/VehicleListModal";
import LabelRequired from "@/components/utils/LabelRequired";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { TipoVehiculo } from "@/models/vehicle/vehicleModel";
import { IVehicleList } from "@/store/userRedux/userReduxInterface";
import {
  getVehicleListThunk,
  selectedVehicleThunk,
} from "@/store/vehicle/vehicleThunk";
import { formatVehiclePlate } from "@/utils/helpers";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FormControl, InputAdornment, Typography, styled } from "@mui/material";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import ButtomLoader from "../buttonLoader/ButtomLoader";
const Description = styled(Typography)(
  ({ theme }) => `
      margin-top: 16px;
      color: ${theme.palette.text.secondary};
    `
);

interface IFormDatosVehicle {
  vehicle?: IVehicleList;
  onSubmit?: (e: any) => void;
  titleButton?: string;
  dataIsEmpty?: (isEmpty: boolean) => void;
  description?: string;
}
const FormDatosVehicle = ({
  vehicle,
  onSubmit,
  titleButton,
  dataIsEmpty,
  description,
}: IFormDatosVehicle) => {
  const [showModal, setShowModal] = useState(false);
  const [vehiclePlate, setVehiclePlate] = useState("");
  const [maximumLoad, setMaximumLoad] = useState("");

  const [errorMsg, setErrorMsg] = useState({
    vehiclePlate: "",
    maximumLoad: "",
  });

  const dispatch = useAppDispatch();
  const {
    vehicle: {
      vehicleList,
      selectedVehicle,
      vehiclePlate: vehiclePlateStorage,
      maximumLoad: maximumLoadStorage,
    },
    user: { isLoading },
  } = useAppSelector((state) => state);

  useEffect(() => {
    if (!vehicleList.length || !selectedVehicle?.id) {
      dispatch(getVehicleListThunk());
    }

    if (!!selectedVehicle?.id && !vehicle) {
      const vehiclePlate = formatVehiclePlate(vehiclePlateStorage);
      setVehiclePlate(vehiclePlate);
      setMaximumLoad(maximumLoadStorage);
    } else if (!!vehicle) {
      const vehiclePlate = formatVehiclePlate(vehicle?.placa);
      dispatch(selectedVehicleThunk(vehicle?.tipovehiculo));
      setVehiclePlate(vehiclePlate);
      setMaximumLoad(vehicle?.cargamaxima?.toString() || "");
    }
  }, [vehicle]);

  const handleOnSeletcTypeVehicle = (selected: TipoVehiculo) => {
    dispatch(selectedVehicleThunk(selected));
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOnchange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = currentTarget;
    if (name === "maximumLoad") {
      const formatValue = value.replace(/^(0+)/g, "").replace(/\D/g, "");

      setMaximumLoad(formatValue);
    } else {
      const formatValue = formatVehiclePlate(value);
      if (formatValue.length > 7) {
        setVehiclePlate(formatValue.slice(0, 7));
        return;
      }
      setVehiclePlate(formatValue);
    }
  };

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLoading) return;
    const upperPlate: string = vehiclePlate?.toUpperCase();


    if (!validTypeVehicleOptionalPlate) {
      if (upperPlate?.length < 7) {
        setErrorMsg({
          ...errorMsg,
          vehiclePlate: "La placa debe tener 6 caracteres",
        });
        return;
      }
    }
    if (Number(maximumLoad) < 5) {
      setErrorMsg({
        ...errorMsg,
        maximumLoad: "La carga máxima no puede ser menor a 5 kilos",
      });
      return;
    }

    if (Number(maximumLoad) > 50000) {
      setErrorMsg({
        ...errorMsg,
        maximumLoad: "La carga máxima no puede ser mayor a 50,000 kilos",
      });
      return;
    }

    setErrorMsg({
      vehiclePlate: "",
      maximumLoad: "",
    });

    const body = {
      tipoVehiculo: selectedVehicle?.id,
      placa: validTypeVehicleOptionalPlate() ? "" : upperPlate,
      capacidad: Number(maximumLoad),
    };
    onSubmit && onSubmit(body);
  };

  useEffect(() => {
    if (
      (selectedVehicle?.id !== vehicle?.tipovehiculo?.id ||
        vehiclePlate !== vehicle?.placa ||
        maximumLoad !== vehicle?.cargamaxima?.toString()) &&
      Object.keys(vehicle as IVehicleList).length > 0
    ) {
      dataIsEmpty && dataIsEmpty(true);
    } else if (
      (selectedVehicle?.id || vehiclePlate || maximumLoad) &&
      Object.keys(vehicle as IVehicleList).length === 0
    ) {
      dataIsEmpty && dataIsEmpty(true);
    } else {
      dataIsEmpty && dataIsEmpty(false);
    }
  }, [vehiclePlate, maximumLoad, selectedVehicle?.id, vehicle?.id]);


  const validTypeVehicleOptionalPlate = () => {
    // selectedVehicle?.flgPlacaObligatoria si es igual a 1 es requerido si es 0 es opcional
    return selectedVehicle && selectedVehicle?.flgPlacaObligatoria === 0;
  }

  const validateForm = () => {
    return (
      !selectedVehicle?.id ||
      (!validTypeVehicleOptionalPlate() && !vehiclePlate) ||
      !maximumLoad ||
      maximumLoad === "0" ||
      isLoading
    );
  };

  return (
    <>
      <VehicleListModal
        onSelect={handleOnSeletcTypeVehicle}
        open={showModal}
        onClose={handleCloseModal}
      />

      <form
        onSubmit={handleOnSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          margin: "0 auto",
        }}
      >
        <Description variant="body1">{description}</Description>

        <FormControl>
          <Input
            error={false}
            id="vehicle"
            label={<LabelRequired str="Tu vehículo" />}
            value={selectedVehicle?.nombre}
            variant="standard"
            placeholder="Elige el tipo de vehículo"
            onChange={() => setShowModal(true)}
            onClick={() => setShowModal(true)}
            helperText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {" "}
                  <ArrowDropDownIcon />{" "}
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>

        <FormControl>
          <Input
            id="vehiclePlate"
            name="vehiclePlate"
            label={
              validTypeVehicleOptionalPlate() ? "Placa del vehículo" : <LabelRequired str="Placa del vehículo" />}
            value={validTypeVehicleOptionalPlate() ? "" : vehiclePlate}
            variant="standard"
            placeholder="Ingresa la  placa o matrícula del vehículo"
            onChange={handleOnchange}
            onKeyDown={(e) => /[+-., ]$/.test(e.key) && e.preventDefault()}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              required: true,
              maxLength: 7,
              sx: {
                textTransform: "uppercase",
                "&.MuiInputBase-input::placeholder": {
                  textTransform: "none",
                },
              },
            }}
            error={!!errorMsg.vehiclePlate}
            helperText={errorMsg.vehiclePlate}
            disabled={validTypeVehicleOptionalPlate()}
          />
        </FormControl>

        <FormControl>
          <Input
            id="maximumLoad"
            name="maximumLoad"
            label={<LabelRequired str="Capacidad de carga máxima (kilos)" />}
            value={maximumLoad}
            variant="standard"
            placeholder="Ingresa el peso que puede cargar tu vehículo"
            onChange={handleOnchange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 5,
              inputMode: "numeric",
            }}
            error={!!errorMsg.maximumLoad}
            helperText={errorMsg.maximumLoad}
          />
        </FormControl>

        <ButtomLoader
          type="submit"
          sx={{ marginTop: 0 }}
          disabled={validateForm()}
          isLoading={isLoading}
        >
          {titleButton || "Siguiente"}
        </ButtomLoader>
      </form>
    </>
  );
};

export default FormDatosVehicle;
