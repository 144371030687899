import DetailsTextV2 from '@/components/DetailsTextV2/Index';
import SingleUserNegV2 from '@/pages/noticeV2Negotation/components/SingleUserNegV2';
import { ConfigDataNegotationV2 } from '@/pages/noticeV2Negotation/shared/hooks/useNoticeConfigDataV2';
import {
  getFormatMetroNegV2,
  getImageTruckInRatingNoticeNegV2,
  getInfoVehicleNegV2,
  getMaximumCapacity,
  getUrlAvatarProfileNoticeNeg,
  getUserInfoNoticeNegV2,
  renderTitleLongDestinyNegV2,
  renderTitleLongOriginNegV2,
  validUserNoticeNegV2,
} from '@/pages/noticeV2Negotation/shared/utils/utils';
import { Typography } from '@mui/material';

export const generalConfigData: ConfigDataNegotationV2[] = [
  {
    Component: SingleUserNegV2,
    props: {
      containerSx: { mt: '16px' },
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'urlProfile',
        defaultResult: '',
        callback: getUrlAvatarProfileNoticeNeg,
      },
      {
        property: 'cliente',
        componentProp: 'showTwoAvatars',
        defaultResult: '',
        callback: validUserNoticeNegV2,
      },
      {
        property: 'cliente',
        componentProp: 'urlTruck',
        defaultResult: '',
        callback: getImageTruckInRatingNoticeNegV2,
      },
      {
        property: 'cliente',
        componentProp: 'userInfo',
        defaultResult: '',
        callback: getUserInfoNoticeNegV2,
      },
    ],
  },
  {
    title: 'Detalles del servicio',
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
      sx: { marginTop: '10px', display: 'block' },
    },
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha',

      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },

  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
      containerSx: {
        alignItems: 'flex-start',
      },
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'aviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: renderTitleLongOriginNegV2,
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-dark',
      title: 'Destino',
      containerSx: {
        alignItems: 'flex-start',
      },
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'aviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: renderTitleLongDestinyNegV2,
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-metter',
      title: 'Área',
    },
    valid: [
      {
        property: 'metraje',
        componentProp: 'show',
        defaultResult: false,
      },

      {
        property: 'metraje',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'metraje',
        componentProp: 'origen',
        defaultResult: '',
        callback: getFormatMetroNegV2,
      },
    ],
  },

  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'aviso',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'aviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: getInfoVehicleNegV2,
      },
      {
        property: 'aviso',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getMaximumCapacity,
        callbakcParams: ['aviso', 'vehiculo'],
      },
    ],
  },
];

export const detailsConfigAccept: ConfigDataNegotationV2[] = [
  ...generalConfigData,
];

export const detailsConfigCounterOffer: ConfigDataNegotationV2[] = [
  ...generalConfigData,
];
