import {
  Toolbar,
  ToolbarProps,
  styled,
  experimental_sx as sx,
} from "@mui/material";
import { FC } from "react";

interface Props extends ToolbarProps {
  children: React.ReactNode;
}

export const CustomToolBar = styled(Toolbar)(
  sx({
    width: "100%",
    minHeight: "64px",
    padding: { xs: 0, sm: 0, md: "0px 32px", xl: "0px" },
    gap: { xs: 0, sm: 0, md: "8px", lg: "8px" },
  })
);

const ToolBarHeader: FC<Props> = (props) => {
  const { children } = props;

  return <CustomToolBar disableGutters>{children}</CustomToolBar>;
};

export default ToolBarHeader;
