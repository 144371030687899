import { ISelectListItems } from '@/components/form/selectControl/SelectControlMulti';
import { InitialStateCustomerRequest } from '@/store/customerRequestRedux/customerRequestSlice';
import { ITipoInmuebles } from '@/store/offertRedux/offert.interface';

export type ModalTypeRequest = {
  type: 'origin' | 'destiny' | 'ciudad1' | 'ciudad2';
};
export const MODAL_TYPE_REQUEST: {
  origin: Extract<ModalTypeRequest['type'], 'origin'>;
  destiny: Extract<ModalTypeRequest['type'], 'destiny'>;
  ciudad1: Extract<ModalTypeRequest['type'], 'ciudad1'>;
  ciudad2: Extract<ModalTypeRequest['type'], 'ciudad2'>;
} = {
  origin: 'origin',
  destiny: 'destiny',
  ciudad1: 'ciudad1',
  ciudad2: 'ciudad2',
};
interface configFormRequest {
  origin: Destiny;
  destiny: Destiny;
}

interface Destiny {
  input1: Input;
  input2: Input;
  input3: Input;
  modal1: Input;
  modal2: Input;
}

interface Input {
  name: string;
  label: string;
  placeholder: string;
}

export const configFormRequest: configFormRequest = {
  origin: {
    input1: {
      name: MODAL_TYPE_REQUEST?.ciudad1,
      label: 'Ciudad de origen',
      placeholder: 'Ciudad de recojo',
    },
    input2: {
      name: MODAL_TYPE_REQUEST?.origin,
      label: 'Distrito de origen',
      placeholder: 'Distrito de recojo',
    },
    input3: {
      name: 'reference-origin',
      label: 'Referencia del origen',
      placeholder: 'Lugar conocido cerca a la dirección',
    },
    modal1: {
      name: 'city-origin',
      label: 'Ciudad de origen',
      placeholder: 'Ciudad de recojo',
    },
    modal2: {
      name: 'district-origin',
      label: 'Distrito de origen',
      placeholder: 'Distrito de recojo',
    },
  },
  destiny: {
    input1: {
      name: MODAL_TYPE_REQUEST?.ciudad2,
      label: 'Ciudad de destino',
      placeholder: 'Ciudad de entrega',
    },
    input2: {
      name: MODAL_TYPE_REQUEST?.destiny,
      label: 'Distrito de destino',
      placeholder: 'Distrito de entrega',
    },
    input3: {
      name: 'reference-destiny',
      label: 'Referencia del destino',
      placeholder: 'Lugar conocido cerca a la dirección',
    },
    modal1: {
      name: 'city-destiny',
      label: 'Ciudad de destino',
      placeholder: 'Ciudad de entrega',
    },
    modal2: {
      name: 'district-destiny',
      label: 'Distrito de destino',
      placeholder: 'Distrito de entrega',
    },
  },
};

export const modalTitleConfig: Record<string, string> = {
  origin: 'Ingresa tu origen',
  destiny: 'Ingresa tu destino',
  ciudad1: 'Ingresa tu origen',
  ciudad2: 'Ingresa tu destino',
};

export const ElevatorOptions = [
  {
    id: 1,
    nombre: 'Si',
  },
  {
    id: 2,
    nombre: 'No',
  },
  {
    id: 3,
    nombre: 'Sólo para algunas cosas',
  },
];

export const configSelectOrInput = (
  application: InitialStateCustomerRequest,
  items: ISelectListItems[]
): ISelectListItems[] => {
  const { flgCiudadoEntreciudades } = application;
  const res: ISelectListItems[] = [];
  items.forEach((item, i) => {
    if (item.offertType === flgCiudadoEntreciudades) {
      res.push(item);
    }
  });
  if (Number(res[0]?.value) === 0) {
    return [res[0]];
  }

  if (Number(res[0].value) === 1) {
    return [res[0]];
  }

  return res;
};

export const adapterConfigInmueble = (
  application: InitialStateCustomerRequest,
  formInmueble: any,
  handleChange: (e: any) => void,
  tipoInmuebles: ITipoInmuebles[]
) => {
  const { flgCiudadoEntreciudades } = application;
  const { idInmueble, piso, elevador } = formInmueble;
  return {
    inmueble: true,
    listInmueble: configSelectOrInput(application, [
      {
        type: 'select',
        label: 'Tipo de inmueble', // "Tipo de inmueble"
        options: tipoInmuebles || [],
        required: true,
        value: idInmueble,
        name: 'idInmueble',
        onChange: handleChange,
        offertType: flgCiudadoEntreciudades,
      },
      {
        type: 'input',
        label: 'Piso',
        name: 'piso',
        placeholder: '# de piso de tu departamento/oficina ',
        required: true,
        onChange: handleChange,
        value: piso,
        offertType: flgCiudadoEntreciudades,
      },
      {
        type: 'select',
        label: 'Uso del ascensor',
        required: true,
        options: ElevatorOptions,
        name: 'elevador',
        value: elevador,
        onChange: handleChange,
        offertType: flgCiudadoEntreciudades,
      },
    ]),
  };
};
