import ConfirmacionCambio from '@/pages/confirmacionCambio/ConfirmacionCambio';
import {
  NOTICE_EDIT,
  NOTICE_EDIT_CONDITIONS_AUCTION,
  NOTICE_EDIT_CONDITIONS_BETWEEN_CITIES,
  NOTICE_EDIT_CONDITIONS_IN_CITY,
  NOTICE_EDIT_CONDITIONS_MOVING,
  NOTICE_EDIT_CONDITIONS_MULTI_DESTINY,
  NOTICE_EDIT_DATE_AUCTION,
  NOTICE_EDIT_DATE_BETWEEN_CITIES,
  NOTICE_EDIT_DATE_IN_CITY,
  NOTICE_EDIT_DATE_MOVING,
  NOTICE_EDIT_DATE_MULTI_DESTINY,
  NOTICE_EDIT_PRICE_AUCTION,
  NOTICE_EDIT_PRICE_BETWEEN_CITIES,
  NOTICE_EDIT_PRICE_IN_CITY,
  NOTICE_EDIT_PRICE_MOVING,
  NOTICE_EDIT_PRICE_MULTI_DESTINY,
  NOTICE_EDIT_VEHICLE_AUCTION,
  NOTICE_EDIT_VEHICLE_BETWEEN_CITIES,
  NOTICE_EDIT_VEHICLE_IN_CITY,
  NOTICE_EDIT_VEHICLE_MOVING,
  NOTICE_EDIT_VEHICLE_MULTI_DESTINY,
  NOTICE_MESSAGE_SENT,
  NOTICE_OFFERT,
  NOTICE_RENEW_AUCTION,
  NOTICE_RENEW_BETWEEN_CITIES,
  PROFILE_NOTICE_LIST_PATH,
  PROFILE_NOTICE_OFF,
} from '../consts/urlPaths';

import FeeMultiCity from '@/pages/createServices/fee/FeeMultiCity';
import NoticeEdit from '@/pages/noticeV2Edition/edition/NoticeEdit';
import VehicleEdit from '@/pages/noticeV2Edition/vehicleEdition/VehicleEdit';
import NoticeDateEdit from '@/pages/profile/pages/noticeDateEdit/NoticeDateEdit';
import NoticeList from '@/pages/profile/pages/noticeList/NoticeList';
import NoticeOff from '@/pages/profile/pages/noticeOff/NoticeOff';
import NoticeOffert from '@/pages/profile/pages/noticeOffert/NoticeOffert';
import { RouterProps } from '@/router/router';
import Conditions from '../../conditionEdition/Conditions';
import FeeV2 from '../../priceEdition/Fee';
import BodyFeeInCity from '../../priceEdition/containers/BodyFeeInCity';
import BodyFeeIntercity from '../../priceEdition/containers/BodyFeeIntercity';
import BodyFeeIntercitySale from '../../priceEdition/containers/BodyFeeIntercitySale';
import BodyFeeMovingCities from '../../priceEdition/containers/BodyFeeMovingCities';
import { VehicleEditAuction } from '../../vehicleEdition/VehicleEditAuction';

const noticesSentMessage = NOTICE_MESSAGE_SENT.map((path) => ({
  path,
  Component: ConfirmacionCambio,
  requiredSession: true,
}));

const noticesOffert = NOTICE_OFFERT.map((path) => ({
  path,
  Component: NoticeOffert,
  requiredSession: true,
}));

const noticesEdit = NOTICE_EDIT.map((path) => ({
  path,
  Component: NoticeEdit,
  requiredSession: true,
}));

export const profileNoticeRouter: RouterProps[] = [
  {
    path: PROFILE_NOTICE_LIST_PATH,
    Component: NoticeList,
    requiredSession: true,
  },
  {
    path: NOTICE_EDIT_VEHICLE_IN_CITY,
    Component: VehicleEdit,
    requiredSession: true,
  },
  {
    path: NOTICE_EDIT_VEHICLE_MULTI_DESTINY,
    Component: VehicleEdit,
    requiredSession: true,
  },
  {
    path: NOTICE_EDIT_VEHICLE_BETWEEN_CITIES,
    Component: VehicleEdit,
    requiredSession: true,
  },
  {
    path: NOTICE_EDIT_VEHICLE_AUCTION,
    Component: VehicleEditAuction,
    requiredSession: true,
  },
  {
    path: NOTICE_EDIT_VEHICLE_MOVING,
    Component: VehicleEdit,
    requiredSession: true,
  },

  {
    path: NOTICE_EDIT_DATE_IN_CITY,
    Component: NoticeDateEdit,
    requiredSession: true,
  },
  {
    path: NOTICE_EDIT_DATE_MULTI_DESTINY,
    Component: NoticeDateEdit,
    requiredSession: true,
  },
  {
    path: NOTICE_EDIT_DATE_BETWEEN_CITIES,
    Component: NoticeDateEdit,
    requiredSession: true,
  },
  {
    path: NOTICE_EDIT_DATE_AUCTION,
    Component: NoticeDateEdit,
    requiredSession: true,
  },
  {
    path: NOTICE_EDIT_DATE_MOVING,
    Component: NoticeDateEdit,
    requiredSession: true,
  },
  // Reenovar
  {
    path: NOTICE_RENEW_BETWEEN_CITIES,
    Component: NoticeDateEdit,
  },
  {
    path: NOTICE_RENEW_AUCTION,
    Component: NoticeDateEdit,
  },
  {
    path: NOTICE_EDIT_CONDITIONS_IN_CITY,
    Component: Conditions,
    requiredSession: true,
  },
  {
    path: NOTICE_EDIT_CONDITIONS_MULTI_DESTINY,
    Component: Conditions,
    requiredSession: true,
  },
  {
    path: NOTICE_EDIT_CONDITIONS_BETWEEN_CITIES,
    Component: Conditions,
    requiredSession: true,
  },
  {
    path: NOTICE_EDIT_CONDITIONS_AUCTION,
    Component: Conditions,
    requiredSession: true,
  },
  {
    path: NOTICE_EDIT_CONDITIONS_MOVING,
    Component: Conditions,
    requiredSession: true,
  },

  {
    path: NOTICE_EDIT_PRICE_IN_CITY,
    Component: FeeV2,
    requiredSession: true,
    children: [
      {
        index: true,
        Component: BodyFeeInCity,
      },
    ],
  },
  {
    path: NOTICE_EDIT_PRICE_MULTI_DESTINY,
    Component: FeeMultiCity,
    requiredSession: true,
  },
  {
    path: NOTICE_EDIT_PRICE_BETWEEN_CITIES,
    Component: FeeV2,
    requiredSession: true,
    children: [
      {
        index: true,
        Component: BodyFeeIntercity,
      },
    ],
  },
  {
    path: NOTICE_EDIT_PRICE_AUCTION,
    Component: FeeV2,
    requiredSession: true,
    children: [
      {
        index: true,
        Component: BodyFeeIntercitySale,
      },
    ],
  },
  {
    path: NOTICE_EDIT_PRICE_MOVING,
    Component: FeeV2,
    requiredSession: true,
    children: [
      {
        index: true,
        Component: BodyFeeMovingCities,
      },
    ],
  },
  {
    path: PROFILE_NOTICE_OFF,
    Component: NoticeOff,
    requiredSession: true,
  },
  ...noticesEdit,
  ...noticesSentMessage,
  ...noticesOffert,
];
