import { ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import { useAppSelector } from "@/hooks/useReducer";
import { SelectCity } from "@/store/locationRedux/selectLocation";
import { SelectNotice } from "@/store/noticeRedux/noticeSlice";
import { styled, Typography } from "@mui/material";

const TextZoneKey = styled(Typography)(
  ({ theme }) => `
    color: ${theme.typography.body1.color};
    font-family: ${theme.typography.h6.fontFamily};
    font-weight: 700;
    margin-bottom: 16px
  `
);

const TextZoneValue = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.body2.fontSize};
    line-height: ${theme.typography.body2.lineHeight};
    margin-left: 7px;
  `
);

type ExtraProps = {
  title: string;
  value: string;
};

type Props = {
  origintitle?: string;
  destinytitle?: string;
  origin: string;
  destiny: string;
  extra?: ExtraProps;
  hiddenData?: boolean;
};

const SectionOriginDestiny = ({
  origintitle = "Origen",
  destinytitle = "Destino",
  origin,
  destiny,
  extra,
  hiddenData,
}: Props) => {
  const {
    selectLocation: { selectedDestiny, selectedOrigin },
    customerRequest,
    notice: { selectNotice },
  } = useAppSelector((state) => state);
  const isMultidestiny =
    (selectNotice as SelectNotice)?.id === ID_MULTI_DESTINY;
    const currentOrigin = selectedOrigin as SelectCity
    const currentDestiny = selectedDestiny as SelectCity

  const handleOrigin = () => {
    if (isMultidestiny) {
      const originName =
      currentOrigin.nombre ||
        customerRequest.selectedOrigin.nombre;
      const multiDestiny = !!currentOrigin.detalle
        ? `(${origin})`
        : "";
      if (origin.length > 0) return `${originName} ${multiDestiny}`;
    }

    return origin;
  };

  const handleDestiny = () => {
    if (isMultidestiny) {
      const destinyName = currentDestiny.nombre;
      const multiDestiny = !!currentDestiny.detalle
        ? `(${destiny})`
        : "";
      if (destinyName && origin.length > 0)
        return `${destinyName} ${multiDestiny}`;
    }

    return destiny;
  };

  return (
    <>
      <TextZoneKey variant="body2">
        {origintitle}:
        <TextZoneValue variant="caption" sx={{color: "black"}}>{handleOrigin()}</TextZoneValue>
      </TextZoneKey>

      {!hiddenData && (
        <>
          <TextZoneKey
            variant="body2"
            sx={{ marginTop: "8px", fontWeight: 700 }}
          >
            {destinytitle}:
            <TextZoneValue variant="caption" sx={{color: "black"}}>{handleDestiny()}</TextZoneValue>
          </TextZoneKey>
          {extra && (
            <TextZoneKey variant="body2" sx={{ marginTop: "8px" }}>
              {`${extra.title}:`}
              <TextZoneValue variant="caption">{extra.value}</TextZoneValue>
            </TextZoneKey>
          )}
        </>
      )}
    </>
  );
};

export default SectionOriginDestiny;
