import { CustomCard } from "@/components/Card/CustomCard";
import { MyNotice } from "@/models/notice/noticeModels";
import CardBody from "./CardBody";
import CardFooter from "./CardFooter";
import CardHeader from "./CardHeader";

const NoticeListCard = ({ data }: { data: MyNotice }) => {
  return (
    <CustomCard>
      <CardHeader data={data} />

      <CardBody data={data} />

      <CardFooter data={data} />
    </CustomCard>
  );
};

export default NoticeListCard;
