import { ID_OFFER } from "@/consts/defaultConsts";
import { useAppSelector } from "@/hooks/useReducer";
import { Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";

const emptyData = {
  negotationId: 0,
  origin: "",
  destiny: "",
  price: 0,
  car: {
    id: 0,
    nombre: "",
  },
};

const FormSuccessMoving = () => {
  const {
    noticeNegotationRequest: { savedDataNogotation },
  } = useAppSelector((state) => state);

  return (
    <Grid container sx={{ mt: 2 }}>
      <Grid item xs={12} sx={{ display: "flex" }}>
        <Stack sx={{ mr: 1 }}>
          <i className="icon-vehicle--truck"></i>
        </Stack>
        <Typography variant="body2" sx={{ color: "#757575", fontSize: "12px" }}>
          Mudanzas
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" sx={{ fontSize: "18px" }}>
          De {savedDataNogotation?.solicitud?.origenTexto} a{" "}
          {savedDataNogotation?.solicitud?.destinoTexto}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex" }}>
        <Typography variant="body2" sx={{ color: "#757575", fontSize: "12px" }}>
          Tu cotización
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
        <Stack sx={{ mr: 1 }}>
          <i className="icon-car-medium"></i>
        </Stack>
        <Typography variant="h4" sx={{ fontSize: "14px", mr: 2 }}>
          Vehículo:
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "16px", color: "#757575" }}>
          {savedDataNogotation?.vehiculo?.tipoVehiculo}{" "}
          {savedDataNogotation?.vehiculo?.placa}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
        <Stack sx={{ mr: 1 }}>
          <i className="icon-person-medium "></i>
        </Stack>
        <Typography variant="h4" sx={{ fontSize: "14px", mr: 2 }}>
          Ayudantes:
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "16px", color: "#757575" }}>
          {savedDataNogotation?.ayudantes}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontSize: "14px", mr: 2 }}>
          Precio por viaje
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h4" sx={{ fontSize: "22px", mr: 2 }}>
          {savedDataNogotation?.tipoSolicitudNegociacion?.id === ID_OFFER
            ? ""
            : "Cotizado por"}{" "}
          S/{savedDataNogotation?.precio}
        </Typography>
        <Typography variant="body2" sx={{ color: "#757575", fontSize: "12px" }}>
          Enviada el {savedDataNogotation?.fechaRegistro} a las{" "}
          {savedDataNogotation?.horaRegistro}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FormSuccessMoving;
