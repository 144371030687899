import Input from "@/components/inputCustom/Input";
import LabelRequired from "@/components/utils/LabelRequired";
import { resizeInputsProps } from "./callbacks";

export const configInCity = [
  [
    {
      styleRow: {
        marginTop: "16px",
      },
      columns: [
        {
          columnsProps: {
            lg: 4,
            md: 4,
            xs: 12,
            sx: { paddingRight: "15px", marginTop: { xs: "8px" } },
            propertyLink: "inputciudad",
            callback: resizeInputsProps,
          },
          component: Input,
          hideRegister: true,
          props: {
            fullWidth: true,
            label: <LabelRequired str="Ciudad" />,
            placeholder: "Ciudad",
            name: "city",
          },
        },
      ],
    },
  ],
  [
    {
      styleRow: {
        marginTop: "16px",
      },
      columns: [
        {
          columnsProps: {
            lg: 4,
            md: 4,
            xs: 12,
            sx: { paddingRight: "15px" },
            callback: resizeInputsProps,
          },
          component: Input,
          hideRegister: false,
          props: {
            fullWidth: true,
            label: <LabelRequired str="Origen" />,
            placeholder: "Origen",
            name: "origin",
          },
        },
      ],
    },
  ],
];
