import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { ID_MOVING_FLOW } from "@/consts/defaultConsts";
import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import { useGlobalButtonsV2 } from "@/pages/noticeV2Negotation/shared/hooks/useGlobalButtons";
import { Container, styled, experimental_sx as sx, } from "@mui/material";

export const ContainerButtonsV2 = styled(Container)(
    sx({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      mt: 2,
      mb: 2,
    })
  );

export const DisabledCounterOfferBtn = () => {
  const { isLoadingNoticeV2, notice } = useGetData();
  const {

  } = useGlobalButtonsV2();
  const isMoving = notice?.idtipoaviso === ID_MOVING_FLOW;
  const counterOffetBtnText = isMoving ? "cotizar" : "contraofertar";

  return (
    <ContainerButtonsV2>
      { !isMoving &&
        <ButtomLoader
          fullWidth
          variant="contained"
          disabled
        >
          aceptar
        </ButtomLoader>
      }
      <ButtomLoader
        fullWidth
        variant={!isMoving ? "outlined": "contained"}
        type="submit"
        isLoading={false}
        disabled
        sx={{ borderColor: "#primary" }}
      >
        {counterOffetBtnText}
      </ButtomLoader>

      <ButtomLoader
        fullWidth
        variant="text"
        type="submit"
        isLoading={isLoadingNoticeV2}
        disabled
      >
        rechazar
      </ButtomLoader>
    </ContainerButtonsV2>
  );
};