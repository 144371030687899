import { NoticeV2NegType } from '@/store/noticeV2Negotiation/noticeV2NegotiationSlice';
import { Container, Grid } from '@mui/material';
import React from 'react';
import GlobalPriceFinished from '../GlobalPriceFinished';
import useFinishedLayoutHook from './hook';
import ButtomLoader from '@/pages/noticeV2/components/buttonLoader/ButtomLoader';
import ArrowBack from '@/pages/viewService/city/components/ArrowBack';
import ButtonRedirectRating from '@/components/buttonRedirectRating/ButtonRedirectRating';

interface Props {
    children: React.ReactNode;
}

const FinishedLayout = ({ children }: Props) => {

    const {
        matches,
        handleHeaderProps,
        notice,
        navigateNegociations,
        hasBackNavigate,
        handleNavigationBack,
        showBtnCalification,
    } = useFinishedLayoutHook();
    return (
        <Container maxWidth={matches ? "xl" : "xs"} sx={{
            mt: "16px",
            marginBottom: matches ? 0 : 4,
        }}>
            <ArrowBack show={hasBackNavigate} onClick={handleNavigationBack} />
            <Grid
                container
                spacing={0}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    pl: matches ? 20 : 0,
                    pr: matches ? 20 : 0,
                }}
            >
                <Grid xs={matches ? 7 : 12}>{children}</Grid>

                <Grid xs={matches ? 5 : 12} sx={{
                    padding:
                        matches ?
                            "0px 16px 0px 16px"
                            :
                            "0px"
                }}>
                    {<GlobalPriceFinished data={notice as NoticeV2NegType} />}
                    {
                        showBtnCalification ?
                            <ButtonRedirectRating isOwner={notice?.flgIsOwner}/>
                            :
                            <ButtomLoader
                                fullWidth
                                variant="outlined"
                                onClick={() => navigateNegociations()}
                                sx={{
                                    mt: 2,
                                }}
                            >
                                VER NEGOCIACIONES
                            </ButtomLoader>
                    }
                </Grid>
            </Grid>
        </Container>
    )
}

export default FinishedLayout