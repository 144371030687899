import { actionsToNegotation } from "@/consts/actions";
import { AUTH_LOGIN } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { noticeRequestAdapter } from "@/pages/noticeRequestFlow/adapters/noticeRequestFlowAdapters";
import { noticeNegotationRequestObjectTypes } from "@/pages/noticeRequestFlow/consts/noticeRequestFlowConsts";
import { initFlowCustomerRequestThunk } from "@/store/customerRequestRedux/customerRequestThunk";
import {
  setNoticeRequestData,
  setSelectNegotationTypeRequest,
} from "@/store/noticeNegotationRequestRedux/noticeNegRequestSlice";
import { initRouteFlowByIdNoticeThunk } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { replaceValue } from "@/utils/helpersActions";
import { useNavigate, useParams } from "react-router-dom";
import { CustomerRequest } from "../../../../models/customerRequest/customerRequestResponse";
import { ISolicitudViewDetailsResponse } from "@/models/customerRequest/requestDetailsVewResponse";

export const usePublicButtons = () => {
  const { id = "", typeRequest = "" } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { requestDetailsView } = useAppSelector((state) => state.customerRequest);
  const { user } = useAppSelector((state) => state.user);
  const requestDetails = requestDetailsView as ISolicitudViewDetailsResponse
  
  const typeNotice = typeRequest;

  /* handleCounterOfferButton: handler para el boton de oferta por el lado del transportista */
  const handleAcceptButton = () => {


    const driverAccept = actionsToNegotation[1];

    const result = noticeRequestAdapter(
      requestDetails,
      driverAccept.id
    );
    dispatch(setNoticeRequestData(result));
    dispatch(setSelectNegotationTypeRequest(driverAccept));
    let route = dispatch(
      initRouteFlowByIdNoticeThunk(
        driverAccept.id,
        "noticeNegotationRequestAccept"
      )
    );
    dispatch(initFlowCustomerRequestThunk());

    const nextRoute = replaceValue(
      route,
      ":typeRequest",
      typeNotice,
      ":idRequest",
      id
    );

    navigate(handlePrepareNextRouter(nextRoute));
  };

  /* handleCounterOfferButton: handler para el boton de cotraoferta por el lado del transportista */
  const handleCounterOfferButton = () => {
    const DriverCounterOffer = actionsToNegotation[2];

    const result = noticeRequestAdapter(
      requestDetails,
      DriverCounterOffer.id
    );

    dispatch(setNoticeRequestData(result));
    dispatch(setSelectNegotationTypeRequest(DriverCounterOffer));

    let route = dispatch(
      initRouteFlowByIdNoticeThunk(2, "noticeNegotationRequestCounterOffer")
    );
    dispatch(initFlowCustomerRequestThunk());

    const nextRoute = replaceValue(
      route,
      ":typeRequest",
      typeNotice,
      ":idRequest",
      id
    );

    navigate(handlePrepareNextRouter(nextRoute));
  };

  /* handleCounterOfferButton: handler para el boton de cotizar por el lado del transportista */
  const handleQuoteButton = () => {
    const driverAccept = actionsToNegotation[4];
 
    const result = noticeRequestAdapter(requestDetails, driverAccept.id);
    dispatch(setNoticeRequestData(result));
    dispatch(setSelectNegotationTypeRequest(driverAccept));
    let route = dispatch(
      initRouteFlowByIdNoticeThunk(
        driverAccept.id,
        "noticeNegotationRequestAccept"
      )
    );

    dispatch(initFlowCustomerRequestThunk());

    const nextRoute = replaceValue(
      route,
      ":typeRequest",
      typeNotice,
      ":idRequest",
      id
    );

    navigate(handlePrepareNextRouter(nextRoute));
  };
// offert express
  const handleOffertExpressButton = () => {
    const driverAccept = actionsToNegotation[4];
    const result = noticeRequestAdapter(requestDetails, driverAccept.id);
    dispatch(setNoticeRequestData(result));
    dispatch(setSelectNegotationTypeRequest(driverAccept));
    let route = dispatch(
      initRouteFlowByIdNoticeThunk(
        driverAccept.id,
        "noticeNegotationOffert"
      )
    );
    dispatch(initFlowCustomerRequestThunk());

    const nextRoute = replaceValue(
      route,
      ":typeSolicitud",
      noticeNegotationRequestObjectTypes[driverAccept.id],
      ":idSolicitud",
      id
    );

    navigate(handlePrepareNextRouter(nextRoute));
  }

  const handlePrepareNextRouter = (rute: string) => {
    if (Object.keys(user).length) {
      return rute;
    } else {
      return `${AUTH_LOGIN}?returnPath=${rute.split("/").slice(0, -1).join("/")}`;
    }
  };

  const objectToReturn: Record<number, () => void> = {
    1: handleAcceptButton,
    2: handleCounterOfferButton,
    4: handleQuoteButton,
    3: handleOffertExpressButton,
  };

  return {
    ...objectToReturn,
  };
};
