import FormClientCounterOffer from '@/components/form/formRequestNegotationFlow/formClientCounterOffer/FormClientCounterOffer';
import FormOffert from '@/components/form/formRequestNegotationFlow/formOffert/FormOffert';
import {
  NEGOTATION_ACCEPT_PATH,
  NEGOTATION_ACCEPT_SEND_PATH,
  NEGOTATION_AGREEMENT_COCOUNTEROFFER_MESSAGE_CLIENT,
  NEGOTATION_AGREEMENT_CONFIRMATION_CLIENT_PATH,
  NEGOTATION_AGREEMENT_CONFIRMATION_DRIVER_PATH,
  NEGOTATION_AGREEMENT_COUNTEROFFER_CLIENT,
  NEGOTATION_AGREEMENT_COUNTEROFFER_DRIVER,
  NEGOTATION_AGREEMENT_MESSAGE_CLIENT,
  NEGOTATION_AGREEMENT_MESSAGE_DRIVER,
  NEGOTATION_AGREEMENT_OFFER_CLIENT,
  NEGOTATION_AGREEMENT_OFFER_DRIVER,
  NEGOTATION_CLIENT_RECEIVE_ACEPTATION,
  NEGOTATION_CLIENT_RECEIVE_COUNTEROFFER,
  NEGOTATION_CLIENT_RECEIVE_REJECTION_COUNTEROFFER,
  NEGOTATION_CLIENT_RECEIVE_REJECTION_OFFERT,
  NEGOTATION_COCOUNTEROFFER_DRIVER,
  NEGOTATION_CONDITIONS_EDIT,
  NEGOTATION_COUNTEROFFER_CILENT_PATH,
  NEGOTATION_COUNTEROFFER_CLIENT,
  NEGOTATION_COUNTEROFFER_PATH,
  NEGOTATION_COUNTEROFFER_SEND_CLIENT_PATH,
  NEGOTATION_COUNTEROFFER_SEND_PATH,
  NEGOTATION_CO_COUNTEROFFERT_CLIENT,
  NEGOTATION_DATE_EDIT,
  NEGOTATION_DECLIME_COCOUNTEROFFERT_DRIVER,
  NEGOTATION_DECLINE_COCOUNTEROFFER_MESSAGE_CLIENT,
  NEGOTATION_DECLINE_COUNTEROFFER_CLIENT,
  NEGOTATION_DECLINE_COUNTEROFFER_DRIVER,
  NEGOTATION_DECLINE_MESSAGE_CLIENT,
  NEGOTATION_DECLINE_MESSAGE_DRIVER,
  NEGOTATION_DECLINE_OFFER_CLIENT,
  NEGOTATION_DECLINE_OFFER_DRIVER,
  NEGOTATION_DESTINY_EDIT,
  NEGOTATION_DRIVER_RECEIVE_ACCEPTATION_COUNTEROFFER,
  NEGOTATION_DRIVER_RECEIVE_ACCEPTATION_OFFER,
  NEGOTATION_DRIVER_RECEIVE_REJECTION_COUNTEROFFER,
  NEGOTATION_DRIVER_RECEIVE_REJECTION_OFFER,
  NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_CLIENT,
  NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_DRIVER,
  NEGOTATION_ENVIRONMENT_EDIT,
  NEGOTATION_LOAD_EDIT,
  NEGOTATION_MENU_PRINCIPAL_NOTICE,
  NEGOTATION_OFFER_PATH,
  NEGOTATION_OFFER_SEND_PATH,
  NEGOTATION_ORIGIN_EDIT,
  NEGOTATION_PRICE_EDIT,
  NEGOTATION_REJECTED_NEGOTATION_MESSAGE_CLIENT,
  NEGOTATION_REJECTED_NEGOTATION_MESSAGE_DRIVER,
  NEGOTATION_REMOVE_REQUEST,
  NEGOTATION_RENEWAL_SUCCESS,
  NEGOTATION_RENEW_REQUEST,
  NEGOTATION_RUTE_EDIT,
  NEGOTATION_TRANSPORTER_RECEIVE_ACEPTATION_DRIVER,
  NEGOTATION_TRANSPORTER_RECEIVE_COUNTEROFFERT_DRIVER,
} from '@/consts/negotationFlowPath';
import ConfirmacionCambio from '@/pages/confirmacionCambio/ConfirmacionCambio';
import { CreateRequestNegSuccess } from '@/pages/createRequestNegotiation/CreateRequestNegSuccess';
import RequestNegSuccessBody from '@/pages/createRequestNegotiation/CreateRequestNegSuccess/RequestNegSuccessBody';
import NegAccept from '@/pages/createRequestNegotiation/createAcceptRequest/NegAccept';
import FormAccept from '@/pages/createRequestNegotiation/createAcceptRequest/containers/FormAccept';
import NegCounterOffer from '@/pages/createRequestNegotiation/createCounterOfferRequest/NegCounterOffer';
import FormCounterOffer from '@/pages/createRequestNegotiation/createCounterOfferRequest/containers/FormCounterOffer';
import CancelNotice from '@/pages/customerRequest/application/components/CancelNotice';
import RenewRequestNegotation from '@/pages/customerRequest/components/RenewRequestNegotation/Index';
import DeclineNoticeRequest from '@/pages/noticeRequestFlow/components/DeclineNoticeRequest';
import { default as Conditions } from '@/pages/noticeRequestFlow/editNegotation/Conditions/Conditions';
import Load from '@/pages/noticeRequestFlow/editNegotation/Load/Load';
import Price from '@/pages/noticeRequestFlow/editNegotation/Price/Price';
import Rute from '@/pages/noticeRequestFlow/editNegotation/Rute/Rute';
import NegOffert from '@/pages/noticeRequestFlow/negOffert/NegOffert';
import NegAgreementConfirmation from '@/pages/noticeRequestFlow/negzAgreementConfirmation/NegAgreementConfirmation';
import RejectNotice from '@/pages/noticeRequestFlow/negzRejection/NegRejection';
import { RequestEdition } from '@/pages/requestEdition';
import FormEditConditions from '@/pages/requestEdition/containers/formConditions/FormEditConditions';
import FormEditLoad from '@/pages/requestEdition/containers/formLoad/FormEditLoad';
import FormEditDestinyMoving from '@/pages/requestEdition/containers/formLoad/formLoadEditMoving/FormEditDestinyMoving';
import FormEditPrice from '@/pages/requestEdition/containers/formPrice/FormEditPrice';
import FormEditRute from '@/pages/requestEdition/containers/formRute/FormEditRute';
import AgreementMessage from '@/pages/requestNegotiation/AgreementMessage';
import DeclineNegotation from '@/pages/requestNegotiation/declineNegotation/DeclineNegotation';
import NegClientReceiveResponse from '@/pages/requestNegotiation/negotiation/negClientReceiveCounterOffer/NegClientReceiveResponse';
import FormSuccess from '@/pages/requestNegotiation/negotiation/negSuccess/FormSuccess';
import { NegSuccessRequestNeg } from '@/pages/requestNegotiation/negotiation/negSuccess/NegSuccessRequestNeg';
import CoCounterOfferToDriver from '@/pages/requestNegotiation/negotiation/negzDriverReceiveCoCounterOffer/NegDriverReceiveCoCounterOffer';
import RequestNegFinishedAccept from '@/pages/requestNegotiation/requestNegFinished/FinishedAccept';
import RenderBodyNegAccept from '@/pages/requestNegotiation/requestNegFinished/FinishedAccept/RenderBodyNegAccept';
import RequestFinishedReject from '@/pages/requestNegotiation/requestNegFinished/FinishedReject';
import RenderBodyNegReject from '@/pages/requestNegotiation/requestNegFinished/FinishedReject/RenderBodyNegReject';
import { privateRuteWithLoginMiddleware } from '@/router/middleware/privateCommontMiddleware';
import { RouterProps } from '@/router/router';

export const noticeNegotationFlowRouter: RouterProps[] = [
  /* FLUJOS PARA QUE EL TRANSPORTISTA ACEPTE SOLICITUD O CONTRAOFERTE SOLICITUD DEL CLIENTE */
  {
    Component: NegAccept,
    path: NEGOTATION_ACCEPT_PATH,
    middleware: privateRuteWithLoginMiddleware,
    children: [
      {
        index: true,
        Component: FormAccept,
      },
    ],
  },
  {
    Component: NegCounterOffer,
    path: NEGOTATION_COUNTEROFFER_PATH,
    middleware: privateRuteWithLoginMiddleware,
    children: [
      {
        index: true,
        Component: FormCounterOffer,
      },
    ],
  },
  // express
  {
    Component: NegOffert,
    path: NEGOTATION_OFFER_PATH,
    middleware: privateRuteWithLoginMiddleware,
    children: [
      {
        index: true,
        Component: FormOffert,
      },
    ],
  },
  {
    Component: CreateRequestNegSuccess,
    path: NEGOTATION_ACCEPT_SEND_PATH,
    middleware: privateRuteWithLoginMiddleware,
    children: [
      {
        index: true,
        Component: RequestNegSuccessBody,
      },
    ],
  },
  {
    Component: CreateRequestNegSuccess,
    path: NEGOTATION_COUNTEROFFER_SEND_PATH,
    middleware: privateRuteWithLoginMiddleware,
    children: [
      {
        index: true,
        Component: RequestNegSuccessBody,
      },
    ],
  },
  {
    Component: NegSuccessRequestNeg,
    path: NEGOTATION_OFFER_SEND_PATH,
    middleware: privateRuteWithLoginMiddleware,
    children: [
      {
        index: true,
        Component: FormSuccess,
      },
    ],
  },

  /* CLIENTE RECIBE RESPUESTA DEL TRANSPORTISTA */

  {
    Component: NegClientReceiveResponse,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_CLIENT_RECEIVE_ACEPTATION,
  },
  {
    Component: NegClientReceiveResponse,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_CLIENT_RECEIVE_COUNTEROFFER,
  },
  {
    Component: NegClientReceiveResponse,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_TRANSPORTER_RECEIVE_ACEPTATION_DRIVER,
  },
  {
    Component: NegClientReceiveResponse,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_TRANSPORTER_RECEIVE_COUNTEROFFERT_DRIVER,
  },

  /* CLIENTE CO-CONTRAOFERTA LA CONTRAOFERTA DEL TRANSPORTISTA */

  {
    Component: CoCounterOfferToDriver,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_CO_COUNTEROFFERT_CLIENT,
  },
  {
    Component: NegCounterOffer,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_COUNTEROFFER_CLIENT,
    children: [
      {
        index: true,
        Component: FormCounterOffer,
      },
    ],
  },

  /* CLIENTE ACEPTA O RECHAZA OFERTA/CONTRAOFERTA DEL TRANSPORTISTA */

  {
    Component: AgreementMessage,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_AGREEMENT_MESSAGE_CLIENT,
  },
  {
    Component: DeclineNegotation,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_DECLINE_MESSAGE_CLIENT,
  },

  /* CLIENTE COCONTRAOFERTA AL TRANSPORTISTA */

  {
    Component: NegCounterOffer,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_COUNTEROFFER_CILENT_PATH,
    children: [
      {
        index: true,
        Component: FormClientCounterOffer,
      },
    ],
  },

  {
    Component: NegSuccessRequestNeg,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_COUNTEROFFER_SEND_CLIENT_PATH,
    children: [
      {
        index: true,
        Component: FormSuccess,
      },
    ],
  },

  /* VISTA TRANSPORTISTA ACEPTA O RECHAZA LA CO-CONTRAOFERTA DEL CLIENTE */

  {
    Component: AgreementMessage,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_AGREEMENT_MESSAGE_DRIVER,
  },
  {
    Component: DeclineNegotation,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_DECLINE_MESSAGE_DRIVER,
  },
  {
    // Component: RejectNotice,
    Component: RequestFinishedReject,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_DECLIME_COCOUNTEROFFERT_DRIVER,
    children: [
      {
        index: true,
        Component: RenderBodyNegReject,
      },
    ],
  },

  /* VISTA PARA EL CLIENTE: RECIBE ACEPTACION O RECHAZO DE SU CONTRAOFERTA */

  {
    Component: AgreementMessage,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_AGREEMENT_COCOUNTEROFFER_MESSAGE_CLIENT,
  },
  {
    // Component: RejectNotice,
    Component: RequestFinishedReject,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_DECLINE_COCOUNTEROFFER_MESSAGE_CLIENT,
    children: [
      {
        index: true,
        Component: RenderBodyNegReject,
      },
    ],
  },

  /* VISTA PARA EL TRANSPORTISTA SOBRE LA ACEPTACION DE LA OFERTA/CONTRAFERTA POR PARTE DEL CLIENTE  */

  {
    Component: AgreementMessage,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_DRIVER_RECEIVE_ACCEPTATION_OFFER,
  },

  {
    Component: AgreementMessage,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_DRIVER_RECEIVE_ACCEPTATION_COUNTEROFFER,
  },

  /* VISTA PARA EL TRANSPORTISTA SOBRE EL RECHAZO DE LA OFERTA/CONTRAFERTA POR PARTE DEL CLIENTE  */

  {
    Component: RejectNotice,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_DRIVER_RECEIVE_REJECTION_OFFER,
  },
  // cliente
  {
    Component: RejectNotice,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_CLIENT_RECEIVE_REJECTION_OFFERT,
  },

  {
    Component: RejectNotice,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_DRIVER_RECEIVE_REJECTION_COUNTEROFFER,
  },
  {
    Component: RejectNotice,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_CLIENT_RECEIVE_REJECTION_COUNTEROFFER,
  },

  /*VISTA DE LA COCONTRAOFERTA PARA EL TRANSPORTISTA*/

  {
    Component: CoCounterOfferToDriver,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_COCOUNTEROFFER_DRIVER,
  },

  /*VISTAS DE CONFIRMACION PARA CLIENTE Y TRANSPORTISTA*/

  {
    // Component: NegAgreementConfirmation,
    Component: RequestNegFinishedAccept,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_AGREEMENT_CONFIRMATION_CLIENT_PATH,
    children: [
      {
        index: true,
        Component: RenderBodyNegAccept,
      },
    ],
  },
  {
    // Component: NegAgreementConfirmation,
    Component: RequestNegFinishedAccept,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_AGREEMENT_CONFIRMATION_DRIVER_PATH,
    children: [
      {
        index: true,
        Component: RenderBodyNegAccept,
      },
    ],
  },

  // ========================================================================================================================================================================== //

  // ===================================================================
  //    SECCION PARA LA EDICIÓN DE LA SOLICITUD POR PARTE DEL CLIENTE
  // ===================================================================

  /* MENÚ DE OPCIONES PARA LA EDICIÓN DE LA SOLICITUD  */
  {
    Component: RequestEdition,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_MENU_PRINCIPAL_NOTICE,
  },

  /* EDICIÓN RUTA DEL SERVICIO */
  {
    Component: Rute,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_RUTE_EDIT,
    children: [
      {
        index: true,
        Component: FormEditRute,
      },
    ],
  },

  /* EDICIÓN RUTA DE LA CARGA Y VEHÍCULO */
  {
    Component: Load,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_LOAD_EDIT,
    children: [
      {
        index: true,
        Component: FormEditLoad,
      },
    ],
  },

  /* EDICIÓN CONDICIONES DEL SERVICIO */
  {
    Component: Conditions,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_CONDITIONS_EDIT,
    children: [
      {
        index: true,
        Component: FormEditConditions,
      },
    ],
  },

  /* EDICIÓN CONDICIONES DEL SERVICIO */
  {
    Component: Price,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_PRICE_EDIT,
    children: [
      {
        index: true,
        Component: FormEditPrice,
      },
    ],
  },

  // ===================================================================
  //    SECCION PARA LA EDICIÓN DE LA SOLICITUD DE MUDANZA
  // ===================================================================

  /* EDICIÓN DE LA FECHA */
  {
    Component: Rute,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_DATE_EDIT,
    children: [
      {
        index: true,
        Component: FormEditRute,
      },
    ],
  },

  /* EDICIÓN RDEL ORIGEN */
  {
    Component: Load,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_ORIGIN_EDIT,
    children: [
      {
        index: true,
        Component: FormEditLoad,
      },
    ],
  },

  /* EDICIÓN DEL DESTINO */
  {
    Component: Load,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_DESTINY_EDIT,
    children: [
      {
        index: true,
        Component: FormEditDestinyMoving,
      },
    ],
  },

  /* EDICIÓN CANTIDAD DE AMBIENTE*/
  {
    Component: Price,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_ENVIRONMENT_EDIT,
    children: [
      {
        index: true,
        Component: FormEditPrice,
      },
    ],
  },

  // ========================================
  //    SECCION PARA RENOVAR LA SOLICITUD
  // ========================================

  {
    Component: RenewRequestNegotation,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_RENEW_REQUEST,
  },
  {
    Component: ConfirmacionCambio,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_RENEWAL_SUCCESS,
  },

  // ========================================================================================================================================================================== //

  /* VISTAS DE RECHAZO POR EL LADO DEL PERFIL TANTO PARA CLIENTE COMO TRANSPORTISTA */
  {
    // Component: RejectNotice,
    Component: RequestFinishedReject,
    path: NEGOTATION_DECLINE_OFFER_CLIENT,
    middleware: privateRuteWithLoginMiddleware,
    children: [
      {
        index: true,
        Component: RenderBodyNegReject,
      },
    ],
  },
  {
    // Component: RejectNotice,
    Component: RequestFinishedReject,
    path: NEGOTATION_DECLINE_COUNTEROFFER_CLIENT,
    middleware: privateRuteWithLoginMiddleware,
    children: [
      {
        index: true,
        Component: RenderBodyNegReject,
      },
    ],
  },
  {
    // Component: RejectNotice,
    Component: RequestFinishedReject,
    path: NEGOTATION_DECLINE_OFFER_DRIVER,
    middleware: privateRuteWithLoginMiddleware,
    children: [
      {
        index: true,
        Component: RenderBodyNegReject,
      },
    ],
  },
  {
    // Component: RejectNotice,
    Component: RequestFinishedReject,
    path: NEGOTATION_DECLINE_COUNTEROFFER_DRIVER,
    middleware: privateRuteWithLoginMiddleware,
    children: [
      {
        index: true,
        Component: RenderBodyNegReject,
      },
    ],
  },

  /* VISTAS DE CONFIRMACIÓN POR EL LADO DEL PERFIL TANTO PARA CLIENTE COMO TRANSPORTISTA */
  {
    Component: NegAgreementConfirmation,
    path: NEGOTATION_AGREEMENT_OFFER_CLIENT,
    middleware: privateRuteWithLoginMiddleware,
  },
  {
    Component: NegAgreementConfirmation,
    path: NEGOTATION_AGREEMENT_COUNTEROFFER_CLIENT,
    middleware: privateRuteWithLoginMiddleware,
  },
  {
    Component: NegAgreementConfirmation,
    path: NEGOTATION_AGREEMENT_OFFER_DRIVER,
    middleware: privateRuteWithLoginMiddleware,
  },
  {
    Component: NegAgreementConfirmation,
    path: NEGOTATION_AGREEMENT_COUNTEROFFER_DRIVER,
    middleware: privateRuteWithLoginMiddleware,
  },

  // ========================================================================================================================================================================== //

  /* VISTAS CUANDO EL CLIENTE CANCELA LA SOLICITUD */

  {
    Component: CancelNotice,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_REMOVE_REQUEST,
  },

  {
    Component: DeclineNoticeRequest,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_REJECTED_NEGOTATION_MESSAGE_DRIVER,
  },

  {
    Component: DeclineNoticeRequest,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_REJECTED_NEGOTATION_MESSAGE_CLIENT,
  },

  {
    Component: NegSuccessRequestNeg,
    path: NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_DRIVER,
    middleware: privateRuteWithLoginMiddleware,
    children: [
      {
        index: true,
        Component: FormSuccess,
      },
    ],
  },

  {
    Component: NegSuccessRequestNeg,
    middleware: privateRuteWithLoginMiddleware,
    path: NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_CLIENT,
    children: [
      {
        index: true,
        Component: FormSuccess,
      },
    ],
  },
];
