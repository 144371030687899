import DynamicComponent from "@/pages/offerts/offertConfirmation/components/DynamicComponent";
import { useConfigData } from "@/pages/requestNegotiation/shared/hooks/useConfigData";
import { multidestinySuccessConfig } from "./config";
import { PriceSuccessMultidestiny } from "./PriceSuccessMultidestiny";


const FormSuccessMultidestiny = () => {
  const { handleInitConfig } = useConfigData();

  return (
    <>
        {multidestinySuccessConfig.map((item, i) => (
        <DynamicComponent key={`${i}`} {...item} {...handleInitConfig(item)} />
      ))}
      <PriceSuccessMultidestiny/>
    </>
  );
};

export default FormSuccessMultidestiny;
