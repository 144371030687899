import VehicleList from "@/components/modals/vehicleListModal/components/VehicleList";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import {
  EstimatedWeightAPI,
  TypeRequestLoadAPI,
} from "@/models/customerRequest/customerRequestTypes";
import { TipoVehiculo } from "@/models/vehicle/vehicleModel";
import {
  setSelectTypevehicle,
  setSelectedEstimatedWeightRequest,
  setSelectedTypeLoadRequest,
} from "@/store/customerRequestRedux/customerRequestSlice";
import React from "react";
import ListGenericCreateRequest from "./ListGenericCreateRequest";
import GenericList from "./WeightList";

export type OnSelectTypes =
  | TipoVehiculo
  | TypeRequestLoadAPI
  | EstimatedWeightAPI;

export type CompontType = "" | "weight" | "vehicle" | "typeLoad";

export interface ShowModalType {
  open: boolean;
  type: CompontType;
}

interface HandleValueDrawerProps {
  title: string;
  component: (props: any) => JSX.Element;
  data?: any;
  onSelect: (selected: OnSelectTypes) => void;
  defaultSelected: any;
}

interface Props {
  showModal: ShowModalType;
  setShowModal: React.Dispatch<React.SetStateAction<ShowModalType>>;
}

const HandleDrawer = (props: Props) => {
  const { showModal, setShowModal } = props;
  const dispatch = useAppDispatch();

  const {
    customerRequest: {
      selectedTypevehicle,
      selectedEstimatedWeightRequest,
      selectedTypeLoadRequest,
      listCustomerTypeLoadRequest,
      listCustomerEstimatedWeightRequest,
    },
  } = useAppSelector((state) => state);

  const handleCloseModal = () => {
    setShowModal({ type: "", open: false });
  };

  const handleOnSeletcTypeVehicle = (selected: OnSelectTypes) => {
    dispatch(setSelectTypevehicle(selected as TipoVehiculo));
    handleCloseModal();
  };

  const handleOnSeletEstimatedWeight = (selected: OnSelectTypes) => {
    dispatch(setSelectedEstimatedWeightRequest(selected as EstimatedWeightAPI));
    handleCloseModal();
  };

  const handleOnSeletLoad = (selected: OnSelectTypes) => {
    dispatch(setSelectedTypeLoadRequest(selected as TypeRequestLoadAPI));
    handleCloseModal();
  };


  const handleDataDrawer = () => {
    let value: HandleValueDrawerProps = {
      title: "Elegir vehiculo",
      component: VehicleList,
      onSelect: handleOnSeletcTypeVehicle,
      defaultSelected: selectedTypevehicle,
    };

    if (showModal.type === "typeLoad")
      value = {
        title: "Tipo de Carga",
        component: GenericList,
        data: listCustomerTypeLoadRequest,
        onSelect: handleOnSeletLoad,
        defaultSelected: selectedTypeLoadRequest,
      };

    if (showModal.type === "weight")
      value = {
        title: "Peso estimado",
        component: GenericList,
        data: listCustomerEstimatedWeightRequest,
        onSelect: handleOnSeletEstimatedWeight,
        defaultSelected: selectedEstimatedWeightRequest,
      };

    return value;
  };

  const { title, component, data, defaultSelected, onSelect } =
    handleDataDrawer();



  return (
    <ListGenericCreateRequest
      title={title}
      onSelect={onSelect}
      open={showModal.open}
      onClose={handleCloseModal}
      btnTittle={"Elegir"}
      defaultSelected={defaultSelected}
      component={component}
      data={data}
    />
  );
};

export default HandleDrawer;
