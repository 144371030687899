import { CONTACT_US_LANDING_PATH } from "@/consts/typeServicesPaths";
import Footer from "@/pages/footer/Footer";
import {
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Information, Title } from "./PoliticsPrivacyStyles";

const PoliticsPrivacy = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <>
      <Container
        maxWidth={matches ? "xl" : "xs"}
        sx={{ marginTop: "35px", mb: 8 }}
      >
        <Title sx={{ textAlign: "center" }}>POLÍTICA DE PRIVACIDAD</Title>

        <Information>
          El presente documento describe nuestro enfoque para tratar datos
          personales de los usuarios de PegaCargo. TINNTO S.A. es la entidad que
          proporciona PegaCargo y controla el procesamiento de datos personales.
        </Information>

        <Information>
          Esta política se aplica cuando utiliza sitios web, aplicaciones
          móviles, productos, contenidos o servicios de PegaCargo (denominado
          “Plataforma” o “PegaCargo”), o cuando se comunica con nosotros. La
          política no cubre cómo manejamos la información sobre nuestros
          candidatos, empleados o socios comerciales.
        </Information>

        <Information>
          Esta política se aplica donde quiera que PegaCargo esté disponible,
          excepto en países donde existe una política de privacidad específica
          de la jurisdicción. Cumpliremos con las leyes locales aplicables en
          relación con todas las prácticas descritas en esta política. Si hay
          una inconsistencia entre esta política y la ley local, cumpliremos con
          la ley local en la medida de la inconsistencia.
        </Information>

        <Information>
          Los usuarios que solicitan o reciben servicios de transporte de carga
          a través de PegaCargo se denominan “Usuarios”, y las personas que
          brindan estos servicios a los Usuarios se denominan “Transportistas”.
        </Information>

        <Information>¿Qué información recopilamos?</Information>

        <Information>
          Recopilamos tres categorías de información sobre usted:
        </Information>

        <List dense>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Información que usted proporciona.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Información que recopilamos automáticamente.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Información que obtenemos de otras fuentes.
              </Information>
            </ListItemText>
          </ListItem>
        </List>

        <Information>
          No recopilamos información sobre su origen racial o étnico, opiniones
          políticas o membresía de cualquier asociación política, creencias
          religiosas o filosóficas, membresía sindical, datos genéticos o datos
          relacionados con la vida u orientación sexuales de una persona.
        </Information>

        <Information>Información que proporcionas</Information>

        <Information>
          <strong>La información de registro del usuario</strong> puede incluir
          nombre, apellido, número de teléfono. Correo electrónico, país y
          ciudad de residencia. Cuando la ley lo permita o lo exija, podemos
          recopilar su imagen (foto del rostro - selfie) para controles de
          identidad, números de identificaciones nacional y cuentas de redes
          sociales por motivos de seguridad, así como números de contacto de
          emergencia, razón social, nombre comercial, número de registro de
          institucional, tributario o fiscal. Sin proporcionar esta información,
          no podrá utilizar PegaCargo ni algunas de sus funciones. Si lo desea
          también puede proporcionar su foto de perfil.
        </Information>

        <Information>
          <strong>La información del transportista</strong> puede incluir el
          nombre y apellido del conductor, foto de perfil, datos de la licencia
          de conducir, información sobre el vehículo registrado (tipo, marca,
          modelo, año de fabricación, color, datos de la tarjeta de propiedad o
          registro del bien, número de placa o matrícula, informe o certificado
          de inspección vehicular, fotografías del vehículo, documento de
          identidad (DNI, carnet de extranjería, pasaporte) comprobante de
          residencia, domicilio, fecha de nacimiento, sexo, número de póliza de
          seguro, derecho a trabajar, historial de conducción, información
          bancaria o de pago, razón social, nombre comercial, número de registro
          de institucional, tributario o fiscal, así como cualquier otro
          documento que puedan ser exigidos por la normativa aplicable. Cuando
          la ley lo permita o lo exija, podremos recopilar además verificaciones
          de antecedentes policiales, judiciales y/o penales. Esta información
          puede ser procesada por proveedores autorizados en nuestro nombre. Sin
          proporcionar dicha información no podrá registrarse como
          <strong> Transportista.</strong>
        </Information>

        <Information>
          <strong>Contenido generado por el usuario</strong> que usted elige
          cargar en PegaCargo, como comentarios, calificaciones y reseñas.
        </Information>

        <Information>
          <strong> La información en la correspondencia</strong> que nos envíe
          puede incluir mensajes de chat, correos electrónicos con archivos
          adjuntos, grabaciones de llamadas telefónicas que nos realice y los
          metadatos relacionados.
        </Information>

        <Information>Información que recopilamos automáticamente</Information>

        <Information>
          <strong>Información sobre la ubicación.</strong> Recopilamos datos de
          ubicación de los usuarios para permitir realizar viajes de transporte
          de carga, brindar asistencia al usuario, garantizar la seguridad,
          detectar fraudes y cumplir con los requisitos legales. Recopilamos
          información de ubicación (incluidas coordenadas GPS y datos Wifi)
          según la configuración de la aplicación PegaCargo y los permisos del
          dispositivo:
        </Information>

        <Information>Usuarios </Information>

        <Information>
          Recopilamos la ubicación precisa de su dispositivo cuando la
          aplicación se ejecuta en primer plano (aplicación abierta en pantalla)
          y cuando la aplicación se ejecuta en segundo plano (aplicación abierta
          pero no en pantalla) desde el momento en que solicita el servicio de
          transporte de carga, hasta que lo termina. Los usuarios no pueden
          utilizar la aplicación sin permitirle recopilar datos de ubicación
          precisos desde sus dispositivos móviles ingresando directamente la
          dirección o las coordenadas de recogida y entrega en la aplicación.{" "}
        </Information>

        <Information>Transportistas </Information>

        <Information>
          Recopilamos la ubicación precisa de su dispositivo cuando la
          aplicación se ejecuta en primer plano (aplicación abierta y en
          pantalla) y cuando ejecuta en segundo plano (aplicación abierta pero
          no en pantalla). Cabe resaltar que la ubicación se actualizará cada 50
          mts de movilidad registrada por el GPS, es decir, si el vehículo se
          mueve menos de 50 mts, no se verá reflejado en la app.{" "}
        </Information>

        <Information>
          <strong>Información de la transacción.</strong> Recopilaremos
          información de transacciones relacionadas con los servicios prestados
          a través de PegaCargo, incluido el monto cobrado, el medio de pago,
          información de la transacción de pago, fecha y hora en que se brindó
          el servicio, ruta y distancia recorrida.
        </Information>

        <Information>
          <strong>Información de uso.</strong> Recopilaremos datos sobre el uso
          de nuestra Plataforma, como fechas y horas de acceso, funciones o
          páginas visitadas, fallas de la Plataforma y otra actividad del
          sistema. Cuando usted brinda su consentimiento expreso, podemos
          recopilar grabaciones de sus actividades dentro de la aplicación.
        </Information>

        <Information>
          <strong>Información de comunicaciones.</strong> Contamos con botones
          de redirección hacia plataformas de terceros (WhatsApp) para que
          Usuarios y Transportistas puedan comunicarse entre sí. Por ende, no
          recopilamos la información que hayan compartido entre sí a través de
          otras plataformas.{" "}
        </Information>

        <Information>
          <strong>Información del dispositivo.</strong> Podríamos recopilar
          información sobre el dispositivo que utiliza para acceder a PegaCargo,
          como marca, modelo de su dispositivo, agente de usuario, dirección IP,
          operador de telefonía móvil, tipo de red, configuración de la zona
          horaria, configuración del idioma, identificadores de publicidad, tipo
          de navegador, sistema operativo, y su versión, parámetros de pantalla,
          estado de batería y aplicaciones instaladas que se pueden utilizar con
          fines de autentificación y antifraude. También podríamos recopilar
          datos de sensores móviles, como velocidad, dirección, altitud,
          aceleración, desaceleración y otros datos técnicos.
        </Information>

        <Information>
          <strong> ID del usuario y viaje.</strong> Le asignamos automáticamente
          una identificación de usuario cuando crea una cuenta en PegaCargo y
          una identificación del viaje para cada servicio de transporte de
          carga.
        </Information>

        <Information>Información que obtenemos de otras fuentes </Information>

        <Information>
          <strong>Servicios de terceros.</strong> Es posible que recibamos
          información sobre los usuarios de nuestros proveedores de servicios,
          que incluyen proveedores de verificación de antecedentes y de
          identidad, socios de seguros, proveedores de servicios financieros y
          proveedores de servicios de marketing. Si elige utilizar una
          aplicación de comunicación de terceros (como WhatsApp), podemos
          recopilar información de estos servicios.
        </Information>

        <Information>
          <strong>Autoridades estatales.</strong> Es posible que recibamos
          información sobre los usuarios de organismos encargados de hacer
          cumplir la ley y otras autoridades gubernamentales según lo requiera o
          autorice la ley.
        </Information>

        <Information>
          <strong>Otros usuarios terceros.</strong> Los terceros pueden
          proporcionarnos información sobre usted, por ejemplo, en relación con
          programas de referencia o a través de soporte al usuario (como
          información relacionada con un incidente o queja, incluidos audio,
          video, imágenes y documentos).
        </Information>

        <Information>Cómo utilizamos su información </Information>

        <Information>
          Utilizamos la información personal para los siguientes propósitos:{" "}
        </Information>

        <List dense>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> 1. </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Para permitirle utilizar PegaCargo.
              </Information>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Permitir a los Usuarios y Transportistas mantener una cuenta en
                PegaCargo, solicitar y proporcionar servicios a través de
                PegaCargo.
              </Information>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Verificar el derecho y la capacidad de los Transportistas para
                prestar servicios de transporte de carga a través de PegaCargo.
              </Information>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Prevenir y combatir comportamientos inseguros o ilegales,
                fraudes y otras violaciones de las reglas de PegaCargo.
              </Information>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Suspender y desactivar a los Usuarios y/o Transportistas que
                participen en actividades prohibidas por la ley, que atenten
                contra el pudor o consideradas violentas.
              </Information>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Proporcionar asistencia y gestionar las quejas de los Usuarios
                y/o Transportistas.
              </Information>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Enviar comunicaciones no comerciales a los Transportistas, tales
                como facturas, recibos de pago o notificaciones.
              </Information>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Mantener la accesibilidad, seguridad y estabilidad de nuestra
                Plataforma, prevenir y resolver incidencias y problemas
                técnicos.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Base jurídica: Ejecución de nuestro contrato con usted,
                necesidad de cumplir con nuestras obligaciones legales y nuestro
                interés legítimo en hacer de PegaCargo un espacio seguro.
              </Information>
            </ListItemText>
          </ListItem>
        </List>

        <List dense>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}>2.</ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Mejorar y desarrollar funciones y productos.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Realizar pruebas, investigaciones, análisis de datos, desarrollo
                y aprendizaje automático.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Recopilar sus comentarios.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Base jurídica: Nuestro interés legítimo en brindarle la mejor
                experiencia en PegaCargo. Cuando para determinados usos sea
                necesario el consentimiento expreso, lo obtendremos por
                separado.{" "}
              </Information>
            </ListItemText>
          </ListItem>
        </List>

        <List dense>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}>3.</ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Promocionar PegaCargo.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Enviar comunicaciones y anuncios de marketing y realizar
                llamadas de marketing.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Proporcionar contenido y publicidad personalizada en PegaCargo y
                en plataformas de terceros.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Organizar concursos, eventos, marcas de automóviles y programas
                de referencia y otras promociones.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Base jurídica: Nuestro interés legítimo de promocionar
                PegaCargo. Cuando para determinadas formas de marketing directo
                sea necesario el consentimiento expreso, lo obtendremos por
                separado.{" "}
              </Information>
            </ListItemText>
          </ListItem>
        </List>

        <List dense>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}>4.</ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Para proteger derechos y cumplir con requisitos legales.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Cumplir con los requisitos de licencia o permiso y cualquier
                otra ley o reglamento aplicable.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Responder a solicitudes de aplicación de la ley, consultas
                gubernamentales, citaciones, órdenes judiciales, reclamaciones
                legales, quejas y/o reclamos.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Proteger los derechos e interés de PegaCargo, nuestros Usuarios,
                Transportistas o el público en general.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Base jurídica: Necesidad de cumplir con nuestras obligaciones
                legales y nuestros intereses legítimos.{" "}
              </Information>
            </ListItemText>
          </ListItem>
        </List>

        <Information>Toma de decisiones automatizadas </Information>

        <List dense>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Utilizamos información personal para tomar decisiones
                automatizadas relacionadas con el uso de nuestra Plataforma:
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Conectar a Transportistas y Usuarios, en función de factores
                como la disponibilidad y la proximidad.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Determinar las calificaciones de los Transportistas y Usuarios.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Señalar o suspender temporalmente a los Usuarios y/o
                Transportistas que se identifiquen como involucrados en fraude,
                actividades prohibidas por la ley, actividades inseguras o
                dañinas. En algunos casos dichas actividades pueden dar lugar a
                la desactivación definitiva.
              </Information>
            </ListItemText>
          </ListItem>
        </List>

        <Information>
          Cuando lo exija la ley, las acciones (incluida la desactivación)
          basadas en dicho procesamiento ocurren solo después de una revisión
          humana y/o la posibilidad de apelar. Para objetar una decisión
          resultante de estos procesos, contáctenos en
          <Link to={CONTACT_US_LANDING_PATH}>
            www.pegacargo.com/contactanos.
          </Link>
        </Information>

        <Information>Cómo compartimos tu información </Information>

        <Information>
          <strong> Con otros Usuarios.</strong> Compartimos cierta información
          sobre los usuarios para permitir la programación y provisión de
          servicios de transporte de carga:{" "}
        </Information>

        <Information>
          Información del Usuario compartida con el Transportista{" "}
        </Information>

        <Information>
          Incluye nombre, apellido, foto de perfil (si está subida), razón
          social (en caso esté registrado como empresa) calificación, lugares de
          recojo y entrega, número de viajes, tarifa ofrecida, comentarios e
          información de contacto. En regiones donde exigimos que los Usuarios
          envíen un número de identificación nacional o número de registro
          tributario, también podemos compartir con el Transportista si el
          Usuario ha enviado este número (pero no compartiremos el número como
          tal).{" "}
        </Information>

        <Information>
          Información del Transportista compartida con el Usuario{" "}
        </Information>

        <Information>
          Incluye nombre, apellido, foto de perfil y razón social (en caso esté
          registrado como empresa), información sobre el vehículo y su foto,
          ubicación antes y durante el viaje, calificaciones y reseñas, número
          de viajes e información de contacto.{" "}
        </Information>

        <Information>
          Cuando sea necesario proporcionar recibos y facturas a los Usuarios,
          podremos compartir información sobre el origen y destino del viaje, la
          duración total y la distancia del viaje, el desglose de la tarifa
          total, el nombre del conductor, el número de identificación y otra
          información requerida por la ley.{" "}
        </Information>

        <Information>
          Si se registra en PegaCargo a través de un enlace de referencia,
          podemos compartir cierta información sobre usted, como su nombre y
          recuento de viajes, con el Transportista que lo recomendó. Esto es
          para permitir que el Transportista referente verifique si es elegible
          para el bono por recomendación.{" "}
        </Information>

        <Information>
          Para fines de servicios distintos de los servicios de transporte
          proporcionados a través de PegaCargo, compartiremos la información con
          otros usuarios que sea necesaria para la prestación de dichos
          servicios.{" "}
        </Information>

        <Information>
          <strong> Con proveedores de servicios.</strong> Podemos compartir su
          información personal con fines comerciales con proveedores de los
          siguientes servicios:{" "}
        </Information>

        <List dense>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Procesamiento de pagos y cumplimiento de transacciones.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Soporte técnico, de Usuario y de Transportista.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Verificación de antecedentes, verificación de identidad.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Seguro contra accidentes
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Almacenamiento en la nube
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Entrega de contenido, mensajería dentro de la aplicación,
                llamadas dentro de la aplicación, notificaciones automáticas.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>Antifraude.</Information>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Publicidad, marketing y análisis.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Investigaciones y encuestas.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Pago de bonificaciones y provisión de otros beneficios
                relacionados con nuestras promociones.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Consultores, abogados, contables, auditores y otros servicios
                profesionales.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>Aeropuertos.</Information>
            </ListItemText>
          </ListItem>
        </List>

        <Information>
          Los siguientes socios procesan datos para sus propios fines como
          controladores independientes y nosotros no controlamos dicho
          procesamiento:{" "}
        </Information>

        <List dense>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Google, en relación con el uso de Google Maps (consulte la
                política de privacidad de Google).
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Empresas de redes sociales (incluida Meta), en relación con
                nuestro uso de sus herramientas (consulte las políticas de
                privacidad de Meta).
              </Information>
            </ListItemText>
          </ListItem>
        </List>

        <Information>
          <strong> Dentro del grupo empresarial.</strong> Podemos compartir su
          información con una empresa matriz, subsidiaria o afiliada de nuestro
          grupo corporativo como parte de nuestras operaciones globales.
        </Information>

        <Information>
          <strong> En relación con una transferencia de negocios.</strong>{" "}
          Podemos transferir información personal en relación con una
          transacción corporativa sustancial en la que la información personal
          se encuentre entre los activos a transferir.
        </Information>

        <Information>
          <strong>Por razones legales.</strong> Podemos revelar su información
          personal cuando sea necesario para proteger derechos y cumplir con
          obligaciones legales.
        </Information>

        <Information>
          Con su consentimiento. Podemos compartir su información personal para
          otros fines de conformidad con su consentimiento o según indicaciones.{" "}
        </Information>

        <Information>
          <strong>Cuando está despersonalizado.</strong> Podemos compartir
          información agregada o anónima que ya no es de identificación
          personal.
        </Information>

        <Information>Transferencia de datos transfronterizas </Information>

        <Information>
          Para respaldar nuestras operaciones globales, podemos compartir su
          información con miembros de grupo corporativo PegaCargo y otras
          entidades fuera de su país de residencia como se describe en la
          sección “Cómo compartimos su información”. Lo hacemos para el
          cumplimiento de nuestro contrato con usted, o en base al
          consentimiento, decisiones de adecuación para los países relevantes u
          otros mecanismos de transferencia y salvaguardias apropiadas según la
          ley aplicable.{" "}
        </Information>

        <Information>
          Tomaremos medidas razonables para garantizar que cualquier
          destinatario en el extranjero trate su información personal de manera
          coherente con la forma en que la tratamos nosotros.{" "}
        </Information>

        <Information>Tus derechos y opciones </Information>

        <Information>
          <strong> Acceder, recibir y actualizar su información.</strong> Puede
          acceder, revisar y actualizar su información ya sea en la
          configuración de su cuenta en la Plataforma o contactándonos en{" "}
          <Link to={CONTACT_US_LANDING_PATH}>
            www.pegacargo.com/contactanos
          </Link>
          . También puede solicitar una copia de toda la información sobre usted
          que procesamos. Puede recibir sus datos en un formato estructurado y
          de uso común.{" "}
        </Information>

        <Information>
          <strong>Eliminando tu cuenta y tus datos.</strong> Puede solicitar la
          eliminación de su cuenta y sus datos contactándonos en{" "}
          <Link to={CONTACT_US_LANDING_PATH}>
            www.pegacargo.com/contactanos.
          </Link>
          Es posible que se le solicite que verifique su identidad. No podremos
          eliminar su cuenta si hay una deuda pendiente o un problema no
          resuelto relacionado con su cuenta (por ejemplo, una queja no
          resuelta). Podemos conservar cierta información después de que se
          elimine su cuenta, siempre que exista una base legítima para dicha
          retención (consulte la sección "Cómo almacenamos su información").
        </Information>

        <Information>
          <strong>Compartir información de ubicación.</strong> Su dispositivo
          puede tener controles que determinan qué información recopilamos. Por
          ejemplo, puede evitar que su dispositivo comparta información de su
          ubicación a través de la configuración del sistema de este.{" "}
        </Information>

        <Information>
          <strong>Oposiciones y retirada del consentimiento.</strong> Tiene
          derecho a oponerse a que procesemos su información cuando el
          procesamiento se base en nuestros intereses legítimos. También puede
          retirar su consentimiento para el procesamiento en cualquier momento
          cuando el procesamiento se base en su consentimiento. En particular,
          podrá ejercer estos derechos mediante:
        </Information>

        <Information>
          <strong>Optar por no recibir notificaciones automáticas.</strong>{" "}
        </Information>

        <List dense>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Puede optar por no recibir notificaciones automáticas a través
                de la configuración de su dispositivo.
              </Information>
            </ListItemText>
          </ListItem>
        </List>

        <Information>
          {" "}
          <strong>
            Darse de baja de correos electrónicos y mensajes de marketing.{" "}
          </strong>{" "}
        </Information>

        <List dense>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Puede optar por no recibir notificaciones automáticas a través
                de la configuración de su dispositivo o de la configuración de
                la aplicación.
              </Information>
            </ListItemText>
          </ListItem>
        </List>

        <Information>
          <strong>Optar por no recibir llamadas de marketing.</strong>{" "}
        </Information>
        <List dense>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Puede optar por no recibir llamadas de marketing durante una
                llamada o comunicándose con nosotros en{" "}
                <Link to={CONTACT_US_LANDING_PATH}>
                  {" "}
                  www.pegacargo.com/contactanos .{" "}
                </Link>
              </Information>
            </ListItemText>
          </ListItem>
        </List>

        <Information>
          <strong>Gestionar cookies. </strong>{" "}
        </Information>
        <List dense>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Puede eliminar las cookies y modificar su configuración de
                cookies en la configuración de su navegador web.
              </Information>
            </ListItemText>
          </ListItem>
        </List>

        <Information>
          <strong>Restricciones. </strong> Puede solicitar que limitemos el uso
          que hacemos de sus datos. Podemos continuar procesando sus datos
          después de dicha solicitud en la medida requerida o permitida por la
          ley.
        </Information>

        <Information>
          {" "}
          <strong>
            {" "}
            Derecho a reclamar ante la autoridad de control.
          </strong>{" "}
          Según la ley local, es posible que tenga derecho a presentar una queja
          ante la autoridad supervisora local.{" "}
        </Information>

        <Information>Cómo almacenamos su información </Information>

        <Information>
          Conservamos su información personal mientras tenga una cuenta con
          nosotros. Podemos conservar cierta información después de la
          cancelación de la cuenta por motivos legítimos:{" "}
        </Information>

        <List dense>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Conservaremos la información transaccional (como datos sobre
                servicios realizados y pagos) durante el tiempo que sea
                necesario para cumplir obligaciones fiscales.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Conservaremos la información durante el periodo requerido por el
                regulador de licencias (cuando corresponda). El periodo de
                retención variará según su ubicación y el tipo de datos.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Conservaremos la correspondencia y otra documentación durante el
                tiempo que sea necesario para cumplir con las obligaciones
                legales de conservación de documentos.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Si existe un reclamo o disputa legal o cualquier posibilidad de
                ello, conservaremos la información correspondiente hasta que
                satisfaga el reclamo/se resuelva la disputa o expire el plazo de
                prescripción. El plazo de prescripción dependerá de su
                ubicación, pero generalmente requerirá retención durante 3 a 7
                años.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                También conservaremos datos relevantes para prevenir fraudes,
                garantizar la seguridad de los Usuarios y/o Transportistas y
                cumplir con nuestras obligaciones legales durante el periodo
                necesario para cumplir con estos fines.
              </Information>
            </ListItemText>
          </ListItem>
        </List>

        <Information>Cómo protegemos su información </Information>

        <Information>
          Tomamos medidas de seguridad técnicas, legales y organizativas
          razonables y apropiadas para proteger su información personal de
          cualquier acción no autorizada que incluya, entre otras, el acceso, la
          divulgación, la alteración o la destrucción que conduzcan a la
          pérdida, el robo indebido de sus datos.{" "}
        </Information>

        <Information>Divulgaciones generales </Information>

        <Information>
          <strong> Protegiendo a los niños.</strong> PegaCargo no está dirigido
          a niños (según define en las leyes locales). Si descubrimos que
          tenemos información personal de un niño, haremos todos los esfuerzos
          razonables para eliminar estos datos y asegurarnos de que no se
          utilicen ni compartan más. Si cree que un niño nos ha proporcionado
          información personal, contáctenos en{" "}
          <Link to={CONTACT_US_LANDING_PATH}>
            www.pegacargo.com/contactanos.
          </Link>
        </Information>

        <Information>
          <strong> Monitoreo telefónico.</strong> Las conversaciones telefónicas
          podrán ser monitoreadas y grabadas con fines de control de calidad y
          capacitación.{" "}
        </Information>

        <Information>
          <strong>No realizar seguimiento.</strong> Algunos navegadores
          transmiten señales de “no rastrear” a los sitios web. Debido a las
          diferencias en cómo los navegadores incorporan esta función,
          actualmente no tomamos medidas en respuesta a estas señales.{" "}
        </Information>

        <Information>
          <strong>Cobranzas de terceros.</strong> Ciertos terceros pueden
          utilizar tecnologías de recopilación automática de información,
          incluidas tecnologías de seguimiento entre sitios, para recopilar
          información personal. Estos terceros pueden incluir su proveedor de
          servicios de internet, navegador web, proveedor de servicios móviles,
          fabricante de dispositivos móviles, anunciantes en línea y empresas de
          análisis de datos. Además, nuestra Plataforma puede contener enlaces a
          plataformas de terceros. Hacer clic en dichos enlaces significa que la
          plataforma respectiva puede recopilar su información personal. No
          somos responsables de las prácticas de privacidad de terceros.{" "}
        </Information>

        <Information>
          <strong>Cambios</strong>{" "}
        </Information>

        <Information>
          Podemos actualizar esta política de vez en cuando para reflejar los
          cambios en las leyes o en la forma en que operamos. Cuando
          actualicemos esta política, se lo notificaremos actualizando la fecha
          de “Última actualización” en la parte superior de esta página y
          publicando la nueva Política de Privacidad y brindándole cualquier
          otro aviso requerido por la ley aplicable. Cuando utiliza PegaCargo,
          acepta los términos y condiciones más recientes de esta política.{" "}
        </Information>
      </Container>
      <Footer />
    </>
  );
};

export default PoliticsPrivacy;
