import { optionTwo } from "@/consts/noticeConsts";
import { currencyFormat } from '@/utils/helpers';
import { Typography } from '@mui/material';

interface IAcceptOrCounterOfferProps {
  price: number;
  type: number;
}

const AcceptOrCounterOffer = ({ price, type }: IAcceptOrCounterOfferProps) => {

  return <Typography variant='h5' sx={{
    textTransform: 'inherit', marginTop: '16px'
  }}>
    {type === optionTwo ? "Contraoferta: " : ""}
    {currencyFormat(price)}
  </Typography>
}

export default AcceptOrCounterOffer
