import { createNoticeServices } from '../../services/noticeServices';
import { getMessaggeError } from '../auth/utils';
import { IMultiPrices, SelectCity } from '../locationRedux/selectLocation';
import {
  saveDataFlow,
  saveListDataFlow,
  setError,
  setLoading,
} from '../saveFlow/saveFlowSlice';
import { AppDispatch, RootState } from '../store';
import { setListOfServices } from './includedServicesSlice';
import {
  getDataForSaveBetweenCities,
  getDataForSaveIntheCity,
  getDataForSaveMoving,
  setDataForSaveIntercitySale,
  getNoticeDestinyInCity,
  setDataForSaveMultiCity,
} from './utils';

export const setConditionsServicesThunk = (conditions: Array<any>) => {
  return (dispatch: AppDispatch) => {
    const arrayConditions = conditions.map((condition) => {
      return {
        ...condition.condicionesservicio,
        isActive: 0,
      };
    });

    dispatch(setListOfServices(arrayConditions));
  };
};

// Guardar los datos del flujo de entre ciudades!!
// NOTE: Cuando sea precio unico, se tienen que poner el mismo precio en {avisodestinos} y en precio!!
export const onSaveFlowInTheCityThunk = (
  price: number,
  multiPrices?: IMultiPrices[]
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      const state = getState();

      const hasMultiPrice = !!multiPrices?.length;

      let dataForSave: any = getDataForSaveIntheCity(
        price,
        state,
        hasMultiPrice
      );

      const avisodestinos = getNoticeDestinyInCity(
        multiPrices,
        price,
        state.selectLocation.selectedDestiny as SelectCity
      );

      if (avisodestinos) {
        dataForSave = { ...dataForSave, avisodestinos };
      }

      const result = await createNoticeServices(dataForSave);
      if (hasMultiPrice || avisodestinos) {
        dispatch(saveListDataFlow(result?.avisoData?.avisos));
      }
      dispatch(saveDataFlow(result?.avisoData?.avisos));
    } catch (err) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

// multiCity service
/*
  pricePerUnit: number, // precio por unidad (por puntos o por hora)
  minUnits: number, // minimo de unidades (minimo de puntos o horas)
  minPrice: number, // precio minimo  (pricePerUnit * minUnits)
  */
export const onSetMultiCityThunk = (
  pricePerUnit: number,
  minUnits: number,
  minPrice: number,
  type: number
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      const state = getState();
      let dataForSave: any = setDataForSaveMultiCity(
        pricePerUnit, // precio por unidad (por puntos o por hora)
        minUnits, // minimo de unidades (minimo de puntos o horas)
        minPrice, // precio minimo  (pricePerUnit * minUnits)
        state,
        type
      );
      const result = await createNoticeServices(dataForSave);
      dispatch(saveDataFlow(result?.avisoData?.avisos));
    } catch (err) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const onSaveFlowBetweenCitiesThunk = (price: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      const state = getState();
      const dataForSave = getDataForSaveBetweenCities(price, state);

      const result = await createNoticeServices(dataForSave);
      dispatch(saveDataFlow(result?.avisoData?.avisos));
    } catch (err) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

// IntercitySale Service
export const onSetIntercitySaleThunk = (
  regularPrice: number,
  descPercentage: number,
  price: number
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      const state = getState();
      let dataForSave: any = setDataForSaveIntercitySale(
        regularPrice,
        descPercentage,
        price,
        state
      );
      const result = await createNoticeServices(dataForSave);
      dispatch(saveDataFlow(result?.avisoData?.avisos));
    } catch (err) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

// moving

export const onSaveFlowMovingThunk = (
  price: number,
  assistant: number,
  type: number,
  multiPrices?: IMultiPrices[]
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));

    try {
      const state = getState();
      const hasMultiPrice = !!multiPrices?.length;
      let dataForSave: any = getDataForSaveMoving(
        price,
        assistant,
        state,
        type,
        hasMultiPrice
      );
      const avisodestinos = getNoticeDestinyInCity(
        multiPrices,
        price,
        state.selectLocation.selectedDestiny as SelectCity
      );
      if (avisodestinos) {
        dataForSave = { ...dataForSave, avisodestinos };
      }
      const result = await createNoticeServices(dataForSave);
      if (hasMultiPrice || avisodestinos) {
        dispatch(saveListDataFlow(result?.avisoData?.avisos));
      }
      dispatch(saveDataFlow(result?.avisoData?.avisos));
    } catch (err) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};
