import {
  ABOUT_US_LANDING_PATH,
  ADS_LANDING_PATH,
  CONTACT_US_LANDING_PATH,
  DRIVERS_LANDING_PATH,
  FAQ_LANDING_PATH,
  POLITICS_PRIVACY,
  TERMS_CONDITIONS_PATH,
} from "@/consts/typeServicesPaths";
import { Box, Grid, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SocialMediaList from "./components/SocialMediaList";
import {
  ContainerBrand,
  ContainerFooter,
  ContainerUpper,
  CustomLink,
  InternalLink,
  socialMediaMobileSx,
} from "./stylesFooter";
import { ReactComponent as LogoFooter } from "./FooterLogo.svg";
import { ReactComponent as GooglePlayLogo } from "./GooglePlayLogo.svg";
import { ReactComponent as AppStoreLogo } from "./AppStoreLogo.svg";
import { ReactComponent as AppGallery } from "./AppGallery.svg";
import { useAppSelector } from "@/hooks/useReducer";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { CLIENT_TYPE, DRIVER_TYPE } from "@/consts/defaultConsts";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import useAppRedirectHook from "@/hooks/useRedirectApp";

const Footer = () => {
  const {
    methods: { linkingIosStore, linkingAndroidStore },
  } = useAppRedirectHook();
  const {
    user: { user },
  } = useAppSelector((state) => state);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleGetColorLink = () => (theme: Theme) => {
    return {
      color: {
        xs: theme.palette.common.white,
        sm: theme.palette.common.white,
        md: theme.palette.common.white,
        lg: theme.palette.common.white,
      },
      fontWeight: "300",
      fontFamily: {
        xs: theme.typography.label1.fontFamily,
        sm: theme.typography.label1.fontFamily,
        md: theme.typography.label1.fontFamily,
        lg: theme.typography.body1.fontFamily,
      },
    };
  };

  const handleNavigate = () => {
    navigate("/")
  }

  const isClient = (user as IUser).tipousuario === CLIENT_TYPE;
  const isDriver = (user as IUser).tipousuario === DRIVER_TYPE;
  const existUserId = !!(user as IUser).id

  return (
    <ContainerFooter disableGutters maxWidth={false}>
      <ContainerUpper container>
        {/* ICON AND SOCIAL NETWORKS */}
        <Grid item xs={12} sm={12} lg={3}>
          <ContainerBrand>
            <Box sx={{ cursor: "pointer" }} onClick={handleNavigate}>
              <LogoFooter />
            </Box>
          </ContainerBrand>
          {/* Social media!! */}
          <SocialMediaList
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "block" },
              paddingLeft: "12px",
            }}
          />
        </Grid>

        <Grid xs={12} sm={9}>
          <Typography
            variant="h5"
            color="#FF6700"
            sx={{
              marginBottom: "32px",
              display: { xs: "none", sm: "none", md: "none", lg: "block" },
              color: "white",
            }}
          >
            {t("footer_title")}
          </Typography>

          <Grid container flexDirection="row">
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={4}
              sx={{ justifyItems: "center" }}
            >
              <Typography
                variant="label1"
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    sm: "none",
                    md: "none",
                    lg: "flex-start",
                  },
                  marginTop: { xs: "16px", sm: "16px", md: "16px", lg: "0px" },
                  color: "#FF6700",
                }}
              >
                {t("footer_use_PegaCargo")}
              </Typography>
              {(isClient ||
                !existUserId) && (
                  <InternalLink to="/" sx={handleGetColorLink()}>
                    {t("footer_look_transportation")}
                  </InternalLink>
                )}
              {(isDriver || !existUserId) && (
                <InternalLink
                  to={DRIVERS_LANDING_PATH}
                  sx={handleGetColorLink()}
                >
                  {t("footer_offers_transportation")}
                </InternalLink>
              )}
              <InternalLink to={ADS_LANDING_PATH} sx={handleGetColorLink()}>
                {t("footer_buy_advertising")}
              </InternalLink>
            </Grid>

            {/* // Column 2 */}
            <Grid xs={12} sm={12} md={12} lg={4}>
              <Typography
                variant="label1"
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    sm: "none",
                    md: "none",
                    lg: "flex-start",
                  },
                  marginTop: { xs: "16px", sm: "16px", md: "16px", lg: "0px" },
                  color: "#FF6700",
                }}
              >
                {t("footer_what_is_PegaCargo")}
              </Typography>
              <InternalLink
                to={ABOUT_US_LANDING_PATH}
                sx={handleGetColorLink()}
              >
                {t("footer_about")}
              </InternalLink>
              <InternalLink
                to={CONTACT_US_LANDING_PATH}
                sx={handleGetColorLink()}
              >
                {t("footer_contact_us")}
              </InternalLink>
              <InternalLink to={FAQ_LANDING_PATH} sx={handleGetColorLink()}>
                {t("footer_frequently_asked_questions")}
              </InternalLink>
            </Grid>

            {/* // Column 3 */}
            <Grid xs={12} sm={12} md={12} lg={4}>
              <Typography
                variant="label1"
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    sm: "none",
                    md: "none",
                    lg: "flex-start",
                  },
                  marginTop: { xs: "16px", sm: "16px", md: "16px", lg: "0px" },
                  color: "#FF6700",
                }}
              >
                {t("footer_our_policy")}
              </Typography>

              <CustomLink href={POLITICS_PRIVACY} rel="noreferrer">
                {t("footer_privacy_policy")}
              </CustomLink>
              <CustomLink
                href="https://forms.office.com/r/xDhxJ23gFk"
                target="_blank"
                rel="noreferrer"
              >
                {t("footer_complaints_book")}
              </CustomLink>
              <CustomLink href={`${TERMS_CONDITIONS_PATH}`} rel="noreferrer">
                {t("footer_terms")}
              </CustomLink>
            </Grid>
          </Grid>
          <SocialMediaList sx={socialMediaMobileSx} />
        </Grid>
      </ContainerUpper>

      <Grid item xs={12} sx={{ marginBottom: "32px", marginTop: "32px" }}>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Box sx={{ marginRight: "8px", cursor: "pointer" }}>
              <GooglePlayLogo 
                onClick={linkingAndroidStore}
              />
          </Box>
          <Box style={{cursor: "pointer" }}>
            <AppStoreLogo 
              onClick={linkingIosStore}
            />
          </Box>
          {/*  <Box sx={{ marginLeft: "8px" }}>
            <AppGallery />
          </Box> */}
        </Box>
      </Grid>

      <Grid item xs={12} textAlign="center" style={{ paddingBottom: "32px" }}>
        <Typography variant="label1" sx={{ color: "white" }}>
          © {new Date().getFullYear()} PegaCargo
        </Typography>
      </Grid>
    </ContainerFooter>
  );
};

export default Footer;
