import { useAppSelector } from "@/hooks/useReducer";
import { SolicitudCreadaData } from "@/models/customerRequest/customerRequestResponse";
import {
  BodyBox,
  ContainerBody,
  SectionBody,
  SectionHeaderFooter,
  SubTitleBody,
  TitleBody,
} from "@/pages/createServices/success/bodySucesstype/styleBodySuccess";
import Icon from "@/utils/Icon";
import { currencyFormat } from "@/utils/helpers";
import { Container } from "@mui/material";
import { SectionBodyMulti } from "./style";
import { districtsDeliveryText } from "@/consts/noticeConsts";


const SuccessMulti = () => {

  const { echoResponse } = useAppSelector((state) => state.customerRequest);
  const {
    solicitudCreada: {
      fecha,
      origenTexto,
      flgHoraoPunto,
      horasRequeridas,
      precioCalculado,
      puntosRequeridos,
    }
  } = echoResponse as SolicitudCreadaData;

  return (
    <Container maxWidth="xs">
      <ContainerBody
        container
        columns={{ xs: 2 }}
        rowSpacing={{ xs: 0 }}
        columnSpacing={{ xs: 0 }}
        justifyContent="center"
        justifyItems="center"
      >

        <SectionHeaderFooter
          item
          xs={2}
          borderBottom={() => ({
            
          })}
        >
          <BodyBox>
            <TitleBody>{districtsDeliveryText}</TitleBody>
          </BodyBox>
          <SubTitleBody>{origenTexto}</SubTitleBody>
        </SectionHeaderFooter>

        {/* multidestino */}
        <SectionBodyMulti
        >
          <SectionBody item xs={1} className="multi">
            {
              flgHoraoPunto === 1 ? (
                <>
                  <BodyBox>
                    <Icon name="icon-timer" size="md" />
                    <TitleBody>Horas</TitleBody>
                  </BodyBox>
                  <SubTitleBody>{horasRequeridas}</SubTitleBody>
                </>
              ) : (
                <>
                  <BodyBox>
                    <Icon name="icon-timeline" size="md" />
                    <TitleBody>Puntos</TitleBody>
                  </BodyBox>
                  <SubTitleBody>{puntosRequeridos}</SubTitleBody>
                </>
              )
            }
          </SectionBody>
          <SectionBody item xs={1.5} className="multi">
            <BodyBox>
              <Icon name="icon-money" size="md" />
              <TitleBody>Precio del servicio</TitleBody>
            </BodyBox>
            <SubTitleBody>
              {currencyFormat(precioCalculado || 0)}
            </SubTitleBody>
          </SectionBody>
        </SectionBodyMulti>

        <SectionHeaderFooter
          item
          xs={2}
          borderBottom={() => ({
            borderBottom: "none",
          })}
        >
          <BodyBox>
            <Icon name="icon-calendar" size="md" />
            <TitleBody>Fecha del servicio</TitleBody>
          </BodyBox>
          <SubTitleBody>{fecha}</SubTitleBody>
        </SectionHeaderFooter>
      </ContainerBody>
      
    </Container>
  );
};

export default SuccessMulti
