import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { FC, FunctionComponent } from "react";

export type ReactSVG = FunctionComponent<
  React.SVGProps<SVGSVGElement> & { title?: string }
>;

interface Props extends SvgIconProps {
  icon: string | FC<SvgIconProps> | ReactSVG;
  isCustom?: boolean;
  iconProps?: any;
}

const IconsSvg = ({ icon, isCustom, ...props }: Props) => {
  if (typeof icon === "string") {
    return <i className={icon}></i>;
  }

  const RenderIcon = icon;

  if (!!isCustom) return <RenderIcon />;

  return (
    <SvgIcon>
      <RenderIcon {...props} />
    </SvgIcon>
  );
};

export default IconsSvg;
