import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SelectedCity {
  id: number;
  nombre: string;
  idpais: number;
}

export interface CitiesState {
  cities: Array<any>;
  citiesByTerm: Array<any>;
  selectedCity: SelectedCity | {};
  isLoading: boolean;
  error: any;
}

const initialState: CitiesState = {
  cities: [],
  citiesByTerm: [],
  selectedCity: {},
  isLoading: false,
  error: null,
};

export const citiesSlice = createSlice({
  name: "cities",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setCities: (state, { payload }: PayloadAction<Array<any>>) => {
      state.cities = payload;
      state.isLoading = false;
      state.error = null;
    },
    setCitiesByTerm: (state, { payload }: PayloadAction<Array<any>>) => {
      state.citiesByTerm = payload;
      state.isLoading = false;
      state.error = null;
    },
    setEmptyCitiesByTerm: (state) => {
      state.citiesByTerm = [];
      state.isLoading = false;
    },
    setError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setCities,
  setError,
  setCitiesByTerm,
  setEmptyCitiesByTerm,
} = citiesSlice.actions;
