import { prepareHistoryListAdapter } from '@/adapters/store/advertisementAdapter';
import { Advertisement } from '@/models/advertisement/advertisementModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PayloadHistoryList {
  advertisements: Advertisement[];
  noticeLength: number;
}

export type HistoryAdvertisement = {
  id: number;
};

type InitialState = {
  isLoading: boolean;
  error: string;
  advertisementList: Advertisement[];
  historyPlinth: HistoryAdvertisement[];
  historyList: Advertisement[];
  historyIndexShow: number[]; // Aqui se guardan los indices que se mostraron!!
};

const initialState: InitialState = {
  isLoading: false,
  error: '',
  advertisementList: [],
  historyPlinth: [],
  historyList: [],
  historyIndexShow: [],
};

export const advertisementSlice = createSlice({
  name: 'advertisement',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.isLoading = false;
      state.advertisementList = [];
    },
    setAdvertisementList: (
      state,
      { payload }: PayloadAction<Advertisement[]>
    ) => {
      state.advertisementList = payload;
      state.error = '';
      state.isLoading = false;
    },
    setHistoryPlinth: (
      state,
      { payload }: PayloadAction<HistoryAdvertisement[]>
    ) => {
      state.historyPlinth = payload;
    },
    setCleanHistoryPlinth: (state) => {
      state.historyPlinth = initialState.historyPlinth;
    },
    setHistoryList: (state, { payload }: PayloadAction<PayloadHistoryList>) => {
      const history = prepareHistoryListAdapter(state.historyList, payload);
      state.historyList = [...state.historyList, ...history];
    },
    setCleanHistoryList: (state) => {
      // borra los datos del historial de avisos y de avisos mostrados!!
      state.historyList = initialState.historyList;
      state.historyIndexShow = initialState.historyIndexShow;
    },
    setHistoryIndexShow: (state, { payload }: PayloadAction<number>) => {
      state.historyIndexShow.push(payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setError,
  setAdvertisementList,
  setHistoryPlinth,
  setHistoryList,
  setCleanHistoryList,
  setCleanHistoryPlinth,
  setHistoryIndexShow,
} = advertisementSlice.actions;
