import React from "react";
import NotFound from "./NotFound";
import no_results_notices from "@/img/no_results_notices.svg";
import { CREATE_CUSTOMER_REQUEST_PATH } from "@/pages/customerRequest/shared/consts/routes";
import { useNavigate } from "react-router-dom";
import PublicActionLand from "../../components/publicActionLand/PublicActionLand";
import { styleContainer, sxSection } from "../styleServices";

const metaData = {
  title: "¿No encontraste al transportista que necesitas?",
  description:
    "Crea una solicitud con los requerimientos del servicio de transporte de carga que se ajuste a tus necesidades.",
};

type Props = {
  isloading: boolean;
  list: any[];
  firstRender: boolean;
};
const SectionInfo = (props: Props) => {
  const { firstRender, list, isloading } = props;
  const navigate = useNavigate();

  const handleNavigateToRequest = () => {
    navigate(CREATE_CUSTOMER_REQUEST_PATH);
  };

  if (!firstRender && !list.length && !isloading) {
    return (
      <NotFound
        show
        imgSrc={no_results_notices}
        buttonText="CREAR SOLICITUD"
        buttonOnClick={handleNavigateToRequest}
      />
    );
  }
  return (
    <PublicActionLand
      title={metaData.title}
      description={metaData.description}
      textButton="CREAR SOLICITUD"
      iconCss="icon-solicitud-client"
      path={CREATE_CUSTOMER_REQUEST_PATH}
      sxSection={sxSection}
      iconMinHeigth="228px"
      styleContainer={styleContainer}
      maxWidth={false}
    />
  );
};

export default SectionInfo;
