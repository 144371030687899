import {
  ACCEPTANCE_OFFERT_CLIENT,
  AGREEMENT_COUNTEROFFERT_CLIENT,
  AGREEMENT_MESSAGE_COUNTEROFFER_DRIVER,
  AGREEMENT_OFFERT,
  CONFIRMATION_AGREEMET_COUNTEROFFERT_CLIENT,
  CONFIRMATION_AGREEMET_OFFERT,
  CONFIRMATION_AGREEMET_OFFERT_CLIENT,
  CONFIRMATION_CO_COUNTEROFFERT_CLIENT,
  CONFIRMATION_CO_COUNTEROFFERT_DRIVER,
  COUNTER_OFFERT_CONFIRMATION,
  DECLINE_COCOUNTEROFFER,
  DECLINE_MESSAGE_COUNTEROFFER_DRIVER,
  DECLINE_OFFER_DRIVER,
  EDIT_CO_COUNTER_OFFER_DRIVER,
  MESSAGE_AGREEMENT_OFFERT,
  MESSAGE_CONFIRMATION_COUNTER_OFFERT,
  MESSAGE_CONFIRMATION_CO_COUNTEROFFERT_DRIVER,
  MESSAGE_CONFIRMATION_CO_COUNTER_OFFER_CLIENT,
  REJECT_CONFIRMATION_COCOUNTEROFFER_CLIENT,
  REJECT_CONFIRMATION_COCOUNTEROFFER_DRIVER,
  REJECT_CONFIRMATION_COUNTEROFFER_CLIENT,
  REJECT_CONFIRMATION_COUNTEROFFER_DRIVER,
  REJECT_CONFIRMATION_OFFERT_CLIENT,
  REJECT_CONFIRMATION_OFFER_DRIVER,
} from '@/consts/acceptanceOffertPath';
import {
  EDIT_COUNTEROFFER_DRIVER,
  EDIT_COUNTEROFFER_SUCCESS_MESSAGE,
} from '@/consts/offertPath';

import Acceptance from '@/pages/acceptanceOffert/acceptance/Acceptance';
import EditPriceCounterOffer from '@/pages/acceptanceOffert/components/EditPriceCounterOffer';
import FeeCounterOffert from '@/pages/acceptanceOffert/components/FeeCounterOffert';

import EditCounterOfferConfirmation from '@/pages/acceptanceOffert/counterOffertConfirmation/EditCounterOfferConfirmation';

import AgreementMessage from '@/pages/noticeV2Negotation/AgreementMessage';
import RejectedMessage from '@/pages/noticeV2Negotation/RejectedMessage';
import NegotationFinishedAccept from '@/pages/noticeV2Negotation/negotationFinished/FinishedAccept';
import NegotationFinishedReject from '@/pages/noticeV2Negotation/negotationFinished/FinishedReject';
import { CoCounterOfferPrincipalComponent } from '@/pages/noticeV2Negotation/negotiation/coCounterOfferDetails/CoCounterOfferPrincipalComponent';
import CounterOffertdetails from '@/pages/noticeV2Negotation/negotiation/counterOfferDetails/PrincipalComponent';

import PrincipalComponent from '@/pages/noticeV2Negotation/negotiation/counterOfferDetails/PrincipalComponent';
import { PincipalDriverCounterOfferNotice } from '@/pages/noticeV2Negotation/negotiation/driverCounterOffer/PrincipalComponent';
import { PrincipalComponentDriverSuccess } from '@/pages/noticeV2Negotation/negotiation/driverCounterOfferSuccessDetails/PrincipalComponentDriverSuccess';

import {
  ACCEPTANCE_COUNTER_OFFER,
  ACCEPTANCE_CO_COUNTEROFFER_DRIVER,
  COUNTER_OFFER_FEE,
  CO_COUNTEROFFER_CLIENT,
  NEGOTATION_REJECTED_NEGOTATION_MESSAGE_CLIENT_NOTICE,
  NEGOTATION_REJECTED_NEGOTATION_MESSAGE_DRIVER_NOTICE,
  NOTICE_NEGOTATION_COUNTER_OFFER_DRIVER,
  OFFER_TO_ACCEPT,
  OFFER_TO_ACCEPT_NO_MY,
} from '@/pages/noticeV2Negotation/shared/const/routes';
import { privateRuteWithLoginMiddleware } from '@/router/middleware/privateCommontMiddleware';
import { RouterProps } from '@/router/router';

export const noticeV2NegotationRouter: RouterProps[] = [
  // ==========================================================================
  //     TRANSPORTISTA: RECIBE OFERTA, CONTRAOFERTA, CO-CONTRAOFERTA
  // ==========================================================================
  {
    path: OFFER_TO_ACCEPT,
    middleware: privateRuteWithLoginMiddleware,
    Component: PrincipalComponent,
  },

  {
    path: NOTICE_NEGOTATION_COUNTER_OFFER_DRIVER,
    middleware: privateRuteWithLoginMiddleware,
    Component: PrincipalComponent,
  },

  {
    path: OFFER_TO_ACCEPT_NO_MY,
    middleware: privateRuteWithLoginMiddleware,
    Component: CounterOffertdetails,
  },

  {
    path: ACCEPTANCE_CO_COUNTEROFFER_DRIVER,
    middleware: privateRuteWithLoginMiddleware,
    Component: CoCounterOfferPrincipalComponent,
  },
  {
    path: COUNTER_OFFER_FEE,
    middleware: privateRuteWithLoginMiddleware,
    Component: PincipalDriverCounterOfferNotice,
  },

  // ==========================================================================================
  //    TRANSPORTISTA: VISTA MENSAJE DE CONFIRMACIÓN DE LA OFERTA, CONTRAOFERTA Y CO-CONTRAOFERTA
  // ==========================================================================================

  {
    path: MESSAGE_AGREEMENT_OFFERT,
    middleware: privateRuteWithLoginMiddleware,
    Component: AgreementMessage,
  },

  {
    path: AGREEMENT_MESSAGE_COUNTEROFFER_DRIVER,
    middleware: privateRuteWithLoginMiddleware,
    Component: AgreementMessage,
  },

  {
    path: MESSAGE_CONFIRMATION_CO_COUNTEROFFERT_DRIVER,
    middleware: privateRuteWithLoginMiddleware,
    Component: AgreementMessage,
  },

  // ==========================================================================================
  //    TRANSPORTISTA: VISTA ACUERDO CONFIRMACIÓN DE LA OFERTA, CONTRAOFERTA Y CO-CONTRAOFERTA
  // ==========================================================================================

  {
    path: CONFIRMATION_AGREEMET_OFFERT,
    middleware: privateRuteWithLoginMiddleware,
    Component: NegotationFinishedAccept,
  },
  {
    path: MESSAGE_CONFIRMATION_COUNTER_OFFERT,
    middleware: privateRuteWithLoginMiddleware,
    Component: NegotationFinishedAccept,
  },
  {
    path: CONFIRMATION_CO_COUNTEROFFERT_DRIVER,
    middleware: privateRuteWithLoginMiddleware,
    Component: NegotationFinishedAccept,
  },

  // ==========================================================================================
  //    TRANSPORTISTA: RECHAZO MENSAJE
  // ==========================================================================================

  {
    path: DECLINE_OFFER_DRIVER,
    middleware: privateRuteWithLoginMiddleware,
    Component: RejectedMessage,
  },
  {
    path: DECLINE_MESSAGE_COUNTEROFFER_DRIVER,
    middleware: privateRuteWithLoginMiddleware,
    Component: RejectedMessage,
  },

  // ==========================================================================================
  //    TRANSPORTISTA: RECHAZO CONFIRMACIÓN
  // ==========================================================================================

  {
    path: REJECT_CONFIRMATION_COCOUNTEROFFER_DRIVER,
    middleware: privateRuteWithLoginMiddleware,
    Component: NegotationFinishedReject,
  },
  {
    path: REJECT_CONFIRMATION_COUNTEROFFER_DRIVER,
    middleware: privateRuteWithLoginMiddleware,
    Component: NegotationFinishedReject,
  },
  {
    path: REJECT_CONFIRMATION_OFFER_DRIVER,
    middleware: privateRuteWithLoginMiddleware,
    Component: NegotationFinishedReject,
  },

  // =========================================================================================================================================

  // ==========================================================================================
  //    CLIENTE: CONTRAOFERTA, CO-CONTRAOFERTA
  // ==========================================================================================

  {
    path: ACCEPTANCE_COUNTER_OFFER,
    middleware: privateRuteWithLoginMiddleware,
    Component: CounterOffertdetails,
  },

  {
    path: CO_COUNTEROFFER_CLIENT,
    middleware: privateRuteWithLoginMiddleware,
    Component: CoCounterOfferPrincipalComponent,
  },

  // ==========================================================================================
  //    CLIENTE: ACUERDO MENSAJE
  // ==========================================================================================

  {
    path: AGREEMENT_OFFERT,
    middleware: privateRuteWithLoginMiddleware,
    Component: AgreementMessage,
    isFree: true,
  },
  {
    path: AGREEMENT_COUNTEROFFERT_CLIENT,
    Component: AgreementMessage,
    middleware: privateRuteWithLoginMiddleware,
    isFree: true,
  },

  {
    path: MESSAGE_CONFIRMATION_CO_COUNTER_OFFER_CLIENT,
    middleware: privateRuteWithLoginMiddleware,
    Component: AgreementMessage,
  },

  // ==========================================================================================
  //    CLIENTE: ACUERDO CONFIRMACIÓN
  // ==========================================================================================

  {
    path: CONFIRMATION_AGREEMET_OFFERT_CLIENT,
    middleware: privateRuteWithLoginMiddleware,
    Component: NegotationFinishedAccept,
  },
  {
    path: CONFIRMATION_AGREEMET_COUNTEROFFERT_CLIENT,
    middleware: privateRuteWithLoginMiddleware,
    Component: NegotationFinishedAccept,
  },

  {
    path: CONFIRMATION_CO_COUNTEROFFERT_CLIENT,
    middleware: privateRuteWithLoginMiddleware,
    Component: NegotationFinishedAccept,
  },

  // ==========================================================================================
  //    CLIENTE: RECHAZO MENSAJE
  // ==========================================================================================

  {
    path: DECLINE_COCOUNTEROFFER,
    middleware: privateRuteWithLoginMiddleware,
    Component: RejectedMessage,
  },

  // ==========================================================================================
  //    CLIENTE: RECHAZO CONFIRMACIÓN
  // ==========================================================================================

  {
    path: REJECT_CONFIRMATION_OFFERT_CLIENT,
    middleware: privateRuteWithLoginMiddleware,
    Component: NegotationFinishedReject,
  },
  {
    path: REJECT_CONFIRMATION_COUNTEROFFER_CLIENT,
    middleware: privateRuteWithLoginMiddleware,
    Component: NegotationFinishedReject,
  },
  {
    path: REJECT_CONFIRMATION_COCOUNTEROFFER_CLIENT,
    middleware: privateRuteWithLoginMiddleware,
    Component: NegotationFinishedReject,
  },

  // =========================================================================================================================================

  {
    path: ACCEPTANCE_OFFERT_CLIENT,
    middleware: privateRuteWithLoginMiddleware,
    Component: Acceptance,
  },

  {
    path: COUNTER_OFFERT_CONFIRMATION,
    Component: PrincipalComponentDriverSuccess,
    middleware: privateRuteWithLoginMiddleware,
  },

  {
    path: EDIT_CO_COUNTER_OFFER_DRIVER,
    middleware: privateRuteWithLoginMiddleware,
    Component: FeeCounterOffert,
  },

  {
    path: EDIT_COUNTEROFFER_DRIVER,
    middleware: privateRuteWithLoginMiddleware,
    Component: EditPriceCounterOffer,
  },

  {
    path: EDIT_COUNTEROFFER_SUCCESS_MESSAGE,
    middleware: privateRuteWithLoginMiddleware,
    Component: EditCounterOfferConfirmation,
  },
  // declined
  {
    path: NEGOTATION_REJECTED_NEGOTATION_MESSAGE_CLIENT_NOTICE,
    middleware: privateRuteWithLoginMiddleware,
    Component: RejectedMessage,
  },
  {
    path: NEGOTATION_REJECTED_NEGOTATION_MESSAGE_DRIVER_NOTICE,
    middleware: privateRuteWithLoginMiddleware,
    Component: RejectedMessage,
  },
];
