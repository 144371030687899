import { TypeCard } from "@/components/cardProfile/CardProfile";
import CustomTags from "@/components/customTags/CustomTags";
import { ID_MOVING_FLOW, ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import DetailTypeFlow from "@/pages/detailNotice/components/detailTypeFlow";
import { TYPE_MOVING } from "@/pages/detailNotice/components/details/consts";
import { getListOfferThunk } from "@/store/offerListRedux/offerListThunk";
import {
  cleanListOfferNoticeActive,
  cleanListOfferNoticeHistory,
} from "@/store/offertRedux/offert.slice";
import { Container, Grid, IconButton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ContainerTags } from "../noticeList/styleNoticeList";
import ListChildren from "./components/ListChildren";
import { HeaderContainer, NoticeOffertStyles } from "./styleNoticeOffert";
import { tabIndex } from "./consts/constsList";

type StateProps = {
  plate: string;
  vehicle: string;
  idTypeNotice: number;
  typeNotice: string;
  originText: string;
  destinyText: string;
  flghoraopunto?: number;
  flgciudadoentreciudades?: number;
};

const NoticeOffert = () => {
  const { containerStyles, currentInfoStyles } = NoticeOffertStyles;
  const {
    offerList: { listOfNegotiations, isLoadingNegotiations: isLoading },
  } = useAppSelector((state) => state);

  const { id = "" } = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const navigate = useNavigate();
  const stateRute = location.state as StateProps;

  useEffect(() => {
    if (!stateRute?.destinyText || !stateRute?.idTypeNotice) {
      navigate(-1);
    }

    dispatch(getListOfferThunk(id));

    return () => {
      dispatch(cleanListOfferNoticeActive());
      dispatch(cleanListOfferNoticeHistory());
    };
  }, []);

  const handleGetDestiny = () => {
    if (!stateRute) return "";
    if (stateRute.idTypeNotice === ID_MULTI_DESTINY) {
      return stateRute.destinyText;
    }

    const template = "De :origin a :destiny";

    return template
      .replace(":origin", stateRute.originText)
      .replace(":destiny", stateRute.destinyText);
  };

  const handleGetNameTypeFlow = () => {
    const { idTypeNotice, typeNotice, flgciudadoentreciudades } = stateRute;
    const { inCity, betweenCities } = TYPE_MOVING;
    if (idTypeNotice === ID_MOVING_FLOW) {
      if (flgciudadoentreciudades === inCity.id) {
        return inCity.text;
      } else if (flgciudadoentreciudades === betweenCities.id) {
        return betweenCities.text;
      }
    }

    return typeNotice;
  };

  const handleGetTypeMoving = () => {
    const { idTypeNotice, flgciudadoentreciudades } = stateRute;
    if (idTypeNotice === ID_MOVING_FLOW) {
      return flgciudadoentreciudades;
    }
  };

  if (!stateRute) return <></>;

  return (
    <Container maxWidth="xl" sx={containerStyles}>
      <Grid container>
        <Grid item xs={12}>
          <HeaderContainer maxWidth={200}>
            <IconButton aria-label="back" onClick={() => navigate(-1)}>
              <i className={"icon-arrow-left--primary"} />
            </IconButton>
            <Typography variant="h6">Ofertas del aviso</Typography>
          </HeaderContainer>
        </Grid>

        <Grid item xs={12} sx={currentInfoStyles}>
          <DetailTypeFlow
            nombre={handleGetNameTypeFlow()}
            id={stateRute?.idTypeNotice}
            typeMoving={handleGetTypeMoving()}
          />
          <Typography variant="h6">{handleGetDestiny()}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2">{`${stateRute.vehicle} ${stateRute.plate && `(${stateRute.plate})`}`}</Typography>
        </Grid>

        <Grid item xs={12}>
          <ContainerTags sx={{ marginTop: "16px" }}>
            <CustomTags
              label1={"por cerrar"}
              label2={"cerradas"}
              label3={"vencidas"}
              childrenTag1={
                <ListChildren
                  noticeList={listOfNegotiations.abiertas}
                  isLoading={isLoading}
                  indexTag={tabIndex.one}
                  typeCard={TypeCard.open}
                />
              }
              childrenTag2={
                <ListChildren
                  noticeList={listOfNegotiations.acuerdos}
                  isLoading={isLoading}
                  indexTag={tabIndex.two}
                  typeCard={TypeCard.close}
                />
              }
              childrenTag3={
                <ListChildren
                  noticeList={listOfNegotiations.cerradas}
                  isLoading={isLoading}
                  indexTag={tabIndex.three}
                  typeCard={TypeCard.expiredDate}
                />
              }
            />
          </ContainerTags>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NoticeOffert;
