import { actionsToNoticeNegotation } from "@/consts/actions";
import { ID_MOVING_FLOW } from "@/consts/defaultConsts";
import { useAppSelector } from "@/hooks/useReducer";
import { objectRenderComponent } from "./config";
import { optionOne, optionThree } from "@/consts/noticeConsts";

const GlobalButtonsPrincipal = () => {
  const {
    offert: { savedOffert },
  } = useAppSelector((state) => state);
  
  const tipoAvisoNegociacion = savedOffert?.ultimaAvisoNegociacionAcciones
    .tipoAvisoNegociacionAccion.id as number;

  const typeNoticeId = savedOffert?.avisoNegociacionAviso.tipoaviso
    .id as number;

  const isMoving = typeNoticeId !== ID_MOVING_FLOW;

  const isDriverCounterOffer =
    tipoAvisoNegociacion === actionsToNoticeNegotation[5].id;

  const isDriverOffer =
    tipoAvisoNegociacion === actionsToNoticeNegotation[1].id;

  const handleOptionToRender = () => {

    if (isMoving) {
      return actionsToNoticeNegotation[tipoAvisoNegociacion]?.componentId;
    } else if (isDriverCounterOffer || isDriverOffer) {
      return optionOne;
    } else {
      return optionThree;
    }
  };
  
  
  const Component = objectRenderComponent[handleOptionToRender() as number];
  if (!Component) return <></>;

  return <Component />;
};

export default GlobalButtonsPrincipal;
