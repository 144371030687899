import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { defaultError } from "@/pages/noticeRequestFlow/consts/noticeRequestFlowConsts";
import { setError } from "@/store/noticeNegotationRequestRedux/noticeNegRequestSlice";
import { useEffect } from "react";
import { useGlobalButtons } from "../hooks/useGlobalButtons";

import { textAceptarContactar, textRechazar } from "@/consts/noticeConsts";
import {
  ButtonAccept,
  ContainerButtons
} from "./StylesGlobalButtonsNotifications";
import { actionsToNoticeNegotation } from "@/consts/actions";


const OfferGlobalButtons = () => {
  const {handleManageSnackbar, handleManageModalOffer, handleManageModalCounterOffer, handleAcceptButton, handleDisabledButtons} = useGlobalButtons()
  const {notiffication } = useNotiffication();
  const dispatch = useAppDispatch();
  const {error, isLoading, savedOffert} = useAppSelector((state) => state.offert);
  const disableButtons = handleDisabledButtons()
  const tipoAvisoNegociacion = savedOffert?.ultimaAvisoNegociacionAcciones
  .tipoAvisoNegociacionAccion.id as number;
const isDriverCounterOffer = tipoAvisoNegociacion === actionsToNoticeNegotation[5].id;


  useEffect(() => {
    if (error) {
      handleManageSnackbar();
    } 
  }, [error])

  useEffect(() => {
    if(!notiffication){
      dispatch(setError(defaultError));
    }
  }, [notiffication])
  

  return (
    <ContainerButtons>
      <ButtonAccept
        fullWidth
        variant="contained"
        onClick={handleAcceptButton}
        disabled={!disableButtons.disableAcceptButton || isLoading}
      >
        {textAceptarContactar}
      </ButtonAccept>


      <ButtomLoader
      fullWidth variant="text"
        type="submit"
        isLoading={isLoading}
        onClick={isDriverCounterOffer ? handleManageModalCounterOffer : handleManageModalOffer}
        disabled={!disableButtons.disableDeclineButton || isLoading}
      >
        {textRechazar}
      </ButtomLoader>

    </ContainerButtons>
  );
};

export default OfferGlobalButtons;
