import { generalConfigDataReject } from '@/pages/noticeV2Negotation/shared/config/generalConfigFinishedReject';
import { ConfigDataNegotationV2 } from '@/pages/noticeV2Negotation/shared/hooks/useNoticeConfigDataV2';

export const detailsConfigReject: ConfigDataNegotationV2[] = [
  ...generalConfigDataReject,
];

export const detailsConfigRejectCounterOffer: ConfigDataNegotationV2[] = [
  ...generalConfigDataReject,
];
