import {
  ABOUT_US_LANDING_PATH,
  ADS_LANDING_PATH,
  CONTACT_US_LANDING_PATH,
  DRIVERS_LANDING_PATH,
  FAQ_LANDING_PATH,
} from '@/consts/typeServicesPaths';

export const headerIconBrand = {
  route: '',
  icon: {
    xs: 'logoBrand--minicom',
    md: 'logoBrand--headercom',
  },
};

export const headerIconMenu = {
  style: {
    color: 'inherit',
    width: '48px',
    height: '48px',
    display: {
      xs: 'block',
      lg: 'none',
    },
    padding: { xs: '5px', sm: '10px', md: '10px', lg: '10px' },
  },
};

export const headerListItems = [
  {
    id: 1,
    title: 'SOY TRANSPORTISTA',
    route: DRIVERS_LANDING_PATH,
    sub: [],
    sxPropos: {
      display: {
        xs: 'none',
        lg: 'block',
      },
    },
  },
  {
    id: 2,
    title: 'SOY ANUNCIANTE',
    route: ADS_LANDING_PATH,
    sub: [],
    sxPropos: {
      display: {
        xs: 'none',
        lg: 'block',
      },
    },
  },
  {
    id: 3,
    title: 'NOSOTROS',
    route: ABOUT_US_LANDING_PATH,
    sub: [],
    sxPropos: {
      display: {
        xs: 'none',
        lg: 'block',
      },
    },
  },
  {
    id: 4,
    title: 'AYUDA',
    route: '',
    sub: [
      {
        title: 'Contáctanos',
        route: CONTACT_US_LANDING_PATH,
      },
      {
        title: 'Preguntas frecuentes',
        route: FAQ_LANDING_PATH,
      },
    ],
    sxPropos: {
      display: {
        xs: 'none',
        lg: 'block',
      },
    },
  },
];

export const headerListItemsDisplay = [
  {
    title: 'SOY TRANSPORTISTA',
    route: DRIVERS_LANDING_PATH,
  },
  {
    title: 'SOY ANUNCIANTE',
    route: ADS_LANDING_PATH,
  },
  {
    title: 'NOSOTROS',
    route: ABOUT_US_LANDING_PATH,
  },
  {
    title: 'CONTÁCTANOS',
    route: CONTACT_US_LANDING_PATH,
    sxPropos: {
      display: {
        xs: 'none',
        lg: 'block',
      },
    },
  },
  {
    title: 'PREGUNTAS FRECUENTES',
    route: FAQ_LANDING_PATH,
  },
];
