import { SelectsForm } from '../../interfaces/interfaces';

export const listDataAscensor = [
  {
    id: 1,
    desciption: 'Si',
  },
  {
    id: 2,
    desciption: 'No',
  },
  {
    id: 3,
    desciption: 'Sólo para algunas cosas',
  },
];

export const defaultInmueble: Record<keyof SelectsForm, string> = {
  typeProperty: '',
  floor: '',
  ascensor: '',
};

export const defaultInput: Record<string, string> = {
  area: '',
  reference: '',
};

export const defaultInputDestiny = {
  destinyReference: '',
};

// ==================================================
//              Oferta de Multidestino
// ==================================================
// Ej: `El mínimo de puntos debe ser 1 y máximo 99.`
export const templateErrorQuantity = `El mínimo de :type debe ser :min y máximo :max.`;
export const flagPoint = 2; // flag de puntos
export const maxPoints = 99; // maximo de puntos de la oferta/contraoferta
export const maxHours = 24; //  maximo de horas de la oferta/contraoferta
export const minHours = 1; //   minimo de horas de la oferta/contraoferta
export const minPoints = 2; //  minimo de puntos de la oferta/contraoferta
export const textPoint = 'puntos';
export const textHours = 'horas';
