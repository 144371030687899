import { ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import { MyNegotiationes } from "@/models/userAuth/agrementToAccept";
import DetailTypeFlow from "@/pages/detailNotice/components/detailTypeFlow";
import { currencyFormat } from "@/utils/helpers";
import { Box, CardContent, Typography, styled } from "@mui/material";
import StateText from "../../../noticeList/components/StateText";
import { typeNoticeByIdObject } from "../../consts/consts";

const Container = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  position: "relative",
}));

const Title = styled(Typography)(() => ({
  textOverflow: "ellipsis",
  overflow: "hidden",
  lineHeight: "1.2",
}));

const ContainerPlate = styled(Box)(() => ({
  marginBottom: "8px",
}));

const Detail = styled(Typography)(() => ({
  // una linea
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));

type Props = {
  data: MyNegotiationes;
  isTypeClient?: boolean;
};

const CardBody = ({ data, isTypeClient }: Props) => {
  const IS_MULTIDESTINY = data.idTipo === ID_MULTI_DESTINY;

  const handleTitle = (data: MyNegotiationes) => {
    const { origenTexto, destinoTexto } = data;

    if (IS_MULTIDESTINY) {
      return destinoTexto;
    }

    return `De ${origenTexto} a ${destinoTexto}`;
  };

  const handleGetNameTypeFlow = (data: MyNegotiationes) => {
    const { title } = typeNoticeByIdObject[data.idTipo];

    return title;
  };

  const handleGetTextPlate = () => {
    const { placa, peso } = data;
    const weight = currencyFormat(Number(peso), false);

    const template = `${placa} | Hasta ${weight} kilos`;

    return template;
  };

  const handleGetDescription = () => {
    if (isTypeClient) {
      return data?.descripcionCarga;
    }

    return handleGetTextPlate();
  };

  const styleMarginTop = IS_MULTIDESTINY ? "10px" : "7px";
  const description = handleGetDescription();

  const handleTitleText = () => {

    return handleTitle(data);
  };

  return (
    <CardContent sx={{ p: 2, pb: 0 }}>
      <Container>
        <DetailTypeFlow
          nombre={handleGetNameTypeFlow(data)}
          id={data.idTipo}
          typeMoving={data?.flgCiudadoEntreciudad}
          flghoraopunto={data?.flgHoraoPunto}
        />

        <StateText state={data?.estado} color={data.colorEstado} />
      </Container>

      <Box
        sx={{
          marginTop: styleMarginTop,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
        }}
      >
        <Title
          variant="subtitle1"
          sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
        >
          {handleTitleText()}
        </Title>
      </Box>
      <Box>
        <Typography
          sx={{ fontSize: "14px", minHeight: "24px", color: "#888888" }}
        >
          {data.fecha}
        </Typography>
      </Box>

      <ContainerPlate>
        <Detail sx={{ fontSize: "14px", minHeight: "24px" }}>
          {description}
        </Detail>
      </ContainerPlate>
    </CardContent>
  );
};

export default CardBody;
