import { IMG_PATH } from "@/consts/defaultConsts";
import { useAppSelector } from "@/hooks/useReducer";
import { NoticeProfile } from "@/models/notice/noticeListOffersModels";
import { resizePhoto } from "@/utils/helpers";
import StarIcon from '@mui/icons-material/Star';
import { Avatar, Grid, Rating, Skeleton, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import HistoryRating from "./historyRating/HistoryRating";
import { RatingStyles } from "./styledUserRating";

export interface Props {
  data: NoticeProfile;
  viewRating?: boolean;
}

const statePercentSize = "70%"
const stateNumberSize = 14

const UserRating = ({ data, viewRating = true }: Props) => {


  const match = useMediaQuery('(max-width:1200px)');
  const { avatarStyles, gridContainerStyles, gradeTextStyles, boxPriceStyles } = RatingStyles;
  const { isLoading } = useAppSelector((state) => state.ratingAgreement);
  const [openRating, setOpenRating] = useState<boolean>(false);
  const { urlProfile, userName, price, state, showPrice, showTwoAvatars, rating, ratingText, urlVehicle, sx,
    timeProximity,
    vehiculoText,
    show = false
  } = data;


  const stylesGrid = !!sx ? sx : {}
  const urlUser = !!urlProfile ? resizePhoto({ photo: IMG_PATH + urlProfile }) : IMG_PATH + urlProfile

  const UserName = userName || "";
  const onOpenRating = () => {
    if (!viewRating) return;
    setOpenRating(true);
  };

  const onCloseRating = () => {
    setOpenRating(false);
  };

  const handleStateSize = () => {

    if (match) return statePercentSize

    return stateNumberSize
  }

  if (show) return null;

  return (
    <>
      <Grid container onClick={onOpenRating} sx={{ cursor: viewRating ? "pointer" : "default" }}
        mb={2}
        gap={0}
      >
        <Grid item xs={2} sx={{display: "flex", flexBasis: `${match ? "18%" : "12%"}`, ...stylesGrid}}>
          {isLoading ? (
            <Skeleton variant="circular" width={48} height={48} />
          ) : showTwoAvatars ? (
            <>
              <Avatar src={urlUser} sx={avatarStyles} />
            </>

          ) : (
            <Avatar src={urlUser} sx={avatarStyles} />
          )}
        </Grid>
        <Grid item xs={showPrice ? 6 : 9} sx={{...gridContainerStyles, mt: 0,}}>
          {isLoading ? (
            <Skeleton variant="rectangular" width={150} height={64} />
          ) : (
            <>
              <Typography
                variant="label1"
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  color: viewRating ? "primary.main" : "",
                }}
              >
                {userName}
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Rating
                  readOnly
                  name="customized-10"
                  defaultValue={2}
                  max={1}
                  size="small"
                  sx={!!rating ? {} : { color: "#9e9e9e" }}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
                <Typography variant="caption" sx={gradeTextStyles}>
                  {!!rating && rating}{" "}
                  {!!ratingText && `(${ratingText})`}
                </Typography>
              </Box>
            </>
          )}
        </Grid>
        {showPrice && (
          <>
            <Grid item xs={3}>
              <Box sx={boxPriceStyles}>
                <Typography variant="body2" color="primary" sx={{ fontSize: handleStateSize() }}>
                  {state}
                </Typography>
                {price !== 0 && <Typography variant="h6">S/ {price}</Typography>}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
      <HistoryRating open={openRating} onClose={onCloseRating} userData={data} />
    </>
  );
};

export default UserRating;
