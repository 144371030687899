import {
  AUTH_RESET_PASSWORD,
  AUTH_CREATE_PASSWORD,
  AUTH_RECOVER_PASSWORD,
  AUTH_VALIDATION,
  PROFILE_DATOS_VALIDACION,
} from "@/consts/typeServicesPaths";
import { EnumTypeCodeSend } from "@/store/auth/authUserThunks";

export const objectNextPage: Record<string, string> = {
  [AUTH_VALIDATION]: AUTH_CREATE_PASSWORD,
  [AUTH_RECOVER_PASSWORD]: AUTH_RESET_PASSWORD,
};

export const titleByPath: Record<string, string> = {
  [AUTH_VALIDATION]: "Confirma tu número",
  [AUTH_RECOVER_PASSWORD]: "Recuperar contraseña",
  [PROFILE_DATOS_VALIDACION]: "Confirma tu número",
};

export const tipoSMS: Record<string, EnumTypeCodeSend> = {
  [AUTH_VALIDATION]: EnumTypeCodeSend.REGISTRO,
  [AUTH_RECOVER_PASSWORD]: EnumTypeCodeSend.RESTABLECER,
  [PROFILE_DATOS_VALIDACION]: EnumTypeCodeSend.CAMBIARNUMERO,
};
