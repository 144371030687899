import { RatingStyles } from '@/components/userRating/styledUserRating';
import { useAppSelector } from '@/hooks/useReducer';
import { DetailsNegotationV2 } from '@/models/notice/noticeV2NegotationModels';
import { UsuarioAPI } from '@/models/userAuth/user';
import {
  dataDriverFormat,
  dataProfileFormat,
} from '@/pages/noticeV2Negotation/shared/adapters/ratingsAdapters';
import useGetData from '@/pages/noticeV2Negotation/shared/hooks/useGetData';
import { useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const useSingleUorTFinishedHook = () => {
  const [openRating, setOpenRating] = useState<boolean>(false);

  const {
    user: { user },
  } = useAppSelector((state) => state);
  const { notice } = useGetData();

  const theme = useTheme();
  const { pathname } = useLocation();
  const { avatarStyles, gridContainerStyles, gradeTextStyles } = RatingStyles;
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const { flgIsOwner } = notice as DetailsNegotationV2;

  const onOpenRating = (viewRating?: boolean) => {
    if (!viewRating) return;
    setOpenRating(true);
  };

  const onCloseRating = () => {
    setOpenRating(false);
  };

  const handleData = () => {
    if ((user as UsuarioAPI).tipousuario === 'T') {
      return dataProfileFormat(notice as DetailsNegotationV2);
    }
    return dataDriverFormat(notice as DetailsNegotationV2);
  };

  return {
    avatarStyles,
    gridContainerStyles,
    gradeTextStyles,
    matches,
    pathname,
    onOpenRating,
    onCloseRating,
    openRating,
    handleData,
    flgIsOwner,
  };
};

export default useSingleUorTFinishedHook;
