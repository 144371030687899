import { Box, SxProps, Theme, Typography } from "@mui/material";
import React from "react";

type Props = {
  price: string | number;
  priceDescription?: string;
  show?: boolean;
  constainerSx?: SxProps<Theme>;
};
const OfferPrice = (props: Props) => {
  const { price, priceDescription, constainerSx, show = true } = props;
  if (!show) return <></>;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        ...constainerSx,
      }}
    >
      <Typography variant="h5">{price}</Typography>
      {priceDescription && (
        <Typography variant="body2" marginLeft={"8px"}>
          {priceDescription}
        </Typography>
      )}
    </Box>
  );
};

export default OfferPrice;
