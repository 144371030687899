import { Stack, Typography, styled } from "@mui/material";

const TextZoneKey = styled(Typography)(
  ({ theme }) => `
    font-family: ${theme.typography.subtitle1.fontFamily};
    fonst-size: ${theme.typography.subtitle1.color};
    font-family: ${theme.typography.subtitle1.fontFamily};
    margin-left: 16px;
  `
);

const ContainerTitle = styled(Stack)(
  () => `
    background-color: rgba(0,0,0,0.06);
    padding: 6px 16px;
  `
);

const SubContainerTitle = styled(Stack)(
  () => `
    flex-direction: row;
  `
);

export interface IconTitleProps {
  title: string;
  icon: string;
}

const ContainerIconTitle = ({ title, icon }: IconTitleProps) => {
  return (
    <ContainerTitle>
      <SubContainerTitle>
        <i className={icon}></i>
        <TextZoneKey>{title}</TextZoneKey>
      </SubContainerTitle>
    </ContainerTitle>
  );
};

export default ContainerIconTitle;
