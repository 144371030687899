import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import MobileSelectedOptions from "./MobileSelectedOptions";
import { ButtonContainer, CardBox, Container } from "./StyleFilter";
import { FORMAT_DATE_FILTER, FormDataFilter } from "./consts/filtersConst";
import { characterCut } from "@/utils/helpers";
import { handleTextAdapter } from "./utils/adapter";
import {
  NOTICE_TITLE_AUCTION_BETWEEN_CITIES,
  maxLengthText,
} from "@/consts/noticeConsts";
import ItemOption from "./components/ItemOption";

interface Props {
  filterStyles?: any;
  buttonCLick: React.Dispatch<React.SetStateAction<boolean>>;
  formData: FormDataFilter;
}

const SelectedOptions = (props: Props) => {
  const { buttonCLick, formData } = props;
  const matches = useMediaQuery("(max-width:1200px)");

  if (!formData) return <></>;

  if (matches) {
    return (
      <MobileSelectedOptions buttonCLick={buttonCLick} formData={formData} />
    );
  }

  const handleShowDate = () => {
    const typeAuction = NOTICE_TITLE_AUCTION_BETWEEN_CITIES;
    if (formData.typeNotice === typeAuction && !!formData?.betweenDate) {
      return formData.betweenDate;
    }

    return dayjs(formData?.timeService).format(FORMAT_DATE_FILTER);
  };

  return (
    <>
      <Container>
        <CardBox>
          <Typography
            sx={{
              fontWeight: 600,
              opacity: 0.9,
              color: formData.origin ? "#424242" : "#9E9E9E",
            }}
          >
            {characterCut(handleTextAdapter(formData.origin || "Origen"), 22)}
          </Typography>
          <Typography
            sx={{ color: "#FF6700", mr: 0.5, ml: 0.5 }}
          >{`>`}</Typography>
          <Typography
            sx={{
              fontWeight: 600,
              opacity: 0.9,
              color: formData.destiny ? "#424242" : "#9E9E9E",
            }}
          >
            {characterCut(handleTextAdapter(formData.destiny || "Destino"), 22)}
          </Typography>
        </CardBox>

        <ItemOption show>{handleShowDate()}</ItemOption>

        <ItemOption show={!!formData.typeVehicle}>
          {characterCut(handleTextAdapter(formData.typeVehicle), 22)}
        </ItemOption>

        <ItemOption show={!!formData.weightOfTheLoad}>
          {formData?.weightOfTheLoad}
        </ItemOption>

        <ItemOption show={!!formData.typeServiceList}>
          {formData?.typeServiceList || ""}
        </ItemOption>

        <CardBox>
          <ButtonContainer>
            <Box
              onClick={() => buttonCLick(true)}
              sx={{ display: "flex", cursor: "pointer" }}
            >
              <Typography sx={{ fontWeight: 600, opacity: 0.9 }}>
                Modificar búsqueda
              </Typography>
              <ExpandMoreIcon sx={{ fontSize: "24px" }} />
            </Box>
          </ButtonContainer>
        </CardBox>
      </Container>
    </>
  );
};

export default SelectedOptions;
