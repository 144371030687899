import { DetailsNegotationV2 } from "@/models/notice/noticeV2NegotationModels";
import { currencyFormat } from "@/utils/helpers";
import { Typography } from "@mui/material";

export const OfferPriceV2 = ({ data }: { data: DetailsNegotationV2 }) => {
  return (
    <Typography
      sx={{ pt: 2, fontWeight: 700, fontSize: "22px", textAlign: "revert" }}
    >
      {currencyFormat(data.precio)}
    </Typography>
  );
};
