import { Alert, AlertProps } from "@mui/material";
import { FC } from "react";

interface Props extends AlertProps {
  children: React.ReactNode | JSX.Element | string;
  icon?: string | boolean;
  open?: boolean;
}

const BasicAlert: FC<Props> = (props) => {
  let {
    open = true,
    icon = "",
    severity = undefined,
    variant = "standard",
    color = undefined,
    sx,
    children,
  } = props;

  switch (severity) {
    case "error":
      icon = "icon-alert-error";
      break;
    case "info":
      icon = "icon-alert-info";
      break;
    case "success":
      icon = "icon-alert-success";
      break;
    case "warning":
      icon = "icon-alert-warning";
      break;
    default:
      icon = false;
      break;
  }

  return (
    <>
      {open && (
        <Alert
          icon={icon && <i className={icon} />}
          severity={severity}
          variant={variant}
          color={color}
          sx={sx}
        >
          {children}
        </Alert>
      )}
    </>
  );
};

export default BasicAlert;
