import { CLIENT_TYPE, IMG_PATH, userRoles } from "@/consts/defaultConsts";
import {
  HOME_PAHT,
  MY_PENDING_AGREEMENTS,
  PROFILE_DATOS_PERSONALS_PATH,
  PROFILE_NOTICE_LIST_PATH,
  PROFILE_PATH,
  REQUESTS_LANDING_PATH,
} from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import useResize from "@/hooks/useResize";
import { RoleAPI, UsuarioAPI } from "@/models/userAuth/user";
import { MY_APPLICATIONS_CUSTOMER_REQUEST_PATH } from "@/pages/customerRequest/shared/consts/routes";
import { logoutThunk } from "@/store/userRedux/userThunks";
import {
  Avatar,
  Badge,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { FC, MouseEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NOTIFICATIONS_PATH } from "../../consts/typeServicesPaths";
import {
  MenuHeader,
  MenuHeaderAccount,
  MenuHeaderAccountItem,
  RedirectMenu,
  RedirectNotifi,
  StyledBadge,
} from "./styleHeader";

import useSocketIO from "@/hooks/useSokect2";
import {
  validationFullProfile,
  validationRegisterTypeClient,
} from "@/router/redirectRules/redirectRules";
import { resizePhoto } from "@/utils/helpers";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { BASE_URL_SOCKET } from "../../config/config";

type Props = {
  onClose: (value: boolean) => void;
  onStatus: (value: boolean) => void;
};

type MessageSocket = {
  notificacionesNoLeidas: number;
  idWebSocketHistorial: number;
  room: string;
  typeData: string;
};

const HeaderListItemUser: FC<Props> = (props) => {
  const [numNotis, setNumNotis] = useState(0);
  const { user } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const { onClose, onStatus } = props;
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [, isMobil] = useResize();
  const open = Boolean(anchorEl);
  const [prevAvatarImg, setPrevAvatarImg] = useState("");
  const currentUser = user as UsuarioAPI;
  const userPerfil = currentUser.perfilUrl;
  const userCompanyName = currentUser?.nombreEmpresa;

  const userRole = handleGetRole();

  const { connectSocket, on, emit } = useSocketIO({
    url: BASE_URL_SOCKET!,
  });

  useEffect(() => {
    if (!!userPerfil) {
      setPrevAvatarImg(userPerfil);
    }
    if (Object.keys(user).length) {
      handleOnConnectSocket();
    }

    return () => {
      if (currentUser?.id) {
        emit("event_leave", "usuario_" + currentUser?.id);
      }
    };
  }, []);

  const handleOnConnectSocket = () => {
    connectSocket();

    emit("event_join", "usuario_" + currentUser?.id);

    on("new_message", (data: MessageSocket) => {
      setNumNotis(data.notificacionesNoLeidas);
    });
  };

  function handleGetRole() {
    const role = currentUser?.role as RoleAPI[];
    if (!!role && !!role.length) {
      const [currentRole] = role;
      return currentRole;
    }

    return null;
  }

  const handleResizeImg = () => {
    const resizedImg = resizePhoto({ photo: IMG_PATH + userPerfil });

    if (userPerfil === "") return { resizedImg: IMG_PATH + "" };

    if (prevAvatarImg !== userPerfil) {
      return { resizedImg: IMG_PATH + userPerfil };
    } else {
      return { resizedImg };
    }
  };

  const { resizedImg } = handleResizeImg();

  const handleClose = (path?: string) => () => {
    if (path) {
      if (path === PROFILE_PATH && !isMobil) {
        navigate(PROFILE_DATOS_PERSONALS_PATH);
      } else {
        navigate(path);
      }
    }
    setAnchorEl(null);
  };

  const handleGetPath = (path: string) => {
    if (path === PROFILE_PATH && !isMobil) {
      return PROFILE_DATOS_PERSONALS_PATH;
    }

    return path;
  };

  const handleStatus = () => {
    onClose(false);
    onStatus(false);
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeSession = () => {
    dispatch(logoutThunk());
    navigate(HOME_PAHT);
    handleClose();
  };

  const showLinks = (): boolean => {
    if (currentUser?.role?.length && !!userRole?.nombre) {
      if (
        userRole?.nombre === userRoles[0] &&
        currentUser?.verificacionRegistro
      ) {
        return true;
      } else if (
        userRole?.nombre === userRoles[4] &&
        currentUser.registroForm === 1
      ) {
        return true;
      }
    }
    return false;
  };

  // Valida si el usuario tiene todos los datos completos
  // y regresa los textos correctos para mostrar
  const handleGetNameAndDescription = () => {
    const descriptionType = {
      incomplete: "Completar registro",
      complete: "Ver perfil",
    };
    const isClient = currentUser?.tipousuario === CLIENT_TYPE;
    let name = userCompanyName ? userCompanyName : currentUser.nombres;
    let description = descriptionType.complete;

    if (!currentUser?.nombres && !currentUser?.nombreEmpresa) {
      name = currentUser?.username;
      description = descriptionType.incomplete;
    } else if (isClient && validationRegisterTypeClient(currentUser)) {
      description = descriptionType.incomplete;
    } else if (validationFullProfile(currentUser) && !isClient) {
      description = descriptionType.incomplete;
    }

    return {
      name,
      description,
    };
  };

  const { name, description } = handleGetNameAndDescription();

  return (
    <Grid
      container
      sx={{ width: "auto" }}
      justifyContent="flex-end"
      alignItems="center"
      gap={1}
    >
      <Grid item onClick={handleStatus}>
        <RedirectNotifi to={NOTIFICATIONS_PATH}>
          <IconButton color="inherit">
            <Badge
              badgeContent={numNotis > 0 ? numNotis : 0}
              color="org"
              max={9}
            >
              <NotificationsNoneOutlinedIcon />
            </Badge>
          </IconButton>
        </RedirectNotifi>
      </Grid>
      <Grid item>
        <IconButton
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <Avatar
            sx={{
              width: 36,
              height: 36,
              backgroundColor: "#ffffff",
              border: "1px solid transparent",
            }}
            src={resizedImg}
            onError={() => {
              return IMG_PATH + userPerfil;
            }}
          >
            <PersonOutlineOutlinedIcon sx={{ color: "#000c" }} />
          </Avatar>
        </IconButton>
        <MenuHeader
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose()}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuHeaderAccount>
            <Grid
              container
              alignItems="center"
              gap={2}
              onClick={handleClose(PROFILE_PATH)}
            >
              <Avatar
                sx={{ width: 36, height: 36, backgroundColor: "#FFFFFF" }}
                src={resizedImg || IMG_PATH + userPerfil}
                onError={() => {
                  return IMG_PATH + userPerfil;
                }}
              >
                <PersonOutlineOutlinedIcon sx={{ color: "#000c" }} />
              </Avatar>
              <Stack>
                <Typography variant="h6" color="white">
                  {name}
                </Typography>
                <Typography variant="body2" color="white">
                  {description}
                </Typography>
              </Stack>
            </Grid>
          </MenuHeaderAccount>
          <div onClick={handleStatus}>
            {user && showLinks() && (
              <>
                <MenuHeaderAccountItem onClick={handleClose()}>
                  <RedirectMenu to={handleGetPath(MY_PENDING_AGREEMENTS)}>
                    <StyledBadge badgeContent={0} color="error">
                      Negociaciones
                    </StyledBadge>
                  </RedirectMenu>
                </MenuHeaderAccountItem>
                {userRoles[0] === userRole?.nombre && (
                  <>
                    <MenuHeaderAccountItem onClick={handleClose()}>
                      <RedirectMenu
                        to={handleGetPath(PROFILE_NOTICE_LIST_PATH)}
                      >
                        Avisos
                      </RedirectMenu>
                    </MenuHeaderAccountItem>

                    <MenuHeaderAccountItem onClick={handleClose()}>
                      <RedirectMenu to={handleGetPath(REQUESTS_LANDING_PATH)}>
                        Solicitudes de clientes
                      </RedirectMenu>
                    </MenuHeaderAccountItem>
                  </>
                )}

                {userRoles[4] === userRole?.nombre && (
                  <>
                    <MenuHeaderAccountItem onClick={handleClose()}>
                      <RedirectMenu
                        to={handleGetPath(
                          MY_APPLICATIONS_CUSTOMER_REQUEST_PATH
                        )}
                      >
                        Solicitudes
                      </RedirectMenu>
                    </MenuHeaderAccountItem>
                  </>
                )}
              </>
            )}
            <MenuHeaderAccountItem onClick={closeSession}>
              <RedirectMenu to={HOME_PAHT}>Cerrar Sesión</RedirectMenu>
            </MenuHeaderAccountItem>
          </div>
        </MenuHeader>
      </Grid>
    </Grid>
  );
};

export default HeaderListItemUser;
