import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Typography } from "@mui/material";
import { AddBtnContainer } from "../../NoticePlace/noticePlaceStyles";
import useAddNewDestinyOrCityHook from "./hook";
import ModalFilter from "@/components/Filter/components/ModalFilter/ModalFilter";
import StandardAlert from "@/components/alerts/StandardAlert";
import { metaDataModalAdd } from "../../NoticePlace/placeContainers/hook";

interface Props {
  title: string;
  term?: string;
}

export const AddNewDestiny = (props: Props) => {
  const { title, term="" } = props;
  const {
    showModal,
    handleOnCloseModal,
    handleSubmitModal,
    handleOnSeletedModal,
    handleFilterByTerm,
    modalActiveWith,
    listLocation,
    handleOnClick,
    error,
  } = useAddNewDestinyOrCityHook()

  return (
    <>
      <ModalFilter
        open={showModal}
        onClose={handleOnCloseModal}
        onSubmit={handleSubmitModal}
        onSeleted={handleOnSeletedModal}
        onInputChange={handleFilterByTerm}
        modalActiveWith={modalActiveWith.name}
        list={listLocation}
        betweenDate={""}
        prevSelected={""}
        metaDataModal={metaDataModalAdd}
        nameTwo={!term}
      />
      <>
        {error && (
          <StandardAlert sx={{ marginBottom: "16px" }} severity="error" color="error">
            {error}
          </StandardAlert>
        )}
      </>
      <AddBtnContainer onClick={handleOnClick(term)}>
        <AddCircleIcon />
        <Typography sx={{ pl: 1 }} variant="subtitle1">
          {title}
        </Typography>
      </AddBtnContainer>
    </>
  );
};
