import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { currencyFormat } from "@/utils/helpers";
import { Box, Typography } from "@mui/material";
import { handleTypeMultidestinyOfferNotice } from "./config";

const spanStyles = {
  fontSize: "14px",
  fontFamily: "Source Sans Pro, Roboto",
  fontWeight: 400,

  maxWidth: "320px",
  letterSpacing: "0.00938em",
  marginLeft: "3px",
};

const PriceMultidestiny = ({ data }: { data: NoticeV2NegType | any }) => {
  const render = handleTypeMultidestinyOfferNotice(data);

  return (
    <Box>
      <Typography sx={{ pt: 2, fontWeight: 700, fontSize: "22px" }}>
        {currencyFormat(data.precio)}

        <span style={{ ...spanStyles, wordWrap: "break-word" }}>
          Por {render.type}s
        </span>
      </Typography>
      <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
        Cantidad de {render.type}:
        <span style={{ ...spanStyles, wordWrap: "break-word" }}>
          {render.amount}
        </span>
      </Typography>
      <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
        Precio total:
        <span style={{ ...spanStyles, wordWrap: "break-word" }}>
          {currencyFormat(render.total || 0)}
        </span>
      </Typography>
    </Box>
  );
};

export default PriceMultidestiny;
