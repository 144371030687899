import { convertDate } from "@/adapters/store/editNegotationRequestAdapter";
import LabelRequired from "@/components/utils/LabelRequired";
import { FORMAT_DATE_DDMMYYYY } from "@/consts/formatsDate";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { setActiveButton } from "@/store/customerRequestRedux/customerRequestSlice";
import { uploadDateInMovingRequestEdit } from "@/store/noticeNegotationRequestRedux/editNegRequestThunk";
import { Grid, Typography, styled } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useId, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import DatePicker from "@/components/form/datePicker.tsx/DatePicker";
import Input from "@/components/inputCustom/Input";
import { districtsDeliveryText } from "@/consts/noticeConsts";

const Container = styled(Grid)(
  () => `
    display: flex;
    flex-direction: column;  
    margin-top: 24px;
  `
);

const FormRuteEditMoving = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    customerRequest: { requestDetailsView, isLoading },
  } = useAppSelector((state) => state);
  const idRequest = requestDetailsView?.id.toString();
  const [datePicker, setDatePicker] = useState<Dayjs | null>(
    dayjs().add(1, "day")
  );

  const id = useId();

  useEffect(() => {
    if (requestDetailsView) {
      !!requestDetailsView?.fecha &&
        setDatePicker(dayjs(requestDetailsView?.fecha, FORMAT_DATE_DDMMYYYY));
    }
  }, []);

  const handleSubmit = (event: EventType["submit"]) => {
    event.preventDefault();

    const newDate = convertDate(datePicker as Dayjs);

    const body = { fecha: newDate };

    dispatch(uploadDateInMovingRequestEdit(idRequest as string, body)).then(
      () => {
        navigate(-1);
      }
    );
  };

  const handleCompareinputs = () => {
    const currentInputsValue = [datePicker];
    const dataToInputsValue = [
      dayjs(requestDetailsView?.fecha, FORMAT_DATE_DDMMYYYY),
    ];

    const result =
      JSON.stringify(currentInputsValue) === JSON.stringify(dataToInputsValue);
    return !result;
  };

  const handleDisabledButton = () => {
    const conditions = [datePicker];
    let result = true;

    const conditionsResult = conditions.some((condition) => !condition);
    const sameData = handleCompareinputs();
    dispatch(setActiveButton(sameData));

    if (sameData) {
      result = false;
      if (conditionsResult) {
        result = true;
      }
    }

    return result;
  };

  return (
    <Container container>
      <form onSubmit={handleSubmit}>
        <DatePicker
          fullWidth
          initValue={datePicker}
          inputFormat={FORMAT_DATE_DDMMYYYY}
          label={<LabelRequired str="¿Para cuándo necesitas la mudanza?" />}
          onChange={setDatePicker}
          disablePast={false}
          minDate={dayjs().add(1, "day")}
          renderInput={Input}
        />

        <Typography variant="body2" color="black" sx={{ mt: 2, mb: 0.5 }}>
          <strong>{districtsDeliveryText}</strong>
        </Typography>
        <Typography
          variant="body2"
          color="black"
          sx={{ wordWrap: "break-word" }}
        >
          {requestDetailsView?.destinoTexto}
        </Typography>

        <Grid item sx={{ marginTop: "16px" }}>
          <ButtomLoader
            type="submit"
            isLoading={isLoading}
            buttomProps={{
              disabled: handleDisabledButton() || isLoading,
            }}
          >
            GUARDAR
          </ButtomLoader>
        </Grid>
      </form>
    </Container>
  );
};

export default FormRuteEditMoving;
