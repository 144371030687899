import {
  HOME_PAHT,
  NOTICE_AUCTION,
  NOTICE_BETWEEN_CITIES,
  NOTICE_IN_CITY,
  NOTICE_IN_CITY_PATHS,
  NOTICE_MOVING,
  NOTICE_MOVING_PATHS_CITY,
  NOTICE_MULTI_DESTINY,
  NOTICE_MULTI_DESTINY_HOURS_PATHS,
  NOTICE_TRANSPORT_AUCTION_PATHS,
  NOTICE_TRANSPORT_BETWEEN_CITIES,
} from '@/consts/typeServicesPaths';
import { customerRequestObjectTypes } from '@/pages/customerRequest/shared/consts/customerRequestConsts';

const noticePathsById: Record<number, string> = {
  1: NOTICE_IN_CITY,
  2: NOTICE_MULTI_DESTINY,
  3: NOTICE_BETWEEN_CITIES,
  4: NOTICE_AUCTION,
  5: NOTICE_MOVING,
};

/**
 * Devuelve la ruta del aviso con la identificación dada, o la ruta de inicio si no se encuentra la
 * identificación
 * @param {number} noticeId - número - la identificación del aviso
 * @returns El camino hacia el aviso.
 */
export const getNamePathNoticeByIdNoticeAdapter = (
  noticeId: number,
  defaultPath = HOME_PAHT
) => {
  if (!noticeId) return defaultPath;

  return noticePathsById[noticeId] || defaultPath;
};

/**
 * Toma un enlace, un id de aviso y un id, y devuelve un nuevo enlace con el id del tipo del aviso y el id del aviso
 * Esta {@function} al momento de crearse que necesita que el link tenga una estructura similar {/mis-avisos/:type-notice/:id/ofertas}
 * Se puede verificar un link asi, en esta constante -> PROFILE_NOTICE_OFFERT;
 * @param {string} link - cadena - el enlace a la oferta
 * @param {number} typeNoticeId - número - el id del tipo del aviso
 * @param {number} id - numero - el id de la oferta
 * @returns El link con el tipo de aviso y id, a donde se apunta!!
 */
export const prepareLinkNoticeOffersAdapter = (
  link: string,
  typeNoticeId: number,
  noticeId: number
) => {
  const noticePath = getNamePathNoticeByIdNoticeAdapter(typeNoticeId);
  const newLink = link
    ?.replace(':id', noticeId?.toString())
    ?.replace(':type-notice', noticePath);
  return newLink;
};

const noticePrivateDetail: Record<number, string> = {
  1: NOTICE_IN_CITY_PATHS[6],
  2: NOTICE_MULTI_DESTINY_HOURS_PATHS[7],
  3: NOTICE_TRANSPORT_BETWEEN_CITIES[7],
  4: NOTICE_TRANSPORT_AUCTION_PATHS[5],
  5: NOTICE_MOVING_PATHS_CITY[7],
};
export const getLinkPrivateNoticeDetailByIdAdapter = (
  idDetail: number,
  idNotice: number,
  defaultPath = HOME_PAHT
) => {
  if (!idNotice) return HOME_PAHT;
  const path =
    noticePrivateDetail[idNotice]?.replace(':id', idDetail.toString()) ||
    defaultPath;

  return path;
};

/**
 * Toma un objeto y devuelve un objeto URLSearchParams
 * @param {Object} obj - Objeto: el objeto que se va a convertir en un objeto URLSearchParams.
 * @returns Una función que toma un objeto y devuelve un objeto URLSearchParams.
 */
export const convertObjectToSearchParams = (obj: Object) => {
  const params = new URLSearchParams();
  Object.entries(obj).forEach(([key, value]) => params.append(key, value));

  return params;
};

// customer request
export const getNamePathCustomerRequestByIdNoticeAdapter = (
  noticeId: number,
  defaultPath = HOME_PAHT
) => {
  if (!noticeId) return defaultPath;

  return customerRequestObjectTypes[noticeId] || defaultPath;
};

export const prepareLinkCustomerRequestAdapter = (
  link: string,
  typeCustomerRequest: number,
  idCustomerRequest: string
): string => {
  const custmerPath =
    getNamePathCustomerRequestByIdNoticeAdapter(typeCustomerRequest);
  const newLink = link
    ?.replace(':typeRequest', custmerPath)
    ?.replace(':id', idCustomerRequest);
  return newLink;
};
