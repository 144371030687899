import * as React from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  ContainerModal,
  HeaderModal,
  ContentModal,
  AccionsModal,
  ButtonAccionModal,
  PillButton,
} from "./stylesBannerModal";

type OptionProps = {
  title: string;
  onClick: () => void;
};

type Props = {
  open: boolean;
  onClose: () => void;
  title?: string;
  content?: string;
  option1?: OptionProps;
  option2?: OptionProps;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | "org"
    | undefined;
  sx?: any;
  sxAccion?: any;
};

const BannerModal: React.FC<Props> = ({
  title,
  content,
  option1,
  option2,
  color,
  onClose,
  open,
  sx,
  sxAccion,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ContainerModal sx={{minWidth: "333px", minHeight: "212px", ...sx}}>
        <HeaderModal container>
          <Typography variant="h6">{title}</Typography>
          <IconButton color={color} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </HeaderModal>
        <ContentModal>{content}</ContentModal>
        <AccionsModal container sx={sxAccion}>
          {option1 && (
            <ButtonAccionModal color={color} onClick={option1.onClick}>
              {option1.title}
            </ButtonAccionModal>
          )}
          {option2 && (
            <PillButton
              variant="contained"
              color={color}
              onClick={option2.onClick}
            >
              {option2.title}
            </PillButton>
          )}
        </AccionsModal>
      </ContainerModal>
    </Modal>
  );
};

export default BannerModal;
