import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import SearchModal from "@/components/modals/searchModal/SearchModal";
import {
  ID_COUNTEROFFER,
  ID_MOVING_BETWEEN_CITIES,
} from "@/consts/defaultConsts";
import { textSiguiente } from "@/consts/noticeConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { DetailNoticeMoving } from "@/models/notice/noticeModels";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import SelectsTypeProperties from "@/pages/flowNoticeNegotation/components/SelectsTypeProperties";
import {
  ID_DEPARTAMENT,
  ID_HOUSE,
} from "@/pages/flowNoticeNegotation/shared/const/default";
import {
  defaultInmueble,
  defaultInput,
} from "@/pages/flowNoticeNegotation/shared/const/offertConst";
import { getPreloadData } from "@/pages/flowNoticeNegotation/shared/utils/utilsMoving";
import { getDistrictsByCityAndTermThunk } from "@/store/locationRedux/districtsThunks";
import { getCurrentStoreMoving } from "@/store/noticeV2ClientAcceptCounterOffer/adapter/movingAdapter";
import { setSelectOrigin } from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferSlice";
import {
  getListTypesPropertyThunk,
  saveOriginInCityThunk,
} from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferThunk";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { cleanInputNumber } from "@/utils/inputsUtils";
import { FormControl, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const MAX_LENGTH = 3;

const FormOriginDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    noticeV2ClientAorCO: {
      selectTypeNotice,
      listTypeProperty,
      sendData,
      isLoading,
      selectedOrigin,
      idTypeNoticeNegotiation,
    },
    districts: { districts },
  } = useAppSelector((state) => state);
  const notice = selectTypeNotice as DetailNoticeMoving;
  const isBetweenCities =
    notice.flgciudadoentreciudades === ID_MOVING_BETWEEN_CITIES;
  const isCounterOffer = idTypeNoticeNegotiation === ID_COUNTEROFFER;

  const [openModal, setOpenModal] = useState(false);
  const [selectsForm, setSelectsForm] = useState(defaultInmueble);
  const [inputForm, setInputForm] = useState(defaultInput);

  useEffect(() => {
    handlePreloadData();
    dispatch(getListTypesPropertyThunk());
  }, []);

  const handlePreloadData = () => {
    const currentData = getCurrentStoreMoving(
      sendData,
      notice.flgciudadoentreciudades
    );
    if (!!Object.keys(currentData).length) {
      const preloadInputForm = getPreloadData(currentData, defaultInput);
      const preloadSelectForm = getPreloadData(currentData, defaultInmueble);

      setInputForm((prev) => ({ ...prev, ...preloadInputForm }));
      setSelectsForm((prev) => ({ ...prev, ...preloadSelectForm }));
    }
  };

  const handleOnChange = (target: Record<string, string>) => {
    const { value, name } = target;
    if (name === "typeProperty" && Number(value) === ID_DEPARTAMENT) {
      setSelectsForm(() => ({ ...defaultInmueble, [name]: value }));
    } else {
      setSelectsForm((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleInputChange = ({ target }: EventType["change"]) => {
    let { name, value } = target;
    if (name === "area") {
      value = cleanInputNumber(value);
    }

    setInputForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleNextRute = () => {
    dispatch(
      getNextRoute(navigate, {
        valueToReplace: notice.id?.toString(),
        valueToSearch: ":id",
      })
    );
  };

  const handleSubmit = () => {
    const body = { ...inputForm, ...selectsForm };

    dispatch(saveOriginInCityThunk(body));
    handleNextRute();
  };

  const handleChangeModal = (term: string) => {
    if (term.length >= MAX_LENGTH) {
      const id = notice.idciudad?.toString();
      dispatch(getDistrictsByCityAndTermThunk(id, term));
    }
  };

  const handleOnSubmitModal = (value: Record<string, any> | null) => {
    const body = {
      nombre: value?.nombre,
      precio: 0,
      iddistrito: value?.id,
    };
    setOpenModal(false);
    dispatch(setSelectOrigin(body));
  };

  const handleValidRangeArea = () => {
    const area = Number(inputForm.area || 0);
    const minArea = 1;
    const maxArea = 9999;

    return area >= minArea && area <= maxArea;
  };

  const handleDisable = () => {
    const { ascensor, typeProperty } = selectsForm;
    const { reference } = inputForm;
    const { iddistrito } = selectedOrigin;
    let areaInput = handleValidRangeArea();

    const rulesMultiSelects = [
      !ascensor,
      !typeProperty,
      !reference,
      !areaInput,
    ];
    const rulesBetweenCities = [!typeProperty, !reference, !areaInput];

    if (isBetweenCities) {
      rulesMultiSelects.push(!iddistrito);
      rulesBetweenCities.push(!iddistrito);
    }
    if (Number(typeProperty) !== ID_HOUSE) {
      return rulesMultiSelects.some((rule) => rule);
    }

    return rulesBetweenCities.some((rule) => rule);
  };

  return (
    <div>
      <Typography variant="subtitle1">Origen: {notice.origentexto}</Typography>
      <SearchModal
        open={openModal}
        onClose={setOpenModal}
        onSubmit={handleOnSubmitModal}
        onChange={handleChangeModal}
        list={districts}
        title="Distrito de origen"
        inputProps={{
          name: "search-district",
          label: "Origen",
          placeholder: "Ingresa distrito de origen",
        }}
      />
      {isBetweenCities && (
        <FormControl fullWidth sx={{ marginTop: "16px" }}>
          <Input
            label={<LabelInputRequired str="Distrito de origen" />}
            name="district"
            placeholder="Distrito de origen"
            value={selectedOrigin.nombre}
            onClick={() => setOpenModal(true)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      )}

      <FormControl fullWidth sx={{ marginTop: "16px" }}>
        <Input
          label={<LabelInputRequired str="Referencia del origen" />}
          name="reference"
          placeholder="Lugar conocido cerca a la dirección"
          rows={2}
          multiline
          value={inputForm.reference}
          onChange={handleInputChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>

      <SelectsTypeProperties
        form={selectsForm}
        listTypeProperty={listTypeProperty}
        onChange={handleOnChange}
        isCounterOffer={isCounterOffer}
      />

      <FormControl fullWidth sx={{ marginTop: "16px" }}>
        <Input
          label={<LabelInputRequired str="Área de tu inmueble (m²)" />}
          name="area"
          value={inputForm.area}
          onChange={handleInputChange}
          placeholder="Metros cuadrados de tu casa/oficina"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>

      <FormControl fullWidth>
        <ButtomLoader
          onClick={handleSubmit}
          disabled={handleDisable()}
          isLoading={isLoading}
        >
          {textSiguiente}
        </ButtomLoader>
      </FormControl>
    </div>
  );
};

export default FormOriginDetails;
