import { BASE_URL_IMAGE } from "@/config/config";
import { NOT_FOUND_IMG } from "@/consts/defaultConsts";
import { HOME_PAHT } from "@/consts/typeServicesPaths";
import { Box, Container, Grid } from "@mui/material";
import { To, useNavigate } from "react-router-dom";
import {
  BoxButton,
  ButtonDecline,
  GridButton,
  GridImage,
  Text,
} from "./stylesNotFound";

type Props = {
  title?: string;
  description?: string;
  buttonText?: string;
  buttonLink?: To;
  image?: string;
};

const DEFAULT_TITLE = "Lo sentimos";
const DEFAULT_DESCRIPTION = "No encontramos la página.";
const DEFAULT_BUTTON_TEXT = "IR AL INICIO";

const NotFound = ({
  title,
  description,
  buttonText,
  buttonLink,
  image,
}: Props) => {
  const navigate = useNavigate();
  const imageUrl = `${BASE_URL_IMAGE}${image || NOT_FOUND_IMG}`;

  const handleNavidate = () => {
    navigate(buttonLink || HOME_PAHT);
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container spacing={2}>
        <GridImage item xs={12}>
          <Box
            component="img"
            sx={{
              height: 353,
              width: 350,
            }}
            alt="Imagen de rechazo"
            src={imageUrl}
          />
          <Text sx={{ fontWeight: 900 }}>{title || DEFAULT_TITLE}</Text>
          <Text sx={{ fontSize: "16px" }}>
            {description || DEFAULT_DESCRIPTION}
          </Text>
        </GridImage>
        <GridButton item xs={12}>
          <BoxButton>
            <ButtonDecline fullWidth variant="text" onClick={handleNavidate}>
              {buttonText || DEFAULT_BUTTON_TEXT}
            </ButtonDecline>
          </BoxButton>
        </GridButton>
      </Grid>
    </Container>
  );
};

export default NotFound;
