import { ID_EXPRESS_FLOW, ID_MOVING_FLOW, ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import { IAcuerdosporaceptar } from "../../../models/userAuth/agrementToAccept";
import { infoCardIcons, movingTitle, multiDestinyTitle } from "../rulesByFlow";
import { TipoAviso } from "../styleInfoCard";

type Props = { data: IAcuerdosporaceptar,
  type: "aviso" | "solicitud"
};
const TypeAviso = ({ data, type }: Props) => {
  const handleGetTitle = (): string => {
    if (data.idtipo === ID_MULTI_DESTINY && !!data?.flghoraopunto) {
      return multiDestinyTitle[data.flghoraopunto];
    }
    if (type === "aviso" && data.idtipo === ID_MOVING_FLOW && !!data.flgciudadoentreciudades) {
      return movingTitle[data.flgciudadoentreciudades];
    }

    return data.nombretipo;
  };

  const handleGetIcon = () => {
    const typeId = data.idtipo;
    const isMultiDestiny = typeId === ID_MULTI_DESTINY;
    if (isMultiDestiny && data?.flghoraopunto === 1) {
      return infoCardIcons[typeId].icon2;
    }
    if ((type === "solicitud") &&  (Number(data.idtipo) === ID_EXPRESS_FLOW)) {
      return "icon-tunder--black";
    }

    return infoCardIcons[typeId].icon;
  };

  const icon = handleGetIcon();

  return (
    <TipoAviso variant="body2">
      <i className={icon}></i> {handleGetTitle()}
    </TipoAviso>
  );
};

export default TypeAviso;
