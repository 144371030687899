import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { PROFILE_NOTICE_OFFERT_LIST } from "@/consts/typeServicesPaths";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { Container } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SuccessHeaderDetailsV2 } from "../../components/SuccessHeaderDetailsV2";
import useGetData from "../../shared/hooks/useGetData";
import { SuccessGlobalPrice } from "./components/SuccessGlobalPrice";

export const DriverSuccessDetailsLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const { responseData } = useGetData();
  const {
    idtipoaviso,
  } = responseData as NoticeV2NegType;

  return (
    <Container maxWidth="xs">
      <SuccessHeaderDetailsV2  typeId={idtipoaviso}/>
      {children}
      {<SuccessGlobalPrice data={responseData as NoticeV2NegType} />}
      <ButtomLoader
        variant="contained"
        sx={{ marginTop: "20px", mb: "20px" }}
        onClick={() => navigate(PROFILE_NOTICE_OFFERT_LIST)}
      >
        VER NEGOCIACIONES
      </ButtomLoader>
    </Container>
  );
};
