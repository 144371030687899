import {
  optionFour,
  optionOne,
  optionThree,
  optionTwo,
} from "@/consts/noticeConsts";
import { declineCounterOfferMsg } from "@/consts/notifficationsMessage";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import {
  counterOfferClientAdapter,
  urlFormat,
} from "@/pages/noticeRequestFlow/adapters/noticeRequestFlowAdapters";
import { useButtonsFunctions } from "@/pages/noticeRequestFlow/hooks/useHandleGlobalButtons";
import { manageActionButtonCoCounterOffer } from "@/pages/requestNegotiation/components/globalButtons/config";
import { cocounterRequestClient } from "@/services/dataLayer/createRequestNeg";
import { saveRequestNogotationByIdThunk } from "@/store/noticeNegotationRequestRedux/noticeNegRequesThunk";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { useNavigate } from "react-router-dom";
import { ButtonAccept, ButtonDecline, ContainerButtons } from "./StylesButtons";

const Buttons = () => {
  const { addNotiffication } = useNotiffication();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    user: { user },
    noticeNegotationRequest: { savedDataNogotation },
  } = useAppSelector((state) => state);
  const { handleModalPriceChanged } = useButtonsFunctions();
  const idRequestNegotation = savedDataNogotation?.id as number;
  const idRequestType: string = `${idRequestNegotation}`;
  const flgAbierto = !!savedDataNogotation?.ultimaSolicitudNegociacionAcciones
    ?.tipoSolicitudNegociacionAccion?.estado?.flgAbierto as boolean;
  const userId = (user as IUser).id;
  const typeNegotation = savedDataNogotation?.solicitud.tipoSolicitud
    ?.nombre as string;
  const precioactual = !!savedDataNogotation?.precioContraoferta
    ? savedDataNogotation?.precioContraoferta
    : savedDataNogotation?.precio;

  const handleAcceptButton = (): void => {
    const dataAdapter = counterOfferClientAdapter(
      manageActionButtonCoCounterOffer[optionThree][optionOne]
    );

    const body = {
      ...dataAdapter,
      precioactual,
    };

    dispatch(saveRequestNogotationByIdThunk(idRequestType, body)).then(
      (value) => {
        if (!!value.flgpreciocambiado) {
          handleModalPriceChanged();
        } else {
          cocounterRequestClient(userId, typeNegotation);
          navigate(urlFormat(idRequestType, optionFour, optionThree, 1), {
            replace: true,
          });
        }
      }
    );
  };

  const handleDeclineButton = (): void => {
    const dataAdapter = counterOfferClientAdapter(
      manageActionButtonCoCounterOffer[optionThree][optionTwo]
    );

    const body = {
      ...dataAdapter,
      precioactual,
    };

    dispatch(saveRequestNogotationByIdThunk(idRequestType, body)).then(
      (value) => {
        if (!!value.flgpreciocambiado) {
          handleModalPriceChanged();
        } else {
          navigate(urlFormat(idRequestType, optionFour, optionThree, 3), {
            replace: true,
          });
        }
      }
    );
  };

  const handleManageModalCounterOffer = (): void => {
    addNotiffication({
      title: declineCounterOfferMsg.TITLE,
      message: declineCounterOfferMsg.MESSAGE,
      leftBtnText: declineCounterOfferMsg.LEFT_BTN_TEXT,
      RightBtnText: declineCounterOfferMsg.RIGHT_BTN_TEXT,
      hiddenSnackbar: true,
      hiddenModal: false,
      onSubmit: async () => {
        handleDeclineButton();
      },
    });
  };

  const handleDisabledBtn = () => {
    const flgIsOwner = !!savedDataNogotation?.flgIsOwner;
    const index =
      savedDataNogotation?.ultimaSolicitudNegociacionAcciones
        ?.tipoSolicitudNegociacionAccion?.id;

    let value: Record<number, boolean> = {
      5: flgIsOwner,
      7: flgIsOwner,
    };

    if(!value) return false

    return value[index as number];
  };

  return (
    <ContainerButtons>
      <ButtonAccept
        fullWidth
        variant="contained"
        onClick={() => !handleDisabledBtn() && handleAcceptButton()}
        disabled={handleDisabledBtn() || !flgAbierto}
      >
        ACEPTAR
      </ButtonAccept>

      <ButtonDecline
        fullWidth
        variant="text"
        onClick={() => !handleDisabledBtn() && handleManageModalCounterOffer()}
        disabled={handleDisabledBtn() || !flgAbierto}
      >
        RECHAZAR
      </ButtonDecline>
    </ContainerButtons>
  );
};

export default Buttons;
