export const HEADERS_MULTIPART = {
  "content-type": "multipart/form-data",
  accept: "*",
};

export const HEADER_FORM_DATA = {
  "content-type": "application/x-www-form-urlencoded",
};

export const USER_AUTH = {
  username: "pegacargoweb",
  password: "33549",
};
