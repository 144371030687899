import React from "react";
import { ContainerInfoCard } from "./styleInfoCard";

const SkeletonInfoCard = () => {
  return (
    <ContainerInfoCard
      sx={{
        background: "rgba(0, 0, 0, 0.12)",
        borderRadius: "8px",
      }}
    ></ContainerInfoCard>
  );
};

export default SkeletonInfoCard;
