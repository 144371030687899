import EmptyListData from "@/components/emptyListData/EmptyListData";
import InfoCustomCard from "@/components/infoCard/InfoCustomCard";
import SkeletonInfoCardList from "@/components/skeletons/SkeletonInfoCardList";
import { Grid } from "@mui/material";
import { IAcuerdosporaceptar } from "../../../../models/userAuth/agrementToAccept";

export type TCustomListProps = {
  list: IAcuerdosporaceptar[];
  isLoading: boolean;
  refObserver?: any;
  isLoadingPagination?: boolean;
  type: "aviso" | "solicitud";
  textButton?: string;
};

const MyCustomList = ({
  list,
  isLoading,
  refObserver,
  isLoadingPagination,
  type,
  textButton,
}: TCustomListProps) => {
  if (isLoading) return <SkeletonInfoCardList />;

  if (!list?.length) return <EmptyListData title="No tienes solicitudes" />;

  return (
    <>
      {list?.map((data, index) => (
        <Grid item key={index}>
          <InfoCustomCard
            data={data}
            flgavisosolicitud={type}
            showStatus={true}
            textButton={textButton}
          />
        </Grid>
      ))}

      {isLoadingPagination && <SkeletonInfoCardList />}

      <div ref={refObserver} />
    </>
  );
};

export default MyCustomList;
