import {
  Button,
  Container,
  styled,
  experimental_sx as sx,
} from '@mui/material';

export const ContainerButtonsV2 = styled(Container)(
  sx({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    mt: 2,
    mb: 2,
  })
);

export const ButtonAccept = styled(Button)(
  sx({
    heigth: '44px',
    backgroundColor: '#FF6700',
    borderRadius: '44px',
    mt: '5px',
    '&:hover': {
      backgroundColor: '#FF6700',
    },
  })
);
