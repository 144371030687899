import { ID_MOVING_FLOW, ID_MULTI_DESTINY } from '@/consts/defaultConsts';
import { Negotiation, TypeNoticeOffert } from '@/models/offert/offertModels';
import { DataResponseEnglishTypeTwo } from '@/store/noticeV2ClientAcceptCounterOffer/interfaces/dataResponseEnglishInterfaces';
import { currencyFormat, stringHoursToFormatLT } from '@/utils/helpers';

const ValidDriveRender = (noticeResponse: DataResponseEnglishTypeTwo) => {
  const { negotiationTypeId } = noticeResponse;
  const isMoving = negotiationTypeId === ID_MOVING_FLOW;

  return isMoving;
};

export const getFormatPrice = (
  tipoAvisoNegociacion: number,
  price = 0,
  notice: DataResponseEnglishTypeTwo
) => {
  const isCounterOffert = tipoAvisoNegociacion === 2;
  const formatPrice = currencyFormat(price);
  const isMoving = notice.negotiationTypeId === 5;
  const isMovingCounterOffert = isMoving && tipoAvisoNegociacion === 2;

  if (isMovingCounterOffert) {
    return '';
  } else if (isMoving) {
    let textToReturn = `Cotizado en ${formatPrice}`;
    if (ValidDriveRender(notice)) {
      textToReturn = `${formatPrice}`;
    }
    return textToReturn;
  } else if (isCounterOffert) {
    return `Contraoferta: ${formatPrice}`;
  } else if (notice?.negotiationTypeId === ID_MULTI_DESTINY) {
    const { hours, points } = notice;
    const finalPrice = !!hours
      ? currencyFormat(hours)
      : currencyFormat(points as number);

    return finalPrice;
  }

  return formatPrice;
};

export const getFormatPriceMoving = (
  tipoAvisoNegociacion: number,
  price = 0,
  offert: Negotiation
) => {
  const isCounterOffert = tipoAvisoNegociacion === 2;
  const formatPrice = currencyFormat(price);
  const isMoving = offert.avisoNegociacionAviso?.tipoaviso?.id === 5;
  const isMovingCounterOffert = isMoving && tipoAvisoNegociacion === 2;

  if (isMovingCounterOffert) {
    return `Cotizado en ${formatPrice}`;
  }

  if (isCounterOffert) {
    return `Contraoferta por ${formatPrice}`;
  }

  return formatPrice;
};

export const getFormatSendDate = (
  tipoAvisoNegociacion: number,
  _: any,
  offert: Negotiation
) => {
  const isMoving = offert.avisoNegociacionAviso?.tipoaviso?.id === 5;
  const isMovingCounterOffert = isMoving && tipoAvisoNegociacion === 2;
  const template = `Enviada el :date a las :dateTime`;
  let { fechaRegistro, horaRegistro } = offert;
  horaRegistro = stringHoursToFormatLT(horaRegistro);

  const result = template
    .replace(':date', fechaRegistro)
    .replace(':dateTime', horaRegistro);

  return isMovingCounterOffert ? '' : result;
};

export const getFormatSendDateMoving = (
  tipoAvisoNegociacion: number,
  _: any,
  offert: Negotiation
) => {
  const isMoving = offert.avisoNegociacionAviso?.tipoaviso?.id === 5;
  const isMovingCounterOffert = isMoving && tipoAvisoNegociacion === 2;
  let { fechaRegistro, horaRegistro } = offert;
  const template = `Enviada el :date a las :dateTime`;
  const result = template
    .replace(':date', fechaRegistro)
    .replace(':dateTime', horaRegistro);

  return isMovingCounterOffert ? result : '';
};

export const getTitleByPrice = (
  tipoAvisoNegociacion: number,
  _: any,
  notice: DataResponseEnglishTypeTwo
) => {
  // puntos o horas
  const isHour = !!notice?.hours && notice?.hours > 0;
  const isPoint = !!notice?.points && notice?.points > 0;
  const isMoving = notice.negotiationTypeId === ID_MOVING_FLOW;
  const isMovingOffert = isMoving && tipoAvisoNegociacion === 1;
  const isMovingCounterOffert = isMoving && tipoAvisoNegociacion === 2;

  if (isHour) {
    return 'Precio por hora';
  } else if (isPoint) {
    return 'Precio por punto';
  } else if (isMovingOffert) {
    return 'Precio por viaje';
  } else if (isMovingCounterOffert) {
    let text = 'Por viaje';

    return text; // Que desaparesca el titulo
  } else {
    return 'Precio de punto a punto';
  }
};

export const showOrHiden = (
  tipoAvisoNegociacion: number,
  value: any,
  notice: DataResponseEnglishTypeTwo
) => {
  const isMoving = notice.negotiationTypeId === 5;
  return isMoving ? '' : 'Descripción de la carga';
};

export const getFormatMetro = (
  tipoAvisoNegociacion: number,
  _: any,
  notice: DataResponseEnglishTypeTwo
) => {
  return `${notice?.squareMeters} m².`;
};

export const validShowTitle = (
  tipoAvisoNegociacion: number,
  _: any,
  notice: DataResponseEnglishTypeTwo
) => {
  const { originText, destinyText } = notice;

  if (!originText && !destinyText) {
    return { display: 'none' };
  }
};

const textElevator: Record<number, string> = {
  1: 'ascensor disponible',
  2: 'sin ascensor',
  3: 'ascensor disponible para algunas cosas',
};

export const getOriginInMoving = (
  _: number,
  value: any,
  offert: Negotiation
) => {
  const offer = offert as Negotiation;
  const flgciudadoentreciudades = offer.avisoNegociacionAviso
    .flgciudadoentreciudades as number;
  const inmuebleOrigen = offer?.inmuebleOrigen as TypeNoticeOffert;
  const piso = offer?.piso as number;
  const flgAscensor = offer?.flgAscensor;
  const referenciaOrigen = offer?.referenciaOrigen as string;
  const origenTexto2 = offer?.origenTexto2 as string;
  const origenTexto = offer?.origenTexto as string;

  const originText = flgciudadoentreciudades === 2 ? origenTexto2 : origenTexto;
  const inmueble = (inmuebleOrigen as TypeNoticeOffert)?.nombre;
  const elevator = textElevator[flgAscensor as number];
  let template = `${inmueble} en piso ${piso} (${elevator}) en ${originText} (${referenciaOrigen})`;

  return elevator
    ? template
    : `${inmueble} en ${originText} (${referenciaOrigen})`;
};

export const getDestinyInMoving = (
  _: number,
  value: any,
  offert: Negotiation
) => {
  const flgciudadoentreciudades = offert.avisoNegociacionAviso
    .flgciudadoentreciudades as number;
  const inmuebleDestino = offert?.inmuebleDestino as TypeNoticeOffert;
  const pisoDestino = offert?.pisoDestino as number;
  const flgAscensorDestino = offert?.flgAscensorDestino;
  const referenciaDestino = offert?.referenciaDestino as string;
  const destinoTexto2 = offert?.destinoTexto2 as string;
  const destinoTexto = offert?.destinoTexto as string;

  const destinyText =
    flgciudadoentreciudades === 2 ? destinoTexto2 : destinoTexto;
  const inmueble = (inmuebleDestino as TypeNoticeOffert)?.nombre;
  const elevator = textElevator[flgAscensorDestino as number];
  let template = `${inmueble} en piso ${pisoDestino} (${elevator}) en ${destinyText} (${referenciaDestino})`;

  return elevator
    ? template
    : `${inmueble} en ${destinyText} (${referenciaDestino})`;
};

export const getTitleAvisoOffertIsOwnerCounterOffert =
  (type?: number) => (_: number, value: any, offert: Negotiation) => {
    const { flgIsOwner, avisoNegociacionAviso } = offert;
    const isMoving = (avisoNegociacionAviso?.tipoaviso?.id as number) === 5;
    const textToRenderOne = isMoving ? 'Tu cotización' : 'Tu contraoferta';
    const textToRenderTwo = isMoving
      ? 'Cotización de transportista'
      : 'Contraoferta de transportista';
    if (flgIsOwner === undefined) return '';
    if (type === 1) return flgIsOwner === 1 ? textToRenderOne : textToRenderTwo;
    return flgIsOwner === 1 ? 'Contraoferta del cliente' : 'Tu contraoferta';
  };
