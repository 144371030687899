
interface Props {
    style: {
      color?: string
      height: string
      width: string
    }
  }

const HomePCIcon = (props: Props) => {

    const { color = "#757575", height, width } = props?.style;
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_27657_17848)">
    <path d="M4.49967 20.4999V11.2403L2.63237 12.673L1.73047 11.4769L4.49964 9.34997L5.99964 8.2L11.9996 3.625L22.2688 11.4769L21.3669 12.6576L19.4996 11.2403V20.4999H4.49967ZM5.99964 19H10.8073V14.6153H13.192V19H17.9996V10.0999L11.9996 5.52495L5.99964 10.0999V19Z" fill={color}/>
    </g>
    <defs>
    <clipPath id="clip0_27657_17848">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default HomePCIcon
