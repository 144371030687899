import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import DatePicker from "@/components/form/datePicker.tsx/DatePicker";
import Input from "@/components/inputCustom/Input";
import { ID_COUNTEROFFER } from "@/consts/defaultConsts";
import { FORMAT_DATE_DDMMYYYY, FORMAT_DATE_V2 } from "@/consts/formatsDate";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { DetailNoticeMoving } from "@/models/notice/noticeModels";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { Form } from "@/pages/flowNoticeNegotation/styles/styles";
import { setSelectData } from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferSlice";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { stringDDMMYYYToDate } from "@/utils/helpers";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { InputAdornment, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const minDate = dayjs().add(1, "day");
const maxDate = dayjs().add(1, "month");

const FormDateDetails = () => {
  const { selectTypeNotice, idTypeNoticeNegotiation, selectDate } =
    useAppSelector((state) => state.noticeV2ClientAorCO);

  const [datePicker, setDatePicker] = useState<Dayjs | null>(null);
  const [errorDate, setErrorDate] = useState("");

  const notice = selectTypeNotice as DetailNoticeMoving;
  const isContraoferta = idTypeNoticeNegotiation === ID_COUNTEROFFER;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (selectDate) {
      setDatePicker(stringDDMMYYYToDate(selectDate));
    } else {
      setDatePicker(minDate);
    }
  }, [selectDate]);

  const handleChangeDate = (value: dayjs.Dayjs | null) => {
    setDatePicker(value);
    !!errorDate && setErrorDate("");
  };

  const handleSubmit = (e: EventType["submit"]) => {
    e.preventDefault();

    const selectDate = datePicker?.format(FORMAT_DATE_DDMMYYYY) || "";

    dispatch(setSelectData(selectDate));

    handleNextRute();
  };

  const handleNextRute = () => {
    dispatch(
      getNextRoute(navigate, {
        valueToReplace: `${pathname.split("/")?.[3]}`,
        valueToSearch: ":id",
      })
    );
  };

  const handleErrorDate = (error: string) => {
    !!error && setErrorDate("Fecha invalida");
  };

  const handleDisabled = () => {
    return !datePicker;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <DatePicker
        fullWidth
        variant="outlined"
        minDate={minDate}
        maxDate={maxDate}
        initValue={datePicker}
        inputFormat={FORMAT_DATE_V2}
        label={<LabelInputRequired str="Fecha de mudanza" />}
        showStylesLabel
        onChange={handleChangeDate}
        onError={handleErrorDate}
        renderInput={Input}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CalendarMonthOutlinedIcon />
            </InputAdornment>
          ),
          endAdornment: null,
        }}
      />
      {!!errorDate && (
        <Typography variant="caption" color="#d32f2f">
          {errorDate}
        </Typography>
      )}
      {!isContraoferta && (
        <Typography variant="body1" color="black">
          El servicio incluye {notice?.ayudantes || 0} ayudantes.
        </Typography>
      )}

      <ButtomLoader
        sx={{ marginTop: "16px" }}
        type="submit"
        disabled={handleDisabled()}
      >
        Siguiente
      </ButtomLoader>
    </Form>
  );
};

export default FormDateDetails;
