import { Stack, styled } from "@mui/material";

const InfoContent = styled(Stack)(
  () => `
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 40px;
  `
);

type Props = {
  icon?: string;
  description?: string;
};

const defaultProps: Props = {
  icon: "icon-alert-info--size18",
  description: "aqui tu alerta",
};

const AlertInfoCard = ({
  icon = defaultProps.icon,
  description = defaultProps.description,
}: Props) => {
  return (
      <InfoContent >
        <i className={icon} />
        <span>{description}</span>
      </InfoContent>
  );
};

export default AlertInfoCard;
