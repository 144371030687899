import { useRef } from 'react';
import * as io from 'socket.io-client';

const emptyConfig: SocketIoConfig = {
  url: '',
  options: {
    autoConnect: false,
  },
};

const useSocketIO = (config: SocketIoConfig) => {
  const ioSocket = useRef<io.Socket | null>(null);
  const subscribersCounterRef = useRef<Record<string, number>>({});
  const eventCallbacksRef = useRef<Record<string, Function[]>>({});

  const connectSocket = () => {
    const { url, options } = config || emptyConfig;
    const ioFunc = (io as any).default ? (io as any).default : io;
    const socket = ioFunc(url, options);
    socket.connect();

    socket.on('connect_error', (error: Error) => {
      console.error('error >>>>', error);
    });

    ioSocket.current = socket;
  };

  const disconnectSocket = () => {
    if (!ioSocket.current) return;
    ioSocket.current.disconnect();
    ioSocket.current = null;
  };

  const on = (eventName: string, callback: any) => {
    if (!ioSocket.current) return;
    if (!eventCallbacksRef.current[eventName]) {
      eventCallbacksRef.current[eventName] = [];
    }
    eventCallbacksRef.current[eventName].push(callback);

    ioSocket.current && ioSocket.current.on(eventName, callback);

    if (!subscribersCounterRef.current[eventName]) {
      subscribersCounterRef.current[eventName] = 0;
    }
    subscribersCounterRef.current[eventName]++;

    return () => {
      if (!ioSocket.current) return;
      const index = eventCallbacksRef.current[eventName].indexOf(callback);
      if (index !== -1) {
        eventCallbacksRef.current[eventName].splice(index, 1);
      }
      ioSocket.current.removeListener(eventName, callback);

      subscribersCounterRef.current[eventName]--;
      if (subscribersCounterRef.current[eventName] === 0) {
        delete eventCallbacksRef.current[eventName];
      }
    };
  };

  const once = (eventName: string, callback: any) => {
    if (!ioSocket.current) return;
    ioSocket.current.once(eventName, callback);
  };

  const emit = (eventName: string, ...args: any[]) => {
    if (!ioSocket.current) return;
    ioSocket.current.emit(eventName, ...args);
  };

  const disconnect = () => {
    if (!ioSocket.current) return;
    ioSocket.current.disconnect();
  };

  const off = (eventName?: string, listener?: Function[]) => {
    if (!ioSocket.current) return;
    if (!eventName) {
      ioSocket.current.removeAllListeners();
      eventCallbacksRef.current = {};
      subscribersCounterRef.current = {};
    } else if (eventName && !listener) {
      ioSocket.current.removeAllListeners(eventName);
      delete eventCallbacksRef.current[eventName];
      delete subscribersCounterRef.current[eventName];
    } else {
      if (!ioSocket.current) return;
      listener?.forEach((l: any) => {
        if (!ioSocket.current) return;
        ioSocket.current.removeListener(eventName, l);
        const index = eventCallbacksRef.current[eventName].indexOf(l);
        if (index !== -1) {
          eventCallbacksRef.current[eventName].splice(index, 1);
        }
      });
      subscribersCounterRef.current[eventName] -= listener?.length || 0;
      if (subscribersCounterRef.current[eventName] <= 0) {
        delete eventCallbacksRef.current[eventName];
        delete subscribersCounterRef.current[eventName];
      }
    }
  };

  return { on, once, emit, disconnect, off, connectSocket, disconnectSocket };
};

export default useSocketIO;

export interface SocketIoConfig {
  url: string;
  options?: {
    path?: string;
    reconnection?: boolean;
    reconnectionAttempts?: number;
    reconnectionDelay?: number;
    reconnectionDelayMax?: number;
    randomizationFactor?: number;
    timeout?: number;
    autoConnect?: boolean;
    query?: {
      [key: string]: string | null;
    };
    parser?: any;
    upgrade?: boolean;
    forceJSONP?: boolean;
    jsonp?: boolean;
    forceBase64?: boolean;
    enablesXDR?: boolean;
    timestampRequests?: boolean;
    timestampParam?: string;
    policyPort?: number;
    transports?: string[];
    transportOptions?: any;
    rememberUpgrade?: boolean;
    onlyBinaryUpgrades?: boolean;
    requestTimeout?: number;
    protocols?: any;
    auth?: { [key: string]: any } | ((cb: (data: object) => void) => void);
    withCredentials?: boolean;
    extraHeaders?: {
      [header: string]: string;
    };
    closeOnBeforeunload?: boolean;
    forceNew?: boolean;
  };
}
