import { Box, Tab, Tabs, styled } from "@mui/material";

export const MainTags = styled(Tabs)(({ theme }) => ({
    "& .MuiTabs-indicator": {
        backgroundColor: theme.palette.warning.main,
    },
}));

export const TabItem = styled(Tab)(({ theme }) => ({
    color: "rgba(0, 0, 0, 0.54)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    "&.Mui-selected": {
        color: theme.palette.warning.main,
    },
    "&.Mui-focusVisible": {
        backgroundColor: "red",
    },
}));


export const TabPanelBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap"
  }));