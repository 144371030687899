import { useAppSelector } from "@/hooks/useReducer";
import { DetailsNegotationV2 } from "@/models/notice/noticeV2NegotationModels";
import { useNavigate } from "react-router-dom";

interface ValuesToReplace {
  search: string;
  value: keyof DetailsNegotationV2;
  defaultResult: string;
}

export interface ValidFormat {
  template: string;
  valuesToReplace: ValuesToReplace[];
}

export interface ConfigDataNegotationV2Valid {
  property: keyof DetailsNegotationV2;
  componentProp: string;
  format?: ValidFormat;
  defaultResult: boolean | string | number | Array<any> | Object;
  callback?: (negotiation: DetailsNegotationV2, aditionalsParams?: any) => any;
  callbakcParams?: any;
}

export interface ConfigDataNegotationV2 {
  title?: string;
  Component: any;
  props: any;
  children?: JSX.Element | JSX.Element[] | string;
  valid?: ConfigDataNegotationV2Valid[];
}

const useNoticeConfigDataV2 = ( notice : DetailsNegotationV2) => {
  const navigate = useNavigate();
  const {
    user: { user },
  } = useAppSelector((state) => state);

  const handleValid = ({ valid, props }: ConfigDataNegotationV2) => {
    if (!valid || !notice) return { props };

    const moreProps = valid.reduce((acc, el) => {
      let value = (notice as any)[el.property];

      if (el?.callback) {
        value = el.callback(notice, el?.callbakcParams || user);
      }

      if (typeof el.defaultResult === "boolean") {
        acc = {
          ...acc,
          [el.componentProp]: !!value,
        };
      } else {
        acc = {
          ...acc,
          [el.componentProp]: value || el.defaultResult,
        };
      }
      return acc;
    }, {});

    return { props: { ...props, ...moreProps } };
  };

  const handleInitConfig = (config: ConfigDataNegotationV2) => {
    let moreProps = handleValid(config);

    return { ...moreProps };
  };

  const handleNavigationBack = () => {
    navigate(-1);
  };

  return {
    handleValid,
    handleInitConfig,
    handleNavigationBack,
  };
};

export default useNoticeConfigDataV2;
