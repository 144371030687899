import DetailsTextV2 from '@/components/DetailsTextV2/Index';
import { ImagesNegV2 } from '@/pages/noticeV2Negotation/components/ImagesNegV2';
import SingleUserNegV2 from '@/pages/noticeV2Negotation/components/SingleUserNegV2';
import { ConfigDataNegotationV2 } from '@/pages/noticeV2Negotation/shared/hooks/useNoticeConfigDataV2';
import {
  getImageTruckInRatingNoticeNegV2,
  getUrlAvatarProfileNoticeNeg,
  getUserInfoNoticeNegV2,
  validUserNoticeNegV2,
} from '@/pages/noticeV2Negotation/shared/utils/utils';
import { Typography } from '@mui/material';

export const generalSuccessBtnCitiesConfigData: ConfigDataNegotationV2[] = [
  {
    Component: SingleUserNegV2,
    props: {
      containerSx: { mt: '16px' },
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'urlProfile',
        defaultResult: '',
        callback: getUrlAvatarProfileNoticeNeg,
      },
      {
        property: 'cliente',
        componentProp: 'showTwoAvatars',
        defaultResult: '',
        callback: validUserNoticeNegV2,
      },
      {
        property: 'cliente',
        componentProp: 'urlTruck',
        defaultResult: '',
        callback: getImageTruckInRatingNoticeNegV2,
      },
      {
        property: 'cliente',
        componentProp: 'userInfo',
        defaultResult: '',
        callback: getUserInfoNoticeNegV2,
      },
    ],
  },
  {
    title: 'Detalles del servicio',
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
      sx: { marginTop: '10px', display: 'block' },
    },
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha',
      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
      containerSx: {
        alignItems: 'flex-start',
      },
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'origenTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referenciaOrigen',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-dark',
      title: 'Destino',
      containerSx: {
        alignItems: 'flex-start',
      },
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'destinoTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },

  {
    title: 'Descripción de la carga',
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
      sx: { marginTop: '10px', display: 'block' },
    },
  },
  {
    Component: ImagesNegV2,
    props: {
      style: { marginTop: '13px' },
    },
    valid: [
      {
        property: 'fotos',
        componentProp: 'images',
        defaultResult: [],
      },
    ],
  },

  {
    Component: Typography,
    props: {
      sx: {
        marginTop: '10px',
        display: 'block',
        wordWrap: 'break-word',
        maxWidth: '320px',
        fontWeight: 'none',
      },
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
];
