import { useAppSelector } from '@/hooks/useReducer';
import React from 'react'
import { objectRenderComponent } from './config';

const ContentSuccess = () => {
    const { selectedTypeRequest:{
        id: idTypeRequest
    } } = useAppSelector((state) => state.customerRequest);

    const Component = objectRenderComponent[idTypeRequest || 1];
    if (!Component) return <></>;
  
    return <Component />;
}

export default ContentSuccess