import { detailsRatingAgreementAdapter } from "@/adapters/store/ratingAgreementAdapter";
import BasicAlert from "@/components/alerts/BasicAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import SwitchControl from "@/components/form/switchControl/SwitchControl";
import HeaderSection from "@/components/headerSection/HeaderSection";
import SkeletonCustomGroup from "@/components/skeletons/SkeletonCustomGroup";
import CustomAvatarRating from "@/components/userRating/CustomAvatarRating";
import { NOTICE_NOT_FOUND } from "@/consts/defaultConsts";
import {
  PROFILE_NOTICE_LIST_PATH,
  REQUESTS_LANDING_PATH,
  SERVICES_LANDING_PATH,
} from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { MY_APPLICATIONS_CUSTOMER_REQUEST_PATH } from "@/pages/customerRequest/shared/consts/routes";
import {
  resetRatingAgreement,
  updateRatingAgreementDetail,
} from "@/store/ratingAgreementRedux/ratingAgreementSlice";
import {
  getRatingAgreementThunk,
  sendRatingAgreementThunk,
} from "@/store/ratingAgreementRedux/ratingAgreementThunk";
import {
  Container,
  FormControl,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  NavigateFunction,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  Calificacione,
  Detalle,
} from "../../models/ratingAgreement/ratingAgrementModel";
import { currencyFormat, deleteDomainUrl } from "../../utils/helpers";
import {
  ButtonSelectDateFilter,
  ContainerListButtons,
} from "../landing/services/styleServices";
import NotFound from "../notFound/NotFound";
import DetailsText from "../offerts/offertConfirmation/components/DetailsText";
import NoAccess from "../noAccess/NoAccess";

const RatingAgreement = () => {
  const dispatch = useAppDispatch();
  const {
    ratingAgreement: {
      puntaje,
      detailRatingAgreement,
      ratingAgreementData,
      isLoading,
      error,
    },
  } = useAppSelector((state) => state);
  const [adData, setAdData] = useState<Calificacione | null>(null);
  const [rating, setRating] = useState<number>(0);
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { idOffert = "" } = useParams();
  const isAviso =
    pathname?.startsWith(PROFILE_NOTICE_LIST_PATH) ||
    pathname?.startsWith(SERVICES_LANDING_PATH);
  const isSolicitud =
    pathname?.startsWith(MY_APPLICATIONS_CUSTOMER_REQUEST_PATH) ||
    pathname?.startsWith(REQUESTS_LANDING_PATH);
  const isInitialLocation: boolean = location.key === "default";
  const arrowTitle =
    (adData?.titulo
      ?.toLowerCase()
      .replace("califica al", "Calificar") as string) || "";

  useEffect(() => {
    dispatch(
      getRatingAgreementThunk({
        idAviso: isAviso ? Number(idOffert) : 0,
        idSolicitud: isSolicitud ? Number(idOffert) : 0,
      })
    );
    return () => {
      dispatch(resetRatingAgreement());
      setRating(0);
    };
  }, [location?.pathname]);

  useEffect(() => {
    if (ratingAgreementData) {
      setAdData(ratingAgreementData as Calificacione);
      setRating(puntaje);
    }
  }, [ratingAgreementData]);

  if (isNaN(Number(idOffert))) {
    return (
      <NotFound
        title="¡Lo sentimos!"
        description="El aviso no está disponible."
        buttonText="VOLVER AL INICIO"
        buttonLink={"/"}
        image={NOTICE_NOT_FOUND}
      />
    );
  }
  if (!adData || isLoading)
    return (
      <Container sx={{ mt: 10 }} maxWidth="xs">
        <SkeletonCustomGroup
          skeletonList={[
            { width: "100%", height: 32 },
            { width: "100%", height: 72 },
            { width: "100%", height: 136 },
            { width: "100%", height: 136 },
          ]}
          gap={2}
        />
      </Container>
    );
  const handlePrepareList = (listItem: Detalle, isActive: number) => {
    const positionIndex = detailRatingAgreement?.findIndex(
      (item) => item.id === listItem.id
    );

    let currentList = detailRatingAgreement?.map((item) => {
      if (listItem.id === item.id) {
        return {
          ...item,
          flgCheck: isActive,
        };
      }

      return item;
    });

    if (positionIndex === 0 && isActive === 0) {
      currentList = currentList?.map((item) => {
        if (item.flgNegativo === 0) {
          return {
            ...item,
            flgCheck: 0,
          };
        }
        return item;
      });
    }

    return currentList;
  };
  const handleChangeSwtich = (listItem: Detalle, isActive: number) => {
    if (adData.flgPuedeModificar === 0) return;
    const currentList = handlePrepareList(listItem, isActive);
    dispatch(updateRatingAgreementDetail(currentList));
  };
  // puntaje
  const handleRating = (
    event: React.ChangeEvent<{}>,
    newValue: number | null
  ) => {
    setRating(newValue as number);
  };

  const validate = (): boolean => {
    if (adData.flgPuedeModificar === 0) return true;

    if (rating === 0 || rating === null) {
      return true;
    }
    if (rating <= 2 && rating >= 1) {
      const list = detailRatingAgreement?.filter(
        (item) => item.flgNegativo === 1
      );
      const listCheck = list?.filter((item) => item.flgCheck === 1);
      if (listCheck?.length === 0) {
        return true;
      }
      return false;
    }

    return false;
  };

  const handleSubmit = () => {
    let details = detailRatingAgreement;

    // si el rating es mayor a 2 se cambia todos los que sean flgNegativo y su flgCheck a 0
    if (rating > 2) {
      details = detailRatingAgreement?.map((item) => {
        if (item.flgNegativo === 1) {
          return {
            ...item,
            flgCheck: 0,
          };
        }
        return item;
      });
    }

    dispatch(
      sendRatingAgreementThunk({
        idAvisoNegociacion: isAviso ? Number(idOffert) : 0,
        idAvisoSolicitud: isSolicitud ? Number(idOffert) : 0,
        puntaje: rating,
        detalle: detailsRatingAgreementAdapter(details),
      })
    ).then((res) => {
      navigate(pathname.replace(/[^/]*$/, "calificacion-confirmacion"), {
        state: { message: "calification successfuly" },
      });
    });
  };

  if (!!error) {
    return <NoAccess />;
  }

  return (
    <Container maxWidth="xs" sx={{ mb: 3, mt: 3 }}>
      {!isInitialLocation && (
        <HeaderSection
          icon="icon-arrow-left--primary"
          title={arrowTitle as string}
          onClick={() => navigate(-1)}
          sx={{
            boxShadow: "none",
            display: "flex",
            justifyContent: "start",
          }}
        />
      )}
      <BasicAlert
        color="warning"
        severity="warning"
        open={!!adData?.mensaje}
        sx={{ marginBottom: "20px" }}
      >
        {adData?.mensaje}
      </BasicAlert>
      <CustomAvatarRating
        viewRating={false}
        idUser={adData.id}
        showTwoAvatars={!!adData.perfil2}
        userName={adData.calificado}
        urlProfile={adData.perfil1}
        urlTruck={adData?.perfil2 && deleteDomainUrl(adData?.perfil2, false)}
      />
      <DetailsText
        icon="icon-calendar"
        title="Fecha del servicio"
        origen={adData?.fecha}
        show={!!adData?.fecha}
        utilsIcon
      />
      <DetailsText
        icon="icon-location-light"
        title="Origen"
        origen={adData?.origen}
        show={!!adData?.origen}
        utilsIcon
      />
      <DetailsText
        icon="icon-location-dark"
        title="Destino"
        origen={adData?.destino}
        show={!!adData?.destino}
        utilsIcon
      />
      {/* tipo */}
      <Typography variant="body1" sx={{ marginTop: 2 }}>
        {adData?.tipoprecio}
      </Typography>
      {/* precio */}
      <Typography variant="h5" sx={{ textTransform: "inherit" }}>
        Precio acordado: {currencyFormat(adData?.precio || 0)}
      </Typography>
      <Stack gap="2px" sx={{ marginTop: 2 }}>
        {detailRatingAgreement?.map(
          (condition) =>
            condition.flgNegativo === 0 && (
              <SwitchControl
                key={condition.id}
                sx={{ marginTop: "2px" }}
                text={condition.nombre}
                onChange={(isActive) => handleChangeSwtich(condition, isActive)}
                value={!!condition.flgCheck}
                disabled={adData.flgPuedeModificar === 0}
              />
            )
        )}
      </Stack>
      <Typography
        variant="label1"
        sx={{ marginTop: 3, display: "flex", justifyContent: "center" }}
      >
        ¿Cómo calificas al {!!adData.perfil2 ? "transportista" : "cliente"}?
      </Typography>
      <Typography
        variant="label1"
        sx={{ marginTop: 1, display: "flex", justifyContent: "center" }}
      >
        <Rating
          name="half-rating"
          defaultValue={puntaje}
          value={rating}
          precision={1}
          onChange={handleRating}
          disabled={adData.flgPuedeModificar === 0}
        />
      </Typography>
      {rating <= 2 && rating >= 1 && (
        <>
          <Typography
            variant="subtitle2"
            sx={{
              marginTop: 3,
              display: "flex",
              justifyContent: "center",
              fontWeight: 370,
            }}
          >
            Oh no! Ayúdanos a mejorar, ¿qué salió mal?
          </Typography>
          <ContainerListButtons sx={{ mt: 2 }}>
            {detailRatingAgreement?.map(
              (condition, index) =>
                condition.flgNegativo === 1 && (
                  <ButtonSelectDateFilter
                    className={condition.flgCheck === 1 ? "active" : ""}
                    onClick={() =>
                      handleChangeSwtich(
                        condition,
                        condition.flgCheck === 1 ? 0 : 1
                      )
                    }
                    key={index}
                    sx={{
                      padding: "2px 12px 2px 12px",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "305",
                    }}
                  >
                    {condition.nombre}
                  </ButtonSelectDateFilter>
                )
            )}
          </ContainerListButtons>
        </>
      )}

      <FormControl fullWidth>
        <ButtomLoader
          onClick={handleSubmit}
          disabled={validate() || isLoading}
          isLoading={isLoading}
        >
          Enviar calificación
        </ButtomLoader>
      </FormControl>
    </Container>
  );
};

export default RatingAgreement;
