export const cleanInputNumber = (str: string) => {
  const newString = str.replace(/\D/g, '');

  return newString;
};

export const cleanInputString = (str: string): string => {
  if (!str) return '';

  const newString = str.replace(/[^A-Za-z ÁÉÍÓÚáéíóúÑñ,]/g, '');
  return newString;
};

// valid email
export const validEmail = (email: string) => {
  const rgx = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
  return rgx.test(email);
};

export const handleReplaceCharacters = (value: string) => {
  return value.replace(/^(0+)/g, '').replace(/[^0-9]/g, '');
};
