import { useAppSelector } from '@/hooks/useReducer';
import SkeletonViewService from '@/pages/loading/SkeletonViewService';
import { ISolicitudNegociacion } from '@/services/interfaces/RequestNegResponseSuccess';
import { objectRenderComponentFinishedReject } from './config';

const RenderBodyNegReject = () => {
    const { noticeNegotationRequest: { errorCode, isLoading: loading, savedDataNogotation } } = useAppSelector((state) => state);

    const requestData = savedDataNogotation as ISolicitudNegociacion;
    if (!requestData) return (<SkeletonViewService />);
    const {
        solicitud: {
            tipoSolicitud: {
                id: idSolicitud,
            }
        }
    } = requestData;
    const Component = objectRenderComponentFinishedReject[idSolicitud];
    if (!Component) return <></>;
    return <Component />;
}

export default RenderBodyNegReject
