import IconsSvg from "@/pages/detailNotice/components/IconsSvg";
import Icon, { IconTypes } from "@/utils/Icon";
import { Theme } from "@emotion/react";
import { Stack, styled, SxProps, Typography } from "@mui/material";
import { FC } from "react";

const Title = styled(Typography)(
  ({ theme }) => `
      color: ${theme.palette.text.secondary};
      font-weight: bold;
    `
);

const ConatinerDetails = styled(Stack)(
  () => `
    justify-content: space-between;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
  `
);

export type DetailsTextProps = {
  show: boolean;
  title: string;
  origen: string;
  icon: string;
  constainerSx?: SxProps<Theme>;
  utilsIcon?: boolean;
};

const DetailsText: FC<DetailsTextProps> = ({
  show,
  title,
  origen,
  icon,
  constainerSx,
  utilsIcon,
}) => {
  if (!show || !origen) return <></>; // El !origen es para todos, cuando venga vacio no pinte nada el componente

  return (
    <ConatinerDetails sx={constainerSx}>
      <Stack flexDirection="row" alignItems="center">
        {icon && (
          <>
            {utilsIcon ? (
              <Icon name={icon as IconTypes} size="md" />
            ) : (
              <IconsSvg icon={icon}></IconsSvg>
            )}
          </>
        )}
        <Title variant="label1" sx={{ marginLeft: icon ? "8px" : "0" }}>
          {title}
        </Title>
      </Stack>

      <Stack flex={1} marginLeft="4px">
        <Typography
          variant="body1"
          sx={{ wordWrap: "break-word", maxWidth: "320px" }}
        >
          {origen}
        </Typography>
      </Stack>
    </ConatinerDetails>
  );
};

export default DetailsText;
