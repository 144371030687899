import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import { optionFive } from "@/consts/noticeConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { ISolicitudViewDetailsResponse } from "@/models/customerRequest/requestDetailsVewResponse";
import SectionOriginDestiny from "@/pages/createServices/fee/SectionOriginDestiny";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { setActiveButton } from "@/store/customerRequestRedux/customerRequestSlice";
import { uploadPriceRequestEdit } from "@/store/noticeNegotationRequestRedux/editNegRequestThunk";
import { handleReplaceCharacters } from "@/utils/inputsUtils";
import { FormControl } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const FormPriceEditBetweenCities = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [price, setPrice] = useState("");
  const [origin, setOrigin] = useState("");
  const [destiny, setDestiny] = useState("");
  const { isLoading, requestDetailsView } = useAppSelector(
    (state) => state.customerRequest
  );
  const idRequest = requestDetailsView?.id.toString();
  const data = requestDetailsView as ISolicitudViewDetailsResponse;

  useEffect(() => {
    if (data) {
      setPrice(data?.precio.toString());
      setOrigin(data?.origenTexto);
      setDestiny(data?.destinoTexto);
    }
  }, []);

  const handleOnchange = ({ currentTarget }: EventType["change"]) => {
    const { value } = currentTarget;
    const prepareValue = handleReplaceCharacters(value);

    if (prepareValue.length < optionFive) {
      setPrice(prepareValue);
    }
  };

  const handleSubmit = (event: EventType["submit"]) => {
    event.preventDefault();
    const precio = Number(price);

    const body = { precio };

    dispatch(uploadPriceRequestEdit(idRequest as string, body)).then(() =>
      navigate(-1)
    );
  };

  const handleCompareinputs = () => {
    const result = price === requestDetailsView?.precio.toString();
    return !result;
  };

  const handleDisabledButton = () => {
    const conditions = [price];
    let result = true;

    const conditionsResult = conditions.some((condition) => !condition);
    const sameData = handleCompareinputs();
    dispatch(setActiveButton(sameData));

    if (sameData) {
      result = false;
      if (conditionsResult) {
        result = true;
      }
    }

    return result;
  };

  return (
    <>
      <SectionOriginDestiny destiny={destiny} origin={origin} />
      <FormControl sx={{ marginTop: "16px", width: "100%" }}>
        <Input
          name="price"
          label={<LabelInputRequired str="Precio ofrecido (S/)" />}
          value={price}
          variant="standard"
          placeholder="Lo que pagarías por este servicio, Ej. 50"
          onChange={handleOnchange}
          onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            inputMode: "numeric",
          }}
        />
      </FormControl>
      <ButtomLoader
        onClick={handleSubmit}
        sx={{ marginTop: "24px" }}
        isLoading={isLoading}
        buttomProps={{
          disabled: handleDisabledButton() || isLoading,
        }}
      >
        guardar
      </ButtomLoader>
    </>
  );
};

export default FormPriceEditBetweenCities;
