import { BASE_URL_IMAGE } from '@/config/config';
import {
  DRIVER_HERO_LG,
  DRIVER_HERO_LOGIN_LG,
  DRIVER_HERO_LOGIN_MD,
  DRIVER_HERO_LOGIN_SM,
  DRIVER_HERO_MD,
  DRIVER_HERO_SM,
} from '@/consts/defaultConsts';
import {
  ADS_SOON_SPONSORED_LANDING_PATH,
  ENTER_THE_WEB,
  REQUESTS_LANDING_PATH,
  SERVICES_LIST_DRIVER,
} from '@/consts/typeServicesPaths';
import { useAppSelector } from '@/hooks/useReducer';
import { useNavigate } from 'react-router-dom';
import {
  KeepCreatingSvg,
  LandCarrierSvg,
  TheyAreLookingForYouSvg,
} from './svg';
import { IdataCarrier } from './type';
import BANNER_DRIVER_XS from '@/img/landing/drivers/BANNER-TRANSPORTISTA-1.jpg';
import BANNER_DRIVER_MD from '@/img/landing/drivers/BANNER-TRANSPORTISTA-2.jpg';
import BANNER_DRIVER_LG from '@/img/landing/drivers/BANNER-TRANSPORTISTA-3.jpg';

interface IRetunrHook {
  data: IdataCarrier;
  onClickButton: (link?: string) => void;
}

const useCarrierHook = (): IRetunrHook => {
  const navigate = useNavigate();

  const { user } = useAppSelector((state) => state.user);

  const handleNavigate = (link?: string) => {
    if (link) {
      navigate(link);
    }
  };

  return {
    data: Object.keys(user).length === 0 ? dataLogout : dataSignIn,
    onClickButton: handleNavigate,
  };
};

export default useCarrierHook;

const dataLogout: IdataCarrier = {
  banner: {
    urlImg: `${BASE_URL_IMAGE}${DRIVER_HERO_SM}`,
    title: 'genera más ingresos sin pagar comisión',
    description:
      'Encuentra nuevos clientes registrándote gratis como transportista. Ofrece tus servicios de transporte de carga dentro y fuera de la ciudad.',
    buttonText: 'registrarme',
    marginTop: '55px',
    responsive: [
      {
        cssProperties: [
          {
            property: 'backgroundImage',
            value: `url(${BASE_URL_IMAGE}${DRIVER_HERO_MD})`,
          },
          { property: 'minHeight', value: '240px' },
          { property: 'maxHeight', value: '260px' },
        ],
        mediaMinWidth: '961px',
      },
      {
        cssProperties: [
          {
            property: 'backgroundImage',
            value: `url(${BASE_URL_IMAGE}${DRIVER_HERO_LG})`,
          },
          { property: 'minHeight', value: '320px' },
          { property: 'maxHeight', value: '340px' },
        ],
        mediaMinWidth: '1280px',
      },
    ],
    btnLink: ENTER_THE_WEB,
  },
  section: {
    title: '¿Por qué registrarme como transportista?',

    items: [
      {
        title: '',
        items: [
          'Consigue clientes nuevos desde la comodidad de tu casa.',
          'Los clientes te pagan directamente.',
          'NO pagas membresía, comisión o plan mensual.',
          'Vende los espacios vacíos  de tu vehículo cuando transportas carga de una ciudad a otra y evita el falso flete.',
          'Crea avisos de transporte para ofrecer tus servicios.',
          'Sé parte del primer buscador digital de transportistas de carga de Latinoamérica.',
        ],
        icon: true,
        IconSvg: LandCarrierSvg,
        buttonText: 'registrarme',
        btnLink: ENTER_THE_WEB,
      },
    ],
  },
};

const dataSignIn: IdataCarrier = {
  banner: {
    urlImg: `${BANNER_DRIVER_XS}`,
    title: 'AVISOS DESTACADOS',
    description:
      'Haz que tus avisos aparezcan en los primeros lugares del buscador',
    buttonText: 'impulsa tus avisos',
    marginTop: '75px',
    responsive: [
      {
        cssProperties: [
          {
            property: 'backgroundImage',
            value: `url(${BANNER_DRIVER_MD})`,
          },
          { property: 'minHeight', value: '240px' },
          { property: 'maxHeight', value: '260px' },
        ],
        mediaMinWidth: '961px',
      },
      {
        cssProperties: [
          {
            property: 'backgroundImage',
            value: `url(${BANNER_DRIVER_LG})`,
          },
          { property: 'minHeight', value: '320px' },
          { property: 'maxHeight', value: '340px' },
        ],
        mediaMinWidth: '1280px',
      },
    ],
    btnLink: ADS_SOON_SPONSORED_LANDING_PATH,
  },
  section: {
    title: '',
    items: [
      {
        title: 'sigue creando avisos',
        items: [
          'Crea la cantidad necesaria de avisos relacionados con los servicios de transporte de carga que ofreces dentro y fuera de la ciudad.',
        ],
        icon: false,
        IconSvg: KeepCreatingSvg,
        buttonText: 'crear avisos',
        btnLink: SERVICES_LIST_DRIVER,
      },
      {
        title: 'te están buscando',
        items: [
          'Revisa las solicitudes de transporte de carga creadas por los clientes.',
        ],
        icon: false,
        IconSvg: TheyAreLookingForYouSvg,
        buttonText: 'ver solicitudes',
        rowReverse: true,
        btnLink: REQUESTS_LANDING_PATH,
      },
    ],
  },
};
