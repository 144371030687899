import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import HeaderSection from "@/components/headerSection/HeaderSection";
import Input from "@/components/inputCustom/Input";
import BannerModal from "@/components/modals/BannerModal/BannerModal";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import { optionFive, optionOne, optionThree, optionTwo } from "@/consts/noticeConsts";
import {
  TEXT_ERROR_MAX_PRICE,
  TEXT_ERROR_MIN_PRICE,
} from "@/consts/textErrors";
import { SUCCESS_TEXT_EDIT_PRICE } from "@/consts/textSuccess";
import {
  NOTICE_MULTI_DESTINY_HOURS_PATHS,
  NOTICE_MULTI_DESTINY_STOPS_PATHS,
  PROFILE_NOTICE_LIST_PATH,
} from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { NoticeDetailMultiDestinyModel } from "@/models/notice/noticeModels";
import useDetailNotice from "@/pages/detailNotice/hook";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { priceInfoText } from "@/pages/noticeV2/NoticePlace/placeConfig";
import { onSetMultiCityThunk } from "@/store/includedServices/inclodedServicesThunks";
import { editNoticePriceThunk } from "@/store/noticeRedux/noticeThunks";
import {
  getNextRoute,
  getPrevRoute,
} from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { setMessageEdit } from "@/store/saveFlow/saveFlowSlice";
import Icon from "@/utils/Icon";
import { cleanValueNumber, currencyFormat } from "@/utils/helpers";
import { Box, Container, FormControl, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ContainerArea } from "../../suggestions/styleSuggestions";
import { BoxContainerInfo } from "../bodyFee/BodyFeeInCity";
import SingleViewZone from "./components/SingleViewZone";
import ViewListZone from "./components/ViewListZone";
import { feeModalData } from "./configdata";
import { ReturnDataToShow, getDataToShowZoneAndDistrict } from "./utils";

const MIN_HOURS_OR_STOPS = 1;
const MAX_HOURS_OR_STOPS = 999;

const FeeMultiCity = () => {
  const { pathname } = useLocation();

  const [firstLoad, setFirstLoad] = useState(true);
  const [pricePerUnit, setPricePerUnit] = useState("");
  const [minUnits, setMinUnits] = useState("");
  const [errorPrice, setErrorPrice] = useState<string>("");
  const [errorMinUnitPrice, setErrorMinUnitPrice] = useState<string>("");
  const [originsSeleted, setOriginsSeleted] = useState<ReturnDataToShow[]>([]);
  const [destinySeleted, setDestinySeleted] = useState<ReturnDataToShow[]>([]);
  const [messageErrorApi, setMessageErrorApi] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const [textOrigin, setTextOrigin] = useState("Ver más");
  const [textDestiny, setTextDestiny] = useState("Ver más");

  const [visibleOrigin, setVisibleOrigin] = useState("none");
  const [visibleDestiny, setVisibleDestiny] = useState("none");
  const currentPrice = Number(pricePerUnit);
  const currentUnitPrice = Number(minUnits);
  const isMultidestinyFlowHour = pathname === NOTICE_MULTI_DESTINY_HOURS_PATHS[optionFive]
  const isMultidestinnyFlowPoints = pathname === NOTICE_MULTI_DESTINY_STOPS_PATHS[5]
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    selectLocation: { multiSelectDestiny, multiSelectOrigin },
    zones: { zones },
    notice: { isLoading: isLoadingNotice },
    detailsNoticeSlice: { isLoading },
  } = useAppSelector((state) => state);

  const { handleGetCurrentDetailNotice } = useDetailNotice();

  const notice: NoticeDetailMultiDestinyModel = useMemo(
    () => handleGetCurrentDetailNotice(),
    [isLoading]
  );

  useEffect(() => {
    handleSetOrigin();
    handleSetDestiny();
    if (pathname.startsWith(PROFILE_NOTICE_LIST_PATH)) {
      const price = notice.precio?.toString();
      const count = notice?.puntosminimo
        ? notice?.puntosminimo
        : notice.horasminimo;
      setIsEdit(true);
      setPricePerUnit(price || "");
      setMinUnits(count?.toString() || "");
    }

    return () => {
      setMessageErrorApi("");
    };
  }, []);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    }
  }, [isLoading]);

  const handlePricePerUnit = ({
    currentTarget,
  }: ChangeEvent<HTMLInputElement>) => {
    const { value } = currentTarget;

    if (value.length > optionThree) return;

    const clenaValue = cleanValueNumber(value);
    setPricePerUnit(clenaValue);

    setIsDirty(true);
  };

  const handleMinUnits = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const { value } = currentTarget;

    if (value.length > optionThree) return;

    const clenaValue = cleanValueNumber(value);
    setMinUnits(clenaValue);

    setIsDirty(true);
  };

  const handleGeBodyHoursOrPoints = () => {
    const { precioxpunto } = notice;
    const numbMinUnits = Number(minUnits);
    const numbPricePerUnit = Number(pricePerUnit);
    const precio = numbPricePerUnit * numbMinUnits;
    
    if (precioxpunto) {
      return {
        puntosminimo: numbMinUnits,
        precioxpunto: numbPricePerUnit,
        precio,
      };
    }

    return {
      horasminimo: numbMinUnits,
      precioxhora: numbPricePerUnit,
    };
  };

  const handleRulesSubmit = () => {
    if (isEdit) {
      handleSubmitEdit();
    } else {
      handleSubmit();
    }
  };

  const handleSubmitEdit = () => {
    const body = handleGeBodyHoursOrPoints();
    setMessageErrorApi("");
    dispatch(editNoticePriceThunk(notice.id, body))
      .then(handleSuccessEdit)
      .catch((error: Error) => setMessageErrorApi(error?.message));
  };

  const handleSuccessEdit = () => {
    dispatch(setMessageEdit(SUCCESS_TEXT_EDIT_PRICE));
    navigate(-1);
  };

  const handleSubmit = () => {

    if (
      currentPrice < MIN_HOURS_OR_STOPS ||
      currentPrice > MAX_HOURS_OR_STOPS
    ) {
      setErrorPrice(handleGetTextError(currentPrice));
      return;
    }
    setErrorPrice("");
    if (
      currentUnitPrice < MIN_HOURS_OR_STOPS ||
      currentUnitPrice > MAX_HOURS_OR_STOPS
    ) {
      setErrorMinUnitPrice(
        handleGetTextError(currentUnitPrice).replace(
          `El precio mínimo`,
          `${
            isMultidestinnyFlowPoints
              ? "Los puntos minimos"
              : "Las horas mínimas"
          }`
        )
      );
      return;
    }
    setErrorMinUnitPrice("");
    const typeFlow = isMultidestinyFlowHour ? optionOne : optionTwo;
    const minPrice = currentPrice * currentUnitPrice;

    dispatch(
      onSetMultiCityThunk(
        currentPrice,
        currentUnitPrice,
        minPrice,
        typeFlow
      )
    )
      .then((res) => {
        dispatch(getNextRoute(navigate));
      })
      .catch((error: Error) => setMessageErrorApi(error?.message));
  };

  const handleGetTextError = (currenValue: number): string => {
    if (currenValue < 10) {
      return TEXT_ERROR_MIN_PRICE;
    }

    return TEXT_ERROR_MAX_PRICE;
  };

  const handleSetOrigin = () => {
    const result = getDataToShowZoneAndDistrict(multiSelectOrigin, zones);
    setOriginsSeleted(result);
  };

  const handleSetDestiny = () => {
    const result = getDataToShowZoneAndDistrict(multiSelectDestiny, zones);
    setDestinySeleted(result);
  };

  const handleValidGoBack = () => {
    if (isDirty) {
      setShowAlert(true);
    } else {
      handleGoback();
    }
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  const handleGoback = () => {
    if (isEdit) {
      navigate(-1);
    } else {
      dispatch(getPrevRoute(navigate));
    }
  };

  const handleDisabled = () => {
    return (
      (!Number(pricePerUnit) && !Number(minUnits)) ||
      isLoading ||
      isLoadingNotice
    );
  };

  const handleLabelInputPrice = () => {
    return `¿Precio por ${notice?.precioxpunto ? "punto" : "hora"} (S/)?`;
  };

  const handleInputText = () => {
    return `¿${!!notice?.puntosminimo ? " Puntos mínimos" : " Horas mínimo?"}`;
  };

  const handleTotalPriceText = () => {
    return `
    ${
      pricePerUnit && minUnits
        ? `${currencyFormat(Number(pricePerUnit) * Number(minUnits))}`
        : "-"
    }`;
  };

  const handlePlaceHolder = () => {
    return `Indica el mínimo de ${
      !!notice?.puntosminimo ? "puntos de entrega" : "horas"
    } para tu servicio`;
  };

  const handlePlaceHolderPrice = () => {
    return `Indica tu precio por ${
      !!notice?.precioxpunto ? "punto de entrega" : "hora"
    }`;
  };

  const handleTitleHeaderSection = () => {
    return `Precio por ${
      isMultidestinnyFlowPoints
        ? "punto de entrega"
        : "horas"
    }`
  }

  return (
    <>
      <Container maxWidth="xs">
        <HeaderSection
          icon="icon-arrow-left--primary"
          title={handleTitleHeaderSection()}
          subTitle="Crear multidestino en la ciudad"
          onClick={handleValidGoBack}
        />

        <BannerModal
          open={showAlert}
          onClose={handleCloseModal}
          title={feeModalData.title}
          content={feeModalData.content}
          option1={{
            title: feeModalData.option1Title,
            onClick: handleCloseModal,
          }}
          option2={{
            title: feeModalData.option2Title,
            onClick: handleGoback,
          }}
          color="org"
          sx={{
            width: "322px",
            height: "292px",
          }}
          sxAccion={{
            padding: "0px",
          }}
        />

        <ContainerArea>
          <SectionIcon icon="icon-money" />

          <BoxContainerInfo>
            <Icon name="icon-handsShake" size="md" />
            <Typography variant="body2" sx={{ fontSize: "16px" }}>
              {priceInfoText}
            </Typography>
          </BoxContainerInfo>

          <>
            {!!messageErrorApi && (
              <StandardAlert
                sx={{ marginBottom: "16px" }}
                severity="error"
                color="error"
              >
                {messageErrorApi}
              </StandardAlert>
            )}
          </>
          {isEdit ? (
            <>
              <Box sx={{ mb: 2, mt: 2 }}>
                <SingleViewZone
                  origentexto={notice?.origentexto}
                  destinotexto={notice?.destinotexto}
                />
              </Box>
            </>
          ) : (
            <>
              <ViewListZone
                title="Origen:"
                data={originsSeleted}
                text={textOrigin}
                setText={setTextOrigin}
                display={visibleOrigin}
                setVisible={setVisibleOrigin}
              />
              <ViewListZone
                title="Destino:"
                data={destinySeleted}
                text={textDestiny}
                setText={setTextDestiny}
                display={visibleDestiny}
                setVisible={setVisibleDestiny}
              />
            </>
          )}

          <Typography sx={{ fontWeight: 700, mb: 2 }}>
            Precio del servicio
          </Typography>

          <FormControl sx={{ marginTop: "8px", width: "100%" }}>
            <Input
              error={!!errorPrice}
              name="price"
              label={<LabelInputRequired str={handleLabelInputPrice()} />}
              value={pricePerUnit}
              variant="standard"
              placeholder={handlePlaceHolderPrice()}
              onChange={handlePricePerUnit}
              onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                inputMode: "numeric",
              }}
              helperText={errorPrice}
            />
          </FormControl>

          <FormControl sx={{ marginTop: "16px", width: "100%" }}>
            <Input
              error={!!errorMinUnitPrice}
              name="minUnitPrice"
              label={<LabelInputRequired str={handleInputText()} />}
              value={minUnits}
              variant="standard"
              placeholder={handlePlaceHolder()}
              onChange={handleMinUnits}
              onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                inputMode: "numeric",
              }}
              helperText={errorMinUnitPrice}
            />
          </FormControl>

          <Box sx={{ display: "flex", mt: "16px" }}>
            <Typography sx={{ fontWeight: 700, mr: 1 }}>
              Precio mínimo:
            </Typography>
            <Typography variant="body2" color="black">
              {handleTotalPriceText()}
            </Typography>
          </Box>

          <ButtomLoader
            onClick={handleRulesSubmit}
            sx={{ marginTop: "24px" }}
            disabled={handleDisabled()}
            isLoading={isLoading || isLoadingNotice}
          >
            {isEdit ? "Guardar" : "PUBLICAR"}
          </ButtomLoader>
        </ContainerArea>
      </Container>
    </>
  );
};

export default FeeMultiCity;
