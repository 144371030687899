import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import DetailItemOption from '../DetailItemOption';
import {
  prepareDateAutionCallback,
  prepareMaximumCapacityCallback,
  prepareVechicleCallback,
} from './detailsCallback';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import { ConfigDataIncity } from './detailsOptionsInterface';

export const detailsConfigStandar: ConfigDataIncity[] = [
  {
    Component: DetailItemOption,
    props: {
      icon: LocalShippingOutlinedIcon,
      title: 'Vehículo: ',
    },
    valid: [
      {
        property: 'vehiculo',
        componentProp: 'props',
        defaultResult: '',
        callback: prepareVechicleCallback,
      },
    ],
  },
  {
    Component: DetailItemOption,
    props: {
      icon: 'icon-balance-medium',
      title: 'Capacidad máx: ',
    },
    valid: [
      {
        property: 'vehiculo',
        componentProp: 'props',
        defaultResult: '',
        callback: prepareMaximumCapacityCallback,
      },
    ],
  },
];

export const detailsConfigAution: ConfigDataIncity[] = [
  {
    Component: DetailItemOption,
    props: {
      icon: CalendarMonthOutlinedIcon,
      title: 'Salida: ',
    },
    valid: [
      {
        property: 'vehiculo',
        componentProp: 'props',
        defaultResult: '',
        callback: prepareDateAutionCallback,
      },
    ],
  },
  {
    Component: DetailItemOption,
    props: {
      icon: LocalShippingOutlinedIcon,
      title: 'Vehículo: ',
    },
    valid: [
      {
        property: 'vehiculo',
        componentProp: 'props',
        defaultResult: '',
        callback: prepareVechicleCallback,
      },
    ],
  },
  {
    Component: DetailItemOption,
    props: {
      icon: 'icon-balance-medium',
      title: 'Capacidad máx: ',
    },
    valid: [
      {
        property: 'vehiculo',
        componentProp: 'props',
        defaultResult: '',
        callback: prepareMaximumCapacityCallback,
      },
    ],
  },
];
