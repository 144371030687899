import { AlertColor } from "@mui/material";

export type ChildConfig = {
  message: string;
  message2: string;
  status: AlertColor;
};
type Config = {
  success: ChildConfig;
  error: ChildConfig;
};
export const configData: Config = {
  success: {
    message: "Regresó la conexión, ",
    message2: "Refresca para continuar.",
    status: "success",
  },
  error: {
    message:
      "Tuvimos un problema, vuelve a intentar o revisa tu conexión a internet.",
    message2: "",
    status: "warning",
  },
};
