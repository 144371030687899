import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { getListPreguntasFrecuentesThunk } from "@/store/landingServices/landingFAQ/landingServicesThunk";
import { useEffect } from "react";
import BannerLand from "../components/bannerLand/BannerLand";
import InfoLand from "../components/infoLand/InfoLand";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Section } from "../components/sectionLand/styleSectionLand";
import {
  AccordionContainer,
  AccordionTitle,
  ContainerItemFAQ,
  IconClose,
  SubTitle,
  TitleFAQ,
  TopCard,
} from "./styleFaq";
import Footer from "@/pages/footer/Footer";
import SectionLand from "../components/sectionLand/sectionLand";
import { BASE_URL_IMAGE } from "@/config/config";
import {
  FREQUENT_QUESTIONS_HERO_LG,
  FREQUENT_QUESTIONS_HERO_MD,
  FREQUENT_QUESTIONS_HERO_SM,
} from "@/consts/defaultConsts";
import { ResponsiveImage } from "@/interfaces/globalInterface";

const responsiveImages: ResponsiveImage[] = [
  {
    cssProperties: [
      {
        property: "backgroundImage",
        value: `url(${BASE_URL_IMAGE}${FREQUENT_QUESTIONS_HERO_MD})`,
      },
      { property: "minHeight", value: "240px" },
      { property: "maxHeight", value: "260px" },
    ],
    mediaMinWidth: "961px",
  },
  {
    cssProperties: [
      {
        property: "backgroundImage",
        value: `url(${BASE_URL_IMAGE}${FREQUENT_QUESTIONS_HERO_LG})`,
      },
      { property: "minHeight", value: "320px" },
      { property: "maxHeight", value: "340px" },
    ],
    mediaMinWidth: "1280px",
  },
];

const Faq = () => {
  const dispatch = useAppDispatch();
  const { listPreguntasFrecuentes } = useAppSelector(
    (state) => state.landingFAQ
  );
  let [expanded, setExpanded] = useState<string | false>("");

  useEffect(() => {
    dispatch(getListPreguntasFrecuentesThunk());
  }, [dispatch]);

  const handleAccordion = (event: any, tipo: number, index: number) => {
    event.stopPropagation();
    setExpanded(tipo + "question" + index);
  };

  const handleAccordionClose = (event: any) => {
    event.stopPropagation();
    handleAccordion(event, 0, 0);
  };

  return (
    <>
      <BannerLand
        urlImg={`${BASE_URL_IMAGE}${FREQUENT_QUESTIONS_HERO_SM}`}
        responsive={responsiveImages}
        positionXY={{ xs: "center", md: "center" }}
        minHeight="166px"
      />
      <SectionLand
        backgroundColor="#382E2B"
        sx={{
          padding: {
            xs: "0 16px",
            sm: "32px",
          },
        }}
      >
        <InfoLand
          title="preguntas frecuentes"
          subtitle=""
          height="128px"
          sx={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            height: {
              xs: "120px",
              sm: "120px",
            },
          }}
        />
      </SectionLand>
      <Section>
        {listPreguntasFrecuentes.map(
          ({ nombre, preguntasfrecuentes }, index) => (
            <AccordionContainer key={index}>
              <AccordionTitle expandIcon={<ExpandMoreIcon color="org" />}>
                <TitleFAQ>{nombre}</TitleFAQ>
              </AccordionTitle>
              {preguntasfrecuentes.map(
                ({ idpreguntasfrecuentestipo, pregunta, respuesta }, index) => (
                  <ContainerItemFAQ
                    key={index}
                    className={
                      expanded ===
                      idpreguntasfrecuentestipo + "question" + index
                        ? "active"
                        : ""
                    }
                    onClick={(event) =>
                      handleAccordion(event, idpreguntasfrecuentestipo, index)
                    }
                  >
                    <TopCard>
                      <SubTitle>{pregunta}</SubTitle>
                      {expanded ===
                        idpreguntasfrecuentestipo + "question" + index && (
                        <IconClose onClick={handleAccordionClose} />
                      )}
                    </TopCard>
                    {expanded ===
                      idpreguntasfrecuentestipo + "question" + index && (
                      <Typography variant="body1" sx={{ color: "black" }}>
                        {respuesta}
                      </Typography>
                    )}
                  </ContainerItemFAQ>
                )
              )}
            </AccordionContainer>
          )
        )}
      </Section>
      <Footer />
    </>
  );
};

export default Faq;
