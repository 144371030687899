import { styled } from "@mui/material";
import React from "react";
import { Link, LinkProps } from "react-router-dom";

const LinkButtonStyle = styled(Link)(
  ({ theme }) => `
    text-decoration: none;
    width: 100%;
    color: #fff;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    font-family: ${theme.typography.button.fontFamily};
    font-size: ${theme.typography.button.fontSize};
    line-height: ${theme.typography.button.lineHeight};
    background: ${theme.palette.warning.main};
    border-radius: 44px;
    padding: 3px;
  `
);

const LinkButton = ({ children, ...props }: LinkProps) => {
  return <LinkButtonStyle {...props}>{children}</LinkButtonStyle>;
};

export default LinkButton;
