import HeaderSectionV2 from "@/components/headerSectionV2/HeaderSectionV2";
import SectionIconV2 from "@/components/sectionIconV2/SectionIconV2";
import { FORMAT_DATE_DDMMYYYY } from "@/consts/formatsDate";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { LocationDetailState } from "@/interfaces/detailNoticeInterface";
import { DetailNoticeMoving } from "@/models/notice/noticeModels";
import { setSelectData } from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferSlice";
import { getPrevRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { Container } from "@mui/material";
import dayjs from "dayjs";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const ID_COUNTEROFFER = 2;

const DateDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { selectTypeNotice, idTypeNoticeNegotiation } = useAppSelector(
    (state) => state.noticeV2ClientAorCO
  );

  const notice = selectTypeNotice as DetailNoticeMoving;
  const state = location.state as LocationDetailState;

  useEffect(() => {
    if (state?.timeService) {
      const newDate = dayjs(state.timeService).format(FORMAT_DATE_DDMMYYYY);
      dispatch(setSelectData(newDate));
    }

    return () => {};
  }, []);

  const handlePreviusRute = () => {
    dispatch(
      getPrevRoute(navigate, {
        valueToReplace: notice.id?.toString(),
        valueToSearch: ":id",
      })
    );
  };
  const isContraoferta = idTypeNoticeNegotiation === ID_COUNTEROFFER;
  return (
    <Container maxWidth="xs">
      <HeaderSectionV2
        icon="icon-arrow-left"
        title="Detalles de la solicitud"
        subTitle={isContraoferta ? "Solicitar cotización" : "Aceptar"}
        onClick={handlePreviusRute}
        sx={{
          boxShadow: "none",
        }}
      />
      <SectionIconV2 icon="icon-calendar" />
      <Outlet />
    </Container>
  );
};

export default DateDetails;
