import ConditionsListOffert from '@/components/conditionsList/ConditionsListOffert';
import DetailsTextContant from '@/components/detailsTextContact/DetailsTextContant';
import GenericComponent from '@/components/genericComponent/GenericComponent';
import { descriptionLoad } from '@/consts/noticeConsts';
import DetailsTextMoving from '@/pages/customerRequest/application/components/DetailsTextMoving';
import { ConfigDataNegotation } from '@/pages/noticeRequestFlow/interface/noticeNegRequestInterface';
import { ConfigOfferAgreementType } from '@/pages/noticeRequestFlow/negzAgreementConfirmation/config';
import {
  getHourOrPOint,
  getTypeMultidestiny,
} from '@/pages/noticeRequestFlow/utils/utilsComponents';
import {
  getCounterOffertFormatSendDate,
  getEmailInRating,
  getFormatSendDate,
  getMaxWeigth,
  getNumberPhoneInRating,
  getRefrigeratedLoad,
  getTitleOriginAndDestiny,
  getTitlePriceCoCounterOfferConfirmation,
  getValueInOffert,
  getVehicleValueNotification,
  getVehicleValues,
} from '@/pages/noticeRequestFlow/utils/utilsNegotation';
import DetailsText from '@/pages/offerts/offertConfirmation/components/DetailsText';
import { Box, Typography } from '@mui/material';

// ==================================================
//          CONFIGURACION MULTIDESTINO
// ==================================================

export const configDataDriverCounterOfferOneMultidestiny: ConfigDataNegotation[] =
  [
    {
      title: 'Datos del cliente',
      Component: Typography,
      props: {
        variant: 'body2',
        sx: { color: '#757575', mb: '4px' },
      },
    },
    {
      Component: GenericComponent,
      props: {
        icon: '',
        title: '',
      },
      valid: [
        {
          property: 'solicitud',
          componentProp: 'show',
          defaultResult: false,
          callback: getTypeMultidestiny,
          callbakcParams: ['solicitud', 'horasrequeridas'],
        },
        {
          property: 'solicitud',
          componentProp: 'origen',
          defaultResult: '',
          callback: getTypeMultidestiny,
          callbakcParams: ['solicitud', 'horasrequeridas'],
        },
      ],
    },
    {
      Component: Typography,
      props: {
        variant: 'subtitle1medium',
      },
      valid: [
        {
          property: 'solicitud',
          componentProp: 'title',
          defaultResult: '',
          callback: getTitleOriginAndDestiny,
        },
      ],
    },
  ];

export const configDataDriverCounterOfferTwoMultidestiny: ConfigDataNegotation[] =
  [
    {
      title: '',
      Component: Box,
      props: {
        variant: 'body2',
        sx: { width: '100%', mt: '16px' },
      },
    },
    {
      title: 'Detalles de contacto',
      Component: Typography,
      props: {
        variant: 'subtitle1medium',
      },
    },
    {
      Component: DetailsTextContant,
      props: {
        icon: 'icon-small-phone',
        title: 'Telefono',
        show: true,
        type: 'phone',
      },
      valid: [
        {
          property: 'avisoNegociacionAviso',
          componentProp: 'origen',
          defaultResult: '',
          callback: getNumberPhoneInRating,
        },
      ],
    },
    {
      Component: DetailsTextContant,
      props: {
        icon: 'icon-whatsapp',
        title: 'Whatsapp',
        show: true,
        type: 'whatsapp',
      },
      valid: [
        {
          property: 'avisoNegociacionAviso',
          componentProp: 'origen',
          defaultResult: '',
          callback: getNumberPhoneInRating,
        },
      ],
    },

    {
      Component: DetailsTextContant,
      props: {
        icon: 'icon-mail',
        title: 'Correo',
        type: 'mail',
      },
      valid: [
        {
          property: 'avisoNegociacionAviso',
          componentProp: 'show',
          defaultResult: '',
          callback: getEmailInRating,
        },
        {
          property: 'avisoNegociacionAviso',
          componentProp: 'origen',
          defaultResult: '',
          callback: getEmailInRating,
        },
      ],
    },

    {
      title: '',
      Component: Box,
      props: {
        variant: 'body2',
        sx: { width: '100%', mt: '16px' },
      },
    },
    {
      title: 'Detalles del acuerdo',
      Component: Typography,
      props: {
        variant: 'subtitle1medium',
      },
    },

    {
      Component: DetailsTextMoving,
      props: {
        icon: 'icon-calendar--white',
        title: 'Fecha del servicio',
        originDescription: '',
      },
      valid: [
        {
          property: 'solicitud',
          componentProp: 'show',
          defaultResult: true,
          callback: getValueInOffert,
          callbakcParams: ['solicitud', 'fecha'],
        },
        {
          property: 'solicitud',
          componentProp: 'origen',
          defaultResult: '',
          callback: getFormatSendDate,
          callbakcParams: ['solicitud', 'fecha'],
        },
      ],
    },
    {
      Component: DetailsText,
      props: {
        icon: 'icon-location-medium',
        title: 'Origen',
      },
      valid: [
        {
          property: 'solicitud',
          componentProp: 'show',
          defaultResult: false,
          callback: getValueInOffert,
          callbakcParams: ['solicitud', 'origentexto'],
        },
        {
          property: 'solicitud',
          componentProp: 'origen',
          defaultResult: '',
          callback: getValueInOffert,
          callbakcParams: ['solicitud', 'origentexto'],
        },
        {
          property: 'referencia',
          componentProp: 'originDescription',
          defaultResult: '',
          callback: getValueInOffert,
          callbakcParams: ['solicitud', 'referencia'],
        },
      ],
    },

    {
      Component: DetailsText,
      props: {
        icon: 'icon-location-medium',
        title: 'Destino',
      },
      valid: [
        {
          property: 'solicitud',
          componentProp: 'show',
          defaultResult: true,
          callback: getValueInOffert,
          callbakcParams: ['solicitud', 'destinotexto'],
        },
        {
          property: 'solicitud',
          componentProp: 'origen',
          defaultResult: '',
          callback: getValueInOffert,
          callbakcParams: ['solicitud', 'destinotexto'],
        },
        {
          property: 'referenciaDestino',
          componentProp: 'originDescription',
          defaultResult: '',
          callback: getValueInOffert,
          callbakcParams: ['solicitud', 'referenciaDestino'],
        },
      ],
    },
    {
      Component: DetailsText,
      props: {
        icon: 'icon-car-medium',
        title: 'Vehículo',
      },
      valid: [
        {
          property: 'solicitud',
          componentProp: 'show',
          defaultResult: true,
          callback: getVehicleValues,
          callbakcParams: ['solicitud', 'tipoVehiculo', 'nombre'],
        },
        {
          property: 'solicitud',
          componentProp: 'origen',
          defaultResult: '',
          callback: getVehicleValueNotification,
        },
      ],
    },
    {
      Component: GenericComponent,
      props: {
        icon: '',
        title: '',
        sx: { mt: '10px' },
      },
      valid: [
        {
          property: 'solicitud',
          componentProp: 'show',
          defaultResult: false,
          callback: getHourOrPOint,
          callbakcParams: ['solicitud', 'horasrequeridas'],
        },
        {
          property: 'solicitud',
          componentProp: 'origen',
          defaultResult: '',
          callback: getHourOrPOint,
          callbakcParams: ['solicitud', 'horasrequeridas'],
        },
      ],
    },
    {
      title: '',
      Component: Box,
      props: {
        variant: 'body2',
        sx: { width: '100%', mt: '16px' },
      },
    },
    {
      title: descriptionLoad,
      Component: Typography,
      props: {
        variant: 'subtitle1medium',
      },
    },
    {
      Component: GenericComponent,
      props: {
        icon: '',
        title: '',
      },
      valid: [
        {
          property: 'solicitud',
          componentProp: 'show',
          defaultResult: true,
          callback: getValueInOffert,
          callbakcParams: ['solicitud', 'descripcionCarga'],
        },
        {
          property: 'solicitud',
          componentProp: 'origen',
          defaultResult: '',
          callback: getValueInOffert,
          callbakcParams: ['solicitud', 'descripcionCarga'],
        },
      ],
    },
    {
      Component: DetailsText,
      props: {
        icon: 'icon-balance-medium',
        title: 'Peso estimado',
      },
      valid: [
        {
          property: 'solicitud',
          componentProp: 'show',
          defaultResult: true,
          callback: getMaxWeigth,
          callbakcParams: ['solicitud', 'pesoestimado'],
        },
        {
          property: 'solicitud',
          componentProp: 'origen',
          defaultResult: '',
          callback: getMaxWeigth,
          callbakcParams: ['solicitud', 'pesoestimado'],
        },
      ],
    },
    {
      Component: DetailsText,
      props: {
        icon: 'icon-snowFlake-medium',
        title: 'Necesita refrigeración',
      },
      valid: [
        {
          property: 'solicitud',
          componentProp: 'show',
          defaultResult: true,
          callback: getValueInOffert,
          callbakcParams: ['solicitud', 'flgcargarefrigerada'],
        },
        {
          property: 'solicitud',
          componentProp: 'origen',
          defaultResult: '',
          callback: getRefrigeratedLoad,
          callbakcParams: ['solicitud', 'flgcargarefrigerada'],
        },
      ],
    },
  ];

export const cofigDataDriverCounterOfferConditionsMultidestiny: ConfigDataNegotation[] =
  [
    {
      Component: ConditionsListOffert,
      props: {
        title: 'Condiciones del servicio:',
        style: { marginTop: '22px' },
      },
      valid: [
        {
          property: 'solicitud',
          componentProp: 'show',
          defaultResult: false,
          callback: getValueInOffert,
          callbakcParams: ['solicitud', 'condiciones'],
        },
        {
          property: 'solicitud',
          componentProp: 'listServices',
          defaultResult: [],
          callback: getValueInOffert,
          callbakcParams: ['solicitud', 'condiciones'],
        },
      ],
    },
  ];

export const configDataDriverCounterOfferThreeMultidestiny: ConfigDataNegotation[] =
  [
    {
      Component: Typography,
      title: 'Precio',
      props: {
        variant: 'body2',
        sx: { color: '#757575', mt: '4px' },
      },
      valid: [
        {
          property: 'solicitud',
          componentProp: 'title',
          defaultResult: '',
        },
      ],
    },
    {
      Component: Typography,
      props: {
        variant: 'h4',
        sx: { fontSize: '22px' },
      },
      valid: [
        {
          property: 'solicitud',
          componentProp: 'title',
          defaultResult: '',
          callback: getTitlePriceCoCounterOfferConfirmation,
        },
      ],
    },
    {
      Component: Typography,
      props: {
        variant: 'body2',
        sx: { color: '#757575', mb: '4px' },
      },
      valid: [
        {
          property: 'solicitud',
          componentProp: 'title',
          defaultResult: '',
          callback: getCounterOffertFormatSendDate,
        },
      ],
    },
  ];

export const ConfigCounterOfferMultidestinyDriver: ConfigOfferAgreementType = {
  1: configDataDriverCounterOfferOneMultidestiny,
  2: configDataDriverCounterOfferTwoMultidestiny,
  3: cofigDataDriverCounterOfferConditionsMultidestiny,
  4: configDataDriverCounterOfferThreeMultidestiny,
};
