import { NOTICE_LIST, NOTICE_REGISTER_LIST } from "@/consts/pathServices";
import { connection } from "@/services/connection";
import { INoticeSend } from "./noticeSendDataTypes";
import { IAvisoResponseCreated } from "./noticeSendDataTypesResponse";
const API = connection();

export const noticeSendDataForSave = async (body: INoticeSend): Promise<IAvisoResponseCreated> => {
    const { data } = await API.post(NOTICE_LIST, body);

    return data;
}
// registro de aviso

export const noticeSendDataForSaveRegister = async (body: INoticeSend): Promise<IAvisoResponseCreated> => {
    const { data } = await API.post(NOTICE_REGISTER_LIST, body);

    return data;
}