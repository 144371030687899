import { REQUESTS_LANDING_PATH } from './typeServicesPaths';

export const NEGOTATION_CLIENT_PATH = '/mis-solicitudes';

export const ACCEPT_PATH = '/acepta';
export const ACCEPT_SEND_PATH = '/acepta-enviada';
export const REAPPLY = '/volver-solicitar';

// ==================================================
//                   Tipo de solicitudes
// ==================================================
export const NEGOTATION_IN_CITY = 'en-la-ciudad';
export const NEGOTATION_MULTI_DESTINY = 'multidestino';
export const NEGOTATION_BETWEEN_CITIES = 'entre-ciudades';
export const NEGOTATION_EXPRESS = 'express';
export const NEGOTATION_MOVING = 'mudanza';
export const NEGOTIATION_AUCTION_BTN_CITIES = 'remate-entre-ciudades';
export const NEGOTATION_MULTI_DESTINY_IN_CITY = 'multidestino-en-la-ciudad';
export const NEGOTATION_OFFER = '/oferta';
export const NEGOTATION_COUNTEROFFER = '/contraoferta';
export const NEGOTATION_CO_COUNTEROFFER = '/co-contraoferta';
export const COUNTEROFFER_SEND_PATH = '/contraoferta-enviada';
export const CO_COUNTEROFFER_SEND_PATH = '/co-contraoferta-confirmacion';

export const NEGOTATION_AGREEMENT_MESSAGE = '/acuerdo-mensaje';
export const NEGOTATION_AGREEMENT_CONFIRMATION = '/acuerdo-confirmacion';
export const NEGOTATION_REJECTION_CONFIRMATION = '/rechazo-confirmacion';
export const NEGOTATION_DECLINE = '/rechazo-mensaje';
export const DECLINE = '/rechazo';
export const AGREEMENT = '/confirmacion';

// ==================================================
//                     RUTAS
// ==================================================

//Aceptación:
export const NEGOTATION_ACCEPT_PATH = `${REQUESTS_LANDING_PATH}/:typeRequest/:idRequest${ACCEPT_PATH}`;
export const NEGOTATION_ACCEPT_SEND_PATH = `${REQUESTS_LANDING_PATH}/:typeRequest/:idRequest${ACCEPT_SEND_PATH}`;

//Contraoferta:
export const NEGOTATION_COUNTEROFFER_PATH = `${REQUESTS_LANDING_PATH}/:typeRequest/:idRequest${NEGOTATION_COUNTEROFFER}`;
export const NEGOTATION_COUNTEROFFER_SEND_PATH = `${REQUESTS_LANDING_PATH}/:typeRequest/:idRequest${COUNTEROFFER_SEND_PATH}`;

// Oferta
export const NEGOTATION_OFFER_PATH = `${REQUESTS_LANDING_PATH}/:typeSolicitud/:idSolicitud${NEGOTATION_OFFER}`;
export const NEGOTATION_OFFER_SEND_PATH = `${REQUESTS_LANDING_PATH}/:typeSolicitud/:idSolicitud${ACCEPT_SEND_PATH}`;

// ARRAY PARA ACEPTAR EL AVISO DEL CLIENTE
export const NEGOTATION_ACCEPT_FLOW_PATHS = [
  NEGOTATION_ACCEPT_PATH,
  NEGOTATION_ACCEPT_SEND_PATH,
];

// ARRAY PARA CONTRAOFERTAR EL AVISO DEL CLIENTE
export const NEGOTATION_COUNTEROFFER_FLOW_PATHS = [
  NEGOTATION_COUNTEROFFER_PATH,
  NEGOTATION_COUNTEROFFER_SEND_PATH,
];

// ARRAY PARA OFERTAR EL AVISO DEL CLIENTE
export const NEGOTATION_OFFER_FLOW_PATHS = [
  NEGOTATION_OFFER_PATH,
  NEGOTATION_OFFER_SEND_PATH,
];

// ========================================================================================================================================================================== //

// ==================================================
//    CLIENTE RECIBE RESPUESTA DEL TRANSPORTISTA
// ==================================================

export const NEGOTATION_CLIENT_RECEIVE_ACEPTATION = `${NEGOTATION_CLIENT_PATH}${NEGOTATION_OFFER}/:typeId`;
export const NEGOTATION_CLIENT_RECEIVE_COUNTEROFFER = `${NEGOTATION_CLIENT_PATH}${NEGOTATION_COUNTEROFFER}/:typeId`;
// transportista
export const NEGOTATION_TRANSPORTER_RECEIVE_ACEPTATION_DRIVER = `${REQUESTS_LANDING_PATH}${NEGOTATION_OFFER}/:typeId`;
export const NEGOTATION_TRANSPORTER_RECEIVE_COUNTEROFFERT_DRIVER = `${REQUESTS_LANDING_PATH}${NEGOTATION_COUNTEROFFER}/:typeId`;

// ==================================================
//       CLIENTE RESPONDE ACEPTA O RECHAZA
// ==================================================

export const NEGOTATION_AGREEMENT_MESSAGE_CLIENT = `${NEGOTATION_CLIENT_PATH}/:typeRequest/:typeId${NEGOTATION_AGREEMENT_MESSAGE}`;
export const NEGOTATION_DECLINE_MESSAGE_CLIENT = `${NEGOTATION_CLIENT_PATH}/:typeRequest/:typeId${NEGOTATION_DECLINE}`;

export const NEGOTATION_COUNTEROFFER_CLIENT = `${NEGOTATION_CLIENT_PATH}/:typeRequest/:typeId`;
export const NEGOTATION_CO_COUNTEROFFERT_CLIENT = `${NEGOTATION_CLIENT_PATH}/co-contraoferta/:typeId`;

// ==================================================
//     CLIENTE CO-CONTRAOFERTA AL TRANSPORTISTA
// ==================================================

//Contraoferta del cliente:
export const NEGOTATION_COUNTEROFFER_CILENT_PATH = `${NEGOTATION_CLIENT_PATH}/:typeRequest/:idRequest${NEGOTATION_CO_COUNTEROFFER}`;
export const NEGOTATION_COUNTEROFFER_SEND_CLIENT_PATH = `${NEGOTATION_CLIENT_PATH}/:typeRequest/:idRequest${CO_COUNTEROFFER_SEND_PATH}`;

// ARRAY PARA CO-CONTRAOFERTAR LA CONTRAOFERTA DEL TRANSPORTISTA
export const NEGOTATION_CO_COUNTEROFFER_FLOW_PATHS = [
  NEGOTATION_COUNTEROFFER_CILENT_PATH,
  NEGOTATION_COUNTEROFFER_SEND_CLIENT_PATH,
];

// ===================================================================
//    TRANSPORTISTA RECIBE ACEPTACIÓN DE SU OFERTA O CONTRAOFERTA
// ===================================================================

export const NEGOTATION_DRIVER_RECEIVE_ACCEPTATION_OFFER = `${REQUESTS_LANDING_PATH}${NEGOTATION_OFFER}/:idRequest${NEGOTATION_AGREEMENT_MESSAGE}`;
export const NEGOTATION_DRIVER_RECEIVE_ACCEPTATION_COUNTEROFFER = `${REQUESTS_LANDING_PATH}${NEGOTATION_COUNTEROFFER}/:idRequest${NEGOTATION_AGREEMENT_MESSAGE}`;

// ===================================================================
//    TRANSPORTISTA RECIBE RECHAZO DE SU OFERTA O CONTRAOFERTA
// ===================================================================

export const NEGOTATION_DRIVER_RECEIVE_REJECTION_OFFER = `${REQUESTS_LANDING_PATH}${NEGOTATION_OFFER}/:idRequest${NEGOTATION_REJECTION_CONFIRMATION}`;
export const NEGOTATION_CLIENT_RECEIVE_REJECTION_OFFERT = `${NEGOTATION_CLIENT_PATH}${NEGOTATION_OFFER}/:idRequest${NEGOTATION_REJECTION_CONFIRMATION}`;
export const NEGOTATION_DRIVER_RECEIVE_REJECTION_COUNTEROFFER = `${REQUESTS_LANDING_PATH}${NEGOTATION_COUNTEROFFER}/:idRequest${NEGOTATION_REJECTION_CONFIRMATION}`;
export const NEGOTATION_CLIENT_RECEIVE_REJECTION_COUNTEROFFER = `${NEGOTATION_CLIENT_PATH}${NEGOTATION_COUNTEROFFER}/:idRequest${NEGOTATION_REJECTION_CONFIRMATION}`;

// ============================================================
//    TRANSPORTISTA RECIBE CO-CONTRAOFERTA DEL CLIENTE
// ============================================================

export const NEGOTATION_COCOUNTEROFFER_DRIVER = `${REQUESTS_LANDING_PATH}${NEGOTATION_CO_COUNTEROFFER}/:typeRequest`;

// ===================================================================
//    TRANSPORTISTA ACEPTA Y/O RECHAZA CO-CONTRAOFERTA DEL CLIENTE
// ===================================================================

export const NEGOTATION_AGREEMENT_MESSAGE_DRIVER = `${REQUESTS_LANDING_PATH}${NEGOTATION_CO_COUNTEROFFER}/:typeId${NEGOTATION_AGREEMENT_MESSAGE}`;
export const NEGOTATION_DECLINE_MESSAGE_DRIVER = `${REQUESTS_LANDING_PATH}${NEGOTATION_CO_COUNTEROFFER}/:typeId${NEGOTATION_DECLINE}`;
export const NEGOTATION_DECLIME_COCOUNTEROFFERT_DRIVER = `${REQUESTS_LANDING_PATH}${NEGOTATION_CO_COUNTEROFFER}/:idRequest${NEGOTATION_REJECTION_CONFIRMATION}`;

// ===================================================================
//    CLIENTE RECIBE ACEPTACION O RECHAZO DE LA COCONTRAOFERTA
// ===================================================================

export const NEGOTATION_AGREEMENT_COCOUNTEROFFER_MESSAGE_CLIENT = `${NEGOTATION_CLIENT_PATH}${NEGOTATION_CO_COUNTEROFFER}/:typeId${NEGOTATION_AGREEMENT_MESSAGE}`;
export const NEGOTATION_DECLINE_COCOUNTEROFFER_MESSAGE_CLIENT = `${NEGOTATION_CLIENT_PATH}${NEGOTATION_CO_COUNTEROFFER}/:idRequest${NEGOTATION_REJECTION_CONFIRMATION}`;

// ============================================================
//    VISTA DE LA CONFIRMACION PARA CLIENTE Y TRANSPORTISTA
// ============================================================

export const NEGOTATION_AGREEMENT_CONFIRMATION_CLIENT_PATH = `${NEGOTATION_CLIENT_PATH}/:typeRequest/:typeId${NEGOTATION_AGREEMENT_CONFIRMATION}`;
export const NEGOTATION_AGREEMENT_CONFIRMATION_DRIVER_PATH = `${REQUESTS_LANDING_PATH}/:typeRequest/:typeId${NEGOTATION_AGREEMENT_CONFIRMATION}`;

// ============================================================
//    VISTA DE LA CONFIRMACION PARA CLIENTE Y TRANSPORTISTA
// ============================================================

export const NEGOTATION_DECLINE_MESSAGE_DRIVER_EXPRESS = `${REQUESTS_LANDING_PATH}${NEGOTATION_OFFER}/:typeId${NEGOTATION_REJECTION_CONFIRMATION}`;

// ========================================================================================================================================================================== //

// ESTAS URL SON PARA EL LADO DEL PERFIL TANTO PARA CLIENTE COMO TRANSPORTISTA

// ==============================================================================
//    URL DE RECHAZO PARA TRANSPORTISTA Y CLIENTE PERO PARA EL LADO DE PERFIL
// ==============================================================================

//CLIENTE

export const NEGOTATION_DECLINE_OFFER_CLIENT = `${NEGOTATION_CLIENT_PATH}${NEGOTATION_OFFER}/:idRequest${NEGOTATION_REJECTION_CONFIRMATION}`;
export const NEGOTATION_DECLINE_COUNTEROFFER_CLIENT = `${NEGOTATION_CLIENT_PATH}${NEGOTATION_COUNTEROFFER}/:idRequest${NEGOTATION_REJECTION_CONFIRMATION}`;

//TRANSPORTISTA

export const NEGOTATION_DECLINE_OFFER_DRIVER = `${REQUESTS_LANDING_PATH}${NEGOTATION_OFFER}/:idRequest${NEGOTATION_REJECTION_CONFIRMATION}`;
export const NEGOTATION_DECLINE_COUNTEROFFER_DRIVER = `${REQUESTS_LANDING_PATH}${NEGOTATION_COUNTEROFFER}/:idRequest${NEGOTATION_REJECTION_CONFIRMATION}`;

// ==============================================================================
//    URL DE CONFIRMACIÓN PARA TRANSPORTISTA Y CLIENTE PERO PARA EL LADO DE PERFIL
// ==============================================================================

//CLIENTE
export const NEGOTATION_AGREEMENT_OFFER_CLIENT = `${NEGOTATION_CLIENT_PATH}${NEGOTATION_OFFER}/:idRequest${AGREEMENT}`;
export const NEGOTATION_AGREEMENT_COUNTEROFFER_CLIENT = `${NEGOTATION_CLIENT_PATH}${NEGOTATION_COUNTEROFFER}/:idRequest${AGREEMENT}`;

//TRANSPORTISTA
export const NEGOTATION_AGREEMENT_OFFER_DRIVER = `${REQUESTS_LANDING_PATH}${NEGOTATION_OFFER}/:idRequest${AGREEMENT}`;
export const NEGOTATION_AGREEMENT_COUNTEROFFER_DRIVER = `${REQUESTS_LANDING_PATH}${NEGOTATION_COUNTEROFFER}/:idRequest${AGREEMENT}`;

// ========================================================================================================================================================================== //

// ===================================================================
//    SECCION PARA LA EDICIÓN DE LA SOLICITUD POR PARTE DEL CLIENTE
// ===================================================================

/* MENÚ DE OPCIONES PARA LA EDICIÓN DE LA SOLICITUD  */
export const NEGOTATION_MENU_PRINCIPAL_NOTICE = `${NEGOTATION_CLIENT_PATH}/:typeRequest/:typeId/editar`;

/* EDICIÓN RUTA DEL SERVICIO */
export const NEGOTATION_RUTE_EDIT = `${NEGOTATION_CLIENT_PATH}/:typeRequest/:typeId/editar/ruta`;

/* EDICIÓN RUTA DE LA CARGA Y VEHÍCULO */
export const NEGOTATION_LOAD_EDIT = `${NEGOTATION_CLIENT_PATH}/:typeRequest/:typeId/editar/carga`;

/* EDICIÓN CONDICIONES DEL SERVICIO */
export const NEGOTATION_CONDITIONS_EDIT = `${NEGOTATION_CLIENT_PATH}/:typeRequest/:typeId/editar/condiciones`;

/* EDICIÓN CONDICIONES DEL SERVICIO */
export const NEGOTATION_PRICE_EDIT = `${NEGOTATION_CLIENT_PATH}/:typeRequest/:typeId/editar/precio`;

// ===================================================================
//    SECCION PARA LA EDICIÓN DE LA SOLICITUD PERO DE MUDANZA
// ===================================================================

/* EDICIÓN DE LA FECHA */
export const NEGOTATION_DATE_EDIT = `${NEGOTATION_CLIENT_PATH}/:typeRequest/:typeId/editar/fecha`;

/* EDICIÓN RDEL ORIGEN */
export const NEGOTATION_ORIGIN_EDIT = `${NEGOTATION_CLIENT_PATH}/:typeRequest/:typeId/editar/origen`;

/* EDICIÓN DEL DESTINO */
export const NEGOTATION_DESTINY_EDIT = `${NEGOTATION_CLIENT_PATH}/:typeRequest/:typeId/editar/destino`;

/* EDICIÓN CANTIDAD DE AMBIENTE*/
export const NEGOTATION_ENVIRONMENT_EDIT = `${NEGOTATION_CLIENT_PATH}/:typeRequest/:typeId/editar/ambientes`;

// ========================================================================================================================================================================== //

// ===================================================================
//    SECCION PARA VOLVER A SOLICITAR O RENOVAR LA SOLICITUD
// ===================================================================

export const NEGOTATION_RENEW_REQUEST = `${NEGOTATION_CLIENT_PATH}/:typeRequest/:typeId${REAPPLY}`;

export const NEGOTATION_RENEWAL_SUCCESS = `${NEGOTATION_CLIENT_PATH}/:typeRequest/:typeId/volver-solicitar-confirmacion`;

export const NEGOTATION_REMOVE_REQUEST = `${NEGOTATION_CLIENT_PATH}/solicitud-desactivada`;

// ========================================================================================================================================================================== //

// ===================================================================================
//    SECCION PARA LA EDICION DE LA NEGOCIACION ENTRE CLIENTE Y TRANSPORTISTA
// ===================================================================================

export const NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION = `${NEGOTATION_CLIENT_PATH}/solicitudes/:typeOffer/:typeRequest/editar`;
export const NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_CLIENT = `${NEGOTATION_CLIENT_PATH}/solicitudes/:typeOffer/:typeRequest/contraoferta-editada`;
export const NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_DRIVER = `${REQUESTS_LANDING_PATH}/solicitudes/:typeOffer/:typeRequest/contraoferta-editada`;

export const NEGOTATION_REJECTED_NEGOTATION_MESSAGE_CLIENT = `${NEGOTATION_CLIENT_PATH}/:typeOffer/:typeRequest/retiro-mensaje`;
export const NEGOTATION_REJECTED_NEGOTATION_MESSAGE_DRIVER = `${REQUESTS_LANDING_PATH}/:typeOffer/:typeRequest/retiro-mensaje`;
