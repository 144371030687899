import FormSuccessBetweenCities from './containers/FormSuccessBetweenCities/Index';
import FormSuccessExpress from './containers/FormSuccessExpress';
import FormSuccessInCity from './containers/FormSuccessInCity/Index';
import FormSuccessMoving from './containers/FormSuccessMoving';
import FormSuccessMultidestiny from './containers/FormSuccessMultidestiny/Index';

export const objectRenderComponent: Record<number, () => JSX.Element> = {
  1: FormSuccessInCity,
  2: FormSuccessMultidestiny,
  3: FormSuccessBetweenCities,
  4: FormSuccessExpress,
  5: FormSuccessMoving,
};
