import { Container, IconButton, useMediaQuery } from "@mui/material";

type Props = { show: boolean; onClick: () => void, sx?: any };

const ArrowBack = ({ onClick, show = false, sx }: Props) => {
  const sm  = useMediaQuery("(max-width:768px)");
  // if (!show) return <></>;

  return (
    <Container
      disableGutters
      sx={{
        display: { sm: "block", md: "block" },
        margin: "5px 0",
        height: { sm: "auto", md: "16px" },
        mb: sm ? 2 : 4,
        mt: 2,
        ...sx
      }}
    >
      {show && (
        <IconButton aria-label="back" onClick={onClick}>
          <i className="icon-arrow-left--primary"></i>
        </IconButton>
      )}
    </Container>
  );
};

export default ArrowBack;
