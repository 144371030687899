import { PROFILE_NOTICE_LIST_PATH } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { UsuarioAPI } from "@/models/userAuth/user";
import { createServiceFeeDataLayer } from "@/services/dataLayer/createNotice";
import { getPrevRouteV2 } from "@/store/routeActionsFlowV2/routeActionsFlowNoticeV2Thunk";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { INoticeCreateListItem } from "../NoticeList/types";
import { currentRuleFee, headerEditText } from "./placeConfig";

const useNoticePlaceHook = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();
  const {
    noticeV2: { selectNotice, error },
    user: { user },
  } = useAppSelector((state) => state);
  const NoticeType = (selectNotice as INoticeCreateListItem) || 0;
  const currentUser = user as UsuarioAPI;
  const isRegister = currentUser?.flgRegistroAviso === null;
  useEffect(() => {
    if (!isRegister) {
      createServiceFeeDataLayer(
        currentUser.id,
        NoticeType?.title || "",
      );
    }
  }, []);

  const handleValidGoBack = () => {
    if (!location.pathname.startsWith(PROFILE_NOTICE_LIST_PATH)) {
      dispatch(getPrevRouteV2(navigate));
      return;
    }
  };

  const handleHeaderText = (): { title: string; subTitle: string } => {
    if (location.pathname.startsWith(PROFILE_NOTICE_LIST_PATH)) {
      return headerEditText;
    }

    return currentRuleFee[NoticeType?.id].header;
  };

  const headerText = useMemo(() => handleHeaderText(), []);
  return {
    headerText,
    handleValidGoBack,
    error,
  }
}

export default useNoticePlaceHook