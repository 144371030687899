import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { Container } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import HeaderSection from "@/components/headerSection/HeaderSection";
import BannerModal from "@/components/modals/BannerModal/BannerModal";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import { nottifficationUnsavedChanges } from "@/consts/notifficationsMessage";
import { PROFILE_NOTICE_LIST_PATH } from "@/consts/typeServicesPaths";
import { UsuarioAPI } from "@/models/userAuth/user";
import { createServiceFeeDataLayer } from "@/services/dataLayer/createNotice";
import { SelectNotice } from "@/store/noticeRedux/noticeSlice";
import { getPrevRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { useEffect, useMemo, useState } from "react";

import { ID_AUCTION_FLOW } from "@/consts/defaultConsts";
import { NoticeDetailAuctionModel } from "@/models/notice/noticeModels";
import useDetailNotice from "@/pages/detailNotice/hook";
import { currentRuleFee, headerEditText } from "./rulesByFlow";
import { ContainerArea } from "./styleSuggestions";

const FeeV2 = () => {
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { TITLE, MESSAGE, LEFT_BTN_TEXT, RIGHT_BTN_TEXT } =
    nottifficationUnsavedChanges;

  const dispatch = useAppDispatch();
  const {
    notice: { selectedNotice, dirtyPrice },
    user: { user },
    detailsNoticeSlice: { isLoading },
  } = useAppSelector((state) => state);

  const { handleGetCurrentDetailNotice } = useDetailNotice();
  const currentSelectedNotice = selectedNotice as SelectNotice;
  const notice: NoticeDetailAuctionModel = useMemo(
    () => handleGetCurrentDetailNotice(),
    [isLoading]
  );

  useEffect(() => {
    const currentUSer = user as UsuarioAPI;
    createServiceFeeDataLayer(currentUSer.id, currentSelectedNotice?.title);
  }, []);

  const handleValidGoBack = () => {
    if (!location.pathname.startsWith(PROFILE_NOTICE_LIST_PATH)) {
      dispatch(getPrevRoute(navigate));
      return;
    }

    if (dirtyPrice) {
      setShowAlert(true);
    } else {
      previusNavigate();
    }
  };

  const previusNavigate = () => {
    navigate(-1);
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  const handleHeaderText = (): { title: string; subTitle: string } => {
    if (location.pathname.startsWith(PROFILE_NOTICE_LIST_PATH)) {
      return headerEditText;
    }

    return currentRuleFee[currentSelectedNotice.id].header;
  };

  const headerText = useMemo(() => handleHeaderText(), []);

  const handleTitle = () => {
    if (notice?.tipoaviso?.id === ID_AUCTION_FLOW) {
      return "Fecha y precio";
    }

    return headerText.subTitle;
  };

  return (
    <Container maxWidth="xs">
      <BannerModal
        open={showAlert}
        onClose={handleCloseModal}
        title={TITLE}
        content={MESSAGE}
        option1={{
          title: LEFT_BTN_TEXT,
          onClick: handleCloseModal,
        }}
        option2={{
          title: RIGHT_BTN_TEXT,
          onClick: previusNavigate,
        }}
        color="org"
        sx={{
          width: "333px",
          height: "230px",
        }}
        sxAccion={{
          padding: "0px",
        }}
      />

      <HeaderSection
        icon="icon-arrow-left--primary"
        title={handleTitle()}
        subTitle={headerText.subTitle}
        onClick={handleValidGoBack}
      />

      <ContainerArea>
        <SectionIcon icon="icon-money" />

        <Outlet />
      </ContainerArea>
    </Container>
  );
};

export default FeeV2;
