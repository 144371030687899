export const currentRuleFee: Record<number, any> = {
  1: {
    header: {
      title: 'Precio del servicio',
      subTitle: 'Crear transporte en la ciudad',
    },
  },
  2: {
    header: {
      title: 'Precio del servicio',
      subTitle: 'Crear multidestino en la ciudad',
    },
  },
  3: {
    header: {
      title: 'Precio del servicio',
      subTitle: 'Crear transporte entre ciudades',
    },
  },
  4: {
    header: {
      title: 'Precio del servicio',
      subTitle: 'Crear remate entre ciudades',
    },
  },
  5: {
    header: {
      title: 'Precio del servicio',
      subTitle: 'Crear servicio de Mudanza',
    },
  },
};

export const headerEditText = {
  title: 'Precio',
  subTitle: 'Editar',
};
