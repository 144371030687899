import { CSSProperties } from 'react';

export type ConfigData = {
  href: string;
  target: string;
  icon: string;
  style: CSSProperties;
};
export const firtsList: ConfigData[] = [
  {
    href: 'https://www.facebook.com/PegaCargo',
    target: '_blank',
    icon: 'icon-facebook--white',
    style: { padding: '4px' },
  },
  {
    href: 'https://www.linkedin.com/company/pegacargo',
    target: '_blank',
    icon: 'icon-linkedin--white',
    style: { padding: '4px' },
  },
  {
    href: 'https://www.tiktok.com/@pegacargo.com',
    target: '_blank',
    icon: 'icon-tiktok--white',
    style: { padding: '4px' },
  },
];

export const secondList: ConfigData[] = [
  {
    href: 'https://www.instagram.com/pegacargo.peru/',
    target: '_blank',
    icon: 'icon-instagram--white',
    style: { padding: '4px' },
  },
  {
    href: 'https://wa.me/51933789672',
    target: '_blank',
    icon: 'icon-whatsapp--white',
    style: { padding: '4px' },
  },
  {
    href: 'mailto:atencionalusuario@pegacargo.com',
    target: '_blank',
    icon: 'icon-email--white',
    style: { padding: '4px' },
  },
];
