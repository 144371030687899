import { ID_AUCTION_FLOW, ID_EXPRESS_FLOW } from "@/consts/defaultConsts";
import { IAcuerdosporaceptar } from "@/models/userAuth/agrementToAccept";
import { styled, Typography } from "@mui/material";

const StyleDiscountPercentage = styled("span")(
  ({ theme }) => `
    font-family: ${theme.typography.h1.fontFamily};
    font-size: ${theme.typography.label2.fontSize};
    font-weight: ${theme.typography.label2.fontWeight};
    line-height: ${theme.typography.label2.lineHeight};
    background-color: ${theme.palette.warning.main};
    color: ${theme.palette.common.white};
    position: absolute;
    width: 69px;
    height: 32px;
    right: 0px;
    top: 0px;
    padding: 0px 16px;
    border-radius: 0px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  `
);

const StyleIsExpress = styled("span")(
  ({ theme }) => `
      font-family: ${theme.typography.caption};
      font-size: ${theme.typography.caption.fontSize};
      font-weight: ${theme.typography.caption.fontWeight};
      line-height: ${theme.typography.caption.lineHeight};
      background-color: ${theme.palette.accent.main};
      color: ${theme.palette.common.white};
      position: absolute;
      width: 92px;
      height: 32px;
      right: 0px;
      top: 0px;
      padding: 0px 16px;
      border-radius: 0px 7px;
      display: flex;
      align-items: center;
      justify-content: center;
    `
);

const StateText = styled(Typography)(
  ({ theme }) => `
    font-weight: ${theme.typography.label2.fontWeight};
    line-height: ${theme.typography.label2.lineHeight};
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 16px;

  `
);

export const stateColor: Record<string, string> = {
  poraceptar: "#0047BA",
  enespera: "#757575",
  retirado: "#D32F2F",
  anulada: "#D32F2F",
  cancelada: "#D32F2F",
  rechazado: "#D32F2F",
  aceptado: "#2E7D32",
};

type Props = {
  data: IAcuerdosporaceptar;
  show: boolean;
};

const DescountPercentage = ({ data, show }: Props) => {
  if (!show) return <></>;

  return <StyleDiscountPercentage>REMATE</StyleDiscountPercentage>;
};

const IsExpress = ({ data, show }: Props) => {
  if (!show) return <></>;

  return <StyleIsExpress> Express</StyleIsExpress>;
};

const State = ({ data, show }: Props) => {

  const handleStateValue = () => {
    const existState = !!data?.estado;

    if (!existState)
      return {
        stateColor: "",
        stateText: "",
      };

    return {
      stateColor: data?.estado.replaceAll(" ", "").toLowerCase(),
      stateText:
        data?.estado[0].toUpperCase() + data?.estado.substring(1).toLowerCase(),
    };
  };

  if (!show) return <></>;

  return (
    <StateText
      variant="caption"
      color={stateColor[handleStateValue().stateColor]}
    >
      {handleStateValue().stateText}
    </StateText>
  );
};

type StateNoticeProps = {
  data: IAcuerdosporaceptar;
  showState?: boolean;
  type: "aviso" | "solicitud";
};
const StateAviso = ({ data, showState, type }: StateNoticeProps) => {
  return (
    <>
      <DescountPercentage
        data={data}
        show={
          !!(data?.idtipo === ID_AUCTION_FLOW && type === "aviso") && !showState
        }
      />
      <IsExpress
        data={data}
        show={
          !!(data?.idtipo === ID_EXPRESS_FLOW && type === "solicitud") &&
          !showState
        }
      />
      <State data={data} show={!!showState} />
    </>
  );
};

export default StateAviso;
