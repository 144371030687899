import { Stack } from "@mui/material";
import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { ButtonStyled, MenuOp, RedirectDisplay } from "./styleHeader";
import { ListItemsDisplay } from "./typeHeaderListITem";

import { QR_EXTERNAL_PATH } from "@/consts/defaultConsts";
import useAppRedirectHook from "@/hooks/useRedirectApp";

type Props = {
  onClose: (value: boolean) => void;
  listItems: ListItemsDisplay[];
  setIsOpenLanguageModal: (value: boolean) => void;
};

const HeaderListItem: FC<Props> = (props) => {
  const { onClose, listItems } = props;
  const {
    methods: { handleRedirect, handleRedirectString },
  } = useAppRedirectHook()

  const { t } = useTranslation();

  const handleStatus = () => {
    onClose(false);
  };

  const handleValidMenu = (title?: string) => {
    if (!title) return false;
    const valid = ["CONTÁCTANOS", "PREGUNTAS FRECUENTES"];

    return valid.some((value) => value === title);
  };

  if (!Array.isArray(listItems) || !listItems.length) return <></>;

  return (
    <MenuOp disableGutters sx={{ display: { lg: "none !important" } }}>
      <Stack alignItems="center" textAlign="center">
        {listItems.map((item, i) => {
          if (handleValidMenu(item.title))
            return <Fragment key={`header-list-item-${i}`}></Fragment>;

          return (
            <RedirectDisplay
              key={`Header-list-item-${i}`}
              to={item.route}
              onClick={handleStatus}
            >
              <i className={item.icon} />
              {t(item.title || "")}
            </RedirectDisplay>
          );
        })}
        <Stack paddingX={"16px"} width={"100%"}>
          <ButtonStyled href={handleRedirectString()} target="_blank">
            descargar la aplicación
          </ButtonStyled>
        </Stack>
      </Stack>

      {/* <LanguageButtonBlack onClick={setIsOpenLanguageModal} /> */}
    </MenuOp>
  );
};

export default HeaderListItem;
