import {
  getTypesMotivationServices,
  postFormContact,
  postFormSponsoredContact,
} from "@/services/landingServices";
import { getTypesUserServices } from "@/services/userServices";
import { getMessaggeError } from "@/store/auth/utils";
import { AppDispatch } from "../../store";
import {
  setListTypesMotivations,
  setError,
  setLoading,
  setListTypesUser,
} from "./landingContactServiceSlice";

export const getListTypesMotivationThunk = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const { contactanosmotivosData } = await getTypesMotivationServices();
      dispatch(
        setListTypesMotivations(contactanosmotivosData.contactanosmotivos)
      );
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

export const getListTypesUserThunk = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const { usuariotiposData } = await getTypesUserServices();
      dispatch(setListTypesUser(usuariotiposData.usuariotipos));
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

interface PostFormContactSendProps {
  formCompleted: any;
  tipopagina?: string
}

export const postFormContactSend = (props: PostFormContactSendProps) => {
  const { formCompleted } = props;

  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {

      await postFormContact({...formCompleted})

      dispatch(setLoading(false));
      dispatch(setError(""));
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setLoading(false));
      dispatch(setError(error));

      const couseError = new Error(`${error?.status}`);

      throw new Error(error.message || undefined, { cause: couseError });
    }
  };
};
