import { PROFILE_NOTICE_OFFERT } from "@/consts/typeServicesPaths";
import { NoticesBussinesV2 } from "@/models/notice/noticeListOffersModels";
import { TypeCard } from "@/components/cardProfile/CardProfile";
import { Grid } from "@mui/material";
import List from "./List";

type Props = {
  isLoading: boolean;
  noticeList: NoticesBussinesV2[];
  indexTag: number;
  typeCard?: TypeCard;
};

const ListChildren = ({ noticeList, isLoading, typeCard }: Props) => {
  if (!noticeList.length) {
    return (
      <List
        noticeList={noticeList}
        isLoading={isLoading}
        linkFooter={PROFILE_NOTICE_OFFERT}
        typeCard={typeCard}
      />
    );
  }

  return (
    <Grid container lg={12} gap="8px" width="100%">
      <List
        noticeList={noticeList}
        isLoading={isLoading}
        linkFooter={PROFILE_NOTICE_OFFERT}
        typeCard={typeCard}
      />
    </Grid>
  );
};

export default ListChildren;
