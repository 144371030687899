import { SHOW_AFTER } from '@/consts/advertisement';
import {
  AdvertisementListThunkProps,
  SaveClickOfSponsoredNoticeBody,
} from '@/interfaces/advertisementInterface';
import {
  Advertisement,
  GetAdvertisementDTO,
} from '@/models/advertisement/advertisementModel';
import {
  HistoryAdvertisement,
  PayloadHistoryList,
} from '@/store/advertisementRedux/advertisementSlice';
import { RootState } from '@/store/store';
import { IUser } from '@/store/userRedux/userReduxInterface';

// ==================================================
//                   Advertisement
// ==================================================

type ResponseAdvertisementList = Pick<
  GetAdvertisementDTO,
  'urlSolicitud' | 'cantidadAnuncios' | 'idTipoAnuncio'
>;
// Prepare body {getAdvertisementListThunk}
export const prepareBodyAdvertisementListThunk = (
  pathname: string,
  sizeList: number,
  idTipoAnuncio?: ResponseAdvertisementList['idTipoAnuncio']
): ResponseAdvertisementList => {
  const defaultTypeAdvertisement = 2;
  const cantidadAnuncios = Math.ceil(sizeList / SHOW_AFTER); // para sacar el numero de publicidad que se necesita pedir

  const body: ResponseAdvertisementList = {
    urlSolicitud: pathname,
    idTipoAnuncio: idTipoAnuncio || defaultTypeAdvertisement,
    cantidadAnuncios,
  };

  return body;
};

type GetHistoryProps = {
  historyPlinth: HistoryAdvertisement[];
  historyList: Advertisement[];
  isSingle?: boolean;
};
export const getHistoryAdapter = ({
  historyList,
  historyPlinth,
  isSingle,
}: GetHistoryProps): HistoryAdvertisement[] => {
  if (isSingle) {
    const history = [...historyList].slice(-3).map(({ id }) => ({ id }));

    return history;
  }

  return historyPlinth.map(({ id }) => ({ id }));
};

// Valida donde tiene que aparecer el ultimo anuncio dentro del listado!!
const getIndexAdvertisement = (
  noticeLength: number,
  curentIndex: number,
  indexMap: number,
  advertisements: number
) => {
  const interval = noticeLength < SHOW_AFTER ? noticeLength : SHOW_AFTER;

  if (noticeLength % interval !== 0 && indexMap + 1 === advertisements) {
    return noticeLength;
  }

  return curentIndex + interval;
};

export const prepareHistoryListAdapter = (
  historyList: Advertisement[],
  anuncios: PayloadHistoryList
) => {
  const { advertisements, noticeLength } = anuncios;
  let index = historyList.length;
  if (index > 0) {
    index = historyList[index - 1]?.index || 0;
  }
  const history = advertisements.map((advertisement, indexMap) => {
    index = getIndexAdvertisement(
      noticeLength,
      index,
      indexMap,
      advertisements.length
    );
    return {
      ...advertisement,
      index,
    };
  });

  return history;
};

export const saveClickOfSponsoredBodyAdapter = (
  idaviso: number,
  idpatrociniodetp = 0
): SaveClickOfSponsoredNoticeBody => {
  const body = {
    avisos: [
      {
        idaviso,
        idpatrociniodetp,
      },
    ],
  };

  return body;
};

export const getBodyAdvertisementRequestAdapter = (
  state: RootState,
  body: AdvertisementListThunkProps['body'],
  isSingle: boolean
) => {
  const { user, advertisement } = state;
  const { historyList, historyPlinth } = advertisement;
  const { id, ciudad } = user.user as IUser;

  const historial = getHistoryAdapter({
    historyList,
    historyPlinth,
    isSingle,
  });

  const newBody: GetAdvertisementDTO = {
    ...body,
    idCiudad: ciudad?.id || 0,
    idUsuario: id || 0,
    historial,
  };

  return newBody;
};
