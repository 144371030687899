import SelectControlMulti from "@/components/form/selectControl/SelectControlMulti";
import Input from "@/components/inputCustom/Input";
import { StyleCustomInput } from "@/components/inputCustom/InputCustom";
import LabelRequired from "@/components/utils/LabelRequired";
import { EventType } from "@/interfaces/globalInterface";
import {
  FormOffertProps,
  InputProps,
} from "@/pages/flowNoticeNegotation/interfaces/interfaces";
import { FormControl, Typography } from "@mui/material";
import { FC } from "react";
import LabelInputRequired from "./LabelInputRequired";

const FormOffer: FC<FormOffertProps> = (props) => {
  const { title, input1, input2, onChange, onClick, inmueble, listInmueble } =
    props;

  const handleChange = (e: EventType["change"]) => {
    onChange && onChange(e);
  };

  const handleClick = (inputProp: InputProps) => (e: EventType["click"]) => {
    onClick && onClick(e, inputProp);
  };

  const handleShowtitle = () => {
    if (input1.show) {
      return title;
    }

    return `${title}: ${input1.value}`;
  };

  return (
    <>
      <Typography variant="subtitle1" marginTop="16px">
        {handleShowtitle()}
      </Typography>
      <FormControl sx={{ marginTop: "16px" }} fullWidth>
        <Input
          name={input1.name}
          label={<LabelInputRequired str={input1.label} />}
          sx={{ display: input1.show ? "" : "none", marginBottom: "20px" }}
          variant="outlined"
          value={input1.value}
          placeholder={input1.placeholder}
          onChange={handleChange}
          onClick={handleClick(input1)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {input2.show && (
          <Input
            name={input2.name}
            label={<LabelInputRequired str={input2.label} />}
            variant="outlined"
            value={input2.value}
            placeholder={input2.placeholder}
            multiline
            rows={2}
            onChange={handleChange}
            onClick={handleClick(input2)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        {inmueble && <SelectControlMulti listItems={listInmueble || []} />}
      </FormControl>
    </>
  );
};

export default FormOffer;
