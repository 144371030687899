import { USER_TOKEN_STORAGE } from '@/consts/defaultConsts';
import { getTokenServices } from '@/services/authServices';
import { loginDataLayer } from '@/services/dataLayer/auth';
import { setLocalStorage } from '@/services/localstorageServices';
import {
  changePasswordServices,
  getTypeDocumentServices,
  getUserExistServices,
  registerUserServices,
  resetPasswordServices,
  sendCodeBySMSServices,
  validateCodeServices,
} from '@/services/userServices';
import { AppDispatch } from '@/store/store';
import {
  getUserByIdTunhk,
  getUserVehiclesByIdThunk,
} from '@/store/userRedux/userThunks';
import { encriptPassword } from '@/utils/bcript';
import { AxiosError } from 'axios';

import {
  setErrorResetPassword,
  setErrorCellPhone,
  setErrorLogin,
  setErrorRegister,
  setErrorSendCode,
  setErrorValidateCode,
  setLoading,
  setSendSM,
  setSuccessCode,
  setTypeUser,
  setUserToken,
  validUserExist,
} from './authUserSlice';
import { ERROR_LOGIN_TEXT } from './defaultConsts';
import { getMessaggeError } from './utils';
import { getUserVehiclesThunk } from '@/store/userRedux/userThunks';
import { validateCompleteRegister } from '@/router/middleware/privateCreateServicesMiddleware';
import { setVehicles } from '../userRedux/userSlice';

export const validateUserCellPhoneThunk = (cellPhone: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const body = { username: cellPhone };
      const { usuarioData } = await getUserExistServices(body);

      dispatch(validUserExist(usuarioData?.usuario || null));
    } catch (err: any) {
      let cause = 500;
      const error = getMessaggeError(err);

      dispatch(setErrorCellPhone(error));
      const newError = err as AxiosError;
      if (newError.isAxiosError) {
        cause = newError?.response?.status || 500;
      }
      const couseError = new Error(`${cause}`);

      throw new Error(error.message || undefined, { cause: couseError });
    }
  };
};

export enum EnumTypeCodeSend {
  'REGISTRO' = 'SMS01', // tipo 1!!
  'RESTABLECER' = 'SMS02', // tipo 2!!
  'CAMBIARNUMERO' = 'SMS03', // tipo 3!!
}
export const sendCodeBySMSThunk = (
  cellPhone: string,
  tipo: EnumTypeCodeSend
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const body = {
        username: cellPhone,
        tipo,
      };

      const {
        metadata: [result],
      } = await sendCodeBySMSServices(body);

      dispatch(setSendSM(result));
    } catch (err: any) {
      let cause = 500;
      const error = getMessaggeError(err);

      dispatch(setErrorSendCode(error?.message));
      const newError = err as AxiosError;
      if (newError.isAxiosError) {
        cause = newError?.response?.status || 500;
      }
      const couseError = new Error(`${cause}`);
      throw new Error(error.message || undefined, { cause: couseError });
    }
  };
};

export const validateCodeThunk = (cellPhone: string, code: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const body = { username: cellPhone, codigo: code };
      const {
        metadata: [result],
      } = await validateCodeServices(body);

      dispatch(setSuccessCode(result));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setErrorValidateCode(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

export const registerUserThunk = (cellPhone: string, password: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const encriptedPassword = encriptPassword(password);
      const body = { username: cellPhone, password: encriptedPassword };
      const {
        metadata: [result],
      } = await registerUserServices(body);

      return result;
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setErrorRegister(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

export const generateTokenUserThunk = (cellPhone: string, password: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const result = await getTokenServices(cellPhone, password);

      setLocalStorage(USER_TOKEN_STORAGE, result.access_token);

      dispatch(setUserToken(result));
      return result;
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setErrorLogin(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

export const generateLoginByCredentialsThunk = (
  cellPhone: string,
  password: string
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      // const encriptedPassword = encriptPassword(password); // TODO: Esperar que en el api, acepte encritada esta data
      const { idusuario } = await dispatch(
        generateTokenUserThunk(cellPhone, password)
      );
      const result = await dispatch(getUserByIdTunhk(idusuario.toString()));

      loginDataLayer(result?.tipousuario || '', result?.id as number);

      if (!!result && !validateCompleteRegister(result)) {
        const vehiculos = await dispatch(getUserVehiclesThunk());
        dispatch(setVehicles(vehiculos));
      }

      return result;
    } catch (err: any) {
      const error = getMessaggeError(err, ERROR_LOGIN_TEXT);

      dispatch(setErrorLogin(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

export const getListTypeUserThunk = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const { tipoDocumentoData } = await getTypeDocumentServices();

      dispatch(setTypeUser(tipoDocumentoData.tipoDocumentos));
    } catch (err: any) {}
  };
};

// Reset Password
export const resetPasswordThunk = (celular: string, newPassword: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const body = {
        username: celular,
        nuevaPassword: encriptPassword(newPassword),
      };
      const {
        metadata: [result],
      } = await resetPasswordServices(body);
      dispatch(setLoading(false));

      return result;
    } catch (err: any) {
      const error = getMessaggeError(
        err,
        'Error: al restablecer la contraseña'
      );

      dispatch(setErrorResetPassword(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

export const changePasswordThunk = (
  passwordActual: string,
  passwordNueva: string
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const encriptedNewPassword = encriptPassword(passwordNueva);
      const body = { passwordActual, passwordNueva: encriptedNewPassword };
      const {
        metadata: [result],
      } = await changePasswordServices(body);
      dispatch(setLoading(false));
      return result;
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setErrorRegister(error.message));
      dispatch(setLoading(false));
      throw new Error(error.message || undefined);
    }
  };
};
