import {
  saveOffertAuctionThunk,
  saveOffertInCityThunk,
  saveOffertMovingThunk,
  saveOffertMultiDestinoThunk,
} from '@/store/offertRedux/offert.thunk';

type Props = {
  title: string;
  subTitle: string;
};

export const configCounterOfferText = {
  title: '¡Notificamos tu interés!',
  subTitle: 'Te notificaremos cuando la otra parte responda.',
};

export const configDataOffert: Record<number, Props> = {
  1: {
    title: '¡Notificamos tu interés!',
    subTitle:
      'Enviaremos un SMS y notificación cuando el transportista responda.',
  },
  2: {
    title: '¡Contraoferta enviada!',
    subTitle: 'Enviaremos un SMS y notificación cuando el cliente responda.',
  },
  3: {
    title: '¡Cotización enviada!',
    subTitle: 'Enviaremos un SMS y notificación cuando el cliente responda.',
  },
};

export const rulesDispatch: Record<number, any> = {
  1: saveOffertInCityThunk,
  2: saveOffertMultiDestinoThunk,
  3: saveOffertInCityThunk,
  4: saveOffertAuctionThunk,
  5: saveOffertMovingThunk,
};
