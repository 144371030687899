import Detalles from '@/pages/customerRequest/createRequest/Detalles/Index';
import Fecha from '@/pages/customerRequest/createRequest/Fecha/Index';
import Load from '@/pages/customerRequest/createRequest/Load/Index';
import Price from '@/pages/customerRequest/createRequest/Price/Index';
import FormOriginOrDestiny from '@/pages/customerRequest/createRequest/Rute/FormOriginOrDestiny/Index';
import FormRute from '@/pages/customerRequest/createRequest/Rute/FormRute/Index';
import Rute from '@/pages/customerRequest/createRequest/Rute/Index';
import {
  REQUEST_MAIN_CARGA_PATH,
  REQUEST_MAIN_CONDICIONES_PATH,
  REQUEST_MAIN_EXPRESS_CANCEL_PATH,
  REQUEST_MAIN_FECHA_PATH,
  REQUEST_MAIN_MOVING_DESTINO_PATH,
  REQUEST_MAIN_MOVING_DETALLES_PATH,
  REQUEST_MAIN_MOVING_ORIGEN_PATH,
  REQUEST_MAIN_PRECIO_PATH,
  REQUEST_MAIN_PRECIO_POR_HORA_PATH,
  REQUEST_MAIN_PRECIO_POR_PUNTO_PATH,
  REQUEST_MAIN_RUTA_PATH,
  REQUEST_MAIN_SOLICITUD_CREADA_PATH,
  TYPE_FLOW,
} from '@/pages/customerRequest/shared/consts/routes';

import Type from '@/pages/customerRequest/components/Type/Index';
import Conditions from '@/pages/customerRequest/createRequest/Conditions';
import FormDetalles from '@/pages/customerRequest/createRequest/Detalles/FormDetalles/Index';
import FormFecha from '@/pages/customerRequest/createRequest/Fecha/FormFecha/Index';
import FormLoad from '@/pages/customerRequest/createRequest/Load/FormLoad/FormLoad';
import FormPrice from '@/pages/customerRequest/createRequest/Price/FormPrice/FormPrice';
import Success from '@/pages/customerRequest/createRequest/Success';
import ContentSuccess from '@/pages/customerRequest/createRequest/Success/ContentSuccess';
import NoticeOff from '@/pages/profile/pages/noticeOff/NoticeOff';
import { privateCreateRequestMiddleware } from '@/router/middleware/privateCreateRequestMiddleware';
import { RouterProps } from '@/router/router';

export const customerRequestInCityFlowRouter: RouterProps[] = [
  {
    Component: Type,
    path: TYPE_FLOW,
    middleware: privateCreateRequestMiddleware,
  },
  {
    Component: Rute,
    path: REQUEST_MAIN_RUTA_PATH,
    middleware: privateCreateRequestMiddleware,
    children: [
      {
        index: true,
        Component: FormRute,
      },
    ],
  },
  {
    Component: Load,
    path: REQUEST_MAIN_CARGA_PATH,
    middleware: privateCreateRequestMiddleware,
    children: [
      {
        index: true,
        Component: FormLoad,
      },
    ],
  },
  {
    Component: Conditions,
    path: REQUEST_MAIN_CONDICIONES_PATH,
    middleware: privateCreateRequestMiddleware,
  },
  {
    Component: Price,
    path: REQUEST_MAIN_PRECIO_PATH,
    middleware: privateCreateRequestMiddleware,
    children: [
      {
        index: true,
        Component: FormPrice,
      },
    ],
  },
  {
    Component: Price,
    path: REQUEST_MAIN_PRECIO_POR_HORA_PATH,
    middleware: privateCreateRequestMiddleware,
    children: [
      {
        index: true,
        Component: FormPrice,
      },
    ],
  },
  {
    Component: Price,
    path: REQUEST_MAIN_PRECIO_POR_PUNTO_PATH,
    middleware: privateCreateRequestMiddleware,
    children: [
      {
        index: true,
        Component: FormPrice,
      },
    ],
  },
  {
    Component: Success,
    path: REQUEST_MAIN_SOLICITUD_CREADA_PATH,
    middleware: privateCreateRequestMiddleware,
    children: [
      {
        index: true,
        Component: ContentSuccess,
      },
    ],
  },

  {
    Component: Fecha,
    path: REQUEST_MAIN_FECHA_PATH,
    middleware: privateCreateRequestMiddleware,
    children: [
      {
        index: true,
        Component: FormFecha,
      },
    ],
  },
  {
    Component: Rute,
    path: REQUEST_MAIN_MOVING_ORIGEN_PATH,
    middleware: privateCreateRequestMiddleware,
    children: [
      {
        index: true,
        Component: FormOriginOrDestiny,
      },
    ],
  },
  {
    Component: Rute,
    path: REQUEST_MAIN_MOVING_DESTINO_PATH,
    middleware: privateCreateRequestMiddleware,
    children: [
      {
        index: true,
        Component: FormOriginOrDestiny,
      },
    ],
  },
  {
    Component: Detalles,
    path: REQUEST_MAIN_MOVING_DETALLES_PATH,
    children: [
      {
        index: true,
        Component: FormDetalles,
      },
    ],
  },
  {
    Component: NoticeOff,
    path: REQUEST_MAIN_EXPRESS_CANCEL_PATH,
    requiredSession: true,
  },
];
