export const NOTICE_CLIENT_PATH = '/avisos';
export const NOTICE_DRIVER_PATH = '/mis-avisos';

export const NOTICE_OFFER = '/oferta';
export const NOTICE_COUNTEROFFER = '/contraoferta';
export const NOTICE_CO_COUNTEROFFER = '/co-contraoferta';
export const COUNTEROFFER_SEND_PATH = '/contraoferta-enviada';
export const CO_COUNTEROFFER_SEND_PATH = '/co-contraoferta-confirmacion';
export const COUNTEROFFER_SEND_SUCCESS_PATH = '/contraoferta-confirmacion';

export const NOTICE_AGREEMENT_MESSAGE = '/acuerdo-mensaje';
export const NOTICE_AGREEMENT_CONFIRMATION = '/acuerdo-confirmacion';
export const NOTICE_REJECTION_CONFIRMATION = '/rechazo-confirmacion';
export const NOTICE_DECLINE = '/rechazo-mensaje';
export const DECLINE = '/rechazo';
export const AGREEMENT = '/confirmacion';

// ==========================================================================
//     TRANSPORTISTA: RECIBE OFERTA, CONTRAOFERTA, CO-CONTRAOFERTA
// ==========================================================================

export const OFFER_TO_ACCEPT = `/mis-avisos/oferta/:idOffer`;
export const OFFER_TO_ACCEPT_NO_MY = `/avisos/oferta/:idOffer`;
// export const OFFER_
export const NOTICE_NEGOTATION_COUNTER_OFFER_DRIVER = `/mis-avisos/contraoferta/:idOffer`;
export const ACCEPTANCE_CO_COUNTEROFFER_DRIVER = `/mis-avisos/co-contraoferta/:idOffer`;
export const COUNTER_OFFER_FEE = `/mis-avisos/contraoferta/:idOffer/co-contraoferta`;

// ==========================================================================================
//    TRANSPORTISTA: VISTA MENSAJE DE CONFIRMACIÓN DE LA OFERTA, CONTRAOFERTA Y CO-CONTRAOFERTA
// ==========================================================================================

export const MESSAGE_AGREEMENT_OFFER = `/mis-avisos/oferta/:idOffer/acuerdo-mensaje`;
export const AGREEMENT_MESSAGE_COUNTEROFFER_DRIVER = `/mis-avisos/contraoferta/:idOffer/acuerdo-mensaje`;
export const MESSAGE_CONFIRMATION_CO_COUNTEROFFER_DRIVER = `/mis-avisos/co-contraoferta/:idOffer/acuerdo-mensaje`;

// ==========================================================================================
//    TRANSPORTISTA: RECHAZA OFERTA, CONTRAOFERTA
// ==========================================================================================

export const DECLINE_OFFER_DRIVER = `/mis-avisos/oferta/:idOffer/rechazo-mensaje`;
export const DECLINE_MESSAGE_COUNTEROFFER_DRIVER = `/mis-avisos/contraoferta/:idOffer/rechazo-mensaje`;

// ==========================================================================================
//    TRANSPORTISTA: VISTA ACUERDO CONFIRMACIÓN DE LA OFERTA, CONTRAOFERTA Y CO-CONTRAOFERTA
// ==========================================================================================

export const CONFIRMATION_AGREEMET_OFFERT = `/mis-avisos/oferta/:idOffer/acuerdo-confirmacion`;
export const MESSAGE_CONFIRMATION_COUNTER_OFFERT = `/mis-avisos/contraoferta/:idOffer/acuerdo-confirmacion`;
export const CONFIRMATION_CO_COUNTEROFFER_DRIVER = `/mis-avisos/co-contraoferta/:idOffer/acuerdo-confirmacion`;

// ==========================================================================================
//    TRANSPORTISTA: VISTA LA OFERTA, CONTRAOFERTA PERO POR EL LADO DEL PERFIL
// ==========================================================================================

/* export const DETAILS_OFFER_DRIVER = `/mis-avisos/oferta/:idOffer/detalles`;
export const DETAILS_COUNTEROFFER_DRIVER = `/mis-avisos/contraoferta/:idOffer/detalles`; */

// ==========================================================================================
//    TRANSPORTISTA: RECHAZO CONFIRMACIÓN
// ==========================================================================================

export const REJECT_CONFIRMATION_COCOUNTEROFFER_DRIVER = `/mis-avisos/co-contraoferta/:idOffer/rechazo-confirmacion`;
export const REJECT_CONFIRMATION_COUNTEROFFER_DRIVER = `/mis-avisos/contraoferta/:idOffer/rechazo-confirmacion`;
export const REJECT_CONFIRMATION_OFFER_DRIVER = `mis-avisos/oferta/:idOffer/rechazo-confirmacion`;

// =========================================================================================================================================

// ==========================================================================================
//    CLIENTE: CONTRAOFERTA, CO-CONTRAOFERTA
// ==========================================================================================

export const ACCEPTANCE_COUNTER_OFFER = `/avisos/contraoferta/:idOffer`;
export const CO_COUNTEROFFER_CLIENT = `/avisos/co-contraoferta/:idOffer`;

// ==========================================================================================
//    CLIENTE: ACUERDO MENSAJE
// ==========================================================================================

export const AGREEMENT_OFFER = `/avisos/oferta/:idOffer/acuerdo-mensaje`;
export const AGREEMENT_COUNTEROFFER_CLIENT = `/avisos/contraoferta/:idOffer/acuerdo-mensaje`;
export const MESSAGE_CONFIRMATION_CO_COUNTER_OFFER_CLIENT = `/avisos/co-contraoferta/:idOffer/acuerdo-mensaje`;

// ==========================================================================================
//    CLIENTE: ACUERDO CONFIRMACIÓN
// ==========================================================================================

export const CONFIRMATION_AGREEMET_OFFER_CLIENT = `/avisos/oferta/:idOffer/acuerdo-confirmacion`;
export const CONFIRMATION_AGREEMET_COUNTEROFFER_CLIENT = `/avisos/contraoferta/:idOffer/acuerdo-confirmacion`;
export const CONFIRMATION_CO_COUNTEROFFER_CLIENT = `/avisos/co-contraoferta/:idOffer/acuerdo-confirmacion`;

// ==========================================================================================
//    CLIENTE: RECHAZO MENSAJE
// ==========================================================================================

export const DECLINE_OFFER = `/avisos/oferta/:idOffer/rechazo-mensaje`;
export const DECLINE_COCOUNTEROFFER = `/avisos/co-contraoferta/:idOffer/rechazo-mensaje`;

// ==========================================================================================
//    CLIENTE: RECHAZO CONFIRMACIÓN
// ==========================================================================================

export const REJECT_CONFIRMATION_OFFER_CLIENT = `avisos/oferta/:idOffer/rechazo-confirmacion`;
export const REJECT_CONFIRMATION_COUNTEROFFER_CLIENT = `avisos/contraoferta/:idOffer/rechazo-confirmacion`;
export const REJECT_CONFIRMATION_COCOUNTEROFFER_CLIENT = `avisos/co-contraoferta/:idOffer/rechazo-confirmacion`;

// =========================================================================================================================================

/* URL'S PARA EL CLIENTE  */

export const ACCEPTANCE_OFFER_CLIENT = `/avisos/oferta/aceptacion/:idOffer`;

export const DETAILS_CLOSE_OFFER = '/avisos/oferta/:idOffer/detalles';

/* URL'S PARA EL TRANSPORTISTA*/

export const EDIT_CO_COUNTER_OFFER_DRIVER = `/mis-avisos/co-contraoferta/:idOffer/editar`;

export const COUNTER_OFFER_CONFIRMATION = `/mis-avisos/contraoferta/:idOffer/co-contraoferta-confirmacion`;

// ========================================================================================================================================================================== //

// ===================================================================================
//    SECCION PARA LA EDICION DE LA NEGOCIACION (AVISO) ENTRE CLIENTE Y TRANSPORTISTA
// ===================================================================================

export const NEGOTATION_REJECTED_NEGOTATION_MESSAGE_CLIENT_NOTICE = `${NOTICE_CLIENT_PATH}/:typeOffer/:idNotice/retiro-mensaje`;
export const NEGOTATION_REJECTED_NEGOTATION_MESSAGE_DRIVER_NOTICE = `${NOTICE_DRIVER_PATH}/:typeOffer/:idNotice/retiro-mensaje`;
