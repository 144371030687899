import {
  ID_NOTICE_AUCTION,
  ID_NOTICE_BETWEEN_CITIES,
} from "@/consts/noticeV2Paths";
import { PROFILE_NOTICE_LIST_PATH } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { UsuarioAPI } from "@/models/userAuth/user";
import { createServiceConditionsDataLayer } from "@/services/dataLayer/createNotice";
import { IAvisotiposcondicione } from "@/services/noticeServices/NoticeListServices/NoticeListTypes";
import { IAvisocondicione } from "@/services/noticeServices/noticeSendData/noticeSendDataTypes";
import { fillSendDataThunk } from "@/store/noticeV2Redux/noticeV2Thunk";
import { ISendDataValues } from "@/store/noticeV2Redux/noticeV2types";
import {
  getNextRouteV2,
  getPrevRouteV2,
} from "@/store/routeActionsFlowV2/routeActionsFlowNoticeV2Thunk";
import { ISelectedVehicle } from "@/store/userRedux/userReduxInterface";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { INoticeCreateListItem } from "../NoticeList/types";
import {
  extractSendData,
  getNoticeType,
  getNoticeTypeInterface,
} from "../adapters/noticeUseTypes";
import { currentRuleConditions, headerEditText } from "./conditionsConfig";

export const useCondition = () => {
  const [listConditions, setListConditions] = useState<
    IAvisotiposcondicione[] | []
  >([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {
    noticeV2: { selectNotice, sendData, variantMoving },
    user: { user, selectedVehicle },
  } = useAppSelector((state) => state);

  const NoticeType = (selectNotice as INoticeCreateListItem) || 0;
  const data = extractSendData(
    sendData,
    NoticeType?.id,
    variantMoving && Number(variantMoving) === 1 ? true : false
  );
  const isIs1or2 = variantMoving && Number(variantMoving) === 1 ? true : false;
  const tipo = variantMoving && Number(variantMoving) === 1 ? "D" : "C";
  const currentUser = user as UsuarioAPI;
  const { cargamaxima } = selectedVehicle as ISelectedVehicle;

  const isRegister = currentUser?.flgRegistroAviso === null;

  useEffect(() => {
    handlePreloadListOfServices();
    if (!isRegister && !data?.avisocondiciones?.length) {
      createServiceConditionsDataLayer(currentUser.id, NoticeType?.title || "");
    }
  }, [sendData]);

  // Función de adaptador para buscar un elemento en newList por una condición dada
  const findConditionIndex = (
    list: IAvisotiposcondicione[],
    condition: number
  ) => {
    return list.findIndex((item) => item.condicionesservicio.id === condition);
  };

  // Función de adaptador para actualizar un elemento en newList
  const updateCondition = (
    list: IAvisotiposcondicione[],
    index: number,
    changes: Partial<IAvisotiposcondicione>
  ) => {
    list[index] = { ...list[index], ...changes };
  };

  const handlePreloadListOfServices = () => {
    const conditions = NoticeType?.avisotiposcondiciones || [];
    let newList = [...conditions];

    if (variantMoving) {
      newList = newList.filter((item) => item.tipo === tipo);
    }

    newList.sort((a, b) => a.orden - b.orden);

    if (data?.avisocondiciones) {
      data.avisocondiciones.forEach((item: IAvisocondicione) => {
        const index = findConditionIndex(newList, item.idcondiciones);
        if (index !== -1) {
          updateCondition(newList, index, { pordefecto: item.flgseleccion });
        }
      });
    }

    if (NoticeType?.id === ID_NOTICE_AUCTION) {
      const index = newList.findIndex((item) => item.flgporpeso === 1);

      if (index !== -1) {
        const nombre =
          cargamaxima === data?.pesomaximo
            ? newList[index].condicionesservicio.creadorpositivo
            : newList[index].condicionesservicio.creadornegativo;

        updateCondition(newList, index, {
          condicionesservicio: {
            ...newList[index].condicionesservicio,
            nombre,
          },
          pordefecto: cargamaxima === data?.pesomaximo ? 1 : 0,
        });
      }
    }

    const listIdsModify = [ID_NOTICE_AUCTION, ID_NOTICE_BETWEEN_CITIES];

    if (listIdsModify.includes(NoticeType?.id)) {
      const index = findConditionIndex(newList, 1);

      if (index !== -1) {
        updateCondition(newList, index, {
          condicionesservicio: {
            ...newList[index].condicionesservicio,
            nombre: newList[index].condicionesservicio.creadornegativo,
          },
          pordefecto: 0,
        });
      }
    }

    setListConditions(newList);
  };

  const handleGoBack = () => {
    previusNavigate();
  };

  const handleClick = () => {
    const values = listConditions.map((item) => {
      return {
        idcondiciones: item.condicionesservicio.id,
        flgseleccion: item.pordefecto,
      };
    });

    const sendDataValues: ISendDataValues = {
      ...data,
      avisocondiciones: values,
    };

    dispatch(
      fillSendDataThunk({
        key: getNoticeType(NoticeType?.id, isIs1or2),
        value: getNoticeTypeInterface(
          NoticeType?.id,
          {
            ...sendDataValues,
          },
          isIs1or2
        ),
      })
    ).then(() => {
      handleNavigate();
    });
  };

  const handleNavigate = () => {
    dispatch(getNextRouteV2(navigate));
  };

  const handleChange = (idCondition: number, isActive: number) => {
    const newList = [...listConditions];
    const index = newList.findIndex(
      (list) => list.condicionesservicio?.id === idCondition
    );

    if (index !== -1) {
      newList[index] = {
        ...newList[index],
        pordefecto: isActive,
      };
    }

    dispatch(
      fillSendDataThunk({
        key: getNoticeType(NoticeType?.id, isIs1or2),
        value: getNoticeTypeInterface(
          NoticeType?.id,
          {
            ...data,
            avisocondiciones: newList.map((item) => {
              return {
                idcondiciones: item.condicionesservicio.id,
                flgseleccion: item.pordefecto,
              };
            }),
          },
          isIs1or2
        ),
      })
    );
  };

  const handleHeaderText = (): { title: string; subTitle: string } => {
    if (location.pathname.startsWith(PROFILE_NOTICE_LIST_PATH)) {
      return headerEditText;
    }

    return currentRuleConditions[NoticeType?.id].header;
  };

  const previusNavigate = () => {
    dispatch(getPrevRouteV2(navigate));
  };

  const headerText = useMemo(() => handleHeaderText(), []);

  return {
    headerText,
    handleGoBack,
    listConditions,
    handleChange,
    handleClick,
  };
};
