import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { FormControl, Grid, styled } from "@mui/material";
import { useFormClientCounterOfferBetweenCities } from "./useFormClientCounterOfferBetweenCities";


const Container = styled(Grid)(
    () => `
      display: flex;
      flex-direction: column;  
      margin-top: 24px;
    `
  );

export const FormCounterOfferBetweenCitiesEdition = () => {
 
    const {
      price,
      isLoading,
      handleOnchange,
      handleDisabledEditCounterOfferButton,
      handleEditCounterOfferSubmit,
    } = useFormClientCounterOfferBetweenCities()


    return (
    <Container container>
    <form onSubmit={handleEditCounterOfferSubmit}>
      <FormControl sx={{ marginTop: "16px", width: "100%" }}>
        <Input
          id={`$1-price`}
          name="price"
          label={<LabelInputRequired str="Precio ofrecido (S/)" />}
          value={price}
          variant="standard"
          placeholder="Ingresa tu precio"
          onChange={handleOnchange}
          onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()} //evita que se escriban caracteres no numéricos(+-.,)//evita que se escriban caracteres no numéricos(+-.,)
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            inputMode: "numeric",
          }}
        />
      </FormControl>

      <Grid item sx={{ marginTop: "16px" }}>
        <ButtomLoader
          type="submit"
          buttomProps={{
            disabled: handleDisabledEditCounterOfferButton() || isLoading,
          }}
          isLoading={isLoading}
        >
          ENVIAR
        </ButtomLoader>
      </Grid>
    </form>
  </Container>
  )
}
