import { IMG_PATH } from "@/consts/defaultConsts";
import Icon from "@/utils/Icon";
import { currencyFormat, deleteDomainUrl, resizePhoto } from "@/utils/helpers";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CustomCard } from "../Card/CustomCard";
import { SmallAvatar } from "./style";

export interface ICardNegInfo {
  imgCart: string;
  imgPerson: string;
  name: string;
  qlt: number;
  points: number;
  status?: string;
  statusColor?: string;
  price: number;
  link?: string;
  onClick?: () => void;
}

const CardNegInfo = ({
  imgCart,
  imgPerson,
  name,
  qlt,
  points,
  status,
  statusColor,
  price,
  link,
  onClick,
}: ICardNegInfo) => {
  const navigate = useNavigate();
  const getSecondUrl = (img: string) => {
    return resizePhoto({ photo: `${IMG_PATH}${img}` });
  };

  const onClickNavigate = () => {
    if(link){
      navigate(deleteDomainUrl(link), {
        state: {
          hasBackNavigate: true,
        }
      });
      return;
    }
    onClick && onClick();
  }

  return (
    <CustomCard
      sx={{
        alignItems: "center",
        padding: "8px 16px",
        gap: "4px",
        display: "flex",
        justifyContent: "space-between",
      }}
      onClick={onClickNavigate}
    >
      <Box sx={{ display: "flex", gap: "8px" }}>
        <Box display="flex">
          <Avatar
            src={getSecondUrl(imgCart)}
            sx={{ width: "64px", height: "64px" }}
          />
          <Box width={20}>
            <SmallAvatar src={getSecondUrl(imgPerson)} />
          </Box>
        </Box>
        <Stack sx={{ padding: "4px 0px", justifyContent: "center" }}>
          <Typography variant="body2" fontSize="14px" fontWeight="400">
            {name}
          </Typography>
          <Typography
            variant="caption"
            fontSize="12px"
            display="flex"
            alignItems="center"
            gap="2px"
          >
            <Icon name="icon-star" color="#FFC107" size={16} /> {qlt} ({points})
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography
          variant="caption"
          color={statusColor}
          fontSize="12px"
          fontWeight="400"
        >
          {status}
        </Typography>
        <Typography variant="h6">{!!price && currencyFormat(price)}</Typography>
      </Box>
    </CustomCard >
  );
};

export default CardNegInfo;
