import ModalFilter from "@/components/Filter/components/ModalFilter/ModalFilter";
import DynamicComponent from "@/components/dinamycComponent/DinamycComponent";
import Input from "@/components/inputCustom/Input";
import LabelRequired from "@/components/utils/LabelRequired";
import ButtomLoader from "@/pages/noticeV2/components/buttonLoader/ButtomLoader";
import { Box, FormControl, Grid, Typography } from "@mui/material";
import { configAuctionBetweenCities } from "../../components/inputsConfig/inputsAuctionBetweenCities";
import { ContainerGrid } from "../../noticePlaceStyles";
import usePlaceAuctionBetweenCitiesHook from "./hook";
import { metaDataModalBetweenCities } from "../hook";

const PlaceAuctionBetweenCities = () => {
  const {
    handleInitConfig,
    showModal,
    handleOnCloseModal,
    handleSubmitModal,
    handleOnSeletedModal,
    handleFilterByTerm,
    modalActiveWith,
    listLocation,
    data,
    onSubmit,
    validateForm,
    getPorcetage,
    handleInputsCustomAuction,
    isLoading,
    validateFormCustomPriceRegular,
    validateFormCustomPrice,
    noticeTypeData,
  } = usePlaceAuctionBetweenCitiesHook();

  return (
    <ContainerGrid container>
      <ModalFilter
        open={showModal}
        onClose={handleOnCloseModal}
        onSubmit={handleSubmitModal}
        onSeleted={handleOnSeletedModal}
        onInputChange={handleFilterByTerm}
        modalActiveWith={modalActiveWith.name}
        list={listLocation}
        betweenDate={""}
        prevSelected={""}
        nameTwo
        metaDataModal={metaDataModalBetweenCities}
      />

      <FormControl
        sx={{
          gap: "16px",
        }}
      >
        {configAuctionBetweenCities.map((config) =>
          config.map((items) =>
            items.columns.map((column) => (
              <DynamicComponent {...handleInitConfig(column)} />
            ))
          )
        )}
      </FormControl>

      <Box sx={{ display: "flex", mt: 1.5 }}>
        <Typography variant="subtitle1" sx={{ fontSize: "14px" }}>
          Capacidad disponible a rematar:
        </Typography>
        <Typography
          sx={{ pl: 1 }}
        >{` ${noticeTypeData.pesomaximo} kilos`}</Typography>
      </Box>

      <HeaderTitle />

      <FormControl sx={{ marginTop: "16px", width: "100%" }}>
        <Input
          label={<LabelRequired str={"Precio regular (S/)"} />}
          name="precioregular"
          value={data.precioregular || ""}
          onChange={handleInputsCustomAuction}
          placeholder={`Por transportar capacidad disponible`}
          onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            inputMode: "numeric",
            maxLength: 6,
          }}
          error={!validateFormCustomPriceRegular(data.precioregular).valid}
          helperText={validateFormCustomPriceRegular(data.precioregular).msg}
        />
      </FormControl>
      <FormControl sx={{ marginTop: "16px", width: "100%" }}>
        <Input
          label={<LabelRequired str={"Precio remate (S/)"} />}
          name="precio"
          value={data.precio || ""}
          onChange={handleInputsCustomAuction}
          placeholder={`Precio en los distritos de cobertura`}
          onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            inputMode: "numeric",
            maxLength: 6,
          }}
          error={
            !validateFormCustomPrice(data.precio, data.precioregular).valid
          }
          helperText={
            validateFormCustomPrice(data.precio, data.precioregular).msg
          }
        />
      </FormControl>

      <Box sx={{ display: "flex", mt: 1 }}>
        <Typography variant="subtitle1" sx={{ fontSize: "14px" }}>
          Porcentaje de descuento:
        </Typography>
        <Typography sx={{ pl: 1 }}>{getPorcetage()}</Typography>
      </Box>

      <Grid item sx={{ marginTop: "16px" }}>
        <ButtomLoader
          onClick={onSubmit}
          buttomProps={{
            disabled: !validateForm() || isLoading,
          }}
          isLoading={isLoading}
        >
          CREAR AVISO
        </ButtomLoader>
      </Grid>
    </ContainerGrid>
  );
};

export const HeaderTitle = () => {
  return (
    <Box sx={{ display: "flex", marginTop: "16px", marginBottom: "16px" }}>
      <Typography variant="subtitle1">Precio del servicio</Typography>
    </Box>
  );
};

export default PlaceAuctionBetweenCities;
