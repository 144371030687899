import { BASE_URL_IMAGE_S3 } from "@/config/config";
import { IMG_PATH } from "@/consts/defaultConsts";
import { resizePhoto } from "@/utils/helpers";
import { Avatar, Badge } from "@mui/material";
import { styled } from "@mui/material/styles";

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 48,
  height: 48,
  border: `2px solid ${theme.palette.background.paper}`,
  position: "absolute",
  bottom: "3px",
  right: "-15px",
}));

export type ThirdAvatar = {
  direccion: string;
  id: number;
  idvehiculo: number;
};

type Props = {
  primaryAvatar: string;
  secondAvatar?: string;
  thirdAvatar?: ThirdAvatar[];
};

const TwoAvatars = ({ primaryAvatar, secondAvatar, thirdAvatar }: Props) => {

  const urlTruck = !!secondAvatar ? secondAvatar?.split("/")[1] : ""

  const getSecondUrl = () => {
    if (!!secondAvatar) {
      return resizePhoto({ photo: `${IMG_PATH}${urlTruck || secondAvatar}` });
    } else if (!!thirdAvatar?.length) {
      return resizePhoto({ photo: `${IMG_PATH}${thirdAvatar[0].direccion}` });
    }
  };

  return (
    <>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={<SmallAvatar src={primaryAvatar} />}
      >
        <Avatar src={getSecondUrl()} sx={{ width: 64, height: 64 }} />
      </Badge>
    </>
  );
};

export default TwoAvatars;
