import React from 'react'
import useBodySuccessTypeHook from './hook'

const BodySuccesstype = () => {
    const {
        Component
    } = useBodySuccessTypeHook();

    if (!Component) return <></>;

  return <Component />
}

export default BodySuccesstype