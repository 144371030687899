import { ModalFilterProps } from "@/interfaces/filterInterface";
import { placeholders } from "../../consts/metaData";

import ModalFilterCity from "./ModalFilterCity";
import ModalFilterDate from "./ModalFilterDate";
import ModalMultiSelect from "./ModalMultiSelect";
import ModalWeightOfTheLoad from "./ModalWeightOfTheLoad";
import { metaDataModals } from "./metaDataConfig";

const MAP_MODAL: Record<
  ModalFilterProps["modalActiveWith"],
  (props: any) => JSX.Element
> = {
  city: ModalFilterCity,
  origin: ModalFilterCity,
  destiny: ModalFilterCity,
  typeVehicle: ModalMultiSelect,
  weightOfTheLoad: ModalWeightOfTheLoad,
  typeServiceList: ModalWeightOfTheLoad,
  betweenDate: ModalFilterDate,
};

const ModalFilter = (props: ModalFilterProps) => {
  const {
    open,
    onClose,
    onSeleted,
    onInputChange,
    onSubmit,
    list,
    modalActiveWith,
    isMultiSelect,
    betweenDate,
    prevSelected,
    typeNoticeSelected,
    metaDataModal = metaDataModals, 
    nameTwo,
  } = props;

  const handleGetModal = () => {
    return MAP_MODAL[modalActiveWith];
  };

  const handleGetMetaData = () => {
    if (typeNoticeSelected) {
      const placeData = placeholders[typeNoticeSelected];
      if (placeData && placeData[modalActiveWith]) {
        const inputLabel = placeData[modalActiveWith]?.label;
        const inputPlaceholder = placeData[modalActiveWith]?.placeholder;
        return {
          ...metaDataModal[modalActiveWith],
          inputLabel,
          inputPlaceholder,
        };
      }
    }

    return metaDataModal[modalActiveWith];
  };

  const Modal = handleGetModal();
  const metaData = handleGetMetaData();

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      onSeleted={onSeleted}
      onInputChange={onInputChange}
      list={list}
      isMultiSelect={isMultiSelect}
      betweenDate={betweenDate}
      metaData={metaData}
      prevSelected={prevSelected}
      modalActiveWith={modalActiveWith}
      nameTwo={nameTwo}
    />
  );
};

export default ModalFilter;
