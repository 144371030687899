import { ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import { currencyFormat } from "@/utils/helpers";
import { Typography } from "@mui/material";
import { PriceMultidestinyOfferResponse } from "./PriceMultidestinyOfferResponse";

export const GlobalPrice = ({ data }: { data: any }) => {
 
    if ((data?.solicitud.tipoSolicitud.id as  number) === ID_MULTI_DESTINY)
        return <PriceMultidestinyOfferResponse data={data} />;
 
    return (
    <>
      <Typography
        sx={{ pt: 2, fontWeight: 700, fontSize: "22px", textAlign: "revert" }}
      >
        {currencyFormat(data.precio)}
      </Typography>
    </>
  );
};
