import { TypeFilterEnum } from '@/models/userAuth/agrementToAccept';

export const typeNoticeByIdObject: Record<number, { title: string }> = {
  1: {
    title: 'En la ciudad',
  },
  2: {
    title: 'Multidestino',
  },
  3: {
    title: 'Entre ciudades',
  },
  4: {
    title: 'Remate entre ciudades',
  },
  5: {
    title: 'Mudanza',
  },
};

export const objectTypeState: Record<
  number,
  { type: TypeFilterEnum; state: 'actives' | 'expired' | 'history' }
> = {
  1: {
    type: TypeFilterEnum.abiertas,
    state: 'actives',
  },
  2: {
    type: TypeFilterEnum.cerradas,
    state: 'history',
  },
  3: {
    type: TypeFilterEnum.vencidas,
    state: 'expired',
  },
};
