import { CLIENT_TYPE, ID_MOVING_FLOW } from "@/consts/defaultConsts";
import { textRetirarCotizacion, textRetirarNegociacion } from "@/consts/noticeConsts";
import {
  nottifficationDeclineCounterOfferDriver,
  nottifficationDeclineOfferDriver,
  nottifficationDeclineQuoteDriver,
} from "@/consts/notifficationsMessage";
import {
  EDIT_COUNTEROFFER_CLIENT,
  EDIT_COUNTEROFFER_DRIVER,
  NEGOTATION_REJECTED_NOTICE_MESSAGE_CLIENT,
  NEGOTATION_REJECTED_NOTICE_MESSAGE_DRIVER,
} from "@/consts/offertPath";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";

import { saveOfferByIdThunkV2 } from "@/store/noticeV2Negotiation/noticeV2NegotiationThunk";
import { setTypeUser } from "@/store/offertRedux/offert.slice";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ButtonCounterOffer, ContainerButtons } from "../../acceptanceOffert/components/StylesGlobalButton";
import { declineActionNotice } from "../shared/config";

export const EditNoticeCounterOfferButtons = () => {
  const {
    offert: { savedOffert },
  } = useAppSelector((state) => state);
  const { handleDeclineNotification } = UseHandleNoticeActionButtons();
  const isMoving = (savedOffert?.avisoNegociacionAviso?.tipoaviso.id as number) === ID_MOVING_FLOW;

  return (
    <ContainerButtons>
      <ButtonCounterOffer fullWidth onClick={() => handleDeclineNotification()} sx={{ borderColor: "#E0E0E0" }}>
        {isMoving ? textRetirarCotizacion : textRetirarNegociacion}
      </ButtonCounterOffer>
    </ContainerButtons>
  );
};

export const EditNoticeOfferButtons = () => {
  const {
    offert: { savedOffert },
  } = useAppSelector((state) => state);
  const { handleDeclineNotification } = UseHandleNoticeActionButtons();
  const isMoving = (savedOffert?.avisoNegociacionAviso?.tipoaviso.id as number) === ID_MOVING_FLOW;

  return (
    <ContainerButtons>
      <ButtonCounterOffer fullWidth onClick={() => handleDeclineNotification()} sx={{ borderColor: "#E0E0E0" }}>
        {isMoving ? textRetirarCotizacion : textRetirarNegociacion}
      </ButtonCounterOffer>
    </ContainerButtons>
  );
};

export const UseHandleNoticeActionButtons = () => {
  const dispatch = useAppDispatch();
  const {
    user: { user },
    saveFlowData: {
      saveFlowData: [notice],
    },
  } = useAppSelector((state) => state);
  const isClient = (user as IUser).tipousuario === CLIENT_TYPE;
  const { typeRequest = "", idOffer = "" } = useParams();
  const { pathname } = useLocation();
  const isMoving = (notice?.tipoaviso?.id as number) === ID_MOVING_FLOW;
  const isCoCounterOffer = pathname.includes("co-contraoferta");
  const isCounterOffer = pathname.includes("contraoferta");
  const textToReplace = isCounterOffer ? (isCoCounterOffer ? "co-contraoferta" : "contraoferta") : "oferta";
  const typeUser = isClient ? 1 : 2;
  const optionOffer = handleOptionOffer();
  const actionId = declineActionNotice[optionOffer][typeUser];

  const { addNotiffication } = useNotiffication();
  const navigate = useNavigate();

  function handleOptionOffer() {
    if (isCoCounterOffer || isCounterOffer) {
      return "contraoferta";
    } else {
      return "oferta";
    }
  }

  const handleEditButton = () => {
    const pathClient = EDIT_COUNTEROFFER_CLIENT.replace(":typeOffer", "contraoferta").replace(":noticeId", typeRequest || idOffer);
    const pathDriver = EDIT_COUNTEROFFER_DRIVER.replace(":typeOffer", "co-contra-oferta").replace(":noticeId", idOffer);
    const path = isClient ? pathClient : pathDriver;
    dispatch(setTypeUser(isClient ? "client" : "driver"));
    navigate(path);
  };

  const handleDeclineButton = () => {
    const path = isClient
      ? NEGOTATION_REJECTED_NOTICE_MESSAGE_CLIENT.replace(":noticeId", idOffer).replace(":typeOffer", textToReplace)
      : NEGOTATION_REJECTED_NOTICE_MESSAGE_DRIVER.replace(":noticeId", idOffer).replace(":typeOffer", textToReplace);
    const data = {
      idOffer: Number(idOffer),
      idAccion: actionId,
    };

    dispatch(saveOfferByIdThunkV2(data)).then(() => {
      navigate(path);
    });
  };

  const handleDeclineNotification = () => {
    let textToRende = !isCounterOffer ? nottifficationDeclineOfferDriver : nottifficationDeclineCounterOfferDriver;
    if (isMoving) textToRende = nottifficationDeclineQuoteDriver;

    addNotiffication({
      title: textToRende.TITLE,
      message: textToRende.MESSAGE,
      leftBtnText: textToRende.LEFT_BTN_TEXT,
      RightBtnText: textToRende.RIGHT_BTN_TEXT,
      hiddenSnackbar: true,
      hiddenModal: false,
      onSubmit: async () => {
        handleDeclineButton();
      },
    });
  };

  return {
    handleDeclineNotification,
    handleEditButton,
  };
};
