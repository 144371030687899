import { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { StyleCustomInput } from "@/components/form/customInput/styleCustomInput";
import LabelRequired from "@/components/utils/LabelRequired";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import VisibilityIcons from "../components/VisibilityIcons";
import { Typography } from "@mui/material";
import { Form } from "../components/styleLoginRegister";
import { CONFIRMATION_PATH, ENTER_THE_WEB, HOME_PAHT } from "@/consts/typeServicesPaths";
import { resetPasswordThunk } from "@/store/auth/authUserThunks";
import HeaderFlow from "../components/HeaderFlow";
import LayoutFlow from "@/components/LayoutFlow/LayoutFlow";
import { CustomControl } from "../password/styles";
import StandardAlert from "@/components/alerts/StandardAlert";
import { SUCCESS_CHANGE_PASSWORD } from "@/consts/textSuccess";

const textButton = "INICIAR SESIÓN"

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showError, setShowError] = useState("");

  const [errorMsg, setErrorMsg] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const { cellPhone, errorResetPassword, isLoading } = useAppSelector(
    (state) => state.auth
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      setShowError(errorResetPassword);
    }
  }, [errorResetPassword, isLoading]);

  const handleValidPasswords = () => {
    return newPassword === confirmPassword;
  };

  const handleChange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = currentTarget;
    const cleanValue = value.replace(/\D/g, "");

    if (cleanValue.length > 4) return;

    if (name === "newPassword") {
      setErrorMsg({ ...errorMsg, newPassword: "" });
      setNewPassword(cleanValue);
    } else {
      setConfirmPassword(cleanValue);
      setErrorMsg({ ...errorMsg, confirmPassword: "" });
    }

    setShowError("");
  };

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if(newPassword.length < 4) {
      setErrorMsg({ ...errorMsg, newPassword: "La contraseña debe tener 4 dígitos" });
      return;
    }

    if (confirmPassword.length < 4) {
      setErrorMsg({ ...errorMsg, confirmPassword: "La contraseña debe tener 4 dígitos" });
      return;
    }

    if (!handleValidPasswords()) {
      setShowError("Las contraseñas no coinciden");
      return;
    }

    try {
      await dispatch(resetPasswordThunk(cellPhone, newPassword));

      handleNavigate();
    } catch (error: any) {
      setShowError(error);
    }
  };

  const handleNavigate = () => {
    navigate(CONFIRMATION_PATH, {
      replace: true,
      state: {message: SUCCESS_CHANGE_PASSWORD, nextRute: ENTER_THE_WEB, textButton: textButton }
    });
  };

  const handleShowError = () => {
    if (typeof showError !== "string") return "Error inesperado";

    return showError;
  };

  const handleDisabled = () => {
    return !newPassword || !confirmPassword || isLoading;
  };

  return (
    <LayoutFlow>
      <HeaderFlow title="Nueva contraseña" />
      <Form onSubmit={handleOnSubmit} autoComplete="off">
        { 
        showError && !isLoading && (
          <StandardAlert
            sx={{ marginBottom: "16px" }}
            severity="error"
            color="error"
            onClick={() => {
              setShowError("");
            }}
          >
            {handleShowError()}
          </StandardAlert>
        )}
        <Typography variant="body1">
          Ingresa una contraseña de 4 dígitos que incluya sólo números.
        </Typography>
        <CustomControl fullWidth>
          <StyleCustomInput
            id="newPassword"
            name="newPassword"
            variant="standard"
            type={showNewPassword ? "text" : "password"}
            error={!!errorMsg.newPassword.length}
            helperText={errorMsg.newPassword}
            label={<LabelRequired str="Ingresa contraseña" />}
            value={newPassword}
            placeholder="Tu nueva contraseña"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
            }}
          />
          <VisibilityIcons onChangeVisible={setShowNewPassword} />
        </CustomControl>

        <CustomControl fullWidth>
          <StyleCustomInput
            id="confirmPassword"
            name="confirmPassword"
            variant="standard"
            type={showConfirmPassword ? "text" : "password"}
            error={!!errorMsg.confirmPassword.length}
            helperText={errorMsg.confirmPassword}
            label={<LabelRequired str="Confirmar contraseña" />}
            value={confirmPassword}
            onChange={handleChange}
            placeholder="Confirmar tu nueva contraseña"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
            }}
          />
          <VisibilityIcons onChangeVisible={setShowConfirmPassword} />
        </CustomControl>

        <ButtomLoader disabled={handleDisabled()} type="submit">
          SIGUIENTE
        </ButtomLoader>
      </Form>
    </LayoutFlow>
  );
};

export default ResetPassword;
