import {
  ListAviso,
  NoticeResponseSaveFlowAPI,
} from "@/models/notice/noticeModels";
import { styled, Typography } from "@mui/material";

const StyleDiscountPercentage = styled("span")(
  ({ theme }) => `
    font-family: ${theme.typography.h1.fontFamily};
    font-size: ${theme.typography.label2.fontSize};
    font-weight: ${theme.typography.label2.fontWeight};
    line-height: ${theme.typography.label2.lineHeight};
    background-color: ${theme.palette.warning.main};
    color: ${theme.palette.common.white};
    position: absolute;
    width: 69px;
    height: 32px;
    right: 0px;
    top: 0px;
    padding: 0px 16px;
    border-radius: 0px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  `
);

const StateText = styled(Typography)(
  ({ theme }) => `
    font-weight: ${theme.typography.label2.fontWeight};
    line-height: ${theme.typography.label2.lineHeight};
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 16px;
  `
);

type Props = {
  data: NoticeResponseSaveFlowAPI | ListAviso;
  show: boolean;
};

const DescountPercentage = ({ data, show }: Props) => {
  if (!show) return <></>;

  const handleCalculateDesount = () => {
    return Math.round((1 - data.precio / Number(data.precioregular)) * 100);
  };

  return (
    <StyleDiscountPercentage>
      {" "}
      -{handleCalculateDesount()}%
    </StyleDiscountPercentage>
  );
};

const State = ({ data, show }: Props) => {
  if (!show) return <></>;

  return (
    <StateText
      variant="caption"
      color={({ palette }) => palette.borderGray.contrastText}
    >
      {data?.estado?.nombre || ""}
    </StateText>
  );
};

type StateNoticeProps = {
  data: NoticeResponseSaveFlowAPI | ListAviso;
  showState?: boolean;
};

const StateNotice = ({ data, showState }: StateNoticeProps) => {
  return (
    <>
      <DescountPercentage
        data={data}
        show={!!data?.precioregular && !showState}
      />
      <State data={data} show={!!showState} />
    </>
  );
};

export default StateNotice;
