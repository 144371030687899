import LayoutFlow from "@/components/LayoutFlow/LayoutFlow";
import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import LabelRequired from "@/components/utils/LabelRequired";
import { AUTH_USER_TYPE, HOME_PAHT } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import {
  generateLoginByCredentialsThunk,
  registerUserThunk,
} from "@/store/auth/authUserThunks";
import { InputAdornment, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderFlow from "../components/HeaderFlow";
import VisibilityIcons from "../components/VisibilityIcons";
import { Form } from "../components/styleLoginRegister";
import { CustomControl } from "./styles";

const MAX_LENGTH_PASSWORD = 4;
interface LocationState {
  success?: boolean;
}
const CreatePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showError, setShowError] = useState("");

  const {
    auth: { cellPhone, isLoading, errorRegister },
    user: { isLoading: isLoadingUser },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!(state as LocationState)?.success) {
      navigate(HOME_PAHT);
    }
  }, [navigate, state]);

  const handleValidPasswords = () => {
    return password === confirmPassword;
  };

  const handleDisabled = () => {
    return (
      password.length !== MAX_LENGTH_PASSWORD ||
      confirmPassword.length !== MAX_LENGTH_PASSWORD ||
      isLoading ||
      isLoadingUser
    );
  };

  const handleChange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = currentTarget;
    const cleanValue = value.replace(/\D/g, "");
    if (cleanValue.length > 4) return;

    if (name === "password") {
      setPassword(cleanValue);
    } else {
      setConfirmPassword(cleanValue);
    }

    setShowError("");
  };

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLoading || isLoadingUser) return;

    if (!handleValidPasswords()) {
      setShowError("Las contraseñas no coinciden");
      return;
    }

    try {
      await dispatch(registerUserThunk(cellPhone, password));
      await dispatch(generateLoginByCredentialsThunk(cellPhone, password));

      handleNavigate();
    } catch (error: any) {
      setShowError(error);
    }
  };

  const handleNavigate = () => {
    navigate(AUTH_USER_TYPE, {
      replace: true,
    });
  };

  const handleShowError = () => {
    if (typeof showError !== "string") return "Error inesperado";

    return showError;
  };

  return (
    <LayoutFlow>
      <HeaderFlow title="Crea una contraseña" />
      <Form onSubmit={handleOnSubmit}>
        <Typography variant="body1">
          Ingresa una contraseña de 4 dígitos que incluya sólo números.
        </Typography>
        <>
          {!!errorRegister && (
            <StandardAlert
              sx={{ marginBottom: "16px" }}
              severity="error"
              color="error"
            >
              {errorRegister}
            </StandardAlert>
          )}
        </>
        <CustomControl fullWidth inputMode="numeric">
          <Input
            label={<LabelRequired str="Contraseña" />}
            name="password"
            variant="standard"
            placeholder="Ingresa tu contraseña"
            type={showPassword ? "" : "password"}
            error={!!showError?.length}
            helperText={handleShowError()}
            autoComplete="off"
            fullWidth
            value={password}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                  <VisibilityIcons onChangeVisible={setShowPassword} />
                </InputAdornment>
              ),
            }}
          />
        </CustomControl>
        <CustomControl fullWidth inputMode="numeric">
          <Input
            label={<LabelRequired str="Confirmar contraseña" />}
            name="confirmPassword"
            variant="standard"
            placeholder="Confirmar contraseña"
            type={showConfirmPassword ? "" : "password"}
            error={!!showError?.length}
            helperText={handleShowError()}
            autoComplete="off"
            fullWidth
            value={confirmPassword}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                  <VisibilityIcons onChangeVisible={setShowConfirmPassword} />
                </InputAdornment>
              ),
            }}
          />
        </CustomControl>
        <ButtomLoader
          type="submit"
          disabled={handleDisabled()}
          isLoading={isLoading || isLoadingUser}
        >
          SIGUIENTE
        </ButtomLoader>
      </Form>
    </LayoutFlow>
  );
};

export default CreatePassword;
