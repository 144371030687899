import { convertObjectToSearchParams } from "@/adapters/routesAdapters";
import { HEADER_FORM_DATA, USER_AUTH } from "../utils/consts";

export const getTokenAdapter = (username: string, password: string) => {
  const body = {
    username,
    password,
    grant_type: "password",
  };
  const params = convertObjectToSearchParams(body);
  const config = { headers: HEADER_FORM_DATA, auth: USER_AUTH };

  return { params, config };
};
