import { Box, Container, styled } from "@mui/material";
import { SubTitle, Title } from "../../pages/notice/noticeList/styleNoticeList";

export const ContainerHeader = styled(Container)(
  ({ theme }) => `
    padding-top: 32px;
    box-sizing: border-box;
  `
);

export const TitleSection = styled(Title)(
  ({ theme }) => `
    margin-top: 0;
    color: ${theme.palette.colorText.main};
    font-weight: 700 
  `
);

export const SubTitleSection = styled(SubTitle)(
  ({ theme }) => `
    margin-top: 0;
    margin-bottom: 0;
    font-size: ${theme.typography.body2.fontSize};
    line-height: ${theme.typography.body2.lineHeight};
    color: ${theme.palette.colorText.main};

  `
);
