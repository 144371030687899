declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export const loginDataLayer = (tipoUsario: string, idUsuario: number) => {
  window.dataLayer.push({
    event: "login",
    tipousuario: tipoUsario,
    idusuario: idUsuario,
  });
};

// Tipo de usuario !!
export const signUpStartDataLayer = (idUsuario: number) => {
  window.dataLayer.push({
    event: "sign_up_start",
    idusuario: idUsuario,
  });
};

// Registro en el formulario!!
export const singUpStartDataLayer = (tipoUsario: string, idUsuario: number) => {
  window.dataLayer.push({
    event: "sign_up_form",
    tipousuario: tipoUsario,
    idusuario: idUsuario,
  });
};

// Registro usuario pantalla de steps!!
export const signUpStepsDataLayer = (tipoUsario: string, idUsuario: number) => {
  window.dataLayer.push({
    event: "sign_up_steps",
    tipousuario: tipoUsario,
    idusuario: idUsuario,
  });
};

// Registro finalizado!!
export const signUpDataLayer = (tipoUsario: string, idUsuario: number) => {
  window.dataLayer.push({
    event: "sign_up",
    tipousuario: tipoUsario,
    idusuario: idUsuario,
  });
};

// Registro finalizado!!
export const loginStartDataLayer = () => {
  window.dataLayer.push({
    event: "login_start",
  });
};

// login Validation!!
export const loginValidationDataLayer = () => {
  window.dataLayer.push({
    event: "login_validation",
  });
};