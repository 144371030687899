import { styled, AccordionDetails, Typography, Grid, Accordion, AccordionSummary } from "@mui/material";
import { Close } from "@mui/icons-material"

export const AccordionContainer = styled(Accordion)(
    () => ({
        width: "100%",
        boxShadow: "none",
        "&&.Mui-expanded": {
            margin: "0px"
        }
    })
);

export const AccordionTitle = styled(AccordionSummary)(
    () => ({
        padding: "0px",
        maxHeight: "56px !important",
    })
);

export const TitleFAQ = styled(Typography)(
    ({ theme }) => ({
        fontSize: theme.typography.label1.fontSize,
        fontFamily: theme.typography.label1.fontFamily,
        fontWeight: theme.typography.label1.fontWeight,
        lineHeight: theme.typography.label1.lineHeight,
    })
);

export const ContainerItemFAQ = styled(AccordionDetails)(
    () => ({
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: "16px",
        width: "100%",
        "&:hover": {
            cursor: "pointer",
        },
        "&&.active": {
            backgroundColor: "rgba(2, 136, 209, 0.1)",
        }
    })
);

export const TopCard = styled(Grid)(
    () => ({
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
    })
);

export const SubTitle = styled(TitleFAQ)(
    ({ theme }) => ({
        display: "flex",
        flexBasis: "auto",
        color: theme.palette.accent.main + "!important",
    })
);

export const IconClose = styled(Close)(
    ({ theme }) => ({
        width: "24px",
        height: "24px",
        cursor: "pointer",
        display: "flex",
        flexBasis: "auto",
        color: theme.palette.org.main
    })
);
