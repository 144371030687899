import {
  DRIVER_CREATE_NOTICE_CONDITIONS_PATH,
  DRIVER_CREATE_NOTICE_CREATED_PATH,
  DRIVER_CREATE_NOTICE_LOCATION_PATH,
  DRIVER_CREATE_NOTICE_MOVING_TYPE_PATH,
  DRIVER_CREATE_NOTICE_PRICE_PATH,
  DRIVER_CREATE_NOTICE_TYPES,
  DRIVER_CREATE_NOTICE_VEHICLE_PATH,
  REGISTER_DRIVER_CREATE_NOTICE_CONDITIONS_PATH,
  REGISTER_DRIVER_CREATE_NOTICE_CREATED_PATH,
  REGISTER_DRIVER_CREATE_NOTICE_LOCATION_PATH,
  REGISTER_DRIVER_CREATE_NOTICE_MOVING_TYPE_PATH,
  REGISTER_DRIVER_CREATE_NOTICE_PRICE_PATH,
  REGISTER_DRIVER_CREATE_NOTICE_TYPES,
  REGISTER_DRIVER_CREATE_NOTICE_VEHICLE_PATH,
} from '@/consts/noticeV2Paths';
import ConditionComponent from '@/pages/noticeV2/NoticeConditions/Index';
import NoticeCreated from '@/pages/noticeV2/NoticeCreated';
import BodySuccesstype from '@/pages/noticeV2/NoticeCreated/BodySucesstype';
import NoticeList from '@/pages/noticeV2/NoticeList';
import NoticePlace from '@/pages/noticeV2/NoticePlace/Index';
import PlaceContainer from '@/pages/noticeV2/NoticePlace/placeContainers';
import NoticeType from '@/pages/noticeV2/NoticeType';
import { NoticeVehicle } from '@/pages/noticeV2/NoticeVehicle/Index';
import { validateCreateNoticeListMiddleware } from '@/router/middleware/privateCreateServicesMiddleware';
import { validAccessCreateNoticeMiddleware } from '@/router/middleware/privateRegister';
import { RouterProps } from '@/router/router';

export const noticeV2FlowsRouter: RouterProps[] = [
  // FALTA AGREGAR LAS RUTAS Y COMPONENTE
  {
    Component: NoticeList,
    path: DRIVER_CREATE_NOTICE_TYPES,
    middleware: validateCreateNoticeListMiddleware,
  },
  {
    Component: NoticeVehicle,
    path: DRIVER_CREATE_NOTICE_VEHICLE_PATH,
  },
  {
    Component: ConditionComponent,
    path: DRIVER_CREATE_NOTICE_CONDITIONS_PATH,
  },
  {
    // el nombre se cambia a "NoticeZone"
    Component: NoticePlace,
    path: DRIVER_CREATE_NOTICE_LOCATION_PATH,
    children: [
      {
        index: true,
        Component: PlaceContainer,
      },
    ],
  },
  {
    Component: NoticeCreated,
    path: DRIVER_CREATE_NOTICE_CREATED_PATH,
    children: [
      {
        index: true,
        Component: BodySuccesstype,
      },
    ],
  },
  // remate
  {
    Component: NoticePlace,
    path: DRIVER_CREATE_NOTICE_PRICE_PATH,
    children: [
      {
        index: true,
        Component: PlaceContainer,
      },
    ],
  },
  // mudanza
  {
    Component: NoticeType,
    path: DRIVER_CREATE_NOTICE_MOVING_TYPE_PATH,
  },
  // =========================
  // register
  // =========================
  {
    Component: NoticeList,
    path: REGISTER_DRIVER_CREATE_NOTICE_TYPES,
    middleware: validateCreateNoticeListMiddleware,
  },
  {
    Component: NoticeVehicle,
    path: REGISTER_DRIVER_CREATE_NOTICE_VEHICLE_PATH,
    middleware: validAccessCreateNoticeMiddleware,
  },
  {
    Component: ConditionComponent,
    path: REGISTER_DRIVER_CREATE_NOTICE_CONDITIONS_PATH,
    middleware: validAccessCreateNoticeMiddleware,
  },
  {
    Component: NoticePlace,
    path: REGISTER_DRIVER_CREATE_NOTICE_LOCATION_PATH,
    middleware: validAccessCreateNoticeMiddleware,
    children: [
      {
        index: true,
        Component: PlaceContainer,
      },
    ],
  },
  {
    Component: NoticeCreated,
    path: REGISTER_DRIVER_CREATE_NOTICE_CREATED_PATH,
    middleware: validAccessCreateNoticeMiddleware,
    children: [
      {
        index: true,
        Component: BodySuccesstype,
      },
    ],
  },
  // remate
  {
    Component: NoticePlace,
    path: REGISTER_DRIVER_CREATE_NOTICE_PRICE_PATH,
    middleware: validAccessCreateNoticeMiddleware,
    children: [
      {
        index: true,
        Component: PlaceContainer,
      },
    ],
  },
  // mudanza
  {
    Component: NoticeType,
    path: REGISTER_DRIVER_CREATE_NOTICE_MOVING_TYPE_PATH,
    middleware: validAccessCreateNoticeMiddleware,
  },
];
