import { NoticesBussinesV2List } from '@/models/notice/noticeListOffersModels';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface InitialState {
  listOfNegotiations: NoticesBussinesV2List;
  isLoadingNegotiations: boolean;
  error: any;
}

const initialState: InitialState = {
  listOfNegotiations: {
    abiertas: [], // por cerrar
    cerradas: [], // acuerdos
    acuerdos: [], // cerradas
  },
  isLoadingNegotiations: false,
  error: '',
};

export const offerListSlice = createSlice({
  name: 'offerList',
  initialState,
  reducers: {
    setError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
    },
    setIsLoadingNegotiations: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingNegotiations = payload;
    },
    setListNegotiations: (
      state,
      { payload }: PayloadAction<NoticesBussinesV2List>
    ) => {
      state.listOfNegotiations = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsLoadingNegotiations, setListNegotiations, setError } =
  offerListSlice.actions;
