import { useAppDispatch, useAppSelector } from '@/hooks/useReducer';
import { EventType } from '@/interfaces/globalInterface';
import { customerRequestObjectTypes } from '@/pages/customerRequest/shared/consts/customerRequestConsts';
import {
  REQUEST_MAIN_MOVING_DESTINO_PATH,
  REQUEST_MAIN_MOVING_ORIGEN_PATH,
} from '@/pages/customerRequest/shared/consts/routes';
import {
  ISaveRequestOriginOrDestiny,
  setSelectCity,
  setSelectDestiny,
  setSelectOrigin,
  setSelectedCityDestiny,
} from '@/store/customerRequestRedux/customerRequestSlice';
import { saveDataRequestMovingOriginOrDestinyThunk } from '@/store/customerRequestRedux/customerRequestThunk';
import { getCitiesByTermThunk } from '@/store/locationRedux/citiesThunks';
import { getDistrictsByCityAndTermThunk } from '@/store/locationRedux/districtsThunks';
import { SelectCity } from '@/store/locationRedux/selectLocation';
import { ITipoInmuebles } from '@/store/offertRedux/offert.interface';
import { getInmueblesThunk } from '@/store/offertRedux/offert.thunk';
import { getNextRoute } from '@/store/routeActionsFlow/routeActionsFlowNoticeThunk';
import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  BuildingFloorName,
  maxLengthBuildingFloor,
} from '@/consts/noticeConsts';
import { IUser } from '@/store/userRedux/userReduxInterface';
import { validateValueInput } from '@/utils/helpers';
import { InputProps } from './FormRequest';
import {
  MODAL_TYPE_REQUEST,
  ModalTypeRequest,
  configFormRequest,
  modalTitleConfig,
} from './configData';
interface IFormConfig {
  [key: string]: string;
}

const useOriginOrDestinyHook = () => {
  const [modalTitle, setModalTitle] = useState('');
  const [openModal2, setOpenModal2] = useState(false);
  const [modalType, setModalType] = useState<ModalTypeRequest['type']>(
    MODAL_TYPE_REQUEST.origin
  );

  const [reference, setReference] = useState('');
  const [referenceDestiny, setReferenceDestiny] = useState('');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const {
    user: { user },
    cities: { citiesByTerm },
    districts: { districts },
    customerRequest: {
      isLoading,
      flgCiudadoEntreciudades,
      selectedTypeRequest,
      // origin
      selectedCity,
      originReference,
      selectedOrigin,
      piso,
      idInmueble,
      flgAscensor,
      // destiny
      selectedCityDestiny,
      selectedDestiny,
      destinyReference,
      pisoDestino,
      idInmuebleDestino,
      flgAscensorDestino,
      requestDetailsView,
    },
    offert: { tipoInmuebles },
  } = useAppSelector((state) => state);

  const customerRequest = useAppSelector((state) => state.customerRequest);
  const isMovingOrigin = pathname.includes('/mudanza/origen');
  const isMovingDestiny = pathname.includes('/mudanza/destino');
  const inCity = flgCiudadoEntreciudades === 1;
  const userDistrict = (user as IUser).distrito;

  const [formRequest, setFormRequest] = useState<IFormConfig>({
    idInmueble: '0',
    piso: '',
    elevador: '0',
  });

  useEffect(() => {
    if (!!userDistrict.nombre) {
      dispatch(
        setSelectOrigin({
          id: userDistrict.id,
          nombre: userDistrict.nombre,
        })
      );
    }
  }, []);

  useEffect(() => {
    dispatch(getInmueblesThunk());
    if (tipoInmuebles as ITipoInmuebles[]) {
      if (showOrigin()) {
        setFormRequest({
          idInmueble: idInmueble?.toString() || '0',
          piso: piso?.toString() || '',
          elevador: flgAscensor?.toString() || '0',
        });
        setReference(originReference || '');
      }
      if (showDestiny()) {
        setFormRequest({
          idInmueble: idInmuebleDestino?.toString() || '0',
          piso: pisoDestino?.toString() || '',
          elevador: flgAscensorDestino?.toString() || '0',
        });
        setReferenceDestiny(destinyReference || '');
      }
    }
  }, [pathname]);

  const showOrigin = () => {
    const valueToReplace = customerRequestObjectTypes[selectedTypeRequest.id];
    return (
      pathname ===
      REQUEST_MAIN_MOVING_ORIGEN_PATH.replace(':typeRequest', valueToReplace)
    );
  };

  const showDestiny = () => {
    const valueToReplace = customerRequestObjectTypes[selectedTypeRequest.id];
    return (
      pathname ===
      REQUEST_MAIN_MOVING_DESTINO_PATH.replace(':typeRequest', valueToReplace)
    );
  };

  const handleInputClickModal = (
    event: EventType['click'],
    { name }: InputProps
  ) => {
    const invalidInputs = [
      origin.input1.name,
      origin.input2.name,
      destiny.input1.name,
      destiny.input2.name,
    ];
    // const isCity = [origin.input1.name, destiny.input1.name].includes(name);
    if (invalidInputs.every((inputName) => inputName !== name)) return;

    setModalTitle(modalTitleConfig[name]);
    setModalType(name as ModalTypeRequest['type']);
    setOpenModal2(true);
  };

  const handleOnChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = target;

    const acceptsArray = [
      origin.input1.name,
      origin.input2.name,
      destiny.input1.name,
      destiny.input2.name,
    ];

    if (acceptsArray.includes(name)) {
      setOpenModal2(true);
      return;
    }

    const newValue = validateValueInput(value);

    if (isMovingDestiny) {
      setReferenceDestiny(newValue);
    } else if (isMovingOrigin) {
      setReference(newValue);
    }
  };

  const handleSubmitModal = (selected: Record<string, any> | null) => {
    if (!selected) return;

    const valueSelected = {
      ...selected,
    } as SelectCity;
    if (modalType === MODAL_TYPE_REQUEST.ciudad1) {
      dispatch(setSelectCity(valueSelected));
      dispatch(
        setSelectOrigin({
          id: 0,
          nombre: '',
        })
      );
    } else if (modalType === MODAL_TYPE_REQUEST.ciudad2) {
      dispatch(setSelectedCityDestiny(valueSelected));
      dispatch(
        setSelectDestiny({
          id: 0,
          nombre: '',
        })
      );
    } else if (modalType === MODAL_TYPE_REQUEST.origin) {
      dispatch(setSelectOrigin(valueSelected));
    } else {
      dispatch(setSelectDestiny(valueSelected));
    }
    setOpenModal2(false);
  };

  const handleChangeModal = (term: string) => {
    if (term.length < 3) return;
    if (
      modalType === MODAL_TYPE_REQUEST.ciudad1 ||
      modalType === MODAL_TYPE_REQUEST.ciudad2
    ) {
      dispatch(getCitiesByTermThunk(term));
    }

    if (!inCity) {
      dispatch(
        getDistrictsByCityAndTermThunk(
          (showOrigin() ? selectedCity : selectedCityDestiny)?.id.toString(),
          term
        )
      );
    } else {
      dispatch(
        getDistrictsByCityAndTermThunk(selectedCity?.id?.toString(), term)
      );
    }
  };
  const { origin, destiny } = configFormRequest;

  const handleChange = (e: any) => {
    const { value, name } = e.target;

    if (name === BuildingFloorName) {
      if (value.length <= maxLengthBuildingFloor) {
        setFormRequest({
          ...formRequest,
          [name]: value,
        });
      }
    } else {
      setFormRequest({
        ...formRequest,
        [name]: value,
      });
    }
  };

  const validateForm = (validateFecha: () => void) => {
    const { idInmueble, piso, elevador } = formRequest;
    const isNumber = (value: string) => {
      return Number(value) !== 0 && Number(value) > 0;
    };
    const isInvalid =
      Number(idInmueble) === 1
        ? !isNumber(idInmueble)
        : !isNumber(idInmueble) || !isNumber(piso) || !isNumber(elevador);

    if (showOrigin() && !inCity) {
      return isInvalid || !selectedCity.id || !selectedOrigin.id || !reference;
    }
    if (showDestiny() && !inCity) {
      return (
        isInvalid ||
        !selectedCityDestiny.id ||
        !selectedDestiny.id ||
        !referenceDestiny
      );
    }
    if (showOrigin() && inCity) {
      return !!(
        isInvalid ||
        !selectedOrigin.id ||
        !reference ||
        validateFecha()
      );
    }
    if (showDestiny() && inCity) {
      return !!(
        isInvalid ||
        !selectedDestiny.id ||
        !referenceDestiny ||
        validateFecha()
      );
    }
  };

  const handleSubmit = (fechaSubmit: () => void) => {
    const { idInmueble, piso, elevador } = formRequest;
    const data: ISaveRequestOriginOrDestiny = {
      idInmueble: Number(formRequest.idInmueble),
      piso: Number(idInmueble) === 1 ? null : piso,
      flgAscensor: Number(idInmueble) === 1 ? null : elevador,
      reference: isMovingOrigin ? reference : referenceDestiny,
    };
    fechaSubmit();
    dispatch(
      saveDataRequestMovingOriginOrDestinyThunk(data, showOrigin() ? 1 : 2)
    ).then((res) => {
      handleNextRute();
    });
  };

  const handleNextRute = () => {
    const valueToReplace = customerRequestObjectTypes[selectedTypeRequest.id];
    dispatch(
      getNextRoute(navigate, { valueToSearch: ':typeRequest', valueToReplace })
    );
  };

  const activeNameTwo = (typeModal: string) => {
    return [
      MODAL_TYPE_REQUEST.ciudad2.toString(),
      MODAL_TYPE_REQUEST?.ciudad1.toString(),
    ].includes(typeModal);
  };

  return {
    isLoading,
    modalTitle,
    openModal2,
    setOpenModal2,
    modalType,
    reference,
    setReference,
    referenceDestiny,
    formRequest,
    setFormRequest,
    selectedCity,
    selectedCityDestiny,
    selectedOrigin,
    selectedDestiny,
    origin,
    destiny,
    handleChange,
    handleOnChange,
    handleInputClickModal,
    handleSubmitModal,
    handleChangeModal,
    validateForm,
    handleSubmit,
    citiesByTerm,
    districts,
    customerRequest,
    tipoInmuebles,
    inCity,
    isMovingDestiny,
    showOrigin,
    showDestiny,
    activeNameTwo,
    requestDetailsView,
  };
};

export default useOriginOrDestinyHook;
