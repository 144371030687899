import { useAppSelector } from "@/hooks/useReducer";
import { objectRenderComponent } from "./config";

const FormEditRute = () => {
  const {   
    requestDetailsView } = useAppSelector((state) => state.customerRequest);

  const Component = objectRenderComponent[requestDetailsView?.tipoSolicitud.id as number];
  if (!Component) return <></>;

  return <Component />;
};


export default FormEditRute