import { styled, Grid, Stack } from "@mui/material";

export const SkeletonSectionContainer = styled(Stack)(() => ({
  width: "100%",
  maxWidth: "1280px",
  margin: "auto",
  padding: "16px 0",
  alignItems: "center",
  rowGap: "16px",
  " @media only screen and (min-width: 768px)": {
    padding: "30px 16px",
  },
}));

export const SkeletonSectionHeaderContainer = styled(Grid)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  columns: 2,
  columnGap: "16px",
}));

export const SkeletonGroupContainer = styled(Stack)(() => ({
  maxWidth: "100%",
}));
