import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import SkeletonViewService from "@/pages/loading/SkeletonViewService";
import { getNegotationRequestThunk } from "@/store/noticeNegotationRequestRedux/noticeNegRequesThunk";
import { validateAuthorizationByCode } from "@/utils/helpers";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AgreementConfirmation from "./AgreementConfirmation";


const NegAgreementConfirmation = () => {
  const { noticeNegotationRequest: { errorCode, isLoading, savedDataNogotation}  } = useAppSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { typeId = "" } = useParams();

  useEffect(() => {
    dispatch(getNegotationRequestThunk(typeId));
  }, []);

  useEffect(() => {
    validateAuthorizationByCode({ errorCode, navigate });
  }, [errorCode]);



  if(!savedDataNogotation || isLoading) return (<SkeletonViewService />);

  return <AgreementConfirmation />;
};

export default NegAgreementConfirmation;
