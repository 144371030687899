import BasicModal from "@/components/dialog/Notiffication";
import { useAppDispatch } from "@/hooks/useReducer";
import {
  NoticeV2NegType,
  resetDetailsNoticeSlice,
} from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { getNegotiationByIdThunk } from "@/store/noticeV2Negotiation/noticeV2NegotiationThunk";
import React from "react";
import { useParams } from "react-router-dom";
import SkeletonViewService from "../../../loading/SkeletonViewService";

import {
  ID_AUCTION_FLOW,
  ID_BETWEEN_CITIES,
  ID_IN_CITY,
  ID_MOVING_FLOW,
  ID_MULTI_DESTINY,
} from "@/consts/defaultConsts";
import useGetData from "../../shared/hooks/useGetData";
import { NoticeNegLayout } from "./NoticeNegLayout";
import { AuctionNoticeV2Neg } from "./containers/AuctionNoticeV2Neg/Index";
import { BtnCitiesNoticeV2Neg } from "./containers/BtnCitiesV2Neg/Index";
import { InTheCityNoticeV2Neg } from "./containers/InTheCityNoticeV2Neg/Index";
import { MovingNoticeV2Neg } from "./containers/MovingNoticeV2Neg/Index";
import { MultidestinyNoticeV2Neg } from "./containers/MultidestinyNoticeV2Neg/Index";

export const componentToRender: Record<
  number,
  ({ notice }: { notice: NoticeV2NegType }) => JSX.Element
> = {
  [ID_IN_CITY]: InTheCityNoticeV2Neg,
  [ID_BETWEEN_CITIES]: BtnCitiesNoticeV2Neg,
  [ID_MULTI_DESTINY]: MultidestinyNoticeV2Neg,
  [ID_MOVING_FLOW]: MovingNoticeV2Neg,
  [ID_AUCTION_FLOW]: AuctionNoticeV2Neg,
};

const CounterOffertdetails = () => {
  const { notice, isLoadingNoticeV2 } = useGetData();
  const dispatch = useAppDispatch();
  const { idOffer = "" } = useParams();

  React.useEffect(() => {
    dispatch(resetDetailsNoticeSlice());
    dispatch(getNegotiationByIdThunk(Number(idOffer)));
  }, []);

  if (isLoadingNoticeV2 || !notice) return <SkeletonViewService />;

  const Component =
    componentToRender[(notice as NoticeV2NegType)?.idtipoaviso as number];

  if (!Component) return <></>;

  return (
    <>
      <NoticeNegLayout>
        <Component notice={notice as NoticeV2NegType} />
      </NoticeNegLayout>
      <BasicModal />
    </>
  );
};

export default CounterOffertdetails;
