import { generalConfigDataFinished } from '@/pages/noticeV2Negotation/shared/config/generalConfigFinished';
import { ConfigDataNegotationV2 } from '@/pages/noticeV2Negotation/shared/hooks/useNoticeConfigDataV2';

export const detailsConfigAcceptFinished: ConfigDataNegotationV2[] = [
  ...generalConfigDataFinished,
];

export const detailsConfigCounterOffer: ConfigDataNegotationV2[] = [
  ...generalConfigDataFinished,
];
