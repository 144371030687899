import { CLIENT_TYPE, DRIVER_TYPE } from '@/consts/defaultConsts';
import { DRIVER_CREATE_NOTICE_TYPES } from '@/consts/noticeV2Paths';
import {
  HOME_PAHT,
  MAIN_NOTICE_MOVING_PATH,
  MAIN_NOTICE_MULTI_DESTYNI_PATH,
  MAIN_NOTICE_PATH,
  MAIN_NOTICE_TRANSPORT_AUCTION_PATH,
  MAIN_NOTICE_TRANSPORT_BETWEEN_CITIES,
  NO_ACCESS_CLIENT,
} from '@/consts/typeServicesPaths';
import { UsuarioAPI } from '@/models/userAuth/user';
import { RootState } from '@/store/store';
import { getRedirectPath } from '../redirectRules/redirectRules';
import { MiddlewarePaths } from '../router';
import { privateRuteWithLoginMiddleware } from './privateCommontMiddleware';

const privatesBaseRoutes = [
  MAIN_NOTICE_PATH,
  MAIN_NOTICE_MULTI_DESTYNI_PATH,
  MAIN_NOTICE_TRANSPORT_BETWEEN_CITIES,
  MAIN_NOTICE_TRANSPORT_AUCTION_PATH,
  MAIN_NOTICE_MOVING_PATH,
  DRIVER_CREATE_NOTICE_TYPES,
];
export const privateCreateServicesMiddleware = (
  state: RootState,
  { currentPath }: MiddlewarePaths
): string => {
  const user = state.user.user as UsuarioAPI;
  if (!user.tipousuario || user.tipousuario === CLIENT_TYPE) {
    return getRedirectPath(user) || HOME_PAHT;
  }

  const isCompleteRegister = validateCompleteRegister(user);
  if (!isCompleteRegister && user.tipousuario === DRIVER_TYPE) {
    const isPrivateRoute = privatesBaseRoutes.findIndex((route) =>
      currentPath.startsWith(route)
    );

    if (isPrivateRoute >= 0) {
      return getRedirectPath(user) || HOME_PAHT;
    }
  }

  return '';
};

export const validateCompleteRegister = (user: UsuarioAPI) => {
  const {
    registroDocumento,
    registroPerfil,
    registroPropiedad,
    registroVehiculo,
    verificacionRegistro,
  } = user;

  return (
    !!registroDocumento &&
    !!registroPerfil &&
    !!registroPropiedad &&
    !!registroVehiculo &&
    !!verificacionRegistro
  );
};

export const privateOffertMiddleware = (state: RootState): string => {
  const user = state.user.user as UsuarioAPI;

  const isCompleteRegister = validateCompleteRegister(user);
  if (!isCompleteRegister && !user.tipousuario) {
    return getRedirectPath(user) || HOME_PAHT;
  }

  return '';
};

export const validateCreateNoticeListMiddleware = (
  state: RootState,
  { currentPath, pathname }: MiddlewarePaths
): string => {
  const currentUser = state.user.user as UsuarioAPI;
  const redirectPath = privateRuteWithLoginMiddleware(state, {
    currentPath,
    pathname,
  });

  const currentRute = privateCreateServicesMiddleware(state, {
    currentPath,
    pathname,
  });

  if (currentRute) {
    return currentRute;
  } else if (redirectPath) {
    return redirectPath;
  } else if (currentUser?.tipousuario !== 'T') {
    return NO_ACCESS_CLIENT;
  }

  return '';
};
