import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { StyleCustomInput } from "@/components/form/customInput/styleCustomInput";
import ReCaptchaTerms from "@/components/reCaptchaTerms/ReCaptchaTerms";
import LabelRequired from "@/components/utils/LabelRequired";
import { BASE_URL_IMAGE } from "@/config/config";
import {
  CONTACT_HERO_LG,
  CONTACT_HERO_MD,
  CONTACT_HERO_SM,
} from "@/consts/defaultConsts";
import { CONTACT_US_CONFIRMATION_PATH } from "@/consts/typeServicesPaths";
import useRecaptcha from "@/hooks/useRecaptcha";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType, ResponsiveImage } from "@/interfaces/globalInterface";
import { IFormContact } from "@/models/landing/contactFormApi";
import Footer from "@/pages/footer/Footer";
import {
  getListTypesMotivationThunk,
  getListTypesUserThunk,
  postFormContactSend,
} from "@/store/landingServices/landingContact/landingServicesThunk";
import { FormControl, MenuItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BannerLand from "../components/bannerLand/BannerLand";
import InfoLand from "../components/infoLand/InfoLand";
import SectionLand from "../components/sectionLand/sectionLand";
import { ContainerContact, Form } from "./styleContact";

const initialForm: IFormContact = {
  tipopagina: "c",
  tipousuario: "default",
  nombreyapellido: "",
  celular: "",
  email: "",
  idmotivo: "default",
  mensaje: "",
};

const responsiveImages: ResponsiveImage[] = [
  {
    cssProperties: [
      {
        property: "backgroundImage",
        value: `url(${BASE_URL_IMAGE}${CONTACT_HERO_MD})`,
      },
      { property: "minHeight", value: "240px" },
      { property: "maxHeight", value: "260px" },
    ],
    mediaMinWidth: "961px",
  },
  {
    cssProperties: [
      {
        property: "backgroundImage",
        value: `url(${BASE_URL_IMAGE}${CONTACT_HERO_LG})`,
      },
      { property: "minHeight", value: "320px" },
      { property: "maxHeight", value: "340px" },
    ],
    mediaMinWidth: "1280px",
  },
];

const Contact = () => {
  const [form, setForm] = useState<IFormContact>(initialForm);
  const [errorAPI, setErrorAPI] = useState("");
  const { listMotivations, listTypesUser, isLoading } = useAppSelector(
    (state) => state.landingContact
  );

  const { handleGetTokenRecaptcha } = useRecaptcha("contact");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getListTypesMotivationThunk());
    dispatch(getListTypesUserThunk());

    !!errorAPI && setErrorAPI("");
    return () => {
      !!errorAPI && setErrorAPI("");
    };
  }, []);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;
    if (name === "celular") {
      setForm({ ...form, [name]: value.replace(/\D/g, "") });
    } else {
      setForm({ ...form, [name]: value });
    }

    !!errorAPI && setErrorAPI("");
  };

  const handleSubmit = async (event: EventType["submit"]) => {
    event.preventDefault();
    if (isLoading) return;

    const { tokenCaptcha, error } = await handleGetTokenRecaptcha();
    if (error) {
      setErrorAPI(error);
      return;
    }
    let data = {
      formCompleted: { ...form, tokenCaptcha },
    };

    dispatch(postFormContactSend({ ...data }))
      .then(() => {
        navigate(CONTACT_US_CONFIRMATION_PATH);
      })
      .catch((error: Error) => setErrorAPI(error?.message));
  };

  const handleDisabled = () => {
    const conditionMessage = !form?.mensaje || form?.mensaje?.length < 5;
    const conditions = [
      form.tipousuario === "default",
      form.nombreyapellido === "",
      form.nombreyapellido.length < 5,
      form.celular.length < 9,
      form.idmotivo === "default",
      conditionMessage,
      errorAPI,
    ];

    return conditions.some((condition) => condition);
  };

  return (
    <>
      <BannerLand
        urlImg={`${BASE_URL_IMAGE}${CONTACT_HERO_SM}`}
        responsive={responsiveImages}
        positionXY={{ xs: "center", md: "center" }}
        minHeight="166px"
      />
      <SectionLand
        backgroundColor="#382E2B"
        sx={{
          padding: {
            xs: "0 16px",
            sm: "32px",
          },
        }}
      >
        <InfoLand
          title="Contáctanos"
          subtitle=""
          height="128px"
          sx={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            height: {
              xs: "120px",
              sm: "120px",
            },
          }}
        />
      </SectionLand>
      <ContainerContact maxWidth="xs">
        {errorAPI && (
          <StandardAlert
            sx={{ marginBottom: "16px" }}
            severity="error"
            color="error"
            onClick={() => {}}
          >
            {errorAPI}
          </StandardAlert>
        )}
        <Typography variant="h6">Formulario de contacto</Typography>
        <Form id="formContact" onSubmit={handleSubmit} autoComplete="off">
          <FormControl fullWidth>
            <StyleCustomInput
              id="tipousuario"
              name="tipousuario"
              label={<LabelRequired str="Indicanos si eres" />}
              select
              value={form.tipousuario}
              variant="standard"
              onChange={handleChange}
            >
              <MenuItem
                value="default"
                selected={true}
                disabled={true}
                sx={{ display: "none" }}
              >
                Seleccionar
              </MenuItem>
              {listTypesUser.map(({ id, nombre }) => (
                <MenuItem key={nombre} value={id}>
                  {nombre}
                </MenuItem>
              ))}
            </StyleCustomInput>
          </FormControl>

          <FormControl fullWidth>
            <StyleCustomInput
              id="nombreyapellido"
              name="nombreyapellido"
              inputProps={{
                minLength: 1,
                maxLength: 40,
              }}
              value={form.nombreyapellido}
              label={<LabelRequired str="Nombres y apellidos" />}
              variant="standard"
              placeholder="Nombres y apellidos"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl fullWidth>
            <StyleCustomInput
              id="celular"
              name="celular"
              inputProps={{
                inputMode: "numeric",
                minLength: 9,
                maxLength: 9,
              }}
              value={form.celular}
              label={<LabelRequired str="Número de celular" />}
              variant="standard"
              placeholder="Celular"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl fullWidth>
            <StyleCustomInput
              id="email"
              name="email"
              inputProps={{
                maxLength: 50,
              }}
              value={form.email}
              label="Correo electrónico"
              type="email"
              variant="standard"
              placeholder="(Opcional)"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl fullWidth>
            <StyleCustomInput
              id="idmotivo"
              name="idmotivo"
              label={<LabelRequired str="Motivo" />}
              select
              variant="standard"
              value={form.idmotivo}
              onChange={handleChange}
            >
              <MenuItem
                value="default"
                selected={true}
                disabled={true}
                sx={{ display: "none" }}
              >
                Seleccionar
              </MenuItem>
              {listMotivations.map(({ id, nombre }) => (
                <MenuItem key={nombre} value={id}>
                  {nombre}
                </MenuItem>
              ))}
            </StyleCustomInput>
          </FormControl>

          <FormControl fullWidth>
            <StyleCustomInput
              id="mensaje"
              name="mensaje"
              inputProps={{
                minLength: 1,
                maxLength: 200,
              }}
              value={form.mensaje}
              multiline
              rows={4}
              label={<LabelRequired str="Escribe tu mensaje aquí" />}
              variant="standard"
              placeholder="Coméntanos tus dudas o consultas y nos comunicaremos contigo."
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChange}
            />
          </FormControl>

          <ReCaptchaTerms />

          <ButtomLoader
            type="submit"
            disabled={handleDisabled()}
            isLoading={isLoading}
          >
            Enviar
          </ButtomLoader>
        </Form>
      </ContainerContact>
      <Footer />
    </>
  );
};

export default Contact;
