import { useAppSelector } from "@/hooks/useReducer";
import { FC, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { BaseRouterProps } from "./router";

interface Props extends Omit<BaseRouterProps, "Component"> {
  children: JSX.Element[] | JSX.Element;
}

const PrivateRoute: FC<Props> = ({
  children,
  redirectPath = "/",
  path = "",
  middleware,
  ...props
}: Props) => {
  const state = useAppSelector((state) => state);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (middleware) {
      const redirectPath = middleware(state, { currentPath: path, pathname });

      if (redirectPath) navigate(redirectPath, { replace: true });
    }
  }, [path]);

  if (!props?.isFree) {
    if (props?.requiredSession && !Object.keys(state.user.user).length) {
      return <Navigate to={redirectPath} replace />;
    } else if (
      props?.requiredSession === false &&
      !!Object.keys(state.user.user).length
    ) {
      return <Navigate to={redirectPath} replace />;
    }
  }

  return <>{children}</>;
};

export default PrivateRoute;
