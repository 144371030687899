import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { ISolicitudNegociacion } from "@/services/interfaces/RequestNegResponseSuccess";
import { getNegotationRequestThunk } from "@/store/noticeNegotationRequestRedux/noticeNegRequesThunk";
import { validateAuthorizationByCode } from "@/utils/helpers";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const useRequestNegFinishedRejectHook = () => {
    const { noticeNegotationRequest: { errorCode, isLoading: loading, savedDataNogotation } } = useAppSelector((state) => state);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { idRequest = "" } = useParams();

    useEffect(() => {
        dispatch(getNegotationRequestThunk(idRequest));
    }, []);

    useEffect(() => {
        validateAuthorizationByCode({ errorCode, navigate });
    }, [errorCode]);

    return {
        savedDataNogotation: savedDataNogotation as ISolicitudNegociacion,
        loading,
    }
}







export default useRequestNegFinishedRejectHook;