import DropZone from "@/components/DrowZone/DropZone";
import LayoutFlow from "@/components/LayoutFlow/LayoutFlow";
import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import BannerModal from "@/components/modals/BannerModal/BannerModal";
import LabelRequired from "@/components/utils/LabelRequired";
import { BASE_URL_IMAGE } from "@/config/config";
import {
  USER_CARD_PROPERTY_PAHT_DEFAULT,
  USER_CARD_PROPERTY_PAHT_DEFAULT_BACK,
} from "@/consts/defaultConsts";
import {
  REGISTER_DRIVER_STEPS,
  REGITER_DRIVER_PROPERTY_CARD,
} from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { DirtyTypeProps } from "@/interfaces/globalInterface";
import { UsuarioAPI } from "@/models/userAuth/user";
import { VehicleAPI } from "@/models/vehicle/vehicleModel";
import HeaderFlow from "@/pages/user/components/HeaderFlow";
import { setError } from "@/store/userRedux/userSlice";
import {
  getUserByIdTunhk,
  uploadPropertyCardThunk,
} from "@/store/userRedux/userThunks";
import { getNextPage } from "@/utils/helpers";
import { Box, Typography, styled } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { To, useLocation, useNavigate } from "react-router-dom";
import { objectNextPage } from "./configDataPropertyCard";

const PrimaryText = styled(Typography)(
  () => `
    margin-top: 16px;
  `
);

const From = styled("form")({
  marginTop: "24px",
});

const PropertyCard = () => {
  const [image, setImage] = useState<string | File>("");
  const [imagePosterior, setImagePosterior] = useState<string | File>("");
  const [imageError, setImageError] = useState("");
  const [dirtyFields, setDirtyFields] = useState<DirtyTypeProps>({
    cardProperty: { dirty: false },
  });
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { isLoading, user, error, vehicles } = useAppSelector(
    ({ user }) => user
  );
  const dispatch = useAppDispatch();

  const currentUser = user as UsuarioAPI;
  const currentVehicles = vehicles as VehicleAPI[];

  useEffect(() => {
    if (error) {
      dispatch(setError(null));
      setImageError("");
    }

    return () => {
      dispatch(setError(null));
      setImageError("");
    };
  }, [image]);

  const handleOnSelectImage = (img: string | File) => {
    setImage(img);
    if (!!error) {
      dispatch(setError(null));
    }
    setDirtyFields(() => ({ cardProperty: { dirty: true } }));
  };

  const handleOnSelectImagePosterior = (img: string | File) => {
    setImagePosterior(img);
    if (!!error) {
      dispatch(setError(null));
    }
    setDirtyFields(() => ({ cardProperty: { dirty: true } }));
  };

  const handleOnError = (e: Error) => {
    setImageError(e?.message);
    dispatch(setError(true));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLoading) return;
    const id = currentUser?.id;
    if (image !== "" || imagePosterior !== "") {
      dispatch(uploadPropertyCardThunk(image, imagePosterior))
        .then(() => handleRefreshUserData(id))
        .catch((e) => dispatch(setError(e)));
    } else {
      handleRefreshUserData(id);
    }
  };

  const handleRefreshUserData = (id: number) => {
    dispatch(getUserByIdTunhk(id?.toString() || "0")).then(() =>
      handleGoNextPage()
    );
  };

  const handleGoNextPage = () => {
    const nextPage = getNextPage(location.pathname, objectNextPage);

    navigate(nextPage as To, { replace: true });
  };

  const handleDisabled = () => {
    if (
      currentUser.urlTarjetaPropiedadRegistro &&
      currentUser.urlTarjetaPropiedadRegistroPosterior
    ) {
      return false;
    } else {
      return !!error || !image || !imagePosterior || isLoading;
    }
  };

  const handleShowImageDefault = useCallback(() => {
    const carPropertyUser = currentUser?.urlTarjetaPropiedadRegistro;
    if (location.pathname === REGITER_DRIVER_PROPERTY_CARD && carPropertyUser) {
      return carPropertyUser;
    } else if (
      currentVehicles?.[0]?.urlPropiedad &&
      currentVehicles?.length === 1
    ) {
      return (vehicles as VehicleAPI[])[0]?.urlPropiedad as string;
    }
    return BASE_URL_IMAGE + USER_CARD_PROPERTY_PAHT_DEFAULT;
  }, [location?.pathname]);

  // posterior
  const handleShowImageDefault2 = useCallback(() => {
    const carPropertyUser = currentUser?.urlTarjetaPropiedadRegistroPosterior;
    const currentVehicle = currentVehicles?.[0];
    if (location.pathname === REGITER_DRIVER_PROPERTY_CARD && carPropertyUser) {
      return carPropertyUser;
    } else if (
      currentVehicle?.urlPropiedadPosterior &&
      currentVehicles?.length === 1
    ) {
      return currentVehicle?.urlPropiedadPosterior as string;
    }
    return BASE_URL_IMAGE + USER_CARD_PROPERTY_PAHT_DEFAULT_BACK;
  }, [location?.pathname]);

  const handleBackNavigation = () => {
    const isDirtyForm = Object.values(dirtyFields).some(({ dirty }) => !!dirty);
    if (isDirtyForm) {
      setShowAlert(true);
    } else {
      navigate(REGISTER_DRIVER_STEPS, { replace: true });
    }
  };

  return (
    <LayoutFlow>
      <HeaderFlow title="Tu vehículo" customGoBack={handleBackNavigation} />
      <>
        {(error || imageError) && (
          <StandardAlert
            sx={{ marginBottom: "16px" }}
            severity="error"
            color="error"
          >
            {imageError || error}
          </StandardAlert>
        )}
      </>

      <BannerModal
        open={showAlert}
        onClose={() => setShowAlert(false)}
        title="Cambios sin guardar"
        content="Aún no has guardado los cambios realizados. ¿Deseas salir?"
        option1={{
          title: "No, quedarme",
          onClick: () => setShowAlert(false),
        }}
        option2={{
          title: "Sí, salir",
          onClick: () => navigate(-1),
        }}
        color="org"
        sx={{
          width: "322px",
          height: "268px",
        }}
        sxAccion={{
          padding: "0px",
        }}
      />

      <PrimaryText variant="body2">
        <LabelRequired str="Foto frontal de la tarjeta de propiedad" />
      </PrimaryText>

      {/* Foto frontal */}
      <Box sx={{ marginTop: "16px" }} />
      <DropZone
        imageDefault={handleShowImageDefault()}
        onError={handleOnError}
        onSelect={handleOnSelectImage}
      />
      <Box sx={{ marginTop: "16px" }} />
      {/* Foto posterior */}
      <PrimaryText variant="body2">
        <LabelRequired str="Foto posterior de la tarjeta de propiedad" />
      </PrimaryText>
      <Box sx={{ marginTop: "16px" }} />
      <DropZone
        imageDefault={handleShowImageDefault2()}
        onError={handleOnError}
        onSelect={handleOnSelectImagePosterior}
      />

      <From onSubmit={handleSubmit}>
        <ButtomLoader
          type="submit"
          disabled={handleDisabled()}
          isLoading={isLoading}
        >
          GUARDAR
        </ButtomLoader>
      </From>
    </LayoutFlow>
  );
};

export default PropertyCard;
