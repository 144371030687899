import { CustomerListTypeRequestDTO } from '@/interfaces/customerRequestInterface';
import { ISolicitudViewDetailsResponse } from '@/models/customerRequest/requestDetailsVewResponse';

export const renderVehicleSuccess = (
  value: any,
  application: ISolicitudViewDetailsResponse
) => {
  const { vehiculo } = application;
  return vehiculo.tipoVehiculo || '';
};

export const getMaxWeightVehicleRequest = (
  value: any,
  application: ISolicitudViewDetailsResponse
) => {
  const {
    vehiculo: { cargaMaxima },
  } = application;

  const title = !!cargaMaxima && ` Capacidad máxima de ${cargaMaxima} kilos   `;
  return title;
};

export const getTypeVehicleAndPlateRequest = (
  value: any,
  application: ISolicitudViewDetailsResponse
) => {
  const {
    vehiculo: { tipoVehiculo, placa },
  } = application;

  const title = !!tipoVehiculo && !!placa && `${tipoVehiculo} (${placa})`;

  return title;
};
