import { Chip, ChipTypeMap, styled } from "@mui/material";

const StyleChip = styled(Chip)(
  () => `
    border: 1px solid rgba(0, 0, 0, 0.87);
    height: 20px;
    padding: 0px 12px;
    font-size: 10px;
    color: #382E2B;
    background: transparent;
    font-weight: bold;
  `
);

type Props = ChipTypeMap["props"] & {
  show?: boolean;
};

const CustomChip = (props: Props) => {
  if (!props.show) {
    return <></>;
  }

  return <StyleChip {...props} />;
};

export default CustomChip;
