import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import LoadMultiPhotos from "@/components/uploadMultiPhotos/LoadMultiPhotos";
import {
  inputDescriptionLoad,
  inputTypeLoad,
  inputTypevehicle,
  inputWeight,
} from "@/pages/customerRequest/createRequest/Load/FormLoad/FormLoadInCity/config";
import HandleDrawer from "@/pages/customerRequest/createRequest/Load/FormLoad/components/HandleDrawer";
import { Form } from "@/pages/customerRequest/createRequest/Load/FormLoad/styles/FormLoadStyles";
import { questionTitle } from "@/pages/customerRequest/shared/consts/customerRequestConsts";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import {
  emptyErrorImage
} from "@/store/customerRequestRedux/customerRequestSlice";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FormControl, InputAdornment, Typography } from "@mui/material";
import { Container } from "../formLoadStyles";
import { useFormEditLoadRequest } from "../useFormEditLoad";

export const FormLoadEditInCity = () => {
  const {
    form,
    showModal,
    tipoCarga,
    setShowModal,
    isLoading,
    errorImage,
    photosPreLoaded,
    selectedTypevehicle,
    selectedEstimatedWeightRequest,
    dispatch,
    handleChange,
    handleDisabledButton,
    handleOnChangeImages,
    handleSubmit,
  } = useFormEditLoadRequest();



  return (
    <Container container>
      <>
        {!!errorImage && (
          <StandardAlert
            severity="error"
            color="error"
            onCloseAlert={() => dispatch(emptyErrorImage())}
          >
            {errorImage}
          </StandardAlert>
        )}
      </>

      <HandleDrawer showModal={showModal} setShowModal={setShowModal} />

      <Typography variant="body1">{questionTitle}</Typography>
      <Form onSubmit={handleSubmit}>
        <FormControl>
          <Input
            disabled
            error={false}
            {...inputTypeLoad}
            name="tipoCarga"
            label={<LabelInputRequired str={inputTypeLoad.label} />}
            value={tipoCarga}
            variant="standard"
            helperText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <ArrowDropDownIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>

        <FormControl sx={{ marginTop: "16px" }} fullWidth>
          <Input
            {...inputDescriptionLoad}
            name="descripcionCarga"
            label={<LabelInputRequired str={inputDescriptionLoad?.label} />}
            value={form.descripcionCarga}
            variant="standard"
            onChange={handleChange}
          />
        </FormControl>

        <LoadMultiPhotos
          initialPhotos={photosPreLoaded}
          onChangeFiles={handleOnChangeImages}
        />

        <FormControl>
          <Input
            error={false}
            {...inputTypevehicle}
            name="tipoVehiculo"
            label={<LabelInputRequired str={inputTypevehicle.label} />}
            value={selectedTypevehicle?.nombre}
            variant="standard"
            onChange={() => setShowModal({ type: "vehicle", open: true })}
            onClick={() => {
              setShowModal({ type: "vehicle", open: true });
            }}
            helperText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <ArrowDropDownIcon />{" "}
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl>
          <Input
            error={false}
            {...inputWeight}
            name="pesoEstimado"
            label={<LabelInputRequired str={inputWeight.label} />}
            value={selectedEstimatedWeightRequest.peso}
            variant="standard"
            onChange={() => setShowModal({ type: "weight", open: true })}
            onClick={() => setShowModal({ type: "weight", open: true })}
            helperText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <ArrowDropDownIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <ButtomLoader
          type="submit"
          sx={{ marginTop: 0 }}
          buttomProps={{ disabled: handleDisabledButton() || isLoading }}
          isLoading={isLoading}
        >
          SIGUIENTE
        </ButtomLoader>
      </Form>
    </Container>
  );
};
