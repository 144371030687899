import { BASE_URL_IMAGE } from "@/config/config";
import { EMPTY_LIST_DATA_IMAGE } from "@/consts/defaultConsts";
import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { EMPTY_LIST_WHITOUT_REQUEST } from "../../consts/defaultConsts";

const Container = styled(Stack)(() => ({
  width: "100%",
  maxWidth: "450px",
}));

type Props = {
  margin?: string;
  title?: string;
  optionTwo?: boolean;
  render?: React.ReactNode;
};

const EmptyListData = ({ margin = "0 auto", title = "No tienes avisos", optionTwo = false, render }: Props) => {
  return (
    <Container sx={{ margin }}>
      {
        optionTwo ? (
          <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}>
            {render}
          </Box>
        )
          : (
            <img src={`${BASE_URL_IMAGE}${EMPTY_LIST_WHITOUT_REQUEST}`} alt={title} />
          )
      }
      <Typography variant="subtitle1" textAlign="center">
        {title}
      </Typography>
    </Container>
  );
};

export default EmptyListData;
