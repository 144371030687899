import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import HeaderModal from "@/components/modals/headerModal/HeaderModal";
import {
  ContainerButtom,
  ContainerModal,
  CustomDrawer,
} from "@/components/modals/stylesContainerFlow";
import useDebounce from "@/hooks/useDebounse";
import { useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { FormControl, Stack, styled } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import AutoCompleteFilterModal from "../AutoCompleteFilterModal";
import CheckList from "../CheckList";
import ListChip from "../ListChip";
import { getListPrevSelected } from "../../utils/helpers";
import { ModalFilterChildrenProps } from "../../interface/filterInterface";

interface Props
  extends Omit<
    ModalFilterChildrenProps,
    "list" | "typeNoticeSelected" | "betweenDate"
  > {}

const FormControlInput = styled(FormControl)(
  () => `
   padding: 16px 16px 0 16px;
  `
);

const ModalFilterCity = (props: Props) => {
  const {
    open,
    onClose,
    onSeleted,
    onInputChange,
    onSubmit,
    isMultiSelect,
    metaData,
    prevSelected,
    modalActiveWith,
    nameTwo,
  } = props;
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected]: any | any[] = useState(null);
  const [inputDirty, setInputDirty] = useState(false);

  const {
    filterNotice: { listLocation, isLoadingFilter, typeNoticeSelecte },
  } = useAppSelector((state) => state);

  const inputSearchRef = useRef<HTMLInputElement>(null);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const inputValueDebounse = useDebounce(inputValue, 500);

  useEffect(() => {
    if (typeNoticeSelecte?.multiselect) {
      if (modalActiveWith === "city") {
        setSelected(null);
      } else {
        setSelected([]);
      }
    } else {
      setSelected(null);
    }
  }, [typeNoticeSelecte?.nombre]);

  useEffect(() => {
    setInputDirty(false);
    if (!open) {
      setInputValue("");
      setSelected(null);
    } else {
      const initialSelected = handleGetInitialSelected();
      setSelected(initialSelected);

      timer.current = setTimeout(() => {
        inputSearchRef.current?.focus();
      }, 100);
    }
    return () => {
      clearTimeout(timer.current as NodeJS.Timeout);
    };
  }, [open]);

  useEffect(() => {
    onInputChange && onInputChange(inputValueDebounse);
  }, [inputValueDebounse]);

  const handleGetInitialSelected = () => {
    if (modalActiveWith === "city") return null;
    if (!prevSelected || !typeNoticeSelecte?.multiselect) {
      return [];
    }

    const selected = getListPrevSelected(listLocation, prevSelected);

    return selected;
  };

  const handleChange = ({ target }: EventType["change"]) => {
    const { value } = target;
    const cleanValue = value.replace(/[^A-Za-z ÁÉÍÓÚáéíóúÑñ,]/g, "");
    setInputValue(cleanValue);
    setInputDirty(true);
    if (selected && !typeNoticeSelecte?.multiselect) {
      setSelected(null);
    }
  };

  const handleSelected = (value: any) => {
    setSelected(value);
    onSeleted && onSeleted(value);
    const select = value?.nombre2 || value?.nombre;
    if (select) {
      setInputValue(select);
    }
  };

  const handleDeleteItemInList = (list: any[]) => {
    setSelected(list);
  };

  const handleToggleSelected = (newValue: any) => {
    const isChecked = handleValidCheck(newValue.nombre);

    if (isChecked) {
      let newSelects = [newValue];
      if (Array.isArray(selected)) {
        newSelects = selected?.filter(
          (select) => select.nombre !== newValue.nombre
        );
      }

      setSelected(newSelects);
      return;
    }
    setSelected((prev: any) => {
      if (!prev) return [newValue];
      return [...prev, newValue];
    });
  };

  const handleValidCheck = (currentValue: string) => {
    if (!Array.isArray(selected)) return;

    const isChecked = selected?.some(({ nombre }) => nombre === currentValue);

    return isChecked;
  };

  const handleOnsubmit = () => {
    onSubmit(selected);
  };

  const handleShowAutoCompleteList = () => {
    if (isMultiSelect) {
      return true;
    }

    return inputValue.length > 2 && !selected;
  };

  return (
    <CustomDrawer open={open} onClose={() => onClose(false)} anchor="right">
      <>
        <HeaderModal title={metaData.title} onClick={() => onClose(false)} />
        <ContainerModal sx={{ position: "relative" }}>
          <FormControlInput>
            <Input
              name={metaData.inputName}
              label={metaData.inputLabel}
              placeholder={metaData.inputPlaceholder}
              onChange={handleChange}
              value={inputValue}
              inputRef={inputSearchRef}
              autoComplete="off"
            />
            {isMultiSelect ? (
              <>
                <Stack
                  direction="row"
                  spacing={1}
                  marginTop={"8px"}
                  flexWrap="wrap"
                >
                  <ListChip
                    list={selected}
                    propertyName="nombre"
                    onDelete={handleDeleteItemInList}
                  />
                </Stack>
                <CheckList
                  list={listLocation}
                  onSeleted={handleToggleSelected}
                  selects={selected}
                />
              </>
            ) : (
              <AutoCompleteFilterModal
                list={listLocation}
                onClick={handleSelected}
                isLoading={isLoadingFilter}
                show={handleShowAutoCompleteList()}
                inputDirty={inputDirty}
                nameTwo={nameTwo}
              />
            )}
          </FormControlInput>
        </ContainerModal>
        <ContainerButtom>
          <ButtomLoader disabled={!selected} onClick={handleOnsubmit}>
            {metaData.buttonText}
          </ButtomLoader>
        </ContainerButtom>
      </>
    </CustomDrawer>
  );
};

export default ModalFilterCity;
