import { Stack, styled, Typography } from "@mui/material";
import React, { FC } from "react";

const Title = styled(Typography)(
  ({ theme }) => `
      color: ${theme.palette.text.secondary};
      font-weight: bold;
      margin-left: 8px;
      
    `
);

const ConatinerDetails = styled(Stack)(
  () => `
    justify-content: space-between;
    display: flex;
    align-items: flex-start;
    flex-direction: row;

  `
);

export type DetailsTextProps = {
  show: boolean;
  title: string;
  origen: string;
  originDescription: string;
  icon: string;
  sx: any
};

const GenericComponent: FC<DetailsTextProps> = ({
  show,
  title,
  origen,
  icon,
  originDescription,
  sx
}) => {
  if (!show) return <></>;

  return (
    <ConatinerDetails sx={sx}>
      {!!title &&
      
      <Stack flexDirection="row">
        <i className={icon}></i>
        <Title variant="label1">
          {title}
          {": "}
        </Title>
      </Stack>}
      
      <Stack flex={1} marginLeft="4px">
        <Typography variant="body1">{origen}</Typography>
        {originDescription && (
          <Typography variant="body1">({originDescription})</Typography>
        )}
      </Stack>
    </ConatinerDetails>
  );
};

export default GenericComponent;
