import { ResponseNoticeOffer } from '@/models/offert/offertModels';
import { ImagesThumbnailV2 } from '@/pages/flowNoticeNegotation/components/ImagesThumbnailV2';
import DetailsTextInline from '@/pages/offerts/offertConfirmation/components/DetailsTextInline';
import { Typography } from '@mui/material';
import {
  getPriceTitle,
  getPriceType,
  getTotalPrice,
  getTypeAcount,
  getValueTypeAcount,
} from '../../../../../shared/utils/callbackMultiDestination';
import DetailsText from '@/pages/flowNoticeNegotation/components/DetailsText';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { districtsDeliveryText } from '@/consts/noticeConsts';

interface ValuesToReplace {
  search: string;
  value: keyof ResponseNoticeOffer;
  defaultResult: string;
}

export interface ValidFormat {
  template: string;
  valuesToReplace: ValuesToReplace[];
}

interface ConfigDataBodyOffertValid {
  property: keyof ResponseNoticeOffer;
  componentProp: string;
  defaultResult: boolean | string | number | Array<any> | Object;
  format?: ValidFormat;
  callback?: (
    tipoAvisoNegociacion: number,
    value: any,
    offert: ResponseNoticeOffer
  ) => any;
}

export interface ConfigDataBodyMultiDestinationOffer {
  title?: string;
  Component: any;
  props: any;
  valid?: ConfigDataBodyOffertValid[];
  children?: JSX.Element | JSX.Element[] | string;
}

export const configDataMultiDestination: ConfigDataBodyMultiDestinationOffer[] =
  [
    {
      Component: Typography,
      props: {
        variant: 'subtitle1medium',
        children: 'Detalles del servicio',
        sx: {
          marginBottom: '10px',
          display: 'block',
        },
      },
    },
    {
      Component: DetailsText,
      props: {
        icon: CalendarMonthOutlinedIcon,
        title: 'Fecha del servicio',
        iconProps: { sx: { color: '#757575' } },
        originDescription: '',
      },
      valid: [
        {
          property: 'fecha',
          componentProp: 'show',
          defaultResult: false,
        },
        {
          property: 'fecha',
          componentProp: 'origen',
          defaultResult: '',
        },
      ],
    },
    {
      Component: DetailsTextInline,
      props: {
        icon: 'icon-location-light',
        title: districtsDeliveryText,
      },
      valid: [
        {
          property: 'destinoTexto',
          componentProp: 'show',
          defaultResult: false,
        },
        {
          property: 'destinoTexto',
          componentProp: 'origen',
          defaultResult: '',
        },
      ],
    },
    {
      Component: Typography,
      props: {
        title: 'Descripción de la carga',
        variant: 'subtitle1medium',
        sx: {
          maxWidth: '500px',
          wordWrap: 'break-word',
          marginTop: '16px',
          display: 'block',
        },
      },
    },
    {
      Component: ImagesThumbnailV2,
      props: {},
      valid: [
        {
          property: 'fotos',
          componentProp: 'images',
          defaultResult: [],
        },
      ],
    },
    {
      Component: Typography,
      props: {
        variant: 'body1',
        sx: {
          marginTop: '30px',
          display: 'block',
        },
      },
      valid: [
        {
          property: 'descripcionCarga',
          componentProp: 'children',
          defaultResult: '',
        },
      ],
    },
    {
      Component: DetailsText,
      props: {
        show: true,
        titleSx: {
          fontSize: '22px',
        },
      },
      valid: [
        {
          property: 'id',
          componentProp: 'title',
          defaultResult: '',
          callback: getPriceTitle,
        },
        {
          property: 'id',
          componentProp: 'origen',
          defaultResult: '',
          callback: getPriceType,
        },
      ],
    },
    {
      Component: DetailsText,
      props: {
        show: true,
      },
      valid: [
        {
          property: 'id',
          componentProp: 'title',
          defaultResult: '',
          callback: getTypeAcount,
        },
        {
          property: 'id',
          componentProp: 'origen',
          defaultResult: '',
          callback: getValueTypeAcount,
        },
      ],
    },
    {
      Component: DetailsText,
      props: {
        show: true,
        title: 'Precio total: ',
      },
      valid: [
        {
          property: 'preciocalculado',
          componentProp: 'origen',
          defaultResult: '',
          callback: getTotalPrice,
        },
      ],
    },
  ];
