import { NoticeDetailAuctionModel } from "@/models/notice/noticeV2NegotationModels";
import { currencyFormat } from "@/utils/helpers";
import { Box, Typography } from "@mui/material";

const AuctionOfferPrice = ({ data }: { data: NoticeDetailAuctionModel }) => {
  const { flgIsOwner } = data;
  return flgIsOwner ? (
    <>
      <Box>
        <Typography
          sx={{ fontWeight: 700, fontSize: "22px", textAlign: "revert", mt: 2 }}
        >
          Oferta rechazada: {currencyFormat(data.precio)}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontWeight: 700, fontSize: "18px" }}>
            Peso del remate: {data.aviso.pesomaximo} kilos
          </Typography>
        </Box>
      </Box>
    </>
  ) : (
    <></>
  );
};

export default AuctionOfferPrice;
