import { actionsToNoticeNegotation } from '@/consts/actions';
import { ID_MOVING_FLOW, ID_MULTI_DESTINY } from '@/consts/defaultConsts';
import { descriptionLoad } from '@/consts/noticeConsts';
import { Negotiation, NoticeNegotiation } from '@/models/offert/offertModels';
import { DataResponseEnglishTypeTwo } from '@/store/noticeV2ClientAcceptCounterOffer/interfaces/dataResponseEnglishInterfaces';
import { currencyFormat, stringHoursToFormatLT } from '@/utils/helpers';

const ValidDriveRender = (noticeResponse: DataResponseEnglishTypeTwo) => {
  const { negotiationTypeId } = noticeResponse;

  const isMoving = negotiationTypeId === ID_MOVING_FLOW;

  return isMoving;
};

export const getFormatPrice = (
  tipoAvisoNegociacion: number,
  price = 0,
  notice: DataResponseEnglishTypeTwo
) => {
  const isCounterOffert = tipoAvisoNegociacion === 2;
  const formatPrice = currencyFormat(price);
  const isMoving = notice.negotiationTypeId === 5;
  const isMovingCounterOffert = isMoving && tipoAvisoNegociacion === 2;

  if (isMovingCounterOffert) {
    return '';
  } else if (isMoving) {
    let textToReturn = `Cotizado en ${formatPrice}`;
    if (ValidDriveRender(notice)) {
      textToReturn = `${formatPrice}`;
    }
    return textToReturn;
  } else if (isCounterOffert) {
    return `Contraoferta por ${formatPrice}`;
  } else if (notice?.negotiationTypeId === ID_MULTI_DESTINY) {
    const { hours, points } = notice;
    const finalPrice = !!hours
      ? currencyFormat(hours)
      : currencyFormat(points as number);

    return finalPrice;
  }

  return formatPrice;
};

export const getFormatPriceMoving = (
  tipoAvisoNegociacion: number,
  price = 0,
  offert: Negotiation
) => {
  const isCounterOffert = tipoAvisoNegociacion === 2;
  const formatPrice = currencyFormat(price);
  const isMoving = offert.avisoNegociacionAviso?.tipoaviso?.id === 5;
  const isMovingCounterOffert = isMoving && tipoAvisoNegociacion === 2;

  if (isMovingCounterOffert) {
    return `Cotizado en ${formatPrice}`;
  }

  if (isCounterOffert) {
    return `Contraoferta por ${formatPrice}`;
  }

  return formatPrice;
};

export const getFormatSendDate = (
  tipoAvisoNegociacion: number,
  _: any,
  offert: Negotiation
) => {
  const isMoving = offert.avisoNegociacionAviso?.tipoaviso?.id === 5;
  const isMovingCounterOffert = isMoving && tipoAvisoNegociacion === 2;
  const template = `Enviada el :date a las :dateTime`;
  let { fechaRegistro, horaRegistro } = offert;
  horaRegistro = stringHoursToFormatLT(horaRegistro);

  const result = template
    .replace(':date', fechaRegistro)
    .replace(':dateTime', horaRegistro);

  return isMovingCounterOffert ? '' : result;
};

export const getFormatSendDateMoving = (
  tipoAvisoNegociacion: number,
  _: any,
  offert: Negotiation
) => {
  const isMoving = offert.avisoNegociacionAviso?.tipoaviso?.id === 5;
  const isMovingCounterOffert = isMoving && tipoAvisoNegociacion === 2;
  let { fechaRegistro, horaRegistro } = offert;
  const template = `Enviada el :date a las :dateTime`;
  const result = template
    .replace(':date', fechaRegistro)
    .replace(':dateTime', horaRegistro);

  return isMovingCounterOffert ? result : '';
};

export const getTitleByPrice = (
  tipoAvisoNegociacion: number,
  _: any,
  notice: DataResponseEnglishTypeTwo
) => {
  // puntos o horas
  const isHour = !!notice?.hours && notice?.hours > 0;
  const isPoint = !!notice?.points && notice?.points > 0;
  const isMoving = notice.negotiationTypeId === ID_MOVING_FLOW;
  const isMovingOffert = isMoving && tipoAvisoNegociacion === 1;
  const isMovingCounterOffert = isMoving && tipoAvisoNegociacion === 2;

  if (isHour) {
    return 'Precio por hora';
  } else if (isPoint) {
    return 'Precio por punto';
  } else if (isMovingOffert) {
    return 'Precio por viaje';
  } else if (isMovingCounterOffert) {
    let text = 'Por viaje';

    return text; // Que desaparesca el titulo
  } else {
    return 'Precio de punto a punto';
  }
};

export const showOrHiden = (
  tipoAvisoNegociacion: number,
  value: any,
  offert: Negotiation
) => {
  const isMoving = offert.avisoNegociacionAviso?.tipoaviso?.id === 5;
  return isMoving ? '' : descriptionLoad;
};

export const getFormatMetro = (
  tipoAvisoNegociacion: number,
  _: any,
  notice: DataResponseEnglishTypeTwo
) => {
  return `${notice?.squareMeters} m².`;
};

export const validShowTitle = (
  tipoAvisoNegociacion: number,
  _: any,
  offert: Negotiation
) => {
  const { origenTexto, destinoTexto } = offert;

  if (!origenTexto && !destinoTexto) {
    return { display: 'none' };
  }
};

const textElevator: Record<number, string> = {
  1: 'ascensor disponible',
  2: 'sin ascensor',
  3: 'ascensor disponible para algunas cosas',
};

export const getOriginInMoving = (
  _: number,
  value: any,
  notice: DataResponseEnglishTypeTwo
) => {
  const data = notice as DataResponseEnglishTypeTwo;
  const inmuebleOrigen = data?.originProperty as string;
  const piso = data?.floor as number;
  const flgAscensor = data?.elevatorFlag;
  const referenciaOrigen = data?.originReference as string;
  const originText = data?.originText as string;

  const inmueble = inmuebleOrigen;
  const elevator = textElevator[flgAscensor as number];
  let template = `${inmueble} en piso ${piso} (${elevator}) en ${originText} (${referenciaOrigen})`;

  return elevator
    ? template
    : `${inmueble} en ${originText} (${referenciaOrigen})`;
};

export const getDestinyInMoving = (
  _: number,
  value: any,
  notice: DataResponseEnglishTypeTwo
) => {
  const {
    destinyText,
    destinyReference,
    destinyProperty = '',
    destinyFloor,
    elevatorFlag,
  } = notice;

  const elevator = textElevator[elevatorFlag as number];
  let template = `${destinyProperty} en piso ${
    destinyFloor as number
  } (${elevator}) en ${destinyText} (${destinyReference})`;

  return elevator
    ? template
    : `${destinyProperty} en ${destinyText} (${destinyReference as string})`;
};

export const getTitleAvisoOffertIsOwnerCounterOffert =
  (type?: number) => (_: number, value: any, offert: Negotiation) => {
    const { flgIsOwner, avisoNegociacionAviso } = offert;
    const isMoving = (avisoNegociacionAviso?.tipoaviso?.id as number) === 5;
    const textToRenderOne = isMoving ? 'Tu cotización' : 'Tu contraoferta';
    const textToRenderTwo = isMoving
      ? 'Cotización de transportista'
      : 'Contraoferta de transportista';
    if (flgIsOwner === undefined) return '';
    if (type === 1) return flgIsOwner === 1 ? textToRenderOne : textToRenderTwo;
    return flgIsOwner === 1 ? 'Contraoferta del cliente' : 'Tu contraoferta';
  };
