import { BASE_URL_IMAGE } from '@/config/config';
import {
  ADVERTISERS_HERO_LG,
  ADVERTISERS_HERO_MD,
} from '@/consts/defaultConsts';
import { ResponsiveImage } from '@/interfaces/globalInterface';
import {
  Box,
  Button,
  Grid,
  GridProps,
  LinkProps,
  Stack,
  Typography,
  experimental_sx as sx,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export const TitleFormAds = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.h1.fontFamily,
  fontSize: '22px',
  lineHeight: '32px',
  fontWeight: '400',
  color: theme.palette.secondary.main,
  '@media only screen and (min-width: 899px)': {
    fontSize: '34px',
    lineHeight: '40px',
  },
}));

export const SubTitleFormAds = styled(Typography)(() => ({
  letterSpacing: '0px',
  color: 'rgba(0, 0, 0, 0.87)',
}));

export const ButtonAccionModal = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.button.fontFamily,
  fontSize: theme.typography.button.fontSize,
  width: '360px',
  height: '36px',
  textDecoration: 'none',
  borderRadius: '36px',
  padding: '0px 16px',
  '@media only screen and (max-width: 899px)': {
    alignSelf: 'center',
    width: '246px',
  },
  backgroundColor: '#FF6700',
  color: 'white',
}));

export const LinkButton = styled(Link)<LinkProps>(() => ({
  textDecoration: 'none',
  color: 'inherit',
  alignItems: 'center',
}));

export const SubHeading = styled(Grid)<GridProps>(
  sx({
    display: 'flex',

    flexDirection: { xs: 'column', lg: 'row' },
    alignItems: { xs: 'center', sm: 'center', md: 'center', lg: 'flex-start' },
    gap: '24px',
  })
);

export const SubHeadingAds = styled(Grid)<GridProps>(
  sx({
    display: 'flex',
    gap: '24px',
    alignItems: {
      xs: 'center',
      sm: 'center',
      md: 'center',
      lg: 'center',
    },
    flexDirection: {
      lg: 'row',
      xs: 'column-reverse',
    },
  })
);

export const ListReasons = styled(Stack)(() => ({}));

export const BoxContainer = styled(Box)(() => ({
  width: '80%',
  mb: 2,
}));

export const Reasons = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '10px',
  gap: '10px',
  '@media only screen and (max-width: 899px)': { alignItems: 'center' },
}));

export const ReasonDetail = styled(Stack)(() => ({
  gap: '4px',
}));

export const ContainerTypeAds = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '32px',
}));

export const ContainerSection = styled(Box)(
  ({ mobile }: { mobile: boolean }) => ({
    backgroundColor: mobile ? '#ffff' : '#fafafa',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingY: '32px',
    alignItems: 'center',
  })
);

export const TitleSection = styled(Typography)(({ theme }) => ({
  fontSize: '22px',
  fontWeight: '700',
}));

export const SubtitleSection = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
}));

export const responsiveImagesAds: ResponsiveImage[] = [
  {
    cssProperties: [
      {
        property: 'backgroundImage',
        value: `url(${BASE_URL_IMAGE}${ADVERTISERS_HERO_MD})`,
      },
      { property: 'minHeight', value: '240px' },
      { property: 'maxHeight', value: '260px' },
    ],
    mediaMinWidth: '961px',
  },
  {
    cssProperties: [
      {
        property: 'backgroundImage',
        value: `url(${BASE_URL_IMAGE}${ADVERTISERS_HERO_LG})`,
      },
      { property: 'minHeight', value: '320px' },
      { property: 'maxHeight', value: '340px' },
    ],
    mediaMinWidth: '1280px',
  },
];
