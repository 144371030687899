import { amountNegotationAdapter } from "@/adapters/store/customerRequestAdapter";
import StandardAlert from "@/components/alerts/StandardAlert";
import BasicModal from "@/components/dialog/Notiffication";
import ImgCarousel from "@/components/viewService/imgCarousel";
import { NOTICE_NOT_FOUND } from "@/consts/defaultConsts";
import { REQUESTS_LANDING_PATH } from "@/consts/typeServicesPaths";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import useResize from "@/hooks/useResize";
import {
  IRequestDetailsViewResponseAPI,
  ISolicitudViewDetailsResponse,
} from "@/models/customerRequest/requestDetailsVewResponse";
import { UsuarioAPI } from "@/models/userAuth/user";
import { MY_APPLICATIONS_CUSTOMER_REQUEST_PATH } from "@/pages/customerRequest/shared/consts/routes";
import SkeletonViewService from "@/pages/loading/SkeletonViewService";
import NotFound from "@/pages/notFound/NotFound";
import ArrowBack from "@/pages/viewService/city/components/ArrowBack";
import {
  getAmountNegotationThunk,
  getListCustomerRequestPrivThunk,
  getListCustomerRequestThunk,
} from "@/store/customerRequestRedux/customerRequestThunk";
import { setSelectNegotationTypeRequest } from "@/store/noticeNegotationRequestRedux/noticeNegRequestSlice";
import { resetOffert } from "@/store/offertRedux/offert.slice";
import { setReturnLogin } from "@/store/userRedux/userSlice";
import { Container, Grid, Stack, styled } from "@mui/material";
import { FC, useEffect, useLayoutEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ButtonService from "./components/ButtonService";
import { usePublicButtons } from "./hooks/usePublicButtons";
import {
  IDefatultButtonListRules,
  applicationButtonCustomRules,
} from "./rulesFlow";
import { saveDataReturnPathThunk } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";

const ContainerGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
const ContainerStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

type ContainerProps = {
  children: JSX.Element | JSX.Element[];
  typeContainer: "grid" | "stack";
};

const ContainerLastSection: FC<ContainerProps> = ({
  children,
  typeContainer,
}) => {
  return typeContainer === "grid" ? (
    <ContainerGrid item md={4} sx={{ pt: { xs: "16px", md: "32px" } }}>
      {children}
    </ContainerGrid>
  ) : (
    <ContainerStack sx={{ pt: { xs: 0, md: 4 } }}>{children}</ContainerStack>
  );
};
interface LocationState {
  hasBackNavigate?: boolean;
}

interface ButtonsDetails extends IDefatultButtonListRules {
  idAction: number;
}

export const Application = () => {
  const publicButtons = usePublicButtons();
  const {
    customerRequest: { isLoading, requestDetailsView },
    user: { user, returnLogin },
    noticeNegotationRequest: { selectedNegotationTypeRequest },
  } = useAppSelector((state) => state);
  const { id = "" } = useParams();

  const [, isMobil] = useResize();

  const [hasBackNavigate, setHasBackNavigate] = useState<boolean>(false);
  const [listButtons, setListButtons] = useState<ButtonsDetails[]>(
    applicationButtonCustomRules.buttonsPublicList as ButtonsDetails[]
  );
  const [errormsg, setErrormsg] = useState<string | null>(null);
  const [errorNotFound, setErrorNotFound] = useState(false);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const profilePath = pathname.startsWith(
    MY_APPLICATIONS_CUSTOMER_REQUEST_PATH
  );
  const { notiffication, removeNotiffication } = useNotiffication();

  useLayoutEffect(() => {
    dispatch(resetOffert());
    if (!!notiffication) {
      removeNotiffication(true);
    }
  }, []);

  useEffect(() => {
    if ((location.state as LocationState)?.hasBackNavigate) {
      setHasBackNavigate(true);
    }

    if (returnLogin === false && selectedNegotationTypeRequest?.id > 0) {
      dispatch(
        setSelectNegotationTypeRequest({
          id: 0,
          nombre: "",
          descripcion: "",
        })
      );
    }

    if (!profilePath && !(user as UsuarioAPI)?.id) {
      dispatch(
        saveDataReturnPathThunk({
          path: location.pathname,
          state: location.state,
        })
      );
    }

    handleGetListButtons();
    handleGetNoticeById();
    return () => {
      setHasBackNavigate(false);
    };
  }, [location?.pathname]);

  useEffect(() => {
    if (!!requestDetailsView?.id) {
      const body = amountNegotationAdapter(
        requestDetailsView as ISolicitudViewDetailsResponse
      );
      dispatch(getAmountNegotationThunk(body));
    }
  }, [requestDetailsView]);

  const handleGetListButtons = () => {
    if (pathname?.startsWith(MY_APPLICATIONS_CUSTOMER_REQUEST_PATH)) {
      setListButtons(
        applicationButtonCustomRules.buttonsPrivateList as ButtonsDetails[]
      );
      return;
    }
    setListButtons(
      applicationButtonCustomRules.buttonsPublicList as ButtonsDetails[]
    );
  };

  const is01Code = (res: IRequestDetailsViewResponseAPI) => {
    const {
      metadata: [code],
    } = res;
    if (code?.codigo === "01") {
      setErrormsg(code?.dato);
    }
  };

  const handleGetNoticeById = async () => {
    if (pathname?.startsWith(MY_APPLICATIONS_CUSTOMER_REQUEST_PATH)) {
      dispatch(getListCustomerRequestPrivThunk(id as string))
        .then((res) => {
          is01Code(res as IRequestDetailsViewResponseAPI);
        })
        .catch((err) => {
          navigate(
            pathname.replace(
              MY_APPLICATIONS_CUSTOMER_REQUEST_PATH,
              REQUESTS_LANDING_PATH
            )
          );
        });

      return;
    }
    dispatch(getListCustomerRequestThunk(id as string))
      .then((res) => {
        const {
          metadata: [code],
          solicitudData: { solicitud },
        } = res;
        is01Code(res as IRequestDetailsViewResponseAPI);

        if (
          returnLogin &&
          code.codigo !== "01" &&
          (user as UsuarioAPI)?.id !== solicitud?.cliente?.id &&
          selectedNegotationTypeRequest?.id > 0
        ) {
          const handlefunction =
            publicButtons[selectedNegotationTypeRequest?.id];
          handlefunction();
        }

        dispatch(setReturnLogin(false));
      })
      .catch(() => setErrorNotFound(true));
  };

  const handlePaddingTop = () => {
    if (!isMobil && !hasBackNavigate) {
      return "32px";
    }
    return "";
  };
  const handlePreparaLinkNotFound = () => {
    return REQUESTS_LANDING_PATH;
  };

  const handleIsYourNotice = () => {
    const isClient =
      (requestDetailsView?.cliente?.id as number) === (user as UsuarioAPI)?.id;

    if ((!profilePath && isClient) || errormsg) {
      return true;
    }
    return false;
  };

  const handleNavigationBack = () => {
    navigate(-1);
  };

  if (errorNotFound) {
    return (
      <NotFound
        title="¡Lo sentimos!"
        description="La solicitud no está disponible."
        buttonText="VER SOLICITUDES"
        buttonLink={handlePreparaLinkNotFound()}
        image={NOTICE_NOT_FOUND}
      />
    );
  }

  if (isLoading) {
    return <SkeletonViewService />;
  }

  const handlePhotosCarousel = () => {
    if (requestDetailsView?.fotos) {
      return requestDetailsView?.fotos.map((item, i) => {
        return {
          id: i,
          direccion: item,
          idvehiculo: 0,
          tipo: "",
        };
      });
    }
    return [];
  };

  return (
    <>
      <Container
        disableGutters
        maxWidth="xl"
        sx={{
          marginBottom: "16px",
          padding: { xs: "0px 0px", md: "0px 24px", lg: "0px 32px" },
          paddingTop: handlePaddingTop(),
        }}
      >
        <ArrowBack show={hasBackNavigate} onClick={handleNavigationBack} />
        {handleIsYourNotice() && (
          <StandardAlert
            severity="warning"
            color="warning"
            sx={{ marginBottom: "16px", marginTop: "16px" }}
          >
            {errormsg ||
              "No es posible ofertar a este aviso porque te pertenece."}
          </StandardAlert>
        )}
        <ImgCarousel listCarrousel={handlePhotosCarousel()} />
        <Grid
          container
          direction={{ xs: "column", md: "row" }}
          justifyContent={{ xs: "center", md: "space-between" }}
          alignItems={{ xs: "center", md: "flex-start" }}
        >
          <Grid item md={7.5}>
            <Stack
              sx={{
                maxWidth: { xs: 360, md: 400 },
                padding: { xs: "32px 16px", md: "32px 16px 32px 0px" },
                wordWrap: "break-word",
              }}
            >
              <Outlet />
              <ContainerLastSection typeContainer="stack">
                <ButtonService listButtons={listButtons} />
              </ContainerLastSection>
            </Stack>
          </Grid>
          <ContainerLastSection typeContainer="grid">
            <ButtonService listButtons={listButtons} />
          </ContainerLastSection>
        </Grid>
      </Container>
      <BasicModal />
    </>
  );
};
