import {
  maxAmauntLength,
  optionFive,
  optionOne,
  optionTwo,
  textHoursError,
  textPointsError,
} from '@/consts/noticeConsts';
import FormPriceBetweenCities from './FormPriceBetweenCities/Index';
import FormPriceInCity from './FormPriceInCity/Index';
import FormPriceMultidestiny from './FormPriceMultidestiny/Index';
import { Dispatch, SetStateAction } from 'react';

export const objectRenderComponent: Record<number, () => JSX.Element> = {
  1: FormPriceInCity,
  2: FormPriceMultidestiny,
  3: FormPriceBetweenCities,
};

export const handleLabelInputs = (flaghoraopunto: number) => {
  let value = 'hora';
  if (flaghoraopunto === optionTwo) value = 'punto';

  let object = {
    label1: `Ofrece tu precio por ${value} (S/)`,
    label2:
      flaghoraopunto === optionTwo ? 'Puntos requeridos' : 'Horas requeridas',
    placeholder1: `Tu oferta por ${value} de entrega/recojo`,
    placeholder2: `Cantidad de ${value}s de entrega/recojo`,
  };

  return object;
};

export const handleErrorPrice = (
  setError: Dispatch<SetStateAction<string>>,
  secondInputValue: string
) => {
  if (Number(secondInputValue) < optionFive) {
    setError('El precio es muy bajo para este servicio.');
    return true;
  }

  return false;
};

export const handleErrorSubmit = (
  flaghoraopunto: number,
  setError: Dispatch<SetStateAction<string>>,
  firstInputValue: string
) => {
  const newFirsInputValue = Number(firstInputValue);
  if (flaghoraopunto === optionTwo) {
    if (newFirsInputValue < optionTwo || newFirsInputValue > maxAmauntLength) {
      setError(textPointsError);

      return true;
    }
  } else if (flaghoraopunto === optionOne) {
    if (Number(firstInputValue) < optionOne || Number(firstInputValue) > 24) {
      setError(textHoursError);

      return true;
    }
  }

  return false;
};
