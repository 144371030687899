import { ID_MOVING_FLOW, ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import { MovingCounterOfferPrice } from "../../negClientReceiveCounterOffer/counterOfferResponse/components/MovingCounterOfferPrice";
import { PriceDefaultFormat } from "../../negClientReceiveCounterOffer/counterOfferResponse/components/PriceDefaultFormat";
import { MultidestinyCoCounterOfferPrice } from "./MultidestinyCoCounterOfferPrice";

export const GlobalCoCounterOfferPrice = ({
  data,
}: {
  data: SolicitudNegociacion;
}) => {
  if ((data?.solicitud.tipoSolicitud.id as number) === ID_MOVING_FLOW)
    return <MovingCounterOfferPrice data={data} />;

  if ((data?.solicitud.tipoSolicitud.id as number) === ID_MULTI_DESTINY)
    return <MultidestinyCoCounterOfferPrice data={data} />;

  return <PriceDefaultFormat data={data} />;
};
