import StandardAlert from "@/components/alerts/StandardAlert";
import DropZone from "@/components/DrowZone/DropZone";
import LabelRequired from "@/components/utils/LabelRequired";
import { Stack, styled, Typography } from "@mui/material";
import { FC } from "react";

const Title = styled(Typography)(
  () => `
    margin: 16px 0;
    display: block;
  `
);

type Props = {
  imageDefault: string;
  onError: (e: Error) => void;
  onSelect: (image: string | File) => void;
  title: string;
  textError: string;
  showTextError: boolean;
};

const SectionImagens: FC<Props> = ({ textError, showTextError, imageDefault, onError, onSelect, title }) => {
  return (
    <Stack>
      <Title variant="subtitle1medium">
        <LabelRequired str={title} />
      </Title>
      <>
        {showTextError && (
          <StandardAlert severity="error" color="error">
            {textError}
          </StandardAlert>
        )}
      </>
      <DropZone imageDefault={imageDefault} onError={onError} onSelect={onSelect} />
    </Stack>
  );
};

export default SectionImagens;
