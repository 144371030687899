import HeaderDetail from "@/pages/noticeV2Negotation/components/HeaderDetail";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import GlobalPriceFinishedReject from "../GobalPriceFinishedReject";
import useRejectLayoutHook from "./hook";
import ButtomLoader from "@/pages/noticeV2/components/buttonLoader/ButtomLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { LocationState } from "@/interfaces/globalInterface";
import ArrowBack from "@/pages/viewService/city/components/ArrowBack";

interface Props {
  children: React.ReactNode;
  notice: NoticeV2NegType;
}

const RejectLayout = ({ children, notice }: Props) => {
  const [hasBackNavigate, setHasBackNavigate] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { matches, handleHeaderProps, handleNavigation, handleNevigateOwner } =
    useRejectLayoutHook();

  const { flgIsOwner } = notice;

  useEffect(() => {
    if ((location.state as LocationState)?.hasBackNavigate) {
      setHasBackNavigate(true);
    }
  }, [location.state]);

  const handleNavigationBack = () => {
    navigate(-1);
  };

  return (
    <Container
      maxWidth={matches ? "xl" : "xs"}
      sx={{
        marginBottom: matches ? 0 : 4,
        marginTop: 2,
      }}
    >
      <ArrowBack show={hasBackNavigate} onClick={handleNavigationBack} />
      <HeaderDetail
        icon={handleHeaderProps().icon}
        title={handleHeaderProps().title}
        iconColor="black"
      />

      <Grid
        container
        spacing={0}
        sx={{
          display: "flex",
          justifyContent: "center",
          pl: matches ? 20 : 0,
          pr: matches ? 20 : 0,
        }}
      >
        <Grid xs={matches ? 7 : 12}>{children}</Grid>
        <Grid
          xs={matches ? 5 : 12}
          sx={{
            padding: matches ? "0px 16px 0px 16px" : "0px",
          }}
        >
          {<GlobalPriceFinishedReject data={notice as NoticeV2NegType} />}
          {flgIsOwner ? (
            <>
              <ButtomLoader
                fullWidth
                variant="contained"
                onClick={() => handleNevigateOwner()}
                sx={{
                  mt: 2,
                }}
              >
                VER OTRAS NEGOCIACIONES
              </ButtomLoader>
            </>
          )
            : (
              <ButtomLoader
                fullWidth
                variant="contained"
                onClick={() => handleNavigation()}
                sx={{
                  mt: 2,
                }}
              >
                BUSCAR TRANSPORTISTAS
              </ButtomLoader>
            )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default RejectLayout;
