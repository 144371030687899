import BasicModal from "@/components/dialog/Notiffication";

import { SkeletonGeneral } from "@/components/skeletons/SkeletonGeneral";
import {
  ID_AUCTION_FLOW,
  ID_BETWEEN_CITIES,
  ID_IN_CITY,
  ID_MOVING_FLOW,
  ID_MULTI_DESTINY,
} from "@/consts/defaultConsts";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import useGetData from "../../shared/hooks/useGetData";
import DriverCounterOfferLayout from "./DriverCounterOfferLayout";
import { AuctionNoticeDriver } from "./containers/AuctionNoticeDriver/Index";
import { BtwnCitiesNoticeDriver } from "./containers/BtwnCitiesNoticeDriver/Index";
import { InTheCityNoticeDriver } from "./containers/InTheCityNoticeDriver/Index";
import { MovingNoticeDriver } from "./containers/MovingNoticeDriver/Index";
import { MultidestinyNoticeDriver } from "./containers/MultidestinyNoticeDriver/Index";


export const componentToRender: Record<
  number,
  ({ notice }: { notice: NoticeV2NegType }) => JSX.Element
> = {
  [ID_IN_CITY]: InTheCityNoticeDriver,
  [ID_BETWEEN_CITIES]: BtwnCitiesNoticeDriver,
  [ID_MULTI_DESTINY]: MultidestinyNoticeDriver,
  [ID_MOVING_FLOW]: MovingNoticeDriver,
  [ID_AUCTION_FLOW]: AuctionNoticeDriver,
};

export const PincipalDriverCounterOfferNotice = () => {

  const { notice } = useGetData();
  const Component =
    componentToRender[(notice as NoticeV2NegType)?.idtipoaviso as number];

  if (!Component) return <SkeletonGeneral/>;

  return (
    <>
      <DriverCounterOfferLayout>
       <Component notice={notice as NoticeV2NegType} />
      </DriverCounterOfferLayout>
      <BasicModal goBack />
    </>
  );
};
