import { FORMAT_DATE_DDMMYYYY, FORMAT_TIME_HHMMmm } from "@/consts/formatsDate";
import { NoticeConditions } from "@/models/notice/noticeModels";
import dayjs from "dayjs";
import { IMultiPrices, multiSelectCity, SelectCity } from "../locationRedux/selectLocation";
import { SelectNotice } from "../noticeRedux/noticeSlice";
import { RootState } from "../store";
import { ISelectedVehicle, IUser } from "../userRedux/userReduxInterface";
import { IListOfServices } from "./includedServicesSlice";

export const getMultiPrices = (multiPrices: IMultiPrices[]) => {
  return multiPrices.map(({ precio, distrito }) => ({
    iddistrito: distrito.id,
    precio,
  }));
};

export const getNoticeDestinyUnitPrice = (selectedDestiny: SelectCity, price: number) => {
  if (!!selectedDestiny.distritodet && !!selectedDestiny.distritodet.length) {
    const result = selectedDestiny.distritodet.map(({ distrito }) => ({
      iddistrito: distrito.id,
      precio: price,
    }));
    return result;
  }
};

export const getDataForSaveIntheCity = (price: number, state: RootState, isMultiPrice: boolean) => {
  const idusuario = getUserIdByState(state);
  const objVehicle = getVehicleData(state);
  const objLocation = getLoacionData(state);
  const avisocondiciones = getNoticeOfConditions(state);
  const flgpreciounico = isMultiPrice ? 0 : 1;
  const idtipoaviso = (state.notice.selectedNotice as SelectNotice).id;
  return {
    precio: price,
    flgpreciounico, // TODO: luego se tiene que colocar dinamico
    idusuario,
    idtipoaviso,
    ...objVehicle,
    ...objLocation,
    avisocondiciones,
  };
};

export const getDataForSaveBetweenCities = (price: number, state: RootState) => {
  const idusuario = getUserIdByState(state);
  const objVehicle = getVehicleData(state);
  const idtipoaviso = (state.notice.selectedNotice as SelectNotice).id;
  const avisocondiciones = getNoticeOfConditions(state);
  const objLocation = getLocationDataBetweenCities(state);
  const objFixDate = getFixedDate(state);

  return {
    precio: price,
    idusuario,
    idtipoaviso,
    avisocondiciones,
    ...objVehicle,
    ...objLocation,
    ...objFixDate,
  };
};

export const getUserIdByState = ({ user }: RootState) => {
  const curentUser = user.user as IUser;

  return curentUser.id;
};

export const getVehicleData = ({ user }: RootState) => {
  const { id: idvehiculo, cargamaxima: pesomaximo, flgrefrigerado: flgcargarefrigerada } = user.selectedVehicle as ISelectedVehicle;

  return { idvehiculo, pesomaximo, flgcargarefrigerada };
};

// getLoacionData, regresa la data de las ubicaciones para el flujo de entre ciudades
export const getLoacionData = ({ selectLocation }: RootState) => {
  const selectedCity = selectLocation.selectedCity as SelectCity;
  const selectedOrigin = selectLocation.selectedOrigin as SelectCity;
  const selectedDestiny = selectLocation.selectedDestiny as SelectCity;

  const idciudad = selectedCity.id;
  const idorigen = selectedOrigin.id;
  const iddestino = selectedDestiny.id;
  const idciudaddestino = idciudad;

  return { idciudad, idorigen, iddestino, idciudaddestino };
};

export const getNoticeOfConditions = ({ includedServices }: RootState) => {
  const { listOfServices } = includedServices;
  const conditions = listOfServices.map((service: IListOfServices | NoticeConditions) => ({
    idcondiciones: service.id,
    flgseleccion: service.isActive,
  }));

  return conditions;
};

// multiCity utils

export const setDataForSaveMultiCity = (
  pricePerUnit: number, // precio por unidad (por puntos o por hora)
  minUnits: number, // minimo de unidades (minimo de puntos o horas)
  minPrice: number, // precio minimo  (pricePerUnit * minUnits)
  state: RootState,
  type: number
) => {
  const idusuario = getUserIdByState(state);
  const objVehicle = getVehicleData(state);
  const objLocation = getLocationDataMultiDistrito(state);
  const avisocondiciones = getNoticeOfConditions(state);
  const idtipoaviso = (state.notice.selectedNotice as SelectNotice).id;
  const flghoraopunto = type;
  const pointorHours =
    type === 1
      ? {
          precioxhora: pricePerUnit,
          horasminimo: minUnits,
        }
      : {
          precioxpunto: pricePerUnit,
          puntosminimo: minUnits,
        };

  return {
    idusuario,
    idtipoaviso,
    flghoraopunto,
    ...pointorHours,
    precio: minPrice,
    ...objVehicle,
    ...objLocation,
    avisocondiciones,
  };
};

const getLocationDataMultiDistrito = ({ selectLocation }: RootState) => {
  const selectedCity = selectLocation.selectedCity as SelectCity;
  const avisoorigenes = selectLocation.multiSelectOrigin as multiSelectCity[];
  const avisodestinos = selectLocation.multiSelectDestiny as multiSelectCity[];
  return {
    idciudad: selectedCity.id,
    idciudaddestino: selectedCity.id,
    avisoorigenes: avisoorigenes.map((origin) => ({
      iddistrito: origin.id,
      idzona: origin.idzone,
    })),
    avisodestinos: avisodestinos.map((destiny) => ({
      iddistrito: destiny.id,
      idzona: destiny.idzone,
    })),
  };
};

/**
 * Devuelve un objeto con las propiedades idciudad e iddestino, que son los ids de las ciudades
 * seleccionadas, para el flujo de entre ciudades!!
 * @param {RootState}  - RootState: Este es el estado raíz de la aplicación.
 */
export const getLocationDataBetweenCities = ({ selectLocation }: RootState) => {
  const selectedCity = selectLocation.selectedCity as SelectCity;
  const selectedDestiny = selectLocation.selectedDestiny as SelectCity;

  const idciudad = selectedCity.id;
  const idciudaddestino = selectedDestiny.id;

  return { idciudad, idciudaddestino };
};

export const getFixedDate = ({ notice }: RootState) => {
  const { fixedDate, isFixedDate } = notice;
  const date = fixedDate ? dayjs(fixedDate)?.format(FORMAT_DATE_DDMMYYYY) : fixedDate;

  return { flgpermanenteofecha: isFixedDate, fechafija: isFixedDate === 1 ? null : date };
};

export const getFixedDateTime = ({ notice }: RootState) => {
  const { fixedDate, HoursMins } = notice;
  const date = dayjs(fixedDate)?.format(FORMAT_DATE_DDMMYYYY);
  const hour = dayjs(HoursMins)?.format(FORMAT_TIME_HHMMmm);

  return { fechafija: date, hora: hour };
};

/**
 * Devuelve un objeto con las propiedades idciudad e iddestino, que son los ids de las ciudades
 * seleccionadas, para el flujo de remate entre ciudades!!
 * @param {RootState}  - RootState: Este es el estado raíz de la aplicación.
 */
const getLocationIntercitySale = ({ selectLocation }: RootState) => {
  const selectedCity = selectLocation.selectedCity as SelectCity;
  const selectedCity2 = selectLocation.selectedCity2 as SelectCity;
  const selectedOrigen = selectLocation.selectedOrigin as SelectCity;
  const selectedDestiny = selectLocation.selectedDestiny as SelectCity;
  return {
    idciudad: selectedCity.id,
    idorigen: selectedOrigen.id,
    idciudaddestino: selectedCity2.id,
    iddestino: selectedDestiny.id,
  };
};

// Remate entre ciudades utils
export const setDataForSaveIntercitySale = (regularPrice: number, descPercentage: number, price: number, state: RootState) => {
  const idusuario = getUserIdByState(state);
  const objVehicle = getVehicleData(state);
  const objLocation = getLocationIntercitySale(state);
  const avisocondiciones = getNoticeOfConditions(state);
  const idtipoaviso = (state.notice.selectedNotice as SelectNotice).id;
  const detailPrice = {
    precioregular: regularPrice,
    porcentajedescuento: descPercentage,
    precio: price,
  };
  const objFixDateTime = getFixedDateTime(state);

  return {
    idusuario,
    idtipoaviso,
    ...detailPrice,
    ...objVehicle,
    ...objLocation,
    avisocondiciones,
    ...objFixDateTime,
  };
};
export const getNoticeDestinyInCity = (multiPrices: IMultiPrices[] | undefined, price: number, selectedDestiny: SelectCity) => {
  let avisodestinos = null;
  if (!!multiPrices && !!multiPrices?.length) {
    avisodestinos = getMultiPrices(multiPrices);
  } else {
    avisodestinos = getNoticeDestinyUnitPrice(selectedDestiny, price);
  }

  return avisodestinos;
};
export const getLocationDataMoving = ({ selectLocation }: RootState) => {
  // const selectedCity = selectLocation.selectedCity as SelectCity;
  const selectedDestiny = selectLocation.selectedDestiny as SelectCity;
  const selectedOrigin = selectLocation.selectedOrigin as SelectCity;

  const idciudad = selectedOrigin.id;
  const idciudaddestino = selectedDestiny.id;

  return {
    idciudad,
    idciudaddestino,
  };
};

// moving

export const getDataForSaveMoving = (price: number, assistant: number, state: RootState, type: number, isMultiPrice: boolean) => {
  const idusuario = getUserIdByState(state);
  const objVehicle = getVehicleData(state);
  const objLocation = type === 2 ? getLocationDataMoving(state) : getLoacionData(state);
  const avisocondiciones = getNoticeOfConditions(state);
  const idtipoaviso = (state.notice.selectedNotice as SelectNotice).id;
  const flgpreciounico = isMultiPrice ? 0 : 1;
  return {
    precio: price,
    idusuario,
    flgpreciounico,
    idtipoaviso,
    avisocondiciones,
    ...objVehicle,
    flgcargarefrigerada: 0,
    ...objLocation,
    ayudantes: assistant,
    flgciudadoentreciudades: type,
  };
};
