import DetailsTextV2 from '@/components/DetailsTextV2/Index';
import ConditionsListOffert from '@/components/conditionsList/ConditionsListOffert';
import TransporterRating from '@/components/userRating/TransporterRating';
import { descriptionLoad, districtsDeliveryText } from '@/consts/noticeConsts';
import {
  getMaxWeightVehicleRequest,
  getTypeVehicleAndPlateRequest,
} from '@/pages/createRequestNegotiation/CreateRequestNegSuccess/utils/bodyUtils';
import DetailsTextMoving from '@/pages/customerRequest/application/components/DetailsTextMoving';
import { ConfigDataBody } from '@/pages/customerRequest/application/config';
import { renderRating } from '@/pages/customerRequest/application/utils/utilsApplication';
import InlineTextDetails from '@/pages/customerRequest/components/InlineTextDetails';
import { ImagesNegV2 } from '@/pages/noticeV2Negotation/components/ImagesNegV2';
import { Typography } from '@mui/material';
import {
  getFormatMetroNegRequest,
  getImagesNegRequest,
  renderTitleLongDestinyNegRequest,
  renderTitleLongOriginNegRequest,
} from '../FinishedAccept/utils/bodyUtils';
import BodyConfigNegReject from './components/BodyConfigNegReject';
import UserRating from '@/components/userRating/UserRating';

export const objectRenderComponentFinishedReject: Record<
  number,
  () => JSX.Element
> = {
  1: BodyConfigNegReject,
  2: BodyConfigNegReject,
  3: BodyConfigNegReject,
  4: BodyConfigNegReject,
  5: BodyConfigNegReject,
};

export const configData: ConfigDataBody[] = [
  {
    Component: UserRating,
    props: {
      containerSx: { mt: '16px', mb: '5px' },
      viewRating: true,
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'data',
        defaultResult: {},
        callback: renderRating,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: 'Detalles del servicio',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha del servicio',
      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'origenTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referencia',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-dark',
      title: 'Destino',
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'destinoTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  // icon-truck
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'vehiculo',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'vehiculo',
        componentProp: 'origen',
        defaultResult: '',
        callback: getTypeVehicleAndPlateRequest,
      },
      {
        property: 'vehiculo',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getMaxWeightVehicleRequest,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: descriptionLoad,
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: ImagesNegV2,
    props: {
      style: { marginTop: '13px' },
    },
    valid: [
      {
        property: 'fotos',
        componentProp: 'images',
        defaultResult: [],
        callback: getImagesNegRequest,
      },
    ],
  },

  {
    Component: Typography,
    props: {
      variant: 'body1',
      marginTop: '4px',
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
  {
    Component: InlineTextDetails,
    props: {
      icon: 'icon-kilos',
      title: 'Peso estimado: ',
    },
    valid: [
      {
        property: 'pesoEstimado',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'pesoEstimado',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
];

// MULTIDESTINY

export const multiDestinyConfigData: ConfigDataBody[] = [
  {
    Component: UserRating,
    props: {
      containerSx: { mt: '16px', mb: '5px' },
      viewRating: true,
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'data',
        defaultResult: {},
        callback: renderRating,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: 'Detalles del servicio',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha del servicio',
      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: InlineTextDetails,
    props: {
      icon: 'icon-location-light',
      title: `${districtsDeliveryText}: `,
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'origenTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'vehiculo',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'vehiculo',
        componentProp: 'origen',
        defaultResult: '',
        callback: getTypeVehicleAndPlateRequest,
      },
      {
        property: 'vehiculo',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getMaxWeightVehicleRequest,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: descriptionLoad,
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: ImagesNegV2,
    props: {
      style: { marginTop: '13px' },
    },
    valid: [
      {
        property: 'fotos',
        componentProp: 'images',
        defaultResult: [],
        callback: getImagesNegRequest,
      },
    ],
  },

  {
    Component: Typography,
    props: {
      variant: 'body1',
      marginTop: '4px',
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
  {
    Component: InlineTextDetails,
    props: {
      icon: 'icon-kilos',
      title: 'Peso estimado: ',
    },
    valid: [
      {
        property: 'pesoEstimado',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'pesoEstimado',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
];

// BETWEEN CITIES

export const betweenCitiesconfigData: ConfigDataBody[] = [
  {
    Component: UserRating,
    props: {
      containerSx: { mt: '16px', mb: '5px' },
      viewRating: true,
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'data',
        defaultResult: {},
        callback: renderRating,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: 'Detalles del servicio',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha del servicio',
      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'origenTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referencia',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-light',
      title: 'Destino',
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'destinoTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  // icon-truck
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'vehiculo',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'vehiculo',
        componentProp: 'origen',
        defaultResult: '',
        callback: getTypeVehicleAndPlateRequest,
      },
      {
        property: 'vehiculo',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getMaxWeightVehicleRequest,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: descriptionLoad,
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: ImagesNegV2,
    props: {
      style: { marginTop: '13px' },
    },
    valid: [
      {
        property: 'fotos',
        componentProp: 'images',
        defaultResult: [],
        callback: getImagesNegRequest,
      },
    ],
  },

  {
    Component: Typography,
    props: {
      variant: 'body1',
      marginTop: '4px',
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
  {
    Component: InlineTextDetails,
    props: {
      icon: 'icon-kilos',
      title: 'Peso estimado: ',
    },
    valid: [
      {
        property: 'pesoEstimado',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'pesoEstimado',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
];

// MOVING

export const configDataCustomRequestMoving: ConfigDataBody[] = [
  {
    Component: UserRating,
    props: {
      containerSx: { mt: '16px', mb: '5px' },
      viewRating: true,
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'data',
        defaultResult: {},
        callback: renderRating,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: 'Detalles del servicio',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha del servicio',
      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextMoving,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'referencia',
        componentProp: 'originDescription',
        defaultResult: '',
        // callback: renderTitleLongOrigin,
        callback: renderTitleLongOriginNegRequest,
      },
    ],
  },
  {
    Component: DetailsTextMoving,
    props: {
      icon: 'icon-location-dark',
      title: 'Destino',
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
        // callback: renderTitleLongDestiny,
        callback: renderTitleLongDestinyNegRequest,
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-metter',
      title: 'Área',
    },
    valid: [
      {
        property: 'metraje',
        componentProp: 'show',
        defaultResult: false,
      },

      {
        property: 'metraje',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'metraje',
        componentProp: 'origen',
        defaultResult: '',
        callback: getFormatMetroNegRequest,
      },
    ],
  },
  // {
  //   Component: DetailsTextMoving,
  //   props: {
  //     icon: 'icon-holidayVillage',
  //     title: 'Ambientes',
  //   },
  //   valid: [
  //     {
  //       property: 'ambientes',
  //       componentProp: 'show',
  //       defaultResult: false,
  //     },
  //     {
  //       property: 'ambientes',
  //       componentProp: 'origen',
  //       defaultResult: '',
  //       callback: renderTitleAmbienteNegRequest,
  //     },
  //     {
  //       property: 'ambientes',
  //       componentProp: 'originDescription',
  //       defaultResult: '',
  //       callback: renderAmbientes,
  //     },
  //   ],
  // },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'vehiculo',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'vehiculo',
        componentProp: 'origen',
        defaultResult: '',
        callback: getTypeVehicleAndPlateRequest,
      },
      {
        property: 'vehiculo',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getMaxWeightVehicleRequest,
      },
    ],
  },
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '16px' },
    },
    valid: [
      {
        property: 'condiciones',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'condiciones',
        componentProp: 'listServices',
        defaultResult: [],
      },
    ],
  },
];
