import axios, { AxiosInstance } from "axios";
import { BASE_URL_AUTH } from "@/config/config";
import { getLocalStorage } from "../localstorageServices";
import { USER_TOKEN_STORAGE } from "@/consts/defaultConsts";

export const controllerInstanceAuth = new AbortController();

const instanceAuth: AxiosInstance = axios.create({
  baseURL: BASE_URL_AUTH,
  signal: controllerInstanceAuth.signal,
});

instanceAuth.interceptors.request.use((request) => {
  const token = getLocalStorage(USER_TOKEN_STORAGE);
  if (!token) return request;

  request.headers = {
    Authorization: `Bearer ${token}`,
  };

  return request;
});

export { instanceAuth };
