import {
  NEGOTATION_ACCEPT_FLOW_PATHS,
  NEGOTATION_COUNTEROFFER_FLOW_PATHS,
  NEGOTATION_CO_COUNTEROFFER_FLOW_PATHS,
  NEGOTATION_OFFER_FLOW_PATHS,
} from '@/consts/negotationFlowPath';
import {
  NOTICE_IN_CITY_PATHS,
  NOTICE_MOVING_PATHS_DISTRICT,
  NOTICE_MULTI_DESTINY_HOURS_PATHS,
  NOTICE_TRANSPORT_AUCTION_PATHS,
  NOTICE_TRANSPORT_BETWEEN_CITIES,
} from '@/consts/typeServicesPaths';
import {
  REQUEST_EXPRESS_FLOW_PATHS,
  REQUEST_IN_CITY_FLOW_PATHS,
  REQUEST_MOVING_IN_CITY_FLOW_PATHS,
  REQUEST_MULTIDESTINY_PATHS,
} from '@/pages/customerRequest/shared/consts/routes';
import {
  COUNTER_OFFER_AUCTION_FLOW_PATH,
  COUNTER_OFFER_BETWEEN_CITIES_PATH,
  COUNTER_OFFER_IN_CITY_FLOW_PATH,
  COUNTER_OFFER_MOVING_FLOW_PATH,
  COUNTER_OFFER_MULTI_DESTINY_FLOW_PATH,
  OFFER_AUCTION_FLOW_PATH,
  OFFER_BETWEEN_CITIES_PATH,
  OFFER_IN_CITY_FLOW_PATH,
  OFFER_MOVING_FLOW_PATH,
  OFFER_MULTI_DESTINY_FLOW_PATH,
} from '@/pages/flowNoticeNegotation/shared/const/routes/clientAccepOrCounteroffer';

export interface IRouteFlowByIdTypeNotice {
  default: Array<string>;
  offert: Array<string>;
  counterOffert: Array<string>;
  customerRequest: Array<string>;
  noticeNegotationOffert: Array<string>;
  noticeNegotationRequestAccept: Array<string>;
  noticeNegotationRequestCounterOffer: Array<string>;
  cocounterOfferForClient: Array<string>;
}

export const configtRouteFlowByIdTypeNotice: Record<
  number,
  IRouteFlowByIdTypeNotice
> = {
  1: {
    default: NOTICE_IN_CITY_PATHS,
    offert: OFFER_IN_CITY_FLOW_PATH,
    counterOffert: COUNTER_OFFER_IN_CITY_FLOW_PATH,
    customerRequest: REQUEST_IN_CITY_FLOW_PATHS,
    noticeNegotationOffert: NEGOTATION_OFFER_FLOW_PATHS,
    noticeNegotationRequestAccept: NEGOTATION_ACCEPT_FLOW_PATHS,
    noticeNegotationRequestCounterOffer: NEGOTATION_COUNTEROFFER_FLOW_PATHS,
    cocounterOfferForClient: NEGOTATION_CO_COUNTEROFFER_FLOW_PATHS,
  },
  2: {
    default: NOTICE_MULTI_DESTINY_HOURS_PATHS,
    offert: OFFER_MULTI_DESTINY_FLOW_PATH,
    counterOffert: COUNTER_OFFER_MULTI_DESTINY_FLOW_PATH,
    customerRequest: REQUEST_MULTIDESTINY_PATHS,
    noticeNegotationOffert: NEGOTATION_OFFER_FLOW_PATHS,
    noticeNegotationRequestAccept: NEGOTATION_ACCEPT_FLOW_PATHS,
    noticeNegotationRequestCounterOffer: NEGOTATION_COUNTEROFFER_FLOW_PATHS,
    cocounterOfferForClient: NEGOTATION_CO_COUNTEROFFER_FLOW_PATHS,
  },
  3: {
    default: NOTICE_TRANSPORT_BETWEEN_CITIES,
    offert: OFFER_BETWEEN_CITIES_PATH,
    counterOffert: COUNTER_OFFER_BETWEEN_CITIES_PATH,
    customerRequest: REQUEST_IN_CITY_FLOW_PATHS,
    noticeNegotationOffert: NEGOTATION_OFFER_FLOW_PATHS,
    noticeNegotationRequestAccept: NEGOTATION_ACCEPT_FLOW_PATHS,
    noticeNegotationRequestCounterOffer: NEGOTATION_COUNTEROFFER_FLOW_PATHS,
    cocounterOfferForClient: NEGOTATION_CO_COUNTEROFFER_FLOW_PATHS,
  },
  4: {
    default: NOTICE_TRANSPORT_AUCTION_PATHS,
    offert: OFFER_AUCTION_FLOW_PATH,
    counterOffert: COUNTER_OFFER_AUCTION_FLOW_PATH,
    customerRequest: REQUEST_EXPRESS_FLOW_PATHS,
    noticeNegotationOffert: NEGOTATION_OFFER_FLOW_PATHS,
    noticeNegotationRequestAccept: NEGOTATION_ACCEPT_FLOW_PATHS,
    noticeNegotationRequestCounterOffer: NEGOTATION_OFFER_FLOW_PATHS,
    cocounterOfferForClient: NEGOTATION_CO_COUNTEROFFER_FLOW_PATHS,
  },
  5: {
    default: NOTICE_MOVING_PATHS_DISTRICT,
    offert: OFFER_MOVING_FLOW_PATH,
    counterOffert: COUNTER_OFFER_MOVING_FLOW_PATH,
    customerRequest: REQUEST_MOVING_IN_CITY_FLOW_PATHS,
    noticeNegotationOffert: NEGOTATION_OFFER_FLOW_PATHS,
    noticeNegotationRequestAccept: NEGOTATION_ACCEPT_FLOW_PATHS,
    noticeNegotationRequestCounterOffer: NEGOTATION_COUNTEROFFER_FLOW_PATHS,
    cocounterOfferForClient: NEGOTATION_CO_COUNTEROFFER_FLOW_PATHS,
  },
};

type GetOffertProps = { typeOffert: keyof IRouteFlowByIdTypeNotice };

export const getRoutesByIdTypeNotice = (
  idType: number,
  typeOffert: GetOffertProps['typeOffert'] = 'default'
) => {
  const routeFlow = configtRouteFlowByIdTypeNotice[idType][typeOffert];

  return routeFlow;
};
