import { Typography } from "@mui/material";

const TITLE_TEMPLATE = "De $origin a $destiny";

type Props = {
  origentexto: string;
  destinotexto: string;
};

const DetailTitle = ({ origentexto, destinotexto }: Props) => {
  const handlePrepareTitle = () => {
    const title = TITLE_TEMPLATE.replace("$origin", origentexto).replace(
      "$destiny",
      destinotexto
    );

    return title;
  };

  if (!origentexto && !destinotexto) return <></>;

  return (
    <Typography variant="h6" marginBottom="8px">
      {handlePrepareTitle()}
    </Typography>
  );
};

export default DetailTitle;
