import { Grid, Stack, styled, Typography } from "@mui/material";
import ButtomLoader from "../form/buttonLoader/ButtomLoader";

export const ContainerCardProfile = styled(Grid)(
  () => `
    box-shadow: 0px 4px 10px #ECEFF1;
    border-radius: 8px;
    background: #FFFFFF;
    padding: 8px 16px;
    cursor: pointer;
    `
);

export const TipoAviso = styled(Typography)(
  ({ theme }) => `
        display: flex;
        align-items: center;
        font-family: ${theme.typography.body2.fontFamily};
        font-size: 12px;
        line-height: ${theme.typography.body2.lineHeight};
        color: ${theme.typography.body2.color};
        gap: 10px;
        min-height: 24px;
    `
);

export const TitleBody = styled(Typography)(
  ({ theme }) => `
        font-family:${theme.typography.subtitle1.fontFamily};
        font-size: ${theme.typography.subtitle1.fontSize};
        line-height: ${theme.typography.subtitle1.fontSize};
        font-weight: ${theme.typography.subtitle1.fontWeight};
        color:"#212121";
        margin-top: 5px;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        min-height: 26px;
    `
);

export const ContentInfo = styled(Grid)(
  ({ theme }) => `
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 44px;
        margin-bottom: 8px;
    `
);

export const StackInfo = styled(Grid)(
  ({ theme }) => `
          display: flex;
          flex-direction: column;
          gap: 4px;
      `
);

export const SubTitleInfo = styled(Typography)(
  ({ theme }) => `
        font-family:${theme.typography.caption.fontFamily};
        font-size: ${theme.typography.caption.fontSize};
        line-height: ${theme.typography.caption.lineHeight};
        font-weight: ${theme.typography.caption.fontWeight};
        color: rgba(0, 0, 0, 0.87);
    `
);

export const TitlePrice = styled(Typography)(
  ({ theme }) => `
        font-size: 12px;
        line-height: ${theme.typography.body2.lineHeight};
        font-family: ${theme.typography.body2.fontFamily};
        color: ${theme.typography.body2.color};
        margin-top: 3px;
        margin-bottom: -1px;
    `
);

export const TextPrice = styled(Typography)(
  ({ theme }) => `
        font-family:${theme.typography.h5.fontFamily};
        font-size: ${theme.typography.h5.fontSize};
        line-height: ${theme.typography.h5.lineHeight};
        font-weight: ${theme.typography.h5.fontWeight};
        text-transform: ${theme.typography.h5.textTransform};
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 52px;
    `
);

export const PriceContainer = styled("div")(() => ({
  width: "auto",
}));

export const MinTitlePrice = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.caption.fontFamily,
  fontSize: theme.typography.caption.fontSize,
  fontWeight: theme.typography.caption.fontWeight,
  lineHeight: theme.typography.caption.lineHeight,
  textTransform: "none",
}));

export const ImgContent = styled(Stack)(
  () => `
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
    `
);

export const ButtonProfile = styled(ButtomLoader)(
  () => `
      max-width: 128px; 
      margin-top: 0;
      padding: 1px 16px 0;
    `
);
