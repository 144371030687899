import { styled, Typography } from "@mui/material";
export const ButtonDropdown = styled(Typography)(
    ({ theme }) => `
        font-size: 18px;
        line-height: 32px;
        font-weight: 370;
        color: ${theme.palette.primary.main};
        margin: 0 4px 0 8px;
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 4px;
    `
)