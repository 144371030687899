import { Container, styled, Typography } from "@mui/material";
import React from "react";

const ContainerEmpty = styled(Container)(
  ({ theme }) => `
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background-color: ${theme.palette.background.default};
      flex-direction: column;
      margin-top: 18px;
      `
);

const Content = styled("div")(
  ({ theme }) => `
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 360px;
      text-align: center;
      `
);
const EmptyVehicles = () => {
  return (
    <ContainerEmpty>
      <Content>
        <i className="icon-undraw-add-people" />
        <Typography variant="h6" color="textSecondary">
          Sin vehículos registrados
        </Typography>
        <Typography variant="body2" component="p" sx={{ mt: 1 }}>
          Agrega un vehículo, crea un aviso y elige la solicitud que más te
          convenga.
        </Typography>
      </Content>
    </ContainerEmpty>
  );
};

export default EmptyVehicles;
