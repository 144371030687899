// index paths

import { DRIVERS_LANDING_PATH } from "./typeServicesPaths";
export const DRIVER_CREATE_NOTICE_TYPES = `${DRIVERS_LANDING_PATH}/crear-aviso`

export const DRIVER_TYPE_NOTICE = ':typeNotice';

export const DRIVER_CREATE_NOTICE_TYPE_PATH = `${DRIVER_CREATE_NOTICE_TYPES}/${DRIVER_TYPE_NOTICE}`;

// ==================================================
//                   Tipo de aviso
// ==================================================
export const NOTICE_IN_CITY = 'en-la-ciudad';
export const NOTICE_BETWEEN_CITIES = 'entre-ciudades'; 
export const NOTICE_MULTI_DESTINY = 'multidestino';
export const NOTICE_MOVING = 'mudanza';
export const NOTICE_AUCTION = 'remate';

export const ID_NOTICE_IN_CITY = 1;
export const ID_NOTICE_MULTI_DESTINY = 2;
export const ID_NOTICE_BETWEEN_CITIES = 3;
export const ID_NOTICE_AUCTION = 4;
export const ID_NOTICE_MOVING = 5;

// ==================================================
//             Services path customizations
// ==================================================
 
// {URL} / transportistas / crear-aviso / :typeAviso / vehiculo
export const DRIVER_CREATE_NOTICE_VEHICLE_PATH = `${DRIVER_CREATE_NOTICE_TYPE_PATH}/vehiculo`;

// ======= path  para mudanza 

// {URL} / transportistas / crear-aviso / mudanza / tipo
export const DRIVER_CREATE_NOTICE_MOVING_TYPE_PATH = `${DRIVER_CREATE_NOTICE_TYPE_PATH}/tipo`;

// ============================

// {URL} / transportistas / crear-aviso / :typeAviso / condiciones
export const DRIVER_CREATE_NOTICE_CONDITIONS_PATH = `${DRIVER_CREATE_NOTICE_TYPE_PATH}/condiciones`;

// ======= path para remate

// {URL} / transportistas / crear-aviso / remate / precio
export const DRIVER_CREATE_NOTICE_PRICE_PATH = `${DRIVER_CREATE_NOTICE_TYPE_PATH}/precio`;

// ============================

// {URL} / transportistas / crear-aviso / :typeAviso / lugar
export const DRIVER_CREATE_NOTICE_LOCATION_PATH = `${DRIVER_CREATE_NOTICE_TYPE_PATH}/lugar`;

// ==================================================
//             view successfully created
// ==================================================

// {URL} / transportistas / crear-aviso / :typeAviso / aviso-creado
export const DRIVER_CREATE_NOTICE_CREATED_PATH = `${DRIVER_CREATE_NOTICE_TYPE_PATH}/aviso-creado`;

// ==================================================
//             Ver notice
// ==================================================

// {URL} / transportistas / avisos / :typeAviso / {id}
export const DRIVER_NOTICE_IN_CITY_PATH = `${DRIVERS_LANDING_PATH}/:typeAviso/:id`;



// =================================================
//             Flujos para cada creacion de aviso
// =================================================

// en-la-ciudad
export const DRIVER_CREATE_NOTICE_IN_CITY_FLOW_PATHS = [
    DRIVER_CREATE_NOTICE_VEHICLE_PATH,
    DRIVER_CREATE_NOTICE_CONDITIONS_PATH,
    DRIVER_CREATE_NOTICE_LOCATION_PATH,
    DRIVER_CREATE_NOTICE_CREATED_PATH,
];

// entre-ciudades
export const DRIVER_CREATE_NOTICE_BETWEEN_CITIES_FLOW_PATHS = [
    DRIVER_CREATE_NOTICE_VEHICLE_PATH,
    DRIVER_CREATE_NOTICE_CONDITIONS_PATH,
    DRIVER_CREATE_NOTICE_LOCATION_PATH,
    DRIVER_CREATE_NOTICE_CREATED_PATH,
];

// multidestino
export const DRIVER_CREATE_NOTICE_MULTI_DESTINY_FLOW_PATHS = [
    DRIVER_CREATE_NOTICE_VEHICLE_PATH,
    DRIVER_CREATE_NOTICE_CONDITIONS_PATH,
    DRIVER_CREATE_NOTICE_LOCATION_PATH,
    DRIVER_CREATE_NOTICE_CREATED_PATH,
];

// mudanza
export const DRIVER_CREATE_NOTICE_MOVING_FLOW_PATHS = [
    DRIVER_CREATE_NOTICE_MOVING_TYPE_PATH,
    DRIVER_CREATE_NOTICE_VEHICLE_PATH,
    DRIVER_CREATE_NOTICE_CONDITIONS_PATH,
    DRIVER_CREATE_NOTICE_LOCATION_PATH,
    DRIVER_CREATE_NOTICE_CREATED_PATH,
];

// remate
export const DRIVER_CREATE_NOTICE_AUCTION_FLOW_PATHS = [
    DRIVER_CREATE_NOTICE_VEHICLE_PATH,
    DRIVER_CREATE_NOTICE_CONDITIONS_PATH,
    DRIVER_CREATE_NOTICE_PRICE_PATH,
    DRIVER_CREATE_NOTICE_CREATED_PATH,
];


// register flujo de creacion de aviso

export const REGISTER_PATH = '/registro';

export const REGISTER_DRIVER_CREATE_NOTICE_TYPES = `${REGISTER_PATH}/transportista/crear-aviso`

export const REGISTER_DRIVER_TYPE_NOTICE = ':typeNotice';

export const REGISTER_DRIVER_CREATE_NOTICE_TYPE_PATH = `${REGISTER_DRIVER_CREATE_NOTICE_TYPES}/${REGISTER_DRIVER_TYPE_NOTICE}`;

// // ==================================================
//             Services path customizations register
// ==================================================

// {URL} / registro / transportista / crear-aviso / :typeAviso / vehiculo
export const REGISTER_DRIVER_CREATE_NOTICE_VEHICLE_PATH = `${REGISTER_DRIVER_CREATE_NOTICE_TYPE_PATH}/vehiculo`;

// ======= path  para mudanza

// {URL} / registro / transportista / crear-aviso / mudanza / tipo
export const REGISTER_DRIVER_CREATE_NOTICE_MOVING_TYPE_PATH = `${REGISTER_DRIVER_CREATE_NOTICE_TYPE_PATH}/tipo`;

// ============================

// {URL} / registro / transportista / crear-aviso / :typeAviso / condiciones
export const REGISTER_DRIVER_CREATE_NOTICE_CONDITIONS_PATH = `${REGISTER_DRIVER_CREATE_NOTICE_TYPE_PATH}/condiciones`;

// ======= path para remate

// {URL} / registro / transportista / crear-aviso / remate / precio
export const REGISTER_DRIVER_CREATE_NOTICE_PRICE_PATH = `${REGISTER_DRIVER_CREATE_NOTICE_TYPE_PATH}/precio`;

// ============================

// {URL} / registro / transportista / crear-aviso / :typeAviso / lugar
export const REGISTER_DRIVER_CREATE_NOTICE_LOCATION_PATH = `${REGISTER_DRIVER_CREATE_NOTICE_TYPE_PATH}/lugar`;

// ==================================================
//             view successfully created
// ==================================================

// {URL} / registro / transportista / crear-aviso / :typeAviso / aviso-creado
export const REGISTER_DRIVER_CREATE_NOTICE_CREATED_PATH = `${REGISTER_DRIVER_CREATE_NOTICE_TYPE_PATH}/aviso-creado`;

// ==================================================

// {URL} / registro / transportista / crear-aviso / :typeAviso / {id}
export const REGISTER_DRIVER_NOTICE_IN_CITY_PATH = `${REGISTER_PATH}/transportista/avisos/${DRIVER_TYPE_NOTICE}/:id`;

// =================================================
//             Flujos para cada creacion de aviso
// =================================================

// en-la-ciudad
export const REGISTER_DRIVER_CREATE_NOTICE_IN_CITY_FLOW_PATHS = [
    REGISTER_DRIVER_CREATE_NOTICE_CONDITIONS_PATH,
    REGISTER_DRIVER_CREATE_NOTICE_LOCATION_PATH,
    REGISTER_DRIVER_CREATE_NOTICE_CREATED_PATH,
];

// entre-ciudades
export const REGISTER_DRIVER_CREATE_NOTICE_BETWEEN_CITIES_FLOW_PATHS = [
    REGISTER_DRIVER_CREATE_NOTICE_CONDITIONS_PATH,
    REGISTER_DRIVER_CREATE_NOTICE_LOCATION_PATH,
    REGISTER_DRIVER_CREATE_NOTICE_CREATED_PATH,
];

// multidestino
export const REGISTER_DRIVER_CREATE_NOTICE_MULTI_DESTINY_FLOW_PATHS = [
    REGISTER_DRIVER_CREATE_NOTICE_CONDITIONS_PATH,
    REGISTER_DRIVER_CREATE_NOTICE_LOCATION_PATH,
    REGISTER_DRIVER_CREATE_NOTICE_CREATED_PATH,
];

// mudanza
export const REGISTER_DRIVER_CREATE_NOTICE_MOVING_FLOW_PATHS = [
    REGISTER_DRIVER_CREATE_NOTICE_MOVING_TYPE_PATH,
    REGISTER_DRIVER_CREATE_NOTICE_CONDITIONS_PATH,
    REGISTER_DRIVER_CREATE_NOTICE_LOCATION_PATH,
    REGISTER_DRIVER_CREATE_NOTICE_CREATED_PATH,
];

// remate
export const REGISTER_DRIVER_CREATE_NOTICE_AUCTION_FLOW_PATHS = [
    REGISTER_DRIVER_CREATE_NOTICE_PRICE_PATH,
    REGISTER_DRIVER_CREATE_NOTICE_CONDITIONS_PATH,
    REGISTER_DRIVER_CREATE_NOTICE_LOCATION_PATH,
    REGISTER_DRIVER_CREATE_NOTICE_CREATED_PATH,
];