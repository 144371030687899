import { BASE_URL_IMAGE } from "@/config/config";
import { ID_AUCTION_FLOW, IMG_PATH, MD_IMG } from "@/consts/defaultConsts";
import NO_FOTO_VEHICLE from '@/img/NO_FOTO_VEHICULO.png';
import { MyNotice } from "@/models/notice/noticeModels";
import { resizePhoto } from "@/utils/helpers";
import { Box, CardMedia } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import FlagDiscount from "../FlagDiscount";

const CardHeader = ({ data }: { data: MyNotice }) => {
  const [fristErrorLoad, setFristErrorLoad] = useState(false);
  const show = data.idTipoAviso === ID_AUCTION_FLOW;
  
  useEffect(() => {
    return () => {
      setFristErrorLoad(false);
    };
  }, []);

  const handleValidateImg = (imgURL: string, newFormat: string) => {
    const photoResized = resizePhoto({
      photo: IMG_PATH + imgURL,
      newFormat,
    });

    if (photoResized?.startsWith("http")) return photoResized;

    return IMG_PATH + photoResized;
  };

  const handleErrorLoadImage = (
    event: SyntheticEvent<HTMLImageElement, Event>
  ) => {
    const imageDefault = `${BASE_URL_IMAGE}/${data.foto}`;
    const target = event.target as HTMLInputElement;
    if (!fristErrorLoad) {
      target.src = imageDefault;
      setFristErrorLoad(true);
    } else {
      target.src = NO_FOTO_VEHICLE;
    }
  };

  

  return (
    <Box sx={{ position: "relative" }}>
      <CardMedia

        component="img"
        height="170"
        width="100%"
        image={handleValidateImg(data.foto, MD_IMG)}
        alt={data.origenTexto}
        onError={handleErrorLoadImage}
        sx={{ objectFit: fristErrorLoad ? "contain" : "cover" }}
      />
      <FlagDiscount
        show={show}
        currentPrice={data.precio}
        beforePrice={data?.precioRegular || 0}
      />
    </Box>
  );
};

export default CardHeader;
