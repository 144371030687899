import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { instanceAxios } from "./instanceFlow";

export const connection = (instance: AxiosInstance = instanceAxios) => {
  return {
    get: async (
      path: string,
      params?: AxiosRequestConfig<any> | undefined
    ): Promise<AxiosResponse<any, any>> => {
      return await instance.get(path, { params });
    },

    post: async (
      path: string,
      data?: any,
      config?: AxiosRequestConfig<any> | undefined
    ) => {
      return await instance.post(path, data, { ...config });
    },

    put: async (
      path: string,
      data?: any,
      config?: AxiosRequestConfig<any> | undefined
    ) => {
      return await instance.put(path, data, { ...config });
    },

    patch: async (
      path: string,
      data: any,
      config?: AxiosRequestConfig<any> | undefined
    ) => {
      return await instance.patch(path, data, { ...config });
    },

    delete: (path: string) => {
      return instance.delete(path);
    },
  };
};
