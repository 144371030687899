import { FORMAT_DATE_DDMMYYYY } from "@/consts/formatsDate";
import {
  AUTH_LOGIN,
  CONFIRMATION_PATH,
  PROFILE_NOTICE_OFF,
  SERVICES_LANDING_PATH,
} from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { LocationDetailState } from "@/interfaces/detailNoticeInterface";
import { NoticeResponseSaveFlowAPI } from "@/models/notice/noticeModels";
import { UsuarioAPI } from "@/models/userAuth/user";
import useDetailNotice from "@/pages/detailNotice/hook";
import {
  TypeStateNoticeEnum,
  btnPrivateNumber,
  btnPublicNumber,
} from "@/pages/viewService/city/rulesByFlow";
import {
  changeStatusNoticeThunk,
  getOffertActiveByIdThunk,
  renewNoticeThunk,
} from "@/store/noticeRedux/noticeThunks";
import {
  resetSendData,
  setIdTypeNoticeNegotiation,
} from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferSlice";
import { saveNoticeByOfferFlow } from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferThunk";
import { IRouteFlowByIdTypeNotice } from "@/store/routeActionsFlow/config";
import { initRouteFlowByIdNoticeThunk } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { setSuccessMsg } from "@/store/userRedux/userSlice";
import { formatDate, stringDDMMYYYToDate } from "@/utils/helpers";
import { Grid, styled } from "@mui/material";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ButtomLoader from "../form/buttonLoader/ButtomLoader";
import BannerModal from "../modals/BannerModal/BannerModal";
import { ModalTextData, modalTextData } from "./condifDateButtonsService";
import { ListButtons, ListItemsButtons } from "./typButtonsListItem";
import dayjs from "dayjs";
import { ID_MULTI_DESTINY } from "@/consts/defaultConsts";

const Container = styled(Grid)(
  () => `
    display: flex;
    flex-direction: column;
    `
);

const mundanzaTexto = "SOLICITAR COTIZACIÓN";
const contraofertaTexto = "CONTRAOFERTAR";
const offertNumber = 1;
const counterOffertNumber = 2;

type ActionModalType = {
  action: (action: any) => void;
  param: any;
};

const ButtonsService = ({ listButtons }: ListItemsButtons) => {
  const [showAlert, setShowAlert] = useState(false);
  const [actionAfterAgreeModal, setActionAfterAgreeModal] =
    useState<ActionModalType | null>(null);
  const [dataModal, setDataModal] = useState<ModalTextData>(
    modalTextData.deactivate
  );
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { handleGetCurrentDetailNotice } = useDetailNotice();

  const {
    user: { user },
    detailsNoticeSlice: { isLoading },
  } = useAppSelector((state) => state);

  const { pathname, ...location } = useLocation();
  const notice = useMemo(() => handleGetCurrentDetailNotice(), [isLoading]);

  const tipoAvisoId = notice?.tipoaviso?.id;
  const state = location.state as LocationDetailState;

  const handleOffertActions = (item: ListButtons) => {
    const idNotice = notice?.tipoaviso?.id;
    const typeOffert: keyof IRouteFlowByIdTypeNotice =
      item.idAction === btnPublicNumber["ACEPTAR"] ? "offert" : "counterOffert";
    const typeAvisoOffert =
      item.idAction === btnPublicNumber["ACEPTAR"]
        ? offertNumber
        : counterOffertNumber;
    dispatch(resetSendData());
    const nextRoute = dispatch(
      initRouteFlowByIdNoticeThunk(idNotice, typeOffert)
    );

    dispatch(setIdTypeNoticeNegotiation(typeAvisoOffert));
    dispatch(saveNoticeByOfferFlow(notice));
    navigate(handlePrepareNextRouter(nextRoute), { state });
  };

  const handleRenewActions = () => {
    const idNotice = notice?.id;
    dispatch(renewNoticeThunk(idNotice)).then((res) => {
      // se navegara a la ruta de success
      const [data] = res as NoticeResponseSaveFlowAPI[];
      let message = `Tu servicio de ${data.origentexto} a ${data.destinotexto} se ha renovado y estará visible hasta el ${data?.fvalidez}.`;

      if (data.tipoaviso.id === ID_MULTI_DESTINY) {
        message = `Tu servicio multidestino en ${data.origentexto} se ha renovado y estará visible hasta el ${data?.fvalidez}.`;
      }

      navigate(`${pathname}/mensaje-enviado`, {
        state: { message },
      });
    });
  };

  const handleActiveOrInactive = (item: ListButtons) => {
    if (item?.tipoestado === TypeStateNoticeEnum.deactivate) {
      setDataModal(modalTextData.deactivate);
      setActionAfterAgreeModal({
        param: item,
        action: handleActiveOrInactiveActions,
      });
      setShowAlert(true);
    } else {
      handleActiveOrInactiveActions(item, CONFIRMATION_PATH);
    }
  };

  const handleActionAccepteddModal = () => {
    handleCloseModal();
    if (!actionAfterAgreeModal) return;
    const { action, param } = actionAfterAgreeModal;

    action(param);
  };

  const handleActiveOrInactiveActions = (item: ListButtons, path?: string) => {
    const nextRute = path || PROFILE_NOTICE_OFF;
    const idNotice = notice?.id;

    dispatch(
      changeStatusNoticeThunk(idNotice, { tipoestado: item.tipoestado })
    ).then(([data]) => {
      const message = handleMesasgeSuccess(data, !!path);
      navigate(nextRute, { replace: true, state: { ...message } });
    });
  };

  const handleMesasgeSuccess = (
    { fvalidez }: NoticeResponseSaveFlowAPI,
    hasMessage: boolean
  ) => {
    if (!fvalidez || !hasMessage) return {};

    const validDate =
      stringDDMMYYYToDate(fvalidez).format(FORMAT_DATE_DDMMYYYY);

    return {
      message: `Tu aviso está visible nuevamente hasta el ${validDate}.`,
      nextRute: pathname.replace("mis-avisos", "avisos"),
    };
  };

  const handlePrepareNextRouter = (rute: string) => {
    const newPath = rute.replace(":id", notice?.id?.toString());
    if (Object.keys(user).length) {
      return newPath;
    } else {
      return `${AUTH_LOGIN}?returnPath=${newPath
        .split("/")
        .slice(0, -1)
        .join("/")}`;
    }
  };

  const handleNavigate = (item: ListButtons) => {
    if (!!item.idAction && item.idAction >= btnPublicNumber["ACEPTAR"]) {
      handleOffertActions(item);
      return;
    }
    if (
      !!item.idAction &&
      item.idAction === btnPrivateNumber["RENOVAR"] &&
      !notice?.fechafija
    ) {
      handleRenewActions();
      return;
    }
    if (
      !!item.idAction &&
      item.idAction === btnPrivateNumber["DESACTIVAR"] &&
      item.tipoestado
    ) {
      // o activar
      handleActiveOrInactive(item);
      return;
    }
    setLoadingEdit(true);

    dispatch(getOffertActiveByIdThunk(notice?.id))
      .then((ofertasActivas) => {
        if (ofertasActivas > 0) {
          handleOpenModalWithEdit(item);
        } else {
          handleGoEdit(item);
        }
        setLoadingEdit(false);
      })
      .catch(() => {
        setLoadingEdit(false);
      });
  };

  const handleOpenModalWithEdit = (item: ListButtons) => {
    setDataModal(modalTextData.edit);
    setActionAfterAgreeModal({ param: item, action: handleGoEdit });
    setShowAlert(true);
  };

  const handleGoEdit = (item: ListButtons) => {
    let path = item.link || "";
    if (item.concatLink) {
      path = `${pathname}${item.link}`;
    }
    if (Object.keys(user).length) {
      navigate(path);
    } else {
      navigate(
        `${AUTH_LOGIN}?returnPath=${path.split("/").slice(0, -1).join("/")}`
      );
    }
  };

  const ActiveOrDisableButton = (item: ListButtons) => {
    if (!item.flg) return true;
    const flg = notice?.tipoestado[item.flg];
    const isOwner = notice?.transportista?.id === (user as UsuarioAPI).id;
    return flg === 0 || (isOwner && pathname.startsWith(SERVICES_LANDING_PATH));
  };

  const showOrHideButton = (item: ListButtons) => {
    const showFlg = notice?.tipoestado[item.showFlg];

    return showFlg === 1; // 0 = desactivado 1 = activado
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  const toggleLoadingIsEdit = (item: ListButtons): boolean => {
    if (item.idAction === btnPrivateNumber["EDITAR"]) {
      return loadingEdit;
    }
    return false;
  };

  return (
    <Container>
      <BannerModal
        open={showAlert}
        onClose={handleCloseModal}
        title={dataModal.title}
        content={dataModal.content}
        option1={{
          title: dataModal.option1Title,
          onClick: handleCloseModal,
        }}
        option2={{
          title: dataModal.option2Title,
          onClick: handleActionAccepteddModal,
        }}
        color="org"
        sx={{
          width: "322px",
          height: "292px",
        }}
        sxAccion={{
          padding: "0px",
        }}
      />
      {!isLoading &&
        listButtons.map(
          (item, i) =>
            showOrHideButton(item) && (
              <ButtomLoader
                disabled={ActiveOrDisableButton(item)}
                variant={item.variant}
                key={i}
                onClick={() => handleNavigate(item)}
                tipoAvisoId={tipoAvisoId}
                mundanzaTexto={mundanzaTexto}
                contraofertaTexto={contraofertaTexto}
                isLoading={toggleLoadingIsEdit(item)}
              >
                {item.title}
              </ButtomLoader>
            )
        )}
    </Container>
  );
};

export default ButtonsService;
