import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import SwitchControl from "@/components/form/switchControl/SwitchControl";
import HeaderSection from "@/components/headerSection/HeaderSection";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import { Container, Stack } from "@mui/material";
import { ContainerArea, SubTitleConditions } from "./noticeConditionsStyles";
import { useCondition } from "./useCondition";

const ConditionComponent = () => {

  const  {
    headerText,
    handleGoBack,
    listConditions,
    handleChange,
    handleClick,
  } = useCondition()
 

  return (
    <Container maxWidth="xs">
      <HeaderSection
        icon="icon-arrow-left--primary"
        title={headerText.title}
        subTitle={headerText.subTitle}
        onClick={handleGoBack}
      />

      <ContainerArea>
        <SectionIcon icon="icon-document--blue" />
        <Stack gap="4px">
          <SubTitleConditions>Recuerda que tu servicio incluye:</SubTitleConditions>
          {!!listConditions.length &&
            listConditions.map(({
              condicionesservicio,
              pordefecto,
              flgver,
            }) => (
              <>
                <SwitchControl
                  text={condicionesservicio?.nombre}
                  onChange={(isActive) => handleChange(condicionesservicio?.id, isActive)}
                  value={!!pordefecto}
                  hideSwitch={!!flgver}
                  pointHidden={true}
                />
              </>
            ))}
        </Stack>
        <ButtomLoader
          sx={{ marginTop: "24px" }}
          onClick={handleClick}
        >
          Siguiente
        </ButtomLoader>
      </ContainerArea>
    </Container>
  );
};

export default ConditionComponent