import { TEXT_ERROR_MAX_LOAD, TEXT_ERROR_MIN_LOAD } from "@/consts/textErrors";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { DirtyTypeProps, EventType } from "@/interfaces/globalInterface";
import { ISelectedVehicle } from "@/store/userRedux/userReduxInterface";
import { setSelectedVehicle } from "@/store/userRedux/userSlice";
import { cleanValueNumber } from "@/utils/helpers";
import { FormControl, Grid, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import ButtomLoader from "../buttonLoader/ButtomLoader";
import { DetailsCoreModel } from "@/models/notice/noticeModels";
import { SelectControl } from "@/pages/noticeV2/components/selectControl/SelectControl";
import Input from "@/components/inputCustom/Input";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";

const Container = styled(Grid)(
  () => `
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    gap: 16px;
  `
);

type Props = {
  onSubmit?: () => void;
  Refrige: boolean;
  loading?: boolean;
  buttonText?: string;
  switchText?: string;
  onDirtyFields?: (dirtyFields: DirtyTypeProps) => void;
  data?: DetailsCoreModel;
  showKilos?: boolean;
  maxWeigth?: string;
  isEdition?: boolean;
};

const FormCreateService = ({
  onSubmit,
  loading,
  buttonText = "SIGUIENTE",
  onDirtyFields,
  data,
  showKilos = false,
  maxWeigth,
  isEdition,
}: Props) => {
  const { vehicles, selectedVehicle } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const [isRefrigeratedLoad, setIsRefrigeratedLoad] = useState(0);
  const [maximumLoad, setMaximumLoad] = useState("");
  const [error, setError] = useState("");
  const [dirtyFields, setDirtyFields] = useState<DirtyTypeProps>({
    selectVehicle: { dirty: false },
  });

  const { cargamaxima, flgrefrigerado, ...selectRest } =
    selectedVehicle as ISelectedVehicle;

  useEffect(() => {
    if (!!maxWeigth) {
      setMaximumLoad(maxWeigth as string);
    } else {
    
      const value = !!cargamaxima ? cargamaxima.toString() : ""
      setMaximumLoad(value);
    }
  }, []);

  useEffect(() => {
    if (!!Object.keys(selectedVehicle).length) {
      setIsRefrigeratedLoad(flgrefrigerado);
    }
  }, [selectedVehicle, cargamaxima, flgrefrigerado]);

  useEffect(() => {
    onDirtyFields && onDirtyFields(dirtyFields);
  }, [dirtyFields]);

  const findSelectedVehicle = (id: number): number => {
    const data = vehicles.find((vehicle) => vehicle?.id === id);

    return data?.cargamaxima || cargamaxima;
  };

  /**
   * Una función que maneja el cambio del valor de entrada.
   * @param  - ChangeEvent<HTMLInputElement>
   */
  const handleOnchange = ({ currentTarget }: EventType["change"]) => {
    const { value, name } = currentTarget;
    if (!!error.length) {
      if (
        Number(value) > 10 ||
        Number(value) < findSelectedVehicle(selectRest?.id || 0)
      ) {
        setError("");
      }
    }

    /* Evitar que el usuario ingrese más de 5 caracteres. */
    if (value.length > 5) return;

    setDirtyFields({ ...dirtyFields, [name]: { dirty: true } });

    const cleanValue = cleanValueNumber(value);

    setMaximumLoad(cleanValue);
  };

  const handleChangeRefrigerate = (isRefrigerate: number) => {
    setIsRefrigeratedLoad(isRefrigerate);

    setDirtyFields({ ...dirtyFields, isRefrigeratedLoad: { dirty: true } });
  };

  const handleChangeSelect = () => {
    setDirtyFields({ ...dirtyFields, selectVehicle: { dirty: true } });
  };

  /**
   * Establece la carga máxima y la bandera de carga refrigerada en el vehículo seleccionado
   * @returns Se devuelve la función handleSubmit.
   */
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (loading) return;

    const currentMaximumLoad = Number(maximumLoad);

    if (!isEdition) {
      if (
        currentMaximumLoad < 10 ||
        currentMaximumLoad > findSelectedVehicle(selectRest?.id || 0)
      ) {
        setError(handleGetTextError(currentMaximumLoad));
        return;
      }
    }

    dispatch(
      setSelectedVehicle({
        ...selectedVehicle,
        cargamaxima: Number(maximumLoad),
        flgrefrigerado: isRefrigeratedLoad,
      })
    );
   
    onSubmit && onSubmit();
  };

  /**
   * Si el valor actual es inferior a 10, devuelva la carga mínima de error de texto; de lo contrario,
   * devuelva la carga máxima de error de texto.
   * @param {number} currenValue - número - el valor actual del control deslizante
   * @returns Una función que toma un número y devuelve una cadena.
   */
  const handleGetTextError = (currenValue: number): string => {
    if (currenValue < 10) {
      return TEXT_ERROR_MIN_LOAD;
    }

    return TEXT_ERROR_MAX_LOAD;
  };

 const handleDisableBtn = () => {
  const existVehicle = !!(selectedVehicle as ISelectedVehicle)?.id 
  if(isEdition){
    return loading || !existVehicle
  }

  return !maximumLoad || loading
 }

  return (
    <Container container>
      <form onSubmit={handleSubmit}>
        <FormControl sx={{ width: "100%" }}>
          <SelectControl
            data={data}
            onChange={handleChangeSelect}
            showMaxLoad
          />
        </FormControl>

        {showKilos && (
          <FormControl sx={{ marginTop: "16px", width: "100%" }}>
            <Input
              name="maxWeight"
              label={
                <LabelInputRequired str="¿Cuál es la capacidad disponible a rematar? (kilos)" />
              }
              value={maximumLoad}
              variant="standard"
              placeholder="Kilos que vas a rematar. Ej. 100"
              onChange={handleOnchange}
              onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                inputMode: "numeric",
              }}
              error={!!error}
              helperText={error}
            />
          </FormControl>
        )}

        <Grid item sx={{ marginTop: "8px" }}>
          <ButtomLoader
            type="submit"
            isLoading={loading}
            disabled={handleDisableBtn()}
          >
            {buttonText}
          </ButtomLoader>
        </Grid>
      </form>
    </Container>
  );
};

export default FormCreateService;
