import { ID_COUNTEROFFER, ID_OFFER } from "@/consts/defaultConsts";
import {
  maxLengthPrice,
  maxLengthReference,
  textAceptarContactar,
  textCannotLowerPrice,
  textCannotUpperPrice,
  textContraofertar,
} from "@/consts/noticeConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { NoticeDetailInCityModel } from "@/models/notice/noticeModels";
import {
  getFirstStepNoticeType,
  getFirstStepNoticeTypeInterface,
} from "@/pages/flowNoticeNegotation/shared/adapters/clientAcceptOrCounterOfferAdapter";
import { NoticeSendData } from "@/store/noticeV2ClientAcceptCounterOffer/interfaces/sendDataInterfacesEnglish";
import {
  fillSendDataNoticeThunk,
  postDataNoticeThunk,
} from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferThunk";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { stringDDMMYYYToDate, validateValueInput } from "@/utils/helpers";
import dayjs, { Dayjs } from "dayjs";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { configFormOffert } from "../configData";

const minDate = dayjs().add(1, "day");

interface IFormDatos
  extends Omit<
    NoticeSendData,
    "cargoDescription" | "verifiedPhotosId" | "date"
  > {
  date: Dayjs | null;
  price?: number;
}

const useRuteInCity = () => {
  const {
    noticeV2ClientAorCO: {
      sendData: { inTheCity },
      selectTypeNotice,
      idTypeNoticeNegotiation,
      selectDate,
    },
  } = useAppSelector((state) => state);

  const notice = selectTypeNotice as NoticeDetailInCityModel;
  const isCounterOffer = idTypeNoticeNegotiation === ID_COUNTEROFFER;
  const { origin, price } = configFormOffert;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [errorDate, setErrorDate] = useState("");
  const [errorPrice, setErrorPrice] = useState("");
  const tipoAvisoId = notice?.tipoaviso?.id;
  const [form, setForm] = useState<IFormDatos>(preloadData());

  useEffect(() => {
    if (form) {
      const ruteToSendData = {
        key: getFirstStepNoticeType(tipoAvisoId),
        value: getFirstStepNoticeTypeInterface(tipoAvisoId, {
          ...inTheCity,
          ...form,
        }),
      };
      const values = {
        data: ruteToSendData,
        tipoAvisoId,
        activeLoading: false,
        noExecuteImg: true,
      };

      const handler = setTimeout(() => {
        dispatch(fillSendDataNoticeThunk(values));
      }, 1000);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [form]);

  function preloadData() {
    const initialDate = selectDate ? stringDDMMYYYToDate(selectDate) : minDate;
    return {
      negotiationTypeId: isCounterOffer ? ID_COUNTEROFFER : ID_OFFER,
      originId: inTheCity?.originId || notice.idorigen || "",
      destinyId: inTheCity?.destinyId || notice.iddestino || "",
      originReference: inTheCity?.originReference || "",
      destinyReference: inTheCity?.destinyReference || "",
      date: initialDate,
      price: inTheCity?.price || "",
    };
  }

  const handleOnChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = target;
    setErrorPrice("")
    const newValue = validateValueInput(value);

    if (name === origin.input2.name) {
      value.length <= maxLengthReference &&
        setForm({ ...form, originReference: newValue });
    } else if (name === price.input.name) {
      value.length <= maxLengthPrice &&
        setForm({ ...form, price: Number(newValue) });
    } else {
      value.length <= maxLengthReference &&
        setForm({ ...form, destinyReference: newValue });
    }
  };

  const handleSubmit = () => {
    if (handleDisabled()) return;

    let data = {
      ...inTheCity,
      ...form,
    };

    if (!isCounterOffer) delete data.price;

    if (isCounterOffer && Number(form?.price) < Number(notice?.precio) / 2) {
      setErrorPrice(textCannotLowerPrice);
      return;
    }

    if(Number(form?.price) > Number(notice?.precio) * 3){
      setErrorPrice(textCannotUpperPrice);
      return;
    }

    dispatch(postDataNoticeThunk(data, `${notice.id}`)).then(() => {
      handleNextRute();
    });
  };

  const handleNextRute = () => {
    dispatch(
      getNextRoute(navigate, {
        valueToReplace: notice.id?.toString(),
        valueToSearch: ":id",
      })
    );
  };

  const handleDisabled = () => {
    let conditions = [
      form.date,
      form.originReference,
      form.destinyReference,
    ] as (string | dayjs.Dayjs | null | number | undefined)[];

    if (isCounterOffer) {
      conditions = [
        form.date,
        form.originReference,
        form.destinyReference,
        form.price,
      ];
    }
    return conditions.some((condition) => !condition);
  };

  const handleChangeDate = (value: dayjs.Dayjs | null) => {
    setForm({ ...form, date: value });
    !!errorDate && setErrorDate("");
  };

  const handleErrorDate = (error: string) => {
    !!error && setErrorDate("Fecha invalida");
  };

  const handleTextButton = () => {
    let textToRender = textAceptarContactar;
    if (isCounterOffer) {
      textToRender = textContraofertar;
    }
    return textToRender;
  };

  return {
    notice,
    isCounterOffer,
    errorPrice,
    form,
    errorDate,
    setForm,
    handleOnChange,
    handleSubmit,
    handleTextButton,
    handleErrorDate,
    handleChangeDate,
    handleDisabled,
  };
};

export default useRuteInCity;
