import { Container, Grid, styled } from '@mui/material';

export const ContainerVehicle = styled(Container)(
  () => `
      padding: 16px 0;
    `
);

export const ContainerForm = styled(Grid)(
  () => `
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      gap: 16px;
    `
);
