import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import { useHandleCounterOffer } from "@/pages/noticeV2Negotation/shared/hooks/useHandleCounterOffer";
import { CustomCardV2 } from "@/pages/noticeV2Negotation/shared/styles";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { currencyFormat } from "@/utils/helpers";
import { Box, FormControl, Grid, TextField, Typography } from "@mui/material";
import { handleTypeMultidestinyOfferNotice } from "../../../counterOfferDetails/containers/MultidestinyNoticeV2Neg/components/config";

const CardPriceInput = () => {
  const { isLoadingNoticeV2, notice } = useGetData();
  const data = notice as NoticeV2NegType;
  const {
    handleOnChange,
    handleDisabled,
    valueInput,
    errorPrice,
    handleSubmit,
  } = useHandleCounterOffer();

  const noticeDataTwo = handleTypeMultidestinyOfferNotice(data);

  const totalPriceCounterOffer =
    Number(valueInput) * Number(noticeDataTwo.amount);
  const typeMultiCapitalize =
    noticeDataTwo.type.charAt(0).toUpperCase() + noticeDataTwo.type.slice(1);

  return (
    <>
      <CustomCardV2 sx={{ mt: 2 }}>
        <Typography sx={{ fontWeight: 600 }}>Contraoferta</Typography>
        <Grid container>
          <Grid xs={4}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ fontSize: "12px" }}>
                {`Por ${noticeDataTwo.type} (S/)`}
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  hiddenLabel
                  id="filled-hidden-label-small"
                  variant="standard"
                  size="small"
                  placeholder="Tu precio"
                  sx={{
                    width: "80px",
                    input: {
                      "&::placeholder": {
                        color: "#bdbdbd",
                      },
                    },
                  }}
                  value={valueInput}
                  onChange={handleOnChange}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: 5,
                  }}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid xs={4}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ fontSize: "12px" }}>
                {`# ${typeMultiCapitalize}s`}
              </Typography>
              <Typography sx={{ fontSize: "14px", color: "#757575" }}>
                {noticeDataTwo.amount}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={4}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ fontSize: "12px" }}>Precio total</Typography>
              <Typography sx={{ fontSize: "14px", color: "#757575" }}>
                {!!totalPriceCounterOffer
                  ? currencyFormat(
                      Number(Number(valueInput) * Number(noticeDataTwo.amount))
                    )
                  : "-"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CustomCardV2>
      {!!errorPrice && (
        <Typography sx={{ color: "#D32F2F", fontSize: "12px", mt: 1 }}>
          {errorPrice}
        </Typography>
      )}
      <ButtomLoader
        fullWidth
        variant="contained"
        isLoading={isLoadingNoticeV2}
        onClick={handleSubmit}
        disabled={handleDisabled() || isLoadingNoticeV2}
      >
        CONTRAOFERTAR
      </ButtomLoader>
    </>
  );
};

export default CardPriceInput;
