import { CheckIconEmptySvg, CheckIconSvg } from "@/assets/svg";
import { UsuarioAPI } from "@/models/userAuth/user";
import Icon, { IconTypes } from "@/utils/Icon";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { FC } from "react";

export type ListStepsProps = {
  text: string;
  text2?: string;
  icon?: IconTypes;
  link: string;
  isChecked: boolean;
  key: keyof UsuarioAPI;
  keyDepends?: keyof UsuarioAPI;
  disabled?: boolean;
};

type Props = {
  list: ListStepsProps[];
  onClick: (item: ListStepsProps) => void;
  showCheckBox?: boolean;
  register?: boolean;
};

const ListSteps: FC<Props> = ({ list, onClick, showCheckBox = true, register }) => {
  const handleOnClick = (listItem: ListStepsProps) => {
    onClick && onClick(listItem);
  };


  const getRenderIcon = (listItem: ListStepsProps): JSX.Element => {
    if (!!register) { return listItem.isChecked ? <CheckIconSvg /> : <CheckIconEmptySvg /> }
    return <Icon name={listItem.icon as IconTypes} size="md" />
  }

  return (
    <>
      <List>
        {list.map((listItem: ListStepsProps, i: number) => (
          <ListItem
            disablePadding
            key={i}
            sx={{
              ".css-cveggr-MuiListItemIcon-root": {
                minWidth: "5px",
              },
            }}
            disableGutters
          >
            <ListItemButton
              onClick={() => handleOnClick(listItem)}
              sx={{
                margin: "0px 0px 16px 0px",
                height: listItem?.isChecked ? "70px" : "56px",
              }}
              disabled={listItem?.disabled}
            >
              <ListItemIcon
                sx={{
                  minWidth: "40px",
                }}
              >
                {getRenderIcon(listItem)}
              </ListItemIcon>
              <ListItemText
                primary={listItem.text}
                secondary={listItem?.isChecked ? "Completo" : null}
                secondaryTypographyProps={{
                  color: "success.main",
                  fontSize: "14px",
                }}
                primaryTypographyProps={{
                  fontSize: "16px",
                }}
              />
              {!listItem?.isChecked && (
                <ArrowForwardIosIcon
                  sx={{
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                />
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default ListSteps;
