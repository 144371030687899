import { FC } from "react";

const DynamicComponent: FC<any> = ({ children, ...configData }) => {
  if (!configData) return <></>;

  const CustomComponent = configData.Component;

  if (children) {
    return <CustomComponent {...configData.props}>{children}</CustomComponent>;
  }

  return <CustomComponent {...configData.props} />;
};

export default DynamicComponent;
