import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IInputsPlace, INoticeInitialState, ISendDataPayload } from "./noticeV2types";


const initialState: INoticeInitialState = {
    noticeList: [],
    selectNotice: {},
    sendData: {
        inTheCity: {},
        multiDestiny: {},
        betweenCities: {},
        auctionBetweenCities: {},
        moveInTheCity: {},
        moveBetweenCities: {},
    },
    echoResponse: {},
    inputsPlace: {
        city: '',
        origin: '',
        destiny: '',
        timeService: '',
    },
    districtsSuggested: {},
    citiesSuggested: {},
    isLoading: false,
    error: null,
    variantMoving: null,
    noticesDuplicate: [],
};  

export const noticeV2Slice = createSlice({
    name: "noticeV2",
    initialState,
    reducers: {
        setIsLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
        setError: (state, { payload }: PayloadAction<any>) => {
            state.error = payload?.message || payload;
            state.isLoading = false;
          },
        setNoticeList: (state, { payload }) => {
            state.noticeList = payload;
        },
        setSelectNotice: (state, { payload }) => {
            state.selectNotice = payload;
        },
        // llenar el sendData
        fillSendData: (state, { payload }: { payload: ISendDataPayload }) => {
            if(state.sendData[payload.key] === undefined) return;
            state.sendData[payload.key] = payload.value;
        },
        resetSendData: (state) => {
            state.sendData = initialState.sendData;
            state.inputsPlace = initialState.inputsPlace;
            state.error = initialState.error;
            state.variantMoving = initialState.variantMoving;
            state.noticesDuplicate = initialState.noticesDuplicate;
        },
        setEchoResponse: (state, { payload }) => {
            state.echoResponse = payload;
        },
        setNoticesDuplicate: (state, { payload }) => {
            state.noticesDuplicate = payload;
        },
        setResetErrorDuplicated: (state) => {
            state.error = null;
            state.noticesDuplicate = [];
        },
        setInputsPlace: (state, { payload }: {
            payload: IInputsPlace;
        } ) => {
            state.inputsPlace= payload;
        },
        setDistrictsSuggested: (state, { payload }) => {
            state.districtsSuggested = payload;
        },
        setCitiesSuggested: (state, { payload }) => {
            state.citiesSuggested = payload;
        },
        setVariantMoving: (state, { payload }) => {
            state.variantMoving = payload;
        }

    }
});

export const { setNoticeList, setSelectNotice, fillSendData, resetSendData, setEchoResponse, setInputsPlace, setDistrictsSuggested, setCitiesSuggested,
    setError,
    setIsLoading,
    setVariantMoving,
    setNoticesDuplicate,
    setResetErrorDuplicated,
} = noticeV2Slice.actions;
