import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { defaultError } from "@/pages/noticeRequestFlow/consts/noticeRequestFlowConsts";
import { setError } from "@/store/noticeNegotationRequestRedux/noticeNegRequestSlice";
import { useEffect } from "react";
import { useGlobalButtons } from "../hooks/useGlobalButtons";

import { ButtonAccept, ContainerButtons } from "./StylesGlobalButtonsNotifications";
  const isQuote = true
const QuoteGlobalButons = () => {

  const { handleManageSnackbar, handleQuoteButton, handleManageModalOffer, handleDisabledButtons } = useGlobalButtons(isQuote);
  const { notiffication } = useNotiffication();
  const dispatch = useAppDispatch();
  const { error, isLoading } = useAppSelector((state) => state.offert);
  const disableButtons = handleDisabledButtons();

  useEffect(() => {
    if (error) {
      handleManageSnackbar();
    }
  }, [error]);

  useEffect(() => {
    if (!notiffication) {
      dispatch(setError(defaultError));
    }
  }, [notiffication]);

  return (
    <ContainerButtons>
      <ButtonAccept disableRipple fullWidth variant="contained" onClick={handleQuoteButton} disabled={!disableButtons.disableAcceptButton || isLoading}>
        COTIZAR
      </ButtonAccept>

      <ButtomLoader
      fullWidth variant="text"
        type="submit"
        isLoading={isLoading}
        onClick={handleManageModalOffer}
        disabled={!disableButtons.disableDeclineButton || isLoading}
      >
        RECHAZAR
      </ButtomLoader>

    </ContainerButtons>
  );
};

export default QuoteGlobalButons;
