import DetailsTextV2 from '@/components/DetailsTextV2/Index';
import { districtsDeliveryText } from '@/consts/noticeConsts';
import { ConfigDataNegotation } from '@/pages/noticeRequestFlow/interface/noticeNegRequestInterface';
import { ImagesNegV2 } from '@/pages/noticeV2Negotation/components/ImagesNegV2';
import {
  getDescriptionLoadRequestNeg,
  getFormatSendDateRequestNeg,
  getImagesRequestNeg,
  getMaxWeightVehicleRequestNeg,
  getValueInOffertRequestNeg,
  getVehicleValueNotificationRequestNeg,
  getVehicleValuesRequestNeg,
} from '@/pages/requestNegotiation/shared/utils/RequestNegotiationsUtils';
import { Typography } from '@mui/material';

export const multidestinySuccessConfig: ConfigDataNegotation[] = [
  {
    Component: Typography,
    props: {
      title: 'Detalles del servicio',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha',
      originDescription: '',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getValueInOffertRequestNeg,
        callbakcParams: ['solicitud', 'fecha'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getFormatSendDateRequestNeg,
        callbakcParams: ['solicitud', 'fecha'],
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-light',
      title: districtsDeliveryText,
      isMultidestiny: true,
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getValueInOffertRequestNeg,
        callbakcParams: ['solicitud', 'origenTexto'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getValueInOffertRequestNeg,
        callbakcParams: ['solicitud', 'origenTexto'],
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getVehicleValuesRequestNeg,
        callbakcParams: ['vehiculo'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getVehicleValueNotificationRequestNeg,
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getMaxWeightVehicleRequestNeg,
        callbakcParams: ['solicitud', 'referenciaDestino'],
      },
    ],
  },
  {
    title: 'Descripción de la carga',
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
      sx: { marginTop: '10px', display: 'block' },
    },
  },
  {
    Component: ImagesNegV2,
    props: {
      style: { marginTop: '13px' },
    },
    valid: [
      {
        property: 'fotos',
        componentProp: 'images',
        defaultResult: [],
        callback: getImagesRequestNeg,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      sx: {
        marginTop: '10px',
        display: 'block',
        wordWrap: 'break-word',
        maxWidth: '320px',
        fontWeight: 'none',
      },
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
        callback: getDescriptionLoadRequestNeg,
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-kilos',
      title: 'Peso estimado',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffertRequestNeg,
        callbakcParams: ['solicitud', 'pesoEstimado'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getValueInOffertRequestNeg,
        callbakcParams: ['solicitud', 'pesoEstimado'],
      },
    ],
  },
];
