import IconsSvg from "@/pages/detailNotice/components/IconsSvg";
import Icon, { IconTypes } from "@/utils/Icon";
import { Theme } from "@emotion/react";
import { Stack, styled, SxProps, Typography } from "@mui/material";
import { FC } from "react";

const Title = styled(Typography)(
  ({ theme }) => `
      color: ${theme.palette.text.secondary};
      font-weight: bold;
      margin-left: 8px;
    `
);

const ConatinerDetails = styled(Stack)(
  ({ theme }) => `
    justify-content: space-between;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    font-family: Source Sans Pro;
  `
);

export type DetailsTextProps = {
  show: boolean;
  title: string;
  origen: string;
  icon?: IconTypes;
  constainerSx?: SxProps<Theme>;
  separate?: boolean;
  titleSx?: SxProps<Theme>;
};

const DetailsText: FC<DetailsTextProps> = ({
  show,
  title,
  origen,
  icon,
  constainerSx,
  separate=true,
  titleSx,
}) => {
  if (!show || !origen) return <></>; 

  return (
    <ConatinerDetails sx={constainerSx}>
      <Stack flexDirection="row" alignItems="center">
        {icon && <Icon name={icon} size="md" />}
        <Title variant="label1" sx={{...titleSx, marginLeft: icon ? "8px" : "0" }}>
          {!!title && `${title}${separate ? ":" : ""}`}
        </Title>
      </Stack>

      <Stack flex={1} marginLeft="4px">
        <Typography
          variant="body1"
          sx={{ wordWrap: "break-word", maxWidth: "320px" }}
        >
          {origen}
        </Typography>
      </Stack>
    </ConatinerDetails>
  );
};

export default DetailsText;
