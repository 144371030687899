import HeaderSection from "@/components/headerSection/HeaderSection";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { getPrevRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { Container, styled } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { defaultError, noticeNegotationRequestObjectTypes, noticeOffertNegRequestTypesArea } from "../consts/noticeRequestFlowConsts";
import SnackbarNotification from "@/components/snackbar/Snackbar";
import useNotiffication from "@/hooks/useNotiffication";
import { setError } from "@/store/noticeNegotationRequestRedux/noticeNegRequestSlice";

const ContainerArea = styled(Container)(
  () => `
      padding: 16px 0;
    `
);
const NegOffert = () => {
  const { removeNotiffication, notiffication } = useNotiffication();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    noticeNegotationRequest: { error, selectedNegotationTypeRequest },
  } = useAppSelector((state) => state);

  const { title = "", subTitle = "", icon = "" } = noticeOffertNegRequestTypesArea[selectedNegotationTypeRequest.id || 1];
  const handlePrevRute = () => {
    const valueToReplace = noticeNegotationRequestObjectTypes[selectedNegotationTypeRequest.id];
    dispatch(getPrevRoute(navigate, { valueToSearch: ":typeRequest", valueToReplace }));
    if (!!error) {
      dispatch(setError(defaultError));
    }
    if (error) {
      dispatch(setError(defaultError));
    }
    if (notiffication) {
      removeNotiffication(false);
    }
  };
  return (
    <Container maxWidth="xs">
      <HeaderSection icon="icon-arrow-left--primary" title={title} subTitle={subTitle} onClick={handlePrevRute} />
      <SnackbarNotification />
      <ContainerArea>
        <SectionIcon icon={icon} />
        <Outlet />
      </ContainerArea>
    </Container>
  );
};

export default NegOffert;
