import { getShareTravelServices } from '@/services/shareTravelServices';
import { AppDispatch } from '../store';
import { setError, setSharedRide } from './sharedRideSlice';
import { getMessaggeError } from '../auth/utils';

export const getShareTravelThunk = (travelId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { viajeCompartido } = await getShareTravelServices(travelId);

      dispatch(setSharedRide(viajeCompartido));
      return viajeCompartido;
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error.message));
      throw new Error(error.message || undefined);
    }
  };
};
