import StandardAlert from "@/components/alerts/StandardAlert";
import FormImagesVehicle from "@/components/form/formImagesVehicle/FormImagesVehicle";
import BannerModal from "@/components/modals/BannerModal/BannerModal";
import {
  PROFILE_CREAR_VEHICULO_PATH,
  PROFILE_MIS_VEHICULOS_PATH,
} from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import HeaderProfile from "@/pages/profile/headerProfile/HeaderProfile";
import { IVehicleList } from "@/store/userRedux/userReduxInterface";
import { setErrorMsg, setSuccessMsg } from "@/store/userRedux/userSlice";
import {
  updateVehicleImagesThunk,
  updateVehicleImageThunk,
} from "@/store/userRedux/userThunks";
import { getRejectedReasonsInAllSetteld } from "@/utils/helpers";
import { useEffect, useRef, useState } from "react";
import { To, useLocation, useNavigate } from "react-router-dom";
import {
  changePathName,
  objButtonTitle,
  objTitle,
} from "../profileConfigDataVehicle";
import {
  maxNumberPhotos,
  messageAlertBack,
  messageAlertDefault,
  messageError,
  messagesSuccess,
  requitedPlate,
} from "./photosConst";

interface IBodyPhotosCandE {
  foto: string | File;
  tipo: string;
}

const PhotosCandE = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // validate if the data is empty and show the modal
  const [dataIsEmpty, setDataIsEmpty] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(messageAlertDefault);

  const {
    user: { selectedVehicleList, errorMsg, isLoading },
  } = useAppSelector((state) => state);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const selectedVehicle = selectedVehicleList as IVehicleList;

  useEffect(() => {
    if (errorMsg) {
      timer.current = setTimeout(() => {
        if (!isLoading) {
          dispatch(setErrorMsg(""));
        }
      }, 15000);
    }

    return () => {
      clearTimeout(timer.current as NodeJS.Timeout);
      errorMsg && dispatch(setErrorMsg(""));
    };
  }, [errorMsg]);

  const orderPhotos = async (data: IBodyPhotosCandE[]) => {
    return await ["fotoFrontal", "fotoLateral", "fotoPosterior"].reduce(
      (acc: any, type: any) => {
        if (data.find((item) => type?.toUpperCase()?.includes(item.tipo))) {
          const photo = data.find((item) =>
            type?.toUpperCase()?.includes(item.tipo)
          );
          return {
            ...acc,
            [type]: photo?.foto,
          };
        }
      },
      {}
    );
  };

  const updateVehicleImage = async (foto: string | File, tipo: string) => {
    const id = selectedVehicle?.id.toString();
    const body = { foto, tipo };

    return await dispatch(updateVehicleImageThunk(body, id));
  };

  const handleUploadPhotos = async (
    data: IBodyPhotosCandE[] | []
  ): Promise<PromiseSettledResult<string | Error>[]> => {
  
    const promise = await Promise.allSettled(
      data.map(async (item) => await updateVehicleImage(item.foto, item.tipo))
    ).catch((error) => error);

    return promise;
  };

  const handleHasError = (
    data: IBodyPhotosCandE[],
    isNewPhotosPath: boolean
  ) => {
    const hasVehiclePhotos = selectedVehicle?.vehiculofotos;
    if (
      isNewPhotosPath &&
      data.length !== maxNumberPhotos &&
      !!hasVehiclePhotos
    ) {
      return messageError;
    }

    return "";
  };

  const handleGetMessageSuccess = (
    data: IBodyPhotosCandE[],
    isNewPhotosPath: boolean
  ) => {
    const newData = data[0];
    const typeVehicle = selectedVehicle?.tipovehiculo;
    const isCreation = pathname === PROFILE_CREAR_VEHICULO_PATH[1];
    const hasRequiredPhotos =
      typeVehicle?.flgPlacaObligatoria === requitedPlate;
    const { saveVehicle, updateVehicle, updatePhotos } = messagesSuccess;

    let message = messagesSuccess.updateVehicle;

    if (!isNewPhotosPath && !!newData) {
      const type = newData?.tipo?.toLowerCase();
      message = updatePhotos.replace(":type", type);
    } else if (!hasRequiredPhotos && isCreation) {
      message = isCreation ? saveVehicle : updateVehicle;
    }

    return message;
  };

  const handleSaveAllPhotos = async (data: IBodyPhotosCandE[]) => {
    const id = selectedVehicle?.id.toString();
    const result = await orderPhotos(data);
    const hasError = await dispatch(updateVehicleImagesThunk(result, id)).catch(
      (error: Error) => {
        dispatch(setErrorMsg(error?.message));
        return true;
      }
    );

    return { error: hasError };
  };

  const handleUpdatePhotos = async (data: IBodyPhotosCandE[]) => {
    const result = await handleUploadPhotos(data);
    const [rejectedReason] = getRejectedReasonsInAllSetteld<string | Error>(
      result
    );

    if (rejectedReason) {
      dispatch(setErrorMsg((rejectedReason as Error)?.message));
    }

    return { error: rejectedReason };
  };

  const handleGetInitialPath = (isNewPhotosPath: boolean) => {
    const typeVehicle = selectedVehicle?.tipovehiculo;
    let nextPath = -1 as To;
    if (typeVehicle?.flgPlacaObligatoria === 0) {
      nextPath = PROFILE_MIS_VEHICULOS_PATH;
    } else if (isNewPhotosPath) {
      nextPath = PROFILE_CREAR_VEHICULO_PATH[2];
    }

    return nextPath;
  };

  const onSubmit = async (data: IBodyPhotosCandE[]) => {
    if (isLoading) return;

    const isNewPhotosPath = pathname === PROFILE_CREAR_VEHICULO_PATH[1];
    const vehiculofotos = selectedVehicle?.vehiculofotos;
    const nextPath = handleGetInitialPath(isNewPhotosPath);
    const message = handleGetMessageSuccess(data, isNewPhotosPath);

    const hasError = handleHasError(data, isNewPhotosPath);
    if (hasError) {
      dispatch(setErrorMsg(hasError));
      return;
    }

    if (!!data.length) {
      if (isNewPhotosPath || vehiculofotos?.length < maxNumberPhotos) {
        const { error } = await handleSaveAllPhotos(data);

        if (error) return;
      } else {
        const { error } = await handleUpdatePhotos(data);

        if (error) return;
      }
    }

    dispatch(setSuccessMsg(message));
    navigate(nextPath);
  };

  // dataIsEmpty
  const handleDataIsEmpty = (isEmpty: boolean) => {
    setDataIsEmpty(isEmpty);
  };

  const navigateBack = () => {
    if (dataIsEmpty) {
      setShowAlert(true);
      setMessageAlert(messageAlertBack);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <HeaderProfile
        title={objTitle[changePathName(pathname)]}
        sx={{
          width: "360px",
        }}
        viewIcon={true}
        backAction={navigateBack}
      >
        <BannerModal
          open={showAlert}
          onClose={() => setShowAlert(false)}
          title={messageAlert.title}
          content={messageAlert.message}
          option1={{
            title: messageAlert.cancel,
            onClick: () => setShowAlert(false),
          }}
          option2={{
            title: messageAlert.ok,
            onClick: () => navigate(-1),
          }}
          color="org"
          sx={{
            width: "322px",
            height: "292px",
          }}
          sxAccion={{
            padding: "0px",
          }}
        />
        {errorMsg && !isLoading && (
          <StandardAlert
            sx={{ marginBottom: "16px" }}
            severity="error"
            color="error"
            onClick={() => {
              dispatch(setErrorMsg(""));
            }}
          >
            {errorMsg}
          </StandardAlert>
        )}
        <FormImagesVehicle
          vehicles={selectedVehicle || {}}
          onSubmit={onSubmit}
          titleButton={objButtonTitle[changePathName(pathname)]}
          dataIsEmpty={handleDataIsEmpty}
        />
      </HeaderProfile>
    </>
  );
};

export default PhotosCandE;
