import FormAcceptBetweenCities from './formAcceptBetweenCities/FormAcceptBetweenCities';
import FormAcceptInCity from './formAcceptInCity/FormAcceptInCity';
import FormAcceptMoving from './formAcceptMoving/FormAcceptMoving';
import FormAcceptMultidestiny from './formAcceptMultidestiny/FormAcceptMultidestiny';

export const objectRenderComponent: Record<number, () => JSX.Element> = {
  1: FormAcceptInCity,
  2: FormAcceptMultidestiny,
  3: FormAcceptBetweenCities,
  5: FormAcceptMoving,
};
