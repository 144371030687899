import { UserFormDataRegisterThunk } from '@/pages/user/interfaces/interfaces';
import { SaveFormDataUser } from '../userReduxInterface';
import { ClientFormDataRegister } from '@/components/form/formPerfilTransportista/FormPerfilTransportista';

type actionType = 'register' | 'update';
export const createFormDataUserAdapter = (
  userData: UserFormDataRegisterThunk,
  action: actionType
): SaveFormDataUser => {
  const userNames = getNameAndCompanyName(userData);
  let updateUser = {};
  if (action === 'update') {
    updateUser = {
      foto: userData.foto,
      descripcion: userData.descripcion,
    };
  }
  return {
    distritoId: userData.district.id,
    email: userData.email || '',
    id: userData.id,
    numDocumento: userData.numberDocument,
    tipoDocumento: Number(userData.typeDocument),
    tipoUsuario: userData.tipousuario,
    ...userNames,
    ...updateUser,
  };
};

const getNameAndCompanyName = (
  userData: UserFormDataRegisterThunk | ClientFormDataRegister
) => {
  const typeDocument =
    (userData as UserFormDataRegisterThunk)?.typeDocument ||
    (userData as ClientFormDataRegister)?.flagCompany;
  const isCompany = Number(typeDocument) === 4; // ruc
  const defaultData = {
    nombre: '',
    apellidos: '',
    nombreEmpresa: '',
    nombreyapellidos: '',
  };

  if (isCompany) {
    return {
      ...defaultData,
      nombreEmpresa: userData.name,
      nombreyapellidos: userData.fullname,
    };
  }

  return {
    ...defaultData,
    nombre: userData.name,
    apellidos: userData.fullname,
  };
};

export const createFormDataClientAdapter = (
  body: ClientFormDataRegister
): SaveFormDataUser => {
  const {
    flagCompany,
    district,
    tipousuario: tipoUsuario,
    numberDocument,
  } = body;
  const isCompany = flagCompany === 4;
  const flgEmpresa = isCompany ? 1 : 0;
  const distritoId = district.id;
  const userNames = getNameAndCompanyName(body);
  const formData = {
    flgEmpresa,
    distritoId,
    tipoUsuario,
    ...userNames,
  };
  if (isCompany) {
    return {
      ...formData,
      numDocumento: numberDocument,
      tipoDocumento: 4,
    };
  }
  return formData;
};
