import { NEGOTATION_COUNTEROFFER } from "@/consts/negotationFlowPath";
import { REQUESTS_LANDING_PATH } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import { ConfigDataNegotation } from "@/pages/noticeRequestFlow/interface/noticeNegRequestInterface";
import DynamicComponent from "@/pages/offerts/offertConfirmation/components/DynamicComponent";
import { setIsEdition } from "@/store/noticeNegotationRequestRedux/noticeNegRequestSlice";
import { useEffect, useId, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { ConfigurationToRenderV2 } from "../config";
import { CounterOfferLayout } from "./CounterOfferLayout";
import { dataNogotationAdapter } from "./counterOfferAdapter";

interface LocationState {
  hasBackNavigate?: boolean;
  hasBackLinkOffert?: boolean;
}

const CounterOfferResponse = () => {
  const currentId = useId();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const locationState = location.state as LocationState;
  const {
    noticeNegotationRequest: { savedDataNogotation },
    user: { user },
  } = useAppSelector((state) => state);
  const [hasBackNavigate, setHasBackNavigate] = useState(false);
  const [hasBackLinkOffert, setHasBackLinkOffert] = useState(false);
  const textTypeNeg = pathname.includes(NEGOTATION_COUNTEROFFER)
    ? "contraoferta"
    : "oferta";
  const isDriver = pathname.startsWith(REQUESTS_LANDING_PATH);
  const {
    validSnackbarDriver,
    validSnackbarClient,
    tipoSolicitud,
    tipoSolicitudNegociacionId,
  } = dataNogotationAdapter(savedDataNogotation as SolicitudNegociacion);

  const textStatusAlert: Record<number, string> = {
    1: `El cliente aún no responde tu ${textTypeNeg}`,
    2: `El transportista aún no responde tu ${textTypeNeg}`,
  };

  useEffect(() => {
    dispatch(setIsEdition(false));
    if (locationState?.hasBackNavigate && !locationState?.hasBackLinkOffert) {
      setHasBackNavigate(true);
    }

    if (locationState?.hasBackLinkOffert && !locationState?.hasBackNavigate) {
      setHasBackLinkOffert(true);
    }
  }, []);

  const handleGeneralConfigData = () => {
    if (
      !!tipoSolicitud &&
      !!tipoSolicitudNegociacionId &&
      savedDataNogotation
    ) {
      const value =
        ConfigurationToRenderV2[tipoSolicitud as number][
          tipoSolicitudNegociacionId as number
        ];

      return value;
    } else {
      return [];
    }
  };

  const valueArray = useMemo(
    () => handleGeneralConfigData(),
    [savedDataNogotation]
  );

  const handleValid = ({ valid, props }: ConfigDataNegotation) => {
    if (!valid || !savedDataNogotation) return { props };

    const moreProps = valid.reduce((acc, el) => {
      let value = (savedDataNogotation as any)[el.property];

      if (el?.callback) {
        value = el.callback(savedDataNogotation, el?.callbakcParams || user);
      }

      if (typeof el.defaultResult === "boolean") {
        acc = {
          ...acc,
          [el.componentProp]: !!value,
        };
      } else {
        acc = {
          ...acc,
          [el.componentProp]: value || el.defaultResult,
        };
      }
      return acc;
    }, {});

    return { props: { ...props, ...moreProps } };
  };

  const handleInitConfig = (config: ConfigDataNegotation) => {
    let moreProps = handleValid(config);

    return { ...moreProps };
  };

  return (
    <>
      <CounterOfferLayout>
        {valueArray.map((item, i) => (
          <DynamicComponent
            key={`${currentId}-${i}`}
            {...item}
            {...handleInitConfig(item)}
          />
        ))}
      </CounterOfferLayout>
    </>
  );
};

export default CounterOfferResponse;
