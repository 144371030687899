import { ISelectListItems } from '@/components/form/selectControl/SelectControlMulti';
import { MODAL_TYPE } from '@/components/modals/searchModalLocationOffert/configDataModal';
import { IOffertInitialState } from '@/store/offertRedux/offert.interface';

interface ConfigFormOffert {
  origin: Destiny;
  destiny: Destiny;
  price: { input: Input };
}

interface Destiny {
  input1: Input;
  input2: Input;
  modal: Input;
}

interface Input {
  name: string;
  label: string;
  placeholder: string;
}

export const configFormOffert: ConfigFormOffert = {
  origin: {
    input1: {
      name: MODAL_TYPE.origin,
      label: 'Distrito de origen',
      placeholder: 'Distrito de origen',
    },
    input2: {
      name: 'reference-origin',
      label: 'Referencia del origen',
      placeholder: 'Lugar, parque o av cercana a la dirección',
    },
    modal: {
      name: 'search-origin',
      label: 'Origen',
      placeholder: 'Ingresa un origen',
    },
  },
  destiny: {
    input1: {
      name: MODAL_TYPE.destiny,
      label: 'Distrito de destino',
      placeholder: 'Distrito de destino',
    },
    input2: {
      name: 'reference-destiny',
      label: 'Referencia del destino',
      placeholder: 'Lugar, parque o av cercana a la dirección',
    },
    modal: {
      name: 'search-destiny',
      label: 'Destino',
      placeholder: 'Ingresa un destino',
    },
  },
  price: {
    input: {
      name: 'price',
      label: 'Contraoferta (S/)',
      placeholder: 'Precio que ofreces',
    },
  },
};

export const modalTitleConfig: Record<string, string> = {
  origin: 'Selecciona un distrito de origen',
  destiny: 'Selecciona un distrito de destino',
};

export const ID_BETWEEN_CITIES = 3;

// inmuebles:

export const pisoOptions = [
  {
    id: 1,
    nombre: '1',
  },
  {
    id: 2,
    nombre: '2',
  },
];

export const ElevatorOptions = [
  {
    id: 1,
    nombre: 'Si',
  },
  {
    id: 2,
    nombre: 'No',
  },
  {
    id: 3,
    nombre: 'Sólo para algunas cosas',
  },
];

export const configSelectOrInput = (
  offert: IOffertInitialState,
  items: ISelectListItems[]
): ISelectListItems[] => {
  const { idTipoAvisoNegociacion } = offert;
  const res: ISelectListItems[] = [];
  items.forEach((item, i) => {
    if (item.offertType === idTipoAvisoNegociacion) {
      res.push(item);
    }
  });
  if (Number(res[0]?.value) === 0) {
    return [res[0]];
  }

  if (Number(res[0]?.value) === 1) {
    return [res[0]];
  }

  return res;
};

interface IConfigInmueble {
  inmueble: boolean;
  listInmueble: ISelectListItems[];
}

export const adapterConfigInmuble = (
  offert: IOffertInitialState,
  formInmueble: any,
  handleChange: (e: any) => void
): IConfigInmueble => {
  const { idTipoAvisoNegociacion, tipoInmuebles } = offert;
  const { idInmueble, piso, elevador } = formInmueble;
  return {
    inmueble: true,
    listInmueble: configSelectOrInput(offert, [
      {
        type: 'select',
        label: 'Tipo de inmueble',
        options: tipoInmuebles || [],
        required: true,
        value: idInmueble,
        name: 'idInmueble',
        onChange: handleChange,
        offertType: idTipoAvisoNegociacion,
      },
      {
        type: 'select',
        label: 'Piso',
        name: 'piso',
        required: true,
        options: pisoOptions,
        onChange: handleChange,
        value: +piso > 0 ? piso : '',
        offertType: 1,
      },
      {
        type: 'input',
        label: 'Piso',
        name: 'piso',
        placeholder: '# de piso de tu departamento',
        required: true,
        onChange: handleChange,
        value: +piso > 0 ? piso : '',
        offertType: 2,
      },
      {
        type: 'select',
        label: '¿Se puede usar el ascensor para la mudanza?',
        required: true,
        options: ElevatorOptions,
        name: 'elevador',
        value: elevador,
        onChange: handleChange,
        offertType: offert?.idTipoAvisoNegociacion,
      },
    ]),
  };
};
