import { optionOne, optionThree, optionTwo } from "@/consts/noticeConsts";
import {
  nottifficationDeclineMsgV2,
  nottifficationPriceChangedV2,
} from "@/consts/notifficationsMessage";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { UltimaAvisoNegociacionAcciones } from "@/models/notice/noticeV2NegotationModels";
import { saveOfferByIdThunkV2 } from "@/store/noticeV2Negotiation/noticeV2NegotiationThunk";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
  ActionDataLayerV2,
  actionButtonNoticeV2,
  typeNoticeName,
} from "../config";
import { nextUrlFormatV2 } from "../utils/utils";
import { getCo_counterOffertURLV2 } from "../utils/utilsGlobalBtnV2";
import useGetData from "./useGetData";
import { ID_MOVING_FLOW } from "@/consts/defaultConsts";

export const useGlobalButtonsV2 = () => {
  const {
    user: { user },
  } = useAppSelector((state) => state);
  const userId = (user as IUser).id;
  const dispatch = useAppDispatch();
  const { notice, typeNoticeNegotationId } = useGetData();
  const typeNoticeId: number = notice?.idtipoaviso as number;
  const precioactual = notice?.precio;
  const { tipoAvisoNegociacionAccion } =
    notice?.ultimaAvisoNegociacionAcciones as UltimaAvisoNegociacionAcciones;
  const actionId = tipoAvisoNegociacionAccion?.id as number;
  const nameTypeNotice = typeNoticeName[typeNoticeId];
  const { addNotiffication } = useNotiffication();
  const navigate: NavigateFunction = useNavigate();
  const isMoving = notice?.idtipoaviso === ID_MOVING_FLOW;

  const handleManageModalDeclineV2 = (): void => {
    let messageText = nottifficationDeclineMsgV2;

    if (typeNoticeNegotationId === optionTwo)
      messageText = nottifficationDeclineMsgV2;

    addNotiffication({
      ...messageText,
      hiddenSnackbar: true,
      hiddenModal: false,
      onSubmit: async () => {
        handleDeclineButtonV2();
      },
    });
  };

  const handleManageModalPriceChangedV2 = (functionOptional?: () => void) => {
    addNotiffication({
      ...nottifficationPriceChangedV2,
      hiddenSnackbar: true,
      hiddenModal: false,
      onSubmit: async () => {
        functionOptional && functionOptional();
      },
    });
  };

  const handleAcceptButtonV2 = () => {
    let actionsButton = actionButtonNoticeV2[actionId][optionOne];

    const data = {
      idOffer: notice?.id as number,
      idAccion: actionsButton,
      precioactual,
    };

    dispatch(saveOfferByIdThunkV2(data)).then((value) => {
      const dataLayerFunction = ActionDataLayerV2[notice?.idTipoAvisoNegociacion as number][optionOne];
      dataLayerFunction(userId, nameTypeNotice as string);

       if (!!value.flgpreciocambiado) {
        handleManageModalPriceChangedV2();
      } else {
        navigate(
          nextUrlFormatV2(`${notice?.id as number}`, actionId, optionOne),
          {
            replace: true,
          }
        );
      } 
    });
  };

  const handleDriverCounterOfferButtonV2 = (): void => {
    let nextRoute = "";

    nextRoute = getCo_counterOffertURLV2(`${notice?.id as number}`);

    navigate(nextRoute);
  };

  const handleDeclineButtonV2 = (): void => {
    const driverCounterOffer = 5;
    let newOption = optionTwo;
    let action = actionId;

    let actionsButton = actionButtonNoticeV2[actionId][optionThree];

    if (actionId === driverCounterOffer) {
      actionsButton = actionButtonNoticeV2[optionThree][optionThree];
    }


    const data = {
      idOffer: notice?.id as number,
      idAccion: actionsButton,
      precioactual,
    };

    if(isMoving) delete data.precioactual
  
    
     dispatch(saveOfferByIdThunkV2(data)).then((value) => {
       if (!!value.flgpreciocambiado) {
        handleManageModalPriceChangedV2();
      } else {
        navigate(
          nextUrlFormatV2(`${notice?.id as number}`, action, newOption),
          { replace: true }
        );
      } 
    }); 
  };

  return {
    handleAcceptButtonV2,
    handleManageModalDeclineV2,
    handleDriverCounterOfferButtonV2,
    handleManageModalPriceChangedV2,
  };
};
