import StandardAlert from "@/components/alerts/StandardAlert";
import DropZone from "@/components/DrowZone/DropZone";
import SearchModal from "@/components/modals/searchModal/SearchModal";
import LabelRequired from "@/components/utils/LabelRequired";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { UsuarioAPI } from "@/models/userAuth/user";
import {
  configByTypeDocument,
  modalProps,
} from "@/pages/user/consts/configDataClients";
import { getListTypeUserThunk } from "@/store/auth/authUserThunks";
import { getDistrictByTermThunk } from "@/store/locationRedux/districtsThunks";
import { IUser } from "@/store/userRedux/userReduxInterface";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
} from "@mui/material";
import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from "react";
import ButtomLoader from "../buttonLoader/ButtomLoader";
import { Label } from "../selectControl/styleSelectControl";
import {
  CLIENT_TYPE,
  IMG_PATH,
  MD_IMG,
  typesDocuments,
} from "@/consts/defaultConsts";
import { resizePhoto } from "@/utils/helpers";
import Input from "@/components/inputCustom/Input";
import { validEmail } from "@/utils/inputsUtils";

const ContainerRow = styled(Stack)(
  () => `
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: space-between;
      margin-top: 16px
    `
);

const Form = styled("form")(
  () => `
      display: flex;
      flex-direction: column;
    `
);

interface District {
  id: number;
  nombre: string;
}
export interface UserFormDataRegisterBase {
  typeDocument: string;
  numberDocument: string;
  name: string;
  district: District;
  email: string;
}

export interface UserFormDataRegisterThunk extends UserFormDataRegisterBase {
  tipousuario: string;
  id: number;
  fullname: string;
}

// Body del registro del usuario tipo cliente!!
export interface ClientFormDataRegister {
  tipousuario: string;
  id?: number;
  fullname: string;
  district: District;
  name: string;
  flagCompany: number;
  numberDocument: string;
}

const initialsValues = {
  typeDocument: "0",
  numberDocument: "",
  name: "",
  fullname: "",
  district: {
    id: 0,
    nombre: "",
  },
  email: "",
};

interface IFormPerfilTransportista {
  disabledTypeDocument?: boolean;
  disabledNumberDocument?: boolean;
  onSubmit?: (data: any) => void;
  dataIsEmpty?: (isEmpty: boolean) => void;
}

const FormPerfilTransportista = ({
  disabledTypeDocument = false,
  disabledNumberDocument = false,
  onSubmit,
  dataIsEmpty,
}: IFormPerfilTransportista) => {
  const [image, setImage] = useState<string | File>("");
  const [description, setDescription] = useState("");
  const [hasError, setHasError] = useState(false);
  const [messageError, setMessageError] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(initialsValues);
  const [errormsg, setErrormsg] = useState({
    numberDocument: "",
    email: "",
  });
  const {
    auth: { typeUser },
    user: { user, error, isLoading },
    districts: { districts },
  } = useAppSelector(({ auth, user, districts }) => ({
    auth,
    user,
    districts,
  }));
  const dispatch = useAppDispatch();
  const currentUser = user as UsuarioAPI;
  const CONFIG_DATA = configByTypeDocument[Number(formData.typeDocument)];
  const photoImg =
    resizePhoto({
      photo: IMG_PATH + currentUser.perfilUrl,
      newFormat: MD_IMG,
    }) || IMG_PATH + currentUser.perfilUrl;

  useEffect(() => {
    if (!typeUser.length) {
      dispatch(getListTypeUserThunk());
    }

    handleLoadInitData();
  }, []);

  const handleLoadInitData = () => {
    if (currentUser) {
      const {
        tipoDocumento,
        numDocumento = "",
        nombreEmpresa = "",
        nombreyapellidos = "",
        email = "",
        nombres = "",
        apellidos = "",
        distrito,
      } = currentUser;

      const typeDocument = tipoDocumento?.id?.toString() || "";
      const updatedFormData = {
        ...formData,
        typeDocument,
        numberDocument: numDocumento,
        name: nombreEmpresa,
        fullname: nombreyapellidos,
        district: {
          id: distrito?.id || 0,
          nombre: distrito?.nombre || "",
        },
        email,
      };

      if (typeDocument !== typesDocuments.RUC.id.toString()) {
        updatedFormData.name = nombres;
        updatedFormData.fullname = apellidos;
        updatedFormData.typeDocument = typeDocument;
      }

      setFormData(updatedFormData);
      setDescription(currentUser.descripcion || "");
    }
  };

  const handleActiveModalCity = () => {
    setShowModal(true);
  };

  const handleOnchange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = currentTarget;
    const { validates } = CONFIG_DATA[name];
    if (validates?.maxLength && value.length > validates.maxLength) {
      return;
    }
    setErrormsg({ email: "", numberDocument: "" });
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleOnChangeSelect = ({ target }: SelectChangeEvent<string>) => {
    const { value, name } = target;

    setFormData(() => ({ ...initialsValues, [name]: value }));
  };

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { id, tipousuario } = user as IUser;
    const body = {
      ...formData,
      id,
      tipousuario,
      descripcion: description,
      foto: image ? image : null,
    };

    if (body.email && !validEmail(body.email)) {
      setErrormsg({
        ...errormsg,
        email: "Ingresa un correo válido. Ej: tucorreo@correo.com",
      });
      return;
    }

    onSubmit && onSubmit(body);
  };

  const handleOnChangeModal = (district: string) => {
    if (!!district.trim().length && showModal && district.length >= 3) {
      dispatch(getDistrictByTermThunk(district));
    }
  };

  const handleSelectedDistrict = (district: any) => {
    setFormData((prev) => ({ ...prev, district }));
    setShowModal(false);
  };

  const handleDisabled = () => {
    const { district, numberDocument, email, ...rest } = formData;
    let rules: any = { numberDocument, ...rest };
    if (currentUser.tipousuario === CLIENT_TYPE && !numberDocument) {
      rules = rest;
    }

    return (
      district.id <= 0 ||
      Object.values(rules).some((value: any) => !value.length) ||
      isLoading
    );
  };

  const handleGetSearchModalProps = () => {
    if (formData.typeDocument === typesDocuments.RUC.id.toString()) {
      return modalProps.company;
    }

    return modalProps.default;
  };

  const searchModalProps = useMemo(
    () => handleGetSearchModalProps(),
    [formData.typeDocument]
  );

  const handleOnchangeDesc = ({
    currentTarget,
  }: ChangeEvent<HTMLInputElement>) => {
    const { value } = currentTarget;
    setDescription(value);
    if (hasError) {
      setHasError(false);
      messageError && setMessageError("");
    }
  };

  const handleOnError = (e: Error) => {
    setMessageError(e?.message || "");
    setHasError(true);
  };

  const handleOnSelectImage = (img: string | File) => {
    setImage(img);
    if (hasError) {
      setHasError(false);
      messageError && setMessageError("");
    }
  };

  const handleOnBlur = () => {
    setDescription((prev) => prev.trim());
  };

  // comprobar si se ha modificado el formulario con respecto a los datos del usuario
  const validateForm = (): boolean => {
    const rules = [
      formData.name !== currentUser.nombres,
      formData.fullname !== currentUser.apellidos,
      formData.district.id !== currentUser.distrito?.id,
      formData.email !== currentUser.email,
      description !== currentUser.descripcion,
      image !== currentUser?.perfilUrl,
    ];

    return rules.some((valid) => valid);
  };

  useEffect(() => {
    if (validateForm()) {
      dataIsEmpty && dataIsEmpty(true);
    } else {
      dataIsEmpty && dataIsEmpty(false);
    }
  }, [formData, description, image]);

  return (
    <>
      <>
        {hasError && (
          <StandardAlert
            sx={{ marginBottom: "16px" }}
            severity="error"
            color="error"
          >
            {messageError || error}
          </StandardAlert>
        )}
      </>
      <SearchModal
        open={showModal}
        onClose={(isOpen: boolean) => setShowModal(isOpen)}
        onSubmit={handleSelectedDistrict}
        onChange={handleOnChangeModal}
        list={districts}
        title={searchModalProps.title}
        inputProps={searchModalProps.inputProps}
      />

      <Form onSubmit={handleOnSubmit}>
        <FormControl
          variant="standard"
          sx={{
            width: "100%",
            gap: 2,
            alignItems: "center",
          }}
        >
          <DropZone
            imageDefault={photoImg || ""}
            onSelect={handleOnSelectImage}
            isAvatar={true}
            onError={handleOnError}
          />
          <Input
            type="text"
            multiline
            fullWidth
            value={description}
            onChange={handleOnchangeDesc}
            onBlur={handleOnBlur}
            variant="standard"
            label="Acerca de ti"
            placeholder="Información relevante como años de experiencia, referencias, certificaciones, etc."
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              rows: 2,
            }}
            inputProps={{
              maxLength: 200,
            }}
            helperText={`${description?.length}/200`}
          />
        </FormControl>
        <ContainerRow order={CONFIG_DATA["numberDocument"].order}>
          <FormControl sx={{ flex: 1.5 }}>
            <InputLabel id={"typeDocument"}>
              <LabelRequired str="Documento" />
            </InputLabel>
            <Select
              label={<LabelRequired str="Documento" />}
              labelId={"typeDocument"}
              id={"typeDocument"}
              value={formData.typeDocument}
              onChange={handleOnChangeSelect}
              name="typeDocument"
              input={<OutlinedInput label="Documento " />}
              disabled={disabledTypeDocument}
            >
              <MenuItem value="0">Tipo de documento</MenuItem>
              {typeUser.map((type, i) => (
                <MenuItem key={`${type.id}-${i}`} value={type.id}>
                  {type.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="standard" sx={{ flex: 3, marginLeft: "26px" }}>
            <Input
              name="numberDocument"
              type={
                CONFIG_DATA["numberDocument"].validates.onliNumbers
                  ? "number"
                  : "text"
              }
              fullWidth
              value={formData.numberDocument}
              onChange={handleOnchange}
              variant="standard"
              label={
                <LabelRequired str={CONFIG_DATA["numberDocument"]?.label} />
              }
              placeholder={CONFIG_DATA["numberDocument"]?.placeholder}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={disabledNumberDocument}
            />
          </FormControl>
        </ContainerRow>

        <ContainerRow order={CONFIG_DATA["name"].order}>
          <FormControl variant="standard" sx={{ flex: 1 }}>
            <Input
              name="name"
              type="text"
              fullWidth
              value={formData.name}
              onChange={handleOnchange}
              variant="standard"
              label={<LabelRequired str={CONFIG_DATA["name"]?.label} />}
              placeholder={CONFIG_DATA["name"]?.placeholder}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ maxLength: 30 }}
            />
          </FormControl>
        </ContainerRow>

        <ContainerRow order={CONFIG_DATA["fullname"].order}>
          <FormControl variant="standard" sx={{ flex: 1 }}>
            <Input
              name="fullname"
              type="text"
              fullWidth
              value={formData.fullname}
              onChange={handleOnchange}
              variant="standard"
              label={<LabelRequired str={CONFIG_DATA["fullname"]?.label} />}
              placeholder={CONFIG_DATA["fullname"]?.placeholder}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ maxLength: 30 }}
            />
          </FormControl>
        </ContainerRow>

        <ContainerRow order={CONFIG_DATA["district"].order}>
          <FormControl variant="standard" sx={{ flex: 1 }}>
            <Input
              name="district"
              type="text"
              fullWidth
              value={formData.district.nombre}
              onChange={handleActiveModalCity}
              onClick={handleActiveModalCity}
              variant="standard"
              label={<LabelRequired str={CONFIG_DATA["district"]?.label} />}
              placeholder={CONFIG_DATA["district"]?.placeholder}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </ContainerRow>

        <ContainerRow order={CONFIG_DATA["email"].order}>
          <FormControl variant="standard" sx={{ flex: 1 }}>
            <Input
              name="email"
              type="text"
              fullWidth
              value={formData.email}
              onChange={handleOnchange}
              variant="standard"
              label={CONFIG_DATA["email"]?.label}
              placeholder={CONFIG_DATA["email"]?.placeholder}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errormsg.email}
              helperText={errormsg.email}
            />
          </FormControl>
        </ContainerRow>

        <ContainerRow order={7}>
          <FormControl variant="standard" sx={{ flex: 1 }}>
            <ButtomLoader
              type="submit"
              disabled={handleDisabled()}
              isLoading={isLoading}
            >
              Guardar
            </ButtomLoader>
          </FormControl>
        </ContainerRow>
      </Form>
    </>
  );
};

export default FormPerfilTransportista;
