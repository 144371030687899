import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { StyleCustomInput } from "@/components/form/customInput/styleCustomInput";
import LayoutFlow from "@/components/LayoutFlow/LayoutFlow";
import LabelRequired from "@/components/utils/LabelRequired";
import { AUTH_VALIDATION, HOME_PAHT } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import {
  sendCodeBySMSThunk,
  validateCodeThunk,
} from "@/store/auth/authUserThunks";
import { styled, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderFlow from "../components/HeaderFlow";
import {
  objectNextPage,
  tipoSMS,
  titleByPath,
} from "./configDataClientProfile";
import CountDownLinkAction from "./CountDownLinkAction";
import { loginValidationDataLayer } from "@/services/dataLayer/auth";
import Input from "@/components/inputCustom/Input";

const Text = styled(Typography)(
  ({ theme }) => `
    margin: 16px 0px;
  `
);

const InputCustom = styled(Input)(
  () => `
    margin-top: 10px;
    margin-bottom: 16px;
  `
);

const MAX_LENGTH_CODE = 4;
interface LocationState {
  success?: boolean;
}

const Validation = () => {
  const [code, setCode] = useState("");
  const [showErrorSendCode, setShowErrorSendCode] = useState(false);
  const { cellPhone, errorValidateCode, errorSendCode, isLoading } =
    useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  useEffect(() => {
    if (!(state as LocationState)?.success) {
      navigate(HOME_PAHT, { replace: true });
    }
  }, [navigate, state]);

  const handleFormaterCellPhone = () => {
    const formaterCellPhone = cellPhone
      .replace(/\D/g, "")
      .replace(/(\d{0,3})(\d{0,3})(\d{0,3})/, "$1 $2 $3");

    return formaterCellPhone;
  };

  const handleOnchange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const value = currentTarget.value.trim();
    if (value.length <= MAX_LENGTH_CODE) {
      setCode(
        value.replace(/\D/g, "") // Solo permite [1-9]
      );
    }
  };

  const handleDisabled = () => {
    return code.length < MAX_LENGTH_CODE || isLoading;
  };

  const handleReSendMSM = () => {
    setShowErrorSendCode(false);
    dispatch(sendCodeBySMSThunk(cellPhone, tipoSMS[pathname])).catch(() =>
      setShowErrorSendCode(true)
    );
  };

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLoading) return;

    dispatch(validateCodeThunk(cellPhone, code)).then(() => {
      loginValidationDataLayer();
      navigate(objectNextPage[pathname], {
        replace: true,
        state: {
          success: true,
        },
      });
    });
  };

  return (
    <LayoutFlow>

      <HeaderFlow title={titleByPath[pathname]} />
      <Text variant="body1">
        Ingresa el código de 4 dígitos enviado al número{" "}
        <Typography variant="label1">{handleFormaterCellPhone()}</Typography>
      </Text>

      <>
        {showErrorSendCode && (
          <StandardAlert
            sx={{ marginBottom: "16px" }}
            severity="error"
            color="error"
            onClick={() => setShowErrorSendCode(false)}
          >
            {errorSendCode}
          </StandardAlert>
        )}
      </>

      <form onSubmit={handleOnSubmit}>
        <InputCustom
          label={<LabelRequired str="Código de verificación" />}
          name="phone"
          variant="standard"
          error={!!errorValidateCode?.length}
          helperText={errorValidateCode}
          placeholder="Ingresa el código de 4 dígitos"
          autoComplete="off"
          fullWidth
          value={code}
          onChange={handleOnchange}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            inputMode: "numeric",
          }}
        />

        <CountDownLinkAction
          initActiveCountdown
          activeCountdown={handleReSendMSM}
        />

        <ButtomLoader
          disabled={handleDisabled()}
          isLoading={isLoading}
          type="submit"
        >
          SIGUIENTE
        </ButtomLoader>
      </form>
    </LayoutFlow>
  );
};

export default Validation;
