import {
  setSelectCity,
  setSelectOrigin,
} from '@/store/customerRequestRedux/customerRequestSlice';
import { setMultiSelectDestinyV2 } from '@/store/locationRedux/selectLocation';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

export type ModalType = { type: 'origin' | 'city' | 'multiDestiny' };

export const MODAL_TYPE: {
  origin: Extract<ModalType['type'], 'origin'>;
  city: Extract<ModalType['type'], 'city'>;
  multiDestiny: Extract<ModalType['type'], 'multiDestiny'>;
} = {
  origin: 'origin',
  city: 'city',
  multiDestiny: 'multiDestiny',
};

interface ConfigFormRute {
  origin: Location;
  city: Location;
  multiDestiny: Location;
}

interface Location {
  input1: InputProps;
  input2?: InputProps;
  modal: InputModal;
}

interface InputProps {
  name: string;
  label: string;
  placeholder: string;
}

interface InputModal extends InputProps {
  title: string;
  type: ModalType['type'];
}

export const configFormRute: ConfigFormRute = {
  city: {
    input1: {
      name: MODAL_TYPE.city,
      label: 'Ciudad',
      placeholder: 'Ingresa una ciudad de origen',
    },
    modal: {
      name: 'search-city',
      label: 'Tu ciudad',
      placeholder: 'Ingresa una ciudad de origen',
      title: 'Selecciona la ciudad de origen',
      type: 'city',
    },
  },
  origin: {
    input1: {
      name: MODAL_TYPE.origin,
      label: 'Distrito de origen',
      placeholder: 'Distrito de origen',
    },
    input2: {
      name: 'reference-origin',
      label: 'Referencia de la ubicación',
      placeholder: 'Lugar, parque o av cercana a la dirección',
    },
    modal: {
      name: 'search-origin',
      label: 'Origen',
      placeholder: 'Ingresa distrito de origen',
      title: 'Selecciona un distrito de origen',
      type: 'origin',
    },
  },

  multiDestiny: {
    input1: {
      name: MODAL_TYPE.multiDestiny,
      label: 'Distrito de destino',
      placeholder: 'Selecciona uno o varios destinos',
    },
    modal: {
      name: 'search-destiny',
      label: 'Distritos',
      placeholder: 'Distritos de cobertura',
      title: 'Elegir distritos de entrega',
      type: 'multiDestiny',
    },
  },
};

export const functionSaveObjectByTypeModal: Record<
  ModalType['type'],
  ActionCreatorWithPayload<any, string>
> = {
  origin: setSelectOrigin,
  city: setSelectCity,
  multiDestiny: setMultiSelectDestinyV2,
};

export type InputPropsV2 = {
  label: string;
  name: string;
  placeholder: string;
};

export type ModalMultidestinyPropsV2 = {
  onChange: (value: string) => void;
  onSubmit: (value: Record<string, any> | null) => void;
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  list: any[];
  inputProps: InputPropsV2;
  title: string;
  stateAccordion: string;
  nameTwo?: boolean;
};

export interface AccordionModalV2Props {
  listAccordion: any[];
  modalActiveWith: string;
  onSubmit: () => void;
  inputProps: InputProps;
  inputValue: string;
  handleOnInputChange: ({
    currentTarget,
  }: ChangeEvent<HTMLInputElement>) => Promise<void>;
}

export type SelectedDistrict = { id: number; nombre: string; nombre2?: string };

export const InputDateText = {
  label: 'Fecha del servicio',
};
