import { Box, Button, Typography, styled } from '@mui/material';

export const ContainerAds = styled(Box)(({ mobile }: { mobile: boolean }) => ({
  border: mobile ? 'none' : '1px solid black',
  borderRadius: mobile ? '0' : '24px',
  width: '100%',
  minHeight: '372px',
  display: 'flex',
  padding: '16px',
  gap: '16px',
  marginTop: '10px',
  marginBottom: '32px',
  flexDirection: mobile ? 'column' : 'row',
  justifyContent: 'center',
  alignItems: mobile ? 'center' : 'center',
}));

export const ImageContainer = styled(Box)(
  ({ mobile }: { mobile: boolean }) => ({
    width: mobile ? '100%' : '18%',
    display: 'flex',
    justifyContent: mobile ? 'center' : 'flex-end',
    alignItems: 'center',
  })
);

export const ImageContainertwo = styled(Box)(
  ({ mobile }: { mobile: boolean }) => ({
    width: mobile ? '100%' : '40%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })
);

export const SubContainerAds = styled(Box)(
  ({ mobile }: { mobile: boolean }) => ({
    width: mobile ? '100%' : '30%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  })
);

export const ButtonAccionModal = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.button.fontFamily,
  fontSize: theme.typography.button.fontSize,
  height: '36px',
  textDecoration: 'none',
  borderRadius: '36px',
  padding: '0px 16px',
  '@media only screen and (max-width: 899px)': {
    alignSelf: 'center',
    width: '246px',
  },
  backgroundColor: '#FF6700',
  color: 'white',
  width: '100%',
  '&:hover': { backgroundColor: '#FF6700' },
}));

export const TypographTitle = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '700',
}));

export const TypographText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: '#666666',
}));
