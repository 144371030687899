export const prepareDayOfWeek = (day: string) => {
  const shortDay = day.charAt(0).toUpperCase() + day.slice(1, day.length);

  return shortDay;
};

// entrada: 2023/07/26 - 2023/07/30
// salida: 26/07/2023 - 30/07/2023
export const prepareBetweenDate = (betweendate: string) => {
  const result = betweendate.split(' - ').reduce((acc, el, i, arr) => {
    const [year, month, day] = el.split('/');

    acc += `${day}/${month}/${year}`;
    if (arr.length - 1 !== i) {
      acc += ' - ';
    }
    return acc;
  }, '');

  return result;
};

export const getListPrevSelected = (
  listLocation: any[],
  prevSelected: string | string[]
) => {
  const selected = listLocation?.filter((location) => {
    if (Array.isArray(prevSelected)) {
      return prevSelected.some((prev) => prev === location.nombre);
    } else {
      return prevSelected.split(',').some((prev) => prev === location.nombre);
    }
  });

  return selected;
};
