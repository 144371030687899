import { FC } from "react";
import { Skeleton, SkeletonProps } from "@mui/material";
import { SkeletonGroupContainer } from "./styleSkeletons";
import { ResponsiveStyleValue } from "@mui/system";

interface Props extends SkeletonProps {
  list: number;
  borderRadius?: string | number;
  gap?: ResponsiveStyleValue<string | number>;
  direction?: ResponsiveStyleValue<"row" | "column">;
  width?: string | number;
}

const SkeletonGroup: FC<Props> = (props) => {
  const items = [];
  let {
    list = 2,
    animation = "pulse",
    variant = "rounded",
    width = 328,
    height = 56,
    borderRadius = "4px",
    gap = "16px",
    direction,
  } = props;

  for (let index = 0; index < list; index++) {
    items.push(
      <Skeleton
        key={index}
        animation={animation}
        variant={variant}
        height={height}
        width={width}
        sx={{
          borderRadius: { borderRadius },
        }}
      />
    );
  }

  return (
    <SkeletonGroupContainer gap={gap} sx={{ flexDirection: direction }}>
      {items}
    </SkeletonGroupContainer>
  );
};

export default SkeletonGroup;
