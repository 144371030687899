

const useButtonContactHook = () => {

    const handlePrepareLink = (type: string, contact: string, whatsapp: string) => {
        const isWhatsapp = type === "whatsapp";
        let prefix = "tel:";
        if (type === "mail") {
            prefix = "mailto:";
        } else if (isWhatsapp) {
            return whatsapp;
        }

        return prefix + contact;
    };
    return {
        handlePrepareLink
    }
}

export default useButtonContactHook;