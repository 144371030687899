import { Chip, Stack } from "@mui/material";
import React from "react";

type Props = {
  list: any[];
  propertyName: string;
  onDelete?: (newList: any[], index: number) => void;
};

const ListChip = ({ list, propertyName, onDelete }: Props) => {
  if (!Array.isArray(list)) return <></>;

  const handleOnDelete = (index: number) => () => {
    let newList = structuredClone(list);
    newList.splice(index, 1);

    onDelete && onDelete(newList, index);
  };

  return (
    <Stack direction="row" spacing={1} marginTop={"8px"} flexWrap="wrap">
      {list.map((item, index) => (
        <Chip
          key={`chips-${index}`}
          label={item[propertyName]}
          variant="outlined"
          onDelete={handleOnDelete(index)}
          sx={{ marginBottom: "8px !important" }}
        />
      ))}
    </Stack>
  );
};

export default ListChip;
