export const messagesSuccess = {
  saveVehicle: 'Vehículo agregado',
  updateVehicle: 'Cambios guardados con éxito.',
  updatePhotos: `La foto :type se ha actualizado con éxito.`,
};

export const messageError =
  'Debes subir las 3 fotos para poder continuar. Intente nuevamente.';

export const requitedPlate = 1;

export const maxNumberPhotos = 3;

export const messageAlertDefault = {
  title: '',
  message: '',
  ok: '',
  cancel: '',
};

export const messageAlertBack = {
  title: 'Cambios sin guardar',
  message:
    'Aún no has guardado la foto, si regresas deberás subirla nuevamente. ¿Deseas regresar?',
  ok: 'SI, REGRESAR',
  cancel: 'NO, QUEDARME',
};
