import { Container, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";

import StandardAlert from "@/components/alerts/StandardAlert";
import HeaderSection from "@/components/headerSection/HeaderSection";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import useNoticePlaceHook from "./hook";
import { BoxContainerInfo, ContainerArea } from "./noticePlaceStyles";
import { priceInfoText } from "./placeConfig";
import Icon from "@/utils/Icon";

const NoticePlace = () => {
  const {
    handleValidGoBack,
    headerText,
    error
  } = useNoticePlaceHook()

  return (
    <Container maxWidth="xs">
      <HeaderSection
        icon="icon-arrow-left--primary"
        title={headerText.title}
        subTitle={headerText.subTitle}
        onClick={handleValidGoBack}
      />
      <>
        {(error && error) && (
          <StandardAlert sx={{ marginBottom: "16px" }} severity="error" color="error">
            {error}
          </StandardAlert>
        )}
      </>
      <ContainerArea>
        <SectionIcon icon="icon-money" />

        <BoxContainerInfo>
          <Icon name="icon-handsShake" size="md" />
          <Typography variant="body2" sx={{ fontSize: "16px" }}>
            {priceInfoText}
          </Typography>
        </BoxContainerInfo>

        <Outlet />
      </ContainerArea>
    </Container>
  );
};

export default NoticePlace;
