import { ID_IN_CITY, ID_MOVING_FLOW, ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import { IAcuerdosporaceptar } from "@/models/userAuth/agrementToAccept";
import { currencyFormat } from "@/utils/helpers";
import { MinTitlePrice, PriceContainer } from "../styleInfoCard";

type MiniPriceProps = { data: IAcuerdosporaceptar,
  type: "aviso" | "solicitud"
};
const MiniPriceCustom = ({ data, type }: MiniPriceProps) => {
  const priceTitle = () => {
    if (data.idtipo === ID_IN_CITY) {
      return "De punto a punto";
    }
    if (data.idtipo === ID_MULTI_DESTINY) {
      if (data.flghoraopunto === 2) {
  
        return `Por punto (min. ${data?.horasopuntos} puntos)`;
      }
      if (data.flghoraopunto === 1) {
   
        return `Por hora (min. ${data?.horasopuntos} horas)`;
      }
    }
    if (type === "aviso" && data.idtipo === ID_MOVING_FLOW) {
   
      return `Por viaje (incluye ${data?.ayudantes} ${data?.ayudantes === 1 ? "ayudante" : "ayudantes"})`;
    }

    return "";
  };

  const price = () => {
    return currencyFormat(data.precio);
  };

  if (!data) return <></>;

  return (
    <PriceContainer>
      <MinTitlePrice dangerouslySetInnerHTML={{ __html: priceTitle() }} />
      {price()}
    </PriceContainer>
  );
};

export default MiniPriceCustom;