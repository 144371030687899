export const remanePlaceholders = [
  'origin',
  'destiny',
  'typeVehicle',
  'weightOfTheLoad',
];

export const placeholders: Record<string, any> = {
  'Transporte en la ciudad': {
    origin: {
      label: 'Origen',
      placeholder: 'Distrito de recojo',
    },
    destiny: {
      label: 'Destino',
      placeholder: 'Distrito de entrega',
    },
    typeVehicle: {
      label: 'Tipo de vehículo',
      placeholder: 'Elige el tipo de vehículo',
    },
    weightOfTheLoad: {
      label: 'Peso de la carga',
      placeholder: 'Peso aproximado de la carga',
    },
  },
  'Multidestino en la ciudad': {
    origin: {
      label: 'Origen',
      placeholder: 'Distritos de recojo',
    },
    destiny: {
      label: 'Destino',
      placeholder: 'Distritos de entrega',
    },
    typeVehicle: {
      label: 'Tipo de vehículo',
      placeholder: 'Elige el tipo de vehículo',
    },
    weightOfTheLoad: {
      label: 'Peso de la carga',
      placeholder: 'Peso aproximado de la carga',
    },
  },
  'Transporte entre ciudades': {
    origin: {
      label: 'Origen',
      placeholder: 'Ciudad de recojo',
    },
    destiny: {
      label: 'Destino',
      placeholder: 'Ciudad de entrega',
    },
    typeVehicle: {
      label: 'Tipo de vehículo',
      placeholder: 'Elige el tipo de vehículo',
    },
    weightOfTheLoad: {
      label: 'Peso de la carga',
      placeholder: 'Peso aproximado de la carga',
    },
  },
  'Remate entre ciudades': {
    origin: {
      label: 'Origen',
      placeholder: 'Ciudad de recojo',
    },
    destiny: {
      label: 'Destino',
      placeholder: 'Ciudad de entrega',
    },
    typeVehicle: {
      label: 'Tipo de vehículo',
      placeholder: 'Elige el tipo de vehículo',
    },
    weightOfTheLoad: {
      label: 'Peso de la carga',
      placeholder: 'Peso aproximado de la carga',
    },
  },
  REQUEST_TYPE_MOVE_CITY: {
    origin: {
      label: 'Origen',
      placeholder: 'Distrito de recojo',
    },
    destiny: {
      label: 'Destino',
      placeholder: 'Distrito de entrega',
    },
    typeVehicle: {
      label: 'Tipo de vehículo',
      placeholder: 'Elige el tipo de vehículo',
    },
  },
  REQUEST_TYPE_MOVE_BETWEEN_CITIES: {
    origin: {
      label: 'Origen',
      placeholder: 'Ciudad de recojo',
    },
    destiny: {
      label: 'Destino',
      placeholder: 'Ciudad de entrega',
    },
    typeVehicle: {
      label: 'Tipo de vehículo',
      placeholder: 'Elige el tipo de vehículo',
    },
  },
};
