import { MetadaValues, ModalActive } from '@/interfaces/filterInterface';

type Keys = ModalActive['modalActiveWith'];

export interface MetadaModalFilter extends Record<Keys, MetadaValues> {}

export const metaDataModals: MetadaModalFilter = {
  city: {
    title: 'Ciudad',
    inputName: 'city',
    inputLabel: 'Ciudad',
    inputPlaceholder: 'Ciudad',
    buttonText: 'Guardar',
  },
  origin: {
    title: 'Origen',
    inputName: 'origin',
    inputLabel: 'Origen',
    inputPlaceholder: 'Origen',
    buttonText: 'Siguiente',
  },
  destiny: {
    title: 'Destino',
    inputName: 'destiny',
    inputLabel: 'Destino',
    inputPlaceholder: 'Destino',
    buttonText: 'Siguiente',
  },
  typeServiceList: {
    title: 'Modalidad del servicio',
    inputName: '',
    inputLabel: '',
    inputPlaceholder: '',
    buttonText: 'ELEGIR',
  },
  typeVehicle: {
    title: 'Elegir vehículo',
    inputName: '',
    inputLabel: '',
    inputPlaceholder: '',
    buttonText: 'ELEGIR',
  },
  weightOfTheLoad: {
    title: 'Peso de la carga',
    inputName: '',
    inputLabel: '',
    inputPlaceholder: '',
    buttonText: 'ELEGIR',
  },
  betweenDate: {
    title: 'Fecha del aviso',
    inputName: '',
    inputLabel: '',
    inputPlaceholder: '',
    buttonText: 'Guardar',
  },
};
