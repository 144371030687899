import ListItemMulti from "@/components/LisItemMulti/ListItemMulti";
import { RoleAPI } from "@/models/userAuth/user";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { FC } from "react";
import { ILinks } from "../interfaces/profileInterface";

type Props = {
  links: ILinks[];
  user: IUser;
};
const ListItemMenu: FC<Props> = ({ links, user }) => {
  if (!Object.keys(user).length || !links.length) return <></>;

  const handleGetUserRole = () => {
    const role = user?.role as RoleAPI[];
    if (!!role && !!role.length) {
      const [currentRole] = role;
      return currentRole?.nombre;
    }

    return "";
  };

  const userRole = handleGetUserRole();

  return (
    <>
      {links.map((value, index) =>
        value.role?.includes(userRole) ? (
          <ListItemMulti key={index} data={value} />
        ) : (
          <></>
        )
      )}
    </>
  );
};

export default ListItemMenu;
