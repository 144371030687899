import { ID_MOVING_FLOW, ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import {
  ListAviso,
  NoticeResponseSaveFlowAPI,
} from "@/models/notice/noticeModels";
import { infoCardIcons, movingTitle, multiDestinyTitle } from "./rulesByFlow";
import { TipoAviso } from "./styleInfoCard";

type Props = { data: NoticeResponseSaveFlowAPI | ListAviso };

const TypeNoticeCard = ({ data }: Props) => {
  const typeId =  data?.tipoaviso?.id || 0;

  const handleGetTitle = (): string => {
    const returnByDefault = data.tipoaviso?.nombre || ""
    
    if (typeId === ID_MULTI_DESTINY && !!data?.flghoraopunto) {
      return multiDestinyTitle[data.flghoraopunto];
    } else if (
      typeId === ID_MOVING_FLOW &&
      !!data.flgciudadoentreciudades
    ) {
      return movingTitle[data.flgciudadoentreciudades];
    }


    return returnByDefault;
  };

  const handleGetIcon = () => {
    let newIcon = !!typeId ? infoCardIcons[typeId].icon : ""
    
    const isMultiDestiny = typeId === ID_MULTI_DESTINY;
    if (isMultiDestiny && data?.flghoraopunto === 1) {
      newIcon = !!typeId ? infoCardIcons[typeId].icon2 : "";
    }

    return newIcon;
  };

  const icon = handleGetIcon();

  return (
    <TipoAviso variant="body2">
      <i className={icon}></i> {handleGetTitle()}
    </TipoAviso>
  );
};

export default TypeNoticeCard;
