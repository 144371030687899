import StandardAlert from "@/components/alerts/StandardAlert";
import HeaderSection from "@/components/headerSection/HeaderSection";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { UsuarioAPI } from "@/models/userAuth/user";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { fillSendDataThunk } from "@/store/noticeV2Redux/noticeV2Thunk";
import { getNextRouteV2, getPrevRouteV2 } from "@/store/routeActionsFlowV2/routeActionsFlowNoticeV2Thunk";
import { ISelectedVehicle } from "@/store/userRedux/userReduxInterface";
import { INoticeCreateListItem } from "../NoticeList/types";
import { extractSendData, getNoticeType, getNoticeTypeInterface } from "../adapters/noticeUseTypes";
import { FormVehicle } from "./components/FormVehicle";
import { StandardAlertText, currentRuleVehicle } from "./vehicleConfig";
import { ContainerVehicle } from "./vehicleStyles";
import { useEffect } from "react";
import { createServiceVehicleDataLayer } from "@/services/dataLayer/createNotice";

export const NoticeVehicle = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    noticeV2: { selectNotice, sendData },
    user: {
      vehicles,
      user,
      isLoading,
      selectedVehicle
    }
  } = useAppSelector((state) => state);
  const currentUser = user as UsuarioAPI;
  const NoticeType = (selectNotice as INoticeCreateListItem) || 0
  const currentHeaderText =
    currentRuleVehicle[NoticeType.id].header;

  useEffect(() => {
    if (!Object.keys(selectedVehicle).length) {
      createServiceVehicleDataLayer(
        currentUser.id,
        NoticeType?.title || ""
      );
    }
  }, []);

  const handleGoBack = () => {
    dispatch(getPrevRouteV2(navigate));
  };

  const handleNavigate = () => {
    dispatch(getNextRouteV2(navigate));
  };

  const handleOnSubmit = ({ id, cargamaxima }: Partial<ISelectedVehicle>) => {

    dispatch(fillSendDataThunk({
      key: getNoticeType(NoticeType.id),
      value: getNoticeTypeInterface(NoticeType.id, {
        ...extractSendData(sendData, NoticeType.id || 0),
        idvehiculo: id,
        pesomaximo: cargamaxima
      })
    })).then(() => {
      handleNavigate();
    })
  };

  return (
    <Container maxWidth="xs">
      <HeaderSection
        icon="icon-arrow-left--primary"
        title={currentHeaderText.title}
        subTitle={currentHeaderText.subTitle}
        onClick={handleGoBack}
      />

      <ContainerVehicle maxWidth="xs">
        {isLoading ? (
          <></>
        ) : (
          <>
            {(vehicles && (vehicles?.length === 0)) && (
              <StandardAlert
                sx={{ marginBottom: "16px" }}
                severity="error"
                color="error"
                onClick={() => { }}
              >
                {StandardAlertText}
              </StandardAlert>
            )}
          </>
        )}

        <SectionIcon icon="icon-truck--blue" />
        <FormVehicle onSubmit={handleOnSubmit}
          idTipoAviso={NoticeType.id}
        />
      </ContainerVehicle>
    </Container>
  );
};
