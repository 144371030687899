import { Grid } from "@mui/material";
import ListCustomer, { ListPropsCustomer } from "./ListCustomer";

const ListChildrenCustomer = ({
  requestList,
  isLoading,
  refObserver,
  isLoadingPagination,
}: ListPropsCustomer) => {
  if (!requestList?.length) {
    return (
      <ListCustomer
        requestList={requestList}
        isLoading={isLoading}
        isLoadingPagination={isLoadingPagination}
        refObserver={refObserver}
      />
    );
  }

  return (
    <Grid container spacing={2}>
      <ListCustomer
        requestList={requestList}
        isLoading={isLoading}
        isLoadingPagination={isLoadingPagination}
        refObserver={refObserver}
      />
    </Grid>
  );
};

export default ListChildrenCustomer;
