import { StackProps, SxProps, Theme } from "@mui/material";
import { FC } from "react";
import { Section, SectionContainer, SubTitle, Title } from "./styleSectionLand";

interface Props extends StackProps {
  title?: string;
  subTitle?: string;
  backgroundColor?: string;
  gap?: string | number;
  alignItems?: string;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
}

const SectionLand: FC<Props> = (props) => {
  const {
    title,
    subTitle,
    backgroundColor = "#FFF",
    gap = "32px",
    alignItems = "center",
    children,
    sx,
  } = props;

  return (
    <Section sx={{ backgroundColor: { backgroundColor }, ...sx }}>
      <SectionContainer gap={gap} alignItems={alignItems}>
        {title && <Title>{title}</Title>}
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
        {children && children}
      </SectionContainer>
    </Section>
  );
};

export default SectionLand;
