import { currencyFormat } from "@/utils/helpers";
import {
  Avatar,
  Box,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Rating,
  Stack,
  Typography,
} from "@mui/material";

import Icon, { IconTypes } from "@/utils/Icon";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { CustomCard } from "../Card/CustomCard";
import { CardTitleType } from "../CardMyRequest/style";
import useCardFilterHook from "./hook";
import { CardFilterHeader, CardFilterOriginText } from "./style";

export type ICardFilterProps = {
  imgHead: string;
  imgProfile: string;
  icon: IconTypes;
  title: string;
  origin: string;
  name: string;
  typeVehicle: string;
  whight: string;
  price?: number;
  patrocinio?: number;
  puntaje?: number;
  calificaciones?: number;
  precioregular?: number;
  priceDetails?: string;
  disabled?: boolean;
  showState?: boolean;
  onClick?: () => void;
};

const CardFilter = ({
  imgHead,
  imgProfile,
  icon,
  title,
  origin,
  name,
  typeVehicle,
  whight,
  price,
  patrocinio,
  puntaje,
  calificaciones,
  precioregular,
  priceDetails,
  disabled = false,
  showState,
  onClick,
}: ICardFilterProps) => {
  const { validateImg, handleImageError } = useCardFilterHook();

  return (
    <CustomCard sx={{ height: "332px", width: "100%" }} onClick={onClick}>
      <CardFilterHeader>
        {!!patrocinio && (
          <Chip
            label="Patrocinado"
            variant="outlined"
            sx={{
              position: "absolute",
              top: 12,
              left: 10,
              backgroundColor: "white",
              border: "1px solid black",
            }}
          />
        )}
        <CardMedia
          sx={{ objectFit: "cover", cursor: "pointer" }}
          component="img"
          width="100%"
          height="170px"
          image={validateImg(imgHead)}
          alt={imgHead}
          onError={handleImageError}
        />

        <Avatar
          alt=""
          src={validateImg(imgProfile, false) || ""}
          sx={{
            width: 64,
            height: 64,
            position: "absolute",
            bottom: "10px",
            left: "10px",
            border: "3px solid white",
          }}
        >
          <PersonOutlineOutlinedIcon
            sx={{
              width: 36,
              height: 36,
              color: "white",
            }}
          />
        </Avatar>
      </CardFilterHeader>
      <CardContent
        sx={{
          padding: "8px 16px",
          gap: "4px",
        }}
      >
        <Stack flexDirection="row" gap="8px" alignItems="center">
          <Icon name={icon || "icon-building"} size={20} />
          <CardTitleType variant="caption">{title}</CardTitleType>
        </Stack>
        <CardFilterOriginText variant="h6">{origin}</CardFilterOriginText>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: "16px" }}>{name}</Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Rating
              name="customized-10"
              defaultValue={2}
              max={1}
              size="small"
              sx={!!puntaje ? {} : { color: "#9e9e9e" }}
            />
            <Typography sx={{ fontSize: "14px" }}>
              {!!puntaje ? `${puntaje} (${calificaciones})` : ""}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box display="flex" alignItems="center" gap="4px" mt={"4px"}>
            <Typography variant="body2">{typeVehicle}</Typography>
            {!!whight && (
              <Typography variant="caption" color="#757575">
                |
              </Typography>
            )}
            <Typography variant="body2">{whight}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: !!precioregular ? "block" : "none" }}>
          <Typography variant="caption" sx={{ textDecoration: "line-through" }}>
            {currencyFormat(precioregular || 0)}
          </Typography>
        </Box>
      </CardContent>
      <CardActions
        disableSpacing
        sx={{ display: "flex", justifyContent: "space-between", p: 2, pt: 0 }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            variant="body2"
            sx={{ fontSize: "22px", fontWeight: 700 }}
          >
            {price && currencyFormat(price)}
          </Typography>

          <Typography sx={{ color: "#757575", fontSize: "12px", pl: 0.5 }}>
            {priceDetails}
          </Typography>
        </Box>

        <ArrowForwardIcon sx={{ color: "#FF6700", cursor: "pointer" }} />
      </CardActions>
    </CustomCard>
  );
};

export default CardFilter;
