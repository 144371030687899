import {
  MyNegotationBodyDTO,
  MyNegotiationesList,
  TypeFilterEnum,
} from '@/models/userAuth/agrementToAccept';
import { RootState } from '@/store/store';

export const prepareBodyByTypeFilterAdapter = (
  state: RootState,
  typeFilter: TypeFilterEnum
): MyNegotationBodyDTO | null => {
  const currentState = getCurrentStore(state, typeFilter);
  const numberPage = getNumberPage(currentState);
  if (numberPage === -1) return null;

  return {
    page: numberPage,
    size: 12,
    ordenamiento: ['id', 'desc'],
    tiposFiltro: [
      {
        nombre: 'Tipo de estado',
        opciones: [typeFilter],
      },
    ],
  };
};

const getCurrentStore = (state: RootState, typeFilter: TypeFilterEnum) => {
  const { actives, history, expired } = state.myListNegotiations;
  const objectLink: Record<TypeFilterEnum, MyNegotiationesList> = {
    [TypeFilterEnum.abiertas]: actives,
    [TypeFilterEnum.cerradas]: history,
    [TypeFilterEnum.vencidas]: expired,
  };

  return objectLink[typeFilter];
};

const getNumberPage = (state: MyNegotiationesList) => {
  if (state.currentPage > -1) {
    if (state.currentPage < state.totalPages - 1) {
      return state.currentPage + 1;
    }
    return -1;
  }

  return 0;
};
