import { MY_PENDING_AGREEMENTS } from "@/consts/typeServicesPaths";
import { LocationState } from "@/interfaces/globalInterface";
import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import { useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const useFinishedLayoutHook = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { notice } = useGetData();
  const { pathname, state } = location;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const isCounterOffer = pathname.includes("contraoferta");

  const states = (state as LocationState);

  const handleHeaderProps = () => {
    if (isCounterOffer) {
      return counterOfferData;
    }

    return acceptData;
  };

  const navigateNegociations = () => {
    navigate(MY_PENDING_AGREEMENTS, {
      replace: true,
    });
  }

  const handleNavigationBack = () => {
    navigate(-1);
  };

  return {
    theme,
    matches,
    handleHeaderProps,
    notice,
    navigateNegociations,
    handleNavigationBack,
    hasBackNavigate: states?.hasBackNavigate || false,
    showBtnCalification: states?.showCalification || false,
  }
}

export const acceptData = {
  title: 'Contacta a tu cliente',
  icon: '',
};

export const counterOfferData = {
  title: 'Contraofertaron tu servicio!',
  icon: 'icon-counterOfferMat',
};


export default useFinishedLayoutHook