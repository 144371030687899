import { FORMAT_DATE_DDMMYYYY } from "@/consts/formatsDate";
import { nottifficationMultidestinyMsg } from "@/consts/notifficationsMessage";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { customerRequestObjectTypes } from "@/pages/customerRequest/shared/consts/customerRequestConsts";
import {
  emptyDefaultObject,
  setDateService,
} from "@/store/customerRequestRedux/customerRequestSlice";
import { getCitiesByCountryThunk } from "@/store/locationRedux/citiesThunks";
import {
  getDistrictsByCityAndTermThunk,
  getDistrictsByCityAndTermThunkV2,
  getOnlyDistrictsByCityThunk,
} from "@/store/locationRedux/districtsThunks";
import {
  multiSelectCity,
  setMultiSelectDestinyV2,
} from "@/store/locationRedux/selectLocation";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { searchTermInArray } from "@/utils/helpers";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MODAL_TYPE,
  ModalType,
  SelectedDistrict,
  configFormRute,
  functionSaveObjectByTypeModal,
} from "../configData";

export type OpenModalType = { open: boolean; input: string };

export const useHandleFormRuteMultidestinyV2 = () => {
  const { addNotiffication } = useNotiffication();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    districts: { districts },
    cities: { cities },
    selectLocation: { multiSelectDestiny },
    customerRequest: {
      selectedCity,
      selectedOrigin,
      dateServices,
      selectedTypeRequest,
    },
  } = useAppSelector((state) => state);
  const { city } = configFormRute;
  const [stateAccordion, setStateAccordion] = useState("");
  const [searchList, setSearchList] = useState<any[]>([]);
  const [currentTypeModal, setCurrentTypeModal] = useState<ModalType["type"]>(
    MODAL_TYPE.city
  );
  const [currentModal, setCurrentModal] = useState(configFormRute.city.modal);
  const [openModal, setOpenModal] = useState(false);
  const [datePicker, setDatePicker] = useState<Dayjs | null>(
    dayjs().add(1, "day")
  );
  const stringDate =  datePicker ? (datePicker as Dayjs).format(FORMAT_DATE_DDMMYYYY)  : "" 

  useEffect(() => {
    if (!!selectedCity.id && !cities.length) {
      dispatch(
        getCitiesByCountryThunk(selectedCity?.idpais?.toString() || "1")
      );
    }

    !!dateServices && setDatePicker(dayjs(dateServices, FORMAT_DATE_DDMMYYYY));
  }, []);

  useEffect(() => {
    dispatch(setDateService(stringDate));
  }, [datePicker]);

  useEffect(() => {
    setSearchList(districts);
  }, [districts]);

  useEffect(() => {
    handleChangeCity();
  }, [selectedCity]);

  const handleChangeCity = async () => {
    if ("id" in selectedCity) {
      dispatch(
        getOnlyDistrictsByCityThunk(selectedCity.id?.toString(), "origin")
      );
    }
  };

  const handleResetOriginAndDestiny = () => {
    const { multiDestiny, origin } = functionSaveObjectByTypeModal;

    dispatch(
      origin({
        id: 0,
        nombre: "",
      })
    );
    dispatch(multiDestiny([]));
  };

  const handleManageDialog = () => {
    addNotiffication({
      title: nottifficationMultidestinyMsg.TITLE,
      message: nottifficationMultidestinyMsg.MESSAGE,
      leftBtnText: nottifficationMultidestinyMsg.LEFT_BTN_TEXT,
      RightBtnText: nottifficationMultidestinyMsg.RIGHT_BTN_TEXT,
      onSubmit: async () => {
        await handleResetOriginAndDestiny();
        handleOnClickSearchInput(MODAL_TYPE.city, true);
      },
    });
  };

  const handleOnClickSearchInput = (
    typeModal: ModalType["type"],
    state?: boolean
  ) => {
    if (
      !state &&
      typeModal === MODAL_TYPE.city &&
      !!multiSelectDestiny.length &&
      !!selectedOrigin.id
    ) {
      handleManageDialog();
    } else {
    
      setCurrentTypeModal(typeModal);
      setCurrentModal(configFormRute[typeModal].modal);
      setOpenModal(true);
      setStateAccordion(typeModal);
    }
  };

  const handleSubmitModal = (selected: Record<string, any> | null) => {
    if (!selected) return;

    if (currentTypeModal === MODAL_TYPE.city) {
      const { multiDestiny, origin } = functionSaveObjectByTypeModal;

      dispatch(multiDestiny([]));
      dispatch(origin(emptyDefaultObject));
    }

    const reduxAction = functionSaveObjectByTypeModal[currentTypeModal];

    dispatch(reduxAction(selected));

    setOpenModal(false);
  };

  const handleChangeModal = async (term: string) => {
    if (currentTypeModal === MODAL_TYPE.city) {
      handleSearchByTermInCity(term);
    } else {
      setSearchList([]);

      if (!!term) {
        dispatch(
          getDistrictsByCityAndTermThunk(selectedCity?.id.toString(), term)
        );
      } else {
        dispatch(getDistrictsByCityAndTermThunkV2(selectedCity?.id.toString()));
      }
    }
  };

  const handleSearchByTermInCity = (term: string) => {
    const currentList = structuredClone(cities);
    const search = searchTermInArray(term, currentList, ["nombre"]);

    setSearchList(search);
  };

  const handleSubmit = (event: EventType["submit"]) => {
    event.preventDefault();
    if (handleDisabledButton()) return;

    handleNextRute();
  };

  const handleNextRute = () => {
    const valueToReplace = customerRequestObjectTypes[selectedTypeRequest.id];
    dispatch(
      getNextRoute(navigate, { valueToSearch: ":typeRequest", valueToReplace })
    );
  };

  const handleRemoveLocation = (item: SelectedDistrict) => {
    const filterData = multiSelectDestiny.filter((el) => el.id !== item.id);

    dispatch(setMultiSelectDestinyV2(filterData as multiSelectCity[]));
  };

  const handleDisabledButton = () => {
    const conditions = [datePicker, selectedCity.id, multiSelectDestiny.length];
    return conditions.some((condition) => !condition);
  };

  return {
    multiSelectDestiny,
    datePicker,
    setDatePicker,
    selectedCity,
    stateAccordion,
    searchList,
    currentModal,
    openModal,
    setOpenModal,
    city,
    handleDisabledButton,
    handleSubmit,
    handleChangeModal,
    handleSubmitModal,
    handleOnClickSearchInput,
    handleRemoveLocation,
    currentTypeModal,
  };
};
