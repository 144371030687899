import {
  ID_AUCTION_FLOW,
  ID_MOVING_FLOW,
  ID_MULTI_DESTINY,
} from "@/consts/defaultConsts";
import { optionOne, optionTwo } from "@/consts/noticeConsts";
import {
  DetailNoticeMoving,
  DetailsNegotationV2,
  NoticeDetailAuctionModel,
} from "@/models/notice/noticeV2NegotationModels";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { CounterOfferPriceV2 } from "../../../components/CounterOfferPriceV2";
import { OfferPriceV2 } from "../../../components/OfferPriceV2";
import { priceAuctionComponentToRender } from "../containers/AuctionNoticeV2Neg/config";
import PriceMoving from "../containers/MovingNoticeV2Neg/PriceMoving";
import { priceMultidestinyComponentToRender } from "../containers/MultidestinyNoticeV2Neg/config";

const componentToRenderPrice: Record<
  number,
  ({ data }: { data: DetailsNegotationV2 }) => JSX.Element
> = {
  [optionOne]: OfferPriceV2,
  [optionTwo]: CounterOfferPriceV2,
};

export const GlobalPrice = ({
  data,
}: {
  data: NoticeV2NegType;
}) => {
  const {idTipoAvisoNegociacion} = data
  const PriceMultidestinyComponent =
    priceMultidestinyComponentToRender[idTipoAvisoNegociacion || optionOne];
  const PriceAuctionComponent = priceAuctionComponentToRender[idTipoAvisoNegociacion || optionOne];

  if ((data?.idtipoaviso as number) === ID_MOVING_FLOW)
    return <PriceMoving data={data as DetailNoticeMoving} />;

  if ((data?.idtipoaviso as number) === ID_MULTI_DESTINY)
    return <PriceMultidestinyComponent data={data} />;

  if ((data?.idtipoaviso as number) === ID_AUCTION_FLOW)
    return <PriceAuctionComponent data={data as NoticeDetailAuctionModel} />;

  const Component = componentToRenderPrice[idTipoAvisoNegociacion || optionOne]; 

   if (!Component) return <></>; 

  return (
    <>
    <Component data={data as DetailsNegotationV2} />
    </>
  );
};
