import { USER_ROLE_DRIVER } from "@/consts/defaultConsts";
import { currencyFormat } from "@/utils/helpers";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { SharedRideCardProps } from "./cardInterface";

interface Props extends Pick<SharedRideCardProps, "user" | "vehicle"> {}

const CardSectionVehicle: React.FC<Props> = ({ user, vehicle }) => {
  const { palette } = useTheme();
  if (!vehicle) return <></>;

  const handleGetDesription = () => {
    const typeVehicle = vehicle?.tipoVehiculo;
    let maximumLoad: number | string = vehicle?.cargamaxima || 0;
    maximumLoad = currencyFormat(maximumLoad, false);

    return `${typeVehicle} ${maximumLoad} kg`;
  };

  const display = user.tipoUsuario !== USER_ROLE_DRIVER ? "none" : "flex";

  return (
    <Box display={display} flex={1}>
      <Typography variant="label1">{vehicle?.placa} </Typography>
      <Typography
        variant="body1"
        sx={{ color: palette.cardbg?.secondary, marginLeft: "8px" }}
      >
        ({handleGetDesription()})
      </Typography>
    </Box>
  );
};

export default CardSectionVehicle;
