import { ConfigDataNegotation } from '../interface/noticeNegRequestInterface';
import RejectionCounterOffer from './components/RejectionCounterOffer/RejectionCounterOffer';
import { ConfigCounterOfferInCityClient } from './components/RejectionCounterOffer/clientConfig/inCity/configCounterOfferInCity';
import { ConfigCounterOfferMultidestinyClient } from './components/RejectionCounterOffer/clientConfig/multidestiny/configCounterOfferMultidestiny';
import { ConfigCounterOfferInCityDriver } from './components/RejectionCounterOffer/driverConfig/inCity/configCounterOfferInCity';
import { ConfigCounterOfferMultidestinyDriver } from './components/RejectionCounterOffer/driverConfig/multidestiny/configCounterOfferMultidestiny';

import RejectionOffer from './components/RejectionOffer/RejectionOffer';
import { ConfigOfferInCityClient } from './components/RejectionOffer/clientConfig/inCity/ConfigOfferInCity';
import { ConfigOfferMultidestinyClient } from './components/RejectionOffer/clientConfig/multidestiny/ConfigOfferMultidestiny';

import { ConfigCounterOfferBetweenCitiesClient } from './components/RejectionCounterOffer/clientConfig/betweenCities/configCounterOfferInBetweenCities';
import { ConfigCounterOfferMovingClient } from './components/RejectionCounterOffer/clientConfig/moving/configCounterOfferMoving';
import { ConfigCounterOfferBetweenCitiesDriver } from './components/RejectionCounterOffer/driverConfig/betweenCities/configCounterOfferBetweenCities';
import { ConfigCounterOfferMovingDriver } from './components/RejectionCounterOffer/driverConfig/moving/configCounterOfferMoving';
import { ConfigOfferBetweenCitiesClient } from './components/RejectionOffer/clientConfig/betweenCities/ConfigOfferBetweenCities';
import { ConfigOfferMovingClient } from './components/RejectionOffer/clientConfig/moving/ConfigOfferMoving';
import { ConfigOfferBetweenCitiesDriver } from './components/RejectionOffer/driverConfig/betweenCities/configOfferBetweenCities';
import { ConfigOfferInCityDriver } from './components/RejectionOffer/driverConfig/inCity/configOfferInCity';
import { ConfigOfferMovingDriver } from './components/RejectionOffer/driverConfig/moving/configOfferMoving';
import { ConfigOfferMultidestinyDriver } from './components/RejectionOffer/driverConfig/multidestiny/configOfferMultidestiny';
import RejectionCoCounterOffer from './components/RejectionCounterOffer/RejectionCoCounterOffer';
import { ConfigOfferExpressDriver } from './components/RejectionOffer/driverConfig/express/configOfferExpress';

export const objectRenderComponent: Record<number, () => JSX.Element> = {
  1: RejectionOffer,
  2: RejectionCounterOffer,
  3: RejectionCoCounterOffer,
  4: RejectionOffer,
};

interface ObjConfigNeg {
  1: ConfigDataNegotation[];
  2: ConfigDataNegotation[];
}

interface ObjConfigDataNegotation {
  1: ObjConfigNeg;
  2: ObjConfigNeg;
  3?: ObjConfigNeg;
}

/* 
    Para estas Configuraciones es importante tener en cuenta que:
     Las primeras opciones de numeros del 1 al 5 es tipo de solicitud Ejemplo: InCity, multidestiny ...
     Las siguientes dos opciones 1 y 2 son del tipo de usuario (cliente o transportista)*/

const ConfigurationToRenderOffer: Record<number, ObjConfigDataNegotation> = {
  1: {
    1: { ...ConfigOfferInCityClient }, //vista del cliente
    2: { ...ConfigOfferInCityDriver }, //vista del transportista
  },
  2: {
    1: { ...ConfigOfferMultidestinyClient },
    2: { ...ConfigOfferMultidestinyDriver },
  },
  3: {
    1: { ...ConfigOfferBetweenCitiesClient }, //vista del cliente
    2: { ...ConfigOfferBetweenCitiesDriver }, //vista del transportista
  },
  // la opción 4 es de express
  4: {
    1: { ...ConfigOfferInCityClient }, //vista del cliente
    2: { ...ConfigOfferExpressDriver }, //vista del transportista
  },
  5: {
    1: { ...ConfigOfferMovingClient },
    2: { ...ConfigOfferMovingDriver },
  },
};

const ConfigurationToRenderCounterOffer: Record<
  number,
  ObjConfigDataNegotation
> = {
  1: {
    1: { ...ConfigCounterOfferInCityClient }, //vista del cliente
    2: { ...ConfigCounterOfferInCityDriver }, //vista del transportista
  },
  2: {
    1: { ...ConfigCounterOfferMultidestinyClient },
    2: { ...ConfigCounterOfferMultidestinyDriver },
  },
  3: {
    1: { ...ConfigCounterOfferBetweenCitiesClient },
    2: { ...ConfigCounterOfferBetweenCitiesDriver },
  },
  5: {
    1: { ...ConfigCounterOfferMovingClient },
    2: { ...ConfigCounterOfferMovingDriver },
  },
};

export const generalCofigurationToRender: Record<number, any> = {
  1: ConfigurationToRenderOffer,
  2: ConfigurationToRenderCounterOffer,
};
