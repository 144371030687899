import { EventType } from "@/interfaces/globalInterface";
import { secondFontFamily } from "@/styles/theme";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Card,
  InputBaseComponentProps,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { FC, MouseEventHandler } from "react";
import LabelInputRequired, { labelTextStyle } from "./LabelInputRequired";
import { theme } from "@/styles/theme";

const errorStyle = {
  // borders rojos y shadow roja
  border: "1px solid " + theme.palette.error.main,
  boxShadow: "none",
};

interface CardItemProps {
  name?: string;
  value?: string;
  infoComponent?: JSX.Element;
  hideInput?: boolean;
  hideLabelInput?: boolean;
  hideBtnClose?: boolean;
  noRequired?: boolean;
  headTitle?: string;
  labelStr?: string;
  placeholder?: string;
  title: string;
  click?: MouseEventHandler<SVGSVGElement>;
  InputProps?: InputBaseComponentProps;
  change?: ({ target }: EventType["change"]) => void;
  error?: boolean;
}

export const CardItem: FC<CardItemProps> = (props) => {
  const {
    name = "",
    value = "",
    infoComponent,
    hideInput = false,
    hideBtnClose = false,
    hideLabelInput = false,
    labelStr = "Contraoferta (S/)",
    placeholder = "Tu precio",
    title = "",
    headTitle = "",
    noRequired = false,
    InputProps,
    click,
    change,
    error,
  } = props;

  const handleChange = ({ target }: EventType["change"]) => {
    change && change({ target } as EventType["change"]);
  };

  return (
    <CustomCard sx={error ? errorStyle : {}}>
      {!!headTitle && <Typography variant="subtitle1">{headTitle}</Typography>}
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {!!title && <Typography variant="body2">{title}</Typography>}
          {infoComponent && infoComponent}
        </Box>

        <CardItemBox>
          {!hideInput && (
            <TextField
              name={name}
              hiddenLabel
              id="filled-hidden-label-small"
              value={value}
              onChange={handleChange}
              label={
                !hideLabelInput && (
                  <>
                    {!noRequired ? (
                      <LabelInputRequired str={labelStr} />
                    ) : (
                      <Typography sx={{ ...labelTextStyle }}>
                        {labelStr}
                      </Typography>
                    )}
                  </>
                )
              }
              variant="standard"
              size="small"
              placeholder={placeholder}
              sx={{
                width: "152px",
                input: {
                  "&::placeholder": {
                    color: "#bdbdbd",
                    fontFamily: secondFontFamily,
                  },
                },
              }}
              inputProps={{ ...InputProps }}
              InputLabelProps={{
                shrink: !hideLabelInput && true,
              }}
            />
          )}
          {!hideBtnClose && (
            <CustomCloseIcon
              sx={{ color: "#848484" }}
              onClick={click && click}
            />
          )}
        </CardItemBox>
      </Box>
    </CustomCard>
  );
};

export const HeaderBox = () => {
  return (
    <HeaderBoxContainer>
      <Typography variant="subtitle1">Destino</Typography>
      <Typography variant="subtitle1">{`Precio (S/)`}</Typography>
    </HeaderBoxContainer>
  );
};

export const CustomCard = styled(Card)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
  minWidth: "275px",
  marginTop: 1,
  marginBottom: "8px",
  padding: "8px",
  boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
}));

export const CardItemBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const CustomCloseIcon = styled(CloseIcon)(() => ({
  cursor: "pointer",
  color: "borderGray.contrastText",
  width: "24px",
  height: "24px",
  marginLeft: "8px",
}));

export const HeaderBoxContainer = styled(Box)(() => ({
  marginTop: "16px",
  marginBottom: "16px",
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: "8px",
  paddingRight: "42px",
}));
