import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { snackbarAcceptInCityMsg } from "@/consts/notifficationsMessage";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { noticeNegotationRequestAdapter } from "@/pages/noticeRequestFlow/adapters/noticeRequestFlowAdapters";
import { noticeNegotationRequestObjectTypes } from "@/pages/noticeRequestFlow/consts/noticeRequestFlowConsts";
import { counterOfferRequest } from "@/services/dataLayer/createRequestNeg";
import { saveDriverNegRequestThunk } from "@/store/noticeNegotationRequestRedux/noticeNegRequesThunk";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { FormControl, Grid, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Input from "@/components/inputCustom/Input";
import {
  maxLengthPrice,
  minPriceMovingBtnCity,
  minPriceMovingInCity,
  optionFive,
  optionOne,
  optionSix,
  textCannotLowerPrice,
  textCannotUpperPrice,
} from "@/consts/noticeConsts";
import SelectCar from "@/pages/createRequestNegotiation/components/SelectCar";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";

const Container = styled(Grid)(
  () => `
    display: flex;
    flex-direction: column;  
    margin-top: 24px;
  `
);

const FormAcceptMoving = () => {
  const [price, setPrice] = useState("");
  const [assistant, setAssistant] = useState("");
  const { addNotiffication } = useNotiffication();
  const [errorPrice, setErrorPrice] = useState("");
  const { idRequest = "", typeRequest = "" } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    user: { user, selectedVehicle },
    customerRequest: { requestDetailsView },
    noticeNegotationRequest: {
      isLoading,
      error,
      noticeRequestData,
    },
  } = useAppSelector((state) => state);

  const vehicleData = { ...selectedVehicle };
  const typeNegotation = requestDetailsView?.tipoSolicitud
    ?.nombre as string;
  const idUser = (user as IUser).id;

  useEffect(() => {
    if (!!error) {
      handleManageDialog();
    }
  }, [error]);

  const handleSubmit = (event: EventType["submit"]) => {
    event.preventDefault();
    const priceTosend = Number(price);
    const body = noticeNegotationRequestAdapter(
      vehicleData,
      noticeRequestData,
      Number(price),
      Number(assistant)
    );
    const typeMoving = requestDetailsView
      ?.flgCiudadoEntreciudades as number;
    const movingInCity = typeMoving === optionOne;
    const minValue = movingInCity
      ? minPriceMovingInCity
      : minPriceMovingBtnCity;
    const maxValue = movingInCity ? optionFive : optionSix;

    if (priceTosend < minValue) {
      setErrorPrice(textCannotLowerPrice);
      return;
    }

    if (price.length > maxValue) {
      setErrorPrice(textCannotUpperPrice);
      return;
    }

    dispatch(saveDriverNegRequestThunk(idRequest, body)).then(() => {
      //dataLayer
      counterOfferRequest(idUser, typeNegotation);
      handleNextRute();
    });
  };

  const handleNextRute = () => {
    const valueToReplace = typeRequest;

    const idValueToReplace = idRequest.toString();

    dispatch(
      getNextRoute(navigate, {
        valueToSearch: ":typeRequest",
        valueToReplace,
        idToSearch: ":idRequest",
        idValueToReplace,
      })
    );
  };

  const handleDisabledButton = () => {
    const conditions = [selectedVehicle, price, assistant];
    const result = conditions.some((condition) => !condition);
    return result;
  };

  const handleOnchangeAssistant = ({ currentTarget }: EventType["change"]) => {
    const { value } = currentTarget;
    const prepareValue = value.replace(/^(0+)/g, "").replace(/[^0-9]/g, "");

    if (prepareValue.length < 3) {
      setAssistant(prepareValue);
    }
  };

  const handleOnchange = ({ currentTarget }: EventType["change"]) => {
    const { value } = currentTarget;
    setErrorPrice("")
    const prepareValue = value.replace(/^(0+)/g, "").replace(/[^0-9]/g, "");

    if (prepareValue.length <= maxLengthPrice) {
      setPrice(prepareValue);
    }
  };

  const handleManageDialog = () => {
    addNotiffication({
      title: error || snackbarAcceptInCityMsg.TITLE,
      message: snackbarAcceptInCityMsg.MESSAGE,
      onSubmit: async () => {},
    });
  };

  return (
    <Container container>
      <form onSubmit={handleSubmit}>
        <FormControl sx={{ width: "100%" }}>
          <SelectCar idAvisoOrSolicitud={Number(idRequest)} />
        </FormControl>

        <FormControl sx={{ marginTop: "16px", width: "100%" }}>
          <Input
            id={`$1-assistant`}
            name="assistant"
            label={
              <LabelInputRequired str="N° de ayudantes que incluye tu precio" />
            }
            value={assistant}
            variant="standard"
            placeholder="Ayudantes que necesites, Ej. 1"
            onChange={handleOnchangeAssistant}
            onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
            }}
          />
        </FormControl>

        <FormControl sx={{ marginTop: "16px", width: "100%" }}>
          <Input
            id={`$1-price`}
            name="price"
            label={
              <LabelInputRequired str="Precio ofrecido por viaje + ayudantes (S/)" />
            }
            value={price}
            variant="standard"
            placeholder="Ingresa tu precio"
            onChange={handleOnchange}
            onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
            }}
            error={!!errorPrice}
            helperText={errorPrice}
          />
        </FormControl>

        <Grid item sx={{ marginTop: "16px" }}>
          <ButtomLoader
            type="submit"
            buttomProps={{
              disabled: handleDisabledButton() || isLoading,
            }}
            isLoading={isLoading}
          >
            ENVIAR COTIZACIÓN
          </ButtomLoader>
        </Grid>
      </form>
    </Container>
  );
};

export default FormAcceptMoving;
