import React from 'react'
import usePlaceMovingHook from './hook'

const PlaceMoving = () => {

    const {
        Component
    } = usePlaceMovingHook()

 if (!Component) return <></>;
    return <Component />
}

export default PlaceMoving