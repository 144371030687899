import {
  NoticeFilterData,
  OrderByFilter,
} from '@/store/FilterServices/filterReduxInterface';
import {
  FilterListOptions,
  FiltersOptions,
} from '../interface/filterInterface';

export const responseFilterListToReduxAdapter = (
  filterList: NoticeFilterData
): FilterListOptions => {
  const { ordenamiento, tiposFiltro } = filterList;
  const filters = prepareFilterByListFilterAdapter(tiposFiltro);
  const orderBy = ordenamiento;

  return { filters, orderBy };
};

export const prepareFilterByListFilterAdapter = (
  filters: NoticeFilterData['tiposFiltro']
): FiltersOptions => {
  const mapLink = {
    'Tipo de aviso': 'typeNotice',
    'Tipo de vehículo': 'typeVehicles',
    'Peso de la carga': 'weightOfTheLoad',
    'Tipo de servicio': 'typeServiceList',
  };
  const filtros: FiltersOptions = filters.reduce(
    (acc, el) => {
      if (mapLink[el.nombre]) {
        acc = {
          ...acc,
          [mapLink[el.nombre]]: el.opciones,
        };
      }
      return acc;
    },
    {
      typeNotice: [],
      typeVehicles: [],
      weightOfTheLoad: [],
      typeServiceList: [],
    }
  );

  return filtros;
};

export const prepareOrderByListFilterAdapter = (order: OrderByFilter[]) => {
  const prepareOrder = order.map(({ nombre }) => nombre);

  return prepareOrder;
};

export const prepareSelectedValueModal = (seleted: any) => {
  let name = '';
  if (Array.isArray(seleted)) {
    name = seleted?.reduce((acc, el) => {
      if (!acc) {
        return el?.nombre;
      }
      return `${acc},${el?.nombre}`;
    }, '');
  } else {
    name = seleted.nombre2 || seleted.nombre;
  }

  return name;
};
