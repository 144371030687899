import { Grid, GridSize, Stack, SxProps, Theme } from "@mui/material";
import { FC } from "react";
import AdvertisementCard from "./AdvertisementCard";

type WrapperProsp = {
  md?: boolean | GridSize | undefined;
  xs?: boolean | GridSize | undefined;
  sm?: boolean | GridSize | undefined;
  lg?: boolean | GridSize | undefined;
};

type Props = {
  wrapperProps: WrapperProsp;
  index: number;
  children: JSX.Element | JSX.Element[];
  showAfter: number;
  totalLength: number;
};

const styleGrid: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  height: "auto",
  alignItems: "center",
};

const AdvertisementListCard: FC<Props> = ({
  wrapperProps,
  index,
  children,
  showAfter,
  totalLength,
}) => {
  const handleShowAdvertisement = (): boolean => {
    const lastIndex = totalLength;
    if (index === 0 && lastIndex > showAfter) {
      return false;
    }

    if (index === lastIndex - 1) {
      return true;
    }

    return (index + 1) % showAfter === 0;
  };

  const showAdvertisement = handleShowAdvertisement();

  return (
    <>
      <Grid item {...wrapperProps} key={`${index}-list-card`}>
        {children}
      </Grid>
      {showAdvertisement && (
        <Grid
          item
          {...wrapperProps}
          key={`${index}-advertisement`}
          sx={styleGrid}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
          <AdvertisementCard index={index + 1} />
          </Stack>
        </Grid>
      )}
    </>
  );
};

export default AdvertisementListCard;
