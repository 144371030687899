import { NoticeDetailAuctionModel } from "@/models/notice/noticeV2NegotationModels";
import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import { Box, Typography } from "@mui/material";
import { SuccessPrice } from "../../components/SuccessGlobalPrice";

export const AuctionSuccessPrices = () => {
  const { responseData } = useGetData();
  return (
    <>
      <SuccessPrice />
      <Box sx={{ display: "flex", alignItems: "center"}}>
        <Typography sx={{ fontWeight: 700, fontSize: "16px"  }}>
          Peso solicitado:
        </Typography>
        <Typography sx={{ fontSize: "16px", ml: 1 }}>
          {`${new Intl.NumberFormat().format(
            (responseData as NoticeDetailAuctionModel).pesoEstimado
          )} Kilos`}
        </Typography>
      </Box>
    </>
  );
};
