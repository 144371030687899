interface Props {
    style: {
        color?: string
        height: string
        width: string
    }
}
const MovingCities = (props: Props) => {

    const { color = "#757575", height, width } = props?.style;
    return (
        <svg width={width} height={height} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M63.3337 47.6455L57.0039 42.9814V38.3174C57.0039 37.6511 56.3376 36.9848 55.6713 36.9848C55.005 36.9848 54.3387 37.6511 54.3387 38.3174V40.6494L48.0089 35.9853C47.6758 35.6522 46.6763 35.6522 46.3432 35.9853L30.6853 47.3123C30.019 47.6455 30.019 48.6449 30.3521 49.3112C30.6853 49.9775 31.6847 49.9775 32.351 49.6444L34.3499 48.3118V60.9714C34.3499 61.6377 35.0162 62.304 35.6825 62.304H58.3365C59.0028 62.304 59.6691 61.6377 59.6691 60.9714V47.9786L61.6679 49.3112C62.0011 49.6444 62.3342 49.6444 62.6674 49.6444C63.0005 49.6444 63.6668 49.3112 64 48.9781C64 48.9781 64 47.9786 63.3337 47.6455ZM49.0084 59.9719H45.0106V52.9758H49.0084V59.9719ZM57.0039 59.9719H52.0067V51.6433C52.0067 50.977 51.3404 50.3107 50.6741 50.3107H43.678C43.0117 50.3107 42.3454 50.977 42.3454 51.6433V59.9719H37.3482V46.6461L47.3426 39.3168L57.337 46.6461C57.0039 46.6461 57.0039 59.9719 57.0039 59.9719Z" fill={color} />
            <path d="M15.6937 10.3331C13.3617 10.3331 11.696 12.332 11.3628 14.664L7.03192 51.9765C6.69877 54.6417 9.0308 56.3074 11.3628 56.3074H26.3544C27.0207 56.3074 27.687 55.6411 27.687 54.9749C27.687 54.3086 27.0207 53.6423 26.3544 53.6423H11.3628C10.3634 53.6423 9.36394 52.6428 9.36394 51.9765L13.6949 14.664C13.6949 13.3315 14.6943 12.6652 15.6937 12.6652H32.6842C33.6837 12.6652 34.35 13.6646 34.6831 14.664L36.682 33.3203C36.682 33.9866 37.3483 34.6529 38.0146 34.3197C38.6809 34.3197 39.3472 33.6534 39.014 32.9871L37.0151 14.3309C36.682 11.9989 35.0163 10 32.6842 10C32.3511 10.3331 15.6937 10.3331 15.6937 10.3331Z" fill={color} />
            <path d="M23.6886 25.658C23.0223 25.658 22.356 26.3243 22.356 26.9906V30.9883C22.356 31.6546 23.0223 32.3209 23.6886 32.3209C24.3548 32.3209 25.0211 31.6546 25.0211 30.9883V26.9906C25.0211 26.3243 24.688 25.658 23.6886 25.658Z" fill={color} />
            <path d="M23.6886 16.658C23.0223 16.658 22.356 17.3243 22.356 17.9906V21.9883C22.356 22.6546 23.0223 23.3209 23.6886 23.3209C24.3548 23.3209 25.0211 22.6546 25.0211 21.9883V17.9906C25.0211 17.3243 24.688 16.658 23.6886 16.658Z" fill={color} />
            <path d="M23.6886 34.9861C23.0223 34.9861 22.356 35.6524 22.356 36.3187V40.3164C22.356 40.9827 23.0223 41.649 23.6886 41.649C24.3548 41.649 25.0211 40.9827 25.0211 40.3164V36.3187C25.0211 35.3192 24.688 34.9861 23.6886 34.9861Z" fill={color} />
            <path d="M23.6886 43.9811C23.0223 43.9811 22.356 44.6474 22.356 45.3137V49.3114C22.356 49.9777 23.0223 50.644 23.6886 50.644C24.3548 50.644 25.0211 49.9777 25.0211 49.3114V45.3137C25.0211 44.6474 24.688 43.9811 23.6886 43.9811Z" fill={color} />
        </svg>
    )
}

export default MovingCities
