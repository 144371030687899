import { userRoles } from '@/consts/defaultConsts';
import {
  PROFILE_DATOS_CAMBIAR_CONTRASENA,
  PROFILE_DATOS_CAMBIAR_TELEFONO,
  PROFILE_DATOS_DOC_IDENTIDAD,
  PROFILE_DATOS_PATH,
  PROFILE_DATOS_PERSONALS_PATH,
  PROFILE_MIS_VEHICULOS_PATH,
} from '@/consts/typeServicesPaths';
import { ILinks } from './interfaces/profileInterface';
import { PersonIcon, TransportationIcon } from '@/assets/svg';

// sublinks
export const LinksDatos: ILinks[] = [
  {
    nombre: 'Datos personales',
    nombre2: 'Datos de empresa',
    path: PROFILE_DATOS_PERSONALS_PATH,
    icon: 'icon-person-circle',
    iconActive: 'icon-user-small-link',
    role: [...userRoles],
  },
  {
    nombre: 'Licencia o DNI',
    path: PROFILE_DATOS_DOC_IDENTIDAD,
    icon: 'icon-badge',
    iconActive: 'icon-doc-identidad-small-link',
    role: [userRoles[0]],
  },
  {
    nombre: 'Cambiar celular',
    path: PROFILE_DATOS_CAMBIAR_TELEFONO,
    icon: 'icon-smart-phone',
    iconActive: 'icon-small-phone-small-link',
    role: [...userRoles],
  },
  {
    nombre: 'Cambiar contraseña',
    path: PROFILE_DATOS_CAMBIAR_CONTRASENA,
    icon: 'icon-lock',
    iconActive: 'icon-small-lock-link',
    role: [...userRoles],
  },
];

// links principales
export const Links: ILinks[] = [
  {
    nombre: 'Datos de la cuenta',
    path: PROFILE_DATOS_PATH,
    icon: 'icon-person-circle',
    subLinks: LinksDatos,
    role: [...userRoles],
  },
  {
    nombre: 'Tus vehículos',
    path: PROFILE_MIS_VEHICULOS_PATH,
    icon: 'icon-vehicles',
    role: [userRoles[0]],
  },
];
