import ScrollTop from "@/components/utils/ScrollTop";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { UsuarioAPI } from "@/models/userAuth/user";
import Advertisement from "@/pages/advertisement/Advertisement";
import { getUserByIdTunhk, logoutThunk } from "@/store/userRedux/userThunks";
import { createBrowserHistory } from "history";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "../pages/header/Header";
import "../styles/main.css";
import "../styles/styleIcons.css";
import PrivateRoute from "./PrivateRoute";
import { router } from "./router";
import ErrorBoundary from "@/components/errorBoundary/ErrorBoundary";

const history = createBrowserHistory({ window });
const AppRouter = () => {
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (Object.keys(user).length && !!(user as UsuarioAPI)?.registroForm) {
      dispatch(getUserByIdTunhk(`${(user as UsuarioAPI).id}`));
    }

    return () => {
      if ( window?.navigator?.userAgent.includes("PegacargoApp") ) {
        dispatch(logoutThunk())
      }
    };
  }, []);

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Header />
        <ScrollTop />
        <Routes>
          {router.map(({ Component, path, children, ...rest }, i) => (
            <Route
              key={"route" + i}
              path={path}
              element={
                <PrivateRoute {...rest} path={path}>
                  <Component history={history} />
                </PrivateRoute>
              }
            >
              {children ? (
                children.map(({ Component, ...props }) => (
                  <Route
                    {...props}
                    key={"route-children" + i}
                    element={
                      <PrivateRoute {...props} path={path}>
                        <Component history={history} />
                      </PrivateRoute>
                    }
                  />
                ))
              ) : (
                <></>
              )}
            </Route>
          ))}
        </Routes>
        <Advertisement />
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default AppRouter;
