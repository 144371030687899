import { Skeleton, Stack } from "@mui/material";

const SkeletonConditions = () => {
  return (
    <Stack
      spacing={2}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Stack
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Skeleton variant="rectangular" width={40} height={40} />
        <Skeleton variant="rectangular" width="85%" height={72} />
      </Stack>

      <Skeleton variant="rectangular" width={72} height={72} />
      <Skeleton variant="rounded" width="100%" height={40} />
      <Skeleton variant="rounded" width="100%" height={300} />
    </Stack>
  );
};

export default SkeletonConditions;
