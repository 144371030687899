import ConditionsListOffert from '@/components/conditionsList/ConditionsListOffert';
import { Negotiation } from '@/models/offert/offertModels';
import { Typography } from '@mui/material';
import {
  getDestinyInMoving,
  getFormatMetro,
  getFormatPrice,
  getFormatSendDate,
  getOriginInMoving,
  getTitleByPrice,
} from '../../utils/utilsOffert';

import DetailsText from '../DetailsText';

interface ValuesToReplace {
  search: string;
  value: keyof Negotiation;
  defaultResult: string;
}

export interface ValidFormat {
  template: string;
  valuesToReplace: ValuesToReplace[];
}

interface ConfigDataBodyOffertValid {
  property: keyof Negotiation;
  componentProp: string;
  defaultResult: boolean | string | number | Array<any> | Object;
  format?: ValidFormat;
  callback?: (
    tipoAvisoNegociacion: number,
    value: any,
    offert: Negotiation
  ) => any;
}

export interface ConfigDataBodyOffert {
  title?: string;
  Component: any;
  props: any;
  valid?: ConfigDataBodyOffertValid[];
  children?: JSX.Element | JSX.Element[] | string;
}

export const configDataMoving: ConfigDataBodyOffert[] = [
  {
    title: 'Detalles de la solicitud',
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
    },
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-calendar--white',
      title: 'Fecha del servicio',

      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-location-medium',
      title: 'Origen',
    },
    valid: [
      {
        property: 'origenTexto2',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'origenTexto2',
        componentProp: 'origen',
        defaultResult: '',
        callback: getOriginInMoving,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-location-medium',
      title: 'Destino',
    },
    valid: [
      {
        property: 'destinoTexto2',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'destinoTexto2',
        componentProp: 'origen',
        defaultResult: '',
        callback: getDestinyInMoving,
      },
    ],
  },
  // metraje
  {
    Component: DetailsText,
    props: {
      icon: 'icon-rules-small',
      title: 'Metraje',
    },
    valid: [
      {
        property: 'metraje',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'metraje',
        componentProp: 'origen',
        defaultResult: '',
        callback: getFormatMetro,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-cargo-person',
      title: 'Ayudantes',
    },
    valid: [
      {
        property: 'ayudantes',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'ayudantes',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: 'Precio de punto a punto',
      variant: 'body2',
      marginTop: '24px',
    },
    valid: [
      {
        property: 'precio',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'precio',
        componentProp: 'title',
        defaultResult: '',
        callback: getTitleByPrice,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'h5',
      sx: { textTransform: 'inherit' },
    },
    valid: [
      {
        property: 'precio',
        componentProp: 'title',
        defaultResult: 0,
        callback: getFormatPrice,
      },
    ],
  },
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '22px' },
    },
    valid: [
      {
        property: 'avisoNegociacionCondiciones',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'avisoNegociacionCondiciones',
        componentProp: 'listServices',
        defaultResult: [],
      },
    ],
  },
  {
    Component: Typography,
    props: {
      sx: { color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px', lineHeight: '20px' },
    },
    valid: [
      {
        property: 'fechaRegistro',
        componentProp: 'title',
        defaultResult: 0,
        callback: getFormatSendDate,
      },
    ],
  },
];
