import useResize from '@/hooks/useResize';
import { INoticeCreateListItem } from '../../types';
import { ContainerText, Item, ItemSubTitle, ItemTitle, RegistroContainerText, RegistroItem, RegistroItemIcon, RegistroItemSubTitle, RegistroItemTitle } from './style';
import Icon from '@/utils/Icon';

interface Props {
  onClick: (item: INoticeCreateListItem) => void;
  listItems: INoticeCreateListItem[];
  isRegisterDriver?: boolean;
}


const NoticeListItems = ({ listItems, onClick, isRegisterDriver = false }: Props) => {

  const [, isMobil] = useResize();

  if (!Array.isArray(listItems) || !listItems.length) return <></>;

  const handleClick = (item: INoticeCreateListItem) => () => {
    onClick(item);
  };
  
  return (
    <>
      {listItems.map((item, i) => {
        return isRegisterDriver ? (
          <RegistroItem key={i} onClick={handleClick(item)}>
            <Icon name={item.icon} size={40}  color='info'/>
            <RegistroContainerText>
              <RegistroItemTitle>{item.title}</RegistroItemTitle>
              <RegistroItemSubTitle>
                {item.description}
              </RegistroItemSubTitle>
            </RegistroContainerText>
          </RegistroItem>
        ) : (
          <Item key={i} onClick={handleClick(item)}>
            <Icon name={item.icon} size={isMobil ? 40 : 72}  color='info'/>
            <ContainerText>
              <ItemTitle>{item.title}</ItemTitle>
              <ItemSubTitle>
                {item.description}
              </ItemSubTitle>
            </ContainerText>
          </Item>
        );
      })}
    </>
  )
}

export default NoticeListItems