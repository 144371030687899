import { useMediaQuery } from "@mui/material";
import React from "react";

function ShareRideError() {
  const theme = useMediaQuery("(max-width: 600px)");

  const width = theme ? 180 : 480;
  const height = theme ? 166 : 466;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 180 166"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Recurso 6 2" clip-path="url(#clip0_35396_6936)">
        <g id="Capa 1">
          <g id="Group">
            <path
              id="Vector"
              d="M88.6167 13.9193C88.7185 15.3209 87.6977 16.5393 86.3409 16.6435C84.984 16.7478 83.8003 15.6948 83.701 14.2958C83.6017 12.8943 84.62 11.6759 85.9769 11.5716C87.3337 11.4673 88.5174 12.5203 88.6167 13.9193Z"
              fill="#F4A36C"
            />
            <path
              id="Vector_2"
              d="M86.1376 15.7708C86.9924 15.7708 87.6854 15.0784 87.6854 14.2242C87.6854 13.3701 86.9924 12.6777 86.1376 12.6777C85.2828 12.6777 84.5898 13.3701 84.5898 14.2242C84.5898 15.0784 85.2828 15.7708 86.1376 15.7708Z"
              fill="#F48A42"
            />
            <path
              id="Vector_3"
              d="M73.3706 14.4682C73.4724 15.8697 72.4516 17.0881 71.0948 17.1924C69.738 17.2967 68.5542 16.2436 68.4549 14.8446C68.3556 13.4431 69.3739 12.2247 70.7308 12.1204C72.0876 12.0161 73.2713 13.0692 73.3706 14.4682Z"
              fill="#F4A36C"
            />
            <path
              id="Vector_4"
              d="M70.8915 16.3196C71.7463 16.3196 72.4393 15.6272 72.4393 14.7731C72.4393 13.919 71.7463 13.2266 70.8915 13.2266C70.0367 13.2266 69.3438 13.919 69.3438 14.7731C69.3438 15.6272 70.0367 16.3196 70.8915 16.3196Z"
              fill="#F48A42"
            />
            <path
              id="Vector_5"
              d="M26.8763 49.9532C26.8763 50.0269 26.8152 50.088 26.7414 50.088C26.6675 50.088 26.6064 50.0269 26.6064 49.9532C26.6064 49.8794 26.6675 49.8184 26.7414 49.8184C26.8152 49.8184 26.8763 49.8794 26.8763 49.9532Z"
              fill="#F5F5F5"
            />
            <path
              id="Vector_6"
              d="M26.0569 49.9532C26.0569 50.0269 25.9959 50.088 25.922 50.088C25.8482 50.088 25.7871 50.0269 25.7871 49.9532C25.7871 49.8794 25.8482 49.8184 25.922 49.8184C25.9959 49.8184 26.0569 49.8794 26.0569 49.9532Z"
              fill="#F5F5F5"
            />
            <path
              id="Vector_7"
              d="M26.4515 49.9532C26.4515 50.0269 26.3904 50.088 26.3166 50.088C26.2427 50.088 26.1816 50.0269 26.1816 49.9532C26.1816 49.8794 26.2427 49.8184 26.3166 49.8184C26.3904 49.8184 26.4515 49.8794 26.4515 49.9532Z"
              fill="#F5F5F5"
            />
            <path
              id="Vector_8"
              d="M178.71 143.973H167.26V144.059H178.71V143.973Z"
              fill="#ECECEC"
            />
            <path
              id="Vector_9"
              opacity="0.22"
              d="M79.9438 145.501C122.846 145.501 157.625 143.426 157.625 140.867C157.625 138.307 122.846 136.232 79.9438 136.232C37.0417 136.232 2.2627 138.307 2.2627 140.867C2.2627 143.426 37.0417 145.501 79.9438 145.501Z"
              fill="#32324C"
            />
            <path
              id="Vector_10"
              d="M89.6147 120.717C94.617 125.928 96.6179 130.347 107.656 130.347L154.257 130.248C157.668 130.248 158.315 130.148 161.848 129.454C170.394 127.765 174.569 119.407 174.569 108.484L4.41113 108.505V120.717H89.6147Z"
              fill="black"
              stroke="black"
              stroke-width="0.14"
            />
            <path
              id="Vector_11"
              d="M4.32183 114.672H2.35148C0.969182 114.672 0.577148 116.203 0.577148 117.612V119.668C0.577148 121.077 0.969182 122.608 2.35148 122.608H4.32183V114.672Z"
              fill="#424242"
              stroke="#424242"
              stroke-width="0.3"
            />
            <path
              id="Vector_12"
              d="M107.608 44.7734H5.8877V106.896H107.608V44.7734Z"
              fill="#7FADD6"
            />
            <path
              id="Vector_13"
              d="M52.9061 103.469H46.7227V106.557H52.9061V103.469Z"
              fill="white"
            />
            <path
              id="Vector_14"
              d="M65.2703 103.469H59.0869V106.557H65.2703V103.469Z"
              fill="white"
            />
            <path
              id="Vector_15"
              d="M59.0868 103.469H52.9033V106.557H59.0868V103.469Z"
              fill="#F44336"
            />
            <path
              id="Vector_16"
              d="M122.113 115.072H103.814V130.141H122.113V115.072Z"
              fill="#E0E0E0"
            />
            <path
              id="Vector_17"
              d="M143.466 108.893H111.525V72.0713C111.525 66.9561 111.805 66.0277 118.299 66.0277H146.971C152.192 66.0277 157.291 65.987 159.794 66.834C163.862 68.1643 164.083 69.0495 165.517 71.6694L171.578 88.1876C171.578 88.1876 174.521 88.6709 174.521 96.2458V118.566C170.007 123.078 169.037 124.004 166.731 124.205C164.427 124.447 162.401 124.528 159.674 124.528C159.674 115.704 154.634 108.898 143.466 108.898V108.893Z"
              fill="url(#paint0_linear_35396_6936)"
            />
            <path
              id="Vector_18"
              d="M169.861 119.668C169.861 121.077 170.253 122.608 171.636 122.608H177.643C179.033 122.608 179.423 121.077 179.423 119.668V117.572C179.423 116.203 179.033 114.672 177.643 114.672H171.636C170.253 114.672 169.861 116.203 169.861 117.572V119.668Z"
              fill="black"
              stroke="black"
              stroke-width="0.19"
            />
            <path
              id="Vector_19"
              d="M141.533 84.6158C140.149 84.6158 139.023 83.4864 139.023 82.1179V73.8181C139.023 72.4497 140.149 71.3203 141.533 71.3203H165.514L171.575 87.8385H165.638L141.533 84.6158Z"
              fill="white"
              stroke="white"
              stroke-width="0.3"
            />
            <g id="Group_2">
              <path
                id="Vector_20"
                d="M174.52 98.8379C174.854 98.8379 175.126 99.1202 175.126 99.4407C175.126 99.7612 174.854 100.046 174.52 100.046V98.8379ZM171.758 100.57H171.152V100.448L171.185 100.367L171.758 100.568V100.57ZM174.52 110.483C174.854 110.483 175.126 110.765 175.126 111.085C175.126 111.406 174.854 111.691 174.52 111.691V110.483ZM174.52 100.049H172.868V98.8404H174.52V100.049ZM172.868 100.049C172.761 100.049 172.695 100.089 172.619 100.171C172.522 100.293 172.428 100.453 172.331 100.735L171.185 100.374C171.295 100.051 171.45 99.69 171.702 99.367C171.977 99.0439 172.362 98.843 172.868 98.843V100.051V100.049ZM172.364 100.532L172.456 109.959L171.244 109.999L171.152 100.573L172.364 100.532ZM172.456 109.959C172.456 110.241 172.682 110.483 172.962 110.483V111.691C172.015 111.691 171.244 110.925 171.244 109.959H172.456ZM172.962 110.483H174.518V111.691H172.962V110.483Z"
                fill="#E84453"
              />
            </g>
            <path
              id="Vector_21"
              d="M158.862 90.8194C158.862 91.8267 158.997 92.6737 160.021 92.6737H162.388C163.409 92.6737 163.775 91.8267 163.775 90.8194V81.1105C163.775 80.1032 163.409 79.2969 162.388 79.2969H160.021C158.997 79.2969 158.862 80.1032 158.862 81.1105V90.8194Z"
              fill="#E0E0E0"
            />
            <path
              id="Vector_22"
              d="M119.516 81.7575C119.516 83.0878 120.6 84.1739 121.937 84.1739H130.009C131.348 84.1739 132.43 83.0853 132.43 81.7575V73.6586C132.43 72.3283 131.345 71.2422 130.009 71.2422H121.937C120.598 71.2422 119.516 72.3309 119.516 73.6586V81.7575Z"
              fill="white"
              stroke="white"
              stroke-width="0.3"
            />
            <path
              id="Vector_23"
              d="M166.338 99.4805H117.632"
              stroke="#E0E0E0"
              stroke-miterlimit="10"
            />
            <path
              id="Vector_24"
              d="M93.0916 103.469H86.9082V106.557H93.0916V103.469Z"
              fill="white"
            />
            <path
              id="Vector_25"
              d="M105.456 103.469H99.2725V106.557H105.456V103.469Z"
              fill="white"
            />
            <path
              id="Vector_26"
              d="M99.2733 103.469H93.0898V106.557H99.2733V103.469Z"
              fill="#F44336"
            />
            <path
              id="Vector_27"
              d="M15.8104 103.469H9.62695V106.557H15.8104V103.469Z"
              fill="white"
            />
            <path
              id="Vector_28"
              d="M28.1746 103.469H21.9912V106.557H28.1746V103.469Z"
              fill="white"
            />
            <path
              id="Vector_29"
              d="M21.994 103.469H15.8105V106.557H21.994V103.469Z"
              fill="#F44336"
            />
            <path
              id="Vector_30"
              d="M157.897 129.091L156.43 118.141C155.865 114.654 152.749 110.904 148.722 110.904H136.159C130.401 110.904 127.57 115.055 126.402 118.901L123.606 129.091"
              fill="#D3D3D3"
            />
            <path
              id="Vector_31"
              d="M127.479 126.55C127.479 134.55 134.054 141.12 142.06 141.12C150.066 141.12 156.642 134.55 156.642 126.55C156.642 118.551 150.066 111.98 142.06 111.98C134.054 111.98 127.479 118.266 127.479 126.55Z"
              fill="black"
              stroke="black"
              stroke-width="0.3"
            />
            <path
              id="Vector_32"
              d="M135.482 126.55C135.482 130.264 138.626 133.405 142.343 133.405C146.06 133.405 149.204 130.264 149.204 126.55C149.204 122.837 146.06 119.695 142.343 119.695C138.626 119.695 135.482 122.837 135.482 126.55Z"
              fill="#EFEFEF"
              stroke="white"
            />
            <path
              id="Vector_33"
              d="M174.642 99.9238H173.09C172.512 99.9238 172.239 100.415 172.046 100.979L172.132 109.76C172.132 110.363 172.601 110.816 173.179 110.816H174.642"
              fill="#E84453"
            />
            <path
              id="Vector_34"
              d="M165.496 71.248L171.741 88.1554"
              stroke="black"
              stroke-width="0.2"
            />
            <path
              id="Vector_35"
              d="M44.7677 129.622L43.3014 118.672C42.7363 115.185 39.6204 111.436 35.5931 111.436H23.0302C17.2719 111.436 14.4411 115.587 13.2727 119.433L10.4775 129.622"
              fill="#D3D3D3"
            />
            <path
              id="Vector_36"
              d="M13.4121 127.806C13.4121 135.806 19.9876 142.376 27.9937 142.376C35.9998 142.376 42.5753 135.806 42.5753 127.806C42.5753 119.806 35.9998 113.236 27.9937 113.236C19.9876 113.236 13.4121 119.522 13.4121 127.806Z"
              fill="black"
              stroke="black"
              stroke-width="0.3"
            />
            <path
              id="Vector_37"
              d="M21.416 127.806C21.416 131.52 24.5599 134.661 28.2766 134.661C31.9933 134.661 35.1372 131.52 35.1372 127.806C35.1372 124.093 31.9933 120.951 28.2766 120.951C24.5599 120.951 21.416 124.093 21.416 127.806Z"
              fill="#EFEFEF"
              stroke="white"
            />
            <path
              id="Vector_38"
              d="M81.3178 105.939H78.4336V106.023H81.3178V105.939Z"
              fill="#ECECEC"
            />
            <g id="Group_3">
              <g id="Group_4">
                <path
                  id="Vector_39"
                  d="M111.633 30.0291H111.643L111.057 29.678V29.6857C110.927 29.6119 110.744 29.6145 110.523 29.7187L105.258 32.1733C104.769 32.3997 104.304 33.0153 104.217 33.5469L102.224 45.6926C102.178 45.975 102.249 46.1734 102.399 46.2649H102.394L102.998 46.6287V46.621C103.127 46.6973 103.313 46.6948 103.537 46.5931L108.802 44.1385C109.29 43.9121 109.756 43.2965 109.843 42.7649C110.235 40.379 111.444 33.0051 111.836 30.6192C111.885 30.319 111.801 30.1155 111.633 30.0316V30.0291Z"
                  fill="#2B454E"
                />
                <path
                  id="Vector_40"
                  d="M105.301 32.9447C105.052 33.2219 104.861 33.5755 104.805 33.8985L102.812 46.0392C102.771 46.3114 102.829 46.5072 102.967 46.6039L102.399 46.2656L102.404 46.2605C102.254 46.1664 102.183 45.9705 102.229 45.6882L104.222 33.5424C104.268 33.2423 104.446 32.9167 104.675 32.6445L105.304 32.9472L105.301 32.9447Z"
                  fill="#1A2E35"
                />
                <path
                  id="Vector_41"
                  d="M102.815 46.0407L104.808 33.895C104.895 33.3634 105.36 32.7504 105.849 32.5214L111.114 30.0668C111.602 29.8405 111.926 30.0847 111.839 30.6163C111.447 33.0022 110.238 40.3736 109.846 42.762C109.759 43.2936 109.294 43.9092 108.805 44.1356L103.54 46.5902C103.052 46.8165 102.728 46.5723 102.815 46.0407Z"
                  fill="#455A64"
                />
              </g>
              <path
                id="Vector_42"
                d="M105.231 36.2967L105.602 34.0227C105.618 33.9235 105.707 33.809 105.798 33.7658L106.783 33.3054C106.875 33.2621 106.936 33.3079 106.918 33.4071C106.845 33.8548 106.618 35.2334 106.547 35.6811C106.531 35.7803 106.442 35.8948 106.351 35.938L105.366 36.3984C105.274 36.4416 105.213 36.3959 105.231 36.2967Z"
                fill="#2B454E"
              />
              <path
                id="Vector_43"
                d="M105.742 34.5284C105.782 34.2868 106.004 34.0019 106.241 33.8925C106.477 33.7832 106.635 33.89 106.595 34.1316C106.554 34.3733 106.332 34.6582 106.096 34.7701C105.859 34.8794 105.701 34.7726 105.742 34.531V34.5284Z"
                fill="#1A2E35"
              />
              <path
                id="Vector_44"
                d="M105.566 35.673C105.607 35.4313 105.828 35.1464 106.065 35.0371C106.302 34.9277 106.46 35.0345 106.419 35.2762C106.378 35.5178 106.157 35.8027 105.92 35.9121C105.683 36.0214 105.525 35.9146 105.566 35.673Z"
                fill="#1A2E35"
              />
            </g>
            <path
              id="Vector_45"
              d="M87.4866 165.399C107.493 165.399 123.712 164.408 123.712 163.186C123.712 161.963 107.493 160.973 87.4866 160.973C67.4802 160.973 51.2617 161.963 51.2617 163.186C51.2617 164.408 67.4802 165.399 87.4866 165.399Z"
              fill="#EDEDED"
            />
            <g id="Group_5">
              <g id="Group_6">
                <path
                  id="Vector_46"
                  d="M65.7614 148.829L65.4229 154.911L70.3589 155.02L70.9648 148.631L65.7614 148.829Z"
                  fill="#FFAE77"
                />
                <g id="Group_7">
                  <path
                    id="Vector_47"
                    d="M67.3807 154.056C67.3807 154.056 69.9212 153.481 70.6187 153.522C71.3137 153.562 76.3007 158.95 77.4819 160.028C79.6533 162.012 80.0225 163.798 78.2838 163.953C73.251 164.401 64.7306 162.582 64.7306 162.582C64.7306 162.582 64.1578 156.854 64.5677 155.078C64.9775 153.303 65.9602 154.529 67.3807 154.058V154.056Z"
                    fill="#FC535A"
                  />
                  <g id="Group_8">
                    <path
                      id="Vector_48"
                      d="M71.3445 159.076C71.2554 159.091 71.1586 159.073 71.0772 159.015C70.9219 158.903 70.8888 158.684 71.0008 158.529C71.039 158.478 71.9376 157.255 73.0068 156.861C74.4298 156.331 75.3946 157.209 75.4354 157.247C75.5754 157.377 75.583 157.598 75.4532 157.738C75.3234 157.878 75.1019 157.886 74.9619 157.756C74.9364 157.733 74.2618 157.138 73.2486 157.512C72.378 157.835 71.571 158.929 71.5634 158.939C71.5074 159.012 71.4285 159.061 71.3445 159.076Z"
                      fill="#FCFBFF"
                    />
                    <path
                      id="Vector_49"
                      d="M70.4255 157.99C70.3364 158.005 70.2397 157.988 70.1582 157.929C70.0029 157.817 69.9698 157.598 70.0819 157.443C70.12 157.392 71.0187 156.169 72.0878 155.775C73.5134 155.246 74.4757 156.123 74.5164 156.161C74.6564 156.291 74.6641 156.512 74.5342 156.652C74.4044 156.792 74.1829 156.8 74.0429 156.67C74.0175 156.647 73.3429 156.052 72.3297 156.426C71.454 156.751 70.6521 157.843 70.6444 157.853C70.5884 157.929 70.5095 157.975 70.4255 157.99Z"
                      fill="#FCFBFF"
                    />
                    <path
                      id="Vector_50"
                      d="M72.6501 160.019C72.561 160.035 72.4643 160.017 72.3828 159.958C72.2276 159.846 72.1945 159.628 72.3065 159.473C72.3446 159.422 73.2433 158.198 74.3125 157.804C75.738 157.275 76.7003 158.152 76.741 158.191C76.881 158.32 76.8887 158.542 76.7588 158.681C76.629 158.821 76.4075 158.829 76.2675 158.699C76.2421 158.676 75.5675 158.081 74.5543 158.455C73.6786 158.781 72.8767 159.872 72.8691 159.882C72.8131 159.958 72.7341 160.004 72.6501 160.019Z"
                      fill="#FCFBFF"
                    />
                  </g>
                </g>
              </g>
              <path
                id="Vector_51"
                d="M67.7648 83.3262C67.7648 83.3262 67.7139 87.2815 67.6045 92.9359C67.3906 103.901 67.8667 117.571 67.1233 124.909C66.2298 133.707 64.5726 147.252 64.3027 150.991H71.7335C73.0191 145.769 77.1151 131.729 78.4567 121.615C79.6226 112.822 80.2946 99.0152 80.8471 92.1627C80.898 91.5141 80.9463 90.9163 80.9871 90.3745C81.1474 88.332 81.2365 87.1009 81.2365 87.1009L67.7648 83.3287V83.3262Z"
                fill="#133248"
              />
              <path
                id="Vector_52"
                d="M72.5919 149.361C72.6861 150.846 72.6403 151.429 72.1693 152.594C72.1693 152.594 68.1726 152.731 63.7559 152.014L63.9926 149.094C63.9926 149.094 67.4929 149.025 72.5919 149.361Z"
                fill="#335464"
              />
              <path
                id="Vector_53"
                d="M66.8893 150.991C70.774 128.755 71.2398 90.6945 71.2449 90.2824L70.7256 90.2773C70.7205 90.6843 70.2546 128.816 66.3623 150.991H66.8893Z"
                fill="#335464"
              />
              <path
                id="Vector_54"
                d="M81.2346 87.1009C81.2346 87.1009 81.1455 88.332 80.9851 90.3745C80.9444 90.9138 80.896 91.5115 80.8451 92.1627C78.3962 92.6205 71.6603 92.9359 67.6025 92.9359C67.712 87.2815 67.7629 83.3262 67.7629 83.3262L81.2346 87.0984V87.1009Z"
                fill="#051630"
              />
            </g>
            <g id="Group_9">
              <g id="Group_10">
                <path
                  id="Vector_55"
                  d="M99.8205 150.371L99.622 155.797L94.6579 155.535L94.5459 149.773L99.8205 150.371Z"
                  fill="#FFAE77"
                />
                <g id="Group_11">
                  <path
                    id="Vector_56"
                    d="M98.3538 155.547C98.3538 155.547 99.4357 154.14 100.276 154.212C101.116 154.285 107.539 159.543 109.758 160.001C111.978 160.459 111.523 162.092 111.1 162.921C110.68 163.753 93.349 162.344 93.349 162.344C93.349 162.344 92.9493 157.177 93.5399 155.608C94.1305 154.039 96.6074 155.893 98.3538 155.547Z"
                    fill="#FC535A"
                  />
                  <g id="Group_12">
                    <path
                      id="Vector_57"
                      d="M101.892 159.201C101.813 159.224 101.724 159.214 101.648 159.17C101.5 159.084 101.449 158.893 101.538 158.743C101.566 158.692 102.264 157.525 103.188 157.077C104.415 156.482 105.354 157.181 105.392 157.212C105.53 157.316 105.555 157.512 105.451 157.649C105.346 157.787 105.15 157.812 105.013 157.708C104.988 157.69 104.333 157.214 103.46 157.639C102.709 158.003 102.083 159.051 102.078 159.061C102.035 159.132 101.968 159.181 101.895 159.204L101.892 159.201Z"
                      fill="#FCFBFF"
                    />
                    <path
                      id="Vector_58"
                      d="M100.976 158.311C100.897 158.334 100.808 158.324 100.732 158.281C100.584 158.194 100.533 158.004 100.622 157.854C100.653 157.803 101.348 156.635 102.269 156.188C103.496 155.59 104.436 156.292 104.474 156.322C104.611 156.427 104.637 156.623 104.532 156.76C104.428 156.897 104.232 156.923 104.094 156.818C104.069 156.801 103.415 156.325 102.542 156.75C101.788 157.116 101.164 158.161 101.159 158.172C101.116 158.243 101.05 158.291 100.976 158.314V158.311Z"
                      fill="#FCFBFF"
                    />
                    <path
                      id="Vector_59"
                      d="M103.142 159.933C103.063 159.955 102.974 159.945 102.898 159.902C102.75 159.816 102.699 159.625 102.788 159.475C102.819 159.426 103.514 158.256 104.435 157.809C105.662 157.211 106.602 157.913 106.64 157.943C106.777 158.048 106.803 158.244 106.698 158.381C106.594 158.518 106.398 158.544 106.26 158.439C106.235 158.422 105.581 157.946 104.708 158.371C103.954 158.737 103.33 159.782 103.325 159.793C103.282 159.864 103.216 159.912 103.142 159.935V159.933Z"
                      fill="#FCFBFF"
                    />
                  </g>
                </g>
              </g>
              <path
                id="Vector_60"
                d="M99.7645 153.245L95.3655 152.917L94.8411 152.881L93.7719 152.803C93.7719 152.803 91.0684 127.102 90.2284 120.255C89.4978 114.308 86.0866 100.943 83.8693 92.6284C83.2405 90.2679 82.7085 88.3144 82.3597 87.0528C82.0924 86.0608 81.9346 85.4961 81.9346 85.4961L96.6435 84.0742C96.6435 84.0742 96.6842 84.3973 96.7529 84.9772C96.8955 86.1676 97.1704 88.4441 97.5039 91.3083C98.4229 99.1019 99.8205 111.232 100.36 117.406C101.748 133.306 99.7645 153.245 99.7645 153.245Z"
                fill="#133248"
              />
              <path
                id="Vector_61"
                d="M101.251 150.479C101.46 151.951 101.458 152.539 101.078 153.734C101.078 153.734 97.1045 154.174 92.6445 153.795L92.6598 150.865C92.6598 150.865 96.1448 150.532 101.254 150.479H101.251Z"
                fill="#335464"
              />
              <path
                id="Vector_62"
                d="M95.3657 152.916L94.8413 152.881C94.5867 144.932 93.8052 125.084 92.2116 116.667C90.2082 106.061 85.6031 90.6158 85.5547 90.4581L86.0562 90.3105C86.1046 90.4657 90.7173 105.938 92.7233 116.573C94.3271 125.041 95.1137 144.985 95.3657 152.916Z"
                fill="#335464"
              />
              <path
                id="Vector_63"
                d="M97.501 91.3076L83.869 92.6278C83.2402 90.2673 82.7081 88.3138 82.3594 87.0522L96.7501 84.9766C96.8926 86.167 97.1675 88.4435 97.501 91.3076Z"
                fill="#051630"
              />
            </g>
            <path
              id="Vector_64"
              d="M96.8949 75.5663C96.2305 60.62 94.7184 45.7297 92.9212 30.8826C91.684 30.0305 89.8282 29.1047 87.9316 28.222C85.2638 26.9757 82.5195 25.8234 81.3154 25.0781C81.1423 25.4012 80.1113 27.1893 78.6323 27.403C77.0056 27.6446 75.4375 25.8132 75.4375 25.8132L75.4146 25.4495C73.1948 27.2173 65.8352 31.0404 64.3155 33.4161C63.6612 34.4412 63.6332 41.9296 63.8674 51.0535C63.8751 51.4478 63.8852 51.8471 63.898 52.249C63.9031 52.4347 63.9107 52.6178 63.9158 52.8035C63.9209 53.0807 63.9285 53.3605 63.9412 53.6429C63.9438 53.9303 63.9565 54.2228 63.9667 54.5153C63.9692 54.6018 63.9718 54.6909 63.9743 54.7773C63.9794 54.9834 63.9871 55.1894 63.9922 55.3929C63.9922 55.459 63.9947 55.5277 63.9972 55.5938C64.0023 55.8151 64.01 56.0415 64.0202 56.2654C64.0227 56.3467 64.0252 56.4256 64.0278 56.507C64.0354 56.7054 64.0431 56.9038 64.0507 57.1047C64.0583 57.3973 64.0711 57.6898 64.0787 57.9823C64.0787 58.0281 64.0838 58.0764 64.0838 58.1222C64.094 58.3638 64.0991 58.608 64.1118 58.8522C64.1118 58.8802 64.1118 58.9082 64.1143 58.9387C64.1143 58.9692 64.1169 59.0023 64.1169 59.0353C64.1245 59.2668 64.1347 59.5008 64.1449 59.7323C64.1551 60.0095 64.1653 60.2919 64.1754 60.5717C64.178 60.6658 64.1805 60.7599 64.1856 60.8515C64.1933 61.0575 64.2009 61.2635 64.2085 61.467C64.2213 61.7519 64.2314 62.0368 64.2416 62.3191C64.2544 62.6727 64.2696 63.0263 64.2849 63.3798C64.7177 74.0325 65.2879 84.1433 65.5068 87.2211C76.5118 89.2942 87.3436 88.7244 98.0023 85.4889C97.5517 82.1873 97.035 78.9086 96.8873 75.5637L96.8949 75.5663Z"
              fill="#EE6F57"
            />
            <path
              id="Vector_65"
              d="M70.9016 35.8893C70.9016 35.8893 70.9067 35.8893 70.9092 35.8893C75.331 35.4798 78.6379 33.4322 80.6337 31.7865C82.795 30.0034 83.8972 28.3374 83.9431 28.2687C84.022 28.1491 83.9889 27.9889 83.8692 27.91C83.7496 27.8312 83.5892 27.8643 83.5103 27.9838C83.467 28.0499 79.0655 34.615 70.8583 35.373C70.7157 35.3857 70.6114 35.5129 70.6241 35.6553C70.6368 35.7952 70.759 35.897 70.8965 35.8893H70.9016Z"
              fill="white"
            />
            <path
              id="Vector_66"
              d="M90.0168 79.6299C90.1517 79.6222 90.2587 79.5103 90.2587 79.373C90.2587 79.2305 90.1441 79.1135 90.0015 79.1135C82.7031 79.0754 78.1438 75.6568 75.6058 72.7952C72.8437 69.6844 71.8 66.5354 71.7898 66.5048C71.7465 66.37 71.5989 66.2937 71.464 66.3395C71.329 66.3827 71.2527 66.5303 71.2985 66.6651C71.3418 66.7974 72.3779 69.9311 75.2036 73.1233C77.8129 76.0714 82.4994 79.5943 89.999 79.6324C90.0041 79.6324 90.0092 79.6324 90.0168 79.6324V79.6299Z"
              fill="white"
            />
            <path
              id="Vector_67"
              d="M83.8516 75.3044C83.9662 75.2967 84.0655 75.2153 84.0884 75.0983C84.1164 74.9584 84.0273 74.8211 83.8873 74.7931C77.4671 73.4679 75.9295 68.2713 75.9142 68.2179C75.876 68.0805 75.7309 67.9991 75.596 68.0398C75.4585 68.078 75.3796 68.2204 75.4178 68.3578C75.4331 68.4137 75.8226 69.772 77.0521 71.3134C78.1824 72.7328 80.2673 74.5743 83.7854 75.2993C83.8083 75.3044 83.8313 75.3069 83.8542 75.3044H83.8516Z"
              fill="white"
            />
            <path
              id="Vector_68"
              d="M75.1301 21.2871L74.9824 25.7588C74.9824 25.7588 75.7334 27.5037 77.5332 27.7072C80.5345 28.0429 81.5197 26.1174 81.5197 26.1174L81.6623 21.8187L75.1275 21.2897L75.1301 21.2871Z"
              fill="#F4A36C"
            />
            <path
              id="Vector_69"
              d="M75.1297 21.2871L75.0635 23.2711C76.2294 24.6549 78.3703 25.0593 81.5677 24.6955L81.6619 21.8187L75.1271 21.2897L75.1297 21.2871Z"
              fill="#FF7F4D"
            />
            <path
              id="Vector_70"
              d="M71.186 15.3146C71.2548 15.7495 71.3337 16.1616 71.4279 16.5304C71.5195 16.8967 71.6264 17.291 71.7563 17.703C72.7949 21.0224 75.1649 25.3491 81.1014 23.4465C84.0493 22.5054 85.5029 19.3081 86.2157 16.0319C86.3863 15.2459 86.5135 14.4548 86.6077 13.6892C86.7808 12.2902 86.8419 10.9752 86.8572 9.92975C86.8776 8.27895 86.0273 6.74006 84.617 5.87778C80.7985 3.54529 76.6643 4.23206 73.9252 5.18083C72.0236 5.83962 70.7635 7.64305 70.7915 9.64741C70.8169 11.3872 70.9111 13.5671 71.186 15.3146Z"
              fill="#F4A36C"
            />
            <path
              id="Vector_71"
              d="M81.8659 13.73C81.8507 14.2082 82.0823 14.6025 82.3878 14.6127C82.6907 14.6228 82.9504 14.2438 82.9657 13.7656C82.9809 13.2874 82.7467 12.8932 82.4438 12.883C82.1409 12.8728 81.8812 13.2518 81.8659 13.73Z"
              fill="#133248"
            />
            <path
              id="Vector_72"
              d="M75.4245 13.5171C75.4093 13.9953 75.6435 14.3896 75.9464 14.3998C76.2493 14.41 76.509 14.031 76.5243 13.5528C76.5395 13.0746 76.3053 12.6803 76.0024 12.6701C75.6995 12.6599 75.4398 13.0389 75.4245 13.5171Z"
              fill="#133248"
            />
            <path
              id="Vector_73"
              d="M84.5203 11.101C84.5203 11.101 82.2623 10.1217 80.4727 10.8237L80.5923 11.6453C80.5923 11.6453 82.5143 11.2816 84.3599 12.0396C84.4897 11.6657 84.6526 11.4902 84.5177 11.0984L84.5203 11.101Z"
              fill="#133248"
            />
            <path
              id="Vector_74"
              d="M74.0473 10.7544C74.0473 10.7544 76.3665 9.92517 78.1026 10.7442L77.9295 11.5556C77.9295 11.5556 76.0355 11.0673 74.1441 11.7032C74.0397 11.3216 73.887 11.1359 74.0499 10.7544H74.0473Z"
              fill="#133248"
            />
            <path
              id="Vector_75"
              d="M78.9353 16.9766C78.5764 16.9638 78.2251 16.8138 78.1716 16.7883C78.0647 16.74 78.0163 16.6154 78.0647 16.5085C78.113 16.4017 78.2378 16.3534 78.3447 16.4017C78.5331 16.4856 78.9913 16.623 79.195 16.5111C79.2331 16.4882 79.2892 16.4449 79.307 16.3178C79.4088 15.5776 79.2866 13.0848 79.2866 13.0619C79.2815 12.9449 79.3706 12.8457 79.4877 12.8381C79.6048 12.833 79.7041 12.922 79.7117 13.039C79.7168 13.1433 79.8365 15.5979 79.7296 16.3737C79.6888 16.6662 79.531 16.8112 79.4037 16.8824C79.2662 16.9588 79.1008 16.9816 78.9379 16.9766H78.9353Z"
              fill="#D6793A"
            />
            <path
              id="Vector_76"
              d="M76.8457 19.1485C77.0672 18.828 76.4511 18.7008 78.8186 18.767C81.1912 18.8331 80.9595 18.8484 81.1784 19.2324L76.8457 19.1485Z"
              fill="white"
            />
            <path
              id="Vector_77"
              d="M85.6003 14.6262C85.7887 13.7563 85.9847 12.6295 85.8905 12.047C85.7174 11.0143 84.8875 10.3835 85.2032 9.40418C85.5189 8.4198 84.5082 7.3235 83.742 7.12256C82.9783 6.91907 81.1658 7.24211 79.422 6.34421C77.7571 5.48447 74.799 5.96267 74.2848 7.03099C73.6611 8.32568 74.4477 8.47576 72.8185 9.84168C72.1694 10.386 72.8694 11.8817 72.9432 12.8406C72.9763 13.2832 72.9763 13.4968 72.9432 13.97L72.928 14.2091L71.034 14.1455C70.8736 12.6575 70.81 11.0168 70.7896 9.64836C70.7591 7.64145 72.0243 5.84058 73.9233 5.18178C76.6625 4.23301 80.7941 3.5437 84.6151 5.87873C86.0254 6.74101 86.8757 8.27736 86.8528 9.92816C86.835 11.2101 86.7459 12.8991 86.4684 14.6542L85.6003 14.6262Z"
              fill="#133248"
            />
            <path
              id="Vector_78"
              d="M62.1471 77.4935C61.8823 77.0916 61.615 76.6693 61.3503 76.2344C60.4848 77.9513 59.5454 79.6326 58.6035 81.3114C59.4894 82.1534 60.36 82.8961 61.1365 83.5142C61.1365 83.5142 61.1399 83.5176 61.1466 83.5244C62.8268 84.8699 64.064 85.6457 64.064 85.6457L66.2023 82.7308C66.2023 82.7308 64.2167 80.6425 62.1445 77.4935H62.1471Z"
              fill="#FFAE77"
            />
            <path
              id="Vector_79"
              d="M66.2048 82.734L64.0664 85.649C64.0664 85.649 62.8292 84.8757 61.1491 83.5276C61.1491 83.5276 61.1457 83.5242 61.1389 83.5174C60.7698 81.3146 60.9938 78.2013 62.147 77.4941C64.2166 80.6431 66.2048 82.7314 66.2048 82.7314V82.734Z"
              fill="#FF7F4D"
            />
            <path
              id="Vector_80"
              d="M65.0638 79.3687C65.494 79.3967 67.7877 79.5111 67.5407 78.7252C67.2683 77.8603 65.0154 76.848 65.0154 76.848C65.0154 76.848 65.0027 76.8047 65.0231 76.7462L64.9976 76.5606L64.8983 74.7012C64.463 74.7037 64.0633 74.7902 63.847 74.8563C62.8618 75.1616 62.9967 77.4152 62.9967 77.4152C62.9967 77.4152 61.7519 77.8603 61.7977 78.771C61.8435 79.6816 62.8465 79.8189 62.8465 79.8189C62.8465 79.8189 61.4769 80.5006 61.7977 81.2764C62.1185 82.0522 63.1545 82.2786 63.1545 82.2786C63.1545 82.2786 61.981 83.0519 62.3908 83.5987C62.8007 84.1456 63.7579 84.4178 63.7579 84.4178C63.7579 84.4178 62.9382 85.5268 63.7706 86.1296C64.603 86.7325 65.8275 86.6027 66.6141 86.3306C67.4007 86.0584 67.2658 85.0562 65.7155 84.3287C65.7155 84.3287 67.0825 84.4661 67.7647 83.3266C68.335 82.3778 66.8814 81.9683 65.6417 81.8055C66.6167 81.8716 68.2968 81.8436 68.5845 80.9152C68.9638 79.6968 65.5882 79.4094 65.0612 79.3738L65.0638 79.3687Z"
              fill="#F4A36C"
            />
            <path
              id="Vector_81"
              d="M78.0474 77.4941L64.9983 76.5606C61.9435 67.1798 62.8955 57.8905 63.5294 53.9581C63.7229 52.7575 63.8883 52.058 63.8985 52.0046C63.6669 52.4294 63.4378 52.877 63.2112 53.3451C60.5586 58.8087 58.2268 66.7804 58.2268 66.7804L61.3732 76.2706L59.3138 81.9658C55.1414 80.8873 49.9992 72.3102 49.4264 68.0242C48.8562 63.7357 52.3972 44.5645 57.5394 38.0554C62.6817 31.5463 74.9798 25.752 74.9798 25.752C73.4499 43.3665 78.0448 77.489 78.0448 77.489L78.0474 77.4941Z"
              fill="#E0E0E0"
            />
            <path
              id="Vector_82"
              d="M70.6261 39.602C70.6261 39.602 70.6108 39.602 70.6032 39.602C66.6548 39.2789 64.8423 35.5449 64.7685 35.3846C64.6998 35.2422 64.7609 35.0718 64.9034 35.0056C65.046 34.9395 65.2165 34.998 65.2827 35.1405C65.3005 35.1761 67.0341 38.7346 70.649 39.0322C70.8068 39.0449 70.9239 39.1823 70.9112 39.34C70.8985 39.49 70.7737 39.602 70.6261 39.602Z"
              fill="#828282"
            />
            <path
              id="Vector_83"
              d="M71.617 37.946C69.7357 37.946 68.1498 36.5775 68.0836 36.519C67.9639 36.4147 67.9538 36.2341 68.0581 36.1171C68.1625 35.9976 68.3433 35.9874 68.4604 36.0891C68.4756 36.1019 69.9547 37.3737 71.617 37.3737C71.6272 37.3737 71.6374 37.3737 71.6475 37.3737C71.8054 37.3737 71.9327 37.5008 71.9327 37.656C71.9327 37.8137 71.8079 37.9434 71.6501 37.9434C71.6399 37.9434 71.6272 37.9434 71.617 37.9434V37.946Z"
              fill="#828282"
            />
            <path
              id="Vector_84"
              d="M65.4512 64.4701C65.4512 64.4701 70.1632 67.4894 72.5663 69.9694L73.4166 68.9062C73.4166 68.9062 68.8471 64.6202 66.2174 63.707L65.4512 64.4701Z"
              fill="#828282"
            />
            <path
              id="Vector_85"
              d="M66.3425 47.7718C65.4642 49.1225 64.2423 52.1367 63.6975 53.5306C63.6314 53.6984 63.5754 53.8409 63.5321 53.9553C63.7255 52.7573 63.891 52.0578 63.9012 52.0069C63.6695 52.4317 63.4404 52.8794 63.2139 53.3474C63.3386 53.0295 63.4964 52.625 63.6823 52.1748C64.306 50.6436 65.202 48.5782 65.9097 47.4895C65.9886 47.3699 66.1465 47.3343 66.2687 47.4132C66.3883 47.4895 66.4214 47.6497 66.345 47.7693L66.3425 47.7718Z"
              fill="#828282"
            />
            <path
              id="Vector_86"
              d="M61.2862 75.5391L58.6387 82.1346L60.1559 83.0478L62.2841 76.7117L61.2862 75.5391Z"
              fill="#828282"
            />
            <path
              id="Vector_87"
              d="M81.5527 25.2168C81.5527 25.2168 85.4272 64.2892 86.3411 77.4931C86.3411 77.4931 93.9833 77.9637 98.3898 75.5396C97.0788 75.8881 95.2459 37.7136 94.0876 31.8785C93.5301 29.0755 81.5527 25.2168 81.5527 25.2168Z"
              fill="#E0E0E0"
            />
            <path
              id="Vector_88"
              d="M88.3975 54.8421C88.3135 54.8421 88.2295 54.8039 88.1735 54.7327C88.0742 54.608 88.0971 54.43 88.2193 54.3308C88.2676 54.2926 92.9644 50.5357 94.2372 46.3515C94.2831 46.2014 94.4434 46.1149 94.5936 46.1607C94.7438 46.2065 94.8304 46.3667 94.7846 46.5168C93.4608 50.874 88.7742 54.6208 88.5757 54.7785C88.5222 54.8192 88.4611 54.8395 88.3975 54.8395V54.8421Z"
              fill="#828282"
            />
            <path
              id="Vector_89"
              d="M89.9714 50.7813C89.9103 50.7813 89.8467 50.7609 89.7958 50.7202C89.671 50.6235 89.6507 50.443 89.7474 50.3183C89.7652 50.2954 91.5014 48.0672 91.8857 45.1573C91.9061 45.0022 92.0512 44.8902 92.2065 44.9106C92.3618 44.9309 92.4738 45.0759 92.4534 45.2311C92.0487 48.2987 90.2718 50.5752 90.1954 50.6693C90.1394 50.7406 90.0554 50.7787 89.9714 50.7787V50.7813Z"
              fill="#828282"
            />
            <path
              id="Vector_90"
              d="M96.692 63.8412C96.692 63.8412 91.9799 66.8605 89.5768 69.3405L88.7266 68.2773C88.7266 68.2773 93.296 63.9913 95.9257 63.0781L96.692 63.8412Z"
              fill="#828282"
            />
            <path
              id="Vector_91"
              d="M108.747 50.4344C106.945 50.3174 105.357 49.5416 103.962 48.1909C103.608 49.5314 103.172 51.4111 103.035 51.1186C102.897 50.8159 102.752 50.5056 102.607 50.1902C102.363 49.6662 102.113 49.127 101.856 48.5725C101.492 47.789 101.115 46.9827 100.736 46.1687C97.941 40.1582 93.8425 31.3242 93.8425 31.3242C92.8777 31.8635 94.2345 41.2545 95.1484 47.3719C95.4361 49.2923 95.0008 50.91 95.2604 52.454C95.6779 54.9442 95.8943 56.2821 95.8943 56.2821L98.9287 62.5521C100.568 66.7211 102.892 66.5329 104.954 65.4188C104.99 65.401 105.031 65.3857 105.061 65.3628C105.815 64.7829 106.413 63.6612 106.968 61.0158C106.981 60.8352 108.208 54.7687 108.75 50.4344H108.747Z"
              fill="#E0E0E0"
            />
            <path
              id="Vector_92"
              d="M101.783 58.9217C101.798 58.9217 101.811 58.9217 101.826 58.9217C101.966 58.914 102.086 58.8021 102.098 58.6597C102.406 55.2105 102.778 52.6542 103.035 51.1179C102.898 50.8152 102.753 50.5049 102.607 50.1895C102.361 51.5452 101.895 54.4271 101.52 58.6063C101.508 58.7665 101.625 58.9064 101.785 58.9217H101.783Z"
              fill="#828282"
            />
            <path
              id="Vector_93"
              d="M110.751 41.7463C110.868 43.1554 108.282 49.6315 108.282 49.6315C106.11 50.6184 104.221 49.1304 104.221 49.1304C104.221 49.1304 103.651 43.0206 104.17 41.1155C104.17 41.1155 104.478 39.4138 104.57 38.4167C104.659 37.4247 105.102 36.2877 105.418 36.4886C105.731 36.6896 105.81 37.501 105.818 38.1166C105.823 38.7321 105.947 39.9098 105.947 39.9098C105.947 39.9098 106.115 38.4549 106.143 37.6562C106.169 36.8549 106.108 35.5831 106.596 35.3313C107.21 35.0159 107.386 36.7227 107.465 37.3408C107.543 37.9589 107.299 39.8818 107.299 39.8818C107.299 39.8818 107.719 37.7248 107.612 37.0864C107.505 36.4479 107.373 35.199 107.406 34.6725C107.439 34.1434 108.116 34.2325 108.322 34.8811C108.526 35.5322 108.76 36.8397 108.816 37.2772C108.888 37.8368 108.75 39.6961 108.75 39.6961C108.75 39.6961 109.017 37.5773 109 37.0177C108.987 36.5624 108.844 35.6645 109.007 35.1405C109.17 34.6165 109.799 34.5173 110 35.8934C110.201 37.2695 110.234 37.4323 110.196 37.8444C110.127 38.5693 110.003 39.3756 110.087 39.6732L110.127 39.8233C110.127 39.8233 110.484 38.3429 110.838 38.7932C111.247 39.3146 110.682 40.8662 110.754 41.7488L110.751 41.7463Z"
              fill="#F4A36C"
            />
            <path
              id="Vector_94"
              d="M103.331 49.1316C105.324 50.1414 107.177 50.5662 108.967 50.5229C109.443 50.0219 109.338 49.4241 109.186 48.75C106.935 48.9357 105.624 48.5618 103.842 47.7402C103.529 48.1752 103.359 48.6381 103.328 49.1316H103.331Z"
              fill="#828282"
            />
            <path
              id="Vector_95"
              d="M75.8271 0.0786955C71.8584 0.317795 71.3391 4.0798 71.2398 5.22188C53.3794 5.01076 86.6182 8.12923 86.6182 8.12923C86.8575 -0.24943 80.2311 -0.188384 75.8271 0.0786955Z"
              fill="#FF6700"
            />
            <path
              id="Vector_96"
              d="M86.6182 8.12659C73.2585 6.43509 71.2398 5.21924 71.2398 5.21924C53.3794 5.00812 86.6182 8.12659 86.6182 8.12659Z"
              fill="#F77F60"
            />
            <path
              id="Vector_97"
              d="M78.6576 29.809C78.2401 27.7843 74.1975 25.5586 74.1975 25.5586L73.4873 26.342C73.4873 26.342 76.6465 30.4194 78.0873 30.483C78.3063 30.4932 78.5074 30.4754 78.6932 30.4372C78.6754 30.2287 78.6703 30.0175 78.6576 29.809Z"
              fill="#828282"
            />
            <path
              id="Vector_98"
              d="M78.2505 29.809C78.668 27.7843 82.7105 25.5586 82.7105 25.5586L83.4207 26.342C83.4207 26.342 80.2616 30.4194 78.8207 30.483C78.6018 30.4932 78.4007 30.4754 78.2148 30.4372C78.2327 30.2287 78.2378 30.0175 78.2505 29.809Z"
              fill="#828282"
            />
            <path
              id="Vector_99"
              d="M74.9974 25.2715L74.1828 25.564C73.7169 25.7319 73.4114 26.1745 73.4191 26.6705C73.5235 32.1189 74.2515 63.1001 77.3471 77.4969H78.4519C78.4519 77.4969 75.6491 46.8871 74.9949 25.274L74.9974 25.2715Z"
              fill="#828282"
            />
            <path
              id="Vector_100"
              d="M81.5527 25.2176L82.3877 25.1973C82.8663 25.3219 83.21 25.7391 83.2456 26.2325C83.6198 31.6682 85.6029 53.2482 87.7133 77.4965L86.056 77.5321C86.056 77.5321 82.8052 46.8053 81.5527 25.2176Z"
              fill="#828282"
            />
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_35396_6936"
          x1="111.525"
          y1="95.2767"
          x2="174.521"
          y2="95.2767"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#606060" />
          <stop offset="0.56" stop-color="#191919" />
        </linearGradient>
        <clipPath id="clip0_35396_6936">
          <rect width="180" height="165.577" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ShareRideError;
