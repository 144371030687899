import { ID_AUCTION_FLOW, IMG_PATH, MD_IMG } from "@/consts/defaultConsts";
import { MyNegotiationes } from "@/models/userAuth/agrementToAccept";
import { resizePhoto } from "@/utils/helpers";
import { Avatar, Box, CardMedia } from "@mui/material";
import FlagDiscount from "../../../noticeList/components/FlagDiscount";
import { SyntheticEvent, useRef } from "react";
import { BASE_URL_IMAGE } from "@/config/config";
import NO_FOTO from "@/img/NO_FOTO.png";

const CardHeader = ({ data }: { data: MyNegotiationes }) => {
  const fristErrorLoad = useRef(false);

  const handleValidateImg = (imgURL: string, newFormat: string) => {
    const photoResized = resizePhoto({
      photo: IMG_PATH + imgURL,
      newFormat,
    });

    if (photoResized?.startsWith("http")) return photoResized;

    return IMG_PATH + photoResized;
  };

  const handleErrorLoadImage = (
    event: SyntheticEvent<HTMLImageElement, Event>
  ) => {
    const imageDefault = `${BASE_URL_IMAGE}/${data.fotoPrincipal}`;
    const target = event.target as HTMLInputElement;
    if (!fristErrorLoad.current) {
      fristErrorLoad.current = true;
      target.src = imageDefault;
    } else {
      target.src = NO_FOTO;
    }
  };

  const show = data.flgAvisoSolicitud === 1 && data.idTipo === ID_AUCTION_FLOW;

  return (
    <Box sx={{ position: "relative" }}>
      <CardMedia
        component="img"
        height="170"
        image={handleValidateImg(data.fotoPrincipal, MD_IMG)}
        alt={data.origenTexto}
        onError={handleErrorLoadImage}
        sx={{ objectFit: fristErrorLoad.current ? "contain" : "cover" }}
      />
      <FlagDiscount
        show={show}
        currentPrice={data.precio}
        beforePrice={data?.precioRegular || 0}
      />
      <Avatar
        src={IMG_PATH + data?.fotoAvatar}
        sx={{
          position: "absolute",
          bottom: "16px",
          left: "16px",
        }}
      />
    </Box>
  );
};

export default CardHeader;
