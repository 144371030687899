import { ConfigDataNegotationV2 } from '@/pages/noticeV2Negotation/shared/hooks/useNoticeConfigDataV2';
import { generalConfigData } from '@/pages/noticeV2Negotation/shared/config/generalConfig';
import { actionsToNegotation } from '@/consts/actions';
import AuctionOfferPrice from './AuctionOfferPrice';
import { NoticeDetailAuctionModel } from '@/models/notice/noticeV2NegotationModels';
import { currencyFormat } from '@/utils/helpers';
import AuctionCounterOfferPrice from './AuctionCounterOfferPrice';
import { actionNegotationNameToId } from '@/pages/noticeV2Negotation/shared/config';
import { generalConfigDataReject } from '@/pages/noticeV2Negotation/shared/config/generalConfigFinishedReject';
import { optionOne, optionTwo } from '@/consts/noticeConsts';

export const priceAuctionComponentToRenderFinished: Record<
  number,
  ({ data }: { data: NoticeDetailAuctionModel }) => JSX.Element
> = {
  [optionOne]: AuctionOfferPrice,
  [optionTwo]: AuctionCounterOfferPrice
};

export const detailsConfigAccept: ConfigDataNegotationV2[] = [
  ...generalConfigDataReject,
];

export const detailsConfigCounterOffer: ConfigDataNegotationV2[] = [
  ...generalConfigDataReject,
];

export const handleDataAuctionToRenderFinished = (data: NoticeDetailAuctionModel) => {
  return [
    {
      sxSubtitleColum: { fontSize: '12px', color: '#757575' },
      sxSubtitleValueColum: { fontSize: '14px', color: '#757575' },
      title: {
        text: 'En tu aviso',
        sx: { color: '#757575', fontSize: '14px' },
      },
      columnOne: {
        subtitle: 'Peso del remate',
        value: `${new Intl.NumberFormat().format(data.aviso.pesomaximo)} Kilos`,
      },
      columnTwo: {
        subtitle: 'Precio del remate',
        value: `${currencyFormat(data.aviso.precio)}`,
      },
    },
    {
      sxSubtitleColum: { fontSize: '14px', fontWeight: 600 },
      sxSubtitleValueColum: { fontWeight: 700, fontSize: '16px' },
      title: {
        text: 'Cliente solicita',
        sx: { fontWeight: 700, fontSize: '16px' },
      },
      columnOne: {
        subtitle: 'Peso del remate',
        value: `${new Intl.NumberFormat().format(data.pesoEstimado)} Kilos`,
      },
      columnTwo: {
        subtitle: 'Precio del remate',
        value: `${currencyFormat(data.precio)}`,
      },
    },
  ];
};