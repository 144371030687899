import { BASE_URL_IMAGE_S3 } from '@/config/config';

export const ID_OFFER = 1;
export const ID_COUNTEROFFER = 2;
export const USER_TOKEN_STORAGE = 'token';
export const USER_TOKEN_APP_STORAGE = 'tokenApp';
export const USER_ID_STORAGE = 'userId';
// ==================================================
//                   Default Images
// ==================================================
export const USER_PROFILE_IMAGE_PATH_DEFAULT = '/user-background-profile.svg';
export const COMPANY_PROFILE_IMAGE_PATH_DEFAULT =
  '/company-background-profile.svg';
export const USER_CARD_PROPERTY_PAHT_DEFAULT = '/card-property-background.svg';
export const USER_CARD_PROPERTY_PAHT_DEFAULT_BACK =
  '/Back-tarjeta-de-propiedad.svg';
export const USER_DOCUMENT_IDETIFICATION_PATH_DEFAULT =
  '/identification-background.svg';
export const USER_FRONT_CAR_DEFAULT = '/Front-car.svg';
export const USER_SIDE_CAR_DEFAULT = '/Side-car.svg';
export const USER_BACK_CAR_DEFAULT = '/Back-car.svg';

export const EMPTY_LIST_DATA_IMAGE = '/empty-list-data.svg';
export const EMPTY_LIST_WHITOUT_REQUEST = '/sin-solicitudes.svg';

export const DECLINE_OFFERT = '/rechazo_img.svg';
export const NO_ACCESS_IMG = '/sin_acceso_img.svg';
export const NOT_FOUND_IMG = '/404_img.svg';
export const CONFIRMATION_IMAGE = '/checkCircleIcon.svg';
export const NOTICE_OFF_IMAGE = '/notice_off.svg';
export const NOTICE_NOT_FOUND = '/notice_not_found.svg';
export const NO_ACCESS_CLIENT_IMAGE = '/no_access_client.svg';
export const NO_ACCESS_DRIVER_IMAGE = '/no_access_driver.svg';

export const FAVICON_32 = 'favicon-32x32.png';
// ==================================================
//                   Imagenes del landing
// ==================================================
// ========================= HOME ===================
export const FRONT_PAGE_HERO_SM = '/portada_sm.png';
export const FRONT_PAGE_HERO_MD = '/portada_md.png';
export const FRONT_PAGE_HERO_LG = '/portada_lg.png';

// ======================== Clientes ================ // no estan!!
export const CLIENTS_HERO_SM = '/clientes_sm.jpg';
export const CLIENTS_HERO_MD = '/clientes_md.jpg';
export const CLIENTS_HERO_LG = '/clientes_lg.jpg';

// ======================= Transportistas ===========
export const DRIVER_HERO_SM = '/transportistas_sm.jpg';
export const DRIVER_HERO_MD = '/transportistas_md.jpg';
export const DRIVER_HERO_LG = '/transportistas_lg.jpg';
export const DRIVER_HERO_LOGIN_SM = '/transportistas_login_md.jpg';
export const DRIVER_HERO_LOGIN_MD = '/transportistas_login_md.jpg';
export const DRIVER_HERO_LOGIN_LG = '/transportistas_login_lg.jpg';

// ======================= Anunciantes ==============
export const ADVERTISERS_HERO_SM = '/anunciantes_sm.jpg';
export const ADVERTISERS_HERO_MD = '/anunciantes_md.jpg';
export const ADVERTISERS_HERO_LG = '/anunciantes_lg.jpg';

// =================== Anunciantes formulario =======
export const ADVERTISERS_FROM_HERO_SM = '/anunciantes_form_sm.jpg';
export const ADVERTISERS_FROM_HERO_MD = '/anunciantes_form_md.jpg';
export const ADVERTISERS_FROM_HERO_LG = '/anunciantes_form_lg.jpg';

// ======================== Nosotros ================ // no esta!!
export const US_HERO_SM = '/nosotros_sm.png';
export const US_HERO_MD = '/nosotros_md.png';
export const US_HERO_LG = '/nosotros_lg.png';

// ======================== Ayuda - Contáctanos =====
export const CONTACT_HERO_SM = '/contacto_sm.jpg';
export const CONTACT_HERO_MD = '/contacto_md.jpg';
export const CONTACT_HERO_LG = '/contacto_lg.jpg';

// ============ Ayuda - Preguntas frecuentes =========
export const FREQUENT_QUESTIONS_HERO_SM = '/contacto_sm.jpg';
export const FREQUENT_QUESTIONS_HERO_MD = '/contacto_md.jpg';
export const FREQUENT_QUESTIONS_HERO_LG = '/contacto_lg.jpg';

// ==================================================
//                   IDS Type flows
// ==================================================
export const ID_IN_CITY = 1;
export const ID_MULTI_DESTINY = 2;
export const ID_BETWEEN_CITIES = 3;
export const ID_AUCTION_FLOW = 4;
export const ID_MOVING_FLOW = 5;
export const ID_EXPRESS_FLOW = 4;

export const ID_MOVING_BETWEEN_CITIES = 2;

export const ArrayIdsTypeFlow: number[] = [
  ID_MULTI_DESTINY,
  ID_BETWEEN_CITIES,
  ID_AUCTION_FLOW,
  ID_MOVING_FLOW,
  ID_EXPRESS_FLOW,
];

// ==================================================
//                   Type usuario
// ==================================================
// ROLE_TRANSPORTISTA
// ROLE_INVITADO
// ROLE_ADMIN
// ROLE_CONTROLLER
// ROLE_CLIENTE
// ROLE_ANUNCIANTE
export const userRoles: string[] = [
  'ROLE_TRANSPORTISTA',
  'ROLE_INVITADO',
  'ROLE_ADMIN',
  'ROLE_CONTROLLER',
  'ROLE_CLIENTE',
  'ROLE_ANUNCIANTE',
];

// ==================================================
//                   status negociacion solicitud
// ==================================================

// 1	Por aceptar	1	T	1	En espera
// 2	Aceptado	0	T	2	Aceptado
// 3	Rechazado	0	T	3	Rechazado
// 5	Aceptado	0	C	2	Aceptado
// 6	Rechazado	0	C	3	Rechazado
// 7	Anulado	0	S	4	Anulado
// 4	Por aceptar	1	C	1	En espera
// 8	Retirada	0	T	5	Retirada
// 9	Retirada	0	C	5	Retirada
// 10	Cancelada	0	C	6	Cancelada
// 11	Cancelada	0	T	6	Cancelada

export const ID_STATUS_NEGOCIATION_SOLICITUD = {
  // T
  POR_ACEPTAR_TRANSPORTISTA: 1,
  ACEPTADO_TRANSPORTISTA: 2,
  RECHAZADO_TRANSPORTISTA: 3,
  ANULADO_TRANSPORTISTA: 4,
  RETIRADA_TRANSPORTISTA: 5,
  CANCELADA_TRANSPORTISTA: 6,
  // C
  ACEPTADO_CLIENTE: 5,
  RECHAZADO_CLIENTE: 6,
  RETIRADA_CLIENTE: 8,
  CANCELADA_CLIENTE: 10,

  // S
  ANULADO: 7,
};

// ==================================================
//                   status negociacion avisos
// ==================================================
// 1	Por aceptar	1	T	1	En Espera
// 2	Aceptado	0	T	2	Aceptado
// 3	Rechazado	0	T	3	Rechazado
// 4	Por Aceptar	1	C	1	En Espera
// 5	Aceptado	0	C	2	Aceptado
// 6	Rechazado	0	C	3	Rechazado
// 7	Anulada	0	S	4	Anulada
// 8	Retirado	0	C	5	Retirado
// 9	Retirado	0	T	5	Retirado
export const ID_STATUS_NEGOCIATION_AVISOS = {
  // T
  POR_ACEPTAR_TRANSPORTISTA: 1,
  ACEPTADO_TRANSPORTISTA: 2,
  RECHAZADO_TRANSPORTISTA: 3,
  ANULADO_TRANSPORTISTA: 4,
  RETIRADA_TRANSPORTISTA: 5,
  // C
  POR_ACEPTAR_CLIENTE: 4,
  ACEPTADO_CLIENTE: 5,
  RECHAZADO_CLIENTE: 6,
  ANULADO_CLIENTE: 7,
  RETIRADA_CLIENTE: 8,
};

export const CLIENT_TYPE = 'C';
export const DRIVER_TYPE = 'T';

// ==================================================
//       PARA EL CAMBIO DE TAMANOS DE LAS IMG
// ==================================================
export const IMG_PATH = BASE_URL_IMAGE_S3 as string;
export const SM_IMG = '_sm.';
export const MD_IMG = '_md.';
export const LG_IMG = '_lg.';

// ==================================================
//       STATUS ERRORS
// ==================================================
export const STATUS_FORBIDDEN = 403;

// ==================================================
//                   Types documents
// ==================================================
export const typesDocuments = {
  DNI: {
    id: 1,
    name: 'DNI',
  },
  PASAPORTE: {
    id: 2,
    name: 'PASAPORTE',
  },
  CEDULA: {
    id: 3,
    name: 'CE',
  },
  RUC: {
    id: 4,
    name: 'RUC',
  },
};

// ==================================================
//                   Types Users
// ==================================================
export const USER_ROLE_CLIENT = 'C';
export const USER_ROLE_DRIVER = 'T';

// ==================================================
//                   QR url
// ==================================================
export const QR_EXTERNAL_PATH = 'https://onelink.to/9bewnm';
