import { BASE_URL_IMAGE_S3 } from "@/config/config";
import { IAdvertisementItem } from "./interfaces/AdvertisementInterface";

interface Props
  extends Pick<IAdvertisementItem, "advertisement" | "onLoad" | "onClick"> {
  externalRef?: any;
  styleImage?: React.CSSProperties | undefined;
  target?: React.HTMLAttributeAnchorTarget | undefined;
}

const AdvertisementImage = ({
  advertisement,
  onClick,
  onLoad,
  externalRef,
  styleImage,
  target = "_blank",
}: Props) => {
  const handleOnLoad = () => {
    onLoad(advertisement);
  };

  const handleOnClick = () => {
    onClick(advertisement);
  };

  return (
    <a
      ref={externalRef}
      href={advertisement.linkClick}
      target={target}
      rel="noreferrer"
    >
      <img
        alt={advertisement.linkClick}
        onClick={handleOnClick}
        onLoad={handleOnLoad}
        src={`${BASE_URL_IMAGE_S3}${advertisement.linkImagen}`}
        style={styleImage}
      />
    </a>
  );
};

export default AdvertisementImage;
