import { ID_AUCTION_FLOW, ID_BETWEEN_CITIES, ID_IN_CITY, ID_MOVING_FLOW, ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { currencyFormat } from "@/utils/helpers";
import { Box, Typography } from "@mui/material";
import { PriceMultidestinyCoCounter } from "../containers/MultidestinyCoCounterOffer/PriceMultidestinyCoCounter";
import { GeneralCoCounterOfferPrice } from "./GeneralPrice";
import { PriceAuctionCoCounter } from "../containers/AuctionCoCounterOffer/PriceAuctionCoCounter";
import { PriceMovingCoCounter } from "../containers/MovingCoCounterOffer/PriceMovingCoCounter";

export const CoCounterOfferPrice = () => {
    const { notice } = useGetData();
  
    const isMoving = notice?.idtipoaviso === ID_MOVING_FLOW;
  
    return (
      <>
        <Typography sx={{ fontWeight: 700, fontSize: "22px" }}>
          {isMoving ? "Precio cotizado: " : "Contraoferta: "}&nbsp;
          {currencyFormat((notice as NoticeV2NegType)?.preciocontraoferta as number)}
        </Typography>
      </>
    );
  };
  
  export const CoCounterOfferGlobalPrice = ({ data }: { data: NoticeV2NegType }) => {

    const Component =
    componentToRender[(data as NoticeV2NegType).idtipoaviso as number];
  if (!Component) return <></>;
  
  return (
    <Box sx={{ mt: 2 }}>
      <Component />
    </Box>
  );
  };
  

  export const componentToRender: Record<number, () => JSX.Element> = {
    [ID_IN_CITY]: GeneralCoCounterOfferPrice,
    [ID_BETWEEN_CITIES]: GeneralCoCounterOfferPrice,
    [ID_MULTI_DESTINY]: PriceMultidestinyCoCounter,
    [ID_MOVING_FLOW]: PriceMovingCoCounter,
    [ID_AUCTION_FLOW]: PriceAuctionCoCounter,
  };