import { NEGOTATION_CLIENT_PATH } from "@/consts/negotationFlowPath";
import { useAppSelector } from "@/hooks/useReducer";
import { SuccessHeaderDetailsV2 } from "@/pages/noticeV2Negotation/components/SuccessHeaderDetailsV2";
import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useGetRequestData } from "../../shared/hooks/useGetRequestData";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { REQUESTS_LANDING_PATH } from "@/consts/typeServicesPaths";

const name = "Cotizar";

const textObject: Record<number, string> = {
  1: "¡Notificamos tu interés!",
  2: "¡Contraoferta enviada!",
  4: "¡Oferta enviada!",
  7: "¡Contraoferta enviada!",
};

export const NegSuccessRequestNeg = () => {
  const { data } = useGetRequestData();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isClient = pathname.startsWith(NEGOTATION_CLIENT_PATH);
  const text_header = isClient ? "transportista" : "cliente";

  const {
    noticeNegotationRequest: {
      isLoading,
      selectedNegotationTypeRequest,
      savedDataNogotation,
    },
  } = useAppSelector((state) => state);
  const [title, setTitle] = useState("");
  const optionId = selectedNegotationTypeRequest?.id;
  const dataNegotation = savedDataNogotation?.tipoSolicitudNegociacion;

  useEffect(() => {
    let newtitle = "¡Notificamos tu interés!";

    if (dataNegotation) {
      if (dataNegotation.nombre === name) {
        newtitle = "¡Cotización enviada!";
      } else {
        newtitle = textObject[optionId];
      }
    }

    setTitle(newtitle);
  }, [dataNegotation]);

  return (
    <Container maxWidth="xs" sx={{ marginTop: "16px" }}>
      <SuccessHeaderDetailsV2
        typeId={data?.solicitud.tipoSolicitud.id as number}
      />
      <Outlet />
      <ButtomLoader
        variant="contained"
        sx={{ marginTop: "20px", mb: "20px" }}
        onClick={() => {
          navigate(REQUESTS_LANDING_PATH, {
            replace: true,
          });
        }}
      >
        VER NEGOCIACIONES
      </ButtomLoader>
    </Container>
  );
};
