import { secondFontFamily } from '@/styles/theme';
import {
  Container,
  Typography,
  styled,
  experimental_sx as sx,
} from '@mui/material';

export const ContentHelp = styled(Container)(
  ({ theme }) => `
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 32px;
        gap: 24px;
        @media screen and (min-width: 768px) {
            padding-top: 64px;
            // gap: px;
        }
    `
);

export const HelpVideo = styled('iframe')(
  ({ theme }) => `
        width: 100%;
        height: 100%;
        margin: 16px 0;
        align-self: center;
        @media screen and (min-width: 768px) {
            width: 850px;
            height: 500px;
            margin-top: 16px;
        }
    `
);

export const ContentSecurity = styled(Container)(
  ({ theme }) => `
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-bottom: 16px;
        @media screen and (min-width: 768px) {
            gap: 0px;
        }
    `
);

export const ContentSecurityItem = styled('div')(
  ({ theme }) => `
        display: flex;
        gap: 24px;
        flex-direction: column;
        @media screen and (min-width: 768px) {
            gap: 32px;
            flex-direction: row;
            padding: 64px 32px;
        }
    `
);
export const ImgSecurity = styled('div')(
  ({ theme }) => `
        width: 100%;
        height: 100%;
        text-align: -webkit-center;
        align-self: center;
        @media screen and (min-width: 768px) {
            flex-basis: 40%;
        }
    `
);
export const SecurityContent = styled('div')(
  ({ theme }) => `
        display: flex;
        flex-direction: column;
        width: 100%;
    `
);

export const TitleSecurity = styled(Typography)(({ theme }) => ({
  fontFamily: secondFontFamily,
  fontSize: theme.typography.h5.fontSize,
  lineHeight: theme.typography.h5.lineHeight,
  fontWeight: 'bold',
  marginBottom: '24px',
}));

export const SubtitleSecurity = styled(Typography)(
  ({ theme }) => `
        font-family: ${theme.typography.h6.fontFamily};
        font-size: ${theme.typography.h6.fontSize};
        font-weight: ${theme.typography.h6.fontWeight};
        line-height: ${theme.typography.h6.lineHeight};
        margin: 0;
        margin-bottom: 24px;
        @media screen and (min-width: 768px) {
        }
    `
);

export const DescriptionSecurity = styled(Typography)(
  ({ theme }) => `
        font-family: ${theme.typography.body1.fontFamily};
        font-size: ${theme.typography.body1.fontSize};
        font-weight: ${theme.typography.body1.fontWeight};
        line-height: ${theme.typography.body1.lineHeight};
        margin-bottom: 16px;
    `
);

export const ImageIcon = styled('img')(
  sx({
    width: { xs: '100%', sm: 'auto' },
  })
);
