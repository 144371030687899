import { NOTICE_NOT_FOUND } from "@/consts/defaultConsts";
import { HOME_PAHT } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { ContainerHeader } from "@/pages/flowNoticeNegotation/styles/styles";
import NotFound from "@/pages/notFound/NotFound";
import { resetSendData } from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferSlice";
import Icon from "@/utils/Icon";
import { Container, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { configDataOffert } from "./config";

const ConfirmationDetails = () => {
  const { pathname } = useLocation();
  const isQuote = pathname.endsWith("solicitar-cotizacion-confirmacion");
  const QuoteTitle = "¡Cotización enviada!";
  const QuoteSubTitle =
    "Enviaremos un SMS y notificación cuando el transportista responda.";

  const {
    noticeV2ClientAorCO: { dataResponse, isLoading },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetSendData());
    };
  }, []);

  const data = dataResponse as any;

  if (!dataResponse && !isLoading) {
    return (
      <NotFound
        title="¡Lo sentimos!"
        description="Esta pagina no esta disponible"
        buttonText="BUSCAR AVISOS SIMILARES"
        buttonLink={HOME_PAHT}
        image={NOTICE_NOT_FOUND}
      />
    );
  }

  return (
    <Container maxWidth="xs" sx={{ marginTop: "16px" }}>
      <ContainerHeader>
        <Stack>
          <Icon name="icon-success" color="success" size="xl" />
        </Stack>
        <Stack
          marginLeft="16px"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {isQuote
              ? QuoteTitle
              : configDataOffert[
                  data.negotiationTypeId || data.idtipoavisonegociacion
                ].title}
          </Typography>
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            {isQuote
              ? QuoteSubTitle
              : configDataOffert[
                  data.negotiationTypeId || data.idtipoavisonegociacion
                ].subTitle}
          </Typography>
        </Stack>
      </ContainerHeader>

      <Outlet />
    </Container>
  );
};

export default ConfirmationDetails;
