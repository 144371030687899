import { FilterListOptions } from '@/components/Filter/interface/filterInterface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  OrderByFilter,
  TypeFilterData,
} from '../FilterServices/filterReduxInterface';
import {
  NoticeData,
  NoticeDataPaginate,
} from '@/models/filterNotice/filterNoticeModels';
import { SingleDistrictInList } from '@/models/location/districtModel';

interface Paginate {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  noticeList: NoticeData[];
}

const defaultPaginate: Paginate = {
  currentPage: 0,
  totalItems: 0,
  totalPages: 0,
  noticeList: [],
};

export interface InitialFilterSlice extends Paginate {
  listOfNotice: Array<any>;
  typeNotice: TypeFilterData[];
  typeVehicles: TypeFilterData[];
  weightOfTheLoad: TypeFilterData[];
  orderByList: OrderByFilter[];
  listLocation: Array<any>;
  isLoadingFilter: boolean;
  isLoadingNotices: boolean;
  isLoadingTypeFilter: boolean;
  error: any;
  typeNoticeSelecte: TypeFilterData;
  typeServiceList: TypeFilterData[];
  listOfDistrict: SingleDistrictInList[];
}

const initialState: InitialFilterSlice = {
  listOfNotice: [], // listado de los avisos, el resultado despues de los filtros!!
  weightOfTheLoad: [], // listado de los pesos de las cargas. Ej: Menor o igual 500 kilos
  typeVehicles: [], // Listado de los tipos de vehiculos. EJ Furgoneta
  typeNotice: [], // listado de tipo de aviso. Ej: En la ciudad
  orderByList: [], // el listado de ordenamiento que se puede usar!!
  listLocation: [], // listado de distritos o ciudades!!
  isLoadingFilter: false, // cuando estan cargando los datos tipos distritos, ciudades, etc
  isLoadingNotices: false, // cuando esta cargando los resultados, los avisos!!
  error: null,
  typeNoticeSelecte: { nombre: 'Transporte en la ciudad' }, // El tipo de aviso soleccionado!!
  typeServiceList: [], // Listado de tipos de servicios. Ej: por puntos, por horas
  listOfDistrict: [],
  isLoadingTypeFilter: false,

  // === paginate ===
  ...defaultPaginate,
};

export const filterNoticeSlice = createSlice({
  name: 'filterNotice',
  initialState,
  reducers: {
    setLoadingFilter: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingFilter = payload;
    },
    setLoadingTypeFilter: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingTypeFilter = payload;
    },
    setLoadingNotices: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingNotices = payload;
    },
    setTypeNoticeSelect: (
      state,
      { payload }: PayloadAction<TypeFilterData>
    ) => {
      state.typeNoticeSelecte = payload;
    },
    setError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
      state.isLoadingNotices = false;
      state.isLoadingFilter = false;
    },
    setListLocation: (state, { payload }: PayloadAction<Array<any>>) => {
      state.listLocation = payload;
      state.isLoadingFilter = false;
      if (state.error) {
        state.error = null;
      }
    },
    setFilterListOptions: (
      state,
      { payload }: PayloadAction<FilterListOptions>
    ) => {
      const { filters, orderBy } = payload;
      state = {
        ...state,
        orderByList: orderBy,
        ...filters,
      };
      return state;
    },
    setTypeServiceList: (
      state,
      { payload }: PayloadAction<TypeFilterData[]>
    ) => {
      state.typeServiceList = payload;
    },
    setListOfDistrict: (
      state,
      { payload }: PayloadAction<SingleDistrictInList[]>
    ) => {
      state.listOfDistrict = payload;
      state.isLoadingFilter = false;
    },
    setNoticeListPaginated: (
      state,
      { payload }: PayloadAction<NoticeDataPaginate>
    ) => {
      const { aviso = [], ...paginate } = payload;
      state = {
        ...state,
        noticeList: aviso,
        ...paginate,
      };
      return state;
    },
    updateNoticeListPaginated: (
      state,
      { payload }: PayloadAction<NoticeDataPaginate>
    ) => {
      const { aviso = [], ...paginate } = payload;
      const newList = updateList(aviso, state.noticeList);

      state = {
        ...state,
        noticeList: newList,
        ...paginate,
      };
      return state;
    },
    hidrateTypeVehicles: (
      state,
      { payload }: PayloadAction<TypeFilterData[]>
    ) => {
      state.typeVehicles = payload;
    },
    resetListOfDistrict: (state) => {
      state.listOfDistrict = initialState.listOfDistrict;
    },
    resetListLocation: (state) => {
      state.listLocation = initialState.listLocation;
    },
    resetFilter: (state) => {
      state = initialState;
      return state;
    },
    resetPaginate: (state) => {
      state = {
        ...state,
        ...defaultPaginate,
      };
      return state;
    },
  },
});

const updateList = (moreData: NoticeData[], noticeList: NoticeData[]) => {
  const newListActive = [...noticeList, ...moreData];

  return newListActive;
};

export const {
  setLoadingFilter,
  setLoadingNotices,
  setError,
  setListLocation,
  resetListLocation,
  setFilterListOptions,
  setTypeNoticeSelect,
  setTypeServiceList,
  setListOfDistrict,
  resetListOfDistrict,
  setNoticeListPaginated,
  updateNoticeListPaginated,
  resetFilter,
  setLoadingTypeFilter,
  resetPaginate,
  hidrateTypeVehicles,
} = filterNoticeSlice.actions;
