type Props = {
  typeFLow: 'puntos' | 'horas';
  min: number;
  max: number;
};
export const buidTextQuantityError = ({ max, min, typeFLow }: Props) => {
  const templateError = `El mínimo de ${typeFLow} debe ser ${min} y máximo ${max}.`;

  return templateError;
};
