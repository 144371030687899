import { Container, Grid, Typography, styled } from "@mui/material";

export const ContainerSuccess = styled(Container)(
    () => `
      padding: 16px;
    `
  );

  export const ContainerHeader = styled(Grid)(
    () => `
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 16px;
    `
  );



  export const ContainerInfo = styled(Grid)(
    () => `
      margin: 16px 0;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 16px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    `
  );
  
  export const TitleInfoDescription = styled(Typography)(
    ({ theme }) => `
      margin-top: 8px;
      text-align: center;
      font-size: ${theme.typography.body2.fontSize};
      line-height: ${theme.typography.body2.lineHeight};  
    `
  );
  
  export const TitleInfo = styled(Typography)(
    ({ theme }) => `
      margin-top: 16px;
      text-align: center;
      font-size: ${theme.typography.label2.fontSize};
      line-height: ${theme.typography.label2.lineHeight};
      font-family: ${theme.typography.label2.fontFamily};
      font-weight: ${theme.typography.label2.fontWeight};
    `
  );
  