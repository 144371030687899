import { useAppSelector } from "@/hooks/useReducer";
import { NoticeDetailsConditiosModels } from "@/models/notice/noticeModels";
import { InitialStateDetailsNotice } from "@/store/detailsNotice/detailsNoticeSlice";
import { useLocation } from "react-router-dom";
import { NOTICE_DRIVER_PATH } from "../noticeV2Negotation/shared/const/routes";

type EntryType = [keyof InitialStateDetailsNotice, any];

const useDetailNotice = () => {
  const { pathname } = useLocation();
  const isPriv = pathname.includes(NOTICE_DRIVER_PATH);
  const { detailsNoticeSlice } = useAppSelector((state) => state);

  const handleGetCurrentDetailNotice = () => {
    if (detailsNoticeSlice.isLoading) return null;
    const whiteList: Array<keyof InitialStateDetailsNotice> = [
      "inCity",
      "betweenCities",
      "multiDestiny",
      "moving",
      "auction",
    ];

    const [keyNotice]: EntryType =
      (Object.entries(detailsNoticeSlice) as EntryType).find(
        ([key, value]) => whiteList.includes(key) && !!value
      ) || [];

    if (!keyNotice || !detailsNoticeSlice[keyNotice]) return null;

    return detailsNoticeSlice[keyNotice];
  };

  const handleGetSpecificStoreDetail = (
    store: keyof InitialStateDetailsNotice
  ) => {
    return detailsNoticeSlice[store];
  };

  const getConditionsInViewDetails = (condiciones: NoticeDetailsConditiosModels[]) => {
    let isOwner = isPriv;
    const conditions = condiciones?.map((condicion) => {
      if (typeof condicion === 'string') {
        return condicion;
      }
  
      if(isOwner) {
        return condicion.transportista;
      }
  
      return condicion.cliente;
    });
  
    return conditions;
  }

  return {
    handleGetCurrentDetailNotice,
    handleGetSpecificStoreDetail,
    getConditionsInViewDetails
  };
};

export default useDetailNotice;
