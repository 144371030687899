import { optionTwo } from "@/consts/noticeConsts";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import { currencyFormat } from "@/utils/helpers";
import { Box, Card, Grid, Typography, styled } from "@mui/material";
import { MultidestinyCounterofferPrice } from "../../negClientReceiveCounterOffer/counterOfferResponse/components/MultidestinyCounterofferPrice";

export const CustomCardMutlidestiny = styled(Card)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  minWidth: "275px",
  padding: "8px",
  boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  minHeight: "100px",
}));



export const MultidestinyCoCounterOfferPrice = ({
  data,
}: {
  data: SolicitudNegociacion;
}) => {
  const newData = (data as SolicitudNegociacion).solicitud;

  const handleTypeMultiAmount = !!newData.puntosRequeridos
    ? `${newData.puntosRequeridos as number}`
    : `${newData.horasRequeridas as number}`;

  const handleTypeMultidestiny = () => {
    let typeMultidestiny = "hora";
    if (newData.flgHoraoPunto === optionTwo) {
      typeMultidestiny = "punto";
    }

    return typeMultidestiny;
  };

  const typeOfMultidestiny = handleTypeMultidestiny();

  const typeMulti =
    typeOfMultidestiny.charAt(0).toUpperCase() + typeOfMultidestiny.slice(1);

  const handleTotalPrice = () => {
    const totalPrice =
      Number(data?.precioContraoferta as number) *
      Number(handleTypeMultiAmount);

    return !!totalPrice
      ? currencyFormat(totalPrice)
      : "-";
  };

  return (
    <>
      <MultidestinyCounterofferPrice data={data} />
      <CustomCardMutlidestiny sx={{ mt: 2 }}>
        <Box
          sx={{ display: "flex", justifyContent: "flex-start", width: "100%" }}
        >
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            Contraoferta
          </Typography>
        </Box>

        <Grid container>
          <Grid xs={4}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ fontSize: "12px" }}>
                {` Precio x ${typeOfMultidestiny}s `}
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                {`${currencyFormat(
                  Number(data.precioContraoferta))}`}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={4}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ fontSize: "12px" }}>
                {`# ${typeMulti}s`}
              </Typography>
              <Typography sx={{ fontSize: "16px" }}>
                {handleTypeMultiAmount}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={4}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ fontSize: "12px" }}>Precio total</Typography>
              <Typography sx={{ fontSize: "16px" }}>
                {handleTotalPrice()}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CustomCardMutlidestiny>
    </>
  );
};
