import { ANDROID_APP_URL, HUAWEI_APP_URL, IOS_APP_URL } from "@/consts/appStorePaths";

const useAppRedirectHook = () => {
    const handleRedirect = () => {
        const url = handleRedirectString()
 
        window.location.href = url
    }
 
    const handleRedirectString = () => {
        const isIOS = navigator.userAgent.match(/iPhone|iPad|iPod|Macintosh/i);
        const isHUAWEI = navigator.userAgent.match(/Huawei/i);
        if (isIOS) {
            return IOS_APP_URL;
        }

        if (isHUAWEI) {
            return HUAWEI_APP_URL;
        }
 
        return ANDROID_APP_URL;
    }

    const linkingIosStore = () => {
        window.location.href = IOS_APP_URL;
    }

    const linkingAndroidStore = () => {
        window.location.href = ANDROID_APP_URL;
    }

    const linkingHuaweiStore = () => {
        window.location.href = HUAWEI_APP_URL;
    }
 
   
    return {
        methods: {
            handleRedirect,
            handleRedirectString,
            linkingIosStore,
            linkingAndroidStore,
            linkingHuaweiStore
        }
    }
};



export default useAppRedirectHook;