
interface Props {
    style: {
        color?: string
        height: string
        width: string
    }
}

const MovingBetweenCitiesIcon = (props: Props) => {

    const { color = "#757575", height, width } = props?.style;
    
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.44875 5L1.86646 10.0816L2.5915 11.0174L3.67906 10.1786V15.8588H13.2585V10.2039L14.306 11.0364L15.0563 10.0816L8.44875 5ZM12.0318 14.63H9.03679V12.2568H7.8586V14.63H4.86358V9.31864L8.45086 6.5639L12.0339 9.31864V14.63H12.0318Z" fill={color} />
            <path d="M22.9937 13.8163L20.1167 9.98877H15.64L15.5472 17.1907H7.90899L7.76356 17.0284C7.24717 16.453 6.51159 16.1221 5.7444 16.1221C4.9772 16.1221 4.23951 16.453 3.72523 17.0284L3.5798 17.1907H1V18.3183H3.03813L3.03181 18.8431C3.03181 20.3354 4.25005 21.5494 5.7465 21.5494C7.24296 21.5494 8.4612 20.3312 8.4612 18.8347L8.45488 18.3183H15.465L15.4587 18.8431C15.4587 20.3354 16.6769 21.5494 18.1734 21.5494C19.6699 21.5494 20.8881 20.3312 20.8881 18.8347L20.8797 18.3183H23V13.8163H22.9937ZM5.74229 20.5862C4.77697 20.5862 3.9908 19.8 3.9908 18.8347C3.9908 17.8694 4.77697 17.0832 5.74229 17.0832C6.70761 17.0832 7.49377 17.8694 7.49377 18.8347C7.49377 19.8 6.70761 20.5862 5.74229 20.5862ZM18.1692 20.5862C17.2039 20.5862 16.4177 19.8 16.4177 18.8347C16.4177 17.8694 17.2039 17.0832 18.1692 17.0832C19.1345 17.0832 19.9207 17.8694 19.9207 18.8347C19.9207 19.8 19.1345 20.5862 18.1692 20.5862ZM16.7233 14.143V10.9999H19.8672L22.1864 14.143H16.7254H16.7233Z" fill={color} />
        </svg>
    )
}

export default MovingBetweenCitiesIcon
