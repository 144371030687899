import BasicModal from "@/components/dialog/Notiffication";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { StyleCustomInput } from "@/components/inputCustom/InputCustom";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import {
  CustomCardV2,
  MultidestinyCounterofferPrice,
} from "@/pages/requestNegotiation/negotiation/negClientReceiveCounterOffer/counterOfferResponse/components/MultidestinyCounterofferPrice";
import { useGetRequestData } from "@/pages/requestNegotiation/shared/hooks/useGetRequestData";
import { Box, FormControl, Grid, Typography, styled } from "@mui/material";
import { FormCounterOfferMultiEdition } from "./FormCounterOfferMultiEdition";
import { useFormClientCounterOfferMultidestiny } from "./useFormClientCounterOfferMultidestiny";

const Container = styled(Grid)(
  () => `
    display: flex;
    flex-direction: column;  
    margin-top: 24px;
  `
);

const FormCounterOfferMultidestiny = () => {
  const { data } = useGetRequestData();
  const {
    errorPrice,
    isLoading,
    typeOfMultidestiny,
    preciocontraoferta,
    handleSubmit,
    isEdition,
    handleDisabledButton,
    handleTotalPrice,
    handleOnchange,
    typeMulti,
    handleTypeMultiAmount,
  } = useFormClientCounterOfferMultidestiny();

  if (isEdition)
    return (
     <FormCounterOfferMultiEdition/>
    );

  return (
    <Container container>
      <form onSubmit={handleSubmit}>
        <MultidestinyCounterofferPrice data={data as SolicitudNegociacion} />

        <CustomCardV2 sx={{ mt: 2 }}>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            Contraoferta
          </Typography>
          <Grid container>
            <Grid xs={4}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: "12px" }}>
                  {`Por ${typeOfMultidestiny}s (S/)`}
                </Typography>
                <FormControl sx={{ mb: 2, width: "100%" }}>
                  <StyleCustomInput
                    id={`$1-price`}
                    name="price"
                    label=""
                    value={preciocontraoferta}
                    variant="standard"
                    placeholder={`Contraoferta`}
                    onChange={handleOnchange}
                    onKeyDown={(e) =>
                      /[+-.,]$/.test(e.key) && e.preventDefault()
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      inputMode: "numeric",
                    }}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid xs={4}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: "12px" }}>
                  {`# ${typeMulti}s`}
                </Typography>
                <Typography sx={{ fontSize: "16px" }}>
                  {handleTypeMultiAmount}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={4}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: "12px" }}>Precio total</Typography>
                <Typography sx={{ fontSize: "16px" }}>
                  {handleTotalPrice()}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        
        </CustomCardV2>

        {!!errorPrice && <Typography color="error" sx={{fontSize: "12px", ml: 1, mt: 1}}>{errorPrice}</Typography>}

        <Grid item sx={{ marginTop: "16px" }}>
          <ButtomLoader
            isLoading={isLoading}
            type="submit"
            buttomProps={{
              disabled: handleDisabledButton() || isLoading,
            }}
          >
            ENVIAR
          </ButtomLoader>
        </Grid>
      </form>
      <BasicModal goBack />
    </Container>
  );
};

export default FormCounterOfferMultidestiny;
