import { optionOne, optionTwo } from "@/consts/noticeConsts";
import { ISolicitudViewDetailsResponse } from '@/models/customerRequest/requestDetailsVewResponse';
import DetailsText from '@/pages/customerRequest/components/DetailsText';
import { currencyFormat } from '@/utils/helpers';

interface IMultiDestinyPriceProps {
    solicitud: ISolicitudViewDetailsResponse;
    price: number;
    priceCalculated: number;
    type: number;
}

const MultiDestinyPrice = ({ solicitud, price, priceCalculated, type }: IMultiDestinyPriceProps) => {
    const {
        flgHoraoPunto,
        horasRequeridas,
        puntosRequeridos,
    } = solicitud;

    return (
        <>
            <DetailsText
                show
                titleSx={{
                    fontSize: '22px',
                }}
                title={
                    type === optionTwo ? `Contraoferta: ${currencyFormat(price)}` : `${currencyFormat(price)}`
                }
                separate={false}
                origen={flgHoraoPunto === optionOne ? `Por hora` : `Por punto`}
            />
            <DetailsText
                show
                title={flgHoraoPunto === optionTwo ? `Cantidad de puntos` : `Cantidad de horas`}
                origen={`${horasRequeridas || puntosRequeridos}`}
                constainerSx={{
                    marginTop: '0px',
                }}
            />
            <DetailsText
                show
                title={"Precio total"}
                origen={`${currencyFormat(priceCalculated)}`}
                constainerSx={{
                    marginTop: '0px',
                }}
            />
        </>
    )
}

export default MultiDestinyPrice
