import HeaderSection from "@/components/headerSection/HeaderSection";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import SnackbarNotification from "@/components/snackbar/Snackbar";
import { NEGOTATION_CLIENT_PATH } from "@/consts/negotationFlowPath";
import { optionOne } from "@/consts/noticeConsts";
import { REQUEST_COUNTER_OFFER_MSG } from "@/consts/requestConsts";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import HeaderDetail from "@/pages/noticeV2Negotation/components/HeaderDetail";
import { setError } from "@/store/noticeNegotationRequestRedux/noticeNegRequestSlice";
import { getPrevRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import Icon from "@/utils/Icon";
import { Container, Grid, Stack, Typography, styled } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  defaultError,
  noticeCounterOfferNegRequestTypesArea,
  noticeNegotationRequestObjectTypes,
} from "../../noticeRequestFlow/consts/noticeRequestFlowConsts";

interface LocationState {
  editNegotation?: boolean;
}

const ContainerArea = styled(Container)(
  () => `
    padding: 16px 0;
  `
);

const NegCounterOffer = () => {
  const { removeNotiffication, notiffication } = useNotiffication();
  const dispatch = useAppDispatch();
  const {
    noticeNegotationRequest: { error, selectedNegotationTypeRequest, isEdition },
  } = useAppSelector((state) => state);
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  const isClient = pathname.startsWith(NEGOTATION_CLIENT_PATH);

  useEffect(() => {
    if (selectedNegotationTypeRequest.id <= 0) {
      navigate(-1);
      return;
    }

    return () => { };
  }, []);

  const {
    title = "",
    subTitle = "",
    icon = "",
  } = noticeCounterOfferNegRequestTypesArea[
    selectedNegotationTypeRequest.id || optionOne
    ];


  const handlePrevRute = () => {
    if (isEdition) {
      navigate(-1);
    } else {
      const valueToReplace =
        noticeNegotationRequestObjectTypes[selectedNegotationTypeRequest.id];
      dispatch(
        getPrevRoute(navigate, {
          valueToSearch: ":typeRequest",
          valueToReplace,
        })
      );
    }

    if (error) {
      dispatch(setError(defaultError));
    }
    if (notiffication) {
      removeNotiffication(false);
    }
  };

  if (isEdition || !!(state as LocationState)?.editNegotation)
    return (
      <Container maxWidth="xs">
        <HeaderSection
          icon="icon-arrow-left--primary"
          title="Editar contraoferta"
          subTitle=""
          onClick={handlePrevRute}
        />

        <ContainerArea>
          <SectionIcon icon={icon} />
          <SnackbarNotification />
          <br />
          <Grid container></Grid>
          <Outlet />
        </ContainerArea>
      </Container>
    );

  return (
    <Container maxWidth="xs">
      <HeaderSection
        icon="icon-arrow-left--primary"
        title={title}
        subTitle={subTitle}
        onClick={handlePrevRute}
      />

      <ContainerArea>
        {
          isClient ? <HeaderDetail
            title={""}
            icon="icon-counterOfferMat"
            iconColor="info"
            outlet={false}
          /> :
          <SectionIcon icon={icon} />
        }
        <SnackbarNotification />
        <Stack
          direction="row"
          padding="8px 0px"
          gap="16px"
          mt="16px"
          alignItems="center"
        >
          <Icon
            name="icon-info"
            size="md"
            color="info"
          />
          <Typography variant="body1">
            {
              isClient ? REQUEST_COUNTER_OFFER_MSG : REQUEST_COUNTER_OFFER_MSG
            }
          </Typography>
        </Stack>
      <Outlet />
    </ContainerArea>
    </Container >
  );
};

export default NegCounterOffer;
