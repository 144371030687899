import {
  getBodyCustomerRequestPhotoAdapter,
  prepareListConditionsAdapter,
  prepareListCustomerRequestAdapter,
  prepareSaveBetweenCitiesAdapter,
  prepareSaveExpressAdapter,
  prepareSaveInCityAdapter,
  prepareSaveMovingAdapter,
  prepareSaveMultidestinyAdapter,
} from '@/adapters/store/customerRequestAdapter';
import {
  getPhotosOffertAdapter,
  manageImageOnSave,
  prepareListPhotos,
} from '@/adapters/store/offertStoreAdapter';
import { FORMAT_DATE_DDMMYYYY } from '@/consts/formatsDate';
import { MockListCustomerTypesRequest } from '@/interfaces/customerRequestInterface';
import { Cuantas_Negociaciones } from '@/models/noticeNegotation/noticeNegotationModel';
import {
  getAmountNegotationRequest,
  getCustomerRequestByIdPrivServices,
  getCustomerRequestByIdServices,
  getListCustomerRequestTypeConditionsServices,
  getListCustomerRequestTypesAmbientesServices,
  getListCustomerRequestTypesLoadServices,
  getListCustomerRequestTypesServices,
  getListCustomerRequestWeightRangeServices,
  getPhotosNegotationRequest,
  saveCustomerRequestPhotosServices,
  saveCustomerRequestServices,
  saveImagesCustomerRequestServices,
} from '@/services/customerRequestServices';
import { ICustomerPhotosProps } from '@/services/interfaces/customerRequestInterface';
import { Dayjs } from 'dayjs';
import { getMessaggeError } from '../auth/utils';
import { AppDispatch, RootState } from '../store';
import { IUser } from '../userRedux/userReduxInterface';
import {
  ISaveRequestOriginOrDestiny,
  setEchoResponseSuccess,
  getlistCustomerEstimatedWeightRequest,
  getlistCustomerTypeLoadRequest,
  getlistCustomerTypeRequest,
  resetError,
  saveAmountNegotation,
  saveData,
  setDataMovingDestiny,
  setDataMovingOrigin,
  setDateService,
  setDescriptionLoad,
  setDetinyReference,
  setError,
  setErrorImage,
  setIdVerifiedImages,
  setIsRefrigerate,
  setLisConditions,
  setListAmbientes,
  setLoading,
  setOriginReference,
  setPhotosPreLoaded,
  setRequestDetailsView,
  setSelectCity,
  setSelectOrigin,
  setWeightOfTheLoad,
} from './customerRequestSlice';
import { optionOne } from '@/consts/noticeConsts';

export const initFlowCustomerRequestThunk = () => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const { user } = getState();
    const currentUser = user?.user as IUser;
    if (currentUser?.ciudad) dispatch(setSelectCity(currentUser.ciudad));

    if (currentUser?.distrito) dispatch(setSelectOrigin(currentUser.distrito));
  };
};

export const getCustomerRequestTypesThunk = (
  mockListItem: MockListCustomerTypesRequest[]
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const { tiposSolicitudData } =
        await getListCustomerRequestTypesServices();

      const result = prepareListCustomerRequestAdapter(
        tiposSolicitudData.tiposSolicitud,
        mockListItem
      );

      dispatch(getlistCustomerTypeRequest(result));
      return result;
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const getCustomerRequestTypesLoadThunk = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const { tiposCargaData } =
        await getListCustomerRequestTypesLoadServices();

      const result = tiposCargaData.tiposCarga;

      dispatch(getlistCustomerTypeLoadRequest(result));
      return;
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const getCustomerRequestRangeWeightThunk = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const { tiposRangoPesoData } =
        await getListCustomerRequestWeightRangeServices();

      const data = tiposRangoPesoData.tiposRangoPeso;

      dispatch(getlistCustomerEstimatedWeightRequest(data));
      return;
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const getListCustomerRequestTypeConditionsThunk = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const {
        customerRequest: { selectedTypeRequest },
      } = getState();
      const idRequestType = selectedTypeRequest.id?.toString();
      const { tipoSolicitudCondicionesData } =
        await getListCustomerRequestTypeConditionsServices(idRequestType);
      const conditions = prepareListConditionsAdapter(
        tipoSolicitudCondicionesData,
        selectedTypeRequest
      );

      dispatch(setLisConditions(conditions));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

type SaveDataRute = {
  originReference: string;
  destinyReference: string;
  dateService: Dayjs;
};
export const saveDataRuteThunk = (data: SaveDataRute) => {
  return (dispatch: AppDispatch) => {
    const { originReference, destinyReference } = data;
    const stringDate = data.dateService?.format(FORMAT_DATE_DDMMYYYY) || '';
    dispatch(setOriginReference(originReference));
    dispatch(setDetinyReference(destinyReference));
    dispatch(setDateService(stringDate));
  };
};

type SaveDataLoadAndVehicle = {
  descriptionLoad: string;
  isRefrigerate: boolean;
  images: File[];
  weight: string;
};
export const saveDataLoadAndVehicleThunk = (data: SaveDataLoadAndVehicle) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { descriptionLoad, isRefrigerate, images } = data;
      const weightOfTheLoad = !data.weight ? 0 : Number(data.weight);
      dispatch(setWeightOfTheLoad(weightOfTheLoad));
      dispatch(setIsRefrigerate(isRefrigerate));
      dispatch(setDescriptionLoad(descriptionLoad));
      dispatch(resetError());
      const IsArrayImgIsNotEmpty = Object.values(images).filter(
        (element) => !!element
      );

      if (!!IsArrayImgIsNotEmpty.length) {
        await dispatch(saveImagesCustomerRequestThunk(data));
      }
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const saveCustomRequestPhotosThunk = (idApplication: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const {
        customerRequest: { images },
      } = getState();
      const body = await getBodyCustomerRequestPhotoAdapter(images);
      const { solicitudData } = await saveCustomerRequestPhotosServices(
        idApplication.toString(),
        body
      );

      dispatch(saveData(solicitudData.solicitud));
      dispatch(setLoading(false));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const saveCustomerRequestThunk = (price: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      const { customerRequest } = getState();
      const { idVerifiedImages, photosPreLoaded } = customerRequest;

      let body = prepareSaveInCityAdapter(customerRequest, price);

      if (idVerifiedImages && photosPreLoaded?.length) {
        await manageImageOnSave(idVerifiedImages, photosPreLoaded).then(
          (res) =>
            (body = {
              ...body,
              idFotosVerificadas: res.fotosverificadasId,
            })
        );
      }

      const { solicitudCreadaData } = await saveCustomerRequestServices(body);

      dispatch(setEchoResponseSuccess(solicitudCreadaData));
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const saveDataRequestMovingOriginOrDestinyThunk = (
  data: ISaveRequestOriginOrDestiny,
  type: number
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      if (type === 1) {
        dispatch(setDataMovingOrigin(data));
      } else {
        dispatch(setDataMovingDestiny(data));
      }
      dispatch(setLoading(false));
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const getListCustomerRequestTypesAmbientesThunk = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const {
        tiposAmbienteData: { tiposAmbiente },
      } = await getListCustomerRequestTypesAmbientesServices();
      const ambientes = tiposAmbiente.map((ambien) => ({
        ...ambien,
        cantidad: 0,
      }));
      dispatch(setListAmbientes(ambientes));
      dispatch(setLoading(false));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};
// express
export const saveCustomerRequestExpressThunk = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      const { customerRequest } = getState();
      const body = prepareSaveExpressAdapter(customerRequest);
      const { solicitudCreadaData } = await saveCustomerRequestServices(body);

      dispatch(setEchoResponseSuccess(solicitudCreadaData));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

// moving

export const saveCustomerRequestMovingThunk = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      const { customerRequest } = getState();
      const body = prepareSaveMovingAdapter(customerRequest);
      const { solicitudCreadaData } = await saveCustomerRequestServices(body);

      dispatch(setEchoResponseSuccess(solicitudCreadaData));

      dispatch(setLoading(false));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const saveCustomerMultidestinyRequestThunk = (
  price: string,
  firstInputValue: string,
  secondInputValue: string,
  flaghoraopunto: number
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      const { customerRequest, selectLocation } = getState();
      const { idVerifiedImages, photosPreLoaded } = customerRequest;

      const { multiSelectDestiny } = selectLocation;

      let body = prepareSaveMultidestinyAdapter(
        customerRequest,
        multiSelectDestiny,
        price,
        firstInputValue,
        secondInputValue,
        flaghoraopunto
      );

      if (idVerifiedImages && photosPreLoaded.length) {
        await manageImageOnSave(idVerifiedImages, photosPreLoaded).then(
          (res) =>
            (body = {
              ...body,
              idFotosVerificadas: res.fotosverificadasId,
            })
        );
      }

      const { solicitudCreadaData } = await saveCustomerRequestServices(body);

      dispatch(setEchoResponseSuccess(solicitudCreadaData));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const saveCustomerBetweenCitiesRequestThunk = (price: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      const { customerRequest } = getState();

      const { idVerifiedImages, photosPreLoaded } = customerRequest;

      let body = prepareSaveBetweenCitiesAdapter(customerRequest, price);

      if (idVerifiedImages && photosPreLoaded.length) {
        await manageImageOnSave(idVerifiedImages, photosPreLoaded).then(
          (res) =>
            (body = {
              ...body,
              idFotosVerificadas: res.fotosverificadasId,
            })
        );
      }

      const { solicitudCreadaData } = await saveCustomerRequestServices(body);

      dispatch(setEchoResponseSuccess(solicitudCreadaData));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};
// cambiar las interfaces
export const getListCustomerRequestThunk = (idCustomerRequest: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const res = await getCustomerRequestByIdServices(idCustomerRequest);
      const { solicitudData } = res;

      dispatch(setRequestDetailsView(solicitudData.solicitud));
      return res;
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};
// cambiar las interfaces
export const getListCustomerRequestPrivThunk = (idCustomerRequest: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      let res = await getCustomerRequestByIdPrivServices(idCustomerRequest);
      let {
        solicitudData: { solicitud },
      } = res;
      let { estado } = solicitud;
      estado.flgPermiteVerNegociaciones =
        estado.flgPermiteVerNegociaciones || optionOne;
      estado.flgVerNegociaciones = estado.flgVerNegociaciones || optionOne;

      dispatch(setRequestDetailsView(solicitud));
      return res;
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const saveImagesCustomerRequestThunk = ({
  images,
}: Pick<SaveDataLoadAndVehicle, 'images'>) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      const {
        customerRequest: { photosPreLoaded, idVerifiedImages },
      } = getState();

      const body: ICustomerPhotosProps = getPhotosOffertAdapter([
        ...photosPreLoaded,
        ...images,
      ]);

      const { fotosverificadas } = await saveImagesCustomerRequestServices(
        idVerifiedImages,
        body
      );
      const recordedPhotos = prepareListPhotos(fotosverificadas);
      dispatch(setIdVerifiedImages(fotosverificadas?.id));
      dispatch(setPhotosPreLoaded(recordedPhotos));
      dispatch(setLoading(false));
    } catch (err: any) {
      const { message } = getMessaggeError(err);

      dispatch(setErrorImage(message));
      throw new Error(message || undefined);
    }
  };
};

// ==================================================================================================
//       THUNK PARA VALIDAR SI LA SOLICITUD TIENE OFERTAS O CONTRAOFERTAS
// ==================================================================================================

export const getAmountNegotationThunk = (body: Cuantas_Negociaciones) => {
  return async (dispatch: AppDispatch) => {
    try {
      const {
        solicitudCuantasData: { totalNegociaciones },
      } = await getAmountNegotationRequest(body);

      dispatch(saveAmountNegotation(totalNegociaciones));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

// =========================================
//       THUNK LAS FOTOS EN LA EDICIÓN
// =========================================

export const getPhotosNegotationThunk = (id: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { solicitudFotoData: solicitudFoto } =
        await getPhotosNegotationRequest(id);

      return solicitudFoto;
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};
