import HeaderSectionV2 from "@/components/headerSectionV2/HeaderSectionV2";
import SectionIconV2 from "@/components/sectionIconV2/SectionIconV2";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { DetailNoticeMoving } from "@/models/notice/noticeModels";
import { ContainerArea } from "@/pages/createServices/suggestions/styleSuggestions";
import { getPrevRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { Container } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";

const ConditionsDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { selectTypeNotice } = useAppSelector(
    (state) => state.noticeV2ClientAorCO
  );

  const notice = selectTypeNotice as DetailNoticeMoving;

  const handlePreviusRute = () => {
    dispatch(
      getPrevRoute(navigate, {
        valueToReplace: notice.id?.toString(),
        valueToSearch: ":id",
      })
    );
  };

  return (
    <Container maxWidth="xs">
      <HeaderSectionV2
        icon="icon-arrow-left"
        title="Condiciones del servicio"
        subTitle="Solicitar cotización"
        onClick={handlePreviusRute}
      />
      <ContainerArea>
        <SectionIconV2 icon="icon-condition" />
        <Outlet />
      </ContainerArea>
    </Container>
  );
};
export default ConditionsDetails;
