import {
  requestRuteAdapter,
  RequestRuteAdapter,
} from "@/adapters/store/editNegotationRequestAdapter";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import DatePicker from "@/components/form/datePicker.tsx/DatePicker";
import Input from "@/components/inputCustom/Input";
import { FORMAT_DATE_DDMMYYYY } from "@/consts/formatsDate";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { setActiveButton } from "@/store/customerRequestRedux/customerRequestSlice";
import { uploadRuteRequestEdit } from "@/store/noticeNegotationRequestRedux/editNegRequestThunk";
import { FormControl, Grid, styled, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { ChangeEvent, useEffect, useId, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { input1, input2 } from "../config";

const Container = styled(Grid)(
  () => `
    display: flex;
    flex-direction: column;  
    margin-top: 24px;
  `
);

const FormRuteEditInCity = () => {
  const navigate = useNavigate();
  const { typeId = "" } = useParams();

  const {
    customerRequest: { requestDetailsView, isLoading },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const [datePicker, setDatePicker] = useState<Dayjs | null>(
    dayjs().add(1, "day")
  );
  const id = useId();
  const [origin, setOrigin] = useState("");
  const [originReference, setOriginReference] = useState("");
  const [destiny, setDestiny] = useState("");
  const [destinyReference, setDestinyReference] = useState("");

  const bodyObject = {
    datePicker,
    originReference,
    destinyReference,
    origin,
    destiny,
  };

  useEffect(() => {
    handleDispatch();
  }, []);

  const handleDispatch = () => {
    if (requestDetailsView) {
      setOrigin(requestDetailsView.origenTexto);
      setDestiny(requestDetailsView?.destinoTexto);
      setOriginReference(requestDetailsView?.referencia);
      setDestinyReference(requestDetailsView?.referenciaDestino);
      requestDetailsView?.fecha &&
        setDatePicker(dayjs(requestDetailsView?.fecha, FORMAT_DATE_DDMMYYYY));
    }
  };

  const handleSubmit = (event: EventType["submit"]) => {
    event.preventDefault();

    const body = requestRuteAdapter(bodyObject as RequestRuteAdapter);

     dispatch(uploadRuteRequestEdit(typeId, body)).then(() => navigate(-1)); 
  };

  const handleChangeReference = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = target;
    if (name === input1.name) {
      setOriginReference(value);
    } else {
      setDestinyReference(value);
    }
  };

  const handleCompareinputs = () => {
    const currentInputsValue = [originReference, destinyReference, datePicker];
    const dataToInputsValue = [
      requestDetailsView?.referencia,
      requestDetailsView?.referenciaDestino,
      dayjs(requestDetailsView?.fecha, FORMAT_DATE_DDMMYYYY),
    ];

    const result =
      JSON.stringify(currentInputsValue) === JSON.stringify(dataToInputsValue);
    return !result;
  };

  const handleDisabledButton = () => {
    const conditions = [originReference, destinyReference, datePicker];
    let result = true;

    const conditionsResult = conditions.some((condition) => !condition);
    const sameData = handleCompareinputs();

    dispatch(setActiveButton(sameData));

    if (sameData) {
      result = false;
      if (conditionsResult) {
        result = true;
      }
    }

    return result;
  };

  return (
    <Container container>
      <form onSubmit={handleSubmit}>
        <DatePicker
          fullWidth
          initValue={datePicker}
          inputFormat={FORMAT_DATE_DDMMYYYY}
          label={<LabelInputRequired str="Fecha del servicio" />}
          onChange={setDatePicker}
          disablePast={false}
          minDate={dayjs().add(1, "day")}
          renderInput={Input}
        />

        <Typography variant="subtitle1" marginTop="16px">
          Origen: {origin}
        </Typography>

        <FormControl sx={{ marginTop: "16px" }} fullWidth>
          <Input
            id={`${id}-reference-origin`}
            name={input1.name}
            fullWidth
            label={<LabelInputRequired str={input1.label} />}
            value={originReference}
            variant="standard"
            placeholder={input1.placeholder}
            onChange={handleChangeReference}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>

        <Typography variant="subtitle1" marginTop="16px">
          Destino: {destiny}
        </Typography>

        <FormControl sx={{ marginTop: "16px" }} fullWidth>
          <Input
            id={`${id}-reference-destiny`}
            name={input2.name}
            label={<LabelInputRequired str={input2.label} />}
            value={destinyReference}
            variant="standard"
            placeholder={input2.placeholder}
            onChange={handleChangeReference}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <Grid item sx={{ marginTop: "16px" }}>
          <ButtomLoader
            type="submit"
            isLoading={isLoading}
            buttomProps={{
              disabled: handleDisabledButton() || isLoading,
            }}
          >
            GUARDAR
          </ButtomLoader>
        </Grid>
      </form>
    </Container>
  );
};

export default FormRuteEditInCity;
