import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import HeaderModal from "@/components/modals/headerModal/HeaderModal";
import {
  ContainerButtom,
  ContainerModal,
  CustomDrawer,
} from "@/components/modals/stylesContainerFlow";
import { FORMAT_DATE_YYYYMMDD } from "@/consts/formatsDate";

import { stringDDMMYYYToDate } from "@/utils/helpers";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { ModalFilterChildrenProps } from "../../interface/filterInterface";
import FilterDate from "../FilterDate";

interface Props
  extends Omit<
    ModalFilterChildrenProps,
    "onInputChange" | "list" | "prevSelected" | "typeNoticeSelected"
  > {}

const ModalFilterDate = (props: Props) => {
  const { open, onClose, onSeleted, onSubmit, betweenDate, metaData } = props;
  const [selectDate, setSelectDate] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!open) {
      setSelectDate([]);
    } else {
      setSelectDate(handleCleanInputDate());
    }
    setIsLoading(false);

    return () => {
      setIsLoading(true);
    };
  }, [open]);

  const handleCleanInputDate = () => {
    const cleanDate = betweenDate.split(" - ");
    if (Array.isArray(cleanDate) && cleanDate.length > 1) {
      const [dateOne, dateTwo] = cleanDate;
      return handlePrepareInDate(dateOne, dateTwo);
    }
    return handlePrepareInDate("", "");
  };

  const handlePrepareInDate = (startDate: string, endDate: string) => {
    const dateOne = handleConverFormatDate(startDate);
    const dateTwo = handleConverFormatDate(endDate);

    return [dateOne, dateTwo];
  };

  const handleConverFormatDate = (currentDate: string) => {
    if (!currentDate) return dayjs(new Date()).format(FORMAT_DATE_YYYYMMDD);

    const converDate =
      stringDDMMYYYToDate(currentDate)?.format(FORMAT_DATE_YYYYMMDD);

    return converDate || "";
  };

  const handleCheck = (startDate: string, endDate: string) => {
    setSelectDate([startDate, endDate]);
    onSeleted && onSeleted([startDate, endDate]);
  };

  const handleSubmit = () => {
    const result = selectDate.join(" - ");
    onSubmit(result);
  };

  return (
    <CustomDrawer open={true} onClose={() => onClose(false)} anchor="right">
      <HeaderModal title={metaData.title} onClick={() => onClose(false)} />
      <ContainerModal sx={{ position: "relative" }}>
        {!isLoading && (
          <FilterDate handleCheck={handleCheck} initialDates={selectDate} />
        )}
      </ContainerModal>
      <ContainerButtom>
        <ButtomLoader onClick={handleSubmit}>
          {metaData.buttonText}
        </ButtomLoader>
      </ContainerButtom>
    </CustomDrawer>
  );
};

export default ModalFilterDate;
