import { ConfigDataNegotation } from '@/pages/noticeRequestFlow/interface/noticeNegRequestInterface';
import { generalConfigNegotiationRequest } from '@/pages/requestNegotiation/shared/config/generalConfig';

// ==================================================
//          CONFIGURACION ENTRE CIUDADES
// ==================================================

export const configDataBetweenCities: ConfigDataNegotation[] = [
  ...generalConfigNegotiationRequest,
];
