import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { TypeFilterData } from "@/store/FilterServices/filterReduxInterface";
import {
  resetListLocation,
  setTypeNoticeSelect,
} from "@/store/filterNoticeRedux/filterNoticeSlice";
import {
  getCitiesByPaisFilterThunk,
  getDistrictListMultiSelectThunk,
  getDistrictsFilterThunk,
  searchDistrictInInternalListThunk,
  searchTypeVehicleFiltrerListThunk,
  searchWeightOfTheLoadThunk,
} from "@/store/filterNoticeRedux/filterNoticeThunk";
import { IUser } from "@/store/userRedux/userReduxInterface";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import DynamicComponent from "../dinamycComponent/DinamycComponent";
import FilterTabs from "./FilterTabs";
import SelectedOptions from "./SelectedOptions";
import {
  BoxCloseIcon,
  BoxContainer,
  ButtonContainer,
  CardBoxMobile,
  FilterStyle,
  tabHeigh,
} from "./StyleFilter";
import { prepareSelectedValueModal } from "./adapter/filterAdapter";
import ModalFilter from "./components/ModalFilter/ModalFilter";
import {
  FormDataFilter,
  INITIAL_STATE,
  MODAL_ACTIVE,
  typePositions,
} from "./consts/filtersConst";
import { placeholders, remanePlaceholders } from "./consts/metaData";
import { Props, configInCity } from "./inputsConfig/inputsInCity";
import { prepareBetweenDate } from "./utils/helpers";
import { ERROR_EQUAL_DESTINY } from "@/consts/textErrors";
dayjs.locale("es");

type FilterProps = {
  onSubmit: (values: any) => void;
  filterStyles?: FilterStyle;
  show?: boolean;
  initValue: FormDataFilter;
  onChangeTypeNotice?: (typeNotice: TypeFilterData) => void;
  initialOpenFilter?: boolean;
  closeArrow?: boolean;
};

const INITIAL_VALIDATE_ERRORS = {
  origin: {
    error: false,
    helperText: "",
  },
  destiny: {
    error: false,
    helperText: "",
  },
  timeService: {
    error: false,
    helperText: "",
  },
};

const Filter = ({
  onSubmit,
  filterStyles,
  initValue,
  show = false,
  onChangeTypeNotice,
  initialOpenFilter,
  closeArrow = true,
}: FilterProps) => {
  const [value, setValue] = useState(0);
  const [formData, setFormData] = useState<Partial<FormDataFilter>>(initValue);
  const [showModal, setShowModal] = useState(false);
  const [modalActiveWith, setModalActiveWith] = useState(MODAL_ACTIVE);
  const [openInputs, setOpenInputs] = useState(true);
  const [hasInputError, setHasInputError] = useState(INITIAL_VALIDATE_ERRORS);

  const matches = useMediaQuery("(max-width:850px)");

  const {
    filterNotice,
    user: { user },
  } = useAppSelector((state) => state);
  const {
    weightOfTheLoad,
    typeVehicles,
    listLocation,
    typeNotice,
    typeNoticeSelecte,
    typeServiceList,
    listOfDistrict,
  } = filterNotice;
  const dispatch = useAppDispatch();

  useEffect(() => {
    const currentUser = user as IUser;

    let city = INITIAL_STATE.city;
    if (Object.keys(currentUser) && currentUser?.ciudad) {
      if (formData?.city === currentUser?.ciudad.nombre2) {
        city = currentUser?.ciudad.nombre2 || "";
      } else {
        city = formData?.city || "";
      }
    }

    setFormData(() => ({ ...INITIAL_STATE, city }));
    setHasInputError(INITIAL_VALIDATE_ERRORS);

    if (!listOfDistrict.length && typeNoticeSelecte.multiselect && !!city) {
      dispatch(getDistrictListMultiSelectThunk(city as string));
    }

    dispatch(resetListLocation());
  }, [typeNoticeSelecte]);

  useEffect(() => {
    if (show) {
      setOpenInputs(initialOpenFilter || false);
    }
    if (initValue) {
      setFormData((prev) => ({ ...prev, ...initValue }));
    }
    if (typeNoticeSelecte && !!typeServiceList.length) {
      const index = typeNotice.findIndex(
        (list) => list.nombre === typeNoticeSelecte.nombre
      );

      setValue(index);
    }
  }, [initValue]);

  useEffect(() => {}, [formData]); // esto es para que se refresque el valor de betweenDate en el modal!!

  const handleChange = (_: React.SyntheticEvent, index: number) => {
    setValue(index);

    dispatch(setTypeNoticeSelect(typeNotice[index]));

    onChangeTypeNotice && onChangeTypeNotice(typeNotice[index]);
  };

  const onChange = (props: Props) => (event: EventType["change"] | Dayjs) => {
    if (event instanceof dayjs) {
      setFormData((prev) => ({ ...prev, timeService: event as Dayjs }));
    }

    handleOnClick(props)();
  };

  const handleCleanParamsBeforeSubmit = (values: FormDataFilter) => {
    if (!typeNoticeSelecte?.inputfechas) {
      const { betweenDate, ...rest } = values;
      return rest;
    }
    return values;
  };

  const handleOnClick = (props: Props) => () => {
    if (props?.inputFormat && props.name !== "betweenDate") return;
    if (props?.type === "button") {
      if (
        !formData.destiny?.length ||
        !formData.origin?.length ||
        hasInputError.timeService.error
      ) {
        const origin = { error: !formData.origin?.length, helperText: "" };
        const destiny = { error: !formData.destiny?.length, helperText: "" };
        setHasInputError((prev) => ({ ...prev, origin, destiny }));
      } else if (
        formData.origin === formData.destiny &&
        typeNoticeSelecte?.busqueda === "C"
      ) {
        const helperText = ERROR_EQUAL_DESTINY;
        const errorValue = { error: true, helperText };
        setHasInputError((prev) => ({
          ...prev,
          origin: errorValue,
          destiny: errorValue,
        }));
      } else {
        setHasInputError(INITIAL_VALIDATE_ERRORS);
        onSubmit &&
          onSubmit(handleCleanParamsBeforeSubmit(formData as FormDataFilter));
        openInputs && setOpenInputs(false);
      }
      return;
    }

    setModalActiveWith({ name: props.name });
    setShowModal(true);
    dispatch(resetListLocation());
    if (
      typeNoticeSelecte?.multiselect &&
      (props.name === "origin" || props.name === "destiny")
    ) {
      dispatch(searchDistrictInInternalListThunk(""));
    }
  };

  const handleGetValue = ({ name }: { name: keyof FormDataFilter }) => {
    if (name === "timeService") {
      return "";
    }
    return formData ? formData[name] : "";
  };

  const handleOnSeletedModal = (seleted: any) => {
    const exemption = ["origin", "destiny", "city"];
    if (exemption.some((exemp) => exemp === modalActiveWith.name)) {
      handleSubmitModal(seleted);
    }
  };

  const handleSubmitModal = (seleted: any) => {
    const valueModal = prepareSelectedValueModal(seleted);
    const { name: activeWith } = modalActiveWith;

    if (activeWith === "city" && typeNoticeSelecte?.busqueda === "D") {
      setFormData(() => ({
        ...INITIAL_STATE,
        [activeWith]: valueModal,
      }));
      dispatch(getDistrictListMultiSelectThunk(seleted?.nombre2 as string));
    } else if (activeWith === "betweenDate") {
      const value = prepareBetweenDate(seleted);
      setFormData((prev) => ({
        ...prev,
        [activeWith]: value,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [activeWith]: valueModal,
      }));
    }

    handleSetInputsErrors(valueModal, seleted);

    handleOnCloseModal();
  };

  const handleSetInputsErrors = (currentValue: string, seleted: any) => {
    const { name: activeWith } = modalActiveWith;
    if (activeWith === "origin" || activeWith === "destiny") {
      const error = typeNoticeSelecte?.multiselect
        ? !seleted.length
        : !!seleted.length;
      let helperText = "";
      const nemesisValue = handleInvalidSelectModal();

      if (nemesisValue && nemesisValue === currentValue) {
        helperText = ERROR_EQUAL_DESTINY;
        const errorValue = { error: true, helperText };
        setHasInputError((prev) => ({
          ...prev,
          origin: errorValue,
          destiny: errorValue,
        }));
      } else {
        setHasInputError((prev) => ({
          ...prev,
          [activeWith]: {
            error,
            helperText,
          },
        }));
      }
    }
  };

  const handleInvalidSelectModal = () => {
    const { name } = modalActiveWith;
    const { busqueda = "" } = typeNoticeSelecte;
    const objectResult = {
      origin: formData.destiny,
      destiny: formData.origin,
    };
    if ((name === "origin" || name === "destiny") && busqueda === "C") {
      return objectResult[name];
    }
    return undefined;
  };

  const handleOnCloseModal = () => {
    setShowModal(false);
    setModalActiveWith(MODAL_ACTIVE);
  };

  const handleFilterByTerm = (value: string) => {
    if (value?.length < 3 && !typeNoticeSelecte?.multiselect) return;

    const { name } = modalActiveWith;
    if (name === "city") {
      if (value?.length > 2) {
        dispatch(getCitiesByPaisFilterThunk(value));
      }
    } else if (name === "origin" || name === "destiny") {
      if (typeNoticeSelecte?.multiselect) {
        dispatch(searchDistrictInInternalListThunk(value));
      } else {
        const typeSearch = "C";
        if (typeNoticeSelecte?.busqueda === typeSearch) {
          dispatch(getCitiesByPaisFilterThunk(value));
        } else {
          dispatch(getDistrictsFilterThunk(value, formData.city as string));
        }
      }
    } else if (name === "typeVehicle") {
      dispatch(searchTypeVehicleFiltrerListThunk(value));
    } else if (name === "weightOfTheLoad") {
      dispatch(searchWeightOfTheLoadThunk(value));
    }
  };

  const handleGetList = () => {
    switch (modalActiveWith.name) {
      case "weightOfTheLoad":
        return weightOfTheLoad;
      case "typeVehicle":
        return typeVehicles;
      case "typeServiceList":
        return typeServiceList;
      default:
        return listLocation;
    }
  };

  const handleValidShow = (columnProps: any) => {
    const moreProps = columnProps?.callback
      ? columnProps?.callback(columnProps, filterNotice)
      : {};
    if (columnProps?.propertyLink) {
      const propertyLink = columnProps?.propertyLink as keyof TypeFilterData;
      if (typeNoticeSelecte[propertyLink] === 0) {
        return { sx: { display: "none" } };
      }
    }

    return { ...columnProps, ...moreProps };
  };

  const handleValidError = (inputProps: any) => {
    if (!Object.keys(inputProps).length) return false;

    if (inputProps?.name === "origin" || inputProps?.name === "destiny") {
      const name: "origin" | "destiny" = inputProps?.name;
      return hasInputError[name];
    }

    return false;
  };

  const handleErrorDate = (props: Props) => (error: any) => {
    if (props.name === "timeService") {
      const timeService = { error: !!error, helperText: "" };
      setHasInputError((prev) => ({ ...prev, timeService }));
    }
  };

  const handleGetPlaceholder = (props: Props) => {
    const typeNoticePlaceholder = placeholders[typeNoticeSelecte.nombre];

    if (
      remanePlaceholders.some((name) => name === props.name) &&
      typeNoticePlaceholder
    ) {
      return typeNoticePlaceholder[props.name] || {};
    }

    return {};
  };

  const handleInitialDate = (props: Props) => () => {
    if (props.name === "timeService") {
      if (!!initValue.timeService) {
        return formData.timeService;
      }
    }
  };

  const handleInitConfig = (column: any) => {
    const moreProps = column?.props?.callback
      ? column?.props?.callback(column?.props, filterNotice)
      : {};

    const props = {
      ...column.props,
      onClick: handleOnClick(column.props),
      onChange: onChange(column.props),
      value: handleGetValue(column.props),
      onError: handleErrorDate(column?.props),
      ...handleValidError(column?.props),
      ...handleValidShow(column.props),
      initValue: handleInitialDate(column.props),
      ...handleGetPlaceholder(column.props),
      ...moreProps,
    };

    return {
      ...column,
      props,
    };
  };

  const handleMultiSelect = () => {
    const { name } = modalActiveWith;
    return (
      (name === "origin" || name === "destiny") &&
      !!typeNoticeSelecte?.multiselect
    );
  };

  const handleColumnsProps = (column: any) => {
    let newStyle = {};
    let newValue = column.columnsProps;

    if (matches) {
      newStyle = {
        paddingRight: "0px",
        paddingBottom: "16px",
        flexBasis: "100%",
      };

      newValue = { ...column.columnsProps, sx: newStyle };

      return newValue;
    }

    return column.columnsProps;
  };

  const handlePositionBtnClose = () => {
    let position = "absolute";
    const currentPosition = typePositions.find(
      (type) => type.type === typeNoticeSelecte.nombre
    );
    if (currentPosition) {
      position = currentPosition.position;
    }

    return position;
  };

  const handleContainerInput = () => {
    if (!show) return {};

    let containerInputs = matches
      ? { padding: "16px", paddingTop: "16px" }
      : filterStyles?.inputsContainer;

    return containerInputs;
  };

  const handleContainerBox = () => {
    if (matches && show)
      return {
        borderRadius: "16px",
        marginTop: `${tabHeigh + 41}px`,
        ...filterStyles?.inputContainer,
      };

    return {};
  };

  const handleBoxContainer = () => {
    return !!filterStyles?.boxContainer ? filterStyles?.boxContainer : {};
  };

  return (
    <section
      style={{
        height: "100%",
        paddingBottom: "10px",
        paddingTop: "10px",
        ...filterStyles?.section,
      }}
    >
      <ModalFilter
        open={showModal}
        onClose={handleOnCloseModal}
        onSubmit={handleSubmitModal}
        onSeleted={handleOnSeletedModal}
        onInputChange={handleFilterByTerm}
        modalActiveWith={modalActiveWith.name}
        list={handleGetList()}
        isMultiSelect={handleMultiSelect()}
        betweenDate={formData?.betweenDate || ""}
        prevSelected={formData ? formData[modalActiveWith?.name] : ""}
        typeNoticeSelected={typeNoticeSelecte.nombre}
      />

      {show && (
        <BoxContainer sx={{ ...handleBoxContainer(), zIndex: 20 }}>
          <Container maxWidth="xl">
            <FilterTabs
              value={value}
              change={handleChange}
              openInputs={openInputs}
              show={!show}
              typeNotice={typeNotice}
              tabsStyled={filterStyles?.StyledTabs}
            />
          </Container>
        </BoxContainer>
      )}

      <Container maxWidth="xl">
        <Box
          sx={{
            boxShadow:
              matches || !openInputs
                ? "none"
                : "-3px 4px 7px 0px rgba(0,0,0,0.05)",
            marginTop: "50px",
            marginBottom: "50px",
            position: matches && openInputs && show ? "relative" : "",
            borderRadius: "24px",
            display: "flex",
            flexDirection: "column",
            paddingBottom: 0,
            zIndex: 10,
            ...filterStyles?.Box,
          }}
        >
          {!show && (
            <FilterTabs
              value={value}
              change={handleChange}
              openInputs={openInputs}
              show={!show}
              typeNotice={typeNotice}
              tabsStyled={filterStyles?.StyledTabs}
            />
          )}

          {openInputs && (
            <>
              <Box
                style={{
                  backgroundColor: "#fff",
                  padding: "16px",
                  borderRadius: "0 0 24px 24px",
                  ...handleContainerBox(),
                  ...handleContainerInput(),
                  border: "1px solid black" 
                }}
              >
                {!matches && show && (
                  <BoxCloseIcon>
                    <ButtonContainer
                      onClick={() => setOpenInputs(false)}
                      sx={{
                        cursor: "pointer",
                        position: handlePositionBtnClose(),
                        top: "15px",
                        right: 6,
                      }}
                    >
                      <Typography sx={{ fontWeight: 600, opacity: 0.9 }}>
                        Cerrar
                      </Typography>
                      <CloseIcon sx={{ fontSize: "24px" }} />
                    </ButtonContainer>
                  </BoxCloseIcon>
                )}

                {configInCity.map((config) =>
                  config.map((items, i) => {
                    return (
                      <Grid
                        key={`config-${i}`}
                        container
                        sx={{ ...items.styleRow}}
                      >
                        {items.columns.map((column, index) => {
                          const newColumnsProps = handleColumnsProps(column);

                          return (
                            <Grid
                              key={`subconfig-${index}`}
                              item
                              {...handleValidShow(newColumnsProps)}
                            >
                              <DynamicComponent {...handleInitConfig(column)} />
                            </Grid>
                          );
                        })}
                      </Grid>
                    );
                  })
                )}
                {matches && show && closeArrow && (
                  <CardBoxMobile sx={{ justifyContent: "center" }}>
                    <ButtonContainer
                      onClick={() => setOpenInputs(false)}
                      sx={{ pb: "0px", cursor: "pointer" }}
                    >
                      <ExpandLessIcon sx={{ fontSize: "24px" }} />
                    </ButtonContainer>
                  </CardBoxMobile>
                )}
              </Box>
            </>
          )}

          <Box sx={{ pt: 2.8, display: openInputs ? "none" : "block" }}>
            <SelectedOptions
              filterStyles={filterStyles}
              buttonCLick={setOpenInputs}
              formData={formData as FormDataFilter}
            />
          </Box>
        </Box>
      </Container>
    </section>
  );
};

export default Filter;
