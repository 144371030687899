import BasicModal from "@/components/dialog/Notiffication";
import HeaderSectionV2 from "@/components/headerSectionV2/HeaderSectionV2";
import SnackbarNotification from "@/components/snackbar/Snackbar";
import { ID_MOVING_FLOW } from "@/consts/defaultConsts";
import {
  NEGOTATION_CO_COUNTEROFFER,
  NEGOTATION_REJECTION_CONFIRMATION,
} from "@/consts/negotationFlowPath";
import { ERROR_NEGOTATION_CLOSED } from "@/consts/textErrors";
import { useAppSelector } from "@/hooks/useReducer";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import HeaderDetail from "@/pages/noticeV2Negotation/components/HeaderDetail";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { IconColor } from "@/utils/Icon";
import { compareUrls, deleteDomainUrl } from "@/utils/helpers";
import {
  Alert,
  Container,
  Grid,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link as ReactLink, useLocation, useNavigate } from "react-router-dom";
import { EditCounterOfferButtons } from "../../components/globalButtons/EditNegotationButtons";
import {
  acceptDataRequestNegotiation,
  cocounterOfferDataRequestNegotiation,
  cocounterOfferDataRequestNegotiationClient,
  handleTextStatusAlert,
  movingCounterOfferRequestNegotiation,
} from "../../shared/config/offerConfig";
import { useGetRequestData } from "../../shared/hooks/useGetRequestData";
import { validIsClientRequestNeg } from "../../shared/utils/RequestNegotiationsUtils";
import Buttons from "./components/Buttons";
import { GlobalCoCounterOfferPrice } from "./components/GlobalCoCounterOfferPrice";
import { noResponseText, optionEleven } from "@/consts/noticeConsts";
interface LocationState {
  hasBackNavigate?: boolean;
  hasBackLinkOffert?: boolean;
}

export const ReceiveCoCounterOfferLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    error,
    data,
    isCounterOffer,
    typeRequest,
    validSnackbarClient,
    validSnackbarDriver,
    redirect,
    flgAbierto,
  } = useGetRequestData();
  const {
    user: { user },
  } = useAppSelector((state) => state);
  let location = useLocation();
  const locationState = location.state as LocationState;
  const { pathname } = location;
  const [hasBackLinkOffert, setHasBackLinkOffert] = useState(false);
  const [hasBackNavigate, setHasBackNavigate] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const isDriver = validIsClientRequestNeg(
    data?.solicitud?.cliente?.id,
    user as IUser
  );
  const negotationClosed = error === ERROR_NEGOTATION_CLOSED;
  const isMoving = typeRequest === ID_MOVING_FLOW;
  const clientOrDriver = !isDriver ? validSnackbarDriver : validSnackbarClient;
  const textTypeNeg = pathname.includes(NEGOTATION_CO_COUNTEROFFER)
    ? "contraoferta"
    : "oferta";
  const noResponse =
    data?.ultimaSolicitudNegociacionAcciones.tipoSolicitudNegociacionAccion
      .estado.id === optionEleven;

  const textStatusAlert = handleTextStatusAlert(textTypeNeg);
  const textToRender = textStatusAlert[!isDriver ? 1 : 2];

  useEffect(() => {
    let defaultofferText = "Oferta del transportista";

    if (locationState?.hasBackNavigate && !locationState?.hasBackLinkOffert) {
      setHasBackNavigate(true);
    }

    if (locationState?.hasBackLinkOffert && !locationState?.hasBackNavigate) {
      setHasBackLinkOffert(true);
    }

    if (typeRequest === ID_MOVING_FLOW) {
      defaultofferText = "Cotización del transportista";
    }
  }, [location.state]);

  const handleNavigationBack = () => {
    navigate(-1);
  };

  const handleNegotationClosed = () => {
    const nextPath = pathname + NEGOTATION_REJECTION_CONFIRMATION;

    return nextPath;
  };

  const handleHeaderProps = () => {
    if (isCounterOffer) {
      if (isMoving) {
        return movingCounterOfferRequestNegotiation;
      }

      const value = !isDriver
        ? cocounterOfferDataRequestNegotiation
        : cocounterOfferDataRequestNegotiationClient;

      return value;
    }

    return acceptDataRequestNegotiation;
  };

  return (
    <>
      {(hasBackLinkOffert || hasBackNavigate) && (
        <HeaderSectionV2
          icon="icon-arrow-left"
          title=""
          onClick={() => handleNavigationBack()}
        />
      )}

      <SnackbarNotification />

      {(redirect && compareUrls(redirect, pathname)) || negotationClosed ? (
        <Alert
          sx={{ mb: 3, p: 2 }}
          severity={negotationClosed ? "error" : "warning"}
        >
          El estado de esta negociación ha cambiado.{" "}
          <Link
            component={ReactLink}
            color={negotationClosed ? "error" : "rgba(237,108,2,1)"}
            fontWeight={600}
            to={
              negotationClosed
                ? handleNegotationClosed()
                : deleteDomainUrl(redirect || "")
            }
            replace
          >
            Ver estado actual.
          </Link>
        </Alert>
      ) : (
        <>
          {!noResponse && (
            <>
              {clientOrDriver() && (
                <Alert sx={{ mb: 3, p: 2 }} severity="info">
                  {textToRender}
                </Alert>
              )}
            </>
          )}
        </>
      )}

      <Container maxWidth={matches ? "xl" : "xs"}>
        {!noResponse && (
          <>
            <HeaderDetail
              title={handleHeaderProps().title}
              icon={handleHeaderProps().icon}
              iconColor={handleHeaderProps().iconColor as IconColor}
            />
          </>
        )}

        {noResponse && (
          <Alert sx={{ mb: 3, mt: 3, p: 2 }} severity="info">
            {noResponseText}
          </Alert>
        )}

        <Grid
          container
          spacing={0}
          sx={{
            display: "flex",
            justifyContent: "center",
            pl: matches ? 20 : 0,
            pr: matches ? 20 : 0,
          }}
        >
          <Grid xs={matches ? 7 : 12}>{children}</Grid>

          <Grid xs={matches ? 5 : 12} sx={{ padding: "0px" }}>
            <GlobalCoCounterOfferPrice data={data as SolicitudNegociacion} />

            {clientOrDriver() && !flgAbierto ? (
              <EditCounterOfferButtons />
            ) : (
              <Buttons />
            )}
          </Grid>
        </Grid>
      </Container>
      <BasicModal />
    </>
  );
};
