import { ConfigDataNegotation } from '@/pages/noticeRequestFlow/interface/noticeNegRequestInterface';
import { generalConfigMultidestiny } from '@/pages/requestNegotiation/shared/config/multidestinyConfig';
/* import { generalConfigMultidestiny } from '../../../shared/config/multidestinyConfig'; */

// ==================================================
//          CONFIGURACION MULTIDESTINO
// ==================================================

export const configDataCounterOfferMultidestiny: ConfigDataNegotation[] = [
  ...generalConfigMultidestiny,
];
