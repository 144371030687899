import { ISelectListItems } from '@/components/form/selectControl/SelectControlMulti';
import { RequestConditions } from '@/models/customerRequest/customerRequestTypesConditions';
import { InitialStateCustomerRequest } from '@/store/customerRequestRedux/customerRequestSlice';
import { ITipoInmuebles } from '@/store/offertRedux/offert.interface';

export type ModalTypeRequest = {
  type: 'origin' | 'destiny' | 'ciudad1' | 'ciudad2';
};
export const MODAL_TYPE_REQUEST: {
  origin: Extract<ModalTypeRequest['type'], 'origin'>;
  destiny: Extract<ModalTypeRequest['type'], 'destiny'>;
  ciudad1: Extract<ModalTypeRequest['type'], 'ciudad1'>;
  ciudad2: Extract<ModalTypeRequest['type'], 'ciudad2'>;
} = {
  origin: 'origin',
  destiny: 'destiny',
  ciudad1: 'ciudad1',
  ciudad2: 'ciudad2',
};
interface configFormRequest {
  origin: Destiny;
  destiny: Destiny;
}

interface Destiny {
  input1: Input;
  input2: Input;
  input3: Input;
  modal1: Input;
  modal2: Input;
}

interface Input {
  name: string;
  label: string;
  placeholder: string;
}

export const configFormRequest: configFormRequest = {
  origin: {
    input1: {
      name: MODAL_TYPE_REQUEST?.ciudad1,
      label: '¿Cuál es tu ciudad de origen?',
      placeholder: 'Ciudad de origen',
    },
    input2: {
      name: MODAL_TYPE_REQUEST?.origin,
      label: 'Distrito de origen',
      placeholder: 'Distrito de origen',
    },
    input3: {
      name: 'reference-origin',
      label: 'Referencia de la ubicación',
      placeholder: 'Lugar, parque o av cercana a la dirección',
    },
    modal1: {
      name: 'city-origin',
      label: 'Ciudad de origen',
      placeholder: 'Ingresa una ciudad',
    },
    modal2: {
      name: 'district-origin',
      label: 'Distrito de origen',
      placeholder: 'Ingresa un distrito',
    },
  },
  destiny: {
    input1: {
      name: MODAL_TYPE_REQUEST?.ciudad2,
      label: '¿Cuál es tu ciudad de destino?',
      placeholder: 'Ciudad de destino',
    },
    input2: {
      name: MODAL_TYPE_REQUEST?.destiny,
      label: 'Distrito de destino',
      placeholder: 'Distrito de destino',
    },
    input3: {
      name: 'reference-destiny',
      label: 'Referencia de la ubicación',
      placeholder: 'Lugar, parque o av cercana a la dirección',
    },
    modal1: {
      name: 'city-destiny',
      label: 'Ciudad de destino',
      placeholder: 'Ingresa una ciudad',
    },
    modal2: {
      name: 'district-destiny',
      label: 'Distrito de destino',
      placeholder: 'Ingresa un distrito',
    },
  },
};

export const modalTitleConfig: Record<string, string> = {
  origin: 'Distrito de origen',
  destiny: 'Distrito de destino',
};

export const ElevatorOptions = [
  {
    id: 1,
    nombre: 'Si',
  },
  {
    id: 2,
    nombre: 'No',
  },
  {
    id: 3,
    nombre: 'Sólo para algunas cosas',
  },
];

export const configSelectOrInput = (
  application: InitialStateCustomerRequest,
  items: ISelectListItems[]
): ISelectListItems[] => {
  const { flgCiudadoEntreciudades } = application;
  const res: ISelectListItems[] = [];
  items.forEach((item, i) => {
    if (item.offertType === flgCiudadoEntreciudades) {
      res.push(item);
    }
  });
  if (Number(res[0]?.value) === 0) {
    return [res[0]];
  }

  if (Number(res[0]?.value) === 1) {
    return [res[0]];
  }

  return res;
};

export const adapterConfigInmueble = (
  application: InitialStateCustomerRequest,
  formInmueble: any,
  handleChange: (e: any) => void,
  tipoInmuebles: ITipoInmuebles[]
) => {
  const { flgCiudadoEntreciudades } = application;
  const { idInmueble, piso, elevador } = formInmueble;
  return {
    inmueble: true,
    listInmueble: configSelectOrInput(application, [
      {
        type: 'select',
        label: 'Tipo de inmueble',
        options: tipoInmuebles || [],
        required: true,
        value: idInmueble,
        name: 'idInmueble',
        onChange: handleChange,
        offertType: flgCiudadoEntreciudades,
      },
      {
        type: 'input',
        label: 'Piso',
        name: 'piso',
        placeholder: '# de piso de tu departamento',
        required: true,
        onChange: handleChange,
        value: piso,
        offertType: flgCiudadoEntreciudades,
      },
      {
        type: 'select',
        label: '¿Se puede usar el ascensor para la mudanza?',
        required: true,
        options: ElevatorOptions,
        name: 'elevador',
        value: elevador,
        onChange: handleChange,
        offertType: flgCiudadoEntreciudades,
      },
    ]),
  };
};

export const conditionAdapter = (
  conditions: RequestConditions[]
): RequestConditions[] => {
  const condiciones = conditions.map((item) => ({
    id: item.id,
    nombre: item.nombre,
    idcondicion: item.idcondicion,
    // transportista: item.transportista,
    // cliente: item.cliente,
    creadorPositivo: '',
    creadorNegativo: '',
    visitantePositivo: '',
    visitanteNegativo: '',
    flgorigendestino: item.flgorigendestino,
    flgVer: item?.flgVer,
    isActive: !!item.flgSeleccion,
  }));

  return condiciones;

  /*   id: number;
  nombre: string;
  creadorpositivo: string;
  creadornegativo: string;
  visitantepositivo: string;
  visitantenegativo: string;
  flgorigendestino: number;
  isActive?: boolean; */
};
