import { useAppSelector } from "@/hooks/useReducer";
import React from "react";
import { BodyBox, ContainerBody, SectionBody, SectionHeaderFooter, SubTitleBody, TitleBody } from "./styleBodySuccess";
import { INoticeCreatedResponse } from "@/services/noticeServices/noticeSendData/noticeSendDataTypesResponse";
import Icon from "@/utils/Icon";
type SectionBeforeDestiny = {
  icon: string;
  title: string;
  propertyFlowData: "fechafija"; // NOTE: Aqui se tienen que agregar todas las propiedades a usar!!
};
type Props = {
  sectionBeforeDestiny?: SectionBeforeDestiny;
  createdData: INoticeCreatedResponse[];
};
const BodySuccessMovingCities = ({ sectionBeforeDestiny, createdData }: Props) => {
  const { precio: price, placa, origentexto, destinotexto, ayudantes, ...rest } = createdData[0];
  return (
    <ContainerBody
      container
      columns={{ xs: 2 }}
      rowSpacing={{ xs: 0 }}
      columnSpacing={{ xs: 0 }}
      justifyContent="center"
      justifyItems="center"
    >
      <SectionHeaderFooter
        item
        xs={2}
        borderBottom={({ palette }) => ({
          borderBottom: `1px solid ${palette.borderGray.main}`,
        })}
      >
        <TitleBody> Origen </TitleBody>
        <SubTitleBody>{origentexto} </SubTitleBody>
      </SectionHeaderFooter>
      <SectionHeaderFooter
        item
        xs={2}
        borderBottom={({ palette }) => ({
          borderBottom: `1px solid ${palette.borderGray.main}`,
        })}
      >
        <BodyBox>
        <Icon name="icon-people" size="md" />
          <TitleBody># Ayudantes</TitleBody>
        </BodyBox>
        <SubTitleBody>{ayudantes}</SubTitleBody>
      </SectionHeaderFooter>
      <SectionBody item xs={1} className="multi">
        <BodyBox>
        <Icon name="icon-truck" size="md" />
          <TitleBody>Vehículo</TitleBody>
        </BodyBox>
        <SubTitleBody>{placa}</SubTitleBody>
      </SectionBody>

      <SectionBody item xs={1} className="multi">
        <BodyBox>
        <Icon name="icon-money" size="md" />
          <TitleBody>Precio por viaje</TitleBody>
        </BodyBox>
        <SubTitleBody>S/ {price}</SubTitleBody>
      </SectionBody>

      {sectionBeforeDestiny && (
        <SectionHeaderFooter
          item
          xs={2}
          borderTop={({ palette }) => ({
            borderTop: `1px solid ${palette.borderGray.main}`,
          })}
        >
          <BodyBox>
            <i className={sectionBeforeDestiny.icon}></i>
            <TitleBody>{sectionBeforeDestiny.title}</TitleBody>
          </BodyBox>
          <SubTitleBody>{rest[sectionBeforeDestiny.propertyFlowData]}</SubTitleBody>
        </SectionHeaderFooter>
      )}

      <SectionHeaderFooter
        item
        xs={2}
        borderTop={({ palette }) => ({
          borderTop: `1px solid ${palette.borderGray.main}`,
        })}
      >
        <TitleBody>Destino</TitleBody>
        <SubTitleBody>{destinotexto}</SubTitleBody>
      </SectionHeaderFooter>
    </ContainerBody>
  );
};

export default BodySuccessMovingCities;
