import StandardAlert from "@/components/alerts/StandardAlert";
import BannerModal from "@/components/modals/BannerModal/BannerModal";
import SkeletonCustomGroup from "@/components/skeletons/SkeletonCustomGroup";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { ButtonCard } from "@/pages/landing/clients/stylePageClients";
import HeaderProfile from "@/pages/profile/headerProfile/HeaderProfile";
import { CustomListLinks } from "@/pages/profile/styleProfile";
import { IVehicleList } from "@/store/userRedux/userReduxInterface";
import { setSuccessMsg } from "@/store/userRedux/userSlice";
import {
  deleteUserVehicleThunk,
  getUserVehiclesByIdThunk,
} from "@/store/userRedux/userThunks";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IListActionsEditVehicle,
  ListActionsEditVehicle,
  photoPosition,
} from "./configDataEditVehicle";

const EditVehicle = () => {
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { isLoading, selectedVehicleList, successMsg } = useAppSelector(
    (state) => state.user
  );
  const vehicle = selectedVehicleList as IVehicleList;

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    dispatch(getUserVehiclesByIdThunk(pathname.split("/")[3]));
  }, [pathname]);

  const handleAcceptDelete = (id: string) => () => {
    dispatch(deleteUserVehicleThunk(id)).then((res) => {
      dispatch(setSuccessMsg("Vehículo eliminado. "));
      navigate(-1);
    });
  };
  useEffect(() => {
    if (successMsg && successMsg !== "") {
      timer.current = setTimeout(() => {
        dispatch(setSuccessMsg(""));
      }, 5000);
    }

    return () => {
      clearTimeout(timer.current as NodeJS.Timeout);
    };
  }, [successMsg]);

  const handleGetList = () => {
    if (vehicle.tipovehiculo?.flgPlacaObligatoria === 0) {
      return ListActionsEditVehicle.slice(0, ListActionsEditVehicle.length - 1);
    }

    return ListActionsEditVehicle;
  };

  const handleGetSecondaryText = (value: IListActionsEditVehicle) => {
    const isDatosPosition = value.position === photoPosition.data;
    const isFotosPosition = value.position === photoPosition.photo;
    const isDocumentoPosition = value.position === photoPosition.document;
    const hasRequiredPhotos =
      vehicle?.flgFotoFrontal &&
      vehicle?.flgFotoLateral &&
      vehicle?.flgFotoPosterior;

    let message = "";
    if (isDatosPosition && !vehicle.flgDatosGenerales) {
      message = vehicle.estado;
    } else if (isFotosPosition && !hasRequiredPhotos) {
      message = vehicle.estado;
    } else if (isDocumentoPosition && vehicle?.flgFotoTarjeta) {
      message = vehicle.estado;
    }

    return message?.toLowerCase();
  };

  const handleGetListActions = useMemo(() => handleGetList, [vehicle]);

  const listVehicle = handleGetListActions();

  if (!vehicle || isLoading) {
    return (
      <SkeletonCustomGroup
        skeletonList={[
          { height: 104, width: 328 },
          { height: 200, width: 328 },
          { height: 60, width: 328 },
        ]}
        gap="16px"
        sx={{
          marginTop: "16px",
        }}
        isContainer={false}
      />
    );
  }

  return (
    <>
      <HeaderProfile
        title={`Editar ${vehicle.placa}(${vehicle?.tipovehiculo?.nombre})`}
        sx={{
          width: "360px",
        }}
        viewIcon={true}
      >
        {successMsg && !isLoading && (
          <StandardAlert
            sx={{ marginBottom: "16px" }}
            severity="success"
            color="success"
            onClick={() => {
              dispatch(setSuccessMsg(""));
            }}
          >
            {successMsg}
          </StandardAlert>
        )}
        <BannerModal
          open={showAlert}
          onClose={() => setShowAlert(false)}
          title="¿Eliminar vehículo?"
          content="Al eliminar un vehículo se desactivarán los avisos activos que se hayan creado con este vehículo y se rechazarán negociaciones activas."
          option1={{
            title: "NO, MANTENERLO",
            onClick: () => setShowAlert(false),
          }}
          option2={{
            title: "ELIMINAR",
            onClick: handleAcceptDelete(vehicle?.id?.toString()),
          }}
          color="org"
          sx={{
            width: "322px",
            height: "316px",
          }}
          sxAccion={{
            padding: "0px",
          }}
        />
        <CustomListLinks>
          {listVehicle.map((value, index) => (
            <ListItem
              key={index}
              disablePadding
              onClick={() => {
                navigate(value.path.replace(":id", vehicle?.id?.toString()));
              }}
              sx={{
                ".css-cveggr-MuiListItemIcon-root": {
                  minWidth: "5px",
                },
              }}
            >
              <ListItemButton
                sx={{
                  margin: "0px 0px 16px 0px",
                  height: "56px",
                }}
              >
                <ListItemText
                  primary={value?.title}
                  secondary={handleGetSecondaryText(value)}
                  secondaryTypographyProps={{
                    color: "error.main",
                  }}
                />
                <ListItemIcon>
                  <i className="icon-small-arrow-right" />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </CustomListLinks>
        <ButtonCard
          variant="outlined"
          color="org"
          onClick={() => setShowAlert(true)}
          sx={{
            width: "100%",
            mt: 2,
            height: "44px",
          }}
        >
          ELIMINAR VEHÍCULO
        </ButtonCard>
      </HeaderProfile>
    </>
  );
};

export default EditVehicle;
