
import { prepareSelectedValueModal } from "@/components/Filter/adapter/filterAdapter";
import LabelRequired from "@/components/utils/LabelRequired";
import { REGISTER_PATH } from "@/consts/noticeV2Paths";
import { REGISTER_DRIVER_STEPS } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { UsuarioAPI } from "@/models/userAuth/user";
import { INoticeCreateListItem } from "@/pages/noticeV2/NoticeList/types";
import { extractSendData, getNoticeType, getNoticeTypeInterface } from "@/pages/noticeV2/adapters/noticeUseTypes";
import { DistritoSugeridoData } from "@/services/noticeServices/noticeListSuggested/noticeListSuggestedTypes";
import { resetListLocation } from "@/store/filterNoticeRedux/filterNoticeSlice";
import { getCitiesByPaisFilterThunk, getDistrictsFilterThunk } from "@/store/filterNoticeRedux/filterNoticeThunk";
import { setDistrictsSuggested, setInputsPlace, setResetErrorDuplicated } from "@/store/noticeV2Redux/noticeV2Slice";
import { fillSendDataThunk, noticeGetDistrictSuggestedThunk, onSaveFlowNoticeV2Thunk } from "@/store/noticeV2Redux/noticeV2Thunk";
import { IInputsPlace, IReturnDataValues } from "@/store/noticeV2Redux/noticeV2types";
import { getNextRouteV2 } from "@/store/routeActionsFlowV2/routeActionsFlowNoticeV2Thunk";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IShowInputsProps } from "../../../components/inputsConfig/types";
import { MODAL_ACTIVE, remanePlaceholders } from "../../types";
import { ERROR_MESSAGES_DUPLICATED_MOVING, MAX_PRICE_IN, MIN_PRICE_MV, placeholdersPlace } from "../../hook";
import { textCannotLowerPrice } from "@/consts/noticeConsts";

const usePlaceMovingInCityHook = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const {
    filterNotice,
    user: { user },
    noticeV2: {
      selectNotice,
      inputsPlace,
      sendData,
      districtsSuggested,
      isLoading,
      variantMoving,
      noticesDuplicate,
    },
  } = useAppSelector((state) => state);
  const { listLocation } = filterNotice;
  const [showModal, setShowModal] = useState(false);

  const [modalActiveWith, setModalActiveWith] = useState(MODAL_ACTIVE);

  const idNoticeType = (selectNotice as INoticeCreateListItem)?.id || 0;
  const { idtipoaviso, idvehiculo, pesomaximo } = extractSendData(
    sendData,
    idNoticeType
  );
  const noticeTypeData = extractSendData(
    sendData,
    idNoticeType,
    variantMoving && Number(variantMoving) === 1 ? true : false
  );
  const isIs1or2 = variantMoving && Number(variantMoving) === 1 ? true : false;

  const { distrito, ciudad, flgRegistroAviso } = user as UsuarioAPI;
  const isRegister = flgRegistroAviso === null;

  const isCity = modalActiveWith.name === 'city';

  useEffect(() => {
    loadFormData();
  }, []);

  const loadFormData = () => {
    if (inputsPlace && !inputsPlace.city && !inputsPlace.origin) {
      // cargar datos por defecto
      const body: IReturnDataValues = {
        ...noticeTypeData,
        idciudad: ciudad?.id || 0,
        idciudaddestino: ciudad?.id || 0,
        idorigen: isRegister ? distrito?.id || 0 : 0,
        idtipoaviso,
        idvehiculo,
        pesomaximo,
      };

      if (isRegister) {
        dispatch(noticeGetDistrictSuggestedThunk(body.idorigen));
      }

      dispatch(
        fillSendDataThunk({
          key: getNoticeType(idNoticeType, isIs1or2),
          value: getNoticeTypeInterface(idNoticeType, body, isIs1or2),
        })
      ).then(() => {
        dispatch(
          setInputsPlace({
            ...inputsPlace,
            city: ciudad?.nombre2 || '',
            origin: isRegister ? distrito?.nombre || '' : '',
          })
        );
      });
    }
  };

  // delete change city
  const handleOnChangeCity = (body: IReturnDataValues, name: string) => {
    dispatch(
      fillSendDataThunk({
        key: getNoticeType(idNoticeType, isIs1or2),
        value: getNoticeTypeInterface(
          idNoticeType,
          {
            ...body,
            idorigen: 0,
            avisodestinos: [],
          },
          isIs1or2
        ),
      })
    ).then(() => {
      dispatch(
        setInputsPlace({
          ...inputsPlace,
          [modalActiveWith.name]: name,
          origin: '',
        })
      );
    });
  };

  const handleOnClick = (props: IShowInputsProps) => () => {
    setModalActiveWith({ name: props.name });
    setShowModal(true);
    dispatch(resetListLocation());
  };

  const onChange = (props: IShowInputsProps) => () => {
    handleOnClick(props)();
  };

  const handleGetValue = ({ name }: { name: keyof IInputsPlace }) => {
    return inputsPlace ? inputsPlace[name] : '';
  };

  const handleGetPlaceholder = (props: IShowInputsProps) => {
    const typeNoticePlaceholder =
      placeholdersPlace[(selectNotice as INoticeCreateListItem)?.id || 0];

    if (
      remanePlaceholders.some((name) => name === props.name) &&
      typeNoticePlaceholder
    ) {
      return typeNoticePlaceholder[props.name] || {};
    }

    return {};
  };

  const handleValidShow = (columnIShowInputsProps: any) => {
    const moreIShowInputsProps = columnIShowInputsProps?.callback
      ? columnIShowInputsProps?.callback(columnIShowInputsProps, filterNotice)
      : {};
    return { ...columnIShowInputsProps, ...moreIShowInputsProps };
  };

  const handleInitConfig = (column: any) => {
    const moreIShowInputsProps = column?.props?.callback
      ? column?.props?.callback(column?.props, {})
      : {};

    const props = {
      ...column.props,
      onClick: handleOnClick(column.props),
      onChange: onChange(column.props),
      value: handleGetValue(column.props),
      ...handleValidShow(column.props),
      ...handleGetPlaceholder(column.props),
      ...moreIShowInputsProps,
    };

    return {
      ...column,
      props,
    };
  };

  const handleOnCloseModal = () => {
    setShowModal(false);
    setModalActiveWith(MODAL_ACTIVE);
  };

  const handleSubmitModal = (seleted: any) => {
    const name = prepareSelectedValueModal(seleted);

    const body: IReturnDataValues = {
      ...noticeTypeData,
    };

    if (isCity) {
      body.idciudad = seleted.id;
      body.idciudaddestino = seleted.id;
    }

    if (modalActiveWith.name === 'origin') {
      body.idorigen = seleted.id;
      dispatch(noticeGetDistrictSuggestedThunk(seleted.id));
      body.avisodestinos = [];
    }

    dispatch(
      fillSendDataThunk({
        key: getNoticeType(idNoticeType, isIs1or2),
        value: getNoticeTypeInterface(idNoticeType, body, isIs1or2),
      })
    ).then(() => {
      dispatch(
        setInputsPlace({ ...inputsPlace, [modalActiveWith.name]: name })
      );
      if (isCity) {
        handleOnChangeCity(body, name);
      }
    });

    handleOnCloseModal();
  };
  const handleOnSeletedModal = (seleted: any) => {
    const exemption = ['origin', 'city'];
    if (exemption.some((exemp) => exemp === modalActiveWith.name)) {
      handleSubmitModal(seleted);
      dispatch(setResetErrorDuplicated());
    }
  };

  const handleFilterByTerm = (value: string) => {
    if (value?.length < 3) return;

    const { name } = modalActiveWith;
    if (name === 'city') {
      dispatch(getCitiesByPaisFilterThunk(value));
    } else if (name === 'origin') {
      dispatch(getDistrictsFilterThunk(value, inputsPlace.city as string));
    }
  };

  const handleInputsSuggested = (id: number, precio: string) => {
    if (precio && !/^\d+$/.test(precio)) return;

    // Clonar el array avisodestinos para evitar mutación directa
    const avisodestinos: any[] = [...(noticeTypeData?.avisodestinos || [])];

    // Buscar el índice del destino en avisodestinos
    const index = avisodestinos.findIndex(
      (item: any) => item.iddistrito === id
    );

    if (index !== -1) {
      if (Number(precio) === 0) {
        avisodestinos.splice(index, 1); // Eliminar el destino si el precio es 0
      } else {
        // Clonar el objeto del destino para evitar mutación directa
        avisodestinos[index] = {
          ...avisodestinos[index],
          precio: Number(precio), // Actualizar el precio
        };
      }
    } else {
      avisodestinos.push({
        iddistrito: id,
        precio: Number(precio),
      });
    }

    const body: IReturnDataValues = {
      ...noticeTypeData,
      avisodestinos,
    };

    dispatch(
      fillSendDataThunk({
        key: getNoticeType(idNoticeType, isIs1or2),
        value: getNoticeTypeInterface(
          idNoticeType,
          {
            ...body,
          },
          isIs1or2
        ),
      })
    );
    dispatch(setResetErrorDuplicated());
  };

  const DeleteSuggestedListItem = (id: number) => {
    if (noticeTypeData?.avisodestinos?.length) {
      const updatedAvisodestinos = noticeTypeData.avisodestinos.filter(
        (item) => item.iddistrito !== id
      );

      dispatch(
        fillSendDataThunk({
          key: getNoticeType(idNoticeType, isIs1or2),
          value: getNoticeTypeInterface(
            idNoticeType,
            {
              ...noticeTypeData,
              avisodestinos: updatedAvisodestinos,
            },
            isIs1or2
          ),
        })
      );
    }

    dispatch(
      setDistrictsSuggested({
        ...districtsSuggested,
        sugeridos: (
          districtsSuggested as DistritoSugeridoData
        ).sugeridos.filter((item) => item.id !== id),
      })
    );
  };

  const handleInputsCustomHourOrPoint = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, name } = event.target;

    // puros numeros
    if (!/^[0-9]*$/.test(value)) return;

    const body: IReturnDataValues = {
      ...noticeTypeData,
    };

    if (name === 'ayudantes') {
      body.ayudantes = Number(value);
    }

    dispatch(
      fillSendDataThunk({
        key: getNoticeType(idNoticeType, isIs1or2),
        value: getNoticeTypeInterface(
          idNoticeType,
          {
            ...body,
          },
          isIs1or2
        ),
      })
    );
    dispatch(setResetErrorDuplicated());
  };

  // En la creación de avisos de mudanza en la ciudad y entre ciudades, el campo cantidad debe ayudantes de aceptar como máximo 99.
  // La cantidad mínima de ayudantes es 1. Si el usuario ingresa valor 0; se debe mostrar error: “Considera al menos 1 ayudante“
  const getValidAyudantes = (value: number) => {
    if (value > 99) {
      return {
        valid: false,
        msg: 'La cantidad máxima de ayudantes es 99.',
      };
    }
    return {
      valid: true,
      msg: 'Considera al menos 1 ayudante.',
    };
  };

  const validateForm = () => {
    const {
      idorigen,
      idciudad,
      idciudaddestino,
      avisodestinos,
      flgciudadoentreciudades,
      ayudantes,
    } = noticeTypeData;
    let validdestinos: boolean[] = [];

    // buscar si hay un precio menor a 5 o mayor a 1000 y retornar false
    if (avisodestinos?.length) {
      avisodestinos?.forEach((item: any) => {
        const { valid } = validatePrecioOfDestinos(item.iddistrito);
        if (valid) {
          validdestinos.push(valid);
        }
      });
    }

    return !!(
      idorigen &&
      idciudad &&
      idciudaddestino &&
      avisodestinos?.length &&
      flgciudadoentreciudades &&
      ayudantes &&
      !validdestinos.length &&
      getValidAyudantes(ayudantes).valid
    );
  };

  const validatePrecioOfDestinos = (id: number) => {
    const avisodestinos = noticeTypeData.avisodestinos || [];
    const index = avisodestinos.findIndex(
      (item: any) => item.iddistrito === id
    );
    if (index !== -1) {
      const { precio } = avisodestinos[index];
      const duplicate = noticesDuplicate && noticesDuplicate.find((item) => item === id);
      if (duplicate) {
        return {
          valid: true,
          message: ERROR_MESSAGES_DUPLICATED_MOVING,
        };
      }
      return {
        valid: !(precio >= MIN_PRICE_MV && precio <= MAX_PRICE_IN),
        message: textCannotLowerPrice,
      };
    }
    return {
      valid: false,
      message: '',
    };
  };

  const handleNavigate = () => {
    if (pathname.includes(REGISTER_PATH)) {
      navigate(REGISTER_DRIVER_STEPS);
      return;
    } else {
      dispatch(getNextRouteV2(navigate));
    }
  };
  const onSubmit = () => {
    if (!validateForm()) {
      return;
    }

    dispatch(
      onSaveFlowNoticeV2Thunk({
        ...noticeTypeData,
      })
    ).then(() => {
      handleNavigate();
    });
  };

  return {
    handleInitConfig,
    // demas cosas
    showModal,
    handleOnCloseModal,
    handleSubmitModal,
    handleOnSeletedModal,
    handleFilterByTerm,
    modalActiveWith,
    listLocation,
    districtsSuggested,
    handleInputsSuggested,
    data: noticeTypeData,
    DeleteSuggestedListItem,
    validateForm,
    onSubmit,
    isLoading,
    handleInputsCustomHourOrPoint,
    getValidAyudantes,
    validatePrecioOfDestinos,
    isRegister,
    formData: inputsPlace,
    isCity,
  };
};

export default usePlaceMovingInCityHook;

