import Icon, { IconTypes } from "@/utils/Icon";
import { Stack, styled, Typography } from "@mui/material";
import { FC } from "react";
const Title = styled(Typography)(
    ({ theme }) => `
        color: ${theme.palette.text.secondary};
        font-weight: bold;
        margin-left: 8px;
        
      `
  );
  
  const ConatinerDetails = styled(Stack)(
    () => `
      justify-content: space-between;
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      margin-top: 10px;
    `
  );
  
  export type DetailsTextProps = {
    show: boolean;
    title: string;
    origen: string;
    originDescription: string;
    icon: IconTypes;
  };
const DetailsTextMoving: FC<DetailsTextProps> = ({
    show,
    title,
    origen,
    icon,
    originDescription,
  }) => {
    if (!show) return <></>;
  return (
    <ConatinerDetails 
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginTop: "10px",
      }}
    >
    <Stack flexDirection="row">
      <Icon name={icon} size="md"/>
      <Title variant="label1">
        {title}
        {": "}
      </Title>
    </Stack>
    <Stack marginLeft={
      // 10% en desktop y 15% en mobile
      { xs: "15%", md: "10%" } 
    }>
      <Typography variant="body1">{origen}</Typography>
      {originDescription && (
        <Typography variant="body1" style={{
          overflowWrap: "break-word"
        }} dangerouslySetInnerHTML={{ __html: originDescription }} />
      )}
    </Stack>
  </ConatinerDetails>
  )
}

export default DetailsTextMoving
