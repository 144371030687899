import { prepareBodyAdvertisementListThunk } from '@/adapters/store/advertisementAdapter';
import { noticeListBodyAdapter } from '@/adapters/store/myNoticeAdapter';
import { editNoticeDateAdapter } from '@/adapters/store/noticeStoreAdapter';
import { ListItems } from '@/components/noticeListItem/typeNoticeListITem';
import {
  NEGOTATION_BETWEEN_CITIES,
  NEGOTATION_IN_CITY,
  NEGOTATION_MOVING,
  NEGOTATION_MULTI_DESTINY_IN_CITY,
  NEGOTIATION_AUCTION_BTN_CITIES,
} from '@/consts/negotationFlowPath';
import { GetListNoticeFilterThunkProps } from '@/interfaces/noticeInterface';
import { MyNoticesAPI, NoticeConditions } from '@/models/notice/noticeModels';
import { filterServicesSend } from '@/pages/landing/services/utils/handleCheck';
import { editNoticeConditionsListAdapter } from '@/services/adapters/noticeAdapter';
import {
  INoticeRenewServices,
  INoticeStatusServices,
  NoticeEditPriceServices,
} from '@/services/interfaces/noticeInterfaces';
import {
  changeStatusNoticeServices,
  editNoticeConditionsServices,
  editNoticeDateServices,
  editNoticePriceServices,
  filterNoticeServices,
  getNoticesActivesAndHistoryServices,
  getOffertActiveByIdServices,
  getTypeNoticeServices,
  renewNoticeServices,
} from '@/services/noticeServices';
import { prepareListNoticeData } from '@/utils/helpersActions';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';
import { ISelectCity } from '../FilterServices/filterReduxInterface';
import { getAdvertisementNoticeListThunk } from '../advertisementRedux/advertisementThunk';
import { getMessaggeError } from '../auth/utils';
import {
  setDataAuction,
  setDataBetweenCities,
  setDataInCity,
  setDataMoving,
  setDataMultiDestiny,
  setLoadingDetailsNotice,
} from '../detailsNotice/detailsNoticeSlice';
import { IListOfServices } from '../includedServices/includedServicesSlice';
import {
  saveDataFlow,
  setLoading as setLoadingSaveDataFlow,
} from '../saveFlow/saveFlowSlice';
import { AppDispatch, RootState } from '../store';
import {
  setErrorActive,
  setErrorHistory,
  setListActive,
  setListHistory,
  setLoadingActive,
  setLoadingHistory,
  updateListActive,
  updateListHistory,
} from './myNoticeSlice';
import {
  setAvisos,
  setCurrentPage,
  setError,
  setIterableAvisos,
  setList,
  setLoading,
  setLoadingIndex,
  setSelectNotice,
  setTotalItems,
  setTotalPages,
} from './noticeSlice';

export const saveDataToStore: Record<
  string,
  ActionCreatorWithPayload<any, string>
> = {
  [NEGOTATION_IN_CITY]: setDataInCity,
  [NEGOTATION_MULTI_DESTINY_IN_CITY]: setDataMultiDestiny,
  [NEGOTATION_BETWEEN_CITIES]: setDataBetweenCities,
  [`${NEGOTATION_MOVING}s`]: setDataMoving,
  [NEGOTIATION_AUCTION_BTN_CITIES]: setDataAuction,
};


export const getSaveDataToStore = (type: string): any => {
  if(type.includes(NEGOTATION_IN_CITY)) return setDataInCity;
  if(type.includes(NEGOTATION_MULTI_DESTINY_IN_CITY)) return setDataMultiDestiny;
  if(type.includes(NEGOTATION_BETWEEN_CITIES)) return setDataBetweenCities;
  if(type.includes(NEGOTATION_MOVING)) return setDataMoving;
  if(type.includes(NEGOTIATION_AUCTION_BTN_CITIES)) return setDataAuction;
};

export const convertTypeNotice = (str: string) => {
  return str.replaceAll(' ', '-').toLowerCase();
};

export const getListNoticeThunk = (
  listData: ListItems[],
  autoSelected = true
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const apiLisData: any = await getTypeNoticeServices();

      const listServices = prepareListNoticeData(listData, apiLisData);

      dispatch(setList(listServices));
      autoSelected && dispatch(setSelectNotice(listServices[0]));
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

export const getListNoticeFilterThunk = (
  data: GetListNoticeFilterThunkProps
) => {
  const { page, size, ordenamiento, tiposFiltro, pathname } = data;

  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoadingIndex(true));

    try {
      const { filter } = getState();
      const selectedCity = filter.selectedCity as ISelectCity;

      const body = filterServicesSend({
        page,
        size,
        ordenamiento,
        tiposFiltro,
        city: selectedCity,
      });

      const apiLisData: any = await filterNoticeServices(body);

      // ================ Ejecuta la peticion de publicidad, para luego insertar en el listado ====
      if (pathname) {
        const advertisementBody = prepareBodyAdvertisementListThunk(
          pathname,
          size,
          2
        );
        dispatch(getAdvertisementNoticeListThunk(advertisementBody));
      }

      dispatch(setCurrentPage(apiLisData.avisoData.currentPage));
      dispatch(setTotalItems(apiLisData.avisoData.totalItems));
      dispatch(setTotalPages(apiLisData.avisoData.totalPages));
      dispatch(setAvisos(apiLisData.avisoData.aviso || []));
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

// scroll infinito to filter data
export const getListNoticeFilterThunkPage = (
  data: GetListNoticeFilterThunkProps
) => {
  const { page, size, ordenamiento, tiposFiltro, pathname } = data;
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      const { filter } = getState();
      const selectedCity = filter.selectedCity as ISelectCity;

      const body = filterServicesSend({
        page,
        size,
        ordenamiento,
        tiposFiltro,
        city: selectedCity,
      });
      const apiLisData: any = await filterNoticeServices(body);

      // ================ Ejecuta la peticion de publicidad, para luego insertar en el listado ====
      if (pathname) {
        const typeList = 2;
        const advertisementBody = prepareBodyAdvertisementListThunk(
          pathname,
          size,
          typeList //  el tipo 2, indica que la publicidad ira en los listados
        );
        dispatch(getAdvertisementNoticeListThunk(advertisementBody));
      }

      dispatch(setCurrentPage(apiLisData.avisoData.currentPage));
      dispatch(setTotalItems(apiLisData.avisoData.totalItems));
      dispatch(setTotalPages(apiLisData.avisoData.totalPages));
      dispatch(setIterableAvisos(apiLisData?.avisoData?.aviso || []));
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

// ==================================================
//          Listado de avisos del transportista
//                Activos e history
// ==================================================
type ListHistoryThunk = {
  page?: number;
  size?: number;
  isPaginate?: boolean;
};
export const getListHistoryThunk = ({
  page,
  size,
  isPaginate,
}: ListHistoryThunk) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoadingHistory(true));
    try {
      const {
        myNotice: { historyNotices },
      } = getState();
      const currentPage = page || historyNotices.currentPage;
      const body = noticeListBodyAdapter({
        page: currentPage,
        size,
        opcionesnumero: 0,
        isPaginate,
      });
      const { avisoData } =
        await getNoticesActivesAndHistoryServices<MyNoticesAPI>(body);
      if (!avisoData?.avisos) return;

      if (isPaginate) {
        dispatch(updateListHistory(avisoData));
      } else {
        dispatch(setListHistory(avisoData));
      }
    } catch (error) {
      dispatch(setErrorHistory(error));
    } finally {
      dispatch(setLoadingHistory(false));
    }
  };
};

export const getListActivesThunk = ({
  page,
  size,
  isPaginate,
}: ListHistoryThunk) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoadingActive(true));

    try {
      const {
        myNotice: { activeNotices },
      } = getState();
      const currentPage = page || activeNotices.currentPage;

      const body = noticeListBodyAdapter({
        page: currentPage,
        size,
        opcionesnumero: 1,
        isPaginate,
      });
      const { avisoData } =
        await getNoticesActivesAndHistoryServices<MyNoticesAPI>(body);
      if (!avisoData?.avisos) return;

      if (isPaginate) {
        dispatch(updateListActive(avisoData));
      } else {
        dispatch(setListActive(avisoData));
      }
    } catch (error) {
      dispatch(setErrorActive(error));
    } finally {
      dispatch(setLoadingActive(false));
    }
  };
};

export type EditNoticeDateThunk = {
  timePicker: Dayjs | null;
  datePicker: Dayjs;
};
export const editNoticeDateThunk = (
  idNotice: number,
  body: EditNoticeDateThunk
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    dispatch(setLoadingSaveDataFlow(true));
    try {
      const bodyAdapter = editNoticeDateAdapter(body);
      const { avisoData } = await editNoticeDateServices(
        idNotice.toString(),
        bodyAdapter
      );

      dispatch(saveDataFlow(avisoData.avisos));
      dispatch(setLoading(false)); // TODO: Este desactiva el loading del slice que lo activo, no quitar!!
      return avisoData;
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const editNoticeConditionsThunk = (
  idNotice: number,
  data: IListOfServices[] | NoticeConditions[]
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    dispatch(setLoadingSaveDataFlow(true));
    try {
      const body = editNoticeConditionsListAdapter(data);
      const { avisoData } = await editNoticeConditionsServices(
        idNotice.toString(),
        body
      );

      const typeOfFlow = convertTypeNotice(
        avisoData.avisos[0].tipoaviso.nombre
      );

      const setDataToStore = getSaveDataToStore(typeOfFlow);

      dispatch(setDataToStore(avisoData.avisos[0]));

      dispatch(setLoading(false)); // TODO: Este desactiva el loading del slice que lo activo, no quitar!!
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const editNoticePriceThunk = (
  idNotice: number,
  body: NoticeEditPriceServices
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoadingDetailsNotice(true));
    try {
      const { avisoData } = await editNoticePriceServices(
        idNotice.toString(),
        body
      );

      const typeOfFlow = convertTypeNotice(
        avisoData.avisos[0].tipoaviso.nombre
      );
      
      const setDataToStore = getSaveDataToStore(typeOfFlow);
      
      dispatch(setDataToStore(avisoData.avisos[0]));

      dispatch(setLoadingDetailsNotice(false));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const renewNoticeThunk = (
  idNotice: number,
  body?: INoticeRenewServices
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    dispatch(setLoadingSaveDataFlow(true));
    try {
      const defaultBody = body || {};

      const { avisoData } = await renewNoticeServices(
        idNotice.toString(),
        defaultBody
      );
      dispatch(saveDataFlow(avisoData.avisos));
      dispatch(setLoading(false)); // TODO: Este desactiva el loading del slice que lo activo, no quitar!!
      return avisoData.avisos;
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const changeStatusNoticeThunk = (
  idNotice: number,
  body: INoticeStatusServices
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    dispatch(setLoadingSaveDataFlow(true));
    try {
      const { avisoData } = await changeStatusNoticeServices(
        idNotice.toString(),
        body
      );
      dispatch(saveDataFlow(avisoData.avisos));
      dispatch(setLoading(false)); // TODO: Este desactiva el loading del slice que lo activo, no quitar!!
      return avisoData.avisos;
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

// offert avtives
export const getOffertActiveByIdThunk = (idNotice: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { avisoData } = await getOffertActiveByIdServices(
        idNotice.toString()
      );
      return avisoData?.ofertasActivas;
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};
