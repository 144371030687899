import { textColorDescount } from "@/pages/acceptanceOffert/components/SectionDescount";
import { Grid, Typography, styled } from "@mui/material";

export const DsctoContainer = styled("div")(() => ({
    width: "auto"
}));

export const DsctoTitle = styled(Grid)(() => ({
    gap: "4px"
}));

export const DsctoText = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.label2.fontSize,
    lineHeight: theme.typography.label2.lineHeight,
}));

export const Before = styled("span")(() => ({
    textDecoration: "line-through",
}));

export const Now = styled("span")(({ theme }) => ({
    fontWeight: theme.typography.label2.fontWeight,
    fontFamily: theme.typography.label2.fontFamily,
    color: textColorDescount
}));