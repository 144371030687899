// ==================================================
//      THUNKS DE LA EDICIÓN DE LA SOLICITUD
// ==================================================

import { requestLoadAdapter } from '@/adapters/store/editNegotationRequestAdapter';
import { getPhotosOffertAdapter } from '@/adapters/store/offertStoreAdapter';
import {
  RequestCondiciones,
  RequestDate,
  Requestdestiny,
  RequestEnvironment,
  Requestorigin,
  RequestPrice,
  RequestRute,
} from '@/models/noticeNegotation/noticeNegotationModel';
import {
  putConditionsRequestEdit,
  putDateRequestEdit,
  putDestinyRequestEdit,
  putEnvironmentRequestEdit,
  putLoadRequestEdit,
  putOriginRequestEdit,
  putPriceRequestEdit,
  putRenewDateRequest,
  putRuteRequestEdit,
} from '@/services/noticeNegotationRequestServices';
import { getMessaggeError } from '../auth/utils';
import {
  saveData,
  setActiveSnackbar,
  setError,
  setFotos,
  setLoading,
  setRequestDetailsView,
} from '../customerRequestRedux/customerRequestSlice';
import { AppDispatch, RootState } from '../store';

export const uploadRuteRequestEdit = (
  idRequestType: string,
  body: RequestRute
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
      const {
        solicitudData: { solicitud },
      } = await putRuteRequestEdit(idRequestType, body);

      dispatch(setRequestDetailsView(solicitud));
      dispatch(setLoading(false));
      dispatch(setActiveSnackbar(true));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export type UploadLoadRequestEditProps = {
  descriptionLoadInput: string;
  weight?: number;
  idTipoCarga?: number;
  idRangoPeso?: number;
  idTypeVehicle: number;
  isRefrigerate: boolean;
  images: File[];
};

export const uploadLoadRequestEdit = (
  idRequestType: string,
  data: UploadLoadRequestEditProps
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      const {
        customerRequest: { photosPreLoaded },
      } = getState();

      const photos = getPhotosOffertAdapter([
        ...photosPreLoaded,
        ...data.images,
      ]);

      const body = requestLoadAdapter({ ...data, images: photos });

      const {
        solicitudData: { solicitud },
      } = await putLoadRequestEdit(idRequestType, body);

      dispatch(setRequestDetailsView(solicitud));
      dispatch(setLoading(false));
      dispatch(setActiveSnackbar(true));
      dispatch(setFotos([]));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const uploadConditionsRequestEdit = (
  idRequestType: string,
  body: RequestCondiciones
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
      const {
        solicitudData: { solicitud },
      } = await putConditionsRequestEdit(idRequestType, body);

      dispatch(setRequestDetailsView(solicitud));
      dispatch(setLoading(false));
      dispatch(setActiveSnackbar(true));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const uploadPriceRequestEdit = (
  idRequestType: string,
  body: RequestPrice
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
      const {
        solicitudData: { solicitud },
      } = await putPriceRequestEdit(idRequestType, body);

      dispatch(setRequestDetailsView(solicitud));
      dispatch(setLoading(false));
      dispatch(setActiveSnackbar(true));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

// ==================================================================================================
//       THUNKS DE LA EDICIÓN DE LA SOLICITUD POR PARTE DEL CLIENTE PERO DE LA MUDANZA
// ==================================================================================================

export const uploadDateInMovingRequestEdit = (
  idRequestType: string,
  body: RequestDate
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
      const {
        solicitudData: { solicitud },
      } = await putDateRequestEdit(idRequestType, body);

      dispatch(saveData(solicitud));
      dispatch(setLoading(false));
      dispatch(setActiveSnackbar(true));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const uploadOriginInMovingRequestEdit = (
  idRequestType: string,
  body: Requestorigin
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
      const {
        solicitudData: { solicitud },
      } = await putOriginRequestEdit(idRequestType, body);

      dispatch(setRequestDetailsView(solicitud));
      dispatch(setLoading(false));
      dispatch(setActiveSnackbar(true));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const uploadDestinyInMovingRequestEdit = (
  idRequestType: string,
  body: Requestdestiny
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
      const {
        solicitudData: { solicitud },
      } = await putDestinyRequestEdit(idRequestType, body);

      dispatch(setRequestDetailsView(solicitud));
      dispatch(setLoading(false));
      dispatch(setActiveSnackbar(true));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const uploadEnvironmentInMovingRequestEdit = (
  idRequestType: string,
  body: RequestEnvironment
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
      const {
        solicitudData: { solicitud },
      } = await putEnvironmentRequestEdit(idRequestType, body);

      dispatch(setRequestDetailsView(solicitud));
      dispatch(setLoading(false));
      dispatch(setActiveSnackbar(true));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

// ==================================================================================================
//       THUNK PARA RENOVAR SOLICITUD
// ==================================================================================================

export const uploadRenewRequest = (
  idRequestType: string,
  body: { fecha: string }
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
      const {
        solicitudData: { solicitud },
      } = await putRenewDateRequest(idRequestType, body);

      dispatch(setRequestDetailsView(solicitud));
      dispatch(setLoading(false));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};
