import { ISolicitudNegociacion } from '@/services/interfaces/RequestNegResponseSuccess';
import AcceptOrCounterOffer from './AcceptOrCounterOffer';
import { ID_MOVING_FLOW, ID_MULTI_DESTINY } from '../../../../consts/defaultConsts';
import MultiDestinyPrice from './MultiDestinyPrice';
import MovingPrice from './MovingPrice';
interface IBodyPriceByTypeProps {
    data: ISolicitudNegociacion;
}
const BodyPriceByType = ({ data }: IBodyPriceByTypeProps) => {
    const {
        precio,
        solicitud: {
            tipoSolicitud: {
                id: idSolicitud,
            },
        },
        tipoSolicitudNegociacion: {
            id: idStatus
        },
        precioCalculado,
        ayudantes,
    } = data;

    if (!precio) return <></>;

    if (idSolicitud === ID_MOVING_FLOW) {
        return <MovingPrice
            price={precio}
            ayudantes={ayudantes}
            type={idStatus}
        />;
    }

    if (idSolicitud === ID_MULTI_DESTINY) {
        return <MultiDestinyPrice
            price={precio}
            solicitud={data.solicitud}
            priceCalculated={precioCalculado}
            type={idStatus}
        />;
    }


    return <AcceptOrCounterOffer price={precio} type={idStatus} />
}

export default BodyPriceByType
