import SkeletonViewService from '@/pages/loading/SkeletonViewService';
import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Layout from './components/Layout';
import useRequestNegFinishedRejectHook from './hook';

const RequestFinishedReject = () => {
  const {
    savedDataNogotation,
    loading,
  } = useRequestNegFinishedRejectHook();
  if (loading || !savedDataNogotation) return (<SkeletonViewService />);
  return (
    <Layout request={savedDataNogotation}>
      <Stack
        sx={{
          gap: "3px",
          wordWrap: "break-word",
        }}
      >
        <Outlet />
      </Stack>
    </Layout>
  )
}

export default RequestFinishedReject
