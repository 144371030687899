export type CurrentRuleVehicleType = Record<
  number,
  Record<string, Record<string, string>>
>;

export const currentRuleVehicle: CurrentRuleVehicleType = {
  1: {
    header: {
      title: 'Vehículo',
      subTitle: 'Crear avisos en la ciudad',
    },
  },
  2: {
    header: {
      title: 'Vehículo',
      subTitle: 'Crear aviso multidestino en la ciudad',
    },
  },
  3: {
    header: {
      title: 'Vehículo',
      subTitle: 'Crear avisos entre ciudades',
    },
  },
  4: {
    header: {
      title: 'Vehículo',
      subTitle: 'Crear aviso de remate entre ciudades',
    },
  },
  5: {
    header: {
      title: 'Vehículo',
      subTitle: 'Crear avisos de mudanza',
    },
  },
};

export const StandardAlertText =
  'Para crear un aviso debes tener por lo menos un vehículo en tu cuenta.';

export const amountWeigth =
  '¿Cuál es la capacidad disponible a rematar? (kilos)';
