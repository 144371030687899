import {
  LOCATION_CITY_BY_COUNTRY,
  LOCATION_CITY_BY_TERM,
  LOCATION_DISTRICTS_BY_CITY,
  LOCATION_ZONAS_BY_CITY,
  LOCATION_ONLY_DISTRICTS_BY_CITY,
  LOCATION_DISTRICTS_BY_TERM,
  LOCATION_DISTRICTS_BY_CITY_BY_TERM,
  LOCATION_CITY_BY_PAIS_BY_TERM,
} from '@/consts/pathServices';
import {
  CitiesByTermAPI,
  DistrictByTermServicesAPI,
} from '@/models/location/cityModels';
import { ResponseDistrictListAPI } from '@/models/location/districtModel';
import { connection } from '@/services/connection';

// ==================================================
//     @OJO : Definicion del API de conexion aqui!!
// ==================================================
const API = connection();

/**
 * Es una función que devuelve una promesa que se resuelve en un objeto que contiene una propiedad de
 * datos que es una matriz de objetos.
 * @param {string} idCity - cuerda
 * @returns Una serie de distritos
 */
export const getDistrictsByCityServices = async (idCity: string) => {
  const path = LOCATION_DISTRICTS_BY_CITY.replace(':id', idCity);
  const { data } = await API.get(path);

  return data;
};

/**
 * Obtiene la ciudad por país
 * @param {string} idCountry - cuerda
 * @returns El resultado de la llamada a la API.
 */
export const getCitiesByCountryServices = async (idCountry: string) => {
  const path = LOCATION_CITY_BY_COUNTRY.replace(':id', idCountry);
  const { data } = await API.get(path);

  return data;
};

/**
 * Obtiene las zonas por ciudad
 * @param {string} idCity - cuerda
 * @returns El resultado de la llamada a la API.
 */
export const getZonesByIdCityServices = async (idCity: string) => {
  const path = LOCATION_ZONAS_BY_CITY.replace(':id', idCity);
  const { data } = await API.get(path);
  return data;
};

// Este servicio regresa las ciudades con los paises a las que pertenecen
export const getCitiesByTermServices = async (
  term: string
): Promise<DistrictByTermServicesAPI> => {
  const path = LOCATION_CITY_BY_TERM.replace(':term', term);
  const { data } = await API.get(path);

  return data;
};

// Este servicio regresa las ciudades con los paises a las que pertenecen por el id del pais y el termino de busqueda
export const getCitiesByPaisAndTermServices = async (
  idCountry: string,
  term: string
): Promise<CitiesByTermAPI> => {
  const path = LOCATION_CITY_BY_PAIS_BY_TERM.replace(':id', idCountry).replace(
    ':term',
    term
  );
  const { data } = await API.get(path);

  return data;
};

/**
 * Es una función que devuelve una promesa que se resuelve en un objeto que contiene una propiedad de
 * datos que es una matriz de objetos.
 * @param {string} idCity - cuerda
 * @returns Una serie de distritos sin zonas
 */
export const getOnlyDistrictsByCityServices = async (
  idCity: string
): Promise<ResponseDistrictListAPI> => {
  const path = LOCATION_ONLY_DISTRICTS_BY_CITY.replace(':id', idCity);
  const { data } = await API.get(path);

  return data;
};

/**
 * Obtiene una lista de distritos por un término
 * @param {string} term - El término a buscar.
 * @returns Una matriz de objetos.
 */
export const getDistrictByTermServices = async (
  term: string
): Promise<ResponseDistrictListAPI> => {
  const path = LOCATION_DISTRICTS_BY_TERM.replace(':term', term);
  const { data } = await API.get(path);

  return data;
};

/**
 * Obtiene una lista de distritos por un término y ciudad
 * @param {string} idCity - El id de la ciudad.
 * @param {string} term - El término a buscar.
 * @returns Una matriz de objetos.
 * @example getDistrictsByCityAndTermServices("1", "San")
 */
export const getDistrictsByCityAndTermServices = async (
  idCity: string,
  term: string
): Promise<ResponseDistrictListAPI> => {
  const path = LOCATION_DISTRICTS_BY_CITY_BY_TERM.replace(
    ':id',
    idCity
  ).replace(':term', term);
  const { data } = await API.get(path);

  return data;
};
