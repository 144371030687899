import { IMG_PATH } from "@/consts/defaultConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { ISolicitudViewDetailsResponse } from "@/models/customerRequest/requestDetailsVewResponse";
import { setPhotosPreLoaded } from "@/store/customerRequestRedux/customerRequestSlice";
import { PhotosPreLoaded } from "@/store/offertRedux/offert.interface";
import { useEffect, useState } from "react";
import { objectRenderComponent } from "./config";

const FormEditLoad = () => {
  const dispatch = useAppDispatch();
  const { savedData, requestDetailsView } = useAppSelector(
    (state) => state.customerRequest
  );
  const photos = !!requestDetailsView?.fotos?.length
    ? requestDetailsView?.fotos.map((item, index) => ({
        name: `foto${index + 1}`,
        url: IMG_PATH + item,
      }))
    : ([] as PhotosPreLoaded[]);
  const [adData, setAdData] = useState<ISolicitudViewDetailsResponse | null>(
    null
  );

  useEffect(() => {
    
    if (requestDetailsView) {
      setAdData(requestDetailsView);
      let finalPhotos = [] as PhotosPreLoaded[];
      if (!!requestDetailsView?.fotos?.length) {
        finalPhotos = photos;
      }

      dispatch(setPhotosPreLoaded(finalPhotos));
    }
  }, [savedData]);

  const Component = objectRenderComponent[adData?.tipoSolicitud.id as number];
  if (!Component) return <></>;

  return <Component />;
};

export default FormEditLoad;
