export type ModalAtive = {
    modalActiveWith:
    | 'city'
    | 'origin'
    | 'destiny'
};
export const MODAL_ACTIVE: Record<string, ModalAtive['modalActiveWith']> = {
    name: 'city',
};

export const remanePlaceholders = [
    'city',
    'origin',
    'destiny',
];