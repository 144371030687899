import {
  NEGOTIATION_GET_DATA,
  NEGOTIATION_SAVE_DATA_ACCEPT,
} from '@/consts/pathServices';
import { connection } from './connection';

const API = connection();

export const getNegotationByIdServices = async <T>(
  idOffer: string
): Promise<T> => {
  const path = NEGOTIATION_GET_DATA.replace(':idOffert', idOffer);
  const { data } = await API.get(path);

  return data;
};

export interface SavedDataByIdType {
  idAccion: number;
  precioactual: number;
}

export const saveOfferByIdServices = async (
  idOffer: string,
  body: SavedDataByIdType
): Promise<any> => {
  const path = NEGOTIATION_SAVE_DATA_ACCEPT.replace(':idOffer', idOffer);
  const { data } = await API.post(path, body);

  return data;
};
