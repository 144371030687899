import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { FormControl, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  EMPTY_MULTIDESTINY,
  HOUR_MULTIDESTINY,
  POINT_MULTIDESTINY,
} from "./const";

import Input from "@/components/inputCustom/Input";
import {
  districtsDeliveryText,
  optionFive,
  optionOne,
  optionTwo,
} from "@/consts/noticeConsts";
import { ISolicitudViewDetailsResponse } from "@/models/customerRequest/requestDetailsVewResponse";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { setActiveButton } from "@/store/customerRequestRedux/customerRequestSlice";
import { uploadPriceRequestEdit } from "@/store/noticeNegotationRequestRedux/editNegRequestThunk";
import { currencyFormat } from "@/utils/helpers";
import { TextZoneKey, TextZoneValue } from "../formPriceStyles";
import { handleReplaceCharacters } from "@/utils/inputsUtils";

const FormPriceEditMultidestiny = () => {
  const { typeId = "" } = useParams();
  const [secondInputValue, setSecondInputValue] = useState("");
  const [firstInputValue, setFirstInputValue] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [inputsMultidestiny, setInputsMultidestiny] =
    useState(EMPTY_MULTIDESTINY);
  const [type, setType] = useState("");

  const {
    customerRequest: { isLoading, requestDetailsView },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const data = requestDetailsView as ISolicitudViewDetailsResponse;
  const typeMultidestiny = data.flgHoraoPunto === optionTwo;
  const paramValue = typeMultidestiny ? "puntosRequeridos" : "horasRequeridas";
  const navigate = useNavigate();

  const { flgHoraoPunto, puntosRequeridos, precio, horasRequeridas } = data;

  useEffect(() => {
    if (data) {
      let obj = HOUR_MULTIDESTINY;
      let flaghoraopuntoValue = optionOne;
      let pointOrHour = horasRequeridas;

      if (flgHoraoPunto === optionTwo) {
        obj = POINT_MULTIDESTINY;
        flaghoraopuntoValue = optionTwo;
        pointOrHour = puntosRequeridos;
      }

      setType(pointOrHour?.toString() as string);
      setFirstInputValue(pointOrHour?.toString() as string);
      setSecondInputValue(precio.toString());
      setInputsMultidestiny(obj);
      setTotalPrice(Number(pointOrHour) * Number(precio));
    }
  }, []);

  useEffect(() => {
    setTotalPrice(Number(secondInputValue) * Number(type));
  }, [firstInputValue, secondInputValue]);

  const handleOnchangeFirstInput = ({ currentTarget }: EventType["change"]) => {
    const { value } = currentTarget;
    const prepareValue = handleReplaceCharacters(value);

    if (prepareValue.length < optionFive) {
      setFirstInputValue(prepareValue);
    }
  };

  const handleOnchangeSecondInput = ({
    currentTarget,
  }: EventType["change"]) => {
    const { value } = currentTarget;
    const prepareValue = handleReplaceCharacters(value);

    if (prepareValue.length < optionFive) {
      setSecondInputValue(prepareValue);
    }
  };

  const handleSubmit = (event: EventType["submit"]) => {
    event.preventDefault();

    const requiresValue = Number(secondInputValue);
    const price = Number(firstInputValue);

    const body = { precio: requiresValue, [paramValue]: price };

    dispatch(uploadPriceRequestEdit(typeId, body)).then(() => navigate(-1));
  };

  const handleCompareInputs = () => {
    const currentInputsValue = [
      Number(firstInputValue),
      Number(secondInputValue),
    ];

    const dataToInputsValue = [data[paramValue], precio];

    const result =
      JSON.stringify(currentInputsValue) === JSON.stringify(dataToInputsValue);
    return !result;
  };

  const handleDisabledButton = () => {
    const conditions = [firstInputValue, secondInputValue];
    let result = true;

    const conditionsResult = conditions.some((condition) => !condition);
    const sameData = handleCompareInputs();
    dispatch(setActiveButton(sameData));

    if (sameData) {
      result = false;
      if (conditionsResult) {
        result = true;
      }
    }

    return result;
  };

  const handleTypeMultidestiny = (capitalize?: boolean) => {
    let value = typeMultidestiny ? "puntos" : "horas";

    if (capitalize) {
      const newValue = value;
      return newValue.charAt(0).toUpperCase() + newValue.slice(1);
    }

    return value;
  };

  return (
    <>
      <Typography variant="body2" color="black">
        <strong>{districtsDeliveryText}:</strong>
        {` ${data?.destinoTexto}`}
      </Typography>

      <Typography variant="body2" color="black" sx={{ mt: 1, fontWeight: 600 }}>
        Modalidad de precio: {` ${handleTypeMultidestiny(true)}`}
      </Typography>

      <Typography variant="body2" color="black" sx={{ mt: 2, fontWeight: 600 }}>
        Precio que ofreces:
      </Typography>

      <FormControl sx={{ marginTop: "16px", width: "100%" }}>
        <Input
          name="price"
          label={
            <LabelInputRequired
              str={`Ofrece tu precio por ${handleTypeMultidestiny()}`}
            />
          }
          value={secondInputValue}
          variant="standard"
          placeholder={inputsMultidestiny.firstInput.placeholder}
          onChange={handleOnchangeSecondInput}
          onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            inputMode: "numeric",
          }}
        />
      </FormControl>
      <FormControl sx={{ marginTop: "16px", width: "100%" }}>
        <Input
          name="number-point-hour"
          label={
            <LabelInputRequired
              str={`${handleTypeMultidestiny(true)} requeridas`}
            />
          }
          value={firstInputValue}
          variant="standard"
          placeholder={inputsMultidestiny.secondInput.placeholder}
          onChange={handleOnchangeFirstInput}
          onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            inputMode: "numeric",
          }}
        />
      </FormControl>
      <TextZoneKey variant="body2" sx={{ marginTop: 3 }}>
        {`Precio ofrecido:`}
        <TextZoneValue variant="caption">
          {!!totalPrice ? currencyFormat(totalPrice) : "-"}
        </TextZoneValue>
      </TextZoneKey>
      <ButtomLoader
        onClick={handleSubmit}
        sx={{ marginTop: "24px" }}
        isLoading={isLoading}
        buttomProps={{
          disabled: handleDisabledButton() || isLoading,
        }}
      >
        Solicitar
      </ButtomLoader>
    </>
  );
};
export default FormPriceEditMultidestiny;
