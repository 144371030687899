import { DestinisAPI, DetailNoticeMoving } from '@/models/notice/noticeModels';
import { SendData } from '../interfaces/sendDataInterfacesEnglish';
import { RootState } from '@/store/store';
import {
  BodyOfferMovingDTO,
  NoticeOfferMovingInCitySlice,
} from '@/interfaces/offerInterface';
import { NoticeConditions } from '@/models/offert/offertConditionsModels';
import { ID_COUNTEROFFER } from '@/consts/defaultConsts';

export const getCurrentStoreMoving = (
  sendData: SendData,
  flgciudadoentreciudades: number
): NoticeOfferMovingInCitySlice => {
  const keyStore =
    flgciudadoentreciudades === 1 ? 'movingInTheCity' : 'movingBetweenCities';
  const currentData = sendData[keyStore];

  return currentData as NoticeOfferMovingInCitySlice;
};

export const requestMovingAdapter = (
  getState: () => RootState
): BodyOfferMovingDTO => {
  const {
    noticeV2ClientAorCO: {
      sendData,
      selectTypeNotice,
      selectDate,
      idTypeNoticeNegotiation,
      selectedDestiny,
      selectedOrigin,
      listOfOfferConditions,
    },
  } = getState();
  const notice = selectTypeNotice as DetailNoticeMoving;
  const dataFlow = getCurrentStoreMoving(
    sendData,
    notice.flgciudadoentreciudades
  );
  const data = {
    dataFlow,
    notice,
    idTypeNoticeNegotiation,
    selectDate,
    selectedDestiny,
    selectedOrigin,
    listOfOfferConditions,
  };
  if (notice.flgciudadoentreciudades === 1) {
    return getBodyInCity(data);
  } else {
    return getBodyBetweenCities(data);
  }
};

type BodyProps = {
  dataFlow: NoticeOfferMovingInCitySlice;
  notice: DetailNoticeMoving;
  idTypeNoticeNegotiation: number;
  selectDate: string;
  selectedDestiny: DestinisAPI;
  selectedOrigin: DestinisAPI;
  listOfOfferConditions: NoticeConditions[];
};

const getBodyInCity = (props: BodyProps) => {
  const {
    dataFlow,
    idTypeNoticeNegotiation,
    notice,
    selectDate,
    listOfOfferConditions,
  } = props;

  let body: BodyOfferMovingDTO = {
    idTipoAvisoNegociacion: idTypeNoticeNegotiation,
    fecha: selectDate,
    ayudantes: notice.ayudantes,
    idOrigen: notice.idorigen,
    referenciaOrigen: dataFlow.reference || '',
    idInmuebleOrigen: converStringToNumber(dataFlow.typeProperty) || 0,
    piso: converStringToNumber(dataFlow.floor) || 0,
    flgAscensor: converStringToNumber(dataFlow.ascensor) || 0,
    metraje: converStringToNumber(dataFlow.area) || 0,
    referenciaDestino: dataFlow.destinyReference || '',
    idInmuebleDestino: converStringToNumber(dataFlow.destinyTypeProperty) || 0,
    pisoDestino: converStringToNumber(dataFlow.destinyFloor) || 0,
    flgAscensorDestino: converStringToNumber(dataFlow.destinyAscensor) || 0,
    idDestino: notice.iddestino,
  };
  if (idTypeNoticeNegotiation === ID_COUNTEROFFER) {
    const avisoNegociacionCondiciones = prepareListConditions(
      listOfOfferConditions
    );

    body = { ...body, avisoNegociacionCondiciones };
  }

  return body;
};

const getBodyBetweenCities = (props: BodyProps) => {
  const {
    dataFlow,
    idTypeNoticeNegotiation,
    notice,
    selectDate,
    selectedDestiny,
    selectedOrigin,
    listOfOfferConditions,
  } = props;

  let body: BodyOfferMovingDTO = {
    idTipoAvisoNegociacion: idTypeNoticeNegotiation,
    fecha: selectDate,
    ayudantes: notice.ayudantes,
    idOrigen: selectedOrigin.iddistrito,
    referenciaOrigen: dataFlow.reference || '',
    idInmuebleOrigen: converStringToNumber(dataFlow.typeProperty) || 0,
    piso: converStringToNumber(dataFlow.floor) || 0,
    flgAscensor: converStringToNumber(dataFlow.ascensor) || 0,
    metraje: converStringToNumber(dataFlow.area) || 0,
    referenciaDestino: dataFlow.destinyReference || '',
    idInmuebleDestino: converStringToNumber(dataFlow.destinyTypeProperty) || 0,
    pisoDestino: converStringToNumber(dataFlow.destinyFloor) || 0,
    flgAscensorDestino: converStringToNumber(dataFlow.destinyAscensor) || 0,
    idDestino: selectedDestiny.iddistrito,
  };

  if (idTypeNoticeNegotiation === ID_COUNTEROFFER) {
    const avisoNegociacionCondiciones = prepareListConditions(
      listOfOfferConditions
    );

    body = { ...body, avisoNegociacionCondiciones };
  }

  return body;
};

export const converStringToNumber = (value?: string): number | null => {
  const currentValue = Number(value);
  if (isNaN(currentValue) || !currentValue) return null;

  return currentValue;
};

type ListConditions = Required<NoticeConditions[]>;
type ListConditionsDTO = BodyOfferMovingDTO['avisoNegociacionCondiciones'];
const prepareListConditions = (
  listConditions: ListConditions
): ListConditionsDTO => {
  const conditions = listConditions.map((condition) => {
    return {
      idcondiciones: condition.condicionesNegociacion.id,
      flgseleccion: condition.pordefecto,
    };
  });

  return conditions;
};
