import IconsSvg from "@/pages/detailNotice/components/IconsSvg";
import Icon, { IconTypes } from "@/utils/Icon";
import { Theme } from "@emotion/react";
import { Box, Stack, styled, SxProps, Typography } from "@mui/material";
import { FC } from "react";

const Title = styled(Typography)(
  ({ theme }) => `
      color: ${theme.palette.text.secondary};
      font-weight: bold;
    `
);

const ConatinerDetails = styled(Stack)(
  () => `
    justify-content: space-between;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
  `
);

export type DetailsTextProps = {
  show: boolean;
  title: string;
  origen: string;
  icon: string;
  constainerSx?: SxProps<Theme>;
};
const DetailsTextInline: FC<DetailsTextProps> = ({
  show,
  title,
  origen,
  icon,
  constainerSx,
}) => {
  if (!show || !origen) return <></>; // El !origen es para todos, cuando venga vacio no pinte nada el componente

  return (
    <ConatinerDetails sx={constainerSx}>
      <Stack flexDirection="row" alignItems="flex-start">
        {icon && (
          <Box>
            <Icon name={icon as IconTypes} size="sm" />
          </Box>
        )}
        <Box>
          <Title variant="body1" sx={{ marginLeft: icon ? "8px" : "0" }}>
            {`${title}: `}
            <Typography
              variant="label1"
              sx={{ wordWrap: "break-word", maxWidth: "320px" }}
            >
              {origen}
            </Typography>
          </Title>
        </Box>
      </Stack>
    </ConatinerDetails>
  );
};

export default DetailsTextInline;
