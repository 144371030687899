export const BASE_URL_FLOW = process.env.REACT_APP_BASE_URL_FLOW;

export const BASE_URL_AUTH = process.env.REACT_APP_BASE_URL_AUTH;

export const BASE_URL_SOCKET = process.env.REACT_APP_BASE_URL_SOCKET;

export const BASE_URL_CUSTOMER_REQUES =
  process.env.REACT_APP_BASE_URL_CUSTOMER_REQUES;

export const BASE_URL_IMAGE = process.env.REACT_APP_BASE_URL_IMAGE;
export const BASE_URL_IMAGE_S3 = process.env.REACT_APP_BASE_URL_IMAGE_S3;

export const RE_CAPTCHA_KEY = process.env.REACT_APP_RE_CAPTCHA_KEY;

export const ID_CITY_LIMA = process.env.REACT_APP_ID_CITY_LIMA;
