import {
  styled,
  TableCell,
  TableContainer,
  TableContainerProps,
  Toolbar,
  Typography,
} from "@mui/material";

export const ContainerTable = styled(TableContainer)<TableContainerProps>(
  () => ({
    borderRadius: "4px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    "@media only screen and (min-width: 899px)": {
      width: "660px",
    },
  })
);

export const BarTitleTable = styled(Toolbar)(() => ({
  minHeight: "48px !important",
  paddingLeft: "16px",
  borderBottom: "1px solid rgba(224, 224, 224, 1)",
}));

export const TitleTable = styled(Typography)(() => ({
  width: "250px",
  textAlign: "center",
}));

export const CellTable = styled(TableCell)(() => ({
  height: "48px",
  padding: "0px 16px",
  textAlign: "right",
}));
