import {
  REGISTER_CLIENT,
  REGISTER_CLIENT_PROFILE,
  REGISTER_DRIVER,
  REGISTER_DRIVER_STEPS,
  REGISTER_DRIVER_STEPS_DOCUMENT,
  REGISTER_DRIVER_STEPS_PROFILE,
  REGISTER_DRIVER_VEHICLE,
  REGISTER_DRIVER_VEHICLE_PHOTOS,
  REGITER_DRIVER_PROPERTY_CARD,
  WELCOME_CLIENT,
  WELCOME_DRIVER,
} from '@/consts/typeServicesPaths';
import ClientProfile from '@/pages/user/register/ClientProfile/ClientProfile';
import { Clients } from '@/pages/user/register/clients';
import FormDataDriver from '@/pages/user/register/driver/FormDataDriver';
import PropertyCard from '@/pages/user/register/driver/propertyCard/PropertyCard';
import DriverSteps from '@/pages/user/register/driver/steps/DriverSteps';
import { IdentificationDocument } from '@/pages/user/register/driver/steps/identificationDocument';
import VehicleRegister from '@/pages/user/register/vehicle/VehicleRegister';
import VehiclePhotos from '@/pages/user/register/vehiclePhotos/VehiclePhotos';
import Welcome from '@/pages/user/welcome/Welcome';
import { privateSectionRegisterMiddleware } from '@/router/middleware/privateRegister';
import { RouterProps } from '@/router/router';

export const registerFlowRouter: RouterProps[] = [
  {
    path: REGISTER_CLIENT,
    Component: Clients,
    requiredSession: true,
  },
  {
    path: REGISTER_CLIENT_PROFILE,
    Component: ClientProfile,
    requiredSession: true,
  },
  {
    path: WELCOME_CLIENT,
    Component: Welcome,
    requiredSession: true,
  },
  {
    path: REGISTER_DRIVER,
    Component: FormDataDriver,
    requiredSession: true,
  },
  {
    path: REGISTER_DRIVER_STEPS,
    Component: DriverSteps,
    requiredSession: true,
    middleware: privateSectionRegisterMiddleware,
  },
  {
    path: REGISTER_DRIVER_STEPS_PROFILE,
    Component: ClientProfile,
    requiredSession: true,
    middleware: privateSectionRegisterMiddleware,
  },
  {
    path: REGISTER_DRIVER_STEPS_DOCUMENT,
    Component: IdentificationDocument,
    requiredSession: true,
    middleware: privateSectionRegisterMiddleware,
  },
  {
    path: REGITER_DRIVER_PROPERTY_CARD,
    Component: PropertyCard,
    requiredSession: true,
    middleware: privateSectionRegisterMiddleware,
  },
  {
    path: REGISTER_DRIVER_VEHICLE,
    Component: VehicleRegister,
    requiredSession: true,
    middleware: privateSectionRegisterMiddleware,
  },
  {
    path: REGISTER_DRIVER_VEHICLE_PHOTOS,
    Component: VehiclePhotos,
    requiredSession: true,
    middleware: privateSectionRegisterMiddleware,
  },
  {
    path: WELCOME_DRIVER,
    Component: Welcome,
    requiredSession: true,
  },
];
