import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { StyleCustomInput } from "@/components/inputCustom/InputCustom";
import SelectCar from "@/pages/createRequestNegotiation/components/SelectCar";
import { CustomCardV2 } from "@/pages/requestNegotiation/negotiation/negClientReceiveCounterOffer/counterOfferResponse/components/MultidestinyCounterofferPrice";
import { currencyFormat } from "@/utils/helpers";
import { Box, FormControl, Grid, Typography, styled } from "@mui/material";
import { FormCounterOfferMultiDestinyEdition } from "./FormCounterOfferMultiDestinyEdition";
import { useCounterOfferMultidestiny } from "./useCounterOfferMultidestiny";

const Container = styled(Grid)(
  () => `
    display: flex;
    flex-direction: column;  
    margin-top: 24px;
  `
);

const FormCounterOfferMultidestiny = () => {
  const {
    errorPrice,
    isLoading,
    requestDetailsView,
    isEdition,
    isCounterOffer,
    typeOfMultidestiny,
    idRequest,
    price,
    handleSubmit,
    handleDisabledButton,
    handleOnchange,
  } = useCounterOfferMultidestiny();

  const handleTypeNeg = (): number => {
    const value = (requestDetailsView?.puntosRequeridos as number)
      ? requestDetailsView?.puntosRequeridos
      : requestDetailsView?.horasRequeridas;
    return value as number;
  };

  const handleCapitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleServicePrice = () => {
    let pointsRequired = requestDetailsView?.puntosRequeridos as number;
    let hoursRequired = requestDetailsView?.horasRequeridas as number;
    let hoursOrPoint = pointsRequired ? pointsRequired : hoursRequired;

    const value = Number(price) * hoursOrPoint;

    return !!value ? `${currencyFormat(value)}` : "-";
  };

  const totalCurrentPrice = currencyFormat(
    (requestDetailsView?.precio as number) * handleTypeNeg()
  );

  if (isEdition) return <FormCounterOfferMultiDestinyEdition />;

  return (
    <Container container>
      <form onSubmit={handleSubmit}>
        <FormControl sx={{ width: "100%", mb: 2 }}>
          <SelectCar idAvisoOrSolicitud={Number(idRequest)} />
        </FormControl>
        <CustomCardV2 sx={{ mt: 2 }}>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            Cliente solicita
          </Typography>
          <Grid container>
            <Grid xs={4}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: "12px" }}>
                  {`Por ${typeOfMultidestiny}s (S/)`}
                </Typography>
                <Typography sx={{ mb: 2, width: "100%" }}>
                  {currencyFormat(Number(requestDetailsView?.precio))}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={4}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: "12px" }}>
                  # {`${handleCapitalize(typeOfMultidestiny)}s`}
                </Typography>
                <Typography sx={{ fontSize: "16px" }}>
                  {handleTypeNeg()}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={4}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: "12px" }}>Precio total</Typography>
                <Typography sx={{ fontSize: "16px" }}>
                  {totalCurrentPrice}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CustomCardV2>

        <CustomCardV2 sx={{ mt: 2 }}>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            Contraoferta
          </Typography>
          <Grid container>
            <Grid xs={4}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: "12px" }}>
                  {`Por ${typeOfMultidestiny}s (S/)`}
                </Typography>
                <FormControl sx={{ mb: 2, width: "100%" }}>
                  <StyleCustomInput
                    id={`$1-price`}
                    name="price"
                    label=""
                    value={price}
                    variant="standard"
                    placeholder={`Contraoferta`}
                    onChange={handleOnchange}
                    onKeyDown={(e) =>
                      /[+-.,]$/.test(e.key) && e.preventDefault()
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      inputMode: "numeric",
                    }}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid xs={4}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: "12px" }}>
                  # {`${handleCapitalize(typeOfMultidestiny)}s`}
                </Typography>
                <Typography sx={{ fontSize: "16px" }}>
                  {handleTypeNeg()}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={4}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: "12px" }}>Precio total</Typography>
                <Typography sx={{ fontSize: "16px" }}>
                  {handleServicePrice()}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CustomCardV2>

        {!!errorPrice && (
          <Typography color="error" sx={{ fontSize: "12px", ml: 1, mt: 1 }}>
            {errorPrice}
          </Typography>
        )}

        <Grid item sx={{ marginTop: "16px" }}>
          <ButtomLoader
            isLoading={isLoading}
            type="submit"
            disabled={handleDisabledButton() || isLoading}
          >
            {isCounterOffer ? "CONTRAOFERTAR" : "SIGUIENTE"}
          </ButtomLoader>
        </Grid>
      </form>
    </Container>
  );
};

export default FormCounterOfferMultidestiny;
