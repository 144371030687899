import { ListStepsProps } from '@/components/ListSteps/ListSteps';

export const listSteps: ListStepsProps[] = [
  {
    text: 'Vehículo asociado',
    link: '/editar-vehiculo',
    isChecked: false,
    icon: 'icon-truck',
    key: 'id',
  },
  {
    text: 'Condiciones del servicio',
    link: '/editar-condiciones',
    isChecked: false,
    icon: 'icon-document',
    key: 'id',
  },
  {
    text: 'Precio',
    link: '/editar-precio',
    isChecked: false,
    icon: 'icon-money',
    key: 'id',
  },
];

export const listStepsEditNegotation: ListStepsProps[] = [
  {
    text: 'Ruta del servicio',
    link: '/ruta',
    isChecked: false,
    icon: 'icon-location-light',
    key: 'id', // en este archivo no se usa esta propiedad
  },
  {
    text: 'Carga y vehículo',
    link: '/carga',
    isChecked: false,
    icon: 'icon-box',
    key: 'id', // en este archivo no se usa esta propiedad
  },
  {
    text: 'Condiciones',
    link: '/condiciones',
    isChecked: false,
    icon: 'icon-document',
    key: 'id', // en este archivo no se usa esta propiedad
  },
  {
    text: 'Precio',
    link: '/precio',
    isChecked: false,
    icon: 'icon-money',
    key: 'id', // en este archivo no se usa esta propiedad
  },
];

export const listStepsEditNegotationBtnCities: ListStepsProps[] = [
  {
    text: 'Vehículo asociado',
    link: '/editar-vehiculo',
    isChecked: false,
    icon: 'icon-truck',
    key: 'id',
  },
  {
    text: 'Precio',
    link: '/editar-precio',
    isChecked: false,
    icon: 'icon-money',
    key: 'id',
  },
];

export const listStepsEditNegotationAuction: ListStepsProps[] = [
  {
    text: 'Vehículo asociado',
    link: '/editar-vehiculo',
    isChecked: false,
    icon: 'icon-truck',
    key: 'id',
  },
  {
    text: 'Fecha y precio',
    link: '/editar-precio',
    isChecked: false,
    icon: 'icon-money',
    key: 'id',
  },
];

export const listStepsEditNegotationMoving: ListStepsProps[] = [
  {
    text: 'Fecha',
    link: '/fecha',
    isChecked: false,
    icon: 'icon-calendar',
    key: 'id', // en este archivo no se usa esta propiedad
  },
  {
    text: 'Origen',
    link: '/origen',
    isChecked: false,
    icon: 'icon-location-light',
    key: 'id', // en este archivo no se usa esta propiedad
  },
  {
    text: 'Destino',
    link: '/destino',
    isChecked: false,
    icon: 'icon-location-dark',
    key: 'id', // en este archivo no se usa esta propiedad
  },
  {
    text: 'Cantidad de ambientes',
    link: '/ambientes',
    isChecked: false,
    icon: 'icon-document',
    key: 'id', // en este archivo no se usa esta propiedad
  },
];

export const listStepsEditRequestNegotationMoving: ListStepsProps[] = [
  {
    text: 'Origen',
    link: '/origen',
    isChecked: false,
    icon: 'icon-location-light',
    key: 'id', // en este archivo no se usa esta propiedad
  },
  {
    text: 'Destino',
    link: '/destino',
    isChecked: false,
    icon: 'icon-location-dark',
    key: 'id', // en este archivo no se usa esta propiedad
  },
  {
    text: 'Ambientes',
    link: '/ambientes',
    isChecked: false,
    icon: 'icon-box',
    key: 'id', // en este archivo no se usa esta propiedad
  },
  {
    text: 'Condiciones',
    link: '/condiciones',
    isChecked: false,
    icon: 'icon-document',
    key: 'id',
  },
];

export const generalConfig: Record<number, ListStepsProps[]> = {
  1: listSteps,
  2: listStepsEditNegotation,
  3: listStepsEditNegotationMoving,
  4: listStepsEditNegotationBtnCities,
  5: listStepsEditNegotationAuction,
};

export type TypeTitleRequest = Record<string, string>;

export const typeTitleRequest: TypeTitleRequest = {
  'en-la-ciudad': 'En la ciudad',
  multidestino: 'Multidestino',
  'entre-ciudades': 'Entre ciudades',
  mudanza: 'Mudanza',
};

export const typeTitleEditionRequest: TypeTitleRequest = {
  'en-la-ciudad': 'En la ciudad',
  multidestino: 'Multidestino',
  'entre-ciudades': 'Entre ciudades',
  'multidestino-en-la-ciudad': 'Multidestino en la ciudad',
  mudanzas: 'Mudanza',
};
