import { useLocation, useNavigate } from 'react-router-dom';

import { clientText, driverText } from '@/consts/noticeConsts';
import { NEGOTATION_AGREEMENT_CONFIRMATION, NEGOTATION_AGREEMENT_MESSAGE, NEGOTATION_CLIENT_PATH } from '@/consts/negotationFlowPath';

const useAgreementMessageHook = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
    const isDriver = pathname.startsWith(NEGOTATION_CLIENT_PATH) 
    const textToRender = isDriver ? "transportista" : "cliente"

  const handleButton = () => {
    const urlNavigate = pathname.replace(NEGOTATION_AGREEMENT_MESSAGE, NEGOTATION_AGREEMENT_CONFIRMATION)
    navigate(urlNavigate, {replace: true})
  };
  return {
    handleButton,
    textToRender,
  };
};

export default useAgreementMessageHook;