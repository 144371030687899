import { OFFER_AUCTION_FLOW_PATH } from '@/pages/flowNoticeNegotation/shared/const/routes/clientAccepOrCounteroffer';
import AuctionBtnCitiesCargoDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/childComponents/FormCargoDetails/AuctionBtnCitiesCargo';
import FormConfirmationDetailAuction from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/childComponents/FormConfirmationDetails/FormConfirmationDetailAuction';
import RuteAuction from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/childComponents/FormRuteDetails/RuteAuction/Index';
import CargoDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/containers/CargoDetails/Index';
import ConfirmationDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/containers/ConfirmationDetails/Index';
import RuteDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/containers/RuteDetails/Index';
import { privateOffertMiddleware } from '@/router/middleware/privateCreateServicesMiddleware';
import { RouterProps } from '@/router/router';

export const offerNoticeNegAuctionRouter: RouterProps[] = [
  {
    path: OFFER_AUCTION_FLOW_PATH[0],
    Component: CargoDetails,
    requiredSession: true,
    middleware: privateOffertMiddleware,
    children: [
      {
        index: true,
        Component: AuctionBtnCitiesCargoDetails,
      },
    ],
  },
  {
    path: OFFER_AUCTION_FLOW_PATH[1],
    Component: RuteDetails,
    middleware: privateOffertMiddleware,
    children: [
      {
        index: true,
        Component: RuteAuction,
      },
    ],
  },
  {
    path: OFFER_AUCTION_FLOW_PATH[2],
    Component: ConfirmationDetails,
    middleware: privateOffertMiddleware,
    children: [
      {
        index: true,
        Component: FormConfirmationDetailAuction,
      },
    ],
  },
];
