import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { StyleCustomInput } from "@/components/form/customInput/styleCustomInput";
import LabelRequired from "@/components/utils/LabelRequired";
import { CONFIRMATION_PATH } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import HeaderProfile from "@/pages/profile/headerProfile/HeaderProfile";
import { tipoSMS, titleByPath } from "@/pages/user/validation/configDataClientProfile";
import CountDownLinkAction from "@/pages/user/validation/CountDownLinkAction";
import { sendCodeBySMSThunk, validateCodeThunk } from "@/store/auth/authUserThunks";
import { changeUserPhoneThunk } from "@/store/userRedux/userThunks";
import { styled } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Input = styled(StyleCustomInput)(
  () => `
      margin-bottom: 16px;
    `
);
const MAX_LENGTH_CODE = 4;

const Validation = () => {
  const [code, setCode] = useState("");
  const [showErrorSendCode, setShowErrorSendCode] = useState(false);
  const { cellPhoneChange, errorValidateCode, errorSendCode } = useAppSelector((state) => state.auth);
  const { error } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const handleOnchange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const value = currentTarget.value.trim();
    if (value.length <= MAX_LENGTH_CODE) {
      setCode(
        value.replace(/\D/g, "") // Solo permite [1-9]
      );
    }
  };

  const handleReSendMSM = () => {
    setShowErrorSendCode(false);
    dispatch(sendCodeBySMSThunk(cellPhoneChange, tipoSMS[pathname])).catch(() => setShowErrorSendCode(true));
  };
  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(validateCodeThunk(cellPhoneChange, code)).then(() => {
      dispatch(changeUserPhoneThunk(cellPhoneChange))
        .then(() => {
          navigate(CONFIRMATION_PATH, { replace: true, state: { type: "changePhone" } });
        })
        .catch(() => {
          setShowErrorSendCode(true);
        });
    });
  };

  return (
    <HeaderProfile title={titleByPath[pathname]}>
      <>
        {showErrorSendCode && (
          <StandardAlert sx={{ marginBottom: "16px" }} severity="error" color="error" onClick={() => setShowErrorSendCode(false)}>
            {errorSendCode || error}
          </StandardAlert>
        )}
      </>
      <form
        onSubmit={handleOnSubmit}
        style={{
          marginTop: "16px",
        }}
      >
        <Input
          label={<LabelRequired str="Código de verificación" />}
          name="phone"
          variant="standard"
          error={!!errorValidateCode?.length}
          helperText={errorValidateCode}
          placeholder="Ingresa el código de 4 dígitos"
          autoComplete="off"
          fullWidth
          value={code}
          onChange={handleOnchange}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            inputMode: "numeric",
          }}
        />

        <CountDownLinkAction initActiveCountdown activeCountdown={handleReSendMSM} />

        <ButtomLoader type="submit">GUARDAR</ButtomLoader>
      </form>
    </HeaderProfile>
  );
};

export default Validation;
