import {
  NOTICE_IN_CITY,
  SERVICES_LANDING_PATH,
  NOTICE_MULTI_DESTINY,
  NOTICE_MOVING,
  NOTICE_BETWEEN_CITIES,
  NOTICE_AUCTION,
  PROFILE_NOTICE_LIST_PATH,
} from './typeServicesPaths';

export const OFFERT_IN_CITY_MAIN_PATH = `${SERVICES_LANDING_PATH}/${NOTICE_IN_CITY}/:id`;
export const QUOTE_PATH = 'solicitar-cotizacion';
export const COUNTER_OFFERT = '/contraoferta';
export const COUNTER_OFFER_RUTE = '/contraoferta-ruta';

export const OFFERT_IN_CITY_FLOW_PATH = [
  `${OFFERT_IN_CITY_MAIN_PATH}/ofertar-carga`,
  `${OFFERT_IN_CITY_MAIN_PATH}/acepta-ruta`,
  `${OFFERT_IN_CITY_MAIN_PATH}/confirmacion`,
];

export const COUNTER_OFFERT_IN_CITY_FLOW_PATH = [
  `${OFFERT_IN_CITY_MAIN_PATH}/contraoferta-carga`,
  `${OFFERT_IN_CITY_MAIN_PATH}/contraoferta-ruta`,
  `${OFFERT_IN_CITY_MAIN_PATH}/contraoferta-confirmacion`,
];

export const OFFERT_BETWEEN_CITIES_MAIN_PATH = `${SERVICES_LANDING_PATH}/${NOTICE_BETWEEN_CITIES}/:id`;
export const OFFERT_BETWEEN_CITIES_PATH = [
  `${OFFERT_BETWEEN_CITIES_MAIN_PATH}/ofertar-carga`,
  `${OFFERT_BETWEEN_CITIES_MAIN_PATH}/ofertar-ruta`,
  `${OFFERT_BETWEEN_CITIES_MAIN_PATH}/ofertar-confirmacion`,
];

export const COUNTER_OFFERT_BETWEEN_CITIES_PATH = [
  `${OFFERT_BETWEEN_CITIES_MAIN_PATH}/contraoferta-carga`,
  `${OFFERT_BETWEEN_CITIES_MAIN_PATH}/contraoferta-ruta`,
  `${OFFERT_BETWEEN_CITIES_MAIN_PATH}/contraoferta-confirmacion`,
];

export const OFFERT_MULTI_DESTINY_MAIN_PATH = `${SERVICES_LANDING_PATH}/${NOTICE_MULTI_DESTINY}/:id`;

export const OFFERT_MULTI_DESTINY_FLOW_PATH = [
  `${OFFERT_MULTI_DESTINY_MAIN_PATH}/ofertar-carga`,
  `${OFFERT_MULTI_DESTINY_MAIN_PATH}/acepta-ruta`,
  `${OFFERT_MULTI_DESTINY_MAIN_PATH}/acepta-precio`,
  `${OFFERT_MULTI_DESTINY_MAIN_PATH}/confirmacion`,
];

export const COUNTER_OFFERT_MULTI_DESTINY_FLOW_PATH = [
  `${OFFERT_MULTI_DESTINY_MAIN_PATH}/contraoferta-carga`,
  `${OFFERT_MULTI_DESTINY_MAIN_PATH}/contraoferta-ruta`,
  `${OFFERT_MULTI_DESTINY_MAIN_PATH}/contraoferta-precio`,
  `${OFFERT_MULTI_DESTINY_MAIN_PATH}/contraoferta-confirmacion`,
];

export const OFFERT_MOVING_MAIN_PATH = `${SERVICES_LANDING_PATH}/${NOTICE_MOVING}/:id`;

export const OFFERT_MOVING_FLOW_PATH = [
  `${OFFERT_MOVING_MAIN_PATH}/ofertar-carga`,
  `${OFFERT_MOVING_MAIN_PATH}/crear-origen`,
  `${OFFERT_MOVING_MAIN_PATH}/crear-destino`,
  `${OFFERT_MOVING_MAIN_PATH}/confirmacion`,
];

export const COUNTER_OFFERT_MOVING_FLOW_PATH = [
  `${OFFERT_MOVING_MAIN_PATH}/solicitar-cotizacion`,
  `${OFFERT_MOVING_MAIN_PATH}/solicitar-cotizacion-origen`,
  `${OFFERT_MOVING_MAIN_PATH}/solicitar-cotizacion-destino`,
  `${OFFERT_MOVING_MAIN_PATH}/solicitar-cotizacion-confirmacion`,
];

// ==================================================
//                   Oferta de Remate
// ==================================================
export const OFFERT_AUCTION_MAIN_PATH = `${SERVICES_LANDING_PATH}/${NOTICE_AUCTION}/:id`;
export const OFFERT_AUCTION_FLOW_PATH = [
  `${OFFERT_AUCTION_MAIN_PATH}/ofertar-carga`,
  `${OFFERT_AUCTION_MAIN_PATH}/confirmacion`,
];

export const COUNTER_OFFERT_AUCTION_FLOW_PATH = [
  `${OFFERT_AUCTION_MAIN_PATH}/contraoferta-carga`,
  `${OFFERT_AUCTION_MAIN_PATH}/contraoferta-condiciones`,
  `${OFFERT_AUCTION_MAIN_PATH}/contraoferta-confirmacion`,
];

export const EDIT_COUNTEROFFER_CLIENT = `${SERVICES_LANDING_PATH}/:typeOffer/:noticeId/editar`;
export const EDIT_COUNTEROFFER_DRIVER = `${PROFILE_NOTICE_LIST_PATH}/:typeOffer/:noticeId/editar`;
export const EDIT_COUNTEROFFER_SUCCESS_MESSAGE = `${PROFILE_NOTICE_LIST_PATH}/:typeOffer/:noticeId/editar-mensaje`;

export const NEGOTATION_REJECTED_NOTICE_MESSAGE_CLIENT = `${SERVICES_LANDING_PATH}/:typeOffer/:noticeId/retiro-mensaje`;
export const NEGOTATION_REJECTED_NOTICE_MESSAGE_DRIVER = `${PROFILE_NOTICE_LIST_PATH}/:typeOffer/:noticeId/retiro-mensaje`;
