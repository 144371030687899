import { InitialStateDetailsNoticeV2Negotation } from '@/store/noticeV2Negotiation/noticeV2NegotiationSlice';

export const whiteList: Array<keyof InitialStateDetailsNoticeV2Negotation> = [
  'inCity',
  'betweenCities',
  'multiDestiny',
  'moving',
  'auction',
];

export const acceptData = {
  title: '¡Aceptaron tu servicio!',
  icon: 'icon-success',
  color: 'success',
};

export const movingCounterOffer = {
  title: '¡Solicitaron una cotización!',
  icon: 'icon-counterOfferMat',
  color: 'info',
};

export const counterOfferData = {
  title: '¡Contraofertaron tu servicio!',
  icon: 'icon-counterOfferMat',
  color: 'info',
};
