import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import LabelRequired from "@/components/utils/LabelRequired";
import {
  TEXT_ERROR_MAX_LOAD_V2,
  TEXT_ERROR_MIN_FIVE_PERCENTAGE,
  TEXT_ERROR_MIN_LOAD,
} from "@/consts/textErrors";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { DirtyTypeProps } from "@/interfaces/globalInterface";
import { ISelectedVehicle } from "@/store/userRedux/userReduxInterface";
import { FormControl, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { INoticeCreateListItem } from "../../NoticeList/types";
import { extractSendData } from "../../adapters/noticeUseTypes";
import { SelectControl } from "../../components/selectControl/SelectControl";
import { amountWeigth } from "../vehicleConfig";
import { ContainerForm } from "../vehicleStyles";
import { FormVehiclesProps } from "../vehicleTypes";

export const FormVehicle = ({
  onSubmit,
  loading,
  buttonText = "SIGUIENTE",
  onDirtyFields,
  idTipoAviso,
}: FormVehiclesProps) => {
  const {
    user: { vehicles, selectedVehicle },
    noticeV2: { selectNotice, sendData },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const [maximumLoad, setMaximumLoad] = useState("");
  const [error, setError] = useState("");
  const [dirtyFields, setDirtyFields] = useState<DirtyTypeProps>({
    selectVehicle: { dirty: false },
  });

  const idNoticeType = (selectNotice as INoticeCreateListItem)?.id || 0;
  const noticeTypeData = extractSendData(sendData, idNoticeType);

  const { cargamaxima, flgrefrigerado, ...selectRest } =
    selectedVehicle as ISelectedVehicle;

    useEffect(() => {
      let newMaxWeight = cargamaxima;
  
      if (!!noticeTypeData.pesomaximo && !dirtyFields.selectVehicle.dirty) newMaxWeight = noticeTypeData.pesomaximo;
  
      if (!!Object.keys(selectedVehicle).length) {
        setMaximumLoad(newMaxWeight.toString());
      }
    }, [selectedVehicle, cargamaxima, flgrefrigerado, dirtyFields]);
    
  
    useEffect(() => {
      onDirtyFields && onDirtyFields(dirtyFields);
    }, [dirtyFields]);

  const findSelectedVehicle = (id: number): number => {
    const data = vehicles.find((vehicle) => vehicle?.id === id);

    return data?.cargamaxima || cargamaxima;
  };

  const handleChangeSelect = () => {
    setDirtyFields({ ...dirtyFields, selectVehicle: { dirty: true } });
    setError("");
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if(error !== "") return
    if (loading) return;

    const currentMaximumLoad = Number(maximumLoad);
    if (
      currentMaximumLoad < 10 ||
      currentMaximumLoad > findSelectedVehicle(selectRest?.id || 0)
    ) {
      setError(handleGetTextError(currentMaximumLoad));
      return;
    }
    
    onSubmit &&
      onSubmit({
        id: selectRest.id,
        cargamaxima: Number(maximumLoad),
      });
  };

  const onChangeMaximumLoad = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fivePercentageLoad = cargamaxima * (5 / 100) || Number(maximumLoad) * (5 / 100)

    if (!/^[0-9]*$/.test(e.target.value)) return;
    setError("");
    const currentMaximumLoad = Number(cargamaxima);
    const value = Number(e.target.value);
    if (value > currentMaximumLoad) {
      setError(handleGetTextError(value));
    }

    if (value < Math.round(fivePercentageLoad)) {
      setError(TEXT_ERROR_MIN_FIVE_PERCENTAGE);
    }

    setMaximumLoad(e.target.value);
  };

  const handleGetTextError = (currenValue: number): string => {
    if (currenValue < 10) {
      return TEXT_ERROR_MIN_LOAD;
    }

    return TEXT_ERROR_MAX_LOAD_V2;
  };

  const validateButton = () => {
    if(error) return true
    const currentMaximumLoad = Number(maximumLoad);
    if (
      currentMaximumLoad <= findSelectedVehicle(selectRest?.id || 0) &&
      currentMaximumLoad >= 10
    ) {
      return false;
    }

    return true;
  };

  return (
    <ContainerForm container>
      <FormControl
        sx={{
          gap: "24px",
        }}
      >
        <SelectControl onChange={handleChangeSelect} showMaxLoad />

        {idTipoAviso === 4 && (
          <Input
            error={!!error.length}
            id="weight"
            name="weight"
            label={<LabelRequired str={amountWeigth} />}
            value={maximumLoad}
            variant="standard"
            placeholder="Ingresa el peso máximo"
            onChange={onChangeMaximumLoad}
            onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
            helperText={error}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
              maxLength: 5,
            }}
          />
        )}
      </FormControl>

      <Grid item sx={{ marginTop: "8px" }}>
        <ButtomLoader
          type="submit"
          isLoading={loading}
          disabled={validateButton()}
          onClick={handleSubmit}
        >
          {buttonText}
        </ButtomLoader>
      </Grid>
    </ContainerForm>
  );
};
