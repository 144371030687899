import { OFFER_IN_CITY_FLOW_PATH } from '@/pages/flowNoticeNegotation/shared/const/routes/clientAccepOrCounteroffer';
import InCityCargoDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/childComponents/FormCargoDetails/InCityCargo';
import FormConfirmationDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/childComponents/FormConfirmationDetails/Index';
import RuteInCity from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/childComponents/FormRuteDetails/RuteInCity/Index';
import CargoDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/containers/CargoDetails/Index';
import ConfirmationDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/containers/ConfirmationDetails/Index';
import RuteDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/containers/RuteDetails/Index';
import { privateOffertMiddleware } from '@/router/middleware/privateCreateServicesMiddleware';
import { RouterProps } from '@/router/router';

export const offertNoticeNegInCityFlowRouter: RouterProps[] = [
  {
    path: OFFER_IN_CITY_FLOW_PATH[0],
    Component: CargoDetails,
    requiredSession: true,
    middleware: privateOffertMiddleware,
    children: [
      {
        index: true,
        Component: InCityCargoDetails,
      },
    ],
  },
  {
    path: OFFER_IN_CITY_FLOW_PATH[1],
    Component: RuteDetails,
    middleware: privateOffertMiddleware,
    children: [
      {
        index: true,
        Component: RuteInCity,
      },
    ],
  },
  {
    path: OFFER_IN_CITY_FLOW_PATH[2],
    Component: ConfirmationDetails,
    middleware: privateOffertMiddleware,
    children: [
      {
        index: true,
        Component: FormConfirmationDetails,
      },
    ],
  },
];
