import { controllerInstanceAuth, controllerInstanceFlow } from "@/services/connection";
import { controllerInstanceCustomerRequest } from "@/services/connection/intanceCustomerRequest";
import { Alert, styled, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CustomSnackbar from "../customSnackbar/CustomSnackbar";
import { ChildConfig, configData } from "./configDataMessage";

const ActionText = styled(Typography)(
  ({ theme }) => `
    font-family: ${theme.typography.body1.fontFamily};
    font-weight: bold;
    font-size: ${theme.typography.body1.fontSize};
    line-height: ${theme.typography.body1.lineHeight};
    text-decoration: underline;
    cursor: pointer;
  `
);

const MessageOffline = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentConfig, setCurrentConfig] = useState<ChildConfig>(configData.error);

  useEffect(() => {
    window.addEventListener("online", () => {
      setIsOpen(true);
      setCurrentConfig(configData.success);
    });
    window.addEventListener("offline", () => {
      setIsOpen(true);
      setCurrentConfig(configData.error);
      controllerInstanceAuth.abort(); // Cancela todas las peticiones a las apis!!
      controllerInstanceFlow.abort(); // Cancela todas las peticiones a las apis!!
      controllerInstanceCustomerRequest.abort(); // Cancela todas las peticiones a las apis!!
    });
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAutoRefresh = () => {
    if (currentConfig.status === configData.success.status) {
      window.location.reload();
    }
    setIsOpen(false);
  };

  const hasFunction = currentConfig.status === configData.success.status ? undefined : handleClose;

  return (
    <CustomSnackbar open={isOpen}>
      <Alert onClose={hasFunction} severity={currentConfig.status}>
        <Typography onClick={handleAutoRefresh}>
          {currentConfig.message}
          <ActionText variant="caption">{currentConfig.message2}</ActionText>
        </Typography>
      </Alert>
    </CustomSnackbar>
  );
};

export default MessageOffline;
