import { BASE_URL_IMAGE } from '@/config/config';
import { NO_ACCESS_IMG } from '@/consts/defaultConsts';
import { Box, Container, Grid } from '@mui/material';
import { BoxButton, ButtonDecline, GridButton, GridImage, RedirectDisplay, Text } from './stylesNoAccess';


const NoAccess = () => {
    const imageUrl = BASE_URL_IMAGE + NO_ACCESS_IMG;



    const handleGetToPath = () => {
      return `/`;
    };

    
  return (
    <Container
    maxWidth="lg"
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Grid container spacing={2}>
      <GridImage xs={12}>
        <Box
          component="img"
          sx={{
            height: 353,
            width: 350,
          }}
          alt="Imagen de rechazo"
          src={imageUrl}
        />
        <Text sx={{fontWeight: 900}}>Lo sentimos</Text>
        <Text sx={{fontSize: "16px"}}>
        Si consideras que se trata de un error, 
        <RedirectDisplay to={handleGetToPath()}> contáctanos aquí</RedirectDisplay>
        </Text>
      </GridImage>
      <GridButton xs={12}>
        <BoxButton>
          <ButtonDecline fullWidth variant="text">
            IR AL INICIO
          </ButtonDecline>
        </BoxButton>
      </GridButton>
    </Grid>
  </Container>
  )
}

export default NoAccess