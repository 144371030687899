import { styled, Switch, SwitchProps, Grid, Typography } from "@mui/material";

export const ContainerSwitch = styled(Grid)(
  () => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
  `
);

const bgCheckIcon = `url('data:image/svg+xml;utf8,<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="${encodeURIComponent(
  "#D1E4F6"
)}" d="M12.0007 1.66656L4.00065 9.66656L0.333984 5.9999L1.27398 5.0599L4.00065 7.7799L11.0607 0.726562L12.0007 1.66656Z"/></svg>')`

const bgCloseIcon = `url('data:image/svg+xml;utf8,<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="${encodeURIComponent(
  "#9E9E9E"
)}" d="M4.99983 5.8119L9.18678 10L9.99983 9.1881L5.81172 5L9.99983 0.813046L9.18793 0L4.99983 4.1881L0.812874 0L0.000976562 0.813046L4.18793 5L0.000976562 9.18695L0.812874 10L4.99983 5.8119Z"/></svg>')`

export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 38.5,
  height: 22,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translate(1.5px, 1px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translate(17.5px, 1px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: bgCheckIcon,
        backgroundColor: "#FF6700",
        borderRadius: "100%",
      },
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark" ? "#2ECA45" : "rgba(25,118,210,0.2)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "light" ? "#FFFFFF" : "#39393D",
    width: 18,
    height: 18,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: bgCloseIcon,
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor:
      theme.palette.mode === "light" ? "rgba(0,0,0,0.38)" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const TextContainer = styled(Typography)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
})


export const PointView = styled(Typography)({
  mr: 1,
  ml: 1,
  fontSize: 20,
  fontWeight: 700,
  color: "#000000",
})