import { NoticeDetailAuctionModel } from "@/models/notice/noticeV2NegotationModels";
import { currencyFormat } from "@/utils/helpers";
import { Box, Grid, Typography } from "@mui/material";
import { CustomCard } from "./AuctionCounterOfferPrice";
import { textColorDescount } from "@/pages/acceptanceOffert/components/SectionDescount";

const AuctionOfferPrice = ({ data }: { data: NoticeDetailAuctionModel }) => {
  const { flgIsOwner } = data;
  return (
    <>
      <Box>
        {
          flgIsOwner ? (
            <>
              <CustomCard sx={{ mt: 2 }}>
                <Typography sx={{ color: '#757575', fontSize: '14px' }}>
                  En el aviso (por {data.aviso.pesomaximo} kilos)
                </Typography>
                <Grid container>
                  <Grid xs={6}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", color: '#757575' }}>
                      <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                        Precio Normal
                      </Typography>
                      <Typography sx={{ fontSize: '14px' }}>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            textDecoration: "line-through",
                          }}
                        >
                          {currencyFormat(data.aviso.precioregular || 0)}
                        </Typography>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid xs={6}>
                    <Box sx={{
                      display: "flex", flexDirection: "column",
                      gap: "10px", color: '#757575'
                    }}>
                      <Typography sx={{ fontSize: '12px', display: "flex", fontWeight: 600, }}>
                        Precio con 
                        
                        
                        <Typography
                          sx={{
                            ml: 0.3,
                            textAlign: "revert",
                            color: textColorDescount,
                            fontWeight: 600,
                            fontSize: '12px',
                          }}
                        >
                          {data.aviso?.porcentajedescuento as number}% dscto
                        </Typography>
                      </Typography>
                      <Typography sx={{ fontSize: '14px', color: '#757575' }}>
                        {currencyFormat(data.aviso.precio)}
                      </Typography>
                  </Box>
                </Grid>
              </Grid>
            </CustomCard>
          </>
        ) : null}
        <Typography
          sx={{ fontWeight: 700, fontSize: "22px", textAlign: "revert", mt: 2 }}
        >
          Acuerdo cerrado: {currencyFormat(data?.preciocontraoferta || data?.precio)}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontWeight: 700, fontSize: "18px" }}>
            Peso del remate: {data?.pesoEstimado} kilos
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default AuctionOfferPrice;
