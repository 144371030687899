import { Stack, Typography } from "@mui/material";
import React from "react";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";

type Props = {
  show: boolean;
  imgSrc: string;
  imgWith?: string;
  buttonText: string;
  buttonOnClick?: () => void;
};

const NotFound = ({
  show,
  imgSrc,
  buttonText,
  imgWith = "320px",
  buttonOnClick,
}: Props) => {
  if (!show) return <></>;

  return (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "36px",
        backgroundColor: "#fafafa",
      }}
    >
      <img src={imgSrc} alt={imgSrc} width={imgWith} />
      <Typography variant="h6" sx={{ marginTop: "16px" }}>
        No encontramos resultados
      </Typography>
      <Typography
        variant="body1"
        sx={{ maxWidth: "335px", textAlign: "center", marginTop: "8px" }}
      >
        Cambia los criterios de tu búsqueda o crea gratis una solicitud.
      </Typography>
      <ButtomLoader
        sx={{ maxWidth: "360px", marginTop: "40px" }}
        onClick={buttonOnClick}
      >
        {buttonText}
      </ButtomLoader>
    </Stack>
  );
};

export default NotFound;
