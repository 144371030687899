import SkeletonViewService from "@/pages/loading/SkeletonViewService";
import { ISolicitudNegociacion } from "@/services/interfaces/RequestNegResponseSuccess";
import { Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import Layout from "./components/Layout";
import useRequestNegFinishedAcceptHook from "./hook";

const RequestNegFinishedAccept = () => {
  const { savedDataNogotation, loading } = useRequestNegFinishedAcceptHook();

  if (loading || !savedDataNogotation) return <SkeletonViewService />;

  return (
    <Layout request={savedDataNogotation as ISolicitudNegociacion}>
      <Stack
        sx={{
          gap: "3px",
          wordWrap: "break-word",
        }}
      >
        <Outlet />
      </Stack>
    </Layout>
  );
};

export default RequestNegFinishedAccept;
