import { INoticeSendAuctionBetweenCities, INoticeSendBetweenCities, INoticeSendInTheCity, INoticeSendMoveBetweenCities, INoticeSendMoveInTheCity, INoticeSendMultiDestiny } from '@/services/noticeServices/noticeSendData/noticeSendDataTypes';
import { IReturnDataValues, ISendData, ISendDataKeys, ISendDataValues } from '../../../store/noticeV2Redux/noticeV2types';
import { configInCity } from '../NoticePlace/components/inputsConfig/inputsInCity';
import { configMultiInCity } from '../NoticePlace/components/inputsConfig/inputsMultiInCity';
import { configBetweenCities } from '../NoticePlace/components/inputsConfig/inputsBetweenCities';
import { configAuctionBetweenCities } from '../NoticePlace/components/inputsConfig/inputsAuctionBetweenCities';
// const noticeByIs: Record<number, string> = {
//     1: NOTICE_IN_CITY,
//     2: NOTICE_BETWEEN_CITIES,
//     3: NOTICE_MULTI_DESTINY,
//     4: NOTICE_MOVING,
//     5: NOTICE_AUCTION,
// };

// devolver el tipo de aviso dependiendo del idTypeNotice y el variant
export const getNoticeType = (idTypeNotice: number, variant?: boolean): ISendDataKeys => {
    switch (idTypeNotice) {
        case 1:
            return 'inTheCity';
        case 2:
            return 'multiDestiny';
        case 3:
            return 'betweenCities';
        case 4:
            return 'auctionBetweenCities';
        case 5:
            if (variant) {
                return 'moveInTheCity';
            }
            return 'moveBetweenCities';
        default:
            return 'inTheCity';
    }
}

// crear una función que reciba el id del tipoAviso y que dependiendo se eso
// devuelva la interfaz que se necesita para crear el aviso.
// el id 4 y 5 son iguales exceptuando por que uno es en la ciudad y otro entre ciudades
export const getNoticeTypeInterface = (idTypeNotice: number, values: Partial<ISendDataValues> , variant?: boolean): ISendDataValues => {
    switch (idTypeNotice) {
        case 1:
            return values as INoticeSendInTheCity;
        case 2:
            return values as INoticeSendMultiDestiny;
        case 3:
            return values as INoticeSendBetweenCities;
        case 4:
            return values as INoticeSendAuctionBetweenCities;
        case 5:
            if (variant) {
                return values as INoticeSendMoveInTheCity;
            }
            return values as INoticeSendMoveBetweenCities;
        default:
            return values as INoticeSendInTheCity;
    }
}

// saber que sendData se esta usando y extraer la info.
export const extractSendData = (sendData: ISendData, idTypeNotice: number, variant?: boolean): IReturnDataValues => {
    const typeNotice = getNoticeType(idTypeNotice, variant);
    const data = getNoticeTypeInterface(idTypeNotice, sendData[typeNotice], variant) as IReturnDataValues
    return data;
}


// saber el tipo de input a usar por el tipo de aviso
export const getInputsTypeAviso: Record<number, any> = {
    1: configInCity,
    2: configMultiInCity,
    3: configBetweenCities,
    4: configAuctionBetweenCities,
    // 5: configBetweenCities,
}


