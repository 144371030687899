import { useEffect } from "react";
import InfoCard from "@/components/infoCard/InfoCard";
import { useAppSelector } from "@/hooks/useReducer";
import { Container, Grid, styled, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { resetFlowNotice } from "@/store/routeActionsFlow/routeActionsFlowNoticeSlice";

const Title = styled(Typography)(
  ({ theme }) => `
      font-family:${theme.typography.h6.fontFamily};
      font-size: ${theme.typography.h6.fontSize};
      line-height: ${theme.typography.h6.fontSize};
      font-weight: ${theme.typography.h6.fontWeight};
      color: ${theme.palette.text.secondary};
      margin-bottom: 16px;
      @media only screen and (min-width: 768px){
        font-family:${theme.typography.h5low.fontFamily};
        font-size: ${theme.typography.h5low.fontSize};
        line-height: ${theme.typography.h5low.fontSize};
        margin-bottom: 32px;
      }
    `
);

const ContainerSuccess = styled(Container)(
  () => `
      padding: 16px;
      @media only screen and (min-width: 768px){
        padding: 32px 64px;
      }
    `
);

const DetailsCardListCities = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetFlowNotice());
  }, []);

  const {
    saveFlowData: { saveListFlowData },
  } = useAppSelector((state) => state);

  return (
    <ContainerSuccess>
      <Title>Mis avisos creados</Title>
      <Grid container spacing={2}>
        {saveListFlowData.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <InfoCard data={item} />
          </Grid>
        ))}
      </Grid>
    </ContainerSuccess>
  );
};

export default DetailsCardListCities;
