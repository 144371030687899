import { BASE_URL_IMAGE } from '@/config/config';
import {
  MY_APPLICATIONS_CUSTOMER_REQUEST_PATH,
  REQUEST_MAIN_EXPRESS_CANCEL_PATH,
} from '@/pages/customerRequest/shared/consts/routes';
import { NOTICE_OFF_IMAGE } from '@/consts/defaultConsts';
import {
  PROFILE_NOTICE_LIST_PATH,
  PROFILE_NOTICE_OFF,
} from '@/consts/typeServicesPaths';

// NOTICE_OFF_IMAGE and CUSTOMREQUEST_CANCEL_IMAGE
export const dataByUrl = {
  [PROFILE_NOTICE_OFF]: {
    image: BASE_URL_IMAGE + NOTICE_OFF_IMAGE,
    title: '¡Aviso desactivado!',
    subTitle: 'Puedes activarlo en el historial de tus avisos.',
    buttonText: 'REGRESAR A MIS AVISOS',
    buttonNavigate: PROFILE_NOTICE_LIST_PATH,
  },
  [REQUEST_MAIN_EXPRESS_CANCEL_PATH]: {
    image: BASE_URL_IMAGE + NOTICE_OFF_IMAGE,
    title: '¡Solicitud cancelada!',
    subTitle:
      'Cuando desees, puedes volver a solicitar el servicio desde el historial.',
    buttonText: 'ver mis solicitudes',
    buttonNavigate: MY_APPLICATIONS_CUSTOMER_REQUEST_PATH,
  },
};
