import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import IconsSvg from "./IconsSvg";

type Props = {
  icon: string | FC;
  title: string;
  value: string;
};

type DetailItemOptionProps = {
  props: Props;
};

const DetailItemOption = ({ props }: DetailItemOptionProps) => {
  const { icon, title, value } = props;

  return (
    <Grid container gap={1} alignItems="center" marginBottom="6px">
      <IconsSvg icon={icon} sx={{ color: "#757575" }} />
      <Typography variant="label2">{title}</Typography>
      <Typography variant="body2">{value}</Typography>
    </Grid>
  );
};

export default DetailItemOption;
