import DetailsTextV2 from '@/components/DetailsTextV2/Index';
import ConditionsListOffert from '@/components/conditionsList/ConditionsListOffert';
import { descriptionLoad, districtsDeliveryText } from '@/consts/noticeConsts';
import {
  getMaxWeightVehicleRequest,
  getTypeVehicleAndPlateRequest,
} from '@/pages/createRequestNegotiation/CreateRequestNegSuccess/utils/bodyUtils';
import DetailsTextMoving from '@/pages/customerRequest/application/components/DetailsTextMoving';
import { ConfigDataBody } from '@/pages/customerRequest/application/config';
import InlineTextDetails from '@/pages/customerRequest/components/InlineTextDetails';
import { ImagesNegV2 } from '@/pages/noticeV2Negotation/components/ImagesNegV2';
import { Typography } from '@mui/material';
import BodyConfigNegAccept from './components/BodyConfigNegAccept';
import SingleUorTFinishedRequest from './components/SingleUorTFinishedRequest';
import {
  changeTitleByNegRequest,
  getEmailInRating,
  getFormatMetroNegRequest,
  getImageTruckInRatingNegRequest,
  getImagesNegRequest,
  getNumberPhoneInRating,
  getUrlAvatarProfileRequest,
  getUserInfoNegRequest,
  getWhatsappInRating,
  renderTitleLongDestinyNegRequest,
  renderTitleLongOriginNegRequest,
  validUserNegRequest,
} from './utils/bodyUtils';
import DetailsText from '@/pages/customerRequest/components/DetailsText';
import { handleFormatSendDateRequestNeg } from '@/pages/customerRequest/application/utils/utilsApplication';

export const objectRenderComponentFinishedAccept: Record<
  number,
  () => JSX.Element
> = {
  1: BodyConfigNegAccept,
  2: BodyConfigNegAccept,
  3: BodyConfigNegAccept,
  4: BodyConfigNegAccept,
  5: BodyConfigNegAccept,
};

export const configData: ConfigDataBody[] = [
  {
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
      sx: { marginTop: '10px', display: 'block', fontSize: '18px' },
      title: 'Detalles del acuerdo',
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'title',
        defaultResult: '',
        callback: changeTitleByNegRequest,
      },
    ],
  },
  {
    Component: SingleUorTFinishedRequest,
    props: {
      containerSx: { mt: '16px' },
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'urlProfile',
        defaultResult: '',
        callback: getUrlAvatarProfileRequest,
      },
      {
        property: 'cliente',
        componentProp: 'showTwoAvatars',
        defaultResult: '',
        callback: validUserNegRequest,
      },
      {
        property: 'cliente',
        componentProp: 'urlTruck',
        defaultResult: '',
        callback: getImageTruckInRatingNegRequest,
      },
      {
        property: 'cliente',
        componentProp: 'userInfo',
        defaultResult: '',
        callback: getUserInfoNegRequest,
      },
      {
        property: 'cliente',
        componentProp: 'userName',
        defaultResult: '',
        callback: getNumberPhoneInRating,
      },
      {
        property: 'cliente',
        componentProp: 'userEmail',
        defaultResult: '',
        callback: getEmailInRating,
      },
      {
        property: 'whatsapp',
        componentProp: 'whatsapp',
        defaultResult: '',
        callback: getWhatsappInRating,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: 'Detalles del acuerdo',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha del servicio',
      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
        callback: handleFormatSendDateRequestNeg,
      },
      {
        property: 'fecha',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: handleFormatSendDateRequestNeg,
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'origenTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referencia',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-dark',
      title: 'Destino',
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'destinoTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  // icon-truck
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'vehiculo',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'vehiculo',
        componentProp: 'origen',
        defaultResult: '',
        callback: getTypeVehicleAndPlateRequest,
      },
      {
        property: 'vehiculo',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getMaxWeightVehicleRequest,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: descriptionLoad,
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: ImagesNegV2,
    props: {
      style: { marginTop: '13px' },
    },
    valid: [
      {
        property: 'fotos',
        componentProp: 'images',
        defaultResult: [],
        callback: getImagesNegRequest,
      },
    ],
  },

  {
    Component: Typography,
    props: {
      variant: 'body1',
      marginTop: '4px',
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
  {
    Component: InlineTextDetails,
    props: {
      icon: 'icon-kilos',
      title: 'Peso estimado: ',
    },
    valid: [
      {
        property: 'pesoEstimado',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'pesoEstimado',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  // condiciones de la solicitud
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '16px' },
    },
    valid: [
      {
        property: 'condiciones',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'condiciones',
        componentProp: 'listServices',
        defaultResult: [],
      },
    ],
  },
];

// MULTIDESTINY

export const multiDestinyConfigData: ConfigDataBody[] = [
  {
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
      sx: { marginTop: '10px', display: 'block', fontSize: '18px' },
      title: 'Detalles del acuerdo',
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'title',
        defaultResult: '',
        callback: changeTitleByNegRequest,
      },
    ],
  },
  {
    Component: SingleUorTFinishedRequest,
    props: {
      containerSx: { mt: '16px' },
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'urlProfile',
        defaultResult: '',
        callback: getUrlAvatarProfileRequest,
      },
      {
        property: 'cliente',
        componentProp: 'showTwoAvatars',
        defaultResult: '',
        callback: validUserNegRequest,
      },
      {
        property: 'cliente',
        componentProp: 'urlTruck',
        defaultResult: '',
        callback: getImageTruckInRatingNegRequest,
      },
      {
        property: 'cliente',
        componentProp: 'userInfo',
        defaultResult: '',
        callback: getUserInfoNegRequest,
      },
      {
        property: 'cliente',
        componentProp: 'userName',
        defaultResult: '',
        callback: getNumberPhoneInRating,
      },
      {
        property: 'cliente',
        componentProp: 'userEmail',
        defaultResult: '',
        callback: getEmailInRating,
      },
      {
        property: 'whatsapp',
        componentProp: 'whatsapp',
        defaultResult: '',
        callback: getWhatsappInRating,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: 'Detalles del acuerdo',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha del servicio',
      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
        callback: handleFormatSendDateRequestNeg,
      },
      {
        property: 'fecha',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: handleFormatSendDateRequestNeg,
      },
    ],
  },
  {
    Component: InlineTextDetails,
    props: {
      icon: 'icon-location-light',
      title: `${districtsDeliveryText}: `,
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'origenTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'vehiculo',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'vehiculo',
        componentProp: 'origen',
        defaultResult: '',
        callback: getTypeVehicleAndPlateRequest,
      },
      {
        property: 'vehiculo',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getMaxWeightVehicleRequest,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: descriptionLoad,
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: ImagesNegV2,
    props: {
      style: { marginTop: '13px' },
    },
    valid: [
      {
        property: 'fotos',
        componentProp: 'images',
        defaultResult: [],
        callback: getImagesNegRequest,
      },
    ],
  },

  {
    Component: Typography,
    props: {
      variant: 'body1',
      marginTop: '4px',
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
  {
    Component: InlineTextDetails,
    props: {
      icon: 'icon-kilos',
      title: 'Peso estimado: ',
    },
    valid: [
      {
        property: 'pesoEstimado',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'pesoEstimado',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },

  // condiciones de la solicitud
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '16px' },
    },
    valid: [
      {
        property: 'condiciones',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'condiciones',
        componentProp: 'listServices',
        defaultResult: [],
      },
    ],
  },
];

// BETWEEN CITIES

export const betweenCitiesconfigData: ConfigDataBody[] = [
  {
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
      sx: { marginTop: '10px', display: 'block', fontSize: '18px' },
      title: 'Detalles del acuerdo',
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'title',
        defaultResult: '',
        callback: changeTitleByNegRequest,
      },
    ],
  },
  {
    Component: SingleUorTFinishedRequest,
    props: {
      containerSx: { mt: '16px' },
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'urlProfile',
        defaultResult: '',
        callback: getUrlAvatarProfileRequest,
      },
      {
        property: 'cliente',
        componentProp: 'showTwoAvatars',
        defaultResult: '',
        callback: validUserNegRequest,
      },
      {
        property: 'cliente',
        componentProp: 'urlTruck',
        defaultResult: '',
        callback: getImageTruckInRatingNegRequest,
      },
      {
        property: 'cliente',
        componentProp: 'userInfo',
        defaultResult: '',
        callback: getUserInfoNegRequest,
      },
      {
        property: 'cliente',
        componentProp: 'userName',
        defaultResult: '',
        callback: getNumberPhoneInRating,
      },
      {
        property: 'cliente',
        componentProp: 'userEmail',
        defaultResult: '',
        callback: getEmailInRating,
      },
      {
        property: 'whatsapp',
        componentProp: 'whatsapp',
        defaultResult: '',
        callback: getWhatsappInRating,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: 'Detalles del acuerdo',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha del servicio',
      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
        callback: handleFormatSendDateRequestNeg,
      },
      {
        property: 'fecha',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: handleFormatSendDateRequestNeg,
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'origenTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referencia',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-light',
      title: 'Destino',
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'destinoTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  // icon-truck
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'vehiculo',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'vehiculo',
        componentProp: 'origen',
        defaultResult: '',
        callback: getTypeVehicleAndPlateRequest,
      },
      {
        property: 'vehiculo',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getMaxWeightVehicleRequest,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: descriptionLoad,
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: ImagesNegV2,
    props: {
      style: { marginTop: '13px' },
    },
    valid: [
      {
        property: 'fotos',
        componentProp: 'images',
        defaultResult: [],
        callback: getImagesNegRequest,
      },
    ],
  },

  {
    Component: Typography,
    props: {
      variant: 'body1',
      marginTop: '4px',
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
  {
    Component: InlineTextDetails,
    props: {
      icon: 'icon-kilos',
      title: 'Peso estimado: ',
    },
    valid: [
      {
        property: 'pesoEstimado',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'pesoEstimado',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },

  // condiciones de la solicitud
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '16px' },
    },
    valid: [
      {
        property: 'condiciones',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'condiciones',
        componentProp: 'listServices',
        defaultResult: [],
      },
    ],
  },
];

// MOVING

export const configDataCustomRequestMoving: ConfigDataBody[] = [
  {
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
      sx: { marginTop: '10px', display: 'block', fontSize: '18px' },
      title: 'Detalles del acuerdo',
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'title',
        defaultResult: '',
        callback: changeTitleByNegRequest,
      },
    ],
  },
  {
    Component: SingleUorTFinishedRequest,
    props: {
      containerSx: { mt: '16px' },
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'urlProfile',
        defaultResult: '',
        callback: getUrlAvatarProfileRequest,
      },
      {
        property: 'cliente',
        componentProp: 'showTwoAvatars',
        defaultResult: '',
        callback: validUserNegRequest,
      },
      {
        property: 'cliente',
        componentProp: 'urlTruck',
        defaultResult: '',
        callback: getImageTruckInRatingNegRequest,
      },
      {
        property: 'cliente',
        componentProp: 'userInfo',
        defaultResult: '',
        callback: getUserInfoNegRequest,
      },
      {
        property: 'cliente',
        componentProp: 'userName',
        defaultResult: '',
        callback: getNumberPhoneInRating,
      },
      {
        property: 'cliente',
        componentProp: 'userEmail',
        defaultResult: '',
        callback: getEmailInRating,
      },
      {
        property: 'whatsapp',
        componentProp: 'whatsapp',
        defaultResult: '',
        callback: getWhatsappInRating,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: 'Detalles del acuerdo',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha del servicio',
      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
        callback: handleFormatSendDateRequestNeg,
      },
      {
        property: 'fecha',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: handleFormatSendDateRequestNeg,
      },
    ],
  },
  {
    Component: DetailsTextMoving,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'referencia',
        componentProp: 'originDescription',
        defaultResult: '',
        // callback: renderTitleLongOrigin,
        callback: renderTitleLongOriginNegRequest,
      },
    ],
  },
  {
    Component: DetailsTextMoving,
    props: {
      icon: 'icon-location-dark',
      title: 'Destino',
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
        // callback: renderTitleLongDestiny,
        callback: renderTitleLongDestinyNegRequest,
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-metter',
      title: 'Área',
    },
    valid: [
      {
        property: 'metraje',
        componentProp: 'show',
        defaultResult: false,
      },

      {
        property: 'metraje',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'metraje',
        componentProp: 'origen',
        defaultResult: '',
        callback: getFormatMetroNegRequest,
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'vehiculo',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'vehiculo',
        componentProp: 'origen',
        defaultResult: '',
        callback: getTypeVehicleAndPlateRequest,
      },
      {
        property: 'vehiculo',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getMaxWeightVehicleRequest,
      },
    ],
  },
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '16px' },
    },
    valid: [
      {
        property: 'condiciones',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'condiciones',
        componentProp: 'listServices',
        defaultResult: [],
      },
    ],
  },
];
