import { MyNegotiationes } from "@/models/userAuth/agrementToAccept";
import { currencyFormat } from "@/utils/helpers";
import { Box, CardActions, Typography, styled } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Price = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  fontWeight: 700,
  fontFamily: theme.typography.h5.fontFamily,
}));

const PriceDetail = styled(Typography)(() => ({
  color: "#757575",
  fontSize: "12px",
  paddingLeft: "4px",
}));

const Container = styled(CardActions)(() => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "16px",
  paddingTop: 0,
}));

type Props = {
  data: MyNegotiationes;
};

const CardFooter = ({ data }: Props) => {
  return (
    <Container disableSpacing>
      <Box display="flex" alignItems="center">
        {!!data.precio && (
          <>
            <Price variant="body2">{currencyFormat(data.precio)}</Price>

            <PriceDetail>{data?.precioDetalle}</PriceDetail>
          </>
        )}
      </Box>

      <ArrowForwardIcon sx={{ color: "#FF6700" }} />
    </Container>
  );
};

export default CardFooter;
