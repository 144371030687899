import {
  ConatinerDetails,
  Title,
} from "@/pages/flowNoticeNegotation/styles/styles";
import Icon, { IconTypes } from "@/utils/Icon";
import { Stack, SxProps, Theme, Typography } from "@mui/material";

export type Props = {
  title: string;
  originDescription: string;
  icon: string;
  capitalizeText: string;
  hoursAmount: string;
  containerSx?: SxProps<Theme>;
  sxDescription?: SxProps<Theme>;
  parent?: boolean;
  optionalSign?: string
};

const vehicleTextType = 'Vehículo'

const DefaultFormat = ({
  title,
  icon,
  originDescription,
  capitalizeText,
  hoursAmount,
  containerSx,
  sxDescription,
  parent=true,
  optionalSign=":"
}: Props) => {

  const handleOriginDescription = () => {
    if(title !== vehicleTextType && parent) return {
      styles: { maxWidth: "320px"},
      originDescription: `(${originDescription})`
    }

    return {
      styles: { maxWidth: "320px", color: "#696969", fontSize: "14px"},
      originDescription
    }

  }

  return (
    <ConatinerDetails sx={{...containerSx, alignItems: "flex-start"}}>
      <Stack flexDirection="row" alignItems="center">
        <Icon name={icon as IconTypes} size="md" />
        <Title variant="label1">{!!title && `${title}${optionalSign} `}</Title>
      </Stack>

      <Stack flex={1} marginLeft="4px">
        <Typography
          variant="body1"
          sx={{ wordWrap: "break-word", maxWidth: "320px" }}
        >
          {capitalizeText}
        </Typography>

        {originDescription && (
          <Typography
            variant="body1"
            sx={{ ...handleOriginDescription()?.styles, wordWrap: "break-word", ...sxDescription }}
          >
            {handleOriginDescription()?.originDescription}{title === hoursAmount && ""}
          </Typography>
        )}
      </Stack>
    </ConatinerDetails>
  );
};

export default DefaultFormat;
