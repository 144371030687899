import {
  Container,
  styled,
  SxProps,
  experimental_sx as sx,
  Theme,
  Box,
} from "@mui/material";
import { FC } from "react";

const ContainerLayout = styled(Container)(
  sx({
    maxWidth: "360px !important",
    display: { lg: "flex" },
    alignItems: { lg: "center" },
    justifyContent: { lg: "center" },
  })
);
const ContainerBody = styled(Box)(
  sx({ width: "100%", padding: { xs: "16px", sm: "32px 16px" } })
);
type Props = {
  children: JSX.Element | JSX.Element[];
  sx?: SxProps<Theme>;
};

const LayoutFlow: FC<Props> = ({ children, sx }) => {
  return (
    <ContainerLayout disableGutters sx={sx}>
      <ContainerBody>{children}</ContainerBody>
    </ContainerLayout>
  );
};

export default LayoutFlow;
