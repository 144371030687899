import { CelebrationIlustrationSvg } from "@/assets/svg";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { closeAgreement, textCongratulation, textDescriptionsSolicitud } from "@/consts/noticeConsts";
import { Box, Container, Stack, Typography } from "@mui/material";
import useAgreementMessageHook from "./hook";


const AgreementMessage = () => {
  
  const { handleButton, textToRender } = useAgreementMessageHook();

  return (
    <>
      <Container maxWidth="xs">
      <Stack gap={2} alignItems={"center"} justifyContent={"center"} mt={5}>
        <CelebrationIlustrationSvg />

        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} mt={3}>
          <Typography variant="h5low" sx={{ fontWeight: 700 }}>
            {textCongratulation}
          </Typography>

          <Typography variant="h5low" sx={{ fontWeight: 700 }}>
            {closeAgreement}
          </Typography>
        </Box>

        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            fontWeight: 400,
          }}
        >
          {textDescriptionsSolicitud}
        </Typography>

        <ButtomLoader
          fullWidth
          variant="contained"
          type="submit"
          onClick={() => handleButton()}
        >
          {`contactar ${textToRender}`}
        </ButtomLoader>
      </Stack>
    </Container>
      
    </>
  );
};

export default AgreementMessage;
