import { IMG_PATH } from "@/consts/defaultConsts";
import {
  UserSharedRide,
  VehicleSharedRide,
} from "@/models/shareTravel/shareTravelModel";
import {
  Avatar,
  Box,
  CardContent,
  Grid,
  Rating,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React from "react";
import StarIcon from "@mui/icons-material/Star";
import CardSectionVehicle from "./CardSectionVehicle";

type Props = {
  user: UserSharedRide;
  vehicle?: VehicleSharedRide;
};

const Conatainer = styled(CardContent)(
  ({ theme }) => `
    padding: 8px 16px;
    background-color: ${theme.palette.cardbg?.disabled};
  `
);

const ContainerAvatar = styled(Grid)(
  () => `
    margin-right: 8px;
    display: flex;
    align-items: center;
  `
);

const BoxRating = styled(Box)(
  () => `
    display: flex;
    flex-direction: column;
    flex: 1;
  `
);

const CardSectionHeader: React.FC<Props> = ({ user, vehicle }) => {
  const { palette } = useTheme();

  const colorStar = (puntaje: number) => {
    if (puntaje > 0) {
      return palette.cardbg?.primary;
    }
    return palette.cardbg?.secondary;
  };

  return (
    <Conatainer>
      <Grid container>
        <Grid xs={12} flexDirection={"row"} display={"flex"}>
          <ContainerAvatar>
            <Avatar src={`${IMG_PATH}${user?.fotoAvatar}`} />
          </ContainerAvatar>
          <BoxRating>
            <Typography variant="body1">{user.nombre}</Typography>
            <Box display={"flex"}>
              <Rating
                readOnly
                name="customized-10"
                defaultValue={2}
                max={1}
                size="small"
                sx={{
                  color: colorStar(user.puntaje),
                }}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
              <Typography
                variant="caption"
                sx={{ color: palette.cardbg?.secondary }}
              >
                {" "}
                {user.puntaje} ({user.calificaciones}){" "}
              </Typography>
            </Box>

            <CardSectionVehicle vehicle={vehicle} user={user} />
          </BoxRating>
        </Grid>
      </Grid>
    </Conatainer>
  );
};

export default CardSectionHeader;
