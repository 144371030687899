import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import SearchModal from "@/components/modals/searchModal/SearchModal";
import { MODAL_TYPE } from "@/components/modals/searchModalLocationOffert/configDataModal";
import { ID_COUNTEROFFER } from "@/consts/defaultConsts";
import { textAceptarContactar, textSiguiente } from "@/consts/noticeConsts";
import { useAppSelector } from "@/hooks/useReducer";
import {
  DestinisAPI,
  NoticeDetailAuctionModel,
} from "@/models/notice/noticeModels";
import { FormControl } from "@mui/material";
import FormOffert from "../../../../../components/FormOffer";
import { configFormOffert } from "../configData";
import { useRuteAuctionBtnCities } from "./useAuctionBtnCities";

const RuteAuction = () => {
  const {
    noticeV2ClientAorCO: {
      isLoading,
      selectedUbications,
      selectTypeNotice,
      idTypeNoticeNegotiation,
    },
  } = useAppSelector((state) => state);
  const isCounterOffer = idTypeNoticeNegotiation === ID_COUNTEROFFER;

  const { origin, destiny } = configFormOffert;
  const notice = selectTypeNotice as NoticeDetailAuctionModel;

  const {
    modalType,
    openModal,
    setOpenModal,
    modalTitle,
    searchList,
    form,
    handleInputClick,
    handleTitleSelected,
    handleSubmit,
    handleOnChange,
    handleSetValue,
    handleChangeModal,
    handleSubmitModal,
    handleDisabled,
  } = useRuteAuctionBtnCities();

  return (
    <div>
      <SearchModal
        open={openModal}
        onClose={setOpenModal}
        onSubmit={handleSubmitModal}
        onChange={handleChangeModal}
        list={searchList}
        title={modalTitle}
        inputProps={
          modalType === MODAL_TYPE.origin ? origin.modal : destiny.modal
        }
      />

      <FormOffert
        title={handleTitleSelected("Origen", notice?.origentexto || "")}
        input1={{
          ...origin.input1,
          value: handleSetValue(selectedUbications?.origin as DestinisAPI),
          show: true,
        }}
        input2={{
          ...origin.input2,
          value: form.originReference,
          show: true,
        }}
        onClick={handleInputClick}
        onChange={handleOnChange}
      />

      <FormOffert
        title={handleTitleSelected("Destino", notice?.destinotexto || "")}
        input1={{
          ...destiny.input1,
          label: destiny.input1.label,
          placeholder: destiny.input1.placeholder,
          value: handleSetValue(selectedUbications?.destiny as DestinisAPI),
          show: true,
        }}
        input2={{
          ...destiny.input2,
          value: form.destinyReference,
          show: true,
        }}
        onClick={handleInputClick}
        onChange={handleOnChange}
      />

      <FormControl fullWidth>
        <ButtomLoader
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={handleDisabled() || isLoading}
        >
          {isCounterOffer ? textSiguiente : textAceptarContactar}
        </ButtomLoader>
      </FormControl>
    </div>
  );
};

export default RuteAuction;
