import { styled } from "@mui/material/styles";
import {
  Button,
  Toolbar,
  Menu,
  MenuItem,
  Grid,
  Badge,
  BadgeProps,
} from "@mui/material";
import { Link, LinkProps, NavLink } from "react-router-dom";
import { thirdFontFamily } from "@/styles/theme";

export const Enter = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.button.fontFamily,
  lineHeight: "30px",
  color: "#fff",
  borderRadius: "28px",
  border: "1px solid",
  padding: "0 16px",
}));

export const MenuOp = styled(Toolbar)(({ theme }) => ({
  backgroundColor: "#fff",
  height: "100vh",
  color: theme.typography.body1.color,
  display: "block",
  overflowY: "hidden",
}));

export const Redirect = styled(NavLink)(({ theme }) => ({
  fontSize: theme.typography.button.fontSize,
  lineHeight: theme.typography.button.lineHeight,
  padding: "10px",
  color: "white",
  textDecoration: "none",
  fontFamily: theme.typography.button.fontFamily,
  textTransform: "uppercase",
  "&:hover": {
    color: theme.palette.org.main,
  },
  "&&.active": {
    color: theme.palette.org.main,
    borderBottom: "3px solid",
  },
}));

export const RedirectDisplay = styled(Link)(({ theme }) => ({
  fontSize: theme.typography.button.fontSize,
  lineHeight: theme.typography.button.lineHeight,
  textDecoration: "none",
  fontWeight: 370,
  color: "black",
  fontFamily: theme.typography.button.fontFamily,
  justifyContent: "center",
  height: "56px",
  display: "flex",
  gap: 2,
  alignItems: "center",
}));

export const ButtonStyled = styled("a")(({ theme }) => ({
  color: "white",
  width: "100%",
  fontWeight: "700",
  backgroundColor: theme.palette.warning.main,
  borderRadius: "44px",
  marginTop: "16px",
  textDecoration: "none",
  padding: "16px",
  textAlign: "center",
  textTransform: "uppercase",
  fontFamily: thirdFontFamily,
  fontSize: "16px",
}));

export const RedirectNotifi = styled(Redirect)(({ theme }) => ({
  padding: "5px",
  "&.active": {
    border: "none",
    color: "white",
  },
}));

export const ButtonMenu = styled(Grid)(({ theme }) => ({
  height: "43px",
  cursor: "pointer",
  fontSize: theme.typography.button.fontSize,
  lineHeight: theme.typography.button.lineHeight,
  padding: "0px 8px",
  color: "white",
  textDecoration: "none",
  fontFamily: theme.typography.button.fontFamily,
  textTransform: "uppercase",
  gap: 10,
  alignItems: "center",
  "&:hover": {
    color: theme.palette.org.main,
  },
  "&&.active": {
    color: theme.palette.org.main,
    borderBottom: "3px solid",
  },
}));

export const MenuHeader = styled(Menu)(() => ({
  ".MuiList-root": {
    padding: "0px",
  },
}));

export const MenuHeaderItem = styled(MenuItem)(() => ({
  height: "48px",
}));

export const RedirectMenu = styled(Link)<LinkProps>(({ theme }) => ({
  fontFamily: theme.typography.body1.fontFamily,
  fontWeight: theme.typography.body1.fontWeight,
  fontSize: theme.typography.body1.fontSize,
  lineHeight: theme.typography.body1.lineHeight,
  padding: "18px 16px",
  color: "rgba(0, 0, 0, 0.87)",
  textDecoration: "none",
  "&:hover": {
    color: theme.palette.primary.main,
  },
  "&&.active": {
    color: theme.palette.org.main,
  },
  width: "100%",
  height: "100%",
}));

export const MenuHeaderAccount = styled(MenuHeaderItem)(({ theme }) => ({
  minWidth: "280px",
  height: "75px",
  backgroundColor: theme.palette.warning.main,
  borderRadius: "0 0 6px 6px",
  "&:hover": {
    backgroundColor: theme.palette.warning.main,
  },
}));

export const MenuHeaderAccountItem = styled(MenuHeaderItem)(() => ({
  height: "56px",
  paddingLeft: "0px",
  paddingRight: "0px",
  paddingTop: "0px",
  paddingBottom: "0px",
}));

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -15,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));
