import ConditionsList from "@/components/conditionsList/ConditionsList";
import ImgCarousel from "@/components/viewService/imgCarousel";
import {
  HOME_PAHT,
  PROFILE_NOTICE_LIST_PATH,
  PROFILE_NOTICE_OFFERT,
  SERVICES_LANDING_PATH,
} from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import {
  NoticeDetailInCityModel,
  NoticeDetailMultiDestinyModel,
} from "@/models/notice/noticeModels";
import { UsuarioAPI } from "@/models/userAuth/user";
import { PhotosVehicleAPI } from "@/models/vehicle/vehicleModel";

import { prepareLinkNoticeOffersAdapter } from "@/adapters/routesAdapters";
import { NOTICE_NOT_FOUND } from "@/consts/defaultConsts";
import { ERROR_CANT_OFFER } from "@/consts/textErrors";
import { LocationDetailState } from "@/interfaces/detailNoticeInterface";
import { resetDetailsNoticeSlice } from "@/store/detailsNotice/detailsNoticeSlice";
import {
  getDetailsNoticeInCityByIdThunk,
  getNoticeByIdPrivateThunk,
} from "@/store/noticeRedux/publicNoticeThunks";
import { firstLetterUpperCase, vehicleIsActive } from "@/utils/helpers";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import SkeletonViewService from "../loading/SkeletonViewService";
import NotFound from "../notFound/NotFound";
import ArrowBack from "../viewService/city/components/ArrowBack";
import { cityData } from "../viewService/city/rulesByFlow";
import { ConditionsCard } from "../viewService/city/styleViewService";
import AlertIsOwner from "./components/AlertIsOwner";
import ButtonNegotiation from "./components/ButtonNegotiation";
import LastSectionButtons from "./components/LastSectionButtons";
import useDetailNotice from "./hook";
import { saveDataReturnPathThunk } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";

const typeNotice: Record<string, string> = {
  "en-la-ciudad": "Transporte+en+la+ciudad",
  multidestino: "Multidestino+en+la+ciudad",
  "entre-ciudades": "Transporte+entre+ciudades",
  remate: "Remate+entre+ciudades",
  mudanza: "Mudanza+en+la+ciudad",
};

interface StateNotice extends NoticeDetailMultiDestinyModel {
  flgciudadoentreciudades?: number;
}

const DetailNotice = () => {
  const {
    user: { user },
    detailsNoticeSlice: { isLoading, error, userMessage },
  } = useAppSelector((state) => state);
  const { handleGetCurrentDetailNotice, getConditionsInViewDetails } = useDetailNotice();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const { id = "" } = useParams();

  const currentUser = user as UsuarioAPI;

  const locationState = location?.state as LocationDetailState;
  const isPrivatePath = location.pathname.startsWith(PROFILE_NOTICE_LIST_PATH);

  const notice = useMemo(() => handleGetCurrentDetailNotice(), [isLoading]);


  useEffect(() => {
    dispatch(resetDetailsNoticeSlice());
    
    if (isPrivatePath) {
      dispatch(getNoticeByIdPrivateThunk(id)).catch(handleredirectPublic);
    } else {
      dispatch(getDetailsNoticeInCityByIdThunk(id));
      if (!currentUser?.id) {
        const data = {
          path: location.pathname,
          state: locationState,
        };
        dispatch(saveDataReturnPathThunk(data));
      }
    }

    return () => {};
  }, [location.pathname]);

  const handleNavigationBack = () => {
    navigate(-1);
  };

  const handleredirectPublic = (e: Error) => {
    if ((e.cause as Error)?.message == "401") {
      const path = location.pathname.replace(
        PROFILE_NOTICE_LIST_PATH,
        SERVICES_LANDING_PATH
      );
      navigate(path);
    }
  };

  const handlePhotosOftheVehicle = () => {
    let emptyPhotos: PhotosVehicleAPI[] = [];
    if (!notice) return emptyPhotos;
    const { vehiculo } = notice;
    if (!vehicleIsActive(vehiculo)) {
      return emptyPhotos;
    }

    return vehiculo?.vehiculofotos || emptyPhotos;
  };

  const handlePreparaLinkNotFound = () => {
    const { pathname } = location;
    const noticeType = pathname.split("/").splice(-2, 1).join();
    const queryType = ":typeNotice";
    const queryParamTemplate = `?typeNotice=${queryType}`;
    const typeNoticeParam = firstLetterUpperCase(typeNotice[noticeType] || "");
    if (!typeNoticeParam) {
      return HOME_PAHT;
    }
    const navigatePath = `${HOME_PAHT}${queryParamTemplate.replace(
      queryType,
      typeNoticeParam
    )}`;

    return navigatePath;
  };

  const handleShowAlertOwner = () => {
    const { id = 0 } = currentUser;
    const { transportista } = notice as NoticeDetailInCityModel;
    const message = ERROR_CANT_OFFER;
    if (!isPrivatePath && transportista?.id === id) {
      return message;
    }

    return "";
  };

  const handleGetLinkNegotiation = () => {
    const { tipoaviso, id } = notice as NoticeDetailInCityModel;

    const path = prepareLinkNoticeOffersAdapter(
      PROFILE_NOTICE_OFFERT,
      tipoaviso.id,
      id
    );

    return path;
  };

  const handlePrepareStateButtonNegotiation = () => {
    const {
      tipoaviso,
      origentexto: originText,
      destinotexto: destinyText,
      vehiculo,
      flghoraopunto,
      flgciudadoentreciudades,
    } = notice as StateNotice;
    const { id: idTypeNotice, nombre: typeNotice } = tipoaviso;
    let plate = "";
    let vehicle = "";
    if(vehiculo){
      const {
        placa,
        tipovehiculo: { nombre: namev },
      } = vehiculo;
      plate = placa || "";
      vehicle = namev || "";
    }

    return {
      plate,
      vehicle,
      idTypeNotice,
      typeNotice,
      originText,
      destinyText,
      flghoraopunto,
      flgciudadoentreciudades,
    };
  };

  const photosVehicle = useMemo(() => handlePhotosOftheVehicle(), [notice]);

  if (!isLoading && !!error) {
    return (
      <NotFound
        title="¡Lo sentimos!"
        description="El aviso no está disponible."
        buttonText="Ir al inicio"
        buttonLink={handlePreparaLinkNotFound()}
        image={NOTICE_NOT_FOUND}
      />
    );
  }

  if (!notice || isLoading) return <SkeletonViewService />;

  const messageAlert = userMessage || handleShowAlertOwner() ;

  const buttonItems = isPrivatePath
    ? cityData.buttonsPrivateList
    : cityData.buttonsPublicList;

  const displayButtonNegotiation = isPrivatePath ? "flex" : "none";
  const stateNegotiation = handlePrepareStateButtonNegotiation();

  return (
    <Container
      disableGutters
      maxWidth="xl"
      sx={{
        marginBottom: "16px",
        padding: { xs: "0px 0px", md: "0px 24px", lg: "0px 32px" },
      }}
    >
      <Box sx={{ mb: 5 }}>
        <ArrowBack
          show={!!locationState?.hasBackNavigate}
          onClick={handleNavigationBack}
        />
      </Box>
      <AlertIsOwner message={messageAlert} />

      {/* CARROUSEL RESPONSIVE */}
      <ImgCarousel listCarrousel={photosVehicle} />

      <Grid
        container
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ xs: "center", md: "flex-start" }}
        alignItems={{ xs: "center", md: "flex-start" }}
      >
        <Grid item md={7.5}>
          <Stack
            sx={{
              maxWidth: { xs: 360, md: "328px" },
              padding: { xs: "32px 16px", md: "32px 16px 32px 0px" },
            }}
            gap={2}
          >
            <Outlet />

            <ConditionsCard>
              <Typography variant="subtitle1medium">
                Condiciones del servicio
              </Typography>
              <ConditionsList listServices={getConditionsInViewDetails(notice.avisocondiciones)} />
            </ConditionsCard>

            <LastSectionButtons
              data={notice}
              buttonsItems={buttonItems}
              typeContainer="stack"
            >
              <ButtonNegotiation
                link={handleGetLinkNegotiation()}
                sx={{ marginTop: "16px", display: displayButtonNegotiation }}
                state={stateNegotiation}
              />
            </LastSectionButtons>
          </Stack>
        </Grid>

        {/* RESPONSIVE DERECHO */}

        <LastSectionButtons data={notice} buttonsItems={buttonItems}>
          <ButtonNegotiation
            link={handleGetLinkNegotiation()}
            sx={{ marginTop: "16px", display: displayButtonNegotiation }}
            state={stateNegotiation}
          />
        </LastSectionButtons>
      </Grid>
    </Container>
  );
};

export default DetailNotice;
