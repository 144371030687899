import LinkButton from "@/components/linkButton/LinkButton";
import { Box, SxProps, Theme } from "@mui/material";
import React from "react";

const DEFAULT_TEXT = "NEGOCIACIONES DEL AVISO";
type Props = {
  link: string;
  text?: string;
  sx?: SxProps<Theme>;
  state?: any;
};

const ButtonNegotiation = ({ link, sx, state, text = DEFAULT_TEXT }: Props) => {
  return (
    <Box sx={sx} display="flex" alignItems="center" justifyContent="center">
      <LinkButton to={link} style={{ textAlign: "center" }} state={state}>
        {" "}
        {text}{" "}
      </LinkButton>
    </Box>
  );
};

export default ButtonNegotiation;
