import { theme } from "@/styles/theme";
import {
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { Information, SubTitle, Title } from "./styleTermsConditionsExpress";
import { Link } from "react-router-dom";
import { CONTACT_US_LANDING_PATH } from "@/consts/typeServicesPaths";

const TermsConditionsExpress = () => {
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Container
      maxWidth={matches ? "xl" : "xs"}
      sx={{ marginTop: "35px", mb: 8 }}
    >
      <Title>Condiciones de uso de PegaCargo Express </Title>

      <Information>
        Estos Términos de uso (“Términos”) rigen su uso del servicio PegaCargo
        Express de las aplicaciones móviles, sitios web, productos, contenido,
        instalaciones y plataforma de PegaCargo (denominada, la “Plataforma”).
      </Information>

      <Information>
        Estos Términos de uso de PegaCargo Express son parte integral de
        los Términos de uso generales . Al utilizar el servicio PegaCargo
        Express, usted expresa su pleno acuerdo con estos Términos y las
        Condiciones Generales de Uso. En caso de cualquier conflicto o
        inconsistencia entre las disposiciones de estos Términos y las
        Condiciones Generales de Uso, se aplicarán las disposiciones de estos
        Términos.
      </Information>
      <Information>
        Usted pacta un contrato con nosotros (“PegaCargo”, “nosotros”, “nos” o
        “nuestro”) cuando confirma que acepta estos Términos o cuando utiliza la
        Plataforma de otro modo.{" "}
      </Information>
      <Information>
        El uso de la Plataforma como Transportista puede estar sujeto a los
        Términos de uso del Transportista designado. Nuestras prácticas de
        privacidad se describen en la Política de Privacidad.{" "}
      </Information>

      <SubTitle>1. Modelo de negocio de PegaCargo Express</SubTitle>
      <List dense>
        <ListItem sx={{ alignItems: "baseline" }}>
          <ListItemIcon sx={{ minWidth: " 30px" }}> 1.1. </ListItemIcon>
          <ListItemText>
            <Information sx={{ marginTop: 0 }}>
              Nuestra Plataforma vincula a terceros proveedores independientes
              de servicios de transporte de carga (“Transportistas”) y a sus
              clientes (“Usuarios”) entre sí. Cuando los Usuarios solicitan el
              transporte de la carga, son los Transportistas quienes ofrecen su
              precio por los servicios. Los Usuario que vean la oferta pueden
              aceptar el precio ofrecido o hacer una contraoferta.
            </Information>
          </ListItemText>
        </ListItem>
        <ListItem sx={{ alignItems: "baseline" }}>
          <ListItemIcon sx={{ minWidth: " 30px" }}> 1.2. </ListItemIcon>
          <ListItemText>
            <Information sx={{ marginTop: 0 }}>
              Los Usuarios son libres de elegir al Transportista de la lista de
              Transportistas que manifestaron su interés en aceptar la
              solicitud. Se forma un acuerdo separado entre el Usuario y el
              Transportista, cuando el Transportista o Usuario confirma el
              transporte de la carga.
            </Information>
          </ListItemText>
        </ListItem>
        <ListItem sx={{ alignItems: "baseline" }}>
          <ListItemIcon sx={{ minWidth: " 30px" }}> 1.3. </ListItemIcon>
          <ListItemText>
            <Information sx={{ marginTop: 0 }}>
              El Usuario deberá pagar al Transportista el precio que ambos
              acordaron a través de la Plataforma. Este precio acordado incluye
              todos los cargos o servicios asociados con el transporte de la
              carga (tasas, peajes, gravámenes, impuestos, etc.). PegaCargo no
              participa y no afecta de ninguna manera el acuerdo entre el
              Usuario y el Transportista.
            </Information>
          </ListItemText>
        </ListItem>
      </List>

      <SubTitle>2. Responsabilidad del Usuario </SubTitle>
      <List dense>
        <ListItem sx={{ alignItems: "baseline" }}>
          <ListItemIcon sx={{ minWidth: " 30px" }}> 2.1. </ListItemIcon>
          <ListItemText>
            <Information sx={{ marginTop: 0 }}>
              Usted, como Usuario, es responsable de proporcionar información
              precisa y completa sobre la entrega, incluida la dirección
              correcta del Origen y Destino, información de contacto y cualquier
              instrucción especial necesaria para la entrega exitosa de la
              Carga.
            </Information>
          </ListItemText>
        </ListItem>
        <ListItem sx={{ alignItems: "baseline" }}>
          <ListItemIcon sx={{ minWidth: " 30px" }}> 2.2. </ListItemIcon>
          <ListItemText>
            <Information sx={{ marginTop: 0 }}>
              Usted se compromete a garantizar el paso sin obstáculos del
              Transportista hasta el lugar de recojo y entrega, así como a
              garantizar su presencia o la presencia de un tercero capaz de
              aceptar la Carga en el lugar de la dirección de entrega
              especificada.
            </Information>
          </ListItemText>
        </ListItem>
        <ListItem sx={{ alignItems: "baseline" }}>
          <ListItemIcon sx={{ minWidth: " 30px" }}> 2.3. </ListItemIcon>
          <ListItemText>
            <Information sx={{ marginTop: 0 }}>
              Usted es responsable de garantizar que la Carga esté correctamente
              embalada y protegida para su transporte. Cualquier daño o retraso
              que resulte de un embalaje inadecuado o de información de entrega
              incorrecta proporcionada por usted será de su exclusiva
              responsabilidad.
            </Information>
          </ListItemText>
        </ListItem>
        <ListItem sx={{ alignItems: "baseline" }}>
          <ListItemIcon sx={{ minWidth: " 30px" }}> 2.4. </ListItemIcon>
          <ListItemText>
            <Information sx={{ marginTop: 0 }}>
              Usted realiza la Carga en el vehículo del Transportista por sus
              propios medios y por su cuenta. También la carga puede ser
              realizada por el Transportista mediante negociaciones entre el
              Usuario y el Transportista.
            </Information>
          </ListItemText>
        </ListItem>
        <ListItem sx={{ alignItems: "baseline" }}>
          <ListItemIcon sx={{ minWidth: " 30px" }}> 2.5. </ListItemIcon>
          <ListItemText>
            <Information sx={{ marginTop: 0 }}>
              Usted declara y garantiza que tiene el derecho legal de poseer y
              enviar los artículos incluidos en el transporte. Usted es
              responsable de garantizar que los artículos que se envían se
              adquieran legalmente y no infrinjan ninguna ley, regulación o
              restricción aplicable.
            </Information>
          </ListItemText>
        </ListItem>
        <ListItem sx={{ alignItems: "baseline" }}>
          <ListItemIcon sx={{ minWidth: " 30px" }}> 2.6. </ListItemIcon>
          <ListItemText>
            <Information sx={{ marginTop: 0 }}>
              Usted reconoce que asume toda la responsabilidad por cualquier
              consecuencia legal o reclamo que pueda surgir debido al envío de
              productos sin la titularidad o autorización adecuada.
            </Information>
          </ListItemText>
        </ListItem>
      </List>

      <SubTitle>3. Responsabilidad del transportista </SubTitle>
      <List dense sx={{ alignItems: "baseline" }}>
        <ListItem sx={{ alignItems: "baseline" }}>
          <ListItemIcon sx={{ minWidth: " 30px" }}> 3.1. </ListItemIcon>
          <ListItemText>
            <Information sx={{ marginTop: 0 }}>
              Usted, como Transportista, es responsable del transporte seguro y
              oportuno de la Carga desde el punto de recojo hasta el punto de
              destino. Debe tomar todas las precauciones necesarias para evitar
              cualquier pérdida o daño a la Carga durante el transporte. En caso
              de pérdida o daño a la Carga mientras esté en su posesión, usted
              será responsable, sujeto a las limitaciones o exclusiones de
              responsabilidad previstas en estos Términos o la ley aplicable.
            </Information>
          </ListItemText>
        </ListItem>
        <ListItem sx={{ alignItems: "baseline" }}>
          <ListItemIcon sx={{ minWidth: " 30px" }}> 3.2. </ListItemIcon>
          <ListItemText>
            <Information sx={{ marginTop: 0 }}>
               Ambas partes reconocen que la responsabilidad de cada parte por
              la entrega se limita a las obligaciones respectivas establecidas
              anteriormente, y ninguna de las partes será responsable de
              cualquier falla o retraso en la entrega causado por circunstancias
              más allá de su control razonable, incluidas, entre otras,
              desastres naturales, acciones gubernamentales o cualquier otro
              evento de fuerza mayor.
            </Information>
          </ListItemText>
        </ListItem>
        <ListItem sx={{ alignItems: "baseline" }}>
          <ListItemIcon sx={{ minWidth: " 30px" }}> 3.3. </ListItemIcon>
          <ListItemText>
            <Information sx={{ marginTop: 0 }}>
              En caso de una entrega fallida debido a la indisponibilidad del
              destinatario, información de entrega incorrecta proporcionada por
              el Usuario o cualquier otro motivo fuera del control razonable del
              Transportista, PegaCargo no asume responsabilidad por almacenar la
              Carga. El Transportista debe ser consciente de que, en tales
              casos, debe hacer arreglos alternativos para el almacenamiento o
              manipulación de la Carga.
            </Information>
          </ListItemText>
        </ListItem>
        <ListItem sx={{ alignItems: "baseline" }}>
          <ListItemIcon sx={{ minWidth: " 30px" }}> 3.4. </ListItemIcon>
          <ListItemText>
            <Information sx={{ marginTop: 0 }}>
              Al aceptar realizar el servicio de transporte de carga solicitado
              por el Usuario, usted tiene el derecho, pero no la obligación, de:
            </Information>
            <List dense sx={{ alignItems: "baseline" }}>
              <ListItem sx={{ alignItems: "baseline" }}>
                <ListItemIcon sx={{ minWidth: " 30px" }}> 3.4.1. </ListItemIcon>
                <ListItemText>
                  <Information sx={{ marginTop: 0 }}>
                    Leer atentamente los rótulos que describan la carga.
                  </Information>
                </ListItemText>
              </ListItem>
              <ListItem sx={{ alignItems: "baseline" }}>
                <ListItemIcon sx={{ minWidth: " 30px" }}> 3.4.2 </ListItemIcon>
                <ListItemText>
                  <Information sx={{ marginTop: 0 }}>
                    Pedirle al Usuario que le muestre el contenido de la carga y
                    selle el paquete o embale los paquetes con usted.
                  </Information>
                </ListItemText>
              </ListItem>
              <ListItem sx={{ alignItems: "baseline" }}>
                <ListItemIcon sx={{ minWidth: " 30px" }}> 3.4.3 </ListItemIcon>
                <ListItemText>
                  <Information sx={{ marginTop: 0 }}>
                    No realizar el servicio de transporte de carga si el Usuario
                    se niega a mostrar el contenido, o si el contenido parece
                    sospechoso o ilegal.
                  </Information>
                </ListItemText>
              </ListItem>
              <ListItem sx={{ alignItems: "baseline" }}>
                <ListItemIcon sx={{ minWidth: " 30px" }}> 3.4.4 </ListItemIcon>
                <ListItemText>
                  <Information sx={{ marginTop: 0 }}>
                    Si el Remitente sospecha una violación de la legislación
                    vigente o de estos Términos, puede contactarnos a través de
                    <Link to={CONTACT_US_LANDING_PATH}>
                      www.pegacargo.com/contactanos.
                    </Link>
                  </Information>
                </ListItemText>
              </ListItem>
            </List>
          </ListItemText>
        </ListItem>
      </List>

      <SubTitle>4. Bienes restringidos </SubTitle>
      <List dense>
        <ListItem sx={{ alignItems: "baseline" }}>
          <ListItemIcon sx={{ minWidth: "30px" }}> 4.1. </ListItemIcon>
          <ListItemText>
            <Information sx={{ marginTop: 0 }}>
              Cuando utiliza la Plataforma, usted es responsable de garantizar
              que la Carga que se entrega no sea un bien restringido.
            </Information>
          </ListItemText>
        </ListItem>
        <ListItem sx={{ alignItems: "baseline" }}>
          <ListItemIcon sx={{ minWidth: "30px" }}> 4.2. </ListItemIcon>
          <ListItemText>
            <Information sx={{ marginTop: 0 }}>
              Bienes restringidos, incluidos, entre otros:
            </Information>
            <List dense>
              <ListItem sx={{ alignItems: "baseline" }}>
                <ListItemIcon sx={{ minWidth: "30px" }}> 4.2.1. </ListItemIcon>
                <ListItemText>
                  <Information sx={{ marginTop: 0 }}>
                    Productos prohibidos de comercializar por ley.
                  </Information>
                </ListItemText>
              </ListItem>
              <ListItem sx={{ alignItems: "baseline" }}>
                <ListItemIcon sx={{ minWidth: "30px" }}> 4.2.2. </ListItemIcon>
                <ListItemText>
                  <Information sx={{ marginTop: 0 }}>
                    Productos o bienes prohibidos de portar sin autorización
                    (licencia) por ley.
                  </Information>
                </ListItemText>
              </ListItem>
              <ListItem sx={{ alignItems: "baseline" }}>
                <ListItemIcon sx={{ minWidth: "30px" }}> 4.2.3. </ListItemIcon>
                <ListItemText>
                  <Information sx={{ marginTop: 0 }}>
                    Productos o bienes que para su manipulación, portación,
                    traslado o almacenamiento requiera algún tipo de
                    capacitación o experiencia de por medio.
                  </Information>
                </ListItemText>
              </ListItem>
            </List>
          </ListItemText>
        </ListItem>
      </List>

      <Information>Contactando con PegaCargo </Information>
      <Information>
        Puede contactarnos a través de{" "}
        <Link to={CONTACT_US_LANDING_PATH}>www.pegacargo.com/contactanos.</Link>{" "}
      </Information>
    </Container>
  );
};

export default TermsConditionsExpress;
