import {
  nottifficationDeclineCounterOfferDriver,
  nottifficationDeclineOfferDriver,
  nottifficationDeclineQuoteDriver,
} from "@/consts/notifficationsMessage";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ContainerButtons,
} from "./StylesGlobalButtonsNotifications";

import { ID_MOVING_FLOW } from "@/consts/defaultConsts";
import {
  NEGOTATION_COUNTEROFFER_CILENT_PATH,
  NEGOTATION_COUNTEROFFER_PATH,
  NEGOTATION_REJECTED_NEGOTATION_MESSAGE_CLIENT,
  NEGOTATION_REJECTED_NEGOTATION_MESSAGE_DRIVER,
} from "@/consts/negotationFlowPath";
import { setSelectTypeRequest } from "@/store/customerRequestRedux/customerRequestSlice";
import {
  setIsEdition,
  setSelectNegotationTypeRequest,
} from "@/store/noticeNegotationRequestRedux/noticeNegRequestSlice";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { useEffect, useState } from "react";
import { validIsClient } from "@/pages/noticeRequestFlow/utils/utilsNegotation";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";

const text1 = "EDITAR NEGOCIACIÓN";
const text2 = "RETIRAR NEGOCIACIÓN";

interface Prop {
  textOne?: string;
  textTwo?: string;
}

export const EditCounterOfferButtons = ({ textOne, textTwo }: Prop) => {
  const { handleDeclineNotification, handleEditButton } =
    UseHandleActionButtons();
  const [textOneToRender, setTextOneToRender] = useState(text1);
  const [textTwoToRender, setTextTwoToRender] = useState(text2);

  useEffect(() => {
    if (textOne) setTextOneToRender(textOne);
    if (textTwo) setTextTwoToRender(textTwo);
  }, []);

  return (
    <ContainerButtons>
       <ButtomLoader
        fullWidth
        variant="contained"
        onClick={() => handleEditButton()}
      >
        {textOneToRender}
      </ButtomLoader> 

      <ButtomLoader
        fullWidth
        variant="outlined"
        
        onClick={() => handleDeclineNotification()}
        sx={{ borderColor: "#E0E0E0" }}
      >
        {textTwoToRender}
      </ButtomLoader>
    </ContainerButtons>
  );
};

export const EditOfferButtons = () => {
  const { handleDeclineNotification } = UseHandleActionButtons();

  return (
    <ContainerButtons>
      <ButtomLoader
        fullWidth
        variant="outlined"
        
        onClick={() => handleDeclineNotification()}
        sx={{ borderColor: "#E0E0E0" }}
      >
        RETIRAR NEGOCIACIÓN
      </ButtomLoader>
    </ContainerButtons>
  );
};

export const UseHandleActionButtons = () => {
  const dispatch = useAppDispatch();
  const {
    user: { user },
    noticeNegotationRequest: { savedDataNogotation },
  } = useAppSelector((state) => state);
  const idFlowType = savedDataNogotation?.solicitud?.tipoSolicitud
    ?.id as number;
  const clientId = savedDataNogotation?.solicitud.cliente.id as number;
  const isClient = validIsClient(clientId, user as IUser);
  const { typeId = "", typeRequest = "" } = useParams();

  const { pathname } = useLocation();
  const isMoving =
    (savedDataNogotation?.solicitud?.tipoSolicitud?.id as number) ===
    ID_MOVING_FLOW;
  const isCoCounterOffer = pathname.includes("co-contraoferta");
  const isCounterOffer = pathname.includes("contraoferta");
  const textToReplace = isCounterOffer
    ? isCoCounterOffer
      ? "co-contraoferta"
      : "contraoferta"
    : "oferta";

  const { addNotiffication } = useNotiffication();
  const navigate = useNavigate();

  const handleEditButton = () => {
    const option = { id: idFlowType, nombre: "", descripcion: "" };
    dispatch(setSelectTypeRequest(option));
    dispatch(setSelectNegotationTypeRequest(option));

    const pathClient = NEGOTATION_COUNTEROFFER_CILENT_PATH.replace(
      ":typeRequest",
      "contraoferta"
    ).replace(":idRequest", typeRequest || typeId);
    const pathDriver = NEGOTATION_COUNTEROFFER_PATH.replace(
      ":typeRequest",
      "contraoferta"
    ).replace(":idRequest", typeId);
    const path = isClient ? pathClient : pathDriver;


    dispatch(setIsEdition(true));
    navigate(path, { state: { editNegotation: true } });
  };

  const handleDeclineButton = () => {
    const path = isClient
      ? NEGOTATION_REJECTED_NEGOTATION_MESSAGE_CLIENT.replace(
          ":typeRequest",
          typeRequest || typeId
        ).replace(":typeOffer", textToReplace)
      : NEGOTATION_REJECTED_NEGOTATION_MESSAGE_DRIVER.replace(
          ":typeRequest",
          typeId
        ).replace(":typeOffer", textToReplace);

    navigate(path, { state: { isNavigationInFlow: true } });
  };

  const handleDeclineNotification = () => {
    let textToRende = !isCounterOffer
      ? nottifficationDeclineOfferDriver
      : nottifficationDeclineCounterOfferDriver;
    if (isMoving) textToRende = nottifficationDeclineQuoteDriver;

    addNotiffication({
      title: textToRende.TITLE,
      message: textToRende.MESSAGE,
      leftBtnText: textToRende.LEFT_BTN_TEXT,
      RightBtnText: textToRende.RIGHT_BTN_TEXT,
      hiddenSnackbar: true,
      hiddenModal: false,
      onSubmit: async () => {
        handleDeclineButton();
      },
    });
  };

  return {
    handleDeclineNotification,
    handleEditButton,
  };
};
