import { ResponseMovingNoticeOffer } from '@/models/offert/offertModels';
import DetailsText from '@/pages/flowNoticeNegotation/components/DetailsText';
import { Typography } from '@mui/material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import {
  getDestinyLocationDetails,
  getListConditions,
  getNumberOfAssistants,
  getOriginLocationDetails,
  getformatPrice,
} from '@/pages/flowNoticeNegotation/shared/utils/callBackMoving';
import OptionsDetailList from '@/pages/flowNoticeNegotation/components/OptionsDetailList';
import { AreaIcon } from '@/assets/svg';
import OfferPrice from '@/components/detalsNotice/OfferPrice';
import ConditionsListOffert from '@/components/conditionsList/ConditionsListOffert';

interface ValuesToReplace {
  search: string;
  value: keyof ResponseMovingNoticeOffer;
  defaultResult: string;
}

export interface ValidFormat {
  template: string;
  valuesToReplace: ValuesToReplace[];
}

interface ConfigDataBodyOffertValid {
  property: keyof ResponseMovingNoticeOffer;
  componentProp: string;
  defaultResult: boolean | string | number | Array<any> | Object;
  format?: ValidFormat;
  callback?: (
    tipoAvisoNegociacion: number,
    value: any,
    offert: ResponseMovingNoticeOffer
  ) => any;
}

export interface ConfigDataBodyMovingOffer {
  title?: string;
  Component: any;
  props: any;
  valid?: ConfigDataBodyOffertValid[];
  children?: JSX.Element | JSX.Element[] | string;
}

export const configDataMoving: ConfigDataBodyMovingOffer[] = [
  {
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
      children: 'Detalles del servicio',
    },
  },
  {
    Component: DetailsText,
    props: {
      icon: CalendarMonthOutlinedIcon,
      title: 'Fecha',
      iconProps: { sx: { color: '#757575' } },
      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: OptionsDetailList,
    props: {
      icon: 'icon-location-light',
      title: 'Origen:',
      sx: { marginTop: '10px' },
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: true,
      },

      {
        property: 'origenTexto',
        componentProp: 'listData',
        defaultResult: [],
        callback: getOriginLocationDetails,
      },
    ],
  },
  {
    Component: OptionsDetailList,
    props: {
      icon: 'icon-location-dark',
      title: 'Destino:',
      sx: { marginTop: '10px' },
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: true,
      },

      {
        property: 'destinoTexto',
        componentProp: 'listData',
        defaultResult: [],
        callback: getDestinyLocationDetails,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: AreaIcon,
      title: 'Área',
    },
    valid: [
      {
        property: 'metraje',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'metraje',
        componentProp: 'origen',
        defaultResult: '',
        format: {
          template: ':area m²',
          valuesToReplace: [
            {
              search: ':area',
              value: 'metraje',
              defaultResult: '',
            },
          ],
        },
      },
    ],
  },
  {
    Component: OfferPrice,
    props: {
      constainerSx: { marginTop: '20px' },
      priceDescription: 'Por viaje',
    },
    valid: [
      {
        property: 'precio',
        componentProp: 'show',
        defaultResult: '',
      },
      {
        property: 'precio',
        componentProp: 'price',
        defaultResult: '',
        callback: getformatPrice,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'body1',
      sx: {
        fontWeight: '600',
      },
    },
    valid: [
      {
        property: 'ayudantes',
        componentProp: 'children',
        defaultResult: '',
        callback: getNumberOfAssistants,
      },
    ],
  },
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: {
        marginTop: '20px',
      },
    },
    valid: [
      {
        property: 'avisoNegociacionCondiciones',
        componentProp: 'show',
        defaultResult: false,
        callback: getListConditions,
      },
      {
        property: 'avisoNegociacionCondiciones',
        componentProp: 'listServices',
        defaultResult: [],
      },
    ],
  },
];
