import { ContainerList } from "../noticeList/styleNoticeList";
import MyCustomList, { TCustomListProps } from "./MyCustomList";

const MyCustomListChildren = ({ list, isLoading, refObserver, isLoadingPagination, type, textButton }: TCustomListProps) => {
  
  return (
    <ContainerList>
      <MyCustomList list={list} isLoading={isLoading} refObserver={refObserver} isLoadingPagination={isLoadingPagination} type={type} textButton={textButton} />
    </ContainerList>
  );
};

export default MyCustomListChildren;
