import { REGISTER_PATH } from '@/consts/noticeV2Paths';
import { HOME_PAHT, REGISTER_DRIVER_STEPS } from '@/consts/typeServicesPaths';
import { UsuarioAPI } from '@/models/userAuth/user';
import { RootState } from '@/store/store';
import { To } from 'history';
import { getRedirectPath } from '../redirectRules/redirectRules';
import { MiddlewarePaths } from '../router';

// TODO: Falta validar las rutas del registro!!
export const privateSectionRegisterMiddleware = (
  state: RootState,
  paths: MiddlewarePaths
) => {
  const { user = null } = state.user;
  if (!user) return -1 as To;

  const validPath = validAccessCreateNoticeMiddleware(state, paths);
  if (validPath) return validPath;

  const path = getRedirectPath(user as UsuarioAPI);
  if (path) {
    return '';
  }

  return HOME_PAHT;
};

export const validateCompleteRegisterMiddleware = (state: RootState) => {
  const { user = null } = state.user;
  if (!user) return -1 as To;
  const currentUser = user as UsuarioAPI;

  return getRedirectPath(currentUser);
};

export const validAccessCreateNoticeMiddleware = (
  { user }: RootState,
  { currentPath, pathname }: MiddlewarePaths
) => {
  const currentUser = user.user as UsuarioAPI;
  if (!currentPath) return HOME_PAHT;

  if (currentPath.startsWith(REGISTER_PATH) && currentUser?.flgRegistroAviso) {
    const path = currentUser?.verificacionRegistro
      ? HOME_PAHT
      : REGISTER_DRIVER_STEPS;
    return path;
  }

  return '';
};
