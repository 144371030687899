import { TipoVehiculo, VehicleAPI } from "@/models/vehicle/vehicleModel";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DataFormVehicle, InitialState } from "./vehicleInterface";

const initialState: InitialState = {
  vehicleList: [],
  selectedVehicle: {
    id: 0,
    nombre: "",
    urlImagen: "",
  },
  vehiclePlate: "",
  maximumLoad: "",
  isRefrigerate: false,
  isLoading: false,
  error: null,
  savedVehicle: null,
};

export const vehicleSlice = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setVehicleList: (state, { payload }: PayloadAction<TipoVehiculo[]>) => {
      state.vehicleList = payload;
      state.isLoading = false;
      state.error = null;
    },
    setVehicleDefault: (state) => {
      state.selectedVehicle = initialState.selectedVehicle;
      state.vehiclePlate = initialState.vehiclePlate;
      state.maximumLoad = initialState.maximumLoad;
      state.isRefrigerate = initialState.isRefrigerate;
    },
    selectedVehicle: (state, { payload }: PayloadAction<TipoVehiculo>) => {
      state.selectedVehicle = payload;
    },
    setDataFormVehicle: (state, { payload }: PayloadAction<DataFormVehicle>) => {
      state.vehiclePlate = payload.vehiclePlate;
      state.maximumLoad = payload.maximumLoad;
      state.isRefrigerate = payload.isRefrigerate;
    },
    setError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
      state.isLoading = false;
    },
    cleanError: (state) => {
      state.error = initialState.error;
    },
    setSaveVehicle: (state, { payload }: PayloadAction<VehicleAPI>) => {
      state.savedVehicle = payload;
      state.isLoading = false;
      state.error = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setVehicleList, setLoading, selectedVehicle, setDataFormVehicle, setError, setVehicleDefault, setSaveVehicle, cleanError } =
  vehicleSlice.actions;
