import IconsSvg from "@/pages/detailNotice/components/IconsSvg";
import {
  ConatinerDetails,
  Title,
} from "@/pages/flowNoticeNegotation/styles/styles";
import { Stack, SxProps, Theme, Typography } from "@mui/material";
import { FC } from "react";

export type DetailsTextProps = {
  show: boolean;
  title: string;
  origen: string;
  icon: string;
  constainerSx?: SxProps<Theme>;
  iconProps?: any;
  titleSx?: SxProps<Theme>;
};

const DetailsText: FC<DetailsTextProps> = ({
  show,
  title,
  origen,
  icon,
  constainerSx,
  iconProps,
  titleSx,
}) => {
  if (!show || !origen) return <></>; // El !origen es para todos, cuando venga vacio no pinte nada el componente

  return (
    <ConatinerDetails sx={constainerSx}>
      <Stack flexDirection="row" alignItems="center">
        {icon && <IconsSvg icon={icon} {...iconProps}></IconsSvg>}
        <Title variant="label1" sx={{ ...titleSx, marginLeft: "8px" }}>
          {!!title && `${title} `}
        </Title>
      </Stack>

      <Stack flex={1} marginLeft="4px">
        <Typography
          variant="body1"
          sx={{ wordWrap: "break-word", maxWidth: "320px" }}
        >
          {origen}
        </Typography>
      </Stack>
    </ConatinerDetails>
  );
};

export default DetailsText;
