import { IconTypes } from "@/utils/Icon";

interface IResIconsByType {
    icon: IconTypes;
    title: string;
}


export const configDetailsByTypeFlow: Record<number, IResIconsByType> = {
    1: {
        icon: 'icon-inCity',
        title: 'En la ciudad',
    },
    2: {
        icon: 'icon-multiDestiny',
        title: 'Multidestino',
    },
    3: {
        icon: 'icon-betweenCities',
        title: 'Entre ciudades',
    },
    4: {
        icon: 'icon-box',
        title: 'Servicio express',
    },
    5: {
        icon: 'icon-movingCities',
        title: 'Mudanza entre ciudad',
    },
}