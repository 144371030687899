import { Container, styled, Typography } from "@mui/material";
import ButtomLoader from "../form/buttonLoader/ButtomLoader";
import { useNavigate } from "react-router-dom";
import { REGISTER_ME, SERVICES_LIST_DRIVER } from "@/consts/typeServicesPaths";
import { getLocalStorage } from "@/services/localstorageServices";
import { USER_TOKEN_STORAGE } from "@/consts/defaultConsts";

const TEXT_ILUSTRATION =
  "Podrás encontrar clientes luego de registrarte y crear tu primer aviso";
const TEXT_REGISTER_ME = "REGISTRARME";

const TEXT_USER_WITHOUT_NOTICE =
  "Podrás encontrar clientes luego de crear tu primer aviso";

const TEXT_CREATE_NOTICE = "CREAR UN AVISO";

const ContainerNotFound = styled(Container)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: ${theme.palette.background.default};
    flex-direction: column;
    `
);

const Content = styled("div")(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 360px;
    text-align: center;
    `
);
interface Props {
  existNotices?: boolean;
}

const Ilustration = (props: Props) => {
  const { existNotices } = props;
  const navigate = useNavigate();
  const userWithNotices = existNotices as boolean;
  const existToken = getLocalStorage(USER_TOKEN_STORAGE);

  const signUpTransporter = () => {
    let nextPath = REGISTER_ME;

    if (existToken) {
      if (!userWithNotices) {
        nextPath = SERVICES_LIST_DRIVER;
      }
    }

    navigate(nextPath);
  };

  const handleText = () => {
    let text = {
      title: TEXT_ILUSTRATION,
      btn: TEXT_REGISTER_ME,
    };

    if (existToken) {
      if (!userWithNotices) {
        text = {
          title: TEXT_USER_WITHOUT_NOTICE,
          btn: TEXT_CREATE_NOTICE,
        };

        return text;
      }
    }

    return text;
  };

  return (
    <ContainerNotFound>
      <Content>
        <i className="icon-ilustration" />
        <br />
        <Typography
          variant="body2"
          component="p"
          sx={{ mt: 1, fontSize: "16px" }}
        >
          {handleText().title}
        </Typography>
        <ButtomLoader
          variant="outlined"
          sx={{
            width: { xs: "100%", md: "355px" },
            marginTop: "16px",
          }}
          onClick={signUpTransporter}
        >
          {handleText().btn}
        </ButtomLoader>
      </Content>
    </ContainerNotFound>
  );
};

export default Ilustration;
