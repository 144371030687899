import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import HeaderModal from "@/components/modals/headerModal/HeaderModal";
import {
  ContainerButtom,
  ContainerModal,
  CustomDrawer,
} from "@/components/modals/stylesContainerFlow";
import { FormControl } from "@mui/material";
import { useEffect, useState } from "react";
import CheckList from "../CheckList";
import { getListPrevSelected } from "../../utils/helpers";
import { ModalFilterChildrenProps } from "../../interface/filterInterface";

interface Props
  extends Omit<
    ModalFilterChildrenProps,
    "isMultiSelect" | "betweenDate" | "onInputChange"
  > {}

const ModalMultiSelect = (props: Props) => {
  const { open, onClose, onSubmit, list, metaData, prevSelected } = props;
  const [selects, setSelects] = useState<any[]>([]);
  useEffect(() => {
    if (!!list.length && prevSelected) {
      const selected = getListPrevSelected(list, prevSelected);
      setSelects(selected);
    }
  }, [prevSelected]);

  const handleToggleSelected = (newValue: any) => {
    if (!Array.isArray(selects)) return;

    const isChecked = handleValidCheck(newValue.nombre);

    if (isChecked) {
      const newSelects = selects?.filter(
        (select) => select.nombre !== newValue.nombre
      );
      setSelects(newSelects);
      return;
    }
    setSelects((prev: any) => [...prev, newValue]);
  };

  const handleValidCheck = (currentValue: string) => {
    const isChecked = selects?.some(({ nombre }) => nombre === currentValue);

    return isChecked;
  };

  const handleOnSubmit = () => {
    onSubmit && onSubmit(selects);
  };

  return (
    <CustomDrawer open={open} onClose={() => onClose(false)} anchor="right">
      <HeaderModal title={metaData.title} onClick={() => onClose(false)} />
      <ContainerModal sx={{ position: "relative" }}>
        <FormControl>
          <CheckList
            list={list}
            onSeleted={handleToggleSelected}
            selects={selects}
          />
        </FormControl>
      </ContainerModal>
      <ContainerButtom>
        <ButtomLoader onClick={handleOnSubmit}>
          {metaData.buttonText}
        </ButtomLoader>
      </ContainerButtom>
    </CustomDrawer>
  );
};

export default ModalMultiSelect;
