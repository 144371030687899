import {
  AMOUNT_NEGOTATION,
  CUSTOMER_REQUEST_LIST_BY_ID_PATH,
  CUSTOMER_REQUEST_LIST_BY_ID_PRIV_PATH,
  CUSTOMER_REQUEST_LIST_FILTER_PATH,
  CUSTOMER_REQUEST_MY_LIST_PATH,
  CUSTOMER_REQUEST_SAVE_IMAGENS,
  CUSTOMER_REQUEST_TYPES_AMBIENTES_PATH,
  CUSTOMER_REQUEST_TYPES_CONDITIONS_PATH,
  CUSTOMER_REQUEST_TYPES_LOAD_PATH,
  CUSTOMER_REQUEST_TYPES_PATH,
  CUSTOMER_REQUEST_WEIGHT_RANGE_PATH,
  GET_PHOTOS_CLIENT,
  GET_V2_OFFERTS_SOLICITUD,
  LIST_OFFERS_CLIENT,
  SAVE_CUSTOMER_REQUEST_PATH,
  SAVE_CUSTOMER_REQUEST_PHOTO_PATH,
} from '@/consts/pathServices';
import { IMyRequestListDataResponse } from '@/models/customerRequest/MyRequestListDataResponse';
import { ICustomerRequestOffertAPI } from '@/models/customerRequest/customerRequestOffertPaginateResponse';
import {
  CustomerRequestResponseAPI,
  ICustomerDataResponseAPI,
  ITiposAmbientesAPI,
  SavedImagesCustomRequestAPI,
} from '@/models/customerRequest/customerRequestResponse';
import {
  CustomerRequestEstimatedWeightAPI,
  CustomerRequestTypesAPI,
  CustomerRequestTypesLoadAPI,
} from '@/models/customerRequest/customerRequestTypes';
import { CustomerRequestTypeConditionsAPI } from '@/models/customerRequest/customerRequestTypesConditions';
import { IRequestDetailsViewResponseAPI } from '@/models/customerRequest/requestDetailsVewResponse';
import { Cuantas_Negociaciones } from '@/models/noticeNegotation/noticeNegotationModel';
import { connection } from './connection';
import { instanceCustomerRequest } from './connection/intanceCustomerRequest';
import {
  ICustomerPhotosProps,
  ISaveCustomerRequestExpressDTO,
  ISaveCustomerRequestMovingDTO,
  saveCustomerRequestDTO,
} from './interfaces/customerRequestInterface';
import { HEADERS_MULTIPART } from './utils/consts';
import { setFormDataByObject } from './utils/utils';
import { IRequestNegListDataRes } from '@/models/customerRequest/RequestNegListDataResponse';
import { IRequestListFilterDataResponse } from '@/models/customerRequest/requestListFilterDataResponse';

const API = connection(instanceCustomerRequest);

export const getListCustomerRequestTypesServices =
  async (): Promise<CustomerRequestTypesAPI> => {
    const path = CUSTOMER_REQUEST_TYPES_PATH;
    const { data } = await API.get(path);

    return data;
  };

export const getListCustomerRequestTypesLoadServices =
  async (): Promise<CustomerRequestTypesLoadAPI> => {
    const path = CUSTOMER_REQUEST_TYPES_LOAD_PATH;
    const { data } = await API.get(path);

    return data;
  };

export const getListCustomerRequestWeightRangeServices =
  async (): Promise<CustomerRequestEstimatedWeightAPI> => {
    const path = CUSTOMER_REQUEST_WEIGHT_RANGE_PATH;
    const { data } = await API.get(path);

    return data;
  };

export const getListCustomerRequestTypeConditionsServices = async (
  idRequestType: string
): Promise<CustomerRequestTypeConditionsAPI> => {
  const path = CUSTOMER_REQUEST_TYPES_CONDITIONS_PATH.replace(
    ':idRequestType',
    idRequestType
  );
  const { data } = await API.get(path);

  return data;
};

export const saveCustomerRequestServices = async (
  body:
    | saveCustomerRequestDTO
    | ISaveCustomerRequestMovingDTO
    | ISaveCustomerRequestExpressDTO
): Promise<ICustomerDataResponseAPI> => {
  const path = SAVE_CUSTOMER_REQUEST_PATH;
  const { data } = await API.post(path, body);

  return data;
};

export const saveCustomerRequestPhotosServices = async (
  idCustomerRequest: string,
  body: ICustomerPhotosProps
): Promise<CustomerRequestResponseAPI> => {
  const path = SAVE_CUSTOMER_REQUEST_PHOTO_PATH.replace(
    ':idRequest',
    idCustomerRequest
  );
  const headers = HEADERS_MULTIPART;
  const formData = setFormDataByObject(body);
  const { data } = await API.post(path, formData, { headers });

  return data;
};

export const getListCustomerRequestTypesAmbientesServices =
  async (): Promise<ITiposAmbientesAPI> => {
    const path = CUSTOMER_REQUEST_TYPES_AMBIENTES_PATH;
    const { data } = await API.get(path);

    return data;
  };

// filter

export const filterCustomerRequestServices = async (body: any): Promise<IRequestListFilterDataResponse> => {
  const { data } = await API.post(CUSTOMER_REQUEST_LIST_FILTER_PATH, body);
  return data;
};

// list customer request
// cambiar el tipado
export const getCustomerRequestByIdServices = async (
  idSolicitud: string
): Promise<IRequestDetailsViewResponseAPI> => {
  const { data } = await API.get(
    CUSTOMER_REQUEST_LIST_BY_ID_PATH.replace(':id', idSolicitud)
  );
  return data;
};
// cambiar el tipado
export const getCustomerRequestByIdPrivServices = async (
  idSolicitud: string
): Promise<IRequestDetailsViewResponseAPI> => {
  const { data } = await API.get(
    CUSTOMER_REQUEST_LIST_BY_ID_PRIV_PATH.replace(':id', idSolicitud)
  );
  return data;
};

export const getCustomerRequestMyListServices = async (body: any): Promise<IMyRequestListDataResponse> => {
  const { data } = await API.post(CUSTOMER_REQUEST_MY_LIST_PATH, body);
  return data;
};

// Guarda las imagenes de la solicitud
export const saveImagesCustomerRequestServices = async (
  idVerified: number,
  body: ICustomerPhotosProps
): Promise<SavedImagesCustomRequestAPI> => {
  const path = CUSTOMER_REQUEST_SAVE_IMAGENS.replace(
    ':idVerified',
    idVerified.toString()
  );
  const headers = HEADERS_MULTIPART;
  const formData = setFormDataByObject(body);
  const { data } = await API.post(path, formData, { headers });

  return data;
};

// ==================================================================================================
//       SERVICIO PARA VALIDAR SI LA SOLICITUD TIENE OFERTAS O CONTRAOFERTAS
// ==================================================================================================

export const getAmountNegotationRequest = async (
  body: Cuantas_Negociaciones
): Promise<any> => {
  const path = AMOUNT_NEGOTATION;
  const { data } = await API.post(path, body);

  return data;
};

// LIST_OFFERS_CLIENT
export const getListOffersClientService = async (idRequest: string): Promise<IRequestNegListDataRes> => {
  let path = LIST_OFFERS_CLIENT.replace(':idRequest', idRequest);
  const { data } = await API.get(path);
  return data;
};

// GET_V2_OFFERTS_SOLICITUD
export const getV2OffertsSolicitudService = async (
  body: any
): Promise<ICustomerRequestOffertAPI> => {
  const { data } = await API.post(GET_V2_OFFERTS_SOLICITUD, body);
  return data;
};

// ==================================================================================================
//       SERVICIO PARA LAS FOTOS EN LA EDICIÓN DE LA NEGOCIACIÓN
// ==================================================================================================

export const getPhotosNegotationRequest = async (id: string): Promise<any> => {
  const path = GET_PHOTOS_CLIENT.replace(':idPhoto', id);
  const { data } = await API.get(path);

  return data;
};
