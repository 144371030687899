import BasicModal from "@/components/dialog/Notiffication";
import HeaderSectionV2 from "@/components/headerSectionV2/HeaderSectionV2";
import SnackbarNotification from "@/components/snackbar/Snackbar";
import { ID_EXPRESS_FLOW, ID_MOVING_FLOW } from "@/consts/defaultConsts";
import {
  NEGOTATION_COUNTEROFFER,
  NEGOTATION_REJECTION_CONFIRMATION,
} from "@/consts/negotationFlowPath";
import { noResponseText, optionSeven } from "@/consts/noticeConsts";
import { ERROR_NEGOTATION_CLOSED } from "@/consts/textErrors";
import { REQUESTS_LANDING_PATH } from "@/consts/typeServicesPaths";
import HeaderDetail from "@/pages/noticeV2Negotation/components/HeaderDetail";
import {
  EditCounterOfferButtons,
  EditOfferButtons,
} from "@/pages/requestNegotiation/components/globalButtons/EditNegotationButtons";
import GlobalButtonsPrincipal from "@/pages/requestNegotiation/components/globalButtons/GlobalButtonsPrincipal";
import {
  acceptDataRequestNegotiation,
  counterOfferDataRequestNegotiation,
  handleTextStatusAlert,
  movingCounterOfferRequestNegotiation,
} from "@/pages/requestNegotiation/shared/config/offerConfig";
import { useGetRequestData } from "@/pages/requestNegotiation/shared/hooks/useGetRequestData";
import { IconColor } from "@/utils/Icon";
import { compareUrls, deleteDomainUrl } from "@/utils/helpers";
import {
  Alert,
  Box,
  Container,
  Grid,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link as ReactLink, useLocation, useNavigate } from "react-router-dom";
import { GlobalPrice } from "./components/GlobalPrice";

interface LocationState {
  hasBackNavigate?: boolean;
  hasBackLinkOffert?: boolean;
}

export const OfferResponseLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    error,
    data,
    isCounterOffer,
    typeRequest,
    validSnackbarClient,
    validSnackbarDriver,
    redirect,
  } = useGetRequestData();
  const navigate = useNavigate();
  let location = useLocation();
  const locationState = location?.state as LocationState;
  const [hasBackNavigate, setHasBackNavigate] = useState(false);
  const [hasBackLinkOffert, setHasBackLinkOffert] = useState(false);
  const [offerText, setOfferText] = useState("");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const isMoving = typeRequest === ID_MOVING_FLOW;
  const { pathname } = location;
  const isDriver = pathname.startsWith(REQUESTS_LANDING_PATH);
  const clientOrDriver = isDriver ? validSnackbarDriver : validSnackbarClient;
  const negotationClosed = error === ERROR_NEGOTATION_CLOSED;
  const noResponse =
    data?.ultimaSolicitudNegociacionAcciones.tipoSolicitudNegociacionAccion
      .estado.id === optionSeven;

  const textTypeNeg = pathname.includes(NEGOTATION_COUNTEROFFER)
    ? "contraoferta"
    : "oferta";

  const textStatusAlert = handleTextStatusAlert(textTypeNeg);

  const textToRender = textStatusAlert[isDriver ? 1 : 2];
  const isExpress = (typeRequest as number) === ID_EXPRESS_FLOW;

  useEffect(() => {
    let defaultofferText = "Oferta del transportista";

    if (locationState?.hasBackNavigate && !locationState?.hasBackLinkOffert) {
      setHasBackNavigate(true);
    }

    if (locationState?.hasBackLinkOffert && !locationState?.hasBackNavigate) {
      setHasBackLinkOffert(true);
    }

    if (typeRequest === ID_MOVING_FLOW) {
      defaultofferText = "Cotización del transportista";
    }

    setOfferText(defaultofferText);
  }, []);

  const handleHeaderProps = () => {
    if (isCounterOffer) {
      if (isMoving) {
        return movingCounterOfferRequestNegotiation;
      }
      return counterOfferDataRequestNegotiation;
    }

    return acceptDataRequestNegotiation;
  };

  const handleNavigationBack = () => {
    navigate(-1);
  };

  const handleNegotationClosed = () => {
    const nextPath = pathname + NEGOTATION_REJECTION_CONFIRMATION;

    return nextPath;
  };

  return (
    <>
      {(hasBackLinkOffert || hasBackNavigate) && (
        <HeaderSectionV2
          icon="icon-arrow-left"
          title=""
          onClick={() => handleNavigationBack()}
        />
      )}
      <SnackbarNotification />
      {(redirect && compareUrls(redirect, pathname)) || negotationClosed ? (
        <Alert
          sx={{ mb: 3, p: 2 }}
          severity={negotationClosed ? "error" : "warning"}
        >
          El estado de esta negociación ha cambiado.{" "}
          <Link
            component={ReactLink}
            color={negotationClosed ? "error" : "rgba(237,108,2,1)"}
            fontWeight={600}
            to={
              negotationClosed
                ? handleNegotationClosed()
                : deleteDomainUrl(redirect || "")
            }
            replace
          >
            Ver estado actual.
          </Link>
        </Alert>
      ) : (
        <>
          {!noResponse && (
            <>
              {clientOrDriver() && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Alert sx={{ mb: 3, p: 2, width: "90%" }} severity="info">
                    {textToRender}
                  </Alert>
                </Box>
              )}
            </>
          )}
        </>
      )}

      <Container maxWidth={matches ? "xl" : "xs"}>
        {!noResponse && (
          <>
            {!clientOrDriver() && (
              <HeaderDetail
                title={handleHeaderProps().title}
                icon={handleHeaderProps().icon}
                iconColor={handleHeaderProps().iconColor as IconColor}
              />
            )}
          </>
        )}

        {noResponse && (
          <Alert sx={{ mb: 3, mt: 3, p: 2 }} severity="info">
            {noResponseText}
          </Alert>
        )}

        <Grid
          container
          spacing={0}
          sx={{
            display: "flex",
            justifyContent: "center",
            pl: matches ? 20 : 0,
            pr: matches ? 20 : 0,
          }}
        >
          <Grid xs={matches ? 7 : 12}>{children}</Grid>

          <Grid xs={matches ? 5 : 12} sx={{ padding: "0px" }}>
            <GlobalPrice data={data} />

            {redirect && compareUrls(redirect, pathname) ? (
              ""
            ) : (
              <>
                {" "}
                {clientOrDriver() ? (
                  isExpress ? (
                    <EditCounterOfferButtons />
                  ) : (
                    <EditOfferButtons />
                  )
                ) : (
                  <GlobalButtonsPrincipal />
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Container>
      <BasicModal />
    </>
  );
};
