import {
  ADVERTISEMENT_LIST,
  CLICK_ADVERTISEMENT,
  SAVE_CLICK_OF_SPONSORED_NOTICE,
  SHOW_ADVERTISEMENT,
} from '@/consts/pathServices';
import {
  AdvertisementClickAPI,
  AdvertisementClickDTO,
  AdvertisementListAPI,
  GetAdvertisementDTO,
  ShowAdvertisementDTO,
} from '@/models/advertisement/advertisementModel';
import { connection, instanceAuth } from './connection';
import { SaveClickOfSponsoredNoticeBody } from '@/interfaces/advertisementInterface';

const API = connection(instanceAuth);

export const getAdvertisementListServices = async (
  body: GetAdvertisementDTO
): Promise<AdvertisementListAPI> => {
  const { data } = await API.put(ADVERTISEMENT_LIST, body);

  return data;
};

export const setClickAdvertisementServices = async (
  body: AdvertisementClickDTO
): Promise<AdvertisementClickAPI> => {
  const { data } = await API.put(CLICK_ADVERTISEMENT, body);

  return data;
};

export const setShowAdvertisementServices = async (
  body: ShowAdvertisementDTO
): Promise<AdvertisementClickAPI> => {
  const { data } = await API.put(SHOW_ADVERTISEMENT, body);

  return data;
};

// ==================================================
//  Para contar los cliks de los aviso promocionados
// ==================================================
export const saveClickOfSponsoredNoticeServices = async (
  body: SaveClickOfSponsoredNoticeBody
) => {
  const API = connection();
  const { data } = await API.post(SAVE_CLICK_OF_SPONSORED_NOTICE, body);

  return data;
};
