import {
  CUSTOMER_REQUEST_FILTER_PATH,
  LIST_FILTERS,
  LIST_FILTERV2,
} from '../consts/pathServices';

import { connection } from '@/services/connection';
import {
  IFilter,
  IFilterCustomerRequest,
  TypesFilter,
} from '@/store/FilterServices/filterReduxInterface';
import { instanceCustomerRequest } from './connection/intanceCustomerRequest';

const API = connection();
const APICR = connection(instanceCustomerRequest);

export const getFiltersServices = async (id: number): Promise<IFilter> => {
  const path = LIST_FILTERS.replace(':id', id.toString());
  const { data } = await API.get(path);

  return data;
};

export const getFiltersCustomerRequestServices = async (
  id: number
): Promise<IFilterCustomerRequest> => {
  const path = CUSTOMER_REQUEST_FILTER_PATH.replace(':idCity', id.toString());
  const { data } = await APICR.get(path);

  return data;
};

export const getFilterListServices = async (): Promise<TypesFilter> => {
  const path = LIST_FILTERV2;
  const { data } = await API.get(path);

  return data;
};
