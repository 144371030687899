import ConditionsListOffert from '@/components/conditionsList/ConditionsListOffert';
import TransporterRating from '@/components/userRating/TransporterRating';
import DetailsText from '@/pages/offerts/offertConfirmation/components/DetailsText';
import { Typography } from '@mui/material';
import DetailTextTitle from '../components/DetailTextTitle';
import { ConfigDataBody } from '../config';
import {
  renderIconByTypeRequest,
  renderRating,
  renderTitleByTypeRequest,
  renderTitleOrigenAndDestiny,
  renderTypeVehicle,
} from '../utils/utilsApplication';
import { descriptionLoad } from '@/consts/noticeConsts';
export const configDataCustomRequestExpress: ConfigDataBody[] = [
  {
    Component: DetailTextTitle,
    props: {
      title: 'En la ciudad',
      icon: 'icon-box',
      show: true,
    },
    valid: [
      {
        property: 'tipoSolicitud',
        componentProp: 'title',
        defaultResult: '',
        callback: renderTitleByTypeRequest,
      },
      {
        property: 'tipoSolicitud',
        componentProp: 'icon',
        defaultResult: '',
        callback: renderIconByTypeRequest,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'h6',
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'title',
        defaultResult: '',
        callback: renderTitleOrigenAndDestiny,
      },
    ],
  },
  {
    Component: TransporterRating,
    props: {
      containerSx: { mt: '16px', mb: '5px' },
      viewRating: true,
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'data',
        defaultResult: {},
        callback: renderRating,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: 'Detalles de la solicitud',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  // {
  //   Component: DetailsText,
  //   props: {
  //     icon: 'icon-calendar--white',
  //     title: 'Fecha del servicio',
  //     originDescription: '',
  //   },
  //   valid: [
  //     {
  //       property: 'fecha',
  //       componentProp: 'show',
  //       defaultResult: false,
  //     },
  //     {
  //       property: 'fecha',
  //       componentProp: 'title',
  //       defaultResult: '',
  //       callback: renderExpiredHoursExpress('title'),
  //     },
  //     {
  //       property: 'fecha',
  //       componentProp: 'origen',
  //       defaultResult: '',
  //       callback: renderExpiredHoursExpress('date'),
  //     },
  //   ],
  // },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'origenTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referencia',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-location-dark',
      title: 'Destino',
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'destinoTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  // icon-car-medium
  {
    Component: DetailsText,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'tipoVehiculo',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'tipoVehiculo',
        componentProp: 'origen',
        defaultResult: '',
        callback: renderTypeVehicle,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: descriptionLoad,
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },

  {
    Component: Typography,
    props: {
      variant: 'body1',
      marginTop: '4px',
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-kilos',
      title: 'Peso estimado',
    },
    valid: [
      {
        property: 'pesoEstimado',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'pesoEstimado',
        componentProp: 'origen',
        defaultResult: '',
        format: {
          template: ':weight kilos',
          valuesToReplace: [
            {
              search: ':weight',
              value: 'pesoEstimado',
              defaultResult: '',
            },
          ],
        },
      },
    ],
  },
  // {
  //   Component: DetailsText,
  //   props: {
  //     icon: 'icon-snowFlake-medium',
  //     title: 'Necesita refrigeración',
  //   },
  //   valid: [
  //     {
  //       property: 'flgcargarefrigerada',
  //       componentProp: 'show',
  //       defaultResult: false,
  //     },
  //     {
  //       property: 'flgcargarefrigerada',
  //       componentProp: 'origen',
  //       defaultResult: '',
  //       callback: renderYesOrNo,
  //     },
  //   ],
  // },

  // condiciones de la solicitud
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '16px' },
    },
    valid: [
      {
        property: 'condiciones',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'condiciones',
        componentProp: 'listServices',
        defaultResult: [],
      },
    ],
  },
];
