import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import { currencyFormat } from "@/utils/helpers";
import { Box, Typography } from "@mui/material";

interface Props {
  data: SolicitudNegociacion;
}

export const MovingCounterOfferPrice = (props: Props) => {
  const { data } = props;

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "flex-start", mt: 2 }}>
        <Typography sx={{ fontWeight: 700, fontSize: "22px" }}>
          Precio cotizado: {currencyFormat(data?.precio)}
        </Typography>
        <Typography sx={{ ml: 1, fontWeight: 400, fontSize: "14px" }}>
          Por viaje
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
          Cantidad de ayudantes:
        </Typography>
        <Typography sx={{ ml: 1, fontSize: "16px" }}>
          {data.ayudantes}
        </Typography>
      </Box>
    </>
  );
};
