import { BASE_URL_FLOW } from "@/config/config";
import { USER_TOKEN_STORAGE } from "@/consts/defaultConsts";
import axios from "axios";
import { getLocalStorage } from "../localstorageServices";

export const controllerInstanceFlow = new AbortController();

const instanceAxios = axios.create({
  baseURL: BASE_URL_FLOW,
  signal: controllerInstanceFlow.signal,
});

instanceAxios.interceptors.request.use((request) => {
  const token = getLocalStorage(USER_TOKEN_STORAGE);
  if (!token) return request;

  request.headers = {
    Authorization: `Bearer ${token}`,
  };

  return request;
});

export { instanceAxios };
