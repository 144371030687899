import { FORMAT_DATE_DDMMYYYY } from "@/consts/formatsDate";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import {
    setDateService,
    setSelectDestiny,
    setSelectOrigin,
} from "@/store/customerRequestRedux/customerRequestSlice";
import { getCitiesByTermThunk } from "@/store/locationRedux/citiesThunks";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useId, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    MODAL_TYPE,
    ModalType,
    configFormRute,
    functionSaveObjectByTypeModal
} from "../FormRute/FormRuteInCity/configFormInCity";

const useFechaCustomHook = () => {
    const [datePicker, setDatePicker] = useState<Dayjs | null>(
        dayjs().add(1, "day")
    );
    const [openModal, setOpenModal] = useState(false);
    const [currentModal, setCurrentModal] = useState({
        name: "search-city",
        label: "Ciudad",
        placeholder: "Ingresa la ciudad",
        title: "Ciudad de origen",
        type: "city",
    });
    const [currentTypeModal, setCurrentTypeModal] = useState<ModalType["type"]>(
        MODAL_TYPE.city
    );
    const id = useId();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        cities: { citiesByTerm },
        customerRequest: {
            selectedCity,
            flgCiudadoEntreciudades,
            selectedTypeRequest,
            dateServices,
        },
    } = useAppSelector((state) => state);

    useEffect(() => {
        if (dateServices) {
            const date = dayjs(dateServices, FORMAT_DATE_DDMMYYYY);
            setDatePicker(date);
        }
    }, []);

    const handleOnClickSearchInput = (typeModal: ModalType["type"]) => {
        setCurrentTypeModal(typeModal);
        setCurrentModal(configFormRute[typeModal].modal);
        setOpenModal(true);
    };

    const handleChangeModal = (term: string) => {
        if (term.length >= 3) {
            dispatch(getCitiesByTermThunk(term));
        }
    };
    const handleSubmitModal = (selected: Record<string, any> | null) => {
        if (!selected) return;
        const reduxAction = functionSaveObjectByTypeModal[currentTypeModal];

        dispatch(reduxAction(selected));
        dispatch(
            setSelectOrigin({
                id: 0,
                nombre: "",
            })
        );
        dispatch(
            setSelectDestiny({
                id: 0,
                nombre: "",
            })
        );

        setOpenModal(false);
    };

    const handleSubmit = () => {
        const stringDate =
            (datePicker as Dayjs)?.format(FORMAT_DATE_DDMMYYYY) || "";
        dispatch(setDateService(stringDate));
    };

    const handleDisabledButton = () => {
        if (flgCiudadoEntreciudades === 1 && !selectedCity?.id) return true;
        if (!datePicker) return true;
        return false;
    };

    const { city } = configFormRute;

    return {
        datePicker,
        setDatePicker,
        openModal,
        setOpenModal,
        currentModal,
        setCurrentModal,
        currentTypeModal,
        setCurrentTypeModal,
        id,
        dispatch,
        navigate,
        citiesByTerm,
        selectedCity,
        flgCiudadoEntreciudades,
        selectedTypeRequest,
        dateServices,
        handleOnClickSearchInput,
        handleChangeModal,
        handleSubmitModal,
        handleSubmit,
        handleDisabledButton,
        city,
    };

}



export default useFechaCustomHook;