import FormCounterOfferBetweenCities from './FormCounterOfferBetweenCities/Index';
import FormCounterOfferInCity from './FormCounterOfferInCity/Index';
import FormCounterOfferMoving from './FormCounterOfferMoving/Index';
import FormCounterOfferMultidestiny from './FormCounterOfferMultidestiny/Index';
import FormCounterOfferExpress from './formCounterOfferExpress/FormCounterOfferExpress';

export const objectRenderComponent: Record<number, () => JSX.Element> = {
  1: FormCounterOfferInCity,
  2: FormCounterOfferMultidestiny,
  3: FormCounterOfferBetweenCities,
  4: FormCounterOfferExpress,
  5: FormCounterOfferMoving,
};
