import { Grid } from "@mui/material";
import AdvertisingLandSection from "../../components/AdvertisingLandSection";
import InfoAppSection from "../../components/InfoAppSection";
import { HOME_PAHT } from "@/consts/typeServicesPaths";
import { CREATE_CUSTOMER_REQUEST_PATH } from "@/pages/customerRequest/shared/consts/routes";

interface InfoSectionProps {
  title?: string;
  text?: string;
  urlButton?: string;
  textButton?: string;
  image?: string;
  imgWidth?: string;
  imgHeight?: string;
  textInfoApp?: string;
}

const InfoSection = (props: InfoSectionProps) => {
  const {
    title = "Ofrece tu precio",
    textInfoApp = "Consigue transporte para tu carga de manera inmediata.",
    text = "Solicita transportistas con las condiciones del servicio que necesitas para enviar tus productos, mercadería o cualquier tipo de carga",
    urlButton = CREATE_CUSTOMER_REQUEST_PATH,
    textButton = "CREAR SOLICITUD",
    image = "icon-new_solicitud-client",
    imgWidth,
    imgHeight,
  } = props;
  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        paddingX: "16px",
        backgroundColor: "#fafafa",
        marginY: "16px",
        marginBottom: "32px",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          maxWidth="xl"
          container
          spacing={2}
          rowSpacing={2}
          sx={{ display: "flex", justifyContent: "space-around" }}
        >
          <Grid item xs={5.5}>
            <AdvertisingLandSection
              title={title}
              text={text}
              urlButton={urlButton}
              textButton={textButton}
              image={image}
              imgWidth={imgWidth}
              imgHeight={imgHeight}
            />
          </Grid>

          <Grid item xs={5.5}>
            <InfoAppSection text={textInfoApp} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InfoSection;
