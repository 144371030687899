import { Typography } from "@mui/material";
import { FC } from "react";
import {
  Before,
  DsctoContainer,
  DsctoTitle,
  Now,
  DsctoText,
} from "./stylesDscto";
import { currencyFormat } from "@/utils/helpers";

interface Props {
  price?: string;
  before: string;
  dscto: string;
}

const Dscto: FC<Props> = (props) => {
  let { price, before, dscto } = props;

  const handleGetPrice = (value: string) => {
    if (value) {
      return currencyFormat(Number(value));
    }
  };

  return (
    <DsctoContainer>
      <DsctoTitle container>
        <DsctoText>
          Antes: <Before>{handleGetPrice(before)}</Before>
        </DsctoText>
        <DsctoText>
          | Ahora: <Now>{dscto}% dscto.</Now>
        </DsctoText>
      </DsctoTitle>
      <Typography variant="h5">{handleGetPrice(price || "")}</Typography>
    </DsctoContainer>
  );
};

export default Dscto;
