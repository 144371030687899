import {
  MODAL_TYPE,
  ModalType,
} from "@/components/modals/searchModalLocationOffert/configDataModal";
import { maxLengthReference } from "@/consts/noticeConsts";
import { COUNTER_OFFER_RUTE } from "@/consts/offertPath";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import {
  DestinisAPI,
  NoticeDetailAuctionModel,
} from "@/models/notice/noticeModels";
import { InputProps } from "@/pages/flowNoticeNegotation/interfaces/interfaces";
import {
  getFirstStepNoticeType,
  getFirstStepNoticeTypeInterface,
} from "@/pages/flowNoticeNegotation/shared/adapters/clientAcceptOrCounterOfferAdapter";
import { NoticeSendData } from "@/store/noticeV2ClientAcceptCounterOffer/interfaces/sendDataInterfacesEnglish";
import { SelectedUbications } from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferSlice";
import {
  saveOfferAuctionThunk,
  fillSendDataNoticeThunk,
  getDistrictsByIdCityOriginAndDestinyThunk,
  setSelectedUbicationsThunk,
} from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferThunk";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { searchTermInArray } from "@/utils/helpers";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { configFormOffert, modalTitleConfig } from "../configData";
import { ID_COUNTEROFFER, ID_OFFER } from "@/consts/defaultConsts";

interface IFormDatos
  extends Omit<
    NoticeSendData,
    "cargoDescription" | "verifiedPhotosId" | "date"
  > {}

interface HookProps {
  modalType: "origin" | "destiny";

  modalTitle: string;
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  searchList: any[];
  form: IFormDatos;
  setForm: Dispatch<SetStateAction<IFormDatos>>;
  getDistrictByIdCity: () => void;
  handleInputClick: (event: EventType["click"], { name }: InputProps) => void;
  handleOnChange: ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleSubmit: () => void;
  handleTitleSelected: (title: string, nombre: string) => string;
  handleSetValue: (selected: DestinisAPI) => string;
  handleChangeModal: (term: string) => void;
  handleSubmitModal: (selected: Record<string, any> | null) => void;
  handleDisabled: () => boolean;
}

export const useRuteAuctionBtnCities = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isCounterOffer = pathname.endsWith(COUNTER_OFFER_RUTE);
  const dispatch = useAppDispatch();
  const { origin, destiny } = configFormOffert;
  const {
    noticeV2ClientAorCO: {
      sendData: { auctionBetweenCities },
      selectedUbications,
      avisodestinos,
      avisoorigenes,
      selectTypeNotice,
    },
  } = useAppSelector((state) => state);
  const notice = selectTypeNotice as NoticeDetailAuctionModel;
  const [modalTitle, setModalTitle] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [searchList, setSearchList] = useState<any[]>([]);
  const [modalType, setModalType] = useState<ModalType["type"]>(
    MODAL_TYPE.origin
  );
  const tipoAvisoId = notice?.tipoaviso?.id;
  const [form, setForm] = useState<IFormDatos>(handlePreloadData());

  useEffect(() => {
    if (form) {
      const ruteToSendData = {
        key: getFirstStepNoticeType(tipoAvisoId),
        value: getFirstStepNoticeTypeInterface(tipoAvisoId, {
          ...auctionBetweenCities,
          ...form,
        }),
      };

      const values = {
        data: ruteToSendData,
        tipoAvisoId,
        activeLoading: false,
      };
      const handler = setTimeout(() => {
        dispatch(fillSendDataNoticeThunk(values));
      }, 1000);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [form]);

  useEffect(() => {
    if (avisodestinos?.length || !avisodestinos?.length) {
      getDistrictByIdCity();
    }
  }, []);

  useEffect(() => {
    if (avisoorigenes.length) {
      setSearchList(avisoorigenes);
    }
  }, [avisoorigenes]);

  useEffect(() => {
    if (modalType === MODAL_TYPE.origin) {
      setSearchList(avisoorigenes);
    } else {
      setSearchList(avisodestinos);
    }
  }, [modalType]);

  function handlePreloadData() {
    const {
      originId = selectedUbications?.origin?.iddistrito || "",
      destinyId = selectedUbications?.destiny?.iddistrito || "",
      originReference = "",
      destinyReference = "",
    } = auctionBetweenCities;

    const negotiationTypeId = isCounterOffer ? ID_COUNTEROFFER : ID_OFFER;

    return {
      negotiationTypeId,
      originId,
      destinyId,
      originReference,
      destinyReference,
    };
  }

  const getDistrictByIdCity = () => {
    const { idciudad, idciudaddestino } = notice;
    dispatch(
      getDistrictsByIdCityOriginAndDestinyThunk(idciudad, idciudaddestino || 0)
    );
  };

  const handleInputClick = (
    event: EventType["click"],
    { name }: InputProps
  ) => {
    const invalidInputs = [origin.input1.name, destiny.input1.name];
    if (invalidInputs.every((inputName) => inputName !== name)) return;

    setModalTitle(modalTitleConfig[name]);

    setModalType(name as ModalType["type"]);

    setOpenModal(true);
  };

  const handleOnChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = target;
    if (name === origin.input1.name || name === destiny.input1.name) {
      setOpenModal(true);
      return;
    }

    if (name === origin.input2.name) {
      value.length < maxLengthReference &&
        setForm({ ...form, originReference: value });
    } else {
      value.length < maxLengthReference &&
        setForm({ ...form, destinyReference: value });
    }
  };

  const handleSubmit = async () => {
    const data = { ...auctionBetweenCities, ...form };

    if (!isCounterOffer) {
      delete data.price;
      delete data.estimatedWeight;
    }

    const ruteToSendData = {
      key: getFirstStepNoticeType(tipoAvisoId),
      value: getFirstStepNoticeTypeInterface(tipoAvisoId, data),
    };

    const values = { data: ruteToSendData, tipoAvisoId };

     await dispatch(fillSendDataNoticeThunk(values));
    if (isCounterOffer) {
      handleNextRute();
    } else {
      dispatch(saveOfferAuctionThunk()).then(() => {
        handleNextRute();
      });
    } 
  };

  const handleNextRute = () => {
    dispatch(
      getNextRoute(navigate, {
        valueToReplace: notice.id?.toString(),
        valueToSearch: ":id",
      })
    );
  };

  const handleTitleSelected = (title: string, nombre: string) => {
    let currentTitle = title;
    currentTitle += `: ${nombre}`;

    return currentTitle;
  };

  const handleSetValue = (selected: DestinisAPI) => {
    if (!selected?.iddistrito) {
      return "";
    }

    return selected?.nombre;
  };

  const handleChangeModal = (term: string) => {
    let listStorage = avisodestinos;
    if (modalType === MODAL_TYPE.origin) {
      listStorage = avisoorigenes;
    }
    const currentList = structuredClone(listStorage);
    const search: DestinisAPI[] = searchTermInArray(term, currentList, [
      "nombre",
    ]);

    setSearchList(search);
  };

  const handleSubmitModal = (selected: Record<string, any> | null) => {
    if (!selected) return;

    const selectValue = {
      ...selected,
      iddistrito: selected?.id,
    } as DestinisAPI;

    if (modalType === MODAL_TYPE.origin) {
      setForm({ ...form, originId: Number(selectValue.iddistrito) });
      dispatch(
        setSelectedUbicationsThunk({
          ...selectedUbications,
          origin: selectValue,
        } as SelectedUbications)
      );
    } else {
      setForm({ ...form, destinyId: Number(selectValue.iddistrito) });
      dispatch(
        setSelectedUbicationsThunk({
          ...selectedUbications,
          destiny: selectValue,
        } as SelectedUbications)
      );
    }
    setOpenModal(false);
  };

  const handleDisabled = () => {
    const { originId, destinyId, originReference, destinyReference } = form;
    const conditions = [originReference, destinyReference, originId, destinyId];

    return conditions.some((condition) => !condition);
  };

  const hookValue: HookProps = {
    modalType,
    modalTitle,
    openModal,
    setOpenModal,
    searchList,
    form,
    setForm,
    getDistrictByIdCity,
    handleInputClick,
    handleOnChange,
    handleSubmit,
    handleTitleSelected,
    handleSetValue,
    handleChangeModal,
    handleSubmitModal,
    handleDisabled,
  };

  return hookValue;
};
