import { Grid, styled } from "@mui/material";
import { Label } from "@/components/form/selectControl/styleSelectControl";

export const Container = styled(Grid)(
  () => `
      display: flex;
      flex-direction: column;  
      margin-top: 24px;
    `
);

export const Form = styled("form")(
  () => `
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 16px;
    `
);

export const ImagesContent = styled("div")(
  () => `
      display: flex;
      flex-direction: row;
      height: 100%;
      background: rgba(0, 0, 0, 0.06);
      height: 76px;
      padding: 8px 12px;
      margin-top: 0px;
      gap: 8px;
    `
);

export const LabelImages = styled(Label)(
  () => `
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 8px;
      `
);
