import { CLIENT_TYPE } from "@/consts/defaultConsts";
import {
  NEGOTATION_COUNTEROFFER,
  NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_DRIVER,
  NEGOTATION_REJECTED_NEGOTATION_MESSAGE_CLIENT,
} from "@/consts/negotationFlowPath";
import { maxLengthAssistant, maxLengthPrice, optionFive, textCannotLowerPrice, textCannotUpperPrice } from "@/consts/noticeConsts";
import { snackbarCounterOfferMsg } from "@/consts/notifficationsMessage";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { ISolicitudViewDetailsResponse } from "@/models/customerRequest/requestDetailsVewResponse";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import { UsuarioAPI } from "@/models/userAuth/user";
import { counterOfferMovingAdapter } from "@/pages/noticeRequestFlow/adapters/noticeRequestFlowAdapters";
import { BodyUploadPriceNegotationPrice } from "@/services/noticeNegotationRequestServices";
import {
  saveDriverNegRequestThunk,
  updatePriceNegotationThunk,
} from "@/store/noticeNegotationRequestRedux/noticeNegRequesThunk";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const idtiposolicitudnegociacion = 4;

interface LocationState {
  editNegotation?: boolean;
}

export const useFormCounterOfferMoving = () => {
  const {
    user: { selectedVehicle, user },
    noticeNegotationRequest: {
      isLoading,
      error,
      isEdition,
      savedDataNogotation,
    },
  } = useAppSelector((state) => state);
  const location = useLocation();
  const { pathname, state } = location;
  const isCounterOffer = pathname.endsWith(NEGOTATION_COUNTEROFFER);
  const { addNotiffication } = useNotiffication();
  const [price, setPrice] = useState("");
  const [errorPrice, setErrorPrice] = useState("");
  const [assistant, setAssistant] = useState("");
  const { idRequest = "", typeRequest = "" } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const vehicleData = { ...selectedVehicle };
  const isClient = (user as UsuarioAPI).tipousuario === CLIENT_TYPE;

  useEffect(() => {
    if (isEdition || !!(state as LocationState)?.editNegotation) {
      setAssistant(`${savedDataNogotation?.ayudantes}`);
      setPrice(`${savedDataNogotation?.precio}`);
    }
  }, []);

  useEffect(() => {
    if (!!error) {
      handleManageDialog();
    }
  }, [error]);

  const handleSubmit = (event: EventType["submit"]) => {
    event.preventDefault();
    if (isLoading) return;
    const priceTosend = Number(price)

    const body = counterOfferMovingAdapter(
      idtiposolicitudnegociacion,
      vehicleData,
      Number(price),
      Number(assistant)
    );

    
    if(priceTosend < optionFive){
      setErrorPrice(textCannotLowerPrice)
      return
    }

    if(price.length > optionFive) {
      setErrorPrice(textCannotUpperPrice)
      return
    }


     dispatch(saveDriverNegRequestThunk(idRequest, body)).then(() =>
      handleNextRute()
    ); 
  };

  const handleNextRute = () => {
    const valueToReplace = typeRequest;
    const idValueToReplace = idRequest.toString();
    dispatch(
      getNextRoute(navigate, {
        valueToSearch: ":typeRequest",
        valueToReplace,
        idToSearch: ":idRequest",
        idValueToReplace,
      })
    );
  };

  const handleDisabledButton = () => {
    const conditions = [selectedVehicle, price, assistant];

    return conditions.some((condition) => !condition);
  };

  const handleOnchangeOne = ({ currentTarget }: EventType["change"]) => {
    const { value } = currentTarget;
    const prepareValue = value.replace(/^(0+)/g, "").replace(/[^0-9]/g, "");

    if (prepareValue.length <= maxLengthAssistant) {
      setAssistant(prepareValue);
    }
  };

  const handleOnchangeTwo = ({ currentTarget }: EventType["change"]) => {
    setErrorPrice("")
    const { value } = currentTarget;
    const prepareValue = value.replace(/^(0+)/g, "").replace(/[^0-9]/g, "");

    if (prepareValue.length <= maxLengthPrice) {
      setPrice(prepareValue);
    }
  };

  const handleManageDialog = () => {
    addNotiffication({
      title: snackbarCounterOfferMsg.TITLE,
      message: snackbarCounterOfferMsg.MESSAGE,
      onSubmit: async () => {},
    });
  };

  const handleCompareInputWithDB = () => {
    const validPrice =
      price === `${(savedDataNogotation as SolicitudNegociacion)?.precio}`;
    const validAssistant =
      assistant ===
      `${(savedDataNogotation as SolicitudNegociacion)?.ayudantes}`;
    return validPrice && validAssistant;
  };

  const handleDisabledEditCounterOfferButton = () => {
    const conditions = [price, assistant];

    if (handleCompareInputWithDB()) return true;

    return conditions.some((condition) => !condition);
  };

  const handleEditCounterOfferSubmit = (event: EventType["submit"]) => {
    event.preventDefault();
    if (isLoading) return;

    const body: BodyUploadPriceNegotationPrice = { precio: Number(price) };
    const pathClient = NEGOTATION_REJECTED_NEGOTATION_MESSAGE_CLIENT.replace(
      ":typeOffer",
      "contraoferta"
    ).replace(":typeRequest", typeRequest);
    const pathDriver =
      NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_DRIVER.replace(
        ":typeOffer",
        "contraoferta"
      ).replace(":typeRequest", idRequest);
    const path = isClient ? pathClient : pathDriver;
    dispatch(updatePriceNegotationThunk(idRequest, body)).then(() =>
      navigate(path, { replace: true, state: { isNavigationInFlow: true } })
    );
  };

  return {
    errorPrice,
    price,
    isLoading,
    idRequest,
    state,
    isEdition,
    isCounterOffer,
    assistant,
    handleSubmit,
    handleDisabledButton,
    handleOnchangeOne,
    handleOnchangeTwo,
    handleDisabledEditCounterOfferButton,
    handleEditCounterOfferSubmit,
  };
};
