import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { ISolicitudViewDetailsResponse } from "@/models/customerRequest/requestDetailsVewResponse";
import { ShowModalType } from "@/pages/customerRequest/createRequest/Load/FormLoad/components/HandleDrawer";
import {
  setActiveButton,
  setLoading,
  setPhotosPreLoaded,
  setSelectTypevehicle,
  setSelectedEstimatedWeightRequest,
  setSelectedTypeLoadRequest,
} from "@/store/customerRequestRedux/customerRequestSlice";
import {
  getCustomerRequestRangeWeightThunk,
  getCustomerRequestTypesLoadThunk,
} from "@/store/customerRequestRedux/customerRequestThunk";
import {
  UploadLoadRequestEditProps,
  uploadLoadRequestEdit,
} from "@/store/noticeNegotationRequestRedux/editNegRequestThunk";
import { getVehicleListThunk } from "@/store/vehicle/vehicleThunk";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useFormEditLoadRequest = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    vehicle: { vehicleList },
    customerRequest: {
      isLoading,
      errorImage,
      requestDetailsView,
      photosPreLoaded,
      selectedTypevehicle,
      selectedEstimatedWeightRequest,
      selectedTypeLoadRequest,
    },
  } = useAppSelector((state) => state);

  const {
    id,
    tipoCarga,
    descripcionCarga,
    tipoVehiculo,
    pesoEstimado,
    idRangoPeso,
    idTipoCarga,
    idTipoVehiculo,
    flgFotosVacio,
    fotos,
  } = requestDetailsView as ISolicitudViewDetailsResponse;

  const initialState = {
    idTipoCarga,
    descripcionCarga,
    idTipoVehiculo,
    idRangoPeso,
  };

  const [form, setForm] = useState(initialState);
  const [images, setImages] = useState<File[]>([]);
  const [showModal, setShowModal] = useState<ShowModalType>({
    open: false,
    type: "",
  });

  useEffect(() => {
    if (!vehicleList.length || !selectedTypevehicle?.id) {
      dispatch(getVehicleListThunk(1));
    }
  }, []);

  useEffect(() => {
    dispatch(getCustomerRequestTypesLoadThunk());
    dispatch(getCustomerRequestRangeWeightThunk());
  }, []);

  useMemo(() => {
    if (!!flgFotosVacio) {
      dispatch(setPhotosPreLoaded([]));
    }
  }, [fotos]);

  useEffect(() => {
    if (idTipoVehiculo) {
      dispatch(
        setSelectTypevehicle({
          id: idTipoVehiculo,
          nombre: tipoVehiculo,
        })
      );
    }

    if (idRangoPeso) {
      dispatch(
        setSelectedEstimatedWeightRequest({
          id: idRangoPeso,
          peso: pesoEstimado,
        })
      );
    }

    if (idTipoCarga) {
      dispatch(
        setSelectedTypeLoadRequest({
          id: idTipoCarga,
          nombre: tipoCarga,
        })
      );
    }
  }, []);

  const handleChange = ({ target }: EventType["change"]) => {
    const { name, value } = target;
  
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleOnChangeImages = (
    images: File[],
    name?: string,
    deleted?: boolean
  ) => {
    if (!!name && !!deleted) {
      const newArrayPhotos = photosPreLoaded.filter(
        (item) => item.name !== name
      );
      dispatch(setPhotosPreLoaded(newArrayPhotos));
    }
    setImages(images);
  };

  const handleDisabledButton = () => {
    const { descripcionCarga } = form;
    const currentData = {
      idTipoCarga: selectedTypeLoadRequest?.id,
      descripcionCarga,
      idTipoVehiculo: selectedTypevehicle?.id,
      idRangoPeso: selectedEstimatedWeightRequest?.id,
    };

    const conditions = [
      tipoCarga,
      descripcionCarga,
      pesoEstimado,
      tipoVehiculo,
      !isLoading,
    ];
    const handleCompareinputs = () => {
      const result =
        JSON.stringify(currentData) === JSON.stringify(initialState);

      return !result;
    };

    const conditionsResult = conditions.some((condition) => !condition);
    const sameData = handleCompareinputs();
    dispatch(setActiveButton(sameData));

    let result = true;

    if (sameData) {
      result = false;
      if (conditionsResult) {
        result = true;
      }

      return result;
    }

    return result;
  };

  const handleSubmit = (event: EventType["submit"]) => {
    event.preventDefault();
    if (isLoading) return;
    const { descripcionCarga } = form;

    const data = {
      idTipoCarga: selectedTypeLoadRequest?.id,
      descriptionLoadInput: descripcionCarga,
      idTypeVehicle: selectedTypevehicle?.id,
      idRangoPeso: selectedEstimatedWeightRequest?.id,
      images: images,
    };

    dispatch(
      uploadLoadRequestEdit(`${id}`, data as UploadLoadRequestEditProps)
    ).then(() => {
      dispatch(setLoading(true));
      navigate(-1);
    });
  };

  return {
    form,
    setForm,
    tipoCarga,
    showModal,
    setShowModal,
    isLoading,
    errorImage,
    fotos,
    photosPreLoaded,
    selectedTypevehicle,
    selectedEstimatedWeightRequest,
    selectedTypeLoadRequest,
    flgFotosVacio,
    dispatch,
    handleChange,
    handleDisabledButton,
    handleOnChangeImages,
    handleSubmit,
  };
};
