import Resizer from "react-image-file-resizer";

type ImageFileResizer = {
  file: File;
  compressFormat?: string;
  quality?: number;
  maxWidth?: number;
  maxHeight?: number;
  rotation?: number;
  outputType?: string;
  minWidth?: number;
  minHeight?: number;
};

const useResizeImage = () => {
  const handleImageFileResizer = async (config: ImageFileResizer) => {
    const {
      file,
      compressFormat = "PNG",
      quality = 50,
      maxWidth = 960,
      maxHeight = 720,
      rotation = 0,
      outputType = "file",
    } = config;

    try {
      return await new Promise<File>((resolve) => {
        Resizer.imageFileResizer(
          file,
          maxWidth,
          maxHeight,
          compressFormat,
          quality,
          rotation,
          handleResolveFunction(resolve),
          outputType
        );
      });
    } catch (error) {
      throw new Error("Error al cargar la imagen");
    }
  };

  const handleResolveFunction = (
    resolve: (value: File | PromiseLike<File>) => void
  ) => {
    return async (value: string | File | Blob | ProgressEvent<FileReader>) => {
      resolve(value as File);
    };
  };

  return {
    handleImageFileResizer,
  };
};

export default useResizeImage;
