import {
  DetailNoticeMoving,
  DetailsNegotationV2,
} from '@/models/notice/noticeV2NegotationModels';
import { IUser } from '@/store/userRedux/userReduxInterface';
import {
  AGREEMENT_MESSAGE_COUNTEROFFER_DRIVER,
  DECLINE_COCOUNTEROFFER,
  DECLINE_MESSAGE_COUNTEROFFER_DRIVER,
  DECLINE_OFFER_DRIVER,
  MESSAGE_AGREEMENT_OFFER,
  MESSAGE_CONFIRMATION_CO_COUNTER_OFFER_CLIENT,
} from '../const/routes';

export const validUserNoticeNegV2 = (
  offert: DetailsNegotationV2,
  user: IUser
) => {
  const { id } = user;

  return id === offert.cliente.id;
};

export const getInfoVehicleNegV2 = (
  negotiation: DetailsNegotationV2,
  user: IUser
) => {
  const { vehiculo } = negotiation.aviso;
  const typeVehicle = vehiculo?.tipovehiculo || '';
  const placaNumber = vehiculo?.placa;
  const isClient = validUserNoticeNegV2(negotiation, user);

  if (placaNumber) {
    const placa = isClient ? '' : `(${placaNumber})`;
    return `${typeVehicle} ${placa}`;
  }

  return typeVehicle;
};

export const getVehicleValueNotificationNoticeNeg = (
  negotiation: DetailsNegotationV2
) => {
  const { tipovehiculo } = negotiation.aviso.vehiculo;

  const title = tipovehiculo ? `${tipovehiculo}  ` : '';

  return title;
};

export const getMaximumCapacity = (negotiation: DetailsNegotationV2) => {
  const { cargamaxima } = negotiation.aviso.vehiculo;
  const value = !!cargamaxima ? `Capacidad máxima de ${cargamaxima} kilos` : '';
  return value;
};

export const getValueInNegotationV2 = (
  negotiation: DetailsNegotationV2,
  aditionalsParams?: any
) => {
  const value = searchPropertyInObjectNegotationV2(
    aditionalsParams,
    negotiation
  );

  return value;
};

export const searchPropertyInObjectNegotationV2 = (
  arrayToSearch: string[],
  objectData: Record<string, any>
) => {
  const result = arrayToSearch.reduce((acc, el) => {
    return acc[el];
  }, objectData);

  return result || '';
};

export const getUrlAvatarProfileNoticeNeg = (
  negotiation: DetailsNegotationV2,
  user: IUser
) => {
  const isClient = validUserNoticeNegV2(negotiation, user);
  let avatarUrl = negotiation?.cliente.perfilUrl;
  if (isClient) {
    avatarUrl = negotiation?.aviso?.transportista?.perfilUrl;
  }

  return avatarUrl;
};

export const getUserInfoNoticeNegV2 = (
  negotiation: DetailsNegotationV2,
  user: IUser
) => {
  const isClient = validUserNoticeNegV2(negotiation, user);
  let userInfo = negotiation?.cliente;
  if (isClient) {
    userInfo = negotiation?.aviso?.transportista;
  }

  return userInfo;
};

export const getImageTruckInRatingNoticeNegV2 = (
  negotiation: DetailsNegotationV2,
  user: IUser
) => {
  const isClient = validUserNoticeNegV2(negotiation, user);
  if (!isClient) return '';
  const { vehiculo } = negotiation.aviso;
  // const [imageTruck] = vehiculo?.vehiculofotos as PhotosVehicleAPI[];

  return vehiculo.foto as string;
};

/* ====================================================== */

export const getFormatMetroNegV2 = (negotiation: DetailsNegotationV2) => {
  const newData = negotiation as DetailNoticeMoving;
  const { metraje } = newData;
  if (!metraje) return '';

  return `${metraje} m².`;
};

export const renderTitleLongOriginNegV2 = (
  negotiation: DetailsNegotationV2
) => {
  const newData = negotiation as DetailNoticeMoving;
  const {
    pisoOrigen,
    flgAscensorOrigen,
    origenTexto,
    referenciaOrigen,
    inmuebleOrigen,
  } = newData;

  const textPrisoOrigen = !!pisoOrigen
    ? ` en piso ${pisoOrigen as number} (${handleShowAscensor(
        flgAscensorOrigen as number
      )})`
    : '';

  return [
    ` ${origenTexto}${handleReferencia(referenciaOrigen)}.`,
    `${inmuebleOrigen}${textPrisoOrigen}.`,
  ];
};

export const renderTitleLongDestinyNegV2 = (
  negotiation: DetailsNegotationV2
) => {
  const newData = negotiation as DetailNoticeMoving;
  const {
    inmuebleDestino,
    pisoDestino,
    flgAscensorDestino,
    destinoTexto,
    referenciaDestino,
  } = newData;

  const pisoDestinoText = !!pisoDestino
    ? ` en piso ${pisoDestino} (${handleShowAscensor(
        flgAscensorDestino as number
      )})`
    : '';

  return [
    ` ${destinoTexto}${handleReferencia(referenciaDestino)}.`,
    `  ${inmuebleDestino}${pisoDestinoText}.`,
  ];
};

const handleShowAscensor = (flg: number) => {
  let value = 'ascensor disponible para algunas cosas';
  if (flg === 1) value = 'ascensor disponible';
  if (flg === 2) value = 'sin ascensor';

  return value;
};

export const handleReferencia = (referencia: string) => {
  return referencia ? ` (${referencia})` : ` ${referencia}`;
};

/* ====================================================== */

export const urlNavigateNoticeConfigV2: Record<number, any> = {
  //Rutas de la oferta por parte del transportista:
  1: {
    //Si el transportista recibe una acetación de oferta:
    1: MESSAGE_AGREEMENT_OFFER, // ruta de acuerdo mensaje
    2: DECLINE_OFFER_DRIVER, // ruta de rechazo mensaje
  },

  2: {
    //Si el transportista recibe una contraoferta:
    1: AGREEMENT_MESSAGE_COUNTEROFFER_DRIVER, // ruta de acuerdo mensaje
    2: DECLINE_MESSAGE_COUNTEROFFER_DRIVER, // ruta de rechazo mensaje

    //Si el cliente recibe una contraoferta por parte del transportista puede:
    3: MESSAGE_CONFIRMATION_CO_COUNTER_OFFER_CLIENT, // ruta de acuerdo mensaje
  },
  5: {
    1: MESSAGE_AGREEMENT_OFFER,
    2: DECLINE_COCOUNTEROFFER,

    //Si el cliente recibe una contraoferta por parte del transportista puede:
    3: MESSAGE_CONFIRMATION_CO_COUNTER_OFFER_CLIENT, // ruta de acuerdo mensaje
  },
};

export const nextUrlFormatV2 = (
  typeId: string,
  actionId: number,
  option: number
) => {
  const path = urlNavigateNoticeConfigV2[actionId][option];
  const url = path.replace(':idOffer', typeId);

  return url;
};

export const dataDriverFormatV2 = (negotiation: DetailsNegotationV2) => {
  const driver = negotiation.aviso.transportista;

  return {
    idUser: driver?.id,
    userName: driver?.nombre as string,
    urlProfile: driver?.perfilUrl,
    rating: driver?.calificacion,
    ratingText: driver?.calificacionTexto,
    showPrice: false,
    showTwoAvatars: false,
    sx: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
};

export const dataProfileFormatV2 = (negotiation: DetailsNegotationV2) => {
  const client = negotiation.cliente;

  const dataProfile = {
    idUser: client?.id,
    userName: client?.nombre as string,
    urlProfile: client?.perfilUrl,
    rating: client?.calificacion,
    ratingText: client?.calificacionTexto,
    showPrice: false,
    showTwoAvatars: false,
    sx: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };

  return dataProfile;
};
