import { connection } from "@/services/connection";
import { NOTICE_LIST_CITY_SUGGESTED, NOTICE_LIST_DISTRICT_SUGGESTED } from "../../../consts/pathServices";
import { ICitiesSuggested, IDistrictsSuggested } from './noticeListSuggestedTypes';
const API = connection();

export const noticeListDataDistrictSuggested = async (id: number, idTypeNotice?: number): Promise<IDistrictsSuggested> => {
    let path = NOTICE_LIST_DISTRICT_SUGGESTED.replace(':id', id.toString());
    // ?idtipoaviso=isTypeNotice
    if(idTypeNotice) {
        path = `${path}?idtipoaviso=${idTypeNotice}`;
    }
    const { data } = await API.get(path);

    return data;
}

export const noticeListDataCitySuggested = async (id: number) : Promise<ICitiesSuggested> => {
    const { data } = await API.get(NOTICE_LIST_CITY_SUGGESTED.replace(':id', id.toString()));

    return data;
}