import { ID_EXPRESS_FLOW, ID_MULTI_DESTINY } from '@/consts/defaultConsts';
import { optionOne, optionTwo } from '@/consts/noticeConsts';
import {
  REQUEST_TYPE_MOVE_BETWEEN_CITIES,
  REQUEST_TYPE_MOVE_CITY,
  REQUEST_TYPE_MULTI_DESTINY_HOUR,
  REQUEST_TYPE_MULTI_DESTINY_POINT,
} from '@/consts/requestConsts';
import { CustomerRequest } from '@/models/customerRequest/customerRequestResponse';
import {
  ICondicione,
  ISolicitudViewDetailsResponse,
} from '@/models/customerRequest/requestDetailsVewResponse';
import { UsuarioAPI } from '@/models/userAuth/user';
import { dataProfileFormat } from '@/pages/acceptanceOffert/adapters/AcceptanceAdapter';
import { IUser } from '@/store/userRedux/userReduxInterface';
import { IconTypes } from '@/utils/Icon';
import {
  currencyFormat,
  formatDate,
  stringHoursToFormatLT,
} from '@/utils/helpers';
import { configDetailsByTypeFlow } from './byTypes';
import dayjs from 'dayjs';
import { FORMAT_DATE_DDMMYYYY, FORMAT_DATE_V2 } from '@/consts/formatsDate';

export const getPriceFormat = (
  value: any,
  application: ISolicitudViewDetailsResponse
) => {
  const formatPrice = currencyFormat(value);
  return formatPrice;
};

export const getTypeMultidestinyName = (
  value: any,
  application: ISolicitudViewDetailsResponse
) => {
  const { flgHoraoPunto } = application;
  let text = 'Por hora';
  if (flgHoraoPunto === 2) {
    text = 'Por punto';
  }
  return text;
};

export const getTypeAcountMultiDestiny = (
  value: any,
  application: ISolicitudViewDetailsResponse
) => {
  if (application?.horasRequeridas) {
    return 'Cantidad de horas: ';
  }

  return 'Cantidad de puntos: ';
};

export const getValueTypeAcountMultidestiny = (
  value: any,
  application: ISolicitudViewDetailsResponse
) => {
  return application?.horasRequeridas || application?.puntosRequeridos;
};

export const renderTitleByTypeRequest = (
  value: any,
  application: ISolicitudViewDetailsResponse
) => {
  const {
    flgHoraoPunto,
    flgCiudadoEntreciudades,
    tipoSolicitud: { id: tipoSolicitudId },
  } = application;
  let { title } = configDetailsByTypeFlow[Number(tipoSolicitudId)];
  if (!!flgHoraoPunto && tipoSolicitudId === ID_MULTI_DESTINY) {
    if (flgHoraoPunto === optionOne) {
      title = REQUEST_TYPE_MULTI_DESTINY_HOUR;
    } else {
      title = REQUEST_TYPE_MULTI_DESTINY_POINT;
    }
  }

  if (flgCiudadoEntreciudades === optionOne) {
    title = REQUEST_TYPE_MOVE_CITY;
  } else if (flgCiudadoEntreciudades === optionTwo) {
    title = REQUEST_TYPE_MOVE_BETWEEN_CITIES;
  }

  return title;
};

export const renderIconByTypeRequest = (
  value: any,
  application: ISolicitudViewDetailsResponse
): IconTypes => {
  const {
    flgCiudadoEntreciudades,
    tipoSolicitud: { id: tipoSolicitudId },
  } = application;
  let { icon } = configDetailsByTypeFlow[Number(tipoSolicitudId)];

  if (flgCiudadoEntreciudades === optionOne) {
    icon = 'icon-moving';
  }

  return icon;
};

export const renderTitleOrigenAndDestiny = (
  value: any,
  application: ISolicitudViewDetailsResponse
) => {
  const TITLE_TEMPLATE = 'De $origin a $destiny';
  const {
    origenTexto,
    destinoTexto,
    tipoSolicitud: { id: tipoSolicitudId },
  } = application;
  let title = TITLE_TEMPLATE.replace('$origin', origenTexto).replace(
    '$destiny',
    destinoTexto
  );

  if (tipoSolicitudId === ID_MULTI_DESTINY) {
    title = origenTexto;
  }

  return title;
};

export const renderTypeVehicle = (
  value: any,
  application: ISolicitudViewDetailsResponse
) => {
  const { tipoVehiculo } = application;
  return tipoVehiculo || '';
};

export const handleFormatSendDateRequestNeg = (
  value: any,
  application: ISolicitudViewDetailsResponse
) => {
  const fecha = application?.fecha;

  try {
    const yourDate = dayjs(fecha, FORMAT_DATE_DDMMYYYY);

    const newDate = `${dayjs(yourDate).format(FORMAT_DATE_V2)}`;
    const result = newDate.charAt(0).toUpperCase() + newDate.slice(1);

    return result;
  } catch (err) {
    return fecha;
  }
};

export const renderTitleLongOrigin = (
  value: any,
  application: ISolicitudViewDetailsResponse
) => {
  const {
    tipoInmueble,
    pisoOrigen,
    flgAscensorOrigen,
    origenTexto2,
    origenTexto,
    referencia,
  } = application;
  let msgAscensor = 'ascensor disponible para algunas cosas';
  let msgType = tipoInmueble;

  if (flgAscensorOrigen === optionOne) {
    msgAscensor = 'con ascensor';
  } else if (flgAscensorOrigen === optionTwo) {
    msgAscensor = 'sin ascensor';
  }

  if (flgAscensorOrigen) {
    msgType = `${tipoInmueble} en piso ${pisoOrigen} (${msgAscensor})`;
  }

  const referenciaText = referencia ? `(${referencia})` : '';

  const desc = `
  • ${origenTexto2 || origenTexto} ${referenciaText}</br>
  • ${msgType}</br>
  `;

  return desc;
};

export const renderTitleLongDestiny = (
  value: any,
  application: ISolicitudViewDetailsResponse
) => {
  const {
    tipoInmuebleDestino,
    pisoDestino,
    flgAscensorDestino,
    destinoTexto2,
    destinoTexto,
    referenciaDestino,
  } = application;

  let msgAscensor = 'ascensor disponible para algunas cosas';
  let msgType = tipoInmuebleDestino;

  if (flgAscensorDestino === optionOne) {
    msgAscensor = 'con ascensor';
  } else if (flgAscensorDestino === optionTwo) {
    msgAscensor = 'sin ascensor';
  }

  if (flgAscensorDestino) {
    msgType = `${tipoInmuebleDestino} en piso ${pisoDestino} (${msgAscensor})`;
  }

  const referenciaText = referenciaDestino ? `(${referenciaDestino})` : '';
  const desc = `
  • ${destinoTexto2 || destinoTexto} ${referenciaText}</br>
  • ${msgType}</br>
  `;
  return desc;
};

export const renderTitleAmbiente = (
  value: any,
  application: ISolicitudViewDetailsResponse | any
) => {
  const { metraje, tipoInmueble } = application;

  return `En ${tipoInmueble?.toLowerCase()} de ${metraje} m².`;
};

export const renderAmbientes = (
  value: any,
  application: ISolicitudViewDetailsResponse
) => {
  const { ambientes } = application;

  return ambientes
    ?.map((ambiente) => {
      return !!ambiente.cantidad
        ? ` - ${ambiente.ambiente}: ${ambiente.cantidad}</br>`
        : '';
    })
    .join('');
};

export const renderRating = (
  user: IUser,
  application: ISolicitudViewDetailsResponse | any
) => {
  return dataProfileFormat(application, user);
};

type TDateExpired = 'title' | 'date';
export const renderExpiredHoursExpress =
  (typeFlow?: TDateExpired) =>
  (selectedTypeRequest: any, value: any, application: CustomerRequest) => {
    const { fecha, hora, tipoSolicitud } = application;
    const tipoSolicitudId = Number(tipoSolicitud.id);

    if (typeFlow === 'title' && tipoSolicitudId === ID_EXPRESS_FLOW) {
      return 'Expira en';
    }
    if (typeFlow === 'date' && tipoSolicitudId === ID_EXPRESS_FLOW) {
      return `${fecha} a las ${stringHoursToFormatLT(hora as string)}`;
    }
  };

export const getConditionsInViewDetails = (
  value: any,
  application: ISolicitudViewDetailsResponse,
  user: IUser | UsuarioAPI | {}
) => {
  const { condiciones, cliente } = application;

  let isOwner = false;
  if (Object.keys(user).length) {
    isOwner = (user as IUser).id === cliente?.id;
  }

  const conditions = condiciones?.map((condicion) => {
    if (typeof condicion === 'string') {
      return condicion;
    }

    if (isOwner) {
      return (condicion as ICondicione).propietario;
    }

    return (condicion as ICondicione).visitante;
  });

  return conditions;
};
