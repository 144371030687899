import HeaderListItem from "@/components/headerListItem/HeaderListItem";
import HeaderListItemDisplay from "@/components/headerListItem/HeaderListItemDisplay";
import { MessageOffline } from "@/components/messageOffline";
import { SHARED_RIDE } from "@/consts/typeServicesPaths";
import useResize from "@/hooks/useResize";
import { Close, Menu } from "@mui/icons-material";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BrandIcon from "./components/BrandIcon";
import ToolBarHeader from "./components/ToolBarHeader";
import {
  headerIconBrand,
  headerIconMenu,
  headerListItems,
  headerListItemsDisplay,
} from "./configData";
import { useAppSelector } from "@/hooks/useReducer";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { CLIENT_TYPE } from "@/consts/defaultConsts";

const SHARED_RIDE_PATH = SHARED_RIDE.replace(":idSharedRide", "");

const headerBlackList = [SHARED_RIDE_PATH];

const imDriverText = "SOY TRANSPORTISTA";

const Header = () => {
  const {
    user: { user },
  } = useAppSelector((state) => state);
  const [isShown, setStatus] = useState(false);
  const [width] = useResize();
  const [isOpenLanguageModal, setIsOpenLanguageModal] = useState(false);
  const [isShow, setIsShow] = useState(true);
  const notLaptop = (width as number) < 1032;
  const typeUser = (user as IUser)?.tipousuario === CLIENT_TYPE;

  const { pathname } = useLocation();

  useEffect(() => {
    if (isShown && notLaptop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isShown, notLaptop]);

  useEffect(() => {
    const isHidden = headerBlackList.some((path) => pathname.includes(path));

    setIsShow(!isHidden);
  }, [pathname]);

  const handleList = () => {
    if (typeUser) {
      return headerListItemsDisplay.filter(
        (item) => item.title !== imDriverText
      );
    }

    return headerListItemsDisplay;
  };

  if (!isShow) return <></>;

  return (
    <AppBar
      position={isShown && notLaptop ? "fixed" : "relative"}
      color="secondary"
    >
      <MessageOffline />
      {/*       <LanguageModal
        open={isOpenLanguageModal}
        onClose={setIsOpenLanguageModal}
      /> */}
      <Container
        disableGutters
        sx={{ padding: "0px !important" }}
        maxWidth="xl"
      >
        <ToolBarHeader>
          {/* LOGO PORTATIL - INICIO */}
          <BrandIcon
            route={headerIconBrand.route}
            onClose={setStatus}
            icon={notLaptop ? headerIconBrand.icon.xs : headerIconBrand.icon.md}
          />

          {/*PAGES */}
          <HeaderListItem
            listItems={headerListItems}
            onClose={setStatus}
            setIsOpenLanguageModal={setIsOpenLanguageModal}
          />

          {/* MENU ICON  */}
          <IconButton
            onClick={() => setStatus((current) => !current)}
            sx={headerIconMenu.style}
          >
            {!isShown ? <Menu /> : <Close />}
          </IconButton>
        </ToolBarHeader>

        {/* MENU OPEN IF */}
        {isShown && (
          <HeaderListItemDisplay
            listItems={handleList()}
            onClose={setStatus}
            setIsOpenLanguageModal={setIsOpenLanguageModal}
          />
        )}
      </Container>
    </AppBar>
  );
};

export default Header;
