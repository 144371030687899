import { CustomerRequestPathTypes } from '../../shared/interface/customerRequestInterface';

export const customerRequestTitle: CustomerRequestPathTypes = {
  1: {
    title: 'Carga y vehículo',
    subTitle: 'Solicitar transporte en la ciudad',
  },
  2: {
    title: 'Carga y vehículo',
    subTitle: 'Solicitar multidestino en la ciudad',
  },
  3: {
    title: 'Carga y vehículo',
    subTitle: 'Solicitar servicio entre ciudades',
  },
  4: {
    title: 'Carga y vehículo',
    subTitle: 'Solicitar servicio express',
  },
  5: {
    title: 'Carga y vehículo',
    subTitle: 'Solicitar mudanza',
  },
};
