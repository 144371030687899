import dayjs from 'dayjs';
import { FORMAT_DATE_FILTER, FormDataFilter } from '../consts/filtersConst';

const textQuoteBetweenCities = 'Remate entre ciudades';

export const handleDateAdapter = (formData: FormDataFilter) => {
  let date = dayjs(formData?.timeService).format(FORMAT_DATE_FILTER);
  let objectValue = formData;

  if (formData.typeNotice !== textQuoteBetweenCities) {
    const timeService = date.charAt(0).toUpperCase() + date.slice(1);
    objectValue = {
      origin: formData.origin,
      destiny: formData.destiny,
      timeService,
      weightOfTheLoad: formData.weightOfTheLoad,
      typeVehicle: formData.typeVehicle,
      typeServiceList: formData.typeServiceList,
      city: '',
      betweenDate: '',
    };
  } else {
    objectValue = {
      origin: formData.origin,
      destiny: formData.destiny,
      betweenDate: formData.betweenDate,
      weightOfTheLoad: formData.weightOfTheLoad,
      typeVehicle: formData.typeVehicle,
      typeServiceList: formData.typeServiceList,
      city: '',
      timeService: '',
    };
  }

  return objectValue;
};

export const handleTextAdapter = (value: string | string[]) => {
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  return value;
};
