import TwoAvatars from "@/components/userRating/TwoAvatars";
import HistoryRating from "@/components/userRating/historyRating/HistoryRating";
import { RatingStyles } from "@/components/userRating/styledUserRating";
import { useAppSelector } from "@/hooks/useReducer";
import { Negotiation } from "@/models/offert/offertModels";
import { characterCut, resizePhoto } from "@/utils/helpers";
import {
  Avatar,
  Box,
  Grid,
  Rating,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { dataDriverFormat } from "../adapters/AcceptanceAdapter";
import { IMG_PATH } from "@/consts/defaultConsts";
import { useLocation } from "react-router-dom";
import {
  NOTICE_AGREEMENT_CONFIRMATION,
  NOTICE_REJECTION_CONFIRMATION,
} from "@/consts/acceptanceOffertPath";
import { UsuarioAPI } from "@/models/userAuth/user";
import { dataProfileFormat } from "../adapters/AcceptanceAdapter";
import StarIcon from "@mui/icons-material/Star";

type Props = {
  urlProfile: string;
  urlTruck?: string;
  showTwoAvatars: boolean;
  userName: string;
  containerSx?: SxProps<Theme>;
  viewRating?: boolean;
  userInfo?: {
    calificacion: number;
    calificacionTexto: string;
    id: number;
    nombre: string;
    perfilUrl: string;
  };
};

const SingleUserRatingAcceptance = (props: Props) => {
  const {
    urlProfile,
    userName,
    containerSx,
    urlTruck,
    showTwoAvatars = false,
    viewRating = true,
    userInfo,
  } = props;

  const [openRating, setOpenRating] = useState<boolean>(false);
  const {
    user: { user },
    offert: { isLoading, savedOffert },
  } = useAppSelector((state) => state);
  const theme = useTheme();
  const { pathname } = useLocation();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const { avatarStyles, gridContainerStyles, gradeTextStyles } = RatingStyles;
  const flgIsOwner = !!(savedOffert as Negotiation)?.flgIsOwner as boolean;
  const isConfitmation =
    pathname.endsWith(NOTICE_AGREEMENT_CONFIRMATION) ||
    pathname.endsWith(NOTICE_REJECTION_CONFIRMATION);

  const handleData = () => {
    if ((user as UsuarioAPI).tipousuario === "T") {
      return dataProfileFormat(savedOffert as Negotiation);
    }
    return dataDriverFormat(savedOffert as Negotiation);
  };

  const urlUser = !!urlProfile
    ? resizePhoto({ photo: IMG_PATH + urlProfile })
    : IMG_PATH + urlProfile;

  const onOpenRating = () => {
    if (!viewRating) return;
    setOpenRating(true);
  };

  const onCloseRating = () => {
    setOpenRating(false);
  };

  if ((flgIsOwner && !isConfitmation) || isLoading) return <></>;

  return (
    <>
      <Grid
        container
        sx={{
          alignItems: "center",
          maxWidth: matches ? "65%" : "100%",
          cursor: viewRating ? "pointer" : "default",
          ...containerSx,
        }}
        onClick={onOpenRating}
      >
        <Grid item xs={3}>
          {showTwoAvatars ? (
            <TwoAvatars primaryAvatar={urlUser} secondAvatar={urlTruck} />
          ) : (
            <Avatar src={urlUser} sx={avatarStyles} />
          )}
        </Grid>
        <Grid item xs={9} sx={gridContainerStyles}>
          <Typography
            sx={{
              color: viewRating ? "primary.main" : "",
            }}
            variant="h6"
          >
            {characterCut(userInfo?.nombre || "", matches ? 35 : 25)}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Rating
              readOnly
              name="customized-10"
              defaultValue={2}
              max={1}
              size="small"
              sx={!!userInfo?.calificacion ? {} : { color: "#9e9e9e" }}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            <Typography variant="caption" sx={gradeTextStyles}>
              {!!userInfo?.calificacion && userInfo?.calificacion}{" "}
              {userInfo?.calificacionTexto}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <HistoryRating
        open={openRating}
        onClose={onCloseRating}
        userData={handleData()}
      />
    </>
  );
};

export default SingleUserRatingAcceptance;
