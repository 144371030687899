import BasicModal from "@/components/dialog/Notiffication";
import HeaderSection from "@/components/headerSection/HeaderSection";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import SnackbarNotification from "@/components/snackbar/Snackbar";
import { NEGOTATION_MOVING } from "@/consts/negotationFlowPath";
import { useAppSelector } from "@/hooks/useReducer";
import { Container, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import useUnsavedAndSavedChanges from "../../hooks/useUnsavedAndSavedChanges";

export interface HeaderSection {
  icon: string;
  iconSection: string;
  title: string;
  subTitle: string;
}

const headerSection: HeaderSection = {
  icon: "icon-arrow-left--primary",
  iconSection: "icon-money",
  title: "Precio",
  subTitle: "Editar",
};

const headerSectionMoving: HeaderSection = {
  icon: "icon-arrow-left--primary",
  iconSection: "icon-box",
  title: "Cantidad de ambientes",
  subTitle: "Solicitar mudanza",
};

const emptyHeaderSection: HeaderSection = {
  icon: "",
  iconSection: "",
  title: "",
  subTitle: "",
};

const ContainerArea = styled(Container)(
  () => `
      padding: 16px 0;
    `
);

const Price = () => {

  const {handleManageModalUnsavedChanges, handleManageErrorAPISnackbar, handleSnackbarError } = useUnsavedAndSavedChanges()
  const navigate = useNavigate();
  const { customerRequest } = useAppSelector((state) => state);
  const activeButton = customerRequest.activeButton
  const error = customerRequest.error
  const [textToRender, setTextToRender] = useState(emptyHeaderSection);
  const { typeRequest = "" } = useParams();

  useEffect(() => {
    if (!!error) {
      handleManageErrorAPISnackbar();
      handleSnackbarError();
    }
  }, [error]);


  useEffect(() => {
    let objTextToRender = headerSection;
    if (typeRequest === NEGOTATION_MOVING) {
      objTextToRender = headerSectionMoving;
    }
    setTextToRender(objTextToRender);
  }, []);



  const handlePrevRute = () => {
    if(activeButton){
      !!error ?  navigate(-1) : handleManageModalUnsavedChanges()
    }else{
      navigate(-1);
    }
    
  };

  return (
    <>
    <Container maxWidth="xs">
      <HeaderSection
        icon={textToRender.icon}
        title={textToRender.title}
        subTitle={textToRender.subTitle}
        onClick={handlePrevRute}
      />
       {!!error && <SnackbarNotification/>}
      <ContainerArea>
        <SectionIcon icon={textToRender.iconSection} />
        <Outlet />
      </ContainerArea>
    </Container>
    <BasicModal/>
    </>
  );
};

export default Price;
