import React from "react";
import { DriverSuccessDetailsLayout } from "./DriverSuccessDetailsLayout";
import {
  ID_AUCTION_FLOW,
  ID_BETWEEN_CITIES,
  ID_IN_CITY,
  ID_MOVING_FLOW,
  ID_MULTI_DESTINY,
} from "@/consts/defaultConsts";
import { InTheCitySuccessDetails } from "./containers/InTheCitySuccessDetails/Index";
import { BtnCitiesSuccessDetails } from "./containers/BtnCitiesSuccessDetails/Index";
import { MultidestinySuccessDetails } from "./containers/MultidestinySuccessDetails/Index";
import { MovingSuccessDetails } from "./containers/MovingSuccessDetails/Index";
import { AuctionSuccessDetails } from "./containers/AuctionSuccessDetails/Index";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import useGetData from "../../shared/hooks/useGetData";

export const componentToRender: Record<number, () => JSX.Element> = {
  [ID_IN_CITY]: InTheCitySuccessDetails,
  [ID_BETWEEN_CITIES]: BtnCitiesSuccessDetails,
  [ID_MULTI_DESTINY]: MultidestinySuccessDetails,
  [ID_MOVING_FLOW]: MovingSuccessDetails,
  [ID_AUCTION_FLOW]: AuctionSuccessDetails,
};

export const PrincipalComponentDriverSuccess = () => {
  const { responseData } = useGetData();

  const Component =
    componentToRender[(responseData as NoticeV2NegType).idtipoaviso as number];

  if (!Component) return <></>;

  return (
    <DriverSuccessDetailsLayout>
      <Component />
    </DriverSuccessDetailsLayout>
  );
};
