import Icon, { IconTypes } from "@/utils/Icon";
import { currencyFormat } from "@/utils/helpers";
import {
  Box,
  CardContent,
  CardMedia,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { CustomCard } from "../Card/CustomCard";
import useCardMyRequestHook from "./hook";
import { CardOriginText, CardTitleType, StateText } from "./style";

export interface ICardMyRequestProps {
  img?: string;
  icon: IconTypes;
  title: string;
  origin: string;
  date: string;
  vehicle: string;
  peso: string;
  price: number;
  precioDetalle?: string;
  state?: string;
  stateColor?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const CardMyRequest = ({
  img = "",
  icon,
  title = "En la ciudad",
  origin,
  date,
  vehicle,
  peso,
  price,
  precioDetalle,
  disabled,
  onClick,
  state,
  stateColor,
}: ICardMyRequestProps) => {
  const { validateImg, handleImageError } = useCardMyRequestHook();

  return (
    <CustomCard sx={{ height: "334px" }} onClick={onClick}>
      <CardMedia
        sx={{ objectFit: "cover", cursor: "pointer" }}
        component="img"
        width="100%"
        height="170px"
        image={validateImg(img)}
        alt={img}
        onError={handleImageError}
      />
      <CardContent
        sx={{
          padding: "8px 16px",
          gap: "4px",
        }}
      >
        <Box 
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="8px"
        >
          <Stack flexDirection="row" gap="8px" alignItems="center">
            <Icon name={icon || "icon-building"} size={20} />
            <CardTitleType variant="caption">{title}</CardTitleType>
          </Stack>
          <StateText variant="caption" color={stateColor}>
            {state}
          </StateText>
        </Box>
        <CardOriginText variant="h6">{origin}</CardOriginText>
        <Typography variant="caption" fontSize="12px" color="#757575">
          {date}
        </Typography>
        <>
          <Box display="flex" alignItems="center" gap="4px" mt={"4px"}>
            <Typography variant="body2">{vehicle}</Typography>
            {!!peso && (
              <Typography variant="caption" color="#757575">
                |
              </Typography>
            )}
            <Typography variant="body2">{peso}</Typography>
          </Box>
        </>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mt={"6px"}
          gap="16px"
        >
          <Box
            gap="4px"
            alignItems={"center"}
            flexDirection={"row"}
            display={"flex"}
          >
            <Typography variant="h5" display="flex" alignItems="center">
              {!!price && currencyFormat(price)}
            </Typography>
            <Typography variant="caption" fontSize="12px" color="#757575">
              {precioDetalle}
            </Typography>
          </Box>
          <IconButton disabled={disabled} aria-label="back">
            <Icon
              name="icon-arrow-right"
              size={24}
              color={disabled ? "disabled" : "org"}
            />
          </IconButton>
        </Stack>
      </CardContent>
    </CustomCard>
  );
};

export default CardMyRequest;
