import { FORMAT_DATE_V2 } from "@/consts/formatsDate";
import { stringDDMMYYYToDate } from "@/utils/helpers";
import DefaultFormat from "./components/DefaultFormat";
import MultidestinyFormat from "./components/MultidestinyFormat";
import TextArrayFormat from "./components/TextArrayFormat";
import { DetailsTextProps } from "./interfaces";

const hoursAmount = "Cantidad de horas";

export const useManageComponent = (props: DetailsTextProps) => {
  const {
    title,
    origen,
    icon,
    isMultidestiny,
    originDescription,
    containerSx,
    sxDescription,
    parent
  } = props;


  const handleCapitalizeText = (str: string) => {
    
    if (title === "Fecha" || title === "Fecha del servicio") {
      const value = stringDDMMYYYToDate(str).format(FORMAT_DATE_V2);

      return value.charAt(0).toUpperCase() + value.slice(1);
    }

    return str;
  };

  const properties = {
    title: title,
    icon: icon,
    capitalizeText: handleCapitalizeText(origen as string),
    containerSx,
    sxDescription,
    parent
  };

  const handleComponentToRender = () => {
    let Component = (
      <DefaultFormat
        {...properties}
        originDescription={originDescription}
        hoursAmount={hoursAmount}
      />
    );

    if (isMultidestiny) Component = <MultidestinyFormat {...properties} />;
    if (Array.isArray(origen))
      Component = <TextArrayFormat title={title} origen={origen} icon={icon} />;

    return Component;
  };

  const ComponentToRender = handleComponentToRender;

  return { ComponentToRender };
};
