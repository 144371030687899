import { Button, styled, Typography } from "@mui/material";

export const StyledButtomLoader = styled(Button)(
    () => `
    border-radius: 44px;
    padding: 10px 16px;
    width: 100%;
    line-height: 24px;
    margin-top: 16px;
    `
  );
  
  export const StyledTxt = styled(Typography)(
    () => `
    font-family: 'Sequel Sans';
    font-style: normal;
    font-weight: 320;
    font-size: 14px;
    line-height: 20px;
    color: #212121
    `
  );
  
  export  const TextZoneKey = styled(Typography)(
    ({ theme }) => `
        color: ${theme.typography.body1.color};
        font-family: ${theme.typography.h6.fontFamily};
      `
  );
  
  export  const TextZoneValue = styled(Typography)(
    ({ theme }) => `
        font-size: ${theme.typography.body2.fontSize};
        line-height: ${theme.typography.body2.lineHeight};
        margin-left: 7px;
      `
  );
  