import { USER_ID_STORAGE, USER_TOKEN_APP_STORAGE, USER_TOKEN_STORAGE } from "@/consts/defaultConsts"
import { HOME_PAHT } from "@/consts/typeServicesPaths"
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer"
import { getLocalStorage, removeLocalrStorage, setLocalStorage } from "@/services/localstorageServices"
import { getUserByIdTunhk, logoutThunk } from "@/store/userRedux/userThunks"
import { Container, styled, useTheme } from "@mui/material"
import queryString from "query-string"
import { useEffect, useRef } from 'react'
import { To, useLocation, useNavigate } from "react-router-dom"


type SearchParams = {
  redirect?: string;
}

const ContainerArea = styled(Container)(
  () => `
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    flex-direction: column;
    height: 100vh;
    transform: translateY(-5%);
  `
);

const Loading = styled("div")<{ color: string }>(
  ({ color }) => `
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: ${color};
    --_m: 
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: l3 1s infinite linear;
    @keyframes l3 {
      to {
        transform: rotate(1turn);
      }
    }
  `
)

const AutoLogin = () => {
  const {
    user: { user },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();

  const { palette } = useTheme()

  const redirect = useRef<To>("")

  useEffect(() => {
    const params: SearchParams = queryString.parse(search);
    if ( params.redirect ) {
      redirect.current = params.redirect as To

      if ( !!Object.keys(user).length ) {
        dispatch(logoutThunk())
      }
      handlePrepareToken()
      handleLogin()      
    } else {
      navigate(HOME_PAHT, { replace: true });
    }
  }, [])

  const handlePrepareToken = () => {
    const tokenApp = getLocalStorage(USER_TOKEN_APP_STORAGE)
    setLocalStorage(USER_TOKEN_STORAGE, tokenApp)
  }

  const handleGetUserId = () => {
    const userId = getLocalStorage(USER_ID_STORAGE)
    removeLocalrStorage(USER_ID_STORAGE)
    return userId?.toString()
  }

  const handleLogin = () => {
    const userId = handleGetUserId()
    dispatch(getUserByIdTunhk(userId))
      .then(() => navigate(redirect.current, { replace: true }))
      .catch(() => navigate(HOME_PAHT, { replace: true }))
      
  }

  return (
    <ContainerArea maxWidth="xs" >
      <Loading color={palette.warning.main}></Loading>
    </ContainerArea>
  )
}

export default AutoLogin