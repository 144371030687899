import HeaderSection from "@/components/headerSection/HeaderSection";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import { REQUEST_MAIN_MOVING_ORIGEN_PATH } from "@/pages/customerRequest/shared/consts/routes";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { createRequestLoadRuteDataLayer } from "@/services/dataLayer/createRequest";
import { getPrevRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { Container, styled } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { customerRequestObjectTypes, customerRequestTypesArea } from "../../shared/consts/customerRequestConsts";

const ContainerArea = styled(Container)(
  () => `
    padding: 16px 0;
  `
);

const Rute = () => {
  const dispatch = useAppDispatch();
  const { customerRequest: { selectedTypeRequest }, user: { user } } = useAppSelector((state) => state);

  const navigate = useNavigate();
  const {pathname} = useLocation();



  useEffect(() => {
    if (selectedTypeRequest.id <= 0) {
      navigate(-1);
    }

    createRequestLoadRuteDataLayer((user as IUser).id, selectedTypeRequest.id )

  }, []);

  const { title = "", subTitle = "" } = customerRequestTypesArea[selectedTypeRequest.id || 1];

  const handlePrevRute = () => {
    const valueToReplace = customerRequestObjectTypes[selectedTypeRequest.id];
    dispatch(getPrevRoute(navigate, { valueToSearch: ":typeRequest", valueToReplace }));
  };

  const movingTitle = (tit: string)=>{
    const valueToReplace = customerRequestObjectTypes[selectedTypeRequest.id];
    if(selectedTypeRequest.id === 5){
      if(pathname === REQUEST_MAIN_MOVING_ORIGEN_PATH.replace(":typeRequest", valueToReplace)){
        return "Origen"
      }
      return "Destino"
    }
    return tit
  }

  return (
    <Container maxWidth="xs">
      <HeaderSection 
        icon="icon-arrow-left--primary" 
        title={ movingTitle(title)} 
        subTitle={subTitle} 
        onClick={handlePrevRute} 
      />
      <ContainerArea>
        <SectionIcon icon="icon-location-light" />
        <Outlet />
      </ContainerArea>
    </Container>
  );
};

export default Rute;
