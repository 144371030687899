import LabelRequired from "@/components/utils/LabelRequired";
import { MenuItem, Select, Typography } from "@mui/material";
import { StyleCustomInput } from "../customInput/styleCustomInput";
import { FormContainer, Label } from "./styleSelectControl";

interface Items {
  id: number;
  nombre: string;
}

export interface ISelectListItems {
  options?: Items[] | undefined;
  required?: boolean;
  label: string;
  name: string;
  type: "select" | "input";
  placeholder?: string;
  value?: string;
  onChange?: (e: any) => void;
  offertType?: number;
}

interface IProps {
  listItems: ISelectListItems[];
  fullWidth?: boolean;
}

const SelectControlMulti = ({ listItems, fullWidth = false }: IProps) => {
  return (
    <>
      {listItems.map((item, i) =>
        item.type === "select" ? (
          <FormContainer fullWidth={fullWidth} sx={{ mt: 2 }} key={i}>
            <Label
              id={i?.toString() + "label"}
              sx={{ backgroundColor: "#fff" }}
            >
              {item.required ? <LabelRequired str={item.label} /> : item.label}
            </Label>
            <Select
              labelId={i?.toString() + "label"}
              id={i + "input"}
              value={item.value}
              onChange={item.onChange}
              name={item.name}
            >
              {Number(item.value) === 0 && (
                <MenuItem value={0} key={`${0}-MenuItem`}>
                  <Typography sx={{ color: "#bdbdbd" }}>Seleccionar</Typography>
                </MenuItem>
              )}
              {item.options?.map((option) => (
                <MenuItem value={option.id} key={`${option.id}-MenuItem`}>
                  {option.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormContainer>
        ) : (
          <StyleCustomInput
            key={i}
            sx={{ mt: 2 }}
            name={item.name}
            label={
              item.required ? <LabelRequired str={item.label} /> : item.label
            }
            value={item.value}
            placeholder={item.placeholder}
            type="number"
            onChange={item.onChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )
      )}
    </>
  );
};

export default SelectControlMulti;
