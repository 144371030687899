import useNotiffication from "@/hooks/useNotiffication";

import {
  Alert,
  AlertColor,
  AlertProps,
  IconButton,
  styled
} from "@mui/material";


export const ButtonIcon = styled(IconButton)(
  () => `
      color: inherit;
  
    `
);

interface Props extends AlertProps {
  children: JSX.Element | string;
  icon?: string;
  action?: string | boolean;
}

const CustomAlert = styled(Alert)(
  () => `
        padding: 16px 18px;
        line-height: 24px;
    `
);



const SnackbarNotification = () => {
  const { notiffication, removeNotiffication } = useNotiffication();

  const handleClose = () => {
    removeNotiffication(false);

  };

  if(notiffication?.hiddenSnackbar) return <></>

  return (
    <>
      {!!notiffication && (
        <CustomAlert
          icon={<i className={`icon-alert-${notiffication?.message}`} />}
          severity={notiffication?.message as AlertColor}
          variant="standard"
          color={notiffication?.message as AlertColor}
          action={
            
              <ButtonIcon sx={{ padding: 0 }} onClick={handleClose}>
               {!notiffication.hiddenClose && <i className={`icon-alert-close-${notiffication?.message}`} />} 
              </ButtonIcon>
            
          }
        >
          {notiffication?.title}
        </CustomAlert>
      )}
    </>
  );
};

export default SnackbarNotification;

/*  */
