import { prepareSelectedValueModal } from '@/components/Filter/adapter/filterAdapter';
import { REGISTER_PATH } from '@/consts/noticeV2Paths';
import { REGISTER_DRIVER_STEPS } from '@/consts/typeServicesPaths';
import { useAppDispatch, useAppSelector } from '@/hooks/useReducer';
import { UsuarioAPI } from '@/models/userAuth/user';
import { INoticeCreateListItem } from '@/pages/noticeV2/NoticeList/types';
import {
  extractSendData,
  getNoticeType,
  getNoticeTypeInterface,
} from '@/pages/noticeV2/adapters/noticeUseTypes';
import { DistritoSugeridoData } from '@/services/noticeServices/noticeListSuggested/noticeListSuggestedTypes';
import { resetListLocation } from '@/store/filterNoticeRedux/filterNoticeSlice';
import {
  getCitiesByPaisFilterThunk,
  getDistrictsFilterThunk,
} from '@/store/filterNoticeRedux/filterNoticeThunk';
import {
  setDistrictsSuggested,
  setInputsPlace,
} from '@/store/noticeV2Redux/noticeV2Slice';
import {
  fillSendDataThunk,
  noticeGetDistrictSuggestedThunk,
  onSaveFlowNoticeV2Thunk,
} from '@/store/noticeV2Redux/noticeV2Thunk';
import {
  IInputsPlace,
  IReturnDataValues,
} from '@/store/noticeV2Redux/noticeV2types';
import { getNextRouteV2 } from '@/store/routeActionsFlowV2/routeActionsFlowNoticeV2Thunk';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IShowInputsProps } from '../../components/inputsConfig/types';
import { ERROR_MESSAGES_DUPLICATED, ERROR_MESSAGES_LOW_PRICE, MAX_PRICE_IN, MIN_PRICE_IN, placeholdersPlace } from '../hook';
import { MODAL_ACTIVE, remanePlaceholders } from '../types';

export const usePlaceInCityHook = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const {
    filterNotice,
    user: { user },
    noticeV2: {
      selectNotice,
      inputsPlace,
      sendData,
      districtsSuggested,
      isLoading,
      noticesDuplicate,
    },
  } = useAppSelector((state) => state);

  const { listLocation } = filterNotice;

  const [showModal, setShowModal] = useState(false);

  const [modalActiveWith, setModalActiveWith] = useState(MODAL_ACTIVE);

  const idNoticeType = (selectNotice as INoticeCreateListItem)?.id || 0;

  const noticeTypeData = extractSendData(sendData, idNoticeType);

  const { distrito, ciudad, flgRegistroAviso } = user as UsuarioAPI;

  const isRegister = flgRegistroAviso === null;

  const isCity = modalActiveWith.name === 'city';

  useEffect(() => {
    loadFormData();
  }, []);

  const loadFormData = () => {
    // Check if there are existing values for city and origin diferent
    if (inputsPlace && !inputsPlace.city && !inputsPlace.origin) {
      // Load default data
      const body: IReturnDataValues = {
        ...noticeTypeData,
        idciudad: ciudad?.id || 0,
        idciudaddestino: ciudad?.id || 0,
        idorigen: isRegister ? distrito?.id || 0 : 0,
      };

      // If user is registering, fetch district suggestions
      if (isRegister) {
        dispatch(noticeGetDistrictSuggestedThunk(body.idorigen));
      }

      // Dispatch actions and update form data
      dispatch(
        fillSendDataThunk({
          key: getNoticeType(idNoticeType),
          value: getNoticeTypeInterface(idNoticeType, body),
        })
      ).then(() => {
        const updatedFormData = {
          ...inputsPlace,
          city: ciudad?.nombre2 || '',
          origin: isRegister ? distrito?.nombre || '' : '',
        };

        dispatch(setInputsPlace(updatedFormData));
      });
    }
  };

  // delete change city
  const handleOnChangeCity = (body: IReturnDataValues, name: string) => {
    const updatedBody = {
      ...body,
      idorigen: 0,
      avisodestinos: [],
    };

    dispatch(
      fillSendDataThunk({
        key: getNoticeType(idNoticeType),
        value: getNoticeTypeInterface(idNoticeType, updatedBody),
      })
    ).then(() => {
      const updatedInputsPlace = {
        ...inputsPlace,
        [modalActiveWith.name]: name,
        origin: '',
      };

      dispatch(setInputsPlace(updatedInputsPlace));
      dispatch(setDistrictsSuggested({}));
    });
  };

  const handleOnClick = (props: IShowInputsProps) => () => {
    setModalActiveWith({ name: props.name });
    setShowModal(true);
    dispatch(resetListLocation());
  };

  const onChange = (props: IShowInputsProps) => () => {
    handleOnClick(props)();
  };

  const handleGetValue = ({ name }: { name: keyof IInputsPlace }) => {
    return inputsPlace[name] || '';
  };

  const handleGetPlaceholder = (props: IShowInputsProps) => {
    const typeNoticePlaceholder = placeholdersPlace[idNoticeType || 0];

    if (
      remanePlaceholders.some((name) => name === props.name) &&
      typeNoticePlaceholder
    ) {
      return typeNoticePlaceholder[props.name] || {};
    }

    return {};
  };

  const handleValidShow = (columnIShowInputsProp: any) => {
    const moreIShowInputsProp = columnIShowInputsProp?.callback
      ? columnIShowInputsProp?.callback(columnIShowInputsProp, filterNotice)
      : {};
    return { ...columnIShowInputsProp, ...moreIShowInputsProp };
  };

  const handleInitConfig = (column: any) => {
    const moreIShowInputsProp = column?.props?.callback
      ? column?.props?.callback(column?.props, {})
      : {};

    const props = {
      ...column.props,
      onClick: handleOnClick(column.props),
      onChange: onChange(column.props),
      value: handleGetValue(column.props),
      ...handleValidShow(column.props),
      ...handleGetPlaceholder(column.props),
      ...moreIShowInputsProp,
    };

    return {
      ...column,
      props,
    };
  };

  const handleOnCloseModal = () => {
    setShowModal(false);
    setModalActiveWith(MODAL_ACTIVE);
  };

  const handleSubmitModal = (selected: any) => {
    const name = prepareSelectedValueModal(selected);

    const body: IReturnDataValues = {
      ...noticeTypeData,
    };

    if (isCity) {
      body.idciudad = selected.id;
      body.idciudaddestino = selected.id;
    }

    if (modalActiveWith.name === 'origin') {
      body.idorigen = selected.id;
      dispatch(noticeGetDistrictSuggestedThunk(selected.id));
      body.avisodestinos = [];
    }

    dispatch(
      fillSendDataThunk({
        key: getNoticeType(idNoticeType),
        value: getNoticeTypeInterface(idNoticeType, body),
      })
    ).then(() => {
      const updatedInputsPlace = {
        ...inputsPlace,
        [modalActiveWith.name]: name,
      };

      dispatch(setInputsPlace(updatedInputsPlace));

      if (isCity) {
        handleOnChangeCity(body, name);
      }
    });

    handleOnCloseModal();
  };

  const handleOnSeletedModal = (seleted: any) => {
    const exemption = ['origin', 'city'];
    if (exemption.some((exemp) => exemp === modalActiveWith.name)) {
      handleSubmitModal(seleted);
    }
  };

  const handleFilterByTerm = (value: string) => {
    if (value?.length < 3) return;

    const { name } = modalActiveWith;
    if (name === 'city') {
      dispatch(getCitiesByPaisFilterThunk(value));
    } else if (name === 'origin') {
      dispatch(getDistrictsFilterThunk(value, inputsPlace.city as string));
    }
  };

  const handleInputsSuggested = (id: number, precio: string) => {
    // precio solo numeros
    if (precio && !/^\d+$/.test(precio)) return;

    // Clonar el array avisodestinos para evitar mutación directa
    const avisodestinos: any[] = [...(noticeTypeData?.avisodestinos || [])];

    // Buscar el índice del destino en avisodestinos
    const index = avisodestinos.findIndex(
      (item: any) => item.iddistrito === id
    );

    if (index !== -1) {
      if (Number(precio) === 0) {
        avisodestinos.splice(index, 1); // Eliminar el destino si el precio es 0
      } else {
        // Clonar el objeto del destino para evitar mutación directa
        avisodestinos[index] = {
          ...avisodestinos[index],
          precio: Number(precio), // Actualizar el precio
        };
      }
    } else {
      avisodestinos.push({
        iddistrito: id,
        precio: Number(precio),
      });
    }

    const body: IReturnDataValues = {
      ...noticeTypeData,
      avisodestinos,
    };

    dispatch(
      fillSendDataThunk({
        key: getNoticeType(idNoticeType),
        value: getNoticeTypeInterface(idNoticeType, {
          ...body,
        }),
      })
    );
  };

  const DeleteSuggestedListItem = (id: number) => {
    if (noticeTypeData?.avisodestinos?.length) {
      const updatedAvisodestinos = noticeTypeData.avisodestinos.filter(
        (item) => item?.iddistrito !== id
      );

      dispatch(
        fillSendDataThunk({
          key: getNoticeType(idNoticeType),
          value: getNoticeTypeInterface(idNoticeType, {
            ...noticeTypeData,
            avisodestinos: updatedAvisodestinos,
          }),
        })
      );
    }

    dispatch(
      setDistrictsSuggested({
        ...districtsSuggested,
        sugeridos: (
          districtsSuggested as DistritoSugeridoData
        ).sugeridos.filter((item) => item.id !== id),
      })
    );
  };

  const handleNavigate = () => {
    if (pathname.includes(REGISTER_PATH)) {
      navigate(REGISTER_DRIVER_STEPS);
      return;
    } else {
      dispatch(getNextRouteV2(navigate));
    }
  };
  const onSubmit = () => {
    if (!validateForm()) {
      return;
    }

    dispatch(
      onSaveFlowNoticeV2Thunk({
        ...noticeTypeData,
      })
    ).then(() => {
      handleNavigate();
    });
  };

  const validatePrecioOfDestinos = (id: number) => {
    const avisodestinos = noticeTypeData.avisodestinos || [];
    const index = avisodestinos.findIndex(
      (item: any) => item.iddistrito === id
    );
    if (index !== -1) {
      const { precio } = avisodestinos[index];
      const duplicate = noticesDuplicate && noticesDuplicate.find((item) => item === id);
      if (duplicate) {
        return {
          valid: true,
          message: ERROR_MESSAGES_DUPLICATED,
        };
      }

      return {
        valid: !(precio >= MIN_PRICE_IN && precio <= MAX_PRICE_IN),
        message: ERROR_MESSAGES_LOW_PRICE,
      };
    }

    return {
      valid: false,
      message: '',
    };
  };

  const validateForm = () => {
    const { idorigen, idciudad, idciudaddestino, avisodestinos } =
      noticeTypeData;

    let validdestinos: boolean[] = [];

    // buscar si hay un precio menor a 5 o mayor a 1000 y retornar false
    if (avisodestinos?.length) {
      avisodestinos?.forEach((item: any) => {
        const { valid } = validatePrecioOfDestinos(item.iddistrito);
        if (valid) {
          validdestinos.push(valid);
        }
      });
    }

    return !!(
      idorigen &&
      idciudad &&
      idciudaddestino &&
      avisodestinos?.length &&
      !validdestinos.length
    );
  };

  return {
    handleInitConfig,
    showModal,
    handleOnCloseModal,
    handleSubmitModal,
    handleOnSeletedModal,
    handleFilterByTerm,
    modalActiveWith,
    listLocation,
    districtsSuggested,
    handleInputsSuggested,
    data: noticeTypeData,
    DeleteSuggestedListItem,
    validateForm,
    onSubmit,
    isLoading,
    validatePrecioOfDestinos,
    isRegister,
    formData: inputsPlace,
    isCity,
  };
};

export default usePlaceInCityHook;


