import { ResponseDistrictListAPI } from "@/models/location/districtModel";

export const getOriginAndDestinyByPromiseSettled = (
  values: PromiseSettledResult<ResponseDistrictListAPI>[]
) => {
  const result = values.reduce(
    (acc, el, i) => {
      if (el.status === "fulfilled") {
        const location = i === 0 ? "origin" : "destiny";
        acc = {
          ...acc,
          [location]: el?.value?.distritoData?.distritos,
        };
      }
      return acc;
    },
    { origin: [], destiny: [] }
  );

  return result;
};
