import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { Box, Card, Grid, Typography, styled } from "@mui/material";
import {
  handleDataToRender,
  handleTypeMultidestiny,
  handleTypeMultidestinyNotice,
} from "./config";

export const CustomCard = styled(Card)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  minWidth: "275px",
  padding: "8px",
  boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  minHeight: "100px",
}));

interface Props {
  data: NoticeV2NegType;
}

const PriceMultiCounterOffer = (props: Props) => {
  const { data } = props;

  const noticeData = handleTypeMultidestiny(data);

  const noticeDataTwo = handleTypeMultidestinyNotice(data);

  const dataTorender = handleDataToRender({ noticeData, noticeDataTwo });

  return (
    <Box>
      {dataTorender.map((item, i) => (
        <CustomCard sx={{ mt: 2 }}>
          <Typography sx={item.title.sx}>{item.title.name}</Typography>
          <Grid container>
            <Grid xs={4}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={item.columnTitleSx}>
                  {item.columnOne.title}
                </Typography>
                <Typography sx={item.clumnSubtitleSx}>
                  {item.columnOne.subtitle}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={4}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={item.columnTitleSx}>
                  {item.columnTwo.title}
                </Typography>
                <Typography sx={item.clumnSubtitleSx}>
                  {item.columnTwo.subtitle}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={4}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={item.columnTitleSx}>
                  {item.columnThree.title}
                </Typography>
                <Typography sx={item.clumnSubtitleSx}>
                  {item.columnThree.subtitle}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CustomCard>
      ))}
    </Box>
  );
};

export default PriceMultiCounterOffer;
