import TwoAvatars from '@/components/userRating/TwoAvatars';
import HistoryRating from '@/components/userRating/historyRating/HistoryRating';
import { IMG_PATH } from '@/consts/defaultConsts';
import ButtonContact from '@/pages/noticeV2Negotation/components/ButtonContact';
import Icon from '@/utils/Icon';
import { characterCut, resizePhoto } from '@/utils/helpers';
import { Avatar, Box, Grid, Stack, SxProps, Theme, Typography } from '@mui/material';
import useSingleUorTFinishedHook from './hook';
import { useLocation } from 'react-router-dom';
import { LocationState } from '@/interfaces/globalInterface';

type Props = {
  urlProfile: string;
  urlTruck?: string;
  showTwoAvatars: boolean;
  userName: string;
  userEmail: string;
  containerSx?: SxProps<Theme>;
  viewRating?: boolean;
  userInfo?: {
    calificacion: number;
    calificacionTexto: string;
    id: number;
    nombre: string;
    perfilUrl: string;
    nombreempresa: string;
  };
  whatsapp?: string;
};

const SingleUorTFinishedRequest = (props: Props) => {
  const { state } = useLocation();
  const states = (state as LocationState);
  const {
    urlProfile,
    userName,
    userEmail,
    containerSx,
    urlTruck,
    showTwoAvatars = false,
    viewRating = true,
    userInfo,
    whatsapp,
  } = props;

  const urlUser = !!urlProfile
    ? resizePhoto({ photo: IMG_PATH + urlProfile })
    : IMG_PATH + urlProfile;

  const {
    avatarStyles,
    gradeTextStyles,
    gridContainerStyles,
    matches,
    pathname,
    onCloseRating,
    onOpenRating,
    openRating,
    handleData,
    flgIsOwner,
  } = useSingleUorTFinishedHook()

  return (
    <Stack
      direction="column"
      width="100%"
    >
      <Grid
        container
        sx={{
          alignItems: "center",
          maxWidth: matches ? "65%" : "100%",
          cursor: viewRating ? "pointer" : "default",
          ...containerSx,
        }}
        onClick={() => onOpenRating(true)}
        gap={0}
      >
        <Grid item xs={3} sm={3} sx={{display: "flex", flexBasis: showTwoAvatars ? "" : "18% !important"}}>
          {showTwoAvatars ? (
            <TwoAvatars primaryAvatar={urlUser} secondAvatar={urlTruck} />
          ) : (
            <Avatar src={urlUser} sx={avatarStyles} />
          )}
        </Grid>
        <Grid item xs={9} sx={{ ...gridContainerStyles, mt: 0 }}>
          <Typography
            sx={{
              color: viewRating ? "primary.main" : "",
            }}
            variant="h6"
            mt={0}
            pt={0}
          >
            {characterCut(userInfo?.nombreempresa || userInfo?.nombre || "", matches ? 35 : 25)}
          </Typography>
          {
            !!userInfo?.nombreempresa &&
            <Typography variant="label2" sx={{
              ...gradeTextStyles,
              color: "black",
            }}>
              {userInfo?.nombre}
            </Typography>
          }
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="label2" sx={gradeTextStyles}>
              {userName}
            </Typography>
            {
              userEmail &&
              <Typography variant="label2" sx={gradeTextStyles}>
                {userEmail}
              </Typography>
            }
          </Box>
        </Grid>
      </Grid>
      {
        !!states?.showCalification ? null :
          <ButtonContact
            contacts={[
              {
                title: "Llamar",
                contact: userName,
                type: "phone",
              },
              {
                title: "WhatsApp",
                contact: userName,
                type: "whatsapp",
                whatsapp,
                backgroundColor: "#25D366",
              }
            ]}
          />
      }
      {
        flgIsOwner ? null
          :
          <Stack
            direction="row"
            padding="8px 0px"
            gap="8px"
            mt="16px"
            alignItems="center"
          >
            <Icon
              name="icon-handsShake"
              size="md"
            />
            <Typography variant="body1">
              Cumplir tus precios hará que te contacten de nuevo y recomienden.
            </Typography>
          </Stack>
      }
      <HistoryRating
        open={openRating}
        onClose={onCloseRating}
        userData={handleData()}
      />
    </Stack>
  )
}

export default SingleUorTFinishedRequest