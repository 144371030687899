import { useAppSelector } from "@/hooks/useReducer";
import { actionsToNegotation } from "../../../consts/actions";
import { objectRenderComponent } from "./config";
import { optionThree } from "@/consts/noticeConsts";



const RejectionConfirmation = () => {
  const {
    noticeNegotationRequest: { savedDataNogotation,  },
  } = useAppSelector((state) => state);
 const lastActionId = savedDataNogotation?.ultimaSolicitudNegociacionAcciones?.tipoSolicitudNegociacionAccion.id
 const optionToRender = lastActionId === actionsToNegotation[9].id ? optionThree : savedDataNogotation?.tipoSolicitudNegociacion.id as number

  const Component = objectRenderComponent[optionToRender];
    
  if (!Component) return <></>;

  return <Component />;
};

export default RejectionConfirmation;
