import { Container, styled, Typography } from "@mui/material";

const ContainerNotFound = styled(Container)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: ${theme.palette.background.default};
    flex-direction: column;
    `
);

const Content = styled("div")(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 360px;
    text-align: center;
    `
);

const NotResultFound = () => {
  return (
    <ContainerNotFound>
      <Content>
        <i className="icon-not-result-found" />
        <Typography variant="h6" component="h6" sx={{ mt: 2 }}>
          No encontramos resultados
        </Typography>
        <Typography variant="body2" component="p" sx={{ mt: 1 }}>
          Puedes ajustar los filtros para encontrar otras opciones.
        </Typography>
      </Content>
    </ContainerNotFound>
  );
};

export default NotResultFound;
