import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector"
import homeTranslation from "./sections/homeTranslation.json"
import aboutUs from "./sections/aboutUs.json"
import headerTranslate from "./sections/header.json"
import footerTranslate from "./sections/footerTranslation.json"

i18next
  .use( initReactI18next )
  .use( LanguageDetector )
  .init({
    debug: process.env.NODE_ENV === "development",
    fallbackLng: ["es", "en"],
    lng: "es",
    resources: {
      es: {
        translation: {
          ...homeTranslation.es.translation,
          ...aboutUs.es.translation,
          ...headerTranslate.es.translation,
          ...footerTranslate.es.translation,
        }
      },
      en: {
        translation: {
          ...homeTranslation.en.translation,
          ...aboutUs.en.translation,
          ...headerTranslate.en.translation,
          ...footerTranslate.en.translation,
        }
      }
    }
  })