import { ContainerHeader } from "@/pages/flowNoticeNegotation/styles/styles";
import Icon from "@/utils/Icon";
import { Stack, Typography } from "@mui/material";
import useGetData from "../shared/hooks/useGetData";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { ID_MOVING_FLOW } from "@/consts/defaultConsts";
import { useLocation } from "react-router-dom";
import { NEGOTATION_CLIENT_PATH } from "@/consts/negotationFlowPath";
import { SMS_NOTIFICATION_CARRIER_RESPONSE, SMS_NOTIFICATION_CLIENT_RESPONSE } from "@/consts/requestConsts";

const COUNTER_OFFERT_TITLE = "¡Contraoferta enviada!";
const COTIZACION_TITLE = "¡Cotización enviada!";

interface Props {
  typeId: number
}

export const SuccessHeaderDetailsV2 = (props: Props) => {
  const { pathname } = useLocation();
  const isSolicitud = pathname.includes(NEGOTATION_CLIENT_PATH);

  const getTitle = () => {
    if (props.typeId === ID_MOVING_FLOW) {
      return COTIZACION_TITLE;
    }

    return COUNTER_OFFERT_TITLE;
  };

  const getSubtitle = () => {
    if (isSolicitud) {
      return SMS_NOTIFICATION_CARRIER_RESPONSE;
    }

    return SMS_NOTIFICATION_CLIENT_RESPONSE;
  }

  return (
    <ContainerHeader sx={{ mt: 4 }}>
      <Stack>
        <Icon name="icon-success" color="success" size="xl" />
      </Stack>
      <Stack
        marginLeft="16px"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">{getTitle()}</Typography>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          {getSubtitle()}
        </Typography>
      </Stack>
    </ContainerHeader>
  );
};
