import React, { FC, useEffect } from "react";
import { MainTags, TabItem } from "./styleCustomTags";
import TabPanel from "./TabPanel";

type Props = {
  label1: string;
  label2: string;
  label3?: string;
  onChange?: (activeTag: number) => void;
  childrenTag1: JSX.Element | JSX.Element[] | string;
  childrenTag2: JSX.Element | JSX.Element[] | string;
  childrenTag3?: JSX.Element | JSX.Element[] | string;
  initialTag?: number;
};

const CustomTags: FC<Props> = ({
  label1,
  label2,
  label3,
  onChange,
  childrenTag1,
  childrenTag2,
  childrenTag3,
  initialTag = 1,
}) => {
  const [value, setValue] = React.useState(initialTag);

  useEffect(() => {
    setValue(initialTag);
  }, [initialTag]);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(Number(newValue));
    onChange && onChange(newValue);
  };
  // centrar
  return (
    <>
      <MainTags value={value} onChange={handleChange} aria-label="Tags avisos">
        <TabItem value={1} label={label1} />
        <TabItem value={2} label={label2} />
        {label3 && <TabItem value={3} label={label3} />}
      </MainTags>
      <TabPanel value={value} index={1}>
        {childrenTag1}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {childrenTag2}
      </TabPanel>
      {label3 && (
        <TabPanel value={value} index={3}>
          {childrenTag3}
        </TabPanel>
      )}
    </>
  );
};

export default CustomTags;
