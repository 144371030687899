import { Container, Typography, styled } from "@mui/material";

export const ContainerArea = styled(Container)(
    () => `
      padding: 16px 0;
    `
  );

export const SubTitleConditions = styled(Typography)(
    ({ theme }) => `
        font-family: ${theme.typography.label1.fontFamily};
        font-weight: ${theme.typography.label1.fontWeight};
        line-size: ${theme.typography.label1.fontSize};
        line-height: ${theme.typography.label1.lineHeight};
        padding: 10px 0;
        min-height: 40px;
      `
  );