import HeaderSectionV2 from "@/components/headerSectionV2/HeaderSectionV2";
import { ContainerArea } from "@/pages/noticeV2/NoticeConditions/noticeConditionsStyles";
import { Alert, Box, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderDetail from "../../components/HeaderDetail";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import useGetData from "../../shared/hooks/useGetData";
import { ID_MOVING_FLOW } from "@/consts/defaultConsts";

const DriverCounterOfferLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const { notice, errorCode } = useGetData();
  const isMoving = notice?.idtipoaviso === ID_MOVING_FLOW;

  const handleTitleHeader = () => {
    let title = "Contraofertar";
    if (notice?.idtipoaviso === ID_MOVING_FLOW) {
      title = "Cotizar";
    }

    return title;
  };

  const handleGoBackPage = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="xs">
      <HeaderSectionV2
        icon="icon-arrow-left"
        title={handleTitleHeader()}
        onClick={() => handleGoBackPage()}
      />
      {(errorCode === 400 || errorCode === 500) && (
        <>
          <Alert sx={{ mb: 3, mt: 3, p: 2 }} severity="error">
            Error al enviar la contraoferta. Por favor vuelve a intentar.
          </Alert>
        </>
      )}
      <ContainerArea>
        <HeaderDetail title="" icon="icon-counterOfferMat" />
        {!isMoving && (
          <Box sx={{ display: "flex" }}>
            <ErrorOutlineIcon sx={{ color: "#0288D1", mr: 2 }} />
            <Typography>Sólo puedes contraofertar una vez.</Typography>
          </Box>
        )}
        {children}
      </ContainerArea>
    </Container>
  );
};

export default DriverCounterOfferLayout;
