import usePlaceContainersHook from "./hook";

const PlaceContainer = () => {
  const { Component } = usePlaceContainersHook();

  if (!Component) return <></>;
  return <Component />;
};

export default PlaceContainer;
