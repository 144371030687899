interface Props {
  style: {
    color?: string;
    height: string;
    width: string;
  };
}

export const HighWayIcon = (props: any) => {
  const { color, height, width } = props.style;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_27701_30050)">
        <mask
          id="mask0_27701_30050"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="73"
          height="72"
        >
          <rect x="0.135254" width="72" height="72" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_27701_30050)">
          <path
            d="M64.2312 59.8461L52.2312 13H55.635L67.635 59.8461H64.2312ZM4.13525 59.8461L16.1353 13H19.5391L7.53905 59.8461H4.13525ZM34.1832 23.1307V13H37.587V23.1307H34.1832ZM34.1832 41.4653V31.3346H37.587V41.4653H34.1832ZM34.1832 59.8461V49.6692H37.587V59.8461H34.1832Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_27701_30050">
          <rect width="72" height="72" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
