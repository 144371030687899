type InfoCardIcons = {
  icon: string;
  icon2: string;
};
export const infoCardIcons: Record<number, InfoCardIcons> = {
  1: {
    icon: "icon-buildings",
    icon2: "",
  },
  2: {
    icon: "icon-multi-points",
    icon2: "icon-watch",
  },
  3: {
    icon: "icon-inter-city--white",
    icon2: "",
  },
  4: {
    icon: "icon-percentaje-medium",
    icon2: "",
  },
  5: {
    icon: "icon-vehicle--truck",
    icon2: "",
  },
};

export const multiDestinyTitle: Record<number, string> = {
  1: "Multidestino por hora",
  2: "Multidestino por punto",
};

export const movingTitle: Record<number, string> = {
  1: "Mudanza en la ciudad",
  2: "Mudanza entre ciudades",
};
