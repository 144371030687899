export interface IOfferHeaderTitle {
  header: {
    title: string;
    subTitle: string;
  };
}

export const offertHeaderTitle: Record<number, IOfferHeaderTitle> = {
  1: {
    header: {
      title: 'Descripción de la carga',
      subTitle: 'Aceptar',
    },
  },
  2: {
    header: {
      title: 'Descripción de la carga',
      subTitle: 'Aceptar',
    },
  },
  3: {
    header: {
      title: 'Descripción de la carga',
      subTitle: 'Aceptar',
    },
  },
  4: {
    header: {
      title: 'Descripción de la carga',
      subTitle: 'Aceptar',
    },
  },
  5: {
    header: {
      title: 'Descripción de la carga',
      subTitle: 'Aceptar',
    },
  },
};
