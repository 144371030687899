import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import HeaderModal from "@/components/modals/headerModal/HeaderModal";
import { REQUESTS_LANDING_PATH } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import {
  IOrdenamiento,
  TTypeOptions,
  TiposFiltro,
} from "@/store/FilterServices/filterReduxInterface";
import { setTiposFiltro } from "@/store/FilterServices/filterSlice";
import { setCleanHistoryList } from "@/store/advertisementRedux/advertisementSlice";
import { getListCustomRequestFilterThunk } from "@/store/customerRequestRedux/customerRequestFilterThunk";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  ContainerButtom,
  ContainerModal,
  CustomDrawerFilter,
} from "../styleServices";
import {
  convertArrayToObjectString,
  handleAddAndDeleteValue,
} from "../utils/handleCheck";
import DrawerTypes from "./DrawerTypes";

type Props = {
  open: boolean;
  onClose: (value: boolean) => void;
  onSubmit: () => void;
  onSeleted?: (value: any) => void;
};

const DrawerListFilter = ({ open, onClose, onSubmit, onSeleted }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [select, setSelect] = useState<string>("");
  const [checkArrFilters, setCheckArrFilters] = useState<TiposFiltro[]>([]);
  const [firstRender, setFirstRender] = useState(true);

  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const {
    filter: { filter, tiposFiltro, selectedOrdenamiento },
  } = useAppSelector((state) => state);

  useEffect(() => {
    if (!firstRender) {
      processFilter();
    }
  }, [searchParams, selectedOrdenamiento]);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    }
    return () => {
      dispatch(setCleanHistoryList());
    };
  }, []);

  const processFilter = () => {
    const parseString = queryString.parse(searchParams.toString());
    if (
      (parseString && Object.keys(parseString).length > 0) ||
      (selectedOrdenamiento as IOrdenamiento)
    ) {
      const myKeysValues: any = queryString.parse(searchParams.toString());

      let arrFilters = Object.keys(myKeysValues).map((key) => {
        return {
          nombre: key,
          opciones: myKeysValues[key].split("|"),
        };
      });

      setCheckArrFilters([...arrFilters]);
      dispatch(setTiposFiltro(arrFilters));
      const bodyList = {
        page: 0,
        size: 12,
        ordenamiento: selectedOrdenamiento as IOrdenamiento,
        tiposFiltro: arrFilters,
        pathname,
      };

      if (pathname.includes(REQUESTS_LANDING_PATH)) {
        dispatch(getListCustomRequestFilterThunk(bodyList));
      }
    } else {
      setCheckArrFilters([]);
      dispatch(setTiposFiltro([]));
    }
  };

  const handleCheck = (
    value: string | string[],
    tipo: number,
    nombre?: string | string[]
  ) => {
    const newCheckArrFilters: TiposFiltro[] = structuredClone(checkArrFilters);
    // const res = handleAddAndDeleteValue(value, tipo, handleCityOriginAndDestiny(nombre || select), newCheckArrFilters);
    const isValueArray = Array.isArray(value);
    const isNameArray = Array.isArray(nombre);
    const isValueString = typeof value === "string";
    const isNameString = typeof nombre === "string";
    let res: TiposFiltro[] = [];
    if (isValueArray && isNameArray) {
      value.forEach((v1, index) => {
        res = handleAddAndDeleteValue(
          v1,
          tipo,
          (nombre as string[])[index],
          newCheckArrFilters
        );
      });
    } else if (isValueString && isNameString) {
      res = handleAddAndDeleteValue(
        value,
        tipo,
        nombre || select,
        newCheckArrFilters
      );
    } else if (isValueArray && isNameString) {
      // array de valores.
      res = handleAddAndDeleteValue(
        value,
        tipo,
        nombre || select,
        newCheckArrFilters
      );
    } else {
      res = handleAddAndDeleteValue(
        value as string,
        tipo,
        select,
        newCheckArrFilters
      );
    }
    setCheckArrFilters(res);
  };

  const adapterTiposFiltroOptions = (
    value: TiposFiltro[],
    check: TiposFiltro[]
  ): TTypeOptions | string => {
    const initialTitle = ["Tipo de aviso", "Tipo de solicitud"];
    const findTipo = value.find((value) => initialTitle.includes(value.nombre));
    const findTipo2 = check?.find((value) =>
      initialTitle.includes(value.nombre)
    );
    if (initialTitle.includes(findTipo?.nombre || "") && findTipo2) {
      const opciones2 = findTipo?.opciones2;
      const optionKey = findTipo2?.opciones[0] as string;
      const obj: any = findTipo?.opciones.reduce((acc, v1, index) => {
        return {
          ...acc,
          [v1]: (opciones2 as TTypeOptions[])[index],
        };
      }, {});

      return obj[optionKey];
    }

    return "";
  };

  const handleOnSubmit = () => {
    const newCheckArrFilters: TiposFiltro[] = structuredClone(checkArrFilters);

    // si el tipo de aviso es C o DC, se debe eliminar el origen y destino y si no se debe eliminar el origenCiudad y destinoCiudad
    const isAccept: TTypeOptions = adapterTiposFiltroOptions(
      filter,
      checkArrFilters
    ) as TTypeOptions;
    if (isAccept === "C") {
      newCheckArrFilters.forEach((value, index) => {
        if (value.nombre === "Origen") {
          newCheckArrFilters.splice(index, 1);
        }
        if (value.nombre === "Destino") {
          newCheckArrFilters.splice(index, 1);
        }
      });
    } else if (isAccept === "D" && !"C".includes(isAccept)) {
      newCheckArrFilters.forEach((value, index) => {
        if (value.nombre === "OrigenCiudad") {
          newCheckArrFilters.splice(index, 1);
        }
        if (value.nombre === "DestinoCiudad") {
          newCheckArrFilters.splice(index, 1);
        }
      });
    }
    const res = convertArrayToObjectString(newCheckArrFilters);
    setSearchParams(res);
    handleOnClose();
  };

  const handleOnClose = () => {
    setSelect("");
    setCheckArrFilters([...tiposFiltro]);
    onClose(false);
  };

  return (
    <CustomDrawerFilter open={open} onClose={handleOnClose} anchor="right">
      <HeaderModal
        title={select || "Filtrar"}
        onClick={handleOnClose}
        onBack={() => setSelect("")}
        selected={!!select}
      />
      <ContainerModal>
        {/* <CustomListFilter> */}
        {select === "" ? (
          filter.map((value, index) => (
            <ListItem
              disablePadding
              key={index}
              sx={{
                ".css-cveggr-MuiListItemIcon-root": {
                  minWidth: "5px",
                },
              }}
              onClick={() => setSelect(value.nombre)}
            >
              <ListItemButton
                sx={{
                  padding: "10px 5px",
                }}
              >
                <ListItemText primary={value.nombre} />
                <ListItemIcon>
                  <i className="icon-small-arrow-right" />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))
        ) : (
          <DrawerTypes
            data={
              filter.find((value) => value.nombre === select) as TiposFiltro
            }
            TipoAviso={adapterTiposFiltroOptions(filter, checkArrFilters)}
            handleCheck={handleCheck}
            checkArrFilters={checkArrFilters}
          />
        )}
        {/* </CustomListFilter> */}
      </ContainerModal>
      <ContainerButtom>
        <ButtomLoader
          buttomProps={{ disabled: select === "" }}
          onClick={handleOnSubmit}
        >
          Mostrar resultados
        </ButtomLoader>
      </ContainerButtom>
    </CustomDrawerFilter>
  );
};

export default DrawerListFilter;
