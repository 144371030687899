import { optionTwo } from '@/consts/noticeConsts';
import {
  SolicitudNegociacion,
  TipoSolicitud,
  TipoSolicitudNegociacionAccion,
  Transportista,
  UltimaSolicitudNegociacionAcciones,
  Vehiculofoto,
} from '@/models/noticeNegotation/noticeNegotationModel';
import { NoticeRequestData } from '@/store/noticeNegotationRequestRedux/noticeNegRequestSlice';
import { SxProps } from '@mui/material';
import { ISelectedVehicle } from '../../../store/userRedux/userReduxInterface';

import { ISolicitudViewDetailsResponse } from '@/models/customerRequest/requestDetailsVewResponse';
import {
  TypeDeclinedNegotation,
  typeDeclinedNegotation,
  typeNegotation,
  urlNavigateConfig,
} from '@/pages/requestNegotiation/components/globalButtons/config';

export interface NoticeNegotationRequestProps {
  idTipoSolicitudNegociacion: number;
  idVehiculo: number;
  precio: number;
  ayudantes?: number;
}

export const noticeRequestAdapter = (
  data: ISolicitudViewDetailsResponse,
  negotationId: number
): NoticeRequestData => {
  const { horasRequeridas, puntosRequeridos } = data;
  const amoutHoutPoint = Number(horasRequeridas || puntosRequeridos);

  return {
    negotationId,
    price: data.precio,
    car: data.tipoVehiculo,
    flghoraopunto: data.flgHoraoPunto,
    amountHourOrPoint: amoutHoutPoint,
  };
};

export const counterOfferMovingAdapter = (
  noticeRequestData: number,
  vehicleData: any,
  price: number,
  helpers: number
) => {
  return {
    idTipoSolicitudNegociacion: noticeRequestData,
    idVehiculo: vehicleData.id,
    precio: price,
    ayudantes: helpers,
  };
};

export const noticeNegotationRequestAdapter = (
  vehicleData: any,
  noticeRequestData: NoticeRequestData,
  price?: number,
  assistant?: number
): NoticeNegotationRequestProps => {
  let newPrice = noticeRequestData.price;
  let newAssistant = 0;

  if (price) {
    newPrice = price;
  }

  const value = {
    idTipoSolicitudNegociacion: noticeRequestData.negotationId,
    idVehiculo: vehicleData.id,
    precio: newPrice,
  };

  if (!!assistant) {
    newAssistant = assistant;
    return {
      ayudantes: newAssistant,
      ...value,
    };
  }

  return value;
};

export interface NoticeExpressNegotationRequestProps {
  idtiposolicitudnegociacion: number;
  idvehiculo: number;
  precio: number;
  tiempoestimado: number;
}

export const offertExpressAdapter = (
  vehicleData: ISelectedVehicle,
  precio: number,
  tiempoestimado: number
): NoticeExpressNegotationRequestProps => {
  return {
    idtiposolicitudnegociacion: 3,
    idvehiculo: vehicleData.id,
    precio,
    tiempoestimado,
  };
};

export const noticeNegotationRequestBetweenCitiesAdapter = (
  vehicleData: any,
  noticeRequestData: NoticeRequestData,
  price?: number
): NoticeNegotationRequestProps => {
  let newPrice = noticeRequestData.price;

  if (price) {
    newPrice = price;
  }

  return {
    idTipoSolicitudNegociacion: noticeRequestData.negotationId,
    idVehiculo: vehicleData.id,
    precio: newPrice,
  };
};

export const counterOfferClientAdapter = (
  idAccion: number,
  preciocontraoferta?: number
) => {
  if (preciocontraoferta) {
    return {
      idAccion,
      preciocontraoferta,
    };
  } else {
    return {
      idAccion,
    };
  }
};

export interface DataProfileFormat {
  idUser: number;
  userName: string;
  urlProfile: string;
  rating: number;
  ratingText: string;
  showPrice: boolean;
  urlVehicle: Vehiculofoto[];
  showTwoAvatars: boolean;
  sx: SxProps;
}

export const dataProfileFormatRequestNeg = (
  savedNotification: SolicitudNegociacion,
  showTwoAvatars: boolean = true
) => {
  const driver = savedNotification?.transportista as Transportista;
  const client = savedNotification?.solicitud?.cliente as Transportista;

  let dataProfile = {
    idUser: driver?.id,
    userName: driver?.nombre,
    urlProfile: driver?.perfilUrl,
    rating: driver?.calificacion,
    ratingText: driver?.calificacionTexto,
    showPrice: false,
    urlVehicle: savedNotification?.vehiculo?.vehiculofotos,
    showTwoAvatars: showTwoAvatars,
    sx: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 0,
    },
  };

  if (!showTwoAvatars) {
    dataProfile = {
      idUser: client?.id,
      userName: client?.nombre as string,
      urlProfile: client?.perfilUrl,
      rating: client?.calificacion,
      ratingText: client?.calificacionTexto,
      showPrice: false,
      urlVehicle: savedNotification?.vehiculo?.vehiculofotos,
      showTwoAvatars: showTwoAvatars,
      sx: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 0,
      },
    };
  }

  return dataProfile;
};

export const idToConfigToReturnFormat = (
  savedDataNogotation: SolicitudNegociacion
) => {
  const data = {
    tipoSolicitud: savedDataNogotation?.solicitud.tipoSolicitud.id,
    tipoSolicitudNegociacionId:
      savedDataNogotation?.tipoSolicitudNegociacion.id,
  };

  return data;
};

export const urlFormat = (
  typeId: string,
  typeOffer: number,
  actionId: number,
  option: number
) => {
  const path = urlNavigateConfig[actionId][option];
  const typeOfferNegotation = typeNegotation[typeOffer];

  const url = path
    .replace(':typeId', typeId)
    .replace(':typeRequest', typeOfferNegotation);

  return url;
};

export const DeclineNegotationFormat = (
  tipoSolicitudNegociacion: TipoSolicitud,
  ultimaSolicitudNegociacionAcciones: UltimaSolicitudNegociacionAcciones
): TypeDeclinedNegotation => {
  const data =
    tipoSolicitudNegociacion.id === optionTwo &&
    ultimaSolicitudNegociacionAcciones.tipoSolicitudNegociacionAccion.id === 9
      ? typeDeclinedNegotation[9]
      : typeDeclinedNegotation[tipoSolicitudNegociacion.id];

  return data;
};

export const flagsTipoSolicitudNegociacionAccion = (
  savedDataNogotation: SolicitudNegociacion
) => {
  const actions = (savedDataNogotation as SolicitudNegociacion)
    ?.ultimaSolicitudNegociacionAcciones
    ?.tipoSolicitudNegociacionAccion as TipoSolicitudNegociacionAccion;

  return {
    flgPermitirAceptarTransportista: actions?.flgPermitirAceptarTransportista,
    flgPermitirRechazarTransportista: actions?.flgPermitirRechazarTransportista,
    flgPermitirContraofertarTransportista:
      actions?.flgPermitirContraofertarTransportista,
    flgPermitirAceptarCliente: actions?.flgPermitirAceptarCliente,
    flgPermitirRechazarCliente: actions?.flgPermitirRechazarCliente,
    flgPermitirContraofertarCliente: actions?.flgPermitirContraofertarCliente,
  };
};
