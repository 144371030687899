import { SxProps, Theme, styled } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";

interface headerIconBrand {
  onClose: (value: boolean) => void;
  route: string;
  icon?: string;
  sx?: SxProps<Theme>;
}

export const IconRedirect = styled(Link)(() => ({}));

const BrandIcon: FC<headerIconBrand> = (props) => {
  const { onClose, route, icon = "logoBrand--headercom", sx } = props;
  return (
    <IconRedirect
      to={route}
      onClick={() => onClose(false)}
      sx={{
        padding: {
          xs: "10px 5px 10px 5px",
          md: "13px 10px 7px 10px",
        },
        ...sx
      }}
    >
      <i className={icon} />
    </IconRedirect>
  );
};

export default BrandIcon;
