import { Grid, useMediaQuery, useTheme } from "@mui/material";
import AdvertisementImage from "./AdvertisementImage";
import { IAdvertisementItem } from "./interfaces/AdvertisementInterface";

const AdvertisementItem = ({ advertisement, index, onLoad, onClick, onlyImage }: IAdvertisementItem) => {
  const theme = useTheme();
  const isSizeLarge = useMediaQuery(theme.breakpoints.up("md"));
  const isSizeMidle = useMediaQuery("(min-width:665px)");

  const handleOnLoad = () => {
    onLoad(advertisement);
  };

  const handleOnClick = () => {
    onClick(advertisement);
  };

  const image = <AdvertisementImage advertisement={advertisement} onClick={handleOnClick} onLoad={handleOnLoad} />;

  if (onlyImage) return image;

  switch (index) {
    case 0:
      return (
        <Grid item xs={12} sm={isSizeMidle ? 6 : 12} md={6} lg={4} display="grid" justifyItems="center" alignItems="center">
          {image}
        </Grid>
      );
    case 1:
      return (
        <Grid item md={6} lg={4} justifyItems="center" alignItems="center" sx={{ display: isSizeMidle ? "grid" : "none" }}>
          {image}
        </Grid>
      );
    default:
      return (
        <Grid item lg={4} justifyItems="center" alignItems="center" sx={{ display: isSizeLarge ? "grid" : "none" }}>
          {image}
        </Grid>
      );
  }
};

export default AdvertisementItem;
