import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/useReducer";
import { CustomerRequest } from "../../../../models/customerRequest/customerRequestResponse";
import { objectRenderComponent } from "./config";

const FormOffert = () => {
  const [adData, setAdData] = useState<CustomerRequest | null>(null);

  const { savedData } = useAppSelector((state) => state.customerRequest);

  useEffect(() => {
    if (!!savedData) {
      setAdData(savedData);
    }
  }, [savedData]);

  if (!adData) return <></>;

  const Component = objectRenderComponent[adData?.tipoSolicitud.id];
  if (!Component) return <></>;

  return <Component />;
};

export default FormOffert;
