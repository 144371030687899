import { SHARE_RIDE } from '@/consts/pathServices';
import { connection, instanceAuth } from './connection';
import { SharedRideResponse } from '@/models/shareTravel/shareTravelModel';

export const getShareTravelServices = async (
  travelId: string
): Promise<SharedRideResponse> => {
  const path = SHARE_RIDE.replace(':idShare', travelId);
  const API = connection(instanceAuth);
  const { data } = await API.get(path);

  return data;
};
