export const REQUEST_ACCEPTED = '¡Solicitud aceptada!';
export const REQUEST_COUNTER_OFFER = '¡Contraoferta enviada!';
export const REQUEST_OFFER = '¡Oferta enviada!';
export const REQUEST_INTEREST = '¡Notificamos tu interés!';
export const REQUEST_QUOTE = '¡Cotización enviada!';
export const SMS_NOTIFICATION_CLIENT_RESPONSE =
  'Te enviaremos un SMS y notificación cuando el cliente responda.';
export const SMS_NOTIFICATION_CARRIER_RESPONSE =
  'Te enviaremos un SMS y notificación cuando el transportista responda.';

export const REQUEST_TYPE_MULTI_DESTINY_HOUR = 'Multidestino por hora';
export const REQUEST_TYPE_MULTI_DESTINY_POINT = 'Multidestino por punto';
export const REQUEST_TYPE_MOVE_CITY = 'Mudanza en la ciudad';
export const REQUEST_TYPE_MOVE_BETWEEN_CITIES = 'Mudanza entre ciudades';

export const REQUEST_COUNTER_OFFER_MSG = 'Sólo puedes contraofertar una vez.';
export const REQUEST_OFFER_WEIGHT_MSG =
  'Sólo puedes ofrecer tu servicio con un vehículo que cumpla con el peso solicitado.';
