import {
  AUTO_LOGIN,
  HOME_PAHT,
  NOTICE_MOVING_PATHS_LIST,
  NOTIFICATIONS_PATH,
  NOT_AUTHORIZED,
  NOT_FOUND,
  NOT_FOUND_PAGE,
  NO_ACCESS,
  NO_ACCESS_CLIENT,
  NO_ACCESS_DRIVER,
  REDIRECT_APP_STORE,
  SHARED_RIDE,
} from '@/consts/typeServicesPaths';
import NoAccess from '@/pages/noAccess/NoAccess';
import NoAcessByRol from '@/pages/noAccess/noAcessByRol/NoAcessByRol';
import NotFound from '@/pages/notFound/NotFound';
import Notifications from '@/pages/notifications/Notifications';
import DetailsCardListCities from '@/pages/viewService/city/detailsCardListCities/DetailsCardListCities';
import { RootState } from '@/store/store';
import { To } from 'history';
import { landingFlowRouter } from './sectionsRouter/landingRouter';
import { noticeNegotationFlowRouter } from './sectionsRouter/noticeNegotationRouter/noticeNegotationRouter';
import { noticePublicRouter } from './sectionsRouter/noticeRouter/noticesPublicRouter';

import { CLIENT_ACCEPT_OR_COUNTEROFFER } from '@/pages/flowNoticeNegotation/routes/aClientAcceptOrCounterOffer/Index';
import Home from '@/pages/landing/home/Home';
import { noticePrivateRouter } from './sectionsRouter/noticeRouter/noticePrivateRouter';
import { noticeV2NegotationRouter } from './sectionsRouter/noticeV2NegotationRouter/noticeV2NegotationRouter';
import { noticeV2FlowsRouter } from './sectionsRouter/noticeV2Router/noticeV2FlowCreate';
import {
  profileNoticeRouter,
  profileRouter,
} from './sectionsRouter/profileRouter';
import {
  customerRequestInCityFlowRouter,
  publicCustomerRequestRouter,
} from './sectionsRouter/requestClientRouter';
import { userRouter } from './sectionsRouter/userRouter';
import { registerFlowRouter } from './sectionsRouter/userRouter/registerFlowRouter';
import AutoLogin from '@/pages/autoLogin';
import SharedRide from '@/pages/sharedRide/SharedRide';
import RedirectAppStore from '@/pages/RedirectAppStore';

export interface MiddlewarePaths {
  currentPath: string;
  pathname: string;
}
export interface BaseRouterProps {
  Component: React.ElementType;
  requiredSession?: boolean; // colocar {true} cuando se necesite estas logueado y  {false} cuando si se esta logueado no se pueda entrar!!
  isFree?: boolean; // para todas las pantallas que siempre se puedan acceder
  path?: string;
  index?: boolean;
  redirectPath?: string;
  middleware?: (state: RootState, paths: MiddlewarePaths) => string | To;
}
export interface RouterProps extends BaseRouterProps {
  children?: BaseRouterProps[];
}

export const router: RouterProps[] = [
  {
    path: HOME_PAHT,
    Component: Home,
    isFree: true,
  },
  {
    path: NOTIFICATIONS_PATH,
    Component: Notifications,
    requiredSession: true,
  },
  {
    path: NOTICE_MOVING_PATHS_LIST,
    Component: DetailsCardListCities,
    requiredSession: true,
  },

  {
    path: NOT_FOUND,
    Component: NotFound,
    isFree: true,
  },
  {
    path: NO_ACCESS,
    Component: NoAccess,
    isFree: true,
  },
  {
    path: NOT_FOUND_PAGE,
    Component: NotFound,
    isFree: true,
  },
  {
    path: NO_ACCESS_CLIENT,
    Component: NoAcessByRol,
  },
  {
    path: NO_ACCESS_DRIVER,
    Component: NoAcessByRol,
  },
  {
    path: NOT_AUTHORIZED,
    Component: NoAccess,
  },
  {
    path: AUTO_LOGIN,
    Component: AutoLogin,
  },
  {
    path: SHARED_RIDE,
    Component: SharedRide,
  },
  // ...noticeFlowsRouter,
  ...noticeV2FlowsRouter,
  ...noticePublicRouter,
  ...noticePrivateRouter,

  ...userRouter,
  ...profileRouter,
  ...profileNoticeRouter,
  ...landingFlowRouter,
  ...registerFlowRouter,

  ...publicCustomerRequestRouter,
  //DEPRECATE //DEPRECADO
  /*  ...offertInCityFlowRouter, */
  /*   ...counterOffertInCityFlowRouter, */
  /*   ...offertMultiDestinyFlowRouter, */
  /* ...offertMovingRouter, */
  /*   ...offertBetweenCitieRouter, */
  /*   ...counterOffertBetweenCitieRouter, */
  /*   ...offertAuctionRouter, */
  /*   ...counterOffertAuctionRouter, */
  // ...acceptanceOffertRouter, //deprecado en proceso de cambio
  ...noticeV2NegotationRouter,
  ...customerRequestInCityFlowRouter,
  ...noticeNegotationFlowRouter,

  //=================================================================================================
  //-----------------------CLIENTE ACEPTA O CONTRAOFERTA EL AVISO DEL TRANSPORTISTA------------------
  //=================================================================================================

  ...CLIENT_ACCEPT_OR_COUNTEROFFER,
  {
    path: REDIRECT_APP_STORE,
    Component: RedirectAppStore,
  }
];
