import {
    Button, Container, experimental_sx as sx,
    styled
} from "@mui/material";

export const ContainerButtons = styled(Container)(
  sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    mt: 2,
    mb: 2,
  })
);

