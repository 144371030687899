import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import SearchModal from "@/components/modals/searchModal/SearchModal";
import {
  ID_COUNTEROFFER,
  ID_MOVING_BETWEEN_CITIES,
} from "@/consts/defaultConsts";
import { textAceptarContactar, textSiguiente } from "@/consts/noticeConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { DetailNoticeMoving } from "@/models/notice/noticeModels";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import SelectsTypeProperties from "@/pages/flowNoticeNegotation/components/SelectsTypeProperties";
import {
  ID_DEPARTAMENT,
  ID_HOUSE,
} from "@/pages/flowNoticeNegotation/shared/const/default";
import {
  defaultInmueble,
  defaultInputDestiny,
} from "@/pages/flowNoticeNegotation/shared/const/offertConst";
import { getDistrictsByCityAndTermThunk } from "@/store/locationRedux/districtsThunks";
import { setSelectDestiny } from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferSlice";
import {
  saveOfferMovingThunk,
  saveOriginInCityThunk,
} from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferThunk";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { cleanInputNumber } from "@/utils/inputsUtils";
import { FormControl, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const FormDestinyDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    noticeV2ClientAorCO: {
      selectTypeNotice,
      listTypeProperty,
      isLoading,
      idTypeNoticeNegotiation,
      selectedDestiny,
    },
    districts: { districts },
  } = useAppSelector((state) => state);
  const notice = selectTypeNotice as DetailNoticeMoving;

  const [openModal, setOpenModal] = useState(false);
  const [selectsForm, setSelectsForm] = useState(defaultInmueble);
  const [inputForm, setInputForm] = useState(defaultInputDestiny);

  const isBetweenCities =
    notice.flgciudadoentreciudades === ID_MOVING_BETWEEN_CITIES;
  const isCounterOffer = idTypeNoticeNegotiation === ID_COUNTEROFFER;

  const handleOnChange = (target: Record<string, string>) => {
    const { value, name } = target;
    if (name === "typeProperty" && Number(value) !== ID_DEPARTAMENT) {
      setSelectsForm(() => ({ ...defaultInmueble, [name]: value }));
    } else {
      setSelectsForm((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleInputChange = ({ target }: EventType["change"]) => {
    let { name, value } = target;
    if (name === "area") {
      value = cleanInputNumber(value);
    }

    setInputForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleNextRute = () => {
    dispatch(
      getNextRoute(navigate, {
        valueToReplace: notice.id?.toString(),
        valueToSearch: ":id",
      })
    );
  };

  const handleConvertNamesSelectsForm = () => {
    return {
      destinyTypeProperty: selectsForm.typeProperty,
      destinyFloor: selectsForm.floor,
      destinyAscensor: selectsForm.ascensor,
    };
  };

  const handleSubmit = () => {
    if (isLoading) return;
    const selects = handleConvertNamesSelectsForm();

    dispatch(saveOriginInCityThunk({ ...inputForm, ...selects }));
    if (idTypeNoticeNegotiation === ID_COUNTEROFFER) {
      handleNextRute();
    } else {
      dispatch(saveOfferMovingThunk()).then(() => {
        handleNextRute();
      });
    }
  };

  const handleChangeModal = (term: string) => {
    if (term.length >= 3) {
      const id = notice.idciudaddestino?.toString();
      dispatch(getDistrictsByCityAndTermThunk(id, term));
    }
  };

  const handleOnSubmitModal = (value: Record<string, any> | null) => {
    const body = {
      nombre: value?.nombre,
      precio: 0,
      iddistrito: value?.id,
    };
    setOpenModal(false);
    dispatch(setSelectDestiny(body));
  };

  const handleDisable = () => {
    const { ascensor, typeProperty } = selectsForm;
    const { destinyReference } = inputForm;
    const rulesMultiSelects = [!ascensor, !typeProperty, !destinyReference];
    const rulesBetweenCities = [!typeProperty, !destinyReference];
    if (isBetweenCities) {
      rulesMultiSelects.push(!selectedDestiny.iddistrito);
      rulesBetweenCities.push(!selectedDestiny.iddistrito);
    }
    if (Number(typeProperty) !== ID_HOUSE) {
      return rulesMultiSelects.some((rule) => rule);
    }

    return rulesBetweenCities.some((rule) => rule);
  };

  const buttonText = isCounterOffer ? textSiguiente : textAceptarContactar;

  return (
    <div>
      <Typography variant="subtitle1">
        Destino: {notice.destinotexto}
      </Typography>

      <SearchModal
        open={openModal}
        onClose={setOpenModal}
        onSubmit={handleOnSubmitModal}
        onChange={handleChangeModal}
        list={districts}
        title="Distrito de destino"
        inputProps={{
          name: "search-district",
          label: "Destino",
          placeholder: "Ingresa distrito de destino",
        }}
      />
      {isBetweenCities && (
        <FormControl fullWidth sx={{ marginTop: "16px" }}>
          <Input
            label={<LabelInputRequired str="Distrito de destino" />}
            name="district"
            placeholder="Distrito de destino"
            value={selectedDestiny.nombre}
            onClick={() => setOpenModal(true)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      )}

      <FormControl fullWidth sx={{ marginTop: "16px" }}>
        <Input
          label={<LabelInputRequired str="Referencia del destino" />}
          name="destinyReference"
          placeholder="Lugar conocido cerca a la dirección"
          rows={2}
          multiline
          value={inputForm.destinyReference}
          onChange={handleInputChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>

      <SelectsTypeProperties
        form={selectsForm}
        listTypeProperty={listTypeProperty}
        onChange={handleOnChange}
        isCounterOffer={isCounterOffer}
      />

      <FormControl fullWidth>
        <ButtomLoader
          onClick={handleSubmit}
          disabled={handleDisable()}
          isLoading={isLoading}
        >
          {buttonText}
        </ButtomLoader>
      </FormControl>
    </div>
  );
};

export default FormDestinyDetails;
