import Icon, { IconTypes } from "@/utils/Icon";
import { SxProps, Theme, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StyleCardHorizont } from "./styleCardHorizont";

type Values = {
  link?: string;
  typeFLow?: string;
};

export type CardHorizonProps = {
  icon: string | any;
  IconComponent?: any;
  title: string;
  link?: string;
  typeFLow?: string;
  containerIconSx?: SxProps<Theme>;
  sx?: SxProps<Theme>;
  onClick?: (value: Values) => void;
  replaceNavigate?: boolean;
};

const CardHorizont = ({
  onClick,
  icon,
  title,
  link,
  typeFLow,
  containerIconSx,
  sx,
  replaceNavigate = false,
  IconComponent,
}: CardHorizonProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    onClick && onClick({ link, typeFLow });

    if (link) {
      navigate(link, { replace: replaceNavigate });
    }
  };

  return (
    <StyleCardHorizont
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        gap: "16px",
        margin: "16px 0px",
      }}
      onClick={handleClick}
    >
      {IconComponent ? (
        <IconComponent color="accent2" />
      ) : (
        <Icon name={icon as IconTypes} size="lg" color="info" />
      )}
      <Typography variant="body1">{title}</Typography>
    </StyleCardHorizont>
  );
};

export default CardHorizont;
