import { WelcomeClientSVG, WelcomeTransportSVG } from '@/assets/svg';
import {
  HOME_PAHT,
  PROFILE_MIS_VEHICULOS_PATH,
  SERVICES_LIST_DRIVER,
  WELCOME_CLIENT,
  WELCOME_DRIVER,
} from '@/consts/typeServicesPaths';
import { CREATE_CUSTOMER_REQUEST_PATH } from '@/pages/customerRequest/shared/consts/routes';

export const configWelcome: Record<string, IConfigWelcome> = {
  [WELCOME_CLIENT]: {
    section: {
      Icon: WelcomeClientSVG,
      subtitle1:
        'Ahora podrás encontrar transporte para tu carga de una forma fácil, rápida y segura.',
      subtitle2: '¿Qué quieres hacer ahora?',
    },
    primaryButton: {
      text: 'BUSCAR TRANSPORTE',
      rute: HOME_PAHT,
    },
    secondaryButton: {
      text: 'SOLICITAR TRANSPORTE',
      rute: CREATE_CUSTOMER_REQUEST_PATH,
    },
  },
  [WELCOME_DRIVER]: {
    section: {
      Icon: WelcomeTransportSVG,
      subtitle1:
        'Ahora podrás encontrar nuevos clientes de una forma fácil, rápida y segura.',
      subtitle2: '¿Qué quieres hacer ahora?',
    },
    primaryButton: {
      text: 'CREAR AVISOS',
      rute: SERVICES_LIST_DRIVER,
    },
    secondaryButton: {
      text: 'AGREGAR VEHÍCULOS',
      rute: PROFILE_MIS_VEHICULOS_PATH,
    },
  },
};

export interface IConfigWelcome {
  section: {
    Icon: React.FC;
    subtitle1: string;
    subtitle2: string;
  };
  primaryButton: {
    text: string;
    rute: string;
  };
  secondaryButton: {
    text: string;
    rute: string;
  };
}
