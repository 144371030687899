import { BASE_URL_IMAGE } from "@/config/config";
import { ADVERTISERS_HERO_SM } from "@/consts/defaultConsts";
import useResize from "@/hooks/useResize";
import imageFirstSectionImgTwo from "@/img/landing/advertisers/section-2-laptop.png";
import imageLaptopSectionThree from "@/img/landing/advertisers/section-3-laptop.png";
import imagePhoneSectionThree from "@/img/landing/advertisers/section-3-phone.png";
import imageFirstSectionImgOne from "@/img/landing/advertisers/section1-left-img1.png";
import Footer from "@/pages/footer/Footer";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import BannerLand from "../components/bannerLand/BannerLand";
import InfoLand from "../components/infoLand/InfoLand";
import SectionLand from "../components/sectionLand/sectionLand";
import AdsSection from "./components/AdsSection";
import { reasonsList } from "./configData";
import {
  ButtonAccionModal,
  ContainerSection,
  ContainerTypeAds,
  ListReasons,
  ReasonDetail,
  Reasons,
  SubHeadingAds,
  SubtitleSection,
  TitleSection,
  responsiveImagesAds,
} from "./styleAds";

const URL_ADS_PATH = "http://Ads.pegacargo.com";

const Ads = () => {
  const [, isMobil] = useResize();
  const mobile = useMediaQuery("(max-width:770px)");
  const desktop = useMediaQuery("(min-width:1000px)");
  const tablet = useMediaQuery("(max-width:1279px)");

  return (
    <>
      {/* banner de imagenes */}
      <BannerLand
        urlImg={`${BASE_URL_IMAGE}${ADVERTISERS_HERO_SM}`}
        responsive={responsiveImagesAds}
        positionXY={{ xs: "center", md: "center" }}
        minHeight="166px"
      >
        {desktop && (
          <SectionLand
            backgroundColor="black"
            sx={{
              background:
                "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%)",
              height: tablet ? "240px" : "340px",
            }}
          >
            <InfoLand
              height="auto"
              title="potencia tu negocio"
              subtitle="Tu publicidad tendrá mayor alcance por personas y empresas que forman parte de tu público objetivo."
              gap={"10px"}
              sx={{
                gap: { xs: "16px", sm: tablet ? "10px" : "20px" },
              }}
            >
              <ButtonAccionModal
                href={URL_ADS_PATH}
                variant="contained"
                color="inherit"
              >
                COMPRAR PUBLICIDAD
              </ButtonAccionModal>
            </InfoLand>
          </SectionLand>
        )}
      </BannerLand>
      {/* contenido de texto */}
      {!desktop && (
        <SectionLand backgroundColor="#382E2B">
          <InfoLand
            height="auto"
            title="potencia tu negocio"
            subtitle="Tu publicidad tendrá mayor alcance por personas y empresas que forman parte de tu público objetivo."
            sx={{ gap: "16px" }}
          >
            <ButtonAccionModal
              href={URL_ADS_PATH}
              variant="contained"
              color="inherit"
              sx={{
                backgroundColor: "#FF6700",
                color: "white",
              }}
            >
              COMPRAR PUBLICIDAD
            </ButtonAccionModal>
          </InfoLand>
        </SectionLand>
      )}
      {/* SECTION 1 */}
      <SectionLand
        sx={{ justifyContent: "flex-start" }}
        title=""
        backgroundColor={mobile ? "#fafafa" : "white"}
      >
        <SubHeadingAds>
          <Grid container gap={2} justifyContent="center" wrap="nowrap">
            <img
              width={"212px"}
              height="420px"
              src={imageFirstSectionImgOne}
              alt="img2"
            />
          </Grid>
          <ListReasons>
            <Typography
              sx={{ fontSize: "22px", fontWeight: 700, marginBottom: "16px" }}
            >
              ¿Por qué comprar publicidad en PegaCargo?
            </Typography>
            {reasonsList.map(({ icon, title, description }, i) => (
              <Reasons key={i}>
                <i className={isMobil ? icon.xs : icon.md} />
                <ReasonDetail>
                  <Typography variant="h6">{title}</Typography>
                  <Typography variant="body1">{description}</Typography>
                </ReasonDetail>
              </Reasons>
            ))}
           {(!mobile && !tablet) && <ButtonAccionModal
              href={URL_ADS_PATH}
              variant="contained"
              color="inherit"
              sx={{
                backgroundColor: "#FF6700",
                color: "white",
                marginTop: "16px",
              }}
            >
              COMPRAR PUBLICIDAD
            </ButtonAccionModal>}
          </ListReasons>
        </SubHeadingAds>
      </SectionLand>

      <ContainerSection mobile={mobile}>
        <ContainerTypeAds maxWidth="lg">
          <TitleSection sx={{ marginTop: "32px", marginBottom: "16px" }}>
            Invierte a tu medida
          </TitleSection>
          <SubtitleSection>
            En PegaCargo tenemos 2 tipos de anuncio
          </SubtitleSection>
        </ContainerTypeAds>
        {/* SECTION 2 */}
        <AdsSection
          title="Tipo zócalo fijo (strip)"
          urlBtn={URL_ADS_PATH}
          text="Medida pixeles: 320 ancho x 100 alto"
          btnTxt="COMPRAR PUBLICIDAD"
          imgOne={imageFirstSectionImgOne}
          imgTwo={imageFirstSectionImgTwo}
        />
        {/* SECTION 3 */}
        <AdsSection
          title="Tipo tarjeta (entre contenido)"
          urlBtn={URL_ADS_PATH}
          text="Medida pixeles: 320 ancho x 200 alto"
          btnTxt="COMPRAR PUBLICIDAD"
          imgOne={imagePhoneSectionThree}
          imgTwo={imageLaptopSectionThree}
        />
      </ContainerSection>

      <Footer />
    </>
  );
};

export default Ads;
