export const messageAlertData = {
  renew: {
    title: '¿Estás seguro?',
    message:
      'No se renovará el servicio ni se guardarán las modificaciones. ¿Deseas descartar los cambios?',
    ok: 'Descartar',
    cancel: 'Cerrar',
  },
  edit: {
    title: 'Cambios sin guardar',
    message: 'Aún no has guardado los cambios realizados, ¿Deseas salir?',
    ok: 'SI, SALIR',
    cancel: 'NO, QUEDARME',
  },
};
