import CardMyRequest from "@/components/CardMyRequest";
import SkeletonCardRequest from "@/components/CardMyRequest/SkeletonCardRequest";
import EmptyListData from "@/components/emptyListData/EmptyListData";
import { IMyRequestListData } from "@/models/customerRequest/MyRequestListDataResponse";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { prepareLinkCustomerRequestAdapter } from "../../../../../adapters/routesAdapters";
import { MY_APPLICATIONS_PATH } from "../../../../customerRequest/shared/consts/routes";
import { adapterMyCardRequestData } from "../adapters/CardAdapterData";

export type ListPropsCustomer = {
  requestList: IMyRequestListData[];
  isLoading: boolean;
  refObserver?: any;
  isLoadingPagination?: boolean;
};
const ListCustomer = ({
  requestList,
  isLoading,
  refObserver,
  isLoadingPagination,
}: ListPropsCustomer) => {
  const navigate = useNavigate();

  if (isLoading) return <SkeletonCardRequest />;

  if (!requestList?.length) {
    return <EmptyListData title="No tienes solicitudes" />;
  }

  const handleNavigate = (idTypeRequest: number, idRequest: number) => {
    const path = prepareLinkCustomerRequestAdapter(
      MY_APPLICATIONS_PATH,
      idTypeRequest,
      idRequest?.toString()
    );
    navigate(path, { state: { hasBackNavigate: true } });
  };

  return (
    <>
      {requestList?.map((data, index) => (
        <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
          <CardMyRequest
            {...adapterMyCardRequestData(data)}
            onClick={() => handleNavigate(data.idTipoSolicitud, data.id)}
          />
        </Grid>
      ))}
      <div ref={refObserver} />
      {isLoadingPagination && <SkeletonCardRequest />}
    </>
  );
};

export default ListCustomer;
