export const inputWeight = {
  label: 'Peso estimado (kilos)',
  placeholder: 'Ingresa el peso estimado de tu carga',
  name: 'pesoEstimado',
  type: 'number',
  autoComplete: 'off',
  fullWidth: true,
  InputLabelProps: {
    shrink: true,
  },
};

export const inputDescriptionLoad = {
  label: 'Descripción de la carga',
  placeholder: 'Información sobre tu carga que necesitan los transportistas.',
  name: 'descriptionLoad',
  autoComplete: 'off',
  fullWidth: true,
  minRows: 3,
  maxRows: 3,
  multiline: true,
  InputLabelProps: {
    shrink: true,
  },
};

export const inputTypevehicle = {
  label: 'Tipo de vehículo',
  placeholder: 'Elige el vehículo que necesitas.',
  name: 'vehicle',
  autoComplete: 'off',
  fullWidth: true,
  InputLabelProps: {
    shrink: true,
  },
};
export const inputTypeWeight = {
  label: 'Tipo de vehículo',
  placeholder: 'Peso estimado de tu carga.',
  name: 'vehicle',
  autoComplete: 'off',
  fullWidth: true,
  InputLabelProps: {
    shrink: true,
  },
};
