import { useEffect, useState } from "react";

interface IObserver {
  distance?: string;
  externalRef: any;
  hasDisconect?: boolean
}

const useObserver = ({ distance = "100px", externalRef, hasDisconect }: IObserver) => {
  const [isNearScreen, setShow] = useState(false);

  useEffect(() => {
    if (!externalRef) return;
    let observer: any;

    const element = externalRef.current;

    Promise.resolve(
      typeof IntersectionObserver !== "undefined" ? IntersectionObserver : import("intersection-observer" as unknown as string)
    ).then(() => {
      observer = new IntersectionObserver(onChange, {
        rootMargin: distance,
      });
      if (element) observer.observe(element);
    });

    return () => observer && observer.disconnect();
  });

  const onChange = (entries: any, observer: any) => {
    const el = entries[0];
    if (el.isIntersecting) {
      setShow(true);
      if (hasDisconect) {
        observer.disconnect();
      }
    } else {
      setShow(false);
    }
  };

  return { isNearScreen };
};

export default useObserver;
