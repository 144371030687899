import { NoticeV2NegType } from '@/store/noticeV2Negotiation/noticeV2NegotiationSlice';
import { actionButtonNoticeV2 } from '../config';
import { ID_MOVING_FLOW } from '@/consts/defaultConsts';

export const counterOfferDataAdapter = ({
  notice,
  valueInput,
  formMoving,
}: {
  notice: NoticeV2NegType;
  valueInput: string;
  formMoving: {
    price: number;
    assistant: number;
  };
}) => {
  let currentPrice = valueInput;
  const noticeId = (notice as NoticeV2NegType).id;
  const isMoving = notice?.idtipoaviso === ID_MOVING_FLOW;

  let data = {
    idOffer: noticeId,
    idAccion: actionButtonNoticeV2[2][2] as number,
    preciocontraoferta: valueInput,
    precioactual: (notice as NoticeV2NegType).precio,
  } as {};

  if (isMoving) {
    currentPrice = formMoving.price.toString();
    data = {
      idOffer: noticeId,
      idAccion: actionButtonNoticeV2[2][2] as number,
      preciocontraoferta: formMoving.price.toString(),
      ayudantes: formMoving.assistant,
    };
  }

  return data;
};
