import { DistrictAPI } from "@/models/location/districtModel";
import { multiSelectCity } from "@/store/locationRedux/selectLocation";

export type ReturnDataToShow = {
  districts: string[],
  zone: string,
  idzone: number
}
/**
 * Toma una matriz de objetos y devuelve una matriz de objetos
 * @param {multiSelectCity[]} multiSelect - multiSeleccionarCiudad[]
 * @param {DistrictAPI[]} zones - DistritoAPI[]
 */
export const getDataToShowZoneAndDistrict = (
  multiSelect: multiSelectCity[],
  zones: DistrictAPI[]
): ReturnDataToShow[] => {
  const result: ReturnDataToShow[] = multiSelect.reduce((acc: ReturnDataToShow[], el: multiSelectCity) => {
    const indexExist = acc.findIndex(({ idzone }: any) => idzone === el.idzone);
    if (indexExist >= 0) {
      acc[indexExist].districts.push(el.name);
      return acc;
    }

    const zone = getZoneById(zones, el.idzone);

    const result = getStructureReturn(acc, el, zone);

    return result
  }, []);

  return result;
};

/**
 * Toma una matriz de objetos y una identificación, y devuelve el nombre del objeto que coincide con la
 * identificación
 * @param {DistrictAPI[]} zones - DistritoAPI[]
 * @param {number} id - El id de la zona que desea obtener.
 * @returns El nombre de la zona que coincide con el id.
 */
const getZoneById = (zones: DistrictAPI[], id: number): string => {
  let zone = "";

  if (!!zones.length) {
    zone = zones.find((zone) => zone?.id === id)?.nombre || "";
  }

  return zone;
};

/**
 * Toma un acumulador, un elemento y una zona y devuelve una matriz de objetos con idzone, zone y
 * districts
 * @param {any} acc - any: este es el acumulador, que es el valor que se devuelve de la iteración
 * anterior.
 * @param {multiSelectCity} el - multiSeleccionarCiudad
 * @param {string} zone - cuerda
 */
const getStructureReturn = (acc: any, el: multiSelectCity, zone: string): ReturnDataToShow[] => ([
  ...acc,
  {
    idzone: el.idzone,
    zone,
    districts: [el.name],
  },
])
