import CustomTags from "@/components/customTags/CustomTags";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { TypeFilterEnum } from "@/models/userAuth/agrementToAccept";
import IconsSvg from "@/pages/detailNotice/components/IconsSvg";
import { getMyNegotationsLitsThunk } from "@/store/myListNegotiationsRedux/myListNegotationsThunk";
import { cleanMyListNegotiationsState } from "@/store/myListNegotiationsRedux/myListNegotiationsSlice";
import { Container, Grid, IconButton, Typography } from "@mui/material";
import queryString from "query-string";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { HeaderContainer } from "../noticeOffert/styleNoticeOffert";
import ListChildren from "./components/ListChildren";
import { loadAbort } from "@/utils/helpers";
import { objectTypeState } from "./consts/consts";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { CLIENT_TYPE } from "@/consts/defaultConsts";

const AgreementToAccept = () => {
  const { myListNegotiations: negotationsList, user } = useAppSelector(
    (state) => state
  );
  const [tagActive, setTagActive] = useState(1);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [_, setSearchParams] = useSearchParams();

  const signal = useRef(loadAbort());
  const currentUser = user.user as IUser;

  useEffect(() => {
    if (!!location.search) {
      const tag = queryString.parse(location.search);
      tag.activeTag && setTagActive(Number(tag.activeTag));
    }
    return () => {
      dispatch(cleanMyListNegotiationsState());
    };
  }, []);

  useEffect(() => {
    hanldeInit(tagActive);
  }, [tagActive]);

  const hanldeInit = async (tag: number) => {
    signal.current.abort(); // Se cancela el request actual
    signal.current = loadAbort(); // se genera un nuevo id para el request!!
    const { state, type } = objectTypeState[tag];
    if (!negotationsList[state].negociaciones.length) {
      await dispatch(
        getMyNegotationsLitsThunk(type, {
          signal: signal.current.signal,
        })
      );
    }
  };

  const handlePaginate = (typeNegotation: TypeFilterEnum) => () => {
    dispatch(getMyNegotationsLitsThunk(typeNegotation, { isPaginate: true }));
  };

  const handleChangeTag = (activeTag: number) => {
    const query = queryString.stringify({ activeTag });
    setTagActive(activeTag);
    setSearchParams(query);
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        marginTop: "41px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} spacing={2}>
          <HeaderContainer maxWidth={170}>
            <IconButton aria-label="back" onClick={() => navigate(-1)}>
              <IconsSvg icon={"icon-arrow-left--primary"} />
            </IconButton>
            <Typography variant="h6">Negociaciones</Typography>
          </HeaderContainer>
        </Grid>

        <Grid item xs={12} sm={12} md={12} spacing={2}>
          <CustomTags
            label1="POR CERRAR"
            label2="CERRADAS"
            label3="VENCIDAS"
            onChange={handleChangeTag}
            initialTag={tagActive}
            childrenTag1={
              <ListChildren
                list={negotationsList.actives.negociaciones}
                isLoading={negotationsList.actives.isLoading}
                paginateCallback={handlePaginate(TypeFilterEnum.abiertas)}
                isTypeClient={currentUser.tipousuario === CLIENT_TYPE}
              />
            }
            childrenTag2={
              <ListChildren
                list={negotationsList.history.negociaciones}
                isLoading={negotationsList.history.isLoading}
                paginateCallback={handlePaginate(TypeFilterEnum.cerradas)}
                isTypeClient={currentUser.tipousuario === CLIENT_TYPE}
              />
            }
            childrenTag3={
              <ListChildren
                list={negotationsList.expired.negociaciones}
                isLoading={negotationsList.expired.isLoading}
                paginateCallback={handlePaginate(TypeFilterEnum.vencidas)}
                isTypeClient={currentUser.tipousuario === CLIENT_TYPE}
              />
            }
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AgreementToAccept;
