import { Grid, GridProps } from "@mui/material";
import React, { FC } from "react";
import SkeletonInfoCard from "../infoCard/SkeletonInfoCard";

interface Props extends GridProps {
  quantity?: number;
}
const defaultQuantity = 6;

const SkeletonInfoCardList: FC<Props> = ({ quantity, ...props }) => {
  return (
    <>
      {[...Array(quantity || defaultQuantity)].map((_, index) => (
        <Grid item key={index} {...props}>
          <SkeletonInfoCard/>
        </Grid>
      ))}
    </>
  );
};

export default SkeletonInfoCardList;
