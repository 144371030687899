import { useState, useEffect } from "react";
import { DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StyleCustomInput } from "../customInput/styleCustomInput";
import useDebounce from "@/hooks/useDebounse";
import { FORMAT_DATE_DDMMYYYY } from "@/consts/formatsDate";
import esFormat from "dayjs/locale/es";
import useResize from "@/hooks/useResize";
import { InputAdornment, Typography } from "@mui/material";
import CalendarIcon from "@mui/icons-material/Event";
import { useAppSelector } from "@/hooks/useReducer";
import "./datePicker.css";
import { fontFamily } from "@/styles/theme";
import { esES } from "@mui/x-date-pickers/locales";
interface Props {
  label: string | React.ReactNode;
  showStylesLabel?: boolean;
  inputFormat?: string;
  initValue: Dayjs | null;
  onError?: (reason: any, value: dayjs.Dayjs | null) => void;
  onChange: (
    value: dayjs.Dayjs | null,
    keyboardInputValue?: string | undefined
  ) => void;
  onClick?: () => void;
  value?: Dayjs | null;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  disablePast?: boolean;
  disabled?: boolean;
  renderInput?: any;
  InputProps?: any;
  fullWidth?: boolean;
  variant?: string;
}

const DatePicker = ({
  onError,
  label,
  showStylesLabel = false,
  onChange,
  value,
  minDate,
  maxDate,
  inputFormat = FORMAT_DATE_DDMMYYYY,
  initValue = null,
  disablePast = true,
  disabled = false,
  renderInput = StyleCustomInput,
  InputProps = {},
  fullWidth = false,
  onClick,
  variant = "standard",
}: Props) => {
  const [datePicker, setDatePicker] = useState<Dayjs | null>(null);
  const [openPicker, setOpenPicker] = useState(false);
  const [, isMobil] = useResize();
  const { fixedDate } = useAppSelector((state) => state.notice);

  const RenderInput = renderInput;

  useEffect(() => {
    if (!initValue) {
      setDatePicker(fixedDate);
    } else {
      setDatePicker(initValue);
    }
  }, [fixedDate, initValue]);

  const debouncedValue = useDebounce(openPicker, 40);

  const handleChange = (newValue: Dayjs | null) => {
    !value && setDatePicker(newValue);
    setOpenPicker(false);
    onChange(value || newValue);
  };

  return (
    <LocalizationProvider
      adapterLocale={esFormat}
      dateAdapter={AdapterDayjs}
      localeText={
        esES.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      {isMobil ? (
        <MobileDatePicker
          label={label}
          inputFormat={inputFormat}
          disablePast={disablePast}
          disabled={disabled}
          value={value || datePicker}
          onChange={handleChange}
          open={!disabled && debouncedValue}
          onOpen={() => {
            setOpenPicker(true);
          }}
          DialogProps={{
            classes: { root: "datePickerCustom" },
          }}
          onClose={() => {
            setOpenPicker(false);
          }}
          onError={onError}
          minDate={minDate}
          maxDate={maxDate}
          renderInput={(params) => (
            <RenderInput
              {...params}
              variant={variant}
              fullWidth={fullWidth}
              onClick={() => {
                setOpenPicker(!openPicker);
                onClick && onClick();
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIcon />
                  </InputAdornment>
                ),
                ...InputProps,
              }}
            />
          )}
        />
      ) : (
        <DesktopDatePicker
          label={
            showStylesLabel ? (
              label
            ) : (
              <Typography sx={{ fontFamily: fontFamily, fontWeight: 600 }}>
                {label}
              </Typography>
            )
          }
          inputFormat={inputFormat}
          disablePast={disablePast}
          disabled={disabled}
          value={value || datePicker}
          onChange={handleChange}
          open={!disabled && debouncedValue}
          PaperProps={{
            classes: { root: "datePickerCustom" },
          }}
          onOpen={() => {
            setOpenPicker(true);
          }}
          onClose={() => {
            setOpenPicker(false);
          }}
          onError={onError}
          minDate={minDate}
          maxDate={maxDate}
          renderInput={(params) => (
            <RenderInput
              {...params}
              variant={variant}
              fullWidth={fullWidth}
              onClick={() => {
                setOpenPicker(!openPicker);
                onClick && onClick();
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={InputProps}
            />
          )}
        />
      )}
    </LocalizationProvider>
  );
};

export default DatePicker;
