import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { StyleCustomInput } from "@/components/inputCustom/InputCustom";
import LabelRequired from "@/components/utils/LabelRequired";
import { snackbarAcceptInCityMsg } from "@/consts/notifficationsMessage";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { counterOfferRequest } from "@/services/dataLayer/createRequestNeg";
import { saveDriverNegRequestThunk } from "@/store/noticeNegotationRequestRedux/noticeNegRequesThunk";
import { FormControl, Grid, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomerRequest } from "../../../../../models/customerRequest/customerRequestResponse";
import { offertExpressAdapter } from "../../../../../pages/noticeRequestFlow/adapters/noticeRequestFlowAdapters";
import { noticeNegotationRequestObjectTypes } from "../../../../../pages/noticeRequestFlow/consts/noticeRequestFlowConsts";
import { getNextRoute } from "../../../../../store/routeActionsFlow/routeActionsFlowNoticeThunk";
import {
  ISelectedVehicle,
  IUser,
} from "../../../../../store/userRedux/userReduxInterface";
import SelectCar from "../../components/SelectCar";

const Container = styled(Grid)(
  () => `
    display: flex;
    flex-direction: column;  
    margin-top: 24px;
  `
);
const FormOffertExpress = () => {
  const { addNotiffication } = useNotiffication();
  const { idSolicitud = "" } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [form, setForm] = useState({
    price: "",
    minutes: "",
  });

  const {
    customerRequest: { savedData },
    user: { user, selectedVehicle },
    noticeNegotationRequest: { isLoading, error },
  } = useAppSelector((state) => state);
  const userId = (user as IUser).id;
  const typeNegotation = savedData?.tipoSolicitud?.nombre as string;

  const handleOnchange = ({ currentTarget }: EventType["change"]) => {
    const { value } = currentTarget;
    const prepareValue = value.replace(/^(0+)/g, "").replace(/[^0-9]/g, "");

    if (prepareValue.length < 5) {
      setForm({ ...form, [currentTarget.name]: prepareValue });
    }
  };

  useEffect(() => {
    if (!!error) {
      handleManageDialog();
    }
  }, [error]);

  const handleSubmit = (event: EventType["submit"]) => {
    event.preventDefault();

    const body = offertExpressAdapter(
      selectedVehicle as ISelectedVehicle,
      Number(form.price),
      Number(form.minutes)
    );

    dispatch(saveDriverNegRequestThunk(idSolicitud, body)).then((res) => {
      counterOfferRequest(userId, typeNegotation);
      handleNextRute();
    });
  };

  const handleNextRute = () => {
    const valueToReplace =
      noticeNegotationRequestObjectTypes[
        (savedData as CustomerRequest).tipoSolicitud.id
      ];
    const idValueToReplace = idSolicitud.toString();
    dispatch(
      getNextRoute(navigate, {
        valueToSearch: ":typeSolicitud",
        valueToReplace,
        idToSearch: ":idSolicitud",
        idValueToReplace,
      })
    );
  };
  const handleDisabledButton = () => {
    const { price, minutes } = form;
    return !price || !minutes;
  };

  const handleManageDialog = () => {
    addNotiffication({
      title: error || snackbarAcceptInCityMsg.TITLE,
      message: snackbarAcceptInCityMsg.MESSAGE,
      onSubmit: async () => {},
    });
  };

  return (
    <Container container>
      <form onSubmit={handleSubmit}>
        <FormControl sx={{ width: "100%" }}>
          <SelectCar idAvisoOrSolicitud={Number(idSolicitud)} />
        </FormControl>

        <FormControl sx={{ marginTop: "16px", width: "100%" }}>
          <StyleCustomInput
            id={`$1-price`}
            name="price"
            label={<LabelRequired str="Precio ofrecido (S/)" />}
            value={form.price}
            variant="standard"
            placeholder="Monto ofrecido por el servicio"
            onChange={handleOnchange}
            onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
            }}
          />
        </FormControl>
        <FormControl sx={{ marginTop: "16px", width: "100%" }}>
          <StyleCustomInput
            id={`$1-minutes`}
            name="minutes"
            label={<LabelRequired str="Tiempo aprox. de llegada (minutos)" />}
            value={form.minutes}
            variant="standard"
            placeholder="Tiempo de llegada"
            onChange={handleOnchange}
            onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
            }}
          />
        </FormControl>

        <Grid item xs={12} sx={{ marginTop: "16px" }}>
          <ButtomLoader
            type="submit"
            buttomProps={{
              disabled: handleDisabledButton() || isLoading,
            }}
          >
            Enviar
          </ButtomLoader>
        </Grid>
      </form>
    </Container>
  );
};

export default FormOffertExpress;
