import { optionOne, optionTwo } from '@/consts/noticeConsts';
import { NoticeDetailMultiDestinyModel } from '@/models/notice/noticeV2NegotationModels';
import { NoticeV2NegType } from '@/store/noticeV2Negotiation/noticeV2NegotiationSlice';
import { currencyFormat } from '@/utils/helpers';

export const handleTypeMultidestinyOfferNotice = (data: NoticeV2NegType) => {
  const newData = data as NoticeDetailMultiDestinyModel;
  let result = {
    type: '',
    amount: '',
    total: 0,
  };

  if (!!newData?.horas) {
    result = {
      type: 'hora',
      amount: newData.horas.toString(),
      total: newData.precioCalculadoContraOferta || newData?.preciocalculado as number,
    };
  } else if (!!newData?.puntos) {
    result = {
      type: 'punto'	,
      amount: newData.puntos.toString(),
      total: newData.precioCalculadoContraOferta || newData?.preciocalculado as number,
    };
  }

  return result;
};

export const handleTypeMultidestiny = (data: NoticeV2NegType) => {
  const newData = data as NoticeDetailMultiDestinyModel;
  let result = {
    type: '',
    amount: '',
    priceType: 0,
    total: 0,
  };

  if (!!newData?.horas) {
    result = {
      type: 'hora',
      amount: newData.horas.toString(),
      priceType: newData.precio,
      total: newData?.preciocalculado as number,
    };
  } else if (!!newData?.puntos) {
    result = {
      type: "punto",
      amount: newData.puntos.toString(),
      priceType: newData.precio,
      total: newData?.preciocalculado as number,
    };
  }

  return result;
};

export const handleTypeMultidestinyNotice = (data: NoticeV2NegType) => {
  const newData = data as NoticeDetailMultiDestinyModel;
  const { aviso } = newData;
  let result = {
    type: '',
    amount: 0,
    price: 0,
    total: 0,
  };

  if (aviso.flghoraopunto === optionOne) {
    result = {
      type: 'hora',
      amount: aviso?.horasminimo as number,
      price: aviso.precio,
      total: aviso?.preciocalculado as number,
    };
  } else if (aviso.flghoraopunto === optionTwo) {
    result = {
      type: 'punto',
      amount: aviso?.puntosminimo as number,
      price: aviso?.precio,
      total: aviso?.preciocalculado as number,
    };
  }

  return result;
};

type NoticeDataType = {
  type: string;
  amount: string;
  priceType: number;
  total: number;
};

type NoticeDataTwoType = {
  type: string;
  amount: number;
  price: number;
  total: number;
};

export const handleDataToRender = ({
  noticeData,
  noticeDataTwo,
}: {
  noticeData: NoticeDataType;
  noticeDataTwo: NoticeDataTwoType;
}) => {
  return [
    {
      columnTitleSx: { fontSize: '12px' },
      clumnSubtitleSx: { fontSize: '14px', color: '#757575' },
      title: {
        name: 'En tu aviso',
        sx: { color: '#757575', fontSize: '14px' },
      },
      columnOne: {
        title: `Precio x ${noticeDataTwo.type}`,
        subtitle: `${currencyFormat(noticeDataTwo.price)}`,
      },
      columnTwo: {
        title: `# ${noticeDataTwo.type}s`,

        subtitle: `${noticeDataTwo.amount}`,
      },
      columnThree: {
        title: `Precio total`,
        subtitle: `${currencyFormat(noticeDataTwo.total)}`,
      },
    },
    {
      columnTitleSx: { fontSize: '12px' },
      clumnSubtitleSx: { fontSize: '14px', fontWeight: 700 },
      title: {
        name: 'Acuerdo cerrado',
        sx: { fontWeight: 700 },
      },
      columnOne: {
        title: `Precio x ${noticeData.type}`,
        subtitle: `${currencyFormat(noticeData.priceType)}`,
      },
      columnTwo: {
        title: `# ${noticeData.type}s`,
        subtitle: `${noticeData.amount}`,
      },
      columnThree: {
        title: `Precio total`,
        subtitle: `${currencyFormat(noticeData.total)}`,
      },
    },
  ];
};
