import { CardContent, Typography, styled } from "@mui/material";
import React from "react";
import { SharedRideCardProps } from "./cardInterface";

const TextDirectionOrigin = styled(Typography)(
  () => `
   position: relative;
   padding-left: 16px;
   max-width: 284px;

   ::before {
    content: "";
    position: absolute;
    background-color: #6393F2;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    filter: drop-shadow(0px 0.63px 3.79px rgba(0, 0, 0, 0.15));
   }
   ::after {
    content: "";
    position: absolute;
    background-color: #6393F2;
    width: 2px;
    height: calc(100% - 50%);
    bottom: -15%;
    left: 4px;
    border-radius: 10px;
    transform: translateX(-50%);
    filter: drop-shadow(0px 0.63px 3.79px rgba(0, 0, 0, 0.15));
   }
`
);

const TextDirectionDestiny = styled(Typography)(
  ({ theme }) => `
   position: relative;
   padding-left: 16px;
   max-width: 284px;

   ::before {
    content: "";
    position: absolute;
    background-color: ${theme.palette.warning.main};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: 60%;
    left: 0;
    transform: translateY(-50%);
    filter: drop-shadow(0px 0.63px 3.79px rgba(0, 0, 0, 0.15));
   }
`
);

const Container = styled(CardContent)(
  () => `
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
  `
);

interface Props
  extends Pick<SharedRideCardProps, "originAddress" | "destinyAddress"> {}

const CardSectionBody: React.FC<Props> = ({
  destinyAddress,
  originAddress,
}) => {
  return (
    <Container>
      <TextDirectionOrigin variant="body1">{originAddress}</TextDirectionOrigin>
      <TextDirectionDestiny variant="body1">
        {destinyAddress}
      </TextDirectionDestiny>
    </Container>
  );
};

export default CardSectionBody;
