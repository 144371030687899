import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Typography, styled } from "@mui/material";

interface Props {
  title: string;
}

export const AddNewDestiny = (props: Props) => {
  const { title } = props;
  const navigate = useNavigate();

  return (
    <AddBtnContainer onClick={() => {}}>
      <AddCircleIcon />
      <Typography sx={{ pl: 1 }} variant="subtitle1">
        {title}
      </Typography>
    </AddBtnContainer>
  );
};


export const AddBtnContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    minHeight: '45px',
    borderRadius: '8px',
    marginTop: '16px',
    userSelect: 'none',
   
  }));
  