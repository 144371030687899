import { DetailsNegotationV2 } from "@/models/notice/noticeV2NegotationModels";
import { currencyFormat } from "@/utils/helpers";
import { Typography } from "@mui/material";

const CoCounterOffertPriceV2 = ({
    data,
  }: {
    data: DetailsNegotationV2;
  }) => {
    const {
      flgIsOwner
    } = data;
  return (
    <>
      <Typography sx={{ pt: 2, fontWeight: 700, fontSize: "16px", display: "flex", gap: "4px" }}>
        {flgIsOwner ?
          "Precio de tu aviso: "
          : "Precio del aviso: "
        }
        <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
          {currencyFormat(data.aviso.precio)}
        </Typography>
      </Typography>
      <Typography sx={{ fontWeight: 700, fontSize: "16px", display: "flex", gap: "4px" }}>
        {
            flgIsOwner ?
            "Oferta del cliente: "
            : "Tu oferta: "
        }
        <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
          {currencyFormat(data?.precio )}
        </Typography>
      </Typography>
      <Typography sx={{ fontWeight: 700, fontSize: "16px", display: "flex", gap: "4px" }}>
        Contraoferta rechazada:
        <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
          {currencyFormat(data?.preciocontraoferta )}
        </Typography>
      </Typography>
    </>
  )
}

export default CoCounterOffertPriceV2
