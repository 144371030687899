import { useAppSelector } from "@/hooks/useReducer";
import DynamicComponent from "@/pages/offerts/offertConfirmation/components/DynamicComponent";
import { useMemo } from "react";
import { ConfigDataBody, multiDestinyConfigData, ValidFormat } from "../config";

const MultidestinyApplication = () => {
  const { 
    customerRequest: {
      requestDetailsView, 
   },
   user: { user: usuario },
  } = useAppSelector(
    (state) => state
  );

  const handleGetConfig = () => {
    if (!requestDetailsView) return [];
    
    return multiDestinyConfigData;
  };

  const selectdConfigData = useMemo(() => handleGetConfig(), [requestDetailsView]);
  
  const handleFormat = (currentValue: any, format?: ValidFormat) => {
    if (!format || !requestDetailsView) return currentValue;

    let { template, valuesToReplace } = format;

    const newValue = valuesToReplace.reduce((acc, el) => {
      const value = requestDetailsView[el.value]?.toString() || el.defaultResult;

      return acc.replace(el.search, value);
    }, template);

    return newValue;
  };

  const handleValid = ({ valid, props }: ConfigDataBody) => {
    if (!valid || !requestDetailsView) return { props };

    const moreProps = valid.reduce((acc, el) => {
      let value = handleFormat(requestDetailsView[el.property], el?.format);

      if (el?.callback) {
        value = el.callback(value, requestDetailsView, usuario );
      }

      if (typeof el.defaultResult === "boolean") {
        acc = {
          ...acc,
          [el.componentProp]: !!value,
        };
      } else {
        acc = {
          ...acc,
          [el.componentProp]: value || el.defaultResult,
        };
      }
      return acc;
    }, {});

    return { props: { ...props, ...moreProps } };
  };
  
  return (
    <>
      {selectdConfigData.map((item, i) => (
        <DynamicComponent key={i} {...item} {...handleValid(item)} />
      ))}
    </>
  );
};

export default MultidestinyApplication;
