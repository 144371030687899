import { useState, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StyleCustomInput } from "../customInput/styleCustomInput";
import useDebounce from "@/hooks/useDebounse";
import { DesktopTimePicker, MobileTimePicker } from "@mui/x-date-pickers";
import useResize from "@/hooks/useResize";
import { InputAdornment } from "@mui/material";
import { useAppSelector } from "@/hooks/useReducer";
interface Props {
  label: string;
  inputFormat?: string;
  initValue?: Dayjs | null;
  onError?: (reason: any, value: dayjs.Dayjs | null) => void;
  onChange: (
    value: dayjs.Dayjs | null,
    keyboardInputValue?: string | undefined
  ) => void;
  minTime?: Dayjs;
}

const TimePicker = ({
  onError,
  label,
  minTime,
  onChange,
  initValue = null,
}: Props) => {
  const [timePicker, setTimePicker] = useState<Dayjs | null>(null);
  const [openPicker, setOpenPicker] = useState(false);
  const [, isMobil] = useResize();
  const { HoursMins } = useAppSelector((state) => state.notice);

  useEffect(() => {
    if (!initValue) {
      setTimePicker(HoursMins || null);
    } else {
      setTimePicker(initValue);
    }
  }, [HoursMins, initValue]);

  const debouncedValue = useDebounce(openPicker, 40);

  const handleChange = (newValue: Dayjs | null) => {
    setTimePicker(newValue);
    onChange(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {isMobil ? (
        <MobileTimePicker
          label={label}
          value={timePicker}
          onChange={handleChange}
          open={debouncedValue}
          minTime={minTime}
          onOpen={() => {
            setOpenPicker(true);
          }}
          onClose={() => {
            setOpenPicker(false);
          }}
          onError={onError}
          renderInput={(params: any) => {
            return (
              <StyleCustomInput
                {...params}
                variant="standard"
                onClick={() => {
                  setOpenPicker(!openPicker);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <i className="icon-watch-medium--white" />
                    </InputAdornment>
                  ),
                }}
              />
            );
          }}
        />
      ) : (
        <DesktopTimePicker
          label={label}
          value={timePicker}
          onChange={handleChange}
          open={debouncedValue}
          minTime={minTime}
          onOpen={() => {
            setOpenPicker(true);
          }}
          onClose={() => {
            setOpenPicker(false);
          }}
          onError={onError}
          renderInput={(params: any) => {
            return (
              <StyleCustomInput
                variant="standard"
                onClick={() => {
                  setOpenPicker(!openPicker);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                {...params}
              />
            );
          }}
        />
      )}
    </LocalizationProvider>
  );
};

export default TimePicker;
