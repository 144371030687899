import BasicAlert from "@/components/alerts/BasicAlert";
import AutoCompleteModal from "@/components/form/formServicesArea/AutoCompleteModal";
import useDebounce from "@/hooks/useDebounse";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { TiposFiltro } from "@/store/FilterServices/filterReduxInterface";
import { setEmptyCitiesByTerm } from "@/store/locationRedux/citiesSlice";
import { getCitiesByTermThunk } from "@/store/locationRedux/citiesThunks";
import { setEmptyDistrictsByTerm } from "@/store/locationRedux/districtsSlice";
import { getDistrictByTermThunk } from "@/store/locationRedux/districtsThunks";
import { FormControl, styled, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useId, useState } from "react";
import { ContainerModal } from "../styleServices";

const FormControlInput = styled(FormControl)(
  () => `
  padding: 16px 16px 0 16px;
  `
);

const CustomFormControl = styled(FormControl)(
  () => `
    width: 100%;
    height: 100%;
  `
);
const formMetaData = {
  city: {
    label: "Origen *",
    name: "city",
    title: "Distritos",
    modalTitle: "Ciudad de origen",
    placeholder: "Ingresa una ciudad de origen",
  },
  city2: {
    label: "Destino *",
    name: "city",
    title: "Ciudad de destino",
    modalTitle: "Ciudad de destino",
    placeholder: "Ingresa una ciudad de destino",
  },
  origin: {
    label: "Origen *",
    name: "origin",
    title: "Distritos",
    modalTitle: "Ciudades",
    placeholder: "Ingresa una ciudad de origen",
  },
  destiny: {
    label: "Destino *",
    name: "destiny",
    title: "Distritos",
    modalTitle: "Ciudades",
    placeholder: "Ingresa una ciudad de destino",
  },
};
interface IProps {
  modalActiveWith: "city" | "city2" | "origin" | "destiny";
  handleCheck: (
    value: string | string[],
    tipo: number,
    nombre?: string | string[]
  ) => void;
  checkArrFilters: TiposFiltro[];
  tipo: "C" | "DC";
}

const SearchList = ({
  modalActiveWith = "city",
  handleCheck,
  checkArrFilters,
  tipo,
}: IProps) => {
  const {
    cities: { citiesByTerm },
    districts: { districts },
  } = useAppSelector((state) => state);

  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected]: any = useState(null);
  const id = useId();
  const dispatch = useAppDispatch();
  const inputValueDebounse = useDebounce(inputValue, 500);

  useEffect(() => {
    handleFilterByTerm();
  }, [inputValueDebounse]);

  useEffect(() => {
    if (selected) {
      setInputValue(selected.nombre2 || selected.nombre);
      dispatch(setEmptyCitiesByTerm());
      dispatch(setEmptyDistrictsByTerm());
    }
  }, [selected]);

  useEffect(() => {
    return () => {
      handleCleanValues();
    };
    // }, [open]);
  }, []);

  const handleOnInputChange = async ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    setInputValue(value.replace(/[^A-Za-z ÁÉÍÓÚáéíóúÑñ,]/g, ""));
    if (selected) {
      setSelected(null);
    }
  };

  const handleFilterByTerm = () => {
    if (inputValue?.length >= 3 && !selected) {
      dispatch(getCitiesByTermThunk(inputValue));
      if (tipo === "DC") {
        dispatch(getDistrictByTermThunk(inputValue));
      }
    }
  };

  const handleSelected = (value: any) => {
    setSelected(value);
    // onSeleted && onSeleted(value);
    // if (tipo === "DC" && value?.ciudad) {
    //   handleCheck(value.nombre?.split(",")[0], 1, modalActiveWith === "city" ? "Origen" : "Destino");
    // }
    handleCheck(
      tipo === "DC" && value?.ciudad
        ? [value?.ciudad, value?.nombre?.split(",")[0].trim()]
        : value?.nombre,
      1,
      tipo === "DC" && value?.ciudad
        ? [
            modalActiveWith === "city" ? "OrigenCiudad" : "DestinoCiudad",
            modalActiveWith === "city" ? "Origen" : "Destino",
          ]
        : modalActiveWith === "city"
        ? "OrigenCiudad"
        : "DestinoCiudad"
    );
  };

  const handleCleanValues = () => {
    setInputValue("");
    setSelected(null);
    dispatch(setEmptyCitiesByTerm());
  };

  const minValue = inputValue.length < 3;
  const metaData = formMetaData[modalActiveWith];

  return (
    <>
      <FormControlInput>
        <TextField
          id={`${id}-${metaData.name}`}
          label={metaData.label}
          name={metaData.name}
          value={inputValue}
          variant="standard"
          placeholder={metaData.placeholder}
          onChange={handleOnInputChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControlInput>
      <ContainerModal>
        <CustomFormControl>
          {minValue || selected ? (
            <BasicAlert
              color="warning"
              action={false}
              open={inputValue.length > 0 && !selected}
            >
              Ingresa al menos 3 letras para ver resultados
            </BasicAlert>
          ) : (
            <>
              {tipo === "DC" && districts.length > 0 && (
                <AutoCompleteModal
                  title={metaData.title}
                  icon="icon-location-medium"
                  list={districts}
                  onClick={handleSelected}
                  onliItems={false}
                  primaryShowKey="nombre2"
                />
              )}
              {!!citiesByTerm.length && (
                <AutoCompleteModal
                  title={metaData.modalTitle}
                  icon="icon-buildings"
                  list={citiesByTerm}
                  onClick={handleSelected}
                  onliItems={tipo === "C"}
                  primaryShowKey="nombre2"
                />
              )}
            </>
          )}
        </CustomFormControl>
      </ContainerModal>
    </>
  );
};

export default SearchList;
