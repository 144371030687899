import { ListStepsProps } from "@/components/ListSteps/ListSteps";
import { DRIVER_CREATE_NOTICE_TYPES, REGISTER_DRIVER_CREATE_NOTICE_TYPES } from "@/consts/noticeV2Paths";
import {
  REGISTER_DRIVER_STEPS,
  REGISTER_DRIVER_STEPS_DOCUMENT,
  REGISTER_DRIVER_STEPS_PROFILE,
  REGISTER_DRIVER_VEHICLE,
  REGITER_DRIVER_PROPERTY_CARD,
  WELCOME_DRIVER,
} from "@/consts/typeServicesPaths";

export const profilePictureOrLogo = "Foto de perfil (opcional)";

export const listSteps: ListStepsProps[] = [
  {
    text: "Datos de registro",
    link: REGISTER_DRIVER_STEPS,
    isChecked: false,
    key: "flgRegistroForm",
    keyDepends: "flgRegistroForm",
    disabled: false,
  },
  {
    text: "Datos de tu cuenta",
    link: REGISTER_DRIVER_STEPS_PROFILE,
    isChecked: false,
    key: "flgRegistroCuenta",
    keyDepends: "flgRegistroForm",
    disabled: false,
  },
  {
    text: "El vehículo",
    link: REGISTER_DRIVER_VEHICLE,
    isChecked: false,
    key: "flgRegistroVehiculo",
    keyDepends: "flgRegistroCuenta",
    disabled: false,
  },
  {
    text: "Tus primeros avisos",
    link: REGISTER_DRIVER_CREATE_NOTICE_TYPES,
    isChecked: false,
    key: "flgRegistroAviso",
    keyDepends: "flgRegistroVehiculo",
    disabled: false,
  },
];

export const objectNextPage = {
  [REGISTER_DRIVER_STEPS]: WELCOME_DRIVER,
};
