import {
  NOTICE_AUCTION,
  NOTICE_BETWEEN_CITIES,
  NOTICE_IN_CITY,
  NOTICE_MOVING,
  NOTICE_MULTI_DESTINY,
  PROFILE_NOTICE_LIST_PATH,
  SERVICES_LANDING_PATH,
} from '@/consts/typeServicesPaths';

const DEVELOPMENT_PATH = ''; /* '/desarrollo' */ // solo para desarrollo

export const QUOTE_PATH = 'solicitar-cotizacion';
export const COUNTER_OFFER = '/contraoferta';
export const COUNTER_OFFER_RUTE = '/contraoferta-ruta';
export const REQUEST_QUOTE_ORIGIN_PATH = 'solicitar-cotizacion-origen';
export const REQUEST_QUOTE_DESTINY_PATH = 'solicitar-cotizacion-destino';

export const OFFER_IN_CITY_MAIN_PATH = `${DEVELOPMENT_PATH}${SERVICES_LANDING_PATH}/${NOTICE_IN_CITY}/:id`;
export const OFFER_IN_CITY_FLOW_PATH = [
  `${OFFER_IN_CITY_MAIN_PATH}/ofertar-carga`,
  `${OFFER_IN_CITY_MAIN_PATH}/acepta-ruta`,
  `${OFFER_IN_CITY_MAIN_PATH}/confirmacion`,
];

export const COUNTER_OFFER_IN_CITY_FLOW_PATH = [
  `${OFFER_IN_CITY_MAIN_PATH}/contraoferta-carga`,
  `${OFFER_IN_CITY_MAIN_PATH}/contraoferta-ruta`,
  `${OFFER_IN_CITY_MAIN_PATH}/contraoferta-confirmacion`,
];

export const OFFER_BETWEEN_CITIES_MAIN_PATH = `${DEVELOPMENT_PATH}${SERVICES_LANDING_PATH}/${NOTICE_BETWEEN_CITIES}/:id`;
export const OFFER_BETWEEN_CITIES_PATH = [
  `${OFFER_BETWEEN_CITIES_MAIN_PATH}/ofertar-carga`,
  `${OFFER_BETWEEN_CITIES_MAIN_PATH}/ofertar-ruta`,
  `${OFFER_BETWEEN_CITIES_MAIN_PATH}/ofertar-confirmacion`,
];

export const COUNTER_OFFER_BETWEEN_CITIES_PATH = [
  `${OFFER_BETWEEN_CITIES_MAIN_PATH}/contraoferta-carga`,
  `${OFFER_BETWEEN_CITIES_MAIN_PATH}/contraoferta-ruta`,
  `${OFFER_BETWEEN_CITIES_MAIN_PATH}/contraoferta-confirmacion`,
];

export const OFFER_MULTI_DESTINY_MAIN_PATH = `${DEVELOPMENT_PATH}${SERVICES_LANDING_PATH}/${NOTICE_MULTI_DESTINY}/:id`;

export const OFFER_MULTI_DESTINY_FLOW_PATH = [
  `${OFFER_MULTI_DESTINY_MAIN_PATH}/ofertar-carga`,
  `${OFFER_MULTI_DESTINY_MAIN_PATH}/acepta-ruta`,
  `${OFFER_MULTI_DESTINY_MAIN_PATH}/acepta-precio`,
  `${OFFER_MULTI_DESTINY_MAIN_PATH}/confirmacion`,
];

export const COUNTER_OFFER_MULTI_DESTINY_FLOW_PATH = [
  `${OFFER_MULTI_DESTINY_MAIN_PATH}/contraoferta-carga`,
  `${OFFER_MULTI_DESTINY_MAIN_PATH}/contraoferta-ruta`,
  `${OFFER_MULTI_DESTINY_MAIN_PATH}/contraoferta-precio`,
  `${OFFER_MULTI_DESTINY_MAIN_PATH}/contraoferta-confirmacion`,
];

export const OFFER_MOVING_MAIN_PATH = `${DEVELOPMENT_PATH}${SERVICES_LANDING_PATH}/${NOTICE_MOVING}/:id`;

export const OFFER_MOVING_FLOW_PATH = [
  `${OFFER_MOVING_MAIN_PATH}/ofertar-carga`,
  `${OFFER_MOVING_MAIN_PATH}/crear-origen`,
  `${OFFER_MOVING_MAIN_PATH}/crear-destino`,
  `${OFFER_MOVING_MAIN_PATH}/confirmacion`,
];

export const COUNTER_OFFER_MOVING_FLOW_PATH = [
  `${OFFER_MOVING_MAIN_PATH}/solicitar-cotizacion`,
  `${OFFER_MOVING_MAIN_PATH}/${REQUEST_QUOTE_ORIGIN_PATH}`,
  `${OFFER_MOVING_MAIN_PATH}/${REQUEST_QUOTE_DESTINY_PATH}`,
  `${OFFER_MOVING_MAIN_PATH}/solicitar-condiciones`,
  `${OFFER_MOVING_MAIN_PATH}/solicitar-cotizacion-confirmacion`,
];

// ==================================================
//                   Oferta de Remate
// ==================================================
export const OFFER_AUCTION_MAIN_PATH = `${DEVELOPMENT_PATH}${SERVICES_LANDING_PATH}/${NOTICE_AUCTION}/:id`;
export const OFFER_AUCTION_FLOW_PATH = [
  `${OFFER_AUCTION_MAIN_PATH}/ofertar-carga`,
  `${OFFER_AUCTION_MAIN_PATH}/acepta-ruta`,
  `${OFFER_AUCTION_MAIN_PATH}/confirmacion`,
];

export const COUNTER_OFFER_AUCTION_FLOW_PATH = [
  `${OFFER_AUCTION_MAIN_PATH}/contraoferta-carga`,
  `${OFFER_AUCTION_MAIN_PATH}/contraoferta-ruta`,
  `${OFFER_AUCTION_MAIN_PATH}/contraoferta-precio`,
  `${OFFER_AUCTION_MAIN_PATH}/contraoferta-confirmacion`,
];

export const EDIT_COUNTEROFFER_CLIENT = `${DEVELOPMENT_PATH}${SERVICES_LANDING_PATH}/:typeOffer/:noticeId/editar`;
export const EDIT_COUNTEROFFER_DRIVER = `${DEVELOPMENT_PATH}${PROFILE_NOTICE_LIST_PATH}/:typeOffer/:noticeId/editar`;
export const EDIT_COUNTEROFFER_SUCCESS_MESSAGE = `${PROFILE_NOTICE_LIST_PATH}/:typeOffer/:noticeId/editar-mensaje`;

export const NEGOTATION_REJECTED_NOTICE_MESSAGE_CLIENT = `${DEVELOPMENT_PATH}${SERVICES_LANDING_PATH}/:typeOffer/:noticeId/retiro-mensaje`;
export const NEGOTATION_REJECTED_NOTICE_MESSAGE_DRIVER = `${DEVELOPMENT_PATH}${PROFILE_NOTICE_LIST_PATH}/:typeOffer/:noticeId/retiro-mensaje`;
