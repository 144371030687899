import useResize from "@/hooks/useResize";
import { Grid } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { ContainerMultiOutletStyle } from "./styleContainerMultiOutlet";

interface IProps {
  children?: React.ReactNode;
  pathIndex: string;
}

const ContainerMultiOutlet = ({ children, pathIndex }: IProps) => {
  const [, isMobil] = useResize();
  const url = window.location.pathname;
  return (
    <>
      <ContainerMultiOutletStyle maxWidth="xl">
        <Grid
          container
          spacing={2}
          sx={{
            ".MuiGrid-item": {
              paddingTop: "0px",
            },
          }}
        >
          {/* // if mobil view and url is not mi-perfil */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: isMobil && url !== pathIndex ? "none" : "block",
            }}
          >
            {children}
          </Grid>
          {
            // if mobil view and url is diferent mi-perfil show outlet
            isMobil && url !== pathIndex ? (
              <Grid
                item
                xs={12}
                md={8}
                sx={{
                  ".MuiGrid-item": {
                    paddingTop: "0px",
                  },
                }}
              >
                <Outlet />
              </Grid>
            ) : null
          }
          {
            // if not mobil view show outlet
            !isMobil ? (
              <Grid
                item
                xs={12}
                md={8}
                sx={{
                  ".MuiGrid-item": {
                    paddingTop: "0px",
                  },
                }}
              >
                <Outlet />
              </Grid>
            ) : null
          }
        </Grid>
      </ContainerMultiOutletStyle>
    </>
  );
};

export default ContainerMultiOutlet;
