import ModalFilter from '@/components/Filter/components/ModalFilter/ModalFilter'
import DynamicComponent from '@/components/dinamycComponent/DinamycComponent'
import Input from '@/components/inputCustom/Input'
import LabelRequired from '@/components/utils/LabelRequired'
import { AddNewDestiny } from '@/pages/noticeV2/components/AddNewDestiny'
import { CardItem } from '@/pages/noticeV2/components/CardItem'
import RadioButtonsGroup from '@/pages/noticeV2/components/RadiosGroup'
import ButtomLoader from '@/pages/noticeV2/components/buttonLoader/ButtomLoader'
import { DistritoSugeridoData } from '@/services/noticeServices/noticeListSuggested/noticeListSuggestedTypes'
import { FormControl, Grid, Typography } from '@mui/material'
import { configMultiInCity } from '../../components/inputsConfig/inputsMultiInCity'
import { ContainerGrid, HeaderBoxContainer } from '../../noticePlaceStyles'
import { usePlaceMultiInCityHook } from './hook'
import { metaDataModalInCIty } from '../hook'

const PlaceMultiInCity = () => {

    const {
        handleInitConfig,
        showModal,
        handleOnCloseModal,
        handleSubmitModal,
        handleOnSeletedModal,
        handleFilterByTerm,
        modalActiveWith,
        listLocation,
        districtsSuggested,
        handleInputsSuggested,
        DeleteSuggestedListItem,
        data,
        changeRadioButtons,
        handleInputsCustomHourOrPoint,
        onSubmit,
        validateForm,
        isLoading,
        isRegister,
        formData,
        validateFormCustomHourOrPointPrice,
        validateFormCustomHourOrPoint,
        isCity,
    } = usePlaceMultiInCityHook()

    const precioCalculado = () => {

        if (data?.flghoraopunto === 1 && data.precioxhora && data.horasminimo) {
            return `S/ ${data?.precioxhora * data.horasminimo}`
        }
        if (data?.flghoraopunto === 2 && data.precioxpunto && data.puntosminimo) {
            return `S/ ${data?.precioxpunto * data.puntosminimo}`
        }

        return "-"
    }

    return (
        <ContainerGrid container>

            <ModalFilter
                open={showModal}
                onClose={handleOnCloseModal}
                onSubmit={handleSubmitModal}
                onSeleted={handleOnSeletedModal}
                onInputChange={handleFilterByTerm}
                modalActiveWith={modalActiveWith.name}
                list={listLocation}
                betweenDate={""}
                prevSelected={""}
                metaDataModal={metaDataModalInCIty}
                nameTwo={isCity}
            />

            <FormControl sx={{
                gap: "16px",
            }}>
                {configMultiInCity.map((config) =>
                    config.map((items) =>
                        items.columns.map((column) => !(column?.hideRegister && isRegister) && (
                            <DynamicComponent
                                {...handleInitConfig(column)}
                            />
                        ))))}

            </FormControl>
            <HeaderBoxContainer>
                <Typography variant="subtitle1">Distritos de cobertura</Typography>
            </HeaderBoxContainer>
            {(districtsSuggested && (districtsSuggested as DistritoSugeridoData)?.sugeridos) && (districtsSuggested as DistritoSugeridoData)?.sugeridos.map((item, i) => (
                <CardItem
                    nombre={item.nombre}
                    id={item.id}
                    precio=""
                    changeInput={handleInputsSuggested}
                    onClick={DeleteSuggestedListItem}
                    hideInput={true}
                    // hide close icon only in first item
                    hideCloseIcon={!(i === 0)}
                />
            ))
            }
            {
                (districtsSuggested && (districtsSuggested as DistritoSugeridoData)?.sugeridos) ?

                    <AddNewDestiny title="Agregar distrito" term={formData.city} />
                    : <Typography variant="body2">
                        Elige un distrito de origen para sugerirte destinos.
                    </Typography>
            }
            <HeaderBoxContainer>
                <Typography variant="subtitle1">Precio del servicio</Typography>
            </HeaderBoxContainer>
            <RadioButtonsGroup
                value={data?.flghoraopunto?.toString() || "2"}
                handleChange={changeRadioButtons}
            />
            <FormControl sx={{ marginTop: "16px", width: "100%" }}>

                <Input
                    label={
                        <LabelRequired str={
                            data.flghoraopunto === 2 ?
                                "Precio por punto de entrega/recojo (S/)" :
                                "Precio por hora (S/)"
                        }
                        />
                    }
                    name="precioXhoraOrPoint"
                    value={data.flghoraopunto === 2 ? (data.precioxpunto || "") : (data.precioxhora || "")}
                    onChange={handleInputsCustomHourOrPoint}
                    placeholder={`Precio en los distritos de cobertura`}
                    onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        inputMode: "numeric",
                        maxLength: 5,
                    }}
                    error={!validateFormCustomHourOrPointPrice(data?.flghoraopunto === 2 ? data.precioxpunto : data.precioxhora).valid}
                    helperText={validateFormCustomHourOrPointPrice(data?.flghoraopunto === 2 ? data.precioxpunto : data.precioxhora).msg}
                />
            </FormControl>
            <FormControl sx={{ marginTop: "16px", width: "100%" }}>

                <Input
                    label={
                        <LabelRequired str={
                            data.flghoraopunto === 2 ?
                                "Puntos mínimos de entrega/recojo" :
                                "Mínimo de horas"
                        }
                        />
                    }
                    name="puntosohoras"
                    value={data.flghoraopunto === 2 ? (data.puntosminimo || "") : (data.horasminimo || "")}
                    onChange={handleInputsCustomHourOrPoint}
                    placeholder={`Cantidad mínima de puntos por servicio`}
                    onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        inputMode: "numeric",
                        maxLength: 2
                    }}
                    error={!validateFormCustomHourOrPoint(data.flghoraopunto === 2 ? data.puntosminimo : data.horasminimo, data.flghoraopunto).valid}
                    helperText={validateFormCustomHourOrPoint(data.flghoraopunto === 2 ? data.puntosminimo : data.horasminimo, data.flghoraopunto).msg}
                />
            </FormControl>

            <Typography variant="subtitle1" sx={{ mt: 1 }}>
                Precio del servicio: {precioCalculado()}
            </Typography>

            <Grid item sx={{ marginTop: "16px" }}>
                <ButtomLoader
                    onClick={onSubmit}
                    buttomProps={{
                        disabled: !validateForm() || isLoading,
                    }}
                    isLoading={isLoading}
                >
                    CREAR AVISO
                </ButtomLoader>
            </Grid>
        </ContainerGrid>
    )
}

export default PlaceMultiInCity