import { ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import { MyNotice } from "@/models/notice/noticeModels";
import DetailTypeFlow from "@/pages/detailNotice/components/detailTypeFlow";
import { currencyFormat } from "@/utils/helpers";
import { Box, CardContent, Typography, styled } from "@mui/material";
import { typeNoticeByIdObject } from "../../consts/NoticeListConst";
import StateText from "../StateText";

const Container = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  position: "relative",
}));

const Title = styled(Typography)(() => ({
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));

const ContainerPlate = styled(Box)(() => ({
  marginTop: "7px",
  marginBottom: "8px",
}));

const CardBody = ({ data }: { data: MyNotice }) => {
  const IS_MULTIDESTINY = data.idTipoAviso === ID_MULTI_DESTINY;

  const handleTitle = (data: MyNotice) => {
    const { origenTexto, destinoTexto } = data;

    if (IS_MULTIDESTINY) {
      return destinoTexto;
    }

    return `De ${origenTexto} a ${destinoTexto}`;
  };

  const handleGetNameTypeFlow = (data: MyNotice) => {
    const { title } = typeNoticeByIdObject[data.idTipoAviso];

    return title;
  };

  const handleGetTextPlate = () => {
    const { placa, pesoMaximo } = data;

    if (!placa && !pesoMaximo) return "No tiene vehículo asociado";

    const weight = currencyFormat(pesoMaximo, false);

    const newPlaca = !!placa  ? `${placa} |` : ""

    const template = `${newPlaca} Hasta ${weight} kilos`;

    return template;
  };

  const styleMarginTop = IS_MULTIDESTINY ? "10px" : "7px";
  const plate = handleGetTextPlate();

  return (
    <CardContent sx={{ p: 2, pb: 0 }}>
      <Container>
        <DetailTypeFlow
          nombre={handleGetNameTypeFlow(data)}
          id={data.idTipoAviso}
          typeMoving={data?.flgCiudadoEntreciudades}
          flghoraopunto={data?.flgHoraoPunto}
        />

        <StateText state={data?.estado} />
      </Container>

      <Box sx={{ marginTop: styleMarginTop }}>
        <Title variant="subtitle1">{handleTitle(data)}</Title>
      </Box>

      <ContainerPlate>
        <Typography sx={{ fontSize: "14px" }}>{plate}</Typography>
      </ContainerPlate>
    </CardContent>
  );
};

export default CardBody;
