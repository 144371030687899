import imageHomePhoneSection from "@/img/landing/advertisers/home-phone.png";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  BoxAppStoresIcons,
  BoxBtn,
  BoxContainer,
  BoxcontainerSectionTwo,
  ButtonStyled,
  ContainerAds,
  ContainerMobile,
  GridContainer,
  SectionTwoContainer,
  SectionTwoGrid,
  SubContainerMobile,
  TextBoxContainer,
  TypographyExpress,
  TypographyText,
  TypographyTitle,
} from "./InfoAppStyled";
import "./style.css";

import { QR_EXTERNAL_PATH } from "@/consts/defaultConsts";
import QRAPP from "@/img/landing/qr.png";
import useAppRedirectHook from "@/hooks/useRedirectApp";

interface InfoAppSectionProps {
  text?: string;
}

const InfoAppSection = (props: InfoAppSectionProps) => {
  const {
    methods: { handleRedirectString, linkingIosStore, linkingAndroidStore },
  } = useAppRedirectHook();
  const { text = "Consigue transporte para tu carga de manera inmediata." } =
    props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matches6 = useMediaQuery("(max-width:920px)");
  const matches7 = useMediaQuery("(max-width:768px)");
  const phoneHomeMatches = useMediaQuery("(max-width:940px)");

  const ImgResponsive = () => {
    if (matches7) {
      return { width: 221, heigth: 270 };
    } else if (phoneHomeMatches) {
      return { width: "172px", heigth: "178px" };
    } else {
      return { width: "223px", heigth: "232px" };
    }
  };

  const responsiveTitle = () => {
    if (matches7) return "22px";

    if (matches6) return "18px";

    return "22px";
  };

  return (
    <ContainerAds
      matches={matches}
      container
      spacing={2}
      sx={{
        padding: {
          xs: "0px ",
          md: "10px",
          lg: "16px",
        },
      }}
    >
      <Grid item xs={12}>
        <BoxContainer matches={matches}>
          <TypographyTitle
            sx={{
              fontSize: responsiveTitle(),
            }}
          >
            Descarga PegaCargo
          </TypographyTitle>

          <TypographyExpress>Express</TypographyExpress>
        </BoxContainer>
        <TextBoxContainer matches6={matches6} matches7={matches7}>
          <TypographyText>{text}</TypographyText>
        </TextBoxContainer>
      </Grid>

      {matches && (
        <BoxBtn>
          <ButtonStyled href={handleRedirectString()} target="_blank">
            descargar la aplicación
          </ButtonStyled>
        </BoxBtn>
      )}

      <Grid item xs={12}>
        <GridContainer
          container
          spacing={2}
          sx={{ display: "flex", alignItems: "flex-start" }}
        >
          {!matches && (
            <SectionTwoContainer matches={matches} item xs={matches ? 12 : 6}>
              <BoxcontainerSectionTwo>
                <img
                  width={"150"}
                  height={"150"}
                  style={{ marginTop: "3px" }}
                  src={QRAPP}
                  alt="qr de la tienda de aplicaciones"
                />
              </BoxcontainerSectionTwo>
              <Typography sx={{ width: "150px", marginTop: "16px" }}>
                Escanea el código QR con la cámara de tu celular
              </Typography>
            </SectionTwoContainer>
          )}

          <SectionTwoGrid matches={matches} item xs={matches ? 12 : 6}>
            <img
              width={ImgResponsive().width}
              height={ImgResponsive().heigth}
              style={{
                marginBottom: phoneHomeMatches ? "16px" : "0px",
              }}
              src={imageHomePhoneSection}
              alt="img2"
            />
          </SectionTwoGrid>
        </GridContainer>
      </Grid>

      {matches && (
        <ContainerMobile item xs={12}>
          <SubContainerMobile container spacing={2}>
            <BoxAppStoresIcons
              item
              xs={12}
              sx={{
                marginBottom: "16px",
              }}
            >
              <i
                style={{ width: "136px", height: "40px", marginRight: "3px", cursor: "pointer" }}
                className="icon-google_play"
                onClick={linkingAndroidStore}
              />

              <i
                style={{ width: "120px", height: "40px", marginLeft: "3px", cursor: "pointer" }}
                className="icon-app_store"
                onClick={linkingIosStore}
              />
            </BoxAppStoresIcons>

            {/* <BoxAppStoresIcons
              item
              xs={12}
              sx={{
                marginBottom: "16px",
              }}
            >
              <i
                style={{ width: "135px", height: "40px" }}
                className="icon-app_gallery"
              />
            </BoxAppStoresIcons> */}
          </SubContainerMobile>
        </ContainerMobile>
      )}
    </ContainerAds>
  );
};
export default InfoAppSection;
