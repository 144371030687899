import { NOTICE_NOT_FOUND } from "@/consts/defaultConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import SkeletonViewService from "@/pages/loading/SkeletonViewService";
import NotFound from "@/pages/notFound/NotFound";
import { getNegotationRequestThunk } from "@/store/noticeNegotationRequestRedux/noticeNegRequesThunk";
import {
  resetError,
  setIsEdition,
} from "@/store/noticeNegotationRequestRedux/noticeNegRequestSlice";
import { IUser } from "@/store/userRedux/userReduxInterface";
import {
  getErrorButtonLinkNotFound,
  validateAuthorizationByCode,
} from "@/utils/helpers";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReceiveRespose from "./ReceiveRespose";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";


const NegClientReceiveResponse = () => {
  const {
    noticeNegotationRequest: {
      errorCode,
      isEdition,
      isLoading,
      savedDataNogotation,
    },
    user: { user },
  } = useAppSelector((state) => state);
  const navigate = useNavigate();
  const [negData, setNegData] = useState<SolicitudNegociacion | null>(null);
  const dispatch = useAppDispatch();
  const { typeId = "" } = useParams();

  useEffect(() => {
    dispatch(getNegotationRequestThunk(typeId));

    return () => {
      dispatch(resetError());
    };
  }, [typeId]);

  useEffect(() => {
    if (savedDataNogotation) {
      setNegData(savedDataNogotation as SolicitudNegociacion);
    }
    if (isEdition) dispatch(setIsEdition(false));
  }, [savedDataNogotation]);

  useEffect(() => {
    validateAuthorizationByCode({ errorCode, navigate });
  }, [errorCode]);

  if (!negData || isLoading) return <SkeletonViewService />;

  if (!!errorCode) {
    return (
      <NotFound
        title="¡Lo sentimos!"
        description="La negociación no está disponible"
        buttonText="VER MIS NEGOCIACIONES"
        buttonLink={getErrorButtonLinkNotFound(user as IUser)}
        image={NOTICE_NOT_FOUND}
      />
    );
  }

  return <ReceiveRespose />;
};

export default NegClientReceiveResponse;
