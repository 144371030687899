import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import SwitchControl from "@/components/form/switchControl/SwitchControl";
import HeaderSection from "@/components/headerSection/HeaderSection";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import SkeletonConditions from "@/components/skeletons/SkeletonConditions";
import { Container, Stack, styled } from "@mui/material";
import useConditionsHook from "./hook";
import { ContainerArea, SubTitleConditions } from "./style";


const Conditions = () => {

  const {
    lisConditions,
    subTitle,
    handleChange,
    handleNextRute,
    handlePrevRute,
    isMoving,
    isLoading
  } = useConditionsHook();
  

  if (lisConditions.length === 0) {
    return (
      <Container sx={{ mt: 6 }} maxWidth="xs">
        <SkeletonConditions />
      </Container>
    );
  }

  return (
    <Container maxWidth="xs">
      <HeaderSection
        icon="icon-arrow-left--primary"
        title="Condiciones del servicio"
        subTitle={subTitle}
        onClick={handlePrevRute}
      />
      <ContainerArea>
        <SectionIcon icon="icon-file--blue" />
        <Stack gap="4px">
        <SubTitleConditions>Tu solicitud incluye:</SubTitleConditions>
          {lisConditions.map((list) => (
            <SwitchControl
              key={list.id}
              text={list.nombre}
              onChange={(isActive) => handleChange(list, isActive)}
              value={!!list.isActive}
              hideSwitch={!!list.flgVer}
              pointHidden={true}
            />
          ))}
        </Stack>
      </ContainerArea>

      <ButtomLoader
        type="submit"
        sx={{ marginTop: "24px" }}
        onClick={handleNextRute}
        isLoading={isMoving && isLoading}
        disabled={isMoving && isLoading}
      >
        {
          isMoving ? "solicitar" : "SIGUIENTE"
        }
        
      </ButtomLoader>
    </Container>
  );
};

export default Conditions;
