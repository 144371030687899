import {
  ABOUT_US_LANDING_PATH,
  ADS_CONFIRMATION_LANDING_PATH,
  ADS_CONFIRMATION_SPONSORED_LANDING_PATH,
  ADS_LANDING_PATH,
  ADS_SOON_LANDING_PATH,
  ADS_SOON_SPONSORED_LANDING_PATH,
  CLIENTS_LANDING_PATH,
  CONDITIONS_EXPRESS,
  CONTACT_US_CONFIRMATION_PATH,
  CONTACT_US_LANDING_PATH,
  DRIVERS_LANDING_PATH,
  FAQ_LANDING_PATH,
  POLITICS_PRIVACY,
  REQUESTS_LANDING_PATH,
  SERVICES_LANDING_PATH,
  TERMS_CONDITIONS_PATH,
} from '@/consts/typeServicesPaths';
import Carrier from '@/pages/landing/Carrier';
import About from '@/pages/landing/about/About';
import Ads from '@/pages/landing/ads/Ads';
import AdsConfirmation from '@/pages/landing/ads/components/AdsConfirmation/AdsConfirmation';
import AdsSoon from '@/pages/landing/ads/components/AdsSoon/AdsSoon';
import Clients from '@/pages/landing/clients/Clients';
import Contact from '@/pages/landing/contact/Contact';
import ContactConfirmation from '@/pages/landing/contact/ContactConfirmation';
import Faq from '@/pages/landing/faq/Faq';
import PoliticsPrivacy from '@/pages/landing/politicsPrivacy/PoliticsPrivacy';
import Requests from '@/pages/landing/requests/Requests';
import Services from '@/pages/landing/services/Services';
import TermsConditions from '@/pages/landing/termsConditions/TermsConditions';
import TermsConditionsExpress from '@/pages/landing/termsConditionsExpress/TermsConditionsExpress';
import { RouterProps } from '@/router/router';

export const landingFlowRouter: RouterProps[] = [
  {
    path: CLIENTS_LANDING_PATH,
    Component: Clients,
    isFree: true,
  },
  {
    path: DRIVERS_LANDING_PATH,
    Component: Carrier,
    isFree: true,
  },
  {
    path: ABOUT_US_LANDING_PATH,
    Component: About,
    isFree: true,
  },
  {
    path: FAQ_LANDING_PATH,
    Component: Faq,
    isFree: true,
  },
  {
    path: ADS_LANDING_PATH,
    Component: Ads,
    isFree: true,
  },
  {
    path: ADS_SOON_LANDING_PATH,
    Component: AdsSoon,
    isFree: true,
  },
  {
    path: ADS_SOON_SPONSORED_LANDING_PATH,
    Component: AdsSoon,
    isFree: true,
  },
  {
    path: ADS_CONFIRMATION_LANDING_PATH,
    Component: AdsConfirmation,
    isFree: true,
  },
  {
    path: ADS_CONFIRMATION_SPONSORED_LANDING_PATH,
    Component: AdsConfirmation,
    isFree: true,
  },
  {
    path: CONTACT_US_LANDING_PATH,
    Component: Contact,
    isFree: true,
  },
  {
    path: CONTACT_US_CONFIRMATION_PATH,
    Component: ContactConfirmation,
    isFree: true,
  },

  {
    path: SERVICES_LANDING_PATH,
    Component: Services,
    isFree: true,
  },
  {
    path: REQUESTS_LANDING_PATH,
    Component: Requests,
    isFree: true,
  },
  {
    path: TERMS_CONDITIONS_PATH,
    Component: TermsConditions,
    isFree: true,
  },
  {
    path: POLITICS_PRIVACY,
    Component: PoliticsPrivacy,
    isFree: true,
  },
  {
    path: CONDITIONS_EXPRESS,
    Component: TermsConditionsExpress,
    isFree: true,
  },
];
