import { Container, Grid, Skeleton, SkeletonProps, useMediaQuery, useTheme } from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system";
import { SkeletonGroupContainer } from "./styleSkeletons";

interface IItemsData extends SkeletonProps {
  width: number | string;
  height: number;
  borderRadius?: number;
  variant?: any;
  animation?: any;
}

interface IProps {
  skeletonList: IItemsData[] | SkeletonProps[];
  isContainer?: boolean;
  gap?: ResponsiveStyleValue<string | number>;
  direction?: ResponsiveStyleValue<"row" | "column">;
  sx?: any;
}

const SkeletonCustomGroup = ({ skeletonList, isContainer, gap, direction, sx }: IProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  return isContainer ? (
    <Container sx={sx} maxWidth={matches ? "xl" : "xs"}>
      <SkeletonGroupContainer gap={gap} sx={{ flexDirection: direction }}>
        <Grid container>
          {skeletonList.map((item, index) => (
            <Grid item xs={matches ? 4 : 12} key={index}>
              <Skeleton
                key={index}
                animation={item.animation || "pulse"}
                variant={item.variant || "rounded"}
                height={matches ? 300 : item.height || 56}
                width={item.width || 328}
                sx={{
                  borderRadius: { borderRadius: "4px" },
                }}
              />
            </Grid>
          ))}
        </Grid>
      </SkeletonGroupContainer>
    </Container>
  ) : (
    <SkeletonGroupContainer gap={gap} sx={{ flexDirection: direction }}>
      {skeletonList.map((item, index) => (
        <Skeleton
          key={index}
          animation={item.animation || "pulse"}
          variant={item.variant || "rounded"}
          height={item.height || 56}
          width={item.width || 328}
          sx={{
            borderRadius: { borderRadius: "4px" },
          }}
        />
      ))}
    </SkeletonGroupContainer>
  );
};

export default SkeletonCustomGroup;
