import { DetailNoticeMoving } from "@/models/notice/noticeV2NegotationModels";
import { currencyFormat } from "@/utils/helpers";
import { Box, Typography } from "@mui/material";

const PriceMoving = ({ data }: { data: DetailNoticeMoving }) => {
  const { flgIsOwner } = data;

  if (!flgIsOwner) return <></>;

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        {
          data?.precio ?
            <>
              <Typography sx={{ fontWeight: 700, fontSize: "22px" }}>
                Oferta rechazada: {currencyFormat(data.preciocontraoferta || data?.precio)}
              </Typography>
              <Typography sx={{ ml: 1, fontWeight: 400, fontSize: "14px" }}>
                Por viaje
              </Typography>
            </>
            : <Typography sx={{ fontWeight: 700, fontSize: "22px" }}>
              Solicitud de negociación rechazada
            </Typography>
        }
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
          Cantidad de ayudantes:
        </Typography>
        <Typography sx={{ ml: 1, fontSize: "16px" }}>
          {data.ayudantescontraoferta || data.ayudantes}
        </Typography>
      </Box>
    </>
  );
};

export default PriceMoving;
