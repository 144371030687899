import { ID_OFFER } from "@/consts/defaultConsts";
import { agreementClosedText, optionOne } from "@/consts/noticeConsts";
import { ISolicitudNegociacion } from "@/services/interfaces/RequestNegResponseSuccess";
import { currencyFormat } from "@/utils/helpers";
import { Box, Typography, styled } from "@mui/material";
import { handleTypeMultiDestinyNegRequest } from "../../utils/bodyUtils";


export const SpanStyles = styled("span")(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Source Sans Pro, Roboto",
  fontWeight: 400,
  maxWidth: "320px",
  letterSpacing: "0.00938em",
  marginLeft: "3px",
  wordWrap: "break-word"
}));

interface IPriceRequestAcceptProps {
    data: ISolicitudNegociacion;   
  }



const PriceMultiDestiny = ({ data }: IPriceRequestAcceptProps) => {
    const render = handleTypeMultiDestinyNegRequest(data);

    return (
        <Box>
          <Typography sx={{ fontWeight: 700, fontSize: "22px" }}>
          {agreementClosedText}: {currencyFormat(data.precioContraoferta || data.precio)}
    
            <SpanStyles >
              Por {render.type}
            </SpanStyles>
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            Cantidad de {render.type}:
            <SpanStyles >
              {render.amount}
            </SpanStyles>
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            Precio total:
            <SpanStyles >
              {currencyFormat(render.total || 0)}
            </SpanStyles>
          </Typography>
        </Box>
      );
}

export default PriceMultiDestiny
