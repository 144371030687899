import { AUTH_LOGIN } from "@/consts/typeServicesPaths";
import { UsuarioAPI } from "@/models/userAuth/user";
import { RootState } from "@/store/store";
import { MiddlewarePaths } from "../router";

export const privateRuteWithLoginMiddleware = (state: RootState, { pathname }: MiddlewarePaths): string => {
  const currentUser = state.user.user as UsuarioAPI;
  if (!Object.keys(currentUser).length) {
    return `${AUTH_LOGIN}?returnPath=${pathname}`;
  }

  return "";
};
