export type ModalTextData = {
  title: string;
  content: string;
  option1Title: string;
  option2Title: string;
};

export type ModalData = {
  deactivate: ModalTextData;
  edit: ModalTextData;
};

export const modalTextData: ModalData = {
  deactivate: {
    title: "Desactivar aviso",
    content: "El aviso no será visible para los clientes y todas las negociaciones activas serán rechazadas. ¿Desea desactivarlo?",
    option1Title: "NO",
    option2Title: "SI, DESACTIVAR",
  },
  edit: {
    title: "Edición del aviso",
    content: "Este aviso tiene ofertas, al realizar un cambio, se rechazarán dichas ofertas, ¿Deseas continuar con la edición?",
    option1Title: "NO",
    option2Title: "SI, CONTINUAR",
  },
};
