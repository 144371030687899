import {
  Avisodestino,
  BodyOfferMultiDestinationDTO,
  RequestOfferMultiDestinationService,
} from '@/interfaces/offerInterface';
import {
  NoticeDetailDestinyModel,
  NoticeDetailMultiDestinyModel,
} from '@/models/notice/noticeModels';
import { RootState } from '@/store/store';

interface SendDataMultidestiny {
  cargoDescription: string;
  verifiedPhotosId?: number;
}

export const requestMultidestinyAdapter = (
  body: BodyOfferMultiDestinationDTO,
  getState: () => RootState
): RequestOfferMultiDestinationService => {
  const {
    noticeV2ClientAorCO: {
      selectTypeNotice,
      selectDate,
      idTypeNoticeNegotiation,
      sendData,
    },
  } = getState();
  const { destinos, idorigen } =
    selectTypeNotice as NoticeDetailMultiDestinyModel;
  const { cargoDescription = '', verifiedPhotosId = 0 } =
    sendData.multiDestiny as SendDataMultidestiny;
  const avisodestinos = getNoticeDestinations(destinos);

  const data: RequestOfferMultiDestinationService = {
    ...body,
    avisodestinos,
    idOrigen: idorigen,
    fecha: selectDate,
    idTipoAvisoNegociacion: idTypeNoticeNegotiation,
    descripcionCarga: cargoDescription,
    idfotosverificadas: verifiedPhotosId,
  };

  return data;
};

const getNoticeDestinations = (destinations: NoticeDetailDestinyModel[]) => {
  const avisodestinos = destinations.reduce((acc: Avisodestino[], el) => {
    if (el.selected) {
      acc.push({ iddistrito: el.iddistrito });
    }
    return acc;
  }, []);

  return avisodestinos;
};
