import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { NoticeDetailAuctionModel } from "@/models/notice/noticeV2NegotationModels";
import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import { useHandleCounterOffer } from "@/pages/noticeV2Negotation/shared/hooks/useHandleCounterOffer";
import { CustomCardV2 } from "@/pages/noticeV2Negotation/shared/styles";
import { Box, FormControl, Grid, TextField, Typography } from "@mui/material";

const PriceCounterOfferAuctionDriver = () => {
  const { isLoadingNoticeV2, notice } = useGetData();
  const data = notice as NoticeDetailAuctionModel;
  const {
    handleSubmit,
    handleOnChange,
    handleDisabled,
    valueInput,
    errorPrice,
  } = useHandleCounterOffer();

  return (
    <>
      <CustomCardV2 sx={{ mt: 2 }}>
        <Typography sx={{ fontWeight: 700, fontSize: "17px" }}>
          Contraoferta
        </Typography>
        <Grid container>
          <Grid xs={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ fontSize: "12px", color: "#757575" }}>
                Peso solicitado
              </Typography>
              <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
                {new Intl.NumberFormat().format(data.pesoEstimado)} Kilos
              </Typography>
            </Box>
          </Grid>
          <Grid xs={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                {`Precio del remate (S/)`}{" "}
                <span style={{ color: "#D32F2F" }}>*</span>
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  hiddenLabel
                  id="filled-hidden-label-small"
                  variant="standard"
                  size="small"
                  placeholder="Tu precio"
                  sx={{
                    width: "80px",
                    input: {
                      "&::placeholder": {
                        color: "#bdbdbd",
                      },
                    },
                  }}
                  value={valueInput}
                  onChange={handleOnChange}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: 5,
                  }}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </CustomCardV2>
      {!!errorPrice && (
        <Typography sx={{ color: "#D32F2F", fontSize: "12px", mt: 1 }}>
          {errorPrice}
        </Typography>
      )}

      <ButtomLoader
        fullWidth
        variant="contained"
        isLoading={isLoadingNoticeV2}
        onClick={handleSubmit}
        disabled={handleDisabled() || isLoadingNoticeV2}
      >
        CONTRAOFERTAR
      </ButtomLoader>
    </>
  );
};

export default PriceCounterOfferAuctionDriver;
