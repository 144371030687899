import { CssBaseline, ThemeProvider } from "@mui/material";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { RE_CAPTCHA_KEY } from "./config/config";
import AppRouter from "./router/AppRouter";
import { theme } from "./styles/theme";

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GoogleReCaptchaProvider reCaptchaKey={RE_CAPTCHA_KEY as string}>
        <AppRouter />
      </GoogleReCaptchaProvider>
    </ThemeProvider>
  );
}

export default App;
