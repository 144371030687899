import { DetailNoticeMoving } from "@/models/notice/noticeV2NegotationModels";
import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import { Box, Typography } from "@mui/material";
import { CoCounterOfferPrice } from "../../components/CoCounterOfferGlobalPrice";

export const PriceMovingCoCounter = () => {
  const { notice } = useGetData();
  const {
    ayudantes,
    ayudantescontraoferta,
  } = notice as DetailNoticeMoving;
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CoCounterOfferPrice />
        <Typography sx={{ fontSize: "14px", ml: 1 }}>Por viaje</Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
          Cantidad de ayudantes:
        </Typography>
        <Typography sx={{ fontSize: "16px", ml: 1 }}>
          {ayudantescontraoferta || ayudantes}
        </Typography>
      </Box>
    </>
  );
};
