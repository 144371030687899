import StandardAlert from "@/components/alerts/StandardAlert";
import DropZone from "@/components/DrowZone/DropZone";
import LabelRequired from "@/components/utils/LabelRequired";
import { BASE_URL_IMAGE } from "@/config/config";
import {
  IMG_PATH,
  MD_IMG,
  USER_BACK_CAR_DEFAULT,
  USER_FRONT_CAR_DEFAULT,
  USER_SIDE_CAR_DEFAULT,
} from "@/consts/defaultConsts";
import { resizePhoto } from "@/utils/helpers";
import { Stack, styled, Typography } from "@mui/material";
import { FC } from "react";

const Title = styled(Typography)(
  () => `
    margin: 16px 0;
    display: block;
  `
);

type Props = {
  imageDefault: string;
  onError: (e: Error) => void;
  onSelect: (image: string | File) => void;
  title: string;
  textError: string;
  showTextError: boolean;
};

const defaultImages = [
  USER_FRONT_CAR_DEFAULT,
  USER_SIDE_CAR_DEFAULT,
  USER_BACK_CAR_DEFAULT,
];

const SectionImagens: FC<Props> = ({
  textError,
  showTextError,
  imageDefault,
  onError,
  onSelect,
  title,
}) => {
  const existDefaultImgae = defaultImages.some((item) => item === imageDefault);

  const handleImageDefault = () => {
    if (!existDefaultImgae) {
      return (
        resizePhoto({ photo: IMG_PATH + imageDefault, newFormat: MD_IMG }) ||
        IMG_PATH + imageDefault
      );
    }

    return  BASE_URL_IMAGE + imageDefault;
  };

  return (
    <Stack>
      <Title variant="subtitle1medium"> 
        <LabelRequired str={title} />
      </Title>
      <>
        {showTextError && (
          <StandardAlert severity="error" color="error">
            {textError}
          </StandardAlert>
        )}
      </>
      <DropZone
        imageDefault={handleImageDefault()}
        onError={onError}
        onSelect={onSelect}
      />
    </Stack>
  );
};

export default SectionImagens;
