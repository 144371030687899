import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import DatePicker from "@/components/form/datePicker.tsx/DatePicker";
import Input from "@/components/inputCustom/Input";
import { FORMAT_DATE_V2 } from "@/consts/formatsDate";
import { textDateServiceLabel } from "@/consts/noticeConsts";
import { useAppSelector } from "@/hooks/useReducer";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { FormControl, InputAdornment, Typography } from "@mui/material";
import { default as FormRuteInputs } from "../../../../../components/FormRuteInputs";
import { configFormOffert } from "../configData";
import useRuteInCity from "./useRuteInCity";
import dayjs from "dayjs";

const minDate = dayjs().add(1, "day");
const maxDate = dayjs().add(1, "month");

const RuteInCity = () => {
  const {
    noticeV2ClientAorCO: { isLoading },
  } = useAppSelector((state) => state);
  const { origin, destiny, price } = configFormOffert;
  const {
    notice,
    isCounterOffer,
    errorPrice,
    form,
    errorDate,
    handleOnChange,
    handleSubmit,
    handleTextButton,
    handleErrorDate,
    handleChangeDate,
    handleDisabled,
  } = useRuteInCity();

  return (
    <div>
      <DatePicker
        fullWidth
        variant="outlined"
        initValue={form.date}
        inputFormat={FORMAT_DATE_V2}
        label={<LabelInputRequired str={textDateServiceLabel} />}
        disablePast
        minDate={minDate}
        maxDate={maxDate}
        showStylesLabel
        onChange={handleChangeDate}
        onError={handleErrorDate}
        renderInput={Input}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CalendarMonthOutlinedIcon />
            </InputAdornment>
          ),
          endAdornment: null,
        }}
      />
      {!!errorDate && (
        <Typography variant="caption" color="#d32f2f">
          {errorDate}
        </Typography>
      )}

      <FormRuteInputs
        title="Origen"
        titleValue={notice.origentexto}
        input={{
          ...origin.input2,
          value: form.originReference,
          show: true,
        }}
        onClick={() => {}}
        onChange={handleOnChange}
      />

      <FormRuteInputs
        title="Destino"
        titleValue={notice.destinotexto}
        input={{
          ...destiny.input2,
          value: form.destinyReference,
          show: true,
        }}
        onClick={() => {}}
        onChange={handleOnChange}
      />

      {isCounterOffer && (
        <FormRuteInputs
          title="Precio"
          titleValue=""
          subtitle="Del aviso"
          subtitleValue={notice.precio.toString()}
          input={{
            ...price.input,
            value: `${form?.price}` || "",
            show: true,
          }}
          onClick={() => {}}
          onChange={handleOnChange}
          multiline={false}
          errorPrice={errorPrice}
        />
      )}

      <FormControl fullWidth>
        <ButtomLoader
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={handleDisabled() || isLoading}
        >
          {handleTextButton()}
        </ButtomLoader>
      </FormControl>
    </div>
  );
};

export default RuteInCity;
