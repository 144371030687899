import StandardAlert from "@/components/alerts/StandardAlert";
import FormPropertyCard from "@/components/form/formPropertyCard.tsx/FormPropertyCard";
import BannerModal from "@/components/modals/BannerModal/BannerModal";
import { PROFILE_CREAR_VEHICULO_PATH, PROFILE_MIS_VEHICULOS_PATH } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import HeaderProfile from "@/pages/profile/headerProfile/HeaderProfile";
import { IVehicleList } from "@/store/userRedux/userReduxInterface";
import { setErrorMsg, setSuccessMsg } from "@/store/userRedux/userSlice";
import { updateVehicleImageTarjetaThunk } from "@/store/userRedux/userThunks";
import { useEffect, useRef, useState } from "react";
import { To, useLocation, useNavigate } from "react-router-dom";
import { changePathName, objTitle } from "../profileConfigDataVehicle";

const DocumentCandE = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // validate if the data is empty and show the modal
  const [dataIsEmpty, setDataIsEmpty] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState({
    title: "",
    message: "",
    ok: "",
    cancel: "",
  });

  const { selectedVehicleList, error } = useAppSelector((state) => state.user);

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (error) {
      timer.current = setTimeout(() => {
        dispatch(setErrorMsg(""));
      }, 15000);
    }

    return () => {
      clearTimeout(timer.current as NodeJS.Timeout);
    };
  }, [error]);

  const onSubmit = (foto: string, fotoPosterior: string) => {
    const vehicleId = (selectedVehicleList as IVehicleList)?.id;
    const body = { tarjeta: foto || null, tarjetaPosterior: fotoPosterior || null };


    if (foto !== "" || fotoPosterior !== "") {
      dispatch(updateVehicleImageTarjetaThunk(body, vehicleId?.toString())).then(handleSuccess);
    } else {
      handleSuccess();
    }
  };

  const handleSuccess = () => {
    const isCreation = pathname === PROFILE_CREAR_VEHICULO_PATH[2];
    const pathNavigate = isCreation ? PROFILE_MIS_VEHICULOS_PATH : (-1 as To);
    const successMessage = isCreation ? "Vehículo agregado" : "Cambios guardados con éxito.";

    dispatch(setSuccessMsg(successMessage));
    navigate(pathNavigate);
  };

  // dataIsEmpty
  const handleDataIsEmpty = (isEmpty: boolean) => {
    setDataIsEmpty(isEmpty);
  };

  const navigateBack = () => {
    if (dataIsEmpty) {
      setShowAlert(true);
      setMessageAlert({
        title: "Cambios sin guardar",
        message: "Aún no has guardado la foto, si regresas deberás subirla nuevamente. ¿Deseas regresar?",
        ok: "SI, REGRESAR",
        cancel: "NO, QUEDARME",
      });
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <HeaderProfile
        title={objTitle[changePathName(pathname)]}
        sx={{
          width: "360px",
        }}
        viewIcon={true}
        backAction={navigateBack}
      >
        <BannerModal
          open={showAlert}
          onClose={() => setShowAlert(false)}
          title={messageAlert.title}
          content={messageAlert.message}
          option1={{
            title: messageAlert.cancel,
            onClick: () => setShowAlert(false),
          }}
          option2={{
            title: messageAlert.ok,
            onClick: () => navigate(-1),
          }}
          color="org"
          sx={{
            width: "322px",
            height: "292px",
          }}
          sxAccion={{
            padding: "0px",
          }}
        />

        <>
          {error && (
            <StandardAlert sx={{ marginBottom: "16px" }} severity="error" color="error">
              {error}
            </StandardAlert>
          )}
        </>

        <FormPropertyCard vehicles={(selectedVehicleList as IVehicleList) || {}} onSubmit={onSubmit} dataIsEmpty={handleDataIsEmpty} />
      </HeaderProfile>
    </>
  );
};

export default DocumentCandE;
