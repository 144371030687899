import { useAppSelector } from "@/hooks/useReducer";

import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { MY_PENDING_AGREEMENTS } from "@/consts/typeServicesPaths";
import { ResponseMovingNoticeOffer } from "@/models/offert/offertModels";
import { useNavigate } from "react-router-dom";
import {
  configDataMoving,
  ValidFormat,
  ConfigDataBodyMovingOffer,
} from "./configurations/configMoving";
import DynamicComponent from "@/pages/flowNoticeNegotation/components/DynamicComponent";

const OfferBodyConfirmationMoving = () => {
  const { dataResponse, idTypeNoticeNegotiation } = useAppSelector(
    (state) => state.noticeV2ClientAorCO
  );

  const navigate = useNavigate();

  const offer = dataResponse as ResponseMovingNoticeOffer;

  const handleFormat = (currentValue: any, format?: ValidFormat) => {
    if (!format || !dataResponse) return currentValue;

    let { template, valuesToReplace } = format;

    const newValue = valuesToReplace.reduce((acc, el) => {
      const value = offer[el.value]?.toString() || el.defaultResult;

      return acc.replace(el.search, value);
    }, template);

    return newValue;
  };

  const handleValid = ({ valid, props }: ConfigDataBodyMovingOffer) => {
    if (!valid || !offer) return { props };

    const moreProps = valid.reduce((acc, el) => {
      let value = handleFormat(offer[el.property], el?.format);

      if (el?.callback) {
        value = el.callback(idTypeNoticeNegotiation, value, dataResponse);
      }

      if (typeof el.defaultResult === "boolean") {
        acc = {
          ...acc,
          [el.componentProp]: !!value,
        };
      } else {
        acc = {
          ...acc,
          [el.componentProp]: value || el.defaultResult,
        };
      }
      return acc;
    }, {});

    return { props: { ...props, ...moreProps } };
  };

  if (!dataResponse) return <h1>Error...</h1>;

  return (
    <>
      {configDataMoving.map((item, i) => (
        <DynamicComponent key={i} {...item} {...handleValid(item)} />
      ))}

      <ButtomLoader
        variant="outlined"
        sx={{ marginTop: "20px" }}
        onClick={() => navigate(MY_PENDING_AGREEMENTS)}
      >
        VER NEGOCIACIONES
      </ButtomLoader>
    </>
  );
};

export default OfferBodyConfirmationMoving;
