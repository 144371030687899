import SkeletonViewService from "@/pages/loading/SkeletonViewService";
import useNegotationFinishedRejectHook, { componentToRender } from "./hook";
import RejectLayout from "../components/RejectLayout";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { Stack } from "@mui/material";

const NegotationFinishedReject = () => {
  const { isLoadingNoticeV2, notice, idOffert } =
    useNegotationFinishedRejectHook();
  if (Number(idOffert) !== notice?.id || isLoadingNoticeV2 || !notice)
    return <SkeletonViewService />;
  const Component =
    componentToRender[(notice as NoticeV2NegType)?.idtipoaviso as number];

  return (
    <RejectLayout notice={notice}>
      <Stack
        sx={{
          wordWrap: "break-word",
        }}
      >
        <Component notice={notice} />
      </Stack>
    </RejectLayout>
  );
};

export default NegotationFinishedReject;
