import {
  DetailNoticeMoving,
  NoticeDetailAuctionModel,
  NoticeDetailBetweenCitiesModel,
  NoticeDetailInCityModel,
  NoticeDetailMultiDestinyModel,
} from '@/models/notice/noticeV2NegotationModels';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type NoticeV2NegType =
  | NoticeDetailInCityModel
  | NoticeDetailBetweenCitiesModel
  | NoticeDetailMultiDestinyModel
  | DetailNoticeMoving
  | NoticeDetailAuctionModel;

export type NoticeV2NegTypeRes = NoticeDetailInCityModel &
  NoticeDetailBetweenCitiesModel &
  NoticeDetailMultiDestinyModel &
  DetailNoticeMoving &
  NoticeDetailAuctionModel;

export interface InitialStateDetailsNoticeV2Negotation {
  inCity: null | NoticeDetailInCityModel;
  betweenCities: null | NoticeDetailBetweenCitiesModel;
  multiDestiny: null | NoticeDetailMultiDestinyModel;
  moving: null | DetailNoticeMoving;
  auction: null | NoticeDetailAuctionModel;
  responseData: null | NoticeV2NegType;
  isLoadingNoticeV2: boolean;
  error: any;
  errorCode: number;
}

const initialState: InitialStateDetailsNoticeV2Negotation = {
  inCity: null,
  betweenCities: null,
  multiDestiny: null,
  moving: null,
  auction: null,
  responseData: null,
  isLoadingNoticeV2: false,
  error: null,
  errorCode: 0,
};

export const noticeV2Negotiation = createSlice({
  name: 'noticeV2Negotiation',
  initialState,
  reducers: {
    setLoadingNoticeV2: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingNoticeV2 = payload;
    },
    setError: (
      state,
      { payload }: PayloadAction<{ message: string; status: number }>
    ) => {
      state.error = payload.message;
      state.errorCode = payload.status;
      state.isLoadingNoticeV2 = false;
    },
    setDataInCityNoticeNegotation: (
      state,
      { payload }: PayloadAction<NoticeDetailInCityModel>
    ) => {
      state.inCity = payload;
      state.isLoadingNoticeV2 = false;
      state.error = null;
    },
    setDataBetweenCitiesNoticeNegotation: (
      state,
      { payload }: PayloadAction<NoticeDetailBetweenCitiesModel>
    ) => {
      state.betweenCities = payload;
      state.isLoadingNoticeV2 = false;
      state.error = null;
    },
    setDataMultiDestinyNoticeNegotation: (
      state,
      { payload }: PayloadAction<NoticeDetailMultiDestinyModel>
    ) => {
      state.multiDestiny = payload;
      state.isLoadingNoticeV2 = false;
      state.error = null;
    },
    setDataMovingNoticeNegotation: (
      state,
      { payload }: PayloadAction<DetailNoticeMoving>
    ) => {
      state.moving = payload;
      state.isLoadingNoticeV2 = false;
      state.error = null;
    },
    setDataAuctionNoticeNegotation: (
      state,
      { payload }: PayloadAction<NoticeDetailAuctionModel>
    ) => {
      state.auction = payload;
      state.isLoadingNoticeV2 = false;
      state.error = null;
    },
    setDataResponse: (state, { payload }: PayloadAction<any>) => {
      state.responseData = payload;
      /*       state.isLoading = false; */
      state.error = null;
    },

    resetDetailsNoticeSlice: () => initialState,
  },
});

export const {
  setError,
  setLoadingNoticeV2,
  setDataInCityNoticeNegotation,
  setDataBetweenCitiesNoticeNegotation,
  setDataMultiDestinyNoticeNegotation,
  resetDetailsNoticeSlice,
  setDataMovingNoticeNegotation,
  setDataAuctionNoticeNegotation,
  setDataResponse,
} = noticeV2Negotiation.actions;
