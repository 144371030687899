import { Box, Button, Grid, Typography, styled } from '@mui/material';

export const AdvertisingLandSectionContainer = styled(Grid)(
  ({ matches }: { matches: boolean }) => ({
    minHeight: '400px',
    height: '100%',
    dosplay: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    border: matches ? '' : '1px solid black',
    borderRadius: matches ? '0px' : '24px',
  })
);

export const ContainerImg = styled(Grid)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '30px',
}));

export const SubContainer = styled(Grid)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
}));

export const BoxContainer = styled(Box)(
  ({ matches }: { matches: boolean }) => ({
    width: !matches ? '50%' : '100%',
  })
);

export const TypographyTitle = styled(Typography)(() => ({
  fontWeight: '700',
  fontSize: '22px',
  marginBottom: '16px',
}));

export const TypographyText = styled(Typography)(() => ({
  fontWeight: '400',
  fontSize: '16px',
}));

export const ButtonStyled = styled(Button)(() => ({
  color: 'white',
  width: '100%',
  fontWeight: '700',
  backgroundColor: '#FF6700',
  borderRadius: '44px',
  marginTop: '16px',
  marginBottom: '16px',
  '&:hover': { backgroundColor: '#FF6700' },
}));

export const BoxImageDesktop = styled(Box)(() => ({
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
}));
