import DatePicker from "@/components/form/datePicker.tsx/DatePicker";
import Input from "@/components/inputCustom/Input";
import { FORMAT_DATE_DDMMYYYY } from "@/consts/formatsDate";
import {
  ButtonSelectDateFilter,
  ContainerListButtons,
} from "@/pages/landing/services/styleServices";
import {
  handleDate,
  handleDateRange,
} from "@/pages/landing/services/utils/handleCheck";
import { InputAdornment, Stack, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";

interface ListDate {
  title: string;
  type: number;
}

const List: ListDate[] = [
  {
    title: "Hoy",
    type: 1,
  },
  {
    title: "Mañana",
    type: 2,
  },
  {
    title: "Esta semana",
    type: 3,
  },
  {
    title: "La próxima semana",
    type: 4,
  },
  {
    title: "Este mes",
    type: 5,
  },
];

// {
//   "nombre":"Fecha de aviso",
//   "opciones": ["2022/12/01", "2022/12/02"]
// }
type IProps = {
  initialDates: string[];
  handleCheck: (startDate: string, endDate: string) => void;
};

const FilterDate = ({ initialDates, handleCheck }: IProps) => {
  const [fechaStart, setFechaStart] = useState<Dayjs>(dayjs().startOf("day"));
  const [fechaFin, setDateFin] = useState<Dayjs>(dayjs().endOf("day"));

  const [selected, setSelected] = useState(1);

  useEffect(() => {
    handleInitDate();
  }, []);

  useEffect(() => {
    if (fechaStart && fechaFin) {
      const [startDate, endDate] = handleDateRange([
        fechaStart?.toString(),
        fechaFin?.toString(),
      ]);
      handleCheck(startDate, endDate);
    }
  }, [fechaStart, fechaFin, selected]);

  const handleInitDate = () => {
    if (!!initialDates.length) {
      let [dateOne = fechaStart, dateTwo = fechaFin] = initialDates;
      dateOne = dateOne || fechaStart;
      const [dateStart, dateFin] = handleDateRange([
        dateOne?.toString(),
        dateTwo?.toString(),
      ]);
      const type = List.find(({ type }) => {
        const [fechaStart, fechaFin] = handleDateRange(handleDate(type));
        if (dateStart === fechaStart && dateFin === fechaFin) {
          return type;
        }
      });

      setSelected(type?.type || 0);
      setFechaStart(dayjs(new Date(dateStart)));
      setDateFin(dayjs(new Date(dateFin)));
    }
  };

  const handleDateChange = (date: Dayjs | null | any) => {
    setFechaStart(date);
    // cambiar la fecha fin solo si es menor a la fecha de inicio
    if (date && fechaFin && date.isAfter(fechaFin)) {
      setDateFin(date);
    }
    setSelected(0);
  };
  const handleDateChange2 = (date: Dayjs | null | any) => {
    setDateFin(date);
    setSelected(0);
  };

  const handleSelect = (type: number) => {
    const [fechaStart, fechaFin] = handleDate(type);
    setFechaStart(dayjs(fechaStart));
    setDateFin(dayjs(fechaFin));
    setSelected(type);
  };

  return (
    <div>
      <ContainerListButtons>
        {List.map((value, index) => (
          <ButtonSelectDateFilter
            className={selected === value.type ? "active" : ""}
            onClick={() => handleSelect(value.type)}
            key={index}
          >
            {value.title}
          </ButtonSelectDateFilter>
        ))}
      </ContainerListButtons>
      <Typography variant="body1" sx={{ m: "20px 0" }}>
        Busca avisos:
      </Typography>
      <Stack spacing={2} sx={{ mt: "20px", gap: "20px" }}>
        <DatePicker
          initValue={fechaStart}
          inputFormat={FORMAT_DATE_DDMMYYYY}
          label="Desde"
          onChange={handleDateChange}
          maxDate={dayjs(new Date()).add(30, "day")}
          renderInput={Input}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <i className="icon-calendar--input" />
              </InputAdornment>
            ),
          }}
        />

        <DatePicker
          initValue={fechaFin}
          inputFormat={FORMAT_DATE_DDMMYYYY}
          label="Hasta"
          onChange={handleDateChange2}
          minDate={dayjs(fechaStart)}
          maxDate={dayjs(new Date()).add(30, "day")}
          renderInput={Input}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <i className="icon-calendar--input" />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </div>
  );
};

export default FilterDate;
