import { ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import { IMyRequestListData } from "@/models/customerRequest/MyRequestListDataResponse";
import { configDetailsByTypeFlow } from "@/pages/customerRequest/application/utils/byTypes";
import { IconTypes } from "@/utils/Icon";
import { ICardMyRequestProps } from '../../../../../components/CardMyRequest/index';
import { REQUEST_TYPE_MOVE_BETWEEN_CITIES, REQUEST_TYPE_MOVE_CITY, REQUEST_TYPE_MULTI_DESTINY_HOUR, REQUEST_TYPE_MULTI_DESTINY_POINT } from "@/consts/requestConsts";
import { optionOne, optionTwo } from "@/consts/noticeConsts";

const renderTitleByTypeMyRequestList = (
    requestList: IMyRequestListData,
) => {
    const {
        flgHoraoPunto,
        flgCiudadoEntreciudades,
        idTipoSolicitud
    } = requestList;
    let { title } = configDetailsByTypeFlow[Number(idTipoSolicitud)];
    if (!!flgHoraoPunto && idTipoSolicitud === ID_MULTI_DESTINY) {
        if (flgHoraoPunto === optionOne) {
            title = REQUEST_TYPE_MULTI_DESTINY_HOUR;
        } else {
            title = REQUEST_TYPE_MULTI_DESTINY_POINT;
        }
    }

    // 1 mudanza en la ciudad, 2 mudanza entre ciudades
    if (flgCiudadoEntreciudades === optionOne) {
        title = REQUEST_TYPE_MOVE_CITY;
    } else if (flgCiudadoEntreciudades === optionTwo) {
        title = REQUEST_TYPE_MOVE_BETWEEN_CITIES;
    }

    return title;
};


const renderIconByTypeMyRequestList = (
    requestList: IMyRequestListData,
): IconTypes => {
    const {
        flgCiudadoEntreciudades,
        idTipoSolicitud
    } = requestList;
    let { icon } = configDetailsByTypeFlow[Number(idTipoSolicitud)];
    // 1 En la ciudad 2 Entre ciudades - OBLIGATORIO
    if (flgCiudadoEntreciudades === 1) {
        icon = "icon-moving"
    }

    return icon;
};


const renderTitleOrigenAndDestiny = (
    requestList: IMyRequestListData,
) => {
    const IS_MULTIDESTINY = requestList.idTipoSolicitud === ID_MULTI_DESTINY;
    const TITLE_TEMPLATE = 'De $origin a $destiny';
    const { origenTexto, destinoTexto } = requestList;
    const title = TITLE_TEMPLATE.replace('$origin', origenTexto).replace(
        '$destiny',
        destinoTexto
    );

    if (IS_MULTIDESTINY) {
        return origenTexto;
    }
    
    return title;
};


export const adapterMyCardRequestData = (requestList: IMyRequestListData): ICardMyRequestProps => {
    const {
        fecha,
        tipoVehiculo,
        pesoEstimado,
        precio,
        precioDetalle,
        foto,
        estado,
        colorEstado,
    } = requestList;
    
    let icon = renderIconByTypeMyRequestList(requestList);
    let title = renderTitleByTypeMyRequestList(requestList);
    let origin = renderTitleOrigenAndDestiny(requestList);


    return {
        icon,
        title,
        origin,
        date: fecha,
        vehicle: tipoVehiculo,
        peso: pesoEstimado,
        price: precio,
        precioDetalle: precioDetalle,
        img: foto,
        state: estado,
        stateColor: colorEstado,
    }

}