import BasicModal from "@/components/dialog/Notiffication";
import HeaderSection from "@/components/headerSection/HeaderSection";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import SnackbarNotification from "@/components/snackbar/Snackbar";
import { ID_MOVING_FLOW } from "@/consts/defaultConsts";
import { useAppSelector } from "@/hooks/useReducer";
import { Container, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useUnsavedAndSavedChanges from "../../hooks/useUnsavedAndSavedChanges";

const ContainerArea = styled(Container)(
  () => `
      padding: 16px 0;
    `
);

export interface HeaderSection {
  icon: string,
  iconSection: string,
  title: string,
  subTitle: string
}

const headerSection: HeaderSection = {
  icon: "icon-arrow-left--primary",
  iconSection: "icon-location-light",
  title: "Ruta del Servicio",
  subTitle: "Editar"
}

const headerSectionMoving: HeaderSection = {
  icon: "icon-arrow-left--primary",
  iconSection: "icon-calendar--blue",
  title: "Fecha",
  subTitle: "Editar",
}

const emptyHeaderSection: HeaderSection = {
  icon: "",
  iconSection: "",
  title: "",
  subTitle: "",
}

const Rute = () => {
  const {handleManageErrorAPISnackbar, handleSnackbarError, handleManageModalUnsavedChanges } = useUnsavedAndSavedChanges()
  const { customerRequest } = useAppSelector((state) => state);
  const activeButton = customerRequest.activeButton
  const error = customerRequest.error
  const typeRequestId = customerRequest.savedData?.tipoSolicitud.id;
  const [header, setHeader] = useState<HeaderSection>(emptyHeaderSection)
  const navigate = useNavigate();

  useEffect(() => {
    if (!!error) {
      handleManageErrorAPISnackbar();
      handleSnackbarError();
    }
  }, [error]);


  useEffect(() => {
    let typeHeader = headerSection
    if (typeRequestId === ID_MOVING_FLOW) {
      typeHeader = headerSectionMoving
    }
    setHeader(typeHeader)
  }, []);


  const handlePrevRute = () => {
    if(activeButton){
      !!error ?  navigate(-1) : handleManageModalUnsavedChanges()
    }else{
      navigate(-1);
    }
    
  };

  return (<>
    <Container maxWidth="xs">
      <HeaderSection
        icon={header.icon}
        title={header.title}
        subTitle={header.subTitle}
        onClick={handlePrevRute}
      />
      {!!error && <SnackbarNotification/>}
      <ContainerArea>
        <SectionIcon icon={header.iconSection} />
        <Outlet />
      </ContainerArea>
    </Container>
    <BasicModal/>
    </>
  );
};

export default Rute;
