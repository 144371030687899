import ImagesThumbnail from '@/components/imagesThumbnail/ImagesThumbnail';
import { Negotiation } from '@/models/offert/offertModels';
import { showRequestDetail } from '@/pages/acceptanceOffert/utils/utilsAcceptance';
import { Typography } from '@mui/material';
import {
  getFormatMetro,
  getFormatPrice,
  getFormatSendDate,
  getTitleByPrice,
  showOrHiden,
} from '../../utils/utilsOffert';

import DetailsText from '../DetailsText';
import { descriptionLoad } from '@/consts/noticeConsts';

interface ValuesToReplace {
  search: string;
  value: keyof Negotiation;
  defaultResult: string;
}

export interface ValidFormat {
  template: string;
  valuesToReplace: ValuesToReplace[];
}

interface ConfigDataBodyOffertValid {
  property: keyof Negotiation;
  componentProp: string;
  defaultResult: boolean | string | number | Array<any> | Object;
  format?: ValidFormat;
  callback?: (
    tipoAvisoNegociacion: number,
    value: any,
    offert: Negotiation
  ) => any;
}

export interface ConfigDataBodyOffert {
  title?: string;
  Component: any;
  props: any;
  valid?: ConfigDataBodyOffertValid[];
  children?: JSX.Element | JSX.Element[] | string;
}

export const configData: ConfigDataBodyOffert[] = [
  {
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'title',
        defaultResult: '',
        callback: showRequestDetail,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-calendar--white',
      title: 'Fecha del servicio',

      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-box-black',
      title: 'Cantidad de horas',
    },
    valid: [
      {
        property: 'horas',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'horas',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-box-black',
      title: 'Cantidad de puntos',
    },
    valid: [
      {
        property: 'puntos',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'puntos',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-location-medium',
      title: 'Origen',
    },
    valid: [
      {
        property: 'origenTexto2',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'origenTexto2',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referenciaOrigen',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-location-medium',
      title: 'Destino',
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'destinoTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  // metraje
  {
    Component: DetailsText,
    props: {
      icon: 'icon-rules-small',
      title: 'Metraje',
    },
    valid: [
      {
        property: 'metraje',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'metraje',
        componentProp: 'origen',
        defaultResult: '',
        callback: getFormatMetro,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-cargo-person',
      title: 'Ayudantes',
    },
    valid: [
      {
        property: 'ayudantes',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'ayudantes',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: descriptionLoad,
      variant: 'subtitle1medium',
      sx: {
        marginTop: '10px',
        display: 'block',
        wordWrap: 'break-word',
        maxWidth: '320px',
      },
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
        callback: showOrHiden,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      sx: {
        variant: 'body1',
        maxWidth: '500px',
        wordWrap: 'break-word',
      },
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-weigth',
      title: 'Peso estimado',
    },
    valid: [
      {
        property: 'pesoEstimado',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'pesoEstimado',
        componentProp: 'origen',
        defaultResult: '',
        format: {
          template: ':weight kilos',
          valuesToReplace: [
            {
              search: ':weight',
              value: 'pesoEstimado',
              defaultResult: '',
            },
          ],
        },
      },
    ],
  },
  {
    Component: ImagesThumbnail,
    props: {
      style: { marginTop: '13px' },
    },
    valid: [
      {
        property: 'fotos',
        componentProp: 'images',
        defaultResult: [],
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: 'Precio de punto a punto',
      variant: 'body2',
      marginTop: '24px',
    },
    valid: [
      {
        property: 'precio',
        componentProp: 'title',
        defaultResult: '',
        callback: getTitleByPrice,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'h5',
      sx: { textTransform: 'inherit' },
    },
    valid: [
      {
        property: 'precio',
        componentProp: 'title',
        defaultResult: 0,
        callback: getFormatPrice,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      sx: { color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px', lineHeight: '20px' },
    },
    valid: [
      {
        property: 'fechaRegistro',
        componentProp: 'title',
        defaultResult: 0,
        callback: getFormatSendDate,
      },
    ],
  },
];
