import { Grid, styled } from '@mui/material';

export const NotificationCardContent = styled(Grid)({
  alignItems: 'center',
  maxWidth: '100%',
  height: '87px',
  borderBottom: '1px solid rgba(0, 0, 0, 0.18)',

  padding: '10px 16px',
  margin: '0px 0px 0px 0px',
  '@media (max-width: 700px)': {
    height: '109px',
    padding: '15.5px 16px',
  },
  '&:first-of-type': {
    borderTop: '1px solid rgba(0, 0, 0, 0.18)',
  },
  '&:hover': {
    backgroundColor: 'rgba(232, 240, 254, 0.5)',
  },
});
