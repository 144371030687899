import { COUNTER_OFFERT_CONFIRMATION } from "@/consts/acceptanceOffertPath";
import { ID_AUCTION_FLOW, ID_MOVING_FLOW } from "@/consts/defaultConsts";
import {
  maxLengthMovingBtnCityPrice,
  maxLengthMovingInCityPrice,
  maxLengthPrice,
  textCannotLowerPrice,
  textCannotUpperPrice,
} from "@/consts/noticeConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { DetailNoticeMoving } from "@/models/notice/noticeV2NegotationModels";
import { cocounterOfferNoticeDataLayer } from "@/services/dataLayer/createNoticeNeg";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { saveOfferByIdThunkV2 } from "@/store/noticeV2Negotiation/noticeV2NegotiationThunk";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { cleanInputNumber } from "@/utils/inputsUtils";
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { counterOfferDataAdapter } from "../adapters/counterOfferAdapters";
import { typeNoticeName } from "../config";
import useGetData from "./useGetData";
import { useGlobalButtonsV2 } from "./useGlobalButtons";

const movingBtnCityId = 2;

export const useHandleCounterOffer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { notice } = useGetData();
  const { handleManageModalPriceChangedV2 } = useGlobalButtonsV2();
  const [valueInput, setValueInput] = useState("");
  const [errorPrice, setErrorPrice] = useState("");
  const isMoving = notice?.idtipoaviso === ID_MOVING_FLOW;
  const [formMoving, setFormMonving] = useState({
    price: (notice as DetailNoticeMoving)?.precio,
    assistant: (notice as DetailNoticeMoving).ayudantes,
  });

  const {
    user: { user },
  } = useAppSelector((state) => state);
  const userId = (user as IUser).id;
  const noticeId = (notice as NoticeV2NegType).id;
  const counter_offert_confirmation_url = COUNTER_OFFERT_CONFIRMATION.replace(
    ":idOffer",
    noticeId.toString()
  );

  const handleGoBackPage = () => {
    navigate(-1);
  };

  const handleOnChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = target;
    setErrorPrice("");
    const newValue = cleanInputNumber(value);

    if (isMoving) {
      let maxLength = maxLengthMovingInCityPrice;

      if (
        (notice as DetailNoticeMoving).flgenlaciudadoentreciudades ===
        movingBtnCityId
      ) {
        maxLength = maxLengthMovingBtnCityPrice;
      }

      value.length <= maxLength &&
        setFormMonving({ ...formMoving, price: Number(newValue) });
      return;
    }

    value.length <= maxLengthPrice && setValueInput(newValue);
  };

  const handleSubmit = () => {
    let currentPrice = valueInput;

    const dataAdapter = counterOfferDataAdapter({
      notice: notice as NoticeV2NegType,
      valueInput,
      formMoving,
    });

    if (!handleErrorInput(currentPrice, formMoving.price)) return;

    dispatch(saveOfferByIdThunkV2(dataAdapter)).then((value) => {
      if (!!value.flgpreciocambiado) {
        handleManageModalPriceChangedV2(() => handleGoBackPage());
      } else {
        cocounterOfferNoticeDataLayer(
          userId,
          typeNoticeName[(notice as NoticeV2NegType)?.idtipoaviso]
        );
        navigate(counter_offert_confirmation_url, { replace: true });
      }
    });
  };

  const handleErrorInput = (currentPrice: string, movingPrice: number) => {
    const numberPrice = Number(currentPrice);
    const numberMovingPrice = Number(movingPrice);
    const noticePrice = Number(notice?.aviso.precio);
    
    if (isMoving) {
      if (numberMovingPrice < 100) {
        setErrorPrice(textCannotLowerPrice);
        return false;
      }
      return true;
    }

    if (numberPrice > noticePrice * 3) {
      setErrorPrice(textCannotUpperPrice);
      return false;
    }

    if (numberPrice < noticePrice / 2) {
      setErrorPrice(textCannotLowerPrice);

      return false;
    }

    return true;
  };

  const handleDisabled = () => {
    let conditions = [valueInput];

    return conditions.some((condition) => !condition);
  };

  return {
    handleOnChange,
    valueInput,
    handleDisabled,
    handleSubmit,
    errorPrice,
    formMoving,
    setFormMonving,
  };
};
