import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { snackbarAcceptInCityMsg } from "@/consts/notifficationsMessage";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import SelectCar from "@/pages/createRequestNegotiation/components/SelectCar";
import { noticeNegotationRequestAdapter } from "@/pages/noticeRequestFlow/adapters/noticeRequestFlowAdapters";
import { acceptRequest } from "@/services/dataLayer/createRequestNeg";
import { saveDriverNegRequestThunk } from "@/store/noticeNegotationRequestRedux/noticeNegRequesThunk";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { FormControl, Grid, styled } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";


const Container = styled(Grid)(
  () => `
    display: flex;
    flex-direction: column;  
    margin-top: 24px;
  `
);

const FormAcceptMultidestiny = () => {
  const { addNotiffication } = useNotiffication();
  const { idRequest = "", typeRequest = "" } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    user: { user, selectedVehicle },
    customerRequest: { requestDetailsView },
    noticeNegotationRequest: { isLoading, error, noticeRequestData },
  } = useAppSelector((state) => state);

  const vehicleData = { ...selectedVehicle };
  const typeNegotation = requestDetailsView?.tipoSolicitud?.nombre as string;
  const idUser = (user as IUser).id;

  useEffect(() => {
      if (!!error) {
        handleManageDialog();
      } 
  }, [isLoading]);

  const handleSubmit = (event: EventType["submit"]) => {
    event.preventDefault();

    const body = noticeNegotationRequestAdapter(vehicleData, noticeRequestData);
 
    dispatch(saveDriverNegRequestThunk(idRequest, body)).then(() => {
      acceptRequest(idUser, typeNegotation);
      handleNextRute();
    });
  };

  const handleNextRute = () => {
    const valueToReplace = typeRequest;
    const idValueToReplace = idRequest.toString();
    dispatch(
      getNextRoute(navigate, {
        valueToSearch: ":typeRequest",
        valueToReplace,
        idToSearch: ":idRequest",
        idValueToReplace,
      })
    );
  };

  const handleDisabledButton = () => {
    const conditions = [selectedVehicle];

    return conditions.some((condition) => !condition);
  };

  const handleManageDialog = () => {
    addNotiffication({
      title: error || snackbarAcceptInCityMsg.TITLE,
      message: snackbarAcceptInCityMsg.MESSAGE,
      onSubmit: async () => {},
    });
  };

  return (
    <Container container>
      <form onSubmit={handleSubmit}>
        <FormControl sx={{ width: "100%" }}>
          <SelectCar idAvisoOrSolicitud={Number(idRequest)} />
        </FormControl>

        <Grid item sx={{ marginTop: "16px" }}>
          <ButtomLoader
            type="submit"
            buttomProps={{
              disabled: isLoading || handleDisabledButton(),
            }}
            isLoading={isLoading}
          >
            ACEPTAR
          </ButtomLoader>
        </Grid>
      </form>
    </Container>
  );
};

export default FormAcceptMultidestiny;
