import { ConfigDataNegotation } from '@/pages/noticeRequestFlow/interface/noticeNegRequestInterface';
import { generalConfigNegotiationRequest } from '@/pages/requestNegotiation/shared/config/generalConfig';

// ==================================================
//          CONFIGURACION EN LA CIUDAD
// ==================================================

export const configDataInCity: ConfigDataNegotation[] = [
  ...generalConfigNegotiationRequest,
];
