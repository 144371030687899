import { createTheme } from '@mui/material/styles';

export const fontFamily = "'Source Sans Pro', Montserrat, sans-serif, 'Roboto'";
export const secondFontFamily = "'Source Sans Pro', 'Roboto', sans-serif";
export const thirdFontFamily = "Montserrat, sans-serif, 'Roboto'";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // 0px - 430px
      sm: 431, // 431px - 767px
      md: 768, // 768px - 1023px
      lg: 1032, // 102px - 1280px
      xl: 1280,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subtitle2: 'span',
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          fontSize: '16px',
          minHeight: '56px',
          gap: '16px',
        },
        message: { padding: 0, margin: 0 },
        icon: { padding: 0, margin: 0 },
        action: { padding: 0 },
        standardSuccess: {
          backgroundColor: 'rgba(76,175,80,0.12)',
          color: 'rgba(46,125,50,1)',
        },
        standardError: {
          backgroundColor: 'rgba(255,82,82,0.12)',
          color: 'rgba(211,47,47,1)',
        },
        standardInfo: {
          backgroundColor: 'rgba(33,150,243,0.12)',
          color: 'rgba(2,136,209,1)',
        },
        standardWarning: {
          backgroundColor: 'rgba(251,140,0,0.12)',
          color: 'rgba(237,108,2,1)',
        },
        outlined: {
          padding: '5px 15px ',
        },
        outlinedSuccess: {
          color: 'rgba(46,125,50,1)',
        },
        outlinedError: {
          color: 'rgba(211,47,47,1)',
        },
        outlinedInfo: {
          color: 'rgba(2,136,209,1)',
        },
        outlinedWarning: {
          color: 'rgba(237,108,2,1)',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.6)',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#0047BA',
    },
    secondary: {
      main: '#382E2B',
    },
    success: {
      main: '#2E7D32',
    },
    info: {
      main: '#0288D1',
      light: '#3f90e6',
      dark: '#0B79D0',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FF6700',
      contrastText: '#ED6C02',
    },
    error: {
      main: '#D32F2F',
    },
    org: { main: '#FF6700', contrastText: '#FFF' },
    borderGray: { main: '#eeeeee', contrastText: '#757575' },
    text: {
      secondary: 'rgba(0,0,0,0.87)',
      disabled: 'rgba(0,0,0,0.6)',
      primary: 'rgba(0,0,0,0.95)',
    },
    accent: {
      main: '#0047BA',
      contrastText: '#00B3E3',
    },
    accent2: {
      main: '#00B3E3',
      contrastText: '#FFF',
    },
    colorText: {
      main: '#000000',
    },
    customShadow: {
      main: '0px 4px 10px #ECEFF1',
      contrastText: 'inset 0px -1px 0px #F6F6F6;',
    },
    bgScrollBar: {
      primary: 'rgba(0,0,0,0.06)',
      secondary: 'rgba(0,0,0,0.1)',
      disabled: 'rgba(0,0,0,0.3)',
    },
    cardbg: {
      primary: '#FFC107',
      secondary: '#9e9e9e',
      disabled: '#FAFAFA',
    },
  },
  typography: {
    h1: {
      fontFamily: fontFamily,
      fontSize: '88px',
      lineHeight: '96px',
    },
    h2: {
      fontFamily: fontFamily,
      fontSize: '57px',
      lineHeight: '60px',
    },
    h3: {
      fontFamily: fontFamily,
      fontSize: '48px',
      lineHeight: '50px',
    },
    h4: {
      fontFamily: fontFamily,
      fontWeight: '370',
      fontSize: '34px',
      lineHeight: '40px',
    },
    h5: {
      fontFamily: thirdFontFamily,
      fontSize: '22px',
      lineHeight: '32px',
      textTransform: 'uppercase',
    },
    h5low: {
      fontSize: '22px',
      lineHeight: '32px',
      fontFamily: fontFamily,
    },
    h6: {
      fontFamily: secondFontFamily,
      fontSize: '18px',
      lineHeight: '32px',
      fontWeight: '700',
    },
    subtitle1: {
      fontFamily: secondFontFamily,
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '28px',
    },
    subtitle1medium: {
      fontFamily: secondFontFamily,
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '28px',
    },
    subtitle2: {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '22px',
      fontFamily: secondFontFamily,
    },
    body1: {
      fontFamily: secondFontFamily,
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
    },
    body2: {
      fontFamily: secondFontFamily,
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
    },
    button: {
      fontFamily: " Montserrat, sans-serif, 'Roboto'",
      fontSize: '14px',
      lineHeight: '36px',
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: secondFontFamily,
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: '400',
    },
    overline: {
      fontFamily: secondFontFamily,
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '32px',
      textTransform: 'uppercase',
    },
    placeholder: {
      fontFamily: secondFontFamily,
      fontWeight: '400',
      fontStyle: 'italic',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0px',
    },
    label1: {
      fontFamily: secondFontFamily,
      fontWeight: '600',
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '20px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    label2: {
      fontFamily: secondFontFamily,
      fontWeight: '600',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
    },
    label3: {
      fontFamily: secondFontFamily,
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '20px',
      color: 'rgba(0, 0, 0, 0.60)',
    },
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    customShadow: Palette['primary'];
    colorText: Palette['primary'];
    accent: Palette['primary'];
    bgScrollBar: Palette['text'];
    org: Palette['primary'];
    borderGray: Palette['primary'];
    accent2: Palette['primary'];
    cardbg?: Palette['text'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    customShadow?: PaletteOptions['primary'];
    colorText?: PaletteOptions['primary'];
    accent?: PaletteOptions['primary'];
    bgScrollBar?: PaletteOptions['text'];
    org?: PaletteOptions['primary'];
    borderGray?: PaletteOptions['primary'];
    accent2?: PaletteOptions['primary'];
    cardbg?: PaletteOptions['text'];
  }

  interface TypographyVariants {
    h5low: React.CSSProperties;
    subtitle1medium: React.CSSProperties;
    overline: React.CSSProperties;
    placeholder: React.CSSProperties;
    label1: React.CSSProperties;
    label2: React.CSSProperties;
    label3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h5low?: React.CSSProperties;
    subtitle1medium?: React.CSSProperties;
    overline?: React.CSSProperties;
    placeholder?: React.CSSProperties;
    label1?: React.CSSProperties;
    label2?: React.CSSProperties;
    label3?: React.CSSProperties;
  }
}

/* Habilitar variables en la typografia */
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h5low?: true;
    subtitle1medium?: true;
    overline?: true;
    placeholder: true;
    label1: true;
    label2: true;
    label3: true;
  }
}

/*Habilitar el Color en Botones  */
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    org: true;
  }
}

declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    org: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    org: true;
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    org: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    org: true;
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    org: true;
  }
}
