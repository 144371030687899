import { getFiltersCustomerRequestServices, getFiltersServices } from "@/services/filterService";
import { AppDispatch } from "../store";
import { setDataFilter, setDataOrdenamiento, setError, setLoading, setSelectedOrdenamiento } from "./filterSlice";

export const getDataFiltersServices = (id: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const { filtroAvisoData } = await getFiltersServices(id);
      const { tiposFiltro, ordenamiento } = filtroAvisoData;
      dispatch(setDataFilter(tiposFiltro || []));
      dispatch(setDataOrdenamiento(ordenamiento || []));
      dispatch(setSelectedOrdenamiento(ordenamiento[0] || {}));
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

export const getDataFiltersCustomerRequestServices = (id: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const { filtroSolicitudData } = await getFiltersCustomerRequestServices(id);
      const { tiposFiltro, ordenamiento } = filtroSolicitudData;
      dispatch(setDataFilter(tiposFiltro || []));
      dispatch(setDataOrdenamiento(ordenamiento || []));
      dispatch(setSelectedOrdenamiento(ordenamiento[0] || {}));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}
