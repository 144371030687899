import { getCitiesByCountryServices, getCitiesByPaisAndTermServices, getCitiesByTermServices } from "../../services/locationServices";
import { AppDispatch } from "../store";
import { setCities, setCitiesByTerm, setError, setLoading } from "./citiesSlice";

export const getCitiesByCountryThunk = (idCountry: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const result = await getCitiesByCountryServices(idCountry);

      dispatch(setCities(result.ciudadData.ciudades));
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

export const getCitiesByTermThunk = (term: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const result = await getCitiesByTermServices(term);

      dispatch(setCitiesByTerm(result?.ciudadData?.ciudades));
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

export const getCitiesByPaisAndTermThunk = (idCountry: string, term: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const result = await getCitiesByPaisAndTermServices(idCountry, term);

      dispatch(setCitiesByTerm(result?.ciudadData?.ciudades));
    } catch (error) {
      dispatch(setError(error));
    }
  };
};
