import { DetailsNegotationV2 } from "@/models/notice/noticeV2NegotationModels";
import { currencyFormat } from "@/utils/helpers";
import { Typography } from "@mui/material";

export const CounterOfferPriceV2 = ({
  data,
}: {
  data: DetailsNegotationV2;
}) => {
  return (
    <>
      <Typography sx={{ pt: 2, fontWeight: 400, fontSize: "16px" }}>
        Precio de tu aviso {currencyFormat(data.aviso.precio)}
      </Typography>
      <Typography sx={{ fontWeight: 700, fontSize: "22px" }}>
        Cliente Ofrece: {currencyFormat(data?.precio)}
      </Typography>
    </>
  );
};
