import {
    Box,
    Button,
    Grid,
    styled,
    Typography,
    experimental_sx as sx,
  } from "@mui/material";

export const ButtonDecline = styled(Button)(
    sx({
      heigth: "44px",
      color: "#FF6700",
      backgroundColor: "#FFFFFF",
      border: "1px solid #FF6700",
      borderRadius: "44px",
      mt: "10px",
      "&:hover": {
        backgroundColor: "white",
      },
    })
  );
  
  export const text = styled(Button)(
    sx({
      heigth: "44px",
      color: "#FF6700",
      backgroundColor: "#FFFFFF",
      border: "1px solid #FF6700",
      borderRadius: "44px",
      mt: "10px",
      "&:hover": {
        backgroundColor: "white",
      },
    })
  );
  
  export const GridImage = styled(Grid)(
    sx({
      pl: 2,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      mt: 4,
    })
  );
  
  export const GridButton = styled(Grid)(
    sx({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      mt: 4,
    })
  );
  
  export const BoxButton = styled(Box)(
    sx({
      width: "90%",
      maxWidth: "520px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      pl: "14px",
    })
  );
  
  export const Text = styled(Typography)(
    sx({
      mt: 2,
      textAlign: "center",
      fontSize: "18px"
    })
  );
  