import StandardAlert from "@/components/alerts/StandardAlert";
import FormCreateService from "@/components/form/formCreateService/FormCreateService";
import HeaderSection from "@/components/headerSection/HeaderSection";
import Input from "@/components/inputCustom/Input";
import BannerModal from "@/components/modals/BannerModal/BannerModal";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import { nottifficationUnsavedChanges } from "@/consts/notifficationsMessage";
import { SUCCESS_TEXT_EDIT_VEHICLE } from "@/consts/textSuccess";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { DirtyTypeProps } from "@/interfaces/globalInterface";
import { DetailsCoreModel } from "@/models/notice/noticeModels";
import useDetailNotice from "@/pages/detailNotice/hook";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { setMessageEdit } from "@/store/saveFlow/saveFlowSlice";
import { editVehicleThunk } from "@/store/vehicle/vehicleThunk";
import { Container, FormControl, styled } from "@mui/material";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { configRefrigerateSwitchById } from "./configData";

const ContainerVehicle = styled(Container)(
  () => `
    padding: 16px 0;
  `
);

export const VehicleEditAuction = () => {
  const [isDirtyfields, setIsDirtyfields] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const { TITLE, MESSAGE, LEFT_BTN_TEXT, RIGHT_BTN_TEXT } =
    nottifficationUnsavedChanges;

  const {
    vehicle: { isLoading },
  } = useAppSelector((state) => ({
    saveFlowData: state.saveFlowData.saveFlowData,
    user: state.user,
    vehicle: state.vehicle,
    detailsNoticeSlice: state.detailsNoticeSlice,
  }));

  const { handleGetCurrentDetailNotice } = useDetailNotice();

  const notice: DetailsCoreModel = useMemo(
    () => handleGetCurrentDetailNotice(),
    [isLoading]
  );

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleOnSubmit = () => {
    if (isLoading) return;
    setError("");

    dispatch(editVehicleThunk(notice.id))
      .then(handleSuccess)
      .catch((e: Error) => setError(e?.message));
  };

  const handleSuccess = () => {
    dispatch(setMessageEdit(SUCCESS_TEXT_EDIT_VEHICLE));
    handleGoBack();
  };

  const handleDirtyFields = (dirtyFields: DirtyTypeProps) => {
    const isDirtyForm = Object.values(dirtyFields).some(({ dirty }) => !!dirty);

    setIsDirtyfields(isDirtyForm);
  };

  const handleValidGoBack = () => {
    if (isDirtyfields) {
      setShowAlert(true);
    } else {
      handleGoBack();
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  const switchConfig = configRefrigerateSwitchById[notice.tipoaviso.id] || 1;

  const handleOnchange = () => {};

  return (
    <>
      <Container maxWidth="xs">
        <HeaderSection
          icon="icon-arrow-left--primary"
          title="Vehículo"
          subTitle="Editar"
          onClick={handleValidGoBack}
        />
        <BannerModal
          open={showAlert}
          onClose={handleCloseModal}
          title={TITLE}
          content={MESSAGE}
          option1={{
            title: LEFT_BTN_TEXT,
            onClick: handleCloseModal,
          }}
          option2={{
            title: RIGHT_BTN_TEXT,
            onClick: handleGoBack,
          }}
          color="org"
          sx={{
            width: "322px",
            height: "234px",

            pb: 6,
          }}
          sxAccion={{
            padding: "0px",
          }}
        />
        <ContainerVehicle maxWidth="xs">
          <SectionIcon icon="icon-truck" />
          <>
            {error && (
              <StandardAlert
                sx={{ marginBottom: "16px" }}
                severity="error"
                color="error"
              >
                {error}
              </StandardAlert>
            )}
          </>
          <FormCreateService
            onSubmit={handleOnSubmit}
            data={notice}
            Refrige={!switchConfig.showRefrigerate}
            switchText={switchConfig.text}
            loading={isLoading}
            buttonText={!!notice ? "GUARDAR" : "SIGUIENTE"}
            onDirtyFields={handleDirtyFields}
            showKilos={true}
            maxWeigth={notice.pesomaximo.toString()}
          />
        </ContainerVehicle>
       
      </Container>
    </>
  );
};
