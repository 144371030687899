import {
  AGREEMENT_MESSAGE_COUNTEROFFER_DRIVER,
  DECLINE_COCOUNTEROFFER,
  DECLINE_MESSAGE_COUNTEROFFER_DRIVER,
  DECLINE_OFFER_DRIVER,
  MESSAGE_AGREEMENT_OFFERT,
  MESSAGE_CONFIRMATION_CO_COUNTER_OFFER_CLIENT,
} from '@/consts/acceptanceOffertPath';
import { actionsToNoticeNegotation } from '@/consts/actions';
import CounterOfferGlobalButtons from './CounterOfferGlobalButtons';
import QuoteGlobalButons from './QuoteGlobalButons';
import OfferGlobalButtons from './OfferGlobalButtons';
import { textRejectConfirmation } from '@/consts/noticeConsts';

export const objectRenderComponent: Record<number, () => JSX.Element> = {
  1: OfferGlobalButtons,
  2: CounterOfferGlobalButtons,
  3: QuoteGlobalButons,
  4: OfferGlobalButtons,
  5: OfferGlobalButtons,
};

export type ActionButton = {
  1: number;
  2?: number;
  3: number;
};

type ActionCoCounterOfferButton = {
  1: number;
  2: number;
};

export type UrlType = {
  1: string;
  2?: string;
  3?: string;
};

export type TypeDeclinedNegotation = {
  title: string;
  message: string;
};

export const actionButton: Record<number, ActionButton> = {
  // Si el cliente acepta el aviso del transportista:
  1: {
    1: actionsToNoticeNegotation[3].id, // El transportista acepta
    3: actionsToNoticeNegotation[4].id, // El transportista rechaza
  },

  // Si el cliente contraoferta el aviso del transportista:
  2: {
    1: actionsToNoticeNegotation[11].id, // El transportista acepta
    2: actionsToNoticeNegotation[5].id, // El transportista contraoferta
    3: actionsToNoticeNegotation[12].id, // El transportista rechaza
  },

  // Si el transportista contraoferta la contraoferta del cliente:

  3: {
    1: actionsToNoticeNegotation[6].id, // El cliente acepta
    3: actionsToNoticeNegotation[7].id, // El cliente rechaza
  },
};

export const manageActionButtonCoCounterOffer: Record<
  number,
  ActionCoCounterOfferButton
> = {
  3: {
    1: actionsToNoticeNegotation[8].id, // transportista acepta contraoferta
    2: actionsToNoticeNegotation[9].id, // transportista rechaza contraoferta
  },
};

export const urlNavigateNoticeConfig: Record<number, any> = {
  //Rutas de la oferta por parte del transportista:
  1: {
    //Si el transportista recibe una acetación de oferta:
    1: MESSAGE_AGREEMENT_OFFERT, // ruta de acuerdo mensaje
    2: DECLINE_OFFER_DRIVER, // ruta de rechazo mensaje
  },

  2: {
    //Si el transportista recibe una contraoferta:
    1: AGREEMENT_MESSAGE_COUNTEROFFER_DRIVER, // ruta de acuerdo mensaje
    2: DECLINE_MESSAGE_COUNTEROFFER_DRIVER, // ruta de rechazo mensaje

    //Si el cliente recibe una contraoferta por parte del transportista puede:
    3: MESSAGE_CONFIRMATION_CO_COUNTER_OFFER_CLIENT, // ruta de acuerdo mensaje
    4: DECLINE_COCOUNTEROFFER, // ruta de rechazo mensaje
  },
};

// Objeto para la vita de rechazo
export const typeDeclinedNegotatioNotice: Record<
  number,
  TypeDeclinedNegotation
> = {
  4: {
    title: 'Oferta rechazada',
    message: textRejectConfirmation,
  },
  7: {
    title: 'Contraoferta rechazada',
    message: textRejectConfirmation,
  },
  12: {
    title: 'Contraoferta rechazada',
    message: textRejectConfirmation,
  },
};
