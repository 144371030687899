import { Title } from "@/pages/flowNoticeNegotation/styles/styles";
import Icon, { IconTypes } from "@/utils/Icon";
import { Box, Typography } from "@mui/material";
import { FC } from "react";

export type Props = {
  title: string;
  origen: string[];
  icon: string;
  optionalSign?: string
};

const TextArrayFormat: FC<Props> = ({ title, origen, icon, optionalSign=":" }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
      <Box sx={{ display: "flex" }}>
        <Icon name={icon as IconTypes} size="md" />
        <Title variant="label1">{!!title && `${title}${optionalSign} `}</Title>
      </Box>

      <Box sx={{ paddingLeft: "8px" }}>
        <ul style={{ margin: "0px" }}>
          {origen.map((item, i) => (
            <li>
              <Typography
                key={i}
                variant="body1"
                sx={{ wordWrap: "break-word", maxWidth: "320px" }}
              >
                {item}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};
export default TextArrayFormat;
