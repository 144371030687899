import { requestConditionsAdapter } from "@/adapters/store/editNegotationRequestAdapter";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import SwitchControl from "@/components/form/switchControl/SwitchControl";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { RequestConditions } from "@/models/customerRequest/customerRequestTypesConditions";
import {
  setActiveButton,
  setLisConditions,
  updateCondition
} from "@/store/customerRequestRedux/customerRequestSlice";
import { uploadConditionsRequestEdit } from "@/store/noticeNegotationRequestRedux/editNegRequestThunk";
import { Container, Stack, Typography, styled } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { conditionAdapter } from "../formConditionsMoving/configData";

const ContainerArea = styled(Container)(
  () => `
    padding: 16px 0;
  `
);

const FormConditionsEditMoving = () => {
  const navigate = useNavigate();
  const { customerRequest,  customerRequest: { requestDetailsView }, } = useAppSelector((state) => state);
  const idRequest = requestDetailsView?.id.toString()
  const dispatch = useAppDispatch();
  const isLoading = customerRequest.isLoading
  const listConditions = customerRequest.lisConditions;
  const conditions = requestDetailsView?.condiciones;
  const newConditions = conditionAdapter(
    conditions as RequestConditions[]
  );

  useEffect(() => {
      dispatch(setLisConditions(newConditions));
    return () => {};
  }, []);

  const handleSubmit = (event: EventType["submit"]) => {
    event.preventDefault();

    const body = requestConditionsAdapter(listConditions)

       dispatch(
      uploadConditionsRequestEdit(idRequest as string, body)
    )  .then(() => navigate(-1)) ;  
 
  };

  const handlePrepareList = (listItem: RequestConditions, isActive: number) => {
    const currentList = listConditions.map((item) => {
      if (listItem.id === item.id) {
        return {
          ...item,
          isActive: !!isActive,
        };
      }

      return item;
    });

    return currentList;
  };

  const handleChangeSwtich = (
    listItem: RequestConditions,
    isActive: number
  ) => {
    const currentList = handlePrepareList(listItem, isActive);

    dispatch(updateCondition(currentList));
  };

  const handleCompareinputs = () => {
    const currentInputsValue = [listConditions];
    const dataToInputsValue = [newConditions];

    const result =
      JSON.stringify(currentInputsValue) === JSON.stringify(dataToInputsValue);
      
      return !result;
  };

  const handleDisabledButton = () => {
    let result = true;
    const sameData = handleCompareinputs();
    dispatch(setActiveButton(sameData))
    if (sameData) {
      result = false;
    }

    return result;
  };

  return (
    <>
    <form onSubmit={handleSubmit}>
      <ContainerArea>
        <Stack gap="4px" sx={{ marginTop: "16px" }}>
          <Typography variant="subtitle1">
          Tu solicitud incluye:
          </Typography>
          {listConditions.map(
            (condition) =>
              condition.idcondicion !== 7 &&
              condition.idcondicion !== 8 && (
                <SwitchControl
                  key={condition.id}
                  sx={{ marginTop: "20px" }}
                  text={condition.nombre}
                  hideSwitch={!!condition.flgVer}
                  onChange={(isActive) =>
                    handleChangeSwtich(condition, isActive)
                  }
                  value={
                    !!condition.isActive 
                  }
                />
              )
          )}
        </Stack>
      </ContainerArea>

    <ButtomLoader
      type="submit"
      sx={{ marginTop: "24px" }}
      isLoading={isLoading}
      buttomProps={{
        disabled: handleDisabledButton() || isLoading,
      }}
    >
      GUARDAR
    </ButtomLoader>    
    </form>
  </>
  );
};
export default FormConditionsEditMoving;
