import {
  REQUEST_BETWEEN_CITIES,
  REQUEST_EXPRESS,
  REQUEST_IN_CITY,
  REQUEST_MOVING,
  REQUEST_MULTI_DESTINY,
} from '@/pages/customerRequest/shared/consts/routes';
import { CustomerRequestPathTypes } from '../interface/customerRequestInterface';

export const customerRequestTypesArea: CustomerRequestPathTypes = {
  1: {
    title: 'Ruta del servicio',
    subTitle: 'Solicitar transporte en la ciudad',
  },
  2: {
    title: 'Ruta del servicio',
    subTitle: 'Solicitar transporte multidestino',
  },
  3: {
    title: 'Ruta del servicio',
    subTitle: 'Solicitar transporte entre ciudades',
  },
  4: {
    title: 'Ruta del servicio',
    subTitle: 'Solicitar servicio express',
  },
  5: {
    title: 'Origen',
    subTitle: 'Solicitar mudanza',
  },
};

export const customerRequestObjectTypes: Record<number, string> = {
  1: REQUEST_IN_CITY,
  2: REQUEST_MULTI_DESTINY,
  3: REQUEST_BETWEEN_CITIES,
  4: REQUEST_EXPRESS,
  5: REQUEST_MOVING,
};

export const titlesObj: Record<string, string> = {
  'en-la-ciudad': 'Solicitar transporte en la ciudad ',
  multidestino: 'Solicitar transporte multidestino ',
  'entre-ciudades': 'Solicitar transporte entre ciudades',
  express: 'Solicitar servicio express',
  mudanza: 'Solicitar mudanza',
};

export const questionTitle = '¿Qué vas a transportar?';
