import { CLIENT_TYPE } from "@/consts/defaultConsts";
import { maxLengthPrice, optionFive, optionTwo, textCannotLowerPrice, textCannotUpperPrice, withoutPriceDefault } from "@/consts/noticeConsts";
import { snackbarCounterOfferMsg } from "@/consts/notifficationsMessage";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import { noticeNegotationRequestAdapter } from "@/pages/noticeRequestFlow/adapters/noticeRequestFlowAdapters";
import useUnsavedAndSavedChanges from "@/pages/noticeRequestFlow/hooks/useUnsavedAndSavedChanges";
import { counterOfferRequest } from "@/services/dataLayer/createRequestNeg";
import { BodyUploadPriceNegotationPrice } from "@/services/noticeNegotationRequestServices";
import {
  saveDriverNegRequestThunk,
  updatePriceNegotationThunk,
} from "@/store/noticeNegotationRequestRedux/noticeNegRequesThunk";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { currencyFormat } from "@/utils/helpers";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  NEGOTATION_COUNTEROFFER,
  NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_DRIVER,
  NEGOTATION_REJECTED_NEGOTATION_MESSAGE_CLIENT,
} from "../../../../../consts/negotationFlowPath";

export const useCounterOfferMultidestiny = () => {
  const { handleSnackbarError } = useUnsavedAndSavedChanges();
  const { pathname } = useLocation();
  const isCounterOffer = pathname.endsWith(NEGOTATION_COUNTEROFFER);
  const [typeOfMultidestiny, setTypeOfMultidestiny] = useState("");
  const { addNotiffication } = useNotiffication();
  const [price, setPrice] = useState("");
  const [errorPrice, setErrorPrice] = useState("");
  const { idRequest = "", typeRequest = "" } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    user: { selectedVehicle, user },
    customerRequest: { requestDetailsView },
    noticeNegotationRequest: {
      isLoading,
      error,
      noticeRequestData,
      isEdition,
      savedDataNogotation,
    },
  } = useAppSelector((state) => state);

  const isClient = (user as IUser).tipousuario === CLIENT_TYPE;

  const vehicleData = { ...selectedVehicle };
  const typeNegotation = requestDetailsView?.tipoSolicitud?.nombre as string;
  const idUser = (user as IUser).id;

  useEffect(() => {
    let typeMultidestiny = "hora";

    if (isEdition) {
      setPrice(`${(savedDataNogotation as SolicitudNegociacion)?.precio}`);
    }

    if (requestDetailsView?.flgHoraoPunto === optionTwo) {
      typeMultidestiny = "punto";
    }
    setTypeOfMultidestiny(typeMultidestiny);
  }, []);

  useEffect(() => {
    if (!!error) {
      handleManageDialog();
      handleSnackbarError();
    }
  }, [error]);

  const handleSubmit = (event: EventType["submit"]) => {
    event.preventDefault();
    if (isLoading) return;

    const priceTosend = Number(price)


    const body = noticeNegotationRequestAdapter(
      vehicleData,
      noticeRequestData,
      priceTosend
    );
    delete body.ayudantes;

    if(priceTosend < optionFive){
      setErrorPrice(textCannotLowerPrice)
      return
    }

    if(price.length > optionFive){
      setErrorPrice(textCannotUpperPrice)
      return
    }
  
     dispatch(saveDriverNegRequestThunk(idRequest, body)).then(() => {
      counterOfferRequest(idUser, typeNegotation);
      handleNextRute();
    }); 
  };

  const handleNextRute = () => {
    const valueToReplace = typeRequest;
    const idValueToReplace = idRequest.toString();
    
    dispatch(
      getNextRoute(navigate, {
        valueToSearch: ":typeRequest",
        valueToReplace,
        idToSearch: ":idRequest",
        idValueToReplace,
      })
    );
  };

  const handleDisabledButton = () => {
    const conditions = [selectedVehicle, price];

    return conditions.some((condition) => !condition);
  };

  const handleOnchange = ({ currentTarget }: EventType["change"]) => {
    setErrorPrice("")
    const { value } = currentTarget;
    const prepareValue = value.replace(/^(0+)/g, "").replace(/[^0-9]/g, "");

    if (prepareValue.length <= maxLengthPrice) {
      setPrice(prepareValue);
    }
  };

  const handleManageDialog = () => {
    addNotiffication({
      title: error || snackbarCounterOfferMsg.TITLE,
      message: snackbarCounterOfferMsg.MESSAGE,
      onSubmit: async () => {},
    });
  };

  const handleCompareInputWithDB = () => {
    return price === `${(savedDataNogotation as SolicitudNegociacion)?.precio}`;
  };

  const handleDisabledEditCounterOfferButton = () => {
    const conditions = [price];

    if (handleCompareInputWithDB()) return true;

    return conditions.some((condition) => !condition);
  };

  const handleEditCounterOfferSubmit = (event: EventType["submit"]) => {
    event.preventDefault();
    if (isLoading) return;

    const body: BodyUploadPriceNegotationPrice = { precio: Number(price) };
    const pathClient = NEGOTATION_REJECTED_NEGOTATION_MESSAGE_CLIENT.replace(
      ":typeOffer",
      "contraoferta"
    ).replace(":typeRequest", typeRequest);
    const pathDriver =
      NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_DRIVER.replace(
        ":typeOffer",
        "contraoferta"
      ).replace(":typeRequest", idRequest);
    const path = isClient ? pathClient : pathDriver;
    dispatch(updatePriceNegotationThunk(idRequest, body)).then(() =>
      navigate(path, { replace: true, state: { isNavigationInFlow: true } })
    );
  };

  const handleServicePrice = () => {
    let pointsRequired = requestDetailsView?.puntosRequeridos as number;
    let hoursRequired = requestDetailsView?.horasRequeridas as number;
    let hoursOrPoint = pointsRequired ? pointsRequired : hoursRequired;

    const value = Number(price) * hoursOrPoint;

    return !!value ? `${currencyFormat(value)}` : withoutPriceDefault;
  };

  return {
    errorPrice,
    isLoading,
    requestDetailsView,
    noticeRequestData,
    isEdition,
    isCounterOffer,
    typeOfMultidestiny,
    idRequest,
    price,
    handleSubmit,
    handleDisabledButton,
    handleOnchange,
    handleDisabledEditCounterOfferButton,
    handleEditCounterOfferSubmit,
    handleServicePrice,
  };
};
