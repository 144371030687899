import { EventType } from '@/interfaces/globalInterface';
import {
  NoticeAcceptMultidestinyType,
  NoticeBetweenCitiesType,
  NoticeInCityType,
  NoticeMovingType,
  NoticeQuoteBetweenCitiesType,
  NoticeTypeFlowInterfaces,
  SendDataKeys,
} from '@/store/noticeV2ClientAcceptCounterOffer/interfaces/sendDataInterfacesEnglish';
import { currencyFormat } from '@/utils/helpers';

export interface DataInputProps {
  form: {
    offerPrice: string;
    pointsOrHours: string;
  };
  TypeMultidestiny: number;
  price: number;
  minimum: number;
  maxLengthPrice: number;
  handleChange: ({ target }: EventType['change']) => void;
}

export const handleCardData = (props: DataInputProps) => {
  const {
    form,
    TypeMultidestiny,
    price,
    maxLengthPrice,
    minimum,
    handleChange,
  } = props;
  return [
    {
      name: 'offerPrice',
      value: form.offerPrice,
      headTitle: !!TypeMultidestiny ? 'Precio por hora' : 'Precio por punto',
      infoComponent: {
        value: currencyFormat(price as number),
      },
      placeholder: 'Tu precio',
      labelStr: 'Contraoferta (S/)',
      change: handleChange,
      noRequired: false,
      InputProps: {
        inputMode: 'numeric',
        minLength: 1,
        maxLength: maxLengthPrice,
        required: true,
      },
    },
    {
      name: 'pointsOrHours',
      value: form.pointsOrHours,
      headTitle: !!TypeMultidestiny
        ? 'Cantidad de horas'
        : 'Cantidad de puntos',
      infoComponent: {
        value: minimum,
      },
      placeholder: !!TypeMultidestiny
        ? 'Horas de tu servicio'
        : 'Puntos de tu servicio',
      labelStr: 'Contraoferta',
      change: handleChange,
      noRequired: false,
      InputProps: {
        inputMode: 'numeric',
        minLength: 1,
        maxLength: 5,
        required: true,
      },
    },
    {
      name: '',
      value: currencyFormat(
        Number(form.offerPrice) * Number(form.pointsOrHours)
      ),
      headTitle: !!TypeMultidestiny
        ? 'Cantidad de horas'
        : 'Cantidad de puntos',
      infoComponent: {
        value: currencyFormat((price as number) * (minimum as number)),
      },
      placeholder: '',
      labelStr: 'Contraoferta',
      change: () => {},
      noRequired: true,

      InputProps: {
        disableUnderline: true,
      },
    },
  ];
};

export const getFirstStepNoticeType = (
  idTypeNotice: number,
  variant?: boolean
): SendDataKeys => {
  switch (idTypeNotice) {
    case 1:
      return 'inTheCity';
    case 2:
      return 'multiDestiny';
    case 3:
      return 'betweenCities';
    case 4:
      return 'auctionBetweenCities';
    case 5:
      if (variant) {
        return 'movingInTheCity';
      }
      return 'movingBetweenCities';
    default:
      return 'inTheCity';
  }
};

export const getFirstStepNoticeTypeInterface = (
  idTypeNotice: number,
  values: Partial<NoticeTypeFlowInterfaces>
) => {
  let newValue = values;

  switch (idTypeNotice) {
    case 1:
      newValue as NoticeInCityType;
      return newValue;
    case 2:
      newValue as NoticeAcceptMultidestinyType;
      return newValue;
    case 3:
      newValue as NoticeBetweenCitiesType;
      return newValue;
    case 4:
      newValue as NoticeQuoteBetweenCitiesType;
      return newValue;
    case 5:
      newValue as NoticeMovingType;
      return newValue;
    default:
      newValue as NoticeInCityType;
      return newValue;
  }
};
