import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import SkeletonViewService from "@/pages/loading/SkeletonViewService";
import { getNegotationRequestThunk } from "@/store/noticeNegotationRequestRedux/noticeNegRequesThunk";
import { validateAuthorizationByCode } from "@/utils/helpers";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RejectionConfirmation from "./RejectionConfirmation";


const NegRejection = () => {
  const { noticeNegotationRequest: { errorCode, isLoading, savedDataNogotation}  } = useAppSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { idRequest = "" } = useParams();

  useEffect(() => {
    dispatch(getNegotationRequestThunk(idRequest));
  }, []);

  useEffect(() => {
    validateAuthorizationByCode({ errorCode, navigate });
  }, [errorCode]);


  if(!savedDataNogotation || isLoading) return (<SkeletonViewService />);

  return <RejectionConfirmation />;
};

export default NegRejection;
