export const SERVICES_LIST_DRIVER = '/transportistas/crear-aviso/';
export const REGISTER_ME = `/ingreso?returnPath=/transportistas`;
export const ENTER_THE_WEB = '/ingreso?returnPath=/';
export const AGREEMENT_RATING = '/calificacion-confirmacion';
// ==================================================
//                   Tipo de aviso
// ==================================================
export const NOTICE_IN_CITY = 'en-la-ciudad';
export const NOTICE_MULTI_DESTINY = 'multidestino';
export const NOTICE_BETWEEN_CITIES = 'entre-ciudades';
export const NOTICE_AUCTION = 'remate';
export const NOTICE_MOVING = 'mudanza';

// ==================================================
//              Aviso de en la ciudad
// ==================================================
export const MAIN_NOTICE_PATH = `${SERVICES_LIST_DRIVER}en-la-ciudad`;
export const NOTICE_IN_CITY_PATHS_PRUEBA = `${MAIN_NOTICE_PATH}/vehiculo/prueba`;
export const NOTICE_IN_CITY_PATHS_CONDITION_PRUEBA = `${MAIN_NOTICE_PATH}/condiciones/prueba`;
export const NOTICE_IN_CITY_PATHS_PRICE_PRUEBA = `${MAIN_NOTICE_PATH}/precio/prueba`;

export const NOTICE_IN_CITY_PATHS = [
  `${MAIN_NOTICE_PATH}/vehiculo`,
  `${MAIN_NOTICE_PATH}/lugar`,
  `${MAIN_NOTICE_PATH}/condiciones`,
  `${MAIN_NOTICE_PATH}/sugerencias`,
  `${MAIN_NOTICE_PATH}/precio`,
  `${MAIN_NOTICE_PATH}/aviso-creado`,
  '/mis-avisos/en-la-ciudad/:id',
];

// ==================================================
//              Aviso de multi destino
// ==================================================
export const MAIN_NOTICE_MULTI_DESTYNI_PATH = `${SERVICES_LIST_DRIVER}multidestino`;
export const NOTICE_MULTI_DESTINY_HOURS_PATHS = [
  `${MAIN_NOTICE_MULTI_DESTYNI_PATH}/vehiculo`,
  `${MAIN_NOTICE_MULTI_DESTYNI_PATH}/lugar`,
  `${MAIN_NOTICE_MULTI_DESTYNI_PATH}/condiciones`,
  `${MAIN_NOTICE_MULTI_DESTYNI_PATH}/sugerencias`,
  `${MAIN_NOTICE_MULTI_DESTYNI_PATH}/tipo`,
  `${MAIN_NOTICE_MULTI_DESTYNI_PATH}/precio-hora`,
  `${MAIN_NOTICE_MULTI_DESTYNI_PATH}/hora-aviso-creado`,
  '/mis-avisos/multidestino/:id',
];

export const NOTICE_MULTI_DESTINY_STOPS_PATHS = [
  `${MAIN_NOTICE_MULTI_DESTYNI_PATH}/vehiculo`,
  `${MAIN_NOTICE_MULTI_DESTYNI_PATH}/lugar`,
  `${MAIN_NOTICE_MULTI_DESTYNI_PATH}/condiciones`,
  `${MAIN_NOTICE_MULTI_DESTYNI_PATH}/sugerencias`,
  `${MAIN_NOTICE_MULTI_DESTYNI_PATH}/tipo`,
  `${MAIN_NOTICE_MULTI_DESTYNI_PATH}/precio-punto`,
  `${MAIN_NOTICE_MULTI_DESTYNI_PATH}/punto-aviso-creado`,
  '/mis-avisos/multidestino/:id',
];

// ==================================================
//               Oferta entre ciudades
// ==================================================
export const MAIN_NOTICE_TRANSPORT_BETWEEN_CITIES = `${SERVICES_LIST_DRIVER}entre-ciudades`;
export const NOTICE_TRANSPORT_BETWEEN_CITIES = [
  `${MAIN_NOTICE_TRANSPORT_BETWEEN_CITIES}/tipo`,
  `${MAIN_NOTICE_TRANSPORT_BETWEEN_CITIES}/vehiculo`,
  `${MAIN_NOTICE_TRANSPORT_BETWEEN_CITIES}/lugar`,
  `${MAIN_NOTICE_TRANSPORT_BETWEEN_CITIES}/condiciones`,
  `${MAIN_NOTICE_TRANSPORT_BETWEEN_CITIES}/sugerencias`,
  `${MAIN_NOTICE_TRANSPORT_BETWEEN_CITIES}/precio`,
  `${MAIN_NOTICE_TRANSPORT_BETWEEN_CITIES}/aviso-creado`,
  '/mis-avisos/entre-ciudades/:id',
];

// ==================================================
//               Remate entre ciudades
// ==================================================
export const MAIN_NOTICE_TRANSPORT_AUCTION_PATH = `${SERVICES_LIST_DRIVER}remate`;
export const NOTICE_TRANSPORT_AUCTION_PATHS = [
  `${MAIN_NOTICE_TRANSPORT_AUCTION_PATH}/vehiculo`,
  `${MAIN_NOTICE_TRANSPORT_AUCTION_PATH}/ruta`,
  `${MAIN_NOTICE_TRANSPORT_AUCTION_PATH}/condiciones`,
  `${MAIN_NOTICE_TRANSPORT_AUCTION_PATH}/precio`,
  `${MAIN_NOTICE_TRANSPORT_AUCTION_PATH}/aviso-creado`,
  '/mis-avisos/remate/:id',
];

// ==================================================
//               Mudanzas
// ==================================================
export const MAIN_NOTICE_MOVING_PATH = `${SERVICES_LIST_DRIVER}mudanza`;
export const NOTICE_MOVING_PATHS_DISTRICT = [
  `${MAIN_NOTICE_MOVING_PATH}/vehiculo`,
  `${MAIN_NOTICE_MOVING_PATH}/tipo`,
  `${MAIN_NOTICE_MOVING_PATH}/lugar-distrito`,
  `${MAIN_NOTICE_MOVING_PATH}/condiciones`,
  `${MAIN_NOTICE_MOVING_PATH}/sugerencias`,
  `${MAIN_NOTICE_MOVING_PATH}/precio-zonas`,
  `${MAIN_NOTICE_MOVING_PATH}/aviso-creado`,
  '/mis-avisos/mudanza/:id',
];
export const NOTICE_MOVING_PATHS_CITY = [
  `${MAIN_NOTICE_MOVING_PATH}/vehiculo`,
  `${MAIN_NOTICE_MOVING_PATH}/tipo`,
  `${MAIN_NOTICE_MOVING_PATH}/lugar-en-la-ciudad`,
  `${MAIN_NOTICE_MOVING_PATH}/condiciones`,
  `${MAIN_NOTICE_MOVING_PATH}/sugerencias`,
  `${MAIN_NOTICE_MOVING_PATH}/precio-ciudades`,
  `${MAIN_NOTICE_MOVING_PATH}/aviso-creado`,
  '/mis-avisos/mudanza/:id',
];
export const TYPEFLOW_NAME_PARAM = ':typeFlow';
export const NOTICE_MOVING_PATHS_LIST = `${SERVICES_LIST_DRIVER}${TYPEFLOW_NAME_PARAM}/lista`;

// ==================================================
//              Registro de usuarios!!
// ==================================================
export const AUTH_LOGIN = '/ingreso';
export const AUTH_PASSWORD = '/clave';
export const AUTH_VALIDATION = '/validacion';
export const AUTH_CREATE_PASSWORD = '/crear-clave';
export const AUTH_USER_TYPE = '/tipo-usuario';
export const AUTH_RECOVER_PASSWORD = '/recuperar-contrasena';
export const AUTH_RESET_PASSWORD = '/cambio-contrasena';

export const REGISTER_CLIENT = '/registro/clientes';
export const REGISTER_CLIENT_PROFILE = '/registro/clientes-perfil';
export const WELCOME_CLIENT = '/registro/clientes/bienvenida';

export const REGISTER_DRIVER = '/registro/transportistas';
export const REGISTER_DRIVER_STEPS = `/registro/transportista/pasos`;
export const REGISTER_DRIVER_STEPS_PROFILE = `${REGISTER_DRIVER_STEPS}/perfil`;
export const REGISTER_DRIVER_STEPS_DOCUMENT = `${REGISTER_DRIVER_STEPS}/doc-identificacion`;
export const REGITER_DRIVER_PROPERTY_CARD = `${REGISTER_DRIVER_STEPS}/doc-registro-vehiculo`;
export const REGISTER_DRIVER_VEHICLE = `${REGISTER_DRIVER_STEPS}/vehiculo`;
export const REGISTER_DRIVER_VEHICLE_PHOTOS = `${REGISTER_DRIVER_STEPS}/fotos-vehiculo`;
export const WELCOME_DRIVER = `/registro/transportista/bienvenida`;

export const HOME_PAHT = '/';
export const NOTIFICATIONS_PATH = '/notificaciones';

// ==================================================
//                   LANDING
// ==================================================

export const CLIENTS_LANDING_PATH = '/clientes';
export const SERVICES_LANDING_PATH = '/avisos';
export const REQUESTS_LANDING_PATH = `/solicitudes`;
export const DRIVERS_LANDING_PATH = '/transportistas';
export const ABOUT_US_LANDING_PATH = '/nosotros';
export const FAQ_LANDING_PATH = '/preguntas-frecuentes';
export const ADS_LANDING_PATH = '/anunciantes';
export const ADS_SOON_LANDING_PATH = '/anunciantes-formulario';
export const ADS_SOON_SPONSORED_LANDING_PATH = '/patrocinados-formulario';
export const ADS_CONFIRMATION_LANDING_PATH = '/anunciantes-confirmacion';
export const ADS_CONFIRMATION_SPONSORED_LANDING_PATH =
  '/patrocinados-confirmacion';
export const CONTACT_US_LANDING_PATH = '/contactanos';
export const CONTACT_US_CONFIRMATION_PATH = '/contactanos-confirmacion';
export const TERMS_CONDITIONS_PATH = '/terminos-condiciones';
export const POLITICS_PRIVACY = 'politica-privacidad';
// ==================================================
//                   PROFILE
// ==================================================

export const CONFIRMATION_PATH = `/confirmacion-cambio`;
export const PROFILE_PATH = '/mi-cuenta';
export const PROFILE_DATOS_PATH = `${PROFILE_PATH}/datos`;
export const PROFILE_DATOS_PERSONALS_PATH = `${PROFILE_DATOS_PATH}/personales`;
export const PROFILE_DATOS_DOC_IDENTIDAD = `${PROFILE_DATOS_PATH}/doc-identidad`;
export const PROFILE_DATOS_CAMBIAR_TELEFONO = `${PROFILE_DATOS_PATH}/cambiar-telefono`;
export const PROFILE_DATOS_VALIDACION = `${PROFILE_DATOS_PATH}/validacion`;
export const PROFILE_DATOS_CAMBIAR_CONTRASENA = `${PROFILE_DATOS_PATH}/cambiar-clave`;

export const PROFILE_MIS_VEHICULOS_PATH = `${PROFILE_PATH}/vehiculos`;
// CREAR VEHICULO
export const PROFILE_CREAR_VEHICULO_PATH = [
  `${PROFILE_MIS_VEHICULOS_PATH}/nuevo`,
  `${PROFILE_MIS_VEHICULOS_PATH}/nuevo-fotos`,
  `${PROFILE_MIS_VEHICULOS_PATH}/nuevo-doc-registro`,
];
// EDITAR VEHICULO
export const PROFILE_VEHICULO_PATH = `${PROFILE_MIS_VEHICULOS_PATH}/:id`;
export const PROFILE_EDITAR_VEHICULO_PATH = [
  `${PROFILE_MIS_VEHICULOS_PATH}/:id/editar`,
  `${PROFILE_MIS_VEHICULOS_PATH}/:id/editar-fotos`,
  `${PROFILE_MIS_VEHICULOS_PATH}/:id/editar-doc-registro`,
];

// ==================================================
//                   Mis avisos
// ==================================================

export const PROFILE_NOTICE_LIST_PATH = `/mis-avisos`;

export const PROFILE_NOTICE_OFF = `${PROFILE_NOTICE_LIST_PATH}/aviso-desactivado`;

export const NOTCE_EDIT_IN_CITY_MAIN = `${PROFILE_NOTICE_LIST_PATH}/${NOTICE_IN_CITY}/:id`;
export const NOTICE_EDIT_IN_CITY = `${NOTCE_EDIT_IN_CITY_MAIN}/editar`;
export const NOTICE_EDIT_VEHICLE_IN_CITY = `${NOTICE_EDIT_IN_CITY}/editar-vehiculo`;
export const NOTICE_EDIT_DATE_IN_CITY = `${NOTICE_EDIT_IN_CITY}/editar-fecha`;
export const NOTICE_EDIT_CONDITIONS_IN_CITY = `${NOTICE_EDIT_IN_CITY}/editar-condiciones`;
export const NOTICE_EDIT_PRICE_IN_CITY = `${NOTICE_EDIT_IN_CITY}/editar-precio`;

export const NOTCE_EDIT_MULTI_DESTINY_MAIN = `${PROFILE_NOTICE_LIST_PATH}/${NOTICE_MULTI_DESTINY}/:id`;
export const NOTICE_EDIT_MULTI_DESTINY = `${NOTCE_EDIT_MULTI_DESTINY_MAIN}/editar`;
export const NOTICE_EDIT_VEHICLE_MULTI_DESTINY = `${NOTICE_EDIT_MULTI_DESTINY}/editar-vehiculo`;
export const NOTICE_EDIT_DATE_MULTI_DESTINY = `${NOTICE_EDIT_MULTI_DESTINY}/editar-fecha`;
export const NOTICE_EDIT_CONDITIONS_MULTI_DESTINY = `${NOTICE_EDIT_MULTI_DESTINY}/editar-condiciones`;
export const NOTICE_EDIT_PRICE_MULTI_DESTINY = `${NOTICE_EDIT_MULTI_DESTINY}/editar-precio`;

export const NOTCE_EDIT_BETWEEN_CITIES_MAIN = `${PROFILE_NOTICE_LIST_PATH}/${NOTICE_BETWEEN_CITIES}/:id`;
export const NOTICE_EDIT_BETWEEN_CITIES = `${NOTCE_EDIT_BETWEEN_CITIES_MAIN}/editar`;
export const NOTICE_EDIT_VEHICLE_BETWEEN_CITIES = `${NOTICE_EDIT_BETWEEN_CITIES}/editar-vehiculo`;
export const NOTICE_EDIT_DATE_BETWEEN_CITIES = `${NOTICE_EDIT_BETWEEN_CITIES}/editar-fecha`;
export const NOTICE_EDIT_CONDITIONS_BETWEEN_CITIES = `${NOTICE_EDIT_BETWEEN_CITIES}/editar-condiciones`;
export const NOTICE_EDIT_PRICE_BETWEEN_CITIES = `${NOTICE_EDIT_BETWEEN_CITIES}/editar-precio`;
// renovar
export const NOTICE_RENEW_BETWEEN_CITIES = `${NOTCE_EDIT_BETWEEN_CITIES_MAIN}/editar-fecha`;

export const NOTCE_EDIT_AUCTION_MAIN = `${PROFILE_NOTICE_LIST_PATH}/${NOTICE_AUCTION}/:id`;
export const NOTICE_EDIT_AUCTION = `${NOTCE_EDIT_AUCTION_MAIN}/editar`;
export const NOTICE_EDIT_VEHICLE_AUCTION = `${NOTICE_EDIT_AUCTION}/editar-vehiculo`;
export const NOTICE_EDIT_DATE_AUCTION = `${NOTICE_EDIT_AUCTION}/editar-fecha`;
export const NOTICE_EDIT_CONDITIONS_AUCTION = `${NOTICE_EDIT_AUCTION}/editar-condiciones`;
export const NOTICE_EDIT_PRICE_AUCTION = `${NOTICE_EDIT_AUCTION}/editar-precio`;
// renovar
export const NOTICE_RENEW_AUCTION = `${NOTCE_EDIT_AUCTION_MAIN}/editar-fecha`;

export const NOTCE_EDIT_MOVING_MAIN = `${PROFILE_NOTICE_LIST_PATH}/${NOTICE_MOVING}/:id`;
export const NOTICE_EDIT_MOVING = `${NOTCE_EDIT_MOVING_MAIN}/editar`;
export const NOTICE_EDIT_VEHICLE_MOVING = `${NOTICE_EDIT_MOVING}/editar-vehiculo`;
export const NOTICE_EDIT_DATE_MOVING = `${NOTICE_EDIT_MOVING}/editar-fecha`;
export const NOTICE_EDIT_CONDITIONS_MOVING = `${NOTICE_EDIT_MOVING}/editar-condiciones`;
export const NOTICE_EDIT_PRICE_MOVING = `${NOTICE_EDIT_MOVING}/editar-precio`;

export const NOTICE_EDIT_DINAMIC_PATH = `${PROFILE_NOTICE_LIST_PATH}/:type-notice/:id/editar`;
export const PROFILE_NOTICE_OFFERT = '/mis-avisos/:type-notice/:id/ofertas';
//
// /* {URL} / mis-avisos / oferta / {id} / acuerdo-calificacion-cliente */
export const QUALIFICATION_CLIENT_PATH = '/acuerdo-calificacion-cliente';
export const QUALIFICATION_DRIVER_PATH = '/acuerdo-calificacion-transportista';
export const PROFILE_NOTICE_OFFERT_AGREEMENT_CLIENT = `${PROFILE_NOTICE_LIST_PATH}/oferta/:idOffert/acuerdo-calificacion-cliente`;
/* {URL} / avisos / oferta / {id} / acuerdo-calificacion-transportista */
export const PROFILE_NOTICE_OFFERT_AGREEMENT_TRANSPORTER = `${SERVICES_LANDING_PATH}/oferta/:idOffert/acuerdo-calificacion-transportista`;
// calificacion-confirmacion
export const PROFILE_NOTICE_OFFERT_AGREEMENT_TRANSPORTER_CONFIRMATION = `${SERVICES_LANDING_PATH}/oferta/:idOffert/calificacion-confirmacion`;
export const PROFILE_NOTICE_OFFERT_AGREEMENT_CLIENT_CONFIRMATION = `${PROFILE_NOTICE_LIST_PATH}/oferta/:idOffert/calificacion-confirmacion`;
//
// /* {URL} / avisos / mis-ofertas */
export const PROFILE_NOTICE_OFFERT_LIST = `${SERVICES_LANDING_PATH}/mis-ofertas`;

// Notice mensaje-enviado path by _MAIN path
export const NOTICE_MESSAGE_SENT = [
  `${NOTCE_EDIT_IN_CITY_MAIN}/mensaje-enviado`,
  `${NOTCE_EDIT_MULTI_DESTINY_MAIN}/mensaje-enviado`,
  `${NOTCE_EDIT_BETWEEN_CITIES_MAIN}/mensaje-enviado`,
  `${NOTCE_EDIT_AUCTION_MAIN}/mensaje-enviado`,
  `${NOTCE_EDIT_MOVING_MAIN}/mensaje-enviado`,
];

export const NOTICE_OFFERT = [
  `${NOTCE_EDIT_IN_CITY_MAIN}/ofertas`,
  `${NOTCE_EDIT_MULTI_DESTINY_MAIN}/ofertas`,
  `${NOTCE_EDIT_BETWEEN_CITIES_MAIN}/ofertas`,
  `${NOTCE_EDIT_AUCTION_MAIN}/ofertas`,
  `${NOTCE_EDIT_MOVING_MAIN}/ofertas`,
];

export const NOTICE_EDIT = [
  NOTICE_EDIT_IN_CITY,
  NOTICE_EDIT_MULTI_DESTINY,
  NOTICE_EDIT_BETWEEN_CITIES,
  NOTICE_EDIT_AUCTION,
  NOTICE_EDIT_MOVING,
];

// ==================================================
//             Detalle de avisos privados
// ==================================================
export const NOTICE_IN_CITY_PRIVATE_PATH = `${PROFILE_NOTICE_LIST_PATH}/${NOTICE_IN_CITY}/:id`;
export const NOTICE_MULTI_DESTINY_PRIVATE_PATH = `${PROFILE_NOTICE_LIST_PATH}/${NOTICE_MULTI_DESTINY}/:id`;
export const NOTICE_BETWEEN_CITIES_PRIVATE_PATH = `${PROFILE_NOTICE_LIST_PATH}/${NOTICE_BETWEEN_CITIES}/:id`;
export const NOTICE_AUCTION_PRIVATE_PATH = `${PROFILE_NOTICE_LIST_PATH}/${NOTICE_AUCTION}/:id`;
export const NOTICE_MOVING_PRIVATE_PATH = `${PROFILE_NOTICE_LIST_PATH}/${NOTICE_MOVING}/:id`;
export const PROFILE_NOTICE_DINAMIC_PRIVATE_PATH = `${PROFILE_NOTICE_LIST_PATH}/:type-notice/:id`;

// ==================================================
//                   Avisos Publicos
// ==================================================

export const NOTICE_IN_CITY_PUBLIC_PATH = `${SERVICES_LANDING_PATH}/${NOTICE_IN_CITY}/:id`;
export const NOTICE_MULTI_DESTINY_PUBLIC_PATH = `${SERVICES_LANDING_PATH}/${NOTICE_MULTI_DESTINY}/:id`;
export const NOTICE_BETWEEN_CITIES_PUBLIC_PATH = `${SERVICES_LANDING_PATH}/${NOTICE_BETWEEN_CITIES}/:id`;
export const NOTICE_AUCTION_PUBLIC_PATH = `${SERVICES_LANDING_PATH}/${NOTICE_AUCTION}/:id`;
export const NOTICE_MOVING_PUBLIC_PATH = `${SERVICES_LANDING_PATH}/${NOTICE_MOVING}/:id`;
export const PROFILE_NOTICE_DINAMIC_PUBLIC_PATH = `${SERVICES_LANDING_PATH}/:type-notice/:id`;

export const NOT_FOUND = '/no-encontrado';
export const NO_ACCESS = '/sin-acceso';

export const NOT_FOUND_PAGE = '*';

export const NO_ACCESS_CLIENT = '/mensaje-clientes';
export const NO_ACCESS_DRIVER = '/mensaje-transportista';
export const NOT_AUTHORIZED = '/mensaje-no-autorizado';
// ======================================================================
//            Acuerdos por aceptar de usuario y transportista
// ======================================================================
export const MY_PENDING_AGREEMENTS = '/mis-acuerdos-pendientes';
