import { Container, styled } from "@mui/material";

export const ContainerMultiOutletStyle = styled(Container)(
  ({ theme }) => `
    padding: 16px;
      @media only screen and (min-width: 768px){
        padding: 32px 64px;
      }
    `
);
