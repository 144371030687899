import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography } from "@mui/material";
import {
  ButtonContainer,
  CardBoxMobile,
  ContainerMobile,
  miniIcons,
  tabHeigh,
} from "./StyleFilter";
import { FormDataFilter } from "./consts/filtersConst";
import { handleDateAdapter, handleTextAdapter } from "./utils/adapter";
import { characterCut } from "@/utils/helpers";
import { maxLengthText } from "@/consts/noticeConsts";

interface Props {
  buttonCLick: React.Dispatch<React.SetStateAction<boolean>>;
  formData: FormDataFilter;
}



const MobileSelectedOptions = (props: Props) => {
  const { buttonCLick, formData } = props;

  const handleData = () => {
    const newFormData = handleDateAdapter(formData);
 

    const val = Object.entries(newFormData).map((entry) => {
      if (entry[1] === "Por horas") {
        return {
          text: entry[1],
          icon: miniIcons.hours,
        };
      } else if (entry[1] === "Por puntos") {
        return {
          text: entry[1],
          icon: miniIcons.point,
        };
      }

      return {
        text: entry[1],
        icon: miniIcons[entry[0]],
      };
    });

    return val;
  };

  const handleStyles = (icon: string) => {
    let value: { fontWeight: number | string } = { fontWeight: 400 };
    const isOrigin = icon === miniIcons.origin;
    const isDestiny = icon === miniIcons.destiny;

    if (isOrigin || isDestiny) value = { fontWeight: 600 };

    return value;
  };

  return (
    <>
      <Box sx={{ height: `${tabHeigh + 20}px` }}></Box>
      <ContainerMobile sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          onClick={() => buttonCLick(true)}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            p: "none",
            flexDirection: "column",
          }}
        >
          {!!formData &&
            handleData()?.map((item, i) => (
              <CardBoxMobile key={i}>
                {item.text && item.icon ? (
                  <>
                    <i className={item.icon} style={{ marginRight: "4px" }}></i>
                    <Typography
                      sx={{
                        opacity: 0.9,
                        color: "#424242",
                        display: "flex",
                        ...handleStyles(item.icon),
                      }}
                    >
                      {characterCut(
                        handleTextAdapter(item.text as string | string[]),
                        maxLengthText
                      )}
                    </Typography>
                  </>
                ) : (
                  <></>
                )}
              </CardBoxMobile>
            ))}
        </Box>
        <Box
          onClick={() => buttonCLick(true)}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <CardBoxMobile>
            <ButtonContainer sx={{ pb: "0px" }}>
              <Box sx={{ display: "flex", cursor: "pointer" }}>
                <ExpandMoreIcon sx={{ fontSize: "24px" }} />
              </Box>
            </ButtonContainer>
          </CardBoxMobile>
        </Box>
      </ContainerMobile>
    </>
  );
};

export default MobileSelectedOptions;
