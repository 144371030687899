import StandardAlert from "@/components/alerts/StandardAlert";

const AlertIsOwner = ({ message }: { message: string }) => {
  if (!message) return <></>;

  return (
    <StandardAlert
      severity="warning"
      color="warning"
      sx={{ marginBottom: "16px" }}
    >
      {message}
    </StandardAlert>
  );
};

export default AlertIsOwner;
