import { GET_RATING_AGREEMENT_DATA, GET_USER_HISTORY_RATING_DATA, SEND_RATING_AGREEMENT_DATA } from "@/consts/pathServices";
import { IGetRatingProps, IRatingAgreementResponseAPI, IRatingAgreementSend } from "@/models/ratingAgreement";
import { IRatingUserHistoryAPI } from "@/models/ratingAgreement/UserHistoryRatingModel";
import { connection, instanceAuth } from "./connection";

const API = connection(instanceAuth);

export const getRatingAgreementDataServices = async ({
  idAviso = 0,
  idSolicitud = 0,
}: IGetRatingProps): Promise<IRatingAgreementResponseAPI> => {
  const path = GET_RATING_AGREEMENT_DATA.replace(":idAviso", idAviso.toString()).replace(":idSolicitud", idSolicitud.toString());
  const { data } = await API.get(path);

  return data;
};

export const sendRatingAgreementDataServices = async (body: IRatingAgreementSend): Promise<any> => {
  const { data } = await API.put(SEND_RATING_AGREEMENT_DATA, body);
  return data;
};

export const getUserHistoryRatingDataService = async (idUser: number): Promise<IRatingUserHistoryAPI> => {
  const path = GET_USER_HISTORY_RATING_DATA.replace(":idUser", idUser.toString());
  const { data } = await API.get(path);
  return data;
}