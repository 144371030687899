import { IconTypes } from '@/utils/Icon';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';

interface IConfigDetailTypeFlow {
  icon: IconTypes;
  iconPoints?: IconTypes;
  iconInCity?: IconTypes;
}

export const configDetailTypeFlow: Record<number, IConfigDetailTypeFlow> = {
  1: {
    icon: "icon-inCity",
  }, // TODO: FALTA EL 2
  2: {
    icon: 'icon-multiDestiny',
    iconPoints: 'icon-multiDestiny',
  },
  3: {
    icon: 'icon-betweenCities',
  },
  4: {
    icon: "icon-auction",
  },
  5: {
    icon: 'icon-moving',
    iconInCity: 'icon-movingCities',
  },
};

export const configDetailTypeNoticeFlow: Record<number, any> = {
  1: {
    icon: LocationCityOutlinedIcon,
  },
  2: {
    icon: 'icon-watch-dense',
    iconPoints: 'icon-deliveries-dense',
  },
  3: {
    icon: 'icon-inter-city--white',
  },
  4: {
    icon: 'icon-percentaje-medium',
  },
  5: {
    icon: 'icon-car-medium',
    iconInCity: 'icon-home',
  },
};

export const configDataTitles: Record<number, any> = {
  1: {
    title: 'En la ciudad',
  },
  2: {
    title: 'Multidestino',
  },
  3: {
    title: 'Entre ciudades',
  },
  4: {
    title: 'Servicio express',
  },
  5: {
    title: 'Mudanza',
  },
};
