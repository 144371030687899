import { useAppSelector } from "@/hooks/useReducer";
import { useEffect, useState } from "react";
import { objectRenderComponent } from "./config";
import { ISolicitudNegociacion } from "@/services/interfaces/RequestNegResponseSuccess";

const RequestNegSuccessBody = () => {
  const [adData, setAdData] = useState<ISolicitudNegociacion | null>(null);

  const { noticeNegotationRequest: {requestNegResponseSuccess} } = useAppSelector((state) => state);

  useEffect(() => {
    if(!!requestNegResponseSuccess){
      setAdData(requestNegResponseSuccess);
    }
  }, [requestNegResponseSuccess]);

  if (!adData) return <></>;

  const Component = objectRenderComponent[(adData as ISolicitudNegociacion)?.solicitud?.tipoSolicitud?.id] 
  if (!Component) return <></>;

  return <Component />;
};

export default RequestNegSuccessBody;
