import StandardAlert from "@/components/alerts/StandardAlert";
import IdentificationDocument from "@/components/IndentificationDocument/IdentificationDocument";
import BannerModal from "@/components/modals/BannerModal/BannerModal";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import useResize from "@/hooks/useResize";
import HeaderProfile from "@/pages/profile/headerProfile/HeaderProfile";
import { setErrorMsg, setSuccessMsg } from "@/store/userRedux/userSlice";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const Identidad = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // validate if the data is empty and show the modal
  const [dataIsEmpty, setDataIsEmpty] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState({
    title: "",
    message: "",
    ok: "",
    cancel: "",
  });
  const [, isMobil] = useResize();
  const { errorMsg, successMsg, isLoading } = useAppSelector(
    (state) => state.user
  );

  const timerClean = useRef<any>(null);
  const timerClean2 = useRef<any>(null);

  useEffect(() => {
    if (errorMsg && errorMsg !== "") {
      timerClean.current = setTimeout(() => {
        dispatch(setErrorMsg(""));
      }, 15000);
    }
    if (successMsg && successMsg !== "") {
      timerClean2.current = setTimeout(() => {
        dispatch(setSuccessMsg(""));
      }, 15000);
    }

    return () => {
      clearTimeout(timerClean.current);
      clearTimeout(timerClean2.current);
    };
  }, [errorMsg, successMsg]);

  const handleDataIsEmpty = (isEmpty: boolean) => {
    setDataIsEmpty(isEmpty);
  };

  const navigateBack = () => {
    if (dataIsEmpty) {
      setShowAlert(true);
      setMessageAlert({
        title: "Cambios sin guardar",
        message: "Aún no has guardado los cambios realizados. ¿Deseas salir?",
        ok: "SI, SALIR",
        cancel: "NO, QUEDARME",
      });
    } else {
      navigate(-1);
    }
  };
  return (
    <>
      <HeaderProfile
        title="Licencia o DNI"
        sx={{
          width: "360px",
        }}
        backAction={navigateBack}
      >
        {errorMsg && !isLoading && (
          <StandardAlert
            sx={{ marginBottom: "16px" }}
            severity="error"
            color="error"
            onClick={() => {
              dispatch(setErrorMsg(""));
            }}
          >
            {errorMsg}
          </StandardAlert>
        )}
        {successMsg && !isLoading && (
          <StandardAlert
            sx={{ marginBottom: "16px" }}
            severity="success"
            color="success"
            onClick={() => {
              dispatch(setSuccessMsg(""));
            }}
          >
            {successMsg}
          </StandardAlert>
        )}
        <BannerModal
          open={showAlert}
          onClose={() => setShowAlert(false)}
          title={messageAlert.title}
          content={messageAlert.message}
          option1={{
            title: messageAlert.cancel,
            onClick: () => setShowAlert(false),
          }}
          option2={{
            title: messageAlert.ok,
            onClick: () => navigate(-1),
          }}
          color="org"
          sx={{
            width: "322px",
            height: "244px",
          }}
          sxAccion={{
            padding: "0px",
          }}
        />
        <IdentificationDocument
          handleClick={() => {
            if (isMobil) {
              navigate(-1);
            }
          }}
          buttonLabel="Guardar"
          dataIsEmpty={handleDataIsEmpty}
        />
      </HeaderProfile>
    </>
  );
};

export default Identidad;
