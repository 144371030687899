import {
  ID_STATUS_NEGOCIATION_SOLICITUD,
  ID_STATUS_NEGOCIATION_AVISOS,
} from '@/consts/defaultConsts';

const rechazoTemplate = `Rechazada el :date a las :dateTime`;
const retiroTemplate = `Retirada el :date a las :dateTime`;
const anuladoTemplate = `Anulada el :date a las :dateTime`;
const canceladoTemplate = `Cancelada el :date a las :dateTime`;

const templateAviso: { [key: number]: string } = {
  [ID_STATUS_NEGOCIATION_AVISOS.RECHAZADO_TRANSPORTISTA]: rechazoTemplate,
  [ID_STATUS_NEGOCIATION_AVISOS.RECHAZADO_CLIENTE]: rechazoTemplate,
  [ID_STATUS_NEGOCIATION_AVISOS.RETIRADA_TRANSPORTISTA]: retiroTemplate,
  [ID_STATUS_NEGOCIATION_AVISOS.RETIRADA_CLIENTE]: retiroTemplate,
  [ID_STATUS_NEGOCIATION_AVISOS.ANULADO_TRANSPORTISTA]: anuladoTemplate,
  [ID_STATUS_NEGOCIATION_AVISOS.ANULADO_CLIENTE]: anuladoTemplate,
};

const templatesSolicitud: { [key: number]: string } = {
  [ID_STATUS_NEGOCIATION_SOLICITUD.RECHAZADO_TRANSPORTISTA]: rechazoTemplate,
  [ID_STATUS_NEGOCIATION_SOLICITUD.RECHAZADO_CLIENTE]: rechazoTemplate,
  [ID_STATUS_NEGOCIATION_SOLICITUD.RETIRADA_TRANSPORTISTA]: retiroTemplate,
  [ID_STATUS_NEGOCIATION_SOLICITUD.RETIRADA_CLIENTE]: retiroTemplate,
  [ID_STATUS_NEGOCIATION_SOLICITUD.ANULADO_TRANSPORTISTA]: anuladoTemplate,
  [ID_STATUS_NEGOCIATION_SOLICITUD.ANULADO]: anuladoTemplate,
  [ID_STATUS_NEGOCIATION_SOLICITUD.CANCELADA_TRANSPORTISTA]: rechazoTemplate,
  [ID_STATUS_NEGOCIATION_SOLICITUD.CANCELADA_CLIENTE]: canceladoTemplate,
};

type TNegociation = 'solicitud' | 'aviso';

export const getTemplateDeclined = (
  status: number,
  fecha: string,
  hora: string,
  type: TNegociation
): string => {
  const template =
    type === 'solicitud' ? templatesSolicitud[status] : templateAviso[status];

  return template
    ?.replace(':date', fecha || '')
    ?.replace(':dateTime', hora || '');
};
