import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { currencyFormat } from "@/utils/helpers";
import { Box, Typography } from "@mui/material";
import { handleTypeMultidestinyOfferNotice } from "../../../counterOfferDetails/containers/MultidestinyNoticeV2Neg/components/config";
import { SuccessPrice } from "../../components/SuccessGlobalPrice";

export const MultidestinySucessPrice = () => {
  const { responseData } = useGetData();

  const newData = handleTypeMultidestinyOfferNotice(
    responseData as NoticeV2NegType
  );

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <SuccessPrice />
        <Typography sx={{ fontSize: "14px", ml: 1 }}>
          {`Por ${newData.type}`}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
          {`Cantidad por ${newData.type}s:`}
        </Typography>
        <Typography sx={{ fontSize: "16px", ml: 1 }}>
          {`${currencyFormat(newData.amount, false)}`}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
          Precio total:
        </Typography>
        <Typography sx={{ fontSize: "16px", ml: 1 }}>
          {currencyFormat(newData.total || 0)}
        </Typography>
      </Box>
    </>
  );
};
