import Icon, { IconTypes } from "@/utils/Icon";
import { Box, SxProps, Theme, Typography } from "@mui/material";

export interface OptionsDetailListProps {
  icon: string;
  title: string;
  show: boolean;
  listData: string[];
  sx?: SxProps<Theme>;
}

const OptionsDetailList = (props: OptionsDetailListProps) => {
  const { icon, listData, show, title, sx } = props;
  if (!show) return <></>;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", ...sx }}>
      <Box sx={{ display: "flex" }}>
        <Icon name={icon as IconTypes} size="md" />
        <Typography variant="label1" sx={{ marginLeft: "8px" }}>
          {title}
        </Typography>
      </Box>

      <Box sx={{ paddingLeft: "8px" }}>
        <ul style={{ margin: "0px" }}>
          {listData.map((item, i) => (
            <li>
              <Typography
                key={i}
                variant="body1"
                sx={{ wordWrap: "break-word", maxWidth: "320px" }}
              >
                {item}
              </Typography>
            </li>
          ))}{" "}
        </ul>
      </Box>
    </Box>
  );
};

export default OptionsDetailList;
