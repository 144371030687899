import { optionOne, optionTwo } from "@/consts/noticeConsts";
import { UltimaAvisoNegociacionAcciones } from "@/models/notice/noticeV2NegotationModels";

import { CounterOfferButtons } from "./CounterOfferButtons";
import { OfferButtons } from "./OfferButtons";
import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { DisabledOfferButtons } from "@/pages/noticeV2Negotation/components/disabledBtn/DisabledOfferBtn";
import { DisabledCounterOfferBtn } from "@/pages/noticeV2Negotation/components/disabledBtn/DisabledCounterOfferBtn";

const buttonsToRender: Record<number, () => JSX.Element> = {
  [optionOne]: OfferButtons,
  [optionTwo]: CounterOfferButtons,
};

const DisabledbuttonsToRender: Record<number, () => JSX.Element> = {
  [optionOne]: DisabledOfferButtons,
  [optionTwo]: DisabledCounterOfferBtn,
};

export const GlobalButtonsNegV2 = () => {
  const { notice } = useGetData();
  const { tipoAvisoNegociacionAccion } =
    notice?.ultimaAvisoNegociacionAcciones as UltimaAvisoNegociacionAcciones;
  const typeNoticeId = tipoAvisoNegociacionAccion?.id as number;

  const Component = buttonsToRender[typeNoticeId];
  const DisableButtons = DisabledbuttonsToRender[(notice as NoticeV2NegType).idTipoAvisoNegociacion]
  if (!Component) return <DisableButtons />;

  return <Component />;
};
