import { useAppSelector } from '@/hooks/useReducer';
import { ISolicitudNegociacion } from '@/services/interfaces/RequestNegResponseSuccess';
import React from 'react'
import { objectRenderComponentFinishedAccept } from './config';

const RenderBodyNegAccept = () => {
    const { noticeNegotationRequest: { errorCode, isLoading: loading, savedDataNogotation } } = useAppSelector((state) => state);
    const {
        solicitud: {
            tipoSolicitud: {
                id: idSolicitud,
            }
        }
    } = savedDataNogotation as ISolicitudNegociacion;
    const Component = objectRenderComponentFinishedAccept[idSolicitud];
  if (!Component) return <></>;
  return <Component />;
}

export default RenderBodyNegAccept
