import { BASE_URL_IMAGE_S3 } from "@/config/config";
import {
  DEFAULT_ERROR_LOAD_IMAGE,
  TEXT_ERROR_IMAGE_GENERIC,
} from "@/consts/textErrors";
import useResizeImage from "@/hooks/useResizeImage";
import { convertImageHeic, validatorExtensionImage } from "@/utils/helpers";
import { Avatar, CircularProgress, Stack, styled } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import ButtomLoader from "../form/buttonLoader/ButtomLoader";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

const Container = styled(Stack)(
  () => `
    max-width: 100%;
    height: 208px;
    position: relative;

    background-origin: content-box;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  `
);

type Props = {
  imageDefault: string | any;
  onError: (e: Error) => void;
  onSelect: (image: string | File) => void;
  isAvatar?: boolean;
};

const DropZone: FC<Props> = ({ imageDefault, onError, onSelect, isAvatar }) => {
  const [ImagePath, setImagePath] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);

  const { handleImageFileResizer } = useResizeImage();

  useEffect(() => {
    setImagePath(handleGetImageDefault());
    return () => {};
  }, []);

  const handleGetImageDefault = () => {
    let image = "";
    if (typeof imageDefault === "string") {
      image = BASE_URL_IMAGE_S3 + imageDefault;
      if (imageDefault?.startsWith("https://")) {
        image = imageDefault;
      }
    } else {
      return imageDefault;
    }

    return image;
  };

  const handlePrepareImage = async (acceptedFiles: File) => {
    if (!acceptedFiles.name.toLowerCase().includes(".heic")) {
      const currentFile = await handleImageFileResizer({ file: acceptedFiles });
      return currentFile;
    }

    const imagePng = await convertImageHeic(acceptedFiles);
    const currentFile = await handleImageFileResizer({ file: imagePng });

    return currentFile;
  };

  const onDrop = useCallback(async ([acceptedFiles]: File[]) => {
    setImagePath("");
    setIsLoading(true);
    let reader = new FileReader();

    if (!acceptedFiles) {
      handleOnError({
        message: TEXT_ERROR_IMAGE_GENERIC,
        name: "undefined",
      });
      return;
    }

    const currentFile = await handlePrepareImage(acceptedFiles);

    onSelect(currentFile);

    reader.readAsDataURL(currentFile);

    reader.onload = function () {
      const result = reader.result;
      setIsLoading(false);
      setImagePath(result);
    };

    reader.onabort = () => handleOnError(DEFAULT_ERROR_LOAD_IMAGE);
    reader.onerror = () => handleOnError(DEFAULT_ERROR_LOAD_IMAGE);
  }, []);

  const handleOnError = (e: Error) => {
    setImagePath(handleGetImageDefault());
    setIsLoading(false);
    !!onError && onError(e);
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
      "image/heic": [],
    },
    validator: validatorExtensionImage,
    onDrop,
    onError: handleOnError,
    maxFiles: 1,
    maxSize: 10000000,
  });

  return isAvatar ? (
    <Container
      {...getRootProps()}
      sx={{
        height: "100%",
      }}
    >
      <Avatar
        sx={{
          width: "111px",
          height: "111px",
          backgroundColor: "#BDBDBD",
          cursor: "pointer",
        }}
        src={ImagePath}
      >
        <PersonOutlineOutlinedIcon
            sx={{
              width: 60, 
              height: 60,
              color: "white",
            }}
        />
      </Avatar>
      <input {...getInputProps()} multiple={false} />
    </Container>
  ) : (
    <Stack
      sx={{
        display: "flex",
        justifyContent: "center",
        maxWidth: "100%",
        border: `1px solid ${isDragActive ? "#00B3E3" : "#CFD8DC"}`,
        borderRadius: "5px",
        boxShadow: "0px 4px 20px 0px #ECEFF1",
      }}
    >
      <Container
        {...getRootProps()}
        sx={{
          backgroundColor: `${isLoading ? "rgba(0, 0, 0, 0.2)" : ""}`,
          backgroundImage:
            typeof ImagePath === "string" ? `url(${ImagePath})` : "",
        }}
      >
        {ImagePath && typeof ImagePath !== "string" && <ImagePath />}
        <input {...getInputProps()} multiple={false} />

        {isLoading && !ImagePath && (
          <CircularProgress
            color="warning"
            size="40px"
            sx={{
              position: "absolute",
              top: "calc(50% - ( 40px / 2 ))",
              left: "calc(50% - ( 40px / 2 ))",
            }}
          />
        )}
      </Container>
      <Stack
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "16px 0",
          borderTop: "1px solid #CFD8DC",
        }}
      >
        <ButtomLoader
          sx={{ maxWidth: "216px" }}
          variant="outlined"
          onClick={open}
        >
          Subir o tomar foto
        </ButtomLoader>
      </Stack>
    </Stack>
  );
};

export default DropZone;
