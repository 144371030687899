import { FormControl, Grid, Typography } from '@mui/material';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  styled,
} from '@mui/material';

export const ContainerModalV2 = styled(Grid)(
  ({ theme }) => `
      &::-webkit-scrollbar {
          width: .4rem;
      }
      &::-webkit-scrollbar-track {
          background-color: ${theme.palette.bgScrollBar.primary};
      }
      &::-webkit-scrollbar-thumb {
          background-color: ${theme.palette.bgScrollBar.secondary};
          border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:active {
        background-color: ${theme.palette.bgScrollBar.disabled};
      }
      overflow-y: auto;
      padding: 16px;
      height: 100%;
      margin-bottom: 92px;
      & .MuiFormControl-root {
        width: 100%;
      }
    `
);

export const ContainerModalMultiShopV2 = styled(ContainerModalV2)(
  () => `
      margin-bottom: 0;
      flex: 8;
      flex-direction: column;
      display: flex;
    `
);

export const ContainerModalButtomMultiShopV2 = styled(ContainerModalV2)(
  () => `
      margin-bottom: 0;
      align-items: flex-end;
      display: flex;
      flex: 1;
    `
);

export const AccordionContainerV2 = styled(Accordion)(() => ({
  '&:before': {
    backgroundColor: 'transparent',
  },
}));

export const AccordionTitleV2 = styled(AccordionSummary)(() => ({
  height: '56px',
  '&:hover': {
    backgroundColor: '#EFECFF',
  },
}));

export const AccordionContentV2 = styled(AccordionDetails)(() => ({
  padding: 0,
}));

export const ListAccordionV2 = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const ItemAccordionV2 = styled(FormControlLabel)(() => ({
  margin: '0px 0px 0px 46px',
  '&:hover': {
    backgroundColor: '#EFECFF',
  },
  height: '42px',
}));

export const StyledCheckboxV2 = styled(Checkbox)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.org.main,
  },
}));

export const ContainerV2 = styled(Grid)(
  () => `
      display: flex;
      flex-direction: column;  
      margin-top: 24px;
    `
);

export const SpanRequiredV2 = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.error.main};
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
  `
);

export const FormControlInputV2 = styled(FormControl)(
  () => `
  padding: 16px 16px 0 16px;
  `
);

export const CustomFormControlV2 = styled(FormControl)(
  () => `
    width: 100%;
    height: 100%;
  `
);
