import SkeletonGroup from "@/components/skeletons/SkeletonGroup";
import { SkeletonSectionContainer } from "@/components/skeletons/styleSkeletons";
import useResize from "@/hooks/useResize";

const SkeletonNoticeList = () => {
  const [, isMobil] = useResize();
  return (
    <>
      {isMobil ? (
        <SkeletonSectionContainer>
          <SkeletonGroup list={5} height={90} />
        </SkeletonSectionContainer>
      ) : (
        <SkeletonSectionContainer>
          <SkeletonGroup list={5} height={300} width={212} direction="row" />
        </SkeletonSectionContainer>
      )}
    </>
  );
};

export default SkeletonNoticeList;
