import { actionsToNegotation } from "@/consts/actions";
import { CLIENT_TYPE } from "@/consts/defaultConsts";
import {
  NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_CLIENT,
  NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_DRIVER,
} from "@/consts/negotationFlowPath";
import { maxLengthPrice, maxMultidestinyRequest, optionFive, optionOne, optionTwo, textCannotLowerPrice, textCannotUpperPrice } from "@/consts/noticeConsts";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import { counterOfferClientAdapter } from "@/pages/noticeRequestFlow/adapters/noticeRequestFlowAdapters";
import { defaultError } from "@/pages/noticeRequestFlow/consts/noticeRequestFlowConsts";
import { useButtonsFunctions } from "@/pages/noticeRequestFlow/hooks/useHandleGlobalButtons";
import { useGetRequestData } from "@/pages/requestNegotiation/shared/hooks/useGetRequestData";
import { cocounterRequest } from "@/services/dataLayer/createRequestNeg";
import { BodyUploadPriceNegotationPrice } from "@/services/noticeNegotationRequestServices";
import {
  saveRequestNogotationByIdThunk,
  updatePriceNegotationThunk,
} from "@/store/noticeNegotationRequestRedux/noticeNegRequesThunk";
import { setError } from "@/store/noticeNegotationRequestRedux/noticeNegRequestSlice";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { currencyFormat } from "@/utils/helpers";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const useFormClientCounterOfferMultidestiny = () => {
  const { data } = useGetRequestData();
  const { handleManageSnackbar } = useButtonsFunctions();
  const [typeOfMultidestiny, setTypeOfMultidestiny] = useState("");
  const [preciocontraoferta, setPreciocontraoferta] = useState("");
  const { notiffication } = useNotiffication();
  const [errorPrice, setErrorPrice] = useState("");
  const { idRequest = "" } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    user: { user },
    noticeNegotationRequest: {
      isLoading,
      selectedNegotationTypeRequest,
      error,
      isEdition,
    },
  } = useAppSelector((state) => state);

  const {
    id,
    precio,
    precioContraoferta,
    solicitud: { horasRequeridas, puntosRequeridos, flgHoraoPunto, tipoSolicitud },
  } = data as SolicitudNegociacion;
  const idAccion = actionsToNegotation[selectedNegotationTypeRequest.id].id;
  const requestNoticeId = id;
  const hourOrPoint = flgHoraoPunto === optionOne ? true : false;
  const amount = hourOrPoint ? (horasRequeridas as number) : (puntosRequeridos as number);
  const isClient = (user as IUser).tipousuario === CLIENT_TYPE;
  const userId = (user as IUser).id;
  const typeNegotation = tipoSolicitud?.nombre as string;
  const { handleModalPriceChanged } = useButtonsFunctions();

  useEffect(() => {
    let typeMultidestiny = "hora";

    if (isEdition) {
      setPreciocontraoferta(
        `${precioContraoferta}`
      );
    }

    if (flgHoraoPunto === optionTwo) {
      typeMultidestiny = "punto";
    }
    setTypeOfMultidestiny(typeMultidestiny);
  }, []);

  useEffect(() => {
    if (error) {
      handleManageSnackbar();
    }
  }, [error]);

  useEffect(() => {
    if (!notiffication) {
      dispatch(setError(defaultError));
    }
  }, [notiffication]);

  const handleSubmit = (event: EventType["submit"]) => {
    event.preventDefault();
    const priceCounterOffer =   Number(preciocontraoferta)
    const dataAdapter = counterOfferClientAdapter(
      idAccion,
      priceCounterOffer
    );

    const body = {
      ...dataAdapter,
      precioactual: precio,
    };

    if(priceCounterOffer < optionFive){
      setErrorPrice(textCannotLowerPrice)
      return
    }

    if(priceCounterOffer > maxMultidestinyRequest){
      setErrorPrice(textCannotUpperPrice)
      return
    }

    dispatch(saveRequestNogotationByIdThunk(`${requestNoticeId}`, body)).then(
      (value) => {
        if (!!value.flgpreciocambiado) {
          handleModalPriceChanged(() => handleGoBackPage());
        } else {
          cocounterRequest(userId, typeNegotation);
          handleNextRute();
        }
      }
    ); 
  };

  const handleNextRute = () => {
    const valueToReplace = typeNegotation[2];
    const idValueToReplace = `${requestNoticeId}`;

    dispatch(
      getNextRoute(navigate, {
        valueToSearch: ":typeRequest",
        valueToReplace,
        idToSearch: ":idRequest",
        idValueToReplace,
      })
    );
  };

  const handleDisabledButton = () => {
    const conditions = [preciocontraoferta];

    return conditions.some((condition) => !condition);
  };

  const handleOnchange = ({ currentTarget }: EventType["change"]) => {
    const { value } = currentTarget;
    setErrorPrice("")
    const prepareValue = value.replace(/^(0+)/g, "").replace(/[^0-9]/g, "");

    if (prepareValue.length <= maxLengthPrice) {
      setPreciocontraoferta(prepareValue);
    }
  };

  const handleCompareInputWithDB = () => {
    return (
      preciocontraoferta ===
      `${precioContraoferta}`
    );
  };

  const handleDisabledEditCounterOfferButton = () => {
    const conditions = [preciocontraoferta];

    if (handleCompareInputWithDB()) return true;

    return conditions.some((condition) => !condition);
  };

  const handleEditCounterOfferSubmit = (event: EventType["submit"]) => {
    event.preventDefault();
    if (isLoading) return;

    const body: BodyUploadPriceNegotationPrice = {
      precio: Number(preciocontraoferta),
    };
    const pathClient =
      NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_CLIENT.replace(
        ":typeOffer",
        "contraoferta"
      ).replace(":typeRequest", idRequest);
    const pathDriver =
      NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_DRIVER.replace(
        ":typeOffer",
        "contraoferta"
      ).replace(":typeRequest", idRequest);

    const path = isClient ? pathClient : pathDriver;
    dispatch(updatePriceNegotationThunk(idRequest, body)).then(() =>
      navigate(path, { replace: true })
    );
  };

  const handleGoBackPage = () => {
    navigate(-1);
  };

  const typeMulti =
    typeOfMultidestiny.charAt(0).toUpperCase() + typeOfMultidestiny.slice(1);

  const handleTypeMultiAmount = !!puntosRequeridos
    ? `${puntosRequeridos as number}`
    : `${horasRequeridas as number}`;

  const handleTotalPrice = () => {
    const totalPrice =
      Number(preciocontraoferta) * Number(handleTypeMultiAmount);
    return !!totalPrice
      ? currencyFormat(
          Number(preciocontraoferta) * Number(handleTypeMultiAmount)
        )
      : "-";
  };

  return {
    errorPrice,
    isLoading,
    isEdition,
    handleSubmit,
    handleNextRute,
    handleDisabledButton,
    handleOnchange,
    handleCompareInputWithDB,
    handleDisabledEditCounterOfferButton,
    handleEditCounterOfferSubmit,
    handleGoBackPage,
    handleTotalPrice,
    amount,
    typeMulti,
    handleTypeMultiAmount,
    typeOfMultidestiny,
    preciocontraoferta,
  };
};
