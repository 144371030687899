import Input from "@/components/inputCustom/Input";
import { EventType } from "@/interfaces/globalInterface";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import {
  FormRuteInputsProps,
  InputProps,
} from "@/pages/flowNoticeNegotation/interfaces/interfaces";
import { Box, FormControl, Typography } from "@mui/material";
import { FC } from "react";

const FormRuteInputs: FC<FormRuteInputsProps> = (props) => {
  const {
    title,
    titleValue,
    input,
    onChange,
    onClick,
    multiline = true,
    subtitle,
    subtitleValue,
    errorPrice,
  } = props;

  const handleChange = (e: EventType["change"]) => {
    onChange && onChange(e);
  };

  const handleClick = (inputProp: InputProps) => (e: EventType["click"]) => {
    onClick && onClick(e, inputProp);
  };

  return (
    <>
      <Typography variant="subtitle1" marginTop="16px">
        {!!titleValue ? `${title}: ${titleValue}` : `${title}`}
      </Typography>
      {!!subtitle && (
        <Box sx={{ marginTop: "8px", display: "flex", alignItems: "center" }}>
          <Typography variant="subtitle2" sx={{  fontWeight: "bold"}}>{subtitle}:</Typography>
          <Typography sx={{ml: 1}}>{`S/ ${subtitleValue}`}</Typography>
        </Box>
      )}

      <FormControl sx={{ marginTop: "16px" }} fullWidth>
        <Input
          name={input.name}
          label={<LabelInputRequired str={input.label} />}
          variant="standard"
          value={input.value}
          placeholder={input.placeholder}
          multiline={multiline}
          rows={2}
          onChange={handleChange}
          onClick={handleClick(input)}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errorPrice}
          helperText={errorPrice}
        />
      </FormControl>
    </>
  );
};

export default FormRuteInputs;
