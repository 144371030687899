import { useAppSelector } from "@/hooks/useReducer";
import PlaceMovingBetweenCities from "./PlaceMovingBetweenCities";
import PlaceMovingInCity from "./PlaceMovingInCity";


const usePlaceMovingHook = () => {
  const {
        noticeV2: { variantMoving },
    } = useAppSelector((state) => state);

    const Component = objectRenderComponent[variantMoving || 2];
  
    return {
        Component,
    }

}

export default usePlaceMovingHook;


const objectRenderComponent: Record<number, () => JSX.Element> = {
    1: PlaceMovingInCity,
    2: PlaceMovingBetweenCities
  };