import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import { CustomCardV2 } from "@/pages/noticeV2Negotation/shared/styles";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { currencyFormat } from "@/utils/helpers";
import { Box, Grid, Typography } from "@mui/material";
import {
  handleDataToRender,
  handleTypeMultidestiny,
} from "../../../counterOfferDetails/containers/MultidestinyNoticeV2Neg/components/config";
import { NoticeDetailMultiDestinyModel } from '@/models/notice/noticeV2NegotationModels';

export const PriceMultidestinyCoCounter = () => {
  const { notice } = useGetData();
  const data = notice as NoticeV2NegType;
  const noticeData = handleTypeMultidestiny(data);

  const counterOfferPrice = (notice as NoticeV2NegType)
    ?.preciocontraoferta as number;
  const typeMultiCapitalize =
    noticeData.type.charAt(0).toUpperCase() + noticeData.type.slice(1);

  const firstCards = handleDataToRender({ noticeData, data: data as NoticeDetailMultiDestinyModel, changeColor: true});
  const dataToRender = [
    ...firstCards,
    {
      columnTitleSx: { fontSize: "12px" },
      clumnSubtitleSx: { fontSize: "14px", fontWeight: 700 },
      title: {
        name: "Contraoferta",
        sx: { fontWeight: 600 },
      },
      columnOne: {
        title: `Precio x ${noticeData.type}`,
        subtitle: `${currencyFormat(counterOfferPrice)}`,
      },
      columnTwo: {
        title: `# ${typeMultiCapitalize}s`,
        subtitle: `${noticeData.amount}`,
      },
      columnThree: {
        title: `Precio total`,
        subtitle: `${currencyFormat(
          Number(noticeData.amount) * counterOfferPrice
        )}`,
      },
    },
  ];

  return (
    <>
      {dataToRender.map((item, i) => (
        <CustomCardV2 sx={{ mt: 2 }}>
          <Typography sx={item.title.sx}>{item.title.name}</Typography>
          <Grid container>
            <Grid xs={4}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={item.columnTitleSx}>
                  {item.columnOne.title}
                </Typography>
                <Typography sx={item.clumnSubtitleSx}>
                  {item.columnOne.subtitle}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={4}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={item.columnTitleSx}>
                  {item.columnTwo.title}
                </Typography>
                <Typography sx={item.clumnSubtitleSx}>
                  {item.columnTwo.subtitle}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={4}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={item.columnTitleSx}>
                  {item.columnThree.title}
                </Typography>
                <Typography sx={item.clumnSubtitleSx}>
                  {item.columnThree.subtitle}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CustomCardV2>
      ))}
    </>
  );
};
