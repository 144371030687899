import React from "react";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import {
  createTheme,
  ThemeProvider,
  Theme,
  useTheme,
} from "@mui/material/styles";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { fontFamily } from "@/styles/theme";

const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            // boxShadow: "-2px 3px 5px 0px rgba(0,0,0,0.08)",
            borderRadius: "4px",
            "--TextField-brandBorderColor": "#E0E0E0",
            "--TextField-brandBorderHoverColor": "#0047BA",
            "--TextField-brandBorderFocusedColor": "#0047BA",
            "& label.Mui-focused": {
              color: "rgba(0, 0, 0, 0.60)",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            "&::placeholder": {
              fontFamily,
              lineHeight: "20px",
              fontSize: "16px",
              color: "#9e9e9e",
              opacity: 1,
            },
            ":disabled": {
              cursor: "not-allowed",
            },
          },
          notchedOutline: {
            borderColor: "var(--TextField-brandBorderColor)",
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderHoverColor)",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderFocusedColor)",
            },
            [`&.Mui-disabled .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "#E0E0E0",
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            borderRadius: "4px",
            "&:before, &:after": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "&:before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
            ".MuiInputBase-input": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
              padding: "9px",
            },
          },
        },
      },
    },
  });

type Props = TextFieldProps & {
  icon?: string;
  positionIcon?: "end" | "start";
  error?: boolean;
};

const Input = ({
  icon,
  positionIcon = "start",
  InputProps,
  error,
  ...props
}: Props) => {
  const outerTheme = useTheme();

  const handleIcon = () => {
    if (icon) {
      return {
        startAdornment: (
          <InputAdornment position={positionIcon}>
            <i className={icon}></i>
          </InputAdornment>
        ),
      };
    }
    return {};
  };

  const handleErrorEndIcon = () => {
    if (!error) return null;
    return {
      endAdornment: (
        <InputAdornment position="end">
          <ErrorOutlineOutlinedIcon color="error" />{" "}
        </InputAdornment>
      ),
    };
  };

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <TextField
        error={error}
        InputLabelProps={{
          sx: {
            fontFamily: fontFamily,
            fontWeight: 600,
          },
        }}
        {...props}
        variant="outlined"
        InputProps={{
          ...handleIcon(),
          ...handleErrorEndIcon(),
          ...InputProps,
        }}
      />
    </ThemeProvider>
  );
};

export default Input;
