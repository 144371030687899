import { prepareLinkCustomerRequestAdapter } from "@/adapters/routesAdapters";
import SkeletonInfoCard from "@/components/infoCard/SkeletonInfoCard";
import NotResultFound from "@/components/notResultFound/NotResultFound";
import { USER_TOKEN_STORAGE } from "@/consts/defaultConsts";
import useObserver from "@/hooks/useObserver";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { APPLICATION_DETAIL_PATH } from "@/pages/customerRequest/shared/consts/routes";
import {
  IOrdenamiento,
  ISelectCity,
} from "@/store/FilterServices/filterReduxInterface";
import { setSelectedOrdenamiento } from "@/store/FilterServices/filterSlice";
import { getDataFiltersCustomerRequestServices } from "@/store/FilterServices/filterThunks";
import { getListCustomRequestFilterThunk } from "@/store/customerRequestRedux/customerRequestFilterThunk";
import { formatDate } from "@/utils/helpers";
import {
  Box,
  Chip,
  Container,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AutoCompleteSelectCity from "../components/autocompleteSelectCity/AutoCompleteSelectCity";
import DrawerListFilter from "../services/components/DrawerListFilter";

import CardFilter from "@/components/CardFilter";
import SkeletonCardRequest from "@/components/CardMyRequest/SkeletonCardRequest";
import Ilustration from "@/components/ilustration/Ilustration";
import { ID_CITY_LIMA } from "@/config/config";
import { SHOW_AFTER } from "@/consts/advertisement";
import AdvertisementListCard from "@/pages/advertisement/advertisementListCard/AdvertisementListCard";
import { getLocalStorage } from "@/services/localstorageServices";
import { setCleanHistoryList } from "@/store/advertisementRedux/advertisementSlice";
import {
  ButtonFilter,
  SecctionFilter,
  SectionListCardsFilter,
  SectionListFilter,
  SelectFilter,
  TextService,
} from "../services/styleServices";
import {
  convertArrayToObjectString,
  handleDeleteValue,
} from "../services/utils/handleCheck";
import useRequestFilterHook from "./hook";

const Requests = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [firstRender, setFirstRender] = useState(true);

  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const {
    filter: { selectedCity, tiposFiltro, ordenamiento, selectedOrdenamiento },
    customerRequest: {
      solicitudes,
      isLoading,
      currentPage,
      totalPages,
      isLoadingPagination,
    },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const existToken = getLocalStorage(USER_TOKEN_STORAGE);
  const refObserver = useRef<any>(null);
  const { isNearScreen } = useObserver({
    distance: "100px",
    externalRef: refObserver,
  });

  // inicializador de los filtros
  useEffect(() => {
    return () => {
      dispatch(setCleanHistoryList());
    };
  }, []);

  useEffect(() => {
    // Borra las publicidades que alguna busqueda anterior!!
    dispatch(setCleanHistoryList());
  }, [search]);

  useEffect(() => {
    const cityID = (selectedCity as ISelectCity)?.id;
    const default_lima = Number(ID_CITY_LIMA);

    dispatch(setCleanHistoryList());
    dispatch(getDataFiltersCustomerRequestServices(cityID || default_lima));

    if (Object.keys(selectedCity).length && cityID !== default_lima) {
      deleteFilterToChangeCity();
    }
  }, [selectedCity]);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    } else if (
      isNearScreen &&
      !isLoadingPagination &&
      currentPage < totalPages - 1 &&
      !isLoading
    ) {
      handlePaginate();
    }
  }, [isNearScreen]);

  const deleteFilterToChangeCity = () => {
    // borrar solo el origen , destino , origenCiudad , destinoCiudad
    const clearData = ["Origen", "Destino", "OrigenCiudad", "DestinoCiudad"];
    // obtener los filtros que no estan en clearData
    const res = tiposFiltro.filter((item) => !clearData.includes(item.nombre));
    const filtro = convertArrayToObjectString(res);
    setSearchParams(filtro);
  };

  const handleSubmitModal = () => {
    setShowModal(false);
  };
  //   la paginación.
  const handlePaginate = () => {
    const body = {
      page: currentPage + 1,
      size: 12,
      ordenamiento: selectedOrdenamiento as IOrdenamiento,
      tiposFiltro,
      pathname,
    };

    dispatch(getListCustomRequestFilterThunk(body));
  };
  //  para limpiar los filtros
  const handleClearFilter = (
    value: string | string[],
    index?: number,
    isNombre?: boolean
  ) => {
    handleDeleteValue(value, tiposFiltro, isNombre).then((res) => {
      const filtro = convertArrayToObjectString(res);
      setSearchParams(filtro);
    });
  };

  const handleSelectOrdenamiento = (e: IOrdenamiento) => {
    dispatch(setSelectedOrdenamiento(e));
  };

  const chipsNames = (item: IOrdenamiento, item2: string): string => {
    if (item.nombre === "Origen" || item.nombre === "OrigenCiudad") {
      return `De ${item2}`;
    }
    if (item.nombre === "Destino" || item.nombre === "DestinoCiudad") {
      return `A ${item2}`;
    }
    return item2;
  };
  const handleNavigate = (idTypeRequest: number, idCustomer: number) => {
    const path = prepareLinkCustomerRequestAdapter(
      APPLICATION_DETAIL_PATH,
      idTypeRequest,
      idCustomer?.toString() || ""
    );

    navigate(path, { state: { hasBackNavigate: true } });
  };

  const { adapterCardFilter } = useRequestFilterHook();

  return (
    <Container maxWidth="xl" sx={{ marginTop: "24px" }}>
      {!!existToken && (
        <>
          <DrawerListFilter
            open={showModal}
            onClose={setShowModal}
            onSubmit={handleSubmitModal}
          />
          <TextService variant="h6">
            Solicitudes de transporte en
            <AutoCompleteSelectCity />
          </TextService>
          <SecctionFilter>
            <ButtonFilter
              variant="outlined"
              color="primary"
              startIcon={<i className="icon-medium-filter" />}
              size="small"
              onClick={() => setShowModal(true)}
            >
              Filtros
            </ButtonFilter>
            <Typography variant="body2">
              Ordenar:
              <SelectFilter
                labelId="demo-simple-select-label"
                id="12input"
                value={
                  (selectedOrdenamiento as IOrdenamiento)?.nombre ||
                  "Más recientes"
                }
                size="small"
              >
                {ordenamiento &&
                  ordenamiento.map((item: IOrdenamiento) => (
                    <MenuItem
                      value={item.nombre}
                      key={`${item.nombre}-MenuItem`}
                      onClick={() => handleSelectOrdenamiento(item)}
                    >
                      {item.nombre}
                    </MenuItem>
                  ))}
              </SelectFilter>
            </Typography>
          </SecctionFilter>
          <SectionListFilter>
            {tiposFiltro.map((item, index) =>
              item.nombre === "Fecha de aviso" ? (
                <Chip
                  key={`${item.nombre}-${index}`}
                  label={`Del ${formatDate(item.opciones[0])} al ${formatDate(
                    item.opciones[1]
                  )}`}
                  onDelete={() => handleClearFilter(item.nombre, index, true)}
                  variant="outlined"
                  deleteIcon={<i className="icon-chip-deleted" />}
                />
              ) : (
                item.opciones.map((item2, index2) => (
                  <Chip
                    label={chipsNames(item, item2) as string}
                    key={`${item2}-${index2}`}
                    variant="outlined"
                    onDelete={() =>
                      handleClearFilter(
                        item2.toString(),
                        tiposFiltro.length - 1
                      )
                    }
                    deleteIcon={<i className="icon-chip-deleted" />}
                  />
                ))
              )
            )}
          </SectionListFilter>{" "}
        </>
      )}
      {!!existToken ? (
        <>
          <SectionListCardsFilter container spacing={2}>
            {solicitudes && !isLoading ? (
              <>
                {solicitudes &&
                  solicitudes?.map((item, index) => (
                    <AdvertisementListCard
                      key={`solicitudes-${index}`}
                      wrapperProps={{
                        xs: 12,
                        sm: 12,
                        md: 6,
                        lg: 4,
                      }}
                      index={index}
                      showAfter={SHOW_AFTER}
                      totalLength={solicitudes.length}
                    >
                      <CardFilter
                        {...adapterCardFilter(item)}
                        onClick={() =>
                          handleNavigate(item.idTipoSolicitud, item.id)
                        }
                      />
                    </AdvertisementListCard>
                  ))}

                <div ref={refObserver} />
              </>
            ) : (
              [...Array(12)].map((item, index) => <SkeletonCardRequest />)
            )}
            {solicitudes?.length === 0 && !isLoadingPagination && (
              <Grid item xs={12} sm={12} md={12}>
                <NotResultFound />
              </Grid>
            )}

            {isLoadingPagination &&
              [...Array(6)].map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <SkeletonInfoCard />
                </Grid>
              ))}
          </SectionListCardsFilter>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "120px",
          }}
        >
          <Ilustration />
        </Box>
      )}
    </Container>
  );
};

export default Requests;
