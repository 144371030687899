import { imageNotFound } from "@/assets/img";
import { IMG_PATH, MD_IMG } from "@/consts/defaultConsts";
import { resizePhoto } from "@/utils/helpers";
import { SyntheticEvent } from "react";

const useCardFilterHook = () => {

  const validateImg = (imgURL: string, imgFount?: boolean) => {

    let newUrl = imgURL;
    let imgDefault = imgFount ? imageNotFound : "";
    if (!imgURL) return imgDefault;

    if (imgURL.startsWith("http")) {
      newUrl = imgURL;
    } else {
      newUrl = resizePhoto({ photo: IMG_PATH + imgURL, newFormat: MD_IMG });
    }

    return newUrl;
  };

  const handleImageError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    (e.target as HTMLInputElement).src = imageNotFound;
  }

  return {
    validateImg,
    handleImageError
  }
}

export default useCardFilterHook;