import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import { currencyFormat } from "@/utils/helpers";
import { Box, Typography } from "@mui/material";

export const PriceDefaultFormat = ({
  data,
}: {
  data: SolicitudNegociacion;
}) => {

    const coCounterOfferPrice = data.precioContraoferta as number;
  const handlePriceText = () => {
  

    let value = {
      requestPrice: `Precio de tu solicitud: ${currencyFormat(
        data.solicitud.precio
      )}`,
      requestCounterOfferPrice: `Transportista ofrece: ${currencyFormat(
        data.precio as number
      )}`,
      coCounterOfferPrice: `Tu Contraoferta: ${currencyFormat(
        coCounterOfferPrice
      )}`,
    };

    if (!data.flgIsOwner) {
      value = {
        requestPrice: `Precio de la solicitud: ${currencyFormat(
          data.solicitud.precio
        )}`,
        requestCounterOfferPrice: `Tu oferta:  ${currencyFormat(
          data.precio as number
        )}`,
        coCounterOfferPrice: `Contraoferta: ${currencyFormat(
          coCounterOfferPrice
        )}`,
      };
    }
    return value;
  };


  return (
    <>
      <Box sx={{ display: "flex", alignItems: "flex-start", mt: 2 }}>
        <Typography sx={{ fontWeight: 400, fontSize: "16px" }}>
          {handlePriceText().requestPrice}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Typography sx={{ fontWeight: !!coCounterOfferPrice ? 400 : 700, fontSize: !!coCounterOfferPrice ? "16px" : "22px" }}>
          {handlePriceText().requestCounterOfferPrice}
        </Typography>
      </Box>
      {!!coCounterOfferPrice && (
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <Typography sx={{ fontWeight: 700, fontSize: "22px" }}>
            {handlePriceText().coCounterOfferPrice}
          </Typography>
        </Box>
      )}
    </>
  );
};
