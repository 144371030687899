import { FormControl, Grid, Typography } from "@mui/material";

import { AddNewDestiny } from "../../../components/AddNewDestiny";
import { CardItem, HeaderBox } from "../../../components/CardItem";
import ButtomLoader from "../../../components/buttonLoader/ButtomLoader";

import ModalFilter from "@/components/Filter/components/ModalFilter/ModalFilter";
import DynamicComponent from "@/components/dinamycComponent/DinamycComponent";
import { DistritoSugeridoData } from "@/services/noticeServices/noticeListSuggested/noticeListSuggestedTypes";
import { configInCity } from "../../components/inputsConfig/inputsInCity";
import { ContainerGrid } from "../../noticePlaceStyles";
import usePlaceInCityHook from "./hook";
import { metaDataModalInCIty } from "../hook";

export const PlaceInCity = () => {


  const {
    handleInitConfig,
    showModal,
    handleOnCloseModal,
    handleSubmitModal,
    handleOnSeletedModal,
    handleFilterByTerm,
    modalActiveWith,
    listLocation,
    districtsSuggested,
    handleInputsSuggested,
    DeleteSuggestedListItem,
    data,
    validateForm,
    onSubmit,
    isLoading,
    validatePrecioOfDestinos,
    isRegister,
    formData,
    isCity,
  } = usePlaceInCityHook()

  return (
    <ContainerGrid container>
      <ModalFilter
        open={showModal}
        onClose={handleOnCloseModal}
        onSubmit={handleSubmitModal}
        onSeleted={handleOnSeletedModal}
        onInputChange={handleFilterByTerm}
        modalActiveWith={modalActiveWith.name}
        list={listLocation}
        betweenDate={""}
        prevSelected={""}
        metaDataModal={metaDataModalInCIty}
        nameTwo={isCity}
      />

      <FormControl sx={{
        gap: "16px",
      }}>
        {configInCity.map((config) =>
          config.map((items) =>
            items.columns.map((column) => !(column?.hideRegister && isRegister) && (
              <DynamicComponent
                {...handleInitConfig(column)}
              />
            ))))}

      </FormControl>
      <HeaderBox />
      {(districtsSuggested && (districtsSuggested as DistritoSugeridoData)?.sugeridos) && (districtsSuggested as DistritoSugeridoData)?.sugeridos.map((item) => (
        <CardItem
          nombre={item.nombre}
          id={item.id}
          precio={data.avisodestinos?.find((destino) => destino.iddistrito === item.id)?.precio.toString() || ""}
          changeInput={handleInputsSuggested}
          onClick={DeleteSuggestedListItem}
          error={validatePrecioOfDestinos(item.id).valid}
          errorMsg={validatePrecioOfDestinos(item.id).message}
        />
      ))
      }
      {(districtsSuggested && (districtsSuggested as DistritoSugeridoData)?.sugeridos) ?
        <AddNewDestiny title="Agregar distrito" term={formData?.city} />
        : <Typography  variant="body2">
          Elige un distrito de origen para sugerirte destinos.
        </Typography>
      }

      <Grid item sx={{ marginTop: "16px" }}>
        <ButtomLoader
          onClick={onSubmit}
          buttomProps={{
            disabled: !validateForm() || isLoading,
          }}
          isLoading={isLoading}
        >
          CREAR AVISO
        </ButtomLoader>
      </Grid>
    </ContainerGrid>
  );
};


