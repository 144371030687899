import React from "react";
import {
  ContainerListZone,
  ListZoneText,
  ListZoneTitle,
} from "./styleViewListZone";

type Props = { origentexto: string; destinotexto: string };
const SingleViewZone = ({ origentexto, destinotexto }: Props) => {
  return (
    <ContainerListZone>
      <ListZoneText sx={{mb: 2}}>
        <ListZoneTitle component="span">Origen: </ListZoneTitle>
        {origentexto}
      </ListZoneText>
      <ListZoneText>
        <ListZoneTitle component="span">Destino: </ListZoneTitle>
        {destinotexto}
      </ListZoneText>
    </ContainerListZone>
  );
};

export default SingleViewZone;
