import { NoticeDetailMultiDestinyModel } from "@/models/notice/noticeV2NegotationModels";
import { CustomCardV2 } from "@/pages/noticeV2Negotation/shared/styles";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { Box, Grid, Typography } from "@mui/material";
import { handleDataToRender, handleTypeMultidestiny } from "./config";

interface Props {
  data: NoticeV2NegType;
}

const PriceMultiCounterOffer = (props: Props) => {
  const { data } = props;

  const noticeData = handleTypeMultidestiny(data);

  const dataTorender = handleDataToRender({
    noticeData,
    data: data as NoticeDetailMultiDestinyModel,
    changeColor: true
  });

  return (
    <Box>
      {dataTorender.map((item, i) => (
        <CustomCardV2 sx={{ mt: 2 }}>
          <Typography sx={item.title.sx}>{item.title.name}</Typography>
          <Grid container>
            <Grid xs={4}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={item.columnTitleSx}>
                  {item.columnOne.title}
                </Typography>
                <Typography sx={item.clumnSubtitleSx}>
                  {item.columnOne.subtitle}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={4}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={item.columnTitleSx}>
                  {item.columnTwo.title}
                </Typography>
                <Typography sx={item.clumnSubtitleSx}>
                  {item.columnTwo.subtitle}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={4}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={item.columnTitleSx}>
                  {item.columnThree.title}
                </Typography>
                <Typography sx={item.clumnSubtitleSx}>
                  {item.columnThree.subtitle}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CustomCardV2>
      ))}
    </Box>
  );
};

export default PriceMultiCounterOffer;
