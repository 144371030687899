import StandardAlert from "@/components/alerts/StandardAlert";
import DropZone from "@/components/DrowZone/DropZone";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import LayoutFlow from "@/components/LayoutFlow/LayoutFlow";
import BannerModal from "@/components/modals/BannerModal/BannerModal";
import { BASE_URL_IMAGE } from "@/config/config";
import { USER_DOCUMENT_IDETIFICATION_PATH_DEFAULT } from "@/consts/defaultConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { DirtyTypeProps } from "@/interfaces/globalInterface";
import { UsuarioAPI } from "@/models/userAuth/user";
import HeaderFlow from "@/pages/user/components/HeaderFlow";
import { setError } from "@/store/userRedux/userSlice";
import { uploadDocumentIdentificationThunk } from "@/store/userRedux/userThunks";
import { getNextPage } from "@/utils/helpers";
import { styled, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { To, useLocation, useNavigate } from "react-router-dom";
import InfocardRegister from "../../../componets/infocardRegister/InfocardRegister";
import { objectNextPage } from "./configDataIdentification";
import LabelRequired from "@/components/utils/LabelRequired";

const Description = styled(Typography)(
  ({ theme }) => `
    margin-top: 16px;
    margin-bottom: 16px;
    color: ${theme.palette.text.secondary};
  `
);

const From = styled("form")({
  marginTop: "24px",
});

const IdentificationDocument = () => {
  const [image, setImage] = useState<string | File>("");
  const [imageError, setImageError] = useState("");
  const [dirtyFields, setDirtyFields] = useState<DirtyTypeProps>({
    DNI: { dirty: false },
  });
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { isLoading, user, error } = useAppSelector(({ user }) => user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (error) {
      dispatch(setError(null));
      setImageError("");
    }

    return () => {
      dispatch(setError(null));
      setImageError("");
    };
  }, [image]);

  const handleOnSelectImage = (img: string | File) => {
    setImage(img);

    setDirtyFields(() => ({ DNI: { dirty: true } }));
  };

  const handleOnError = (e: Error) => {
    setImageError(e?.message);
    dispatch(setError(true));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLoading) return;

    dispatch(uploadDocumentIdentificationThunk(image))
      .then(() => handleGoNextPage())
      .catch((e) => dispatch(setError(e)));
  };

  const handleGoNextPage = () => {
    const nextPage = getNextPage(location.pathname, objectNextPage);

    navigate(nextPage as To);
  };

  const handleDisabled = () => {
    return !!error || !image || isLoading;
  };

  const handleShowImageDefault = useCallback(() => {
    const currentUser = user as UsuarioAPI;
    if (currentUser?.documentoUrl) {
      return currentUser?.documentoUrl;
    }

    return BASE_URL_IMAGE + USER_DOCUMENT_IDETIFICATION_PATH_DEFAULT;
  }, [user]);

  const handleBackNavigation = () => {
    const isDirtyForm = Object.values(dirtyFields).some(({ dirty }) => !!dirty);
    if (isDirtyForm) {
      setShowAlert(true);
    } else {
      navigate(-1);
    }
  };

  return (
    <LayoutFlow>
      <HeaderFlow title="Licencia o DNI" customGoBack={handleBackNavigation} />
      <>
        {(error || imageError) && (
          <StandardAlert
            sx={{ marginBottom: "16px" }}
            severity="error"
            color="error"
          >
            {imageError || error}
          </StandardAlert>
        )}
      </>
      <BannerModal
        open={showAlert}
        onClose={() => setShowAlert(false)}
        title="Cambios sin guardar"
        content="Aún no has guardado los cambios realizados. ¿Deseas salir?"
        option1={{
          title: "No, quedarme",
          onClick: () => setShowAlert(false),
        }}
        option2={{
          title: "Sí, salir",
          onClick: () => navigate(-1),
        }}
        color="org"
        sx={{
          width: "322px",
          height: "268px",
        }}
        sxAccion={{
          padding: "0px",
        }}
      />

      <Description variant="body1">
        <LabelRequired str="Licencia o documento de identidad"/>
      </Description>

      <DropZone
        imageDefault={handleShowImageDefault()}
        onError={handleOnError}
        onSelect={handleOnSelectImage}
      />

      <From onSubmit={handleSubmit}>
        <ButtomLoader
          disabled={handleDisabled()}
          sx={{ margin: "24px 0" }}
          type="submit"
          isLoading={isLoading}
        >
          GUARDAR
        </ButtomLoader>
      </From>
    </LayoutFlow>
  );
};

export default IdentificationDocument;
