import {
  CONDITIONS_EXPRESS,
  CONTACT_US_LANDING_PATH,
  POLITICS_PRIVACY,
} from "@/consts/typeServicesPaths";
import Footer from "@/pages/footer/Footer";
import {
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Information, SubTitle, Title } from "./dataTermsConditionsStyled";

const TermsConditions = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <>
      <Container
        maxWidth={matches ? "xl" : "xs"}
        sx={{ marginTop: "35px", mb: 8 }}
      >
        <Title>TÉRMINOS GENERALES DE USO </Title>

        <Information>
          Estos Términos de uso (“Términos”) rigen el uso de las aplicaciones
          móviles, sitios web, productos, contenido, instalaciones y plataforma
          de PegaCargo (colectivamente, la “Plataforma”).
        </Information>

        <Information>
          Usted pacta un contrato con nosotros (“PegaCargo”, “nosotros”, “nos” o
          “nuestro”) cuando confirma que acepta estos Términos o cuando utiliza
          la Plataforma de otro modo.
        </Information>

        <Information>
          Es posible que se apliquen términos complementarios a una categoría
          particular de PegaCargo, como PegaCargo Express. Nuestras prácticas de
          privacidad se describen en la Política de Privacidad.{" "}
        </Information>

        <SubTitle sx={{ mt: 2 }}>
          1. Independencia de los Transportistas. Opciones para los Usuarios{" "}
        </SubTitle>

        <Information>
          {" "}
          <strong>Nuestro modelo de negocio </strong>{" "}
        </Information>
        <Information>
          Nuestra Plataforma vincula a proveedores externos independientes de
          transporte, logística o servicios de mensajería (“Transportistas”) y
          sus clientes (“Usuarios”) entre sí.{" "}
          <strong>Cuando los Usuarios solicitan un servicio (SOLICITUD)</strong>
          , serán los Transportistas quienes oferten su precio por los servicios
          de transporte de carga. Los Usuarios que vean la oferta de los
          Transportistas pueden aceptar el precio ofrecido o hacer una
          contraoferta.{" "}
          <strong>
            {" "}
            Cuando los Transportistas ofrecen sus servicios de transporte a
            través de avisos creados en la plataforma (AVISOS)
          </strong>
          , serán los usuarios quienes seleccionen el aviso de su interés y
          aceptarán o contra ofertarán su precio.{" "}
        </Information>
        <Information>
          El Usuario es libre de elegir al Transportista de la lista de
          Transportistas que manifestaron su interés en aceptar la solicitud. Se
          forma un acuerdo independiente entre el Transportista y el Usuario
          cuando alguno de ellos acepta el servicio o la oferta.
        </Information>
        <Information>
          El Usuario deberá pagar al Transportista el precio que ambos acordaron
          a través de la Plataforma. Este precio acordado incluye todos los
          cargos asociados con el viaje (tarifas, peajes, gravámenes, impuestos,
          etc.) o los servicios ofrecidos en el aviso de transporte de carga
          creado. PegaCargo no participa y no afecta de ninguna manera el
          acuerdo entre el Transportista y el Usuario.{" "}
        </Information>
        <Information>
          {" "}
          <strong>Estado de PegaCargo </strong>{" "}
        </Information>
        <Information>
          PegaCargo es una empresa de tecnología que no proporciona transporte,
          logística, mensajería ni ningún servicio relacionado (“Servicios”).
          Estos Servicios son proporcionados por Transportistas independientes
          con el uso de nuestra Plataforma. Cualquier decisión de ofrecer o
          aceptar los Servicios es una decisión independiente tomada a entera
          discreción de cada usuario y bajo su propio riesgo. PegaCargo no
          dirige ni controla a los Transportistas en general ni en su prestación
          de los Servicios. Cualquier característica, proceso u otro esfuerzo
          que realicemos para mejorar su disfrute de nuestra Plataforma no es
          una indicación de ninguna relación de empleo o agencia con ningún
          usuario.
        </Information>
        <Information>
          Estos Términos no reemplazan ni afectan de otro modo la aplicabilidad
          de cualquier acuerdo que los Usuarios puedan tener con los
          Transportistas con respecto a los Servicios.{" "}
        </Information>
        <Information>
          {" "}
          <strong> Tarifas y pagos</strong>{" "}
        </Information>
        <Information>
          PegaCargo puede cobrar a los Transportistas una tarifa de licencia,
          membresía o comisión por usar la Plataforma. Es posible que cambiemos
          dicho monto de vez en cuando. El uso continuado de la Plataforma
          constituirá su aceptación de las tarifas actualizadas.{" "}
        </Information>
        <Information>
          La tarifa de licencia, membresía o comisión cobrada por cada pedido
          completado. Los pagos se registran en su cuenta personal y puede
          encontrar el monto actual de la tarifa de comisión en su cuenta
          personal.{" "}
        </Information>
        <Information>
          Se cobra una tarifa de comisión por un servicio completado cuando se
          inicia el viaje del punto de recojo al punto de entrega. El pago se
          reembolsa en su cuenta si el Usuario cancela el pedido o no se
          presenta. Los reembolsos en tales casos pueden tardar hasta 30 días
          después de que PegaCargo cancele y revise el pedido.{" "}
        </Information>
        <Information>
          Si hay fondos no utilizados en su cuenta después de habernos escrito
          sobre la eliminación de la aplicación PegaCargo y la disolución de los
          Términos, envíe un escaneo de una solicitud por escrito para devolver
          los fondos no utilizados a través de{" "}
          <Link to={CONTACT_US_LANDING_PATH}>
            {" "}
            www.pegacargo.com/contactanos
          </Link>
          , junto con los datos de la cuenta bancaria donde recibir el
          reembolso.{" "}
        </Information>
        <Information>
          PegaCargo no ofrece reembolsos en efectivo. El reembolso se pagará
          dentro de los 10 días hábiles siguientes a la recepción de su
          solicitud por escrito de reembolso de los fondos no utilizados.{" "}
        </Information>
        <Information>
          En caso de que haya fondos no utilizados en su cuenta y no hayamos
          recibido su solicitud por escrito para devolver los fondos no
          utilizados dentro de los 3 años, PegaCargo tiene derecho a debitarlos.
        </Information>

        <Information>
          Si el saldo de la cuenta del Transportista en la aplicación PegaCargo
          se vuelve negativo, el Transportista debe pagar el monto total de la
          deuda dentro de los 2 días calendario. El acceso del Transportista a
          la aplicación PegaCargo estará limitado hasta que se recargue el saldo
          por el monto de la deuda; durante este período, el Transportista no
          podrá ver las solicitudes de viajes de los Usuarios ni negociar con
          ellos.{" "}
        </Information>
        <Information>
          Los Transportistas son responsables de la recaudación y remisión de
          todos los impuestos aplicables asociados con los Servicios de
          Transporte de Carga mediante el uso de la Plataforma. PegaCargo no
          será responsable de ninguna transacción entre Usuarios y
          Transportistas en la que se haya producido una mala conducta fiscal.{" "}
        </Information>
        <Information>
          PegaCargo, a su entera discreción, puede, de vez en cuando, poner a
          disposición de cualquier Usuario o Transportista promociones,
          descuentos, programas de referencia y programas de fidelización (en
          adelante, Ofertas) con características diferentes. Dichas Ofertas
          pueden afectar o aplicarse a pagos de servicios o precio de viaje,
          reduciendo dichos montos, y se realizan en Bonos.{" "}
        </Information>
        <Information>
          Los bonos son válidos únicamente para su uso en la aplicación
          PegaCargo, no son transferibles ni canjeables por dinero en efectivo.
          Los bonos se pueden utilizar para pagar la tarifa de comisión en los
          servicios de transporte de carga de los Transportistas.{" "}
        </Information>
        <Information>
          Se puede proporcionar más información sobre las Ofertas en un aviso
          dentro de la aplicación o mediante cualquier otro medio de
          comunicación mencionado en estos Términos. PegaCargo también se
          reserva el derecho de retener o deducir Bonos u otros beneficios
          obtenidos a través de Ofertas en caso de que PegaCargo determine o
          crea que el canje de la Oferta o la recepción de los Bonos fue por
          error, fraudulento, ilegal o en violación de la promoción aplicable o
          estos Términos. PegaCargo se reserva el derecho de terminar,
          descontinuar, modificar o cancelar cualquier Oferta en cualquier
          momento y a su exclusivo criterio sin previo aviso al usuario.{" "}
        </Information>
        <Information>
          {" "}
          <strong>Anuncios Publicitarios en PegaCargo </strong>{" "}
        </Information>
        <Information>
          PegaCargo muestra y mostrará anuncios publicitarios digitales
          contratados por personas y/o empresas que deseen dar a conocer un
          producto o servicio a través de nuestra plataforma, tanto en la
          versión web como en la versión App. Al hacer clic en dichos anuncios
          publicitarios en cualquiera de sus versiones (tarjeta o zócalo), el
          usuario será redireccionado hacia una página web, App o cualquier otro
          enlace que el anunciante haya consignado dentro de su estrategia
          comercial. PegaCargo no se hace responsable por el contenido de las
          “plataformas destino” de dichos enlaces.{" "}
        </Information>

        <SubTitle sx={{ mt: 2 }}>2. Tu cuenta PegaCargo</SubTitle>

        <Information>
          {" "}
          <strong>Registro de cuenta </strong>{" "}
        </Information>
        <Information>
          Para acceder a la funcionalidad de la Plataforma, debe crear una
          cuenta con nosotros. Para registrarse, debe tener al menos 18 años o
          la mayoría de edad legal en su país y tener la autoridad para celebrar
          un contrato con nosotros y utilizar la Plataforma. Cuando crea una
          cuenta, debe proporcionar información precisa y actualizada sobre
          usted.{" "}
        </Information>
        <Information>
          Para prevenir el fraude, garantizar su seguridad y cumplir con las
          leyes y regulaciones contra el lavado de dinero y sanciones (según
          corresponda), le solicitaremos la información al momento de abrir su
          Cuenta para confirmar su identidad. También podemos solicitarle que
          actualice y verifique su información de vez en cuando.{" "}
        </Information>
        <Information>
          Ofrecemos diferentes tipos de cuentas dependiendo de si utiliza la
          Plataforma como Usuario o como Transportista. Para crear una cuenta de
          Transportista debe proporcionarnos información adicional y pasar el
          proceso de verificación.{" "}
        </Information>
        <Information>
          Si utiliza la Plataforma en otro país, acepta estar sujeto a los
          Términos de PegaCargo para ese país.
        </Information>
        <Information>
          {" "}
          <strong>Mantenimiento de su cuenta</strong>{" "}
        </Information>
        <Information>
          Debe actualizar sus datos de inmediato si cambian. Si su número de
          móvil cambia, avísenos lo antes posible. Si ya no utiliza su número,
          su operador de telefonía móvil podrá expedirlo a una nueva persona
          que, al utilizar la Plataforma, podrá acceder a su cuenta.{" "}
        </Information>

        <Information>
          No puede autorizar a otros a usar su cuenta. Debe mantener la
          seguridad del acceso a su dispositivo y el secreto de su información
          de inicio de sesión. Usted será responsable de toda la actividad que
          ocurra en su cuenta. Si sospecha que un tercero conoce su contraseña o
          ha accedido a su cuenta, infórmenos comunicándose con{" "}
          <Link to={CONTACT_US_LANDING_PATH}>
            www.pegacargo.com/contactanos.
          </Link>
        </Information>

        <Information>
          {" "}
          <strong>Terminación de cuenta </strong>{" "}
        </Information>
        <Information>
          Puede eliminar su cuenta en cualquier momento comunicándose con el
          servicio de atención al usuario ({" "}
          <Link to={CONTACT_US_LANDING_PATH}>
            www.pegacargo.com/contactanos
          </Link>
          ). En algunos casos, no podremos cancelar su cuenta o podremos
          conservar cierta información para fines legítimos, como prevenir
          fraudes y garantizar la seguridad de nuestros Usuarios, cumplir con
          obligaciones legales o gestionar reclamos o disputas no
          resueltas. Consulte nuestra{" "}
          <Link to={POLITICS_PRIVACY}>Política de privacidad</Link> para
          comprender cómo tratamos su información después de la cancelación de
          la cuenta.{" "}
        </Information>
        <Information>
          Las cuentas que hayan estado inactivas durante más de 3 años podrán
          eliminarse. Tenemos derecho a cancelar o suspender el acceso a su
          cuenta (consulte la sección “derechos de PegaCargo”). {" "}
        </Information>

        <SubTitle sx={{ mt: 2 }}>3. Tu seguridad </SubTitle>

        <Information>
          La salud y seguridad de los usuarios de PegaCargo es nuestra
          prioridad. Tomamos medidas razonables para mantener la Plataforma como
          un entorno seguro para nuestros usuarios. Por ejemplo, revisamos los
          documentos de todos los Transportistas antes de permitirles prestar
          sus Servicios. Además, podemos realizar verificaciones aleatorias para
          verificar que la cuenta del Transportista sea utilizada por el
          Transportista que la registró y que el Transportista utilice el
          vehículo asociado con la cuenta de este Transportista. Es posible que
          solicitemos a los Usuarios que pasen un control de vida o verifiquen
          su identidad proporcionando un número de identificación emitido por el
          gobierno.{" "}
        </Information>

        <Information>
          A pesar de nuestros mejores esfuerzos, reconocemos los límites de la
          capacidad de una plataforma en línea para facilitar la seguridad fuera
          de línea. No tenemos control absoluto sobre la calidad o seguridad del
          transporte que se produce como resultado de los Servicios. {" "}
        </Information>
        <Information>
          No podemos garantizar que cada Usuario o Transportista sea quien dice
          ser. Revise las fotografías del Transportista o Usuario que ve en la
          Plataforma para asegurarse de que sea la misma persona que ve en la
          foto. Pero si nota que el Transportista que llegó hasta usted es
          diferente del Transportista en la aplicación PegaCargo, infórmelo a
          PegaCargo e investigaremos y aplicaremos medidas de responsabilidad al
          Transportista.{" "}
        </Information>
        <Information>
          Le recomendamos que tenga precaución y cuidado al interactuar con
          otros usuarios. Usted utiliza y proporciona los Servicios del
          Transportista bajo su propio riesgo. 
        </Information>
        <Information>
          En caso de sospecha de cualquier amenaza a la salud o la seguridad,
          infórmelo inmediatamente al servicio de atención al usuario (
          <Link to={CONTACT_US_LANDING_PATH}>
            www.pegacargo.com/contactanos
          </Link>
          ) o utilice el "botón de seguridad (ESCUDO)". El botón SOS tiene dos
          opciones:{" "}
        </Information>

        <Information>
          {" "}
          <strong>
            Darle la oportunidad de llamar a la policía de su país.{" "}
          </strong>{" "}
        </Information>
        <Information>
          Si hace clic en el botón "Llamar policía", se abrirá una barra de
          marcación estándar con un número de policía configurado
          automáticamente. Si hace clic en el botón "Llamar ambulancia", se
          abrirá una barra de marcación estándar con un número de policía
          configurado automáticamente.{" "}
        </Information>
        <Information>
          {" "}
          <strong>
            Darle la oportunidad de compartir la información de su viaje con un
            número de sus contactos.{" "}
          </strong>{" "}
        </Information>

        <Information>
          Si hace clic en el botón "Compartir información del viaje", se abre el
          panel de selección del destinatario de la información del viaje y
          luego la información sobre su viaje se envía al número especificado
          por usted.{" "}
        </Information>

        <Information>
          Intentamos hacer todo lo posible para mantener nuestra aplicación
          segura y conveniente para usted. Por lo tanto, estamos constantemente
          mejorando y modificando los sistemas de verificación de usuarios y la
          funcionalidad de nuestra aplicación.{" "}
        </Information>

        <SubTitle sx={{ mt: 2 }}>4. Obligaciones del Transportista </SubTitle>

        <List dense>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Al proporcionar los Servicios como Transportista, usted declara,
                garantiza y acepta que:
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Posee una licencia de conducir válida y todos los permisos y
                autorizaciones necesarios para brindar los Servicios, está
                médicamente apto para brindar los Servicios.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Usted es propietario o tiene el derecho legal de operar el
                vehículo que utiliza para proporcionar los Servicios, dicho
                vehículo está en buenas condiciones de funcionamiento y cumple
                con los estándares y requisitos de seguridad legales y de la
                industria.
              </Information>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Solo proporcionará los Servicios utilizando el vehículo que haya
                sido informado a PegaCargo.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                No permitirá que nadie lo acompañe en el vehículo mientras
                presta los Servicios. Sin que no haya sido previamente
                coordinado con el Usuario solicitante del servicio.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                No proporcionará los Servicios mientras esté bajo la influencia
                de la fatiga, el alcohol o las drogas, ni participará en
                comportamientos inseguros o ilegales mientras proporciona los
                Servicios.
              </Information>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                No discriminará a los Usuarios.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                No permitirá que nadie lo acompañe en el vehículo mientras
                presta los Servicios. Sin que no haya sido previamente
                coordinado con el Usuario solicitante del servicio.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                No proporcionará los Servicios mientras esté bajo la influencia
                de la fatiga, el alcohol o las drogas, ni participará en
                comportamientos inseguros o ilegales mientras proporciona los
                Servicios.
              </Information>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                No discriminará a los Usuarios.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                No solicitará ningún pago adicional al precio acordado con el
                Usuario a través de la Plataforma.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Usted será responsable de calcular, cobrar y pagar todos los
                impuestos aplicables previstos por la legislación respectiva en
                su jurisdicción que surjan de la prestación de los servicios a
                los Usuarios.
              </Information>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Cumplirá con nuestras solicitudes razonables de proporcionar
                información en relación con los Servicios y el uso de la
                Plataforma.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                No utilizará la información que haya obtenido a través de la
                Plataforma para ningún fin no relacionado con el uso o la
                prestación de los servicios del Transportista.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Cumpla con los requisitos de todas las leyes aplicables
                relacionadas con la lucha contra el lavado de dinero, sanciones,
                anticorrupción, antisoborno, lucha contra el comercio ilegal y
                lucha contra la financiación del terrorismo, prohibiendo el uso
                de trabajo infantil o forzado, adecuado y no tomar ninguna
                acción que puedan conducir a una violación de tales leyes, no
                facilitar, alentar o inducir a nadie a participar en dichas
                actividades.
              </Information>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Cumplirá con las políticas de PegaCargo aplicables en su país.
              </Information>
            </ListItemText>
          </ListItem>
        </List>

        <SubTitle sx={{ mt: 2 }}>
          5. Comunicaciones entre Transportistas y Usuarios{" "}
        </SubTitle>

        <Information>
          Debe tratar a otros usuarios de PegaCargo con respeto. Sólo podrá
          comunicarse con otros usuarios para los fines relacionados con los
          Servicios. No compartirá ninguna información de contacto
          innecesaria. La comunicación debe finalizar cuando se completa el
          Servicio, a menos que sea para devolver un artículo perdido. Cualquier
          otra comunicación puede considerarse acoso y dar lugar a la suspensión
          o cancelación de su cuenta.{" "}
        </Information>
        <Information>
          Permitimos a los usuarios comunicarse en la Plataforma, por ejemplo, a
          través de comentarios, o llamadas dentro de la aplicación (la
          disponibilidad de estas funciones depende de su ubicación). Tenemos
          derecho a monitorear y registrar sus comunicaciones con otros usuarios
          en la Plataforma para revisar el cumplimiento de estos Términos.{" "}
        </Information>
        <Information>
          Cuando esta funcionalidad esté disponible, podrá comunicarse con el
          otro usuario a través de su número de teléfono móvil proporcionado en
          la interfaz de la aplicación. En este caso, se aplicarán los cargos
          móviles establecidos por su operador de telefonía móvil.{" "}
        </Information>

        <SubTitle sx={{ mt: 2 }}>6. Comunicaciones PegaCargo </SubTitle>

        <Information>
          Podemos enviarle notificaciones operativas sobre su cuenta o los
          Servicios que ha proporcionado, actualizaciones sobre PegaCargo y la
          Plataforma, solicitudes de comentarios y comunicaciones de
          marketing. Podemos comunicarnos con usted por correo electrónico, SMS,
          teléfono y notificación automática. Para los tipos de comunicaciones
          que requieren su consentimiento, cumpliremos con las leyes locales y
          le daremos la opción de cancelar su participación.{" "}
        </Information>

        <SubTitle sx={{ mt: 2 }}>7. Comunicaciones PegaCargo </SubTitle>
        <List dense>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                No debes utilizar la Plataforma para:
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Hacer cualquier cosa ilegal.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Hacer cualquier cosa que viole estos Términos o cualquier otra
                regla de la Plataforma y Políticas de PegaCargo.
              </Information>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Utilizar la Plataforma para cualquier fin no previsto en estos
                Términos.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Transferir o vender su cuenta, contraseña o identificación a
                cualquier otra parte. 
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Hacerse pasar por otra persona o disfrazar su identidad, usar o
                intentar usar la cuenta de otro usuario.
              </Information>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Solicitar a otros que participen en actividades ilegales o
                peligrosas.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Acechar, amenazar o acosar a otros.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Cargar cualquier contenido en la Plataforma que sea inexacto,
                inapropiado, que infrinja los derechos de cualquier persona
                (como los derechos de propiedad intelectual, privacidad o
                personalidad) o que sea ilegal de otro modo.
              </Information>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Socavar las operaciones o la seguridad de la Plataforma,
                intentar obtener acceso no autorizado a la Plataforma o sus
                sistemas o redes relacionados.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Extraer cualquier dato o contenido de la Plataforma.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Crear responsabilidad para PegaCargo o hacer que quedemos
                sujetos a regulación como transportistas o proveedor de
                servicios de taxi o taxi carga.
              </Information>
            </ListItemText>
          </ListItem>
        </List>

        <SubTitle sx={{ mt: 2 }}>8. Derechos de PegaCargo </SubTitle>

        <Information>
          Tenemos derecho a investigar cualquier sospecha de incumplimiento de
          estos Términos. Mientras lo hacemos, se nos permitirá suspender su
          acceso a algunas o todas las funciones de la Plataforma, actuando de
          manera razonable y objetiva según la gravedad de la presunta
          infracción.{" "}
        </Information>
        <Information>
          Posteriormente, podríamos determinar suspender o cancelar temporal o
          permanentemente su cuenta, o imponer límites o restringir su acceso a
          las funciones de la Plataforma si:
        </Information>

        <List dense>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Determinamos, actuando de manera razonable y objetiva, que usted
                está incumpliendo material o reiteradamente estos Términos u
                otras reglas y políticas de PegaCargo.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Tenemos motivos objetivos para creer razonablemente que está a
                punto de infringir gravemente estos Términos.
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Estamos legalmente obligados a hacerlo. 
              </Information>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "15px" }}> - </ListItemIcon>
            <ListItemText>
              <Information sx={{ marginTop: 0 }}>
                Se requiere razonablemente en respuesta a un problema técnico o
                de seguridad grave.
              </Information>
            </ListItemText>
          </ListItem>
        </List>

        <Information>
          Si previamente hemos cancelado su cuenta por incumplimiento de estos
          Términos, pero usted usa nuestra Plataforma nuevamente (por ejemplo,
          abriendo otra cuenta), tenemos derecho a suspender o cancelar dichas
          cuentas.{" "}
        </Information>
        <Information>
          Si cree que hemos cometido un error al suspender o cancelar su cuenta,
          puede apelar a través de{" "}
          <Link to={CONTACT_US_LANDING_PATH}>
            {" "}
            www.pegacargo.com/contactanos.
          </Link>
        </Information>

        <SubTitle sx={{ mt: 2 }}>9. Su contenido </SubTitle>
        <Information>
          Usted es responsable de la información, los archivos y las imágenes
          (colectivamente, "Contenido") que pone a disposición en la
          Plataforma. Debe asegurarse de que su Contenido no viole las leyes ni
          los derechos de nadie. No nos comprometemos a revisar el Contenido de
          los usuarios y no asumimos ninguna responsabilidad por el
          mismo. Podemos eliminar o restringir el acceso a cualquier Contenido,
          si creemos razonablemente que infringe estos Términos o causa daño a
          PegaCargo, a nuestros usuarios u otros terceros. No somos propietarios
          de su contenido. Al hacer que el contenido esté disponible en la
          plataforma, usted otorga a PegaCargo una licencia perpetua,
          irrevocable, mundial, libre de regalías y no exclusiva para usar su
          Contenido, incluso para reproducir, adaptar o realizar trabajos
          derivados, ejecutar y comunicar su Contenido al público. con el fin de
          operar, desarrollar y proporcionar la Plataforma.
        </Information>

        <SubTitle sx={{ mt: 2 }}>10. Propiedad intelectual </SubTitle>
        <Information>
          La Plataforma contiene Contenido (como diseño, imágenes, sonidos,
          textos, bases de datos, códigos informáticos, marcas registradas y no
          registradas y otros objetos similares) propiedad de PegaCargo o bajo
          licencia de ella, que está protegido por derechos de autor, marcas
          comerciales, patentes, secretos comerciales y otros
          procesos. PegaCargo y su licenciatario, cuando corresponda, poseen
          todos los derechos, títulos e intereses, incluidos todos los derechos
          de propiedad intelectual relacionados, en y para la Plataforma
          (software y/o la aplicación y, por extensión, el servicio y cualquier
          sugerencia, idea, solicitud de mejora, comentarios, recomendaciones u
          otra información proporcionada por usted o cualquier otra parte en
          relación con la Plataforma).
        </Information>
        <Information>
          PegaCargo le otorga una licencia limitada, no exclusiva,
          intransferible, no asignable y revocable para: (a) acceder y utilizar
          la Plataforma en su dispositivo personal únicamente con el fin de
          utilizar la Plataforma; y (b) acceder y ver cualquier Contenido o
          material que pueda estar disponible a través de la Plataforma, en cada
          caso únicamente para su uso personal y no comercial. Todos los
          derechos que no se le otorgan expresamente en este documento están
          reservados por PegaCargo y/o el licenciatario de PegaCargo.{" "}
        </Information>
        <Information>
          Usted no puede, ni puede permitir que ninguna otra parte: (a)
          modificar, reproducir o crear trabajos derivados basados ​​en la
          Plataforma; (b) realizar ingeniería inversa, descompilar, desensamblar
          o intentar descubrir o cambiar de otro modo el código fuente de la
          Plataforma para crear un producto o servicio competitivo, crear un
          producto utilizando ideas, características, funciones o gráficos
          similares, copiar cualquier idea, característica , funciones o
          gráficos; (c) enmarcar, vincular o reflejar cualquier parte de la
          Plataforma en cualquier otro servidor o dispositivo inalámbrico o
          basado en Internet; (d) publicar, distribuir o reproducir de cualquier
          manera cualquier material protegido por derechos de autor, marcas
          comerciales u otra información de propiedad exclusiva sin obtener el
          consentimiento previo de PegaCargo.{" "}
        </Information>

        <SubTitle sx={{ mt: 2 }}>11. Indemnización </SubTitle>
        <Information>
          Usted acepta defender, indemnizar y eximir de responsabilidad a
          PegaCargo y sus afiliados, subsidiarias y sus funcionarios,
          directores, empleados y agentes de todos y cada uno de los reclamos,
          demandas, pérdidas, responsabilidades y gastos (incluidos los
          honorarios razonables de abogados) que surjan de o en conexión con:
          (a) el uso de la Plataforma o los Servicios; (b) su incumplimiento o
          violación de cualquiera de estos Términos, cualquier ley o regulación
          aplicable, o los derechos de cualquier tercero (colectivamente,
          "Pérdidas").
        </Information>

        <SubTitle sx={{ mt: 2 }}>12. Responsabilidad </SubTitle>
        <Information>
          Sin limitar la aplicación de ninguna ley y reglamento, todas las
          condiciones, representaciones y garantías, ya sean expresas,
          implícitas, legales o de otro tipo, incluidas, entre otras, cualquier
          garantía implícita de comerciabilidad, idoneidad para un propósito
          particular o no infracción de terceros. derechos, quedan excluidos y
          renunciados en la mayor y máxima medida.
        </Information>
        <Information>
          En la máxima medida permitida por la ley, en ningún caso PegaCargo
          será responsable ante usted o cualquier otra persona por cualquier
          daño o pérdida directa, indirecta, punitiva, económica, futura
          especial, ejemplar, incidental, consecuente u otros daños o pérdidas
          de cualquier tipo (incluyendo pero no limitado a lesiones personales,
          angustia emocional y pérdida de datos, bienes, ingresos, ganancias,
          uso u otra ventaja económica), ya sea que surjan de un contrato,
          agravio (incluida la negligencia) o que surjan de, o de alguna manera
          estén relacionados con, el Plataforma, incluido, entre otros, el uso o
          la imposibilidad de usarlos, cualquier confianza depositada por usted
          en la integridad, exactitud o existencia de cualquier publicidad, o
          como resultado de cualquier relación o transacción entre usted y
          cualquier Transportista, incluso si PegaCargo ha sido advertido
          previamente de la posibilidad de tales daños.{" "}
        </Information>
        <Information>
          {" "}
          <strong>Servicios de terceros </strong>{" "}
        </Information>
        <Information>
          No existe ninguna relación de empresa conjunta, sociedad, empleo o
          agencia entre PegaCargo y cualquiera de nuestros usuarios. En la
          medida máxima permitida por la ley, PegaCargo no es responsable de los
          actos u omisiones de ningún Transportista o Usuario o de cualquiera de
          los Servicios. PegaCargo no será parte en disputas o negociaciones
          entre usuarios de la Plataforma. Usted renuncia y libera expresamente
          a PegaCargo de toda responsabilidad, reclamo, causa de acción o daño
          relacionado con el uso de la Plataforma en sus relaciones con otros
          usuarios de la Plataforma.{" "}
        </Information>
        <Information>
          Si bien otros Servicios de terceros están disponibles en la
          Plataforma, solo se puede acceder a ciertos Servicios o Contenidos de
          terceros saliendo de la Plataforma. Una vez que haga clic en un enlace
          para acceder a dichos tipos de Servicios, estará sujeto a los términos
          y condiciones y a la política de privacidad de ese sitio web, destino
          o proveedor de servicios independiente, que son diferentes de
          PegaCargo. PegaCargo no le advertirá que está sujeto a los términos y
          condiciones (incluidas las políticas de privacidad) de otro sitio web,
          destino. Usted utiliza todos los enlaces en sitios web y anuncios de
          terceros bajo su propio riesgo, ya que no forman parte de la
          Plataforma y no están controlados por PegaCargo. En ningún caso
          PegaCargo será responsable de ningún producto o servicio de dichos
          proveedores externos.{" "}
        </Information>

        <SubTitle sx={{ mt: 2 }}>13. Resolución de disputas </SubTitle>
        <Information>
          Ley aplicable y arbitraje. Estos Términos se rigen por la ley de
          Perú. Si tenemos una disputa, primero intentaremos resolverla
          amigablemente con usted. Todas las disputas o reclamos que surjan de o
          en conexión con estos Términos, incluidas las disputas relacionadas
          con su validez, incumplimiento, terminación o nulidad, se resolverán
          finalmente conforme a las Reglas de Arbitraje (Reglas de Viena) del
          Centro de Arbitraje Internacional de Viena (VIAC) de la Cámara
          Económica Federal de Austria por un árbitro designado de conformidad
          con dicho Reglamento.{" "}
        </Information>

        <SubTitle sx={{ mt: 2 }}>
          14. Cambios a estos Términos o a la Plataforma{" "}
        </SubTitle>
        <Information>
          Es posible que realicemos cambios en estos Términos o en la Plataforma
          de vez en cuando. Cuando actualicemos los Términos, publicaremos su
          última versión en nuestra aplicación y nuestro sitio web. También le
          avisaremos con antelación razonable sobre cambios importantes que le
          afectarán. Si desea seguir usando la Plataforma después de que
          introduzcamos cambios en los Términos o en la Plataforma, deberá
          aceptar los cambios y continuar usando nuestra Plataforma. Si no está
          de acuerdo con dichos cambios, deberá dejar de utilizar la Plataforma.
          A menos que se realicen cambios sustanciales en las disposiciones de
          arbitraje, usted acepta que la modificación de estos Términos no crea
          una nueva oportunidad para optar por no participar en el arbitraje (si
          corresponde).{" "}
        </Information>

        <SubTitle sx={{ mt: 2 }}>15. Generales</SubTitle>
        <Information>
          Nos comprometemos a proporcionarle la Plataforma con habilidad y
          cuidado razonables. Sin embargo, tenga en cuenta que proporcionamos la
          Plataforma "tal como está disponible" o "tal cual". Significa que no
          hacemos ninguna representación ni garantía en cuanto a la
          confiabilidad, seguridad, puntualidad, calidad, idoneidad,
          disponibilidad, precisión o integridad de la Plataforma.{" "}
        </Information>
        <Information>
          Mantenga la Plataforma actualizada a su versión actual de la
          aplicación y garantice la compatibilidad de su dispositivo. Le
          recomendamos que utilice la Plataforma con un plan de datos con
          límites de uso de datos ilimitados o muy altos.{" "}
        </Information>
        <Information>
          La Plataforma puede contener enlaces a sitios web de terceros,
          anuncios, servicios, ofertas u otros eventos o actividades que
          PegaCargo no proporciona, no es propiedad ni controla. Los usas bajo
          tu propio riesgo.{" "}
        </Information>
        <Information>
          Nada en estos Términos afecta ningún derecho legal que usted no pueda
          aceptar contractualmente modificar o renunciar. Siempre tendrá la
          protección total de las leyes que se aplican a usted. {" "}
        </Information>
        <Information>16. Contactar con PegaCargo </Information>
        <Information>
          Puede contactarnos a través de{" "}
          <Link to={CONTACT_US_LANDING_PATH}>
            www.pegacargo.com/contactanos.
          </Link>
        </Information>

        <Information>
          Para revisar las condiciones de uso de PegaCargo Express, revisa el
          documento{" "}
          <Link to={CONDITIONS_EXPRESS}>
            {" "}
            Condiciones de Uso PegaCargo Express
          </Link>
        </Information>
      </Container>
      <Footer />
    </>
  );
};

export default TermsConditions;
