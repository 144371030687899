import { Initialstate } from "./authUserSlice";

export const initialState: Initialstate = {
  userAuth: {
    id: 0,
    username: "",
    enabled: -1,
  },
  userToken: {
    access_token: "",
    token_type: "",
    idusuario: 0,
  },
  typeUser: [],
  cellPhone: "",
  cellPhoneChange: "",
  isLoading: false,
  errorCellPhone: null,
  sendSMS: null,
  lastDateSMS: null,
  successCodeValidation: null,
  errorValidateCode: "",
  errorRegister: "",
  errorSendCode: "",
  errorLogin: "",
  errorResetPassword: "",
};

export const ERROR_GENERIC_TEXT =
  "Tenemos un problema ahora, inténtalo más tarde.";
export const ERROR_LOGIN_TEXT = "La contraseña es incorrecta";
export const ERROR_RESET_PASSWORD = "Error: al restablecer la contraseña";
