import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { useAppSelector } from "@/hooks/useReducer";
import { Negotiation } from "@/models/offert/offertModels";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { PROFILE_NOTICE_OFFERT_LIST } from "../../../../../consts/typeServicesPaths";
import DynamicComponent from "../DynamicComponent";
import { configDataMoving } from "./configDataMoving";
import {
  ConfigDataBodyOffert,
  ValidFormat,
  configData,
} from "./configDataOffertBodyInCity";

const configToRender: Record<number, ConfigDataBodyOffert[]> = {
  1: configData,
  2: configData,
  5: configDataMoving,
};

interface Props {
  hiddenButton?: boolean;
}

const OffertBodyConfirmationInCity = ({ hiddenButton }: Props) => {
  const navigate = useNavigate();
  const {
    offert: { savedOffert, idTipoAvisoNegociacion },
  } = useAppSelector((state) => state);
  const { tipoaviso } = (savedOffert as Negotiation)?.avisoNegociacionAviso;

  const handleGetConfig = () => {
    const configTypeFlow = configToRender[tipoaviso?.id];

    if (!savedOffert) return configData;

    return configTypeFlow;
  };

  const selectdConfigData = useMemo(() => handleGetConfig(), []);

  const handleFormat = (currentValue: any, format?: ValidFormat) => {
    if (!format || !savedOffert) return currentValue;

    let { template, valuesToReplace } = format;

    const newValue = valuesToReplace.reduce((acc, el) => {
      const value = savedOffert[el.value]?.toString() || el.defaultResult;

      return acc.replace(el.search, value);
    }, template);

    return newValue;
  };

  const handleValid = ({ valid, props }: ConfigDataBodyOffert) => {
    if (!valid || !savedOffert) return { props };

    const moreProps = valid.reduce((acc, el) => {
      let value = handleFormat(savedOffert[el.property], el?.format);

      if (el?.callback) {
        value = el.callback(idTipoAvisoNegociacion, value, savedOffert);
      }

      if (typeof el.defaultResult === "boolean") {
        acc = {
          ...acc,
          [el.componentProp]: !!value,
        };
      } else {
        acc = {
          ...acc,
          [el.componentProp]: value || el.defaultResult,
        };
      }
      return acc;
    }, {});

    return { props: { ...props, ...moreProps } };
  };

  return (
    <>
      {selectdConfigData.map((item, i) => (
        <DynamicComponent key={i} {...item} {...handleValid(item)} />
      ))}

      {!hiddenButton && (
        <ButtomLoader
          variant="outlined"
          sx={{ marginTop: "20px" }}
          onClick={() => navigate(PROFILE_NOTICE_OFFERT_LIST)}
        >
          VER MIS OFERTAS
        </ButtomLoader>
      )}
    </>
  );
};

export default OffertBodyConfirmationInCity;
