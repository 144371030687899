import { Tab, Tabs, TabsActions, styled } from "@mui/material";
import React, {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  Ref,
} from "react";

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  action?: Ref<TabsActions> | undefined;
}

interface StyledTabProps {
  label: ReactNode;
  icon?: string | ReactElement<any, string | JSXElementConstructor<any>>;
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    orientation="horizontal"
    variant="scrollable"
    scrollButtons
    allowScrollButtonsMobile
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    TabScrollButtonProps={{
      sx: {
        "&.MuiTabScrollButton-root": {
          color: "#fff",
          "& > svg": {
            width: "1.5em",
            height: "1.5em",
          },
        },
      },
    }}
  />
))({
  ".MuiTabs-scroller": {
    whiteSpace: "nowrap",
    overflowX: "auto !important",
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "transparent",
  },
});

export const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} iconPosition="start" />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.subtitle2.fontSize,
  marginRight: theme.spacing(1),
  width: "156px",
  height: "48px",
  minHeight: "48px",
  border: "1px solid transparent",
  color: "rgba(255, 255, 255, 0.7)",
  textAlign: "start",
  gap: "5px",
  "&.Mui-selected": {
    border: "1px solid #fff",
    borderRadius: "28px",
    backgroundColor: "white",
    color: "#382e2b"
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
  ".MuiTab-iconWrapper": {
    marginRight: "6px",
  },
}));
