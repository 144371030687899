import { prepareLinkNoticeOffersAdapter } from "@/adapters/routesAdapters";
import InfoCard from "@/components/infoCard/InfoCard";
import NotResultFound from "@/components/notResultFound/NotResultFound";
import { ListItems } from "@/components/noticeListItem/typeNoticeListITem";
import SkeletonInfoCardList from "@/components/skeletons/SkeletonInfoCardList";
import { BASE_URL_IMAGE } from "@/config/config";
import { CREATE_CUSTOMER_REQUEST_PATH } from "@/pages/customerRequest/shared/consts/routes";
import {
  FRONT_PAGE_HERO_LG,
  FRONT_PAGE_HERO_MD,
  FRONT_PAGE_HERO_SM,
} from "@/consts/defaultConsts";
import {
  PROFILE_NOTICE_DINAMIC_PUBLIC_PATH,
  SERVICES_LANDING_PATH,
} from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { ResponsiveImage } from "@/interfaces/globalInterface";
import Footer from "@/pages/footer/Footer";
import noticeData from "@/pages/notice/noticeList/noticeDataList.json";
import { setSelectCity } from "@/store/FilterServices/filterSlice";
import { SelectNotice, setSelectNotice } from "@/store/noticeRedux/noticeSlice";
import {
  getListNoticeFilterThunk,
  getListNoticeThunk,
} from "@/store/noticeRedux/noticeThunks";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BannerLand from "../components/bannerLand/BannerLand";
import InfoLand from "../components/infoLand/InfoLand";
import PublicActionLand from "../components/publicActionLand/PublicActionLand";
import SectionLand from "../components/sectionLand/sectionLand";
import SelectCards from "../components/selectCards/SelectCards";
import {
  convertArrayToObjectString,
  defaultTipoAviso,
} from "../services/utils/handleCheck";
import {
  ButtonCard,
  CardsContent,
  CardsItems,
  LinkButton,
} from "./stylePageClients";

const responsiveImages: ResponsiveImage[] = [
  {
    cssProperties: [
      {
        property: "backgroundImage",
        value: `url(${BASE_URL_IMAGE}${FRONT_PAGE_HERO_MD})`,
      },
      { property: "minHeight", value: "240px" },
      { property: "maxHeight", value: "260px" },
    ],
    mediaMinWidth: "961px",
  },
  {
    cssProperties: [
      {
        property: "backgroundImage",
        value: `url(${BASE_URL_IMAGE}${FRONT_PAGE_HERO_LG})`,
      },
      { property: "minHeight", value: "320px" },
      { property: "maxHeight", value: "340px" },
    ],
    mediaMinWidth: "1280px",
  },
];

const selectCardTransform = {
  xs: "inherit",
  sm: "inherit",
  md: "translateY(-140px)",
  lg: "translateY(-100px)",
  xl: "translateY(-142px)",
};

const selectCardSx = {
  height: { xs: "auto", sm: "auto", md: "100px", lg: "100px", xl: 0 },
  paddingBottom: { md: "0px", lg: "50px" },
  marginTop: { xs: "0", sm: "0", md: "35px", lg: "0" },
  paddingTop: { xs: 0, sm: 0 },
};

const Clients = () => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  const { list, avisos, isLoading, selectNotice } = useAppSelector(
    (state) => state.notice
  );
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const isTablet = useMediaQuery("(min-width:768px)");
  const [flowTitleCurrent, setFlowTitleCurrent] = useState("");

  useEffect(() => {
    handleInit();
  }, []);

  useEffect(() => {
    const item = handleGetCurrentItem();
    if (item) {
      dispatch(setSelectNotice(item));
    }
  }, [search]);

  useEffect(() => {
    if (
      (selectNotice as SelectNotice)?.title &&
      (selectNotice as SelectNotice)?.title !== flowTitleCurrent
    ) {
      filterSelectCards((selectNotice as SelectNotice)?.title);
      setFlowTitleCurrent((selectNotice as SelectNotice)?.title);
    }
  }, [selectNotice]);

  const handleInit = async () => {
    const autoSelected = handleIsAutoselected();
    dispatch(
      getListNoticeThunk(noticeData.listItems as ListItems[], autoSelected)
    );
    dispatch(setSelectCity({}));
  };

  const handleIsAutoselected = () => {
    if (!!handleGetCurrentItem()) {
      return false;
    }

    return true;
  };

  const handleGetCurrentItem = () => {
    const id = search[search.length - 1];
    if (!id || isNaN(Number(id)) || !list.length) return null;

    const item = list.find((item) => item.id === Number(id));

    return item;
  };

  const filterSelectCards = (nombre: string) => {
    const body = {
      page: 0,
      size: 6,
      ordenamiento: {
        nombre: "Más reciente",
        opciones: ["rn,asc"],
      },
      tiposFiltro: handleGetTypeFilters(nombre),
    };
    dispatch(getListNoticeFilterThunk(body));
  };

  const handleGetTypeFilters = (nombre: string) => {
    return [
      ...defaultTipoAviso(nombre),
      {
        nombre: "Patrocinio unico",
        opciones: [],
        opcionesnumero: [1],
      },
    ];
  };

  const handleClick = (item: ListItems) => {
    if (matches) {
      navigate(`${pathname}?id=${item.id}`);
    } else {
      navigate(
        `${SERVICES_LANDING_PATH}?${convertArrayToObjectString(
          defaultTipoAviso(item.title)
        )}`
      );
    }
  };

  const handleNavigate = (idDetail: number, idNotice: number) => {
    const path = prepareLinkNoticeOffersAdapter(
      PROFILE_NOTICE_DINAMIC_PUBLIC_PATH,
      idNotice,
      idDetail
    );

    navigate(path, { state: { hasBackNavigate: true } });
  };

  return (
    <div style={{ backgroundColor: isTablet ? "" : "#382E2B" }}>
      {/* banner de imagenes */}
      <BannerLand
        urlImg={`${BASE_URL_IMAGE}${FRONT_PAGE_HERO_SM}`}
        responsive={responsiveImages}
        positionXY={{ xs: "center", md: "center" }}
        minHeight="166px"
      />
      {/* contenido de texto */}
      <SectionLand
        backgroundColor="#382E2B"
        gap={32}
        sx={{ height: { xs: "auto", sm: "auto", md: "370px" } }}
      >
        <InfoLand
          title="BUSCA TRANSPORTISTAS PARA TU CARGA"
          subtitle="Encuentra los mejores precios para transportar artículos, productos, materia prima, mercadería, mobiliario y cualquier otro tipo de carga dentro y fuera de la ciudad."
          height={{ xs: "auto", sm: "auto", md: "220px" }}
        />
      </SectionLand>

      {/* cards seleccionables. */}
      <SectionLand
        sx={selectCardSx}
        backgroundColor={isTablet ? "" : "transparent"}
      >
        <SelectCards
          sx={{
            background: isTablet ? "" : "transparent",
            height: { xs: "auto", sm: "auto" },
          }}
          listItems={list}
          onClick={handleClick}
          selected={(selectNotice as SelectNotice)?.title}
          transform={selectCardTransform}
        />
      </SectionLand>

      <SectionLand
        backgroundColor={isTablet ? "" : "#382E2B"}
        sx={{
          display: { xs: "none", md: "flex" },
        }}
      >
        <CardsContent>
          <CardsItems>
            <Grid container spacing={2}>
              {
                // max 6 items
                avisos && !isLoading ? (
                  avisos.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <InfoCard data={item} navigateBody={handleNavigate} />
                    </Grid>
                  ))
                ) : (
                  <SkeletonInfoCardList xs={12} sm={6} md={4} />
                )
              }
              {avisos?.length === 0 && !isLoading && (
                <Grid item xs={12} sm={12} md={12}>
                  <NotResultFound />
                </Grid>
              )}
            </Grid>
          </CardsItems>

          <LinkButton
            to={`${SERVICES_LANDING_PATH}?${convertArrayToObjectString(
              defaultTipoAviso((selectNotice as SelectNotice)?.title)
            )}`}
          >
            <ButtonCard variant="outlined" color="org">
              VER MÁS
            </ButtonCard>
          </LinkButton>
        </CardsContent>
      </SectionLand>
      <PublicActionLand
        title="¿No encontraste el transportista de carga que necesitas?"
        description="Crea gratis una solicitud, recibe las mejores ofertas de transportistas y elige la que más te convenga en cuanto a calificación, precio y condiciones del servicio."
        textButton="CREA TU SOLICITUD"
        iconCss="icon-solicitud-client"
        path={CREATE_CUSTOMER_REQUEST_PATH}
        sxSection={{
          height: {
            xs: "600px",
            sm: "547.25px",
            md: "336px",
          },
          alignItems: {
            xs: "flex-start",
            md: "center",
          },
        }}
      />
      <Footer />
    </div>
  );
};

export default Clients;
