import { Container } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export const SkeletonGeneral = () => {
  return (
    <Container maxWidth="xs">
    <Stack spacing={1} sx={{mt: 8}}>
      <Skeleton variant="rectangular" width="100%" height={100} />
      <Skeleton variant="rectangular" width="100%" height={50} />
      <Skeleton variant="rounded" width="100%" height={400} />
    </Stack>
    </Container>
  );
};
