import { ID_COUNTEROFFER } from '@/consts/defaultConsts';
import { FORMAT_DATE_DDMMYYYY } from '@/consts/formatsDate';
import { BodyAuctionOfferDTO } from '@/interfaces/offerInterface';
import { NoticeDetailAuctionModel } from '@/models/notice/noticeV2NegotationModels';
import { RootState } from '@/store/store';
import { stringDDMMYYYToDate } from '@/utils/helpers';
import dayjs from 'dayjs';
import {
  SelectTypeNotice,
  SendDataInitialState,
} from '../noticeClientAcceptOrCounterOfferSlice';

interface SendDataAuction {
  cargoDescription: string;
  verifiedPhotosId: number;
  negotiationTypeId: number;
  originId: number;
  destinyId: number;
  originReference: string;
  destinyReference: string;

  weight?: number; // solo en contraoferta!!
  price?: number; // solo en contraoferta!!
}

export const getCurrentStoreAuction = (
  sendData: SendDataInitialState
): SendDataAuction => {
  const currentData = sendData.auctionBetweenCities;

  return currentData as SendDataAuction;
};

export const requestAuctionAdapter = (
  getState: () => RootState
): BodyAuctionOfferDTO => {
  const {
    noticeV2ClientAorCO: {
      sendData,
      idTypeNoticeNegotiation,
      selectTypeNotice,
    },
  } = getState();
  const dataFlow = getCurrentStoreAuction(sendData);
  const noticeData = selectTypeNotice as SelectTypeNotice &
    NoticeDetailAuctionModel;
  const fecha = getFormatDate(noticeData?.fechafija);

  let body: BodyAuctionOfferDTO = {
    fecha,
    idTipoAvisoNegociacion: idTypeNoticeNegotiation,
    descripcionCarga: dataFlow.cargoDescription,
    idfotosverificadas: dataFlow.verifiedPhotosId,
    idOrigen: dataFlow.originId,
    referenciaOrigen: dataFlow.originReference,
    idDestino: dataFlow.destinyId,
    referenciaDestino: dataFlow.destinyReference,
  };

  if (idTypeNoticeNegotiation === ID_COUNTEROFFER) {
    body = {
      ...body,
      pesoEstimado: dataFlow?.weight || 0,
      precio: dataFlow?.price || 0,
    };
  }

  return body;
};

const getFormatDate = (currentDate: string) => {
  if (!currentDate) {
    return dayjs(new Date()).format(FORMAT_DATE_DDMMYYYY);
  }

  return stringDDMMYYYToDate(currentDate).format(FORMAT_DATE_DDMMYYYY);
};
