import DetailsTextV2 from '@/components/DetailsTextV2/Index';
import ConditionsListOffert from '@/components/conditionsList/ConditionsListOffert';
import { districtsDeliveryText } from '@/consts/noticeConsts';
import DetailsTextMoving from '@/pages/customerRequest/application/components/DetailsTextMoving';
import { ConfigDataBody } from '@/pages/customerRequest/application/config';
import {
  handleFormatSendDateRequestNeg,
  renderAmbientes,
  renderTitleAmbiente,
  renderTitleLongDestiny,
  renderTitleLongOrigin,
} from '@/pages/customerRequest/application/utils/utilsApplication';
import DetailsText from '@/pages/customerRequest/components/DetailsText';
import InlineTextDetails from '@/pages/customerRequest/components/InlineTextDetails';
import { Typography } from '@mui/material';
import BodySuccessByType from './BodySuccessByType';
import {
  getMaxWeightVehicleRequest,
  getTypeVehicleAndPlateRequest,
} from './utils/bodyUtils';
import { ImagesNegV2 } from '@/pages/noticeV2Negotation/components/ImagesNegV2';
import { getImagesNegRequest } from '@/pages/requestNegotiation/requestNegFinished/FinishedAccept/utils/bodyUtils';

export const objectRenderComponent: Record<number, () => JSX.Element> = {
  1: BodySuccessByType,
  2: BodySuccessByType,
  3: BodySuccessByType,
  4: BodySuccessByType,
  5: BodySuccessByType,
};

// default config

export const configData: ConfigDataBody[] = [
  {
    Component: Typography,
    props: {
      title: 'Detalles del servicio',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha',
      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'origenTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referencia',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-dark',
      title: 'Destino',
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'destinoTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  // icon-truck
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'vehiculo',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'vehiculo',
        componentProp: 'origen',
        defaultResult: '',
        callback: getTypeVehicleAndPlateRequest,
      },
      {
        property: 'vehiculo',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getMaxWeightVehicleRequest,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: 'Descripción de la carga',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: ImagesNegV2,
    props: {
      style: { marginTop: '13px' },
    },
    valid: [
      {
        property: 'fotos',
        componentProp: 'images',
        defaultResult: [],
        callback: getImagesNegRequest,
      },
    ],
  },

  {
    Component: Typography,
    props: {
      variant: 'body1',
      marginTop: '4px',
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
  {
    Component: InlineTextDetails,
    props: {
      icon: 'icon-kilos',
      title: 'Peso estimado: ',
    },
    valid: [
      {
        property: 'pesoEstimado',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'pesoEstimado',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  // condiciones de la solicitud
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '16px' },
    },
    valid: [
      {
        property: 'condiciones',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'condiciones',
        componentProp: 'listServices',
        defaultResult: [],
      },
    ],
  },
];

// MULTIDESTINY

export const multiDestinyConfigData: ConfigDataBody[] = [
  {
    Component: Typography,
    props: {
      title: 'Detalles del servicio',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha',
      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-light',
      title: districtsDeliveryText,
      isMultidestiny: true,
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'origenTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'vehiculo',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'vehiculo',
        componentProp: 'origen',
        defaultResult: '',
        callback: getTypeVehicleAndPlateRequest,
      },
      {
        property: 'vehiculo',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getMaxWeightVehicleRequest,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: 'Descripción de la carga',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: ImagesNegV2,
    props: {
      style: { marginTop: '13px' },
    },
    valid: [
      {
        property: 'fotos',
        componentProp: 'images',
        defaultResult: [],
        callback: getImagesNegRequest,
      },
    ],
  },

  {
    Component: Typography,
    props: {
      variant: 'body1',
      marginTop: '4px',
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
  {
    Component: InlineTextDetails,
    props: {
      icon: 'icon-kilos',
      title: 'Peso estimado: ',
    },
    valid: [
      {
        property: 'pesoEstimado',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'pesoEstimado',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },

  // condiciones de la solicitud
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '16px' },
    },
    valid: [
      {
        property: 'condiciones',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'condiciones',
        componentProp: 'listServices',
        defaultResult: [],
      },
    ],
  },
];

// BETWEEN CITIES

export const betweenCitiesconfigData: ConfigDataBody[] = [
  {
    Component: Typography,
    props: {
      title: 'Detalles del servicio',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha',
      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'origenTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referencia',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-location-light',
      title: 'Destino',
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'destinoTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  // icon-truck
  {
    Component: DetailsText,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'vehiculo',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'vehiculo',
        componentProp: 'origen',
        defaultResult: '',
        callback: getTypeVehicleAndPlateRequest,
      },
      {
        property: 'vehiculo',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getMaxWeightVehicleRequest,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: 'Descripción de la carga',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: ImagesNegV2,
    props: {
      style: { marginTop: '13px' },
    },
    valid: [
      {
        property: 'fotos',
        componentProp: 'images',
        defaultResult: [],
        callback: getImagesNegRequest,
      },
    ],
  },

  {
    Component: Typography,
    props: {
      variant: 'body1',
      marginTop: '4px',
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
  {
    Component: InlineTextDetails,
    props: {
      icon: 'icon-kilos',
      title: 'Peso estimado: ',
    },
    valid: [
      {
        property: 'pesoEstimado',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'pesoEstimado',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },

  // condiciones de la solicitud
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '16px' },
    },
    valid: [
      {
        property: 'condiciones',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'condiciones',
        componentProp: 'listServices',
        defaultResult: [],
      },
    ],
  },
];

// MOVING

export const configDataCustomRequestMoving: ConfigDataBody[] = [
  {
    Component: Typography,
    props: {
      title: 'Detalles del servicio',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha',
      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
        callback: handleFormatSendDateRequestNeg,
      },
      {
        property: 'fecha',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: handleFormatSendDateRequestNeg,
      },
    ],
  },
  {
    Component: DetailsTextMoving,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'referencia',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: renderTitleLongOrigin,
      },
    ],
  },
  {
    Component: DetailsTextMoving,
    props: {
      icon: 'icon-location-dark',
      title: 'Destino',
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: renderTitleLongDestiny,
      },
    ],
  },
  {
    Component: DetailsTextMoving,
    props: {
      icon: 'icon-holidayVillage',
      title: 'Ambientes',
    },
    valid: [
      {
        property: 'ambientes',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'ambientes',
        componentProp: 'origen',
        defaultResult: '',
        callback: renderTitleAmbiente,
      },
      {
        property: 'ambientes',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: renderAmbientes,
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'vehiculo',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'vehiculo',
        componentProp: 'origen',
        defaultResult: '',
        callback: getTypeVehicleAndPlateRequest,
      },
      {
        property: 'vehiculo',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getMaxWeightVehicleRequest,
      },
    ],
  },
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '16px' },
    },
    valid: [
      {
        property: 'condiciones',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'condiciones',
        componentProp: 'listServices',
        defaultResult: [],
      },
    ],
  },
];
