import ShareRideError from "@/assets/svg/ShareRideError";
import { Grid, Typography, styled } from "@mui/material";
import React from "react";

const Container = styled(Grid)(
  () => `
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 80vh;
    flex-direction: column;
  `
);

function ErrorSharedRide() {
  return (
    <Container>
      <ShareRideError />
      <Typography variant="subtitle1" sx={{ fontWeight: 600, mt: "16px" }}>
        Servicio completado
      </Typography>
      <Typography variant="body2" sx={{ color: "#616161", mt: "8px" }}>
        Descarga PegaCargo
      </Typography>
    </Container>
  );
}

export default ErrorSharedRide;
