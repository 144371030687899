import {
  AUTH_USER_TYPE,
  REGISTER_CLIENT,
  REGISTER_CLIENT_PROFILE,
  REGISTER_DRIVER,
  REGISTER_DRIVER_STEPS,
} from '@/consts/typeServicesPaths';
import { UsuarioAPI } from '@/models/userAuth/user';

const rulesUserClient = [
  validationTypeUser,
  validationRegisterForm,
  validationRegisterTypeClient,
];
const rulesUserDriver = [
  validationTypeUser,
  validationRegisterForm,
  validationFullProfile,
];

export const getRedirectPath = (user: UsuarioAPI) => {
  const typeUser = user?.tipousuario;
  const rule = typeUser === 'C' ? rulesUserClient : rulesUserDriver;

  const nextPage = validatorFunctions(rule, user);

  return nextPage;
};

const validatorFunctions = (arrayFunc: Array<Function>, user: UsuarioAPI) => {
  let nextRoute = '';

  try {
    arrayFunc.forEach((func) => {
      const rute = func(user);
      if (rute) {
        throw (nextRoute = rute);
      }
    });
  } catch (err) {
    return nextRoute;
  }

  return nextRoute;
};

function validationTypeUser(user: UsuarioAPI) {
  const hasTypeUser = user?.tipousuario;
  if (!hasTypeUser) return AUTH_USER_TYPE;
}

// @OJO este caso no va a pasar. por que no se guarda el tipo de usuario si no hasta el formulario
function validationRegisterForm(user: UsuarioAPI) {
  const hasRegisterForm = user?.registroForm;
  const typeUser = user?.tipousuario;
  if (!hasRegisterForm) {
    if (typeUser === 'C') {
      return REGISTER_CLIENT;
    }
    return REGISTER_DRIVER;
  }
}

export function validationFullProfile(user: UsuarioAPI) {
  const {
    registroDocumento,
    registroPerfil,
    registroPropiedad,
    registroVehiculo,
    verificacionRegistro,
  } = user;
  if (!!verificacionRegistro) {
    return '';
  } else if (
    !registroDocumento ||
    !registroPerfil ||
    !registroPropiedad ||
    !registroVehiculo ||
    !verificacionRegistro
  ) {
    return REGISTER_DRIVER_STEPS;
  }
}

export function validationRegisterTypeClient(user: UsuarioAPI) {
  const { registroPerfil } = user;

  if (!registroPerfil) {
    return REGISTER_CLIENT_PROFILE;
  }

  return '';
}
