import LayoutFlow from "@/components/LayoutFlow/LayoutFlow";
import StandardAlert from "@/components/alerts/StandardAlert";
import CustomSnackbar from "@/components/customSnackbar/CustomSnackbar";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { StyleCustomInput } from "@/components/form/customInput/styleCustomInput";
import LabelRequired from "@/components/utils/LabelRequired";
import { AUTH_PASSWORD, AUTH_VALIDATION } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import {
  setCellPhone,
  setErrorCellPhone,
  setErrorSendCode,
} from "@/store/auth/authUserSlice";
import {
  EnumTypeCodeSend,
  sendCodeBySMSThunk,
  validateUserCellPhoneThunk,
} from "@/store/auth/authUserThunks";
import { extractCodeStateInError } from "@/utils/helpers";
import { Alert, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import HeaderFlow from "../components/HeaderFlow";
import { Form } from "../components/styleLoginRegister";
import { CustomControl } from "../password/styles";
import { loginStartDataLayer } from "@/services/dataLayer/auth";
import Input from "@/components/inputCustom/Input";

const MAX_LENGTH_PHONE = 9;

const Login = () => {
  const [phone, setPhone] = useState("");
  const [errorApi, setErrorApi] = useState(false);
  const [errorSendMessage, setErrorSendMessage] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);

  const { errorSendCode, isLoading, errorCellPhone } = useAppSelector(
    (state) => state.auth
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const inputSearchRef = useRef<HTMLInputElement>(null);

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    inputSearchRef.current?.focus();
  }, []);

  useEffect(() => {
    if (errorApi) {
      timer.current = setTimeout(() => {
        setErrorApi(false);
        dispatch(setErrorSendCode(""));
      }, 15000);
    }
    return () => {
      clearTimeout(timer.current as NodeJS.Timeout);
    };
  }, [errorSendCode, errorApi]);

  const handleOnchange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const value = target.value.replace(/\D/g, "");
    if (value.length > MAX_LENGTH_PHONE) return;

    setPhone(value);
  };

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLoading) return;

    dispatch(validateUserCellPhoneThunk(phone))
      .then(() => {
        loginStartDataLayer();
        dispatch(setCellPhone(phone));
        handleGotoPassword();
      })
      .catch(handleGoToRegister);
  };

  const handleGoToRegister = async (error?: Error) => {
    if (error && extractCodeStateInError(error) !== 404) {
      setErrorPhone(true);
      return;
    }
    if (errorCellPhone) dispatch(setErrorCellPhone(null));
    if (errorApi) setErrorApi(false);

    dispatch(setCellPhone(phone));
    await dispatch(sendCodeBySMSThunk(phone, EnumTypeCodeSend.REGISTRO))
      .then(() =>
        navigate(AUTH_VALIDATION, {
          state: {
            success: true,
          },
        })
      )
      .catch((err: any) => {
        if (err.cause === 500) {
          setErrorApi(true);
          return;
        }
        setErrorSendMessage(true);
      });
  };

  const handleGotoPassword = () => {
    navigate(AUTH_PASSWORD, {
      state: {
        phone,
        returnPath: searchParams.get("returnPath"),
      },
    });
  };

  const handleDisabled = () => {
    return phone.trim().length < 9 || isLoading;
  };

  return (
    <LayoutFlow sx={{ height: { lg: "75vh" } }}>
      <HeaderFlow arrowBack={false} title="Ingresa a PegaCargo.com" />
      <CustomSnackbar open={errorSendMessage}>
        <Alert onClose={() => setErrorSendMessage(false)} severity="warning">
          <Typography>
            Tenemos inconvenientes con el envío de SMS. Por favor intenta más
            tarde.
          </Typography>
        </Alert>
      </CustomSnackbar>
      <>
        {errorPhone && errorCellPhone?.message ? (
          <StandardAlert
            sx={{ marginBottom: "16px" }}
            severity="error"
            color="error"
          >
            {errorCellPhone?.message}
          </StandardAlert>
        ) : (
          <></>
        )}
      </>
      <>
        {errorApi ? (
          <StandardAlert
            sx={{ marginBottom: "16px" }}
            severity="error"
            color="error"
          >
            {errorSendCode}
          </StandardAlert>
        ) : (
          <></>
        )}
      </>
      <Form onSubmit={handleOnSubmit}>
        <Typography variant="body1">
          Sólo necesitamos tu número de celular.
        </Typography>
        <CustomControl fullWidth>
          <Input
            inputRef={inputSearchRef}
            label={<LabelRequired str="Número de celular" />}
            name="phone"
            variant="standard"
            placeholder="Ingresa tu celular"
            autoComplete="off"
            value={phone}
            onChange={handleOnchange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
              required: true,
            }}
          />
        </CustomControl>
        <ButtomLoader
          type="submit"
          disabled={handleDisabled()}
          isLoading={isLoading}
        >
          SIGUIENTE
        </ButtomLoader>
      </Form>
    </LayoutFlow>
  );
};

export default Login;
