import SearchModal from "@/components/modals/searchModal/SearchModal";
import { ITipoInmuebles } from "@/store/offertRedux/offert.interface";
import { FormControl, InputAdornment } from "@mui/material";

import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import FormRequest from "./FormRequest";
import { MODAL_TYPE_REQUEST, adapterConfigInmueble } from "./configData";
import useFechaCustomHook from "./hookFecha";
import useOriginOrDestinyHook from "./hookOriginOrDestiny";

import { FORMAT_DATE_DDMMYYYY } from "@/consts/formatsDate";
import dayjs from "dayjs";

import DatePicker from "@/components/form/datePicker.tsx/DatePicker";
import Input from "@/components/inputCustom/Input";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import Icon from "@/utils/Icon";
import { MODAL_TYPE } from "../FormRute/FormRuteInCity/configFormInCity";
import { optionOne } from "@/consts/noticeConsts";

const FormOriginOrDestiny = () => {
  const {
    datePicker,
    setDatePicker,
    openModal,
    setOpenModal,
    currentModal,
    id,
    citiesByTerm: citiesByTermFecha,
    selectedCity: selectedCityFecha,
    flgCiudadoEntreciudades,
    handleOnClickSearchInput,
    handleChangeModal: handleChangeModalFecha,
    handleSubmitModal: handleSubmitModalFecha,
    handleSubmit: handleSubmitFecha,
    handleDisabledButton,
    city,
  } = useFechaCustomHook();

  const {
    modalTitle,
    openModal2,
    modalType,
    reference,
    referenceDestiny,
    formRequest,
    selectedCity,
    selectedCityDestiny,
    selectedOrigin,
    selectedDestiny,
    origin,
    destiny,
    handleChange,
    handleOnChange,
    handleInputClickModal,
    handleSubmitModal,
    handleChangeModal,
    validateForm,
    handleSubmit,
    setOpenModal2,
    citiesByTerm,
    districts,
    customerRequest,
    tipoInmuebles,
    inCity,
    showOrigin,
    showDestiny,
    activeNameTwo,
  } = useOriginOrDestinyHook();
  const newOrigin = origin;
  const handleManageList = () => {
    const { ciudad1, ciudad2 } = MODAL_TYPE_REQUEST;
    if (modalType === ciudad1 || modalType === ciudad2) return citiesByTerm;
    return districts;
  };

  const handleManageinputProps = () => {
    const { ciudad1, ciudad2, origin } = MODAL_TYPE_REQUEST;

    if (modalType === ciudad1) return newOrigin.modal1;
    else if (modalType === ciudad2) return destiny.modal1;
    else if (modalType === origin) return newOrigin.modal2;

    return destiny.modal2;
  };

  return (
    <>
      <SearchModal
        open={openModal2}
        onClose={setOpenModal2}
        onSubmit={handleSubmitModal}
        onChange={handleChangeModal}
        list={handleManageList()}
        title={modalTitle}
        inputProps={handleManageinputProps()}
        nameTwo={activeNameTwo(modalType)}
      />
      {showOrigin() && (
        <>
          <SearchModal
            open={openModal}
            onClose={setOpenModal}
            onSubmit={handleSubmitModalFecha}
            onChange={handleChangeModalFecha}
            list={citiesByTermFecha}
            title={currentModal.title}
            inputProps={currentModal}
            nameTwo={activeNameTwo(modalType)}
          />

          <DatePicker
            initValue={datePicker}
            inputFormat={FORMAT_DATE_DDMMYYYY}
            label={<LabelInputRequired str="Fecha de mudanza" />}
            onChange={setDatePicker}
            minDate={dayjs().add(1, "day")}
            maxDate={dayjs().add(1, "month")}
            renderInput={Input}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon name="icon-calendar" size="md" />
                </InputAdornment>
              ),
            }}
          />
          {flgCiudadoEntreciudades === optionOne && (
            <FormControl sx={{ marginTop: "16px" }} fullWidth>
              <Input
                id={`${id}-city`}
                fullWidth
                name={city.input1.name}
                label={<LabelInputRequired str="Ciudad de origen" />}
                value={selectedCityFecha?.nombre2 || selectedCityFecha?.nombre}
                variant="standard"
                placeholder={city.input1.placeholder}
                onClick={() => handleOnClickSearchInput(MODAL_TYPE.city)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          )}
        </>
      )}

      {showOrigin() && (
        <FormRequest
          input1={{
            ...origin.input1,
            value: selectedCity.nombre2 || selectedCity.nombre || "",
            show: !inCity,
          }}
          input2={{
            ...origin.input2,
            value: selectedOrigin.nombre2 || selectedOrigin.nombre || "",
            show: true,
          }}
          input3={{
            ...origin.input3,
            value: reference,
            show: true,
          }}
          onClick={handleInputClickModal}
          onChange={handleOnChange}
          {...adapterConfigInmueble(
            customerRequest,
            formRequest,
            handleChange,
            tipoInmuebles as ITipoInmuebles[]
          )}
        />
      )}
      {showDestiny() && (
        <FormRequest
          activeDisabledInput={true}
          input1={{
            ...destiny.input1,
            value:
              selectedCityDestiny.nombre2 ||
              selectedCityDestiny.nombre ||
              (inCity && selectedOrigin.nombre) ||
              "",
            show: !inCity,
          }}
          input2={{
            ...destiny.input2,
            value: selectedDestiny.nombre2 || selectedDestiny.nombre || "",
            show: true,
          }}
          input3={{
            ...destiny.input3,
            value: referenceDestiny,
            show: true,
          }}
          onClick={handleInputClickModal}
          onChange={handleOnChange}
          {...adapterConfigInmueble(
            customerRequest,
            formRequest,
            handleChange,
            tipoInmuebles as ITipoInmuebles[]
          )}
        />
      )}

      <FormControl fullWidth>
        <ButtomLoader
          onClick={() => handleSubmit(handleSubmitFecha)}
          disabled={validateForm(handleDisabledButton)}
        >
          Siguiente
        </ButtomLoader>
      </FormControl>
    </>
  );
};

export default FormOriginOrDestiny;
