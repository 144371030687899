import { Container, styled } from "@mui/material";

export const ContainerHeaderProfile = styled(Container)(
    () => `
          padding: 0px;
          gap: 4px;
          @media only screen and (min-width: 768px){
              padding: 32px 0px;
              gap: 16px;
              margin-left: 64px;
          }
          `
  );
