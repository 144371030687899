import {
  driverurl,
  optionOne,
  optionTwo
} from "@/consts/noticeConsts";
import { useAppSelector } from "@/hooks/useReducer";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import { useMediaQuery, useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { idToConfigToReturnFormat } from "../adapters/noticeRequestFlowAdapters";
import { ConfigDataNegotation } from "../interface/noticeNegRequestInterface";
import { generalCofigurationToRender } from "../negzAgreementConfirmation/config";

interface UseAgreementConfirmation {
  selectOneconfigData: ConfigDataNegotation[];
  selectTwoconfigData: ConfigDataNegotation[];
  selectConditionconfigData: ConfigDataNegotation[];
  selectThreeconfigData: ConfigDataNegotation[];
  savedDataNogotation: SolicitudNegociacion | null;
  configToRender: number;
  showTwoAvatars: boolean;
  setConfigToRender: React.Dispatch<React.SetStateAction<number>>;
  matches: boolean;
  tipoSolicitud: number;
  isOwner: number;
}

const useAgreementConfirmation = (): UseAgreementConfirmation => {
  const {
    noticeNegotationRequest: { savedDataNogotation },
  } = useAppSelector((state) => state);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const [configToRender, setConfigToRender] = useState(1);
  const { pathname } = useLocation();

  const { tipoSolicitud } = idToConfigToReturnFormat(
    savedDataNogotation as SolicitudNegociacion
  );

  const showTwoAvatars = pathname?.includes(driverurl);

  const validateClient = pathname?.includes(driverurl) ? optionOne : optionTwo;

  const handleGeneralConfigData = () => {
    const isValidate =
      savedDataNogotation && configToRender && tipoSolicitud && validateClient;
      
    if (isValidate) {
      const [
        selectOneconfigData,
        selectTwoconfigData,
        selectConditionconfigData,
        selectThreeconfigData,
      ] = Array.from(Array(4).keys()).map(
        (item) =>
          generalCofigurationToRender[configToRender][tipoSolicitud][
            validateClient
          ][item + 1] as ConfigDataNegotation[]
      );

      return {
        selectOneconfigData,
        selectTwoconfigData,
        selectConditionconfigData,
        selectThreeconfigData,
      };
    } else {
      return {
        selectOneconfigData: [],
        selectTwoconfigData: [],
        selectConditionconfigData: [],
        selectThreeconfigData: [],
      };
    }
  };

  const {
    selectOneconfigData,
    selectTwoconfigData,
    selectConditionconfigData,
    selectThreeconfigData,
  } = useMemo(() => handleGeneralConfigData(), [savedDataNogotation]);

  return {
    selectOneconfigData,
    selectTwoconfigData,
    selectConditionconfigData,
    selectThreeconfigData,
    savedDataNogotation: savedDataNogotation as SolicitudNegociacion,
    configToRender,
    showTwoAvatars,
    setConfigToRender,
    matches,
    tipoSolicitud,
    isOwner: savedDataNogotation?.flgIsOwner || 0,
  };
};

export default useAgreementConfirmation;
