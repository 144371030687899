import { useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import {
  AdvertisingLandSectionContainer,
  BoxContainer,
  BoxImageDesktop,
  ButtonStyled,
  ContainerImg,
  SubContainer,
  TypographyText,
  TypographyTitle,
} from "./styles";
import { CREATE_CUSTOMER_REQUEST_PATH } from "@/pages/customerRequest/shared/consts/routes";

interface IAdvertisingLandSectionProps {
  title?: string;
  text?: string;
  urlButton?: string;
  textButton?: string;
  image?: string;
  imgWidth?: string;
  imgHeight?: string;
}

const AdvertisingLandSection = (props: IAdvertisingLandSectionProps) => {
  const {
    title = "Ofrece tu precio",
    text = "Solicita transportistas con las condiciones del servicio que necesitas para enviar tus productos, mercadería o cualquier tipo de carga",
    urlButton = CREATE_CUSTOMER_REQUEST_PATH,
    textButton = "CREAR SOLICITUD",
    image = "icon-new_solicitud-client",
    imgWidth,
    imgHeight,
  } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AdvertisingLandSectionContainer
      matches={matches}
      container
      spacing={2}
      sx={{
        padding: {
          xs: "16px ",
          md: "10px",
          lg: "16px",
        },
      }}
    >
      {matches && (
        <ContainerImg item xs={12}>
          <i
            className={image}
            style={{
              width: imgWidth ? imgWidth : "",
              height: imgHeight ? imgHeight : "",
            }}
          />
        </ContainerImg>
      )}
      <SubContainer item xs={12}>
        <BoxContainer matches={matches}>
          <TypographyTitle>{title}</TypographyTitle>
          <TypographyText>{text}</TypographyText>
          <Link to={urlButton}>
            <ButtonStyled>{textButton}</ButtonStyled>
          </Link>
        </BoxContainer>
        {!matches && (
          <BoxImageDesktop>
            <i
              className={image}
              style={{
                width: imgWidth ? imgWidth : "",
                height: imgHeight ? imgHeight : "",
              }}
            />
          </BoxImageDesktop>
        )}
      </SubContainer>
    </AdvertisingLandSectionContainer>
  );
};

export default AdvertisingLandSection;
