import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { NEGOTATION_REMOVE_REQUEST } from "@/consts/negotationFlowPath";
import {
  optionEight,
  optionOne,
  optionThree,
  optionTwo,
} from "@/consts/noticeConsts";
import {
  DialogType,
  nottifficationEditNoticeRequest,
  nottifficationRevomeNoticeRequest,
} from "@/consts/notifficationsMessage";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { removeNoticeRequest } from "@/store/noticeNegotationRequestRedux/noticeNegRequesThunk";

import { REQUESTS_LANDING_PATH } from "@/consts/typeServicesPaths";
import { ISolicitudViewDetailsResponse } from "@/models/customerRequest/requestDetailsVewResponse";
import { noticeNegotationRequestObjectTypes } from "@/pages/noticeRequestFlow/consts/noticeRequestFlowConsts";
import { Grid, styled } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { usePublicButtons } from "../hooks/usePublicButtons";
import {
  IDefatultButtonListRules,
  ListItemsButtonsCustomerRequest,
} from "../rulesFlow";
import {
  renderIconByTypeRequest,
  renderTitleByTypeRequest,
  renderTitleOrigenAndDestiny,
} from "../utils/utilsApplication";
const Container = styled(Grid)(
  () => `
      display: flex;
      flex-direction: column;
      `
);
const ButtonService = ({ listButtons }: ListItemsButtonsCustomerRequest) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const publicButtons = usePublicButtons();
  const { requestDetailsView, amountNegotations } = useAppSelector(
    (state) => state.customerRequest
  );
  const tipoSolicitudId = requestDetailsView?.tipoSolicitud.id;
  const validPathName = pathname?.startsWith(REQUESTS_LANDING_PATH);
  const navigate = useNavigate();

  const { addNotiffication } = useNotiffication();

  const ActiveOrDisableButton = (item: IDefatultButtonListRules) => {
    if (!item.flg) return true;
    const flg = requestDetailsView?.estado[item.flg];

    return flg === 0;
  };

  const handleManageModal = (
    handleRute: () => void,
    textToRender: DialogType
  ): void => {
    addNotiffication({
      title: textToRender.TITLE,
      message: textToRender.MESSAGE,
      leftBtnText: textToRender.LEFT_BTN_TEXT,
      RightBtnText: textToRender.RIGHT_BTN_TEXT,
      hiddenSnackbar: true,
      hiddenModal: false,
      onSubmit: async () => {
        handleRute();
      },
    });
  };

  const ShowOrHideButton = (item: IDefatultButtonListRules) => {
    const showFlg = requestDetailsView?.estado[item.showFlg];
    return showFlg === optionOne;
  };

  const handleNavigate = (item: IDefatultButtonListRules) => {
    let textToRender;
    if (validPathName) {
      const handlefunction = publicButtons[item.idAction as number];
      handlefunction();
    } else {
      let handleRute;

      if (item.id === optionOne) {
        textToRender = nottifficationEditNoticeRequest;
        handleRute = ConfigToHangleButton[item.id];

        !!amountNegotations
          ? handleManageModal(handleRute, textToRender)
          : handleRute();
      } else if (item.id === optionTwo) {
        handleRute = ConfigToHangleButton[item.id];
        handleRute();
      } else if (item.id === optionThree) {
        handleRute = ConfigToHangleButton[item.id];

        if (!!amountNegotations) {
          textToRender = nottifficationRevomeNoticeRequest;
          handleManageModal(handleRute, textToRender);
        } else {
          handleRute();
        }
      } else if (item.id === optionEight) {
        handleNavigateToOfferts(
          requestDetailsView as ISolicitudViewDetailsResponse,
          item?.link || ""
        );
      }
    }
  };

  const handleNextRute = () => {
    const nextPath = pathname + "/editar";
    navigate(nextPath);
  };

  const handleNextRuteRenew = () => {
    const nextPath = pathname + "/volver-solicitar";
    navigate(nextPath);
  };

  const handleDeleteNotice = () => {
    const path = NEGOTATION_REMOVE_REQUEST;

    dispatch(
      removeNoticeRequest(requestDetailsView?.id.toString() as string)
    ).then(() => {
      navigate(path);
    });
  };

  const handleNavigateToOfferts = (
    requestDetailsView: ISolicitudViewDetailsResponse,
    link: string
  ) => {
    const {
      id: idSolicitud,
      tipoSolicitud: { id: idTypeRequest },
      fecha,
    } = requestDetailsView;
    let path = "";
    const valueToReplace = noticeNegotationRequestObjectTypes[idTypeRequest];
    path = link
      .replace(":typeRequest", valueToReplace)
      .replace(":id", idSolicitud.toString());

    const title: string = renderTitleByTypeRequest("", requestDetailsView);
    const icon: string = renderIconByTypeRequest("", requestDetailsView);
    const origin: string = renderTitleOrigenAndDestiny("", requestDetailsView);
    const date: string = `Para el ${fecha}`;
    const typeSolicitudId = idTypeRequest;

    const linkToNavigate = {
      title,
      icon,
      origin,
      date,
      typeSolicitudId,
    };

    navigate(path, {
      state: linkToNavigate,
    });
  };

  const ConfigToHangleButton: Record<number, () => void> = {
    1: handleNextRute,
    2: handleNextRuteRenew,
    3: handleDeleteNotice,
  };

  return (
    <>
      <Container>
        {listButtons.map(
          (item, i) =>
            ShowOrHideButton(item) && (
              <ButtomLoader
                disabled={ActiveOrDisableButton(item)}
                variant={item.variant}
                key={i}
                onClick={() => handleNavigate(item)}
                tipoAvisoId={tipoSolicitudId}
              >
                {item.title}
              </ButtomLoader>
            )
        )}
      </Container>
    </>
  );
};

export default ButtonService;
