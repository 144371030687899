import DynamicComponent from "@/pages/offerts/offertConfirmation/components/DynamicComponent";
import { useConfigData } from "@/pages/requestNegotiation/shared/hooks/useConfigData";
import { useGetRequestData } from "@/pages/requestNegotiation/shared/hooks/useGetRequestData";
import { currencyFormat } from "@/utils/helpers";
import { Typography } from "@mui/material";
import { inCitySuccessConfig } from "./config";

const FormSuccessInCity = () => {
  const { data } = useGetRequestData();
  const { handleInitConfig } = useConfigData();

  return (
    <>
      {inCitySuccessConfig.map((item, i) => (
        <DynamicComponent key={`${i}`} {...item} {...handleInitConfig(item)} />
      ))}
      <Typography sx={{ fontWeight: 700, fontSize: "22px", mt: 2 }}>
        Contraoferta: {currencyFormat(data?.precioContraoferta as number)}
      </Typography>
    </>
  );
};

export default FormSuccessInCity;
