import React from 'react';

import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';

//Location Icons
import {
  AuctionIcon,
  BetweenCitiesIcon,
  InCityIcon,
  MovingCities,
  MovingIcon,
  MultiDestinyIcon,
} from '@/assets/icons';
import {
  AreaIcon,
  ConditionIcon,
  HomePCIcon,
  KilosIcon,
  MoneyIcon,
  MovingBetweenCitiesIcon,
  TransportationIcon,
} from '@/assets/svg';
import { HighWayIcon } from '@/assets/svg/HighWayIcon';
import { MultidestinyTruckIcon } from '@/assets/svg/MultidestinyTruckIcon';
import { CounterOfferMatIcon } from '@/pages/noticeV2Negotation/components/CounterOfferMatIcon';
import { MetterIcon } from '@/pages/noticeV2Negotation/components/MetterIcon';
import { theme } from '@/styles/theme';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import ShareLocationOutlinedIcon from '@mui/icons-material/ShareLocationOutlined';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import StarIcon from '@mui/icons-material/Star';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
interface IconProps extends React.ComponentProps<any> {
  name: IconTypes;
  size?: IconSizeEnum;
  color?: IconColor;
  className?: string;
  styles?: any;
}

export enum IconSize {
  sm,
  md,
  lg,
  xl,
}

export type IconSizeEnum = 'sm' | 'lg' | 'md' | 'xl' | number;

export type IconColor =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'warning'
  | 'success'
  | 'error'
  | 'black'
  | 'org'
  | 'disabled'
  | string;

export type IconTypes =
  | 'icon-document'
  | 'icon-calendar'
  | 'icon-handsShake'
  | 'icon-location-light'
  | 'icon-location-dark'
  | 'icon-truck'
  | 'icon-success'
  | 'icon-box'
  | 'icon-arrow-left'
  | 'icon-building'
  | 'icon-metter'
  | 'icon-counterOfferMat'
  | 'icon-sentimentVeryDissatisfied'
  | 'icon-area'
  | 'icon-condition'
  | 'icon-money'
  | 'icon-kilos'
  | 'icon-people'
  | 'icon-timeline'
  | 'icon-timer'
  | 'icon-share-location'
  | 'icon-truck-cities'
  | 'icon-home'
  | 'icon-HighWay'
  | 'icon-MultidestinyTruckIcon'
  | 'icon-holidayVillage'
  | 'icon-arrow-right'
  | 'icon-star'
  | 'icon-person-circle'
  | 'icon-lock'
  | 'icon-smart-phone'
  | 'icon-badge'
  | 'icon-vehicles'
  | 'icon-info'
  | 'icon-moving'
  | 'icon-movingCities'
  | 'icon-inCity'
  | 'icon-multiDestiny'
  | 'icon-betweenCities'
  | 'icon-auction'
  | 'icon-buildings--white'
  | 'icon-inter-city--in-filter'
  | 'icon-delivery--white'
  | 'icon-truck__lateral'
  | 'icon-truck__moving'
  | 'icon-percentaje--white';

const icons = new Map<IconTypes, any>();
icons.set('icon-document', ArticleOutlinedIcon);
icons.set('icon-calendar', CalendarMonthOutlinedIcon);
icons.set('icon-handsShake', HandshakeOutlinedIcon);
icons.set('icon-location-light', PlaceOutlinedIcon);
icons.set('icon-location-dark', LocationOnRoundedIcon);
icons.set('icon-truck', LocalShippingOutlinedIcon);
icons.set('icon-box', Inventory2OutlinedIcon);
icons.set('icon-success', TaskAltOutlinedIcon);
icons.set('icon-arrow-left', ArrowBackOutlinedIcon);
icons.set('icon-arrow-right', ArrowForwardIcon);
icons.set('icon-building', LocationCityOutlinedIcon);
icons.set('icon-metter', MetterIcon);
icons.set('icon-counterOfferMat', CounterOfferMatIcon);
icons.set('icon-sentimentVeryDissatisfied', SentimentVeryDissatisfiedIcon);
icons.set('icon-area', AreaIcon);
icons.set('icon-condition', ConditionIcon);
icons.set('icon-money', MoneyIcon);
icons.set('icon-kilos', KilosIcon);
icons.set('icon-people', GroupsOutlinedIcon);
icons.set('icon-timeline', TimelineOutlinedIcon);
icons.set('icon-timer', TimerOutlinedIcon);
icons.set('icon-share-location', ShareLocationOutlinedIcon);
icons.set('icon-truck-cities', MovingBetweenCitiesIcon);
icons.set('icon-home', HomePCIcon);
icons.set('icon-HighWay', HighWayIcon);
icons.set('icon-MultidestinyTruckIcon', MultidestinyTruckIcon);
icons.set('icon-holidayVillage', HolidayVillageOutlinedIcon);
icons.set('icon-star', StarIcon);
icons.set('icon-person-circle', AccountCircleOutlinedIcon);
icons.set('icon-lock', LockOutlinedIcon);
icons.set('icon-smart-phone', SmartphoneOutlinedIcon);
icons.set('icon-badge', BadgeOutlinedIcon);
icons.set('icon-vehicles', TransportationIcon);
icons.set('icon-info', InfoOutlinedIcon);
// iconos de los servicios de aviso y solicitud.
icons.set('icon-auction', AuctionIcon);
icons.set('icon-betweenCities', BetweenCitiesIcon);
icons.set('icon-inCity', InCityIcon);
icons.set('icon-movingCities', MovingCities);
icons.set('icon-moving', MovingIcon);
icons.set('icon-multiDestiny', MultiDestinyIcon);
icons.set('icon-buildings--white', InCityIcon);
icons.set('icon-inter-city--in-filter', BetweenCitiesIcon);
icons.set('icon-delivery--white', MultiDestinyIcon);
icons.set('icon-truck__lateral', MovingIcon);
icons.set('icon-truck__moving', MovingCities);
icons.set('icon-percentaje--white', AuctionIcon);

let iconSize = 16;
let iconColor = theme.palette.borderGray.contrastText;

const Icon = (props: IconProps) => {
  const { name, size, color, className, styles } = props;
  let iconInstance = icons.get((name || 'icon-document') as IconTypes);

  iconSize = 16;
  iconColor = theme.palette.borderGray.contrastText;

  switch (size) {
    case 'sm':
      iconSize = 20;
      break;

    case 'md':
      iconSize = 24;
      break;
    case 'lg':
      iconSize = 32;
      break;
    case 'xl':
      iconSize = 72;
      break;
    default:
      iconSize = size || 16;
      break;
  }

  switch (color) {
    case 'primary':
      iconColor = theme.palette.primary.main;
      break;

    case 'secondary':
      iconColor = theme.palette.secondary.main;
      break;
    case 'info':
      iconColor = theme.palette.info.light;
      break;
    case 'success':
      iconColor = theme.palette.success.main;
      break;
    case 'warning':
      iconColor = theme.palette.warning.main;
      break;
    case 'error':
      iconColor = theme.palette.error.main;
      break;
    case 'black':
      iconColor = '#000000';
      break;
    case 'org':
      iconColor = theme.palette.org.main;
      break;
    case 'white':
      iconColor = '#ffffff';
      break;
    case 'disabled':
      iconColor = theme.palette.text.disabled;
      break;
    default:
      iconColor = color || theme.palette.borderGray.contrastText;
      break;
  }

  const style = {
    color: iconColor,
    width: iconSize,
    height: iconSize,
    stroke: '#fff',
    strokeWidth: '2%',
    ...styles,
  };

  if (!iconInstance) iconInstance = icons.get('icon-document' as IconTypes);

  return React.createElement(iconInstance, {
    className,
    style,
  });
};

export default Icon;
