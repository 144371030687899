import { useAppSelector } from '@/hooks/useReducer';
import { UsuarioAPI } from '@/models/userAuth/user';
import { createServiceDataLayer } from '@/services/dataLayer/createNotice';
import { IAvisoCreadoData } from '@/services/noticeServices/noticeSendData/noticeSendDataTypesResponse';
import { useEffect } from 'react';
import { INoticeCreateListItem } from '../NoticeList/types';
import {
  PROFILE_NOTICE_DINAMIC_PRIVATE_PATH,
  PROFILE_NOTICE_LIST_PATH,
  SERVICES_LIST_DRIVER,
} from '@/consts/typeServicesPaths';
import { useNavigate } from 'react-router-dom';

const objectTypeNotice: Record<number, string> = {
  1: 'en-la-ciudad',
  2: 'multidestino',
  3: 'entre-ciudades',
  4: 'remate',
  5: 'mudanza',
};

const useNoticeCreatedHook = () => {
  const {
    noticeV2: { echoResponse, selectNotice },
    user: { user },
  } = useAppSelector((state) => state);
  const navigate = useNavigate();
  const selectedNotice = (selectNotice as INoticeCreateListItem) || null;
  const currentUser = user as UsuarioAPI;

  useEffect(() => {
    createServiceDataLayer(currentUser?.id || 0, selectedNotice?.title || '');
  }, []);

  const handleNavigate = () => {
      navigate(PROFILE_NOTICE_LIST_PATH, { replace: true });
  };

  const handleSeeNotice = () => {
    navigate(SERVICES_LIST_DRIVER, { replace: true });
  };

  return {
    echoResponse: echoResponse as IAvisoCreadoData,
    handleNavigate,
    handleSeeNotice,
  };
};

export default useNoticeCreatedHook;
