export interface DialogType {
  TITLE: string;
  MESSAGE: string;
  LEFT_BTN_TEXT: string;
  RIGHT_BTN_TEXT: string;
}

export const notifficationMsg = {
  TITLE: 'Retirar contraoferta',
  MESSAGE:
    'El cliente ya no verá el precio que ofreciste. ¿Quieres retirar la contraoferta?',
  LEFT_BTN_TEXT: 'NO, MANTENER',
  RIGHT_BTN_TEXT: 'SI, RETIRAR',
};

export const nottifficationQuotationMsg = {
  TITLE: 'Retirar cotización',
  MESSAGE:
    'El cliente ya no verá el precio que ofreciste. ¿Quieres retirar la cotización?',
  LEFT_BTN_TEXT: 'NO, MANTENER',
  RIGHT_BTN_TEXT: 'SI, RETIRAR',
};

export const nottifficationDeclineMsg = {
  TITLE: 'Rechazar servicio',
  MESSAGE: '¿Estás seguro de rechazar el servicio?',
  LEFT_BTN_TEXT: 'NO',
  RIGHT_BTN_TEXT: 'SÍ, RECHAZAR',
};

export const declineCounterOfferMsg = {
  TITLE: 'Rechazar servicio',
  MESSAGE: '¿Estás seguro de rechazar el servicio?',
  LEFT_BTN_TEXT: 'NO',
  RIGHT_BTN_TEXT: 'SI, RECHAZAR',
};

export const nottifficationDeclineMsgV2 = {
  title: 'Rechazar servicio',
  message: '¿Estás seguro de rechazar el servicio?',
  leftBtnText: 'NO',
  RightBtnText: 'SÍ, RECHAZAR',
};

export const nottifficationDeclineAgreementExpressMsg = (user: string) => {
  const result = {
    TITLE: 'Cancelar acuerdo',
    MESSAGE: `¿Quieres rechazar el acuerdo con el actual ${user}?`,
    LEFT_BTN_TEXT: 'NO',
    RIGHT_BTN_TEXT: 'SI, CANCELAR',
  };

  return result;
};

export const nottifficationDeclineQuotationMsg = {
  TITLE: 'Rechazar cotización',
  MESSAGE:
    'Estás a punto de rechazar la cotización del cliente. ¿Quieres retirar la cotización?',
  LEFT_BTN_TEXT: 'NO, MANTENER',
  RIGHT_BTN_TEXT: 'SI, RETIRAR',
};

export const nottifficationMultidestinyMsg = {
  TITLE: 'Cambio de Ciudad',
  MESSAGE:
    'Si cambias de ciudad se perderán el distrito de origen y distritos de destino. ¿Quieres cambiar de ciudad?',
  LEFT_BTN_TEXT: 'NO, MANTENER',
  RIGHT_BTN_TEXT: 'SI, CAMBIAR',
};

export const nottifficationEditNoticeRequest: DialogType = {
  TITLE: 'Edición de la solicitud',
  MESSAGE:
    'Esta solicitud tiene ofertas, al realizar un cambio, se rechazarán dichas ofertas, ¿Deseas continuar con la edición?',
  LEFT_BTN_TEXT: 'NO',
  RIGHT_BTN_TEXT: 'SI, CONTINUAR',
};
export const nottifficationRevomeNoticeRequest: DialogType = {
  TITLE: 'Cancelar solicitud',
  MESSAGE:
    'Al cancelar la solicitud se van a rechazar todas las ofertas que aún estén abiertas. ¿Deseas cancelar?',
  LEFT_BTN_TEXT: 'CERRAR',
  RIGHT_BTN_TEXT: 'CANCELAR',
};

export const nottifficationRevomeWithoutOfferNoticeRequest: DialogType = {
  TITLE: 'Cancelar solicitud',
  MESSAGE:
    'Al cancelar la solicitud se van a rechazar todas las ofertas que aún estén abiertas. ¿Deseas cancelar?',
  LEFT_BTN_TEXT: 'CERRAR',
  RIGHT_BTN_TEXT: 'CANCELAR',
};

export const nottifficationUnsavedChanges = {
  TITLE: 'Cambios sin guardar',
  MESSAGE: 'Aún no has guardado los cambios realizados, ¿Deseas salir?',
  LEFT_BTN_TEXT: 'NO, QUEDARME',
  RIGHT_BTN_TEXT: 'SI, SALIR',
};

export const nottifficationDeclineOfferDriver = {
  TITLE: 'Retirar negociación',
  MESSAGE:
    'Comunicaremos tu decisión a la otra parte. ¿Quieres retirar la negociación?',
  LEFT_BTN_TEXT: 'NO, MANTENER',
  RIGHT_BTN_TEXT: 'SI, RETIRAR',
};

export const nottifficationDeclineCounterOfferDriver = {
  TITLE: 'Retirar negociación',
  MESSAGE:
    'Comunicaremos tu decisión a la otra parte. ¿Quieres retirar la negociación?',
  LEFT_BTN_TEXT: 'NO, MANTENER',
  RIGHT_BTN_TEXT: 'SI, RECHAZAR',
};

export const nottifficationDeclineQuoteDriver = {
  TITLE: 'Retirar negociación',
  MESSAGE:
    'Comunicaremos tu decisión a la otra parte. ¿Quieres retirar la negociación?',
  LEFT_BTN_TEXT: 'NO, MANTENER',
  RIGHT_BTN_TEXT: 'SI, RECHAZAR',
};

export const nottifficationPriceChanged = {
  TITLE: 'Precio actualizado',
  MESSAGE:
    'El precio de la negociación fue modificado. Revísalo antes de continuar.',
  LEFT_BTN_TEXT: '',
  RIGHT_BTN_TEXT: 'DE ACUERDO',
};

export const nottifficationPriceChangedV2 = {
  title: 'Precio actualizado',
  message:
    'El precio de la negociación fue modificado. Revísalo antes de continuar.',
  leftBtnText: '',
  RightBtnText: 'DE ACUERDO',
};

// MENSAJE PARA LOS SNACKBARS

export const snackbarAcceptInCityMsg = {
  TITLE: 'Error al enviar la contraoferta. Por favor vuelve a intentar.',
  MESSAGE: 'error',
  LEFT_BTN_TEXT: '',
  RIGHT_BTN_TEXT: '',
};

export const snackbarCounterOfferMsg = {
  TITLE: 'Error al enviar la contraoferta. Por favor vuelve a intentar.',
  MESSAGE: 'error',
  LEFT_BTN_TEXT: '',
  RIGHT_BTN_TEXT: '',
};

export const snackbarErrorMesasage = {
  TITLE: 'Tenemos un problema ahora, inténtalo más tarde.',
  MESSAGE: 'error',
  LEFT_BTN_TEXT: '',
  RIGHT_BTN_TEXT: '',
};

// MENSAJE PARA SNACKBAR DE YA HAS RESPONDIDO ESTE AVISO

export const snackbarAlreadyAnswered = {
  TITLE: '¡Ya has respondido este aviso!',
  MESSAGE: 'warning',
  LEFT_BTN_TEXT: '',
  RIGHT_BTN_TEXT: '',
};

export const snackbarSavedChanges = {
  TITLE: 'Condiciones del servicio modificadas con éxito.',
  MESSAGE: 'success',
  LEFT_BTN_TEXT: '',
  RIGHT_BTN_TEXT: '',
};
