import { Avatar, Card, CardActionArea, styled } from "@mui/material";


export const CardNegInfoContainer = styled(Card)(() => ({
    display: "flex",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px 0px #ECEFF1"
}));

export const CardTouch = styled(CardActionArea)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    gap: "4px",
}));

export const SmallAvatar = styled(Avatar)(() => ({
    width: 48,
    height: 48,
    left: "-28px",
    top: "10px",
  }));