import { ID_OFFER } from '@/consts/defaultConsts';
import { NoticeDetailMultiDestinyModel } from '@/models/notice/noticeV2NegotationModels';
import { NoticeV2NegType } from '@/store/noticeV2Negotiation/noticeV2NegotiationSlice';
import { currencyFormat } from '@/utils/helpers';

export const handleTypeMultidestinyOfferNotice = (data: NoticeV2NegType) => {
  const newData = data as NoticeDetailMultiDestinyModel;
  const total =
    newData.idTipoAvisoNegociacion === ID_OFFER
      ? newData?.preciocalculado
      : newData?.precioCalculadoContraOferta;

  let result = {
    type: 'punto',
    amount: newData?.puntos || 0,
    total,
  };

  if (!!newData?.horas) {
    result = {
      ...result,
      type: 'hora',
      amount: newData?.horas || 0,
    };
  }

  return result;
};

export const handleTypeMultidestiny = (data: NoticeV2NegType) => {
  const newData = data as NoticeDetailMultiDestinyModel;
  let result = {
    type: '',
    amount: '',
    priceType: 0,
    total: 0,
    flgIsOwner: newData.flgIsOwner,
  };

  if (!!newData?.horas) {
    result = {
      type: 'hora',
      amount: newData.horas.toString(),
      priceType: newData.precio,
      total: newData?.preciocalculado as number,
      flgIsOwner: newData.flgIsOwner,
    };
  } else if (!!newData?.puntos) {
    result = {
      type: 'punto',
      amount: newData.puntos.toString(),
      priceType: newData.precio,
      total: newData?.preciocalculado as number,
      flgIsOwner: newData.flgIsOwner,
    };
  }

  return result;
};

type NoticeDataType = {
  type: string;
  amount: string;
  priceType: number;
  total: number;
  flgIsOwner: number;
};

export const handleDataToRender = ({
  noticeData,
  data,
  changeColor,
}: {
  noticeData: NoticeDataType;
  data: NoticeDetailMultiDestinyModel;
  changeColor?: boolean;
}) => {
  const newData = data as NoticeDetailMultiDestinyModel;
  const typeMulti =
    noticeData.type.charAt(0).toUpperCase() + noticeData.type.slice(1);
  return [
    {
      columnTitleSx: { fontSize: '12px', color: '#757575' },
      clumnSubtitleSx: { fontSize: '14px', color: '#757575' },
      title: {
        name: !!noticeData.flgIsOwner ? 'En tu aviso' : 'En el aviso',
        sx: { color: '#757575', fontSize: '16px' },
      },
      columnOne: {
        title: `Precio x ${noticeData.type}`,
        subtitle: `${currencyFormat(newData.aviso.precio)}`,
      },
      columnTwo: {
        title: `# ${typeMulti}s`,

        subtitle: !!newData.aviso?.puntosminimo
          ? `${newData.aviso?.puntosminimo as number}`
          : `${newData.aviso?.horasminimo as number}`,
      },
      columnThree: {
        title: `Precio total`,
        subtitle: `${currencyFormat(newData.aviso.preciocalculado)}`,
      },
    },
    {
      columnTitleSx: {
        fontSize: '12px',
        color: !!changeColor ? '#757575' : '',
      },
      clumnSubtitleSx: {
        fontSize: '14px',
        fontWeight: !!changeColor ? 'none' : 700,
        color: !!changeColor ? '#757575' : '',
      },
      title: {
        name: !!noticeData.flgIsOwner ? 'Cliente Solicita' : 'Tu oferta',
        sx: {
          fontWeight: !!changeColor ? 'none' : 600,
          color: !!changeColor ? '#757575' : '',
        },
      },
      columnOne: {
        title: `Precio x ${noticeData.type}`,
        subtitle: `${currencyFormat(noticeData.priceType)}`,
      },
      columnTwo: {
        title: `# ${typeMulti}s`,
        subtitle: `${noticeData.amount}`,
      },
      columnThree: {
        title: `Precio total`,
        subtitle: `${currencyFormat(noticeData.total)}`,
      },
    },
  ];
};
