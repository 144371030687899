import ConditionsListOffert from '@/components/conditionsList/ConditionsListOffert';

import DetailsTextV2 from '@/components/DetailsTextV2/Index';
import SingleUserNegV2 from '@/pages/noticeV2Negotation/components/SingleUserNegV2';
import {
  getEmailInRating,
  getNumberPhoneInRating,
  getWhatsappInRating,
} from '@/pages/noticeV2Negotation/shared/adapters/ratingsAdapters';
import { ConfigDataNegotationV2 } from '@/pages/noticeV2Negotation/shared/hooks/useNoticeConfigDataV2';
import {
  getFormatMetroNegV2,
  getImageTruckInRatingNoticeNegV2,
  getInfoVehicleNegV2,
  getMaximumCapacity,
  getUrlAvatarProfileNoticeNeg,
  getUserInfoNoticeNegV2,
  getValueInNegotationV2,
  renderTitleLongDestinyNegV2,
  renderTitleLongOriginNegV2,
  validUserNoticeNegV2,
} from '@/pages/noticeV2Negotation/shared/utils/utils';
import { Typography } from '@mui/material';
import SingleUorTFinished from '../../../components/SingleUorTFinished';
import { changeTitleOrNegotiation } from '@/pages/noticeV2Negotation/shared/adapters/configFinishedAdapters';

export const generalConfigData: ConfigDataNegotationV2[] = [
  {
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
      sx: { marginTop: '10px', display: 'block' },
      title: 'Detalles del acuerdo',
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'title',
        defaultResult: '',
        callback: changeTitleOrNegotiation,
      },
    ],
  },
  {
    Component: SingleUorTFinished,
    props: {
      containerSx: { mt: '16px' },
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'urlProfile',
        defaultResult: '',
        callback: getUrlAvatarProfileNoticeNeg,
      },
      {
        property: 'cliente',
        componentProp: 'showTwoAvatars',
        defaultResult: '',
        callback: validUserNoticeNegV2,
      },
      {
        property: 'cliente',
        componentProp: 'urlTruck',
        defaultResult: '',
        callback: getImageTruckInRatingNoticeNegV2,
      },
      {
        property: 'cliente',
        componentProp: 'userInfo',
        defaultResult: '',
        callback: getUserInfoNoticeNegV2,
      },
      {
        property: 'cliente',
        componentProp: 'userName',
        defaultResult: '',
        callback: getNumberPhoneInRating,
      },
      {
        property: 'cliente',
        componentProp: 'userEmail',
        defaultResult: '',
        callback: getEmailInRating,
      },
      {
        property: 'whatsapp',
        componentProp: 'whatsapp',
        defaultResult: '',
        callback: getWhatsappInRating,
      },
    ],
  },
  {
    title: 'Detalles del acuerdo',
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
      sx: { marginTop: '10px', display: 'block' },
    },
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha del servicio',

      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },

  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
      containerSx: {
        alignItems: 'flex-start',
      },
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'aviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: renderTitleLongOriginNegV2,
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-dark',
      title: 'Destino',
      containerSx: {
        alignItems: 'flex-start',
      },
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'aviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: renderTitleLongDestinyNegV2,
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-metter',
      title: 'Área',
    },
    valid: [
      {
        property: 'metraje',
        componentProp: 'show',
        defaultResult: false,
      },

      {
        property: 'metraje',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'metraje',
        componentProp: 'origen',
        defaultResult: '',
        callback: getFormatMetroNegV2,
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
      containerSx: {
        alignItems: 'flex-start',
      },
      sxDescription: {
        color: '#757575',
        fontSize: '14px',
      },
      parent: false,
    },
    valid: [
      {
        property: 'aviso',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'aviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: getInfoVehicleNegV2,
      },
      {
        property: 'aviso',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getMaximumCapacity,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      sx: {
        display: 'block',
        wordWrap: 'break-word',
        maxWidth: '320px',
        fontWeight: 'none',
      },
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '22px' },
    },
    valid: [
      {
        property: 'condiciones',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInNegotationV2,
        callbakcParams: ['condiciones'],
      },
      {
        property: 'condiciones',
        componentProp: 'listServices',
        defaultResult: [],
        callback: getValueInNegotationV2,
        callbakcParams: ['condiciones'],
      },
    ],
  },
];

export const detailsConfigAccept: ConfigDataNegotationV2[] = [
  ...generalConfigData,
];

export const detailsConfigCounterOffer: ConfigDataNegotationV2[] = [
  ...generalConfigData,
];
