import React from 'react'
import { ResponsiveStyleValue } from '@mui/system';
import { Button, Container, Grid, Typography, styled } from "@mui/material";
import { ResponsiveImage } from '@/interfaces/globalInterface';
import { styleCreatorMediaQuerys } from '@/utils/helpers';
import { HeaderCustomBanner } from './style';
import SectionLand from '../sectionLand/sectionLand';
import InfoLand from '../infoLand/InfoLand';
import useResize from '@/hooks/useResize';
import CustomInfoLand from '../CustomInfoLand';
import { BannerProps } from '../../Carrier/type';
import { secondFontFamily, thirdFontFamily } from "@/styles/theme";



interface CustomBannerProps {
  urlImg: string;
    urlTexture?: string | boolean;
    positionXY?: ResponsiveStyleValue<[key: string] | string>;
    position?: string;
    height?: string;
    minHeight?: string;
    maxHeight?: string;
    children?: React.ReactNode;
    responsive?: ResponsiveImage[];
    BannerHeroStyle?: any
    infoLand?: infoLandProps;
  }

  interface infoLandProps extends BannerProps {
    onClickButton?: (link?: string) => void;
  }
  type Props = { responsive?: ResponsiveImage[]; image?: string };
const BannerHero = styled(HeaderCustomBanner)<Props>(({ responsive = [], image = "" }) => ({
  backgroundImage: `url(${image})`,
  overflow: "hidden",
  ...styleCreatorMediaQuerys(responsive),
}));
const CustomBanner = ({
    urlImg,
    urlTexture = "./images/curve-lines.svg",
    positionXY = "center",
    position = "relative",
    minHeight = "146px",
    maxHeight = "166px",
    height = "",
    responsive,
    children,
    BannerHeroStyle,
    infoLand
  }: CustomBannerProps) => {

  const [width, isMobile] = useResize();

  return (
    <>
    <BannerHero
    sx={{
      position: { position },
      backgroundPosition: positionXY,
      minHeight,
      maxHeight,
      height,
      ...BannerHeroStyle
    }}
    image={urlImg}
    responsive={responsive}
  >
    {
      // 1279
      (infoLand?.title && (Number(width) > 1279)) && (
    <>

    <div
        style={{
          position: "absolute",
          width: "35%",
          height: "963px",
          flexShrink: 0,
          borderRadius: "963px",
          backgroundColor: "rgba(0, 0, 0, 0.70)",
          filter: "blur(50px)",
          transform: "scale(1.5)",
          top: "-50%",
        }}
      />
    {/* section info */}
    <Container maxWidth="xl" sx={{
      position: "relative"
    }}>
      <Grid
        container
        spacing={2}
        sx={{
          height: "100%",
          justifyContent: "center",
          padding: "0 20px",
        }}
      >
        <Grid item xs={12} md={6} sx={{
            padding: "55px 60px",
            marginTop: infoLand?.marginTop || "55px",
            gap: "24px",
            display: "flex",
            flexDirection: "column",
            // height: "100%",
        }}>
          <Typography variant="h4" color="white" sx={{
            fontFamily: thirdFontFamily,
            fontWeight: 700,
            fontSize: "34px",
            lineHeight: "40px",
            textTransform: "uppercase",
            }}>
            {infoLand?.title}
          </Typography>
          <Typography variant='label1' color="white"
            sx={{
                fontFamily: secondFontFamily,
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "20px",
            }}
          >
            {infoLand?.description}
          </Typography>
          <Button variant="contained" color="org"
            sx={{
                borderRadius: "44px",
                gap: "16px",
                padding: "0px 16px",
                width: "240px",
                height: "44px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textTransform: "uppercase",
                fontFamily: thirdFontFamily,
                fontWeight: 700,
                fontSize: "14px",
                lineHeight: "36px",
                fontStyle: "normal",
                textAlign: "center",
            }}
            onClick={()=> {infoLand?.onClickButton && infoLand?.onClickButton(infoLand?.btnLink)}}
          >
            {infoLand?.buttonText}
          </Button>
        </Grid>
        {/* desaparece en movil */}
        <Grid item xs={12} md={6}>
        </Grid>
      </Grid>
    </Container>
    </>
      )
    }
    {children}
  </BannerHero>
  {
    (infoLand?.title && (Number(width) <= 1279)) && (

  <SectionLand backgroundColor="#382E2B"
      sx={{
        padding: { xs: "32px 16px 16px 16px", sm: "32px 24px", lg: "64px" },
      }}
  >
        <CustomInfoLand
          title={infoLand?.title}
          subtitle={infoLand?.description || ""}
          sx={{ gap: "16px" }}

        >
          <Button variant="contained" color="org"
            sx={{
                borderRadius: "44px",
                gap: "16px",
                padding: "0px 16px",
                width: "100%",
                height: "44px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textTransform: "uppercase",
                fontFamily: thirdFontFamily,
                fontWeight: 700,
                fontSize: "14px",
                lineHeight: "36px",
                fontStyle: "normal",
                textAlign: "center",
            }}
            onClick={()=> {infoLand?.onClickButton && infoLand?.onClickButton(infoLand.btnLink)}}
          >
            {infoLand?.buttonText}
          </Button>
        </CustomInfoLand>
        </SectionLand>
    )
  }
        </>
  )
}

export default CustomBanner
