export const acceptDataRequestNegotiation = {
  title: '¡Aceptaron tu solicitud!',
  icon: 'icon-success',
  iconColor: 'success',
};

export const movingCounterOfferRequestNegotiation = {
  title: '¡Solicitaron una cotización!',
  icon: 'icon-counterOfferMat',
  iconColor: 'info',
};

export const counterOfferDataRequestNegotiation = {
  title: '¡Contraofertaron tu solicitud!',
  icon: 'icon-counterOfferMat',
  iconColor: 'info',
};

export const cocounterOfferDataRequestNegotiation = {
  title: '¡Contraofertaron tu servicio!',
  icon: 'icon-counterOfferMat',
  iconColor: 'info',
};

export const cocounterOfferDataRequestNegotiationClient = {
  title: '¡Contraofertaste el servicio!',
  icon: 'icon-counterOfferMat',
  iconColor: 'info',
};

export const handleTextStatusAlert = (textTypeNeg: string) => {
  const textStatusAlert: Record<number, string> = {
    1: `El cliente aún no responde tu ${textTypeNeg}`,
    2: `El transportista aún no responde tu ${textTypeNeg}`,
  };

  return textStatusAlert;
};
