import ConditionsListOffert from '@/components/conditionsList/ConditionsListOffert';
import DetailsTextV2 from '@/components/DetailsTextV2/Index';
import { ImagesNegV2 } from '@/pages/noticeV2Negotation/components/ImagesNegV2';
import { ConfigDataNegotationV2 } from '@/pages/noticeV2Negotation/shared/hooks/useNoticeConfigDataV2';
import { Typography } from '@mui/material';

import {
  getEmailInRating,
  getNumberPhoneInRating,
  getWhatsappInRating,
} from '@/pages/noticeV2Negotation/shared/adapters/ratingsAdapters';
import {
  getImageTruckInRatingNoticeNegV2,
  getInfoVehicleNegV2,
  getMaximumCapacity,
  getUrlAvatarProfileNoticeNeg,
  getUserInfoNoticeNegV2,
  getValueInNegotationV2,
  validUserNoticeNegV2,
} from '@/pages/noticeV2Negotation/shared/utils/utils';
import SingleUorTFinished from '../../../components/SingleUorTFinished';
import { actionsToNegotation } from '@/consts/actions';
import { NoticeV2NegType } from '@/store/noticeV2Negotiation/noticeV2NegotiationSlice';
import PriceMultidestiny from './components/PriceMulti';
import PriceMultiCounterOffer from './components/PriceMultiCounterOffer';
import { changeTitleOrNegotiation } from '@/pages/noticeV2Negotation/shared/adapters/configFinishedAdapters';
import { districtsDeliveryText, optionOne, optionTwo } from '@/consts/noticeConsts';

export const priceMultidestinyComponentToRenderFinishedAccept: Record<
  number,
  ({ data }: { data: NoticeV2NegType }) => JSX.Element
> = {
  [optionOne]: PriceMultidestiny,
  [optionTwo]: PriceMultiCounterOffer,
};

export const generalConfigData: ConfigDataNegotationV2[] = [
  {
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
      sx: { marginTop: '10px', display: 'block' },
      title: 'Detalles del acuerdo',
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'title',
        defaultResult: '',
        callback: changeTitleOrNegotiation,
      },
    ],
  },
  {
    Component: SingleUorTFinished,
    props: {
      containerSx: { mt: '16px' },
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'urlProfile',
        defaultResult: '',
        callback: getUrlAvatarProfileNoticeNeg,
      },
      {
        property: 'cliente',
        componentProp: 'showTwoAvatars',
        defaultResult: '',
        callback: validUserNoticeNegV2,
      },
      {
        property: 'cliente',
        componentProp: 'urlTruck',
        defaultResult: '',
        callback: getImageTruckInRatingNoticeNegV2,
      },
      {
        property: 'cliente',
        componentProp: 'userInfo',
        defaultResult: '',
        callback: getUserInfoNoticeNegV2,
      },
      {
        property: 'cliente',
        componentProp: 'userName',
        defaultResult: '',
        callback: getNumberPhoneInRating,
      },
      {
        property: 'cliente',
        componentProp: 'userEmail',
        defaultResult: '',
        callback: getEmailInRating,
      },
      {
        property: 'whatsapp',
        componentProp: 'whatsapp',
        defaultResult: '',
        callback: getWhatsappInRating,
      },
    ],
  },
  {
    title: 'Detalles del acuerdo',
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
      sx: { marginTop: '10px', display: 'block' },
    },
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha del servicio',

      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-light',
      title: districtsDeliveryText,
      isMultidestiny: true,
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'destinoTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },

  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
      containerSx: {
        alignItems: 'flex-start',
      },
      sxDescription: {
        color: '#757575',
        fontSize: '14px',
      },
      parent: false,
    },
    valid: [
      {
        property: 'aviso',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'aviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: getInfoVehicleNegV2,
      },
      {
        property: 'aviso',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getMaximumCapacity,
      },
    ],
  },
  {
    title: 'Descripción de la carga',
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
      sx: { marginTop: '10px', display: 'block' },
    },
  },
  {
    Component: ImagesNegV2,
    props: {
      style: { marginTop: '13px' },
    },
    valid: [
      {
        property: 'fotos',
        componentProp: 'images',
        defaultResult: [],
      },
    ],
  },

  {
    Component: Typography,
    props: {
      sx: {
        marginTop: '10px',
        display: 'block',
        wordWrap: 'break-word',
        maxWidth: '320px',
        fontWeight: 'none',
      },
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '22px' },
    },
    valid: [
      {
        property: 'condiciones',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInNegotationV2,
        callbakcParams: ['condiciones'],
      },
      {
        property: 'condiciones',
        componentProp: 'listServices',
        defaultResult: [],
        callback: getValueInNegotationV2,
        callbakcParams: ['condiciones'],
      },
    ],
  },
];

export const detailsConfigAccept: ConfigDataNegotationV2[] = [
  ...generalConfigData,
];

export const detailsConfigCounterOffer: ConfigDataNegotationV2[] = [
  ...generalConfigData,
];
