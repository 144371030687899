import { useAppSelector } from "@/hooks/useReducer";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";

import { optionFour, optionTwo } from "@/consts/noticeConsts";
import { dataNogotationAdapter } from "../../negotiation/negClientReceiveCounterOffer/counterOfferResponse/counterOfferAdapter";

export const useGetRequestData = () => {
  const {
    noticeNegotationRequest: { error, savedDataNogotation },
  } = useAppSelector((state) => state);

  const {
    validSnackbarDriver,
    validSnackbarClient,
    isOwner,
    typeRequest,
    tipoSolicitudNegociacionId,
    flgAbierto
  } = dataNogotationAdapter(savedDataNogotation as SolicitudNegociacion);
  const isCounterOffer = tipoSolicitudNegociacionId === optionTwo || tipoSolicitudNegociacionId === optionFour

  return {
    error,
    flgAbierto,
    data: savedDataNogotation,
    isCounterOffer,
    validSnackbarDriver,
    validSnackbarClient,
    isOwner,
    typeRequest,
    typeNoticeNegotationId: tipoSolicitudNegociacionId,
    redirect: savedDataNogotation?.redirect,
  };
};
