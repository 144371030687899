import { Box, FormControl, InputLabel, styled } from "@mui/material";

export const FormContainer = styled(FormControl)(
    () => ({
        minWidth: "120px"
    })
);

export const Label = styled(InputLabel)(
    ({ theme }) => ({
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: theme.typography.label1.fontSize,
        lineHeight: theme.typography.label3.lineHeight,
        fontFamily: theme.typography.label3.fontFamily,
        fontWeight: theme.typography.label1.fontWeight
    })
);



export const AddNewCarBox = styled(Box)(
    ({ theme }) => ({
        display: "flex",
        alignItems: "center",
        color: theme.palette.primary.main,
        padding: "4px",
        paddingLeft: "15px",
        cursor: "pointer",
        userSelect: "none",
        "&:hover": {
          backgroundColor: "#f5f5f5",
        },
    })
);