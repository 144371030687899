
import { Grid, Stack, Typography, styled } from "@mui/material";


export const TitleInfoDescription = styled(Typography)(
  ({ theme }) => `
      margin-top: 8px;
      text-align: center;
      font-size: ${theme.typography.body2.fontSize};
      line-height: ${theme.typography.body2.lineHeight};  
    `
);

export const ContainerInfo = styled(Grid)(
  () => `
      margin: 16px 0;
      padding-bottom: 16px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-bottom: 1px solid #eeeeee;
    `
);

export const BodyBox = styled(Stack)(
  () => `
    flex-direction: row;
    justify-content: center;
    align-items: center;
  gap: 8px;
  `
);

export const SectionBodyMulti = styled(BodyBox)(
  ({ theme }) => `
    margin: 0;
    padding: 8px 16px;
    width: 100%;
    border-top: 1px solid ${theme.palette.borderGray.main};
  `
);