import { actionsToNoticeNegotation } from '@/consts/actions';
import {
  ID_AUCTION_FLOW,
  ID_BETWEEN_CITIES,
  ID_MOVING_FLOW,
  ID_MULTI_DESTINY,
} from '@/consts/defaultConsts';
import { Negotiation, NoticeNegotiation } from '@/models/offert/offertModels';
import { VehicleAPI } from '@/models/vehicle/vehicleModel';
import { IUser } from '@/store/userRedux/userReduxInterface';
import {
  currencyFormat,
  searchPropertyInObject,
  stringHoursToFormatLT,
} from '@/utils/helpers';

import {
  NOTICE_CLIENT_PATH,
  NOTICE_CO_COUNTEROFFER,
} from '@/consts/acceptanceOffertPath';

import { getTemplateDeclined } from '@/adapters/store/negotationAdapter';
import {
  dataDriverFormat,
  dataProfileFormat,
} from '@/pages/acceptanceOffert/adapters/AcceptanceAdapter';
import { DataResponseEnglishTypeTwo } from '@/store/noticeV2ClientAcceptCounterOffer/interfaces/dataResponseEnglishInterfaces';

export const validIsClient = (offert: Negotiation, user: IUser) => {
  const { id } = user;

  return id === offert.cliente.id;
};

export const validIsDriver = (offert: Negotiation, user: IUser) => {
  const { id } = user;

  return id === offert.avisoNegociacionAviso.transportista.id;
};

export const getTypeUser = (offert: Negotiation, user: IUser) => {
  const isClient = validIsClient(offert, user);
  if (isClient) {
    return 'Datos del transportista';
  }

  return 'Datos del cliente';
};

export const getTypeUserDecline = (offert: Negotiation, user: IUser) => {
  const isClient = validIsClient(offert, user);
  if (isClient) {
    return 'Datos del transportista';
  }

  return 'Tu aviso';
};
export const getAvatarIdUser = (offert: Negotiation, user: IUser) => {
  const isClient = validIsClient(offert, user);
  let idUser = offert?.cliente.id;
  if (isClient) {
    idUser = offert?.avisoNegociacionAviso?.transportista?.id;
  }

  return idUser;
};
export const getUrlAvatarProfile = (offert: Negotiation, user: IUser) => {
  const isClient = validIsClient(offert, user);
  let avatarUrl = offert?.cliente.perfilUrl;
  if (isClient) {
    avatarUrl = offert?.avisoNegociacionAviso?.transportista?.perfilUrl;
  }

  return avatarUrl;
};

export const getUserNameRating = (offert: Negotiation, user: IUser) => {
  const isClient = validIsClient(offert, user);
  let userName = offert?.cliente.nombreyapellidos;
  if (isClient) {
    userName = offert?.avisoNegociacionAviso?.transportista?.nombreyapellidos;
  }

  return userName;
};

export const getNumberPhoneInRating = (offert: Negotiation, user: IUser) => {
  const isClient = validIsClient(offert, user);
  let phone = offert?.cliente?.username;
  if (isClient) {
    phone = offert?.avisoNegociacionAviso?.transportista?.username;
  }

  return phone;
};

export const getEmailInRating = (offert: Negotiation, user: IUser) => {
  const isClient = validIsClient(offert, user);
  let email = offert?.cliente?.email;
  if (isClient) {
    email = offert?.avisoNegociacionAviso?.transportista?.email;
  }

  return email;
};

export const getFechaSalilda = (offert: Negotiation, user: IUser) => {
  const { avisoNegociacionAviso } = offert;
  const fechaSalida = avisoNegociacionAviso?.fechafija;
  const hora = avisoNegociacionAviso?.hora;

  return `${fechaSalida} - ${hora}`;
};

export const getImageTruckInRating = (offert: Negotiation, user: IUser) => {
  const isClient = validIsClient(offert, user);
  if (!isClient) return '';
  const { vehiculo } = offert.avisoNegociacionAviso;
  const [imageTruck] = vehiculo?.vehiculofotos;

  return imageTruck?.direccion as string;
};

export const formatDataProfile = (offert: Negotiation, user: IUser) => {
  const isClient = validIsClient(offert, user);
  if (isClient) {
    return dataProfileFormat(offert);
  }
  return dataDriverFormat(offert);
};

export const getInfoVehicle = (offert: Negotiation) => {
  const {
    avisoNegociacionAviso: { vehiculo },
  } = offert;
  const typeVehicle = vehiculo?.tipovehiculo?.nombre;

  return `${typeVehicle} ${vehiculo?.placa}`;
};

export const showVehicleInOffert = (offert: Negotiation, user: IUser) => {
  const isClient = validIsClient(offert, user);

  return !isClient;
};

export const getTypeByPrice = (offert: Negotiation) => {
  // TODO: Falta mapear cuando es contra oferta
  const isHour = !!offert?.horas && offert?.horas > 0;
  const isPoint = !!offert?.puntos && offert?.puntos > 0;
  const isMoving =
    offert.avisoNegociacionAviso?.tipoaviso?.id === ID_MOVING_FLOW;
  const isMovingOffert = isMoving;
  const isBetweenCities =
    offert.avisoNegociacionAviso?.tipoaviso?.id === ID_BETWEEN_CITIES;

  if (offert.avisoNegociacionAviso?.tipoaviso?.id === ID_AUCTION_FLOW)
    return '';

  if (isHour) {
    return 'Precio por hora';
  } else if (isPoint) {
    return 'Precio por punto';
  } else if (isMovingOffert) {
    return 'Precio por viaje';
  } else if (isBetweenCities) {
    return '';
  } else {
    return 'Precio de punto a punto';
  }
};

export const getFormatPrice = (offert: Negotiation) => {
  const { precio, preciocontraoferta, ultimaAvisoNegociacionAcciones } = offert;
  let formatPrice = currencyFormat(precio);
  const { estado, id } =
    ultimaAvisoNegociacionAcciones?.tipoAvisoNegociacionAccion;
  if (estado.id === 2 || estado.id === 5) {
    if (!!preciocontraoferta) {
      formatPrice = currencyFormat(preciocontraoferta);
    }

    return `Acordado en ${formatPrice}`;
  } else if (id === 5) {
    formatPrice = currencyFormat(preciocontraoferta as number);
  }

  return formatPrice;
};

export const getFormatEditionPrice = (offert: Negotiation) => {
  const { precio, preciocontraoferta } = offert;
  let result = `Contraferta S/${precio}`;
  if (!!preciocontraoferta) result = `Contraferta S/${preciocontraoferta}`;

  return result;
};

export const getFormatSendDate = (offert: Negotiation) => {
  const template = `Enviada el :date a las :dateTime`;
  const { fechaAcuerdo, horaAcuerdo } = offert;
  const hourLT = stringHoursToFormatLT(horaAcuerdo);

  const result = template
    .replace(':date', fechaAcuerdo)
    .replace(':dateTime', hourLT);

  return result;
};

export const getFormatNoticeSendDate = (offert: Negotiation) => {
  const { fecha } = offert;
  const dateHour = fecha;

  const result = dateHour;

  return result;
};

export const getFormatDateDateRegister = (offert: Negotiation) => {
  const template = `Enviada el :date a las :dateTime`;
  let { fechaRegistro, horaRegistro } = offert;
  horaRegistro = stringHoursToFormatLT(horaRegistro);

  const result = template
    .replace(':date', fechaRegistro)
    .replace(':dateTime', horaRegistro);

  return result;
};

export const getValueInOffert = (
  offer: Negotiation,
  aditionalsParams?: any
) => {
  const value = searchPropertyInObject(aditionalsParams, offer);

  return value;
};

export const getTitleOriginAndDestiny = (offert: Negotiation) => {
  const {
    avisoNegociacionAviso: { origentexto, destinotexto },
  } = offert;
  const title = `De ${origentexto} a ${destinotexto}`;

  return title;
};

export const getVehicleValues = (offer: Negotiation) => {
  const avisoNegociacionAviso =
    offer?.avisoNegociacionAviso as NoticeNegotiation;
  const vehiculo = avisoNegociacionAviso.vehiculo as VehicleAPI;
  const isMoving = avisoNegociacionAviso.tipoaviso.id === ID_MOVING_FLOW;
  const isCounterOffer = (offer.tipoAvisoNegociacion?.id as number) === 2;
  let title = `${vehiculo.tipovehiculo.nombre} ${vehiculo.placa}`;

  if (isMoving && isCounterOffer) {
    title = `${vehiculo.tipovehiculo.nombre}`;
  }

  return title;
};

export const getMaxWeightValues = (offert: Negotiation) => {
  const {
    avisoNegociacionAviso: { pesomaximo },
  } = offert;
  const title = `${pesomaximo} kilos`;

  return title;
};
export const getEstimatedWeightValues = (offert: Negotiation) => {
  const { pesoEstimado } = offert;
  const title = `${pesoEstimado} kilos`;

  return title;
};

export const getMaxWeigth = (offer: Negotiation) => {
  const avisoNegociacionAviso =
    offer?.avisoNegociacionAviso as NoticeNegotiation;
  const pesomaximo = avisoNegociacionAviso.pesomaximo as number;

  let title = `${pesomaximo} kilos`;

  return title;
};

export const getRefrigeratedLoad = ({
  avisoNegociacionAviso: { flgcargarefrigerada },
}: Negotiation) => {
  const title = !!flgcargarefrigerada ? 'Si' : '';

  return title;
};

export const getCounterOffertFormatSendDate = (offert: Negotiation) => {
  const template = `Enviada el :date a las :dateTime`;
  const {
    fechaContraTransportista = '',
    horaContraTransportista = '',
    fechaRegistro = '',
    horaRegistro = '',
  } = offert;
  const hourLT = stringHoursToFormatLT(horaContraTransportista);
  const hourLTTwo = stringHoursToFormatLT(horaRegistro);

  const result = template
    .replace(':date', fechaContraTransportista || fechaRegistro)
    .replace(':dateTime', hourLT || hourLTTwo);

  return result;
};

export const getCounterofferFormatSendDate = (offert: Negotiation) => {
  const template = `Enviada el :date a las :dateTime`;
  const { fechaRegistro = '', horaRegistro = '' } = offert;
  const hourLT = stringHoursToFormatLT(horaRegistro);

  const result = template
    .replace(':date', fechaRegistro)
    .replace(':dateTime', hourLT);

  return result;
};

export const showRequestDetail = (
  _: number,
  value: any,
  noticeResponse: DataResponseEnglishTypeTwo
) => {
  const { originText = '', destinyText = '' } = noticeResponse;
  if (!!originText || !!destinyText) {
    return 'Detalles de la solicitud';
  }
  return '';
};

export const showDetailTitle = (offert: Negotiation) => {
  const { fechaAcuerdo, fecha, origenTexto, destinoTexto } = offert;
  if (fechaAcuerdo || fecha || origenTexto || destinoTexto) {
    return 'Detalles del acuerdo';
  }

  return '';
};

export const showByTypeNotice = (offert: Negotiation, idTypeNotice: number) => {
  const { tipoaviso } = offert?.avisoNegociacionAviso;

  return tipoaviso.id === idTypeNotice;
};

export const isDifferentFromAuction = (offert: Negotiation) => {
  const { tipoaviso } = offert?.avisoNegociacionAviso;

  return tipoaviso.id !== ID_AUCTION_FLOW;
};

export const resetMarginTopInAuctionFlow = (offert: Negotiation) => {
  const isDifferent = isDifferentFromAuction(offert);

  return !isDifferent ? { marginTop: 0 } : {};
};

export const resetMarginTopByFun = (
  offert: Negotiation,
  validFunc: (offert: Negotiation) => any
) => {
  const isValid = validFunc(offert);

  return !!isValid ? {} : { marginTop: 0 };
};

export const getNoticeNumberOFHelpers = ({
  avisoNegociacionAviso,
}: Negotiation) => {
  if (!avisoNegociacionAviso?.ayudantes) return;

  const quantity = avisoNegociacionAviso?.ayudantes || 0;
  const template = ':quantity (incluido en el precio)';

  return template.replace(':quantity', quantity.toString());
};

export const getNumberOFHelpers = (offert: Negotiation) => {
  const {
    avisoNegociacionAviso: { ayudantes },
  } = offert;
  if (ayudantes === undefined) return '';

  const template = ':quantity (incluido en el precio)';

  return template.replace(':quantity', ayudantes.toString());
};

export const getNumberOFInMovingNoticeHelpers = (offert: Negotiation) => {
  const ayudantes = offert?.avisoNegociacionAviso?.ayudantes;
  if (ayudantes === undefined) return '';

  const template = ':quantity (incluido en el precio)';

  return template.replace(':quantity', ayudantes.toString());
};

export const getFormatMetro = (offer: Negotiation) => {
  const { metraje } = offer;
  if (!metraje) return '';

  return `${metraje} m².`;
};

export const getHoursAndPrice = (offert: Negotiation) => {
  const {
    horas,
    avisoNegociacionAviso: { precioxhora },
  } = offert;

  return `${horas} (S/ ${precioxhora} por hora)`;
};

export const getPointsAndPrice = (offer: Negotiation) => {
  const {
    puntos,
    avisoNegociacionAviso: { precioxpunto },
  } = offer;

  return `${puntos} (S/ ${precioxpunto} por punto)`;
};

export const getHourPointPriceText = (savedOffer: any) => {
  const {
    solicitud: { flghoraopunto },
  } = savedOffer;
  const type = flghoraopunto === 1 ? 'hora' : 'punto';

  const title = `Precio por ${type}`;

  return title;
};

export const getVehicleValueNotification = (offer: Negotiation) => {
  const { avisoNegociacionAviso } = offer;
  const tipoVehiculo = avisoNegociacionAviso.vehiculo;
  const title = !!tipoVehiculo && `${tipoVehiculo?.tipovehiculo.nombre} `;

  return title;
};

export const getTitleCounterOfferPrice = (offer: Negotiation) => {
  const { precio } = offer;
  const title = `S/ ${precio}`;

  return title;
};

export const getTitleCounterOfferPriceCLient = (offer: Negotiation) => {
  const { precio } = offer;

  let title = `Contraoferta de S/ ${precio}`;

  return title;
};

export const getTitleOfferPriceCLient = (offer: Negotiation) => {
  const { precio, avisoNegociacionAviso } = offer;
  const flgHoraPunto = avisoNegociacionAviso?.flghoraopunto as number;
  const priceHour = avisoNegociacionAviso?.precioxhora as number;
  const pricePoint = avisoNegociacionAviso?.precioxpunto as number;
  const typeNotice = avisoNegociacionAviso?.tipoaviso?.id as number;

  let title = ` S/ ${precio}`;

  if (typeNotice === 2) {
    title = flgHoraPunto === 1 ? ` S/ ${priceHour}` : ` S/ ${pricePoint}`;
  }

  return title;
};

export const getTitleCounterOfferDriverPrice = (offer: Negotiation) => {
  const { preciocontraoferta } = offer;

  let title = `Contraoferta de S/ ${preciocontraoferta}`;

  return title;
};

export const getTitleCounterOfferPriceQuote = (offer: Negotiation) => {
  const { precio, preciocontraoferta } = offer;

  const isPrecio = !!precio;
  let precioText = precio as number;

  if (!isPrecio) precioText = preciocontraoferta as number;
  if (!precioText) return '';

  let title = `Cotizado en S/ ${precioText}`;

  return title;
};

export const getTitleOfferTitleQuote = (offer: Negotiation) => {
  const { ultimaAvisoNegociacionAcciones, flgIsOwner } = offer;
  const idAction = ultimaAvisoNegociacionAcciones.tipoAvisoNegociacionAccion.id;

  let title = `Tu cotización`;
  if (idAction === 12) title = '';
  if (idAction === 7)
    title = flgIsOwner === 1 ? 'Tu cotización' : 'Cotización del Transportista';

  return flgIsOwner === 1 ? title : 'Cotización del Transportista';
};

export const getTitleCounterOfferPriceDriver = (offer: Negotiation) => {
  //TODO: revisar porque está faltando el precio del transportista
  const { precio, avisoNegociacionAviso } = offer;

  let title = `Contraoferta de S/ ${precio}`;
  const pricePerHour = avisoNegociacionAviso?.precioxhora;
  const pricePerPoint = avisoNegociacionAviso?.precioxpunto;
  const isMultidestiny =
    avisoNegociacionAviso.tipoaviso.id === ID_MULTI_DESTINY;
  const hourOrPoint = avisoNegociacionAviso?.flghoraopunto === 1;

  if (isMultidestiny) {
    if (hourOrPoint) title = `Contraoferta de S/ ${pricePerHour}`;
    else title = `Contraoferta de S/ ${pricePerPoint}`;
  }

  return title;
};

export const getDeclineCounterOffertFormatDate = (offer: Negotiation) => {
  // const template = `Rechazada el :date a las :dateTime`;
  const {
    fechaRechazo = '',
    horaRechazo = '',
    // anulado
    fechaAnulada = '',
    horaAnulada = '',
    // retiro
    fechaRetiro = '',
    horaRetiro = '',
    ultimaAvisoNegociacionAcciones: {
      tipoAvisoNegociacionAccion: {
        estado: { id: idEstado },
      },
    },
  } = offer;
  const hourLT = stringHoursToFormatLT(
    horaRechazo || horaAnulada || horaRetiro
  );

  return getTemplateDeclined(
    idEstado,
    fechaRechazo || fechaAnulada || fechaRetiro,
    hourLT,
    'aviso'
  );

  // const result = template.replace(":date", fechaRechazo).replace(":dateTime", hourLT);

  // return result;
};

export const getHelpers = (offer: Negotiation) => {
  // TODO:
  const { ayudantes } = offer;
  const title = `${ayudantes}`;

  return title;
};

export const getVehicleMovingNotificationAcceptance = (offer: Negotiation) => {
  const {
    avisoNegociacionAviso: {
      vehiculo: { tipovehiculo, placa },
    },
  } = offer;
  const title = !!tipovehiculo && `${tipovehiculo.nombre} ${placa}  `;

  return title;
};

export const getTitleAvisoIsOwner = (offert: Negotiation) => {
  const { flgIsOwner } = offert;
  if (flgIsOwner === undefined) return '';
  return flgIsOwner === 1 ? 'Tu Aviso' : 'Aviso de transportista';
};

export const getTitleAvisoIsOwnerCounterOffert =
  (type?: number) => (offert: Negotiation) => {
    const { flgIsOwner } = offert;
    if (flgIsOwner === undefined) return '';
    if (type === 1)
      return flgIsOwner === 1
        ? 'Tu contraoferta'
        : 'Contraoferta de transportista';
    return flgIsOwner === 1 ? 'Contraoferta del cliente' : 'Tu contraoferta';
  };

export const handleTypeTextFormat = (
  pathname: string,
  isOwner: number,
  isMoving: boolean
) => {
  let textToReturn = '';
  const validateUrl = pathname.includes(NOTICE_CO_COUNTEROFFER);
  const isAvisos = pathname.startsWith(NOTICE_CLIENT_PATH);

  if (validateUrl && isOwner === 1) textToReturn = 'Contraoferta del cliente';
  if (validateUrl && isOwner === 0) textToReturn = 'Tu contraoferta';

  if (validateUrl && isAvisos && isMoving)
    textToReturn = 'Tu solicitud de mudanza';

  return textToReturn;
};

export const handleValidDriveRender = (
  savedOffert: Negotiation,
  isMoving: boolean
) => {
  const isClientOffer =
    savedOffert?.ultimaAvisoNegociacionAcciones.tipoAvisoNegociacionAccion
      .id === actionsToNoticeNegotation[1].id;
  const isClientCounterOffer =
    savedOffert?.ultimaAvisoNegociacionAcciones.tipoAvisoNegociacionAccion
      .id === actionsToNoticeNegotation[2].id;

  return (isMoving && isClientOffer) || (isMoving && isClientCounterOffer);
};

export const renderRatingAvisoClient = (savedOffert: Negotiation) => {
  return {
    ...dataProfileFormat(savedOffert),
  };
};
