import { styled, Container } from "@mui/material";

export const ContainerContact = styled(Container)(() => ({
  marginTop: "16px",
  marginBottom: "16px",
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  minHeight: "65vh",
}));

export const Form = styled("form")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
}));
