import { getListOfferServices } from '@/services/negotiationServices';
import { AppDispatch } from '../store';
import {
  setError,
  setIsLoadingNegotiations,
  setListNegotiations,
} from './offerListSlice';
import { getMessaggeError } from '../auth/utils';

export const getListOfferThunk = (idOffer: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoadingNegotiations(true));

    try {
      const { avisonegociacionData } = await getListOfferServices(idOffer);

      dispatch(setListNegotiations(avisonegociacionData.avisonegociaciones));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    } finally {
      dispatch(setIsLoadingNegotiations(false));
    }
  };
};
