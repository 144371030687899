import { useAppSelector } from "@/hooks/useReducer";
import { IAvisoCreadoData } from "@/services/noticeServices/noticeSendData/noticeSendDataTypesResponse";
import BodySuccessBetweenMoving from "./BodySuccessBetweenMoving";
import BodySuccessInCity from "./BodySuccessInCity";
import BodySuccessIntercitySale from "./BodySuccessIntercitySale";
import BodySuccessMultiHandP from "./BodySuccessMultiHandP";

const useBodySuccessTypeHook = () => {

    const {
        echoResponse
    } = useAppSelector((state) => state.noticeV2);

    const Component = objectRenderComponent[(echoResponse as IAvisoCreadoData)?.avisoscreados[0]?.idtipoaviso];
  
    return {
        Component,
    }
}

export default useBodySuccessTypeHook;


const objectRenderComponent: Record<number, () => JSX.Element> = {
    1: BodySuccessInCity,
    2: BodySuccessMultiHandP,
    3: BodySuccessInCity,
    4: BodySuccessIntercitySale,
    5: BodySuccessBetweenMoving,
  };