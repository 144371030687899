import { styled } from "@mui/material";

export const HeaderBanner = styled("div")(
  () => `
    background: #534C34;  
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;      
  `
);

export const CurveLines = styled("img")(
  () => `
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    object-fit: contain;
    object-position: 0 100%;
    @media (max-width: 600px) { 
        display: none;
    }
  `
);
