import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import { CLIENT_TYPE } from "@/consts/defaultConsts";
import {
  NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_DRIVER,
  NEGOTATION_REJECTED_NEGOTATION_MESSAGE_CLIENT,
} from "@/consts/negotationFlowPath";
import { maxLengthPrice } from "@/consts/noticeConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { BodyUploadPriceNegotationPrice } from "@/services/noticeNegotationRequestServices";
import { updatePriceNegotationThunk } from "@/store/noticeNegotationRequestRedux/noticeNegRequesThunk";
import { FormControl, Grid, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IUser } from "../../../../../store/userRedux/userReduxInterface";

const Container = styled(Grid)(
  () => `
    display: flex;
    flex-direction: column;  
    margin-top: 24px;
  `
);
const FormCounterOfferExpress = () => {
  const { idRequest = "", typeRequest = "" } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    price: "",
    minutes: "",
  });

  const {
    user: { user },
    noticeNegotationRequest: { isLoading, isEdition, savedDataNogotation },
  } = useAppSelector((state) => state);

  const isClient = (user as IUser).tipousuario === CLIENT_TYPE

  useEffect(() => {
    if (isEdition) {
      setForm({
        price: savedDataNogotation?.precio.toString() as string,
        minutes: `${savedDataNogotation?.tiempoestimado as number}`,
      });
    }
    return () => {};
  }, []);

  const handleOnchange = ({ currentTarget }: EventType["change"]) => {
    const { value } = currentTarget;
    const prepareValue = value.replace(/^(0+)/g, "").replace(/[^0-9]/g, "");

    if (prepareValue.length <= maxLengthPrice) {
      setForm({ ...form, [currentTarget.name]: prepareValue });
    }
  };

  const handleCompareInputWithDB = () => {
    return (
      form.price === `${(savedDataNogotation as SolicitudNegociacion)?.precio}`
    );
  };

  const handleDisabledEditCounterOfferButton = () => {
    const conditions = [form.price];

    if (handleCompareInputWithDB()) return true;

    return conditions.some((condition) => !condition);
  };

  const handleEditCounterOfferSubmit = (event: EventType["submit"]) => {
    event.preventDefault();
    if (isLoading) return;

    const body: BodyUploadPriceNegotationPrice = { precio: Number(form.price) };
    const pathClient = NEGOTATION_REJECTED_NEGOTATION_MESSAGE_CLIENT.replace(
      ":typeOffer",
      "contraoferta"
    ).replace(":typeRequest", typeRequest);
    const pathDriver =
      NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_DRIVER.replace(
        ":typeOffer",
        "contraoferta"
      ).replace(":typeRequest", idRequest);
    const path = isClient ? pathClient : pathDriver;
    dispatch(updatePriceNegotationThunk(idRequest, body)).then(() =>
      navigate(path, { replace: true, state: { isNavigationInFlow: true } })
    );
  };

  return (
    <Container container>
      <form onSubmit={handleEditCounterOfferSubmit}>
        <FormControl sx={{ marginTop: "16px", width: "100%" }}>
          <Input
            id={`$1-price`}
            name="price"
            label={<LabelInputRequired str="Precio ofrecido (S/)" />}
            value={form.price}
            variant="standard"
            placeholder="Monto ofrecido por el servicio"
            onChange={handleOnchange}
            onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()} 
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
            }}
          />
        </FormControl>

        <Grid item xs={12} sx={{ marginTop: "16px" }}>
          <ButtomLoader
            type="submit"
            isLoading={isLoading}
            buttomProps={{
              disabled: handleDisabledEditCounterOfferButton() || isLoading,
            }}
          >
            ENVIAR
          </ButtomLoader>
        </Grid>
      </form>
    </Container>
  );
};

export default FormCounterOfferExpress;
