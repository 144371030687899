import { styled } from "@mui/material";

export const HeaderCustomBanner = styled("div")(
  () => `
    background: #534C34;  
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;      
  `
);
