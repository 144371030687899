import React from "react";
import { CoCounterOfferLayout } from "./CoCounterOfferLayout";
import {
  ID_AUCTION_FLOW,
  ID_BETWEEN_CITIES,
  ID_IN_CITY,
  ID_MOVING_FLOW,
  ID_MULTI_DESTINY,
} from "@/consts/defaultConsts";
import { InTheCityCoCounterOfferV2 } from "./containers/InTheCityCoCounterOffer/Index";
import { BtnCitiesCoCounterOfferV2 } from "./containers/BtnCitiesCoCounterOffer/Index";
import { MultidestinyCoCounterOfferV2 } from "./containers/MultidestinyCoCounterOffer/Index";
import { MovingCoCounterOfferV2 } from "./containers/MovingCoCounterOffer/Index";
import { AuctionCoCounterOfferV2 } from "./containers/AuctionCoCounterOffer/Index";
import { useAppDispatch } from "@/hooks/useReducer";
import { useParams } from "react-router-dom";
import {
  NoticeV2NegType,
  resetDetailsNoticeSlice,
} from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { getNegotiationByIdThunk } from "@/store/noticeV2Negotiation/noticeV2NegotiationThunk";
import BasicModal from "@/components/dialog/Notiffication";
import useGetData from "../../shared/hooks/useGetData";
import SkeletonViewService from "@/pages/loading/SkeletonViewService";

export const componentToRender: Record<number, () => JSX.Element> = {
  [ID_IN_CITY]: InTheCityCoCounterOfferV2,
  [ID_BETWEEN_CITIES]: BtnCitiesCoCounterOfferV2,
  [ID_MULTI_DESTINY]: MultidestinyCoCounterOfferV2,
  [ID_MOVING_FLOW]: MovingCoCounterOfferV2,
  [ID_AUCTION_FLOW]: AuctionCoCounterOfferV2,
};

export const CoCounterOfferPrincipalComponent = () => {
  const { notice, isLoadingNoticeV2 } = useGetData();
  const dispatch = useAppDispatch();
  const { idOffer = "" } = useParams();


  React.useEffect(() => {
    dispatch(resetDetailsNoticeSlice());
    dispatch(getNegotiationByIdThunk(Number(idOffer)));
  }, []);

  if (isLoadingNoticeV2 || !notice) return <SkeletonViewService />;

  const Component =
    componentToRender[(notice as NoticeV2NegType)?.idtipoaviso as number];

  if (!Component) return <></>;

  return (
    <>
      <CoCounterOfferLayout>
        <Component />
      </CoCounterOfferLayout>
      <BasicModal />
    </>
  );
};
