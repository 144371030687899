import { Typography } from '@mui/material';
import { ResponseAuctionNoticeOffer } from '@/models/offert/offertModels';
import DetailsTextV2 from '@/components/DetailsTextV2/Index';
import { ImagesThumbnailV2 } from '@/pages/flowNoticeNegotation/components/ImagesThumbnailV2';
import {
  getFormatPrice,
  showDetailDescound,
} from '@/pages/flowNoticeNegotation/shared/utils/callbackOfferAuction';
import DetailsText from '@/pages/offerts/offertConfirmation/components/DetailsText';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DestailDescount from '@/pages/flowNoticeNegotation/components/destailDescount/DestailDescount';

interface ValuesToReplace {
  search: string;
  value: keyof ResponseAuctionNoticeOffer;
  defaultResult: string;
}

export interface ValidFormat {
  template: string;
  valuesToReplace: ValuesToReplace[];
}

interface ConfigDataBodyOffertValid {
  property: keyof ResponseAuctionNoticeOffer;
  componentProp: string;
  defaultResult: boolean | string | number | Array<any> | Object;
  format?: ValidFormat;
  callback?: (
    tipoAvisoNegociacion: number,
    value: any,
    offert: ResponseAuctionNoticeOffer
  ) => any;
}

export interface ConfigDataBodyAuctionOffert {
  title?: string;
  Component: any;
  props: any;
  valid?: ConfigDataBodyOffertValid[];
  children?: JSX.Element | JSX.Element[] | string;
}

export const configDataAuction: ConfigDataBodyAuctionOffert[] = [
  {
    children: 'Detalles del servicio',
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
      sx: { marginTop: '10px', display: 'block' },
    }
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: "icon-calendar",
      title: 'Fecha',
      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
      containerSx: {
        alignItems: 'flex-start',
      },
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'origenTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referenciaOrigen',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-dark',
      title: 'Destino',
      containerSx: {
        alignItems: 'flex-start',
      },
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'destinoTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  {
    Component: Typography,
    children: 'Descripción de la carga',
    props: {
      variant: 'subtitle1medium',
      sx: { marginTop: '10px', display: 'block' },
    },
  },
  {
    Component: ImagesThumbnailV2,
    props: {
      style: { marginTop: '13px' },
    },
    valid: [
      {
        property: 'fotos',
        componentProp: 'images',
        defaultResult: [],
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'body1',
      marginTop: '4px',
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'children',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DestailDescount,
    props: {
      sx: {
        marginTop: '16px',
      },
    },
    valid: [
      {
        property: 'idtipoavisonegociacion',
        componentProp: 'show',
        defaultResult: false,
        callback: showDetailDescound,
      },
      {
        property: 'precioregular',
        componentProp: 'beforePrice',
        defaultResult: 0,
      },
      {
        property: 'precio',
        componentProp: 'currentPrice',
        defaultResult: 0,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'h5',
      sx: { textTransform: 'inherit' },
    },
    valid: [
      {
        property: 'precio',
        componentProp: 'children',
        defaultResult: 0,
        callback: getFormatPrice,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      constainerSx: { marginTop: '0' },
      title: 'Peso del remate',
      show: true,
    },
    valid: [
      {
        property: 'pesoEstimado',
        componentProp: 'show',
        defaultResult: '',
      },
      {
        property: 'pesoEstimado',
        componentProp: 'origen',
        defaultResult: '',
        format: {
          template: ':weight kilos',
          valuesToReplace: [
            {
              search: ':weight',
              value: 'pesoEstimado',
              defaultResult: '',
            },
          ],
        },
      },
    ],
  },
];
