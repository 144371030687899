import { getNegotationsListServices } from '@/services/userServices';
import { AppDispatch, RootState } from '../store';
import { getMessaggeError } from '../auth/utils';
import {
  setErrorActive,
  setErrorExpired,
  setErrorHistory,
  setIsLoadingActive,
  setIsLoadingExpired,
  setIsLoadingHistory,
  setMyNegotationsActives,
  setMyNegotationsExpired,
  setMyNegotationsHistory,
  updateListActives,
  updateListExpired,
  updateListHistory,
} from './myListNegotiationsSlice';
import { TypeFilterEnum } from '@/models/userAuth/agrementToAccept';
import { prepareBodyByTypeFilterAdapter } from '@/adapters/store/myNegotationsListAdapter';

const objectFunByType = {
  [TypeFilterEnum.abiertas]: {
    setLoading: setIsLoadingActive,
    setList: setMyNegotationsActives,
    updateList: updateListActives,
    setError: setErrorActive,
  },
  [TypeFilterEnum.cerradas]: {
    setLoading: setIsLoadingHistory,
    setList: setMyNegotationsHistory,
    updateList: updateListHistory,
    setError: setErrorHistory,
  },
  [TypeFilterEnum.vencidas]: {
    setLoading: setIsLoadingExpired,
    setList: setMyNegotationsExpired,
    updateList: updateListExpired,
    setError: setErrorExpired,
  },
};

type Props = {
  isPaginate?: boolean;
  signal?: AbortSignal;
};
export const getMyNegotationsLitsThunk = (
  typeFilter: TypeFilterEnum,
  props?: Props
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { setLoading, setError, setList, updateList } =
      objectFunByType[typeFilter];
    dispatch(setLoading(true));
    try {
      const state = getState();
      const body = prepareBodyByTypeFilterAdapter(state, typeFilter);
      if (!body) {
        dispatch(setLoading(false));
        return;
      }

      const { negociacionesPorUsuario } = await getNegotationsListServices(
        body,
        props?.signal
      );
      if (props?.isPaginate) {
        dispatch(updateList(negociacionesPorUsuario));
      } else {
        dispatch(setList(negociacionesPorUsuario));
      }
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    } finally {
      dispatch(setLoading(false));
    }
  };
};
