interface Props {
    style: {
        color?: string
        height: string
        width: string
    }
}
const AuctionIcon = (props: Props) => {

    const { color = "#757575", height, width } = props?.style;
    return (
        <svg width={width} height={height} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.6587 8C16.905 8 14.9775 10.2029 14.7021 12.6812L10.021 55.3624C9.74561 58.116 12.2239 60.3189 14.9775 60.3189H32.05C32.8761 60.3189 33.4268 59.7682 33.4268 58.9421C33.4268 58.116 32.8761 57.5653 32.05 57.5653H14.9775C13.876 57.5653 12.7746 56.7392 12.7746 55.6377L17.4558 12.9565C17.4558 11.5797 18.2819 10.7536 19.6587 10.7536H38.9341C40.0355 10.7536 40.8616 11.5797 41.1369 12.9565L43.3399 34.4348C43.3399 35.2609 44.1659 35.8116 44.7167 35.5363C45.5428 35.5363 46.0935 34.7102 45.8181 34.1595L43.6152 12.6812C43.3399 10.2029 41.4123 8 38.6587 8C38.934 8 19.6587 8 19.6587 8Z" fill={color} />
            <path d="M29.2972 15.1594C28.4711 15.1594 27.9204 15.7101 27.9204 16.5362V20.942C27.9204 21.7681 28.4711 22.3188 29.2972 22.3188C30.1233 22.3188 30.674 21.7681 30.674 20.942V16.5362C30.674 15.7101 30.1233 15.1594 29.2972 15.1594Z" fill={color} />
            <path d="M29.2972 25.3478C28.4711 25.3478 27.9204 25.8985 27.9204 26.7246V31.1304C27.9204 31.9565 28.4711 32.5072 29.2972 32.5072C30.1233 32.5072 30.674 31.9565 30.674 31.1304V26.7246C30.674 25.8985 30.1233 25.3478 29.2972 25.3478Z" fill={color} />
            <path d="M29.2972 35.8115C28.4711 35.8115 27.9204 36.3622 27.9204 37.1883V41.5941C27.9204 42.4202 28.4711 42.9709 29.2972 42.9709C30.1233 42.9709 30.674 42.4202 30.674 41.5941V37.1883C30.674 36.3622 30.1233 35.8115 29.2972 35.8115Z" fill={color} />
            <path d="M29.2972 46C28.4711 46 27.9204 46.5507 27.9204 47.3768V51.7826C27.9204 52.6087 28.4711 53.1594 29.2972 53.1594C30.1233 53.1594 30.674 52.6087 30.674 51.7826V47.3768C30.674 46.8261 30.1233 46 29.2972 46Z" fill={color} />
            <path d="M43.3398 51.2319C46.0935 51.2319 48.2964 49.029 48.2964 46.2754C48.2964 43.5217 46.0935 41.3188 43.3398 41.3188C40.5862 41.3188 38.3833 43.5217 38.3833 46.2754C38.3833 49.029 40.5862 51.2319 43.3398 51.2319ZM43.3398 45.1739C43.8906 45.1739 44.4413 45.7246 44.4413 46.2754C44.4413 46.8261 43.8906 47.3768 43.3398 47.3768C42.7891 47.3768 42.2384 46.8261 42.2384 46.2754C42.5137 45.4493 42.7891 45.1739 43.3398 45.1739Z" fill={color} />
            <path d="M57.3838 65C60.1374 65 62.3403 62.7971 62.3403 60.0434C62.3403 57.2898 60.1374 55.0869 57.3838 55.0869C54.6302 55.0869 52.4272 57.2898 52.4272 60.0434C52.4272 62.7971 54.6302 65 57.3838 65ZM57.3838 59.2174C57.9345 59.2174 58.4852 59.7681 58.4852 60.3188C58.4852 60.8695 57.9345 61.4202 57.3838 61.4202C56.8331 61.4202 56.2823 60.8695 56.2823 60.3188C56.5577 59.4927 56.8331 59.2174 57.3838 59.2174Z" fill={color} />
            <path d="M38.9331 64.4493C39.2084 64.7246 39.7592 65 40.3099 65C40.8606 65 41.4113 64.7246 41.6867 64.4493L61.7881 44.3478C62.6142 43.5217 62.6142 42.4203 61.7881 41.5942C60.9621 40.7681 59.8606 40.7681 59.0345 41.5942L38.9331 61.6956C38.3823 62.5217 38.3823 63.8985 38.9331 64.4493Z" fill={color} />
        </svg>
    )
}

export default AuctionIcon
