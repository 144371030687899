import ConditionsListOffert from '@/components/conditionsList/ConditionsListOffert';
import GenericComponent from '@/components/genericComponent/GenericComponent';
import { descriptionLoad } from '@/consts/noticeConsts';
import DetailsTextMoving from '@/pages/customerRequest/application/components/DetailsTextMoving';
import { ConfigDataNegotation } from '@/pages/noticeRequestFlow/interface/noticeNegRequestInterface';
import { DetailTypeFlows } from '@/pages/noticeRequestFlow/utils/utilsComponents';
import {
  getCounterOffertFormatSendDate,
  getDeclineCounterOffertFormatDate,
  getFormatSendDateExpress,
  getMaxWeigth,
  getRefrigeratedLoad,
  getTimeAproxToArrival,
  getTitleOriginAndDestiny,
  getTitlePrice,
  getValueInOffert,
  getVehicleValueNotification,
  getVehicleValues,
} from '@/pages/noticeRequestFlow/utils/utilsNegotation';
import DetailsText from '@/pages/offerts/offertConfirmation/components/DetailsText';
import { Box, Typography } from '@mui/material';

// ==================================================
//          CONFIGURACION EXPRESS
// ==================================================

export const configDataDriverOneExpress: ConfigDataNegotation[] = [
  {
    title: 'Solicitud del cliente',
    Component: Typography,
    props: {
      variant: 'body2',
      sx: { color: '#757575', mb: '4px' },
    },
  },
  {
    Component: DetailTypeFlows,
    props: {},
    valid: [
      {
        property: 'solicitud',
        componentProp: 'nombre',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'tipoSolicitud', 'nombre'],
      },
      {
        property: 'solicitud',
        componentProp: 'id',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'tipoSolicitud', 'id'],
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'title',
        defaultResult: '',
        callback: getTitleOriginAndDestiny,
      },
    ],
  },
];

export const configDataDriverTwoExpress: ConfigDataNegotation[] = [
  {
    title: '',
    Component: Box,
    props: {
      variant: 'body2',
      sx: { width: '100%', mt: '16px' },
    },
  },

  {
    title: '',
    Component: Box,
    props: {
      variant: 'body2',
      sx: { width: '100%', mt: '16px' },
    },
  },
  {
    title: 'Detalles del acuerdo',
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
    },
  },

  {
    Component: DetailsTextMoving,
    props: {
      icon: 'icon-calendar--white',
      title: 'Fecha del servicio',
      originDescription: '',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'fecha'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getFormatSendDateExpress,
        callbakcParams: ['solicitud', 'fecha'],
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-location-medium',
      title: 'Origen',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'origentexto'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'origentexto'],
      },
      {
        property: 'referencia',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'referencia'],
      },
    ],
  },

  {
    Component: DetailsText,
    props: {
      icon: 'icon-location-medium',
      title: 'Destino',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'destinotexto'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'destinotexto'],
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'referenciaDestino'],
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-car-medium',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getVehicleValues,
        callbakcParams: ['solicitud', 'tipoVehiculo', 'nombre'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getVehicleValueNotification,
      },
    ],
  },
  {
    title: '',
    Component: Box,
    props: {
      variant: 'body2',
      sx: { width: '100%', mt: '16px' },
    },
  },
  {
    title: descriptionLoad,
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
    },
  },
  {
    Component: GenericComponent,
    props: {
      icon: '',
      title: '',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'descripcionCarga'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'descripcionCarga'],
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-balance-medium',
      title: 'Peso estimado',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getMaxWeigth,
        callbakcParams: ['solicitud', 'pesoestimado'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getMaxWeigth,
        callbakcParams: ['solicitud', 'pesoestimado'],
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-snowFlake-medium',
      title: 'Necesita refrigeración',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'flgcargarefrigerada'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getRefrigeratedLoad,
        callbakcParams: ['solicitud', 'flgcargarefrigerada'],
      },
    ],
  },
];

export const cofigDataDriverConditionsExpress: ConfigDataNegotation[] = [
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '22px' },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'condiciones'],
      },
      {
        property: 'solicitud',
        componentProp: 'listServices',
        defaultResult: [],
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'condiciones'],
      },
    ],
  },
];

export const configDataDriverThreeExpress: ConfigDataNegotation[] = [
  {
    Component: Typography,
    title: 'Tu oferta',
    props: {
      variant: 'body2',
      sx: { color: '#757575', mt: '4px' },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-car-medium',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getVehicleValues,
        callbakcParams: ['solicitud', 'tipoVehiculo', 'nombre'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getVehicleValues,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'body2',
      sx: { fontSize: '14px', mt: 2 },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'title',
        defaultResult: '',
        callback: getTimeAproxToArrival,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'h4',
      sx: { fontSize: '22px' },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'title',
        defaultResult: '',
        callback: getTitlePrice,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'body2',
      sx: { color: '#757575', mb: '4px' },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'title',
        defaultResult: '',
        callback: getCounterOffertFormatSendDate,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'body2',
      sx: { color: 'red', mb: '4px' },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'title',
        defaultResult: '',
        callback: getDeclineCounterOffertFormatDate,
      },
    ],
  },
];

export const ConfigOfferExpressDriver = {
  1: configDataDriverOneExpress,
  2: configDataDriverTwoExpress,
  3: cofigDataDriverConditionsExpress,
  4: configDataDriverThreeExpress,
};
