import { useAppSelector } from "@/hooks/useReducer";
import { NoticeProfile } from "@/models/notice/noticeListOffersModels";
import { Avatar, Box, Grid, Rating, Skeleton, Typography } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { NOTICE_DRIVER_PATH } from "../../consts/acceptanceOffertPath";
import { NEGOTATION_CLIENT_PATH } from "../../consts/negotationFlowPath";
import TwoAvatars from "./TwoAvatars";
import HistoryRating from "./historyRating/HistoryRating";
import { RatingStyles } from "./styledUserRating";
import { resizePhoto } from "@/utils/helpers";
import { IMG_PATH } from "@/consts/defaultConsts";
import StarIcon from '@mui/icons-material/Star';

export interface Props {
  data: NoticeProfile;
  containerSx?: any;
  viewRating?: boolean;
  colorName?: string;
  viewHistory?: boolean;
  sm?: number;
}

const TransporterRating = ({
  data,
  containerSx,
  colorName = "primary.main",
  sm = 1,
  viewRating,
  viewHistory = true,
}: Props) => {
  const { urlProfile, userName, showTwoAvatars, rating, ratingText, sx } = data;
  const { avatarStyles, gridContainerStyles, gradeTextStyles } = RatingStyles;

  const urlUser = !!urlProfile
    ? resizePhoto({ photo: IMG_PATH + urlProfile })
    : IMG_PATH + urlProfile;

  const { isLoading } = useAppSelector((state) => state.ratingAgreement);

  const [openRating, setOpenRating] = useState<boolean>(false);
  const location = useLocation();
  const { pathname } = location;
  const isMyAviso = pathname.startsWith(NOTICE_DRIVER_PATH);
  const isSolicitud = pathname.startsWith(NEGOTATION_CLIENT_PATH);

  const onOpenRating = () => {
    if (!viewRating) return;
    setOpenRating(true);
  };

  const onCloseRating = () => {
    setOpenRating(false);
  };

  if (viewHistory && (isMyAviso || isSolicitud)) {
    return null;
  }

  return (
    <>
      <Grid
        container
        mb={2}
        sx={{ ...containerSx, cursor: viewRating ? "pointer" : "default" }}
        onClick={onOpenRating}
        gap={0}
      >
        <Grid item xs={2} sm={3} sx={{display: "flex", flexBasis: showTwoAvatars ? "" : "18% !important"}}>
          {isLoading ? (
            <Skeleton variant="circular" width={48} height={48} />
          ) : showTwoAvatars ? (
            <TwoAvatars primaryAvatar="/static/images/avatar/2.jpg" />
          ) : (
            <Avatar src={urlUser} sx={avatarStyles} />
          )}
        </Grid>
        <Grid
          item
          xs={9}
          sx={{
            ...gridContainerStyles,
            mt: 0,
          }}
        >
          {isLoading ? (
            <Skeleton variant="rectangular" width={220} height={64} />
          ) : (
            <>
              <Typography
                variant="label1"
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  color: colorName,
                }}
              >
                {userName}
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Rating
                  readOnly
                  name="customized-10"
                  defaultValue={2}
                  max={1}
                  size="small"
                  sx={!!rating ? {} : { color: "#9e9e9e" }}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
                <Typography variant="caption" sx={gradeTextStyles}>
                  {!!rating && rating}{" "}
                  {!!ratingText && `(${ratingText})`}
                </Typography>
              </Box>
            </>
          )}
        </Grid>
      </Grid>

      <HistoryRating
        open={openRating}
        onClose={onCloseRating}
        userData={data}
      />
    </>
  );
};

export default TransporterRating;
