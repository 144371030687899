import { actionsToNegotation } from "@/consts/actions";
import { CLIENT_TYPE } from "@/consts/defaultConsts";
import {
  NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_CLIENT,
  NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_DRIVER,
} from "@/consts/negotationFlowPath";
import {
  cocounterofferPriceMaxLength,
  optionThree,
  textCannotLowerPrice,
  textCannotUpperPrice,
} from "@/consts/noticeConsts";
import { snackbarCounterOfferMsg } from "@/consts/notifficationsMessage";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import { counterOfferClientAdapter } from "@/pages/noticeRequestFlow/adapters/noticeRequestFlowAdapters";
import { noticeNegotationRequestObjectTypes } from "@/pages/noticeRequestFlow/consts/noticeRequestFlowConsts";
import { useButtonsFunctions } from "@/pages/noticeRequestFlow/hooks/useHandleGlobalButtons";
import useUnsavedAndSavedChanges from "@/pages/noticeRequestFlow/hooks/useUnsavedAndSavedChanges";
import { cocounterRequest } from "@/services/dataLayer/createRequestNeg";
import { BodyUploadPriceNegotationPrice } from "@/services/noticeNegotationRequestServices";
import {
  saveRequestNogotationByIdThunk,
  updatePriceNegotationThunk,
} from "@/store/noticeNegotationRequestRedux/noticeNegRequesThunk";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const useFormClientCounterOfferBetweenCities = () => {
  const { handleSnackbarError } = useUnsavedAndSavedChanges();
  const { addNotiffication } = useNotiffication();
  const [price, setPrice] = useState("");
  const [errorPrice, setErrorPrice] = useState("");
  const { idRequest = "" } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    user: { user },
    noticeNegotationRequest: {
      error,
      isLoading,
      selectedNegotationTypeRequest,
      savedDataNogotation,
      isEdition,
    },
  } = useAppSelector((state) => state);
  const idAccion = actionsToNegotation[selectedNegotationTypeRequest.id].id;
  const isClient = (user as IUser).tipousuario === CLIENT_TYPE;
  const userId = (user as IUser).id;
  const typeNegotation = savedDataNogotation?.solicitud.tipoSolicitud
    ?.nombre as string;
  const { handleModalPriceChanged } = useButtonsFunctions();

  useEffect(() => {
    if (isEdition) {
      setPrice(
        `${(savedDataNogotation as SolicitudNegociacion)?.precioContraoferta}`
      );
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (!!error) {
      handleManageDialog();
      handleSnackbarError();
    }
  }, [error]);

  const handleSubmit = (event: EventType["submit"]) => {
    event.preventDefault();

    const dataAdapter = counterOfferClientAdapter(idAccion, Number(price));

    const body = {
      ...dataAdapter,
      precioactual: savedDataNogotation?.precio,
    };

    const preceFromRequest = savedDataNogotation?.solicitud.precio as number;
    const newPrice = Number(price);

    if (newPrice < preceFromRequest / 2) {
      setErrorPrice(textCannotLowerPrice);
      return;
    }

    if (newPrice > preceFromRequest * 3) {
      setErrorPrice(textCannotUpperPrice);
      return;
    }

    dispatch(saveRequestNogotationByIdThunk(idRequest, body)).then((value) => {
      if (!!value.flgpreciocambiado) {
        handleModalPriceChanged(() => handleGoBackPage());
      } else {
        cocounterRequest(userId, typeNegotation);
        handleNextRute();
      }
    });
  };

  const handleNextRute = () => {
    let indexToSearch =
      selectedNegotationTypeRequest.id === actionsToNegotation[7].id
        ? optionThree
        : selectedNegotationTypeRequest.id;
    const valueToReplace = noticeNegotationRequestObjectTypes[indexToSearch];
    const idValueToReplace = idRequest.toString();
    dispatch(
      getNextRoute(navigate, {
        valueToSearch: ":typeRequest",
        valueToReplace,
        idToSearch: ":idRequest",
        idValueToReplace,
      })
    );
  };

  const handleDisabledButton = () => {
    const conditions = [price];

    return conditions.some((condition) => !condition);
  };

  const handleOnchange = ({ currentTarget }: EventType["change"]) => {
    const { value } = currentTarget;
    setErrorPrice("");
    const prepareValue = value.replace(/^(0+)/g, "").replace(/[^0-9]/g, "");

    setPrice(prepareValue);
  };

  const handleManageDialog = () => {
    addNotiffication({
      title: snackbarCounterOfferMsg.TITLE,
      message: snackbarCounterOfferMsg.MESSAGE,
      onSubmit: async () => {},
    });
  };

  const handleCompareInputWithDB = () => {
    return (
      price ===
      `${(savedDataNogotation as SolicitudNegociacion)?.precioContraoferta}`
    );
  };

  const handleDisabledEditCounterOfferButton = () => {
    const conditions = [price];

    if (handleCompareInputWithDB()) return true;

    return conditions.some((condition) => !condition);
  };

  const handleEditCounterOfferSubmit = (event: EventType["submit"]) => {
    event.preventDefault();
    if (isLoading) return;

    const body: BodyUploadPriceNegotationPrice = { precio: Number(price) };
    const pathClient =
      NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_CLIENT.replace(
        ":typeOffer",
        "contraoferta"
      ).replace(":typeRequest", idRequest);
    const pathDriver =
      NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_DRIVER.replace(
        ":typeOffer",
        "contraoferta"
      ).replace(":typeRequest", idRequest);

    const path = isClient ? pathClient : pathDriver;
    dispatch(updatePriceNegotationThunk(idRequest, body)).then(() => {
      navigate(path, { replace: true });
    });
  };

  const handleGoBackPage = () => {
    navigate(-1);
  };

  return {
    price,
    errorPrice,
    isLoading,
    isEdition,
    handleSubmit,
    handleNextRute,
    handleDisabledButton,
    handleOnchange,
    handleManageDialog,
    handleCompareInputWithDB,
    handleDisabledEditCounterOfferButton,
    handleEditCounterOfferSubmit,
  };
};
