import { SharedRide } from '@/models/shareTravel/shareTravelModel';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type InitialState = {
  sharedRide: SharedRide | null;
  isLoading: boolean;
  error: string;
};

const initialState: InitialState = {
  sharedRide: null,
  isLoading: false,
  error: '',
};

export const sharedRideSlice = createSlice({
  name: 'sharedRide',
  initialState,
  reducers: {
    setLoading: (
      state,
      { payload }: PayloadAction<InitialState['isLoading']>
    ) => {
      state.isLoading = payload;
    },
    setSharedRide: (
      state,
      { payload }: PayloadAction<InitialState['sharedRide']>
    ) => {
      state.sharedRide = payload;
      state.error = '';
    },
    setError: (state, { payload }: PayloadAction<InitialState['error']>) => {
      state.error = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setSharedRide, setError } = sharedRideSlice.actions;
