import BasicAlert from "@/components/alerts/BasicAlert";
import HeaderSectionV2 from "@/components/headerSectionV2/HeaderSectionV2";
import SectionIconV2 from "@/components/sectionIconV2/SectionIconV2";
import { ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import { districtsDeliveryText } from "@/consts/noticeConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { MergeDetailNoticeModel } from "@/models/notice/noticeModels";
import { ContainerArea } from "@/pages/createServices/suggestions/styleSuggestions";
import {
  REQUEST_QUOTE_DESTINY_PATH,
  REQUEST_QUOTE_ORIGIN_PATH,
} from "@/pages/flowNoticeNegotation/shared/const/routes/clientAccepOrCounteroffer";
import { getPrevRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { Container } from "@mui/material";
import { useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getHeaderType } from "./config";

const RuteDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const isRequestQuoteOrigin = pathname.endsWith(REQUEST_QUOTE_ORIGIN_PATH);
  const isRequestQuoteDestiny = pathname.endsWith(REQUEST_QUOTE_DESTINY_PATH);

  const { selectTypeNotice, idTypeNoticeNegotiation, error } = useAppSelector(
    (state) => state.noticeV2ClientAorCO
  );

  const notice = selectTypeNotice as MergeDetailNoticeModel;
  const idTypeNotice = notice.tipoaviso?.id;

  const handlePreviusRute = () => {
    dispatch(
      getPrevRoute(navigate, {
        valueToReplace: notice.id?.toString(),
        valueToSearch: ":id",
      })
    );
  };

  const handleGetMetaData = () => {
    const id = notice.tipoaviso?.id;
    let metaData = getHeaderType(id, idTypeNoticeNegotiation, pathname);
    if (isRequestQuoteOrigin || isRequestQuoteDestiny) {
      metaData = {
        ...metaData,
        subTitle: "Solicitar cotización",
      };
    }

    return metaData;
  };

  const { subTitle = "", title = "" } = useMemo(
    () => handleGetMetaData(),
    [pathname]
  );

  const handleTitle = () => {
    return idTypeNotice === ID_MULTI_DESTINY ? districtsDeliveryText : title;
  };

  return (
    <Container maxWidth="xs">
      <HeaderSectionV2
        icon="icon-arrow-left"
        title={handleTitle()}
        subTitle={subTitle}
        onClick={handlePreviusRute}
      />
      <ContainerArea>
        <SectionIconV2 icon="icon-location-light" />
        {error && (
          <BasicAlert severity="error" sx={{ padding: "16px", mb: 2 }}>
            {error}
          </BasicAlert>
        )}
        <Outlet />
      </ContainerArea>
    </Container>
  );
};

export default RuteDetails;
