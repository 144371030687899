import { NoticeDetailAuctionModel } from "@/models/notice/noticeV2NegotationModels";
import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import { CustomCardV2 } from "@/pages/noticeV2Negotation/shared/styles";
import { Box, Grid, Typography } from "@mui/material";
import { handleDataAuctionCoCounterOfferToRender } from "../../../counterOfferDetails/containers/AuctionNoticeV2Neg/config";

export const PriceAuctionCoCounter = () => {
  const { notice } = useGetData();
  const newData = notice as NoticeDetailAuctionModel;
  const dataToRender = handleDataAuctionCoCounterOfferToRender(newData);

  return (
    <Box>
      {dataToRender.map((item, i) => (
        <CustomCardV2 sx={{ mt: 2 }} key={i}>
          <Typography sx={item.title.sx}>{item.title.text}</Typography>
          <Grid container>
            <Grid xs={6}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={item.sxSubtitleColum}>
                  {item.columnOne.subtitle}
                </Typography>
                <Typography sx={item.sxSubtitleValueColum}>
                  {item.columnOne.value}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={item.sxSubtitleColum}>
                  {item.columnTwo.subtitle}
                </Typography>
                <Typography sx={item.sxSubtitleValueColum}>
                  {item.columnTwo.value}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CustomCardV2>
      ))}
    </Box>
  );
};
