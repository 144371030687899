import HeaderSection from "@/components/headerSection/HeaderSection";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import { TYPE_FLOW } from "@/pages/customerRequest/shared/consts/routes";
import { ID_MOVING_FLOW } from "@/consts/defaultConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { CustomerListTypeRequestDTO } from "@/interfaces/customerRequestInterface";
import { createRequestSetTipeDataLayer } from "@/services/dataLayer/createRequest";
import { setFlgCiudadoEntreciudades } from "@/store/customerRequestRedux/customerRequestSlice";
import { initRouteFlow } from "@/store/routeActionsFlow/routeActionsFlowNoticeSlice";
import {
  getNextRoute,
  getPrevRoute,
} from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { replaceValue } from "@/utils/helpersActions";
import { Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { customerRequestObjectTypes } from "../../shared/consts/customerRequestConsts";

import {
  MovingObj,
  TypeOfFlow,
  emptyObj,
  handleResetOriginAndDestiny,
} from "./config";

import {
  ButtonStyled,
  ContainerArea,
  IconStyled,
  SubContainer,
} from "./typeStyles";
import Icon, { IconTypes } from "@/utils/Icon";

const Type = () => {
  const {
    customerRequest: { listCustomerTypeRequest, selectedTypeRequest },
    user: { user },
  } = useAppSelector((state) => state);
  const [typeOfFlow, setTypeOfFlow] = useState<TypeOfFlow>(emptyObj);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const path = replaceValue(
    TYPE_FLOW,
    ":typeRequest",
    customerRequestObjectTypes[2]
  );
  let obj = MovingObj;

  useEffect(() => {
    if (selectedTypeRequest.id <= 0) navigate(-1);

    dispatch(setFlgCiudadoEntreciudades(0));
    handleResetOriginAndDestiny(dispatch);
    createRequestSetTipeDataLayer((user as IUser).id, selectedTypeRequest.id);
    setTypeOfFlow(obj);

    return () => { };
  }, []);

  const handlePrevRute = () => {
    const valueToReplace = customerRequestObjectTypes[selectedTypeRequest.id];
    dispatch(
      getPrevRoute(navigate, { valueToSearch: ":typeRequest", valueToReplace })
    );
  };

  const handleClick = (
    item: CustomerListTypeRequestDTO,
    pathArray?: string[],
    numselect?: number
  ) => {
    const valueToReplace = customerRequestObjectTypes[selectedTypeRequest.id];
    dispatch(
      setFlgCiudadoEntreciudades(
        selectedTypeRequest.id === ID_MOVING_FLOW && numselect ? numselect : 0
      )
    );
    dispatch(initRouteFlow(pathArray || []));
    dispatch(
      getNextRoute(navigate, {
        valueToSearch: ":typeRequest",
        valueToReplace: valueToReplace,
      })
    );
  };

  return (
    <Container maxWidth="xs">
      <HeaderSection
        icon="icon-arrow-left--primary"
        title={typeOfFlow.title}
        subTitle={typeOfFlow.subTitle}
        onClick={handlePrevRute}
      />
      <ContainerArea>
        {/* <SectionIcon icon={typeOfFlow.icon} /> */}
        <Icon name={typeOfFlow.icon as IconTypes} size="xl" color="info"
          styles={{
            width: "100%",
          }}
        />
        <SubContainer container>
          <Typography sx={{ textAlign: "start" }}>
            {typeOfFlow.question}
          </Typography>
          <Grid container spacing={2} sx={{ pt: 3 }}>
            <Grid item xs={12}>
              <ButtonStyled
                sx={{ height: "45px" }}
                fullWidth
                onClick={() => {
                  handleClick(
                    listCustomerTypeRequest[typeOfFlow.id],
                    typeOfFlow?.firstBtn?.pathArray,
                    typeOfFlow?.firstBtn.id
                  );
                }}
              >
                {/* <IconStyled className={typeOfFlow.firstBtn.icon} /> */}
                <Icon name={typeOfFlow?.firstBtn?.icon} size="lg" color="info" />
                <Typography>{typeOfFlow.firstBtn.text}</Typography>
              </ButtonStyled>
            </Grid>
            <Grid item xs={12}>
              <ButtonStyled
                sx={{ height: "45px" }}
                fullWidth
                onClick={() => {
                  handleClick(
                    listCustomerTypeRequest[typeOfFlow.id],
                    typeOfFlow?.secondBtn?.pathArray,
                    typeOfFlow?.secondBtn.id
                  );
                }}
              >
                {/* <IconStyled className={typeOfFlow.secondBtn.icon} /> */}
                <Icon name={typeOfFlow?.secondBtn?.icon} size="lg" color="info"/>
                <Typography>{typeOfFlow.secondBtn.text}</Typography>
              </ButtonStyled>
            </Grid>
          </Grid>
        </SubContainer>
      </ContainerArea>
    </Container>
  );
};

export default Type;
