import CustomTags from "@/components/customTags/CustomTags";
import useObserver from "@/hooks/useObserver";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { getMyRequestsToClientsThunk } from "@/store/userRedux/userThunks";
import { Container, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import MyCustomListChildren from "../components/MyCustomListChildren";
import { ContainerTags, HeaderContainer } from "../noticeList/styleNoticeList";
import {
  ACTIVE_OPTION,
  INACTIVE_OPTION,
} from "../noticeOffert/consts/constsList";
import {
  NoticeListBodyAdapter,
  myOffersToCarriersListAdapter,
} from "@/adapters/store/myNoticeAdapter";
import { cleanPagination } from "@/store/userRedux/userSlice";

const MySolicitudList = () => {
  const [indexTag, setIndexTag] =
    useState<NoticeListBodyAdapter["opcionesnumero"]>(ACTIVE_OPTION);

  const {
    agreementToAccepts,
    isLoading,
    isLoadingPagination,
    currentPage,
    totalPages,
  } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();
  const refObserver = useRef<any>(null);
  const { isNearScreen } = useObserver({
    distance: "100px",
    externalRef: refObserver,
  });

  useEffect(() => {
    handlePaginate();
    return () => {
      dispatch(cleanPagination());
    };
  }, [indexTag]);

  useEffect(() => {
    if (isNearScreen && !isLoadingPagination && currentPage < totalPages - 1) {
      handlePaginate(true, currentPage);
    }
  }, [isNearScreen]);

  const handlePaginate = (isPaginate = false, page = 0) => {
    const body = handlePrepareBodyRequestList(isPaginate, page);

    dispatch(getMyRequestsToClientsThunk(body));
  };

  const handlePrepareBodyRequestList = (isPaginate = false, page = 0) => {
    const body = myOffersToCarriersListAdapter({
      page,
      opcionesnumero: indexTag,
      isPaginate,
    });

    return body;
  };

  const handleOnchangeTags = (index: number) => {
    let newIndex: NoticeListBodyAdapter["opcionesnumero"] =
      index === 1 ? ACTIVE_OPTION : INACTIVE_OPTION;
    setIndexTag(newIndex);
  };

  return (
    <Container maxWidth="xl" sx={{ marginTop: "41px" }}>
      <Grid container>
        <Grid item xs={12}>
          <HeaderContainer maxWidth={301}>
            <Typography variant="h6">Mis ofertas a clientes</Typography>
          </HeaderContainer>
        </Grid>
        <Grid item xs={12}>
          <ContainerTags>
            <CustomTags
              label1="ACTIVOS"
              label2="HISTORIAL"
              childrenTag1={
                <MyCustomListChildren
                  list={agreementToAccepts}
                  isLoading={isLoading}
                  isLoadingPagination={isLoadingPagination}
                  refObserver={refObserver}
                  type="solicitud"
                />
              }
              childrenTag2={
                <MyCustomListChildren
                  list={agreementToAccepts}
                  isLoading={isLoading}
                  isLoadingPagination={isLoadingPagination}
                  refObserver={refObserver}
                  type="solicitud"
                />
              }
              onChange={handleOnchangeTags}
            />
          </ContainerTags>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MySolicitudList;
