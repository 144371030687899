import { Container, Grid, Skeleton, Stack } from "@mui/material";
import SkeletonGroup from "@/components/skeletons/SkeletonGroup";
import { SkeletonSectionContainer } from "@/components/skeletons/styleSkeletons";

const SkeletonViewService = () => {
    return (
        <Container disableGutters sx={{ maxWidth: "954px !important" }}>
            <SkeletonSectionContainer >
                <Skeleton variant="rounded" width={"100%"} height={214} />
                <Grid
                    container
                    direction={{ xs: "column", md: "row" }}
                    justifyContent={{ xs: "center", md: "space-between" }}
                    alignItems={{ xs: "center", md: "flex-start" }}
                >
                    <Grid item md={7}>
                        <Stack gap={2} sx={{ mb: "8px" }}>
                            <Skeleton variant="rounded" width={328} height={56} />
                            <Skeleton variant="rounded" width={328} height={88} />
                            <Skeleton variant="rounded" width={328} height={216} />
                            <Skeleton variant="rounded" width={328} height={32} />
                            <Skeleton variant="rounded" width={328} height={20} />
                        </Stack>
                    </Grid>
                    <Grid item md={4}>
                        <SkeletonGroup
                            list={3}
                            height={44}
                            borderRadius="44px"
                        />
                    </Grid>
                </Grid>
            </SkeletonSectionContainer>
        </Container>
    );
};

export default SkeletonViewService;
