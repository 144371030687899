import BasicModal from "@/components/dialog/Notiffication";
import SnackbarNotification from "@/components/snackbar/Snackbar";
import UserRating from "@/components/userRating/UserRating";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import { dataProfileFormatRequestNeg } from "@/pages/noticeRequestFlow/adapters/noticeRequestFlowAdapters";
import Photos from "@/pages/noticeRequestFlow/components/Photos";
import RowCollapse from "@/pages/noticeRequestFlow/components/RowCollapse";
import useNoticeConfigData from "@/pages/noticeRequestFlow/hooks/useNoticeConfigData";
import DynamicComponent from "@/pages/offerts/offertConfirmation/components/DynamicComponent";
import ArrowBack from "@/pages/viewService/city/components/ArrowBack";
import { Collapse, Container, Grid } from "@mui/material";
import { useEffect, useId, useState } from "react";
import { useLocation } from "react-router-dom";
import useRejectionConfirmation from "../../hooks/useRejectionConfirmation";
import { hiddenPricenInRowCollapse } from "@/consts/noticeConsts";

interface LocationState {
  hasBackNavigate?: boolean;
}

const RejectionCoCounterOfferClient = () => {
  const [hasBackNavigate, setHasBackNavigate] = useState(false);
  const [isCollapse, setisCollapse] = useState(false);

  const { handleInitConfig, handleNavigationBack } = useNoticeConfigData();
  const {
    matches,
    showTwoAvatars,
    savedDataNogotation,
    selectOneconfigData,
    selectConditionconfigData,
    selectThreeconfigData,
    selectTwoconfigData,
    valdateExpress,
  } = useRejectionConfirmation();
  const isNotOwner = savedDataNogotation?.flgIsOwner === 0;

  let location = useLocation();
  const currentId = useId();

  useEffect(() => {
    if ((location.state as LocationState)?.hasBackNavigate) {
      setHasBackNavigate(true);
    }
  }, []);

  return (
    <>
      <Container maxWidth={matches ? "xl" : "xs"} sx={{ marginTop: "35px" }}>
        <SnackbarNotification />
        <ArrowBack show={hasBackNavigate} onClick={handleNavigationBack} />

        <Grid container>
          <Grid item xs={matches ? 6 : 12} sx={{ p: matches ? 2 : 0 }}>
            <Collapse collapsedSize="68px" in={matches ? true : isCollapse}>
              {selectOneconfigData.map((item, i) => (
                <DynamicComponent
                  key={`${currentId}-${i}`}
                  {...item}
                  {...handleInitConfig(item)}
                />
              ))}

              {(valdateExpress || isNotOwner) && (
                <>
                  <UserRating
                    data={dataProfileFormatRequestNeg(
                      savedDataNogotation as SolicitudNegociacion,
                      showTwoAvatars
                    )}
                  />
                </>
              )}

              {selectTwoconfigData.map((item, i) => (
                <DynamicComponent
                  key={`${currentId}-${i}`}
                  {...item}
                  {...handleInitConfig(item)}
                />
              ))}

              <br />

              <Photos />

              {!matches &&
                selectConditionconfigData.map((item, i) => (
                  <DynamicComponent
                    key={`${currentId}-${i}`}
                    {...item}
                    {...handleInitConfig(item)}
                  />
                ))}
            </Collapse>

            <RowCollapse
              hiddenPricenInRowCollapse={hiddenPricenInRowCollapse}
              isCollapse={isCollapse}
              setisCollapse={setisCollapse}
              negotation={savedDataNogotation}
            />
          </Grid>

          <Grid
            item
            xs={matches ? 6 : 12}
            sx={
              matches
                ? { border: "1px solid #EFEFEF", borderRadius: "16px", p: 4 }
                : {}
            }
          >
            <Grid container>
              <Grid item xs={matches ? 12 : 12}>
                {matches &&
                  selectConditionconfigData.map((item, i) => (
                    <DynamicComponent
                      key={`${currentId}-${i}`}
                      {...item}
                      {...handleInitConfig(item)}
                    />
                  ))}

                <br />

                {selectThreeconfigData.map((item, i) => (
                  <DynamicComponent
                    key={`${currentId}-${i}`}
                    {...item}
                    {...handleInitConfig(item)}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <BasicModal />
    </>
  );
};

export default RejectionCoCounterOfferClient;
