import LayoutFlow from "@/components/LayoutFlow/LayoutFlow";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import LabelRequired from "@/components/utils/LabelRequired";
import {
  AUTH_RECOVER_PASSWORD,
  CLIENTS_LANDING_PATH,
  HOME_PAHT,
  REQUESTS_LANDING_PATH,
  SERVICES_LANDING_PATH,
} from "@/consts/typeServicesPaths";
import useCountdown from "@/hooks/useCountdown";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { UsuarioAPI } from "@/models/userAuth/user";
import { getRedirectPath } from "@/router/redirectRules/redirectRules";
import { setErrorLogin } from "@/store/auth/authUserSlice";
import {
  EnumTypeCodeSend,
  generateLoginByCredentialsThunk,
  sendCodeBySMSThunk,
} from "@/store/auth/authUserThunks";
import { resetReturnPathThunk } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { setReturnLogin } from "@/store/userRedux/userSlice";
import { InputAdornment, styled } from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderFlow from "../components/HeaderFlow";
import { Form } from "../components/styleLoginRegister";
import { CustomControl, Text } from "./styles";
import VisibilityIcons from "../components/VisibilityIcons";

const CustomLink = styled(Link)(
  ({ theme }) => `
    font-family: ${theme.typography.label2.fontFamily};
    font-weight: ${theme.typography.label2.fontWeight};
    font-size: ${theme.typography.label2.fontSize};
    line-height: ${theme.typography.label2.lineHeight};
    font-style: normal;
    color: ${theme.palette.primary.main};
    text-decoration: none;
  `
);

type LocationState = { phone: string; returnPath: string };

const Password = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState("");
  const { handleIsEnabled } = useCountdown();

  const {
    auth: { errorLogin, isLoading, cellPhone },
    user: { isLoading: isLoadingUser },
    routeFlowNotice,
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { state } = useLocation();

  const inputSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputSearchRef.current?.focus();

    return () => {
      dispatch(resetReturnPathThunk());
    };
  }, []);

  useEffect(() => {
    if (!(state as LocationState)?.phone) {
      navigate(HOME_PAHT, { replace: true });
    }
  }, [navigate, state]);

  useEffect(() => {
    if (!isLoading) {
      setShowError(errorLogin);
    }
  }, [errorLogin, isLoading]);

  const handleChange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const { value } = currentTarget;
    const cleanValue = value.replace(/\D/g, "");
    if (cleanValue.length > 4) return;
    dispatch(setErrorLogin(""));
    setPassword(cleanValue);

    setShowError("");
  };

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLoading) return;
    const returnPath = (state as LocationState)?.returnPath;
    if (
      returnPath?.startsWith(SERVICES_LANDING_PATH) ||
      returnPath?.startsWith(REQUESTS_LANDING_PATH)
    ) {
      dispatch(setReturnLogin(true));
    }

    dispatch(
      generateLoginByCredentialsThunk((state as LocationState).phone, password)
    ).then(handleNavigate);
  };

  const handleNavigate = (user: UsuarioAPI | undefined) => {
    let returnPath = (state as LocationState)?.returnPath || HOME_PAHT;
    const currentUser = user as UsuarioAPI;
    let stateRute = {};
    if (Object.keys(currentUser).length) {
      const rute = handleGetPathWithUSer(user);
      returnPath = rute.returnPath;
      stateRute = rute.stateRute;
    } else {
      returnPath = CLIENTS_LANDING_PATH;
    }

    navigate(returnPath, {
      replace: true,
      state: stateRute,
    });
  };

  /**
   * La función `handleGetPathWithUser` devuelve la ruta estatal y la ruta de retorno según el tipo de
   * usuario y el aviso de flujo de ruta actual.
   * @param {UsuarioAPI | undefined} user - El parámetro `usuario` es de tipo `UsuarioAPI | indefinido`.
   * Representa el objeto de usuario que se pasa a la función. El tipo `UsuarioAPI` es un tipo
   * personalizado que define la estructura de un objeto de usuario. El tipo "indefinido" indica que el
   * objeto de usuario puede ser opcional y puede
   * @returns Se devuelve un objeto con dos propiedades: "stateRute" y "returnPath".
   */
  const handleGetPathWithUSer = (user: UsuarioAPI | undefined) => {
    const currentUser = user as UsuarioAPI;
    const getNextRoute = getRedirectPath(currentUser);
    const { returnPath: returnPathState } = routeFlowNotice;
    let returnPath = getNextRoute;
    let stateRute = {};
    if (!!returnPathState.path) {
      returnPath = returnPathState.path;
      stateRute = returnPathState.state;
    }

    return { stateRute, returnPath };
  };

  const handleShowError = () => {
    if (typeof showError !== "string") return "Error inesperado";

    return showError;
  };

  const handleLinkForgotPassword = () => {
    if (!handleIsEnabled) {
      dispatch(sendCodeBySMSThunk(cellPhone, EnumTypeCodeSend.RESTABLECER));
    }
  };

  return (
    <LayoutFlow sx={{ height: { lg: "75vh" } }}>
      <HeaderFlow title="Ingresa tu contraseña" />
      <Form onSubmit={handleOnSubmit}>
        <Text>La contraseña debe tener 4 dígitos.</Text>
        <CustomControl fullWidth inputMode="numeric">
          <Input
            inputRef={inputSearchRef}
            label={<LabelRequired str="Contraseña" />}
            name="password"
            variant="standard"
            placeholder="Ingresa tu contraseña"
            type={showPassword ? "text" : "password"}
            error={!!showError?.length}
            helperText={handleShowError()}
            autoComplete="off"
            fullWidth
            value={password}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                  <VisibilityIcons onChangeVisible={setShowPassword} />
                </InputAdornment>
              ),
            }}
          />
        </CustomControl>

        <CustomLink
          to={AUTH_RECOVER_PASSWORD}
          state={{ success: true }}
          onClick={handleLinkForgotPassword}
        >
          Olvidé mi contraseña
        </CustomLink>

        <ButtomLoader
          disabled={isLoading || isLoadingUser}
          isLoading={isLoading || isLoadingUser}
          type="submit"
        >
          INGRESAR
        </ButtomLoader>
      </Form>
    </LayoutFlow>
  );
};

export default Password;
