import { DetailsCoreModel } from "@/models/notice/noticeModels";

export const prepareVechicleCallback = (
  detail: DetailsCoreModel,
  props: any
) => {
  const {
    vehiculo: { placa, tipovehiculo },
  } = detail;
  const { nombre } = tipovehiculo;
  let value = nombre;
  if (!!placa) {
    value += ` (${placa})`;
  }

  return {
    ...props,
    value,
  };
};

export const prepareMaximumCapacityCallback = (
  detail: DetailsCoreModel,
  props: any
) => {
  const { pesomaximo } = detail;
  const value = `${pesomaximo} Kilos`;
  return {
    ...props,
    value,
  };
};

export const prepareDateAutionCallback = (
  detail: DetailsCoreModel,
  props: any
) => {
  const value = detail.fvalidez || "";

  return {
    ...props,
    value,
  };
};
