import { DistrictAPI } from "@/models/location/districtModel";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ZonesState {
    zones: DistrictAPI[];
    isLoading: boolean;
    error: any;
}

const initialState: ZonesState = {
    zones: [],
    isLoading: false,
    error: null,
};

export const zonesSlice = createSlice({
    name: "zones",
    initialState,
    reducers: {
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoading = payload;
        },
        setZones: (state, { payload }: PayloadAction<Array<any>>) => {
            state.zones = payload;
            state.isLoading = false;
            state.error = null;
        },
        setError: (state, { payload }: PayloadAction<any>) => {
            state.error = payload;
            state.isLoading = false;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setLoading, setZones, setError } = zonesSlice.actions;
