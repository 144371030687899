import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { Label } from "@/components/form/selectControl/styleSelectControl";
import LayoutFlow from "@/components/LayoutFlow/LayoutFlow";
import SearchModal from "@/components/modals/searchModal/SearchModal";
import LabelRequired from "@/components/utils/LabelRequired";
import { BASE_URL_IMAGE } from "@/config/config";
import { AUTH_USER_TYPE } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { singUpStartDataLayer } from "@/services/dataLayer/auth";
import { getListTypeUserThunk } from "@/store/auth/authUserThunks";
import { getDistrictByTermThunk } from "@/store/locationRedux/districtsThunks";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { saveFormDataUserThunk } from "@/store/userRedux/userThunks";
import { cleanEmptySpaceInObject, getNextPage } from "@/utils/helpers";
import {
  Checkbox,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from "react";
import { To, useLocation, useNavigate } from "react-router-dom";
import HeaderFlow from "../../components/HeaderFlow";
import { titleByPath } from "../ClientProfile/configDataClientProfile";
import {
  configByTypeDocument,
  modalProps,
  objectNextPage,
} from "../../consts/configDataClients";
import { FormData } from "../../interfaces/interfaces";
import Input from "@/components/inputCustom/Input";
import { UserFormDataRegisterThunk } from "@/components/form/formPerfilTransportista/FormPerfilTransportista";
import { typesDocuments } from "@/consts/defaultConsts";
import { validEmail } from "@/utils/inputsUtils";

const ContainerRow = styled(Stack)(
  () => `
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    margin-top: 16px
  `
);

const StyleLink = styled("a")(
  ({ theme }) => `
    color: ${theme.palette.info.main};
    text-decoration: none;
    font-family: ${theme.typography.label2.fontFamily};
    font-size: ${theme.typography.label2.fontSize};
    font-weight: ${theme.typography.label2.fontWeight};
    line-height: ${theme.typography.label2.lineHeight};
  `
);

const Form = styled("form")(
  () => `
    display: flex;
    flex-direction: column;
  `
);

const initialsValues: FormData = {
  typeDocument: 1,
  numberDocument: "",
  name: "",
  fullname: "",
  district: {
    id: 0,
    nombre: "",
  },
  email: "",
};

type BodySubmit = {
  id: number;
  tipousuario: string;
  typeDocument: number;
  numberDocument: string;
  name: string;
  fullname: string;
  district: {
    id: number;
    nombre: string;
  };
  email: string;
};

type MessageError = {
  numberDocument: string;
  email: string;
};

const FormDataDriver = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(initialsValues);
  const [termsAndconditions, setTermsAndconditions] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errormsg, setErrormsg] = useState({
    numberDocument: "",
    email: "",
  });

  const navigate = useNavigate();
  const location = useLocation();

  const {
    auth: { typeUser },
    user: { user, error, isLoading },
    districts: { districts },
  } = useAppSelector(({ auth, user, districts }) => ({
    auth,
    user,
    districts,
  }));
  const dispatch = useAppDispatch();

  const CONFIG_DATA = configByTypeDocument[Number(formData.typeDocument)];
  const currentUser = user as IUser;

  useEffect(() => {
    if (!typeUser.length) {
      dispatch(getListTypeUserThunk());
    }

    if (currentUser?.id) {
      singUpStartDataLayer(currentUser?.tipousuario, currentUser.id);
    }

    if (!currentUser.tipousuario) {
      navigate(AUTH_USER_TYPE, { replace: true });
    }
  }, []);

  const handleActiveModalCity = () => {
    setShowModal(true);
  };

  const handleOnchange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = currentTarget;

    const { validates } = CONFIG_DATA[name];
    if (validates?.maxLength && value.length > validates.maxLength) {
      return;
    }
    setErrormsg({ email: "", numberDocument: "" });
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleOnChangeSelect = ({ target }: SelectChangeEvent<string>) => {
    const { value, name } = target;

    setFormData(() => ({ ...initialsValues, [name]: value }));
  };

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    const { id, tipousuario } = currentUser;
    const body = { ...formData, id, tipousuario };
    event.preventDefault();

    if (isLoading) return;

    setShowError(false);
    const nextPage = getNextPage(location.pathname, objectNextPage) as To;

    const messageError = handleMessageError(body);
    if (messageError) {
      setErrormsg(messageError);
      return;
    }

    setErrormsg({ email: "", numberDocument: "" });
    const cleanBody = cleanEmptySpaceInObject<UserFormDataRegisterThunk>(body);

    dispatch(saveFormDataUserThunk(cleanBody))
      .then(() => navigate(nextPage, { replace: true }))
      .catch(() => setShowError(true));
  };

  const handleMessageError = (body: BodySubmit): MessageError | null => {
    const {
      numberDocument: { validates },
    } = configByTypeDocument[Number(body.typeDocument)];
    const { numberDocument, email } = body;

    const isNumberDocumentValid = /^\d+$/.test(numberDocument);
    const isAlphaNumericValid = /^[a-zA-Z0-9]+$/.test(numberDocument);
    const isNumberDocumentLengthValid =
      numberDocument.length <= validates.maxLength;
    const isEmailValid = validEmail(email);

    let messageError = null;
    if (validates?.onliNumbers === true && !isNumberDocumentValid) {
      messageError = {
        ...errormsg,
        numberDocument: "Sólo puede incluir números.",
      };
    } else if (validates?.onliNumbers === false && !isAlphaNumericValid) {
      messageError = {
        ...errormsg,
        numberDocument: "Sólo puede incluir números y letras.",
      };
    } else if (!isNumberDocumentLengthValid) {
      messageError = {
        ...errormsg,
        numberDocument: `El número de documento debe tener ${validates.maxLength} caracteres`,
      };
    } else if (body.email && !isEmailValid) {
      messageError = {
        ...errormsg,
        email: "Ingresa un correo válido. Ej: tucorreo@correo.com",
      };
    }

    return messageError;
  };

  const handleOnChangeModal = (district: string) => {
    if (!!district.trim().length && showModal && district.length >= 3) {
      dispatch(getDistrictByTermThunk(district));
    }
  };

  const handleSelectedDistrict = (district: any) => {
    setFormData((prev) => ({ ...prev, district }));
    setShowModal(false);
  };

  const handleDisabled = () => {
    const { district, typeDocument, email, ...rest } = formData;

    return (
      isLoading ||
      district.id <= 0 ||
      Number(typeDocument) <= 0 ||
      !termsAndconditions ||
      Object.values(rest).some((value) => !value.length)
    );
  };

  const handleGetSearchModalProps = () => {
    if (formData.typeDocument === typesDocuments.RUC.id) {
      return modalProps.company;
    }

    return modalProps.default;
  };

  const handleGetBack = () => {
    navigate(AUTH_USER_TYPE, { replace: true });
  };

  const searchModalProps = useMemo(
    () => handleGetSearchModalProps(),
    [formData.typeDocument]
  );

  return (
    <>
      <LayoutFlow>
        <>
          <HeaderFlow
            title={titleByPath[location.pathname]}
            customGoBack={handleGetBack}
          />

          {showError && (
            <StandardAlert
              sx={{ marginBottom: "16px" }}
              severity="error"
              color="error"
            >
              {error}
            </StandardAlert>
          )}
        </>
        <SearchModal
          open={showModal}
          onClose={(isOpen: boolean) => setShowModal(isOpen)}
          onSubmit={handleSelectedDistrict}
          onChange={handleOnChangeModal}
          list={districts}
          title={searchModalProps.title}
          inputProps={searchModalProps.inputProps}
        />

        <Form onSubmit={handleOnSubmit}>
          <ContainerRow order={CONFIG_DATA["numberDocument"].order}>
            <FormControl
              // variant="standard"
              sx={{ flex: 1.5 }}
            >
              <Label id={"typeDocument"}>
                <LabelRequired str="Documento" />
              </Label>
              <Select
                labelId={"typeDocument"}
                id={"typeDocument"}
                value={`${formData.typeDocument}`}
                onChange={handleOnChangeSelect}
                name="typeDocument"
                input={<OutlinedInput label="Documento" />}
              >
                {typeUser.map((type, i) => (
                  <MenuItem key={`${type.id}-${i}`} value={type.id}>
                    {type.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="standard" sx={{ flex: 3, marginLeft: "8px" }}>
              <Input
                name="numberDocument"
                type={
                  CONFIG_DATA["numberDocument"].validates.onliNumbers
                    ? "number"
                    : "text"
                }
                fullWidth
                value={formData.numberDocument}
                onChange={handleOnchange}
                variant="standard"
                label={
                  <LabelRequired str={CONFIG_DATA["numberDocument"]?.label} />
                }
                placeholder={CONFIG_DATA["numberDocument"]?.placeholder}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errormsg.numberDocument}
                helperText={errormsg.numberDocument}
              />
            </FormControl>
          </ContainerRow>

          <ContainerRow order={CONFIG_DATA["name"].order}>
            <FormControl variant="standard" sx={{ flex: 1 }}>
              <Input
                name="name"
                type="text"
                fullWidth
                value={formData.name}
                onChange={handleOnchange}
                variant="standard"
                label={<LabelRequired str={CONFIG_DATA["name"]?.label} />}
                placeholder={CONFIG_DATA["name"]?.placeholder}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </ContainerRow>

          <ContainerRow order={CONFIG_DATA["fullname"].order}>
            <FormControl variant="standard" sx={{ flex: 1 }}>
              <Input
                name="fullname"
                type="text"
                fullWidth
                value={formData.fullname}
                onChange={handleOnchange}
                variant="standard"
                label={<LabelRequired str={CONFIG_DATA["fullname"]?.label} />}
                placeholder={CONFIG_DATA["fullname"]?.placeholder}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </ContainerRow>

          <ContainerRow order={CONFIG_DATA["district"].order}>
            <FormControl variant="standard" sx={{ flex: 1 }}>
              <Input
                name="district"
                type="text"
                fullWidth
                value={formData.district.nombre}
                onChange={handleActiveModalCity}
                onClick={handleActiveModalCity}
                variant="standard"
                label={<LabelRequired str={CONFIG_DATA["district"]?.label} />}
                placeholder={CONFIG_DATA["district"]?.placeholder}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </ContainerRow>

          <ContainerRow order={CONFIG_DATA["email"].order}>
            <FormControl variant="standard" sx={{ flex: 1 }}>
              <Input
                name="email"
                type="text"
                fullWidth
                value={formData.email}
                onChange={handleOnchange}
                variant="standard"
                label={CONFIG_DATA["email"]?.label}
                placeholder={CONFIG_DATA["email"]?.placeholder}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errormsg.email}
                helperText={errormsg.email}
              />
            </FormControl>
          </ContainerRow>

          <ContainerRow order={6}>
            <Checkbox
              checked={termsAndconditions}
              color="org"
              onChange={() => setTermsAndconditions(!termsAndconditions)}
            />
            <Typography variant="body2" marginLeft="7px">
              Acepto los {/* // TODO: Falta agregar Los links */}
              <StyleLink
                href={`${BASE_URL_IMAGE}/Terminos-y-condiciones.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                términos y condiciones{" "}
              </StyleLink>
              y reconozco que leí la{" "}
              <StyleLink
                href={`${BASE_URL_IMAGE}/Politica-privacidad.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                política de privacidad.
              </StyleLink>
            </Typography>
          </ContainerRow>

          <ContainerRow order={7}>
            <FormControl variant="standard" sx={{ flex: 1 }}>
              <ButtomLoader
                type="submit"
                disabled={handleDisabled()}
                isLoading={isLoading}
              >
                SIGUIENTE
              </ButtomLoader>
            </FormControl>
          </ContainerRow>
        </Form>
      </LayoutFlow>
    </>
  );
};

export default FormDataDriver;
