import { Button, Grid, Stack, styled, Typography } from "@mui/material";

export const ContainerModal = styled(Stack)(
    ({ theme }) => `
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);
    width:305px;
    background-color: #FFFFFF;
    border-radius: 2px;
    outline:none;
    gap:10px;
    border: 1px solid #CFD8DC;
    padding: 24px 16px 16px;
    `
);

export const HeaderModal = styled(Grid)(
    () => `
    align-items:center;
    justify-content:space-between;
    `
);

export const ContentModal = styled(Typography)(
    () => `
    padding:10px;
    `
);

export const AccionsModal = styled(Grid)(
    () => `
    justify-content: end;
    padding:10px;
    gap:10px;
    `
);

export const ButtonAccionModal = styled(Button)(
    () => `
    padding: 0px 16px;
    height: 36px;
    `
);

export const PillButton = styled(ButtonAccionModal)(
    () => `
    border-radius:36px
    `
);