import { Box, Stack, styled, Typography } from "@mui/material";

export const ContainerListZone = styled(Stack)(() => ({
  gap: "8px",
  marginBottom: "8px",
}));

export const ListZoneText = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.body2.fontFamily,
  fontSize: theme.typography.body2.fontSize,
  fontWeight: theme.typography.body2.fontWeight,
  lineHeight: theme.typography.body2.lineHeight,
}));

export const ListZoneTitle = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.label2.fontFamily,
  fontSize: theme.typography.label2.fontSize,
  fontWeight: theme.typography.label2.fontWeight,
  lineHeight: theme.typography.label2.lineHeight,
}));

export const ShowButton = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.label2.fontFamily,
  fontsize: theme.typography.label2.fontSize,
  fontWeight: theme.typography.label2.fontWeight,
  lineHeight: theme.typography.label2.lineHeight,
  color: theme.palette.primary.main,
  // textAlign: "end",
  cursor: "pointer",
}));
