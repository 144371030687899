import Icon, { IconTypes } from "@/utils/Icon";
import { Box, styled } from "@mui/material";
import { FC } from "react";

const ContainerIcon = styled(Box)(
  () => `
    padding: 16px;
  `
);

type Props = {
  icon: string;
};

const SectionIconV2: FC<Props> = ({ icon }) => {
 
 
  return (
    <ContainerIcon component="aside" justifyContent="center" display="flex">
        <Icon
        name={icon as IconTypes}
        size="xl"
        color="info"
      />  
    </ContainerIcon>
  );
};

export default SectionIconV2;
