import { ConfigDataNegotation } from '@/pages/noticeRequestFlow/interface/noticeNegRequestInterface';
import { configDataOneCoCounterOfferBetweenCities } from './config/betweenCities/betweenCities';
import { configDataOneCoCounterOfferInCity } from './config/inCity/InCityConfig';
import { configDataOneCoCounterOfferMoving } from './config/moving/movigConfig';
import { configDataOneCoCounterOfferMultidestiny } from './config/multidestiny/multidestinyConfig';

export const ConfigurationToRender: Record<number, ConfigDataNegotation[]> = {
  1: configDataOneCoCounterOfferInCity,
  2: configDataOneCoCounterOfferMultidestiny,
  3: configDataOneCoCounterOfferBetweenCities,
  5: configDataOneCoCounterOfferMoving,
};
