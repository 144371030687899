import { Box, styled } from '@mui/material';

export const Container = styled(Box)(
  () => `
      background-color: #fff;
      border-radius: 0 0 10px 10px;
      padding: 16px;
      padding-top: 48px;
      padding-bottom: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
        `
);

export const ContainerMobile = styled(Box)(
  () => `
      background-color: #fff;
      border-radius: 10px;
      padding: 16px;
      padding-bottom: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px;
      box-shadow: -3px 4px 7px 0px rgba(0,0,0,0.05);
        `
);

export const ButtonContainer = styled(Box)(
  () => `
        width: 100%,
        background-color: none;
        color: #FF6700;
        display: flex;
        align-items: center;
        padding: 16px 0px;
        justify-content: flex-end;
  
      `
);

export const BoxContainer = styled(Box)(
  () => `
  position: absolute;
  right: 0;
  left: 0;
  background-color: #382E2B;
  
      `
);

export const CardBox = styled(Box)(
  () => `
    align-items: center;
    padding: 12px 0px;
    height: 72px;
    display: flex;
   
      `
);

export const CardBoxMobile = styled(Box)(
  () => `
    align-items: center;
    display: flex;
   
      `
);

export const BoxCloseIcon = styled(Box)(() => ({
  position: 'relative',
  marginTop: '40px',
  alignItems: 'end',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
}));

export type FilterStyle = Record<string, Record<string, string | number>>;

export const filterStyles: FilterStyle = {
  section: {
    backgroundColor: 'none',
    paddingBottom: 'none',
    paddingTop: 'none',
  },
  Box: {
    marginTop: 'none',
    marginBottom: 'none',
  },
  StyledTabs: {
    borderRadius: 'none',
    position: 'absolute',
    xIndex: 2,
    width: '100%',
    right: 0,
    left: 0,
  },
  inputsContainer: {
    paddingTop: '48px',
  },
};

export const tabHeigh = 60;

export const miniIcons: Record<string, string> = {
  origin: 'icon-location-mini-light-icon',
  destiny: 'icon-location-mini-dark-icon',
  timeService: 'icon-calendar-mini-icon ',
  betweenDate: 'icon-calendar-mini-icon ',
  weightOfTheLoad: 'icon-weigh-mini-icon',
  typeVehicle: 'icon-truck-mini-icon',
  point: 'icon-points-mini-icon',
  hours: 'icon-hours-mini-icon ',
};
