import { ImageModal } from "@/components/modals/ImageModal/ImageModal";
import { IMG_PATH, LG_IMG, MD_IMG } from "@/consts/defaultConsts";
import { useAppSelector } from "@/hooks/useReducer";
import { ResizeAllPhotosType } from "@/interfaces/globalInterface";
import { resizePhoto, reziseAllPhotos } from "@/utils/helpers";
import {
  Box
} from "@mui/material";
import { SyntheticEvent, useState } from "react";

const Photos = () => {
  const [seletedImage, setSeletedImage] = useState("");
  const {
    noticeNegotationRequest: { savedDataNogotation },
  } = useAppSelector((state) => state);

  const photos = !!savedDataNogotation?.solicitud?.fotos?.length
    ? reziseAllPhotos({
        photos: savedDataNogotation?.solicitud.fotos,
      } as ResizeAllPhotosType)
    : [];

  const validateImg = (imgURL: string) => {
    let newUrl = imgURL;
    if (!imgURL) return "";

    if (imgURL.startsWith("http")) {
      newUrl = imgURL;
    } else {
      newUrl = resizePhoto({ photo: IMG_PATH + imgURL, newFormat: MD_IMG });
    }
    return newUrl;
  };

  const handleResizeImg = (imgUrl: string) => {

    const newImg = !!imgUrl
      ? resizePhoto({
          photo: imgUrl,
          newFormat: LG_IMG,
        })
      : "";

    setSeletedImage(newImg);
  };

  const handleError = (
    e: SyntheticEvent<HTMLImageElement, Event>,
    img: string
  ) => {
    (e.target as HTMLInputElement).src = img;
  };

  if ((photos?.length as number) < 1) return <></>;

  return (
    <>
      <ImageModal
        imageUrl={validateImg(seletedImage)}
        onClose={() => setSeletedImage("")}
      />

      {photos?.map((item) => (
        <Box
          key={item.id}
          component="img"
          sx={{
            height: 60,
            width: 60,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
            mr: 1,
            objectFit: "cover",
            cursor: "pointer"
          }}
          alt="Imagen de la solicitud"
          src={item.urlImagen}
          onClick={() => handleResizeImg(item?.urlImagen)}
        />
      ))}

    </>
  );
};

export default Photos;
