import HeaderSectionV2 from "@/components/headerSectionV2/HeaderSectionV2";

import { noResponseText, optionEleven, optionOne, optionTwo } from "@/consts/noticeConsts";
import HeaderDetail from "@/pages/noticeV2Negotation/components/HeaderDetail";
import {
  acceptData,
  counterOfferData,
  movingCounterOffer,
} from "@/pages/noticeV2Negotation/shared/const/default";
import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { Alert, Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertComponent } from "../../components/AlertComponent";
import { GlobalButtonsNegV2 } from "./buttonsComponents/GlobalButtonsNegV2";
import { GlobalPrice } from "./components/GlobalPrice";
import { DRIVER_TYPE, ID_MOVING_FLOW } from "@/consts/defaultConsts";
import { IconColor } from "@/utils/Icon";
import {
  EditNoticeCounterOfferButtons,
  EditNoticeOfferButtons,
} from "@/pages/noticeV2Negotation/components/EditNoticeButtons";

interface LocationState {
  hasBackNavigate?: boolean;
  hasBackLinkOffert?: boolean;
}

const responseNotYet = "El cliente aún no responde.";
const responseNotYetDriver = "El transportista aún no responde.";

export const NoticeNegLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const { notice, typeNoticeNegotationId } = useGetData();
  const { state } = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const isCounterOffer = typeNoticeNegotationId === optionTwo;
  const locationState = state as LocationState;
  const [hasBackNavigate, setHasBackNavigate] = React.useState(false);
  const [hasBackLinkOffert, setHasBackLinkOffert] = React.useState(false);
  const isMoving = notice?.idtipoaviso === ID_MOVING_FLOW;
  const flgIsOwner = (notice as NoticeV2NegType)?.flgIsOwner;
  const noResponse =
    notice?.ultimaAvisoNegociacionAcciones.tipoAvisoNegociacionAccion.estado
      .id === optionEleven;
  const typeUser =
    notice?.ultimaAvisoNegociacionAcciones.tipoAvisoNegociacionAccion.estado
      .tipoUsuario;
  const [onCloseAlert, setOnCloseAlert] = React.useState(false);

  React.useEffect(() => {
    if (locationState?.hasBackNavigate && !locationState?.hasBackLinkOffert) {
      setHasBackNavigate(true);
    }

    if (locationState?.hasBackLinkOffert && !locationState?.hasBackNavigate) {
      setHasBackLinkOffert(true);
    }
  }, []);

  const handleHeaderProps = () => {
    if (isCounterOffer) {
      if (isMoving) {
        return movingCounterOffer;
      }
      return counterOfferData;
    }

    return acceptData;
  };

  const handleNavigationBack = () => {
    navigate(-1);
  };

  const handleEdition = () => {
    if (notice?.idTipoAvisoNegociacion === optionOne)
      return <EditNoticeOfferButtons />;

    return <EditNoticeCounterOfferButtons />;
  };

  const handleTextAlert = () => {
    return typeUser === DRIVER_TYPE ? responseNotYetDriver : responseNotYet;
  };

  return (
    <>
      {(hasBackNavigate || hasBackLinkOffert) && (
        <HeaderSectionV2
          icon="icon-arrow-left"
          title=""
          onClick={() => handleNavigationBack()}
        />
      )}
      <Container maxWidth={matches ? "xl" : "xs"}>
        {!flgIsOwner && !noResponse  && (
          <Alert
            onClose={() => {
              setOnCloseAlert(true);
            }}
            sx={{ mb: 3, mt: 3, p: 2 }}
            severity="info"
          >
            {handleTextAlert()}
          </Alert>
        )}

        {
          noResponse && (
            <Alert
        
            sx={{ mb: 3, mt: 3, p: 2 }}
            severity="info"
          >
            {noResponseText}
          </Alert>
          )
        }

        {!!flgIsOwner && !noResponse && (
          <HeaderDetail
            title={handleHeaderProps().title}
            icon={handleHeaderProps().icon}
            iconColor={handleHeaderProps().color as IconColor}
          />
        )}

        <Grid
          container
          spacing={0}
          sx={{
            display: "flex",
            justifyContent: "center",
            pl: matches ? 20 : 0,
            pr: matches ? 20 : 0,
          }}
        >
          <Grid xs={matches ? 7 : 12}>{children}</Grid>

          <Grid xs={matches ? 5 : 12} sx={{ padding: "0px" }}>
            {<GlobalPrice data={notice as NoticeV2NegType} />}
            {!noResponse && (
              <>{!!flgIsOwner ? <GlobalButtonsNegV2 /> : handleEdition()}</>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
