import { NoticeOfferMovingInCitySlice } from '@/interfaces/offerInterface';
import { DetailNoticeMoving } from '@/models/notice/noticeModels';
import { RootState } from '@/store/store';
import { SendDataInitialState } from '../noticeClientAcceptOrCounterOfferSlice';
import { ID_NOTICE_IN_CITY } from '@/consts/noticeV2Paths';

export const saveStorageInCityAdapter = (
  body: NoticeOfferMovingInCitySlice,
  getState: () => RootState
) => {
  const {
    noticeV2ClientAorCO: { selectTypeNotice, sendData },
  } = getState();

  const { flgciudadoentreciudades } = selectTypeNotice as DetailNoticeMoving;
  const key = getKeyStorage(flgciudadoentreciudades);

  const value = {
    ...sendData[key],
    ...body,
  };

  return { key, value };
};

const getKeyStorage = (flgciudadoentreciudades: number) => {
  let key: keyof SendDataInitialState = 'movingBetweenCities';
  if (flgciudadoentreciudades === ID_NOTICE_IN_CITY) {
    key = 'movingInTheCity';
  }

  return key;
};
