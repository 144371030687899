import { useAppSelector } from "@/hooks/useReducer";
import { objectRenderComponent } from "./config";

const FormClientCounterOffer = () => {
  const {savedDataNogotation} = useAppSelector((state) => state.noticeNegotationRequest);

  const Component = objectRenderComponent[savedDataNogotation?.solicitud.tipoSolicitud.id as number];
  if (!Component) return <></>;

  return <Component />;
};


export default FormClientCounterOffer