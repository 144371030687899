import { FormControl, styled, Typography } from "@mui/material";

export const Text = styled(Typography)(
  ({ theme }) => `
    font-family: ${theme.typography.body1.fontFamily};
    font-size: ${theme.typography.body1.fontSize};
    font-weight: ${theme.typography.body1.fontWeight};
    line-height: ${theme.typography.body1.lineHeight};
    font-style: normal;
  `
);

export const CustomControl = styled(FormControl)(
  () => `
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
  `
);
