import { prepareSelectedValueModal } from '@/components/Filter/adapter/filterAdapter';
import { FORMAT_DATE_DDMMYYYY } from '@/consts/formatsDate';
import { REGISTER_PATH } from '@/consts/noticeV2Paths';
import { REGISTER_DRIVER_STEPS } from '@/consts/typeServicesPaths';
import { useAppDispatch, useAppSelector } from '@/hooks/useReducer';
import { EventType } from '@/interfaces/globalInterface';
import { UsuarioAPI } from '@/models/userAuth/user';
import { INoticeCreateListItem } from '@/pages/noticeV2/NoticeList/types';
import {
  extractSendData,
  getNoticeType,
  getNoticeTypeInterface,
} from '@/pages/noticeV2/adapters/noticeUseTypes';
import { resetListLocation } from '@/store/filterNoticeRedux/filterNoticeSlice';
import { getCitiesByPaisFilterThunk, getCitiesInternationalizationFilterThunk } from '@/store/filterNoticeRedux/filterNoticeThunk';
import { setInputsPlace } from '@/store/noticeV2Redux/noticeV2Slice';
import {
  fillSendDataThunk,
  onSaveFlowNoticeV2Thunk,
} from '@/store/noticeV2Redux/noticeV2Thunk';
import {
  IInputsPlace,
  IReturnDataValues,
} from '@/store/noticeV2Redux/noticeV2types';
import { getNextRouteV2 } from '@/store/routeActionsFlowV2/routeActionsFlowNoticeV2Thunk';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IShowInputsProps } from '../../components/inputsConfig/types';
import { ERROR_MESSAGES_AUCTION, ERROR_MESSAGES_AUCTION_MIN, ERROR_MESSAGES_AUCTION_SAME, ERROR_MESSAGES_LOW_PRICE, MAX_PRICE_BW, MIN_PRICE_BW, placeholdersPlace } from '../hook';
import { MODAL_ACTIVE, remanePlaceholders } from '../types';

export const usePlaceAuctionBetweenCitiesHook = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const {
    filterNotice,
    user: { user },
    noticeV2: { selectNotice, inputsPlace, sendData, isLoading },
  } = useAppSelector((state) => state);
  const { listLocation } = filterNotice;
  const [showModal, setShowModal] = useState(false);

  const [modalActiveWith, setModalActiveWith] = useState(MODAL_ACTIVE);

  const idNoticeType = (selectNotice as INoticeCreateListItem)?.id || 0;

  const noticeTypeData = extractSendData(sendData, idNoticeType);

  useEffect(() => {
    loadFormData();
  }, []);

  const loadFormData = () => {
    if (inputsPlace && !inputsPlace.origin && !inputsPlace.destiny) {
      const ciudad = (user as UsuarioAPI)?.ciudad || {};

      const body: IReturnDataValues = {
        ...noticeTypeData,
        idciudad: ciudad?.id || 0,
        fechafija: dayjs().add(1, 'day').format(FORMAT_DATE_DDMMYYYY),
      };

      dispatch(
        fillSendDataThunk({
          key: getNoticeType(idNoticeType),
          value: getNoticeTypeInterface(idNoticeType, body),
        })
      ).then(() => {
        dispatch(
          setInputsPlace({
            ...inputsPlace,
            origin: ciudad?.nombre2 || '',
          })
        );
      });
    }
  };

  const handleOnClick = (props: IShowInputsProps) => () => {
    if (props?.inputFormat && props.name === 'timeService') return;
    setModalActiveWith({ name: props.name });
    setShowModal(true);
    dispatch(resetListLocation());
  };

  const onChange =
    (props: IShowInputsProps) => (event: EventType['change'] | Dayjs) => {
      if (event instanceof dayjs) {
        dispatch(
          fillSendDataThunk({
            key: getNoticeType(idNoticeType),
            value: getNoticeTypeInterface(idNoticeType, {
              ...noticeTypeData,
              fechafija: (event as Dayjs).format(FORMAT_DATE_DDMMYYYY),
            }),
          })
        );
      }

      handleOnClick(props)();
    };

  const handleGetValue = ({ name }: { name: keyof IInputsPlace }) => {
    if (name === 'timeService') {
      return '';
    }
    return inputsPlace ? inputsPlace[name] : '';
  };

  const handleValidError = (inputIShowInputsProps: any) => {
    if (!Object.keys(inputIShowInputsProps).length) return false;
    if (inputIShowInputsProps?.name === 'origin') {
      return validateInputs().valid;
    }

    if (inputIShowInputsProps?.name === 'destiny') {
      return validateInputs().valid;
    }

    return false;
  };

  const handleGetPlaceholder = (props: IShowInputsProps) => {
    const typeNoticePlaceholder = placeholdersPlace[idNoticeType || 0];

    if (
      remanePlaceholders.some((name) => name === props.name) &&
      typeNoticePlaceholder
    ) {
      return typeNoticePlaceholder[props.name] || {};
    }

    return {};
  };

  const handleValidShow = (columnIShowInputsProps: any) => {
    const moreIShowInputsProps = columnIShowInputsProps?.callback
      ? columnIShowInputsProps?.callback(columnIShowInputsProps, filterNotice)
      : {};
    return { ...columnIShowInputsProps, ...moreIShowInputsProps };
  };

  const handleInitConfig = (column: any) => {
    const moreIShowInputsProps = column?.props?.callback
      ? column?.props?.callback(column?.props, {})
      : {};

    const props = {
      ...column.props,
      onClick: handleOnClick(column.props),
      onChange: onChange(column.props),
      value: handleGetValue(column.props),
      error: handleValidError(column?.props),
      ...handleValidShow(column.props),
      ...handleGetPlaceholder(column.props),
      ...moreIShowInputsProps,
      helperText: validateInputs().msg,
    };

    return {
      ...column,
      props,
    };
  };

  const handleOnCloseModal = () => {
    setShowModal(false);
    setModalActiveWith(MODAL_ACTIVE);
  };

  const handleSubmitModal = (seleted: any) => {
    const name = prepareSelectedValueModal(seleted);

    const body: IReturnDataValues = {
      ...noticeTypeData,
    };

    if (modalActiveWith.name === 'origin') {
      body.idciudad = seleted.id;
    }

    if (modalActiveWith.name === 'destiny') {
      body.idciudaddestino = seleted.id;
    }

    dispatch(
      fillSendDataThunk({
        key: getNoticeType(idNoticeType),
        value: getNoticeTypeInterface(idNoticeType, body),
      })
    ).then(() => {
      dispatch(
        setInputsPlace({ ...inputsPlace, [modalActiveWith.name]: name })
      );
    });

    handleOnCloseModal();
  };
  const handleOnSeletedModal = (seleted: any) => {
    const exemption = ['origin', 'destiny'];
    if (exemption.some((exemp) => exemp === modalActiveWith.name)) {
      handleSubmitModal(seleted);
    }
  };

  const handleFilterByTerm = (value: string) => {
    if (value?.length < 3) return;

    const { name } = modalActiveWith;
    if (name === 'origin' || name === 'destiny') {
      dispatch(getCitiesInternationalizationFilterThunk(value));
    }
  };

  const handleInputsCustomAuction = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, name } = event.target;

    if (!/^[0-9]*$/.test(value)) return;

    const body: IReturnDataValues = {
      ...noticeTypeData,
    };

    if (name === 'precioregular') {
      body.precioregular = Number(value);
    }

    if (name === 'precio') {
      body.precio = Number(value);
    }

    dispatch(
      fillSendDataThunk({
        key: getNoticeType(idNoticeType),
        value: getNoticeTypeInterface(idNoticeType, {
          ...body,
        }),
      })
    );
  };

  const validateFormCustomPriceRegular = (value: number) => {
    if (Number(value) < MIN_PRICE_BW || Number(value) > MAX_PRICE_BW) {
      return {
        valid: false,
        msg: ERROR_MESSAGES_LOW_PRICE,
      };
    }
    return {
      valid: true,
      msg: '',
    };
  };

  const validateFormCustomPrice = (value: number, precioRegular: number) => {
    if (value > precioRegular) {
      return {
        valid: false,
        msg: ERROR_MESSAGES_AUCTION,
      };
    }

    const porcentaje = Math.abs(
      ((value - precioRegular) / precioRegular) * 100
    );
    if (porcentaje < 20 || porcentaje > 80) {
      return {
        valid: false,
        msg: ERROR_MESSAGES_AUCTION_MIN,
      };
    }

    return {
      valid: true,
      msg: ERROR_MESSAGES_AUCTION_MIN,
    };
  };

  const getPorcetage = () => {
    const { precioregular, precio } = noticeTypeData;
    if (!precioregular || !precio) return '-';
    const porcentaje = Math.abs(
      ((precio - precioregular) / precioregular) * 100
    );

    if (precio > precioregular) return '-';

    return `${porcentaje.toFixed(0)}%`;
  };

  const validateForm = () => {
    const { idciudad, idciudaddestino, fechafija, precioregular, precio } =
      noticeTypeData;

    return !!(
      idciudad &&
      idciudaddestino &&
      fechafija &&
      precioregular &&
      precio &&
      !validateInputs().valid &&
      validateFormCustomPriceRegular(precioregular).valid &&
      validateFormCustomPrice(precio, precioregular).valid
    );
  };

  const handleNavigate = () => {
    if (pathname.includes(REGISTER_PATH)) {
      navigate(REGISTER_DRIVER_STEPS);
      return;
    } else {
      dispatch(getNextRouteV2(navigate));
    }
  };
  const onSubmit = () => {
    if (!validateForm()) {
      return;
    }

    dispatch(
      onSaveFlowNoticeV2Thunk({
        ...noticeTypeData,
        porcentajedescuento: Number((getPorcetage() as any).replace('%', '')),
      })
    ).then(() => {
      handleNavigate();
    });
  };

  const validateInputs = () => {
    const { idciudad, idciudaddestino } = noticeTypeData;

    if (idciudad === idciudaddestino) {
      return {
        valid: true,
        msg: ERROR_MESSAGES_AUCTION_SAME,
      };
    }

    return {
      valid: false,
      msg: '',
    };
  };

  return {
    handleInitConfig,
    showModal,
    handleOnCloseModal,
    handleSubmitModal,
    handleOnSeletedModal,
    handleFilterByTerm,
    modalActiveWith,
    listLocation,
    data: noticeTypeData,
    validateForm,
    onSubmit,
    getPorcetage,
    handleInputsCustomAuction,
    isLoading,
    validateFormCustomPriceRegular,
    validateFormCustomPrice,
    noticeTypeData,
  };
};

export default usePlaceAuctionBetweenCitiesHook;
