import { ADS_SOON_LANDING_PATH } from '@/consts/typeServicesPaths';
import { createData } from './components/TableAds/TableAds';
import imageSecondSectionImgOne from '@/img/landing/advertisers/section2-left-img1.png';
import imageSecondSectionImgTwo from '@/img/landing/advertisers/section2-left-img2.png';
import imageThirdSectionImgOne from '@/img/landing/advertisers/section3-left-img1.png';
import imageThirdSectionImgTwo from '@/img/landing/advertisers/section3-left-img2.png';

export const reasonsList = [
  {
    icon: {
      xs: 'icon-cargoPerson--medium',
      md: 'icon-cargoPerson--large',
    },
    title: 'Encuentra clientes potenciales',
    description:
      'Somos una plataforma digital especializada en el transporte de carga. Tu publicidad tendrá mayor alcance por personas y empresas que forman parte de tu público objetivo.',
  },
  {
    icon: {
      xs: 'icon-money--many',
      md: 'icon-money--large',
    },
    title: 'Económico',
    description: 'Tenemos planes de pago flexibles en inversión y duración.',
  },
  {
    icon: {
      xs: 'icon-dolarCheck--medium',
      md: 'icon-dolarCheck--large',
    },
    title: 'Indicadores en línea',
    description:
      'Visualización en línea del número de impresiones de tu publicidad y su CTR (Click Through Rate).',
  },
  //ESTO SE VA A USAR EN OTRO MOMENTO
  /* {
    icon: {
      xs: "icon-check--medium",
      md: "icon-check--large"
    },
    title: "Fácil y rápido",
    description: "Compra y publica en línea tu publicidad."
  } */
];

export const rowsZocalo = [
  createData('20', '1,800', '40', '0.040'),
  createData('1,801', '3,600', '36', '0.036'),
  createData('3,601', '6,400', '32', '0.032'),
  createData('6,401', '8,400', '28', '0.028'),
  createData('8,401', '9,600', '24', '0.024'),
  createData('9,601', 'A más', '20', '0.020'),
];

export const rowsTarjeta = [
  createData('20', '1,800', '60', '0.060'),
  createData('1,801', '3,600', '54', '0.054'),
  createData('3,601', '6,400', '48', '0.048'),
  createData('6,401', '8,400', '42', '0.042'),
  createData('8,401', '9,600', '36', '0.036'),
  createData('9,601', 'A más', '30', '0.030'),
];

export const configSections = [
  {
    sectionLand: {
      title: 'Invierte a tu medida',
      subTitle: 'En PegaCargo tenemos 2 tipos de anuncio.',
      gap: '16px',
    },
    SubHeading: {
      sx: {
        alignItems: {
          xs: 'center',
          sm: 'center',
          md: 'center',
          lg: 'center',
        },
        mt: 4,
      },
    },
    box: {
      bgcolor: '#FAFAFA',
      p: 2,
      borderRadius: 2,
    },
    textsTop: {
      title: {
        text: 'Tipo zócalo fijo (strip)',
        sx: { fontWeight: 'bold', color: 'red' },
      },
      subTitle: {
        text: 'Medida pixeles: 320 ancho x 100 alto',
        sx: { color: '#646464' },
      },
    },
    gridImgContainer: {
      urlOne: imageSecondSectionImgOne,
      urlTwo: imageSecondSectionImgTwo,
    },
    textBottom: {
      sx: {
        width: '80%',
        mt: 2,
      },
      word: '',
      description:
        'Será visible en los avisos de transporte, notificaciones y en el proceso de negociación.',
    },
    textTable: {
      title: 'Inversión anuncio (zócalo)',
      word: 'CPM',
      description: 'Costo por mil impresiones (vistas del anuncio)',
      rows: rowsZocalo,
    },
    button: {
      link: ADS_SOON_LANDING_PATH,
      text: 'COMPRAR PUBLICIDAD',
    },
  },
  {
    sectionLand: {
      title: '',
      subTitle: '',
      gap: '16px',
    },
    SubHeading: {
      sx: {
        alignItems: {
          xs: 'center',
          sm: 'center',
          md: 'center',
          lg: 'center',
        },
      },
    },
    box: {
      bgcolor: '#FAFAFA',
      p: 2,
      borderRadius: 2,
    },
    textsTop: {
      title: {
        text: 'Tipo tarjeta (entre contenido)',
        sx: { fontWeight: 'bold' },
      },
      subTitle: {
        text: 'Medida pixeles: 320 ancho x 200 alto',
        sx: { color: '#646464' },
      },
    },
    gridImgContainer: {
      gap: 2,
      justifyContent: 'center',
      urlOne: imageThirdSectionImgOne,
      urlTwo: imageThirdSectionImgTwo,
    },
    textBottom: {
      sx: {
        width: '80%',
        mt: 2,
      },
      word: '',
      description:
        'Será visible en la lista de avisos y solicitudes de transporte.',
    },
    textTable: {
      title: 'Inversión anuncio (tarjeta)',
      word: 'CPM',
      description: 'Costo por mil impresiones (vistas del anuncio)',
      rows: rowsTarjeta,
    },
    button: {
      link: ADS_SOON_LANDING_PATH,
      text: 'COMPRAR PUBLICIDAD',
    },
  },
];
