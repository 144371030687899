import { FC } from "react";

type Props = {
  children?: JSX.Element | JSX.Element[] | string;
  component: any;
  props: any;
};

const DynamicComponent: FC<Props> = ({
  component: Component,
  children,
  props,
}) => {
  if (!Component) return <></>;

  if (children) {
    return (
      <>
        <Component {...props}>{children}</Component>
      </>
    );
  }

  return (
    <>
      <Component {...props} />
    </>
  );
};

export default DynamicComponent;
