import {
  CLIENT_RENEW_NEGOTATION_REQUEST,
  EDIT_CLIENT_NEGOTATION_CONDITIONS,
  EDIT_CLIENT_NEGOTATION_DATE,
  EDIT_CLIENT_NEGOTATION_DESTINY,
  EDIT_CLIENT_NEGOTATION_ENVIRONMENT,
  EDIT_CLIENT_NEGOTATION_LOAD,
  EDIT_CLIENT_NEGOTATION_ORIGIN,
  EDIT_CLIENT_NEGOTATION_PRICE,
  EDIT_CLIENT_NEGOTATION_RUTE,
  EDIT_NEGOTATION_NOTICE_REQUEST,
  NOTICE_NEGOTATION_CLIENT_RESPONSE_PATH,
  NOTICE_NEGOTATION_REQUEST_PATH,
  REMOVE_NOTICE_REQUEST,
  SAVE_IMAGES_NEGOTIATION_FLOW,
} from '@/consts/pathServices';

import {
  RequestCondiciones,
  RequestDate,
  RequestEnvironment,
  RequestPrice,
  RequestRute,
  Requestdestiny,
  Requestorigin,
} from '@/models/noticeNegotation/noticeNegotationModel';
import { NoticeNegotiationIamgesAPI } from '@/models/noticeNegotation/noticeNegotiationImagesModel';
import { connection } from './connection';
import { instanceCustomerRequest } from './connection/intanceCustomerRequest';
import { HEADERS_MULTIPART } from './utils/consts';
import { setFormDataByObject } from './utils/utils';
import { IRequestNegResponseSuccess } from './interfaces/RequestNegResponseSuccess';

const API = connection(instanceCustomerRequest);

export const requestNegotationServices = async (
  idRequestType: string,
  body: any
): Promise<IRequestNegResponseSuccess> => {
  const path = NOTICE_NEGOTATION_REQUEST_PATH.replace(
    ':idRequestType',
    idRequestType
  );
  const { data } = await API.post(path, body);

  return data;
};

export const getNoticeResponseToClient = async (
  idRequestType: string
): Promise<any> => {
  const path = NOTICE_NEGOTATION_REQUEST_PATH.replace(
    ':idRequestType',
    idRequestType
  );
  const { data } = await API.get(path);

  return data;
};

export const putNoticeResponseToDriver = async (
  idRequestType: string,
  body: any
): Promise<any> => {
  const path = NOTICE_NEGOTATION_CLIENT_RESPONSE_PATH.replace(
    ':idRequestType',
    idRequestType
  );
  const { data } = await API.put(path, body);

  return data;
};

// ==================================================
//             Imagenes de la negociacion
// ==================================================
// Guarda las imagenes de las ofertas a los avisos
export const saveImagesNegotiationServices = async (
  idPhotosVerified: number,
  images: any
): Promise<NoticeNegotiationIamgesAPI> => {
  const path = SAVE_IMAGES_NEGOTIATION_FLOW.replace(
    ':idPhotosVerified',
    idPhotosVerified.toString()
  );
  const headers = HEADERS_MULTIPART;
  const formData = setFormDataByObject(images);
  const API = connection();
  const { data } = await API.post(path, formData, { headers });

  return data;
};

// ============================================================================
//       SERVICIOS DE LA EDICIÓN DE LA SOLICITUD POR PARTE DEL CLIENTE
// ============================================================================

export const putRuteRequestEdit = async (
  idRequestType: string,
  body: RequestRute
): Promise<any> => {
  const path = EDIT_CLIENT_NEGOTATION_RUTE.replace(
    ':idRequestType',
    idRequestType
  );

  const { data } = await API.put(path, body);

  return data;
};

export const putLoadRequestEdit = async (
  idRequestType: string,
  body: any
): Promise<any> => {
  const headers = HEADERS_MULTIPART;
  const path = EDIT_CLIENT_NEGOTATION_LOAD.replace(
    ':idRequestType',
    idRequestType
  );
  const formData = setFormDataByObject(body);

  const { data } = await API.put(path, formData, { headers });

  return data;
};

export const putConditionsRequestEdit = async (
  idRequestType: string,
  body: RequestCondiciones
): Promise<any> => {
  const path = EDIT_CLIENT_NEGOTATION_CONDITIONS.replace(
    ':idRequestType',
    idRequestType
  );
  const { data } = await API.put(path, body);

  return data;
};

export const putPriceRequestEdit = async (
  idRequestType: string,
  body: RequestPrice
): Promise<any> => {
  const path = EDIT_CLIENT_NEGOTATION_PRICE.replace(
    ':idRequestType',
    idRequestType
  );
  const { data } = await API.put(path, body);

  return data;
};

// ==================================================================================================
//       SERVICIOS DE LA EDICIÓN DE LA SOLICITUD POR PARTE DEL CLIENTE PERO DE LA MUDANZA
// ==================================================================================================

export const putDateRequestEdit = async (
  idRequestType: string,
  body: RequestDate
): Promise<any> => {
  const path = EDIT_CLIENT_NEGOTATION_DATE.replace(
    ':idRequestType',
    idRequestType
  );
  const { data } = await API.put(path, body);

  return data;
};

export const putOriginRequestEdit = async (
  idRequestType: string,
  body: Requestorigin
): Promise<any> => {
  const path = EDIT_CLIENT_NEGOTATION_ORIGIN.replace(
    ':idRequestType',
    idRequestType
  );
  const { data } = await API.put(path, body);

  return data;
};

export const putDestinyRequestEdit = async (
  idRequestType: string,
  body: Requestdestiny
): Promise<any> => {
  const path = EDIT_CLIENT_NEGOTATION_DESTINY.replace(
    ':idRequestType',
    idRequestType
  );
  const { data } = await API.put(path, body);

  return data;
};

export const putEnvironmentRequestEdit = async (
  idRequestType: string,
  body: RequestEnvironment
): Promise<any> => {
  const path = EDIT_CLIENT_NEGOTATION_ENVIRONMENT.replace(
    ':idRequestType',
    idRequestType
  );
  const { data } = await API.put(path, body);

  return data;
};

// ==================================================================================================
//       SERVICIOS DE RENOVAR LA SOLICITUD POR PARTE DEL CLIENTE
// ==================================================================================================

export const putRenewDateRequest = async (
  idRequestType: string,
  body: RequestDate
): Promise<any> => {
  const path = CLIENT_RENEW_NEGOTATION_REQUEST.replace(
    ':idRequestType',
    idRequestType
  );
  const { data } = await API.put(path, body);

  return data;
};

// ============================================================================
//       SERVICIO DE LA ELIMINACIÓN DE LA SOLICITUD
// ============================================================================

export const putRemoveNoticeRequest = async (
  idRequestType: string
): Promise<any> => {
  const path = REMOVE_NOTICE_REQUEST.replace(':idRequestType', idRequestType);
  const { data } = await API.put(path);

  return data;
};

// ============================================================================
//       SERVICIO DE LA EDICION DE LA NEGOCIACION
// ============================================================================

export interface BodyUploadPriceNegotationPrice {
  precio: number;
}

export const uploadPriceNegotation = async (
  idRequestType: string,
  body: BodyUploadPriceNegotationPrice
): Promise<any> => {
  const path = EDIT_NEGOTATION_NOTICE_REQUEST.replace(
    ':idRequestType',
    idRequestType
  );
  const { data } = await API.put(path, body);

  return data;
};
