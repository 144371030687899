import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import SwitchControl from "@/components/form/switchControl/SwitchControl";
import HeaderSection from "@/components/headerSection/HeaderSection";
import BannerModal from "@/components/modals/BannerModal/BannerModal";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import { nottifficationUnsavedChanges } from "@/consts/notifficationsMessage";
import { SUCCESS_TEXT_EDIT_CONDITIONS } from "@/consts/textSuccess";
import { PROFILE_NOTICE_LIST_PATH } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { DetailsCoreModel, NoticeConditions } from "@/models/notice/noticeModels";
import { UsuarioAPI } from "@/models/userAuth/user";
import useDetailNotice from "@/pages/detailNotice/hook";
import { createServiceConditionsDataLayer } from "@/services/dataLayer/createNotice";
import { setListOfServices } from "@/store/includedServices/includedServicesSlice";
import { SelectNotice } from "@/store/noticeRedux/noticeSlice";
import { editNoticeConditionsThunk } from "@/store/noticeRedux/noticeThunks";
import {
  getNextRoute,
  getPrevRoute,
} from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { setMessageEdit } from "@/store/saveFlow/saveFlowSlice";
import {
  Container,
  Stack,
  Typography,
  styled
} from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  buttonText,
  currentRuleConditions,
  headerEditText
} from "./rulesByFlow";

const ContainerArea = styled(Container)(
  () => `
    padding: 16px 0;
  `
);

const SubTitleConditions = styled(Typography)(
  ({ theme }) => `
    font-family: ${theme.typography.label1.fontFamily};
    font-weight: ${theme.typography.label1.fontWeight};
    line-size: ${theme.typography.label1.fontSize};
    line-height: ${theme.typography.label1.lineHeight};
    padding: 10px 0;
    min-height: 40px;
  `
);

const Conditions = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isDirtyfields, setIsDirtyfields] = useState(false);


  const navigate = useNavigate();
  const location = useLocation();
  const {
    includedServices: { listOfServices },
    notice: { selectedNotice, isLoading },
    user: { user },
  } = useAppSelector((state) => state);

  const { handleGetCurrentDetailNotice } = useDetailNotice();

  const notice: DetailsCoreModel = useMemo(
    () => handleGetCurrentDetailNotice(),
    [isLoading]
  );

  const listConditions = notice.avisocondiciones

  const dispatch = useAppDispatch();
    const {TITLE, MESSAGE, LEFT_BTN_TEXT, RIGHT_BTN_TEXT} = nottifficationUnsavedChanges

  useEffect(() => {
   
    if (!!notice) {
      setIsEdit(location.pathname.startsWith(PROFILE_NOTICE_LIST_PATH));
      handlePreloadListOfServices();
    }
    const currentUSer = user as UsuarioAPI;
    createServiceConditionsDataLayer(
      currentUSer.id,
      (selectedNotice as SelectNotice)?.title
    );
  }, []);

  const handlePreloadListOfServices = () => {
   
    const newList = notice.avisocondiciones.map((list) => ({
      ...list,
      isActive: list.flgseleccion || 0,
    }));

    dispatch(setListOfServices(newList as NoticeConditions[]));
  };

  const handleGoBack = () => {
    if (!isEdit) {
      dispatch(getPrevRoute(navigate));
      return;
    }

    if (isDirtyfields) {
      setShowAlert(true);
    } else {
      previusNavigate();
    }
  };

  const handleClick = () => {
    if (isLoading) return;
    
    if (isEdit) {
      dispatch(editNoticeConditionsThunk(notice.id, listOfServices)).then(
        handleSuccessEdit
      );
    } else {
      handleNavigate();
    }
  };

  const handleSuccessEdit = () => {
    dispatch(setMessageEdit(SUCCESS_TEXT_EDIT_CONDITIONS));
    previusNavigate();
  };

  const handleNavigate = () => {
    dispatch(getNextRoute(navigate));
  };




  const handleChange = (item: any, isActive: number) => {

    const newList = listOfServices.map((list) => {
      if (list.id === item.id) {
        return {
          ...item,
          isActive,
        };
      }

      return list;
    });
    setIsDirtyfields(true);
    dispatch(setListOfServices(newList));
  };

  const handleHeaderText = (): { title: string; subTitle: string } => {
    if (location.pathname.startsWith(PROFILE_NOTICE_LIST_PATH)) {
      return headerEditText;
    }

    return currentRuleConditions[(selectedNotice as SelectNotice).id].header;
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  const previusNavigate = () => {
    navigate(-1);
  };

  const headerText = useMemo(() => handleHeaderText(), []);



  return (
    <Container maxWidth="xs">
      <HeaderSection
        icon="icon-arrow-left--primary"
        title={headerText.title}
        subTitle={headerText.subTitle}
        onClick={handleGoBack}
      />
      <BannerModal
        open={showAlert}
        onClose={handleCloseModal}
        title={TITLE}
        content={MESSAGE}
        option1={{
          title: LEFT_BTN_TEXT,
          onClick: handleCloseModal,
        }}
        option2={{
          title: RIGHT_BTN_TEXT,
          onClick: previusNavigate,
        }}
        color="org"
        sx={{
          width: "333px",
          height: "230px",
        }}
        sxAccion={{
          padding: "0px",
        }}
      />

      <ContainerArea>
        <SectionIcon icon="icon-document" />
        <Stack gap="4px">
          <SubTitleConditions>Tu servicio incluye:</SubTitleConditions>
          {!!listConditions.length &&
            listConditions.map((services) => 
               (
              <Fragment key={services.id}>
                <SwitchControl
                  text={services.nombre}
                  hideSwitch={!!services.flgver}
                  onChange={(isActive) => handleChange(services, isActive)}
                  value={!!services.flgseleccion}
                />
              </Fragment>
            ))}
        </Stack>
        <ButtomLoader
          sx={{ marginTop: "24px" }}
          onClick={handleClick}
          isLoading={isLoading}
          disabled={isLoading}
        >
          {isEdit ? buttonText.edit : buttonText.flow}
        </ButtomLoader>
      </ContainerArea>
    </Container>
  );
};

export default Conditions;
