import { CustomCard } from "@/components/Card/CustomCard";
import EmptyListData from "@/components/emptyListData/EmptyListData";
import SkeletonInfoCardList from "@/components/skeletons/SkeletonInfoCardList";
import { agreementClosedText } from "@/consts/noticeConsts";
import useObserver from "@/hooks/useObserver";
import { MyNegotiationes } from "@/models/userAuth/agrementToAccept";
import { Grid, styled } from "@mui/material";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import CardBody from "./negotationCard/CardBody";
import CardFooter from "./negotationCard/CardFooter";
import CardHeader from "./negotationCard/CardHeader";
import { deleteDomainUrl } from '../../../../../utils/helpers';
import { EmptyNegotationProfileClient, EmptyNegotationProfileTransport } from "@/assets/svg";
import { useAppSelector } from "@/hooks/useReducer";
import { UsuarioAPI } from '../../../../../models/userAuth/user';

type Props = {
  list: MyNegotiationes[];
  isLoading: boolean;
  paginateCallback?: () => void;
  isTypeClient?: boolean;
};

const LinkWrapper = styled(Link)(
  () => `
    text-decoration: none;
  `
);

const ListChildren = ({
  list,
  isLoading,
  paginateCallback,
  isTypeClient,
}: Props) => {
  const { user } = useAppSelector((state) => state.user);
  const { tipousuario } = user as UsuarioAPI;
  const refObserver = useRef<HTMLDivElement | null>(null);
  const firstRender = useRef<boolean>(true);
  const { isNearScreen } = useObserver({
    distance: "100px",
    externalRef: refObserver,
  });

  useEffect(() => {
    handlePaginateCallback();

    if (firstRender.current) {
      firstRender.current = false;
    }
    return () => {};
  }, [isNearScreen]);

  const handlePaginateCallback = () => {
    const rules = [
      !firstRender.current,
      !!list.length,
      !isLoading,
      isNearScreen,
    ];

    if (rules.some((rule) => !rule)) return;

    paginateCallback && paginateCallback();
  };

  if (!list.length && !isLoading) return <EmptyListData title="No tienes negociaciones" 
  render={(tipousuario === "T") ?
    <EmptyNegotationProfileTransport/> :
  <EmptyNegotationProfileClient/>}
  optionTwo
/>;

  const showRatingButton = (data: MyNegotiationes) => {
    const {
      estado
    } = data;
    return estado === agreementClosedText;
  }

  return (
    <>
      <Grid container spacing={2}>
        {list.map((negotiation, index) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={`cards-${index}`}>
            <LinkWrapper
              to={deleteDomainUrl(negotiation.link)}
              state={{
                hasBackNavigate: true,
                showCalification: showRatingButton(negotiation),
              }}
            >
              <CustomCard>
                <CardHeader data={negotiation} />
                <CardBody data={negotiation} isTypeClient={isTypeClient} />
                <CardFooter data={negotiation} />
              </CustomCard>
            </LinkWrapper>
          </Grid>
        ))}
        {isLoading && <SkeletonInfoCardList md={4} />}
      </Grid>
      <div ref={refObserver} />
    </>
  );
};

export default ListChildren;
