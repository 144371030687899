export const currentRuleConditions: Record<number, any> = {
  1: {
    header: {
      title: 'Condiciones del servicio',
      subTitle: 'Crear avisos en la ciudad',
    },
  },
  2: {
    header: {
      title: 'Condiciones del servicio',
      subTitle: 'Crear aviso multidestino en la ciudad',
    },
  },
  3: {
    header: {
      title: 'Condiciones del servicio',
      subTitle: 'Crear avisos entre ciudades',
    },
  },
  4: {
    header: {
      title: 'Condiciones del servicio',
      subTitle: 'Crear aviso de remate entre ciudades',
    },
  },
  5: { 
    header: {
      title: 'Condiciones del servicio',
      subTitle: 'Crear avisos de mudanza',
    },
  },
};

export const headerEditText = {
  title: 'Condiciones del servicio',
  subTitle: 'Editar',
};

export const buttonText = {
  edit: 'GUARDAR',
  flow: 'SIGUIENTE',
};

export const pickUpOriginClient = '¿Recojo en punto de origen de cliente?';
