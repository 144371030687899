import { ConfigDataNegotation } from '../interface/noticeNegRequestInterface';
import AgreementConfirmationCounterOffer from './components/agreementConfirmationCounterOffer/AgreementConfirmationCounterOffer';
import { ConfigCounterOfferInCityClient } from './components/agreementConfirmationCounterOffer/clientConfig/inCity/configCounterOfferInCity';
import { ConfigCounterOfferMultidestinyClient } from './components/agreementConfirmationCounterOffer/clientConfig/multidestiny/configCounterOfferMultidestiny';
import { ConfigCounterOfferInCityDriver } from './components/agreementConfirmationCounterOffer/driverConfig/inCity/configCounterOfferInCity';
import { ConfigCounterOfferMultidestinyDriver } from './components/agreementConfirmationCounterOffer/driverConfig/multidestiny/configCounterOfferBetweenCities';

import AgreementConfirmationOffer from './components/AgreementConfirmationOffer/AgreementConfirmationOffer';
import { ConfigOfferInCityClient } from './components/AgreementConfirmationOffer/clientConfig/inCity/ConfigOfferInCity';
import { ConfigOfferMultidestinyClient } from './components/AgreementConfirmationOffer/clientConfig/multidestiny/ConfigOfferMultidestiny';

import { ConfigCounterOfferBetweenCitiesClient } from './components/agreementConfirmationCounterOffer/clientConfig/betweenCities/configCounterOfferInBetweenCities';
import { ConfigCounterOfferMovingClient } from './components/agreementConfirmationCounterOffer/clientConfig/moving/configCounterOfferMoving';
import { ConfigCounterOfferBetweenCitiesDriver } from './components/agreementConfirmationCounterOffer/driverConfig/betweenCities/configCounterOfferBetweenCities';
import { ConfigCounterOfferMovingDriver } from './components/agreementConfirmationCounterOffer/driverConfig/moving/configCounterOfferMoving';

import { ConfigOfferMovingClient } from './components/AgreementConfirmationOffer/clientConfig/moving/ConfigOfferMoving';
import { ConfigOfferBetweenCitiesDriver } from './components/AgreementConfirmationOffer/driverConfig/betweenCities/configOfferBetweenCities';
import { ConfigOfferInCityDriver } from './components/AgreementConfirmationOffer/driverConfig/inCity/configOfferInCity';
import { ConfigOfferMovingDriver } from './components/AgreementConfirmationOffer/driverConfig/moving/configOfferMoving';
import { ConfigOfferMultidestinyDriver } from './components/AgreementConfirmationOffer/driverConfig/multidestiny/configOfferMultidestiny';
import { ConfigOfferBetweenCitiesClient } from './components/AgreementConfirmationOffer/clientConfig/betweenCities/ConfigOfferbetweenCities';
import { ConfigOfferExpressClient } from './components/AgreementConfirmationOffer/clientConfig/express/ConfigOfferExpress';
import { ConfigOfferExpressDriver } from './components/AgreementConfirmationOffer/driverConfig/express/configOfferExpress';

export const objectRenderComponent: Record<number, () => JSX.Element> = {
  1: AgreementConfirmationOffer,
  2: AgreementConfirmationCounterOffer,
  3: AgreementConfirmationOffer, // este es para express
  4: AgreementConfirmationCounterOffer,
};

export type ConfigOfferAgreementType = Record<number, ConfigDataNegotation[]>;

type ObjConfigAgreementConfirmationType = Record<
  number,
  Record<number, ConfigOfferAgreementType>
>;

/* 
    Para estas Configuraciones es importante tener en cuenta que:
     Las primeras opciones de numeros del 1 al 5 es tipo de solicitud Ejemplo: InCity, multidestiny ...
     Las siguientes dos opciones 1 y 2 son del tipo de usuario (cliente o transportista)*/

const ConfigurationToRenderOffer: ObjConfigAgreementConfirmationType = {
  1: {
    1: { ...ConfigOfferInCityClient }, //vista del cliente
    2: { ...ConfigOfferInCityDriver }, //vista del transportista
  },
  2: {
    1: { ...ConfigOfferMultidestinyClient },
    2: { ...ConfigOfferMultidestinyDriver },
  },
  3: {
    1: { ...ConfigOfferBetweenCitiesClient }, //vista del cliente
    2: { ...ConfigOfferBetweenCitiesDriver }, //vista del transportista
  },
  4: {
    1: { ...ConfigOfferExpressClient }, //vista del cliente express
    2: { ...ConfigOfferExpressDriver }, //vista del transportista express
  },
  5: {
    1: { ...ConfigOfferMovingClient },
    2: { ...ConfigOfferMovingDriver },
  },
};

const ConfigurationToRenderCounterOffer: ObjConfigAgreementConfirmationType = {
  1: {
    1: { ...ConfigCounterOfferInCityClient }, //vista del cliente
    2: { ...ConfigCounterOfferInCityDriver }, //vista del transportista
  },
  2: {
    1: { ...ConfigCounterOfferMultidestinyClient },
    2: { ...ConfigCounterOfferMultidestinyDriver },
  },
  3: {
    1: { ...ConfigCounterOfferBetweenCitiesClient },
    2: { ...ConfigCounterOfferBetweenCitiesDriver },
  },
  5: {
    1: { ...ConfigCounterOfferMovingClient },
    2: { ...ConfigCounterOfferMovingDriver },
  },
};

type GeneralCofigurationToRenderType = Record<
  number,
  ObjConfigAgreementConfirmationType
>;

export const generalCofigurationToRender: GeneralCofigurationToRenderType = {
  1: ConfigurationToRenderOffer,
  2: ConfigurationToRenderCounterOffer,
};
