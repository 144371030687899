import { ID_EXPRESS_FLOW } from "@/consts/defaultConsts";
import { driverurl, optionFour, optionOne, optionThree, optionTwo } from "@/consts/noticeConsts";
import { useAppSelector } from "@/hooks/useReducer";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import { useMediaQuery, useTheme } from "@mui/material";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { idToConfigToReturnFormat } from "../../adapters/noticeRequestFlowAdapters";
import { ConfigDataNegotation } from "../../interface/noticeNegRequestInterface";
import { generalCofigurationToRender } from "../config";


const useRejectionConfirmation = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const { pathname } = useLocation();
  const {
    noticeNegotationRequest: { savedDataNogotation },
  } = useAppSelector((state) => state);
  const typeRequestId = savedDataNogotation?.tipoSolicitudNegociacion.id
  const { tipoSolicitud } = idToConfigToReturnFormat(savedDataNogotation as SolicitudNegociacion);
  const showTwoAvatars = pathname?.includes(driverurl);
  const validateClient = pathname?.includes(driverurl)
    ? optionOne
    : optionTwo;
    const optionToRender =  typeRequestId === optionTwo ? optionTwo : optionOne
  const valdateExpress = savedDataNogotation?.solicitud.tipoSolicitud.id === ID_EXPRESS_FLOW

  const handleGetIsRejectOneNotification = () => {

    const configToReturn =
      generalCofigurationToRender[optionToRender][tipoSolicitud][
        validateClient
      ][optionOne];
    if (!savedDataNogotation) return configToReturn;

    return configToReturn;
  };

  const handleGetIsRejectTwoNotification = () => {
    const configToReturn = generalCofigurationToRender[optionToRender][
      tipoSolicitud
    ][validateClient][optionTwo] as ConfigDataNegotation[];
    if (savedDataNogotation) return configToReturn;

    return configToReturn;
  };

  const handleGetCondition = () => {
    const configToReturn = generalCofigurationToRender[optionToRender][
      tipoSolicitud
    ][validateClient][optionThree] as ConfigDataNegotation[];
    if (savedDataNogotation) return configToReturn;

    return configToReturn;
  };

  const handleGetIsRejectThreeNotification = () => {
    const configToReturn = generalCofigurationToRender[optionToRender][
      tipoSolicitud
    ][validateClient][optionFour] as ConfigDataNegotation[];
    if (savedDataNogotation) return configToReturn;

    return configToReturn;
  };

  const selectOneconfigData: ConfigDataNegotation[] = useMemo(
    () => savedDataNogotation && handleGetIsRejectOneNotification(),
    [savedDataNogotation]
  );
  const selectTwoconfigData: ConfigDataNegotation[] = useMemo(
    () => handleGetIsRejectTwoNotification(),
    [savedDataNogotation]
  );

  const selectConditionconfigData: ConfigDataNegotation[] = useMemo(
    () => handleGetCondition(),
    [savedDataNogotation]
  );
  const selectThreeconfigData: ConfigDataNegotation[] = useMemo(
    () => handleGetIsRejectThreeNotification(),
    [savedDataNogotation]
  );

  return {
    selectOneconfigData,
    selectTwoconfigData,
    selectConditionconfigData,
    selectThreeconfigData,
    savedDataNogotation,
    showTwoAvatars,
    matches,
    valdateExpress
  };
};

export default useRejectionConfirmation;
