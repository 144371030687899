declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

// Acepto aviso
export const acceptNotice = (idUsuario: number, noticeType: string) => {
  window.dataLayer.push({
    event: 'service_acceptance',
    idusuario: idUsuario,
    tipoaviso: noticeType,
  });
};

// Contraoferta aviso
export const counterOfferNotice = (idUsuario: number, noticeType: string) => {
  window.dataLayer.push({
    event: 'service_counteroffer',
    idusuario: idUsuario,
    tipoaviso: noticeType,
  });
};

// Acepta oferta del cliente
export const acceptNoticeClient = (idUsuario: number, noticeType: string) => {
  window.dataLayer.push({
    event: 'service_acceptance_agreement',
    idusuario: idUsuario,
    tipoaviso: noticeType,
  });
};

// Acepta contraoferta del cliente
export const acceptCounterOfferClient = (
  idUsuario: number,
  noticeType: string
) => {
  window.dataLayer.push({
    event: 'service_counteroffer_agreement',
    idusuario: idUsuario,
    tipoaviso: noticeType,
  });
};

// Co-contraoferta aviso
export const cocounterOfferNoticeDataLayer = (
  idUsuario: number,
  noticeType: string
) => {
  window.dataLayer.push({
    event: 'service_co-counteroffer',
    idusuario: idUsuario,
    tipoaviso: noticeType,
  });
};

// Acepta co-contraoferta transportista
export const cocounterNoticeDriver = (
  idUsuario: number,
  noticeType: string
) => {
  window.dataLayer.push({
    event: 'service_co-counteroffer_agreement',
    idusuario: idUsuario,
    tipoaviso: noticeType,
  });
};
