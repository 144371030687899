import {
  MODAL_TYPE,
  ModalType,
} from "@/components/modals/searchModalLocationOffert/configDataModal";
import {
  ID_BETWEEN_CITIES,
  ID_COUNTEROFFER,
  ID_OFFER,
} from "@/consts/defaultConsts";
import {
  maxLengthPrice,
  maxLengthReference,
  textCannotLowerPrice,
  textCannotUpperPrice,
  textNegativePrice,
} from "@/consts/noticeConsts";
import { COUNTER_OFFER_RUTE } from "@/consts/offertPath";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import {
  DestinisAPI,
  NoticeDetailBetweenCitiesModel,
} from "@/models/notice/noticeModels";
import { InputProps } from "@/pages/flowNoticeNegotation/interfaces/interfaces";
import {
  getFirstStepNoticeType,
  getFirstStepNoticeTypeInterface,
} from "@/pages/flowNoticeNegotation/shared/adapters/clientAcceptOrCounterOfferAdapter";
import { NoticeSendData } from "@/store/noticeV2ClientAcceptCounterOffer/interfaces/sendDataInterfacesEnglish";
import { SelectedUbications } from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferSlice";
import {
  fillSendDataNoticeThunk,
  getDistrictsByIdCityOriginAndDestinyThunk,
  postDataNoticeThunk,
  setSelectedUbicationsThunk,
} from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferThunk";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { searchTermInArray, stringDDMMYYYToDate } from "@/utils/helpers";
import dayjs, { Dayjs } from "dayjs";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { configFormOffert, modalTitleConfig } from "../configData";

const minDate = dayjs().add(1, "day");

interface IFormDatos
  extends Omit<
    NoticeSendData,
    "cargoDescription" | "verifiedPhotosId" | "date"
  > {
  date: Dayjs | null;
  price?: number;
}

interface HookProps {
  modalType: "origin" | "destiny";
  errorPrice: string;
  modalTitle: string;
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  searchList: any[];
  form: IFormDatos;
  setForm: Dispatch<SetStateAction<IFormDatos>>;
  getDistrictByIdCity: () => void;
  handleInputClick: (event: EventType["click"], { name }: InputProps) => void;
  handleOnChange: ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleSubmit: () => void;
  handleTitleSelected: (title: string, nombre: string) => string;
  handleSetValue: (selected: DestinisAPI) => string;
  handleChangeModal: (term: string) => void;
  handleSubmitModal: (selected: Record<string, any> | null) => void;
  handleChangeDate: (value: dayjs.Dayjs | null) => void;
  handleDisabled: () => boolean;
}

export const useRuteBtnCities = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isCounterOffer = pathname.endsWith(COUNTER_OFFER_RUTE);
  const dispatch = useAppDispatch();
  const { origin, destiny, price } = configFormOffert;
  const {
    noticeV2ClientAorCO: {
      sendData: { betweenCities },
      selectedUbications,
      avisodestinos,
      avisoorigenes,
      selectTypeNotice,
      selectDate,
    },
  } = useAppSelector((state) => state);
  const notice = selectTypeNotice as NoticeDetailBetweenCitiesModel;
  const [errorDate, setErrorDate] = useState("");
  const [errorPrice, setErrorPrice] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [searchList, setSearchList] = useState<any[]>([]);
  const [modalType, setModalType] = useState<ModalType["type"]>(
    MODAL_TYPE.origin
  );
  const tipoAvisoId = notice?.tipoaviso?.id;
  const [form, setForm] = useState<IFormDatos>(handlePreloadData());

  useEffect(() => {
    if (form) {
      const ruteToSendData = {
        key: getFirstStepNoticeType(tipoAvisoId),
        value: getFirstStepNoticeTypeInterface(tipoAvisoId, {
          ...betweenCities,
          ...form,
        }),
      };

      const values = {
        data: ruteToSendData,
        tipoAvisoId,
        activeLoading: false,
        noExecuteImg: true,
      };
      const handler = setTimeout(() => {
        dispatch(fillSendDataNoticeThunk(values));
      }, 1000);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [form]);

  useEffect(() => {
    if (!avisodestinos?.length) {
      getDistrictByIdCity();
    }
  }, []);

  useEffect(() => {
    if (avisoorigenes.length) {
      setSearchList(avisoorigenes);
    }
  }, [avisoorigenes]);

  useEffect(() => {
    if (modalType === MODAL_TYPE.origin) {
      setSearchList(avisoorigenes);
    } else {
      setSearchList(avisodestinos);
    }
  }, [modalType]);

  function handlePreloadData() {
    const initialDate = selectDate ? stringDDMMYYYToDate(selectDate) : minDate;
    const { destinyId, originId, originReference, destinyReference, price } =
      betweenCities;
    const origin = selectedUbications?.origin;
    const destiny = selectedUbications?.destiny;

    return {
      negotiationTypeId: isCounterOffer ? ID_COUNTEROFFER : ID_OFFER,
      originId: originId || origin?.iddistrito || "",
      destinyId: destinyId || destiny?.iddistrito || "",
      originReference: originReference || "",
      destinyReference: destinyReference || "",
      date: initialDate,
      price: price || "",
    };
  }

  const getDistrictByIdCity = () => {
    const { idciudad, idciudaddestino } = notice;
    dispatch(
      getDistrictsByIdCityOriginAndDestinyThunk(idciudad, idciudaddestino || 0)
    );
  };

  const handleInputClick = (_: EventType["click"], { name }: InputProps) => {
    const invalidInputs = [origin.input1.name, destiny.input1.name];
    if (invalidInputs.every((inputName) => inputName !== name)) return;

    setModalTitle(modalTitleConfig[name]);

    setModalType(name as ModalType["type"]);

    setOpenModal(true);
  };

  const handleOnChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = target;
    if (name === origin.input1.name || name === destiny.input1.name) {
      setOpenModal(true);
      return;
    }

    if (name === origin.input2.name) {
      value.length < maxLengthReference &&
        setForm({ ...form, originReference: value });
    } else if (name === price.input.name) {
      setErrorPrice("");
      value.length <= maxLengthPrice &&
        setForm({ ...form, price: Number(value) });
    } else {
      value.length < maxLengthReference &&
        setForm({ ...form, destinyReference: value });
    }
  };

  const handleSubmit = () => {
    const price = Number(form?.price);
    const noticePrice = Number(notice?.precio);

    if (isCounterOffer) {
      if (price < 0) {
        setErrorPrice(textNegativePrice);
        return;
      }
      if (price < noticePrice / 2) {
        setErrorPrice(textCannotLowerPrice);
        return;
      }
    }

    if (price > noticePrice * 3) {
      setErrorPrice(textCannotUpperPrice);
      return;
    }

    const data = { ...betweenCities, ...form };

    if (!isCounterOffer) delete data.price;

    dispatch(postDataNoticeThunk(data, `${notice.id}`)).then(() => {
      handleNextRute();
    });
  };

  const handleNextRute = () => {
    dispatch(
      getNextRoute(navigate, {
        valueToReplace: notice.id?.toString(),
        valueToSearch: ":id",
      })
    );
  };

  const handleTitleSelected = (title: string, nombre: string) => {
    let currentTitle = title;
    if (notice.tipoaviso.id === ID_BETWEEN_CITIES) {
      currentTitle += `: ${nombre}`;
    }

    return currentTitle;
  };

  const handleSetValue = (selected: DestinisAPI) => {
    if (!selected?.iddistrito) {
      return "";
    }

    return selected?.nombre;
  };

  const handleChangeModal = (term: string) => {
    let listStorage = avisodestinos;
    if (modalType === MODAL_TYPE.origin) {
      listStorage = avisoorigenes;
    }
    const currentList = structuredClone(listStorage);
    const search: DestinisAPI[] = searchTermInArray(term, currentList, [
      "nombre",
    ]);

    setSearchList(search);
  };

  const handleSubmitModal = (selected: Record<string, any> | null) => {
    if (!selected) return;

    const selectValue = {
      ...selected,
      iddistrito: selected?.id,
    } as DestinisAPI;

    if (modalType === MODAL_TYPE.origin) {
      setForm({ ...form, originId: Number(selectValue.iddistrito) });
      dispatch(
        setSelectedUbicationsThunk({
          ...selectedUbications,
          origin: selectValue,
        } as SelectedUbications)
      );
    } else {
      setForm({ ...form, destinyId: Number(selectValue.iddistrito) });
      dispatch(
        setSelectedUbicationsThunk({
          ...selectedUbications,
          destiny: selectValue,
        } as SelectedUbications)
      );
    }
    setOpenModal(false);
  };

  const handleChangeDate = (value: dayjs.Dayjs | null) => {
    setForm({ ...form, date: value });
    !!errorDate && setErrorDate("");
  };

  const handleDisabled = () => {
    const {
      originId,
      destinyId,
      originReference,
      destinyReference,
      date,
      price,
    } = form;
    let conditions: unknown[] = [
      originReference,
      destinyReference,
      date,
      originId,
      destinyId,
    ];

    if (isCounterOffer) {
      conditions.push(price);
    }

    return conditions.some((condition) => !condition);
  };

  const hookValue: HookProps = {
    modalType,
    errorPrice,
    modalTitle,
    openModal,
    setOpenModal,
    searchList,
    form,
    setForm,
    getDistrictByIdCity,
    handleInputClick,
    handleOnChange,
    handleSubmit,
    handleTitleSelected,
    handleSetValue,
    handleChangeModal,
    handleSubmitModal,
    handleChangeDate,
    handleDisabled,
  };

  return hookValue;
};
