import StandardAlert from "@/components/alerts/StandardAlert";
import HeaderSection from "@/components/headerSection/HeaderSection";
import { PROFILE_DATOS_PERSONALS_PATH } from "@/consts/typeServicesPaths";
import { useAppSelector } from "@/hooks/useReducer";
import useResize from "@/hooks/useResize";
import { RoleAPI, UsuarioAPI } from "@/models/userAuth/user";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { setSuccessMsg } from "@/store/userRedux/userSlice";
import Icon from "@/utils/Icon";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LinksDatos } from "../../rulesFlow";
import { CustomListLinks } from "../../styleProfile";

const Datos = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, isMobil] = useResize();

  const { user, successMsg } = useAppSelector((state) => state.user);

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const NavigateBack = () => {
    navigate(-1);
  };

  if (!isMobil) {
    navigate(PROFILE_DATOS_PERSONALS_PATH, { replace: true });
  }

  const icon = isMobil ? "icon-arrow-left--primary" : "";

  return (
    <>
      <HeaderSection
        icon={icon}
        title="Datos de la cuenta"
        onClick={NavigateBack}
        sx={{
          boxShadow: "none",
        }}
      />
      {successMsg && isMobil && (
        <StandardAlert
          sx={{ marginBottom: "16px" }}
          severity="success"
          color="success"
          onClick={() => {
            dispatch(setSuccessMsg(""));
          }}
        >
          Cambios guardados con éxito
        </StandardAlert>
      )}
      <CustomListLinks>
        {LinksDatos.map(
          (value, index) =>
            value.role?.includes(
              ((user as IUser).role as RoleAPI[])[0].nombre || ""
            ) && (
              <ListItem
                disablePadding
                key={index}
                sx={{
                  ".css-cveggr-MuiListItemIcon-root": {
                    minWidth: "5px",
                  },
                }}
                onClick={() => handleNavigate(value.path)}
              >
                <ListItemButton
                  sx={{
                    padding: "10px 5px",
                    margin: "0px 0px 16px 0px",
                    height: "56px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "40px",
                    }}
                  >
                    <Icon name={value.icon} size="md"/>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      value.nombre2 &&
                      (user as UsuarioAPI).tipoDocumento?.id.toString() === "4"
                        ? value.nombre2
                        : value.nombre
                    }
                  />
                  <ListItemIcon>
                    <i className="icon-small-arrow-right" />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            )
        )}
      </CustomListLinks>
    </>
  );
};

export default Datos;
