type ConfigProps = { text: string; showRefrigerate: boolean };
export const configRefrigerateSwitchById: Record<number, ConfigProps> = {
  1: {
    text: '¿Puede llevar carga refrigerada?',
    showRefrigerate: true,
  },
  2: {
    text: '¿Puede llevar carga refrigerada?',
    showRefrigerate: true,
  },
  3: {
    text: '¿Puede llevar carga refrigerada?',
    showRefrigerate: true,
  },
  4: {
    text: '¿Llevas carga refrigerada?',
    showRefrigerate: true,
  },
  5: {
    text: '',
    showRefrigerate: false,
  },
};
