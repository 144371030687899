import { styled } from "@mui/material";
import React from "react";

const StyleDiscountPercentage = styled("span")(
  ({ theme }) => `
    font-family: ${theme.typography.h1.fontFamily};
    font-size: ${theme.typography.label2.fontSize};
    font-weight: ${theme.typography.label2.fontWeight};
    line-height: ${theme.typography.label2.lineHeight};
    background-color: ${theme.palette.warning.main};
    color: ${theme.palette.common.white};
    position: absolute;
    width: 69px;
    height: 32px;
    right: 0px;
    top: 0px;
    border-radius: 0px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  `
);

type Props = {
  show: boolean;
  currentPrice: number;
  beforePrice: number;
};

const FlagDiscount = (props: Props) => {
  const { show, currentPrice = 0, beforePrice = 0 } = props;
  if (!show) return <></>;

  const handleCalculateDesount = () => {
    return Math.round((1 - currentPrice / beforePrice) * 100);
  };

  const descount = handleCalculateDesount();
  const template = `-${descount}%`;

  return <StyleDiscountPercentage>{template}</StyleDiscountPercentage>;
};

export default FlagDiscount;
