import { NoticesBussinesData } from '@/models/notice/noticeListOffersModels';
import { DestinisAPI } from '@/models/notice/noticeModels';
import { NoticeConditions } from '@/models/offert/offertConditionsModels';
import { Negotiation } from '@/models/offert/offertModels';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
  IOffertInitialState,
  ITipoInmuebles,
  PhotosPreLoaded,
  SaveInmuebleProps,
  SaveLoadOffertProps,
  SaveLocationProps,
} from './offert.interface';

const initialState: IOffertInitialState = {
  images: [],
  isLoading: false,
  error: '',
  errorCode: 0,
  // nuevo
  idTipoAvisoNegociacion: 0,
  descripcionCarga: '',
  pesoEstimado: 0,
  fecha: '',
  ayudantes: 0,
  precio: '',
  selectedOrigin: {
    iddistrito: 0,
    precio: 0,
    nombre: '',
  },
  referenciaOrigen: '',
  selectedDestiny: {
    iddistrito: 0,
    precio: 0,
    nombre: '',
  },
  referenciaDestino: '',
  savedOffert: null,
  avisoorigenes: [],
  avisodestinos: [],
  puntos: null,
  horas: null,
  conditions: [],
  // inmuebles:
  tipoInmuebles: [],
  idInmuebleOrigen: 0,
  idInmuebleDestino: 0,
  piso: '',
  pisoDestino: '',
  flgAscensor: '',
  flgAscensorDestino: '',
  metraje: '',
  noticesBussinesDataActives: {
    currentPage: -1,
    totalItems: -1,
    totalPages: -1,
    avisonegociaciones: [],
  },
  noticesBussinesDataHistory: {
    currentPage: -1,
    totalItems: -1,
    totalPages: -1,
    avisonegociaciones: [],
  },
  abiertas: 0,
  cerradas: 0,
  typeUser: '',
  flgHistorial: 0,

  // load images
  idVerifiedImages: 0,
  isLoadingImage: false,
  imageUploadError: '',
  photosPreLoaded: [],
};

export const offertSlice = createSlice({
  name: 'offert',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setIsLoadingImage: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingImage = payload;
    },
    setError: (
      state,
      { payload }: PayloadAction<{ message: string; status: number }>
    ) => {
      state.error = payload.message;
      state.errorCode = payload.status;
      state.isLoading = false;
    },
    resetError: (state) => {
      state.error = initialState.error;
      state.errorCode = initialState.errorCode;
    },
    setSelectOrigin: (state, { payload }: PayloadAction<DestinisAPI>) => {
      state.selectedOrigin = payload;
    },
    setSelectDestiny: (state, { payload }: PayloadAction<DestinisAPI>) => {
      state.selectedDestiny = payload;
    },
    setOffertData: (
      state,
      action: PayloadAction<Omit<SaveLoadOffertProps, 'images'>>
    ) => {
      state.descripcionCarga = action.payload.descripcionCarga;
      state.pesoEstimado = action.payload.pesoEstimado;
      state.isLoading = false;
    },
    setDataLocation: (state, { payload }: PayloadAction<SaveLocationProps>) => {
      state.referenciaOrigen = payload.originReference;
      state.referenciaDestino = payload.destinyReference;
      state.fecha = payload.departureDate;
      state.isLoading = false;
    },
    saveOffert: (state, { payload }: PayloadAction<Negotiation>) => {
      state.savedOffert = payload;
      state.error = '';
      state.errorCode = 0;
    },
    setTipoAvisoNegociacion: (state, { payload }: PayloadAction<number>) => {
      state.idTipoAvisoNegociacion = payload;
    },
    setAyudantes: (state, { payload }: PayloadAction<number>) => {
      state.ayudantes = payload;
    },
    setFecha: (state, { payload }: PayloadAction<string>) => {
      state.fecha = payload;
    },
    setPrice: (state, { payload }: PayloadAction<string>) => {
      state.precio = payload;
    },
    setAvisoOrigenes: (state, { payload }: PayloadAction<DestinisAPI[]>) => {
      state.avisoorigenes = payload;
      state.isLoading = false;
    },
    setAvisoDestinos: (state, { payload }: PayloadAction<DestinisAPI[]>) => {
      state.avisodestinos = payload;
      state.isLoading = false;
    },
    setPuntos: (state, { payload }: PayloadAction<number>) => {
      state.puntos = payload;
    },
    setHoras: (state, { payload }: PayloadAction<number>) => {
      state.horas = payload;
    },
    setConditions: (state, { payload }: PayloadAction<NoticeConditions[]>) => {
      state.conditions = payload;
      state.isLoading = false;
    },
    setTipoInmuebles: (state, { payload }: PayloadAction<ITipoInmuebles[]>) => {
      state.tipoInmuebles = payload;
    },
    setInmuebleOrigen: (state, { payload }: PayloadAction<number>) => {
      state.idInmuebleOrigen = payload;
    },
    setInmuebleDestino: (state, { payload }: PayloadAction<number>) => {
      state.idInmuebleDestino = payload;
    },
    setDataMovingOrigin: (
      state,
      { payload }: PayloadAction<SaveInmuebleProps>
    ) => {
      state.referenciaOrigen = payload.reference;
      state.idInmuebleOrigen = payload.idInmueble;
      state.piso = payload.piso;
      state.flgAscensor = payload.flgAscensor;
      state.metraje = payload.metraje;
      state.isLoading = false;
    },
    setDataMovingDestiny: (
      state,
      { payload }: PayloadAction<SaveInmuebleProps>
    ) => {
      state.referenciaDestino = payload.reference;
      state.idInmuebleDestino = payload.idInmueble;
      state.pisoDestino = payload.piso;
      state.flgAscensorDestino = payload.flgAscensor;
      state.isLoading = false;
    },
    setReferenceOrigin: (state, { payload }: PayloadAction<string>) => {
      state.referenciaOrigen = payload;
    },
    setReferenceDestiny: (state, { payload }: PayloadAction<string>) => {
      state.referenciaDestino = payload;
    },
    setListOfferNoticeActive: (
      state,
      { payload }: PayloadAction<NoticesBussinesData>
    ) => {
      state.noticesBussinesDataActives = payload;
      state.abiertas = payload?.abiertas as number;
      state.cerradas = payload?.cerradas as number;
      state.flgHistorial = payload?.flgHistorial as number;
      state.isLoading = false;
    },
    cleanListOfferNoticeActive: (state) => {
      state.noticesBussinesDataActives =
        initialState.noticesBussinesDataActives;
      state.abiertas = initialState.abiertas;
      state.cerradas = initialState.cerradas;
      state.flgHistorial = initialState.flgHistorial;
      state.isLoading = initialState.isLoading;
    },
    setListUpdatedOfferNoticeActive: (
      state,
      { payload }: PayloadAction<NoticesBussinesData>
    ) => {
      const defaultAvisonegociaciones =
        state.noticesBussinesDataActives?.avisonegociaciones || [];
      const avisonegociaciones = [
        ...defaultAvisonegociaciones,
        ...payload.avisonegociaciones,
      ];

      state.noticesBussinesDataActives = {
        ...payload,
        avisonegociaciones,
      };

      state.isLoading = false;
    },
    setListOfferNoticeHistory: (
      state,
      { payload }: PayloadAction<NoticesBussinesData>
    ) => {
      state.noticesBussinesDataHistory = payload;
      state.abiertas = payload?.abiertas as number;
      state.cerradas = payload?.cerradas as number;
      state.isLoading = false;
      state.flgHistorial = payload?.flgHistorial as number;
    },
    cleanListOfferNoticeHistory: (state) => {
      state.noticesBussinesDataHistory =
        initialState.noticesBussinesDataHistory;
      state.abiertas = initialState.abiertas;
      state.cerradas = initialState.cerradas;
      state.isLoading = initialState.isLoading;
      state.flgHistorial = initialState.flgHistorial;
    },
    setListUpdatededOfferNoticeHistory: (
      state,
      { payload }: PayloadAction<NoticesBussinesData>
    ) => {
      const defaultAvisonegociaciones =
        state.noticesBussinesDataHistory?.avisonegociaciones || [];
      const avisonegociaciones = [
        ...defaultAvisonegociaciones,
        ...payload.avisonegociaciones,
      ];

      state.noticesBussinesDataHistory = {
        ...payload,
        avisonegociaciones,
      };
      state.isLoading = false;
    },
    setTypeUser: (state, { payload }: PayloadAction<string>) => {
      state.typeUser = payload;
    },
    setIdVerifiedImages: (state, { payload }: PayloadAction<number>) => {
      state.idVerifiedImages = payload;
      state.imageUploadError = '';
    },
    setPhotosPreLoaded: (
      state,
      { payload }: PayloadAction<PhotosPreLoaded[]>
    ) => {
      state.photosPreLoaded = payload;
      state.isLoadingImage = false;
    },
    setImageUploadError: (state, { payload }: PayloadAction<string>) => {
      state.imageUploadError = payload;
      state.isLoadingImage = false;
    },
    resetOffert: (state) => {
      return {
        ...initialState,
        idTipoAvisoNegociacion: state.idTipoAvisoNegociacion,
      };
    },
  },
});

export const {
  setIsLoading,
  setOffertData,
  setError,
  setSelectOrigin,
  setSelectDestiny,
  setDataLocation,
  saveOffert,
  setTipoAvisoNegociacion,
  setPrice,
  resetOffert,
  setAyudantes,
  setFecha,
  setAvisoOrigenes,
  setAvisoDestinos,
  setPuntos,
  setHoras,
  setConditions,
  setTipoInmuebles,
  // inmuebles:
  setInmuebleOrigen,
  setInmuebleDestino,
  setDataMovingOrigin,
  setDataMovingDestiny,
  setReferenceOrigin,
  setReferenceDestiny,
  setListOfferNoticeActive,
  setListOfferNoticeHistory,
  setListUpdatedOfferNoticeActive,
  setListUpdatededOfferNoticeHistory,
  setTypeUser,
  setIsLoadingImage,
  setIdVerifiedImages,
  setImageUploadError,
  setPhotosPreLoaded,
  cleanListOfferNoticeActive,
  cleanListOfferNoticeHistory,
  resetError,
} = offertSlice.actions;
