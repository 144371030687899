import DetailsTextV2 from '@/components/DetailsTextV2/Index';
import UserRating from '@/components/userRating/UserRating';
import { ConfigDataNegotation } from '@/pages/noticeRequestFlow/interface/noticeNegRequestInterface';
import {
  getFormatSendDateRequestNeg,
  getImageVehicleRequestNeg,
  getMaxWeightVehicleRequestNeg,
  getProfileDataRequestNeg,
  getValueInNegRequestNeg,
  getValueInOffertRequestNeg,
  getVehicleValueNotificationRequestNeg,
  getVehicleValuesRequestNeg,
  renderEnvirontmentRequestNeg,
  renderTitleLongDestinyRequestNeg,
  renderTitleLongOriginRequestNeg,
} from '@/pages/requestNegotiation/shared/utils/RequestNegotiationsUtils';
import { Typography } from '@mui/material';
import { VehicleImg } from '../../offerResponse/components/VehicleImg';
import ConditionsListOffert from '@/components/conditionsList/ConditionsListOffert';

// ==================================================
//          CONFIGURACION MUDANZA
// ==================================================

export const configDataCounterOfferMoving: ConfigDataNegotation[] = [
  {
    Component: VehicleImg,
    props: {
      style: { marginTop: '13px' },
    },
    valid: [
      {
        property: 'fotos',
        componentProp: 'imageVehicle',
        defaultResult: [],
        callback: getImageVehicleRequestNeg,
      },
    ],
  },
  {
    Component: UserRating,
    props: {},
    valid: [
      {
        property: 'solicitud',
        componentProp: 'data',
        defaultResult: '',
        callback: getProfileDataRequestNeg,
        callbakcParams: ['solicitud'],
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'body2',
      sx: { fontSize: '16px', mb: 1, mt: 1, fontWeight: 600 },
      title: 'Detalles del servicio',
    },
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha',
      originDescription: '',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getValueInOffertRequestNeg,
        callbakcParams: ['solicitud', 'fecha'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getFormatSendDateRequestNeg,
        callbakcParams: ['solicitud', 'fecha'],
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
      containerSx: {
        alignItems: 'flex-start',
      },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: renderTitleLongOriginRequestNeg,
        callbakcParams: ['solicitud'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: renderTitleLongOriginRequestNeg,
        callbakcParams: ['solicitud'],
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-dark',
      title: 'Destino',
      containerSx: {
        alignItems: 'flex-start',
      },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: renderTitleLongDestinyRequestNeg,
        callbakcParams: ['solicitud'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: renderTitleLongDestinyRequestNeg,
        callbakcParams: ['solicitud'],
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-holidayVillage',
      title: 'Ambientes',
      containerSx: {
        alignItems: 'flex-start',
      },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: renderEnvirontmentRequestNeg,
        callbakcParams: ['solicitud'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: renderEnvirontmentRequestNeg,
        callbakcParams: ['solicitud'],
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getVehicleValuesRequestNeg,
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getVehicleValueNotificationRequestNeg,
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getMaxWeightVehicleRequestNeg,
        callbakcParams: ['solicitud', 'referenciaDestino'],
      },
    ],
  },
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '22px' },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInNegRequestNeg,
        callbakcParams: ['condiciones'],
      },
      {
        property: 'condiciones',
        componentProp: 'listServices',
        defaultResult: [],
        callback: getValueInNegRequestNeg,
        callbakcParams: ['condiciones'],
      },
    ],
  },
];
