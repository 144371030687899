import DetailsTextV2 from '@/components/DetailsTextV2/Index';
import DetailsText from '@/pages/flowNoticeNegotation/components/DetailsText';
import { ImagesThumbnailV2 } from '@/pages/flowNoticeNegotation/components/ImagesThumbnailV2';
import { ConfigDataNotice } from '@/pages/flowNoticeNegotation/interfaces/interfaces';
import { showRequestDetail } from '@/pages/flowNoticeNegotation/shared/utils/utilsFlowNoticeNegotation';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { Typography } from '@mui/material';
import {
  getFormatMetro,
  getFormatPrice,
  showOrHiden,
} from '../../../../../shared/utils/utilsOffer';

export const configDataInCity: ConfigDataNotice[] = [
  {
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
    },
    valid: [
      {
        property: 'originText',
        componentProp: 'children',
        defaultResult: '',
        callback: showRequestDetail,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: CalendarMonthOutlinedIcon,
      title: 'Fecha',
      iconProps: { sx: { color: '#757575' } },
      originDescription: '',
    },
    valid: [
      {
        property: 'date',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'date',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-box',
      title: 'Cantidad de horas',
    },
    valid: [
      {
        property: 'hours',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'hours',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-box',
      title: 'Cantidad de puntos',
    },
    valid: [
      {
        property: 'points',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'points',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
      containerSx: {
        alignItems: 'flex-start',
      },
    },
    valid: [
      {
        property: 'originText',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'originText',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'originReference',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-dark',
      title: 'Destino',
      containerSx: {
        alignItems: 'flex-start',
      },
    },
    valid: [
      {
        property: 'destinyText',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'destinyText',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'destinyReference',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-rules',
      title: 'Metraje',
    },
    valid: [
      {
        property: 'squareMeters',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'squareMeters',
        componentProp: 'origen',
        defaultResult: '',
        callback: getFormatMetro,
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-cargo',
      title: 'Ayudantes',
    },
    valid: [
      {
        property: 'assistants',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'assistants',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: 'Descripción de la carga',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '10px',
        display: 'block',
        wordWrap: 'break-word',
        maxWidth: '320px',
      },
    },
    valid: [
      {
        property: 'cargoDescription',
        componentProp: 'children',
        defaultResult: '',
        callback: showOrHiden,
      },
    ],
  },
  {
    Component: ImagesThumbnailV2,
    props: {
      style: { marginTop: '13px' },
    },
    valid: [
      {
        property: 'photos',
        componentProp: 'images',
        defaultResult: [],
      },
    ],
  },
  {
    Component: Typography,
    props: {
      sx: {
        variant: 'body1',
        maxWidth: '500px',
        wordWrap: 'break-word',
      },
    },
    valid: [
      {
        property: 'cargoDescription',
        componentProp: 'children',
        defaultResult: '',
      },
    ],
  },

  {
    Component: Typography,
    props: {
      variant: 'h5',
      sx: { textTransform: 'inherit', marginTop: 2 },
    },
    valid: [
      {
        property: 'price',
        componentProp: 'children',
        defaultResult: 0,
        callback: getFormatPrice,
      },
    ],
  },
];
