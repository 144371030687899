import { actionsToNegotation } from '@/consts/actions';
import {
  NEGOTATION_AGREEMENT_MESSAGE_CLIENT,
  NEGOTATION_AGREEMENT_MESSAGE_DRIVER,
  NEGOTATION_COUNTEROFFER_CLIENT,
  NEGOTATION_DECLINE_MESSAGE_CLIENT,
  NEGOTATION_DECLINE_MESSAGE_DRIVER,
  NEGOTATION_DECLINE_MESSAGE_DRIVER_EXPRESS,
} from '@/consts/negotationFlowPath';
import CounterOfferGlobalButtons from './CounterOfferGlobalButtons';
import OfferGlobalButtons from './OfferGlobalButtons';
import { textRejectConfirmation } from '@/consts/noticeConsts';

export const objectRenderComponent: Record<number, () => JSX.Element> = {
  1: OfferGlobalButtons,
  2: CounterOfferGlobalButtons,
  3: OfferGlobalButtons,
  4: OfferGlobalButtons,
};

export type ActionButton = {
  1: number;
  2?: number;
  3: number;
};

type ActionCoCounterOfferButton = {
  1: number;
  2: number;
};

export type UrlType = {
  1: string;
  2?: string;
  3: string;
};

export type TypeDeclinedNegotation = {
  title: string;
  message: string;
};

export const manageActionButton: Record<number, ActionButton> = {
  // Si el transportista acepta el aviso del cliente:
  1: {
    1: actionsToNegotation[5].id, // El cliente acepta
    3: actionsToNegotation[6].id, // El cliente rechaza
  },

  // Si el transportista contraoferta el aviso del cliente:
  2: {
    1: actionsToNegotation[12].id, // El cliente acepta
    2: actionsToNegotation[7].id, // El cliente contraoferta
    3: actionsToNegotation[13].id, // El cliente rechaza
  },
  // Express: Si ya hay un acuerdo entre cliente y transportista (OJO: EXPRESS):
  3: {
    1: actionsToNegotation[20].id, // El cliente rechaza acuerdo
    3: actionsToNegotation[19].id, // El transportista rechaza acuerdo
  },
  // Si el transportista cotiza el aviso del cliente:
  4: {
    1: actionsToNegotation[17].id, // El cliente acepta cotización
    3: actionsToNegotation[18].id, // El cliente rechaza cotización
  },
};

export const manageActionButtonCoCounterOffer: Record<
  number,
  ActionCoCounterOfferButton
> = {
  3: {
    1: actionsToNegotation[8].id, // transportista acepta contraoferta
    2: actionsToNegotation[9].id, // transportista rechaza contraoferta
  },
};

export const urlNavigateConfig: Record<number, any> = {
  //Rutas de la oferta por parte del cliente:
  1: {
    1: NEGOTATION_AGREEMENT_MESSAGE_CLIENT,
    3: NEGOTATION_DECLINE_MESSAGE_CLIENT,
    4: NEGOTATION_DECLINE_MESSAGE_DRIVER_EXPRESS,
  },

  //Rutas de la contraoferta por parte del cliente:
  2: {
    1: NEGOTATION_AGREEMENT_MESSAGE_CLIENT,
    2: NEGOTATION_COUNTEROFFER_CLIENT,
    3: NEGOTATION_DECLINE_MESSAGE_CLIENT,
  },
  //Rutas de la co-contraoferta por parte del transportista:
  3: {
    1: NEGOTATION_AGREEMENT_MESSAGE_DRIVER,
    3: NEGOTATION_DECLINE_MESSAGE_DRIVER,
  },
  //Rutas de la mudanza por parte del cliente:
  4: {
    1: NEGOTATION_AGREEMENT_MESSAGE_CLIENT,
    3: NEGOTATION_DECLINE_MESSAGE_CLIENT,
  },
};

// Objeto para la vita de rechazo
export const typeDeclinedNegotation: Record<number, TypeDeclinedNegotation> = {
  1: {
    title: 'Oferta rechazada',
    message: textRejectConfirmation,
  },
  2: {
    title: 'Contraoferta rechazada',
    message: textRejectConfirmation,
  },
  // La opción 3 es para Express
  3: {
    title: 'Acuerdo cancelado',
    message: textRejectConfirmation,
  },
  4: {
    title: 'Cotización rechazada',
    message: textRejectConfirmation,
  },
  9: {
    title: 'Contraoferta rechazada',
    message: textRejectConfirmation,
  },
};

// Objeto para cambiar el :typeOffer de la ruta
export const typeNegotation: Record<number, string> = {
  1: 'oferta',
  2: 'contraoferta',
  3: 'cotizacion',
  4: 'co-contraoferta',
};
