import { ID_AUCTION_FLOW } from "@/consts/defaultConsts";
import { NoticeNegotiation } from "@/models/offert/offertModels";
import { currencyFormat } from "@/utils/helpers";
import { Grid, Typography, styled } from "@mui/material";

export const textColorDescount = "#FF6700"

const DescountText = styled(Typography)(
  ({ theme }) => `
    color: ${textColorDescount};
    margin-left: 3px;
  `
);

const ThroughLineText = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.text.secondary};
    text-decoration-line: line-through;
    margin: 0 3px;
  `
);

const ContainerText = styled(Grid)(
  () => `
    display: flex;
    align-items: center;
    justify-content: flex-start;
  `
);

type Props = {
  offert: NoticeNegotiation;
  show: boolean;
};

const SectionDescount = ({ offert, show }: Props) => {
  const { tipoaviso, porcentajedescuento, precioregular } = offert;
  if (tipoaviso.id !== ID_AUCTION_FLOW || !show) return <></>;

  return (
    <ContainerText xs={12} md={12}>
      <Typography variant="body1">Antes: </Typography>
      <ThroughLineText variant="body1">
        {currencyFormat(precioregular)}
      </ThroughLineText>
      <Typography variant="body1"> | Ahora: </Typography>
      <DescountText variant="label1">
        {porcentajedescuento}% dscto.
      </DescountText>
    </ContainerText>
  );
};

export default SectionDescount;
