import { useAppSelector } from "@/hooks/useReducer";
import DynamicComponent from "@/pages/offerts/offertConfirmation/components/DynamicComponent";
import {
  ConfigDataBodyOffert,
  ValidFormat,
} from "@/pages/offerts/offertConfirmation/components/offertBodyConfirmation/configDataMoving";
import { useMemo } from "react";
import { actionAcceptance } from "../consts/actionAcceptance";
import {
  configDataMoving,
  configDataOfferSection,
} from "./configCounterOffertSection";

const CounterOffertSection = () => {
  const {
    offert: { savedOffert, idTipoAvisoNegociacion },
  } = useAppSelector((state) => state);

  const idState =
    savedOffert?.ultimaAvisoNegociacionAcciones?.tipoAvisoNegociacionAccion?.id;

  const idTypeNotice = savedOffert?.avisoNegociacionAviso.tipoaviso.id;

  const handleGetConfig = () => {
    if (idTypeNotice === 5 && idState === actionAcceptance[5].id) {
      return configDataMoving;
    }

    return configDataOfferSection;
  };

  const selectdConfigData = useMemo(() => handleGetConfig(), []);

  const handleFormat = (currentValue: any, format?: ValidFormat) => {
    if (!format || !savedOffert) return currentValue;

    let { template, valuesToReplace } = format;

    const newValue = valuesToReplace.reduce((acc, el) => {
      const value = savedOffert[el.value]?.toString() || el.defaultResult;

      return acc.replace(el.search, value);
    }, template);

    return newValue;
  };

  const handleValid = ({ valid, props }: ConfigDataBodyOffert) => {
    if (!valid || !savedOffert) return { props };

    const moreProps = valid.reduce((acc, el) => {
      let value = handleFormat(savedOffert[el.property], el?.format);

      if (el?.callback) {
        value = el.callback(idTipoAvisoNegociacion, value, savedOffert);
      }

      if (typeof el.defaultResult === "boolean") {
        acc = {
          ...acc,
          [el.componentProp]: !!value,
        };
      } else {
        acc = {
          ...acc,
          [el.componentProp]: value || el.defaultResult,
        };
      }
      return acc;
    }, {});

    return { props: { ...props, ...moreProps } };
  };

  if (idState !== actionAcceptance[5].id) return <></>;

  return (
    <>
      {selectdConfigData.map((item, i) => (
        <DynamicComponent key={i} {...item} {...handleValid(item)} />
      ))}
    </>
  );
};

export default CounterOffertSection;
