import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
} from "@mui/material";

interface Props {
  color?: CircularProgressProps["color"];
  text?: string;
}

const CircularLoading = (props: Props) => {
  const { color = "org", text = "" } = props;
  return (
    <Box sx={{ textAlign: "center" }}>
      <CircularProgress color={color} />
      <Typography variant="body1">{text}</Typography>
    </Box>
  );
};

export default CircularLoading;
