import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { StyleCustomInput } from "@/components/form/customInput/styleCustomInput";
import Input from "@/components/inputCustom/Input";
import BannerModal from "@/components/modals/BannerModal/BannerModal";
import LabelRequired from "@/components/utils/LabelRequired";
import { CONFIRMATION_PATH } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import HeaderProfile from "@/pages/profile/headerProfile/HeaderProfile";
import { Form } from "@/pages/user/components/styleLoginRegister";
import VisibilityIcons from "@/pages/user/components/VisibilityIcons";
import { CustomControl } from "@/pages/user/password/styles";
import { setErrorRegister } from "@/store/auth/authUserSlice";
import { changePasswordThunk } from "@/store/auth/authUserThunks";
import { InputAdornment, Typography } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const MAX_LENGTH_PASSWORD = 4;

const Password = () => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [showError, setShowError] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState({
    title: "",
    message: "",
    ok: "",
    cancel: "",
  });

  const { isLoading, errorRegister } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setErrorRegister(""));
  }, [dispatch]);

  const handleValidPasswords = () => {
    return newPassword === confirmPassword;
  };

  const handleDisabled = () => {
    return (
      newPassword.length !== MAX_LENGTH_PASSWORD ||
      confirmPassword.length !== MAX_LENGTH_PASSWORD ||
      password.length !== MAX_LENGTH_PASSWORD
    );
  };

  const handleChange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = currentTarget;
    const cleanValue = value.replace(/\D/g, "");
    if (cleanValue.length > 4) return;
    dispatch(setErrorRegister(""));
    if (name === "password") {
      setPassword(cleanValue);
    } else if (name === "newPassword") {
      setNewPassword(cleanValue);
    } else {
      setConfirmPassword(cleanValue);
    }

    setShowError("");
  };

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!handleValidPasswords()) {
      setShowError("Las contraseñas no coinciden.");
      return;
    }

    await dispatch(changePasswordThunk(password, newPassword)).then(() => {
      navigate(CONFIRMATION_PATH, {
        replace: true,
        state: { type: "changePassword" },
      });
    });
  };

  const handleShowError = () => {
    if (typeof showError !== "string") return "Error inesperado";

    return showError;
  };

  const navigateBack = () => {
    if (password || newPassword || confirmPassword) {
      setShowAlert(true);
      setMessageAlert({
        title: "Cambios sin guardar",
        message: "Aún no has guardado los cambios realizados. ¿Deseas salir?",
        ok: "SI, SALIR",
        cancel: "NO, QUEDARME",
      });
    } else {
      navigate(-1);
    }
  };

  return (
    <HeaderProfile title="Cambiar contraseña" backAction={navigateBack}>
      <BannerModal
        open={showAlert}
        onClose={() => setShowAlert(false)}
        title={messageAlert.title}
        content={messageAlert.message}
        option1={{
          title: messageAlert.cancel,
          onClick: () => setShowAlert(false),
        }}
        option2={{
          title: messageAlert.ok,
          onClick: () => navigate(-1),
        }}
        color="org"
        sx={{
          width: "322px",
          height: "244px",
        }}
        sxAccion={{
          padding: "0px",
        }}
      />
      {errorRegister && (
        <StandardAlert
          sx={{ marginBottom: "16px" }}
          severity="error"
          color="error"
          onClick={() => {}}
        >
          {errorRegister}
        </StandardAlert>
      )}
      <Form onSubmit={handleOnSubmit}>
        <Typography variant="body1">
          Ingresa una contraseña de 4 dígitos que incluya sólo números.
        </Typography>
        <CustomControl fullWidth inputMode="numeric">
          <Input
            label={<LabelRequired str="Contraseña actual" />}
            name="password"
            variant="standard"
            placeholder="Ingresa tu contraseña"
            type={showPassword ? "" : "password"}
            autoComplete="off"
            fullWidth
            value={password}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <VisibilityIcons onChangeVisible={setShowPassword} />
                </InputAdornment>
              ),
            }}
          />
        </CustomControl>
        <CustomControl fullWidth inputMode="numeric">
          <Input
            label={<LabelRequired str="Nueva contraseña" />}
            name="newPassword"
            variant="standard"
            placeholder="Ingresa tu contraseña actual"
            type={showNewPassword ? "" : "password"}
            error={!!showError?.length}
            helperText={handleShowError()}
            autoComplete="off"
            fullWidth
            value={newPassword}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <VisibilityIcons onChangeVisible={setShowNewPassword} />
                </InputAdornment>
              ),
            }}
          />
        </CustomControl>
        <CustomControl fullWidth inputMode="numeric">
          <Input
            label={<LabelRequired str="Confirmar contraseña" />}
            name="confirmPassword"
            variant="standard"
            placeholder="Vuelve a escribir tu nueva contraseña"
            type={showConfirmPassword ? "" : "password"}
            error={!!showError?.length}
            helperText={handleShowError()}
            autoComplete="off"
            fullWidth
            value={confirmPassword}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <VisibilityIcons onChangeVisible={setShowConfirmPassword} />
                </InputAdornment>
              ),
            }}
          />
        </CustomControl>
        <ButtomLoader
          type="submit"
          disabled={handleDisabled()}
          isLoading={isLoading}
        >
          GUARDAR
        </ButtomLoader>
      </Form>
    </HeaderProfile>
  );
};

export default Password;
