import {
  DetailNoticeMoving,
  NoticeDetailAuctionModel,
  NoticeDetailBetweenCitiesModel,
  NoticeDetailInCityModel,
  NoticeDetailMultiDestinyModel,
} from '@/models/notice/noticeModels';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface InitialStateDetailsNotice {
  inCity: null | NoticeDetailInCityModel;
  betweenCities: null | NoticeDetailBetweenCitiesModel;
  multiDestiny: null | NoticeDetailMultiDestinyModel;
  moving: null | DetailNoticeMoving;
  auction: null | NoticeDetailAuctionModel;
  isLoading: boolean;
  error: any;
  userMessage: string;
}

const initialState: InitialStateDetailsNotice = {
  inCity: null,
  betweenCities: null,
  multiDestiny: null,
  moving: null,
  auction: null,
  isLoading: false,
  error: null,
  userMessage: '',
};

export const detailsNoticeSlice = createSlice({
  name: 'detailsNoticeSlice',
  initialState,
  reducers: {
    setLoadingDetailsNotice: (state, { payload }) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload?.message || payload;
      state.isLoading = false;
    },
    setDataInCity: (
      state,
      { payload }: PayloadAction<NoticeDetailInCityModel>
    ) => {
      state.inCity = payload;
      state.isLoading = false;
      state.error = null;
    },
    setDataBetweenCities: (
      state,
      { payload }: PayloadAction<NoticeDetailBetweenCitiesModel>
    ) => {
      state.betweenCities = payload;
      state.isLoading = false;
      state.error = null;
    },
    setDataMultiDestiny: (
      state,
      { payload }: PayloadAction<NoticeDetailMultiDestinyModel>
    ) => {
      state.multiDestiny = payload;
      state.isLoading = false;
      state.error = null;
    },
    setDataMoving: (state, { payload }: PayloadAction<DetailNoticeMoving>) => {
      state.moving = payload;
      state.isLoading = false;
      state.error = null;
    },
    setDataAuction: (
      state,
      { payload }: PayloadAction<NoticeDetailAuctionModel>
    ) => {
      state.auction = payload;
      state.isLoading = false;
      state.error = null;
    },
    setUserMessage: (state, { payload }: PayloadAction<string>) => {
      state.userMessage = payload;
    },
    resetDetailsNoticeSlice: () => initialState,
  },
});

export const {
  setError,
  setLoadingDetailsNotice,
  setDataInCity,
  setDataBetweenCities,
  setDataMultiDestiny,
  resetDetailsNoticeSlice,
  setDataMoving,
  setDataAuction,
  setUserMessage,
} = detailsNoticeSlice.actions;
