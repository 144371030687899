import { ListItemsProps } from "@/components/noticeListItem/typeNoticeListITem";
import useResize from "@/hooks/useResize";
import { ListItems } from "@/components/noticeListItem/typeNoticeListITem";
import {
  ContentSectionCardList,
  MatListContent,
  MatListItem,
  MatListItemContent,
  MatListItemImg,
  MatListItemSubTitle,
  MatListItemTitle,
} from "./styleSelectCards";
import { ResponsiveStyleValue, SxProps } from "@mui/system";
import { Theme } from "@mui/material";

interface Props extends ListItemsProps {
  onClick?: (item: ListItems) => void;
  selected?: string;
  transform?: ResponsiveStyleValue<string>;
  sx?: SxProps<Theme>;
}
const SelectCards = ({
  listItems,
  onClick,
  selected,
  sx,
  transform = "translateY(-154px)",
}: Props) => {
  const [width] = useResize();

  return (
    <ContentSectionCardList sx={sx}>
      <MatListContent sx={{ transform: transform }}>
        {listItems.map((item, index) => (
          <MatListItem
            key={index}
            onClick={() => onClick && onClick(item)}
            className={selected === item.title ? "active" : ""}
          >
            <MatListItemImg>
              <i className={width < 899 ? item.icon : item.iconDesk} />
            </MatListItemImg>
            <MatListItemContent>
              {item.title && <MatListItemTitle>{item.title}</MatListItemTitle>}
              {item.subtitle2 && (
                <MatListItemSubTitle variant="body2">
                  {item.subtitle2}
                </MatListItemSubTitle>
              )}
            </MatListItemContent>
          </MatListItem>
        ))}
      </MatListContent>
    </ContentSectionCardList>
  );
};

export default SelectCards;
