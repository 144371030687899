declare global {
    interface Window {
      dataLayer: Record<string, any>[];
    }
  }

  // Acepta solicitud
export const acceptRequest = (idUsuario: number, noticeType: string) => {
    window.dataLayer.push({
      event: "request_acceptance",
      idusuario: idUsuario,
      tipoaviso: noticeType,
    });
  };

  // Contraoferta solicitud
export const counterOfferRequest = (idUsuario: number, noticeType: string) => {
    window.dataLayer.push({
      event: "request_counteroffer",
      idusuario: idUsuario,
      tipoaviso: noticeType,
    });
  };

  // Acepta oferta transportista
export const OfferRequestDriver = (idUsuario: number, noticeType: string) => {
    window.dataLayer.push({
      event: "request_acceptance_agreement",
      idusuario: idUsuario,
      tipoaviso: noticeType,
    });
  };


// Acepta contraoferta transportista
export const acceptRequestDriver = (idUsuario: number, noticeType: string) => {
    window.dataLayer.push({
      event: "request_counteroffer_agreement",
      idusuario: idUsuario,
      tipoaviso: noticeType,
    });
  };

// Co-Contraoferta solicitud
export const cocounterRequest = (idUsuario: number, noticeType: string) => {
    window.dataLayer.push({
      event: "request_co-counteroffer",
      idusuario: idUsuario,
      tipoaviso: noticeType,
    });
  };

// Acepta co-contraoferta cliente
export const cocounterRequestClient = (idUsuario: number, noticeType: string) => {
    window.dataLayer.push({
      event: "request_co-counteroffer_agreement",
      idusuario: idUsuario,
      tipoaviso: noticeType,
    });
  };
