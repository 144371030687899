import HeaderSectionV2 from "@/components/headerSectionV2/HeaderSectionV2";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import { optionTwo } from "@/consts/noticeConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { NoticeDetailMultiDestinyModel } from "@/models/notice/noticeModels";
import { getPrevRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { Container } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";

const PriceDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectTypeNotice, idTypeNoticeNegotiation } = useAppSelector(
    (state) => state.noticeV2ClientAorCO
  );

  const notice = selectTypeNotice as NoticeDetailMultiDestinyModel;

  const handlePreviusRute = () => {
    dispatch(
      getPrevRoute(navigate, {
        valueToReplace: notice.id?.toString(),
        valueToSearch: ":id",
      })
    );
  };
  const isContraoferta = idTypeNoticeNegotiation === optionTwo;
  return (
    <Container maxWidth="xs">
      <HeaderSectionV2
        icon="icon-arrow-left"
        title="Precio del servicio"
        subTitle={isContraoferta ? "Contraoferta" : "Aceptar"}
        onClick={handlePreviusRute}
        sx={{
          boxShadow: "none",
        }}
      />
      <SectionIcon icon="icon-money" />
      <Outlet />
    </Container>
  );
};

export default PriceDetails;
