import ConditionsListOffert from '@/components/conditionsList/ConditionsListOffert';
import TransporterRating from '@/components/userRating/TransporterRating';
import { Typography } from '@mui/material';
import DetailTextTitle from '../components/DetailTextTitle';
import { ConfigDataBody } from '../config';
import {
  getConditionsInViewDetails,
  renderAmbientes,
  renderIconByTypeRequest,
  renderRating,
  renderTitleAmbiente,
  renderTitleByTypeRequest,
  renderTitleLongDestiny,
  renderTitleLongOrigin,
  renderTitleOrigenAndDestiny,
} from '../utils/utilsApplication';
import DetailsTextMoving from './DetailsTextMoving';
import DetailsText from '../../components/DetailsText';
export const configDataCustomRequestMoving: ConfigDataBody[] = [
  {
    Component: DetailTextTitle,
    props: {
      title: 'En la ciudad',
      icon: 'icon-box',
      show: true,
    },
    valid: [
      {
        property: 'tipoSolicitud',
        componentProp: 'title',
        defaultResult: '',
        callback: renderTitleByTypeRequest,
      },
      {
        property: 'tipoSolicitud',
        componentProp: 'icon',
        defaultResult: '',
        callback: renderIconByTypeRequest,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'h6',
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'title',
        defaultResult: '',
        callback: renderTitleOrigenAndDestiny,
      },
    ],
  },
  {
    Component: TransporterRating,
    props: {
      containerSx: { mt: '16px', mb: '5px' },
      viewRating: true,
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'data',
        defaultResult: {},
        callback: renderRating,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: 'Detalles del servicio',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha',
      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextMoving,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
    },
    valid: [
      {
        property: 'origenTexto2',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'referencia',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: renderTitleLongOrigin,
      },
    ],
  },
  {
    Component: DetailsTextMoving,
    props: {
      icon: 'icon-location-dark',
      title: 'Destino',
    },
    valid: [
      {
        property: 'destinoTexto2',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: renderTitleLongDestiny,
      },
    ],
  },
  {
    Component: DetailsTextMoving,
    props: {
      icon: 'icon-holidayVillage',
      title: 'Ambientes',
    },
    valid: [
      {
        property: 'ambientes',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'ambientes',
        componentProp: 'origen',
        defaultResult: '',
        callback: renderTitleAmbiente,
      },
      {
        property: 'ambientes',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: renderAmbientes,
      },
    ],
  },

  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '16px' },
    },
    valid: [
      {
        property: 'condiciones',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'condiciones',
        componentProp: 'listServices',
        defaultResult: [],
        callback: getConditionsInViewDetails,
      },
    ],
  },
];
