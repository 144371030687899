import { BASE_URL_IMAGE } from "@/config/config";
import {
  IMG_PATH,
  MD_IMG,
  USER_DOCUMENT_IDETIFICATION_PATH_DEFAULT,
} from "@/consts/defaultConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { UsuarioAPI } from "@/models/userAuth/user";
import { setErrorMsg } from "@/store/userRedux/userSlice";
import { uploadDocumentIdentificationThunk } from "@/store/userRedux/userThunks";
import { resizePhoto } from "@/utils/helpers";
import { Typography, styled } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import DropZone from "../DrowZone/DropZone";
import ButtomLoader from "../form/buttonLoader/ButtomLoader";

const Description = styled(Typography)(
  ({ theme }) => `
      margin-top: 16px;
      color: ${theme.palette.text.secondary};
    `
);

const From = styled("form")({
  marginTop: "24px",
});

interface IProps {
  handleClick: () => void;
  description?: string;
  buttonLabel?: string;
  dataIsEmpty?: (isEmpty: boolean) => void;
}

const IdentificationDocument = ({
  handleClick,
  description,
  dataIsEmpty,
  buttonLabel = "",
}: IProps) => {
  const [image, setImage] = useState<string | File>("");

  const { isLoading, user, error } = useAppSelector(({ user }) => user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (error) {
      dispatch(setErrorMsg(""));
    }

    dataIsEmpty?.(!!image);

    return () => {
      dispatch(setErrorMsg(""));
    };
  }, [image]);

  const handleOnSelectImage = (img: string | File) => {
    setImage(img);
    if (!!error) {
      dispatch(setErrorMsg(""));
    }
  };

  const handleOnError = (error: Error) => {
    dispatch(setErrorMsg(error?.message));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLoading) return;

    dispatch(uploadDocumentIdentificationThunk(image))
      .then(() => {
        handleClick();
      })
      .catch((e: Error) => dispatch(setErrorMsg(e.message)));
  };

  const handleDisabled = () => {
    return !!error || !image;
  };

  const handleShowImageDefault = useCallback(() => {
    const currentUser = user as UsuarioAPI;
    if (currentUser?.documentoUrl) {
      return resizePhoto({
        photo: IMG_PATH + currentUser?.documentoUrl,
        newFormat: MD_IMG,
      });
    }

    return BASE_URL_IMAGE + USER_DOCUMENT_IDETIFICATION_PATH_DEFAULT;
  }, [user]);

  return (
    <>
      <Description variant="body1">{description}</Description>

      <DropZone
        imageDefault={handleShowImageDefault()}
        onError={handleOnError}
        onSelect={handleOnSelectImage}
      />

      <From onSubmit={handleSubmit}>
        <ButtomLoader
          disabled={handleDisabled()}
          sx={{ margin: 0 }}
          type="submit"
          isLoading={isLoading}
        >
          {buttonLabel}
        </ButtomLoader>
      </From>
    </>
  );
};

export default IdentificationDocument;
