import { Box, Grid, Typography, styled } from '@mui/material';

export const SectionAppContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',

  marginBottom: '32px',
}));

export const BoxContainer = styled(Box)(() => ({
  width: '100%',
  backgroundColor: '#FF6700',
  borderRadius: '24px',
  display: ' flex',
  justifyContent: 'space-around',
  padding: '24px',
  height: '390px',
}));

export const SectionOneBox = styled(Grid)(
  ({ matches }: { matches: boolean }) => ({
    width: matches ? '40%' : '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  })
);

export const SectionTwoBox = styled(Grid)(
  ({
    matches2,
    matchesMiddle,
  }: {
    matchesMiddle: boolean;
    matches2: boolean;
  }) => ({
    width: matchesMiddle ? '35%' : '29%',
    display: 'flex',
    flexDirection: matches2 ? 'column' : 'row',
    justifyContent: matchesMiddle ? 'flex-end' : '',
  })
);

export const HeaderBox = styled(Box)(({ matches }: { matches: boolean }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: matches ? 'center' : 'flex-start',
}));

export const ContentBox = styled(Box)(
  ({ matches6, matches7 }: { matches6: boolean; matches7: boolean }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: matches6 ? 'center' : 'flex-start',
    paddingX: matches7 ? '16px' : '0px',
    marginBottom: '16px',
  })
);

export const ContainerQr = styled(Box)(({ matches }: { matches: boolean }) => ({
  display: 'flex',
  flexDirection: matches ? 'column' : 'row',
  justifyContent: matches ? 'center' : 'flex-start',
  alignItems: 'center',
}));

export const ImgQrBox = styled(Box)(() => ({
  width: 150,
  height: 150,
}));

export const TypographyTextExpress = styled(Typography)(() => ({
  color: '#054A91',
  fontSize: '48px',
  fontWeight: '700',
  marginLeft: '5px',
  fontFamily: 'Blackrush',
  marginTop: '4px',
}));

export const TypographyTitleText = styled(Typography)(() => ({
  color: 'white',
  fontSize: '22px',
  fontWeight: '700',
}));

export const BoxInfo = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: '16px',
}));

export const TypographyInfo = styled(Typography)(() => ({
  marginBottom: '5px',
  fontSize: '16px',
}));
