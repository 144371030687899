import {
  CONFIRMATION_PATH,
  MY_PENDING_AGREEMENTS,
  PROFILE_CREAR_VEHICULO_PATH,
  PROFILE_DATOS_CAMBIAR_CONTRASENA,
  PROFILE_DATOS_CAMBIAR_TELEFONO,
  PROFILE_DATOS_DOC_IDENTIDAD,
  PROFILE_DATOS_PATH,
  PROFILE_DATOS_PERSONALS_PATH,
  PROFILE_DATOS_VALIDACION,
  PROFILE_EDITAR_VEHICULO_PATH,
  PROFILE_MIS_VEHICULOS_PATH,
  PROFILE_NOTICE_OFFERT_LIST,
  PROFILE_PATH,
  PROFILE_VEHICULO_PATH,
} from '@/consts/typeServicesPaths';
import ConfirmacionCambio from '@/pages/confirmacionCambio/ConfirmacionCambio';
import { MY_OFFERTS_CUSTOMER_REQUEST_PATH } from '@/pages/customerRequest/shared/consts/routes';
import Profile from '@/pages/profile/Profile';
import AgreementToAccept from '@/pages/profile/pages/agreementToAccept/AgreementToAccept';
import Datos from '@/pages/profile/pages/datos/Datos';
import Identidad from '@/pages/profile/pages/datos/identidad/Identidad';
import Password from '@/pages/profile/pages/datos/password/Password';
import Personal from '@/pages/profile/pages/datos/personal/Personal';
import Telefono from '@/pages/profile/pages/datos/telefono/Telefono';
import Validation from '@/pages/profile/pages/datos/telefono/Validation';
import MyAvisosList from '@/pages/profile/pages/myAvisosList/MyAvisosList';
import MySolicitudList from '@/pages/profile/pages/mySolicitudList/MySolicitudList';
import Vehicles from '@/pages/profile/pages/vehicles/Vehicles';
import DocumentCandE from '@/pages/profile/pages/vehicles/document/DocumentCandE';
import EditVehicle from '@/pages/profile/pages/vehicles/edit/EditVehicle';
import PhotosCandE from '@/pages/profile/pages/vehicles/photos/PhotosCandE';
import VehicleCandE from '@/pages/profile/pages/vehicles/vehicle/VehicleCandE';
import { privateCreateRequestListMiddleware } from '@/router/middleware/privateCreateRequestMiddleware';
import { privateRuteOnlyDriver } from '@/router/middleware/privateProfileMiddleware';
import { validateCompleteRegisterMiddleware } from '@/router/middleware/privateRegister';
import { RouterProps } from '@/router/router';

const crearVehiculo: RouterProps[] = [
  {
    path: PROFILE_CREAR_VEHICULO_PATH[0],
    Component: VehicleCandE,
    requiredSession: true,
    middleware: privateRuteOnlyDriver,
  },
  {
    path: PROFILE_CREAR_VEHICULO_PATH[1],
    Component: PhotosCandE,
    requiredSession: true,
    middleware: privateRuteOnlyDriver,
  },
  {
    path: PROFILE_CREAR_VEHICULO_PATH[2],
    Component: DocumentCandE,
    requiredSession: true,
    middleware: privateRuteOnlyDriver,
  },
];

const editarVehiculo: RouterProps[] = [
  {
    path: PROFILE_EDITAR_VEHICULO_PATH[0],
    Component: VehicleCandE,
    requiredSession: true,
    middleware: privateRuteOnlyDriver,
  },
  {
    path: PROFILE_EDITAR_VEHICULO_PATH[1],
    Component: PhotosCandE,
    requiredSession: true,
  },
  {
    path: PROFILE_EDITAR_VEHICULO_PATH[2],
    Component: DocumentCandE,
    requiredSession: true,
    middleware: privateRuteOnlyDriver,
  },
];

export const profileRouter: RouterProps[] = [
  {
    path: CONFIRMATION_PATH,
    Component: ConfirmacionCambio,
  },
  {
    path: PROFILE_PATH,
    Component: Profile,
    middleware: validateCompleteRegisterMiddleware,
    children: [
      {
        path: PROFILE_DATOS_PATH,
        Component: Datos,
        index: true,
      },
      {
        path: PROFILE_DATOS_PERSONALS_PATH,
        Component: Personal,
      },
      {
        path: PROFILE_DATOS_DOC_IDENTIDAD,
        Component: Identidad,
      },
      {
        path: PROFILE_DATOS_CAMBIAR_TELEFONO,
        Component: Telefono,
      },
      {
        path: PROFILE_DATOS_VALIDACION,
        Component: Validation,
      },
      {
        path: PROFILE_DATOS_CAMBIAR_CONTRASENA,
        Component: Password,
      },
      {
        path: PROFILE_MIS_VEHICULOS_PATH,
        Component: Vehicles,
        middleware: privateRuteOnlyDriver,
      },
      ...crearVehiculo,
      // EDITAR VEHICULO
      {
        path: PROFILE_VEHICULO_PATH,
        Component: EditVehicle,
        middleware: privateRuteOnlyDriver,
      },
      ...editarVehiculo,
    ],
  },
  {
    path: MY_PENDING_AGREEMENTS,
    Component: AgreementToAccept,
    requiredSession: true,
  },
  // list offerts avisos and solicitudes
  {
    path: MY_OFFERTS_CUSTOMER_REQUEST_PATH,
    Component: MySolicitudList,
    requiredSession: true,
  },
  {
    path: PROFILE_NOTICE_OFFERT_LIST,
    Component: MyAvisosList,
    middleware: privateCreateRequestListMiddleware,
    requiredSession: true,
  },
];
