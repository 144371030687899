import { DistrictAPI, DistritoDetAPI } from '@/models/location/districtModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IMultiPrices {
  iddistrito: number;
  id: number;
  precio: number | string;
  distrito: DistrictAPI;
}

export interface SelectCity {
  id: number;
  nombre: string;
  nombre2?: string;
  idpais?: number;
  detalle?: string;
  pais?: string;
  distritodet?: DistritoDetAPI[];
  ciudad?: SelectCity;
}

export interface multiSelectCity {
  id: number;
  name: string;
  idzone: number;
}
export interface SelectLocationState {
  selectedCity: SelectCity | {};
  selectedCity2: SelectCity | {};
  selectedOrigin: SelectCity | {};
  selectedDestiny: SelectCity | {};
  modalActiveWith: 'city' | 'city2' | 'origin' | 'destiny';
  multiPrices: IMultiPrices[] | any[];
  // multi zone
  multiSelectOrigin: multiSelectCity[] | any[];
  multiSelectDestiny: multiSelectCity[] | any[];
}

const initialState: SelectLocationState = {
  selectedCity: {},
  selectedCity2: {},
  selectedOrigin: {},
  selectedDestiny: {},
  modalActiveWith: 'origin',
  multiPrices: [],
  multiSelectOrigin: [],
  multiSelectDestiny: [],
};

export const selectLocationSlice = createSlice({
  name: 'selectLocation',
  initialState,
  reducers: {
    setCity: (state, { payload }: PayloadAction<SelectCity>) => {
      state.selectedCity = payload;
    },
    setCity2: (state, { payload }: PayloadAction<SelectCity>) => {
      state.selectedCity2 = payload;
    },
    setOrigin: (state, { payload }: PayloadAction<SelectCity | {}>) => {
      state.selectedOrigin = payload;
    },
    setDestiny: (state, { payload }: PayloadAction<SelectCity | {}>) => {
      state.selectedDestiny = payload;
    },
    setMultiprices: (
      state,
      { payload }: PayloadAction<IMultiPrices[] | any[]>
    ) => {
      state.multiPrices = payload;
    },
    setModalActiveWith: (
      state,
      { payload }: PayloadAction<SelectLocationState['modalActiveWith']>
    ) => {
      state.modalActiveWith = payload;
    },
    // multi zone
    setMultiSelectOrigin: (
      state,
      { payload }: PayloadAction<multiSelectCity[] | any[]>
    ) => {
      state.multiSelectOrigin = payload;
    },
    setMultiSelectDestinyV2: (
      state,
      { payload }: PayloadAction<multiSelectCity[] | any[]>
    ) => {
      state.multiSelectDestiny = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCity,
  setCity2,
  setOrigin,
  setDestiny,
  setModalActiveWith,
  setMultiprices,
  setMultiSelectOrigin,
  setMultiSelectDestinyV2,
} = selectLocationSlice.actions;
