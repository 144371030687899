import {
  setSelectCity,
  setSelectDestiny,
  setSelectOrigin,
} from '@/store/customerRequestRedux/customerRequestSlice';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

export type ModalType = { type: 'origin' | 'destiny' | 'city' };

export const MODAL_TYPE: {
  origin: Extract<ModalType['type'], 'origin'>;
  destiny: Extract<ModalType['type'], 'destiny'>;
  city: Extract<ModalType['type'], 'city'>;
} = {
  origin: 'origin',
  destiny: 'destiny',
  city: 'city',
};

interface ConfigFormRute {
  origin: Location;
  destiny: Location;
  city: Location;
}

interface Location {
  input1: InputProps;
  input2: InputProps;
  modal: InputModal;
}

interface InputProps {
  name: string;
  label: string;
  placeholder: string;
}

interface InputModal extends InputProps {
  title: string;
  type: ModalType['type'];
}

export const configFormRute: ConfigFormRute = {
  city: {
    input1: {
      name: MODAL_TYPE.city,
      label: 'Ciudad',
      placeholder: 'Ciudad de recojo',
    },
    input2: {
      name: 'reference-city',
      label: 'Tu ciudad',
      placeholder: 'Ingresa una ciudad de origen',
    },
    modal: {
      name: 'search-city',
      label: 'Ciudad de origen ',
      placeholder: 'Ciudad de recojo',
      title: 'Ingresa tu ciudad',
      type: 'city',
    },
  },
  origin: {
    input1: {
      name: MODAL_TYPE.origin,
      label: 'Distrito de origen',
      placeholder: 'Distrito de recojo',
    },
    input2: {
      name: 'reference-origin',
      label: 'Referencia del origen',
      placeholder: 'Lugar conocido cerca a la dirección',
    },
    modal: {
      name: 'search-origin',
      label: 'Distrito de origen',
      placeholder: 'Distrito de recojo',
      title: 'Ingresa tu origen',
      type: 'origin',
    },
  },
  destiny: {
    input1: {
      name: MODAL_TYPE.destiny,
      label: 'Distrito de destino',
      placeholder: 'Distrito de entrega',
    },
    input2: {
      name: 'reference-destiny',
      label: 'Referencia del destino',
      placeholder: 'Lugar conocido cerca a la dirección',
    },
    modal: {
      name: 'search-destiny',
      label: 'Distrito de destino',
      placeholder: 'Distrito de entrega',
      title: 'Ingresa tu destino',
      type: 'destiny',
    },
  },
};

export const functionSaveObjectByTypeModal: Record<
  ModalType['type'],
  ActionCreatorWithPayload<any, string>
> = {
  origin: setSelectOrigin,
  destiny: setSelectDestiny,
  city: setSelectCity,
};
