import { IMG_PATH, MD_IMG, SM_IMG } from "@/consts/defaultConsts";
import { currencyFormat, resizePhoto } from "@/utils/helpers";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Rating,
  Typography,
  styled,
} from "@mui/material";

import { NoticeData } from "@/models/filterNotice/filterNoticeModels";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link, To } from "react-router-dom";
import StateNotice from "./StateNotice";

type Props = {
  data: NoticeData;
  navigation: {
    to: To;
    state?: any;
  };
};

const LinkWrapper = styled(Link)(
  () => `
    text-decoration: none;
    color: inherit;
  `
);

const chipSx = {
  width: "64px",
  height: "64px",
  position: "absolute",
  bottom: 12,
  left: 10,
  backgroundColor: "white",
  border: "3px solid white",
};

const NewInfoCard = ({ data, navigation }: Props) => {
  const newData = data as NoticeData;
  const handleValidateImg = (imgURL: string, newFormat: string) => {
    const photoResized = resizePhoto({
      photo: IMG_PATH + imgURL,
      newFormat,
    });

    if (photoResized?.startsWith("http")) return photoResized;

    return IMG_PATH + photoResized;
  };

  const handleGetCalification = (newData: NoticeData) => {
    if (!!newData.puntaje) {
      return `${newData.puntaje} (${newData.calificaciones})`;
    }

    return "";
  };

  return (
    <Card sx={{ borderRadius: "8px", cursor: "pointer" }}>
      <LinkWrapper {...navigation}>
        <Box sx={{ position: "relative", height: "170px", width: "100%" }}>
          <StateNotice data={newData as any} />
          {!!newData.flgpatrocinio && (
            <Chip
              label="Patrocinado"
              variant="outlined"
              sx={{
                position: "absolute",
                top: 12,
                left: 10,
                backgroundColor: "white",
                border: "1px solid black",
              }}
            />
          )}
          <CardMedia
            component="img"
            height="170"
            image={handleValidateImg(newData.vehiculofoto, MD_IMG) || ""}
            alt="Paella dish"
          />

          <Avatar
            alt="Avatar"
            src={handleValidateImg(newData.perfilfoto, SM_IMG) || ""}
            sx={chipSx}
          />
        </Box>
        <CardContent sx={{ p: 2, pb: 0 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: "16px" }}>{newData.nombre}</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Rating
                name="customized-10"
                defaultValue={2}
                max={1}
                size="small"
                sx={!!newData.puntaje ? {} : { color: "#9e9e9e" }}
              />
              <Typography sx={{ fontSize: "14px" }}>
                {handleGetCalification(newData)}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "14px" }}>
              {newData.tipovehiculo} | Hasta{" "}
              {currencyFormat(newData.pesomaximo || 0, false)} kilos
            </Typography>
          </Box>
          <Box sx={{ display: !!newData.precioregular ? "block" : "none" }}>
            <Typography
              variant="caption"
              sx={{ textDecoration: "line-through" }}
            >
              {currencyFormat(newData?.precioregular || 0)}
            </Typography>
          </Box>
        </CardContent>
        <CardActions
          disableSpacing
          sx={{ display: "flex", justifyContent: "space-between", p: 2, pt: 0 }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              variant="body2"
              sx={{ fontSize: "22px", fontWeight: 700 }}
            >
              {currencyFormat(newData.precio)}
            </Typography>

            <Typography sx={{ color: "#757575", fontSize: "12px", pl: 0.5 }}>
              {newData.preciodetalle}
            </Typography>
          </Box>

          <ArrowForwardIcon sx={{ color: "#FF6700", cursor: "pointer" }} />
        </CardActions>
      </LinkWrapper>
    </Card>
  );
};

export default NewInfoCard;
