import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import { maxLengthPrice } from "@/consts/noticeConsts";
import { SUCCESS_TEXT_EDIT_PRICE } from "@/consts/textSuccess";
import { PROFILE_NOTICE_LIST_PATH } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { DetailsCoreModel } from "@/models/notice/noticeModels";
import useDetailNotice from "@/pages/detailNotice/hook";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { priceInfoText } from "@/pages/noticeV2/NoticePlace/placeConfig";
import { onSaveFlowInTheCityThunk } from "@/store/includedServices/inclodedServicesThunks";
import {
  IMultiPrices,
  SelectCity,
  setMultiprices,
} from "@/store/locationRedux/selectLocation";
import { setDirtyPrice } from "@/store/noticeRedux/noticeSlice";
import { editNoticePriceThunk } from "@/store/noticeRedux/noticeThunks";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { setMessageEdit } from "@/store/saveFlow/saveFlowSlice";
import Icon from "@/utils/Icon";
import { cleanValueNumber } from "@/utils/helpers";
import { Box, FormControl, Typography, styled } from "@mui/material";
import { ChangeEvent, useEffect, useId, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ModalFee from "../ModalFee";
import SectionEnterPrice from "../SectionEnterPrice";
import SectionMultiPrices from "../SectionMultiPrices";
import SectionOriginDestiny from "../SectionOriginDestiny";
import { configText } from "./configDataBodyFee";

export const BoxContainerInfo = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  marginBottom: "16px",
}));

const BodyFeeInCity = () => {
  const [openModal, setOpenModal] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [price, setPrice] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [messageErrorApi, setMessageErrorApi] = useState("");

  const id = useId();

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();
  const {
    selectLocation: { selectedOrigin, selectedDestiny, multiPrices },
    saveFlowData: { isLoading, saveFlowData },
    notice: { isLoading: isLoadingNotice },
  } = useAppSelector((state) => state);

  const { handleGetCurrentDetailNotice } = useDetailNotice();

  const notice: DetailsCoreModel = useMemo(
    () => handleGetCurrentDetailNotice(),
    [isLoading]
  );

  useEffect(() => {
    if (!!notice) {
      setPrice(notice.precio.toString());
      setIsEdit(location.pathname.startsWith(PROFILE_NOTICE_LIST_PATH));
    }
    dispatch(setDirtyPrice(false));
    return () => {
      dispatch(setDirtyPrice(false));
      setMessageErrorApi("");
    };
  }, []);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    } else if (!isLoading && !!saveFlowData.length) {
      handleNavigate();
    }
  }, [isLoading]);

  const handleNavigate = () => {
    dispatch(getNextRoute(navigate));
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleRemoveMiltuPrice = () => {
    setPrice("");
    dispatch(setMultiprices([]));
  };

  const handleShowSectionEnterPrice = () => {
    if ("distritodet" in selectedDestiny) {
      return !!selectedDestiny?.distritodet?.length;
    }
  };

  const handleOnchange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const { value } = currentTarget;
    /* Evitar que el usuario ingrese más de 5 caracteres. */
    if (value.length >= maxLengthPrice) return;

    const clenaValue = cleanValueNumber(value);
    setPrice(clenaValue);

    dispatch(setDirtyPrice(true));
  };

  const handleOnSaveMultiPrice = (multiPrice: IMultiPrices[]) => {
    dispatch(setMultiprices(multiPrice));
    setOpenModal(false);
  };

  const handleSubmit = () => {
    if (isLoadingNotice || isLoading) return;

    let currentPrice = !price.trim().length ? 0 : Number(price);
    const [notice] = saveFlowData;
    if (isEdit && !!notice) {
      dispatch(editNoticePriceThunk(notice.id, { precio: currentPrice })).then(
        handleSuccessEdit
      );
    } else {
      handleFlowNoticeSubmit(currentPrice);
    }
  };

  const handleSuccessEdit = () => {
    dispatch(setMessageEdit(SUCCESS_TEXT_EDIT_PRICE));
    navigate(-1);
  };

  const handleFlowNoticeSubmit = (currentPrice: number) => {
    dispatch(onSaveFlowInTheCityThunk(currentPrice, multiPrices)).catch(
      (err) => {
        setMessageErrorApi(err.message);
      }
    );
  };

  const handleSubTitle = (origin: SelectCity, destiny: SelectCity) => {
    return `De ${origin.nombre} a ${destiny.nombre}`;
  };

  const handleShowOrigin = () => {
    if (isEdit && !!notice) {
      return notice.origentexto;
    }

    const origin = selectedOrigin as SelectCity;
    if (origin?.detalle) {
      return `${origin.nombre}  (${origin.detalle})`;
    }

    return origin?.nombre;
  };

  const handleShowDestiny = () => {
    if (isEdit && !!notice) {
      return notice.destinotexto;
    }

    const destiny = selectedDestiny as SelectCity;
    if (destiny?.detalle) {
      return `${destiny.nombre}  (${destiny.detalle})`;
    }

    return destiny?.nombre;
  };

  const handleDisabled = () => {
    return (
      (!price.length && !multiPrices.length) || isLoading || isLoadingNotice
    );
  };

  const textButton = isEdit ? configText.button.edit : configText.button.flow;

  return (
    <>
      {!!messageErrorApi && (
        <StandardAlert
          sx={{ marginBottom: "16px" }}
          severity="error"
          color="error"
        >
          {messageErrorApi}
        </StandardAlert>
      )}
      <BoxContainerInfo>
        <Icon name="icon-handsShake" size="md" />
        <Typography variant="body2" sx={{ fontSize: "16px" }}>
          {priceInfoText}
        </Typography>
      </BoxContainerInfo>

      <ModalFee
        title="Ingresar precios a detalle"
        subTitle={handleSubTitle(
          selectedOrigin as SelectCity,
          selectedDestiny as SelectCity
        )}
        open={openModal}
        onClose={handleCloseModal}
        origin={(selectedOrigin as SelectCity)?.nombre}
        destinations={multiPrices || []}
        onSave={handleOnSaveMultiPrice}
        selectedDestiny={selectedDestiny as SelectCity}
        unitPrice={price}
      />
      {!!multiPrices?.length ? (
        <SectionMultiPrices
          multiPrices={multiPrices}
          selectedOrigin={selectedOrigin as SelectCity}
          onModalEdit={handleOpenModal}
          onModalRemoveMultiPrice={handleRemoveMiltuPrice}
        />
      ) : (
        <>
          <Box sx={{ marginBottom: "16px" }}>
            <SectionOriginDestiny
              origin={handleShowOrigin()}
              destiny={handleShowDestiny()}
            />
          </Box>

          <FormControl sx={{ marginTop: "16px", width: "100%" }}>
            <Input
              id={`${id}-price`}
              name="price"
              label={<LabelInputRequired str="Precio (S/)" />}
              value={price}
              variant="standard"
              placeholder="Lo que cobrarías por este servicio, Ej. 500"
              onChange={handleOnchange}
              onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                inputMode: "numeric",
              }}
            />
          </FormControl>

          {handleShowSectionEnterPrice() && (
            <SectionEnterPrice
              onClick={handleOpenModal}
              description="¿Quieres ingresar precios por distrito?"
              textBold="Hazlo aquí"
            />
          )}
        </>
      )}

      <ButtomLoader
        onClick={handleSubmit}
        sx={{ marginTop: "24px" }}
        isLoading={isLoadingNotice || isLoading}
        disabled={handleDisabled()}
      >
        {textButton}
      </ButtomLoader>
    </>
  );
};

export default BodyFeeInCity;
