import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { REQUEST_ACCEPTED, REQUEST_COUNTER_OFFER, REQUEST_INTEREST, REQUEST_OFFER, REQUEST_QUOTE, SMS_NOTIFICATION_CLIENT_RESPONSE } from "@/consts/requestConsts";
import { useAppSelector } from "@/hooks/useReducer";
import HeaderSuccess from "@/pages/noticeV2/NoticeCreated/HeaderSuccess";
import { ISolicitudNegociacion } from "@/services/interfaces/RequestNegResponseSuccess";
import { Container, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { REQUESTS_LANDING_PATH } from "../../../consts/typeServicesPaths";
import BodyPriceByType from "./BodyPriceByType";

const name = "Cotizar";

const textObject: Record<number, string> = {
  1: REQUEST_ACCEPTED,
  2: REQUEST_COUNTER_OFFER,
  4: REQUEST_OFFER,
  7: REQUEST_COUNTER_OFFER,
};

export const CreateRequestNegSuccess = () => {
  const navigate = useNavigate();

  const {
    noticeNegotationRequest: { selectedNegotationTypeRequest, requestNegResponseSuccess },
  } = useAppSelector((state) => state);
  const [title, setTitle] = useState("");
  const optionId = selectedNegotationTypeRequest?.id;
  const {
    precio,
    tipoSolicitudNegociacion,
  } = requestNegResponseSuccess as ISolicitudNegociacion;
  const dataNegotation = tipoSolicitudNegociacion;


  useEffect(() => {
    let newtitle = REQUEST_INTEREST;

    if (dataNegotation) {
      if (dataNegotation.nombre === name) {
        newtitle = REQUEST_QUOTE;
      } else {
        newtitle = textObject[optionId];
      }
    }

    setTitle(newtitle);
  }, [dataNegotation]);

  return (
    <Container maxWidth="xs" sx={{ marginTop: "16px", mb: '16px' }}>
      <HeaderSuccess icon="icon-success" title={title} subtitle={SMS_NOTIFICATION_CLIENT_RESPONSE} />
      <Stack
        sx={{
          gap: "3px",
          wordWrap: "break-word",
        }}
      >
        <Outlet />
        {
          !!precio && <BodyPriceByType
            data={requestNegResponseSuccess as ISolicitudNegociacion}
          />
        }
      </Stack>
      <Grid
        container
        sx={{
          marginBottom: "16px",
        }}
      >
        <Grid item width="100%">
          <ButtomLoader
            variant="contained"
            onClick={() => {
              navigate(REQUESTS_LANDING_PATH, {
                replace: true,
              });
            }}
          >
            VER solicitudes
          </ButtomLoader>
        </Grid>
      </Grid>
    </Container>
  );
}
