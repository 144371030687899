import {
  TypeDocumentAPI,
  UserAuth,
  UserToken,
} from "@/models/userAuth/userAuth";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";
import { initialState } from "./defaultConsts";

export interface Initialstate {
  userAuth: UserAuth;
  userToken: UserToken;
  typeUser: TypeDocumentAPI[];
  cellPhone: string;
  cellPhoneChange: string;
  isLoading: boolean;
  sendSMS: any;
  lastDateSMS: Dayjs | null;
  successCodeValidation: any;
  errorCellPhone: any;
  errorValidateCode: string;
  errorRegister: any;
  errorSendCode: any;
  errorLogin: any;
  errorResetPassword: any;
}

export const authUserSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    setCellPhone: (state, { payload }: PayloadAction<string>) => {
      state.cellPhone = payload;
    },
    setCellPhoneChange: (state, { payload }: PayloadAction<string>) => {
      state.cellPhoneChange = payload;
    },
    validUserExist: (state, { payload }: PayloadAction<UserAuth | null>) => {
      state.userAuth = payload ?? initialState.userAuth;
      state.isLoading = false;
      state.errorCellPhone = initialState.errorCellPhone;
      state.errorValidateCode = initialState.errorValidateCode;
    },
    setUserToken: (state, { payload }: PayloadAction<UserToken>) => {
      state.userToken = payload;
      state.isLoading = false;
      state.errorCellPhone = initialState.errorCellPhone;
      state.errorValidateCode = initialState.errorValidateCode;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
      state.errorValidateCode = initialState.errorValidateCode;
    },
    setErrorCellPhone: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.errorCellPhone = payload;
      state.errorValidateCode = initialState.errorValidateCode;
    },
    setSendSM: (state, { payload }: PayloadAction<any>) => {
      state.sendSMS = payload;
      state.isLoading = false;
      state.lastDateSMS = dayjs();
      state.errorValidateCode = initialState.errorValidateCode;
    },
    setErrorValidateCode: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorValidateCode = payload;
    },
    setSuccessCode: (state, { payload }: PayloadAction<any>) => {
      state.successCodeValidation = payload;
      state.isLoading = false;
      state.errorValidateCode = initialState.errorValidateCode;
    },
    setErrorRegister: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorRegister = payload;
    },
    setErrorSendCode: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorSendCode = payload;
    },
    setErrorLogin: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorLogin = payload;
    },
    setErrorResetPassword: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorResetPassword = payload;
    },
    setTypeUser: (state, { payload }: PayloadAction<TypeDocumentAPI[]>) => {
      state.isLoading = false;
      state.typeUser = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCellPhone,
  validUserExist,
  setLoading,
  setErrorCellPhone,
  setSendSM,
  setErrorValidateCode,
  setSuccessCode,
  setUserToken,
  setErrorRegister,
  setErrorSendCode,
  setErrorResetPassword,
  setErrorLogin,
  setTypeUser,
  setCellPhoneChange,
} = authUserSlice.actions;
