import {
  setSelectCity,
  setSelectedCityDestiny,
  setSelectedDistrictDestiny,
  setSelectOrigin,
} from '@/store/customerRequestRedux/customerRequestSlice';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

export type ModalType = {
  type: 'cityOrigin' | 'districtOrigin' | 'cityDestiny' | 'districtDestiny';
};

export const MODAL_TYPE: {
  cityOrigin: Extract<ModalType['type'], 'cityOrigin'>;
  districtOrigin: Extract<ModalType['type'], 'districtOrigin'>;
  cityDestiny: Extract<ModalType['type'], 'cityDestiny'>;
  districtDestiny: Extract<ModalType['type'], 'districtDestiny'>;
} = {
  cityOrigin: 'cityOrigin',
  districtOrigin: 'districtOrigin',
  cityDestiny: 'cityDestiny',
  districtDestiny: 'districtDestiny',
};

interface ConfigFormRute {
  cityOrigin: Location;
  districtOrigin: Location;
  cityDestiny: Location;
  districtDestiny: Location;
}

interface Location {
  input1: InputProps;
  input2?: InputProps;
  modal: InputModal;
}

interface InputProps {
  name: string;
  label: string;
  placeholder: string;
}

interface InputModal extends InputProps {
  title: string;
  type: ModalType['type'];
}

export const configFormRute: ConfigFormRute = {
  cityOrigin: {
    input1: {
      name: MODAL_TYPE.cityOrigin,
      label: 'Ciudad de origen',
      placeholder: 'Ciudad de recojo',
    },
    input2: {
      name: 'reference-city',
      label: 'Tu ciudad',
      placeholder: 'Ingresa una ciudad de origen',
    },
    modal: {
      name: 'city-origin',
      label: 'Ciudad de origen',
      placeholder: 'Ciudad de recojo',
      title: 'Ingresa tu origen',
      type: 'cityOrigin',
    },
  },
  districtOrigin: {
    input1: {
      name: MODAL_TYPE.districtOrigin,
      label: 'Distrito de origen',
      placeholder: 'Distrito de recojo',
    },
    input2: {
      name: MODAL_TYPE.districtOrigin,
      label: 'Referencia del origen',
      placeholder: 'Lugar conocido cerca a la dirección',
    },
    modal: {
      name: 'search-origin',
      label: 'Distrito de origen',
      placeholder: 'Distrito de recojo',
      title: 'Ingresa tu origen',
      type: 'districtOrigin',
    },
  },
  cityDestiny: {
    input1: {
      name: MODAL_TYPE.cityDestiny,
      label: 'Ciudad de destino',
      placeholder: 'Ciudad de entrega',
    },
    input2: {
      name: 'reference-destiny',
      label: 'Referencia de la ubicación',
      placeholder: 'Lugar, parque o av cercana a la dirección',
    },
    modal: {
      name: 'city-destiny',
      label: 'Ciudad de destino',
      placeholder: 'Ciudad de entrega',
      title: 'Ingresa tu destino',
      type: 'cityDestiny',
    },
  },
  districtDestiny: {
    input1: {
      name: MODAL_TYPE.districtDestiny,
      label: 'Distrito de destino',
      placeholder: 'Distrito de entrega',
    },
    input2: {
      name: 'reference-destiny',
      label: 'Referencia del destino',
      placeholder: 'Lugar conocido cerca a la dirección',
    },
    modal: {
      name: 'search-destiny',
      label: 'Distrito de destino',
      placeholder: 'Distrito de entrega',
      title: 'Ingresa tu destino',
      type: 'districtDestiny',
    },
  },
};

export const functionSaveObjectByTypeModal: Record<
  ModalType['type'],
  ActionCreatorWithPayload<any, string>
> = {
  cityOrigin: setSelectCity,
  districtOrigin: setSelectOrigin,
  cityDestiny: setSelectedCityDestiny,
  districtDestiny: setSelectedDistrictDestiny,
};
