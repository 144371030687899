import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import HeaderDetails from "@/pages/requestNegotiation/components/HaderDetails";
import { ISolicitudNegociacion } from "@/services/interfaces/RequestNegResponseSuccess";
import { IconTypes } from "@/utils/Icon";
import { Container, Grid } from "@mui/material";
import React from "react";
import PriceNegReject from "../PriceNegReject";
import useFinishedRejectLayoutHook from "./hook";
import ArrowBack from "@/pages/viewService/city/components/ArrowBack";
import { useAppSelector } from "@/hooks/useReducer";
import { actionsToNegotation } from "@/consts/actions";
import { UsuarioAPI } from "@/models/userAuth/user";
import { CLIENT_TYPE, DRIVER_TYPE } from "@/consts/defaultConsts";
interface ILayoutProps {
  children: React.ReactNode;
  request: ISolicitudNegociacion;
}
const Layout = ({ children, request }: ILayoutProps) => {
  const {
    matches,
    navigateNegociations,
    handleHeaderProps,
    isCoCounterOffer,
    hasBackNavigate,
    handleNavigationBack,
  } = useFinishedRejectLayoutHook();

  const {
    noticeNegotationRequest: { savedDataNogotation },
    user,
  } = useAppSelector((state) => state);
  const userData = (user.user as UsuarioAPI).tipousuario;


  const handleHeader = () => {
    const lastActionId =
      savedDataNogotation?.ultimaSolicitudNegociacionAcciones
        .tipoSolicitudNegociacionAccion.id;

    const driverRejected =
      (lastActionId === actionsToNegotation[6].id) && (userData === DRIVER_TYPE);
    const clientRejected =
      (lastActionId === actionsToNegotation[9].id) && (userData === CLIENT_TYPE);

    if (driverRejected) {
      return driverRejected;
    }
    if (clientRejected) return clientRejected;
  };

  return (
    <Container
      maxWidth={matches ? "xl" : "xs"}
      sx={{
        mt: "16px",
        marginBottom: matches ? 0 : 4,
      }}
    >
      <ArrowBack show={hasBackNavigate} onClick={handleNavigationBack} />
      {handleHeader() && (
        <HeaderDetails
          icon={handleHeaderProps().icon as IconTypes}
          title={handleHeaderProps().title}
          iconColor="black"
        />
      )}
      <Grid
        container
        spacing={0}
        sx={{
          display: "flex",
          justifyContent: "center",
          pl: matches ? 20 : 0,
          pr: matches ? 20 : 0,
        }}
      >
        <Grid xs={matches ? 6 : 12}>{children}</Grid>

        <Grid
          xs={matches ? 6 : 12}
          sx={{
            padding: matches ? "0px 16px 0px 16px" : "16px 0px",
          }}
        >
          {request && <PriceNegReject request={request} />}
          <ButtomLoader
            fullWidth
            variant="contained"
            onClick={() => navigateNegociations()}
            sx={{
              mt: 5,
            }}
          >
            {isCoCounterOffer ? "ver otras negociaciones" : "VER SOLICITUDES"}
          </ButtomLoader>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Layout;
