import { BASE_URL_IMAGE_S3 } from "@/config/config";
import { NoticesBussinesV2 } from "@/models/notice/noticeListOffersModels";
import { currencyFormat } from "@/utils/helpers";
import { Avatar, Grid, Typography, styled } from "@mui/material";
import { ContainerCardProfile } from "./styleCardProfile";

export enum TypeCard {
  open = 1,
  close = 2,
  expiredDate = 3,
}

type Props = {
  negotiation: NoticesBussinesV2;
  navigateBody?: (idDetail: number, idNotice: number) => void;
  onClickButton?: (link: string) => void;
  typeCard: TypeCard;
};

const GridDetails = styled(Grid)(
  () => `
    align-items: flex-end;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: -5px;
  `
);

const GridInfo = styled(Grid)(() => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  flexDirection: "column",
  paddingBottom: "7px",
}));

const CardProfile = ({
  negotiation,
  onClickButton,
  typeCard = TypeCard.open,
}: Props) => {
  const handleFormatDate = () => {
    if (!negotiation?.fecha) return "";

    if (typeCard === TypeCard.open) {
      return `Vence el ${negotiation?.fecha}`;
    } else if (typeCard === TypeCard.close) {
      return `Para el ${negotiation?.fecha}`;
    }
  };

  const handleNavigate = () => {
    const { link } = negotiation;
    onClickButton && onClickButton(link);
  };

  const templateDate = handleFormatDate();

  return (
    <>
      <ContainerCardProfile container xs={12} onClick={handleNavigate}>
        <Grid item xs={2}>
          <Avatar src={BASE_URL_IMAGE_S3 + negotiation.foto} />
        </Grid>
        <GridInfo item xs={6}>
          <Typography variant="body2">{negotiation.nombre}</Typography>
          <Typography
            variant="caption"
            color="#9E9E9E"
            display={templateDate ? "block" : "none"}
          >
            {templateDate}
          </Typography>
        </GridInfo>
        <GridDetails item xs={4}>
          <Typography variant="caption" color={negotiation?.colorEstado || ""}>
            {negotiation.estado}
          </Typography>
          {negotiation.precio && (
            <Typography variant="h6">
              {" "}
              {currencyFormat(negotiation.precio)}{" "}
            </Typography>
          )}
        </GridDetails>
      </ContainerCardProfile>
    </>
  );
};

export default CardProfile;
