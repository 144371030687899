import { prepareSelectedValueModal } from "@/components/Filter/adapter/filterAdapter";
import LabelRequired from "@/components/utils/LabelRequired";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { UsuarioAPI } from "@/models/userAuth/user";
import { INoticeCreateListItem } from "@/pages/noticeV2/NoticeList/types";
import { extractSendData, getNoticeType, getNoticeTypeInterface } from "@/pages/noticeV2/adapters/noticeUseTypes";
import { DistritoSugeridoData } from "@/services/noticeServices/noticeListSuggested/noticeListSuggestedTypes";
import { resetListLocation } from "@/store/filterNoticeRedux/filterNoticeSlice";
import { getCitiesByPaisFilterThunk, getDistrictsFilterThunk } from "@/store/filterNoticeRedux/filterNoticeThunk";
import { setDistrictsSuggested, setInputsPlace } from "@/store/noticeV2Redux/noticeV2Slice";
import { fillSendDataThunk, noticeGetDistrictSuggestedThunk, onSaveFlowNoticeV2Thunk } from "@/store/noticeV2Redux/noticeV2Thunk";
import { IInputsPlace, IReturnDataValues } from "@/store/noticeV2Redux/noticeV2types";
import { getNextRouteV2 } from "@/store/routeActionsFlowV2/routeActionsFlowNoticeV2Thunk";
import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { REGISTER_PATH } from "@/consts/noticeV2Paths";
import { REGISTER_DRIVER_STEPS } from "@/consts/typeServicesPaths";
import { IShowInputsProps } from "../../components/inputsConfig/types";
import { MODAL_ACTIVE, remanePlaceholders } from "../types";
import { MAX_PRICE_IN, MIN_PRICE_IN, placeholdersPlace } from "../hook";
import { textCannotLowerPrice, textHoursError, textPointsError } from "@/consts/noticeConsts";


export const usePlaceMultiInCityHook = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    filterNotice,
    user: { user },
    noticeV2: {
      selectNotice,
      inputsPlace,
      sendData,
      districtsSuggested,
      isLoading,
    },
  } = useAppSelector((state) => state);
  const { listLocation } = filterNotice;
  const [showModal, setShowModal] = useState(false);
  const [modalActiveWith, setModalActiveWith] = useState(MODAL_ACTIVE);

  const noticeTypeData = extractSendData(
    sendData,
    (selectNotice as INoticeCreateListItem)?.id || 0
  );
  const currentUser = user as UsuarioAPI;
  const isRegister = currentUser?.flgRegistroAviso === null;

  const isCity = modalActiveWith.name === 'city';

  useEffect(() => {
    loadFormData();
  }, []);

  useEffect(() => {
    if ((districtsSuggested as DistritoSugeridoData)?.sugeridos) {
      const body: IReturnDataValues = {
        ...noticeTypeData,
      };
      let avisodestinos: any[] = [];
      const { sugeridos } = districtsSuggested as DistritoSugeridoData;
      const defaultSuggestions = [...sugeridos];
      avisodestinos = defaultSuggestions.map((item) => ({
        iddistrito: item?.id,
      }));
      body.avisodestinos = avisodestinos;
      dispatch(
        fillSendDataThunk({
          key: getNoticeType((selectNotice as INoticeCreateListItem).id),
          value: getNoticeTypeInterface(
            (selectNotice as INoticeCreateListItem).id,
            body
          ),
        })
      );
    }
  }, [districtsSuggested]);

  const loadFormData = async () => {
    if (inputsPlace && !inputsPlace.city && !inputsPlace.origin) {
      const ciudad = currentUser?.ciudad || {};
      const district = currentUser?.distrito || {};
      // cargar datos por defecto
      let avisodestinos: any[] = [];
      const body: IReturnDataValues = {
        ...noticeTypeData,
        idciudad: ciudad?.id || 0,
        idciudaddestino: ciudad?.id || 0,
        idorigen: isRegister ? district?.id || 0 : 0,
        flghoraopunto: 2,
      };
      if (isRegister) {
        const res = await dispatch(
          noticeGetDistrictSuggestedThunk(body.idorigen, body.idtipoaviso)
        );
        const { sugeridos } = res as DistritoSugeridoData;
        const defaultSuggestions = [...sugeridos];
        avisodestinos = defaultSuggestions.map((item) => ({
          iddistrito: item?.id,
        }));
      }

      dispatch(
        fillSendDataThunk({
          key: getNoticeType((selectNotice as INoticeCreateListItem).id),
          value: getNoticeTypeInterface(
            (selectNotice as INoticeCreateListItem).id,
            {
              ...body,
              avisodestinos,
            }
          ),
        })
      ).then(() => {
        dispatch(
          setInputsPlace({
            ...inputsPlace,
            city: ciudad?.nombre2 || '',
            origin: isRegister ? district?.nombre || '' : '',
          })
        );
      });
    }
  };

  // delete change city
  const handleOnChangeCity = (body: IReturnDataValues, name: string) => {
    dispatch(
      fillSendDataThunk({
        key: getNoticeType((selectNotice as INoticeCreateListItem).id),
        value: getNoticeTypeInterface(
          (selectNotice as INoticeCreateListItem).id,
          {
            ...body,
            idorigen: 0,
            avisodestinos: [],
          }
        ),
      })
    ).then(() => {
      dispatch(
        setInputsPlace({
          ...inputsPlace,
          [modalActiveWith.name]: name,
          origin: '',
        })
      );
      dispatch(setDistrictsSuggested({}));
    });
  };

  const handleOnClick = (props: IShowInputsProps) => () => {
    setModalActiveWith({ name: props.name });
    setShowModal(true);
    dispatch(resetListLocation());
  };

  const onChange =
    (props: IShowInputsProps) => (event: EventType['change'] | Dayjs) => {
      handleOnClick(props)();
    };

  const handleGetValue = ({ name }: { name: keyof IInputsPlace }) => {
    return inputsPlace ? inputsPlace[name] : '';
  };

  const handleGetPlaceholder = (props: IShowInputsProps) => {
    const typeNoticePlaceholder =
      placeholdersPlace[(selectNotice as INoticeCreateListItem)?.id || 0];

    if (
      remanePlaceholders.some((name) => name === props.name) &&
      typeNoticePlaceholder
    ) {
      return typeNoticePlaceholder[props.name] || {};
    }

    return {};
  };

  const handleValidShow = (columnIShowInputsProps: any) => {
    const moreIShowInputsProps = columnIShowInputsProps?.callback
      ? columnIShowInputsProps?.callback(columnIShowInputsProps, filterNotice)
      : {};
    return { ...columnIShowInputsProps, ...moreIShowInputsProps };
  };

  const handleInitConfig = (column: any) => {
    const moreIShowInputsProps = column?.props?.callback
      ? column?.props?.callback(column?.props, {})
      : {};

    const props = {
      ...column.props,
      onClick: handleOnClick(column.props),
      onChange: onChange(column.props),
      value: handleGetValue(column.props),
      ...handleValidShow(column.props),
      ...handleGetPlaceholder(column.props),
      ...moreIShowInputsProps,
    };

    return {
      ...column,
      props,
    };
  };

  const handleOnCloseModal = () => {
    setShowModal(false);
    setModalActiveWith(MODAL_ACTIVE);
  };

  const handleSubmitModal = async (seleted: any) => {
    const name = prepareSelectedValueModal(seleted);

    const body: IReturnDataValues = {
      ...noticeTypeData,
    };

    if (isCity) {
      body.idciudad = seleted.id;
      body.idciudaddestino = seleted.id;
    }

    if (modalActiveWith.name === 'origin') {
      body.idorigen = seleted.id;
      let avisodestinos: any[] = [];
      const res = await dispatch(
        noticeGetDistrictSuggestedThunk(seleted.id, body.idtipoaviso)
      );
      const { sugeridos } = res as DistritoSugeridoData;
      const defaultSuggestions = [...sugeridos];
      avisodestinos = defaultSuggestions.map((item) => ({
        iddistrito: item.id,
      }));
      body.avisodestinos = avisodestinos;
    }

    dispatch(
      fillSendDataThunk({
        key: getNoticeType((selectNotice as INoticeCreateListItem).id),
        value: getNoticeTypeInterface(
          (selectNotice as INoticeCreateListItem).id,
          body
        ),
      })
    ).then(() => {
      dispatch(
        setInputsPlace({ ...inputsPlace, [modalActiveWith.name]: name })
      );
      if (isCity) {
        handleOnChangeCity(body, name);
      }
    });

    handleOnCloseModal();
  };
  const handleOnSeletedModal = (seleted: any) => {
    const exemption = ['origin', 'city'];
    if (exemption.some((exemp) => exemp === modalActiveWith.name)) {
      handleSubmitModal(seleted);
    }
  };

  const handleFilterByTerm = (value: string) => {
    if (value?.length < 3) return;

    const { name } = modalActiveWith;
    if (name === 'city') {
      dispatch(getCitiesByPaisFilterThunk(value));
    } else if (name === 'origin') {
      const searchCities = [3, 4];
      if (searchCities.includes((selectNotice as INoticeCreateListItem).id)) {
        dispatch(getCitiesByPaisFilterThunk(value));
      } else {
        dispatch(getDistrictsFilterThunk(value, inputsPlace.city as string));
      }
    }
  };

  const handleInputsSuggested = (id: number, precio: string) => {
    if (precio && !/^\d+$/.test(precio)) return;

    const avisodestinos: any[] = [...(noticeTypeData?.avisodestinos || [])];

    // Buscar el índice del destino en avisodestinos
    const index = avisodestinos.findIndex(
      (item: any) => item.iddistrito === id
    );

    if (index !== -1) {
      if (Number(precio) === 0) {
        avisodestinos.splice(index, 1); // Eliminar el destino si el precio es 0
      } else {
        // Clonar el objeto del destino para evitar mutación directa
        avisodestinos[index] = {
          ...avisodestinos[index],
          precio: Number(precio), // Actualizar el precio
        };
      }
    } else {
      avisodestinos.push({
        iddistrito: id,
        precio: Number(precio),
      });
    }

    const body: IReturnDataValues = {
      ...noticeTypeData,
      avisodestinos,
    };

    dispatch(
      fillSendDataThunk({
        key: getNoticeType((selectNotice as INoticeCreateListItem).id),
        value: getNoticeTypeInterface(
          (selectNotice as INoticeCreateListItem).id,
          {
            ...body,
          }
        ),
      })
    );
  };

  const DeleteSuggestedListItem = (id: number) => {
    const updatedAvisodestinos = noticeTypeData.avisodestinos.filter(
      (item) => item.iddistrito !== id
    );

    dispatch(
      fillSendDataThunk({
        key: getNoticeType((selectNotice as INoticeCreateListItem).id),
        value: getNoticeTypeInterface(
          (selectNotice as INoticeCreateListItem).id,
          {
            ...noticeTypeData,
            avisodestinos: updatedAvisodestinos,
          }
        ),
      })
    );

    dispatch(
      setDistrictsSuggested({
        ...districtsSuggested,
        sugeridos: (
          districtsSuggested as DistritoSugeridoData
        ).sugeridos.filter((item) => item.id !== id),
      })
    );
  };

  const changeRadioButtons = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    let body: IReturnDataValues = {
      ...noticeTypeData,
      flghoraopunto: Number(value),
    };

    dispatch(
      fillSendDataThunk({
        key: getNoticeType((selectNotice as INoticeCreateListItem).id),
        value: getNoticeTypeInterface(
          (selectNotice as INoticeCreateListItem).id,
          {
            ...body,
          }
        ),
      })
    );
  };

  const handleInputsCustomHourOrPoint = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, name } = event.target;

    if (!/^[0-9]*$/.test(value)) return;

    const body: IReturnDataValues = {
      ...noticeTypeData,
    };

    if (noticeTypeData.flghoraopunto === 1) {
      if (name === 'precioXhoraOrPoint') {
        body.precio = Number(value);
        body.precioxhora = Number(value);
      }

      if (name === 'puntosohoras') {
        body.horasminimo = Number(value);
      }
    }

    if (noticeTypeData.flghoraopunto === 2) {
      if (name === 'precioXhoraOrPoint') {
        body.precio = Number(value);
        body.precioxpunto = Number(value);
      }
      if (name === 'puntosohoras') {
        body.puntosminimo = Number(value);
      }
    }

    dispatch(
      fillSendDataThunk({
        key: getNoticeType((selectNotice as INoticeCreateListItem).id),
        value: getNoticeTypeInterface(
          (selectNotice as INoticeCreateListItem).id,
          {
            ...body,
          }
        ),
      })
    );
  };
  const validateFormCustomHourOrPointPrice = (value: number) => {
    if (Number(value) < MIN_PRICE_IN || Number(value) > MAX_PRICE_IN) {
      return {
        valid: false,
        msg: textCannotLowerPrice,
      };
    }
    return {
      valid: true,
      msg: '',
    };
  };

  const validateFormCustomHourOrPoint = (value: number, type: number) => {
    if (type === 1) {
      if (Number(value) < 1 || Number(value) > 24)
        return {
          valid: false,
          msg: textHoursError,
        };
    }
    if (type === 2) {
      if (Number(value) < 2 || Number(value) > 99)
        return {
          valid: false,
          msg: textPointsError,
        };
    }
    return {
      valid: true,
      msg: '',
    };
  };

  const validateForm = () => {
    const {
      idorigen,
      idciudad,
      idciudaddestino,
      avisodestinos,
      flghoraopunto,
      // si es 1
      horasminimo,
      precioxhora,
      // si es 2
      puntosminimo,
      precioxpunto,
    } = noticeTypeData;

    return !!(
      idorigen &&
      idciudad &&
      idciudaddestino &&
      avisodestinos.length > 0 &&
      flghoraopunto &&
      ((flghoraopunto === 1 &&
        horasminimo &&
        precioxhora &&
        validateFormCustomHourOrPoint(horasminimo, 1).valid &&
        validateFormCustomHourOrPointPrice(precioxhora).valid) ||
        (flghoraopunto === 2 &&
          puntosminimo &&
          precioxpunto &&
          validateFormCustomHourOrPoint(puntosminimo, 2).valid &&
          validateFormCustomHourOrPointPrice(precioxpunto).valid))
    );
  };

  const location = useLocation();
  const { pathname } = location;

  const handleNavigate = () => {
    if (pathname.includes(REGISTER_PATH)) {
      navigate(REGISTER_DRIVER_STEPS);
      return;
    } else {
      dispatch(getNextRouteV2(navigate));
    }
  };
  const onSubmit = () => {
    if (!validateForm()) {
      return;
    }

    dispatch(
      onSaveFlowNoticeV2Thunk({
        ...noticeTypeData,
      })
    ).then(() => {
      handleNavigate();
    });
  };

  return {
    handleInitConfig,
    showModal,
    handleOnCloseModal,
    handleSubmitModal,
    handleOnSeletedModal,
    handleFilterByTerm,
    modalActiveWith,
    listLocation,
    districtsSuggested,
    handleInputsSuggested,
    data: noticeTypeData,
    DeleteSuggestedListItem,
    changeRadioButtons,
    validateForm,
    onSubmit,
    handleInputsCustomHourOrPoint,
    isLoading,
    isRegister,
    formData: inputsPlace,
    validateFormCustomHourOrPointPrice,
    validateFormCustomHourOrPoint,
    isCity,
  };
};

export default usePlaceMultiInCityHook;

