import { NEGOTATION_REJECTION_CONFIRMATION } from "@/consts/negotationFlowPath";
import { textCurrentState, textStateChangeAlert } from "@/consts/noticeConsts";
import { ERROR_NOTICE_CLOSED } from "@/consts/textErrors";
import { compareUrls, deleteDomainUrl } from "@/utils/helpers";
import { Alert, Link } from "@mui/material";
import React from "react";
import { Link as ReactLink, useLocation } from "react-router-dom";
import useGetData from "../shared/hooks/useGetData";

const responseNotYet = "El cliente aún no responde.";

export const AlertComponent = () => {
  const { error, notice } = useGetData();
  const { pathname } = useLocation();
  const negotationClosed = error === ERROR_NOTICE_CLOSED;
  const [onCloseAlert, setOnCloseAlert] = React.useState(false);
  const belongToClient =
    notice?.ultimaAvisoNegociacionAcciones.tipoAvisoNegociacionAccion.descripcion.includes(
      "cliente"
    );

  const handleNegotationClosed = () => {
    const nextPath = pathname + NEGOTATION_REJECTION_CONFIRMATION;

    return nextPath;
  };

  return (
    <>
      {(!!notice?.redirect && compareUrls(notice?.redirect, pathname)) ||
      negotationClosed ? (
        <Alert
          sx={{ mb: 3, p: 2 }}
          severity={negotationClosed ? "error" : "warning"}
        >
          {textStateChangeAlert}
          <Link
            component={ReactLink}
            color={negotationClosed ? "error" : "rgba(237,108,2,1)"}
            fontWeight={600}
            to={
              negotationClosed
                ? handleNegotationClosed()
                : deleteDomainUrl(notice?.redirect || "")
            }
            replace
          >
            {textCurrentState}
          </Link>
        </Alert>
      ) : (
        !onCloseAlert &&
        !belongToClient && (
          <Alert
            onClose={() => {
              setOnCloseAlert(true);
            }}
            sx={{ mb: 3, mt: 3, p: 2 }}
            severity="info"
          >
            {responseNotYet}
          </Alert>
        )
      )}
    </>
  );
};
