import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import SwitchControl from "@/components/form/switchControl/SwitchControl";
import { textSolicitarCotizacion } from "@/consts/noticeConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { DetailNoticeMoving } from "@/models/notice/noticeModels";
import { NoticeConditions } from "@/models/offert/offertConditionsModels";
import { setListOfOfferConditions } from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferSlice";
import {
  getListOfOfferConditionsThunk,
  saveOfferMovingThunk,
} from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferThunk";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { FormControl, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const active = 1;
const inActive = 0;

const FormConditionsDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { listOfOfferConditions, selectTypeNotice, isLoading } = useAppSelector(
    (state) => state.noticeV2ClientAorCO
  );

  const notice = selectTypeNotice as DetailNoticeMoving;

  useEffect(() => {
    if (!listOfOfferConditions.length) {
      dispatch(getListOfOfferConditionsThunk());
    }
  }, []);

  const handleSubmit = () => {
    if (isLoading) return;
    dispatch(saveOfferMovingThunk()).then(() => {
      handleNextRute();
    });
  };

  const handleNextRute = () => {
    dispatch(
      getNextRoute(navigate, {
        valueToReplace: notice.id?.toString(),
        valueToSearch: ":id",
      })
    );
  };

  const handleChange = (condition: NoticeConditions) => {
    const newUpdatedList = listOfOfferConditions.map((itemList) => {
      if (itemList.id === condition.id) {
        return {
          ...itemList,
          pordefecto: itemList.pordefecto === active ? inActive : active,
        };
      }

      return itemList;
    });

    dispatch(setListOfOfferConditions(newUpdatedList));
  };

  const validateForm = () => {
    return true;
  };

  return (
    <>
      <Stack sx={{ marginTop: "auto" }} spacing={2}>
        <Typography variant="subtitle1">Condiciones del servicio</Typography>

        {listOfOfferConditions.map((condition) => (
          <SwitchControl
            text={condition?.condicionesNegociacion.nombre}
            onChange={() => handleChange(condition)}
            value={!!condition.pordefecto}
            hideSwitch={!!condition.flgver}
          />
        ))}
      </Stack>

      <FormControl fullWidth>
        <ButtomLoader
          onClick={handleSubmit}
          disabled={!validateForm()}
          isLoading={isLoading}
        >
          {textSolicitarCotizacion}
        </ButtomLoader>
      </FormControl>
    </>
  );
};

export default FormConditionsDetails;
