import BasicAlert from "@/components/alerts/BasicAlert";
import CircularLoading from "@/components/utils/CircularLoading";
import { List, ListItemButton, ListItemText, styled } from "@mui/material";

interface Props {
  list?: ListProps[];
  onClick?: (vale: ListProps) => void;
  isLoading?: boolean;
  show?: boolean;
  inputDirty?: boolean;
  nameTwo?: boolean;
}

export interface ListProps {
  nombre: string;
  nombre2?: string;
  id: number;
  idpais: number;
  detalle?: string;
}

const ListItem = styled(ListItemButton)(
  ({ theme }) => `
    &:hover {
      background-color: rgba(0,0,0,0.06);

      & .MuiListItemText-root .MuiTypography-root{
        color: ${theme.palette.error.main};
      }
    }

  `
);

const TextItem = styled(ListItemText)(
  ({ theme }) => `
    color: ${theme.palette.colorText.main};
    .MuiTypography-root.MuiTypography-body2 {
      color: ${theme.palette.text.disabled};
    }
  `
);

const AutoCompleteFilterModal = ({
  list,
  onClick,
  isLoading,
  show = true,
  inputDirty = true,
  nameTwo = false,
}: Props) => {
  if (!show) return <></>;

  if (isLoading) return <CircularLoading text="Buscando" />;

  const handleSelect = (select: ListProps) => {
    !!onClick && onClick(select);
  };

  if ((!Array.isArray(list) || !list.length) && inputDirty) {
    return (
      <BasicAlert severity="warning">No encontramos resultados</BasicAlert>
    );
  }

  const getTextItem = (res: ListProps) => {
    if (nameTwo) {
      return res.nombre2 || "";
    }
    return res.nombre || "";
  }

  return (
    <>
      <List>
        {list?.map((rest) => {
          const {nombre2, id, detalle } = rest;
          return (
          <ListItem
            key={id}
            onClick={() => handleSelect({nombre2, detalle, ...rest })}
          >
            <TextItem primary={ getTextItem(rest)} secondary={detalle || ""} />
          </ListItem>
        )}
        )}
      </List>
    </>
  );
};

export default AutoCompleteFilterModal;
