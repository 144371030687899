import { ID_MOVING_FLOW, ID_MULTI_DESTINY } from '@/consts/defaultConsts';
import { agreementClosedText, optionTwo } from '@/consts/noticeConsts';
import { ISolicitudNegociacion } from '@/services/interfaces/RequestNegResponseSuccess';
import { currencyFormat } from '@/utils/helpers';
import { Typography } from '@mui/material';
import MovingNegPrice from './MovingNegPrice';
import MultiDestinyNegOwnerPrice from './MultiDestinyNegOwnerPrice';
import PriceMultiDestiny from './PriceMultiDestiny';

interface IPricesProps {
    request: ISolicitudNegociacion;
}
const PricesNegAccept = ({ request }: IPricesProps) => {
    const {
        solicitud: {
            tipoSolicitud: {
                id: idTipoSolicitud,
            },
        },
        tipoSolicitudNegociacion: {
            id: idTipoSolicitudNegociacion,
        },
        flgIsOwner,
    } = request

    if(idTipoSolicitud === ID_MULTI_DESTINY) {
        if(flgIsOwner && idTipoSolicitudNegociacion === optionTwo) {
            return <MultiDestinyNegOwnerPrice data={request} />
        }
        return <PriceMultiDestiny data={request} />
    }

    if (idTipoSolicitud === ID_MOVING_FLOW){
        return <MovingNegPrice data={request} />
      }

  return <>
  <Typography
    sx={{ fontWeight: 700, fontSize: "22px", textAlign: "revert" }}
  >
    {agreementClosedText}: {currencyFormat(request.precioContraoferta || request.precio)}
  </Typography>
</>
}

export default PricesNegAccept
