import {
  USER_BACK_CAR_DEFAULT,
  USER_FRONT_CAR_DEFAULT,
  USER_SIDE_CAR_DEFAULT,
} from "@/consts/defaultConsts";
import { TEXT_ERROR_IMAGE_GENERIC } from "@/consts/textErrors";
import { useAppSelector } from "@/hooks/useReducer";
import { IVehicleList } from "@/store/userRedux/userReduxInterface";
import { useEffect, useState } from "react";
import ButtomLoader from "../buttonLoader/ButtomLoader";
import SectionImagens from "./components/SectionImagens";
interface IBodyPhotosCandE {
  foto: string | File;
  tipo: string;
}
interface IFormImagesVehicle {
  vehicles?: IVehicleList;
  onSubmit?: (data: IBodyPhotosCandE[] | []) => void;
  titleButton?: string;
  dataIsEmpty?: (isEmpty: boolean) => void;
}

const FormImagesVehicle = ({
  vehicles,
  onSubmit,
  titleButton,
  dataIsEmpty,
}: IFormImagesVehicle) => {
  const [imageFront, setImageFront] = useState<string | File>("");
  const [imageErrorFront, setImageErrorFront] = useState<boolean>(false);
  const [messageErrorFront, setMessageErrorFront] = useState("");

  const [imageSide, setImageSide] = useState<string | File>("");
  const [imageErrorSide, setImageErrorSide] = useState<boolean>(false);
  const [messageErrorSide, setMessageErrorSide] = useState("");

  const [imageBack, setImageBack] = useState<string | File>("");
  const [imageErrorBack, setImageErrorBack] = useState<boolean>(false);
  const [messageErrorBack, setMessageErrorBack] = useState("");

  const {
    user: { isLoading: isUserLoading },
    vehicle: { isLoading },
  } = useAppSelector((state) => state);

  useEffect(() => {
    // dataIsEmpty
    if (imageFront || imageSide || imageBack) {
      dataIsEmpty && dataIsEmpty(true);
    } else {
      dataIsEmpty && dataIsEmpty(false);
    }
  }, [imageFront, imageSide, imageBack]);

  useEffect(() => {
    if (imageErrorFront && !!imageFront) {
      setImageErrorFront(() => false);
      setMessageErrorFront("");
    }
  }, [imageFront]);

  useEffect(() => {
    if (imageErrorSide && !!imageSide) {
      setImageErrorSide(() => false);
      setMessageErrorSide("");
    }
  }, [imageSide]);

  useEffect(() => {
    if (imageErrorBack && !!imageBack) {
      setImageErrorBack(() => false);
      setMessageErrorBack("");
    }
  }, [imageBack]);

  // ============== IMAGE FRONT =================
  const handleSelectedImageFront = (img: string | File) => {
    setImageFront(() => img);
  };

  const handleErrorImageFront = ({ message }: Error) => {
    setImageErrorFront(() => true);
    setImageFront(() => "");
    setMessageErrorFront(() => message);
  };

  // ============== IMAGE SIDE =================
  const handleSelectedImageSide = (img: string | File) => {
    setImageSide(() => img);
  };

  const handleErrorImageSide = ({ message }: Error) => {
    setImageErrorSide(true);
    setImageSide("");
    message && setMessageErrorSide(message);
  };

  // ============== IMAGE BACK =================
  const handleSelectedImageBack = (img: string | File) => {
    setImageBack(() => img);
  };

  const handleErrorImageBack = ({ message }: Error) => {
    setImageErrorBack(true);
    setImageBack("");
    message && setMessageErrorBack(message);
  };

  const handleSubmit = () => {
    const body = [];
    if (imageFront) {
      body.push({ foto: imageFront, tipo: "FRONTAL" });
    }
    if (imageSide) {
      body.push({ foto: imageSide, tipo: "LATERAL" });
    }
    if (imageBack) {
      body.push({ foto: imageBack, tipo: "POSTERIOR" });
    }
    onSubmit && onSubmit(body);
  };

  const handleDisabled = () => {
    if ((vehicles as IVehicleList)?.vehiculofotos?.length === 3) {
      return false;
    } else {
      return (
        !imageFront || !imageSide || !imageBack || isUserLoading || isLoading
      );
    }
  };

  const handleGetDefaultImage = (type: "FRONTAL" | "LATERAL" | "POSTERIOR") => {
    switch (type) {
      case "FRONTAL":
        return USER_FRONT_CAR_DEFAULT;
      case "LATERAL":
        return USER_SIDE_CAR_DEFAULT;
      default:
        return USER_BACK_CAR_DEFAULT;
    }
  };

  const handleImageDefault = (type: "FRONTAL" | "LATERAL" | "POSTERIOR") => {
    const vehiculofotos = (vehicles as IVehicleList)?.vehiculofotos;
    const imageDefault = handleGetDefaultImage(type);
    if (!!vehicles && !!vehiculofotos?.length) {
      const imageFront = vehiculofotos.find((photo) => photo.tipo === type);
      return imageFront?.direccion || imageDefault;
    }

    return imageDefault;
  };

  return (
    <>
      <SectionImagens
        title="Foto frontal"
        textError={messageErrorFront || TEXT_ERROR_IMAGE_GENERIC}
        showTextError={imageErrorFront}
        imageDefault={handleImageDefault("FRONTAL")}
        onError={handleErrorImageFront}
        onSelect={handleSelectedImageFront}
      />

      <SectionImagens
        title="Foto lateral"
        textError={messageErrorSide || TEXT_ERROR_IMAGE_GENERIC}
        showTextError={imageErrorSide}
        imageDefault={handleImageDefault("LATERAL")}
        onSelect={handleSelectedImageSide}
        onError={handleErrorImageSide}
      />

      <SectionImagens
        title="Foto posterior"
        textError={messageErrorBack || TEXT_ERROR_IMAGE_GENERIC}
        showTextError={imageErrorBack}
        imageDefault={handleImageDefault("POSTERIOR")}
        onSelect={handleSelectedImageBack}
        onError={handleErrorImageBack}
      />

      <ButtomLoader
        type="submit"
        onClick={handleSubmit}
        isLoading={isUserLoading || isLoading}
        disabled={handleDisabled()}
        sx={{ marginBottom: "16px" }}
      >
        {titleButton || "Guardar"}
      </ButtomLoader>
    </>
  );
};

export default FormImagesVehicle;
