import { actionsToNoticeNegotation } from '@/consts/actions';
import {
  CLIENT_TYPE,
  DRIVER_TYPE,
  ID_AUCTION_FLOW,
  ID_BETWEEN_CITIES,
  ID_MOVING_FLOW,
  ID_MULTI_DESTINY,
} from '@/consts/defaultConsts';
import { CustomerRequest } from '@/models/customerRequest/customerRequestResponse';
import { ISolicitudViewDetailsResponse } from '@/models/customerRequest/requestDetailsVewResponse';
import { NoticeResponseSaveFlowAPI } from '@/models/notice/noticeModels';
import {
  Client,
  Negotiation,
  OffertStatus,
} from '@/models/offert/offertModels';
import { PhotosVehicleAPI } from '@/models/vehicle/vehicleModel';
import { IUser } from '@/store/userRedux/userReduxInterface';
import { SxProps } from '@mui/material';
import { urlNavigateNoticeConfig } from '../globalButtons/config';

export interface DataProfileDriverFormatReturn {
  idUser: number;
  userName: string;
  urlProfile: string;
  rating: number;
  ratingText: string;
  showPrice: boolean;
  showTwoAvatars: boolean;
  sx: {
    display: string;
    justifyContent: string;
    alignItems: string;
  };
}

export const dataProfileFormat = (
  savedOffert: Negotiation | CustomerRequest | ISolicitudViewDetailsResponse,
  user?: IUser
): DataProfileDriverFormatReturn => {
  const data = savedOffert as ISolicitudViewDetailsResponse;
  const { cliente, transportista } = data;

  const profile = !!data?.flgIsOwner ? transportista : cliente;

  const userName = !!profile?.nombre ? profile?.nombre : '';

  const dataProfile = {
    idUser: profile?.id,
    userName,
    urlProfile: profile?.perfilUrl,
    rating: profile?.calificacion,
    ratingText: profile?.calificacionTexto,
    showPrice: false,
    showTwoAvatars: false,
    sx: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };

  return dataProfile;
};

export const dataTransportistaFormat = (
  savedOffert: NoticeResponseSaveFlowAPI
): DataProfileDriverFormatReturn => {
  const driver = savedOffert?.transportista;

  return {
    idUser: driver?.id,
    userName: driver?.nombre,
    urlProfile: driver?.perfilUrl,
    rating: driver?.calificacion,
    ratingText: driver?.calificacionTexto,
    showPrice: false,
    showTwoAvatars: false,
    sx: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
};

export const dataDriverFormat = (
  savedOffert: Negotiation
): DataProfileDriverFormatReturn => {
  const driver = savedOffert?.avisoNegociacionAviso.transportista;

  return {
    idUser: driver?.id,
    userName: driver?.nombreyapellidos as string,
    urlProfile: driver?.perfilUrl,
    rating: driver?.calificacion,
    ratingText: driver?.calificacionTexto,
    showPrice: false,
    showTwoAvatars: false,
    sx: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
};

export const nextUrlFormat = (
  typeId: string,
  actionId: number,
  option: number
) => {
  const path = urlNavigateNoticeConfig[actionId][option];

  const url = path.replace(':idOffert', typeId);

  return url;
};

export const idAcceptanceToConfigToReturnFormat = (
  savedOffert: Negotiation
) => {
  const data = {
    tipoAviso: savedOffert?.avisoNegociacionAviso.tipoaviso.id,
  };

  return data;
};

export interface DataProfileFormat {
  idUser: number;
  userName: string;
  urlProfile: string;
  rating: number;
  ratingText: string;
  showPrice: boolean;
  urlVehicle: PhotosVehicleAPI[];
  showTwoAvatars: boolean;
  sx: SxProps;
}

export const dataAcceptanceProfileFormat = (
  savedOffer: Negotiation,
  showTwoAvatars: boolean = true
): DataProfileFormat => {
  const driver = savedOffer?.avisoNegociacionAviso.transportista as Client;
  const client = savedOffer?.cliente as Client;

  let dataProfile = {
    idUser: driver?.id,
    userName: driver?.nombreyapellidos as string,
    urlProfile: driver?.perfilUrl,
    rating: driver?.calificacion,
    ratingText: driver?.calificacionTexto,
    showPrice: false,
    urlVehicle: savedOffer?.avisoNegociacionAviso.vehiculo?.vehiculofotos,
    showTwoAvatars: showTwoAvatars,
    sx: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 0,
    },
  };

  if (!showTwoAvatars) {
    dataProfile = {
      idUser: client?.id,
      userName: client?.nombreyapellidos as string,
      urlProfile: client?.perfilUrl,
      rating: client?.calificacion,
      ratingText: client?.calificacionTexto,
      showPrice: false,
      urlVehicle: savedOffer?.avisoNegociacionAviso.vehiculo?.vehiculofotos,
      showTwoAvatars: showTwoAvatars,
      sx: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 0,
      },
    };
  }

  return dataProfile;
};

export const idToConfigToReturnFormat = (savedOffer: Negotiation) => {
  const data = {
    tipoSolicitud: savedOffer?.avisoNegociacionAviso.tipoaviso.id,
    tipoSolicitudNegociacionId: savedOffer?.tipoAvisoNegociacion.id,
  };

  return data;
};

export const acceptanceAdapter = (savedOffert: Negotiation) => {
  const actionId = savedOffert?.ultimaAvisoNegociacionAcciones
    ?.tipoAvisoNegociacionAccion.id as number;
  const typeFlow = savedOffert?.avisoNegociacionAviso?.tipoaviso?.id as number;
  const state = savedOffert?.ultimaAvisoNegociacionAcciones
    .tipoAvisoNegociacionAccion.estado as OffertStatus;
  const idState =
    savedOffert?.ultimaAvisoNegociacionAcciones?.tipoAvisoNegociacionAccion?.id;

  const isAuction = typeFlow === ID_AUCTION_FLOW;
  const isMoving = typeFlow === ID_MOVING_FLOW;
  const isMultdestiny = typeFlow === ID_MULTI_DESTINY;
  const isBetweenCities = typeFlow === ID_BETWEEN_CITIES;
  const isOwner = savedOffert?.flgIsOwner;

  const isDriverCounterOffer = actionId === actionsToNoticeNegotation[5].id;

  const flagsUser = (savedOffert as Negotiation)?.ultimaAvisoNegociacionAcciones
    ?.tipoAvisoNegociacionAccion;

  const activeBtnDriver = () => {
    const validation =
      !!flagsUser?.flgPermitirAceptarTransportista &&
      !!flagsUser?.flgPermitirRechazarTransportista &&
      !!flagsUser?.flgPermitirContraofertarTransportista;

    if (validation) return true;

    return false;
  };

  const activeBtnClient = () => {
    const validation =
      !!flagsUser?.flgPermitirAceptarCliente &&
      !!flagsUser?.flgPermitirRechazarCliente &&
      !!flagsUser?.flgPermitirContraofertarCliente;

    if (validation) return true;

    return false;
  };

  const isClient = state?.tipoUsuario === CLIENT_TYPE;
  const isDriver = state?.tipoUsuario === DRIVER_TYPE;

  const validSnackbarDriver =
    isClient && !activeBtnDriver() && !!state?.flgAbierto;

  const validSnackbarClient =
    isDriver && !activeBtnClient() && !!state?.flgAbierto;

  return {
    typeFlow,
    idState,
    isAuction,
    isMoving,
    isMultdestiny,
    isBetweenCities,
    isOwner,
    tipoAvisoNegociacionAccionId: actionId,
    isDriverCounterOffer,
    flgPermitirAceptarTransportista: flagsUser?.flgPermitirAceptarTransportista,
    flgPermitirRechazarTransportista:
      flagsUser?.flgPermitirRechazarTransportista,
    flgPermitirContraofertarTransportista:
      flagsUser?.flgPermitirContraofertarTransportista,
    flgPermitirAceptarCliente: flagsUser?.flgPermitirAceptarCliente,
    flgPermitirRechazarCliente: flagsUser?.flgPermitirRechazarCliente,
    flgPermitirContraofertarCliente: flagsUser?.flgPermitirContraofertarCliente,
    activeBtnDriver,
    activeBtnClient,
    tipoUsuario: state?.tipoUsuario,
    validSnackbarDriver,
    validSnackbarClient,
  };
};
