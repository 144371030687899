import { PROFILE_EDITAR_VEHICULO_PATH } from '@/consts/typeServicesPaths';

export interface IListActionsEditVehicle {
  title: string;
  position: string;
  path: string;
  icon?: string;
}

export const ListActionsEditVehicle: IListActionsEditVehicle[] = [
  {
    title: 'Datos del vehículo',
    path: PROFILE_EDITAR_VEHICULO_PATH[0],
    position: 'datos',
  },
  {
    title: 'Fotos del vehículo',
    path: PROFILE_EDITAR_VEHICULO_PATH[1],
    position: 'fotos',
  },
  {
    title: 'Tarjeta de propiedad',
    path: PROFILE_EDITAR_VEHICULO_PATH[2],
    position: 'documento',
  },
];

export const photoPosition = {
  data: 'datos',
  photo: 'fotos',
  document: 'documento',
};
