import {
  COUNTER_OFFER_MOVING_FLOW_PATH,
  OFFER_MOVING_FLOW_PATH,
} from '@/pages/flowNoticeNegotation/shared/const/routes/clientAccepOrCounteroffer';

interface ITitleAndSubTitle {
  title: string;
  subTitle: string;
}

interface IOriginAndDestiny {
  origin: ITitleAndSubTitle;
  destiny: ITitleAndSubTitle;
}

interface ITypeOffert {
  [key: number]: IOriginAndDestiny;
}

interface ITypeOffertDefault {
  [key: number]: ITitleAndSubTitle;
}

export interface ITypeAviso {
  [key: number]: ITypeOffert;
  default: ITypeOffertDefault;
}

export const headerType: ITypeAviso = {
  5: {
    1: {
      origin: {
        title: 'Origen',
        subTitle: 'Aceptar',
      },
      destiny: {
        title: 'Destino',
        subTitle: 'Aceptar',
      },
    },
    2: {
      origin: {
        title: 'Origen',
        subTitle: 'Contraofertar',
      },
      destiny: {
        title: 'Destino',
        subTitle: 'Contraofertar',
      },
    },
    3: {
      origin: {
        title: 'Origen',
        subTitle: 'Aceptar',
      },
      destiny: {
        title: 'Destino',
        subTitle: 'Aceptar',
      },
    },
  },
  default: {
    1: {
      title: 'Origen y destino',
      subTitle: 'Aceptar',
    },
    2: {
      title: 'Origen y destino',
      subTitle: 'Contraofertar',
    },
  },
};

export const getHeaderType = (
  idTipoAviso: number,
  idTipoAvisoNegociacion: number,
  pathname: string,
  replace?: string
): ITitleAndSubTitle => {
  const isMoving = idTipoAviso === 5;
  const re = /\d+/g;
  const path = pathname.replace(re, replace || ':id');
  const isOrigin =
    path === OFFER_MOVING_FLOW_PATH[1] ||
    path === COUNTER_OFFER_MOVING_FLOW_PATH[1];
  const isDestiny =
    path === OFFER_MOVING_FLOW_PATH[2] ||
    path === COUNTER_OFFER_MOVING_FLOW_PATH[2];

  if (isOrigin) {
    let result = headerType[idTipoAviso][idTipoAvisoNegociacion].origin;

    if (isMoving) result = headerType[idTipoAviso][3].origin;

    return result;
  } else if (isDestiny) {
    let result = headerType[idTipoAviso][idTipoAvisoNegociacion].destiny;
    if (isMoving) result = headerType[idTipoAviso][3].destiny;
    return result;
  }
  return headerType.default[idTipoAvisoNegociacion];
};
