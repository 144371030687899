import { AnyAction, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';

import {
  appReducer,
  initialReducerFlow,
  initialReducerLogout,
  persistConfig,
} from './configStore';

const reducerProxy = (state: any, action: AnyAction) => {
  if (action.type === 'user/logout') {
    return appReducer({ ...state, ...initialReducerLogout }, action);
  }
  if (action.type === 'saveFlow/resetFlow') {
    return appReducer({ ...state, ...initialReducerFlow }, action);
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, reducerProxy);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'development',
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
