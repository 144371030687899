import { currencyFormat } from "@/utils/helpers";
import { Before, DsctoContainer, DsctoText, DsctoTitle, Now } from "./style";
import { SxProps, Theme } from "@mui/material";

type Props = {
  beforePrice: number;
  currentPrice: number;
  show?: boolean;
  sx?: SxProps<Theme>;
};

const DestailDescount = (props: Props) => {
  const { beforePrice, currentPrice, sx, show = true } = props;
  if (!show) return <></>;

  const handleGetPrice = (value: number) => {
    if (value) {
      return currencyFormat(value);
    }
  };

  const handleGetDescount = () => {
    const descount = (currentPrice / beforePrice) * 100;

    return Math.round(descount);
  };

  return (
    <DsctoContainer sx={sx}>
      <DsctoTitle container>
        <DsctoText>
          Antes: <Before>{handleGetPrice(beforePrice)}</Before>
        </DsctoText>
        <Now>{handleGetDescount()}% dscto.</Now>
      </DsctoTitle>
    </DsctoContainer>
  );
};

export default DestailDescount;
