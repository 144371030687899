import { REGISTER_CLIENT, REGISTER_DRIVER } from '@/consts/typeServicesPaths';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
export const dataListOptions = [
  {
    icon: 'icon-user--blue',
    IconComponent: AccountCircleOutlinedIcon,
    title: 'Registrarme como usuario',
    link: REGISTER_CLIENT,
    typeFLow: 'C',
    containerIconSx: { marginRight: '16px' },
  },
  {
    icon: 'icon-vehicle-side--blue',
    IconComponent: LocalShippingOutlinedIcon,
    title: 'Registrarme como transportista',
    link: REGISTER_DRIVER,
    typeFLow: 'T',
    containerIconSx: { marginRight: '16px' },
    sx: { marginTop: '16px' },
  },
];
 
export const title = 'Registro en PegaCargo.com';
export const description = '¿Qué quieres hacer?';
