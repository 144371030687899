import { SxProps, Theme, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import {
  ContainerSwitch,
  IOSSwitch,
  PointView,
  TextContainer,
} from "./styleSwitchControl";

type Props = {
  text: string;
  inputProps?: Object;
  sx?: SxProps<Theme>;
  onChange?: (value: number) => void;
  value?: boolean;
  disabled?: boolean;
  hideSwitch?: boolean;
  pointHidden?: boolean;
};

const active = 1;
const inactive = 0;

const Point = ({ show = false }: { show: boolean }) => {
  if (!show) return null;

  return <PointView>•</PointView>;
};

const SwitchControl = ({
  inputProps,
  text,
  sx,
  onChange,
  value = false,
  disabled = false,
  hideSwitch = true,
  pointHidden = false,
}: Props) => {
  const [switchCheck, setswitchCheck] = useState(value);
  useEffect(() => {
    setswitchCheck(value);
  }, [value]);

  const handleChange = (value: boolean) => {
    const currentValue = !!value ? active : inactive;
    onChange && onChange(currentValue);
    setswitchCheck(value);
  };

  return (
    <ContainerSwitch item sx={sx}>
      <>
        <TextContainer>
          <Point show={pointHidden} />
          {text}
        </TextContainer>
        { hideSwitch && (
          <IOSSwitch
            checked={switchCheck}
            onChange={() => handleChange(!switchCheck)}
            inputProps={{ "aria-label": "controlled", ...inputProps }}
            disabled={disabled}
          />
        )}
      </>
    </ContainerSwitch>
  );
};

export default SwitchControl;
