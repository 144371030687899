import { Box, Grid, Stack, styled } from '@mui/material';

export const HeaderContainer = styled(Stack)(
  () => `
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `
);

export const ContainerTags = styled(Box)(
  () => `
    width: 100%;
    padding: 0;
    margin-top: 25px;
  `
);

export const ContainerList = styled(Grid)(
  () => `
    gap: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
  `
);

export const ContainerMyNoticeList = styled(Grid)(
  () => `
  grid-template-columns: repeat( auto-fill, minmax(310px, 1fr) );
  display: grid;
  gap: 16px;
  width: 100%;
  `
);
