import {
  getDistrictByTermServices,
  getDistrictsByCityAndTermServices,
  getDistrictsByCityServices,
  getOnlyDistrictsByCityServices,
} from '../../services/locationServices';
import { AppDispatch } from '../store';
import {
  setDistricts,
  setDistricts2,
  setError,
  setLoading,
} from './districtsSlice';

export const getDistrictsByCityThunk = (idCity: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const result = await getDistrictsByCityServices(idCity);

      dispatch(setDistricts(result?.distritoData?.distritos));
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

/**
 * @returns Distritos sin zonas.
 */
export const getOnlyDistrictsByCityThunk = (idCity: string, city: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      if (city === 'origin') {
        const result = await getOnlyDistrictsByCityServices(idCity);
        dispatch(setDistricts(result?.distritoData?.distritos));
      }
      if (city === 'destiny') {
        const result = await getOnlyDistrictsByCityServices(idCity);
        dispatch(setDistricts2(result?.distritoData?.distritos));
      }
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

export const getDistrictByTermThunk = (
  term: string,
  activeDistrict2?: boolean
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const result = await getDistrictByTermServices(term);
      dispatch(setDistricts(result?.distritoData?.distritos));
      if (!!activeDistrict2) {
        dispatch(setDistricts2(result?.distritoData?.distritos));
      }
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

export const getDistrictsByCityAndTermThunk = (
  idCity: string,
  term: string
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const result = await getDistrictsByCityAndTermServices(idCity, term);

      dispatch(setDistricts(result?.distritoData?.distritos));
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

export const getDistrictsByCityAndTermThunkV2 = (idCity: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const result = await getOnlyDistrictsByCityServices(idCity);

      dispatch(setDistricts(result?.distritoData?.distritos));
    } catch (error) {
      dispatch(setError(error));
    }
  };
};
