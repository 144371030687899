import { ID_MOVING_FLOW, ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import { MovingCounterOfferPrice } from "./MovingCounterOfferPrice";
import { MultidestinyCounterofferPrice } from "./MultidestinyCounterofferPrice";
import { PriceDefaultFormat } from "./PriceDefaultFormat";

export const GlobalPriceRequestNeg = ({
  data,
}: {
  data: SolicitudNegociacion;
}) => {
  
  if ((data?.solicitud.tipoSolicitud.id as number) === ID_MOVING_FLOW)
  return <MovingCounterOfferPrice data={data}/>
    
  if ((data?.solicitud.tipoSolicitud.id as number) === ID_MULTI_DESTINY)
    return <MultidestinyCounterofferPrice data={data} />;

  return <PriceDefaultFormat data={data}/>;
};
