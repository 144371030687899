import React, { useState } from 'react'
import { MainTags, TabItem, TabPanelBox } from './style';

interface ITabsItem {
  label: string;
  value: number;
  panel: JSX.Element | JSX.Element[] | string;
}


interface ITabsCustomProps {
  items: ITabsItem[];
  arialLabel: string;
  onChange?: (activeTag: number) => void;
  initialTag?: number;
}


const TagsCustom = (
  {
    items,
    arialLabel,
    onChange,
    initialTag = 1
  }: ITabsCustomProps
) => {
  const [value, setValue] = useState(initialTag);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(Number(newValue));
    onChange && onChange(newValue);
  };


  const calculeWithForTabs = () => {
    const width = 100 / items.length;
    return `${width}%`;
  }

  return (
    <>
      <MainTags value={value} onChange={handleChange} aria-label={arialLabel || "tags"}>
        {items.map((item, index) => (
          <TabItem
            key={index}
            value={item.value}
            label={item.label}
            sx={{
              width: {
                xs: calculeWithForTabs(),
                sm: "auto",
              },
              fontSize: {
                xs: "12px",
                sm: "14px",
              }
            }}
          />
        ))}
      </MainTags>
      {
        items.map((panel, index) => (
          <TabPanel
            key={index}
            value={value}
            index={panel.value}
          >
            {panel.panel}
          </TabPanel>
        ))
      }
    </>

  )
}

interface TabPanelProps {
  children?: React.ReactNode;
  value: number;
  index: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <TabPanelBox
        sx={{
          padding: {
            xs: "16px 0",
            sm: "16px",
          },
        }}
      >{children}</TabPanelBox>}
    </div>
  );
};

export default TagsCustom
