import { InitialFilterSlice } from '@/store/filterNoticeRedux/filterNoticeSlice';

export const resizeButtonColumsProps = (
  _: any,
  { typeNoticeSelecte }: InitialFilterSlice
) => {
  if (!!typeNoticeSelecte?.inputhoraopunto) {
    return {
      lg: 12,
      md: 12,
      xs: 12,
      sx: {
        paddingRight: '15px',
        display: 'flex',
        justifyContent: 'flex-end',
      },
    };
  }
};

export const resizeInputsProps = (
  _: any,
  { typeNoticeSelecte }: InitialFilterSlice
) => {
  if (!!typeNoticeSelecte?.inputhoraopunto) {
    return {
      lg: 4,
      md: 4,
      xs: 12,
      sx: {
        paddingRight: { xs: '0px', sm: '15px' },
        marginTop: { xs: '12px', sm: '12px', md: 0, lg: 0 },
      },
    };
  }
};

export const resizeButtonProps = (
  _: any,
  { typeNoticeSelecte }: InitialFilterSlice
) => {
  if (!!typeNoticeSelecte?.inputhoraopunto) {
    return {
      sx: {
        maxWidth: { xs: '100%', sm: '100%', md: '40%', lg: '33.33%' },
      },
    };
  } else {
    return {
      sx: {
        marginTop: '5px',
      },
    };
  }
};

export const renderInputTimeService = (
  props: any,
  filter: InitialFilterSlice
) => {
  const { typeNoticeSelecte } = filter;

  if (!!typeNoticeSelecte?.inputfechas) {
    return { sx: { display: 'none' } };
  }
  return resizeInputsProps(props, filter);
};

export const renderInputBetweenDate = (
  props: any,
  filter: InitialFilterSlice
) => {
  const { typeNoticeSelecte } = filter;
  if (!typeNoticeSelecte?.inputfechas) {
    return { sx: { display: 'none' } };
  }
  return resizeInputsProps(props, filter);
};
