import {
  ratingDetailsToggleTrue,
  userHistoryRatingAdapter,
} from '@/adapters/store/ratingAgreementAdapter';
import {
  getRatingAgreementDataServices,
  getUserHistoryRatingDataService,
  sendRatingAgreementDataServices,
} from '@/services/ratingAgreementServices';
import {
  IGetRatingProps,
  IRatingAgreementSend,
} from '../../models/ratingAgreement/ratingAgrementModel';
import { getMessaggeError } from '../auth/utils';
import { AppDispatch, RootState } from '../store';
import {
  getRatingAgreementError,
  getRatingAgreementSuccess,
  getUserHistoryRatingDataSuccess,
  setIsLoading,
} from './ratingAgreementSlice';

export const getRatingAgreementThunk =
  ({ idAviso = 0, idSolicitud = 0 }: IGetRatingProps) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setIsLoading(true));
    try {
      const {
        calificacionData: {
          calificaciones: [data],
        },
      } = await getRatingAgreementDataServices({ idAviso, idSolicitud });

      dispatch(getRatingAgreementSuccess(ratingDetailsToggleTrue(data)));
      dispatch(setIsLoading(false));
    } catch (err) {
      const error = getMessaggeError(err);

      dispatch(getRatingAgreementError(error));
      throw new Error(error.message || undefined);
    }
  };

export const sendRatingAgreementThunk =
  (body: IRatingAgreementSend) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setIsLoading(true));
      await sendRatingAgreementDataServices(body);
    } catch (err) {
      const error = getMessaggeError(err);

      dispatch(getRatingAgreementError(error));
      throw new Error(error.message || undefined);
    }
  };

export const getUserHistoryRatingDataThunk =
  (idUser: number) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { aboutRating } = await getUserHistoryRatingDataService(idUser);
      dispatch(
        getUserHistoryRatingDataSuccess(userHistoryRatingAdapter(aboutRating))
      );
    } catch (err) {
      const error = getMessaggeError(err);

      dispatch(getRatingAgreementError(error));
      throw new Error(error.message || undefined);
    }
  };
