import {
  CustomerListEstimatedWeightRequestDTO,
  CustomerListTypeLoadRequestDTO,
  CustomerListTypeRequestDTO,
} from '@/interfaces/customerRequestInterface';
import { ISolicitudNegociacionNegList } from '@/models/customerRequest/RequestNegListDataResponse';
import {
  CustomerRequest,
  ICustumerRequestPhotos,
  ITiposAmbientes,
  SolicitudCreadaData,
} from '@/models/customerRequest/customerRequestResponse';
import {
  EstimatedWeightAPI,
  TypeRequestLoadAPI,
} from '@/models/customerRequest/customerRequestTypes';
import { RequestConditions } from '@/models/customerRequest/customerRequestTypesConditions';
import { ISolicitudViewDetailsResponse } from '@/models/customerRequest/requestDetailsVewResponse';
import { ISolicitudeFilterData } from '@/models/customerRequest/requestListFilterDataResponse';
import { TypeVehicleAPI } from '@/models/vehicle/vehicleModel';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IMyRequestListData,
  setMyRequestListDataFilter,
} from '../../models/customerRequest/MyRequestListDataResponse';
import { SelectCity } from '../locationRedux/selectLocation';
import { PhotosPreLoaded } from '../offertRedux/offert.interface';

interface ImagesSave {
  blob: string;
  name: string;
}

export interface ISaveRequestOriginOrDestiny {
  reference: string;
  idInmueble: number;
  piso: string | null;
  flgAscensor: string | null;
}

export interface multiSelectDestiny {
  iddistrito: number;
  idzona: number;
}

export interface setCustomerRequestDataFilterProps {
  solicitudes: ISolicitudeFilterData[];
  currentPage: number;
  totalItems: number;
  totalPages: number;
  flgHistorial?: number;
}

export interface InitialStateCustomerRequest {
  isLoading: boolean;
  isLoadingPagination: boolean;
  error: any;
  errorCode: number;
  listCustomerTypeRequest: CustomerListTypeRequestDTO[];
  listCustomerTypeLoadRequest: CustomerListTypeLoadRequestDTO[];
  listCustomerEstimatedWeightRequest: CustomerListEstimatedWeightRequestDTO[];
  lisConditions: RequestConditions[];
  selectedTypeRequest: CustomerListTypeRequestDTO;
  originReference: string;
  destinyReference: string;
  selectedCity: SelectCity;
  selectedOrigin: SelectCity;
  selectedDestiny: SelectCity;
  dateServices: string;
  savedData: CustomerRequest | null;
  echoResponse: SolicitudCreadaData | {};
  images: ImagesSave[];
  descriptionLoad: string;
  isRefrigerate: boolean;
  weightOfTheLoad: number;
  selectedTypevehicle: TypeVehicleAPI;
  selectedTypeLoadRequest: TypeRequestLoadAPI;
  selectedEstimatedWeightRequest: EstimatedWeightAPI;
  idVerifiedImages: number;
  errorImage: string;
  fotos?: ICustumerRequestPhotos[];
  //

  selectedCityDestiny: SelectCity;
  selectedDistrictDestiny: SelectCity;

  multiSelectDestiny: multiSelectDestiny[] | any[];
  //

  // moving
  flgCiudadoEntreciudades: number; // 1 = ciudad, 2 = entre ciudades
  ambientes: ITiposAmbientes[]; // ambientes
  metraje: string; // metraje
  // origen
  idInmueble: number;
  piso: string | null;
  flgAscensor: string | null;
  // destino
  idInmuebleDestino: number;
  pisoDestino: string | null;
  flgAscensorDestino: string | null;
  // filter
  solicitudes: ISolicitudeFilterData[];
  currentPage: number;
  totalItems: number;
  totalPages: number;
  activeButton: boolean;
  activeSnackbar: boolean;
  amountNegotations: number;
  // offert
  negSolicitudList: ISolicitudNegociacionNegList | {};
  numAbiertas: number;
  numRechazadas: number;
  flgHistorial: number;
  photosPreLoaded: PhotosPreLoaded[];
  // new solicitud view details
  requestDetailsView: ISolicitudViewDetailsResponse | null;
  myRequestListData: IMyRequestListData[];
}

export const emptyDefaultObject = {
  id: 0,
  nombre: '',
};

export const emptyDefaultEstimatedWeightObject = {
  id: 0,
  peso: '',
};

export const emptySelectedTypeRequest = {
  id: 0,
  nombre: '',
  descripcion: '',
};

const initialState: InitialStateCustomerRequest = {
  isLoading: false,
  isLoadingPagination: false,
  error: '',
  errorCode: 0,
  listCustomerTypeRequest: [],
  listCustomerTypeLoadRequest: [],
  listCustomerEstimatedWeightRequest: [],
  lisConditions: [], // falta una "T" en el nombre
  originReference: '',
  destinyReference: '',
  dateServices: '',
  images: [],
  savedData: null,
  echoResponse: {},
  descriptionLoad: '',
  isRefrigerate: false,
  weightOfTheLoad: 0,
  idVerifiedImages: 0,
  errorImage: '',
  fotos: [],
  selectedTypeRequest: emptySelectedTypeRequest,
  selectedCity: emptyDefaultObject,
  selectedOrigin: emptyDefaultObject,
  selectedDestiny: emptyDefaultObject,
  selectedTypevehicle: emptyDefaultObject,
  selectedTypeLoadRequest: emptyDefaultObject,
  selectedEstimatedWeightRequest: emptyDefaultEstimatedWeightObject,
  selectedCityDestiny: emptyDefaultObject,
  selectedDistrictDestiny: emptyDefaultObject,
  multiSelectDestiny: [],

  // moving
  flgCiudadoEntreciudades: 0, // 1 = ciudad, 2 = entre ciudades
  ambientes: [],
  metraje: '',
  // origen
  idInmueble: 0,
  piso: '',
  flgAscensor: '',
  // destino
  idInmuebleDestino: 0,
  pisoDestino: '',
  flgAscensorDestino: '',
  // filter
  solicitudes: [],
  currentPage: 0,
  totalItems: 0,
  totalPages: 0,
  activeButton: false,
  activeSnackbar: false,
  amountNegotations: 0,
  negSolicitudList: [],
  numAbiertas: 0,
  numRechazadas: 0,
  flgHistorial: 0,
  photosPreLoaded: [],
  // new solicitud view details
  requestDetailsView: null,
  myRequestListData: [],
};

export const customerRequestSlice = createSlice({
  name: 'requestClient',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (
      state,
      { payload }: PayloadAction<{ message: string; status: number }>
    ) => {
      state.error = payload.message;
      state.errorCode = payload.status;
      state.isLoading = false;
    },
    resetError: (state) => {
      state.error = '';
      state.errorCode = 0;
    },
    getlistCustomerTypeRequest: (
      state,
      { payload }: PayloadAction<CustomerListTypeRequestDTO[]>
    ) => {
      state.listCustomerTypeRequest = payload;
      state.isLoading = false;
      state.error = '';
      state.errorCode = 0;
    },
    getlistCustomerTypeLoadRequest: (
      state,
      { payload }: PayloadAction<CustomerListTypeLoadRequestDTO[]>
    ) => {
      state.listCustomerTypeLoadRequest = payload;
      state.isLoading = false;
      state.error = '';
      state.errorCode = 0;
    },
    getlistCustomerEstimatedWeightRequest: (
      state,
      { payload }: PayloadAction<CustomerListEstimatedWeightRequestDTO[]>
    ) => {
      state.listCustomerEstimatedWeightRequest = payload;
      state.isLoading = false;
      state.error = '';
      state.errorCode = 0;
    },
    setSelectTypeRequest: (
      state,
      { payload }: PayloadAction<CustomerListTypeRequestDTO>
    ) => {
      state.selectedTypeRequest = payload;
    },

    setSelectCity: (state, { payload }: PayloadAction<SelectCity>) => {
      state.selectedCity = payload;
    },
    setSelectOrigin: (state, { payload }: PayloadAction<SelectCity>) => {
      state.selectedOrigin = payload;
    },
    setSelectDestiny: (state, { payload }: PayloadAction<SelectCity>) => {
      state.selectedDestiny = payload;
    },
    setDateService: (state, { payload }: PayloadAction<string>) => {
      state.dateServices = payload;
    },
    setOriginReference: (state, { payload }: PayloadAction<string>) => {
      state.originReference = payload;
    },
    setDetinyReference: (state, { payload }: PayloadAction<string>) => {
      state.destinyReference = payload;
    },
    setSelectTypevehicle: (
      state,
      { payload }: PayloadAction<TypeVehicleAPI>
    ) => {
      state.selectedTypevehicle = payload;
    },
    setSelectedTypeLoadRequest: (
      state,
      { payload }: PayloadAction<TypeRequestLoadAPI>
    ) => {
      state.selectedTypeLoadRequest = payload;
    },
    setSelectedEstimatedWeightRequest: (
      state,
      { payload }: PayloadAction<EstimatedWeightAPI>
    ) => {
      state.selectedEstimatedWeightRequest = payload;
    },
    setWeightOfTheLoad: (state, { payload }: PayloadAction<number>) => {
      state.weightOfTheLoad = payload;
    },
    setIsRefrigerate: (state, { payload }: PayloadAction<boolean>) => {
      state.isRefrigerate = payload;
    },
    setDescriptionLoad: (state, { payload }: PayloadAction<string>) => {
      state.descriptionLoad = payload;
    },
    setImages: (state, { payload }: PayloadAction<ImagesSave[]>) => {
      state.images = payload;
    },
    setLisConditions: (
      state,
      { payload }: PayloadAction<RequestConditions[]>
    ) => {
      state.lisConditions = payload;
      state.isLoading = false;
      state.error = '';
      state.errorCode = 0;
    },
    setListAmbientes: (
      state,
      { payload }: PayloadAction<ITiposAmbientes[]>
    ) => {
      state.ambientes = payload;
    },
    setFotos: (state, { payload }: PayloadAction<ICustumerRequestPhotos[]>) => {
      state.fotos = payload;
    },
    setUpdatelistAmbientes: (
      state,
      { payload }: PayloadAction<ITiposAmbientes[]>
    ) => {
      state.ambientes = payload;
    },
    updateCondition: (
      state,
      { payload }: PayloadAction<RequestConditions[]>
    ) => {
      state.lisConditions = payload;
    },
    saveData: (state, { payload }: PayloadAction<CustomerRequest>) => {
      state.savedData = payload;
      state.isLoading = false;
      state.error = '';
      state.errorCode = 0;
    },

    // create customer request successs
    setEchoResponseSuccess: (
      state,
      { payload }: PayloadAction<SolicitudCreadaData>
    ) => {
      state.echoResponse = payload;
      state.isLoading = false;
      state.error = '';
      state.errorCode = 0;
    },

    setResetSavedData: (state) => {
      state.savedData = null;
    },

    saveAmountNegotation: (state, { payload }: PayloadAction<number>) => {
      state.amountNegotations = payload;
    },
    // moving
    setDataMovingOrigin: (
      state,
      { payload }: PayloadAction<ISaveRequestOriginOrDestiny>
    ) => {
      state.idInmueble = payload.idInmueble;
      state.piso = payload.piso;
      state.flgAscensor = payload.flgAscensor;
      state.originReference = payload.reference;
    },
    setDataMovingDestiny: (
      state,
      { payload }: PayloadAction<ISaveRequestOriginOrDestiny>
    ) => {
      state.idInmuebleDestino = payload.idInmueble;
      state.pisoDestino = payload.piso;
      state.flgAscensorDestino = payload.flgAscensor;
      state.destinyReference = payload.reference;
    },
    setActiveButton: (state, { payload }: PayloadAction<boolean>) => {
      state.activeButton = payload;
    },
    setActiveSnackbar: (state, { payload }: PayloadAction<boolean>) => {
      state.activeSnackbar = payload;
    },
    setSelectedCityDestiny: (state, { payload }: PayloadAction<SelectCity>) => {
      state.selectedCityDestiny = payload;
    },
    setSelectedDistrictDestiny: (
      state,
      { payload }: PayloadAction<SelectCity>
    ) => {
      state.selectedDistrictDestiny = payload;
    },

    setMultiSelectDestiny: (
      state,
      { payload }: PayloadAction<multiSelectDestiny[] | any[]>
    ) => {
      state.multiSelectDestiny = payload;
    },

    //

    setFlgCiudadoEntreciudades: (state, { payload }: PayloadAction<number>) => {
      state.flgCiudadoEntreciudades = payload;
    },
    // filtros
    setCustomerDataFilter: (
      state,
      { payload }: PayloadAction<setCustomerRequestDataFilterProps>
    ) => {
      state.currentPage = payload.currentPage;
      state.totalItems = payload.totalItems;
      state.totalPages = payload.totalPages;
      state.solicitudes = payload.solicitudes;
      state.isLoading = false;
      state.isLoadingPagination = false;
    },
    setMyRequestListDataAction: (
      state,
      { payload }: PayloadAction<setMyRequestListDataFilter>
    ) => {
      state.currentPage = payload.currentPage;
      state.totalItems = payload.totalItems;
      state.totalPages = payload.totalPages;
      state.isLoading = false;
      state.isLoadingPagination = false;
      state.myRequestListData = payload.myRequestListData;
    },
    setSolicitudes: (state) => {
      state.solicitudes = [];
    },
    setIsLoadingPagination: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingPagination = payload;
    },
    // offert
    // negSolicitudList
    setNegSolicitudList: (
      state,
      { payload }: PayloadAction<ISolicitudNegociacionNegList>
    ) => {
      // falta data
      state.negSolicitudList = payload;
      state.isLoading = false;
    },
    setNumAbiertas: (state, { payload }: PayloadAction<number>) => {
      state.numAbiertas = payload;
    },
    setMetraje: (state, { payload }: PayloadAction<string>) => {
      state.metraje = payload;
    },
    setNumRechazadas: (state, { payload }: PayloadAction<number>) => {
      state.numRechazadas = payload;
    },
    setIdVerifiedImages: (state, { payload }: PayloadAction<number>) => {
      state.idVerifiedImages = payload;
      state.errorImage = '';
    },
    setErrorImage: (state, { payload }: PayloadAction<string>) => {
      state.errorImage = payload;
    },
    emptyErrorImage: (state) => {
      state.errorImage = '';
    },
    setPhotosPreLoaded: (
      state,
      { payload }: PayloadAction<PhotosPreLoaded[]>
    ) => {
      state.photosPreLoaded = payload;
    },
    setRequestDetailsView: (
      state,
      { payload }: PayloadAction<ISolicitudViewDetailsResponse>
    ) => {
      state.requestDetailsView = payload;
      state.isLoading = false;
      state.error = '';
      state.errorCode = 0;
    },
    resetState: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setError,
  getlistCustomerTypeRequest,
  setSelectTypeRequest,
  resetState,
  setSelectCity,
  setSelectOrigin,
  setSelectDestiny,
  setOriginReference,
  setDetinyReference,
  setDateService,
  setSelectTypevehicle,
  setSelectedTypeLoadRequest,
  setSelectedEstimatedWeightRequest,
  getlistCustomerTypeLoadRequest,
  getlistCustomerEstimatedWeightRequest,
  setWeightOfTheLoad,
  setIsRefrigerate,
  setDescriptionLoad,
  setImages,
  resetError,
  setLisConditions,
  updateCondition,
  saveData,
  setEchoResponseSuccess,
  setResetSavedData,
  // moving
  setDataMovingOrigin,
  setDataMovingDestiny,
  setListAmbientes,
  setUpdatelistAmbientes,
  setFotos,
  //
  emptyErrorImage,
  setErrorImage,
  setIdVerifiedImages,
  setSelectedCityDestiny,
  setSelectedDistrictDestiny,
  setMultiSelectDestiny,
  //
  setMetraje,
  setFlgCiudadoEntreciudades,
  // filtros
  setCustomerDataFilter,
  setIsLoadingPagination,
  setSolicitudes,
  setActiveButton,
  setActiveSnackbar,
  saveAmountNegotation,
  // offert
  setNegSolicitudList,
  setNumAbiertas,
  setNumRechazadas,
  setPhotosPreLoaded,
  setRequestDetailsView,
  setMyRequestListDataAction,
} = customerRequestSlice.actions;
