import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import { useGetRequestData } from "@/pages/requestNegotiation/shared/hooks/useGetRequestData";
import { handleTypeMultidestinyOfferRequest } from "@/pages/requestNegotiation/shared/utils/multidenityPriceUtils";
import { currencyFormat } from "@/utils/helpers";
import { Box, Typography } from "@mui/material";

export const PriceSuccessMultidestiny = () => {
  const { data } = useGetRequestData();
  const newData = handleTypeMultidestinyOfferRequest(
    data as SolicitudNegociacion
  );


  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: "flex" }}>
        <Typography sx={{ fontWeight: 700, fontSize: "22px", mt: 0.5}}>
          Contraoferta: {currencyFormat(data?.precioContraoferta as number)}
        </Typography>
        <Typography sx={{ fontSize: "14px", ml: 1 }}>
          {`Por ${newData.type}`}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
          {`Cantidad por ${newData.type}s:`}
        </Typography>
        <Typography sx={{ fontSize: "16px", ml: 1 }}>
          {`${currencyFormat(newData.amount, false)}`}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
          Precio total:
        </Typography>
        <Typography sx={{ fontSize: "16px", ml: 1 }}>
          {currencyFormat(data?.precioContraoferta as number * Number(newData.amount))}
        </Typography>
      </Box>
    </Box>
  );
};
