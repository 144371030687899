import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { SUCCESS_TEXT_EDIT_PRICE } from "@/consts/textSuccess";
import { PROFILE_NOTICE_LIST_PATH } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { onSaveFlowMovingThunk } from "@/store/includedServices/inclodedServicesThunks";
import { setDirtyPrice } from "@/store/noticeRedux/noticeSlice";
import { editNoticePriceThunk } from "@/store/noticeRedux/noticeThunks";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { setMessageEdit } from "@/store/saveFlow/saveFlowSlice";
import { cleanValueNumber } from "@/utils/helpers";
import { Box, FormControl, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useId, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Input from "@/components/inputCustom/Input";
import { maxLengthPrice, optionFive, optionOne, optionTwo } from "@/consts/noticeConsts";
import {
  DetailNoticeMoving
} from "@/models/notice/noticeModels";
import useDetailNotice from "@/pages/detailNotice/hook";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { priceInfoText } from "@/pages/noticeV2/NoticePlace/placeConfig";
import Icon from "@/utils/Icon";
import SectionOriginDestiny from "../components/SectionOriginDestiny";
import { BoxContainerInfo } from "./BodyFeeInCity";
import { configText } from "./configDataBodyFee";
import { handleReplaceCharacters } from "@/utils/inputsUtils";

const BodyFeeMovingCities = () => {
  const [price, setPrice] = useState("");
  const [assistant, setAssistant] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const {
    notice: { isLoading: isLoadingNotice },
    detailsNoticeSlice: { isLoading },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const { handleGetCurrentDetailNotice } = useDetailNotice();

  const notice: DetailNoticeMoving = useMemo(
    () => handleGetCurrentDetailNotice(),
    [isLoading]
  );

  const navigate = useNavigate();
  const location = useLocation();

  const id = useId();

  useEffect(() => {
    if (!!notice) {
      setPrice(notice.precio?.toString());
      setAssistant(notice?.ayudantes.toString() || "");
      setIsEdit(location.pathname.startsWith(PROFILE_NOTICE_LIST_PATH));
    }
    dispatch(setDirtyPrice(false));
    return () => {
      dispatch(setDirtyPrice(false));
    };
  }, []);

  const handleOnChangeAssistant = ({
    currentTarget,
  }: ChangeEvent<HTMLInputElement>) => {
    const { value } = currentTarget;
    const valor = handleReplaceCharacters(value) 

    if (valor.length > optionFive) {
      return "";
    }
  
    if (valor.length === optionOne) {
      setAssistant(
        valor
      );
    } else if (valor.length === optionTwo && value === "00") {
      setAssistant("0");
    } else {
      setAssistant(
        valor
      );
    }

    dispatch(setDirtyPrice(true));
  };

  const handleOnchange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const { value } = currentTarget;

    if (value.length >= maxLengthPrice) return;

    const clenaValue = cleanValueNumber(value);
    setPrice(clenaValue);

    dispatch(setDirtyPrice(true));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLoading || isLoadingNotice) return;

    let currentPrice = !price.length ? 0 : Number(price);
    const currentAssistant = Number(assistant);
    if (isEdit) {
      dispatch(
        editNoticePriceThunk(notice.id, {
          precio: currentPrice,
          ayudantes: currentAssistant,
        })
      ).then(handleSuccessEdit);
    } else {
      dispatch(onSaveFlowMovingThunk(currentPrice, currentAssistant, 2)).then(
        () => handleNavigate()
      );
    }
  };

  const handleSuccessEdit = () => {
    dispatch(setMessageEdit(SUCCESS_TEXT_EDIT_PRICE));
    navigate(-1);
  };

  const handleNavigate = () => {
    dispatch(getNextRoute(navigate));
  };

  const handleDisabled = () => {
    return (
      !price.length ||
      Number(price) <= 0 ||
      !assistant.length ||
      isLoading ||
      isLoadingNotice
    );
  };

  const textButton = isEdit ? configText.button.edit : configText.button.flow;

  return (
    <>
      <BoxContainerInfo>
        <Icon name="icon-handsShake" size="md" />
        <Typography variant="body2" sx={{ fontSize: "16px" }}>
          {priceInfoText}
        </Typography>
      </BoxContainerInfo>
      
      <Box sx={{ mb: 2 }}>
        <SectionOriginDestiny
          origin={notice?.origentexto || ""}
          destiny={notice?.destinotexto || ""}
        />
      </Box>

      <form onSubmit={handleSubmit}>
        <FormControl sx={{ marginTop: "16px", width: "100%" }}>
          <Input
            name="assistant"
            label={
              <LabelInputRequired str="Número de ayudantes considerados en el servicio" />
            }
            value={assistant}
            variant="standard"
            placeholder="Cantidad de ayudantes que incluye tu precio"
            onChange={handleOnChangeAssistant}
            onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
              required: true,
            }}
          />
        </FormControl>
        <FormControl sx={{ marginTop: "16px", width: "100%" }}>
          <Input
            id={`${id}-price`}
            name="price"
            label={<LabelInputRequired str="Precio (S/)" />}
            value={price}
            variant="standard"
            placeholder="Tu precio"
            onChange={handleOnchange}
            onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
              required: true,
            }}
          />
        </FormControl>

        <ButtomLoader
          type="submit"
          sx={{ marginTop: "24px" }}
          disabled={handleDisabled()}
          isLoading={isLoading || isLoadingNotice}
        >
          {textButton}
        </ButtomLoader>
      </form>
    </>
  );
};

export default BodyFeeMovingCities;
