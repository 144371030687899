import { useAppSelector } from '@/hooks/useReducer';
import BodySuccessMovingCities from './BodySuccessMovingCities';
import BodySuccessMultiMoving from './BodySuccessMultiMoving';
import { IAvisoCreadoData } from '@/services/noticeServices/noticeSendData/noticeSendDataTypesResponse';

const BodySuccessBetweenMoving = () => {
    const {
        echoResponse,
      } = useAppSelector((state) => state.noticeV2);
    
      const isMulti = (echoResponse as IAvisoCreadoData)?.avisoscreados.length > 1;
    return isMulti ? (
        <BodySuccessMultiMoving createdData={(echoResponse as IAvisoCreadoData)?.avisoscreados} />
    ) : (
        <BodySuccessMovingCities createdData={(echoResponse as IAvisoCreadoData).avisoscreados}/>
    )
}

export default BodySuccessBetweenMoving