import {
  UserSharedRide,
  VehicleSharedRide,
} from "@/models/shareTravel/shareTravelModel";
import { Card, styled } from "@mui/material";
import React from "react";
import CardSectionBody from "./CardSectionBody";
import CardSectionHeader from "./CardSectionHeader";

const CardContainer = styled(Card)(
  () => `
    position: absolute;
    top: 25px;
    left: 25px;
    border-radius: 8px;
    padding: 0;
    background-color: white;
    @media only screen and (max-width: 431px) {
      width: 100%;
      left: 0;
      top: unset;
      bottom: -35px;
    }
  `
);

type Props = {
  user: UserSharedRide;
  vehicle?: VehicleSharedRide;
  originAddress: string;
  destinyAddress: string;
};

const SharedRideFloatCard: React.FC<Props> = ({
  user,
  vehicle,
  originAddress,
  destinyAddress,
}) => {
  if (!user) return <></>;

  return (
    <CardContainer>
      <CardSectionHeader user={user} vehicle={vehicle} />

      <CardSectionBody
        destinyAddress={destinyAddress}
        originAddress={originAddress}
      />
    </CardContainer>
  );
};

export default SharedRideFloatCard;
