import {
  NEGOTATION_BETWEEN_CITIES,
  NEGOTATION_EXPRESS,
  NEGOTATION_IN_CITY,
  NEGOTATION_MOVING,
  NEGOTATION_MULTI_DESTINY,
} from '@/consts/negotationFlowPath';
import { noticeNegRequestPathTypes } from '../interface/noticeNegRequestInterface';

export const noticeAcceptNegRequestTypesArea: noticeNegRequestPathTypes = {
  1: {
    title: 'Elige tu vehículo',
    subTitle: 'Aceptar',
    icon: 'icon-truck',
  },
  2: {
    title: 'Elige tu vehículo',
    subTitle: 'Aceptar',
    icon: 'icon-truck',
  },
  3: {
    title: 'Elige tu vehículo',
    subTitle: 'Aceptar',
    icon: 'icon-truck',
  },
  4: {
    title: 'Ofrece tu precio',
    subTitle: '',
    icon: 'icon-money',
  },
  5: {
    title: 'Cotizar',
    subTitle: '',
    icon: 'icon-money',
  },
};

export const noticeCounterOfferNegRequestTypesArea: noticeNegRequestPathTypes =
  {
    1: {
      title: 'Vehículo y precio',
      subTitle: 'Contraofertar',
      icon: 'icon-money',
    },
    2: {
      title: 'Vehículo y precio',
      subTitle: 'Contraofertar',
      icon: 'icon-money',
    },
    3: {
      title: 'Vehículo y precio',
      subTitle: 'Contraofertar',
      icon: 'icon-money',
    },
    4: {
      title: 'Vehículo y precio',
      subTitle: 'Contraofertar',
      icon: 'icon-money',
    },
    5: {
      title: 'Cotizar',
      subTitle: '',
      icon: 'icon-money',
    },
    7: {
      title: 'Contraoferta',
      subTitle: '',
      icon: 'icon-money',
    },
  };

export const noticeOffertNegRequestTypesArea: noticeNegRequestPathTypes = {
  4: {
    title: 'Ofrece tu precio',
    subTitle: '',
    icon: 'icon-money',
  },
};

export const noticeNegotationRequestObjectTypes: Record<number, string> = {
  1: NEGOTATION_IN_CITY,
  2: NEGOTATION_MULTI_DESTINY,
  3: NEGOTATION_BETWEEN_CITIES,
  4: NEGOTATION_EXPRESS,
  5: NEGOTATION_MOVING,
};

export const defaultError: { message: string; status: number } = {
  message: '',
  status: 0,
};
