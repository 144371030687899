import { VEHICLE_EDIT, VEHICLE_LIST, VEHICLE_REGISTER } from "@/consts/pathServices";
import { NoticeResponseAPI } from "@/models/notice/noticeModels";
import { ResponseTypeVehicleAPI, ResponseVehicleAPI, VehicleRegister } from "@/models/vehicle/vehicleModel";
import { connection, instanceAuth } from "@/services/connection";
import { HEADERS_MULTIPART } from "./utils/consts";
import { setFormDataByObject } from "./utils/utils";

const API = connection(instanceAuth);

export const getVehicleListServices = async (viewhand?: 0 | 1): Promise<ResponseTypeVehicleAPI> => {
  let path = VEHICLE_LIST;
  if (viewhand) {
    path = `${path}?viewhand=${viewhand}`;
  }
  const { data } = await API.get(path);

  return data;
};

export const registerVehicleServices = async (vehicle: VehicleRegister): Promise<ResponseVehicleAPI> => {
  const formData = setFormDataByObject(vehicle);
  const headers = HEADERS_MULTIPART;
  const { data } = await API.post(VEHICLE_REGISTER, formData, { headers });

  return data;
};

export type EditVehicleServicesProps = {
  idvehiculo: number;
  pesomaximo: number;
  flgcargarefrigerada: number;
};
export const editVehicleServices = async (idNotice: number, body: EditVehicleServicesProps): Promise<NoticeResponseAPI> => {
  const API = connection();
  const path = VEHICLE_EDIT.replace(":id", idNotice.toString());
  const { data } = await API.put(path, body);

  return data;
};
