import { FormControl, Grid, Typography } from "@mui/material";

import { AddNewDestiny } from "../../../components/AddNewDestiny";
import { CardItem, HeaderBox } from "../../../components/CardItem";
import ButtomLoader from "../../../components/buttonLoader/ButtomLoader";

import ModalFilter from "@/components/Filter/components/ModalFilter/ModalFilter";
import DynamicComponent from "@/components/dinamycComponent/DinamycComponent";
import { CiudadSugeridoData } from "@/services/noticeServices/noticeListSuggested/noticeListSuggestedTypes";
import { IAvisodestinoBetween } from "@/services/noticeServices/noticeSendData/noticeSendDataTypes";
import { configBetweenCities } from "../../components/inputsConfig/inputsBetweenCities";
import { ContainerGrid } from "../../noticePlaceStyles";
import { usePlaceBetweenCitiesHook } from "./hook";
import { metaDataModalBetweenCities } from "../hook";

const PlaceBetweenCities = () => {
  const {
    handleInitConfig,
    showModal,
    handleOnCloseModal,
    handleSubmitModal,
    handleOnSeletedModal,
    handleFilterByTerm,
    modalActiveWith,
    listLocation,
    citiesSuggested,
    handleInputsSuggested,
    DeleteSuggestedListItem,
    data,
    onSubmit,
    validateForm,
    isLoading,
    validatePrecioOfDestinos,
  } = usePlaceBetweenCitiesHook()

  return (
    <ContainerGrid container>

      <ModalFilter
        open={showModal}
        onClose={handleOnCloseModal}
        onSubmit={handleSubmitModal}
        onSeleted={handleOnSeletedModal}
        onInputChange={handleFilterByTerm}
        modalActiveWith={modalActiveWith.name}
        list={listLocation}
        betweenDate={""}
        prevSelected={""}
        nameTwo={true}
        metaDataModal={metaDataModalBetweenCities}
      />

      <FormControl sx={{
        gap: "16px",
      }}>
        {configBetweenCities.map((config) => config.map((items) => items.columns.map((column) => (
          <DynamicComponent
            {...handleInitConfig(column)}
          />
        ))))}

      </FormControl>
      <HeaderBox />
      {(citiesSuggested && (citiesSuggested as CiudadSugeridoData)?.sugeridos) && (citiesSuggested as CiudadSugeridoData)?.sugeridos.map((item) => (
        <CardItem
          nombre={item.nombre}
          id={item.id}
          precio={data.avisodestinos?.find((cities: IAvisodestinoBetween) => cities.idciudad === item.id)?.precio.toString() || ""}
          changeInput={handleInputsSuggested}
          onClick={DeleteSuggestedListItem}
          error={validatePrecioOfDestinos(item.id).valid}
          errorMsg={validatePrecioOfDestinos(item.id).message}
          maxLength={6}
        />
      ))
      }
      {
        (citiesSuggested && (citiesSuggested as CiudadSugeridoData)?.sugeridos) ?
          <AddNewDestiny title="Agregar ciudad" />
          : <Typography variant="body2">
            Elige un distrito de origen para sugerirte destinos.
          </Typography>
      }

      <Grid item sx={{ marginTop: "16px" }}>
        <ButtomLoader
          onClick={onSubmit}
          buttomProps={{
            disabled: !validateForm() || isLoading,
          }}
          isLoading={isLoading}
        >
          CREAR AVISO
        </ButtomLoader>
      </Grid>
    </ContainerGrid>
  );
}

export default PlaceBetweenCities