type Input = {
  label: string;
  placeholder: string;
};

interface Multidestiny {
  firstInput: Input;
  secondInput: Input;
}

export const EMPTY_MULTIDESTINY: Multidestiny = {
  firstInput: {
    label: '',
    placeholder: '',
  },
  secondInput: {
    label: '',
    placeholder: '',
  },
};

export const HOUR_MULTIDESTINY: Multidestiny = {
  firstInput: {
    label: 'Número de horas requeridas',
    placeholder: 'Cantidad de horas, Ej. 5',
  },
  secondInput: {
    label: 'Precio por hora (S/)',
    placeholder: 'Lo que pagarías por hora',
  },
};

export const POINT_MULTIDESTINY: Multidestiny = {
  firstInput: {
    label: 'Número de puntos de entrega',
    placeholder: 'Cantidad de puntos de entrega',
  },
  secondInput: {
    label: 'Precio por punto de entrega (S/)',
    placeholder: 'Lo que pagarías por punto o parada',
  },
};

export const serviceText = 'Modalidad del servicio';
export const priceText = 'Precio que ofreces';
