import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import HeaderSection from "@/components/headerSection/HeaderSection";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import LabelRequired from "@/components/utils/LabelRequired";
import { CLIENT_TYPE, DRIVER_TYPE, ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import { maxLengthPrice, textCannotLowerPrice } from "@/consts/noticeConsts";
import { EDIT_COUNTEROFFER_SUCCESS_MESSAGE } from "@/consts/offertPath";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { UsuarioAPI } from "@/models/userAuth/user";
import { ContainerArea } from "@/pages/createServices/suggestions/styleSuggestions";
import { BodyUploadPriceNegotationPrice } from "@/services/noticeNegotationRequestServices";
import { IOffertInitialState } from "@/store/offertRedux/offert.interface";
import { updatePriceOfferNoticeThunk } from "@/store/offertRedux/offert.thunk";
import { theme } from "@/styles/theme";
import { validateValueInput } from "@/utils/helpers";
import { Container, FormControl, TextField, TextFieldProps, Typography, styled, experimental_sx as sx } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { textNegativePrice } from "../../../consts/noticeConsts";

const StyleCustomInput = styled(TextField)<TextFieldProps>(
  sx({
    width: "100%",
    "& .MuiFormLabel-root": {
      fontFamily: theme.typography.label3.fontFamily,
      fontSize: "16px",
      fontWeight: theme.typography.label3.fontWeight,
      lineHeight: theme.typography.label3.lineHeight,
      color: "#707070",
    },
    "& .MuiInputBase-input::placeholder": {
      fontFamily: theme.typography.placeholder.fontFamily,
      fontStyle: theme.typography.placeholder.fontStyle,
      fontSize: theme.typography.placeholder.fontSize,
      lineHeight: theme.typography.placeholder.lineHeight,
      fontWeight: theme.typography.placeholder.fontWeight,
      letterSpacing: theme.typography.placeholder.letterSpacing,
    },
    "& .MuiFormHelperText-root": {
      letterSpacing: 0,
    },
  })
);

const EditPriceCounterOffer = () => {
  const { noticeId = "" } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    user: { user },
    offert,
  } = useAppSelector((state) => state);
  const typeUser = (user  as UsuarioAPI).tipousuario
  const isLoading = offert.isLoading;
  const [errorPrice, setErrorPrice] = useState("");
  const [price, setPrice] = useState("");
  const isClient = typeUser === CLIENT_TYPE;
  const isMultidestiny = offert.savedOffert?.avisoNegociacionAviso?.tipoaviso?.id as number === ID_MULTI_DESTINY;
  
  
  useEffect(() => {
    if (isClient) {
      setPrice(`${(offert as IOffertInitialState)?.savedOffert?.precio as number}`);
    } else if (typeUser === DRIVER_TYPE) {
      setPrice(`${(offert as IOffertInitialState)?.savedOffert?.preciocontraoferta as number}`);
    }

    return () => {};
  }, []);

  const handleGoBackPage = () => {
    navigate(-1);
  };

  const handleChangePrice = ({ target }: EventType["change"]) => {
    const { value } = target;
    setErrorPrice("");
    const newValue = validateValueInput(value).replace("-", "").replace(/^[a-zA-Z]*$/, "")

    if(value.length <= maxLengthPrice){
       setPrice(newValue);
    }
   
  };

  const handleCompareInputWithDB = () => {
    const precio = (offert as IOffertInitialState)?.savedOffert?.precio;
    const preciocontraoferta = (offert as IOffertInitialState)?.savedOffert?.preciocontraoferta;
    const priceDB = typeUser === CLIENT_TYPE ? precio : preciocontraoferta;
    return price === `${priceDB}`;
  };

  const handleDisabledEditCounterOfferButton = () => {
    const conditions = [price];

    if (handleCompareInputWithDB()) return true;

    return conditions.some((condition) => !condition);
  };

  const handleEditCounterOfferSubmit = (event: EventType["submit"]) => {
    const initialPrice = offert.savedOffert?.avisoNegociacionAviso?.precio as number
    event.preventDefault();
    if (isLoading) return;

   
    if (  
      Number(price) < 0 &&
      !isMultidestiny
    ) {
      setErrorPrice(textNegativePrice);
      return;
    }

    if (
      Number(price) < Number(initialPrice) / 2 
    ) {
      setErrorPrice( textCannotLowerPrice.replace(":price", `${initialPrice / 2}`)
      );
      return;
    }


     const body: BodyUploadPriceNegotationPrice = { precio: Number(price) };
    const pathClient = EDIT_COUNTEROFFER_SUCCESS_MESSAGE.replace(":typeOffer", "contraoferta").replace(":noticeId", noticeId);
    const pathDriver = EDIT_COUNTEROFFER_SUCCESS_MESSAGE.replace(":typeOffer", "contraoferta").replace(":noticeId", noticeId); // si por alguna razon se debe cambiar la url solo hay que cambiar por separado sin afectar nada
    const path = isClient ? pathClient : pathDriver;
    dispatch(updatePriceOfferNoticeThunk(noticeId, body)).then(() => navigate(path, { replace: true })); 
  };

  const changePriceTheService = () => {
    let priceTotal: number | string = `S/ ${Number(price) * Number(offert.savedOffert?.horas)}`;

    if (!!offert.savedOffert?.puntos as boolean) {
      priceTotal = `S/ ${Number(price) * Number(offert.savedOffert?.puntos)}`;
    }
    if (!price) priceTotal = "-";

    return priceTotal;
  };

  const textByTypePrice = () => {
    return (!!offert.savedOffert?.puntos as boolean)
      ? {
          amount: `<strong>Cantidad de puntos:</strong> ${offert.savedOffert?.puntos}`,
          label: "Precio por punto que ofreces",
        }
      : {
          amount: `<strong>Cantidad de horas:</strong> ${offert.savedOffert?.horas}`,
          label: "Precio por hora que ofreces",
        };
  };

  const textLabelToRender = () => {
    let text = "Precio ofrecido (S/)";

    if (isMultidestiny) {
      text = textByTypePrice().label;
    }
    return text;
  };

  return (
    <Container maxWidth="xs">
      <HeaderSection icon="icon-arrow-left--primary" title="Editar Contraoferta" subTitle="" onClick={handleGoBackPage} />
      <ContainerArea>
        <form>
          <SectionIcon icon="icon-money" />
          {isMultidestiny && (
            <Typography variant="body2" color="black">
              <div dangerouslySetInnerHTML={{ __html: textByTypePrice().amount }} />
            </Typography>
          )}
          <FormControl sx={{ marginTop: "16px" }} fullWidth>
            <StyleCustomInput
              name="price"
              label={<LabelRequired str={textLabelToRender()} />}
              sx={{ marginBottom: "16px" }}
              variant="standard"
              value={price}
              placeholder="Precio que ofreces"
              type="text"
              onChange={handleChangePrice}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errorPrice}
              helperText={errorPrice}

            />
          </FormControl>

          {isMultidestiny && (
            <Typography
              variant="body2"
              color="black"
              sx={{
                marginTop: "16px",
                marginBottom: "16px",
              }}
            >
              <strong>Precio del servicio:</strong>&nbsp;
              {changePriceTheService()}
            </Typography>
          )}

          <FormControl fullWidth>
            <ButtomLoader
              onClick={handleEditCounterOfferSubmit}
              isLoading={isLoading}
              disabled={handleDisabledEditCounterOfferButton() || isLoading}
            >
              ENVIAR
            </ButtomLoader>
          </FormControl>
        </form>
      </ContainerArea>
    </Container>
  );
};

export default EditPriceCounterOffer;
