import {
  DetailNoticeMoving,
  DetailsCoreModel,
  NoticeDetailMultiDestinyModel,
} from "@/models/notice/noticeModels";
import { FC } from "react";
import { PriceContainer, TextPrice, TitlePrice } from "./stylesPrice";
import { currencyFormat } from "@/utils/helpers";
import {
  ID_BETWEEN_CITIES,
  ID_IN_CITY,
  ID_MOVING_FLOW,
  ID_MULTI_DESTINY,
} from "@/consts/defaultConsts";

interface Props {
  data: NoticeDetailMultiDestinyModel | DetailsCoreModel | DetailNoticeMoving;
}

const Price: FC<Props> = (props: Props) => {
  let { data } = props;

  const priceTitle = () => {
    
    if (data.tipoaviso.id === ID_IN_CITY) {
      return "Por viaje";
    }
    if (data.tipoaviso.id === ID_BETWEEN_CITIES) {
      return "Por viaje";
    }
    if (data.tipoaviso.id === ID_MULTI_DESTINY) {
      const newData = data as NoticeDetailMultiDestinyModel;
      if (newData?.precioxpunto) {
        return `Por punto | Mín. ${newData?.puntosminimo} puntos`;
      }
      if (newData?.precioxhora) {
        return `Por hora | Mín. ${newData?.horasminimo} horas`;
      }
    }
    if (data.tipoaviso.id === ID_MOVING_FLOW) {
      const newData = data as DetailNoticeMoving;
      return `Por viaje + ${newData.ayudantes} ayudantes`;
    }
    return "";
  };

  const price = () => {
    const newData = data as NoticeDetailMultiDestinyModel;
    let price = newData.precio;
    if (newData.tipoaviso.id === ID_MULTI_DESTINY) {
      if (newData.precioxpunto) {
        price = newData.precioxpunto;
      } else if (newData.precioxhora) {
        price = newData.precioxhora;
      }
    }
    return currencyFormat(price);
  };

  if (!data) return <></>;

  return (
    <PriceContainer>
      <TextPrice>{price()}</TextPrice>
      <TitlePrice>{priceTitle()}</TitlePrice>
    </PriceContainer>
  );
};

export default Price;
