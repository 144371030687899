
interface Props {
  style: {
    color?: string
    height: string
    width: string
  }
}

const MoneyIcon = (props: Props) => {

  const { color = "#757575", height, width } = props?.style;

  // convertir a un componente que recibe parametros
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_30232_25768)">
        <mask id="mask0_30232_25768" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
          <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_30232_25768)">
          <path d="M4.8077 18.5C4.30257 18.5 3.875 18.325 3.525 17.975C3.175 17.625 3 17.1974 3 16.6923V7.3077C3 6.80257 3.175 6.375 3.525 6.025C3.875 5.675 4.30257 5.5 4.8077 5.5H20.1923C20.6974 5.5 21.125 5.675 21.475 6.025C21.825 6.375 22 6.80257 22 7.3077V16.6923C22 17.1974 21.825 17.625 21.475 17.975C21.125 18.325 20.6974 18.5 20.1923 18.5H4.8077ZM4.8077 17H20.1923C20.2692 17 20.3397 16.9679 20.4038 16.9038C20.4679 16.8397 20.5 16.7692 20.5 16.6923V7.3077C20.5 7.23077 20.4679 7.16024 20.4038 7.09613C20.3397 7.03203 20.2692 6.99998 20.1923 6.99998H4.8077C4.73077 6.99998 4.66024 7.03203 4.59613 7.09613C4.53202 7.16024 4.49998 7.23077 4.49998 7.3077V16.6923C4.49998 16.7692 4.53202 16.8397 4.59613 16.9038C4.66024 16.9679 4.73077 17 4.8077 17ZM15.6538 15.8461H19.3461V12.1538H18.1538V14.6538H15.6538V15.8461ZM12.5 14.6923C13.2436 14.6923 13.8782 14.4294 14.4038 13.9038C14.9294 13.3782 15.1923 12.7436 15.1923 12C15.1923 11.2564 14.9294 10.6218 14.4038 10.0962C13.8782 9.57052 13.2436 9.3077 12.5 9.3077C11.7564 9.3077 11.1218 9.57052 10.5961 10.0962C10.0705 10.6218 9.8077 11.2564 9.8077 12C9.8077 12.7436 10.0705 13.3782 10.5961 13.9038C11.1218 14.4294 11.7564 14.6923 12.5 14.6923ZM5.65383 11.8461H6.84613V9.34613H9.34613V8.15383H5.65383V11.8461Z" fill={color} />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_30232_25768">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MoneyIcon
