import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import { StyleCustomInput } from "@/components/inputCustom/InputCustom";
import LabelRequired from "@/components/utils/LabelRequired";
import SelectCar from "@/pages/createRequestNegotiation/components/SelectCar";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { FormControl, Grid, styled } from "@mui/material";
import { useFormCounterOfferMoving } from "./useFormCounterOfferMoving";

const Container = styled(Grid)(
  () => `
    display: flex;
    flex-direction: column;  
    margin-top: 24px;
  `
);

interface LocationState {
  editNegotation?: boolean;
}

const FormCounterOfferMoving = () => {
  const {
    errorPrice,
    price,
    isLoading,
    idRequest,
    state,
    isEdition,
    isCounterOffer,
    assistant,
    handleSubmit,
    handleDisabledButton,
    handleOnchangeOne,
    handleOnchangeTwo,
    handleDisabledEditCounterOfferButton,
    handleEditCounterOfferSubmit,
  } = useFormCounterOfferMoving();

  if (isEdition || !!(state as LocationState)?.editNegotation)
    return (
      <Container container>
        <form onSubmit={handleEditCounterOfferSubmit}>
          <FormControl sx={{ marginTop: 1, mb: 2, width: "100%" }}>
            <Input
              name="price"
              label={
                <LabelRequired str={`N° de ayudantes que incluye tu precio `} />
              }
              value={assistant}
              variant="standard"
              placeholder={`Ayudantes que necesites, Ej. 1`}
              onChange={handleOnchangeOne}
              onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                inputMode: "numeric",
              }}
            />
          </FormControl>

          <FormControl sx={{ marginTop: 1, mb: 2, width: "100%" }}>
            <Input
              id={`$1-price`}
              name="price"
              label={
                <LabelRequired
                  str={`Precio ofrecido por viaje + ayudantes (S/)`}
                />
              }
              value={price}
              variant="standard"
              placeholder={`Ingresa tu precio`}
              onChange={handleOnchangeTwo}
              onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                inputMode: "numeric",
              }}
            />
          </FormControl>

          <Grid item sx={{ marginTop: "16px" }}>
            <ButtomLoader
              isLoading={isLoading}
              type="submit"
              disabled={handleDisabledEditCounterOfferButton() || isLoading}
            >
              GUARDAR
            </ButtomLoader>
          </Grid>
        </form>
      </Container>
    );

  return (
    <Container container>
      <form onSubmit={handleSubmit}>
        <FormControl sx={{ width: "100%", mb: 2 }}>
          <SelectCar idAvisoOrSolicitud={Number(idRequest)} />
        </FormControl>

        <FormControl sx={{ marginTop: 1, mb: 2, width: "100%" }}>
          <StyleCustomInput
            id={`$1-price`}
            name="price"
            label={
              <LabelInputRequired
                str={`N° de ayudantes que incluye tu precio `}
              />
            }
            value={assistant}
            variant="standard"
            placeholder={`Ayudantes que necesites, Ej. 1`}
            onChange={handleOnchangeOne}
            onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
            }}
          />
        </FormControl>

        <FormControl sx={{ marginTop: 1, mb: 2, width: "100%" }}>
          <StyleCustomInput
            id={`$1-price`}
            name="price"
            label={
              <LabelRequired
                str={`Precio ofrecido por viaje + ayudantes (S/)`}
              />
            }
            value={price}
            variant="standard"
            placeholder={`Ingresa tu precio`}
            onChange={handleOnchangeTwo}
            onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
            }}
            error={!!errorPrice}
            helperText={errorPrice}
          />
        </FormControl>

        <Grid item sx={{ marginTop: "16px" }}>
          <ButtomLoader
            isLoading={isLoading}
            type="submit"
            disabled={handleDisabledButton() || isLoading}
          >
            {isCounterOffer ? "CONTRAOFERTAR" : "SIGUIENTE"}
          </ButtomLoader>
        </Grid>
      </form>
    </Container>
  );
};

export default FormCounterOfferMoving;
