import { Card, styled } from '@mui/material';

export const CustomCard = styled(Card)(() => ({
  maxHeight: '334px',
  borderRadius: '8px',
  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.15)',
  cursor: 'pointer',
  '&:hover': {
    filter: 'brightness(90%)',
    transition: 'ease-in all .2s',
    '.MuiCardMedia-root': {
      opacity: 0.9,
    },
  },
}));
