import { actionsToNegotation } from "@/consts/actions";
import { CLIENT_TYPE } from "@/consts/defaultConsts";
import {
    NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_CLIENT,
    NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_DRIVER,
} from "@/consts/negotationFlowPath";
import {
    textCannotLowerPrice,
    textCannotUpperPrice,
} from "@/consts/noticeConsts";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import { counterOfferClientAdapter } from "@/pages/noticeRequestFlow/adapters/noticeRequestFlowAdapters";
import { defaultError } from "@/pages/noticeRequestFlow/consts/noticeRequestFlowConsts";
import { useButtonsFunctions } from "@/pages/noticeRequestFlow/hooks/useHandleGlobalButtons";
import { typeNegotation } from "@/pages/requestNegotiation/components/globalButtons/config";
import { useGetRequestData } from "@/pages/requestNegotiation/shared/hooks/useGetRequestData";

import { cocounterRequest } from "@/services/dataLayer/createRequestNeg";
import { BodyUploadPriceNegotationPrice } from "@/services/noticeNegotationRequestServices";
import {
    saveRequestNogotationByIdThunk,
    updatePriceNegotationThunk,
} from "@/store/noticeNegotationRequestRedux/noticeNegRequesThunk";
import { setError } from "@/store/noticeNegotationRequestRedux/noticeNegRequestSlice";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const useFormClientCounterOfferInCity = () => {
  const { handleManageSnackbar } = useButtonsFunctions();
  const { notiffication } = useNotiffication();
  const [preciocontraoferta, setPreciocontraoferta] = useState("");
  const [errorPrice, setErrorPrice] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { idRequest = "" } = useParams();
  const { data } = useGetRequestData();

  const {
    user: { user },
    noticeNegotationRequest: {
      isLoading,
      error,
      selectedNegotationTypeRequest,
      savedDataNogotation,
      isEdition,
    },
  } = useAppSelector((state) => state);
  const idNegotation = (savedDataNogotation as SolicitudNegociacion).id;
  const idAccion = actionsToNegotation[selectedNegotationTypeRequest.id].id;
  const isClient = (user as IUser).tipousuario === CLIENT_TYPE;
  const userId = (user as IUser).id;
  const typeNotice = savedDataNogotation?.solicitud.tipoSolicitud
    ?.nombre as string;
  const { handleModalPriceChanged } = useButtonsFunctions();

  useEffect(() => {
    if (isEdition) {
      setPreciocontraoferta(
        `${(savedDataNogotation as SolicitudNegociacion)?.precioContraoferta}`
      );
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (error) {
      handleManageSnackbar();
    }
  }, [error]);

  useEffect(() => {
    if (!notiffication) {
      dispatch(setError(defaultError));
    }
  }, [notiffication]);

  const handleSubmit = (event: EventType["submit"]) => {
    event.preventDefault();
    const precioactual = savedDataNogotation?.precio as number;
    const preceFromRequest = savedDataNogotation?.solicitud.precio as number;
    const counterOfferPrice = Number(preciocontraoferta);
    const dataAdapter = counterOfferClientAdapter(idAccion, counterOfferPrice);

    const body = {
      ...dataAdapter,
      precioactual,
    };

    if (counterOfferPrice < preceFromRequest / 2) {
      setErrorPrice(textCannotLowerPrice);
      return;
    }

    if (counterOfferPrice > preceFromRequest * 3) {
      setErrorPrice(textCannotUpperPrice);
      return;
    }

    dispatch(
      saveRequestNogotationByIdThunk(idNegotation.toString(), body)
    ).then((value) => {
      if (!!value.flgpreciocambiado) {
        handleModalPriceChanged(() => handleGoBackPage());
      } else {
        cocounterRequest(userId, typeNotice);
        handleNextRute();
      }
    });
  };

  const handleNextRute = () => {
    const valueToReplace = typeNegotation[2];
    const idValueToReplace = `${idNegotation}`;

    dispatch(
      getNextRoute(navigate, {
        valueToSearch: ":typeRequest",
        valueToReplace,
        idToSearch: ":idRequest",
        idValueToReplace,
      })
    );
  };

  const handleDisabledButton = () => {
    const conditions = [preciocontraoferta];

    return conditions.some((condition) => !condition);
  };

  const handleOnchange = ({ currentTarget }: EventType["change"]) => {
    const { value } = currentTarget;
    const prepareValue = value.replace(/^(0+)/g, "").replace(/[^0-9]/g, "");

    if (prepareValue.length < 5) {
      setPreciocontraoferta(prepareValue);
    }
  };

  const handleCompareInputWithDB = () => {
    return (
      preciocontraoferta ===
      `${(savedDataNogotation as SolicitudNegociacion)?.precioContraoferta}`
    );
  };

  const handleDisabledEditCounterOfferButton = () => {
    const conditions = [preciocontraoferta];

    if (handleCompareInputWithDB()) return true;

    return conditions.some((condition) => !condition);
  };

  const handleEditCounterOfferSubmit = (event: EventType["submit"]) => {
    event.preventDefault();
    if (isLoading) return;

    const body: BodyUploadPriceNegotationPrice = {
      precio: Number(preciocontraoferta),
    };
    const pathClient =
      NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_CLIENT.replace(
        ":typeOffer",
        "contraoferta"
      ).replace(":typeRequest", idRequest);
    const pathDriver =
      NEGOTATION_EDIT_COUNTEROFFER_NEGOTATION_SUCCESS_DRIVER.replace(
        ":typeOffer",
        "contraoferta"
      ).replace(":typeRequest", idRequest);

    const path = isClient ? pathClient : pathDriver;
    dispatch(updatePriceNegotationThunk(idRequest, body)).then(() =>
      navigate(path, { replace: true })
    );
  };

  const handleGoBackPage = () => {
    navigate(-1);
  };

  return {
    isLoading,
    isEdition,
    errorPrice,
    preciocontraoferta,
    handleSubmit,
    handleDisabledButton,
    handleOnchange,
    handleDisabledEditCounterOfferButton,
    handleEditCounterOfferSubmit,
  };
};
