import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IInitialFilterState,
  ISelectCity,
  TiposFiltro,
  Ordenamiento,
} from "./filterReduxInterface";

const initialState: IInitialFilterState = {
  filter: [],
  selectedCity: {},
  tiposFiltro: [],
  ordenamiento: [],
  selectedOrdenamiento: {},
  selectedDate: [],
  isLoading: false,
  error: null,
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setDataFilter: (state, { payload }: PayloadAction<TiposFiltro[]>) => {
      state.filter = payload;
      state.isLoading = false;
      state.error = null;
    },

    setSelectCity: (state, { payload }: PayloadAction<ISelectCity | {}>) => {
      state.selectedCity = payload;
    },
    setError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
      state.isLoading = false;
    },
    setTiposFiltro: (state, { payload }: PayloadAction<TiposFiltro[]>) => {
      state.tiposFiltro = payload;
    },
    setDataOrdenamiento: (state, { payload }: PayloadAction<Ordenamiento[]>) => {
      state.ordenamiento = payload;
      state.isLoading = false;
      state.error = null;
    },
    setSelectedOrdenamiento: (
      state,
      { payload }: PayloadAction<Ordenamiento | {}>
    ) => {
      state.selectedOrdenamiento = payload;
    },
    setSelectedDate: (
      state,
      { payload }: PayloadAction<string[] | []>
    ) => {
      state.selectedDate = payload;
    },
  },
});

export const {
  setLoading,
  setDataFilter,
  setError,
  setSelectCity,
  setTiposFiltro,
  setDataOrdenamiento,
  setSelectedOrdenamiento,
  setSelectedDate,
} = filterSlice.actions;
