import { Preguntasfrecuentestipo } from "@/models/landing/preguntasFrecuentesApi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IPreguntasFrecuentes {
    listPreguntasFrecuentes: Preguntasfrecuentestipo[] | [],
    isLoading: boolean;
    error: any;
}

const initialState: IPreguntasFrecuentes = {
    listPreguntasFrecuentes: [],
    isLoading: true,
    error: null,
};

export const landingFAQServicesSlice = createSlice({
    name: "landingFAQ",
    initialState,
    reducers: {
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoading = payload;
        },
        setError: (state, { payload }: PayloadAction<any>) => {
            state.error = payload;
            state.isLoading = false;
        },
        setListPreguntasFrecuentes: (state, { payload }: PayloadAction<Array<any>>) => {
            state.listPreguntasFrecuentes = payload;
            state.isLoading = false;
            state.error = null;
        }
    },
});

// Action creators are generated for each case reducer function
export const { setLoading, setError, setListPreguntasFrecuentes } = landingFAQServicesSlice.actions;
