import { CelebrationIlustrationSvg } from "@/assets/svg";
import {
  closeAgreement,
  textComunication,
  textCongratulation,
} from "@/consts/noticeConsts";
import ButtomLoader from "@/pages/noticeV2/components/buttonLoader/ButtomLoader";
import { Box, Container, Stack, Typography } from "@mui/material";
import useAgreementMessageHook from "./hook";

const AgreementMessage = () => {
  const { handleButton, textToRender } = useAgreementMessageHook();
  return (
    <Container maxWidth="xs">
      <Stack gap={2} alignItems={"center"} justifyContent={"center"} mt={5} >
        <CelebrationIlustrationSvg />
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <Typography variant="h5low" sx={{ fontWeight: 700 }}>
            {textCongratulation}
          </Typography>

          <Typography variant="h5low" sx={{ fontWeight: 700 }}>
            {closeAgreement}
          </Typography>
        </Box>

        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            fontWeight: 400,
          }}
        >
          {textComunication}
        </Typography>

        <ButtomLoader
          fullWidth
          variant="contained"
          type="submit"
          onClick={() => handleButton()}
        >
          {`contactar ${textToRender}`}
        </ButtomLoader>
      </Stack>
    </Container>
  );
};

export default AgreementMessage;
