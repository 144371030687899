import BasicAlert from "@/components/alerts/BasicAlert";
import { AlertColor } from "@mui/material";
import { FC } from "react";

type Props = {
  textAlert: string;
  show: boolean;
  severity?: AlertColor;
};

const AlertOffert: FC<Props> = ({ textAlert, show, severity = "warning" }) => {
  if (!show) return <></>;

  return (
    <BasicAlert severity={severity} sx={{ padding: "16px" }}>
      {textAlert}
    </BasicAlert>
  );
};

export default AlertOffert;
