import { ID_MOVING_FLOW, ID_MULTI_DESTINY } from '@/consts/defaultConsts';
import { useAppSelector } from '@/hooks/useReducer';
import { ConfigDataBody, ValidFormat } from "@/pages/customerRequest/application/config";
import DynamicComponent from "@/pages/offerts/offertConfirmation/components/DynamicComponent";
import { ISolicitudNegociacion } from '@/services/interfaces/RequestNegResponseSuccess';
import { useMemo } from "react";
import { configData, configDataCustomRequestMoving, multiDestinyConfigData } from '../../config';
;
const BodyConfigNegReject = () => {
  const { noticeNegotationRequest: { errorCode, isLoading: loading, savedDataNogotation },
    user: { user: usuario },
} = useAppSelector((state) => state);
    let {
        solicitud,
        vehiculo,
        whatsapp,
        transportista,
        flgIsOwner,
    } = savedDataNogotation as ISolicitudNegociacion;
    solicitud = {
        ...solicitud,
        vehiculo,
        whatsapp,
        transportista,
        flgIsOwner,
    }

    const {
        tipoSolicitud: selectedTypeRequest,
    } = solicitud;


    const handleGetConfig = () => {
        if (!solicitud) return [];

        if (solicitud?.tipoSolicitud.id === ID_MOVING_FLOW) {
            return configDataCustomRequestMoving;
        }

        if (solicitud?.tipoSolicitud.id === ID_MULTI_DESTINY) {
            return multiDestinyConfigData;
        }


        return configData;
    };

    const selectdConfigData = useMemo(() => handleGetConfig(), [solicitud]);
    const handleFormat = (currentValue: any, format?: ValidFormat) => {
        if (!format || !solicitud) return currentValue;

        let { template, valuesToReplace } = format;

        const newValue = valuesToReplace.reduce((acc, el) => {
            const value = solicitud[el.value]?.toString() || el.defaultResult;

            return acc.replace(el.search, value);
        }, template);

        return newValue;
    };

    const handleValid = ({ valid, props }: ConfigDataBody) => {
        if (!valid || !solicitud) return { props };

        const moreProps = valid.reduce((acc, el) => {
            let value = handleFormat(solicitud[el.property], el?.format);

            if (el?.callback) {
                value = el.callback(value, solicitud, usuario);
            }

            if (typeof el.defaultResult === "boolean") {
                acc = {
                    ...acc,
                    [el.componentProp]: !!value,
                };
            } else {
                acc = {
                    ...acc,
                    [el.componentProp]: value || el.defaultResult,
                };
            }
            return acc;
        }, {});

        return { props: { ...props, ...moreProps } };
    };
    return (
        <>
            {selectdConfigData.map((item, i) => (
                <DynamicComponent key={i} {...item} {...handleValid(item)} />
            ))}
        </>
    );
}

export default BodyConfigNegReject
