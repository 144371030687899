import { useAppSelector } from "@/hooks/useReducer";
import { ConfigDataNegotation } from "@/pages/noticeRequestFlow/interface/noticeNegRequestInterface";


export const useConfigData = () => {
    const {
        noticeNegotationRequest: { savedDataNogotation },
        user: { user },
      } = useAppSelector((state) => state);

    const handleValid = ({ valid, props }: ConfigDataNegotation) => {
        if (!valid || !savedDataNogotation) return { props };
    
        const moreProps = valid.reduce((acc, el) => {
          let value = (savedDataNogotation as any)[el.property];
    
          if (el?.callback) {
            value = el.callback(savedDataNogotation, el?.callbakcParams || user);
          }
    
          if (typeof el.defaultResult === "boolean") {
            acc = {
              ...acc,
              [el.componentProp]: !!value,
            };
          } else {
            acc = {
              ...acc,
              [el.componentProp]: value || el.defaultResult,
            };
          }
          return acc;
        }, {});
    
        return { props: { ...props, ...moreProps } };
      };
    
      const handleInitConfig = (config: ConfigDataNegotation) => {
        let moreProps = handleValid(config);
    
        return { ...moreProps };
      };
  return {handleInitConfig}
}
