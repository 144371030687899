import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import { useGlobalButtonsV2 } from "@/pages/noticeV2Negotation/shared/hooks/useGlobalButtons";
import { ContainerButtonsV2 } from "./buttonsStyles";

export const OfferButtons = () => {
  const {isLoadingNoticeV2} = useGetData()
  const { handleAcceptButtonV2, handleManageModalDeclineV2 } = useGlobalButtonsV2();



  return (
    <ContainerButtonsV2>
      <ButtomLoader
        fullWidth
        variant="contained"
        onClick={() => handleAcceptButtonV2()}
        isLoading={isLoadingNoticeV2}
        disabled={isLoadingNoticeV2 }
      >
        aceptar
      </ButtomLoader>

      <ButtomLoader
        fullWidth
        variant="text"
        type="submit"
        onClick={() => handleManageModalDeclineV2()}
        isLoading={isLoadingNoticeV2}
        disabled={isLoadingNoticeV2 }
      >
        rechazar
      </ButtomLoader>
    </ContainerButtonsV2>
  );
};
