import { useAppSelector } from "@/hooks/useReducer";
import { objectRenderComponent } from "./config";
const FormDetalles = () => {
    const { selectedTypeRequest } = useAppSelector((state) => state.customerRequest);

    const Component = objectRenderComponent[selectedTypeRequest.id];
    if (!Component) return <></>;
  
    return <Component />;
}

export default FormDetalles