import { IMG_PATH, MD_IMG } from "@/consts/defaultConsts";
import { resizePhoto } from "@/utils/helpers";
import { Box } from "@mui/material";
import React from "react";

interface Props {
  imageVehicle: string;
}

export const VehicleImg = ({ imageVehicle }: Props) => {
  const newUrl = resizePhoto({
    photo: IMG_PATH + imageVehicle,
    newFormat: MD_IMG,
  });


  if(!imageVehicle.length) return <></>

  return (
    <Box
      component="img"
      sx={{
        height: 233,
        width: "100%",
        maxHeight: { xs: 233, md: 237 },
        maxWidth: { xs: "100%", md: "100%", lg: "85%" },
        objectFit: "contain",
        mb: 2
      }}
      alt="vehiculo"
      src={newUrl}
    />
  );
};
