import HeaderSection from '@/components/headerSection/HeaderSection';
import ListOptions from '@/pages/user/user-type/components/ListOptions';
import { theme } from '@/styles/theme';
import ShareLocationOutlinedIcon from '@mui/icons-material/ShareLocationOutlined';
import { Container, Stack, Typography } from '@mui/material';
import { ContainerArea } from '../NoticeConditions/noticeConditionsStyles';
import useNoticeTypeHook from './hook';
const NoticeType = () => {
    const {
        handleClick,
        handleGoBack,
    } = useNoticeTypeHook()
    
  return (
    <Container maxWidth="xs">
      <HeaderSection
        icon="icon-arrow-left--primary"
        title={"Alcance del servicio"}
        subTitle={"Crear avisos de mudanza"}
        onClick={handleGoBack}
      />
        <ContainerArea>
        <ShareLocationOutlinedIcon
            sx={{ color: theme.palette.accent2.main as string, 
                width: "100%", height: "72px",
                marginBottom: "16px",
                marginLeft: "auto",
                marginRight: "auto", 

        }}
            fontSize='inherit'
        />
        <Stack gap="4px">
          <Typography variant="body1" >
          ¿Dónde quieres ofrecer el servicio de mudanza?
          </Typography>
        <ListOptions list={[{
            icon: 'icon-truck__lateral',
            title: 'En la ciudad',
            typeFLow: "1"
        },{
            icon: 'icon-truck__moving',
            title: 'Entre ciudades',
            typeFLow: "2"
        }]} onClick={handleClick} replaceNavigate={true} />
        </Stack>
      </ContainerArea>
    </Container>
  )
}

export default NoticeType
