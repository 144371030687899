import { styled, Typography } from "@mui/material";

export const PriceContainer = styled("div")(() => ({
  width: "auto",
  display: "flex",
  alignItems: "center",
}));

export const TitlePrice = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.body2.fontFamily,
  fontSize: theme.typography.body2.fontSize,
  fontWeight: theme.typography.body2.fontWeight,
  lineHeight: theme.typography.body2.lineHeight,
  marginLeft: "5px",
}));

export const TextPrice = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.h5.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.h5.fontWeight,
  lineHeight: theme.typography.h5.lineHeight,
}));
