import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import DatePicker from "@/components/form/datePicker.tsx/DatePicker";
import Input from "@/components/inputCustom/Input";
import SearchModal from "@/components/modals/searchModal/SearchModal";
import { FORMAT_DATE_DDMMYYYY } from "@/consts/formatsDate";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { customerRequestObjectTypes } from "@/pages/customerRequest/shared/consts/customerRequestConsts";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { emptyDefaultObject } from "@/store/customerRequestRedux/customerRequestSlice";
import { saveDataRuteThunk } from "@/store/customerRequestRedux/customerRequestThunk";
import { getCitiesByCountryThunk } from "@/store/locationRedux/citiesThunks";
import { getDistrictsByCityAndTermThunk } from "@/store/locationRedux/districtsThunks";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import Icon from "@/utils/Icon";
import { searchTermInArray, validateValueInput } from "@/utils/helpers";
import { FormControl, Grid, InputAdornment, Typography, styled } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { ChangeEvent, useEffect, useId, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MODAL_TYPE, ModalType, configFormRute, functionSaveObjectByTypeModal } from "./configFormInCity";


const Container = styled(Grid)(
  () => `
    display: flex;
    flex-direction: column;  
    margin-top: 24px;
  `
);

const CITY_CONST = "city";

export type DataValueType = { name: string; value: string | boolean };

const FormRuteInCity = () => {
  const [datePicker, setDatePicker] = useState<Dayjs | null>(
    dayjs().add(1, "day")
  );
  const [openModal, setOpenModal] = useState(false);
  const [searchList, setSearchList] = useState<any[]>([]);
  const [currentModal, setCurrentModal] = useState(configFormRute.city.modal);
  const [currentTypeModal, setCurrentTypeModal] = useState<ModalType["type"]>(
    MODAL_TYPE.city
  );
  const [originReference, setOriginReference] = useState("");
  const [destinyReference, setDestinyReference] = useState("");

  const dispatch = useAppDispatch();
  const {
    cities: { cities },
    districts: { districts },
    customerRequest: {
      selectedCity,
      selectedDestiny,
      selectedOrigin,
      originReference: originReferenceStore,
      destinyReference: destinyReferenceStore,
      dateServices,
      selectedTypeRequest,
    },
  } = useAppSelector((state) => state);

  const navigate = useNavigate();

  const id = useId();

  useEffect(() => {
    if (!!selectedCity.id && !cities.length) {
      dispatch(
        getCitiesByCountryThunk(selectedCity?.idpais?.toString() || "1")
      );
    }

    setOriginReference(originReferenceStore);
    setDestinyReference(destinyReferenceStore);
    !!dateServices && setDatePicker(dayjs(dateServices, FORMAT_DATE_DDMMYYYY));
  }, []);

  useEffect(() => {
    if (currentTypeModal !== MODAL_TYPE.city) {
      setSearchList(districts);
    }
  }, [districts]);

  const handleChangeModal = async (term: string) => {
    if (!term.length) return;

    if (currentTypeModal === MODAL_TYPE.city) {
      handleSearchByTermInCity(term);
    } else {
      setSearchList([]);
      dispatch(
        getDistrictsByCityAndTermThunk(selectedCity?.id.toString(), term)
      );
    }
  };

  const handleSearchByTermInCity = (term: string) => {
    const currentList = structuredClone(cities);
    const search = searchTermInArray(term, currentList, ["nombre"]);

    setSearchList(search);
  };

  const handleSubmitModal = (selected: Record<string, any> | null) => {
    if (!selected) return;

    const reduxAction = functionSaveObjectByTypeModal[currentTypeModal];

    dispatch(reduxAction(selected));

    if (currentTypeModal === CITY_CONST && selectedCity.id !== selected.id) {
      const { destiny, origin } = functionSaveObjectByTypeModal;

      dispatch(destiny(emptyDefaultObject));
      dispatch(origin(emptyDefaultObject));
    }

    setOpenModal(false);
  };

  const handleOnClickSearchInput = (typeModal: ModalType["type"]) => {
    setCurrentTypeModal(typeModal);
    setCurrentModal(configFormRute[typeModal].modal);
    setOpenModal(true);
  };

  const handleChangeReference = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { origin } = configFormRute;
    const { value, name } = target;
    const newValue = validateValueInput(value);
    if (name === origin.input2.name) {
      setOriginReference(newValue);
    } else {
      setDestinyReference(newValue);
    }
  };

  const handleSubmit = (event: EventType["submit"]) => {
    event.preventDefault();
    if (handleDisabledButton()) return;
    const data = {
      dateService: datePicker as Dayjs,
      originReference,
      destinyReference,
    };
    dispatch(saveDataRuteThunk(data));

    handleNextRute();
  };

  const handleNextRute = () => {
    const valueToReplace = customerRequestObjectTypes[selectedTypeRequest.id];
    dispatch(
      getNextRoute(navigate, { valueToSearch: ":typeRequest", valueToReplace })
    );
  };

  const handleDisabledButton = () => {
    const conditions = [
      originReference,
      destinyReference,
      datePicker,
      selectedCity.id,
      selectedDestiny.id,
      selectedOrigin.id,
    ];

    return conditions.some((condition) => !condition);
  };

  const { origin, destiny, city } = configFormRute;

  const [form, setForm] = useState({
    title: "",
    category: "",
    duration: "",
    serviceDescription: "",
    selection: 0,
    check: false,
  });

  const handleChange = ({ name, value }: DataValueType) => {

    setForm({ ...form, [name]: value });
  };

  return (
    <Container container>
      <SearchModal
        open={openModal}
        onClose={setOpenModal}
        onSubmit={handleSubmitModal}
        onChange={handleChangeModal}
        list={searchList}
        title={currentModal.title}
        inputProps={currentModal}
        nameTwo={currentTypeModal === MODAL_TYPE.city}
      />

      <form onSubmit={handleSubmit}>
        <DatePicker
          initValue={datePicker}
          inputFormat={FORMAT_DATE_DDMMYYYY}
          label={<LabelInputRequired str="Fecha del servicio" />}
          onChange={setDatePicker}
          minDate={dayjs().add(1, "day")}
          maxDate={dayjs().add(1, "month")}
          renderInput={Input}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon name="icon-calendar" size="md" />
              </InputAdornment>
            ),
          }}
          fullWidth
        />

        <FormControl sx={{ marginTop: "16px" }} fullWidth>
          <Input
            id={`${id}-city`}
            fullWidth
            name={city.input1.name}
            label={<LabelInputRequired str={city.input1.label} />}
            value={selectedCity?.nombre2 || selectedCity?.nombre}
            variant="standard"
            placeholder={city.input1.placeholder}
            onClick={() => handleOnClickSearchInput(MODAL_TYPE.city)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <Typography variant="subtitle1" marginTop="16px">
          Origen
        </Typography>

        <FormControl sx={{ marginTop: "16px" }} fullWidth>
          <Input
            id={`${id}-origin`}
            name={origin.input1.name}
            fullWidth
            label={<LabelInputRequired str={origin.input1.label} />}
            value={selectedOrigin?.nombre2 || selectedOrigin?.nombre}
            variant="standard"
            placeholder={origin.input1.placeholder}
            onClick={() => handleOnClickSearchInput(MODAL_TYPE.origin)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>

        <FormControl sx={{ marginTop: "16px" }} fullWidth>
          <Input
            id={`${id}-reference-origin`}
            name={origin.input2.name}
            fullWidth
            label={<LabelInputRequired str={origin.input2.label} />}
            value={originReference}
            variant="standard"
            placeholder={origin.input2.placeholder}
            onChange={handleChangeReference}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ maxLength: 200 }}
          />
        </FormControl>

        <Typography variant="subtitle1" marginTop="16px">
          Destino
        </Typography>

        <FormControl sx={{ marginTop: "16px" }} fullWidth>
          <Input
            id={`${id}-origin`}
            name={destiny.input1.name}
            fullWidth
            label={<LabelInputRequired str={destiny.input1.label} />}
            value={selectedDestiny.nombre2 || selectedDestiny.nombre}
            variant="standard"
            placeholder={destiny.input1.placeholder}
            onClick={() => handleOnClickSearchInput(MODAL_TYPE.destiny)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>

        <FormControl sx={{ marginTop: "16px" }} fullWidth>
          <Input
            id={`${id}-reference-destiny`}
            name={destiny.input2.name}
            label={<LabelInputRequired str={destiny.input2.label} />}
            value={destinyReference}
            variant="standard"
            placeholder={destiny.input2.placeholder}
            onChange={handleChangeReference}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ maxLength: 200 }}
          />
        </FormControl>
        <Grid item sx={{ marginTop: "16px" }}>
          <ButtomLoader
            type="submit"
            onClick={handleSubmit}
            buttomProps={{
              disabled: handleDisabledButton(),
            }}
          >
            SIGUIENTE
          </ButtomLoader>
        </Grid>
      </form>
    </Container>
  );
};

export default FormRuteInCity;
