import { secondFontFamily } from "@/styles/theme";
import { TextField, Typography } from "@mui/material";
import { CardItemBox, CustomCard, CustomCloseIcon, HeaderBoxContainer, errorStyle } from "../NoticePlace/noticePlaceStyles";

interface CardItemProps {
  nombre: string;
  precio: string;
  id: number;
  hideInput?: boolean;
  onClick?: (id: number) => void;
  changeInput?: (id: number, value: string) => void;
  hideCloseIcon?: boolean;
  error?: boolean;
  errorMsg?: string;
  maxLength?: number;
}


const DEFAULT_LENGTH = 5;


export const CardItem = ({ nombre, precio, id, hideInput, onClick, changeInput, hideCloseIcon = true, error = false, errorMsg, maxLength }: CardItemProps) => {
  return (
    <>
      <CustomCard
        sx={error ? errorStyle : {}}
      >
        <Typography variant="body2">{nombre}</Typography>
        <CardItemBox>
          {!hideInput && (
            <TextField
              hiddenLabel
              id="filled-hidden-label-small"
              variant="standard"
              size="small"
              placeholder="Tu precio"
              sx={{
                width: "80px",
                input: {
                  "&::placeholder": {
                    color: "#bdbdbd",
                    fontFamily: secondFontFamily,
                  },
                },
              }}
              value={precio}
              onChange={(e) => {
                changeInput && changeInput(id, e.target.value);
              }}
              inputProps={{ 
                inputMode: "numeric", 
                pattern: "[0-9]*",
                maxLength:  maxLength || DEFAULT_LENGTH,
              }}
            />
          )}
          {
            hideCloseIcon &&
            <CustomCloseIcon sx={{ color: "#848484" }}
              onClick={() => {
                onClick && onClick(id);
              }}
            />
          }
        </CardItemBox>
      </CustomCard>
      {error &&
        <Typography
          variant="caption"
          color="error"
          mb={1}
          fontSize={12}
          display={"flex"}
          alignItems={"center"}
          ml={1}
          mt={-0.8}
        >
          {errorMsg}
        </Typography>
      }
    </>
  );
};

export const HeaderBox = () => {
  return (
    <HeaderBoxContainer>
      <Typography variant="subtitle1">Destino</Typography>
      <Typography variant="subtitle1">{`Precio (S/)`}</Typography>
    </HeaderBoxContainer>
  );
};
