import { CustomerListTypeRequestDTO } from '@/interfaces/customerRequestInterface';
import { RequestConditions } from '@/models/customerRequest/customerRequestTypesConditions';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Negotiation } from '@/models/offert/offertModels';
import { SolicitudNegociacion } from '@/models/noticeNegotation/noticeNegotationModel';
import { ISolicitudNegociacion } from '@/services/interfaces/RequestNegResponseSuccess';
export interface ISaveRequestOriginOrDestiny {
  reference: string;
  idInmueble: number;
  piso: string;
  flgAscensor: string;
}

// export interface TypeVehicleAPI {
//   id: number;
//   nombre: string;
//   urlImagen?: string;
// }

export interface NoticeRequestData {
  negotationId: number;
  price: number;
  car: string;
  flghoraopunto?: number;
  amountHourOrPoint?: number;
  tiempoestimado?: number;
}

export interface SelectedNegotationTypeRequest {
  id: number;
  nombre: string;
  descripcion: string;
  iconDesk?: string;
  idestado?: number;
}
export interface InitialStateNegotationRequest {
  isLoading: boolean;
  error: any;
  errorCode: number;
  listCustomerTypeRequest: CustomerListTypeRequestDTO[];
  savedNotification: Negotiation | null;
  lisConditions: RequestConditions[];
  savedDataNogotation: SolicitudNegociacion | ISolicitudNegociacion | null;
  selectedNegotationTypeRequest: SelectedNegotationTypeRequest;
  noticeRequestData: NoticeRequestData;
  isEdition: boolean;
  requestNegResponseSuccess: ISolicitudNegociacion | null;
}

const initialState: InitialStateNegotationRequest = {
  isLoading: false,
  error: '',
  errorCode: 0,
  listCustomerTypeRequest: [],
  lisConditions: [], // falta una "T" en el nombre
  savedDataNogotation: null,
  savedNotification: null,
  selectedNegotationTypeRequest: {
    id: 0,
    nombre: '',
    descripcion: '',
  },
  noticeRequestData: {
    negotationId: 0,
    flghoraopunto: 0,
    price: 0,
    car: '',
    amountHourOrPoint: 0,
  },
  isEdition: false,
  requestNegResponseSuccess: null,
};

export const noticeNegRequestSlice = createSlice({
  name: 'noticeNegotationRequest',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (
      state,
      { payload }: PayloadAction<{ message: string; status: number }>
    ) => {
      state.error = payload.message;
      state.errorCode = payload.status;
      state.isLoading = false;
    },
    resetError: (state) => {
      state.error = '';
      state.errorCode = 0;
    },
    getlistCustomerTypeRequest: (
      state,
      { payload }: PayloadAction<CustomerListTypeRequestDTO[]>
    ) => {
      state.listCustomerTypeRequest = payload;
      state.isLoading = false;
      state.error = '';
      state.errorCode = 0;
    },
    saveDataNegotation: (state, { payload }: PayloadAction<any>) => {
      state.savedDataNogotation = payload;
      state.isLoading = false;
      state.error = '';
      state.errorCode = 0;
    },
    setDataRequestNegSuccess: (state, { payload }: PayloadAction<ISolicitudNegociacion>) => {
      state.requestNegResponseSuccess = payload;
      state.isLoading = false;
      state.error = '';
      state.errorCode = 0;
    },
    setSelectNegotationTypeRequest: (
      state,
      { payload }: PayloadAction<CustomerListTypeRequestDTO>
    ) => {
      state.selectedNegotationTypeRequest = payload;
    },
    setNoticeRequestData: (
      state,
      { payload }: PayloadAction<NoticeRequestData>
    ) => {
      state.noticeRequestData = payload;
    },
    saveNotification: (state, { payload }: PayloadAction<Negotiation>) => {
      state.savedNotification = payload;
      state.error = '';
      state.errorCode = 0;
    },
    setIsEdition: (state, { payload }: PayloadAction<boolean>) => {
      state.isEdition = payload;
    },

    resetOffert: (state) => {
      return {
        ...initialState,
        setSelectNegotationTypeRequest: state.selectedNegotationTypeRequest,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setError,
  resetError,
  getlistCustomerTypeRequest,
  saveDataNegotation,
  setSelectNegotationTypeRequest,
  setNoticeRequestData,
  saveNotification,
  setIsEdition,
  setDataRequestNegSuccess,
} = noticeNegRequestSlice.actions;
