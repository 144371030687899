import { Box, ButtonProps, CircularProgress, Typography } from "@mui/material";
import { FC } from "react";
import { StyledButtomLoader } from "./styleButtomLoader";

interface Props extends ButtonProps {
  isLoading?: boolean;
  sizeLoading?: string;
  children: JSX.Element | string;
  onClick?: (e: any) => void;
  buttomProps?: Object;
  tipoAvisoId?: number;
  mundanzaTexto?: string;
  contraofertaTexto?: string;
}

const mudanzaId = 5;

const ButtomLoader: FC<Props> = (props) => {
  const {
    color = "org",
    variant = "contained",
    isLoading = false,
    sizeLoading = "20px",
    children,
    onClick,
    tipoAvisoId,
    mundanzaTexto,
    contraofertaTexto,
    buttomProps,
    ...rest
  } = props;

  const handleClick = (e: any) => {
    onClick && onClick(e);
  };

  const validTextFlow =
    tipoAvisoId === mudanzaId && children === contraofertaTexto;

  if (validTextFlow) {
    return (
      <Box
        sx={{
          mt: 2,
          paddingX: 1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: " center",
        }}
      >
        <Typography sx={{ fontSize: "15px" }}>
          Para cambiar las condiciones del servicio,
        </Typography>
        <Typography
          onClick={handleClick}
          sx={{ fontSize: "15px", color: "#2253c7", cursor: "pointer" }}
        >
          solicita una cotización
        </Typography>
      </Box>
    );
  }

  return (
    <StyledButtomLoader
      disableRipple
      variant={variant}
      color={color}
      onClick={handleClick}
      {...buttomProps}
      {...rest}
    >
      <>
        {isLoading ? (
          <CircularProgress color="inherit" size={sizeLoading} />
        ) : (
          <>{validTextFlow ? mundanzaTexto : children}</>
        )}
      </>
    </StyledButtomLoader>
  );
};

export default ButtomLoader;
