import { useAppSelector } from "@/hooks/useReducer";
import { SelectNotice } from "@/store/noticeRedux/noticeSlice";
import { TextField } from "@mui/material";
import { ChangeEvent, useEffect, useId, useState } from "react";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import HeaderModal from "@/components/modals/headerModal/HeaderModal";
import {
  ContainerButtom,
  ContainerModal,
  CustomDrawer,
} from "@/components/modals/stylesContainerFlow";
import { IMultiPrices, SelectCity } from "@/store/locationRedux/selectLocation";
import { optionFive } from "@/consts/noticeConsts";
import { handleReplaceCharacters } from "@/utils/inputsUtils";


type Props = {
  title: string;
  subTitle: string;
  open: boolean;
  onClose: () => void;
  origin: string;
  destinations: IMultiPrices[];
  onSave?: (value: IMultiPrices[]) => void;
  selectedDestiny: SelectCity;
  unitPrice?: string;
};

const ModalFee = ({
  open,
  onClose,
  title,
  subTitle,
  origin,
  destinations,
  onSave,
  selectedDestiny,
  unitPrice,
}: Props) => {
  const [inputsValues, setInputsValues] = useState<IMultiPrices[]>([]);
  const id = useId();
  const {
    notice: { selectedNotice },
  } = useAppSelector((state) => state);
  useEffect(() => {
    if (!open) return;

    if (destinations?.length <= 0) {
      if (!!selectedDestiny?.distritodet?.length) {
        const prices = selectedDestiny.distritodet?.map((district) => {
          return {
            ...district,
            iddistrito: district.id,
            id: district.id,
            precio: unitPrice || "",
          };
        });
        setInputsValues(prices);
      }
    } else {
      setInputsValues(destinations);
    }
  }, [open]);

  const handleChange = (multiPrice: IMultiPrices) => {
    return ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      const { value } = currentTarget;

      if (value.length > optionFive) {
        return "";
      }

      const newMultiPrices = inputsValues.map((currentValue: IMultiPrices) => {
        if (currentValue.id === multiPrice.id) {
          return {
            ...currentValue,
            precio: handleReplaceCharacters(value)
             
          };
        }
        return currentValue;
      });

      setInputsValues(newMultiPrices);
    };
  };

  const handleOnSave = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSave && onSave(inputsValues);
    setInputsValues([]);
  };

  const handleGenerateLabel = (origin: string, name: string) => {
    const label = `De ${origin} a ${name} ${
      (selectedNotice as SelectNotice).id === optionFive ? "+ ayudante" : ""
    } (S/) *`;

    return label;
  };

  const handleOnClose = () => {
    setInputsValues([]);
    onClose();
  };

  const handleDisabled = () => {
    return inputsValues.some((value) => !value.precio?.toString().trim());
  };

  return (
    <CustomDrawer open={open} onClose={handleOnClose} anchor="right">
      <>
        <HeaderModal title={title} subTitle={subTitle} onClick={onClose} />
        <ContainerModal>
          <form onSubmit={handleOnSave}>
            {inputsValues.map((input: IMultiPrices, i: number) => (
              <TextField
                key={`${id}-${i}`}
                label={handleGenerateLabel(origin, input?.distrito?.nombre)}
                name={input?.distrito?.nombre}
                value={input?.precio}
                variant="standard"
                placeholder="Ingresa el valor del viaje"
                onChange={handleChange(input)}
                onKeyDown={(e) => /[+-.,*]$/.test(e.key) && e.preventDefault()}
                sx={{ marginBottom: "16px" }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  inputMode: "numeric",
                  required: true,
                }}
              />
            ))}
            <input type="submit" hidden />
            <ContainerButtom>
              <ButtomLoader
                type="submit"
                buttomProps={{ disabled: false }}
                disabled={handleDisabled()}
              >
                Guardar
              </ButtomLoader>
            </ContainerButtom>
          </form>
        </ContainerModal>
      </>
    </CustomDrawer>
  );
};

export default ModalFee;
