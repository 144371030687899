import {
  PROFILE_CREAR_VEHICULO_PATH,
  PROFILE_EDITAR_VEHICULO_PATH,
} from "@/consts/typeServicesPaths";

export const objTitle = {
  // comparar el array de strings con el array de strings
  [PROFILE_CREAR_VEHICULO_PATH[0]]: "Agregar vehículo",
  [PROFILE_CREAR_VEHICULO_PATH[1]]: "Fotos del vehículo",
  [PROFILE_CREAR_VEHICULO_PATH[2]]: "Tarjeta de propiedad",
  [PROFILE_EDITAR_VEHICULO_PATH[0]]: "Datos del vehículo",
  [PROFILE_EDITAR_VEHICULO_PATH[1]]: "Fotos del vehículo",
  [PROFILE_EDITAR_VEHICULO_PATH[2]]: "Tarjeta de propiedad",
};

export const objButtonTitle = {
  [PROFILE_CREAR_VEHICULO_PATH[0]]: "Siguiente",
  [PROFILE_CREAR_VEHICULO_PATH[1]]: "Siguiente",
  [PROFILE_CREAR_VEHICULO_PATH[2]]: "Guardar",
  [PROFILE_EDITAR_VEHICULO_PATH[0]]: "Guardar",
  [PROFILE_EDITAR_VEHICULO_PATH[1]]: "Guardar",
  [PROFILE_EDITAR_VEHICULO_PATH[2]]: "Guardar",
};

export const changePathName = (path: string) => {
  // if path content number in position 3 replace for :id
  if (path.split("/")[3].match(/\d+/g)) {
    return path.replace(path.split("/")[3], ":id");
  }
  return path;
};
