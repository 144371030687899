import { CREATE_CUSTOMER_REQUEST_PATH, REQUEST_MAIN_EXPRESS_CANCEL_PATH } from "@/pages/customerRequest/shared/consts/routes";
import { HOME_PAHT } from "@/consts/typeServicesPaths";
import { useAppSelector } from "@/hooks/useReducer";
import { customerRequestObjectTypes } from "@/pages/customerRequest/shared/consts/customerRequestConsts";
import { BoxButton, ButtonDecline, GridButton, GridImage, Text } from "@/pages/notFound/stylesNotFound";
import { Box, Container, Grid, styled } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { dataByUrl } from "./configData";

const ContainerNoticeOff = styled(Container)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const NoticeOff = () => {

  const {
    customerRequest: { selectedTypeRequest },
  } = useAppSelector((state) => state);
  const valueToReplace = customerRequestObjectTypes[selectedTypeRequest?.id];

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleNavigate = (path?: string) => {
    navigate(path || HOME_PAHT, { replace: true });
  };

  const data = pathname.startsWith(CREATE_CUSTOMER_REQUEST_PATH) ? dataByUrl[REQUEST_MAIN_EXPRESS_CANCEL_PATH.replace(valueToReplace, ":typeRequest")] : dataByUrl[pathname];
  return (
    <ContainerNoticeOff maxWidth="lg">
      <Grid container spacing={2}>
        <GridImage xs={12}>
          <Box component="img" sx={{ height: 353, width: 350 }} alt="Imagen de aviso desactivado" src={data?.image} />
          <Text sx={{ fontWeight: 900 }}>{data?.title}</Text>
          <Text sx={{ fontSize: "16px" }}>{data?.subTitle}</Text>
        </GridImage>
        <GridButton xs={12}>
          <BoxButton>
            <ButtonDecline fullWidth variant="text" onClick={() => handleNavigate(data?.buttonNavigate)}>
              {data?.buttonText}
            </ButtonDecline>
          </BoxButton>
        </GridButton>
      </Grid>
    </ContainerNoticeOff>
  );
};

export default NoticeOff;
