import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICurrentLocation {
  currentPath: string;
  step: number;
}

export interface RouteActionsFlowNotice {
  currentLocation: ICurrentLocation | {};
  routeFlow: string[];
  returnPath: {
    path: string;
    state: any;
  };
}

const initialState: RouteActionsFlowNotice = {
  currentLocation: {},
  routeFlow: [],
  returnPath: {
    path: '',
    state: null,
  },
};

export const routeActionsFlowNoticeSlice = createSlice({
  name: 'routeActionsFlow',
  initialState,
  reducers: {
    setCurrentLocation: (
      state,
      { payload }: PayloadAction<RouteActionsFlowNotice['currentLocation']>
    ) => {
      state.currentLocation = payload;
    },
    initRouteFlow: (
      state,
      { payload }: PayloadAction<RouteActionsFlowNotice['routeFlow']>
    ) => {
      state.routeFlow = payload;
    },
    resetFlowNotice: (state) => {
      state.routeFlow = [];
      state.currentLocation = {};
    },
    // se usa para redirigir al usuario a una ruta en especifico
    setReturnPath: (
      state,
      { payload }: PayloadAction<RouteActionsFlowNotice['returnPath']>
    ) => {
      state.returnPath = payload;
    },
    resetReturnPath: (state) => {
      state.returnPath = initialState.returnPath;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCurrentLocation,
  initRouteFlow,
  resetFlowNotice,
  setReturnPath,
  resetReturnPath,
} = routeActionsFlowNoticeSlice.actions;
