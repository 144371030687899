import CustomRadio from "@/components/form/customRadio/CustomRadio";
import { BASE_URL_IMAGE, BASE_URL_IMAGE_S3 } from "@/config/config";
import { useAppSelector } from "@/hooks/useReducer";
import { CustomerListTypeLoadRequestDTO } from "@/interfaces/customerRequestInterface";
import { Stack, styled, Typography } from "@mui/material";

const ContainerList = styled(Stack)(
  () => `
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  cursor:pointer;
  display: flex;
  `
);

const Text = styled(Typography)(
  () => `
  flex: 1;
  margin-left: 16px;
  `
);

type Props = {
  onSelect: (selected: CustomerListTypeLoadRequestDTO) => void;
  selected: CustomerListTypeLoadRequestDTO;
  data: any[];
};

const GenericList = ({ onSelect, selected, data }: Props) => {
  const {
    customerRequest: { isLoading },
  } = useAppSelector((state) => state);

  const handleOnSelect = (selected: CustomerListTypeLoadRequestDTO) => {
    onSelect(selected);
  };

  return (
    <>
      {!isLoading &&
        data.map((item: any) => {
          return (
            <ContainerList key={item.id} onClick={() => handleOnSelect(item)}>
              <CustomRadio checked={selected.id === item.id} />
              {item.urlImagen && (
                <img
                  src={`${BASE_URL_IMAGE_S3}${item.urlImagen}`}
                  alt={item.nombre}
                  style={{ width: 72, height: 72 }}
                />
              )}
              <Text>{!!item.nombre ? item.nombre : item.peso}</Text>
            </ContainerList>
          );
        })}
    </>
  );
};

export default GenericList;
