import {
  PROFILE_NOTICE_OFFERT_AGREEMENT_CLIENT,
  PROFILE_NOTICE_OFFERT_AGREEMENT_CLIENT_CONFIRMATION,
  PROFILE_NOTICE_OFFERT_AGREEMENT_TRANSPORTER,
  PROFILE_NOTICE_OFFERT_AGREEMENT_TRANSPORTER_CONFIRMATION,
} from '@/consts/typeServicesPaths';
import RatingAgreement from '@/pages/ratingAgreement/RatingAgreement';
import { RouterProps } from '@/router/router';
import {
  NEGOTATION_OFFERT_AGREEMENT_TRANSPORTER,
  NEGOTATION_OFFERT_AGREEMENT_CLIENT,
  NEGOTATION_OFFERT_AGREEMENT_TRANSPORTER_CONFIRMATION,
  NEGOTATION_OFFERT_AGREEMENT_CLIENT_CONFIRMATION,
} from '@/pages/customerRequest/shared/consts/routes';
import ConfirmacionCambio from '@/pages/confirmacionCambio/ConfirmacionCambio';
import { privateRuteWithLoginMiddleware } from '@/router/middleware/privateCommontMiddleware';

// RatingAgreement
export const ratingFlowRouter: RouterProps[] = [
  {
    path: NEGOTATION_OFFERT_AGREEMENT_TRANSPORTER,
    Component: RatingAgreement,
    middleware: privateRuteWithLoginMiddleware,
  },
  {
    path: NEGOTATION_OFFERT_AGREEMENT_CLIENT,
    Component: RatingAgreement,
    middleware: privateRuteWithLoginMiddleware,
  },
  {
    path: PROFILE_NOTICE_OFFERT_AGREEMENT_TRANSPORTER,
    Component: RatingAgreement,
    middleware: privateRuteWithLoginMiddleware,
  },
  {
    path: PROFILE_NOTICE_OFFERT_AGREEMENT_CLIENT,
    Component: RatingAgreement,
    middleware: privateRuteWithLoginMiddleware,
  },
  // ConfirmacionCambio
  {
    path: NEGOTATION_OFFERT_AGREEMENT_TRANSPORTER_CONFIRMATION,
    Component: ConfirmacionCambio,
    requiredSession: true,
  },
  {
    path: NEGOTATION_OFFERT_AGREEMENT_CLIENT_CONFIRMATION,
    Component: ConfirmacionCambio,
    requiredSession: true,
  },
  {
    path: PROFILE_NOTICE_OFFERT_AGREEMENT_TRANSPORTER_CONFIRMATION,
    Component: ConfirmacionCambio,
    requiredSession: true,
  },
  {
    path: PROFILE_NOTICE_OFFERT_AGREEMENT_CLIENT_CONFIRMATION,
    Component: ConfirmacionCambio,
    requiredSession: true,
  },
];
