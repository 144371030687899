import { FormDataFilter } from '@/components/Filter/consts/filtersConst';
import { FORMAT_DATE_YYYYMMDD } from '@/consts/formatsDate';
import { TypeFilterData } from '@/store/FilterServices/filterReduxInterface';
import dayjs, { Dayjs } from 'dayjs';

export const prepareUrlParams = (
  values: FormDataFilter,
  typeNoticeSelecte: TypeFilterData
): Partial<FormDataFilter> => {
  const initObject = { typeNotice: typeNoticeSelecte.nombre };

  const prepareObject = Object.entries(values).reduce((acc, [key, value]) => {
    let newValue = value;

    if (value instanceof dayjs) {
      newValue = getDateByFormat(value as Dayjs);
    } else if (typeof value == 'string') {
      if (!value.length) return acc;

      if (validationSplitValue(key, value)) {
        newValue = value.split(',');
      }
    }

    return { ...acc, [key]: newValue };
  }, initObject);

  return prepareObject;
};

export const getDateByFormat = (date: Dayjs, format = FORMAT_DATE_YYYYMMDD) => {
  if (!date) return '';

  return date.format(format);
};

const validationSplitValue = (key: string, value: string | string[]) => {
  const exemption = ['city', 'destinyCity', 'weightOfTheLoad'];

  return !exemption?.some((exemp) => exemp === key) && !!value.includes(',');
};
