import { Container, Stack, styled, Typography, Button } from "@mui/material";

export const ContainerMessageLand = styled(Container)(() => ({
  display: "flex",
  textAlign: "center",
  paddingRight: "16px",
  paddingLeft: "16px",
  paddingBottom: "48px",
  "@media only screen and (min-width: 899px)": {
    minHeight: "70vh",
    padding: "52px",
  },
}));

export const ListItems = styled(Stack)(() => ({
  gap: "20px",
  textAlign: "center",
  alignItems: "center",
  marginRight: "auto",
  marginLeft: "auto",
}));

export const ListItemsBody = styled(Stack)(() => ({
  gap: "16px",
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.h5.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  lineHeight: theme.typography.h5.lineHeight,
  textAlign: "center",
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.body1.fontFamily,
  fontSize: theme.typography.body1.fontSize,
  lineHeight: theme.typography.body1.lineHeight,
  textAlign: "center",
  minWidth: "328px",
  maxWidth: "526px",
}));

export const ButtonHome = styled(Button)(() => ({
  maxWidth: "330px",
  borderRadius: "44px",
  padding: "10px 16px",
  width: "100%",
  lineHeight: "24px",
}));
