import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import DatePicker from "@/components/form/datePicker.tsx/DatePicker";
import Input from "@/components/inputCustom/Input";
import ModalFilterMultiSelect from "@/components/modals/modalFilterMultiSelect/ModalFilterMultiSelect";
import { FORMAT_DATE_DDMMYYYY, FORMAT_DATE_V2 } from "@/consts/formatsDate";
import {
  districtsDeliveryText,
  textDateServiceLabel,
  textSiguiente,
} from "@/consts/noticeConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import {
  NoticeDetailBetweenCitiesModel,
  NoticeDetailDestinyModel,
} from "@/models/notice/noticeModels";
import { AddNewDestiny } from "@/pages/flowNoticeNegotation/components/AddNewDestiny";
import { CardItem } from "@/pages/flowNoticeNegotation/components/CarItem";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { getCitiesByPaisFilterThunk } from "@/store/filterNoticeRedux/filterNoticeThunk";
import { setDataLocation } from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferSlice";
import { updateDestinationsSelectsThunk } from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferThunk";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { stringDDMMYYYToDate } from "@/utils/helpers";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { Box, FormControl, InputAdornment, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const minDate = dayjs().add(1, "day");
const maxDate = dayjs().add(1, "month");

const RuteMultidestiny = () => {
  const {
    noticeV2ClientAorCO: { selectTypeNotice, selectDate },
  } = useAppSelector((state) => state);
  const notice = selectTypeNotice as NoticeDetailBetweenCitiesModel;
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [datePicker, setDatePicker] = useState<Dayjs | null>(null);
  const [errorDate, setErrorDate] = useState("");
  const [destinations, setDestinations] = useState(notice.destinos || []);
  const [openModal, setOpenModal] = useState(false);
  const [listFiltered, setListFiltered] = useState<NoticeDetailDestinyModel[] | null>(null)

  useEffect(() => {
    setDestinations(() =>
      notice.destinos.filter((destiny) => destiny.selected)
    );

    if (selectDate) {
      setDatePicker(stringDDMMYYYToDate(selectDate));
    } else {
      setDatePicker(minDate);
    }

    return () => {};
  }, []);

  const handleBtnClick = () => {
    setOpenModal(true);
  };

  const handleSubmit = () => {
    const data = {
      selectDate: datePicker?.format(FORMAT_DATE_DDMMYYYY) || "",
      originReference: "",
      destinyReference: "",
    };

    dispatch(setDataLocation(data));
    dispatch(updateDestinationsSelectsThunk(destinations));
    handleNextRute();
  };

  const handleNextRute = () => {
    dispatch(
      getNextRoute(navigate, {
        valueToReplace: notice.id?.toString(),
        valueToSearch: ":id",
      })
    );
  };

  const handleDisabled = () => {
    const conditionsMultidestiny = [destinations.length, datePicker];

    return conditionsMultidestiny.some((condition) => !condition);
  };

  const handleChangeDate = (value: dayjs.Dayjs | null) => {
    setDatePicker(value);
    !!errorDate && setErrorDate("");
  };

  const handleErrorDate = (error: string) => {
    !!error && setErrorDate("Fecha invalida");
  };

  const handleOnRemoveSelect = (select: NoticeDetailDestinyModel) => {
    const newDestinations = destinations.filter(
      (values) => values.nombre !== select.nombre
    );

    setDestinations(() => newDestinations);
  };

  const handleOnSubmitModal = (selected: NoticeDetailDestinyModel[]) => {
    setDestinations(() => selected);
    setOpenModal(false);
  };

  const handleFilterByTerm = (value: string) => {
    if(value === "")  setListFiltered(null)
    if (value?.length < 3) return;

    const newValue = notice.destinos.filter((item) => {
      const destinyName = item.nombre.toLowerCase();
      return destinyName.includes(value.toLowerCase());
    }) as  NoticeDetailDestinyModel[]

    setListFiltered(newValue)
  };

  return (
    <div>
      <ModalFilterMultiSelect<NoticeDetailDestinyModel>
        open={openModal}
        onClose={() => setOpenModal(false)}
        list={!!listFiltered ? listFiltered : notice.destinos}
        onSubmit={handleOnSubmitModal}
        preSelected={destinations}
        onInputChange={handleFilterByTerm}
        metaDataModal={{
          buttonText: "elegir",
          inputLabel: "Distrito",
          inputName: "distrito",
          inputPlaceholder: " Distrito de recojo/entrega",
          title: districtsDeliveryText,
        }}
      />

      <DatePicker
        fullWidth
        variant="outlined"
        minDate={minDate}
        maxDate={maxDate}
        initValue={datePicker}
        inputFormat={FORMAT_DATE_V2}
        label={<LabelInputRequired str={textDateServiceLabel} />}
        showStylesLabel
        renderInput={Input}
        onChange={handleChangeDate}
        onError={handleErrorDate}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CalendarMonthOutlinedIcon />
            </InputAdornment>
          ),
          endAdornment: null,
        }}
      />
      {!!errorDate && (
        <Typography variant="caption" color="#d32f2f">
          {errorDate}
        </Typography>
      )}

      <Typography variant="subtitle1" marginTop="16px" marginBottom="16px">
        Distrito de recojo/entrega
      </Typography>

      {!!destinations.length && (
        <Box>
          {destinations.map((item) => (
            <CardItem
              hideInput
              title={item.nombre}
              click={() => handleOnRemoveSelect(item)}
            />
          ))}
        </Box>
      )}

      <Box onClick={handleBtnClick}>
        <AddNewDestiny title="Agregar distrito" />
      </Box>

      <FormControl fullWidth>
        <ButtomLoader onClick={handleSubmit} disabled={handleDisabled()}>
          {textSiguiente}
        </ButtomLoader>
      </FormControl>
    </div>
  );
};

export default RuteMultidestiny;
