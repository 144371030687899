import Footer from "@/pages/footer/Footer";
import MessageLand from "../../../components/messageLand/MessageLand";


const AdsConfirmation = () => {

  return (
    <>
      <MessageLand
        title="¡Enviado exitosamente!"
        text="En breve, nuestro equipo comercial se comunicará contigo."
      />
      <Footer />
    </>
  );
};

export default AdsConfirmation;
