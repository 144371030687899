import DropZone from "@/components/DrowZone/DropZone";
import LabelRequired from "@/components/utils/LabelRequired";
import { BASE_URL_IMAGE } from "@/config/config";
import {
  IMG_PATH,
  MD_IMG,
  USER_CARD_PROPERTY_PAHT_DEFAULT,
  USER_CARD_PROPERTY_PAHT_DEFAULT_BACK,
} from "@/consts/defaultConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { IVehicleList } from "@/store/userRedux/userReduxInterface";
import { setError } from "@/store/userRedux/userSlice";
import { resizePhoto } from "@/utils/helpers";
import { Box, Typography, styled } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import ButtomLoader from "../buttonLoader/ButtomLoader";
const PrimaryText = styled(Typography)(
  () => `
      margin-top: 16px;
    `
);

const From = styled("form")({
  marginTop: "24px",
});

interface IFormImagesVehicle {
  vehicles?: IVehicleList;
  onSubmit?: (foto?: any, fotoPosterior?: any) => void;
  dataIsEmpty?: (isEmpty: boolean) => void;
}

const FormPropertyCard = ({
  vehicles,
  onSubmit,
  dataIsEmpty,
}: IFormImagesVehicle) => {
  const [image, setImage] = useState<string | File>("");
  const [imagePosterior, setImagePosterior] = useState<string | File>("");
  const { isLoading, error } = useAppSelector(({ user }) => user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (error) {
      dispatch(setError(null));
    }

    if (image) {
      dataIsEmpty && dataIsEmpty(true);
    } else {
      dataIsEmpty && dataIsEmpty(false);
    }

    return () => {
      dispatch(setError(null));
    };
  }, [image]);

  const handleOnSelectImage = (img: string | File) => {
    setImage(img);
    if (!!error) {
      dispatch(setError(null));
    }
  };

  // posterior select

  const handleOnSelectImagePosterior = (img: string | File) => {
    setImagePosterior(img);
    if (!!error) {
      dispatch(setError(null));
    }
  };

  const handleOnError = () => {
    dispatch(setError(true));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLoading) return;
    onSubmit && onSubmit(image, imagePosterior);
  };

  const handleShowImageDefault = useCallback(() => {
    const current = vehicles as IVehicleList;
    if (current?.urlPropiedad) {
      const imageResized = resizePhoto({
        photo: IMG_PATH + current?.urlPropiedad,
        newFormat: MD_IMG,
      });
      return imageResized;
    }

    return BASE_URL_IMAGE + USER_CARD_PROPERTY_PAHT_DEFAULT;
  }, [vehicles]);

  const handleShowImageDefault2 = useCallback(() => {
    const current = vehicles as IVehicleList;
    if (current?.urlPropiedadPosterior) {
      const imageResized = resizePhoto({
        photo: IMG_PATH + current?.urlPropiedadPosterior,
        newFormat: MD_IMG,
      });
      return imageResized;
    }

    return BASE_URL_IMAGE + USER_CARD_PROPERTY_PAHT_DEFAULT_BACK;
  }, [vehicles]);

  const handleDisabled = () => {
    if (
      (vehicles as IVehicleList)?.urlPropiedad &&
      (vehicles as IVehicleList)?.urlPropiedadPosterior
    ) {
      return false;
    } else {
      return !image || !imagePosterior || isLoading;
    }
  };

  return (
    <>
      {/* Foto frontal */}
      <Box sx={{ marginTop: "20px" }}>
        <LabelRequired
          str="Foto frontal de la tarjeta de propiedad"
          fontSize="16px"
          containerSx={{ marginBottom: "16px" }}
        />
        <DropZone
          imageDefault={handleShowImageDefault()}
          onError={handleOnError}
          onSelect={handleOnSelectImage}
        />
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        {/* Foto posterior */}
        <LabelRequired
          str="Foto posterior de la tarjeta de propiedad"
          fontSize="16px"
          containerSx={{ marginBottom: "16px" }}
        />
        <DropZone
          imageDefault={handleShowImageDefault2()}
          onError={handleOnError}
          onSelect={handleOnSelectImagePosterior}
        />
      </Box>

      <From onSubmit={handleSubmit}>
        <ButtomLoader
          type="submit"
          disabled={handleDisabled()}
          isLoading={isLoading}
          sx={{ marginTop: 0 }}
        >
          GUARDAR
        </ButtomLoader>
      </From>
    </>
  );
};

export default FormPropertyCard;
