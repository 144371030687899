import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import {
  districtsDeliveryText,
  maxLengthPrice,
  withoutPriceDefault,
} from "@/consts/noticeConsts";
import ViewListZone from "@/pages/createServices/fee/FeeMultiCity/components/ViewListZone";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import RadioButtonsGroup from "@/pages/noticeV2/components/RadiosGroup";
import { currencyFormat } from "@/utils/helpers";
import { FormControl, Typography } from "@mui/material";
import { useId } from "react";
import { handleLabelInputs } from "../config";
import { TextZoneKeyV2, TextZoneValueV2 } from "./FormPriceMultidestinyStyles";
import { priceText, serviceText } from "./const";
import { useFormPriceMultidestiny } from "./hook/useFormPriceMultidestiny";

const FormPriceMultidestiny = () => {
  const id = useId();
  const {
    error,
    error1,
    totalPrice,
    isLoading,
    firstInputValue,
    flaghoraopunto,
    secondInputValue,
    handleOnchangeSecondInput,
    handleOnchangeFirstInput,
    handleSubmit,
    handleDisabledButton,
    changeRadioButtons,
    destinySeleted,
    textDestiny,
    setTextDestiny,
    visibleDestiny,
    setVisibleDestiny,
    validateFormCustomHourOrPoint,
    validateFormCustomHourOrPointPrice,
  } = useFormPriceMultidestiny();

  return (
    <>
      <ViewListZone
        title={`${districtsDeliveryText}:`}
        data={destinySeleted}
        text={textDestiny}
        setText={setTextDestiny}
        display={visibleDestiny}
        setVisible={setVisibleDestiny}
      />
      <Typography sx={{ fontSize: "16px", fontWeight: 700, mt: 2 }}>
        {serviceText}
      </Typography>

      <RadioButtonsGroup
        value={flaghoraopunto.toString()}
        handleChange={changeRadioButtons}
        hiddenLabel
      />

      <Typography sx={{ fontSize: "16px", fontWeight: 700, mb: 2 }}>
        {priceText}
      </Typography>

      <FormControl sx={{ marginTop: "16px", width: "100%" }}>
        <Input
          name="price"
          label={
            <LabelInputRequired str={handleLabelInputs(flaghoraopunto).label1} />
          }
          value={secondInputValue}
          variant="standard"
          placeholder={handleLabelInputs(flaghoraopunto).placeholder1}
          onChange={handleOnchangeSecondInput}
          onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            inputMode: "numeric",
            minLength: 1,
            maxLength: maxLengthPrice,
          }}
          error={!validateFormCustomHourOrPointPrice().valid}
          helperText={!validateFormCustomHourOrPointPrice().valid && validateFormCustomHourOrPointPrice().msg}
        />
      </FormControl>

      <FormControl sx={{ marginTop: "16px", width: "100%" }}>
        <Input
          id={`${id}-price`}
          name="price"
          label={
            <LabelInputRequired str={handleLabelInputs(flaghoraopunto).label2} />
          }
          value={firstInputValue}
          variant="standard"
          placeholder={handleLabelInputs(flaghoraopunto).placeholder2}
          onChange={handleOnchangeFirstInput}
          onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            inputMode: "numeric",
            minLength: 1,
            maxLength: 2,
          }}
          error={!validateFormCustomHourOrPoint().valid}
          helperText={!validateFormCustomHourOrPoint().valid && validateFormCustomHourOrPoint().msg}
        />
      </FormControl>

      <TextZoneKeyV2
        variant="body2"
        sx={{ marginTop: 3, fontSize: "14px", fontWeight: 600 }}
      >
        {`Precio del servicio:`}
        <TextZoneValueV2 variant="caption">
          {!!totalPrice ? currencyFormat(totalPrice) : withoutPriceDefault}
        </TextZoneValueV2>
      </TextZoneKeyV2>
      <ButtomLoader
        onClick={handleSubmit}
        sx={{ marginTop: "24px" }}
        isLoading={isLoading}
        disabled={handleDisabledButton()}
      >
        Solicitar
      </ButtomLoader>
    </>
  );
};

export default FormPriceMultidestiny;
