import { Grid, IconButton } from "@mui/material";
import { FC } from "react";
import {
  ContainerHeader, SubTitleSection, TitleSection
} from "./styleHeaderSection";

type Props = {
  title: string;
  subTitle?: string;
  icon?: string;
  onClick?: (event: any) => void;
  sx?: any;
  buttonAction?: any;
};

const HeaderSection: FC<Props> = ({
  title,
  subTitle,
  icon,
  onClick,
  sx,
  buttonAction,
}) => {

  const handleClick = (event: any) => {
    onClick && onClick(event);

  };

  return (
    <ContainerHeader component="aside" sx={sx}>
      <Grid container columns={
        buttonAction ? 12 : 6
      } alignItems="center">
        {icon && (
          <Grid item xs={buttonAction ? 2 : 1}>
            <IconButton aria-label="back" onClick={handleClick}>
              <i className={icon}></i>
            </IconButton>
          </Grid>
        )}
        <Grid item xs={buttonAction ? 6 : 5}>
          <TitleSection variant="h6" sx={{fontWeight: 700}}>{title}</TitleSection>
          {subTitle && <SubTitleSection>{subTitle}</SubTitleSection>}
        </Grid>
        {
          buttonAction && (
          <Grid item xs={4}>
            {buttonAction && buttonAction}
          </Grid>
          )
        }
      </Grid>
    </ContainerHeader>
  );
};

export default HeaderSection;
