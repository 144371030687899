import {
  Calificacione,
  Detalle,
  IRatingUserHistory,
} from '@/models/ratingAgreement';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IInitialState {
  isLoading: boolean;
  error: any;
  errorCode: number;
  ratingAgreementData: Calificacione | {};
  detailRatingAgreement: Detalle[];
  puntaje: number;
  ratingHistoryUserData: IRatingUserHistory | null;
}

const initialState: IInitialState = {
  isLoading: false,
  error: '',
  errorCode: 0,
  ratingAgreementData: {},
  detailRatingAgreement: [],
  puntaje: 0,
  ratingHistoryUserData: null,
};

export const ratingAgreementSlice = createSlice({
  name: 'ratingAgreement',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    getRatingAgreementSuccess: (
      state,
      action: PayloadAction<Calificacione>
    ) => {
      state.ratingAgreementData = action.payload;
      state.detailRatingAgreement = action.payload.detalle;
      state.puntaje = action.payload.puntaje;
    },
    updateRatingAgreementDetail: (state, action: PayloadAction<Detalle[]>) => {
      state.detailRatingAgreement = action.payload;
    },
    getRatingAgreementError: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetRatingAgreement: (state) => {
      state.isLoading = false;
      state.error = '';
      state.errorCode = 0;
      state.ratingAgreementData = {};
      state.detailRatingAgreement = [];
      state.puntaje = 0;
      state.ratingHistoryUserData = null;
    },

    getUserHistoryRatingDataSuccess: (
      state,
      action: PayloadAction<IRatingUserHistory>
    ) => {
      state.ratingHistoryUserData = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  setIsLoading,
  getRatingAgreementSuccess,
  getRatingAgreementError,
  resetRatingAgreement,
  updateRatingAgreementDetail,
  getUserHistoryRatingDataSuccess,
} = ratingAgreementSlice.actions;
