import { ID_MOVING_FLOW, ID_MULTI_DESTINY } from '@/consts/defaultConsts';
import DetailsText from '@/pages/customerRequest/components/DetailsText';
import { ISolicitudNegociacion } from '@/services/interfaces/RequestNegResponseSuccess';
import { currencyFormat } from '@/utils/helpers';
import { optionOne } from '../../../../../../consts/noticeConsts';
import MovingNegPrice from './MovingNegPrice';
import PriceMultiDestiny from './PriceMultiDestiny';
interface IPricesProps {
  request: ISolicitudNegociacion;
}
const PriceNegReject = ({ request }: IPricesProps) => {
  const {
    solicitud: {
      tipoSolicitud: {
        id: idTipoSolicitud,
      },
      precio
    },
    tipoSolicitudNegociacion: {
      id: idTipoSolicitudNegociacion,
    }
  } = request

  if (idTipoSolicitudNegociacion === optionOne) {
    return <></>
  }

  if (idTipoSolicitud === ID_MULTI_DESTINY) {
    return <PriceMultiDestiny data={request} />
  }

  if (idTipoSolicitud === ID_MOVING_FLOW) {
    return <MovingNegPrice data={request} />
  }

  return (
    <>
      <DetailsText
        title='Precio de la solicitud'
        origen={currencyFormat(precio)}
        show
        constainerSx={{
          mt: 0,
        }}
      />
      <DetailsText
        title={
          request.precioContraoferta ?
            'Contraoferta del transportista'
            :
            'Contraoferta rechazada'
        }
        origen={currencyFormat(request.precio)}
        show
        constainerSx={{
          mt: 0,
        }}
      />
      {
        request.precioContraoferta &&
        <DetailsText
          title='Contraoferta rechazada'
          origen={currencyFormat(request.precioContraoferta)}
          show
          constainerSx={{
            mt: 0,
          }}
        />
      }
    </>
  )
}

export default PriceNegReject
