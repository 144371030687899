import ContainerMultiOutlet from "@/components/ContainerMultiOutlet/ContainerMultiOutlet";
import HeaderSection from "@/components/headerSection/HeaderSection";
import { IUser } from "@/store/userRedux/userReduxInterface";

import {
  PROFILE_DATOS_PERSONALS_PATH,
  PROFILE_PATH,
} from "@/consts/typeServicesPaths";
import { useAppSelector } from "@/hooks/useReducer";
import useResize from "@/hooks/useResize";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ListItemMenu from "./components/ListItemMenu";
import { Links } from "./rulesFlow";
import { CustomListLinks } from "./styleProfile";

const Profile = () => {
  const [width, isMobil] = useResize();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    if ((width as number) > 0 && !isMobil && pathname === PROFILE_PATH) {
      navigate(PROFILE_DATOS_PERSONALS_PATH);
    }
  }, [width, isMobil]);

  const handleNavigateBack = () => {
    navigate(-1);
  };

  return (
    <ContainerMultiOutlet pathIndex={PROFILE_PATH}>
      <HeaderSection
        title="Perfil"
        onClick={handleNavigateBack}
        sx={{
          boxShadow: "none",
        }}
      />
      <CustomListLinks>
        <ListItemMenu links={Links} user={user as IUser} />
      </CustomListLinks>
    </ContainerMultiOutlet>
  );
};

export default Profile;
