import ShareButton from "@/components/shareButton/ShareButton";
import TransporterRating from "@/components/userRating/TransporterRating";
import { ID_AUCTION_FLOW, ID_MOVING_FLOW } from "@/consts/defaultConsts";
import { PROFILE_NOTICE_LIST_PATH } from "@/consts/typeServicesPaths";
import { useAppSelector } from "@/hooks/useReducer";
import {
  DetailNoticeMoving,
  DetailsCoreModel,
} from "@/models/notice/noticeModels";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { Stack, styled } from "@mui/material";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import useDetailNotice from "../../hook";
import { getTransporterRatingFormat } from "../../utils";
import DetailTitle from "../DetailTitle";
import DetailOptionsList from "../detailOptionsList";
import {
  detailsConfigAution,
  detailsConfigStandar,
} from "../detailOptionsList/configDataInCity";
import DetailTypeFlow from "../detailTypeFlow";
import "./style.css";
import { TYPE_MOVING } from "./consts";

const ContainerDetail = styled(Stack)(
  () => `
    display: flex;
    flex-direction: column;
  `
);

const WrapperDetailFlow = styled("div")(
  () => `
    display: flex;
  `
);

const Details = () => {
  const {
    detailsNoticeSlice: { isLoading },
    user: userRedux,
  } = useAppSelector((state) => state);
  const { handleGetCurrentDetailNotice } = useDetailNotice();

  const notice: DetailsCoreModel = useMemo(
    () => handleGetCurrentDetailNotice(),
    [isLoading]
  );

  const { pathname } = useLocation();
  const user = userRedux.user as IUser;

  const isPrivate = pathname.startsWith(PROFILE_NOTICE_LIST_PATH);

  const handleGetDetailListConfig = () => {
    const { tipoaviso } = notice;

    if (tipoaviso.id === ID_AUCTION_FLOW) {
      return detailsConfigAution;
    }

    return detailsConfigStandar;
  };

  const handleUSerIsOwner = () => {
    if (!!Object.keys(user).length) {
      return user.id === notice.transportista?.id;
    }

    return false;
  };

  const handleShowRating = () => {
    const isOwner = handleUSerIsOwner();
    if (isPrivate) {
      return false;
    }

    return !isOwner;
  };

  const handleGetNameTypeFlow = () => {
    const { tipoaviso, flgciudadoentreciudades } = notice as DetailNoticeMoving;
    const { inCity, betweenCities } = TYPE_MOVING;
    if (tipoaviso.id === ID_MOVING_FLOW) {
      if (flgciudadoentreciudades === inCity.id) {
        return inCity.text;
      } else if (flgciudadoentreciudades === betweenCities.id) {
        return betweenCities.text;
      }
    }

    return tipoaviso.nombre;
  };

  const handleGetTypeMoving = () => {
    const { tipoaviso, flgciudadoentreciudades } = notice as DetailNoticeMoving;
    if (tipoaviso.id === ID_MOVING_FLOW) {
      return flgciudadoentreciudades;
    }
  };

  if (!notice) return <></>;

  const { tipoaviso, origentexto, destinotexto, transportista } = notice;

  return (
    <ContainerDetail>
      <WrapperDetailFlow>
        <DetailTypeFlow
          nombre={handleGetNameTypeFlow()}
          id={tipoaviso?.id}
          typeMoving={handleGetTypeMoving()}
        />
        <ShareButton show={!isPrivate} />
      </WrapperDetailFlow>

      <DetailTitle origentexto={origentexto} destinotexto={destinotexto} />

      {/* perfil transportista */}
      {handleShowRating() && (
        <TransporterRating
          data={getTransporterRatingFormat(transportista)}
          viewRating={true}
        />
      )}

      {/* Detalle */}
      <DetailOptionsList
        detailsList={handleGetDetailListConfig()}
        notice={notice}
      />
    </ContainerDetail>
  );
};

export default Details;
