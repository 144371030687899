import { Negotiation } from "@/models/offert/offertModels";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import { NOTICE_AGREEMENT_CONFIRMATION } from "@/consts/acceptanceOffertPath";
import { ID_AUCTION_FLOW } from "@/consts/defaultConsts";
import { currencyFormat } from "@/utils/helpers";
import React from "react";
import { useLocation } from "react-router-dom";
import { getTypeByPrice } from "../utils/utilsAcceptance";
import SectionDescount from "./SectionDescount";

type Props = {
  isCollapse: boolean;
  setisCollapse: React.Dispatch<React.SetStateAction<boolean>>;
  offert: Negotiation;
  marginB?: number;
  typeFlow?: number;
};

const RowCollapse = ({
  isCollapse,
  setisCollapse,
  offert,
  marginB = -30
}: Props) => {
  const { pathname } = useLocation();
  const { tipoaviso } = offert?.avisoNegociacionAviso;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const mobile = useMediaQuery("(max-width:1030px)");
  const isValidPrice = pathname.endsWith(NOTICE_AGREEMENT_CONFIRMATION);
  const textToRender = getTypeByPrice(offert);

  const handlePrice = () => {
    let price = currencyFormat(offert.avisoNegociacionAviso.precio);
    const offer = offert;

    if (textToRender === "Precio por hora")
      price = currencyFormat(
        offert?.avisoNegociacionAviso?.precioxhora as number
      );
    if (textToRender === "Precio por punto")
      price = currencyFormat(
        offert?.avisoNegociacionAviso?.precioxpunto as number
      );

    return price;
  };

  return (
    <>
      <Box sx={{ pl: 2 }}>
        <Grid
          container
          spacing={2}
          sx={{ mt: 2, marginBottom: `${marginB}px` }}
        >
          {!isValidPrice && (
            <SectionDescount offert={offert.avisoNegociacionAviso} show />
          )}
        </Grid>
        <Grid container spacing={2} sx={{ mt: 4 }}>
          <Grid
            xs={7}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              paddingTop: 2
            }}
          >
            {tipoaviso.id !== ID_AUCTION_FLOW && !isValidPrice && (
              <Typography variant="caption">
                {getTypeByPrice(offert)}{" "}
              </Typography>
            )}
            {!isValidPrice && (
              <Typography variant="label1" sx={{ fontSize: "22px" }}>
                {handlePrice()}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                !matches && setisCollapse(!isCollapse);
              }}
            >
              {!matches && (
                <>
                  <Typography
                    variant="label1"
                    color="primary"
                    sx={{ fontSize: "15px", pl: mobile ? "0px" : "16px" }}
                  >
                    {isCollapse ? "Ocultar" : "Ver detalles"}
                  </Typography>

                  <KeyboardArrowUpIcon
                    color="primary"
                    sx={{
                      transform: isCollapse ? "rotate(0deg)" : "rotate(180deg)",
                    }}
                  />
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      {!matches && (
        <Box
          sx={{ width: "100%", borderTop: "1px solid #E0E0E0", mt: "16px" }}
        ></Box>
      )}
    </>
  );
};

export default RowCollapse;
