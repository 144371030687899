export interface StateProps {
  id: number;
  idestado: number;
  nombre: string;
  descripcion: string;
  componentId?: number
}

export const actionsToNegotation: Record<number, StateProps> = {
  1: {
    id: 1,
    nombre: "Transportista - Acepta",
    descripcion: "El Trasportista acepta",
    idestado: 4,
  },
  2: {
    id: 2,
    nombre: "Transportista - Contraoferta",
    descripcion: "El Trasportista contraoferta",
    idestado: 4,
  },
  3: {
    id: 3,
    nombre: "Transportista - Oferta",
    descripcion: "El Trasportista oferta",
    idestado: 4,
  },
  4: {
    id: 4,
    nombre: "Transportista - Cotiza",
    descripcion: "El Trasportista cotiza",
    idestado: 4,
  },
  5: {
    id: 5,
    nombre: "Cliente - Acepta",
    descripcion: "El Cliente acepta",
    idestado: 5,
  },
  6: {
    id: 6,
    nombre: "Cliente - Rechaza",
    descripcion: "El Cliente rechaza",
    idestado: 6,
  },
  7: {
    id: 7,
    nombre: "Cliente - Contraoferta",
    descripcion: "El Cliente contraoferta",
    idestado: 1
  },
  8: {
    id: 8,
    nombre: "Transportista - Acepta Contraoferta",
    descripcion: "El Trasportista acepta la contraoferta del Cliente",
    idestado: 2
  },
  9: {
    id: 9,
    nombre: "Transportista - Rechaza Contraoferta",
    descripcion: "El Trasportista rechaza la contraoferta del Cliente",
    idestado: 3
  },
  10: {
    id: 10,
    nombre: "Sistema - Anula",
    descripcion: "El sistema anula la negociacion por cambios de datos en el aviso",
    idestado: 7
  },
  11: {
    id: 11,
    nombre: "Sistema - Anula",
    descripcion: "El sistema anula la negociacion por eliminacion de vehiculo",
    idestado: 7
  },
  12: {
    id: 12,
    nombre: "Cliente - Acepta Contraoferta",
    descripcion: "El Cliente acepta contraoferta",
    idestado: 5
  },
  13: {
    id: 13,
    nombre: "Cliente - Rechaza Contraoferta",
    descripcion: "El Cliente rechaza contraoferta",
    idestado: 6
  },
  14: {
    id: 14,
    nombre: "Transportista - Retira Oferta",
    descripcion: "El Transportista retira su oferta",
    idestado: 8
  },
  15: {
    id: 15,
    nombre: "Transportista - Retira Contraoferta",
    descripcion: "El Transportista retira su Contraoferta",
    idestado: 8
  },
  16: {
    id: 16,
    nombre: "Cliente - Retira Co-contraoferta",
    descripcion: "El Cliente retira su Co-contraoferta",
    idestado: 9
  },
  17: {
    id: 17,
    nombre: "Cliente - Acepta Cotización",
    descripcion: "El Cliente acepta cotización",
    idestado: 5
  },
  18: {
    id: 18,
    nombre: "Cliente - Rechaza Cotización",
    descripcion: "El Cliente rechaza cotización",
    idestado: 6
  },
  19: {
    id: 19,
    nombre: "Transportista - Cancela Acuerdo",
    descripcion: "El Transportista cancela su acuerdo",
    idestado: 3
  },
  20: {
    id: 20,
    nombre: "Cliente - Cancela Acuerdo",
    descripcion: "El Cliente cancela su acuerdo",
    idestado: 6
  },
  21: {
    id: 21,
    nombre: "Cliente - Cancela Solicitud",
    descripcion: "El Cliente cancela su solicitud",
    idestado: 3
  },
};

export const actionsToNoticeNegotation: Record<number, StateProps> = {
  3: {
    id: 3,
    nombre: "Transportista - Acepta",
    descripcion: "El Trasportista acepta",
    idestado: 2,
    componentId: 1
  },
  4: {
    id: 4,
    nombre: "Transportista - Rechaza",
    descripcion: "El Trasportista rechaza",
    idestado: 3,
    componentId: 1
  },
  5: {
    id: 5,
    nombre: "Transportista - Contraoferta",
    descripcion: "El Transportista contraoferta",
    idestado: 4,
    componentId: 1
  },
  6: {
    id: 6,
    nombre: "Cliente - Acepta Contraoferta",
    descripcion: "El cliente acepta la contraoferta del Transportista",
    idestado: 5,
    componentId: 2
  },
  7: {
    id: 7,
    nombre: "Cliente - Rechaza Contraoferta",
    descripcion: "El cliente rechaza la contraoferta del Transportista",
    idestado: 6,
    componentId: 2
  },
  8: {
    id: 8,
    nombre: "Sistema - Anula",
    descripcion: "El sistema anula la negociacion por cambio de datos en el aviso",
    idestado: 7,
    componentId: 1
  },
  9: {
    id: 9,
    nombre: "Sistema - Anula",
    descripcion: "El sistema anula la negociacion por eliminacion de vehiculo",
    idestado: 7,
    componentId: 1
  },
  10: {
    id: 10,
    nombre: "Sistema - Anula",
    descripcion: "El sistema anula por vencimiento del aviso",
    idestado: 7,
    componentId: 1
  },
  1: {
    id: 1,
    nombre: "Cliente - Acepta",
    descripcion: "El cliente acepta el aviso",
    idestado: 1,
    componentId: 1 
  },
  11: {
    id: 11,
    nombre: "Transportista - Acepta Contraoferta",
    descripcion: "El Transportista acepta contraoferta",
    idestado: 2,
    componentId: 1
  },
  2: {
    id: 2,
    nombre: "Cliente - Contraoferta",
    descripcion: "El cliente contraoferta el aviso",
    idestado: 1,
    componentId: 2
  },
  12: {
    id: 12,
    nombre: "Transportista - Rechaza Contraoferta",
    descripcion: "El Transportista rechaza contraoferta",
    idestado: 3,
    componentId: 2
  },
  13: {
    id: 13,
    nombre: "Cliente - Retira Oferta",
    descripcion: "El cliente retira oferta",
    idestado: 8,
    componentId: 1
  },
  14: {
    id: 14,
    nombre: "Cliente - Retira Contraoferta",
    descripcion: "El cliente retira contraofertaoferta",
    idestado: 8,
    componentId: 2
  },
  15: {
    id: 15,
    nombre: "Transportista - Retira Co-contraoferta",
    descripcion: "El Transportista retira co-contraofertaoferta",
    idestado: 8,
    componentId: 2
  },

};
