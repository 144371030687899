export const RatingStyles = {
    avatarStyles:{
        width: "48px", 
        height: "48px",
    },
    gridContainerStyles: {
        display: "flex", 
        flexDirection: "column", 
        mt: 1
    },
    gradeTextStyles: {
        color: "#9E9E9E"
    },
    boxPriceStyles: {
        display: "flex", flexDirection: "column"
    },
    
};
  