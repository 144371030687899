import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NoticeResponseSaveFlowAPI } from '../../models/notice/noticeModels';

interface SaveFlow {
  saveFlowData: NoticeResponseSaveFlowAPI[];
  saveListFlowData: NoticeResponseSaveFlowAPI[];
  messageEdit: string;
  isLoading: boolean;
  error: null | any;
}

const initialState: SaveFlow = {
  saveFlowData: [],
  saveListFlowData: [],
  messageEdit: '',
  isLoading: false,
  error: null,
};

export const saveFlowSlice = createSlice({
  name: 'saveFlow',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    saveDataFlow: (
      state,
      { payload }: PayloadAction<NoticeResponseSaveFlowAPI[]>
    ) => {
      state.saveFlowData = payload;
      state.isLoading = false;
    },
    saveListDataFlow: (
      state,
      { payload }: PayloadAction<NoticeResponseSaveFlowAPI[]>
    ) => {
      state.saveListFlowData = payload;
      state.isLoading = false;
    },
    setError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
      state.isLoading = false;
    },
    setResetDataFlow: (state) => {
      state.saveFlowData = initialState.saveFlowData;
    },
    setMessageEdit: (state, { payload }: PayloadAction<string>) => {
      state.messageEdit = payload;
    },
    resetFlow: () => {},
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  saveDataFlow,
  saveListDataFlow,
  setError,
  setResetDataFlow,
  resetFlow,
  setMessageEdit,
} = saveFlowSlice.actions;
