import SelectControlMulti, {
  ISelectListItems,
} from "@/components/form/selectControl/SelectControlMulti";
import Input from "@/components/inputCustom/Input";
import LabelRequired from "@/components/utils/LabelRequired";
import { EventType } from "@/interfaces/globalInterface";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { FormControl } from "@mui/material";
import { FC } from "react";

export type InputProps = {
  name: string;
  label: string;
  value: string;
  placeholder: string;
  show: boolean;
};
type Props = {
  input1: InputProps;
  input2: InputProps;
  input3: InputProps;
  onChange: (e: EventType["change"]) => void;
  onClick: (e: EventType["click"], inputProp: InputProps) => void;
  inmueble?: boolean;
  listInmueble?: ISelectListItems[];
  activeDisabledInput?: boolean;
};
const FormRequest: FC<Props> = (props) => {
  const {
    input1,
    input2,
    input3,
    onChange,
    onClick,
    inmueble,
    listInmueble,
    activeDisabledInput,
  } = props;
  const handleChange = (e: EventType["change"]) => {
    onChange && onChange(e);
  };

  const handleClick = (inputProp: InputProps) => (e: EventType["click"]) => {
    if (inputProp.name === input2.name) {
      if (activeDisabledInput) {
        !!input1.value && onClick && onClick(e, inputProp);
      } else {
        onClick && onClick(e, inputProp);
      }
    } else {
      onClick && onClick(e, inputProp);
    }
  };

  return (
    <FormControl sx={{ marginTop: "16px", gap: "8px" }} fullWidth>
      <Input
        name={input1.name}
        label={<LabelInputRequired str={input1.label} />}
        sx={{ display: input1.show ? "" : "none", marginBottom: "16px" }}
        variant="standard"
        value={input1.value}
        placeholder={input1.placeholder}
        onChange={handleChange}
        onClick={handleClick(input1)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      
        <Input
          name={input2.name}
          label={<LabelInputRequired str={input2.label} />}
          variant="standard"
          sx={{ display: input2.show ? "" : "none", marginBottom: "16px" }}
          value={input2.value}
          placeholder={input2.placeholder}
          onChange={handleChange}
          onClick={handleClick(input2)}
          disabled={
            activeDisabledInput ? (!!input1.value ? false : true) : false
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      {input3.show && (
        <Input
          name={input3.name}
          label={<LabelInputRequired str={input3.label} />}
          variant="standard"
          value={input3.value}
          placeholder={input3.placeholder}
          multiline
          onChange={handleChange}
          onClick={handleClick(input3)}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ maxLength: 200 }}
        />
      )}

      {inmueble && <SelectControlMulti listItems={listInmueble || []} />}
    </FormControl>
  );
};

export default FormRequest;
