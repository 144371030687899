import {
  Container,
  ListItem,
  ListItemAvatar,
  styled,
  Typography,
} from "@mui/material";

export const SubTitleConditions = styled(Typography)(
  ({ theme }) => `
    font-family: ${theme.typography.h6.fontFamily};
    font-size: ${theme.typography.body1.fontSize};
    line-height: ${theme.typography.body2.lineHeight};
    margin-top: 16px;
  `
);

export const SuggestionsListItem = styled(ListItem)(
  () => `
    padding: 0;
    margin-top: 21px;

    & .MuiListItemText-root {
      margin: 0;
    }
  `
);

export const SuggestionsListItemAvatar = styled(ListItemAvatar)(
  () => `
    min-width: 30px
  `
);

export const ContainerArea = styled(Container)(
  () => `
    padding: 16px 0;
  `
);
