import {
  getPhotosOffertAdapter,
  prepareListPhotos,
} from '@/adapters/store/offertStoreAdapter';
import { LocationDetailState } from '@/interfaces/detailNoticeInterface';
import {
  BodyAuctionOfferDTO,
  BodyOfferMovingDTO,
  BodyOfferMultiDestinationDTO,
  NoticeOfferMovingInCitySlice,
  RequestOfferMultiDestinationService,
} from '@/interfaces/offerInterface';
import { ResponseDistrictListAPI } from '@/models/location/districtModel';
import {
  DetailNoticeMoving,
  MergeDetailNoticeModel,
  NoticeDetailAuctionModel,
  NoticeDetailDestinyModel,
  NoticeDetailMultiDestinyModel,
} from '@/models/notice/noticeModels';
import {
  ResponseAuctionNoticeOffer,
  ResponseMovingNoticeOffer,
  ResponseNoticeOffer,
  ResponseOfferCore,
} from '@/models/offert/offertModels';
import { UsuarioAPI } from '@/models/userAuth/user';
import { getOriginAndDestinyByPromiseSettled } from '@/services/adapters/districtAdapter';
import { NegotiationPhotosProps } from '@/services/interfaces/negotiationInterface';
import { getOnlyDistrictsByCityServices } from '@/services/locationServices';
import {
  getOfferCondicionsByIdNotice,
  getTypeInmuebleService,
} from '@/services/negotiationServices';
import { saveImagesNegotiationServices } from '@/services/noticeNegotationRequestServices';
import {
  noticeSendDataForSave,
  savingNoticeOffer,
} from '@/services/noticeServices/NoticeClientAcceptOrCounterOffer/noticeSendDataForSave';
import { DataResponseNoticeSpanish } from '@/services/noticeServices/NoticeClientAcceptOrCounterOffer/noticeServiceDataResponseType';
import { getMultiPromises } from '@/utils/helpers';
import { getMessaggeError } from '../auth/utils';
import { AppDispatch, RootState } from '../store';
import { requestAuctionAdapter } from './adapter/auctionAdapter';
import { saveStorageInCityAdapter } from './adapter/inCityAdapter';
import { requestMovingAdapter } from './adapter/movingAdapter';
import { requestMultidestinyAdapter } from './adapter/multidestinyAdapter';
import { translateData, translateTo } from './adapter/translateAdapter';
import { DataResponseEnglishTypeTwo } from './interfaces/dataResponseEnglishInterfaces';
import { SendDataPayload } from './interfaces/sendDataInterfacesEnglish';
import {
  PhotosPreLoaded,
  SelectedUbications,
  fillSendNoticeData,
  resetSendData,
  setAvisoDestinos,
  setAvisoOrigenes,
  setDataResponse,
  setError,
  setImageUploadError,
  setIsLoading,
  setIsLoadingImage,
  setListOfOfferConditions,
  setListTypeProperty,
  setPhotosPreLoaded,
  setSelectTypeNotice,
  setSelectedUbications,
} from './noticeClientAcceptOrCounterOfferSlice';
import dayjs from 'dayjs';
import { FORMAT_DATE_DDMMYYYY } from '@/consts/formatsDate';

// TODO: Esto deberia de estar en utils o adapter!!
const manageImage = async (
  idVerifiedImages: number,
  photosPreLoaded: PhotosPreLoaded[],
  images: File[]
) => {
  const photos = getPhotosOffertAdapter([...photosPreLoaded, ...images]);

  let arrayPhotos = { ...photos };

  Object.keys(arrayPhotos).forEach((key) =>
    arrayPhotos[key as keyof NegotiationPhotosProps] === undefined
      ? delete arrayPhotos[key as keyof NegotiationPhotosProps]
      : {}
  );

  const { fotosverificadas } = await saveImagesNegotiationServices(
    idVerifiedImages,
    photos
  );

  const recordedPhotos = prepareListPhotos(
    fotosverificadas,
    Object.keys(arrayPhotos)
  );

  return { recordedPhotos, fotosverificadasId: fotosverificadas.id };
};

export interface FillSendDataNoticeProps {
  data: SendDataPayload;
  images?: File[];
  noExecuteImg?: boolean;
  activeLoading?: boolean;
}

export const fillSendDataNoticeThunk = (props: FillSendDataNoticeProps) => {
  const {
    data,
    images = [],
    activeLoading = true,
    noExecuteImg = false,
  } = props;
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    !!images.length && dispatch(setIsLoadingImage(true));
    activeLoading && dispatch(setIsLoading(true));
    let photoVerifiedId;

    const newData = data.value as DataResponseEnglishTypeTwo;
    const idPhotosVerified = newData.verifiedPhotosId
      ? newData.verifiedPhotosId
      : 0;

    try {
      const {
        noticeV2ClientAorCO: { photosPreLoaded = [] },
      } = getState();

      if (!noExecuteImg) {
        const dataPhotos = await manageImage(
          idPhotosVerified,
          photosPreLoaded,
          images
        );

        photoVerifiedId = dataPhotos.fotosverificadasId;

        !!images?.length &&
          dispatch(setPhotosPreLoaded(dataPhotos.recordedPhotos));
      }

      let dataToSendStore = {
        key: data.key,
        value: {
          ...data.value,
          verifiedPhotosId: photoVerifiedId || 0,
        },
      };

      if (noExecuteImg) {
        dataToSendStore = {
          key: data.key,
          value: {
            ...data.value,
            verifiedPhotosId: idPhotosVerified,
          },
        };
      }

      dispatch(fillSendNoticeData(dataToSendStore));

      !!images.length && dispatch(setIsLoadingImage(false));
      activeLoading && dispatch(setIsLoading(false));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setImageUploadError(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

export const postDataNoticeThunk = (
  // borrar esto!!!!!!!!!!!!!!!
  body: DataResponseEnglishTypeTwo,
  noticeId: string
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setError(null));
    dispatch(setIsLoading(true));
    try {
      const state = getState();

      const {
        user: { user },
      } = state;
      const isNotRegister = !(user as UsuarioAPI);
      if (isNotRegister) return;
      if (
        (typeof body.date as any) !== 'string' &&
        (body.date as any) instanceof dayjs
      ) {
        body = {
          ...body,
          date: dayjs(body.date).format(FORMAT_DATE_DDMMYYYY),
        };
      }
      const adapterDataBeforeService = translateData(
        body as DataResponseEnglishTypeTwo,
        translateTo.spanish
      );

      const result = await noticeSendDataForSave(
        adapterDataBeforeService as DataResponseNoticeSpanish,
        noticeId
      );

      const dataTranslated = translateData(
        result,
        translateTo.english
      ) as DataResponseEnglishTypeTwo;

      dispatch(setDataResponse(dataTranslated));
      dispatch(setIsLoading(false));
    } catch (err) {
      const error = getMessaggeError(err);
      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const setSelectedUbicationsThunk = (data: SelectedUbications) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setSelectedUbications(data));
  };
};

export const getDistrictsByIdCityOriginAndDestinyThunk = (
  idOrigin: number,
  idDestiny: number
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    try {
      const idCityOrigin = idOrigin.toString();
      const idCityDestiny = idDestiny.toString();

      const result = await getMultiPromises<ResponseDistrictListAPI>([
        getOnlyDistrictsByCityServices(idCityOrigin),
        getOnlyDistrictsByCityServices(idCityDestiny),
      ]);
      const { destiny, origin } = getOriginAndDestinyByPromiseSettled(result);

      dispatch(setAvisoOrigenes(origin));
      dispatch(setAvisoDestinos(destiny));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const resetNoticeSendDataThunk = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(resetSendData());
  };
};

export const saveNoticeByOfferFlow = (notice: MergeDetailNoticeModel) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setSelectTypeNotice(notice));
  };
};

export const getListOfOfferConditionsThunk = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setIsLoading(true));
    try {
      const {
        noticeV2ClientAorCO: { selectTypeNotice },
      } = getState();
      const {
        tipoaviso: { id },
      } = selectTypeNotice as DetailNoticeMoving;
      const { avisoNegociacionTipoData } = await getOfferCondicionsByIdNotice(
        id?.toString()
      );

      dispatch(
        setListOfOfferConditions(avisoNegociacionTipoData.avisoCondiciones)
      );
      dispatch(setIsLoading(false));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const preSelectDataByFilterThunk = (
  locationState: LocationDetailState
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    if (!locationState?.destiny) return;

    const { noticeV2ClientAorCO } = getState();
    const selectTypeNotice =
      noticeV2ClientAorCO.selectTypeNotice as NoticeDetailMultiDestinyModel;
    const destinyState = locationState?.destiny?.split(',');

    const destinos = selectTypeNotice.destinos.map((destiny) => {
      const selected = !!destinyState?.includes(destiny.nombre);
      return {
        ...destiny,
        selected,
      };
    });

    dispatch(setSelectTypeNotice({ ...selectTypeNotice, destinos }));
  };
};

export const updateDestinationsSelectsThunk = (
  destinations: NoticeDetailDestinyModel[]
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const { noticeV2ClientAorCO } = getState();
    const selectTypeNotice =
      noticeV2ClientAorCO.selectTypeNotice as NoticeDetailMultiDestinyModel;
    const destinos = selectTypeNotice.destinos.map((destiny) => {
      return {
        ...destiny,
        selected: !!destinations.find((item) => item.nombre === destiny.nombre),
      };
    });
    dispatch(setSelectTypeNotice({ ...selectTypeNotice, destinos }));
  };
};

interface ResponseMultiDestinyOffer
  extends ResponseOfferCore<ResponseNoticeOffer> {}
interface BodyNoticeOffer extends RequestOfferMultiDestinationService {}

export const saveOfferMultiDestinyThunk = (
  body: BodyOfferMultiDestinationDTO
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setIsLoading(true));
    try {
      const {
        noticeV2ClientAorCO: { selectTypeNotice },
      } = getState();

      const { id } = selectTypeNotice as NoticeDetailMultiDestinyModel;

      const data = requestMultidestinyAdapter(body, getState);

      const {
        avisoNegociacionData: { avisoNegociacion },
      } = await savingNoticeOffer<BodyNoticeOffer, ResponseMultiDestinyOffer>(
        data,
        id.toString()
      );

      dispatch(setDataResponse(avisoNegociacion));
      dispatch(setIsLoading(false));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const getListTypesPropertyThunk = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    try {
      const {
        tipoInmueblesData: { tipoInmuebles = [] },
      } = await getTypeInmuebleService();

      dispatch(setListTypeProperty(tipoInmuebles));
      dispatch(setIsLoading(false));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

// ======= Save In city ========
export const saveOriginInCityThunk = (body: NoticeOfferMovingInCitySlice) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const data = saveStorageInCityAdapter(body, getState);

      dispatch(fillSendNoticeData(data));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

// =============== Save Moving ===========
interface ResponseMovingOffer
  extends ResponseOfferCore<ResponseMovingNoticeOffer> {}
export const saveOfferMovingThunk = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setIsLoading(true));
    try {
      const {
        noticeV2ClientAorCO: { selectTypeNotice },
      } = getState();
      const { id } = selectTypeNotice as DetailNoticeMoving;
      const body = requestMovingAdapter(getState);

      const {
        avisoNegociacionData: { avisoNegociacion },
      } = await savingNoticeOffer<BodyOfferMovingDTO, ResponseMovingOffer>(
        body,
        id.toString()
      );

      dispatch(setDataResponse(avisoNegociacion));
      dispatch(setIsLoading(false));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

interface ResponseAuctionffer
  extends ResponseOfferCore<ResponseAuctionNoticeOffer> {}
// ============= save Auction ============
export const saveOfferAuctionThunk = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setIsLoading(true));
    try {
      const {
        noticeV2ClientAorCO: { selectTypeNotice },
      } = getState();
      const { id } = selectTypeNotice as NoticeDetailAuctionModel;
      const body = requestAuctionAdapter(getState);

      const {
        avisoNegociacionData: { avisoNegociacion },
      } = await savingNoticeOffer<BodyAuctionOfferDTO, ResponseAuctionffer>(
        body,
        id.toString()
      );

      dispatch(setDataResponse(avisoNegociacion));
      dispatch(setIsLoading(false));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};
