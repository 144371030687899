import { MY_OFFERTS_CUSTOMER_REQUEST_PATH } from '@/pages/customerRequest/shared/consts/routes';
import {
  CLIENT_TYPE,
  DRIVER_TYPE,
  IMG_PATH,
  SM_IMG,
  STATUS_FORBIDDEN,
} from '@/consts/defaultConsts';
import { FORMAT_DATE_DDMMYYYY } from '@/consts/formatsDate';
import { TEXT_ERROR_IMAGE_GENERIC } from '@/consts/textErrors';
import {
  HOME_PAHT,
  NOT_AUTHORIZED,
  PROFILE_NOTICE_OFFERT_LIST,
} from '@/consts/typeServicesPaths';
import {
  EditPhotosType,
  ResizeAllPhotosType,
  ResizePhotoType,
  ResizePhotosProps,
  ResponsiveImage,
  ResponsiveImageProperties,
} from '@/interfaces/globalInterface';
import {
  CustomerRequest,
  ICustumerRequestPhotos,
} from '@/models/customerRequest/customerRequestResponse';
import { NoticeDetailVehicleModel } from '@/models/notice/noticeModels';
import { Photos } from '@/models/noticeNegotation/noticeNegotationModel';
import { VehicleAPI } from '@/models/vehicle/vehicleModel';
import { PhotosPreLoaded } from '@/store/offertRedux/offert.interface';
import { IUser } from '@/store/userRedux/userReduxInterface';
import dayjs from 'dayjs';
import heic2any from 'heic2any';
import moment from 'moment';
import { FileError } from 'react-dropzone';
import { NavigateFunction } from 'react-router-dom';

export const searchTermInArray = (
  term: string,
  arr: Array<any>,
  propertyToSearch: string[]
) => {
  const search = term
    .trim()
    ?.toLocaleLowerCase()
    .replace(/\(/g, '\\(')
    .replace(/\)/g, '\\)');
  if (!search) return [];
  const result = arr.filter((value) => {
    return propertyToSearch.some((porperty) =>
      value[porperty]?.toLocaleLowerCase().match(search)
    );
  });

  return result;
};

export const cleanValueNumber = (value: string): string => {
  if (!value) return '';

  const newValue = value
    .trim()
    .replace(/^(0+)/g, '')
    .replace(/[^0-9]/g, '');

  return newValue;
};

export const validSpacialCharacter = (character: string) => {
  const rgx = new RegExp(/[a-z\s+]/gi); // valida si es una letra o espacio

  return rgx.test(character);
};

export const convertValueDestinyAndOrigin = (
  mainValue: string,
  detalle: string | undefined
) => {
  let currentValue = mainValue;
  if (detalle) {
    currentValue += ` (${detalle})`;
    currentValue = characterCut(currentValue, 40, '...)');
  }

  return currentValue;
};

export const characterCut = (str: string, len: number, strReplace = '...') => {
  if (str.length <= len) return str;

  const newStr = str.slice(0, len).concat(strReplace);

  return newStr;
};

export const hasAllArePriceEqual = (el: any, index: number, arr: any[]) => {
  if (index === 0) return true;

  return el.precio === arr[index - 1].precio;
};

export const formatDate = (currentDate: Date | string | null) => {
  if (!currentDate) return '';

  const date = new Date(currentDate);
  const options = { timeZone: 'UTC' };

  const formattedDate = new Intl.DateTimeFormat('es-PE', options).format(date);

  return formattedDate;
};

export const searchPropertyInObject = (
  arrayToSearch: string[],
  objectData: Record<string, any>
) => {
  const result = arrayToSearch.reduce((acc, el) => {
    return acc[el];
  }, objectData);

  return result || '';
};

export const removeAccents = (str: string) => {
  if (!str) return '';

  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const getNextPage = (
  currentPath: string,
  objectNextPage: Record<string, string>
): string | number => {
  const nextPage = objectNextPage[currentPath] || -1;

  return nextPage;
};

// non-decimal currency format
// el segundo parametro {formatCurrency}, espara que coloque el prefijo S/
// en el caso de no querer ese prefijo pero se requiere el formato para el valor, mandar en flase
export const currencyFormat = (value: number, formatCurrency = true) => {
  let options: Intl.NumberFormatOptions | undefined = {
    style: 'currency',
    currency: 'PEN',
    minimumFractionDigits: 0,
  };
  if (!formatCurrency) {
    options = undefined;
  }
  const formatter = new Intl.NumberFormat('es-PE', options);

  return formatter.format(value);
};

export const firstLetterUpperCase = (str: string) => {
  if (!str) return '';

  const newStr = str.charAt(0).toUpperCase() + str.slice(1);

  return newStr;
};

// Recorre un objecto y elimina los espacio en blanco de los valores tipo string!!
export const cleanEmptySpaceInObject = <T>(objectData: Record<any, any>): T => {
  const cleanData = Object.entries(objectData).reduce((acc, [key, value]) => {
    if (typeof value === 'string') {
      return {
        ...acc,
        [key]: value.replace(/\s+/g, ' '),
      };
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {});

  return cleanData as T;
};

// ==================================================
//                   Flow Helpers
// ==================================================
// Regresa {true} si el vehiculo esta activo, si no, regresa {false}
export const vehicleIsActive = (
  vehicle: VehicleAPI | NoticeDetailVehicleModel
) => {
  if (!vehicle) return false;

  return vehicle?.estado !== 'DESACTIVADO';
};

// Formatea la placa y le agrega el "-"
export const formatVehiclePlate = (plate: string) => {
  if (!plate) return '';
  const preparePlate = plate.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '');

  const rxg = /(\S{3})(\S{3})/;
  const newPlate = preparePlate.toUpperCase().replace(rxg, '$1-$2');

  return newPlate;
};

// ==================================================
//                   Dates
// ==================================================
export const stringDDMMYYYToDate = (
  dateString: string,
  separation = '/'
): dayjs.Dayjs => {
  const [day, month, year] = dateString.split(separation);

  return dayjs([month, day, year].join(separation));
};

// hour = 14:25 => 2:25 PM
export const stringHoursToFormatLT = (hour: string) => {
  if (!hour) return '';

  const [newHour, newMinute] = hour.split(':');

  const result = moment({
    hour: Number(newHour),
    minute: Number(newMinute),
  }).format('LT');

  return result;
};

// convert ENGLISH date to SPANISH date
export const stringDateToSpanish = (dateString: string) => {
  if (!dateString) return '';
  moment.defineLocale('es', {
    relativeTime: {
      future: 'en %s',
      past: 'hace %s',
      s: 'unos segundos',
      ss: '%d segundos',
      m: 'un minuto',
      mm: '%d minutos',
      h: 'una hora',
      hh: '%d horas',
      d: 'un día',
      dd: '%d días',
      M: 'un mes',
      MM: '%d meses',
      y: 'un año',
      yy: '%d años',
    },
  });
  return moment(dateString).locale('es').fromNow();
};

// las Apis de ofertas, reciben este formato de fecha al guardar!!
export const getFormatDateBeforeSend = (str: string) => {
  if (!str) return '';
  const formatDate = dayjs(str).format(FORMAT_DATE_DDMMYYYY);
  if (formatDate === 'Invalid Date') {
    return str;
  }
  return formatDate;
};

// ==================================================
//                   Promises
// ==================================================
export const getMultiPromises = async <T>(
  promises: Promise<T>[]
): Promise<PromiseSettledResult<T>[]> => {
  const result = await Promise.allSettled(promises);

  return result;
};

export const getRejectedReasonsInAllSetteld = <T>(
  results: PromiseSettledResult<T>[]
): T[] => {
  const isRejected = <T>(
    result: PromiseSettledResult<T>
  ): result is PromiseRejectedResult => result.status === 'rejected';
  const rejectedReasons = results
    .filter(isRejected)
    .map((result) => result.reason);

  return rejectedReasons;
};

export const getFulfilledInAllSetteld = <T>(
  results: PromiseSettledResult<T>[]
): T[] => {
  const isFulfilled = <T>(
    result: PromiseSettledResult<T>
  ): result is PromiseFulfilledResult<T> => result.status === 'fulfilled';
  const fulfilledValues = results
    .filter(isFulfilled)
    .map((result) => result.value);

  return fulfilledValues;
};

// ==================================================
//                   Images
// ==================================================
export const blobToFile = (theBlob: Blob, fileName: string): File => {
  let newFile: any = theBlob;

  newFile.lastModifiedDate = new Date();
  newFile.name = fileName;
  newFile.webkitRelativePath = '';

  return theBlob as File;
};

export const stringBase64ToBlob = async (blobURl: string) => {
  let blobRes = await fetch(blobURl);
  let blob = await blobRes.blob();

  return blob;
};

export const convertImageHeic = async (file: File, type = 'png') => {
  const blobURl = URL.createObjectURL(file);
  const blob = await stringBase64ToBlob(blobURl);
  const conversionResult = await heic2any({
    blob,
    toType: `image/${type}`,
    quality: 0.94,
  });
  const [fileName] = file.name.split('.');

  const result = blobToFile(conversionResult as Blob, `${fileName}.${type}`);

  return result;
};

export const blobToBase64 = (blob: Blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

// ==================================================
//                   CREATOR MEDIA QUERYS
// ==================================================
export const styleCreatorMediaQuerys = (responsive: ResponsiveImage[]) => {
  const result = responsive?.reduce((acc, { cssProperties, mediaMinWidth }) => {
    const values = styleProperties(cssProperties);
    const media = {
      [`@media (min-width: ${mediaMinWidth})`]: {
        ...values,
      },
    };
    return { ...acc, ...media };
  }, {});

  return result;
};

const styleProperties = (cssProperties: ResponsiveImageProperties[]) => {
  const styleProperties = cssProperties.reduce((acc, { property, value }) => {
    acc = {
      ...acc,
      [property]: value,
    };

    return acc;
  }, {});

  return styleProperties;
};

// ==================================================
//              borrar el dominio de la url
// ==================================================

export const deleteDomainUrl = (
  url: string,
  inicio?: boolean,
  num?: number
): string => {
  inicio = inicio ?? true;

  let result = url
    ? url
        .replace(/(^\w+:|^)\/\//, '')
        .split('/')
        .slice(1)
        .join('/')
    : '';
  // borrar la cantidad de carpetas que se le indique
  if (num) {
    result = result.split('/').slice(num).join('/');
  }
  // devolver / si no tiene al inicio
  if (inicio) {
    if (result.slice(0, 1) !== '/') result = '/' + result;
  }

  return result;
};

// ====================================================================
//          Manejo de las imagenes en la edición de Solicitud
// ====================================================================

export interface HandleImagesProps {
  savedData: CustomerRequest;
  setImagesInput: React.Dispatch<React.SetStateAction<EditPhotosType[]>>;
  setImagesFromDB: React.Dispatch<React.SetStateAction<EditPhotosType[]>>;
}

export const handleImages = async (props: HandleImagesProps) => {
  const { savedData, setImagesFromDB, setImagesInput } = props;
  const currentImages = await Promise.allSettled(
    (savedData as CustomerRequest).fotos.map(async (item, index) => {
      return await fetch(item.imagenBase64 as string)
        .then((res) => {
          return res.blob();
        })
        .then(async (blob) => {
          const base64 = await blobToBase64(blob);
          return base64;
        })
        .catch((err: any) => {
          return err;
        });
    })
  );

  const imagesDecoded = getFulfilledInAllSetteld(currentImages);

  const result = Imagesformat(imagesDecoded);

  setImagesInput(result);
  setImagesFromDB(result);
};

export const Imagesformat = (images: unknown[]) => {
  const result = images.map((item, index) => ({
    blob: item,
    file: `Imagen ${index + 1}`,
  })) as EditPhotosType[];

  return result;
};

export const ImgBase64Format = (fotos: ICustumerRequestPhotos[]) => {
  if (!fotos) return [];

  const result = fotos.map((item, index) => ({
    blob: `data:image/jpeg;base64,${item.imagenBase64}`,
    file: `Imagen ${index + 1}`,
  }));

  return result;
};

// ==================================================
//                   Manejo de Url
// ==================================================
// ---------- Creador de Slug para las url ----------
export const slugify = (str: string) => {
  return String(str)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
};

// ==================================================
//                   Comprar urls de paginas
// ==================================================

export const compareUrls = (url1: string, url2: string) => {
  const url1WithoutDomain = deleteDomainUrl(url1);
  const url2WithoutDomain = deleteDomainUrl(url2);

  return url1WithoutDomain !== url2WithoutDomain;
};

export const extractCodeStateInError = (error: any): Number => {
  const { cause } = error;
  const causeErrorCode = cause ? cause.message.substring(0, 3) : '000';
  return Number(causeErrorCode);
};

// ==================================================
//           Cambiar el tamano de la foto
// ==================================================

export const IMG_PATH_RESIZED =
  (IMG_PATH as string).substring(0, IMG_PATH.length - 1) + '-resized/';

export type PhotosType = {
  photos: Photos[] | ICustumerRequestPhotos[];
};

export const reziseAllPhotos = (props: ResizeAllPhotosType) => {
  const {
    photos,
    newFormat = SM_IMG,
    imgPath = IMG_PATH,
    imgPathResized = IMG_PATH_RESIZED,
  } = props;

  const result = photos.map((item) => ({
    id: item.id,
    urlImagen: resizePhoto({
      photo: item.urlImagen,
      newFormat,
      imgPath,
      imgPathResized,
    }),
    idsolicitud: item.idsolicitud || 0,
  }));

  return result;
};

export const resizePhotosPreloaded = (
  props: ResizePhotosProps
): PhotosPreLoaded[] => {
  const {
    photos,
    newFormat = SM_IMG,
    imgPath = IMG_PATH,
    imgPathResized = IMG_PATH_RESIZED,
  } = props;

  return photos.map((item) => ({
    name: item.name,
    url: resizePhoto({
      photo: !item.url.startsWith(imgPath) ? imgPath + item.url : item.url,
      newFormat,
      imgPath,
      imgPathResized,
    }),
  }));
};

export const resizePhoto = (props: ResizePhotoType) => {
  const {
    photo,
    newFormat = SM_IMG,
    imgPath = IMG_PATH,
    imgPathResized = IMG_PATH_RESIZED,
  } = props;
  let photoUrl = photo;
  const isNotFromS3 = !photo.includes('_') || !photo.includes('-');

  if (!photo.startsWith(imgPath)) {
    photoUrl = imgPath + photo;
  }

  if (isNotFromS3) {
    return photoUrl;
  }

  if (photo.startsWith(IMG_PATH_RESIZED)) {
    if (photo.includes(SM_IMG)) {
      photoUrl = photo.replace(SM_IMG, '.');
    } else {
      photoUrl = photo;
    }
  }

  const index = photoUrl.lastIndexOf('.');
  const url = photoUrl.split('');
  url[index] = newFormat;

  return url.join('').replace(imgPath, imgPathResized);
};

interface ValidateAuthorizationByCodeProp {
  errorCode: number;
  navigate: NavigateFunction;
  pathNavigate?: string;
}

export const validateAuthorizationByCode = (
  props: ValidateAuthorizationByCodeProp
) => {
  const { errorCode, navigate, pathNavigate = NOT_AUTHORIZED } = props;

  if (!errorCode) return;

  if (errorCode === STATUS_FORBIDDEN) {
    navigate(pathNavigate);
  }
};

// ==================================================
//                   Images
// ==================================================
// Validador de extensiones permitidas
export const validatorExtensionImage = (
  acceptedFiles: File
): FileError | null => {
  const validExtensions = ['jpeg', 'png', 'jpg', 'heic'];
  const name = acceptedFiles.name?.toLocaleLowerCase();
  const isValid = validExtensions.find((extension) => name.includes(extension));

  if (!isValid) {
    return {
      code: '500',
      message: TEXT_ERROR_IMAGE_GENERIC,
    };
  }

  return null;
};

export const validateValueInput = (value: string) => {
  if (value.trim().length === 0) return '';
  if (value.startsWith('.') || value.startsWith(',')) return '';

  const result = value
    .replace(/[>_;{}@!^&\/\\#+()=$~%'":*?<]/g, '')
    .replace(',,', ',')
    .replace('..', '.')
    .replace('.,', '.')
    .replace(',.', ',')
    .replace('[', '')
    .replace(']', '')
    .replace('  ', ' ')
    .replace(' .', '.')
    .replace('. ', '')
    .replace('-', '');

  return result;
};
// ==================================================
//                   Offert Utils
// ==================================================
export const getErrorButtonLinkNotFound = (user: IUser) => {
  const { tipousuario = '' } = user;
  if (tipousuario === CLIENT_TYPE) {
    return PROFILE_NOTICE_OFFERT_LIST;
  } else if (tipousuario === DRIVER_TYPE) {
    return MY_OFFERTS_CUSTOMER_REQUEST_PATH;
  } else {
    return HOME_PAHT;
  }
};

// Navega un path atras en la url!!
// input: // http://localhost:4200/solicitudes/oferta/840/mensaje
// out: // http://localhost:4200/solicitudes/oferta/840
export const getUrlWithoutLastPath = (url: string, separator = '/') => {
  if (!url) return '';

  const lastIndex = url.lastIndexOf(separator);

  return url.slice(0, lastIndex);
};

// ==================================================
//                   Request
// ==================================================

/**
 * La función `loadAbort` devuelve un nuevo objeto `AbortController`.
 * @returns La función `loadAbort` devuelve una instancia de la clase `AbortController`.
 */
export const loadAbort = () => {
  const controller = new AbortController();
  return controller;
};
