import EmptyListData from "@/components/emptyListData/EmptyListData";
import SkeletonInfoCardList from "@/components/skeletons/SkeletonInfoCardList";
import { MyNotice } from "@/models/notice/noticeModels";
import { Grid, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { typeNoticeByIdObject } from "../consts/NoticeListConst";
import NoticeListCard from "./Card/Index";

export type ListProps = {
  noticeList: MyNotice[];
  isLoading: boolean;
};

const LinkWrapper = styled(Link)(
  () => `
    text-decoration: none;
  `
);

const List = ({ noticeList, isLoading }: ListProps) => {
  if (!noticeList.length && !isLoading) return <EmptyListData />;

  const handlePathNavigate = (data: MyNotice) => {
    const { url } = typeNoticeByIdObject[data.idTipoAviso];
    const path = url.replace(":id", data.id.toString());

    return path;
  };

  return (
    <>
      {noticeList?.map((data, index) => (
        <Grid item key={`card-${index}`}>
          <LinkWrapper to={handlePathNavigate(data)} state={{
                hasBackNavigate: true,
          }}>
            <NoticeListCard data={data} />
          </LinkWrapper>
        </Grid>
      ))}
      {isLoading && <SkeletonInfoCardList />}
    </>
  );
};

export default List;
