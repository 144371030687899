import { ServiceRejectedSvg } from "@/assets/svg";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { NEGOTATION_CLIENT_PATH } from "@/consts/negotationFlowPath";
import { serviceRejectText, showOtherNegTextBtn, textRejectConfimDriver, textRejectConfirmClient } from "@/consts/noticeConsts";
import { MY_PENDING_AGREEMENTS } from "@/consts/typeServicesPaths";
import { Box, Container, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const DeclineNegotation = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isClient = pathname.startsWith(NEGOTATION_CLIENT_PATH);

  const handleRedirection = () => {
    navigate(MY_PENDING_AGREEMENTS);
  };

  return (
    <Container maxWidth="xs">
      <Stack gap={2} alignItems={"center"} justifyContent={"center"} mt={5}>
        <ServiceRejectedSvg />
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <Typography variant="h5low" sx={{ fontWeight: 700 }}>
            {serviceRejectText}
          </Typography>
        </Box>
        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            fontWeight: 400,
          }}
        >
          {
            isClient ?
              textRejectConfimDriver
              :
              textRejectConfirmClient
          }
        </Typography>

        <ButtomLoader
          fullWidth
          variant="contained"
          type="submit"
          onClick={() => handleRedirection()}
        >
          {showOtherNegTextBtn}
        </ButtomLoader>
      </Stack>
    </Container>
  );
};

export default DeclineNegotation;
