import { Typography, styled, Stack } from "@mui/material";
import React, { FC, useId, useState } from "react";
import BannerModal from "@/components/modals/BannerModal/BannerModal";
import { IMultiPrices, SelectCity } from "@/store/locationRedux/selectLocation";

const SubTitle = styled(Typography)(
  ({ theme }) => `
    color: rgba(0, 0, 0, 0.87);
    font-size: ${theme.typography.label1.fontSize};
    font-family: ${theme.typography.label1.fontFamily};
    font-weight: ${theme.typography.label1.fontWeight};
    line-height: ${theme.typography.label1.lineHeight};
    margin-bottom: 16px;
  `
);

const ListItem = styled(Typography)(
  ({ theme }) => `
  color: rgba(0, 0, 0, 0.87);
    font-size: ${theme.typography.body1.fontSize};
    font-family: ${theme.typography.body1.fontFamily};
    font-weight: ${theme.typography.body1.fontWeight};
    line-height: ${theme.typography.body1.lineHeight};  
    &.separator {
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #0000001e;
    }
  `
);

const Description = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.body1.fontSize};
    line-height: ${theme.typography.body2.lineHeight};
  `
);

const TextLink = styled(Description)(
  ({ theme }) => `
    color: ${theme.palette.accent.main};
    font-weight: bold;
  `
);

type Props = {
  multiPrices: IMultiPrices[];
  selectedOrigin: SelectCity;
  onModalEdit: () => void;
  onModalRemoveMultiPrice: () => void;
};

const SectionMultiPrices: FC<Props> = ({
  multiPrices,
  selectedOrigin,
  onModalEdit,
  onModalRemoveMultiPrice,
}) => {
  const id = useId();
  const [showModal, setShowModal] = useState(false);

  const handleArraySort = () => {
    const newArray = [...multiPrices];
    const arrSort = newArray?.sort(
      (a, b) => Number(a.precio) - Number(b.precio)
    );

    return arrSort;
  };

  const handleAddClassSeparator = (
    multiPrice: IMultiPrices,
    i: number,
    currentArr: IMultiPrices[]
  ) => {
    const index = i + 1;

    if (!!currentArr[index]) {
      if (Number(multiPrice.precio) < Number(currentArr[index].precio)) {
        return "separator";
      }
    } else if (index === currentArr.length) {
      return "separator";
    }

    return "";
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <BannerModal
        open={showModal}
        onClose={handleCloseModal}
        title="Precio único"
        content="Si regresa a definir un precio único, se perderán todos los precios ingresados hasta el momento. ¿Desea continuar?"
        option1={{ title: "No", onClick: handleCloseModal }}
        option2={{ title: "Sí, continuar", onClick: onModalRemoveMultiPrice }}
        color="org"
      />
      {!!multiPrices?.length && (
        <>
          <SubTitle>Precios por distrito (S/)</SubTitle>

          {handleArraySort()?.map((multiPrice, i, currentArr) => (
            <ListItem
              key={id + i}
              className={handleAddClassSeparator(multiPrice, i, currentArr)}
            >
              {`${selectedOrigin.nombre} a ${
                multiPrice?.distrito?.nombre
              }: S/ ${Number(multiPrice.precio)}`}
            </ListItem>
          ))}
        </>
      )}
      <Stack
        justifyContent="center"
        display="flex"
        marginTop="16px"
        marginBottom="16px"
        sx={{ cursor: "pointer" }}
        onClick={onModalEdit}
      >
        <TextLink align="center" variant="caption">
          Editar precios
        </TextLink>
      </Stack>

      <Stack
        justifyContent="center"
        display="flex"
        sx={{ cursor: "pointer" }}
        onClick={() => setShowModal(true)}
      >
        <Description align="center" variant="body2">
          ¿Quieres ingresar un precio para todos los distritos?
          <TextLink align="center" variant="caption">
            {" "}
            Hazlo aquí
          </TextLink>
        </Description>
      </Stack>
    </>
  );
};

export default SectionMultiPrices;
