import DropZone from "@/components/DrowZone/DropZone";
import LayoutFlow from "@/components/LayoutFlow/LayoutFlow";
import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import BannerModal from "@/components/modals/BannerModal/BannerModal";
import LabelRequired from "@/components/utils/LabelRequired";
import { DRIVER_TYPE } from "@/consts/defaultConsts";
import { TEXT_ERROR_IMAGE_GENERIC } from "@/consts/textErrors";
import { Typography, styled } from "@mui/material";
import HeaderFlow from "../../components/HeaderFlow";
import useClientProfileHook from "./hook";

const PrimaryText = styled(Typography)(
  () => `
    margin-top: 16px;
    margin-bottom: 16px;
  `
);

const From = styled("form")({
  marginTop: "24px",
});

const ClientProfile = () => {
  const {
    description,
    hasError,
    showAlert,
    isLoading,
    error,
    handleOnSelectImage,
    handleOnError,
    handleOnchange,
    handleSubmit,
    handleDisabled,
    handleOnBlur,
    handleShowImageDefault,
    handleShowTitle,
    handleDescription,
    handleBackNavigation,
    handleGetBack,
    setShowAlert,
    handleGetInputText,
    user,
  } = useClientProfileHook();

  const isDriver = user?.tipousuario === DRIVER_TYPE;
  const { label, placeholder } = handleGetInputText();

  return (
    <LayoutFlow>
      <HeaderFlow
        title={handleShowTitle()}
        customGoBack={handleBackNavigation}
      />

      <>
        {!!hasError && (
          <StandardAlert severity="error">
            <Typography variant="body1" color="error">
              {error || TEXT_ERROR_IMAGE_GENERIC}
            </Typography>
          </StandardAlert>
        )}
      </>

      <BannerModal
        open={showAlert}
        onClose={() => setShowAlert(false)}
        title="Cambios sin guardar"
        content="Aún no has guardado los cambios realizados. ¿Deseas salir?"
        option1={{
          title: "No, quedarme",
          onClick: () => setShowAlert(false),
        }}
        option2={{
          title: "Sí, salir",
          onClick: () => handleGetBack(),
        }}
        color="org"
        sx={{
          width: "322px",
          height: "268px",
        }}
        sxAccion={{
          padding: "0px",
        }}
      />

      <PrimaryText variant="body1">
        <LabelRequired str={handleDescription()} required={isDriver} />
      </PrimaryText>

      <DropZone
        imageDefault={handleShowImageDefault()}
        onError={handleOnError}
        onSelect={handleOnSelectImage}
      />

      <From onSubmit={handleSubmit}>
        <Input
          type="text"
          multiline
          fullWidth
          value={description}
          onChange={handleOnchange}
          name="description"
          onBlur={handleOnBlur}
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            rows: 4,
          }}
          inputProps={{
            maxLength: 200,
          }}
          helperText={`${description?.length}/200`}
          label={label}
          placeholder={placeholder}
        />
        <ButtomLoader
          disabled={handleDisabled()}
          sx={{ margin: "24px 0" }}
          type="submit"
          isLoading={isLoading}
        >
          {isDriver ? "SIGUIENTE" : "FINALIZAR"}
        </ButtomLoader>
      </From>
    </LayoutFlow>
  );
};

export default ClientProfile;
