import { ListItems } from '@/components/noticeListItem/typeNoticeListITem';
import { INoticeCreateListItem } from '@/pages/noticeV2/NoticeList/types';
import { IAvisotipo } from '@/services/noticeServices/NoticeListServices/NoticeListTypes';

export const prepareListNoticeData = (
  listData: ListItems[],
  apiLisData: Array<any>
) => {
  const listServices = listData.map((item: any) => {
    const { nombre, ...rest } = apiLisData.find(
      (listItem: any) => listItem.id === item.id
    );

    if (nombre) {
      return {
        ...item,
        title: nombre,
        ...rest,
      };
    }

    return item;
  });

  return listServices;
};

export const replaceValue = (
  str: string,
  search: string,
  value: string,
  searchId?: string,
  valueId?: string
) => {
  const newString = str.replace(search, value);
  let valueToReturn = newString;

  if (searchId) {
    valueToReturn = newString.replace(searchId as string, valueId as string);
  }

  return valueToReturn;
};


export const RestructureNoticeListData = (noticeList: INoticeCreateListItem[], avisoTipos: IAvisotipo[]): INoticeCreateListItem[] => {
  const idsAvisos = avisoTipos.map((item: IAvisotipo) => item.id);
  const listServices = noticeList.map((item: INoticeCreateListItem) => {
    const { nombre, ...rest } = avisoTipos.find(
      (listItem: IAvisotipo) => listItem.id === item.id
    ) || {};

    if (nombre) {
      return {
        ...item,
        title: item.title || nombre,
        ...rest,
      };
    }

    return item;
  });

  // quitar de la lista si el id de noticeList no existe en avisotipos
  listServices.forEach((item: INoticeCreateListItem, index: number) => {
    if (!idsAvisos.includes(item.id)) {
      listServices.splice(index, 1);
    }
  });

  return listServices;
}