import { ISetAgreementToAcceptBody } from '@/models/userAuth/agrementToAccept';
import { UsuarioAPI } from '@/models/userAuth/user';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISetNotificationBody } from '../../models/userAuth/notification';
import {
  IInitialState,
  ISelectedVehicle,
  IUser,
  IVehicleList,
} from './userReduxInterface';

const initialState: IInitialState = {
  user: {},
  vehicles: [],
  selectedVehicle: {},
  isLoading: false,
  error: null,
  errorMsg: '',
  refrigeratedLoad: 0,
  successMsg: '',
  listVehicles: [],
  selectedVehicleList: {},
  returnLogin: false,
  notifications: [],
  agreementToAccepts: [],
  isLoadingPagination: false,
  currentPage: 0,
  totalPages: 0,
  totalItems: 0,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setUser: (state, { payload }: PayloadAction<IUser | UsuarioAPI>) => {
      state.user = payload;
      state.error = null;
      state.isLoading = false;
    },
    setVehicles: (state, { payload }: PayloadAction<Array<any>>) => {
      state.vehicles = payload;
      state.error = null;
      state.isLoading = false;
    },
    setSelectedVehicle: (
      state,
      { payload }: PayloadAction<Object | ISelectedVehicle | {}>
    ) => {
      state.selectedVehicle = payload;
    },
    setEmptyVehicle: (state) => {
      state.selectedVehicle = initialState.selectedVehicle;
    },
    setTypeUser: (state, { payload }: PayloadAction<string>) => {
      state.user = { ...state.user, tipousuario: payload };
    },
    setError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload?.message || payload;
      state.isLoading = false;
    },
    setErrorMsg: (state, { payload }: PayloadAction<string>) => {
      state.errorMsg = payload;
      state.isLoading = false;
    },
    logout: () => {},
    setSuccessMsg: (state, { payload }: PayloadAction<string>) => {
      state.successMsg = payload;
      state.isLoading = false;
    },
    setListVehicles: (state, { payload }: PayloadAction<IVehicleList[]>) => {
      state.listVehicles = payload;
      state.isLoading = false;
    },
    setSelectedVehicleList: (
      state,
      { payload }: PayloadAction<IVehicleList | {}>
    ) => {
      state.selectedVehicleList = payload;
      state.isLoading = false;
    },
    setReturnLogin: (state, { payload }: PayloadAction<boolean>) => {
      state.returnLogin = payload;
    },
    setNotifications: (
      state,
      { payload }: PayloadAction<ISetNotificationBody>
    ) => {
      state.notifications = payload.notificaciones || [];
      state.totalItems = payload.totalItems;
      state.totalPages = payload.totalPages;
      state.currentPage = payload.currentPage;
      state.isLoading = false;
      state.isLoadingPagination = false;
    },
    setAgreementToAccepts: (
      state,
      { payload }: PayloadAction<ISetAgreementToAcceptBody>
    ) => {
      state.agreementToAccepts = payload.acuerdosporaceptar || [];
      state.totalItems = payload.totalItems;
      state.totalPages = payload.totalPages;
      state.currentPage = payload.currentPage;
      state.isLoading = false;
      state.isLoadingPagination = false;
    },
    setLoadingPagination: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingPagination = payload;
    },
    cleanPagination: (state) => {
      state.agreementToAccepts = initialState.agreementToAccepts;
      state.isLoadingPagination = initialState.isLoadingPagination;
      state.currentPage = initialState.currentPage;
      state.totalPages = initialState.totalPages;
      state.totalItems = initialState.totalItems;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setUser,
  setVehicles,
  setSelectedVehicle,
  setTypeUser,
  setError,
  logout,
  setSuccessMsg,
  setListVehicles,
  setSelectedVehicleList,
  setErrorMsg,
  setReturnLogin,
  setEmptyVehicle,
  setNotifications,
  setLoadingPagination,
  setAgreementToAccepts,
  cleanPagination,
} = userSlice.actions;
