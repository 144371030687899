import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import { useGlobalButtonsV2 } from "@/pages/noticeV2Negotation/shared/hooks/useGlobalButtons";
import { ContainerButtonsV2 } from "./buttonsStyles";
import { ID_MOVING_FLOW } from "@/consts/defaultConsts";

export const CounterOfferButtons = () => {
  const { isLoadingNoticeV2, notice } = useGetData();
  const {
    handleAcceptButtonV2,
    handleManageModalDeclineV2,
    handleDriverCounterOfferButtonV2,
  } = useGlobalButtonsV2();
  const isOwnerNotice = !!notice?.flgIsOwner;
  const isMoving = notice?.idtipoaviso === ID_MOVING_FLOW;
  const counterOffetBtnText = isMoving ? "cotizar" : "contraofertar";

  return (
    <ContainerButtonsV2>
      { !isMoving &&
        <ButtomLoader
          fullWidth
          variant="contained"
          onClick={() => handleAcceptButtonV2()}
          isLoading={isLoadingNoticeV2}
          disabled={isLoadingNoticeV2 || !isOwnerNotice}
        >
          aceptar
        </ButtomLoader>
      }
      <ButtomLoader
        fullWidth
        variant={!isMoving ? "outlined": "contained"}
        type="submit"
        isLoading={false}
        onClick={() => handleDriverCounterOfferButtonV2()}
        disabled={false}
        sx={{ borderColor: "#primary" }}
      >
        {counterOffetBtnText}
      </ButtomLoader>

      <ButtomLoader
        fullWidth
        variant="text"
        type="submit"
        onClick={() => handleManageModalDeclineV2()}
        isLoading={isLoadingNoticeV2}
        disabled={isLoadingNoticeV2 || !isOwnerNotice}
      >
        rechazar
      </ButtomLoader>
    </ContainerButtonsV2>
  );
};
