interface Props {
    style: {
        color?: string
        height: string
        width: string
    }
}
const MovingIcon = (props: Props) => {

    const { color = "#757575", height, width } = props?.style;
    return (
        <svg width={width} height={height} viewBox={`0 0 72 72`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M36.5672 35.4289V23.0206L38.4284 24.2614C38.7386 24.5716 39.0488 24.5716 39.359 24.5716C39.6693 24.5716 40.2897 24.2614 40.5999 23.9512C41.2203 23.3308 40.9101 22.4002 40.2897 22.09L34.0855 17.4368V13.0939C34.0855 12.1633 33.4651 11.8531 32.8447 11.8531C31.914 11.8531 31.6038 12.4735 31.6038 13.0939V15.5756L25.3997 11.2327C24.7792 10.9224 24.1588 10.9224 23.8486 11.2327L8.64839 22.09C8.02797 22.7104 7.71776 23.3308 8.33818 23.9512C8.9586 24.5716 9.57902 24.8818 10.1994 24.2614L12.0607 23.0206V35.4289C12.0607 36.3596 12.6811 36.6698 13.3015 36.6698H35.3263C36.257 36.98 36.5672 36.3596 36.5672 35.4289ZM26.3303 34.1881H22.2976V27.3635H26.3303V34.1881ZM33.7753 34.1881H28.812V26.1227C28.812 25.1921 28.1915 24.8818 27.5711 24.8818H20.7465C19.8159 24.8818 19.5057 25.5023 19.5057 26.1227V34.1881H14.5423V21.1593L24.1588 14.0245L33.7753 21.1593V34.1881Z" fill={color} />
            <path d="M64.4855 46.2864L58.2813 41.9434V37.6005C58.2813 36.6699 57.6609 36.3597 57.0405 36.3597C56.4201 36.3597 55.7996 36.9801 55.7996 37.6005V40.0822L49.5955 35.7392C48.975 35.429 48.3546 35.429 48.0444 35.7392L32.8442 46.5966C32.2238 47.217 31.9136 47.8374 32.534 48.4578C33.1544 49.0782 33.7748 49.3884 34.3952 48.768L36.2565 47.5272V59.9355C36.2565 60.8661 36.8769 61.1764 37.4973 61.1764H59.5221C60.4528 61.1764 60.763 60.5559 60.763 59.9355V47.217L62.6242 48.4578C62.9344 48.768 63.2446 48.768 63.5549 48.768C63.8651 48.768 64.4855 48.4578 64.7957 48.1476C65.1059 47.5272 65.1059 46.5966 64.4855 46.2864ZM50.5261 58.3845H46.4934V51.5599H50.5261V58.3845ZM58.2813 58.3845H53.318V50.3191C53.318 49.3884 52.6976 49.0782 52.0771 49.0782H45.2525C44.3219 49.0782 44.0117 49.6986 44.0117 50.3191V58.3845H39.0484V45.3557L48.6648 38.2209L58.2813 45.3557V58.3845Z" fill={color} />
            <path d="M30.0534 54.6619H17.9553V41.9434C17.9553 41.0127 17.3349 40.7025 16.7145 40.7025C15.7838 40.7025 15.4736 41.3229 15.4736 41.9434V55.9027C15.4736 56.8334 16.0941 57.1436 16.7145 57.1436H30.3637C31.2943 57.1436 31.6045 56.5232 31.6045 55.9027C31.6045 55.2823 30.9841 54.6619 30.0534 54.6619Z" fill={color} />
            <path d="M39.9798 16.8165H55.4902V30.7759C55.4902 31.7065 56.1106 32.0167 56.731 32.0167C57.3514 32.0167 57.9718 31.3963 57.9718 30.7759V15.5757C57.9718 14.6451 57.3514 14.3348 56.731 14.3348H39.6696C38.7389 14.3348 38.4287 14.9553 38.4287 15.5757C38.4287 16.1961 39.0491 16.8165 39.9798 16.8165Z" fill={color} />
        </svg>

    )
}

export default MovingIcon
