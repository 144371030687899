export const textAlert = 'El transportista lleva sólo carga refrigerada.';

export const textImages = 'Fotos de la carga (máx. 3)';

export const inputDescribe = {
  label: 'Descripción de la carga',
  placeholder: 'Información sobre tu carga que necesitan los transportistas.',
  name: 'cargoDescription',
  type: 'text',
  multiline: true,
  fullWidth: true,
  InputLabelProps: {
    shrink: true,
  },
  InputProps: {
    rows: 2,
  },
};

export const inputWeight = {
  label: 'Peso estimado (kilos)',
  placeholder: 'Ingresa el peso estimado de tu carga',
  name: 'pesoEstimado',
  type: 'number',
  autoComplete: 'off',
  fullWidth: true,
  InputLabelProps: {
    shrink: true,
  },
};
