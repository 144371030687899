import { styled, Card } from "@mui/material";

export const StyleCardHorizont = styled(Card)(({ theme }) => ({
  height: "64px",
  width: "100%",
  padding: "0px 16px",
  boxSizing: "border-box",
  borderRadius: "10px",
  boxShadow: theme.palette.customShadow.main,
}));
