import {
  ID_IN_CITY,
  ID_MOVING_FLOW,
  ID_MULTI_DESTINY,
} from "@/consts/defaultConsts";
import { Grid, styled, Typography } from "@mui/material";
import { TypeVehicleAPI } from "../../../../models/vehicle/vehicleModel";
import { configDetailTypeFlow } from "./config";
import IconsSvg from "../IconsSvg";
import Icon, { IconTypes } from "@/utils/Icon";

const Caption = styled(Typography)(
  ({ theme }) => `
    font-size: 12px;
    font-weight: 100;
    color: ${theme.palette.borderGray.contrastText};
    `
);

const IS_HOURS = 1;
const IS_POINT = 2;
const IS_IN_CITY = ID_IN_CITY;
const IS_BETWEEN_CITIES = 2;
interface Props extends Omit<TypeVehicleAPI, "urlImagen"> {
  flghoraopunto?: number;
  typeMoving?: number;
}

const DetailTypeFlow = ({ nombre, id, flghoraopunto, typeMoving }: Props) => {
  const handleName = () => {
    let result = nombre;

    if (flghoraopunto === IS_HOURS) result = "Multidestino por hora";
    else if (flghoraopunto === IS_POINT) result = "Multidestino por punto";
    else if (typeMoving === IS_IN_CITY) result = "Mudanza en la ciudad";
    else if (typeMoving === IS_BETWEEN_CITIES)
      result = "Mudanza entre ciudades";

    return result;
  };

  const handleGetIcon = () => {
    if (flghoraopunto === IS_POINT && id === ID_MULTI_DESTINY) {
      return configDetailTypeFlow[id].iconPoints;
    } else if (id === ID_MOVING_FLOW && typeMoving === IS_POINT) {
      return configDetailTypeFlow[id].iconInCity;
    }

    return configDetailTypeFlow[id || 1].icon;
  };

  return (
    <Grid container gap={1} alignItems="center">
      <Icon name={handleGetIcon() as IconTypes} size={20}/>
      <Caption variant="body2">{handleName()}</Caption>
    </Grid>
  );
};

export default DetailTypeFlow;
