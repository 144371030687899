import TwoAvatars from "@/components/userRating/TwoAvatars";
import HistoryRating from "@/components/userRating/historyRating/HistoryRating";
import { RatingStyles } from "@/components/userRating/styledUserRating";
import { useAppSelector } from "@/hooks/useReducer";
import { characterCut, resizePhoto } from "@/utils/helpers";
import {
  Avatar,
  Box,
  Grid,
  Rating,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";

import { DRIVER_TYPE, IMG_PATH } from "@/consts/defaultConsts";
import { UsuarioAPI } from "@/models/userAuth/user";

import { DetailsNegotationV2 } from "@/models/notice/noticeV2NegotationModels";
import StarIcon from "@mui/icons-material/Star";
import useGetData from "../shared/hooks/useGetData";
import { dataDriverFormatV2, dataProfileFormatV2 } from "../shared/utils/utils";

type Props = {
  urlProfile: string;
  urlTruck?: string;
  showTwoAvatars: boolean;
  userName: string;
  containerSx?: SxProps<Theme>;
  viewRating?: boolean;
  userInfo?: {
    calificacion: number;
    calificacionTexto: string;
    id: number;
    nombre: string;
    perfilUrl: string;
  };
};

const SingleUserNegV2 = (props: Props) => {
  const {
    urlProfile,
    containerSx,
    urlTruck,
    showTwoAvatars = false,
    viewRating = true,
    userInfo,
  } = props;
  const { notice } = useGetData();
  const [openRating, setOpenRating] = useState<boolean>(false);
  const {
    user: { user },
  } = useAppSelector((state) => state);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const { avatarStyles, gridContainerStyles, gradeTextStyles } = RatingStyles;

  const handleData = () => {
    if ((user as UsuarioAPI).tipousuario === DRIVER_TYPE) {
      return dataProfileFormatV2(notice as DetailsNegotationV2);
    }
    return dataDriverFormatV2(notice as DetailsNegotationV2);
  };

  const urlUser = !!urlProfile
    ? resizePhoto({ photo: IMG_PATH + urlProfile })
    : IMG_PATH + urlProfile;

  const onOpenRating = () => {
    if (!viewRating) return;
    setOpenRating(true);
  };

  const onCloseRating = () => {
    setOpenRating(false);
  };

  return (
    <>
      <Grid
        container
        sx={{
          alignItems: "center",
          maxWidth: matches ? "65%" : "100%",
          cursor: viewRating ? "pointer" : "default",
          ...containerSx,
        }}
        gap={0}
        onClick={onOpenRating}
      >
        <Grid item xs={3} sx={{display: "flex", flexBasis: showTwoAvatars ? "" : "18% !important"}}>
          {showTwoAvatars ? (
            <TwoAvatars primaryAvatar={urlUser} secondAvatar={urlTruck} />
          ) : (
            <Avatar src={urlUser} sx={avatarStyles} />
          )}
        </Grid>
        <Grid item xs={9} sx={{
          ...gridContainerStyles,
          ml: 0,
          mt: 0,
          }}>
          <Typography
            sx={{
              color: viewRating ? "primary.main" : "",
            }}
            variant="h6"
          >
            {characterCut(userInfo?.nombre || "", matches ? 35 : 25)}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Rating
              readOnly
              name="customized-10"
              defaultValue={2}
              max={1}
              size="small"
              sx={!!userInfo?.calificacion ? {} : { color: "#9e9e9e" }}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            <Typography variant="caption" sx={gradeTextStyles}>
              {!!userInfo?.calificacion && userInfo?.calificacion}{" "}
              {!!userInfo?.calificacionTexto && `(${userInfo?.calificacionTexto})`}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <HistoryRating
        open={openRating}
        onClose={onCloseRating}
        userData={handleData()}
      />
    </>
  );
};
export default SingleUserNegV2;
