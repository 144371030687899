import { Drawer, Grid, Stack, styled } from "@mui/material";

export const CustomDrawer = styled(Drawer)(
  () => `
  & .MuiDrawer-paper {
    width: 414px;
  }
  @media only screen and (max-width: 768px) {
    .MuiDrawer-paper {
      width: 100%;
    }
  }
  `
);

export const ContainerModal = styled(Grid)(
  ({ theme }) => `
    &::-webkit-scrollbar {
        width: .4rem;
    }
    &::-webkit-scrollbar-track {
        background-color: ${theme.palette.bgScrollBar.primary};
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${theme.palette.bgScrollBar.secondary};
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:active {
      background-color: ${theme.palette.bgScrollBar.disabled};
    }
    overflow-y: auto;
    padding: 16px;
    height: 100%;
    margin-bottom: 92px;
    & .MuiFormControl-root {
      width: 100%;
    }
  `
);

export const ContainerButtom = styled(Stack)(
  () => `
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 16px;
    z-index: 999;
  `
);

export const ContainerModalMultiShop = styled(ContainerModal)(
  () => `
    margin-bottom: 0;
    flex: 8;
    flex-direction: column;
    display: flex;
  `
);

export const ContainerModalButtomMultiShop = styled(ContainerModal)(
  () => `
    margin-bottom: 0;
    align-items: flex-end;
    display: flex;
    flex: 1;
  `
);
