import TagsCustom from "@/components/TagsCustom";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import useObserver from "@/hooks/useObserver";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import {
  CREATE_CUSTOMER_REQUEST_PATH,
  OFFERT_CUSTOMER_REQUEST_PATH,
} from "@/pages/customerRequest/shared/consts/routes";
import { getMyListCustomersRequestThunk } from "@/store/customerRequestRedux/customerRequestFilterThunk";
import { Container, Grid, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HeaderContainer } from "../noticeList/styleNoticeList";
import ListChildrenCustomer from "./components/ListChildrenCustomer";

const CustomerRequestList = () => {
  const [numberOptions, setNumberOptions] = useState<number>(1);

  const {
    customerRequest: {
      myRequestListData,
      isLoading,
      isLoadingPagination,
      currentPage,
      totalPages,
    },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const location = useLocation();
  const { search, pathname } = location;
  const navigate = useNavigate();

  const refObserver = useRef<any>(null);

  const { isNearScreen } = useObserver({
    distance: "100px",
    externalRef: refObserver,
  });

  useEffect(() => {
    dispatch(
      getMyListCustomersRequestThunk(
        0,
        6,
        {
          nombre: "Más reciente",
          opciones: ["id,desc"],
        },
        [
          {
            nombre: "Abierto o Cerrado",
            opciones: [],
            opcionesnumero: [numberOptions],
          },
        ],
        numberOptions
      )
    );
  }, [numberOptions, location]);

  useEffect(() => {
    if (isNearScreen && !isLoadingPagination && currentPage < totalPages - 1) {
      handlePaginate();
    }
  }, [isNearScreen]);

  const handlePaginate = () => {
    dispatch(
      getMyListCustomersRequestThunk(
        currentPage + 1,
        6,
        {
          nombre: "Más reciente",
          opciones: ["id,desc"],
        },
        [
          {
            nombre: "Abierto o Cerrado",
            opciones: [],
            opcionesnumero: [numberOptions],
          },
        ],
        numberOptions
      )
    );
  };

  const handleClickCreate = () => {
    navigate(CREATE_CUSTOMER_REQUEST_PATH);
  };

  const handleOnChangeTag = (activeTag: number) => {
    const num = Number(activeTag);
    setNumberOptions(num === 2 ? 0 : num);
    navigate(`${pathname}?tag=${activeTag}`);
  };

  const handleCurrentTag = () => {
    const id = search[search.length - 1];
    if (!id || isNaN(Number(id))) return undefined;

    return Number(id);
  };

  const currentTag = useMemo(() => {
    const tag = handleCurrentTag();
    !!tag && setNumberOptions(tag === 2 ? 0 : tag);
    return tag;
  }, [search]);

  return (
    <Container maxWidth="xl" sx={{ marginTop: "41px" }}>
      <Grid container>
        <Grid item xs={12}>
          <HeaderContainer
            maxWidth={{
              xs: "100%",
              sm: "301px",
            }}
          >
            <Typography variant="h6">Solicitudes</Typography>
            <ButtomLoader
              variant="outlined"
              sx={{ maxWidth: "80px", marginTop: "0", padding: "1px 16px 0" }}
              onClick={handleClickCreate}
            >
              crear
            </ButtomLoader>
          </HeaderContainer>
        </Grid>
        <Grid item xs={12} sm={12} md={12} mt="16px">
          <TagsCustom
            items={[
              {
                value: 1,
                label: "ACTIVAS",
                panel: (
                  <ListChildrenCustomer
                    requestList={myRequestListData}
                    isLoading={isLoading}
                    isLoadingPagination={isLoadingPagination}
                    refObserver={refObserver}
                  />
                ),
              },
              {
                value: 2,
                label: "HISTORIAL",
                panel: (
                  <ListChildrenCustomer
                    requestList={myRequestListData}
                    isLoading={isLoading}
                    isLoadingPagination={isLoadingPagination}
                    refObserver={refObserver}
                  />
                ),
              },
            ]}
            arialLabel="Mis solicitudes"
            initialTag={currentTag}
            onChange={handleOnChangeTag}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CustomerRequestList;
