import { FAVICON_32 } from "@/consts/defaultConsts";
import ShareIcon from "@mui/icons-material/Share";
import { IconButton, styled } from "@mui/material";
import ShareOnSocial from "react-share-on-social";

const Icon = styled(ShareIcon)(
  () => `
    color: #FF6700; 
    fontSize: 19px;
  `
);

const defaultLink = window.location.href;
const defaultFavicon = `${window.location.origin}/favicon${FAVICON_32}`;

type Props = {
  link?: string;
  linkFavicon?: string;
  show?: boolean;
};

const ShareButton = (props: Props) => {
  const {
    link = defaultLink,
    linkFavicon = defaultFavicon,
    show = true,
  } = props;
  if (!show) return <></>;

  return (
    <ShareOnSocial link={link} linkFavicon={linkFavicon} noReferer>
      <IconButton aria-label="share">
        <Icon />
      </IconButton>
    </ShareOnSocial>
  );
};

export default ShareButton;
