import ConditionsListOffert from '@/components/conditionsList/ConditionsListOffert';
import UserRating from '@/components/userRating/UserRating';
import DetailsTextMoving from '@/pages/customerRequest/application/components/DetailsTextMoving';
import { ConfigDataNegotation } from '@/pages/noticeRequestFlow/interface/noticeNegRequestInterface';
import { DetailTypeFlows } from '@/pages/noticeRequestFlow/utils/utilsComponents';
import {
  getDeclineOffertFormatDate,
  getFormatMetro,
  getFormatSendDate,
  getHelpers,
  getOffertFormatSendDate,
  getTitleCounterOfferPrice,
  getTitleOriginAndDestiny,
  getValueInOffert,
  getVehicleValues,
  renderAmbientesOffert,
  renderRatingTransporter,
  renderTitleAmbienteOffert,
  renderTitleLongDestinyOffert,
  renderTitleLongOriginOffert,
} from '@/pages/noticeRequestFlow/utils/utilsNegotation';
import DetailsText from '@/pages/offerts/offertConfirmation/components/DetailsText';
import { Box, Typography } from '@mui/material';

// ==================================================
//          CONFIGURACION MUDANZA
// ==================================================

export const configDataClientOneMoving: ConfigDataNegotation[] = [
  {
    title: 'Tu solicitud',
    Component: Typography,
    props: {
      variant: 'body2',
      sx: { color: '#757575', mb: '4px' },
    },
  },
  {
    Component: DetailTypeFlows,
    props: {},
    valid: [
      {
        property: 'solicitud',
        componentProp: 'nombre',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'tipoSolicitud', 'nombre'],
      },
      {
        property: 'solicitud',
        componentProp: 'id',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'tipoSolicitud', 'id'],
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'title',
        defaultResult: '',
        callback: getTitleOriginAndDestiny,
      },
    ],
  },
];

export const configDataClientTwoMoving: ConfigDataNegotation[] = [
  {
    title: '',
    Component: Box,
    props: {
      variant: 'body2',
      sx: { width: '100%', mt: '16px' },
    },
  },
  // {
  //   title: "Detalles de contacto",
  //   Component: Typography,
  //   props: {
  //     variant: "subtitle1medium",
  //   },
  // },
  // {
  //   Component: DetailsTextContant,
  //   props: {
  //     icon: "icon-small-phone",
  //     title: "Telefono",
  //     show: true,
  //     type: "phone",
  //   },
  //   valid: [
  //     {
  //       property: "avisoNegociacionAviso",
  //       componentProp: "origen",
  //       defaultResult: "",
  //       callback: getNumberPhoneInRating,
  //     },
  //   ],
  // },
  // {
  //   Component: DetailsTextContant,
  //   props: {
  //     icon: "icon-whatsapp",
  //     title: "Whatsapp",
  //     show: true,
  //     type: "whatsapp",
  //   },
  //   valid: [
  //     {
  //       property: "avisoNegociacionAviso",
  //       componentProp: "origen",
  //       defaultResult: "",
  //       callback: getNumberPhoneInRating,
  //     },
  //   ],
  // },

  // {
  //   Component: DetailsTextContant,
  //   props: {
  //     icon: "icon-mail",
  //     title: "Correo",
  //     type: "mail",
  //   },
  //   valid: [
  //     {
  //       property: "avisoNegociacionAviso",
  //       componentProp: "show",
  //       defaultResult: "",
  //       callback: getEmailInRating,
  //     },
  //     {
  //       property: "avisoNegociacionAviso",
  //       componentProp: "origen",
  //       defaultResult: "",
  //       callback: getEmailInRating,
  //     },
  //   ],
  // },

  {
    title: '',
    Component: Box,
    props: {
      variant: 'body2',
      sx: { width: '100%', mt: '16px' },
    },
  },
  {
    title: 'Detalles de la solicitud',
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
    },
  },

  {
    Component: DetailsTextMoving,
    props: {
      icon: 'icon-calendar--white',
      title: 'Fecha del servicio',
      originDescription: '',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'fecha'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getFormatSendDate,
        callbakcParams: ['solicitud', 'fecha'],
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-location-medium',
      title: 'Origen',
    },
    valid: [
      // {
      //   property: "solicitud",
      //   componentProp: "show",
      //   defaultResult: false,
      //   callback: getValueInOffert,
      //   callbakcParams: ["solicitud", "origentexto"],
      // },
      // {
      //   property: "solicitud",
      //   componentProp: "origen",
      //   defaultResult: "",
      //   callback: getValueInOffert,
      //   callbakcParams: ["solicitud", "origentexto"],
      // },
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: renderTitleLongOriginOffert,
      },
      {
        property: 'referencia',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'referencia'],
      },
    ],
  },

  {
    Component: DetailsText,
    props: {
      icon: 'icon-location-medium',
      title: 'Destino',
    },
    valid: [
      // {
      //   property: "solicitud",
      //   componentProp: "show",
      //   defaultResult: true,
      //   callback: getValueInOffert,
      //   callbakcParams: ["solicitud", "destinotexto"],
      // },
      // {
      //   property: "solicitud",
      //   componentProp: "origen",
      //   defaultResult: "",
      //   callback: getValueInOffert,
      //   callbakcParams: ["solicitud", "destinotexto"],
      // },
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: renderTitleLongDestinyOffert,
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'referenciaDestino'],
      },
    ],
  },
  {
    Component: DetailsTextMoving,
    props: {
      icon: 'icon-box-black',
      title: 'Ambientes',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: renderTitleAmbienteOffert,
      },
      // {
      //   property: "solicitud",
      //   componentProp: "originDescription",
      //   defaultResult: "",
      //   callback: renderAmbientesOffert,
      // },
      {
        property: 'solicitud',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: renderAmbientesOffert,
        callbakcParams: ['solicitud', 'originDescription'],
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-rules-small',
      title: 'Metraje',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getFormatMetro,
        callbakcParams: ['solicitud', 'metraje'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getFormatMetro,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-cargoPerson--medium',
      title: 'Ayudantes',
    },
    valid: [
      {
        property: 'vehiculo',
        componentProp: 'show',
        defaultResult: true,
        callback: getHelpers,
        callbakcParams: ['ayudantes'],
      },
      {
        property: 'ayudantes',
        componentProp: 'origen',
        defaultResult: '',
        callback: getHelpers,
      },
    ],
  },
  {
    title: '',
    Component: Box,
    props: {
      variant: 'body2',
      sx: { width: '100%', mt: '16px' },
    },
  },
];

export const cofigDataClientConditionsMoving: ConfigDataNegotation[] = [
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '22px' },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'condiciones'],
      },
      {
        property: 'solicitud',
        componentProp: 'listServices',
        defaultResult: [],
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'condiciones'],
      },
    ],
  },
];

export const configDataClientThreeMoving: ConfigDataNegotation[] = [
  {
    Component: Typography,
    title: 'Cotización del transportista',
    props: {
      variant: 'body2',
      sx: { color: '#757575', mt: '4px' },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
  {
    Component: UserRating,
    props: {
      containerSx: { mt: '16px', mb: '5px' },
      viewRating: true,
    },
    valid: [
      {
        property: 'transportista',
        componentProp: 'data',
        defaultResult: {},
        callback: renderRatingTransporter,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-car-medium',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getVehicleValues,
        callbakcParams: ['solicitud', 'tipoVehiculo', 'nombre'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getVehicleValues,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-cargoPerson--medium',
      title: 'Ayudantes',
    },
    valid: [
      {
        property: 'vehiculo',
        componentProp: 'show',
        defaultResult: true,
        callback: getHelpers,
        callbakcParams: ['ayudantes'],
      },
      {
        property: 'ayudantes',
        componentProp: 'origen',
        defaultResult: '',
        callback: getHelpers,
      },
    ],
  },
  {
    Component: Typography,
    title: 'Precio por viaje',
    props: {
      variant: 'body2',
      sx: { color: '#757575', mt: '4px' },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'h4',
      sx: { fontSize: '22px' },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'title',
        defaultResult: '',
        callback: getTitleCounterOfferPrice,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'body2',
      sx: { color: '#757575', mb: '4px' },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'title',
        defaultResult: '',
        callback: getOffertFormatSendDate,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'body2',
      sx: { color: 'red', mb: '4px' },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'title',
        defaultResult: '',
        callback: getDeclineOffertFormatDate,
      },
    ],
  },
];

export const ConfigOfferMovingClient = {
  1: configDataClientOneMoving,
  2: configDataClientTwoMoving,
  3: cofigDataClientConditionsMoving,
  4: configDataClientThreeMoving,
};
