import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { Container, styled, experimental_sx as sx, } from "@mui/material";


export const ContainerButtonsV2 = styled(Container)(
    sx({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      mt: 2,
      mb: 2,
    })
  );

export const DisabledOfferButtons = () => {


  return (
    <ContainerButtonsV2>
      <ButtomLoader
        fullWidth
        variant="contained"
        disabled
      >
        aceptar
      </ButtomLoader>

      <ButtomLoader
        fullWidth
        variant="text"
        type="submit"
        disabled
      >
        rechazar
      </ButtomLoader>
    </ContainerButtonsV2>
  );
};
