import ConditionsListOffert from '@/components/conditionsList/ConditionsListOffert';
import SinglerUserRating from '@/components/userRating/SinglerUserRating';
import DetailTypeFlow from '@/components/viewService/detailsService/detailTypeFlow';
import DetailsText from '@/pages/offerts/offertConfirmation/components/DetailsText';
import { Typography } from '@mui/material';

import { ID_MOVING_FLOW } from '@/consts/defaultConsts';
import SingleUserRatingAcceptance from '../components/SingleUserRatingAcceptance';
import { ConfigDataAcceptance } from '../interfaces/acceptanceInterface';
import {
  getAvatarIdUser,
  getFechaSalilda,
  getImageTruckInRating,
  getInfoVehicle,
  getMaxWeigth,
  getNoticeNumberOFHelpers,
  getRefrigeratedLoad,
  getTitleAvisoIsOwner,
  getTitleOriginAndDestiny,
  getUrlAvatarProfile,
  getUserNameRating,
  getValueInOffert,
  getVehicleValues,
  showVehicleInOffert,
  validIsClient,
} from '../utils/utilsAcceptance';

export const configDataAcceptance: ConfigDataAcceptance[] = [
  {
    Component: Typography,
    props: {
      variant: 'body2',
      sx: { color: '#757575', mb: '4px' },
      title: 'Tu aviso',
    },
    valid: [
      {
        property: 'flgIsOwner',
        componentProp: 'title',
        defaultResult: 'Tu aviso',
        callback: getTitleAvisoIsOwner,
      },
    ],
  },
  {
    Component: DetailTypeFlow,
    props: {},
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'nombre',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'tipoaviso', 'nombre'],
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'id',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'tipoaviso', 'id'],
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'flghoraopunto',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'flghoraopunto'],
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'title',
        defaultResult: '',
        callback: getTitleOriginAndDestiny,
      },
    ],
  },
  {
    Component: SingleUserRatingAcceptance,
    props: {
      containerSx: { mt: '16px', mb: '16px' },
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'urlProfile',
        defaultResult: '',
        callback: getUrlAvatarProfile,
      },
      {
        property: 'cliente',
        componentProp: 'showTwoAvatars',
        defaultResult: '',
        callback: validIsClient,
      },
      {
        property: 'cliente',
        componentProp: 'urlTruck',
        defaultResult: '',
        callback: getImageTruckInRating,
      },
      {
        property: 'cliente',
        componentProp: 'userName',
        defaultResult: '',
        callback: getUserNameRating,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-car-medium',
      title: 'Vehículo',

      originDescription: '',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'vehiculo', 'placa'],
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: getVehicleValues,
      },
    ],
  },

  {
    Component: DetailsText,
    props: {
      icon: 'icon-balance-medium',
      title: 'Peso máximo',

      originDescription: '',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'pesomaximo'],
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: getMaxWeigth,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-snowFlake-medium',
      title: 'Puede llevar carga refrigerada',
      show: true,
      originDescription: '',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: getRefrigeratedLoad,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-cargo-person',
      title: 'Ayudantes',
      originDescription: '',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'show',
        defaultResult: false,
        callback: getNoticeNumberOFHelpers,
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: getNoticeNumberOFHelpers,
      },
    ],
  },
  // Seccion para multi destino por horas o puntos!!
  {
    Component: DetailsText,
    props: {
      icon: 'icon-watch-dense',
      title: 'Horas mínimas',
    },
    valid: [
      {
        property: 'horas',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'horasminimo'],
      },
      {
        property: 'horas',
        componentProp: 'origen',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'horasminimo'],
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-deliveries-dense',
      title: 'Puntos mínimos de entrega',
    },
    valid: [
      {
        property: 'puntos',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'puntosminimo'],
      },
      {
        property: 'puntos',
        componentProp: 'origen',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'puntosminimo'],
      },
    ],
  },
  // Seccion para multi destino por horas o puntos fin!!
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '22px' },
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'condiciones'],
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'listServices',
        defaultResult: [],
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'condiciones'],
      },
    ],
  },
];

export const configDataAcceptanceAuction: ConfigDataAcceptance[] = [
  {
    Component: Typography,
    props: {
      variant: 'body2',
      sx: { color: '#757575', mb: '4px' },
      title: 'Tu aviso',
    },
    valid: [
      {
        property: 'flgIsOwner',
        componentProp: 'title',
        defaultResult: 'Tu aviso',
        callback: getTitleAvisoIsOwner,
      },
    ],
  },
  {
    Component: DetailTypeFlow,
    props: {},
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'nombre',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'tipoaviso', 'nombre'],
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'id',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'tipoaviso', 'id'],
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'title',
        defaultResult: '',
        callback: getTitleOriginAndDestiny,
      },
    ],
  },

  {
    Component: DetailsText,
    props: {
      icon: 'icon-calendar--white',
      title: 'Salida',

      originDescription: '',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: getFechaSalilda,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-location-medium',
      title: 'Origen',
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: true,
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'origentexto2'],
      },
      {
        property: 'destinoTexto',
        componentProp: 'origen',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'origentexto2'],
      },
    ],
  },

  {
    Component: DetailsText,
    props: {
      icon: 'icon-location-medium',
      title: 'Destino',
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: true,
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'destinotexto2'],
      },
      {
        property: 'destinoTexto',
        componentProp: 'origen',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'destinotexto2'],
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-vehicle--truck',
      title: 'Vehiculo',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'show',
        defaultResult: false,
        callback: showVehicleInOffert,
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: getInfoVehicle,
      },
    ],
  },

  {
    Component: DetailsText,
    props: {
      icon: 'icon-balance-medium',
      title: 'Peso',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'show',
        defaultResult: true,
        callback: getMaxWeigth,
        callbakcParams: ['pesomaximo'],
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: getMaxWeigth,
        callbakcParams: ['pesomaximo'],
      },
    ],
  },

  {
    Component: DetailsText,
    props: {
      icon: 'icon-snowFlake-medium',
      title: 'Sólo carga refrigerada',
      show: true,
      originDescription: '',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: getRefrigeratedLoad,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-cargo-person',
      title: 'Ayudantes',
      originDescription: '',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'show',
        defaultResult: false,
        callback: getNoticeNumberOFHelpers,
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: getNoticeNumberOFHelpers,
      },
    ],
  },
  // Seccion para multi destino por horas o puntos!!
  {
    Component: DetailsText,
    props: {
      icon: 'icon-watch-dense',
      title: 'Horas mínimas',
    },
    valid: [
      {
        property: 'horas',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'horasminimo'],
      },
      {
        property: 'horas',
        componentProp: 'origen',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'horasminimo'],
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-deliveries-dense',
      title: 'Puntos mínimos de entrega',
    },
    valid: [
      {
        property: 'puntos',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'puntosminimo'],
      },
      {
        property: 'puntos',
        componentProp: 'origen',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'puntosminimo'],
      },
    ],
  },
  // Seccion para multi destino por horas o puntos fin!!
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '22px' },
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'condiciones'],
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'listServices',
        defaultResult: [],
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'condiciones'],
      },
    ],
  },
];

export const configDataAcceptanceOfferMovingDriver: ConfigDataAcceptance[] = [
  {
    Component: Typography,
    props: {
      variant: 'body2',
      sx: { color: '#757575', mb: '4px' },
      title: 'Tu aviso',
    },
    valid: [
      {
        property: 'flgIsOwner',
        componentProp: 'title',
        defaultResult: 'Tu aviso',
        callback: getTitleAvisoIsOwner,
      },
    ],
  },
  {
    Component: DetailTypeFlow,
    props: {},
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'nombre',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'tipoaviso', 'nombre'],
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'id',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'tipoaviso', 'id'],
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'title',
        defaultResult: '',
        callback: getTitleOriginAndDestiny,
      },
    ],
  },
  {
    Component: SingleUserRatingAcceptance,
    props: {
      containerSx: { mt: '16px', mb: '16px' },
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'urlProfile',
        defaultResult: '',
        callback: getUrlAvatarProfile,
      },
      {
        property: 'cliente',
        componentProp: 'showTwoAvatars',
        defaultResult: '',
        callback: validIsClient,
      },
      {
        property: 'cliente',
        componentProp: 'urlTruck',
        defaultResult: '',
        callback: getImageTruckInRating,
      },
      {
        property: 'cliente',
        componentProp: 'userName',
        defaultResult: '',
        callback: getUserNameRating,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-car-medium',
      title: 'Vehículo',

      originDescription: '',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'vehiculo', 'placa'],
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: getVehicleValues,
      },
    ],
  },

  {
    Component: DetailsText,
    props: {
      icon: 'icon-cargo-person',
      title: 'Ayudantes',
      originDescription: '',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'show',
        defaultResult: false,
        callback: getNoticeNumberOFHelpers,
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: getNoticeNumberOFHelpers,
      },
    ],
  },

  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '22px' },
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'condiciones'],
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'listServices',
        defaultResult: [],
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'condiciones'],
      },
    ],
  },
];

export const configDataCocounterOfferAgreement: ConfigDataAcceptance[] = [
  {
    title: 'Aviso de transportista',
    Component: Typography,
    props: {
      variant: 'body2',
      sx: { color: '#757575', mb: '4px' },
    },
  },
  {
    Component: DetailTypeFlow,
    props: {},
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'nombre',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'tipoaviso', 'nombre'],
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'id',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'tipoaviso', 'id'],
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'title',
        defaultResult: '',
        callback: getTitleOriginAndDestiny,
      },
    ],
  },
  {
    Component: SingleUserRatingAcceptance,
    props: {
      containerSx: { mt: '16px' },
      viewRating: true,
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'idUser',
        defaultResult: '',
        callback: getAvatarIdUser,
      },
      {
        property: 'cliente',
        componentProp: 'urlProfile',
        defaultResult: '',
        callback: getUrlAvatarProfile,
      },
      {
        property: 'cliente',
        componentProp: 'showTwoAvatars',
        defaultResult: '',
        callback: validIsClient,
      },
      {
        property: 'cliente',
        componentProp: 'urlTruck',
        defaultResult: '',
        callback: getImageTruckInRating,
      },
      {
        property: 'cliente',
        componentProp: 'userName',
        defaultResult: '',
        callback: getUserNameRating,
      },
    ],
  },

  {
    Component: DetailsText,
    props: {
      icon: 'icon-car-medium',
      title: 'Vehículo',
      show: true,
      originDescription: '',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'vehiculo', 'placa'],
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: getVehicleValues,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-balance-medium',
      title: 'Peso máximo',

      originDescription: '',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'pesomaximo'],
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: getMaxWeigth,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-snowFlake-medium',
      title: 'Puede llevar carga refrigerada',
      show: true,
      originDescription: '',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: getRefrigeratedLoad,
      },
    ],
  },
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '22px' },
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'condiciones'],
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'listServices',
        defaultResult: [],
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'condiciones'],
      },
    ],
  },
];

export const configDataCoCounterOffer: ConfigDataAcceptance[] = [
  {
    title: 'Aviso de transportista',
    Component: Typography,
    props: {
      variant: 'body2',
      sx: { color: '#757575', mb: '4px' },
    },
  },
  {
    Component: DetailTypeFlow,
    props: {},
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'nombre',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'tipoaviso', 'nombre'],
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'id',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'tipoaviso', 'id'],
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'title',
        defaultResult: '',
        callback: getTitleOriginAndDestiny,
      },
    ],
  },
  {
    Component: SinglerUserRating,
    props: {
      containerSx: { mt: '16px' },
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'urlProfile',
        defaultResult: '',
        callback: getUrlAvatarProfile,
      },
      {
        property: 'cliente',
        componentProp: 'showTwoAvatars',
        defaultResult: '',
        callback: validIsClient,
      },
      {
        property: 'cliente',
        componentProp: 'urlTruck',
        defaultResult: '',
        callback: getImageTruckInRating,
      },
      {
        property: 'cliente',
        componentProp: 'userName',
        defaultResult: '',
        callback: getUserNameRating,
      },
    ],
  },

  {
    Component: DetailsText,
    props: {
      icon: 'icon-car-medium',
      title: 'Vehículo',
      show: true,
      originDescription: '',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'vehiculo', 'placa'],
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: getVehicleValues,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-cargo-person',
      title: 'Ayudantes',
      originDescription: '',
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'show',
        defaultResult: false,
        callback: getNoticeNumberOFHelpers,
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: getNoticeNumberOFHelpers,
      },
    ],
  },
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '22px' },
    },
    valid: [
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'condiciones'],
      },
      {
        property: 'avisoNegociacionAviso',
        componentProp: 'listServices',
        defaultResult: [],
        callback: getValueInOffert,
        callbakcParams: ['avisoNegociacionAviso', 'condiciones'],
      },
    ],
  },
];

type TypeTextObjToRenderType = Record<
  number,
  Record<number, Record<number, string>>
>;

export const typeTextObjToRender: TypeTextObjToRenderType = {
  //cliente
  1: {
    //oferta
    1: {
      1: 'Tu oferta',
      2: 'Tu solicitud',
    },
    //contraoferta
    2: {
      1: 'Tu contraoferta',
      2: 'Tu solicitud de cotización',
    },
  },
  //transportista
  2: {
    //oferta
    1: {
      1: 'Oferta del cliente',
      2: 'Solicitud del cliente',
    },
    //contraoferta
    2: {
      1: 'Contraoferta del cliente',
      2: 'Solicitud de cotización del cliente',
    },
  },
};

export const handleTextAlert = (
  textTypeNeg: string,
  isDriver: boolean,
  typeFlow?: number
) => {
  let textClient = textTypeNeg;

  if (typeFlow === ID_MOVING_FLOW) textClient = 'cotización';

  const textStatusAlert: Record<number, string> = {
    1: `El cliente aún no responde tu ${textTypeNeg}`,
    2: `El transportista aún no responde tu ${textClient}`,
  };

  return textStatusAlert[isDriver ? 1 : 2];
};
