import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import LayoutFlow from "@/components/LayoutFlow/LayoutFlow";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { styled, Typography } from "@mui/material";

import ListSteps, { ListStepsProps } from "@/components/ListSteps/ListSteps";
import { UsuarioAPI } from "@/models/userAuth/user";
import { signUpStepsDataLayer } from "@/services/dataLayer/auth";
import { endUserRegistrationThunk } from "@/store/userRedux/userThunks";
import { hydrateVehicleThunk } from "@/store/vehicle/vehicleThunk";
import { getNextPage } from "@/utils/helpers";
import { useEffect, useMemo } from "react";
import { To, useLocation, useNavigate } from "react-router-dom";
import { listSteps, objectNextPage } from "./configData";

const Description = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.text.disabled}; 
    margin-top: 16px;
  `
);

const DriverSteps = () => {
  const user = useAppSelector(({ user }) => user.user as UsuarioAPI);
  const {
    user: { isLoading, vehicles },
    vehicle: { selectedVehicle },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
 
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user.id) {
      signUpStepsDataLayer(user.tipousuario, user.id);
    }
  }, []);

  useEffect(() => {
    handleHydrateSelectdVehicle();
  }, [vehicles]);

  const handleClickStep = (listItem: ListStepsProps) => {
    if(!listItem?.isChecked) { 
      navigate(listItem?.link);
    }
  };

  const handleSetChekedUser = () => {
    const newListData = listSteps.map((list: ListStepsProps) => ({
      ...list,
      isChecked: !!user[list.key],
      disabled: list.keyDepends && !user[list.keyDepends],
    }));

    return newListData;
  };

  const handleHydrateSelectdVehicle = () => {
    if (!selectedVehicle?.id && !!vehicles.length) {
      dispatch(hydrateVehicleThunk());
    }
  };

  const listData = useMemo(
    () => handleSetChekedUser(),
    [user?.flgRegistroForm, user?.flgRegistroCuenta, user?.flgRegistroVehiculo, user?.flgRegistroAviso]
  );

  const handleDisabled = () => {
    return !user?.flgRegistroForm || !user?.flgRegistroCuenta || !user?.flgRegistroVehiculo || !user?.flgRegistroAviso;
  };

  const handleSubmit = () => {
    dispatch(endUserRegistrationThunk()).then(handleGoNextPage);
  };

  const handleGoNextPage = () => {
    const nextPage = getNextPage(location.pathname, objectNextPage);

    navigate(nextPage as To, { replace: true });
  };

  return (
    <LayoutFlow>
      <Typography variant="h6">Continuemos con tu registro</Typography>
      <Description variant="body1">Completa los siguientes pasos para conseguir nuevos clientes:</Description>

      <ListSteps list={listData} onClick={handleClickStep} register/>
      
      <ButtomLoader disabled={handleDisabled()} onClick={handleSubmit} type="submit" isLoading={isLoading}
        sx={{ 
          display: handleDisabled() ? "none" : "block",
        }}
      >
        FINALIZAR
      </ButtomLoader>
    </LayoutFlow>
  );
};

export default DriverSteps;
