import React from 'react'
import { characterCut } from "@/utils/helpers";
import {
    BodyBox,
    ContainerBody,
    SectionBody,
    SectionBodyMultiNotice,
    SectionHeaderFooter,
    SubTitleBody,
    TitleBody,
} from "./styleBodySuccess";
import { INoticeCreatedResponse, INoticeResponseCreated } from '@/services/noticeServices/noticeSendData/noticeSendDataTypesResponse';
import Icon from '@/utils/Icon';

type Props = {
    createdData: INoticeCreatedResponse[];
};
const BodySuccessMultiMoving = ({ createdData }: Props) => {

    const {
        origentexto,
        placa,
        ayudantes,
    } = createdData[0] as INoticeCreatedResponse;

    return (
        <ContainerBody
            container
            columns={{ xs: 2 }}
            rowSpacing={{ xs: 0 }}
            columnSpacing={{ xs: 0 }}
            justifyContent="center"
            justifyItems="center"
        >
            <SectionHeaderFooter
                item
                xs={2}
                borderBottom={() => ({
                    borderBottom: `1px solid #eeeeee`,
                })}
            >
                <TitleBody > Origen </TitleBody>
                <SubTitleBody>
                    {origentexto}{" "}
                </SubTitleBody>
            </SectionHeaderFooter>
            {createdData.map(({
                destinotexto,
                precio,
            }, i) => (
                <SectionBodyMultiNotice
                    sx={{
                        borderTop: i !== 0 ? `1px solid #eeeeee` : "none",
                    }}
                >
                    <SectionBody item xs={1} className="multi">
                        <BodyBox>
                            <TitleBody>Destino</TitleBody>
                        </BodyBox>
                        <SubTitleBody>
                            {characterCut(destinotexto, 11)}
                        </SubTitleBody>
                    </SectionBody>

                    <SectionBody item xs={1} className="multi">
                        <BodyBox>
                            <Icon name="icon-money" size="md" />
                            <TitleBody>Precio por viaje</TitleBody>
                        </BodyBox>
                        <SubTitleBody>S/ {precio}</SubTitleBody>
                    </SectionBody>
                </SectionBodyMultiNotice>
            ))}
            <SectionBodyMultiNotice
                sx={{
                    borderTop: `1px solid #eeeeee`,
                }}
            >
                <SectionBody item xs={1} className="multi">
                    <BodyBox>
                        <Icon name="icon-truck" size="md" />
                        <TitleBody>Vehículo</TitleBody>
                    </BodyBox>
                    <SubTitleBody>{placa}</SubTitleBody>
                </SectionBody>
                <SectionBody item xs={1} className="multi">
                    <BodyBox>
                        <Icon name="icon-people" size="md" />
                        <TitleBody># Ayudantes</TitleBody>
                    </BodyBox>
                    <SubTitleBody>{ayudantes}</SubTitleBody>
                </SectionBody>
            </SectionBodyMultiNotice>
        </ContainerBody>
    )
}

export default BodySuccessMultiMoving
