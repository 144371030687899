import { NoticeDetailAuctionModel } from "@/models/notice/noticeV2NegotationModels";
import { Box, Card, Grid, Typography, styled } from "@mui/material";
import { handleDataAuctionToRenderFinished } from "./config";

export const CustomCard = styled(Card)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  minWidth: "275px",
  padding: "8px",
  boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  minHeight: "100px",
}));

const AuctionCounterOfferPrice = ({
  data,
}: {
  data: NoticeDetailAuctionModel;
}) => {
  const dataToRender = handleDataAuctionToRenderFinished(data);

  return (
    <Box>
      {dataToRender.map((item, i) => (
        <CustomCard sx={{ mt: 2 }} key={i}>
          <Typography sx={item.title.sx}>{item.title.text}</Typography>
          <Grid container>
            <Grid xs={6}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={item.sxSubtitleColum}>
                  {item.columnOne.subtitle}
                </Typography>
                <Typography sx={item.sxSubtitleValueColum}>
                  {item.columnOne.value}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={item.sxSubtitleColum}>
                  {item.columnTwo.subtitle}
                </Typography>
                <Typography sx={item.sxSubtitleValueColum}>
                  {item.columnTwo.value}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CustomCard>
      ))}
    </Box>
  );
};

export default AuctionCounterOfferPrice;
