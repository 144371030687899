import { Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const Title = styled(Typography)(
  () => `
  font-size: 34px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
    `
);

export const SubTitle = styled(Typography)(
  () => `
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    `
);

export const Information = styled(Typography)(
  () => `
    font-size: 16px;
    font-weight: 305;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 16px
    `
);

export const Conditions = styled(Typography)(
  () => `
    font-size: 16px;
    font-weight: 305;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 8px
    `
);

export const Redirection = styled(Link)(
  ({ theme }) => `
      text-decoration: none;
      font-family: ${theme.typography.h1.fontFamily};
      font-size: ${theme.typography.label2.fontSize};
      font-weight: ${theme.typography.label2.fontWeight};
      line-height: ${theme.typography.label2.lineHeight};
          color: blue;
    `
);
