import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "./useReducer";

const DEFAULT_COUNT_DOWN = 60;

const useCountdown = () => {
  const [countdown, setCountdown] = useState(DEFAULT_COUNT_DOWN);
  const [isActive, setIsActive] = useState(false);

  const { lastDateSMS } = useAppSelector((state) => state.auth);
  const [diferent, setDiferent] = useState(0);
  const now = dayjs();

  useEffect(() => {
    setDiferent(now.diff(lastDateSMS, "second"));
    setCountdown(DEFAULT_COUNT_DOWN - diferent);
  }, [lastDateSMS, now, diferent]);

  const handleIsEnabled = countdown > 0;

  const refCount = useRef<NodeJS.Timer>();
  useEffect(() => {
    return () => {
      handleInActiveCountDown();
    };
  }, []);

  const handleInActiveCountDown = () => {
    clearInterval(refCount.current);
  };

  const handleActiveCountdown = () => {
    setIsActive(true);
    let count = DEFAULT_COUNT_DOWN;
    refCount.current = setInterval(() => {
      if (count <= 0) {
        clearInterval(refCount.current);
        setCountdown(() => DEFAULT_COUNT_DOWN);
        setIsActive(false);
      } else {
        count--;
        setCountdown((prev) => prev - 1);
      }
    }, 1000);
  };

  return {
    handleActiveCountdown,
    handleInActiveCountDown,
    handleIsEnabled,
    countdown,
    isActive,
  };
};

export default useCountdown;
