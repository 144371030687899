import { ValidateUserResponseAPI } from "@/models/userAuth/userAuth";
import { AxiosError } from "axios";
import { ERROR_GENERIC_TEXT } from "./defaultConsts";
import { INoticeSendDataErrorDuplicated } from "@/services/noticeServices/noticeSendData/noticeSendDataErrorDuplicated";

export const convertValidateCodeMessaggeError = (
  errorData: AxiosError,
  defaultMessagge: string
) => {
  const data = errorData?.response?.data;
  const status = errorData?.response?.status || 0;

  if (data && (data as ValidateUserResponseAPI)?.metadata) {
    const messageDetail = (data as ValidateUserResponseAPI)?.metadata[0]?.detalle;
    if (messageDetail) return { status, message: messageDetail };
    const message = (data as ValidateUserResponseAPI)?.metadata[0]?.dato;
    if (message) return { status, message };
  }
  return { status, message: defaultMessagge };
};

export const getMessaggeError = (
  err: any,
  defaultMessagge = ERROR_GENERIC_TEXT
) => {
  const error = err as AxiosError;
  if (error.isAxiosError) {
    const messaggeError = convertValidateCodeMessaggeError(
      error,
      defaultMessagge
    );
    return messaggeError;
  }

  return { status: 0, message: defaultMessagge };
};


export const getNoticeNumberDuplicateError = (err: any) : number[] => {
  const error = err as AxiosError;
  if (error.isAxiosError) {
    const data = error?.response?.data;
    if (data && (data as INoticeSendDataErrorDuplicated)?.avisosDuplicados) {
      const noticesDuplicate = (data as INoticeSendDataErrorDuplicated)?.avisosDuplicados;
      return noticesDuplicate;
    }
  }
  return [];
};