import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import SelectCar from "@/pages/createRequestNegotiation/components/SelectCar";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { FormControl, Grid, styled } from "@mui/material";
import { FormCounterOfferInCityEdition } from "./FormCounterOfferInCityEdition";
import { useFormCounterOfferInCity } from "./useFormCounterOfferInCity";
import { textPriceLabel } from "@/consts/noticeConsts";


const Container = styled(Grid)(
  () => `
    display: flex;
    flex-direction: column;  
    margin-top: 24px;
  `
);

const FormCounterOfferInCity = () => {
  const {
    errorPrice,
    isEdition,
    isCounterOffer,
    idRequest,
    price,
    isLoading,
    handleSubmit,
    handleDisabledButton,
    handleOnchange,
  } =  useFormCounterOfferInCity()

  

  if (isEdition)
    return (<FormCounterOfferInCityEdition/>)

  return (
    <Container container>
      <form onSubmit={handleSubmit}>
        <FormControl sx={{ width: "100%" }}>
          <SelectCar idAvisoOrSolicitud={Number(idRequest)} />
        </FormControl>

        <FormControl sx={{ marginTop: "16px", width: "100%" }}>
          <Input
            id={`$1-price`}
            name="price"
            label={<LabelInputRequired str={textPriceLabel} />}
            value={price}
            variant="standard"
            placeholder="Ingresa tu precio"
            onChange={handleOnchange}
            onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()} //evita que se escriban caracteres no numéricos(+-.,)//evita que se escriban caracteres no numéricos(+-.,)
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
            }}
            error={!!errorPrice}
            helperText={errorPrice}
          />
        </FormControl>

        <Grid item xs={12} sx={{ marginTop: "16px" }}>
          <ButtomLoader
            type="submit"
            disabled={handleDisabledButton() || isLoading}
            isLoading={isLoading}
          >
            {isCounterOffer ? "CONTRAOFERTAR" : "SIGUIENTE"}
          </ButtomLoader>
        </Grid>
      </form>
    </Container>
  );
};

export default FormCounterOfferInCity;
