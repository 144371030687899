// import DatePicker from "@/components/form/datePicker.tsx/DatePicker";
import { theme } from '@/styles/theme';
import {
  Button,
  Container,
  Drawer,
  Grid,
  List,
  Select,
  Stack,
  styled,
  Typography,
  experimental_sx as sx,
  Box,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
export const TotalContainer = styled(Container)(
  sx({
    padding: { xs: '16px', md: '32px' },
  })
);

export const ContainerServices = styled(Container)(
  sx({
    maxWidth: '1152px !important',
    width: '100%',
  })
);

export const TextService = styled(Typography)(
  sx({
    fontFamily: theme.typography.h6.fontFamily,
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
    lineHeight: theme.typography.h6.lineHeight,
    textAlign: 'left',
    position: 'relative',
  })
);

export const SecctionFilter = styled('div')(
  ({ theme }) => `
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;
    `
);

export const ButtonFilter = styled(Button)(
  ({ theme }) => `
        font-size: 14px;
        font-weight: 370;
    `
);

export const SectionListFilter = styled('div')(
  ({ theme }) => `
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    `
);

export const SelectFilter = styled(Select)(
  ({ theme }) => `
        color: ${theme.palette.primary.main};
        font-size: 14px;
        padding: 2px;
        .MuiOutlinedInput-notchedOutline{ 
            border: none;
        }
        .css-1slpsil-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
            padding:2px;
        }
    `
);

// drawer

export const CustomDrawerFilter = styled(Drawer)(
  () => `
    & .MuiPaper-root.MuiPaper-elevation {
      overflow-y: unset;
    }
    & .MuiDrawer-paper {
      width: 414px;
    }
    @media only screen and (max-width: 768px) {
      .MuiDrawer-paper {
        width: 100%;
      }
    }
    `
);

export const ContainerModal = styled(Grid)(
  ({ theme }) => `
      &::-webkit-scrollbar {
          width: .4rem;
      }
      &::-webkit-scrollbar-track {
          background-color: ${theme.palette.bgScrollBar.primary};
      }
      &::-webkit-scrollbar-thumb {
          background-color: ${theme.palette.bgScrollBar.secondary};
          border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:active {
        background-color: ${theme.palette.bgScrollBar.disabled};
      }
      overflow-y: auto;
      padding: 16px;
      height: 100%;
      margin-bottom: 80px;
      & .MuiFormControl-root {
        width: 100%;
      }
    `
);

// export const CustomListFilter = styled(List)(
//   ({ theme }) => `
//       width: 100%;
//       height: 100%;
//       padding: 0;
//       margin: 0;
//       margin-bottom: 80px;
//       & .MuiListItem-root {
//         width: 100%;
//       }
//       overflow-y: auto;
//     `
// );

export const ContainerButtom = styled(Stack)(
  () => `
      background-color: white;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 16px;
      z-index: 999;
    `
);

export const SectionListCardsFilter = styled(Grid)(
  () => `
    margin-top: 20px;
    margin-bottom: 30px;
    `
);

// filter

export const ContainerListButtons = styled('div')(
  () => `
    display: flex;
    align-items: center;
    gap: 16px 8px;
    flex-wrap: wrap;

    `
);

export const ButtonSelectDateFilter = styled(Button)(
  ({ theme }) => `
        font-size: 14px;
        font-weight: 365;
        line-height: 20px;
        color: ${theme.palette.secondary.main};
        border: 1px solid ${theme.palette.secondary.main};
        border-radius: 25px;
        padding: 6px 12px;
        gap: 8px;
        font-style: normal;
        text-transform: none;
        &.active { 
          background-color: ${theme.palette.secondary.main};
          color: white;
        }
      `
);

export const DatePickerFilter = styled(DatePicker)(
  ({ theme }) => `
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border-color: transparent;
    border-bottom: 1px solid #E5E5E5;
  }
  `
);

export const sxSection = {
  backgroundColor: 'white',

  alignItems: {
    xs: 'flex-start',
    md: 'center',
  },
  padding: 0,
  pl: '24px',
  pr: '24px',
};

export const styleContainer = {
  maxWidth: '1234px',
  minHeight: '350px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: 4,
};

export const wrapperProps = {
  xs: 12,
  sm: 12,
  md: 6,
  lg: 4,
};

export const stackOrderByListStyles = {
  position: 'absolute',
  right: 0,
  visibility: 'hidden',
  bottom: 1,
};

export const stackContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  marginBottom: '16px',
  position: 'relative',
};

export const BoxSectionInfo = styled(Box)(
  () => `
    width: 100vw;
    & div {
      padding-top: 0;
    }
  `
);
