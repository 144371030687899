import { NoticeDetailAuctionModel } from "@/models/notice/noticeV2NegotationModels";
import { textColorDescount } from "@/pages/acceptanceOffert/components/SectionDescount";
import { currencyFormat } from "@/utils/helpers";
import { Box, Typography } from "@mui/material";

const AuctionOfferPrice = ({ data }: { data: NoticeDetailAuctionModel }) => {
  return (
    <>
      <Box sx={{ display: "flex", pt: 2, alignItems: "center" }}>
        <Typography sx={{ fontWeight: 400, fontSize: "16px" }}>
          Antes:
        </Typography>
        <Typography
          sx={{
            ml: 1,
            fontWeight: 400,
            fontSize: "16px",
            textDecoration: "line-through",
          }}
        >
          {currencyFormat(data.precio)}
        </Typography>
        <Typography
          sx={{
            ml: 1,
            fontWeight: 700,
            fontSize: "16px",
            textAlign: "revert",
            color: textColorDescount,
          }}
        >
          {data.aviso?.porcentajedescuento as number}% dscto
        </Typography>
      </Box>
      <Typography
        sx={{ fontWeight: 700, fontSize: "22px", textAlign: "revert" }}
      >
        {currencyFormat(data.precio)}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
          Peso del remate:
        </Typography>
        <Typography
          sx={{
            ml: 1,
            fontWeight: 400,
            fontSize: "16px",
          }}
        >
          {data.aviso.pesomaximo}
        </Typography>
      </Box>
    </>
  );
};

export default AuctionOfferPrice;
