import { DRIVER_TYPE_NOTICE, REGISTER_DRIVER_CREATE_NOTICE_TYPES, REGISTER_DRIVER_TYPE_NOTICE } from "@/consts/noticeV2Paths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { setSelectNotice } from "@/store/noticeV2Redux/noticeV2Slice";
import { fillSendDataThunk, getListNoticeV2Thunk, resetSendDataThunk } from "@/store/noticeV2Redux/noticeV2Thunk";
import { IReturnDataValues } from "@/store/noticeV2Redux/noticeV2types";
import { GetOffertProps } from "@/store/routeActionsFlowV2/config";
import { initRouteFlowByIdNoticeV2Thunk } from "@/store/routeActionsFlowV2/routeActionsFlowNoticeV2Thunk";
import { setSelectedVehicle } from "@/store/userRedux/userSlice";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { extractSendData, getNoticeType, getNoticeTypeInterface } from "../adapters/noticeUseTypes";
import { INoticeCreateList, INoticeCreateListItem } from "./types";

const useNoticeListHook = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        pathname,
    } = location;
    const [loading, setLoading] = useState<boolean>(false);
    const [noticeList, setNoticeList] = useState<INoticeCreateListItem[]>([]);
    const isRegisterDriver = (pathname === REGISTER_DRIVER_CREATE_NOTICE_TYPES);
    const {
        noticeV2: { sendData },
        user: { vehicles: [vehiculo] }
    } = useAppSelector((state) => state);

    useEffect(() => {
        initNoticeList();
    }, []);

    const initNoticeList = async () => {
        await setLoading(true);
        await dispatch(getListNoticeV2Thunk(noticeCreateList.noticeList,
            isRegisterDriver
        )).then((res: any) => {
            setNoticeList(res);
            setLoading(false);
        });
        await dispatch(resetSendDataThunk());
        await dispatch(setSelectedVehicle({}));
        setLoading(false);
    }

    const handleInitRouteFlow = (idNotice: number, nameReplace: string, typeNotice: GetOffertProps['typeNotice'] = 'noticeFlow',) => {
        const nextRoute = dispatch(initRouteFlowByIdNoticeV2Thunk(idNotice, nameReplace, typeNotice));
        navigate(nextRoute);
    };

    const handleClick = (item: INoticeCreateListItem) => {
        dispatch(setSelectNotice(item));
        
        let body = {
            idtipoaviso: item.id,
        } as Partial<IReturnDataValues>;

        if (isRegisterDriver) {
            body.idvehiculo = vehiculo.id;
            body.pesomaximo = vehiculo.cargamaxima;
        }
        dispatch(fillSendDataThunk({
            key: getNoticeType(item.id),
            value: getNoticeTypeInterface(item.id, {
                ...extractSendData(sendData, item.id || 0),
                ...body,
            })
        })).then(() => {
            handleInitRouteFlow(item.id, 
                isRegisterDriver ? REGISTER_DRIVER_TYPE_NOTICE : DRIVER_TYPE_NOTICE, 
                isRegisterDriver ? 'noticeFlowRegister' : 'noticeFlow'
            );
        })
    };

    const handleBackNavigate = () => {
        navigate(-1);
    };

    return {
        loading,
        header: noticeCreateList,
        noticeList,
        handleClick,
        isRegisterDriver,
        handleBackNavigate,
    };
};


export default useNoticeListHook;




const noticeCreateList: INoticeCreateList = {
    title: '¡Crea gratis tus avisos!',
    subTitle: 'Elige el tipo de servicio que quieres ofrecer.',
    noticeList: [
        {
            id: 1,
            title: "Transporte en la ciudad",
            description: "Ofrece un servicio de un punto a otro dentro de la ciudad.",
            icon: "icon-inCity",
        },
        {
            id: 3,
            title: "Transporte entre ciudades",
            description: "Ofrece un servicio de una ciudad a otra. Ej. Lima a Piura.",
            icon: 'icon-betweenCities'
        },
        {
            id: 2,
            description: "Recoge y entrega pedidos dentro de uno o varios distritos de cobertura.",
            icon: "icon-multiDestiny"
        },
        {
            id: 5,
            description: "Ofrece un servicio de mudanza dentro o fuera de la ciudad.",
            icon: "icon-moving"
        },
        {
            id: 4,
            description: "Evita los falsos fletes vendiendo los espacios vacíos de tu vehículo.",
            icon: "icon-auction"
        },
    ]
}


