import { DRIVER_TYPE } from '@/consts/defaultConsts';
import { NO_ACCESS_CLIENT } from '@/consts/typeServicesPaths';
import { UsuarioAPI } from '@/models/userAuth/user';
import { RootState } from '@/store/store';

export const privateRuteOnlyDriver = (state: RootState) => {
  const currentUser = state.user.user as UsuarioAPI;

  if (
    !Object.keys(currentUser).length ||
    currentUser?.tipousuario !== DRIVER_TYPE
  ) {
    return NO_ACCESS_CLIENT;
  }

  return '';
};
