import SkeletonHeader from "@/components/skeletons/SkeletonHeader";
import { ADS_LANDING_PATH } from "@/consts/typeServicesPaths";
import useObserver from "@/hooks/useObserver";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { Advertisement } from "@/models/advertisement/advertisementModel";
import {
  setClickAdvertisementThunk,
  setShowAdvertisementThunk,
} from "@/store/advertisementRedux/advertisementThunk";
import { LegacyRef, memo, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import AdvertisementImage from "../AdvertisementImage";

const defaultAdvertisement = {
  id: 0,
  linkImagen: "Tarjeta-320x200.png",
  linkClick: ADS_LANDING_PATH,
};

const AdvertisementCard = ({ index }: { index: number }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [idLoad, setIdLoad] = useState(0);

  const { pathname } = useLocation();

  const { historyList, historyIndexShow } = useAppSelector(
    (state) => state.advertisement
  );
  const dispatch = useAppDispatch();

  const advertisementRef = useRef<LegacyRef<HTMLAnchorElement> | undefined>(
    undefined
  );

  const { isNearScreen } = useObserver({
    distance: "50px",
    externalRef: advertisementRef,
    hasDisconect: true,
  });

  useEffect(() => {
    if (isNearScreen && idLoad > 0 && !historyIndexShow.includes(index)) {
      dispatch(setShowAdvertisementThunk(idLoad, pathname, index));
    }
  }, [isNearScreen, idLoad]);

  const handleOnLoad = (item: Advertisement) => {
    setIdLoad(item.id);
  };

  const handleOnClick = (item: Advertisement) => {
    if (item.id === 0) return;
    dispatch(setClickAdvertisementThunk(item.id, pathname));
  };

  const handleGetAdvertisement = (): Advertisement => {
    if (!!historyList && !!historyList.length) {
      const advertisement = historyList.find(
        (history) => history.index === index
      );
      if (advertisement) {
        setIsLoading(false);
        return advertisement;
      }
    }

    return defaultAdvertisement;
  };

  const advertisement = useMemo(() => handleGetAdvertisement(), [historyList]);

  if (isLoading) {
    return (
      <SkeletonHeader
        height={200}
        sx={{ borderRadius: "8px", width: "100%" }}
      />
    );
  }

  return (
    <AdvertisementImage
      advertisement={advertisement}
      onClick={handleOnClick}
      onLoad={handleOnLoad}
      externalRef={advertisementRef}
      styleImage={{ width: "100%" }}
    />
  );
};

export default memo(AdvertisementCard);
