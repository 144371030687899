import { AUTH_TOKEN_PATH } from "@/consts/pathServices";
import { UserToken } from "@/models/userAuth/userAuth";
import { connection, instanceAuth } from "@/services/connection";
import { getTokenAdapter } from "./adapters/authAdapter";

export const getTokenServices = async (
  username: string,
  password: string
): Promise<UserToken> => {
  const API = connection(instanceAuth);
  const { params, config } = getTokenAdapter(username, password);

  const { data } = await API.post(AUTH_TOKEN_PATH, params, { ...config });

  return data;
};
