import {
  ID_AUCTION_FLOW,
  ID_BETWEEN_CITIES,
  ID_IN_CITY,
  ID_MOVING_FLOW,
  ID_MULTI_DESTINY,
} from '@/consts/defaultConsts';
import { useAppDispatch } from '@/hooks/useReducer';
import { resetDetailsNoticeSlice } from '@/store/detailsNotice/detailsNoticeSlice';
import { NoticeV2NegType } from '@/store/noticeV2Negotiation/noticeV2NegotiationSlice';
import { getNegotiationByIdThunk } from '@/store/noticeV2Negotiation/noticeV2NegotiationThunk';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useGetData from '../../shared/hooks/useGetData';
import AuctionReject from './views/AuctionReject';
import BtnCitiesReject from './views/BtnCitiesReject';
import InTheCityReject from './views/InTheCityReject';
import MovingReject from './views/MovingReject';
import MultidestinyReject from './views/MultidestinyReject';

const useNegotationFinishedRejectHook = () => {
  const location = useLocation();
  const { notice, isLoadingNoticeV2 } = useGetData();
  const dispatch = useAppDispatch();
  const { idOffert = '' } = useParams();
  useEffect(() => {
    dispatch(resetDetailsNoticeSlice());
    dispatch(getNegotiationByIdThunk(Number(idOffert)));
    return () => {
      dispatch(resetDetailsNoticeSlice());
    };
  }, []);

  return {
    isLoadingNoticeV2,
    notice,
    idOffert,
  };
};

export const componentToRender: Record<
  number,
  ({ notice }: { notice: NoticeV2NegType }) => JSX.Element
> = {
  [ID_IN_CITY]: InTheCityReject,
  [ID_BETWEEN_CITIES]: BtnCitiesReject,
  [ID_MULTI_DESTINY]: MultidestinyReject,
  [ID_MOVING_FLOW]: MovingReject,
  [ID_AUCTION_FLOW]: AuctionReject,
};

export default useNegotationFinishedRejectHook;
