import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import SkeletonViewService from "@/pages/loading/SkeletonViewService";
import {
  idToConfigToReturnFormat
} from "@/pages/noticeRequestFlow/adapters/noticeRequestFlowAdapters";
import DynamicComponent from "@/pages/offerts/offertConfirmation/components/DynamicComponent";
import { getNegotationRequestThunk } from "@/store/noticeNegotationRequestRedux/noticeNegRequesThunk";
import { setIsEdition } from "@/store/noticeNegotationRequestRedux/noticeNegRequestSlice";
import { validateAuthorizationByCode } from "@/utils/helpers";
import { useEffect, useId, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useConfigData } from "../../shared/hooks/useConfigData";
import { ReceiveCoCounterOfferLayout } from "./ReceiveCoCounterOfferLayout";
import { ConfigurationToRender } from "./configRender";

interface LocationState {
  hasBackNavigate?: boolean;
  hasBackLinkOffert?: boolean;
}

const NegDriverReceiveCoCounterOffer = () => {
  const currentId = useId();
  const { typeId = "", typeRequest = "" } = useParams();
  let location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    noticeNegotationRequest: {
      errorCode,
      isEdition,
      isLoading,
      savedDataNogotation,
    },
  } = useAppSelector((state) => state);

  const [hasBackNavigate, setHasBackNavigate] = useState(false);
  const [hasBackLinkOffert, setHasBackLinkOffert] = useState(false);
  const { tipoSolicitud } = idToConfigToReturnFormat(
    savedDataNogotation as SolicitudNegociacion
  );

  const { handleInitConfig } = useConfigData();

  useEffect(() => {
    dispatch(getNegotationRequestThunk(typeId || typeRequest));

    if (
      (location.state as LocationState)?.hasBackNavigate &&
      !(location.state as LocationState)?.hasBackLinkOffert
    ) {
      setHasBackNavigate(true);
    }

    if (
      (location.state as LocationState)?.hasBackLinkOffert &&
      !(location.state as LocationState)?.hasBackNavigate
    ) {
      setHasBackLinkOffert(true);
    }
    if (isEdition) dispatch(setIsEdition(false));
  }, []);

  useEffect(() => {
    validateAuthorizationByCode({ errorCode, navigate });
  }, [errorCode]);

  const handleRenderConfiguration = () => {
    const valueExists = Object.keys(ConfigurationToRender).some(
      (item) => +item === tipoSolicitud
    );

    if (valueExists) {
      const value = ConfigurationToRender[tipoSolicitud];

      return value;
    } else {
      return [];
    }
  };

  const valueArray = useMemo(
    () => handleRenderConfiguration(),
    [savedDataNogotation]
  );

  if (!savedDataNogotation || isLoading) return <SkeletonViewService />;

  return (
    <>
      <ReceiveCoCounterOfferLayout>
        {valueArray.map((item, i) => (
          <DynamicComponent
            key={`${currentId}-${i}`}
            {...item}
            {...handleInitConfig(item)}
          />
        ))}
      </ReceiveCoCounterOfferLayout>
    </>
  );
};

export default NegDriverReceiveCoCounterOffer;
