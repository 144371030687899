import DetailsTextV2 from '@/components/DetailsTextV2/Index';
import { districtsDeliveryText } from '@/consts/noticeConsts';
import { ImagesNegV2 } from '@/pages/noticeV2Negotation/components/ImagesNegV2';
import { ConfigDataNegotationV2 } from '@/pages/noticeV2Negotation/shared/hooks/useNoticeConfigDataV2';
import { Typography } from '@mui/material';

export const generalConfigDataSuccessMultidestinyV2: ConfigDataNegotationV2[] =
  [
    {
      title: 'Detalles del servicio',
      Component: Typography,
      props: {
        variant: 'subtitle1medium',
        sx: { marginTop: '10px', display: 'block' },
      },
    },
    {
      Component: DetailsTextV2,
      props: {
        icon: 'icon-calendar',
        title: 'Fecha',

        originDescription: '',
      },
      valid: [
        {
          property: 'fecha',
          componentProp: 'show',
          defaultResult: false,
        },
        {
          property: 'fecha',
          componentProp: 'origen',
          defaultResult: '',
        },
      ],
    },
    {
      Component: DetailsTextV2,
      props: {
        icon: 'icon-location-light',
        title: districtsDeliveryText,
        isMultidestiny: true,
      },
      valid: [
        {
          property: 'destinoTexto',
          componentProp: 'show',
          defaultResult: false,
        },
        {
          property: 'destinoTexto',
          componentProp: 'origen',
          defaultResult: '',
        },
      ],
    },

    {
      title: 'Descripción de la carga',
      Component: Typography,
      props: {
        variant: 'subtitle1medium',
        sx: { marginTop: '10px', display: 'block' },
      },
    },
    {
      Component: ImagesNegV2,
      props: {
        style: { marginTop: '13px' },
      },
      valid: [
        {
          property: 'fotos',
          componentProp: 'images',
          defaultResult: [],
        },
      ],
    },

    {
      Component: Typography,
      props: {
        sx: {
          marginTop: '10px',
          display: 'block',
          wordWrap: 'break-word',
          maxWidth: '320px',
          fontWeight: 'none',
        },
      },
      valid: [
        {
          property: 'descripcionCarga',
          componentProp: 'title',
          defaultResult: '',
        },
      ],
    },
  ];
