import SkeletonCustomGroup from "@/components/skeletons/SkeletonCustomGroup";
import { HOME_PAHT, MY_PENDING_AGREEMENTS, NOT_FOUND, NO_ACCESS } from "@/consts/typeServicesPaths";
import { useAppSelector } from "@/hooks/useReducer";
import DynamicComponent from "@/pages/offerts/offertConfirmation/components/DynamicComponent";
import { configCounterOfferText } from "@/pages/offerts/offertConfirmation/configDataOffertConfirmation";
import { Box, Button, Container, Stack, Typography, styled, experimental_sx as sx } from "@mui/material";
import { useEffect, useId, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ConfigDataAcceptance, handleValidAcceptanceProps } from "../interfaces/acceptanceInterface";
import { handleValidAcceptance } from "../utils/helpersAcceptance";
import { editCounterOfferConfig } from "./editCounterOfferConfig";

const ContainerHeader = styled(Stack)(
  () => `
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    margin-bottom: 16px;
  `
);

export const ButtonCounterOffer = styled(Button)(
  sx({
    heigth: "44px",
    color: "#FF6700",
    backgroundColor: "#FFFFFF",
    border: "1px solid #FF6700",
    borderRadius: "44px",
    mt: "10px",
    "&:hover": {
      backgroundColor: "white",
    },
  })
);

const EditCounterOfferConfirmation = () => {
  const {
    offert: { savedOffert, isLoading, errorCode },
    user: { user },
  } = useAppSelector((state) => state);
  const { subTitle, title } = configCounterOfferText;
  const currentId = useId();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !!errorCode) {
      const path = errorCode === 404 ? NOT_FOUND : NO_ACCESS;
      navigate(path, { replace: true });
    } else if (!isLoading && !savedOffert) {
      navigate(HOME_PAHT);
    }
  }, [savedOffert, isLoading]);

  const handleGetConfig = () => {
    return editCounterOfferConfig;
  };

  const handleInitConfig = (config: ConfigDataAcceptance) => {
    const configuration = {
      config,
      savedOffert,
      user
    } as handleValidAcceptanceProps

    let moreProps = handleValidAcceptance(configuration);

    return { ...moreProps };
  };

  const onRedirectToPendingOfferts = () => {
    navigate(MY_PENDING_AGREEMENTS, { replace: true });
  };


  const selectdConfigData = useMemo(() => handleGetConfig(), []);

  if (isLoading || !savedOffert) {
    return (
      <Box sx={{ marginTop: "35px" }}>
        <SkeletonCustomGroup
          isContainer
          skeletonList={[
            { height: 104, width: 328 },
            { height: 52, width: 328 },
            { height: 433, width: 328 },
          ]}
          gap="16px"
        />
      </Box>
    );
  }

  return (
    <Container maxWidth="xs" sx={{ marginTop: "35px" }}>
      <ContainerHeader>
        <Stack>
          <i className="icon-alert-success--big"></i>
        </Stack>
        <Stack marginLeft="16px">
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2">{subTitle}</Typography>
        </Stack>
      </ContainerHeader>
      {selectdConfigData.map((item, i) => (
        <DynamicComponent key={`${currentId}-${i}`} {...item} {...handleInitConfig(item)} />
      ))}
      <br />
      <ButtonCounterOffer fullWidth onClick={onRedirectToPendingOfferts}>
        VER OTROS ACUERDOS POR ACEPTAR
      </ButtonCounterOffer>
    </Container>
  );
};

export default EditCounterOfferConfirmation;
