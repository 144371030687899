import { IconButton, styled, Typography } from "@mui/material";
const Container = styled("div")(
  () => `
          display: flex;
          gap: 16px;
          align-items: center;
          justify-content: space-between;
          height: 64px;
          border-bottom: 1px solid #E2E2E2;
          padding: 14px 14px 14px 0;
      `
);
const ButtonsCount = styled("div")(
    () => `
          display: flex;
          gap: 14px;
          align-items: center;
      `
  );
  const ButtonplusAndMin = styled(IconButton)(
    () => `
          width: 36px;
          height: 36px;
          border: 2px solid rgba(0, 0, 0, 0.87);
          color: #000000;
          font-weight: 500;
          font-size: 24px;
          text-align: center;
          align-items: center;
          justify-content: center;
            &:disabled {
                color: #E2E2E2;
                border: 2px solid #E2E2E2;
                background-color: #F2F2F2;
            }
      `
  );
interface AmbientesProps {
    title: string;
    idAmbiente: number;
    cantidad: number;
    onChange: (idAmbiente: number, cantidad: number) => void;
}
const Ambientes = ({ title, idAmbiente, cantidad, onChange }: AmbientesProps) => {
  return (
    <Container>
      <Typography variant="label2" color="GrayText">
        {title}
      </Typography>
        <ButtonsCount>
            <ButtonplusAndMin
                onClick={() => {
                    if (cantidad > 0) {
                        onChange(idAmbiente, cantidad - 1);
                    }
                }}
                disabled={cantidad <= 0}
            >
                -
            </ButtonplusAndMin>
            <Typography
              variant="label2"
              color="black"
              sx={{
                fontSize: "18px",
                width: "20px",
                textAlign: "center",
              }}
            >
                {cantidad}  
            </Typography>
            <ButtonplusAndMin
                onClick={() => {
                    onChange(idAmbiente, cantidad + 1);
                }}
                disabled={cantidad >= 10}
            >
                +
            </ButtonplusAndMin>
        </ButtonsCount>

    </Container>
  );
};

export default Ambientes;
