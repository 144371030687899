import { ISolicitudNegociacion } from '@/services/interfaces/RequestNegResponseSuccess';
import { currencyFormat } from '@/utils/helpers';
import { Box, Typography } from '@mui/material';
import React from 'react'
interface IPriceRequestAcceptProps {
    data: ISolicitudNegociacion;
  }
const MovingNegPrice = ({ data }: IPriceRequestAcceptProps) => {
  return (
    <>
    <Box sx={{ display: "flex", alignItems: "flex-start", mt: 2 }}>
      <Typography sx={{ fontWeight: 700, fontSize: "22px" }}>
      Acuerdo cerrado: {currencyFormat(data?.precio)}
      </Typography>
      <Typography sx={{ ml: 1, fontWeight: 400, fontSize: "14px" }}>
        Por viaje
      </Typography>
    </Box>
    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
      <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
        Cantidad de ayudantes:
      </Typography>
      <Typography sx={{ ml: 1, fontSize: "16px" }}>
        {data.ayudantes}
      </Typography>
    </Box>
  </>
  )
}

export default MovingNegPrice
