import Icon, { IconTypes } from "@/utils/Icon";
import { FC } from "react";
import { Title } from "../../notice/noticeList/styleNoticeList";
import { ContainerHeader } from "./style";
import { Typography } from "@mui/material";



type Props = {
  icon: IconTypes;
  title: string;
  subtitle?: string;
};

const HeaderSuccess: FC<Props> = ({ icon, title, subtitle }) => {
  return (
    <ContainerHeader container alignItems="center" justifyContent="center" gap="8px">
      <Icon name={icon} color="success" size="xl" />

      <Title variant="h6">{title}</Title>
      {
        subtitle && <Typography sx={{ fontSize: "14px", textAlign: "center" }} variant="body2">
          {subtitle}
        </Typography>
      }
    </ContainerHeader>
  );
};

export default HeaderSuccess;
