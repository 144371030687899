import { BASE_URL_IMAGE } from "@/config/config";
import { useAppSelector } from "@/hooks/useReducer";
import { TiposFiltro } from "@/store/FilterServices/filterReduxInterface";
import { Box, Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, Typography } from "@mui/material";

type Props = {
  data: TiposFiltro;
  handleCheck: (value: string, tipo: number) => void;
  checkArrFilters: TiposFiltro[];
};

const CheckList = ({ data, handleCheck, checkArrFilters }: Props) => {
  const isChecked = (value: string, nombre: string): string => {
    const filter = checkArrFilters.find((item) => item.nombre === nombre);
    if (filter) {
      return filter.opciones.find((item) => item === value) as string;
    }
    return "";
  };

  return (
    <>
      {data.tipoComponente === 1 ? (
        <RadioGroup onChange={(e) => handleCheck(e.target.value, Number(data.tipoComponente))}>
          {data?.opciones?.map((value, index) => (
            <FormControlLabel
              key={index}
              value={value}
              control={<Radio checked={isChecked(value, data.nombre) === value} />}
              label={value}
            />
          ))}
        </RadioGroup>
      ) : (
        <FormGroup>
          {data?.opciones?.map((value, index) => (
            <FormControlLabel
              control={<Checkbox checked={isChecked(value, data.nombre) && value ? true : false} />}
              label={
                <Box sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                }}>
                  {data.opciones2 && data.opciones2[index] && (
                    <img src={`${BASE_URL_IMAGE}${data.opciones2[index]}`} alt={data.nombre} style={{ width: 72, height: 72 }} />
                  )}
                  <Typography variant="body2">{value}</Typography>
                </Box>
              }
              key={index}
              onChange={(e) => handleCheck(value, Number(data.tipoComponente))}
              // checked={isChecked(value, data.nombre)}
            />
          ))}
        </FormGroup>
      )}
    </>
  );
};

export default CheckList;
