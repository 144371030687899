import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ContainerMessageLand,
  ListItems,
  ListItemsBody,
  Title,
  Text,
  ButtonHome,
} from "./styleMessageLand";
import { ADS_CONFIRMATION_SPONSORED_LANDING_PATH } from "@/consts/typeServicesPaths";

interface Props {
  icon?: string;
  title?: string;
  text?: string;
  children?: React.ReactNode;
}

const MessageLand: FC<Props> = (props) => {
  const { pathname } = useLocation();
  const isSponsoredPage = pathname.endsWith(
    ADS_CONFIRMATION_SPONSORED_LANDING_PATH
  );
  const { icon = "icon-person--success", title, text, children } = props;

  const handleIcon = () => {
    let newIcon = icon;
    if (isSponsoredPage) {
      newIcon = "icon-check--green-success";
    }

    return newIcon;
  };

  const navigate = useNavigate();

  return (
    <ContainerMessageLand sx={{alignItems: "center"}}>
      <ListItems >
        <i className={handleIcon()} />
        <ListItemsBody>
          <Title>{title}</Title>
          <Text>{text}</Text>
          {children}
        </ListItemsBody>
        <ButtonHome
          variant="outlined"
          color="org"
          onClick={() => navigate("/")}
        >
          IR AL INICIO
        </ButtonHome>
      </ListItems>
    </ContainerMessageLand>
  );
};

export default MessageLand;
