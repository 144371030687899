import { NoticeDetailAuctionModel } from "@/models/notice/noticeV2NegotationModels";
import { Box, Card, Grid, Typography, styled } from "@mui/material";
import { handleDataAuctionToRenderFinished } from "./config";
import { currencyFormat } from "@/utils/helpers";

export const CustomCard = styled(Card)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  minWidth: "275px",
  padding: "8px",
  boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  minHeight: "100px",
}));

const AuctionCounterOfferPrice = ({
  data,
}: {
  data: NoticeDetailAuctionModel;
}) => {

  return (
    <Box>
      <Typography
        sx={{ fontWeight: 700, fontSize: "22px", textAlign: "revert", mt: 2 }}
      >
        Contraoferta rechazada: {currencyFormat(data.precio)}
      </Typography>
      <Typography sx={{ fontWeight: 700, fontSize: "16px", display: "flex", gap: "4px" }}>
        Peso del remate: <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>{data.aviso.pesomaximo} kilos</Typography>
      </Typography>
    </Box>
  );
};

export default AuctionCounterOfferPrice;
