import {
  CustomerRequestState,
  ICustomRequestStatusServices,
} from '@/models/customerRequest/customerRequestResponse';
import { IStateRequestDetails } from '@/models/customerRequest/requestDetailsVewResponse';
import { OFFERT_CUSTOMER_REQUEST_PATH } from '../shared/consts/routes';

export interface IDefatultButtonListRules extends ICustomRequestStatusServices {
  id: number;
  title: string;
  variant: 'text' | 'outlined' | 'contained' | undefined;
  isDisabled: boolean;
  idAction?: number;
  link?: string;
  concatLink?: boolean;
  flg: keyof IStateRequestDetails;
  showFlg: keyof IStateRequestDetails;
}
export type ListItemsButtonsCustomerRequest = {
  listButtons: IDefatultButtonListRules[];
};

export interface IApplicationButtonListRules {
  buttonsPrivateList: ButtonsPrivateList[];
  buttonsPublicList: ButtonsPublicList[];
}

export interface ButtonsPrivateList extends IDefatultButtonListRules {}

export interface ButtonsPublicList extends IDefatultButtonListRules {}

export enum TypeStateCR {
  'activate' = 1,
  'deactivate' = 2,
}

export const applicationButtonCustomRules: IApplicationButtonListRules = {
  buttonsPrivateList: [
    {
      id: 8,
      title: 'NEGOCIACIONES DE LA SOLICITUD',
      variant: 'contained',
      isDisabled: false,
      link: OFFERT_CUSTOMER_REQUEST_PATH,
      concatLink: true,
      flg: 'flgPermiteVerNegociaciones',
      showFlg: 'flgVerNegociaciones',
    },
    {
      id: 1,
      title: 'EDITAR',
      variant: 'contained',
      isDisabled: false,
      link: '/customer-request/edit/',
      concatLink: true,
      flg: 'flgPermiteEditar',
      showFlg: 'flgVerEditar',
    },
    {
      id: 2,
      title: 'VOLVER A SOLICITAR',
      variant: 'outlined',
      isDisabled: false,

      link: '/customer-request/renew/',
      concatLink: true,
      flg: 'flgPermiteRenovar',
      showFlg: 'flgVerRenovar',
    },
    {
      id: 3,
      title: 'CANCELAR',
      variant: 'outlined',
      isDisabled: false,

      link: '/customer-request/deactivate/',
      concatLink: true,
      tipoestado: TypeStateCR.deactivate,
      flg: 'flgPermiteDesactivar',
      showFlg: 'flgVerDesactivar',
    },
    {
      id: 3,
      title: 'DESACTIVAR',
      variant: 'contained',
      isDisabled: false,

      link: '/customer-request/activate/',
      concatLink: true,
      tipoestado: TypeStateCR.activate,
      flg: 'flgPermiteActivar',
      showFlg: 'flgVerActivar',
    },
  ],
  buttonsPublicList: [
    {
      id: 4,
      idAction: 1,
      title: 'ACEPTAR',
      variant: 'contained',
      isDisabled: false,
      link: '/customer-request/accept/',
      flg: 'flgPermiteAceptar',
      showFlg: 'flgVerAceptar',
    },
    {
      id: 5,
      idAction: 2,
      title: 'CONTRAOFERTAR',
      variant: 'outlined',
      isDisabled: false,

      link: '/customer-request/counter-offer/',
      flg: 'flgPermiteContraofertar',
      showFlg: 'flgVerContraofertar',
    },
    {
      id: 6,
      idAction: 3,
      title: 'OFERTAR',
      variant: 'outlined',
      isDisabled: false,
      link: '/customer-request/offer/',
      flg: 'flgPermiteOfertar',
      showFlg: 'flgVerOfertar',
    },
    {
      id: 7,
      idAction: 4,
      title: 'COTIZAR',
      variant: 'contained',
      isDisabled: false,
      link: '/customer-request/quote/',
      flg: 'flgPermiteCotizar',
      showFlg: 'flgVerCotizar',
    },
  ],
};
