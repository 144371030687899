import { BASE_URL_IMAGE } from "@/config/config";
import { DECLINE_OFFERT } from "@/consts/defaultConsts";
import { NEGOTATION_CLIENT_PATH } from "@/consts/negotationFlowPath";
import { useAppSelector } from "@/hooks/useReducer";
import { BoxButton, ButtonDecline, GridButton, GridImage, Text } from "@/pages/noAccess/stylesNoAccess";
import { Box, Grid } from "@mui/material";
import { Container } from "@mui/system";
import { useNavigate } from "react-router-dom";

const title = "Solicitud cancelada";
const message = "Cuando desees, puedes volver a solicitar el servicio desde el historial.";

const CancelNotice = () => {
  const navigate = useNavigate();

  const imageUrl = BASE_URL_IMAGE + DECLINE_OFFERT;

  const handleRedirectionSolicitud = () => {
    let nextRoute = NEGOTATION_CLIENT_PATH;
    navigate(nextRoute, { replace: true });
  };
  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container spacing={2}>
        <GridImage item xs={12}>
          <Box
            component="img"
            sx={{
              height: 353,
              width: 350,
            }}
            alt="Imagen de rechazo"
            src={imageUrl}
          />
          <Text sx={{fontWeight: "bold"}}>¡{title}!</Text>
          <Text>{message}</Text>
        </GridImage>
        <GridButton item xs={12}>
          <BoxButton>
            <ButtonDecline fullWidth variant="text" onClick={handleRedirectionSolicitud}>
              VER MIS SOLICITUDES
            </ButtonDecline>
          </BoxButton>
        </GridButton>
      </Grid>
    </Container>
  );
};

export default CancelNotice;
