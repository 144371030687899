import LayoutFlow from "@/components/LayoutFlow/LayoutFlow";
import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import BannerModal from "@/components/modals/BannerModal/BannerModal";
import VehicleListModal from "@/components/modals/vehicleListModal/VehicleListModal";
import LabelRequired from "@/components/utils/LabelRequired";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { DirtyTypeProps } from "@/interfaces/globalInterface";
import { TipoVehiculo } from "@/models/vehicle/vehicleModel";
import { cleanError } from "@/store/vehicle/vehicleSlice";
import {
  createVehicleThunk,
  getVehicleListThunk,
  hydrateVehicleThunk,
  selectedVehicleThunk,
} from "@/store/vehicle/vehicleThunk";
import {
  cleanValueNumber,
  formatVehiclePlate,
  getNextPage,
} from "@/utils/helpers";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FormControl, InputAdornment, Typography, styled } from "@mui/material";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { To, useLocation, useNavigate } from "react-router-dom";
import HeaderFlow from "../../components/HeaderFlow";
import { Form } from "../../components/styleLoginRegister";
import { objectNextPage } from "./configDataVehicle";

const Description = styled(Typography)(
  ({ theme }) => `
    margin-top: 16px;
    color: ${theme.palette.text.secondary};
  `
);

const VehicleRegister = () => {
  const [showModal, setShowModal] = useState(false);
  const [vehiclePlate, setVehiclePlate] = useState("");
  const [maximumLoad, setMaximumLoad] = useState("");
  const [dirtyFields, setDirtyFields] = useState<DirtyTypeProps>({
    vehiclePlate: { dirty: false },
  });
  const [showAlert, setShowAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    vehiclePlate: "",
    maximumLoad: "",
  });

  const {
    vehicle: {
      vehicleList,
      selectedVehicle,
      vehiclePlate: vehiclePlateStorage,
      maximumLoad: maximumLoadStorage,
      isLoading,
      error,
    },
    user: { vehicles },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    handleInit();

    return () => {
      dispatch(cleanError());
    };
  }, []);

  const handleInit = async () => {
    if (!selectedVehicle?.id && !!vehicles.length) {
      await dispatch(hydrateVehicleThunk());
    }

    if (!vehicleList.length || !selectedVehicle?.id) {
      dispatch(getVehicleListThunk());
    }
    if (!!Object.keys(selectedVehicle).length || !!selectedVehicle?.id) {
      setVehiclePlate(vehiclePlateStorage);
      setMaximumLoad(maximumLoadStorage);
    }
  };

  const handleOnSeletcTypeVehicle = (selected: TipoVehiculo) => {
    dispatch(selectedVehicleThunk(selected));
    handleCloseModal();

    setDirtyFields((prev) => ({ ...prev, typeOfVehicle: { dirty: true } }));
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSetMessageError = (input: "maximumLoad" | "vehiclePlate") => {
    let message = "";
    if (input === "vehiclePlate" && !validTypeVehicleOptionalPlate()) {
      if (!vehiclePlate) {
        message = "Ingresa la placa o matrícula del vehículo";
      } else if (vehiclePlate.length < 7) {
        message = "La placa debe tener 6 caracteres";
      }
    } else if (input === "maximumLoad") {
      if (!maximumLoad) {
        message = "Ingresa la carga máxima del vehículo";
      } else if (Number(maximumLoad) < 5) {
        message = "La carga máxima no puede ser menor a 5 kilos";
      } else if (Number(maximumLoad) > 50000) {
        message = "La carga máxima no puede ser mayor a 50,000 kilos";
      }
    }

    setErrorMsg({ ...errorMsg, [input]: message });
    return message;
  };

  const handleOnchange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = currentTarget;
    if (name === "maximumLoad") {
      const cleanValue = cleanValueNumber(value);
      setMaximumLoad(cleanValue);
      if (Number(cleanValue) > 10) {
        setErrorMsg({ ...errorMsg, maximumLoad: "" });
      }
    } else {
      const formatValue = formatVehiclePlate(value);
      if (formatValue.length > 7) {
        // los 7 caracteres son 6 de la placa y el guion
        setVehiclePlate(formatValue.slice(0, 7));
        return;
      }
      setVehiclePlate(formatValue);
    }

    setDirtyFields((prev) => ({ ...prev, [name]: { dirty: true } }));
  };

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const upperPlate = vehiclePlate?.toUpperCase();

    if (
      handleSetMessageError("maximumLoad") ||
      handleSetMessageError("vehiclePlate") ||
      isLoading
    ) {
      return;
    }

    setErrorMsg({
      vehiclePlate: "",
      maximumLoad: "",
    });

    const body = {
      placa: validTypeVehicleOptionalPlate() ? "" : upperPlate,
      cargaMaxima: Number(maximumLoad),
      idTipoVehiculo: selectedVehicle.id,
    };
    dispatch(createVehicleThunk(body)).then(handleGoNextPage);
  };

  const handleGoNextPage = () => {
    const nextPage = getNextPage(location.pathname, objectNextPage);

    navigate(nextPage as To, { replace: true });
  };

  const validateForm = () => {
    return (
      (!validTypeVehicleOptionalPlate() && !vehiclePlate) ||
      !maximumLoad ||
      maximumLoad === "0" ||
      !selectedVehicle?.id ||
      isLoading
    );
  };

  const handleBackNavigation = () => {
    const isDirtyForm = Object.values(dirtyFields).some(({ dirty }) => !!dirty);
    if (isDirtyForm) {
      setShowAlert(true);
    } else {
      navigate(-1);
    }
  };

  const validTypeVehicleOptionalPlate = () => {
    // selectedVehicle?.flgPlacaObligatoria si es igual a 1 es requerido si es 0 es opcional
    return selectedVehicle && selectedVehicle?.flgPlacaObligatoria === 0;
  };

  return (
    <LayoutFlow>
      <HeaderFlow title="Tu vehículo" customGoBack={handleBackNavigation} />
      <VehicleListModal
        onSelect={handleOnSeletcTypeVehicle}
        open={showModal}
        onClose={handleCloseModal}
      />
      <>
        {!!error && (
          <StandardAlert
            sx={{ marginBottom: "16px" }}
            severity="error"
            color="error"
          >
            {error}
          </StandardAlert>
        )}
      </>
      <BannerModal
        open={showAlert}
        onClose={() => setShowAlert(false)}
        title="Cambios sin guardar"
        content="Aún no has guardado los cambios realizados. ¿Deseas salir?"
        option1={{
          title: " No, quedarme",
          onClick: () => setShowAlert(false),
        }}
        option2={{
          title: "Sí, salir",
          onClick: () => navigate(-1),
        }}
        color="org"
        sx={{
          width: "322px",
          height: "268px",
        }}
        sxAccion={{
          padding: "0px",
        }}
      />

      <Form onSubmit={handleOnSubmit}>
        <Description variant="body1">
          Para comenzar vas a crear un vehículo. Luego vas a poder crear más
          vehículos en tu perfil.
        </Description>
        <FormControl>
          <Input
            id="vehicle"
            label={<LabelRequired str="Tipo de vehículo" />}
            value={selectedVehicle?.nombre}
            variant="standard"
            placeholder="Elige el tipo de vehículo"
            onChange={() => setShowModal(true)}
            onClick={() => setShowModal(true)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {" "}
                  <ArrowDropDownIcon />{" "}
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>

        <FormControl>
          <Input
            error={!!errorMsg.vehiclePlate}
            id="vehiclePlate"
            name="vehiclePlate"
            label={
              validTypeVehicleOptionalPlate() ? (
                "Placa del vehículo"
              ) : (
                <LabelRequired str="Placa del vehículo" />
              )
            }
            value={validTypeVehicleOptionalPlate() ? "" : vehiclePlate}
            variant="standard"
            placeholder="Ingresa la  placa o matrícula del vehículo"
            onChange={handleOnchange}
            onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
            helperText={errorMsg.vehiclePlate}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              required: true,
              maxLength: 7,
              sx: {
                textTransform: "uppercase",
                "&.MuiInputBase-input::placeholder": {
                  textTransform: "none",
                },
              },
            }}
            disabled={validTypeVehicleOptionalPlate()}
          />
        </FormControl>

        <FormControl>
          <Input
            error={!!errorMsg.maximumLoad}
            id="maximumLoad"
            name="maximumLoad"
            label={<LabelRequired str="Capacidad de carga máxima (kilos)" />}
            value={maximumLoad}
            variant="standard"
            placeholder="Ingresa el peso que puede cargar tu vehículo"
            onChange={handleOnchange}
            helperText={errorMsg.maximumLoad}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 5,
              inputMode: "numeric",
            }}
          />
        </FormControl>

        <ButtomLoader
          type="submit"
          sx={{ marginTop: 0 }}
          disabled={validateForm()}
          isLoading={isLoading}
        >
          SIGUIENTE
        </ButtomLoader>
      </Form>
    </LayoutFlow>
  );
};

export default VehicleRegister;
