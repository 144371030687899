import { Stack, styled, Typography } from "@mui/material";
import { FC } from "react";

const Title = styled(Typography)(
  ({ theme }) => `
      color: ${theme.palette.text.secondary};
      font-weight: bold;
      margin-left: 8px;
      
    `
);

const ConatinerDetails = styled(Stack)(
  () => `
    justify-content: space-between;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin-top: 10px;
  `
);

const DetailText = styled("a")(
  ({ theme }) => `
    color: ${theme.palette.primary.main};
    font-weight: bold;
    fontSize: ${theme.typography.body1.fontSize};
    line-height: ${theme.typography.body1.lineHeight};
    text-decoration: none;
  `
);

export type DetailsTextContantProps = {
  show: boolean;
  title: string;
  origen: string;
  icon: string;
  type: "phone" | "mail" | "whatsapp";
};

const DetailsTextContant: FC<DetailsTextContantProps> = ({ show, title, origen, icon, type }) => {
  if (!show) return <></>;

  const isWhatsapp = type === "whatsapp";

  const handlePrepareLink = () => {
    let prefix = "tel:";
    if (type === "mail") {
      prefix = "mailto:";
    } else if (isWhatsapp) {
      prefix = "https://api.whatsapp.com/send?phone=";
    }

    return prefix + origen;
  };

  return (
    <ConatinerDetails>
      <Stack flexDirection="row">
        <i className={icon}></i>
        <Title variant="label1">
          {title}
          {": "}
        </Title>
      </Stack>
      <Stack flex={1} marginLeft="8px" sx={{wordBreak: "break-word"}}>
        <DetailText href={handlePrepareLink()} target={isWhatsapp ? "_blank" : "_self"}>
          {origen}
        </DetailText>
      </Stack>
    </ConatinerDetails>
  );
};

export default DetailsTextContant;
