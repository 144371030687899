import { requestDestinyMovingAdapter } from "@/adapters/store/editNegotationRequestAdapter";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { optionOne, optionTwo } from "@/consts/noticeConsts";
import { useAppDispatch } from "@/hooks/useReducer";
import {
  ISolicitudMovingBetweenCities,
  ISolicitudMovingInCity,
} from "@/models/customerRequest/requestDetailsVewResponse";
import FormRequest from "@/pages/customerRequest/createRequest/Rute/FormOriginOrDestiny/FormRequest";
import useOriginOrDestinyHook from "@/pages/customerRequest/createRequest/Rute/FormOriginOrDestiny/hookOriginOrDestiny";
import {
  setActiveButton,
  setLoading,
  setSelectDestiny,
} from "@/store/customerRequestRedux/customerRequestSlice";
import { uploadDestinyInMovingRequestEdit } from "@/store/noticeNegotationRequestRedux/editNegRequestThunk";
import { ITipoInmuebles } from "@/store/offertRedux/offert.interface";
import { validateValueInput } from "@/utils/helpers";
import { Box, FormControl, Typography } from "@mui/material";
import { ChangeEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { adapterConfigInmueble } from "./configData";

const FormEditDestinyMoving = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    isLoading,
    reference,
    setReference,
    formRequest,
    setFormRequest,
    selectedCityDestiny,
    selectedOrigin,
    selectedDestiny,
    destiny,
    handleChange,
    handleInputClickModal,
    customerRequest,
    tipoInmuebles,
    inCity,
    requestDetailsView,
  } = useOriginOrDestinyHook();

  const {
    id,
    idDestino,
    destinoTexto,
    idTipoInmuebleDestino,
    pisoDestino,
    destinoTexto2,
    flgAscensorDestino,
    referenciaDestino,
    flgCiudadoEntreciudades,
  } = requestDetailsView as ISolicitudMovingInCity &
    ISolicitudMovingBetweenCities;

  useEffect(() => {
    dispatch(setLoading(false));
    if (requestDetailsView) {
      const idInmueble = `${idTipoInmuebleDestino}`;
      const piso = `${pisoDestino}`;
      const elevador = `${flgAscensorDestino}`;

      setReference(referenciaDestino);
      dispatch(
        setSelectDestiny({
          id: idDestino,
          nombre:
            flgCiudadoEntreciudades === optionTwo
              ? destinoTexto2
              : destinoTexto,
        })
      );
      setFormRequest({ idInmueble, piso, elevador });
    }
  }, []);

  const handleSubmit = () => {
    if (isLoading) return;
    const body = requestDestinyMovingAdapter(reference, currentForm);

    dispatch(uploadDestinyInMovingRequestEdit(`${id}`, body)).then(() => {
      navigate(-1);
    });
  };

  const handleOnChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = target;

    const newValue = validateValueInput(value);

    setReference(newValue);
  };

  const IsHomePlace = Number(formRequest.idInmueble) === optionOne;

  const currentForm = {
    idInmueble: formRequest.idInmueble.toString(),
    piso: IsHomePlace ? null : formRequest.piso.toString(),
    elevador: IsHomePlace ? null : formRequest.elevador.toString(),
  };

  const formReq = () => {
    const { idInmueble, piso, elevador } = formRequest;
    const IsHomePlaceId = Number(idInmueble) === optionOne;

    return {
      idInmueble: idInmueble.toString(),
      piso: IsHomePlaceId ? null : piso,
      elevador: IsHomePlaceId ? null : elevador,
    };
  };

  const handleCompareinputs = () => {
    const InmuebleCasa = idTipoInmuebleDestino === optionOne;
    const inmueble = {
      idInmueble: `${idTipoInmuebleDestino}`,
      piso: InmuebleCasa ? null : `${pisoDestino}`,
      elevador: InmuebleCasa ? null : `${flgAscensorDestino}`,
    };

    const currentInputsValue = [reference, formReq()];
    const dataValue = [referenciaDestino, inmueble];

    const result =
      JSON.stringify(currentInputsValue) === JSON.stringify(dataValue);

    return !result;
  };

  const isNumber = (value: string) => {
    return Number(value) !== 0 && Number(value) > 0;
  };

  const handleDisabledButton = () => {
    const currentIdInmueble = Number(currentForm.idInmueble) === optionOne;

    const isValid = currentIdInmueble
      ? [currentForm.idInmueble]
      : [currentForm.idInmueble, currentForm.piso, currentForm.elevador];
    const isInvalid = isValid.some((item) => !isNumber(item as string));

    const conditions = [reference, ...isValid];
    let result = true;

    const conditionsResult = conditions.some((condition) => !condition);
    const sameData = handleCompareinputs();
    dispatch(setActiveButton(sameData));

    if (sameData) {
      result = false;
      if (conditionsResult) {
        result = true;
      }
      if (isInvalid) {
        result = true;
      }
    }

    return result;
  };

  const hanleInputsDestiny = () => {
    const destinyCityName = selectedCityDestiny.nombre;
    const destinyCityNameTwo = selectedCityDestiny.nombre2;
    const originName = selectedOrigin.nombre;

    return {
      option1: {
        ...destiny.input1,
        value:
          destinyCityNameTwo || destinyCityName || (inCity && originName) || "",
        show: false,
      },
      option2: {
        ...destiny.input2,
        value: selectedDestiny.nombre2 || selectedDestiny.nombre || "",
        show: false,
      },
      option3: {
        ...destiny.input3,
        value: reference,
        show: true,
      },
    };
  };
  const {option1, option2, option3} = hanleInputsDestiny();

  return (
    <>
      <Box sx={{ display: "flex", mt: 2, mb: 2 }}>
        <Typography sx={{ fontWeight: 700 }}>Destino:</Typography>
        <Typography sx={{ ml: 1 }}>{selectedDestiny.nombre}</Typography>
      </Box>

      <FormRequest
        activeDisabledInput={true}
        input1={option1}
        input2={option2}
        input3={option3}
        onClick={handleInputClickModal}
        onChange={handleOnChange}
        {...adapterConfigInmueble(
          customerRequest,
          formRequest,
          handleChange,
          tipoInmuebles as ITipoInmuebles[]
        )}
      />

      <FormControl fullWidth>
        <ButtomLoader
          onClick={() => handleSubmit()}
          disabled={handleDisabledButton() || isLoading}
          isLoading={isLoading}
        >
          Guardar
        </ButtomLoader>
      </FormControl>
    </>
  );
};
export default FormEditDestinyMoving;
