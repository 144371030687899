import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import {
  maxLengthPrice,
  optionFive,
  textCannotLowerPrice,
} from "@/consts/noticeConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import SectionOriginDestiny from "@/pages/createServices/fee/SectionOriginDestiny";
import { customerRequestObjectTypes } from "@/pages/customerRequest/shared/consts/customerRequestConsts";
import { TYPE_REQUEST_PATH } from "@/pages/customerRequest/shared/consts/routes";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { setError } from "@/store/customerRequestRedux/customerRequestSlice";
import { saveCustomerRequestThunk } from "@/store/customerRequestRedux/customerRequestThunk";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { FormControl } from "@mui/material";
import { useEffect, useId, useState } from "react";
import { useNavigate } from "react-router-dom";

const FormPriceInCity = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const id = useId();
  const { error } = useAppSelector((state) => state.customerRequest);
  const [price, setPrice] = useState("");
  const [messageError, setMessageError] = useState("");
  const { selectedDestiny, selectedOrigin, isLoading, selectedTypeRequest } =
    useAppSelector((state) => state.customerRequest);


  useEffect(() => {
    setMessageError(error.message);
  }, [error]);

  const handleOnchange = ({ currentTarget }: EventType["change"]) => {
    const { value } = currentTarget;
    const prepareValue = value.replace(/^(0+)/g, "").replace(/[^0-9]/g, "");

    if (prepareValue.length <= maxLengthPrice) {
      setPrice(prepareValue);
    }
  };

  const handleSubmit = () => {
    if (isLoading) return;

    dispatch(saveCustomerRequestThunk(price)).then(handleNextRute);
  };

  const handleNextRute = () => {
    const valueToReplace = customerRequestObjectTypes[selectedTypeRequest.id];
    dispatch(
      getNextRoute(navigate, {
        valueToSearch: TYPE_REQUEST_PATH,
        valueToReplace,
      })
    );
  };

  const handleDisabled = () => {
    return !price || isLoading || !validForm().isValid;
  };

  const validForm = () => {
    return {
      isValid: Number(price) >= optionFive || !price,
      message: textCannotLowerPrice,
    };
  };

  const onCloseAlert = (value: boolean) => {
    if (!value) {
      dispatch(setError({ message: "", status: 0 }));
    }
  };

  return (
    <>
      {!!error && (
        <StandardAlert
          sx={{ marginBottom: "16px" }}
          severity="error"
          color="error"
          onCloseAlert={onCloseAlert}
        >
          {messageError || error}
        </StandardAlert>
      )}
      <SectionOriginDestiny
        destiny={selectedDestiny.nombre}
        origin={selectedOrigin.nombre}
      />
      <FormControl sx={{ marginTop: "32px", width: "100%" }}>
        <Input
          id={`${id}-price`}
          name="price"
          label={<LabelInputRequired str="Ofrece tu precio (S/)" />}
          value={price}
          variant="standard"
          placeholder="Lo que ofreces por este servicio"
          onChange={handleOnchange}
          onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            inputMode: "numeric",
            maxLength: 5,
          }}
          error={!validForm().isValid}
          helperText={!validForm().isValid && validForm().message}
        />
      </FormControl>
      <ButtomLoader
        onClick={handleSubmit}
        sx={{ marginTop: "24px" }}
        isLoading={isLoading}
        disabled={handleDisabled()}
      >
        Solicitar
      </ButtomLoader>
    </>
  );
};

export default FormPriceInCity;
