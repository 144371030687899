import { optionOne, optionTwo } from "@/consts/noticeConsts";
import { DetailNoticeMoving } from "@/models/notice/noticeV2NegotationModels";
import { currencyFormat } from "@/utils/helpers";
import { Box, Typography } from "@mui/material";

const PriceMoving = ({ data }: { data: DetailNoticeMoving }) => {
  const { ultimaAvisoNegociacionAcciones } = data;
  const actionId = ultimaAvisoNegociacionAcciones.tipoAvisoNegociacionAccion
    .id as number || optionOne;

  if (actionId === optionTwo || !data?.precio) return <></>;

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Typography sx={{ fontWeight: 700, fontSize: "22px" }}>
          {currencyFormat(data?.precio)}
        </Typography>
        <Typography sx={{ ml: 1, fontWeight: 400, fontSize: "14px" }}>
          Por viaje
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
          Cantidad de ayudantes:
        </Typography>
        <Typography sx={{ ml: 1, fontSize: "16px" }}>
          {data.ayudantes}
        </Typography>
      </Box>
    </>
  );
};

export default PriceMoving;
