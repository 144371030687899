import HeaderSection from "@/components/headerSection/HeaderSection";
import SectionIconV2 from "@/components/sectionIconV2/SectionIconV2";
import SnackbarNotification from "@/components/snackbar/Snackbar";
import { optionFive } from "@/consts/noticeConsts";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { customerRequestObjectTypes } from "@/pages/customerRequest/shared/consts/customerRequestConsts";
import { REQUEST_MAIN_MOVING_ORIGEN_PATH } from "@/pages/customerRequest/shared/consts/routes";
import { setError } from "@/store/noticeNegotationRequestRedux/noticeNegRequestSlice";
import { getPrevRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { Container, Grid, Stack, Typography, styled } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { defaultError, noticeAcceptNegRequestTypesArea } from "../../noticeRequestFlow/consts/noticeRequestFlowConsts";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Icon from "@/utils/Icon";
import { ID_MOVING_FLOW } from "@/consts/defaultConsts";

const ContainerArea = styled(Container)(
  () => `
    padding: 16px 0;
  `
);

const NegAccept = () => {
  const { removeNotiffication, notiffication } = useNotiffication();
  const dispatch = useAppDispatch();
  const {
    customerRequest: { requestDetailsView },
    noticeNegotationRequest: { error },

  } = useAppSelector((state) => state);

  const idTypeSolicitud = requestDetailsView?.tipoSolicitud.id || 1;

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if ((idTypeSolicitud as number) <= 0) {
      navigate(-1);
    }

    return () => { };
  }, []);

  const { title = "", subTitle = "", icon = "" } = noticeAcceptNegRequestTypesArea[idTypeSolicitud as number];

  const handlePrevRute = () => {
    const valueToReplace = customerRequestObjectTypes[idTypeSolicitud as number];
    dispatch(getPrevRoute(navigate, { valueToSearch: ":typeRequest", valueToReplace }));
    if (!!error) {
      dispatch(setError(defaultError));
    }
    if (error) {
      dispatch(setError(defaultError));
    }
    if (notiffication) {
      removeNotiffication(false);
    }
  };

  const movingTitle = (tit: string) => {
    const valueToReplace = customerRequestObjectTypes[idTypeSolicitud as number];
    if ((idTypeSolicitud as number) === optionFive) {
      if (pathname === REQUEST_MAIN_MOVING_ORIGEN_PATH.replace(":typeRequest", valueToReplace)) {
        return "Origen";
      }

      return "Cotizar";
    }
    return tit;
  };

  return (
    <Container maxWidth="xs">
      <HeaderSection icon="icon-arrow-left--primary" title={movingTitle(title)} subTitle={subTitle} onClick={handlePrevRute} />
      <SnackbarNotification />
      <ContainerArea>
        <SectionIconV2 icon={icon} />
        {
          (idTypeSolicitud !== ID_MOVING_FLOW) && (<>
            <br />
            <Stack
              direction="row"
              padding="8px 0px"
              gap="16px"
              mt="16px"
              alignItems="center"
            >
              <Icon
                name="icon-info"
                size="md"
                color="info"
              />
              <Typography variant="body1">
                Sólo puedes ofrecer tu servicio con un vehículo que cumpla con el peso solicitado.
              </Typography>
            </Stack>
          </>)
        }
        <Outlet />
      </ContainerArea>
    </Container>
  );
};

export default NegAccept;
