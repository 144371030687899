import {
  getBodyAdvertisementRequestAdapter,
  saveClickOfSponsoredBodyAdapter,
} from '@/adapters/store/advertisementAdapter';
import { AdvertisementListThunkProps } from '@/interfaces/advertisementInterface';
import { NoticeData } from '@/models/filterNotice/filterNoticeModels';
import {
  getAdvertisementListServices,
  saveClickOfSponsoredNoticeServices,
  setClickAdvertisementServices,
  setShowAdvertisementServices,
} from '@/services/advertisemnetServices';
import { getMessaggeError } from '../auth/utils';
import { AppDispatch, RootState } from '../store';
import { IUser } from '../userRedux/userReduxInterface';
import {
  setAdvertisementList,
  setError,
  setHistoryIndexShow,
  setHistoryList,
  setHistoryPlinth,
  setLoading,
} from './advertisementSlice';

// Realiza el request y configuracion para los avisos que se muestran en el listado de avisos !!
export const getAdvertisementNoticeListThunk = (
  body: AdvertisementListThunkProps['body']
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const state = getState();

      const newBody = getBodyAdvertisementRequestAdapter(state, body, true);

      const { anunciosData } = await getAdvertisementListServices(newBody);

      const { noticeList } = state.filterNotice;

      dispatch(
        setHistoryList({
          advertisements: anunciosData.anuncios,
          noticeLength: noticeList.length,
        })
      );

      return anunciosData.anuncios;
    } catch (err: any) {
      const { message } = getMessaggeError(err);

      dispatch(setError(message));
      throw new Error(message || undefined);
    }
  };
};

// Realiza el request y configuracion para los avisos que se muestran en los zocalos!!
export const getAdvertisementPlinthThunk = (
  body: AdvertisementListThunkProps['body']
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));

    try {
      const state = getState();

      const newBody = getBodyAdvertisementRequestAdapter(state, body, false);

      const { anunciosData } = await getAdvertisementListServices(newBody);

      dispatch(
        setHistoryPlinth(
          anunciosData.anuncios.map(({ id }) => ({
            id,
          }))
        )
      );

      dispatch(setAdvertisementList(anunciosData.anuncios));
    } catch (err: any) {
      const { message } = getMessaggeError(err);

      dispatch(setError(message));
      throw new Error(message || undefined);
    }
  };
};

// Realiza el request y configuracion para los avisos que se muestran en el listado de solicitudes !!
export const getAdvertisementRequestListThunk = (
  body: AdvertisementListThunkProps['body']
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const state = getState();

      const newBody = getBodyAdvertisementRequestAdapter(state, body, true);

      const { anunciosData } = await getAdvertisementListServices(newBody);

      const { solicitudes } = state.customerRequest;

      dispatch(
        setHistoryList({
          advertisements: anunciosData.anuncios,
          noticeLength: solicitudes.length,
        })
      );

      return anunciosData.anuncios;
    } catch (err: any) {
      const { message } = getMessaggeError(err);

      dispatch(setError(message));
      throw new Error(message || undefined);
    }
  };
};

export const setShowAdvertisementThunk = (
  idAdvertisement: number,
  currentPath: string,
  index?: number
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const {
        user: { user },
      } = getState();
      const idUsuario = (user as IUser).id || 0;
      const body = {
        idUsuario,
        idAnuncio: idAdvertisement,
        urlImpreso: currentPath,
      };

      await setShowAdvertisementServices(body);
      index && dispatch(setHistoryIndexShow(index));
    } catch (err: any) {}
  };
};

export const setClickAdvertisementThunk = (
  idAdvertisement: number,
  currentPath: string
) => {
  return async (_: AppDispatch, getState: () => RootState) => {
    try {
      const {
        user: { user },
      } = getState();
      const idUsuario = (user as IUser).id || 0;
      const body = {
        idUsuario,
        idAnuncio: idAdvertisement,
        urlClick: currentPath,
      };

      await setClickAdvertisementServices(body);
    } catch (err: any) {
      console.error('error', err);
    }
  };
};

// ==================================================
//                  Sponsored
// ==================================================
export const saveClickOfSponsoredNoticeThunk = (selectedNotice: NoticeData) => {
  return async () => {
    try {
      const { id, idpatrociniodetp } = selectedNotice;
      const body = saveClickOfSponsoredBodyAdapter(id, idpatrociniodetp);

      await saveClickOfSponsoredNoticeServices(body);
    } catch (error) {}
  };
};
