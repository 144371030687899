import { optionOne } from "@/consts/noticeConsts";
import useGetDataInSlice from "@/hooks/useGetDataInSlice";
import { useAppSelector } from "@/hooks/useReducer";
import {
  InitialStateDetailsNoticeV2Negotation,
  NoticeV2NegType
} from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import React from "react";
import { whiteList } from "../../shared/const/default";
import { validateAuthorizationByCode } from "@/utils/helpers";
import { useNavigate } from "react-router-dom";

const useGetData = () => {

  const { noticeV2NegotiationSlice } = useAppSelector((state) => state);
  const { isLoadingNoticeV2, error, errorCode, responseData } = noticeV2NegotiationSlice;
  const navigate = useNavigate();
  
  React.useEffect(() => {
    validateAuthorizationByCode({ errorCode, navigate });
  }, [errorCode]);

  const { handleGetCurrentDetailNotice } =
    useGetDataInSlice<InitialStateDetailsNoticeV2Negotation>({
      store: noticeV2NegotiationSlice,
      whiteList,
      isLoading: isLoadingNoticeV2,
    });

  const notice = React.useMemo(
    () => handleGetCurrentDetailNotice(),
    [isLoadingNoticeV2]
  ) as NoticeV2NegType | null;
  const typeNoticeNegotationId = notice?.idTipoAvisoNegociacion as number || optionOne


  return { error, errorCode, notice, typeNoticeNegotationId, isLoadingNoticeV2 , responseData};
};

export default useGetData;
