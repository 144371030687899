import { getNamePathNoticeByIdNoticeV2Adapter } from "@/adapters/routesV2Adapter";
import {
    DRIVER_CREATE_NOTICE_AUCTION_FLOW_PATHS, DRIVER_CREATE_NOTICE_BETWEEN_CITIES_FLOW_PATHS, DRIVER_CREATE_NOTICE_IN_CITY_FLOW_PATHS, DRIVER_CREATE_NOTICE_MOVING_FLOW_PATHS, DRIVER_CREATE_NOTICE_MULTI_DESTINY_FLOW_PATHS,
    REGISTER_DRIVER_CREATE_NOTICE_TYPES,
    REGISTER_DRIVER_CREATE_NOTICE_IN_CITY_FLOW_PATHS,
    REGISTER_DRIVER_CREATE_NOTICE_BETWEEN_CITIES_FLOW_PATHS,
    REGISTER_DRIVER_CREATE_NOTICE_MULTI_DESTINY_FLOW_PATHS,
    REGISTER_DRIVER_CREATE_NOTICE_MOVING_FLOW_PATHS,
    REGISTER_DRIVER_CREATE_NOTICE_AUCTION_FLOW_PATHS,
    DRIVER_TYPE_NOTICE,
    
} from "@/consts/noticeV2Paths";

export interface IRouteFlowByIdTypeNoticeV2 {
    noticeFlow: Array<string>;
    noticeFlowRegister: Array<string>;
}



export const configtRouteFlowByIdTypeNotice: Record<
    number,
    IRouteFlowByIdTypeNoticeV2
> = {
    1: {
        noticeFlow: DRIVER_CREATE_NOTICE_IN_CITY_FLOW_PATHS,
        noticeFlowRegister: REGISTER_DRIVER_CREATE_NOTICE_IN_CITY_FLOW_PATHS
    },
    2: {
        noticeFlow: DRIVER_CREATE_NOTICE_MULTI_DESTINY_FLOW_PATHS,
        noticeFlowRegister: REGISTER_DRIVER_CREATE_NOTICE_MULTI_DESTINY_FLOW_PATHS
    },
    3: {
        noticeFlow: DRIVER_CREATE_NOTICE_BETWEEN_CITIES_FLOW_PATHS,
        noticeFlowRegister: REGISTER_DRIVER_CREATE_NOTICE_BETWEEN_CITIES_FLOW_PATHS
    },
    4: {
        noticeFlow: DRIVER_CREATE_NOTICE_AUCTION_FLOW_PATHS,
        noticeFlowRegister: REGISTER_DRIVER_CREATE_NOTICE_AUCTION_FLOW_PATHS
    },
    5: {
        noticeFlow: DRIVER_CREATE_NOTICE_MOVING_FLOW_PATHS,
        noticeFlowRegister: REGISTER_DRIVER_CREATE_NOTICE_MOVING_FLOW_PATHS
    },
};


export type GetOffertProps = { typeNotice: keyof IRouteFlowByIdTypeNoticeV2 };


export const getRoutesByIdTypeNoticeV2 = (
    idType: number,
    replaceName: string = DRIVER_TYPE_NOTICE,
    typeNotice: GetOffertProps['typeNotice'] = 'noticeFlow',
) => {
    const routeFlow = configtRouteFlowByIdTypeNotice[idType][typeNotice];
    const name = getNamePathNoticeByIdNoticeV2Adapter(idType);
    const routeFlowWithNoticePath = routeFlow.map((route) =>
        route.replace(replaceName, name)
    );
    return routeFlowWithNoticePath;
};