import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { UsuarioAPI } from "@/models/userAuth/user";
import { createServiceTypeDataLayer } from "@/services/dataLayer/createNotice";
import { setResetErrorDuplicated, setVariantMoving } from "@/store/noticeV2Redux/noticeV2Slice";
import { fillSendDataThunk } from "@/store/noticeV2Redux/noticeV2Thunk";
import { getNextRouteV2, getPrevRouteV2 } from "@/store/routeActionsFlowV2/routeActionsFlowNoticeV2Thunk";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { INoticeCreateListItem } from "../NoticeList/types";
import { extractSendData, getNoticeType, getNoticeTypeInterface } from "../adapters/noticeUseTypes";


const useNoticeTypeHook = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    noticeV2: { selectNotice, sendData },
    user: { user },
  } = useAppSelector((state) => state);

  const NoticeType = (selectNotice as INoticeCreateListItem) || 0;
  const currentUser = user as UsuarioAPI;

  const isRegister = currentUser?.flgRegistroAviso === null;

  useEffect(() => {
    if (!isRegister) {
      createServiceTypeDataLayer(
        currentUser?.id || 0,
        NoticeType?.title || ""
      )
    }
  }, [])

  const handleClick = ({ typeFLow }: { typeFLow?: string }) => {
    const numType = Number(typeFLow);
    const cityOrBetweenCities = numType === 1 ? true : false;
    const NoticeDataType = extractSendData(sendData, NoticeType?.id, cityOrBetweenCities);
    dispatch(fillSendDataThunk({
      key: getNoticeType(NoticeType?.id, cityOrBetweenCities),
      value: getNoticeTypeInterface(NoticeType?.id, {
        ...NoticeDataType,
        flgciudadoentreciudades: numType
      }, cityOrBetweenCities)
    })).then(() => {
      dispatch(setVariantMoving(numType));
      dispatch(setResetErrorDuplicated());
      handleNavigate();
    });
  };

  const handleNavigate = () => {
    dispatch(getNextRouteV2(navigate));
  };

  const handleGoBack = () => {

    previusNavigate();

  };

  const previusNavigate = () => {
    dispatch(getPrevRouteV2(navigate));
  };
  return {
    handleClick,
    handleGoBack,
  }
}

export default useNoticeTypeHook;