import { useAppSelector } from "@/hooks/useReducer";
import { CustomerRequest } from "@/models/customerRequest/customerRequestResponse";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import { useEffect, useState } from "react";
import { objectRenderComponent } from "./config";

const FormSuccess = () => {
  const [adData, setAdData] = useState<
    CustomerRequest | SolicitudNegociacion | null
  >(null);

  const {
    customerRequest: { savedData },
    noticeNegotationRequest: { savedDataNogotation },
  } = useAppSelector((state) => state);

  useEffect(() => {
    if (!!savedData) {
      setAdData(savedData);
    } else if (!!savedDataNogotation) {
      setAdData(savedDataNogotation as SolicitudNegociacion);
    }
  }, [savedData]);

  if (!adData) return <></>;

  const Component =
    objectRenderComponent[
      (adData as SolicitudNegociacion)?.solicitud.tipoSolicitud.id
    ];
  if (!Component) return <></>;

  return <Component />;
};

export default FormSuccess;
