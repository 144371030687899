import { Label } from "@/components/form/selectControl/styleSelectControl";
import { PhotosPreLoaded } from "@/store/offertRedux/offert.interface";
import { resizePhotosPreloaded } from "@/utils/helpers";
import { styled } from "@mui/material";
import { FC, useState } from "react";
import StandardAlert from "../alerts/StandardAlert";
import SkeletonEditNegImg from "../skeletons/SkeletonEditNegImg";
import UploadMultiPhotos from "./UploadMultiPhotos";

type Props = {
  onChangeFiles: (images: File[], name?: string, deleted?: boolean) => void;
  onError?: (error: Error) => void;
  initialPhotos: PhotosPreLoaded[];
  title?: string;
  isLoading?: boolean;
};

const ImagesContent = styled("div")(
  () => `
      display: flex;
      flex-direction: row;
      height: 100%;
      background: rgba(0, 0, 0, 0.06);
      height: 76px;
      padding: 8px 12px;
      margin-top: 0px;
      gap: 8px;
    `
);

const LabelImages = styled(Label)(
  () => `
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 8px;
      margin-left: 15px
      `
);

const Container = styled("div")(
  () => `
    margin-top: 4px;
  `
);

const defaultTitle = "Fotos de la carga (máx. 3)";

const LoadMultiPhotos: FC<Props> = ({
  onChangeFiles,
  onError,
  initialPhotos,
  isLoading = false,
  title = defaultTitle
}) => {
  const [messageError, setMessageError] = useState("");
  const initialPhotosResized = !!initialPhotos?.length
    ? resizePhotosPreloaded({ photos: initialPhotos })
    : [];

  const handleOnChangeImages = (
    images: File[],
    name?: string,
    deleted?: boolean
  ) => {
    onChangeFiles(images, name, deleted);
    setMessageError("");
  };

  const handleOnError = (error: Error) => {
    setMessageError(error?.message || "");
    onError && onError(error);
  };

  const handleOnCloseAlert = (open: boolean) => {
    !open && setMessageError("");
  }


  if (isLoading) {
    return (
      <Container>
        <SkeletonEditNegImg />
      </Container>
    );
  }

  return (
    <Container>
      <>
        {!!messageError && (
          <StandardAlert
            sx={{ marginBottom: "16px" }}
            severity="error"
            color="error"
            onCloseAlert={handleOnCloseAlert}
          >
            {messageError}
          </StandardAlert>
        )}
      </>
      <LabelImages htmlFor="images">{title}</LabelImages>
      <ImagesContent>
        <UploadMultiPhotos
          maxFiles={3}
          sx={{
            width: "60px",
            height: "60px",
          }}
          files={initialPhotosResized}
          onChangeFiles={handleOnChangeImages}
          onError={handleOnError}
        />
      </ImagesContent>
    </Container>
  );
};

export default LoadMultiPhotos;
