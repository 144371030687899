import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import LayoutFlow from "@/components/LayoutFlow/LayoutFlow";
import { Stack, styled, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { configWelcome } from "./configDataWelcome";
import { useEffect } from "react";
import { useAppSelector } from "@/hooks/useReducer";
import { UsuarioAPI } from "@/models/userAuth/user";
import { signUpDataLayer } from "@/services/dataLayer/auth";

const centerSx = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  maxWidth: "380px !important",
};

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  marginTop: "16px",
}));

const Description1 = styled(Typography)(() => ({
  marginTop: "16px",
}));

const Welcome = () => {
  const user = useAppSelector(({ user }) => user.user as UsuarioAPI);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    signUpDataLayer(user?.tipousuario, user?.id);
  }, []);

  const handleNavigate = (rute: string) => {
    navigate(rute, { replace: true });
  };

  const {
    primaryButton,
    secondaryButton,
    section: { Icon, subtitle1, subtitle2 },
  } = configWelcome[location.pathname];

  if (!primaryButton || !secondaryButton || !Icon) return null;

  return (
    <LayoutFlow sx={centerSx}>
      <Icon />
      <Title variant="h6">¡Ya estás en PegaCargo.com!</Title>

      <Description1 variant="body1">{subtitle1}</Description1>
      <Typography variant="body1">{subtitle2}</Typography>

      <Stack sx={{ marginTop: "36px", width: "100%" }}>
        <ButtomLoader
          fullWidth
          onClick={() => handleNavigate(primaryButton?.rute)}
        >
          {primaryButton.text}
        </ButtomLoader>

        <ButtomLoader
          fullWidth
          variant="outlined"
          onClick={() => handleNavigate(secondaryButton?.rute)}
        >
          {secondaryButton.text}
        </ButtomLoader>
      </Stack>
    </LayoutFlow>
  );
};

export default Welcome;
