import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { BASE_URL_IMAGE } from "@/config/config";
import { CONTACT_US_LANDING_PATH, HOME_PAHT } from "@/consts/typeServicesPaths";
import { Container, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { configNoAccessByRol } from "./config";

const CustomLink = styled(Link)(
  () => `
    color: #0047BA;
    font-weight: bold;
  `
);

const NoAcessByRol = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const config = configNoAccessByRol[pathname];

  const handleOnNavigate = () => {
    navigate(config?.buttonLink || HOME_PAHT);
  };

  return (
    <Container maxWidth="xs" sx={{ marginTop: "5em" }}>
      <img src={`${BASE_URL_IMAGE}${config?.imageSrc}`} alt="no access"></img>
      <Typography variant="h6" sx={{ mt: "41px" }} align="center">
        {config?.title}
      </Typography>
      <Typography variant="body1" marginTop="8px">
        {config?.description}
      </Typography>
      <Typography variant="body1" marginTop="8px">
        {config?.description2}{" "}
        <CustomLink to={CONTACT_US_LANDING_PATH} replace>
          aquí.
        </CustomLink>
      </Typography>
      <ButtomLoader sx={{ marginY: "40px" }} onClick={handleOnNavigate}>
        {config?.buttonText}
      </ButtomLoader>
    </Container>
  );
};

export default NoAcessByRol;
