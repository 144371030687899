import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IDistrict {
  id: number;
  nombre: string;
  flgzona: number;
  idciudad: number;
  detalle?: string;
  distritodet?: any[];
}
export interface DistrictsState {
  districts: Array<any>;
  districts2: Array<any>;
  isLoading: boolean;
  error: any;
}

const initialState: DistrictsState = {
  districts: [],
  districts2: [],
  isLoading: false,
  error: null,
};

export const districtsSlice = createSlice({
  name: "districts",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setDistricts: (state, { payload }: PayloadAction<Array<any>>) => {
      state.districts = payload;
      state.isLoading = false;
      state.error = null;
    },
    setEmptyDistrictsByTerm: (state) => {
      state.districts = [];
    },
    setDistricts2: (state, { payload }: PayloadAction<Array<any>>) => {
      state.districts2 = payload;
      state.isLoading = false;
      state.error = null;
    },
    setError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setDistricts, setDistricts2, setError, setEmptyDistrictsByTerm } = districtsSlice.actions;
