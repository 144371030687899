import useCountdown from "@/hooks/useCountdown";
import { Link, styled, Typography } from "@mui/material";
import { MouseEvent, useEffect, FC } from "react";

const CustomLink = styled(Link)(
  ({ theme }) => `
    text-decoration: none;
    font-family: ${theme.typography.label2.fontFamily};
    font-size: ${theme.typography.label2.fontSize};
    font-weight: ${theme.typography.label2.fontWeight};
    line-height: ${theme.typography.label2.lineHeight};
  `
);

type Props = {
  activeCountdown?: () => void;
  initActiveCountdown?: boolean;
};

const CountDownLinkAction: FC<Props> = ({
  initActiveCountdown = false,
  activeCountdown,
}) => {
  const { countdown, handleIsEnabled, handleActiveCountdown, isActive } =
    useCountdown();

  useEffect(() => {
    if (initActiveCountdown && !isActive) {
      handleActiveCountdown();
    }
  }, [handleActiveCountdown, initActiveCountdown, isActive]);

  const handleClick = (
    event:
      | MouseEvent<HTMLSpanElement, globalThis.MouseEvent>
      | MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault();

    handleActiveCountdown();

    activeCountdown && activeCountdown();
  };

  return (
    <>
      {isActive && handleIsEnabled ? (
        <Typography variant="body2">{countdown} segundos</Typography>
      ) : (
        <CustomLink href="#" onClick={(e) => handleClick(e)}>
          Reenviar código
        </CustomLink>
      )}
    </>
  );
};

export default CountDownLinkAction;
