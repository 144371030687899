interface Props<T> {
  store: T;
  whiteList: Array<keyof T>;
  isLoading?: boolean;
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

const useGetDataInSlice = <T extends object>({
  store,
  whiteList,
  isLoading,
}: Props<T>) => {
  // Valida la lista blanca con el objecto(store), que se le envia y cuando uno de ellos tenga valor, lor regresa
  const handleGetCurrentDetailNotice = () => {
    if (isLoading) return null;
    const entries = handleGetEntries<T>(store);
    if (!entries) return null;

    const [keyNotice] =
      entries.find(([key, value]) => whiteList.includes(key) && !!value) || [];

    if (!keyNotice || !store[keyNotice]) return null;

    return store[keyNotice];
  };

  // UTIL, Tipado para el {Object.entries}
  const handleGetEntries = <W extends object>(obj: W) =>
    Object.entries(obj) as Entries<W>;

  return { handleGetCurrentDetailNotice };
};

export default useGetDataInSlice;
