import { theme } from '@/styles/theme';
import { Grid, experimental_sx as sx, Typography, styled } from '@mui/material';

export const ContentSection = styled(Grid)(
  sx({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    gap: { xs: '16px', sm: '32px' },
  })
);

export const SectionTitle = styled(Typography)(
  sx({
    fontFamily: theme.typography.h5.fontFamily,
    fontSize: {
      xs: theme.typography.h5.fontSize,
      sm: '30px',
      md: theme.typography.h3.fontSize,
    },
    lineHeight: {
      xs: theme.typography.h5.lineHeight,
      sm: theme.typography.h3.lineHeight,
    },
    textTransform: 'uppercase',
    color: theme.palette.org.contrastText,
  })
);

export const SectionSubTitle = styled(Typography)(
  sx({
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: {
      xs: theme.typography.body1.fontSize,
      md: theme.typography.h6.fontSize,
    },
    lineHeight: {
      xs: theme.typography.body1.lineHeight,
      md: theme.typography.h6.lineHeight,
    },
    color: theme.palette.org.contrastText,
  })
);
