import { prepareSelectedValueModal } from '@/components/Filter/adapter/filterAdapter';
import LabelRequired from '@/components/utils/LabelRequired';
import { REGISTER_PATH } from '@/consts/noticeV2Paths';
import { REGISTER_DRIVER_STEPS } from '@/consts/typeServicesPaths';
import { useAppDispatch, useAppSelector } from '@/hooks/useReducer';
import { UsuarioAPI } from '@/models/userAuth/user';
import { INoticeCreateListItem } from '@/pages/noticeV2/NoticeList/types';
import {
  extractSendData,
  getNoticeType,
  getNoticeTypeInterface,
} from '@/pages/noticeV2/adapters/noticeUseTypes';
import { CiudadSugeridoData } from '@/services/noticeServices/noticeListSuggested/noticeListSuggestedTypes';
import { IAvisodestinoBetween } from '@/services/noticeServices/noticeSendData/noticeSendDataTypes';
import { resetListLocation } from '@/store/filterNoticeRedux/filterNoticeSlice';
import { getCitiesByPaisFilterThunk, getCitiesInternationalizationFilterThunk } from '@/store/filterNoticeRedux/filterNoticeThunk';
import {
  setCitiesSuggested,
  setInputsPlace,
} from '@/store/noticeV2Redux/noticeV2Slice';
import {
  fillSendDataThunk,
  noticeGetCitiesSuggestedThunk,
  onSaveFlowNoticeV2Thunk,
} from '@/store/noticeV2Redux/noticeV2Thunk';
import {
  IInputsPlace,
  IReturnDataValues,
} from '@/store/noticeV2Redux/noticeV2types';
import { getNextRouteV2 } from '@/store/routeActionsFlowV2/routeActionsFlowNoticeV2Thunk';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IShowInputsProps } from '../../components/inputsConfig/types';
import { MODAL_ACTIVE, remanePlaceholders } from '../types';
import { ListProps } from '@/components/Filter/components/AutoCompleteFilterModal';
import { ERROR_MESSAGES_DUPLICATED, ERROR_MESSAGES_LOW_PRICE, MAX_PRICE_BW, MIN_PRICE_BW, placeholdersPlace } from '../hook';

export const usePlaceBetweenCitiesHook = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const { pathname } = location;

  const {
    filterNotice,
    user: { user },
    noticeV2: {
      selectNotice,
      inputsPlace,
      sendData,
      citiesSuggested,
      isLoading,
      noticesDuplicate,
    },
  } = useAppSelector((state) => state);
  const { listLocation } = filterNotice;
  const [showModal, setShowModal] = useState(false);
  const [modalActiveWith, setModalActiveWith] = useState(MODAL_ACTIVE);

  const idNoticeType = (selectNotice as INoticeCreateListItem)?.id || 0;

  const noticeTypeData = extractSendData(sendData, idNoticeType);

  const { ciudad, flgRegistroAviso } = user as UsuarioAPI;

  const isRegister = flgRegistroAviso === null;
  useEffect(() => {
    loadFormData();
  }, []);

  const loadFormData = () => {
    if (inputsPlace && !inputsPlace.origin) {
      // cargar datos por defecto
      const body: IReturnDataValues = {
        ...noticeTypeData,
        idciudad: isRegister ? ciudad?.id || 0 : 0,
      };
      if (isRegister) {
        dispatch(noticeGetCitiesSuggestedThunk(body.idciudad));
      }
      dispatch(
        fillSendDataThunk({
          key: getNoticeType(idNoticeType),
          value: getNoticeTypeInterface(idNoticeType, body),
        })
      ).then(() => {
        dispatch(
          setInputsPlace({
            ...inputsPlace,
            origin: isRegister ? ciudad?.nombre2 || '' : '',
          })
        );
      });
    }
  };

  const handleOnClick = (props: IShowInputsProps) => () => {
    setModalActiveWith({ name: props.name });
    setShowModal(true);
    dispatch(resetListLocation());
  };

  const onChange = (props: IShowInputsProps) => () => {
    handleOnClick(props)();
  };

  const handleGetValue = ({ name }: { name: keyof IInputsPlace }) => {
    return inputsPlace[name] || '';
  };

  const handleGetPlaceholder = (props: IShowInputsProps) => {
    const typeNoticePlaceholder = placeholdersPlace[idNoticeType];

    if (
      remanePlaceholders.some((name) => name === props.name) &&
      typeNoticePlaceholder
    ) {
      return typeNoticePlaceholder[props.name] || {};
    }

    return {};
  };

  const handleValidShow = (columnIShowInputsProps: any) => {
    const moreIShowInputsProps = columnIShowInputsProps?.callback
      ? columnIShowInputsProps?.callback(columnIShowInputsProps, filterNotice)
      : {};
    return { ...columnIShowInputsProps, ...moreIShowInputsProps };
  };

  const handleInitConfig = (column: any) => {
    const moreIShowInputsProps = column?.props?.callback
      ? column?.props?.callback(column?.props, {})
      : {};

    const props = {
      ...column.props,
      onClick: handleOnClick(column.props),
      onChange: onChange(column.props),
      value: handleGetValue(column.props),
      ...handleValidShow(column.props),
      ...handleGetPlaceholder(column.props),
      ...moreIShowInputsProps,
    };

    return {
      ...column,
      props,
    };
  };

  const handleOnCloseModal = () => {
    setShowModal(false);
    setModalActiveWith(MODAL_ACTIVE);
  };

  const handleSubmitModal = (seleted: ListProps) => {
    const name = prepareSelectedValueModal(seleted);

    const body: IReturnDataValues = {
      ...noticeTypeData,
    };

    if (modalActiveWith.name === 'origin') {
      body.idciudad = seleted.id;
      dispatch(noticeGetCitiesSuggestedThunk(seleted.id));
      body.avisodestinos = [];
    }

    dispatch(
      fillSendDataThunk({
        key: getNoticeType(idNoticeType),
        value: getNoticeTypeInterface(idNoticeType, body),
      })
    ).then(() => {
      dispatch(
        setInputsPlace({
          ...inputsPlace,
          [modalActiveWith.name]: name,
        })
      );
    });

    handleOnCloseModal();
  };
  const handleOnSeletedModal = (seleted: any) => {
    const exemption = ['origin'];
    if (exemption.some((exemp) => exemp === modalActiveWith.name)) {
      handleSubmitModal(seleted);
    }
  };

  const handleFilterByTerm = (value: string) => {
    if (value?.length < 3) return;

    const { name } = modalActiveWith;
    if (name === 'origin') {
      dispatch(getCitiesInternationalizationFilterThunk(value));
    }
  };

  const handleInputsSuggested = (id: number, precio: string) => {
    // si llega el precio validar de que sea puros numeros
    if (precio && !/^\d+$/.test(precio)) return;

    // Clonar el array avisodestinos para evitar mutación directa
    const avisodestinos: any[] = [...(noticeTypeData?.avisodestinos || [])];

    // Buscar el índice del destino en avisodestinos
    const index = avisodestinos.findIndex((item: any) => item.idciudad === id);

    if (index !== -1) {
      if (Number(precio) === 0) {
        avisodestinos.splice(index, 1); // Eliminar el destino si el precio es 0
      } else {
        // Clonar el objeto del destino para evitar mutación directa
        avisodestinos[index] = {
          ...avisodestinos[index],
          precio: Number(precio), // Actualizar el precio
        };
      }
    } else {
      avisodestinos.push({
        idciudad: id,
        precio: Number(precio),
      });
    }

    const body: IReturnDataValues = {
      ...noticeTypeData,
      avisodestinos,
    };

    dispatch(
      fillSendDataThunk({
        key: getNoticeType(idNoticeType),
        value: getNoticeTypeInterface(idNoticeType, {
          ...body,
        }),
      })
    );
  };

  const DeleteSuggestedListItem = (id: number) => {
    if (noticeTypeData?.avisodestinos?.length) {
      const updatedAvisodestinos = noticeTypeData.avisodestinos?.filter(
        (item: IAvisodestinoBetween) => item.idciudad !== id
      );

      dispatch(
        fillSendDataThunk({
          key: getNoticeType(idNoticeType),
          value: getNoticeTypeInterface(idNoticeType, {
            ...noticeTypeData,
            avisodestinos: updatedAvisodestinos,
          }),
        })
      );
    }

    dispatch(
      setCitiesSuggested({
        ...citiesSuggested,
        sugeridos: (citiesSuggested as CiudadSugeridoData).sugeridos.filter(
          (item) => item.id !== id
        ),
      })
    );
  };

  const validateForm = () => {
    const { idciudad, avisodestinos } = noticeTypeData;

    let validdestinos: boolean[] = [];

    // buscar si hay un precio menor a 5 o mayor a 1000 y retornar false
    if (avisodestinos?.length) {
      avisodestinos?.forEach((item: any) => {
        const { valid } = validatePrecioOfDestinos(item.idciudad);
        if (valid) {
          validdestinos.push(valid);
        }
      });
    }

    return !!(idciudad && avisodestinos?.length && !validdestinos.length);
  };

  const handleNavigate = () => {
    if (pathname.includes(REGISTER_PATH)) {
      navigate(REGISTER_DRIVER_STEPS);
      return;
    } else {
      dispatch(getNextRouteV2(navigate));
    }
  };
  const onSubmit = () => {
    if (!validateForm()) {
      return;
    }

    dispatch(
      onSaveFlowNoticeV2Thunk({
        ...noticeTypeData,
      })
    ).then(() => {
      handleNavigate();
    });
  };

  const validatePrecioOfDestinos = (id: number) => {
    const avisodestinos = noticeTypeData.avisodestinos || [];
    const index = avisodestinos.findIndex((item: any) => item.idciudad === id);
    if (index !== -1) {
      const { precio } = avisodestinos[index];
      const duplicate = noticesDuplicate && noticesDuplicate.find((item) => item === id);
      if (duplicate) {
        return {
          valid: true,
          message: ERROR_MESSAGES_DUPLICATED,
        };
      }
      return {
        valid: !(precio >= MIN_PRICE_BW && precio <= MAX_PRICE_BW),
        message: ERROR_MESSAGES_LOW_PRICE,
      };
    }
    return {
      valid: false,
      message: '',
    };
  };

  return {
    handleInitConfig,
    // demas cosas
    showModal,
    handleOnCloseModal,
    handleSubmitModal,
    handleOnSeletedModal,
    handleFilterByTerm,
    modalActiveWith,
    listLocation,
    citiesSuggested,
    handleInputsSuggested,
    data: noticeTypeData,
    DeleteSuggestedListItem,
    validateForm,
    onSubmit,
    isLoading,
    validatePrecioOfDestinos,
  };
};

export default usePlaceBetweenCitiesHook;

