import { ERROR_EQUAL_DESTINY } from '@/consts/textErrors';
import { useAppDispatch, useAppSelector } from '@/hooks/useReducer';
import {
  CiudadSugeridoData,
  DistritoSugeridoData,
} from '@/services/noticeServices/noticeListSuggested/noticeListSuggestedTypes';
import { resetListLocation } from '@/store/filterNoticeRedux/filterNoticeSlice';
import {
  getCitiesByPaisFilterThunk,
  getCitiesInternationalizationFilterThunk,
  getDistrictsFilterThunk,
} from '@/store/filterNoticeRedux/filterNoticeThunk';
import {
  setCitiesSuggested,
  setDistrictsSuggested,
} from '@/store/noticeV2Redux/noticeV2Slice';
import { useState } from 'react';
import { INoticeCreateListItem } from '../../NoticeList/types';
import { extractSendData } from '../../adapters/noticeUseTypes';

const useAddNewDestinyOrCityHook = () => {
  const dispatch = useAppDispatch();

  const {
    filterNotice,
    noticeV2: { districtsSuggested, citiesSuggested, sendData, selectNotice },
  } = useAppSelector((state) => state);
  const idNoticeType = (selectNotice as INoticeCreateListItem)?.id || 0;
  const noticeTypeData = extractSendData(sendData, idNoticeType);
  const { listLocation } = filterNotice;
  const [showModal, setShowModal] = useState(false);
  const [cityTerm, setCityTerm] = useState<string>('');

  const [modalActiveWith, setModalActiveWith] = useState(MODAL_ACTIVE);
  const [error, setError] = useState<string>('');

  const handleOnCloseModal = () => {
    setShowModal(false);
    setModalActiveWith(MODAL_ACTIVE);
  };

  const handleSubmitModal = (seleted: any) => {
    const { name } = modalActiveWith;
    let arraySuggester = [];

    if (name === 'city') {
      arraySuggester = (citiesSuggested as CiudadSugeridoData).sugeridos || [];
    } else {
      arraySuggester =
        (districtsSuggested as DistritoSugeridoData).sugeridos || [];
    }

    const isExist = arraySuggester?.some((item) => item.id === seleted.id);
    if (isExist) {
      setError(`${name === 'city' ? 'La Ciudad' : 'El destino'} ya existe.`);
      handleOnCloseModal();
      return;
    }

    if (name === 'city' && noticeTypeData.idciudad === seleted.id) {
      setError(ERROR_EQUAL_DESTINY);
      handleOnCloseModal();
      return;
    }

    setError('');

    let newSuggested = [
      ...arraySuggester,
      {
        id: seleted.id,
        nombre: name === 'city' ? seleted.nombre : seleted.nombre2,
      },
    ];

    if (name === 'city') {
      dispatch(
        setCitiesSuggested({
          ...citiesSuggested,
          sugeridos: newSuggested,
        })
      );
    } else {
      dispatch(
        setDistrictsSuggested({
          ...districtsSuggested,
          sugeridos: newSuggested,
        })
      );
    }

    handleOnCloseModal();
  };

  const handleOnSeletedModal = (seleted: any) => {
    const exemption = ['destiny', 'city'];
    if (exemption.some((exemp) => exemp === modalActiveWith.name)) {
      handleSubmitModal(seleted);
    }
  };

  const handleFilterByTerm = (value: string) => {
    if (value?.length < 3) return;

    const { name } = modalActiveWith;
    if (name === 'city') {
      dispatch(getCitiesInternationalizationFilterThunk(value));
    } else if (name === 'destiny') {
      dispatch(getDistrictsFilterThunk(value, cityTerm));
    }
  };

  const handleOnClick = (cityTerrm?: string) => () => {
    setModalActiveWith({ name: cityTerrm ? 'destiny' : 'city' });
    setCityTerm(cityTerrm || '');
    setShowModal(true);
    dispatch(resetListLocation());
    setError('');
  };

  return {
    showModal,
    handleOnCloseModal,
    handleSubmitModal,
    handleOnSeletedModal,
    handleFilterByTerm,
    modalActiveWith,
    listLocation,
    handleOnClick,
    error,
    setCityTerm,
  };
};
export type ModalAtive = {
  modalActiveWith: 'city' | 'origin' | 'destiny';
};
export const MODAL_ACTIVE: Record<string, ModalAtive['modalActiveWith']> = {
  name: 'city',
};

export default useAddNewDestinyOrCityHook;
