import {
  NOTICE_AUCTION_PRIVATE_PATH,
  NOTICE_BETWEEN_CITIES_PRIVATE_PATH,
  NOTICE_IN_CITY_PRIVATE_PATH,
  NOTICE_MOVING_PRIVATE_PATH,
  NOTICE_MULTI_DESTINY_PRIVATE_PATH,
} from '@/consts/typeServicesPaths';

export const typeNoticeByIdObject: Record<
  number,
  { title: string; url: string }
> = {
  1: {
    title: 'En la ciudad',
    url: NOTICE_IN_CITY_PRIVATE_PATH,
  },
  2: {
    title: 'Multidestino',
    url: NOTICE_MULTI_DESTINY_PRIVATE_PATH,
  },
  3: {
    title: 'Entre ciudades',
    url: NOTICE_BETWEEN_CITIES_PRIVATE_PATH,
  },
  4: {
    title: 'Remate entre ciudades',
    url: NOTICE_AUCTION_PRIVATE_PATH,
  },
  5: {
    title: 'Mudanza',
    url: NOTICE_MOVING_PRIVATE_PATH,
  },
};
