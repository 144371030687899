import { ID_EXPRESS_FLOW, ID_MOVING_FLOW } from "@/consts/defaultConsts";
import { useAppSelector } from "@/hooks/useReducer";
import DynamicComponent from "@/pages/offerts/offertConfirmation/components/DynamicComponent";
import { useMemo } from "react";
import { configData, ConfigDataBody, ValidFormat } from "../config";
import { configDataCustomRequestExpress } from "./configExpress";
import { configDataCustomRequestMoving } from "./configMoving";

const CustomAplication = () => {
  const { 
    customerRequest: {
      requestDetailsView, 
   },
   user: { user: usuario },
  } = useAppSelector(
    (state) => state
  );


  const handleGetConfig = () => {
  

    if (!requestDetailsView) return [];
  
    if(requestDetailsView?.tipoSolicitud.id === ID_MOVING_FLOW){
        return configDataCustomRequestMoving;
    }
    if(requestDetailsView?.tipoSolicitud.id === ID_EXPRESS_FLOW){
        return configDataCustomRequestExpress;
    }

    return configData;
  };

  const selectdConfigData = useMemo(() => handleGetConfig(), [requestDetailsView]);
  const handleFormat = (currentValue: any, format?: ValidFormat) => {
    if (!format || !requestDetailsView) return currentValue;

    let { template, valuesToReplace } = format;

    const newValue = valuesToReplace.reduce((acc, el) => {
      const value = requestDetailsView[el.value]?.toString() || el.defaultResult;

      return acc.replace(el.search, value);
    }, template);

    return newValue;
  };

  const handleValid = ({ valid, props }: ConfigDataBody) => {
    if (!valid || !requestDetailsView) return { props };

    const moreProps = valid.reduce((acc, el) => {
      let value = handleFormat(requestDetailsView[el.property], el?.format);

      if (el?.callback) {
        value = el.callback(value, requestDetailsView, usuario );
      }

      if (typeof el.defaultResult === "boolean") {
        acc = {
          ...acc,
          [el.componentProp]: !!value,
        };
      } else {
        acc = {
          ...acc,
          [el.componentProp]: value || el.defaultResult,
        };
      }
      return acc;
    }, {});

    return { props: { ...props, ...moreProps } };
  };
  return (
    <>
      {selectdConfigData.map((item, i) => (
        <DynamicComponent key={i} {...item} {...handleValid(item)} />
      ))}
    </>
  );
};

export default CustomAplication;
