import { actionsToNegotation } from "@/consts/actions";
import { ID_EXPRESS_FLOW } from "@/consts/defaultConsts";
import {
  optionFour,
  optionOne,
  optionSeven,
  optionThree,
} from "@/consts/noticeConsts";
import {
  declineCounterOfferMsg,
  nottifficationDeclineAgreementExpressMsg,
  nottifficationDeclineMsg,
  nottifficationPriceChanged,
  snackbarAlreadyAnswered,
  snackbarErrorMesasage,
} from "@/consts/notifficationsMessage";
import { REQUESTS_LANDING_PATH } from "@/consts/typeServicesPaths";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import {
  counterOfferClientAdapter,
  urlFormat,
} from "@/pages/noticeRequestFlow/adapters/noticeRequestFlowAdapters";
import { manageActionButton } from "@/pages/requestNegotiation/components/globalButtons/config";
import {
  OfferRequestDriver,
  acceptRequestDriver,
} from "@/services/dataLayer/createRequestNeg";
import { initFlowCustomerRequestThunk } from "@/store/customerRequestRedux/customerRequestThunk";
import { saveRequestNogotationByIdThunk } from "@/store/noticeNegotationRequestRedux/noticeNegRequesThunk";
import { setSelectNegotationTypeRequest } from "@/store/noticeNegotationRequestRedux/noticeNegRequestSlice";
import { initRouteFlowByIdNoticeThunk } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { replaceValue } from "@/utils/helpersActions";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { SolicitudNegociacion } from "../../../models/noticeNegotation/noticeNegotationModel";

interface HandleDisabledButtons {
  disableAcceptButton: boolean;
  disableDeclineButton: boolean;
  disableCounterOfferButton: boolean;
}

interface UseButtonsFunctions {
  handleManageSnackbar: () => void;
  handleManageModalOffer: () => void;
  handleManageModalCounterOffer: () => void;
  handleAcceptButton: () => void;
  handleClientCounterOfferButton: () => void;
  handleDeclineButton: () => void;
  handleDisabledButtons: () => HandleDisabledButtons;
  handleManageSnackbarAlreadyAnswered: () => void;
  handleModalDeclineAgreement: () => void;
  handleModalPriceChanged: (functionOptional?: () => void) => void;
}

export const useButtonsFunctions = (): UseButtonsFunctions => {
  const {
    user: { user },
    noticeNegotationRequest: { savedDataNogotation },
  } = useAppSelector((state) => state);

  const { pathname } = useLocation();
  const validPathName = pathname?.startsWith(REQUESTS_LANDING_PATH) as boolean;
  const navigate: NavigateFunction = useNavigate();
  const dispatch = useAppDispatch();
  const { addNotiffication } = useNotiffication();

  const savedDataNegotationRequest =
    savedDataNogotation as SolicitudNegociacion;
  const actionId: number = savedDataNegotationRequest?.tipoSolicitudNegociacion
    ?.id as number;
  const idRequestType: string = `${savedDataNegotationRequest?.id}`;
  const TypeNegotation: number = savedDataNegotationRequest
    ?.tipoSolicitudNegociacion.id as number;
  const actionsNegotation =
    savedDataNegotationRequest?.ultimaSolicitudNegociacionAcciones
      ?.tipoSolicitudNegociacionAccion;
  const typeNegotationRequest =
    savedDataNegotationRequest?.solicitud.tipoSolicitud.id;

  const userId = (user as IUser).id;
  const typeNegotation = savedDataNogotation?.solicitud.tipoSolicitud
    ?.nombre as string;
  const precioactual = !!savedDataNogotation?.precioContraoferta
    ? savedDataNogotation?.precioContraoferta
    : savedDataNogotation?.precio;

  const handleManageSnackbar = (): void => {
    addNotiffication({
      title: snackbarErrorMesasage.TITLE,
      message: snackbarErrorMesasage.MESSAGE,
      hiddenModal: true,
      hiddenSnackbar: false,
      onSubmit: async () => {},
    });
  };

  const handleManageSnackbarAlreadyAnswered = (): void => {
    addNotiffication({
      title: snackbarAlreadyAnswered.TITLE,
      message: snackbarAlreadyAnswered.MESSAGE,
      hiddenModal: true,
      hiddenSnackbar: false,
      onSubmit: async () => {},
    });
  };

  const handleManageModalOffer = (): void => {
    addNotiffication({
      title: nottifficationDeclineMsg.TITLE,
      message: nottifficationDeclineMsg.MESSAGE,
      leftBtnText: nottifficationDeclineMsg.LEFT_BTN_TEXT,
      RightBtnText: nottifficationDeclineMsg.RIGHT_BTN_TEXT,
      hiddenSnackbar: true,
      hiddenModal: false,
      onSubmit: async () => {
        handleDeclineButton();
      },
    });
  };

  const handleManageModalCounterOffer = (): void => {
    addNotiffication({
      title: declineCounterOfferMsg.TITLE,
      message: declineCounterOfferMsg.MESSAGE,
      leftBtnText: declineCounterOfferMsg.LEFT_BTN_TEXT,
      RightBtnText: declineCounterOfferMsg.RIGHT_BTN_TEXT,
      hiddenSnackbar: true,
      hiddenModal: false,
      onSubmit: async () => {
        handleDeclineButton();
      },
    });
  };

  const handleModalDeclineAgreement = (): void => {
    const user = validPathName ? "cliente" : "transportista";
    const nottificationText = nottifficationDeclineAgreementExpressMsg(user);
    addNotiffication({
      title: nottificationText.TITLE,
      message: nottificationText.MESSAGE,
      leftBtnText: nottificationText.LEFT_BTN_TEXT,
      RightBtnText: nottificationText.RIGHT_BTN_TEXT,
      hiddenSnackbar: true,
      hiddenModal: false,
      onSubmit: async () => {
        handleDeclineAgreementButton();
      },
    });
  };

  const handleModalPriceChanged = (functionOptional?: () => void) => {
    addNotiffication({
      title: nottifficationPriceChanged.TITLE,
      message: nottifficationPriceChanged.MESSAGE,
      leftBtnText: nottifficationPriceChanged.LEFT_BTN_TEXT,
      RightBtnText: nottifficationPriceChanged.RIGHT_BTN_TEXT,
      hiddenSnackbar: true,
      hiddenModal: false,
      onSubmit: async () => {
        functionOptional && functionOptional();
      },
    });
  };

  const handleAcceptButton = (): void => {
    let action = actionId;
    let typeNeg = TypeNegotation;

    if (actionId === optionFour) {
      typeNeg = optionThree;
    }
    if (typeNegotationRequest === ID_EXPRESS_FLOW) {
      typeNeg = optionOne;
      action = optionOne;
    }

    const dataAdapter = counterOfferClientAdapter(
      manageActionButton[action][optionOne]
    );

    const body = {
      ...dataAdapter,
      precioactual,
    };

    dispatch(saveRequestNogotationByIdThunk(idRequestType, body)).then(
      (value) => {
        if (!!value.flgpreciocambiado) {
          handleModalPriceChanged();
        } else {
          const dataLayerFunction =
            ActionDataLayer[
              savedDataNogotation?.ultimaSolicitudNegociacionAcciones
                ?.tipoSolicitudNegociacionAccion?.id as number
            ];
          dataLayerFunction(userId, typeNegotation as string);

          navigate(
            urlFormat(
              savedDataNegotationRequest?.id.toString(),
              typeNeg,
              action,
              optionOne
            ),
            { replace: true }
          );
        }
      }
    );
  };

  const handleClientCounterOfferButton = (): void => {
    const typeFlow = actionsToNegotation[optionSeven];
    const idTypeFlow = savedDataNegotationRequest?.solicitud.tipoSolicitud
      .id as number;

    dispatch(setSelectNegotationTypeRequest(typeFlow));
    let route = dispatch(
      initRouteFlowByIdNoticeThunk(idTypeFlow, "cocounterOfferForClient")
    );
    dispatch(initFlowCustomerRequestThunk());

    const nextRoute = replaceValue(
      route,
      ":typeRequest",
      typeNegotation[2],
      ":idRequest",
      savedDataNegotationRequest?.id.toString()
    );
    
    navigate(nextRoute);
  };

  const handleDeclineButton = (): void => {
    let action = actionId;
    let typeNeg = TypeNegotation;

    if (actionId === optionFour) {
      typeNeg = optionThree;
    }

    if (typeNegotationRequest === ID_EXPRESS_FLOW) {
      typeNeg = optionOne;
      action = optionOne;
    }

    if (actionId === optionFour) action = optionOne;
    const dataAdapter = counterOfferClientAdapter(
      manageActionButton[action][optionThree]
    );

    const body = {
      ...dataAdapter,
      precioactual,
    };

    dispatch(saveRequestNogotationByIdThunk(idRequestType, body)).then(
      (value) => {
        if (!!value.flgpreciocambiado) {
          handleModalPriceChanged();
        } else {
          navigate(
            urlFormat(
              savedDataNegotationRequest?.id.toString(),
              typeNeg,
              action,
              optionThree
            ),
            { replace: true }
          );
        }
      }
    );
  };

  const handleDeclineAgreementButton = () => {
    let action = validPathName ? optionThree : optionOne;
    let typeNeg = TypeNegotation;

    if (typeNegotationRequest === ID_EXPRESS_FLOW) {
      typeNeg = optionOne;
    }

    const dataAdapter = counterOfferClientAdapter(
      manageActionButton[action][validPathName ? optionThree : optionOne]
    );

    const body = {
      ...dataAdapter,
      precioactual,
    };

    dispatch(saveRequestNogotationByIdThunk(idRequestType, body)).then(
      (value) => {
        if (!!value.flgpreciocambiado) {
          handleModalPriceChanged();
        } else {
          navigate(
            urlFormat(
              savedDataNegotationRequest?.id.toString(),
              typeNeg,
              action,
              optionThree
            ),
            { replace: true }
          );
        }
      }
    );
  };

  const handleDisabledButtons = (): HandleDisabledButtons => {
    let disableAcceptButton = !!actionsNegotation?.flgVerAceptarCliente;
    let disableDeclineButton = !!actionsNegotation?.flgVerRechazarCliente;
    let disableCounterOfferButton =
      !!actionsNegotation?.flgVerContraofertarCliente;

    if (validPathName) {
      disableAcceptButton = !!actionsNegotation?.flgVerAceptarTransportista;
      disableDeclineButton = !!actionsNegotation?.flgVerRechazarTransportista;
      disableCounterOfferButton =
        !!actionsNegotation?.flgVerContraofertarTransportista;
    }

    return {
      disableAcceptButton,
      disableDeclineButton,
      disableCounterOfferButton,
    };
  };

  return {
    handleManageSnackbar,
    handleManageModalOffer,
    handleManageModalCounterOffer,
    handleAcceptButton,
    handleClientCounterOfferButton,
    handleDeclineButton,
    handleDisabledButtons,
    handleManageSnackbarAlreadyAnswered,
    handleModalDeclineAgreement,
    handleModalPriceChanged,
  };
};

export const useAgreementDeclineButtonFunctions = () => {
  const { savedDataNogotation } = useAppSelector(
    (state) => state.noticeNegotationRequest
  );

  const { pathname } = useLocation();
  const validPathName = pathname?.startsWith(REQUESTS_LANDING_PATH) as boolean;
  const navigate: NavigateFunction = useNavigate();
  const dispatch = useAppDispatch();
  const { addNotiffication } = useNotiffication();

  const savedDataNegotationRequest =
    savedDataNogotation as SolicitudNegociacion;
  const idRequestType: string = `${savedDataNegotationRequest?.id}`;
  const TypeNegotation: number = savedDataNegotationRequest
    ?.tipoSolicitudNegociacion.id as number;
  const typeNegotationRequest =
    savedDataNegotationRequest?.solicitud.tipoSolicitud.id;

  const handleModalDeclineAgreement = (): void => {
    const user = validPathName ? "cliente" : "transportista";
    const nottificationText = nottifficationDeclineAgreementExpressMsg(user);
    addNotiffication({
      title: nottificationText.TITLE,
      message: nottificationText.MESSAGE,
      leftBtnText: nottificationText.LEFT_BTN_TEXT,
      RightBtnText: nottificationText.RIGHT_BTN_TEXT,
      hiddenSnackbar: true,
      hiddenModal: false,
      onSubmit: async () => {
        handleDeclineAgreementButton();
      },
    });
  };

  const handleDeclineAgreementButton = () => {
    //handleDeclineAgreementButton: esta funcion es para express
    let action = 1;
    let typeNeg = TypeNegotation;

    if (typeNegotationRequest === ID_EXPRESS_FLOW) {
      typeNeg = optionOne;
    }

    const body = counterOfferClientAdapter(
      manageActionButton[3][validPathName ? 3 : 1]
    );

    dispatch(saveRequestNogotationByIdThunk(idRequestType, body)).then(() =>
      navigate(
        urlFormat(
          savedDataNegotationRequest?.id.toString(),
          typeNeg,
          action,
          validPathName ? 4 : 3
        ),
        { replace: true }
      )
    );
  };

  return {
    handleModalDeclineAgreement,
  };
};

export const ActionDataLayer: Record<
  number,
  (idUsuario: number, noticeType: string) => void
> = {
  1: OfferRequestDriver,
  2: acceptRequestDriver,
  3: acceptRequestDriver,
  4: acceptRequestDriver,
};
