import { ID_OFFER } from '@/consts/defaultConsts';
import { optionOne, optionTwo } from '@/consts/noticeConsts';
import { SolicitudNegociacion } from '@/models/noticeNegotation/noticeNegotationModel';
import { currencyFormat } from '@/utils/helpers';

export const handleTypeMultidestinyOfferRequest = (
  data: SolicitudNegociacion
) => {
  const newData = data.solicitud;
  const total =
    data.tipoSolicitudNegociacion.id === ID_OFFER
      ? data?.precioCalculado
      : data?.precioCalculadoContraOferta;

  let result = {
    type: 'punto',
    amount: newData?.puntosRequeridos || 0,
    total: total,
  };

  if (newData?.flgHoraoPunto === optionOne) {
    result = {
      ...result,
      type: 'hora',
      amount: newData?.horasRequeridas || 0,
    };
  }

  return result;
};

export const handleTypeMultidestinyRequestNeg = (
  data: SolicitudNegociacion
) => {
  const newData = data.solicitud;
  let result = {
    type: '',
    amount: '',
    priceType: 0,
    total: 0,
    flgIsOwner: data.flgIsOwner,
  };

  if (newData?.flgHoraoPunto === optionOne) {
    result = {
      type: 'hora',
      amount: (newData.horasRequeridas as number).toString(),
      priceType: data.precio,
      total: data?.precioCalculado as number,
      flgIsOwner: data.flgIsOwner,
    };
  } else if (newData?.flgHoraoPunto === optionTwo) {
    result = {
      type: 'punto',
      amount: (newData.puntosRequeridos as number).toString(),
      priceType: data.precio,
      total: data?.precioCalculado as number,
      flgIsOwner: data.flgIsOwner,
    };
  }

  return result;
};

type NoticeDataType = {
  type: string;
  amount: string;
  priceType: number;
  total: number;
};

export const handleDataToRenderRequestNeg = ({
  noticeData,
  data,
}: {
  noticeData: NoticeDataType;
  data: SolicitudNegociacion;
}) => {
  const newData = data.solicitud;
  const typeMulti =
    noticeData.type.charAt(0).toUpperCase() + noticeData.type.slice(1);

  return [
    {
      columnTitleSx: { fontSize: '12px' },
      clumnSubtitleSx: { fontSize: '16px', color: '#757575' },
      title: {
        name: !!data.flgIsOwner ? 'En tu solicitud' : 'En la solicitud',
        sx: { color: '#757575', fontSize: '16px' },
      },
      columnOne: {
        title: `Precio x ${noticeData.type}`,
        subtitle: `${currencyFormat(newData.precio)}`,
      },
      columnTwo: {
        title: `# ${typeMulti}s`,

        subtitle: !!newData.puntosRequeridos
          ? `${newData.puntosRequeridos as number}`
          : `${newData.horasRequeridas as number}`,
      },
      columnThree: {
        title: `Precio total`,
        subtitle: `${currencyFormat(
          Number(noticeData.amount) * (data.solicitud.precio as number)
        )}`,
      },
    },
    {
      columnTitleSx: { fontSize: '12px' },
      clumnSubtitleSx: { fontSize: '16px', fontWeight: 700 },
      title: {
        name: !!data.flgIsOwner ? 'Transportista ofrece' : 'Tu oferta',
        sx: { fontWeight: 600, fontSize: '16px' },
      },
      columnOne: {
        title: `Precio x ${noticeData.type}`,
        subtitle: `${currencyFormat(noticeData.priceType)}`,
      },
      columnTwo: {
        title: `# ${typeMulti}s`,
        subtitle: `${noticeData.amount}`,
      },
      columnThree: {
        title: `Precio total`,
        subtitle: `${currencyFormat(noticeData.total)}`,
      },
    },
  ];
};
