import {
    Button, Container, experimental_sx as sx,
    styled
} from "@mui/material";

export const ContainerButtons = styled(Container)(
  sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    mt: 2,
    mb: 2
  })
);

export const ButtonAccept = styled(Button)(
  sx({
    heigth: "44px",
    backgroundColor: "#FF6700",
    borderRadius: "44px",
    mt: "5px",
    "&:hover": {
        backgroundColor: '#FF6700'
      },
  })
);

export const ButtonDecline = styled(Button)(
  sx({
    color: "#FF6700",
    "&:hover": {
        color: '#FF6700',
        backgroundColor: 'transparent'
      },
   
  })
);

export const ButtonCounterOffer = styled(Button)(
  sx({
    heigth: "44px",
    color: "#FF6700",
    backgroundColor: "#FFFFFF",
    border: "1px solid #FF6700",
    borderRadius: "44px",
    mt: "10px",
    "&:hover": {
        backgroundColor: 'white'
      },
  })
);
