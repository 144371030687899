import ConditionsListOffert from '@/components/conditionsList/ConditionsListOffert';
import { Typography } from '@mui/material';

import DetailsTextV2 from '@/components/DetailsTextV2/Index';
import { ConfigDataNotice } from '@/pages/flowNoticeNegotation/interfaces/interfaces';
import {
  getDestinyInMoving,
  getFormatMetro,
  getFormatPrice,
  getOriginInMoving,
  getTitleByPrice,
} from '../../../containers/ConfirmationDetails/utils/utilsOffert';

export const configDataMoving: ConfigDataNotice[] = [
  {
    title: 'Detalles de la solicitud',
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
    },
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha del servicio',

      originDescription: '',
    },
    valid: [
      {
        property: 'date',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'date',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
      containerSx: {
        alignItems: 'flex-start',
      },
    },
    valid: [
      {
        property: 'originText',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'originText',
        componentProp: 'origen',
        defaultResult: '',
        callback: getOriginInMoving,
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-dark',
      title: 'Destino',
      containerSx: {
        alignItems: 'flex-start',
      },
    },
    valid: [
      {
        property: 'originText',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'originText',
        componentProp: 'origen',
        defaultResult: '',
        callback: getDestinyInMoving,
      },
    ],
  },
  // metraje
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-rules',
      title: 'Metraje',
    },
    valid: [
      {
        property: 'squareMeters',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'squareMeters',
        componentProp: 'origen',
        defaultResult: '',
        callback: getFormatMetro,
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-cargo',
      title: 'Ayudantes',
    },
    valid: [
      {
        property: 'assistants',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'assistants',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: 'Precio de punto a punto',
      variant: 'body2',
      marginTop: '24px',
    },
    valid: [
      {
        property: 'price',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'price',
        componentProp: 'title',
        defaultResult: '',
        callback: getTitleByPrice,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'h5',
      sx: { textTransform: 'inherit' },
    },
    valid: [
      {
        property: 'price',
        componentProp: 'title',
        defaultResult: 0,
        callback: getFormatPrice,
      },
    ],
  },
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '22px' },
    },
    valid: [
      {
        property: 'negotiationConditions',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'negotiationConditions',
        componentProp: 'listServices',
        defaultResult: [],
      },
    ],
  },
];
