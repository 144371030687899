import HeaderSectionV2 from "@/components/headerSectionV2/HeaderSectionV2";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { Alert, Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertComponent } from "../../components/AlertComponent";
import HeaderDetail from "../../components/HeaderDetail";
import useGetData from "../../shared/hooks/useGetData";
import { CoCounterOfferButtons } from "./components/CoCounterOfferButtons";
import { CoCounterOfferGlobalPrice } from "./components/CoCounterOfferGlobalPrice";
import { ID_MOVING_FLOW } from "@/consts/defaultConsts";
import {
  EditNoticeCounterOfferButtons,
  EditNoticeOfferButtons,
} from "@/pages/noticeV2Negotation/components/EditNoticeButtons";
import { noResponseText, optionEleven, optionOne } from "@/consts/noticeConsts";

interface LocationState {
  hasBackNavigate?: boolean;
  hasBackLinkOffert?: boolean;
}

export const CoCounterOfferLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { notice } = useGetData();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const { state } = useLocation();
  const locationState = state as LocationState;
  const [hasBackNavigate, setHasBackNavigate] = React.useState(false);
  const [hasBackLinkOffert, setHasBackLinkOffert] = React.useState(false);
  const isOwner = !!notice?.flgIsOwner;
  const isMoving = notice?.idtipoaviso === ID_MOVING_FLOW;
  const noResponse =
    notice?.ultimaAvisoNegociacionAcciones.tipoAvisoNegociacionAccion.estado
      .id === optionEleven;
  React.useEffect(() => {
    if (locationState?.hasBackNavigate && !locationState?.hasBackLinkOffert) {
      setHasBackNavigate(true);
    }

    if (locationState?.hasBackLinkOffert && !locationState?.hasBackNavigate) {
      setHasBackLinkOffert(true);
    }
  }, []);

  const handleNavigationBack = () => {
    navigate(-1);
  };

  const handleTitleMoving = () => {
    return isMoving
      ? "¡Cotizaron tu mudanza!"
      : "¡Contraofertaron tu servicio!";
  };

  const handleEdition = () => {
    if (notice?.idTipoAvisoNegociacion === optionOne)
      return <EditNoticeOfferButtons />;

    return <EditNoticeCounterOfferButtons />;
  };

  return (
    <Container maxWidth={matches ? "xl" : "xs"}>
      {(hasBackNavigate || hasBackLinkOffert) && (
        <HeaderSectionV2
          icon="icon-arrow-left"
          title={""}
          onClick={() => handleNavigationBack()}
        />
      )}

      {isOwner && !noResponse && <AlertComponent />}

      {!isOwner && !noResponse && (
        <HeaderDetail title={handleTitleMoving()} icon="icon-counterOfferMat" />
      )}

      {noResponse && (
        <Alert sx={{ mb: 3, mt: 3, p: 2 }} severity="info">
          {noResponseText}
        </Alert>
      )}

      <Grid
        container
        spacing={0}
        sx={{
          display: "flex",
          justifyContent: "center",
          pl: matches ? 20 : 0,
          pr: matches ? 20 : 0,
        }}
      >
        <Grid xs={matches ? 7 : 12}>{children}</Grid>

        <Grid xs={matches ? 5 : 12} sx={{ padding: "0px" }}>
          <CoCounterOfferGlobalPrice data={notice as NoticeV2NegType} />
          {!isOwner ? <CoCounterOfferButtons /> : handleEdition()}
        </Grid>
      </Grid>
    </Container>
  );
};
