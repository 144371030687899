import { convertDate } from "@/adapters/store/editNegotationRequestAdapter";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import DatePicker from "@/components/form/datePicker.tsx/DatePicker";
import HeaderSection from "@/components/headerSection/HeaderSection";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import SnackbarNotification from "@/components/snackbar/Snackbar";
import LabelRequired from "@/components/utils/LabelRequired";
import { FORMAT_DATE_DDMMYYYY, FORMAT_TIME_HHMMmm } from "@/consts/formatsDate";
import {
  NEGOTATION_RENEWAL_SUCCESS,
  REAPPLY,
} from "@/consts/negotationFlowPath";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { customerRequestObjectTypes } from "@/pages/customerRequest/shared/consts/customerRequestConsts";

import { setActiveButton } from "@/store/customerRequestRedux/customerRequestSlice";
import { uploadRenewRequest } from "@/store/noticeNegotationRequestRedux/editNegRequestThunk";
import { Container, Grid, styled } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useUnsavedAndSavedChanges from "../../../noticeRequestFlow/hooks/useUnsavedAndSavedChanges";
import moment from "moment";
import Input from "@/components/inputCustom/Input";

export interface HeaderSection {
  icon: string;
  iconSection: string;
  title: string;
  subTitle: string;
}
const ContainerArea = styled(Container)(
  () => `
        padding: 16px 0;
      `
);

const headerSection: HeaderSection = {
  icon: "icon-arrow-left--primary",
  iconSection: "icon-location--blue",
  title: "Ruta del Servicio",
  subTitle: "Editar",
};

const headerReApplySection: HeaderSection = {
  icon: "icon-arrow-left--primary",
  iconSection: "icon-location--blue",
  title: "Fecha",
  subTitle: "Volver a solicitar",
};

const Containerr = styled(Grid)(
  () => `
    display: flex;
    flex-direction: column;  
    margin-top: 24px;
  `
);

const RenewRequestNegotation = () => {
  const { pathname } = useLocation();
  const validPath = pathname.endsWith("volver-solicitar");
  const { handleManageModalUnsavedChanges } = useUnsavedAndSavedChanges();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isReApply = pathname.endsWith(REAPPLY);
  const sectionText = isReApply ? headerReApplySection : headerSection;
  const {
    customerRequest: { isLoading, error, activeButton, requestDetailsView },
  } = useAppSelector((state) => state);
  const idRequest = requestDetailsView?.id.toString();
  const oneDay = dayjs().add(1, "day");
  const [datePicker, setDatePicker] = useState<Dayjs | null>(oneDay);
  const [minDate, setMinDate] = useState<Dayjs | null>(oneDay);
  const fechaRequest = requestDetailsView?.fecha || "";
  const nextDay = dayjs(moment(fechaRequest, FORMAT_TIME_HHMMmm).toDate()).add(
    1,
    "day"
  );

  useEffect(() => {
    if (requestDetailsView) {
      if (!!fechaRequest) {
        // verificar si la fecha es una mas antigua usar la actual fecha con un dia siguiente.
        const date = dayjs(fechaRequest, FORMAT_DATE_DDMMYYYY);
        const isBefore = date.isBefore(dayjs());
        if (isBefore) {
          setDatePicker(oneDay);
          setMinDate(oneDay);
          return;
        }
        setDatePicker(nextDay);
        setMinDate(nextDay);
      }
    }
  }, []);

  const handleSubmit = (event: EventType["submit"]) => {
    event.preventDefault();

    const newDate = convertDate(datePicker as Dayjs);

    const body = { fecha: newDate };

    dispatch(uploadRenewRequest(idRequest as string, body)).then(() => {
      handleNextRute();
    });
  };

  const handleNextRute = () => {
    const valueToReplace =
      customerRequestObjectTypes[
        requestDetailsView?.tipoSolicitud.id as number
      ];
    const idValueToReplace = requestDetailsView?.id.toString();
    const nextPath = NEGOTATION_RENEWAL_SUCCESS.replace(
      ":typeRequest",
      valueToReplace
    ).replace(":typeId", idValueToReplace as string);
    navigate(nextPath, { state: { message: "successful request" } });
  };

  const handleCompareinputs = () => {
    const currentInputsValue = [datePicker];
    const dataToInputsValue = [dayjs(fechaRequest, FORMAT_DATE_DDMMYYYY)];

    const result =
      JSON.stringify(currentInputsValue) === JSON.stringify(dataToInputsValue);
    return !result;
  };

  const handleDisabledButton = () => {
    const conditions = [datePicker];
    let result = true;

    const conditionsResult = conditions.some((condition) => !condition);
    const sameData = handleCompareinputs();
    dispatch(setActiveButton(sameData));

    if (sameData) {
      result = false;
      if (conditionsResult) {
        result = true;
      }
    }

    return result;
  };

  const handlePrevRute = () => {
    if (activeButton) {
      !!error ? handleManageModalUnsavedChanges() : navigate(-1);
    } else {
      navigate(-1);
    }
  };

  return (
    <Container maxWidth="xs">
      <HeaderSection
        icon={sectionText.icon}
        title={sectionText.title}
        subTitle={sectionText.subTitle}
        onClick={handlePrevRute}
      />
      {<SnackbarNotification />}
      <ContainerArea>
        <SectionIcon icon={sectionText.iconSection} />
        <Containerr container>
          <form onSubmit={handleSubmit}>
            <DatePicker
              fullWidth
              initValue={datePicker}
              inputFormat={FORMAT_DATE_DDMMYYYY}
              label={<LabelRequired str="¿Cuándo?" />}
              onChange={setDatePicker}
              disablePast={false}
              minDate={minDate as dayjs.Dayjs}
              maxDate={dayjs().add(1, "month")}
              renderInput={Input}
            />

            <Grid item sx={{ marginTop: "16px" }}>
              <ButtomLoader
                type="submit"
                isLoading={isLoading}
                buttomProps={{
                  disabled: handleDisabledButton() || isLoading,
                }}
              >
                {validPath ? "VOLVER A SOLICITAR" : "GUARDAR"}
              </ButtomLoader>
            </Grid>
          </form>
        </Containerr>
      </ContainerArea>
    </Container>
  );
};

export default RenewRequestNegotation;
