import TransporterRating from "@/components/userRating/TransporterRating";
import { ID_IN_CITY } from "@/consts/defaultConsts";
import { PROFILE_NOTICE_LIST_PATH } from "@/consts/typeServicesPaths";
import { useAppSelector } from "@/hooks/useReducer";
import { NoticeDetailMultiDestinyModel } from "@/models/notice/noticeModels";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { Stack, Typography, styled } from "@mui/material";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import useDetailNotice from "../../hook";
import {
  cleanStringDuplicates,
  convertArrayToString,
  getTransporterRatingFormat,
} from "../../utils";
import DetailOptionsList from "../detailOptionsList";
import { detailsConfigStandar } from "../detailOptionsList/configDataInCity";
import DetailTypeFlow from "../detailTypeFlow";
import ShareButton from "@/components/shareButton/ShareButton";

const ContainerDetail = styled(Stack)(
  () => `
    display: flex;
    flex-direction: column;
  `
);

const WrapperDetailFlow = styled("div")(
  () => `
    display: flex;
  `
);

interface LocationState {
  hasBackNavigate?: boolean;
  destiny: string;
  timeService: string;
}

const DetailsMultiDestiny = () => {
  const {
    detailsNoticeSlice: { isLoading },
    user: userRedux,
  } = useAppSelector((state) => state);
  const { handleGetCurrentDetailNotice } = useDetailNotice();

  const notice: NoticeDetailMultiDestinyModel = useMemo(
    () => handleGetCurrentDetailNotice(),
    [isLoading]
  );
  const user = userRedux.user as IUser;
  const { pathname, state } = useLocation();
  const locationState = state as LocationState;
  const isPrivate = pathname.startsWith(PROFILE_NOTICE_LIST_PATH);

  const handleGetDetailListConfig = () => {
    const { tipoaviso } = notice;

    if (tipoaviso.id === ID_IN_CITY) {
      // TODO: trabajando!!
      return detailsConfigStandar;
    }

    return detailsConfigStandar;
  };

  const handleUSerIsOwner = () => {
    if (!!Object.keys(user).length) {
      return user.id === notice.transportista?.id;
    }

    return false;
  };

  const handleShowRating = () => {
    const isOwner = handleUSerIsOwner();
    if (isPrivate) {
      return false;
    }

    return !isOwner;
  };

  const handleGetDestinyShow = () => {
    const destiny = locationState?.destiny;
    if (destiny) {
      let clenaDestiny = cleanStringDuplicates(destiny);
      return convertArrayToString(clenaDestiny);
    }
    return destinotexto;
  };

  if (!notice) return <></>;

  const { tipoaviso, destinotexto, transportista, flghoraopunto } = notice;

  return (
    <ContainerDetail>
      <WrapperDetailFlow>
        <DetailTypeFlow
          nombre={tipoaviso?.nombre}
          id={tipoaviso?.id}
          flghoraopunto={flghoraopunto}
        />
        <ShareButton show={!isPrivate} />
      </WrapperDetailFlow>

      <Typography variant="h6" marginBottom="8px">
        Distritos de cobertura
      </Typography>
      <Typography variant="body2" marginBottom="16px">
        {handleGetDestinyShow()}
      </Typography>

      {/* perfil transportista */}
      {handleShowRating() && (
        <TransporterRating
          data={getTransporterRatingFormat(transportista)}
          viewRating={true}
        />
      )}

      {/* Detalle */}
      <DetailOptionsList
        detailsList={handleGetDetailListConfig()}
        notice={notice}
      />
    </ContainerDetail>
  );
};

export default DetailsMultiDestiny;
