import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface PublicNoticeState {
  list: Array<any>;
  selectedNotice: any;
  isLoading: boolean;
  error: any;
}

const initialState: PublicNoticeState = {
  list: [],
  selectedNotice: {},
  isLoading: false,
  error: null,
};

export const publicNoticeSlice = createSlice({
  name: "public/notice",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setList: (state, { payload }: PayloadAction<Array<any>>) => {
      state.list = payload;
      state.isLoading = false;
      state.error = null;
    },
    selectedNotice: (state, { payload }: PayloadAction<Object>) => {
      state.selectedNotice = payload;
    },
    setError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setList, setError, selectedNotice } =
  publicNoticeSlice.actions;
