import { REQUEST_TYPE_MOVE_BETWEEN_CITIES, REQUEST_TYPE_MOVE_CITY } from "@/consts/requestConsts";

export const TYPE_MOVING = {
  inCity: {
    text: REQUEST_TYPE_MOVE_CITY,
    id: 1,
  },
  betweenCities: {
    text: REQUEST_TYPE_MOVE_BETWEEN_CITIES,
    id: 2,
  },
};
