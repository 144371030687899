import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import { ContentSection, SectionSubTitle, SectionTitle } from "./styleInfoLand";

interface InfoLandProps {
  icon?: string;
  title: string;
  subtitle: string | JSX.Element;
  sx?: SxProps<Theme>;
  subtitle2?: string;
  button?: JSX.Element;
  height?: any;
  children?: React.ReactNode;
  gap?: string | number;
}

const InfoLand = ({
  icon,
  sx,
  children,
  title,
  button,
  subtitle,
  subtitle2,
  height = "",
  gap,
}: InfoLandProps) => {
  return (
    <ContentSection sx={{ height: height, gap, ...sx }}>
      {icon && <i className={icon} />}
      <SectionTitle>{title}</SectionTitle>
      {button && button}
      {subtitle && <SectionSubTitle>{subtitle}</SectionSubTitle>}
      {subtitle2 && <SectionSubTitle>{subtitle2}</SectionSubTitle>}
      {children && children}
    </ContentSection>
  );
};

export default InfoLand;
