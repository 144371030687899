import {
  getBodyCounterOffertAuctionAdapter,
  getBodyOffertAuctionAdapter,
  getBodyOffertInCityByOffertStorage,
  getBodyOffertMovingByOffertStorage,
  getBodyOffertMultiDestinyByOffertStorage,
  getBodyOffertPhotoAdapter,
  getPhotosOffertAdapter,
  prepareListPhotos,
} from '@/adapters/store/offertStoreAdapter';
import { ResponseDistrictListAPI } from '@/models/location/districtModel';
import { getOriginAndDestinyByPromiseSettled } from '@/services/adapters/districtAdapter';
import {
  NegotiationPhotosProps,
  SaveNegotiationServices,
} from '@/services/interfaces/negotiationInterface';
import { getOnlyDistrictsByCityServices } from '@/services/locationServices';
import {
  BodyUploadPriceNegotationPrice,
  getOfferCondicionsByIdNotice,
  getOffertByIdServices,
  getTypeInmuebleService,
  saveNegotiationPhotosServices,
  saveNegotiationServices,
  saveOffertByIdServices,
  uploadPriceNoticeOfferNegotation,
} from '@/services/negotiationServices';
import { saveImagesNegotiationServices } from '@/services/noticeNegotationRequestServices';
import { getMultiPromises } from '@/utils/helpers';
import { getMessaggeError } from '../auth/utils';
import { AppDispatch, RootState } from '../store';
import {
  ISaveLoadOffertProps,
  ITipoInmuebles,
  SaveInmuebleProps,
  SaveLocationProps,
} from './offert.interface';
import {
  resetOffert,
  saveOffert,
  setAvisoDestinos,
  setAvisoOrigenes,
  setConditions,
  setDataLocation,
  setDataMovingDestiny,
  setDataMovingOrigin,
  setError,
  setIdVerifiedImages,
  setImageUploadError,
  setIsLoading,
  setIsLoadingImage,
  setOffertData,
  setPhotosPreLoaded,
  setTipoAvisoNegociacion,
  setTipoInmuebles,
  setTypeUser,
} from './offert.slice';

export const plusOffertThunk = (data: ISaveLoadOffertProps) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setIsLoadingImage(true));
    try {
      dispatch(setOffertData(data));
      const {
        offert: { idVerifiedImages, photosPreLoaded },
      } = getState();

      const photos = getPhotosOffertAdapter([
        ...photosPreLoaded,
        ...data.images,
      ]);

      let arrayPhotos = { ...photos };
      Object.keys(arrayPhotos).forEach((key) =>
        arrayPhotos[key as keyof NegotiationPhotosProps] === undefined
          ? delete arrayPhotos[key as keyof NegotiationPhotosProps]
          : {}
      );

      const { fotosverificadas } = await saveImagesNegotiationServices(
        idVerifiedImages,
        photos
      );

      const recordedPhotos = prepareListPhotos(
        fotosverificadas,
        Object.keys(arrayPhotos)
      );

      dispatch(setIdVerifiedImages(fotosverificadas.id));
      dispatch(setPhotosPreLoaded(recordedPhotos));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setImageUploadError(error.message));
      throw new Error(error.message || undefined);
    }
  };
};

export const saveLocationDataThunk = (data: SaveLocationProps) => {
  return (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    try {
      dispatch(setDataLocation(data));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const saveOffertPhotosThunk = (idOffert: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const {
        offert: { images },
      } = getState();
      const body = await getBodyOffertPhotoAdapter(images);

      const { avisoNegociacionData } = await saveNegotiationPhotosServices(
        idOffert.toString(),
        body
      );

      dispatch(saveOffert(avisoNegociacionData.avisoNegociacion));
      dispatch(setIsLoading(false));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const saveOffertInCityThunk = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setIsLoading(true));
    try {
      const {
        offert,
        saveFlowData: {
          saveFlowData: [notice],
        },
      } = getState();
      const body = getBodyOffertInCityByOffertStorage(offert);

      const { avisoNegociacionData } =
        await saveNegotiationServices<SaveNegotiationServices>(
          notice.id.toString(),
          body
        );

      dispatch(saveOffert(avisoNegociacionData.avisoNegociacion));
      if (offert.images.length) {
        dispatch(
          saveOffertPhotosThunk(avisoNegociacionData.avisoNegociacion.id)
        );
      } else {
        dispatch(setIsLoading(false));
      }
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const getDistrictsByIdCityOriginAndDestiny = (
  idOrigin: number,
  idDestiny: number
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    try {
      const idCityOrigin = idOrigin.toString();
      const idCityDestiny = idDestiny.toString();

      const result = await getMultiPromises<ResponseDistrictListAPI>([
        getOnlyDistrictsByCityServices(idCityOrigin),
        getOnlyDistrictsByCityServices(idCityDestiny),
      ]);
      const { destiny, origin } = getOriginAndDestinyByPromiseSettled(result);

      dispatch(setAvisoOrigenes(origin));
      dispatch(setAvisoDestinos(destiny));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

// multiDestino

export const saveOffertMultiDestinoThunk = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setIsLoading(true));
    try {
      const {
        offert,
        saveFlowData: {
          saveFlowData: [notice],
        },
      } = getState();
      const body = getBodyOffertMultiDestinyByOffertStorage(offert);
      const { avisoNegociacionData } =
        await saveNegotiationServices<SaveNegotiationServices>(
          notice.id.toString(),
          body
        );

      dispatch(saveOffert(avisoNegociacionData.avisoNegociacion));
      if (offert.images.length) {
        dispatch(
          saveOffertPhotosThunk(avisoNegociacionData.avisoNegociacion.id)
        );
      } else {
        dispatch(setIsLoading(false));
      }
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const saveOffertAuctionThunk = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const {
        offert,
        saveFlowData: { saveFlowData },
      } = getState();
      const id = saveFlowData[0].id;
      const noticeId = id?.toString();
      const OFFERT_ID = 1;

      const body =
        offert.idTipoAvisoNegociacion === OFFERT_ID
          ? getBodyOffertAuctionAdapter(offert, saveFlowData)
          : getBodyCounterOffertAuctionAdapter(offert, saveFlowData);

      const { avisoNegociacionData } = await saveNegotiationServices(
        noticeId,
        body
      );

      dispatch(saveOffert(avisoNegociacionData.avisoNegociacion));
      if (offert.images.length) {
        dispatch(
          saveOffertPhotosThunk(avisoNegociacionData.avisoNegociacion.id)
        );
      } else {
        dispatch(setIsLoading(false));
      }
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const getOffertConditionThunk = (idNegotation?: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setIsLoading(true));
    try {
      const {
        saveFlowData: {
          saveFlowData: [notice],
        },
      } = getState();
      const id = notice.tipoaviso.id.toString();

      const { avisoNegociacionTipoData } = await getOfferCondicionsByIdNotice(
        id,
        idNegotation
      );

      const conditions = avisoNegociacionTipoData.avisoCondiciones.map(
        (condition) => ({
          ...condition,
          condicionesNegociacion: {
            ...condition.condicionesNegociacion,
            isActive: !!condition.condicionesNegociacion.flgseleccion,
          },
        })
      );
      dispatch(setConditions(conditions));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

// get inmuebles
interface ResponseInmuebleListAPI {
  tipoInmueblesData: {
    tipoInmuebles: ITipoInmuebles[];
  };
}

export const getInmueblesThunk = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    try {
      const data = await getTypeInmuebleService();
      const { tipoInmueblesData } = data as ResponseInmuebleListAPI;
      dispatch(setTipoInmuebles(tipoInmueblesData.tipoInmuebles));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const saveOffertMovingInmuebleThunk = (
  data: SaveInmuebleProps,
  type: number
) => {
  return (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    try {
      if (type === 1) {
        dispatch(setDataMovingOrigin(data));
      } else {
        dispatch(setDataMovingDestiny(data));
      }
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const saveOffertMovingThunk = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setIsLoading(true));
    try {
      const {
        offert,
        saveFlowData: {
          saveFlowData: [notice],
        },
      } = getState();
      const body = getBodyOffertMovingByOffertStorage(offert);
      const { avisoNegociacionData } = await saveNegotiationServices(
        notice.id.toString(),
        body
      );

      dispatch(saveOffert(avisoNegociacionData.avisoNegociacion));
      dispatch(setIsLoading(false));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const getOffertByIdThunk = (idOffert: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    dispatch(resetOffert());

    try {
      const id = idOffert.toString();
      const { avisoNegociacionData } = await getOffertByIdServices(id);

      dispatch(saveOffert(avisoNegociacionData.avisoNegociacion));
      dispatch(
        setTipoAvisoNegociacion(
          avisoNegociacionData.avisoNegociacion.tipoAvisoNegociacion.id
        )
      );
      dispatch(setIsLoading(false));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

interface SaveOffertByIdThunkProps {
  idOffer: number;
  idAccion?: number;
  preciocontraoferta?: number;
  precioactual?: number;
}

export const saveOffertByIdThunk = (props: SaveOffertByIdThunkProps) => {
  return async (dispatch: AppDispatch) => {
    const { idOffer, idAccion, preciocontraoferta, precioactual } = props;
    dispatch(setIsLoading(true));

    try {
      const id = idOffer.toString();
      const body = {
        idAccion,
        preciocontraoferta,
        precioactual,
      };

      const { avisoNegociacionData } = await saveOffertByIdServices(id, body);

      dispatch(saveOffert(avisoNegociacionData.avisoNegociacion));
      dispatch(setIsLoading(false));
      return avisoNegociacionData.avisoNegociacion;
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const updatePriceOfferNoticeThunk = (
  idNotice: string,
  body: BodyUploadPriceNegotationPrice
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    try {
      const { avisoNegociacionData } = await uploadPriceNoticeOfferNegotation(
        idNotice,
        body
      );
      dispatch(saveOffert(avisoNegociacionData.avisoNegociacion));

      dispatch(setTypeUser(''));
      dispatch(setIsLoading(false));
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};
