import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import DatePicker from "@/components/form/datePicker.tsx/DatePicker";
import Input from "@/components/inputCustom/Input";
import { FORMAT_DATE_DDMMYYYY, FORMAT_DATE_V2 } from "@/consts/formatsDate";
import { maxLengthPrice } from "@/consts/noticeConsts";
import { TEXT_ERROR_MAX_PRICE_LOAD } from "@/consts/textErrors";
import { SUCCESS_TEXT_EDIT_PRICE } from "@/consts/textSuccess";
import { PROFILE_NOTICE_LIST_PATH } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { NoticeDetailAuctionModel } from "@/models/notice/noticeModels";
import useDetailNotice from "@/pages/detailNotice/hook";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { priceInfoText } from "@/pages/noticeV2/NoticePlace/placeConfig";
import { onSetIntercitySaleThunk } from "@/store/includedServices/inclodedServicesThunks";
import { SelectCity } from "@/store/locationRedux/selectLocation";
import { setDirtyPrice } from "@/store/noticeRedux/noticeSlice";
import { editNoticePriceThunk } from "@/store/noticeRedux/noticeThunks";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { setMessageEdit } from "@/store/saveFlow/saveFlowSlice";
import Icon from "@/utils/Icon";
import { cleanValueNumber } from "@/utils/helpers";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { Box, FormControl, InputAdornment, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BoxContainerInfo } from "./BodyFeeInCity";
import { configText } from "./configDataBodyFee";

const INPUT_MAX_LENGTH = 5;
const minDate = dayjs().add(1, "day");
const maxDate = dayjs().add(1, "month");

const BodyFeeIntercitySale = () => {
  const {
    selectLocation: { selectedOrigin, selectedDestiny },
    detailsNoticeSlice: { isLoading },
  } = useAppSelector((state) => state);

  const { handleGetCurrentDetailNotice } = useDetailNotice();

  const notice: NoticeDetailAuctionModel = useMemo(
    () => handleGetCurrentDetailNotice(),
    [isLoading]
  );

  const [regularPrice, setRegularPrice] = useState("");
  const [descPrice, setDescPrice] = useState("");
  const [error, setError] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [messageErrorApi, setMessageErrorApi] = useState("");
  const [datePicker, setDatePicker] = useState<Dayjs | null>(
    dayjs().add(1, "day")
  );
  const [errorDate, setErrorDate] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();
  const currentSelectedDestiny = selectedDestiny as SelectCity;
  const currentSelectedOrigin = (selectedOrigin as SelectCity)

  useEffect(() => {
    if (!!notice) {
      const date = dayjs(dayjs(notice.fechafija, FORMAT_DATE_DDMMYYYY));
      setRegularPrice(notice?.precioregular?.toString() || "");
      setDescPrice(notice?.precio?.toString());
      setDatePicker(date);
      setIsEdit(location.pathname.startsWith(PROFILE_NOTICE_LIST_PATH));
    }

    dispatch(setDirtyPrice(false));
    return () => {
      dispatch(setDirtyPrice(false));
      setMessageErrorApi("");
    };
  }, []);

  const handleRegularPrice = ({ target }: EventType["change"]) => {
    const { value } = target;

    if (value.length > INPUT_MAX_LENGTH) return;

    if (!value.length || Number(value) <= Number(descPrice)) {
      setDescPrice("");
    }

    const valueToSave = cleanValueNumber(value);

    setRegularPrice(valueToSave);
    dispatch(setDirtyPrice(true));
  };

  const handleDescPrice = ({ target }: EventType["change"]) => {
    const { value } = target;

    if (
      value.length >= maxLengthPrice ||
      Number(value) >= Number(regularPrice)
    ) {
      setError(TEXT_ERROR_MAX_PRICE_LOAD);
    } else {
      setError("");
    }

    const valueToSave = cleanValueNumber(value);

    setDescPrice(valueToSave);

    dispatch(setDirtyPrice(true));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLoading) return;

    const currentRegularPrice = Number(regularPrice);
    const currentDescPrice = Number(descPrice);
    const currentDescPercentage = Number(
      ((1 - Number(descPrice) / Number(regularPrice)) * 100).toFixed(2)
    );
    setMessageErrorApi("");
    if (isEdit) {
      const data = {
        precio: currentDescPrice,
        precioregular: currentRegularPrice,
        porcentajeDescuento: currentDescPercentage,
        fechafija: dayjs(datePicker)?.format(FORMAT_DATE_DDMMYYYY),
      };

      dispatch(editNoticePriceThunk(notice.id, data)).then(handleSuccessEdit);
    } else {
      dispatch(
        onSetIntercitySaleThunk(
          currentRegularPrice,
          currentDescPercentage,
          currentDescPrice
        )
      )
        .then((res) => {
          dispatch(getNextRoute(navigate));
        })
        .catch((error: Error) => setMessageErrorApi(error?.message));
    }
  };

  const handleSuccessEdit = () => {
    dispatch(setMessageEdit(SUCCESS_TEXT_EDIT_PRICE));
    navigate(-1);
  };

  const handleIsSubmit = () => {
    return !Number(regularPrice) || !Number(descPrice) || !!error || isLoading;
  };

  const handleShowDescount = (): string => {
    if (descPrice && regularPrice) {
      return (
        ((1 - Number(descPrice) / Number(regularPrice)) * 100).toFixed(2) + "%"
      );
    }
    return "-";
  };

  const textButton = isEdit ? configText.button.edit : configText.button.flow;

  const handleErrorDate = (error: string) => {
    !!error && setErrorDate("Fecha invalida");
  };

  const handleChangeDate = (value: dayjs.Dayjs | null) => {
    setDatePicker(value);
    !!errorDate && setErrorDate("");
  };

  return (
    <>
      <>
        {!!messageErrorApi && (
          <StandardAlert
            sx={{ marginBottom: "16px" }}
            severity="error"
            color="error"
          >
            {messageErrorApi}
          </StandardAlert>
        )}
      </>

      <BoxContainerInfo>
        <Icon name="icon-handsShake" size="md" />
        <Typography variant="body2" sx={{ fontSize: "16px" }}>
          {priceInfoText}
        </Typography>
      </BoxContainerInfo>

      <Typography variant="body2" color="black" sx={{ mb: 2 }}>
        <strong>Origen:</strong> {currentSelectedOrigin?.nombre}
        {notice?.origentexto && `${notice?.origentexto}`}
      </Typography>
      <Typography variant="body2" color="black" sx={{ mb: 3 }}>
        <strong>Destino:</strong> {currentSelectedDestiny?.nombre}
        {notice?.destinotexto && `${notice?.destinotexto}`}
      </Typography>

      <form onSubmit={handleSubmit} autoComplete="off">
        <DatePicker
          fullWidth
          variant="outlined"
          minDate={minDate}
          maxDate={maxDate}
          initValue={datePicker}
          inputFormat={FORMAT_DATE_V2}
          label={<LabelInputRequired str="Fecha de salida" />}
          showStylesLabel
          renderInput={Input}
          onChange={handleChangeDate}
          onError={handleErrorDate}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CalendarMonthOutlinedIcon />
              </InputAdornment>
            ),
            endAdornment: null,
          }}
        />

        <Box
          sx={{
            display: "flex",
            marginTop: "16px",
            marginBottom: "16px",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1" sx={{ fontSize: "14px" }}>
            Capacidad disponible a rematar:
          </Typography>
          <Typography sx={{ fontSize: "14px", ml: 1 }}>
            {notice?.pesomaximo} kilos
          </Typography>
        </Box>

        <Box sx={{ display: "flex", marginTop: "16px", marginBottom: "16px" }}>
          <Typography variant="subtitle1">Precio del servicio</Typography>
        </Box>

        <FormControl sx={{ marginTop: "16px", width: "100%" }}>
          <Input
            name="regularPrice"
            label={<LabelInputRequired str="Precio regular (S/)" />}
            value={regularPrice}
            variant="standard"
            placeholder="Lo que cobrarías por este servicio, Ej. 500"
            onChange={handleRegularPrice}
            onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
              minLength: 1,
              maxLength: 5,
              required: true,
            }}
          />
        </FormControl>
        <FormControl sx={{ marginTop: "16px", width: "100%" }}>
          <Input
            name="descPrice"
            label={<LabelInputRequired str="Precio remate (S/)" />}
            error={!!error.length}
            value={descPrice}
            variant="standard"
            placeholder="Precio final del servicio con descuento"
            onChange={handleDescPrice}
            onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
              minLength: 1,
              maxLength: 5,
              required: true,
            }}
            helperText={error}
          />
        </FormControl>
        <Typography
          variant="body2"
          color="black"
          sx={{
            marginTop: "16px",
            marginBottom: "16px",
          }}
        >
          Porcentaje de descuento: {handleShowDescount()}
        </Typography>

        <ButtomLoader
          type="submit"
          disabled={handleIsSubmit()}
          isLoading={isLoading}
        >
          {textButton}
        </ButtomLoader>
      </form>
    </>
  );
};

export default BodyFeeIntercitySale;
