import FormPriceEditBetweenCities from "./formPriceEditBetweenDestiny/FormPriceEditBetweenDestiny";
import FormPriceEditInCity from "./formPriceEditInCity/FormPriceEditInCity";
import FormDetallesMoving from "./formPriceEditMoving/FormDetallesMoving";
import FormPriceEditMultidestiny from "./formPriceEditMultidestiny/FormPriceEditMultidestiny";



export const objectRenderComponent: Record<number, () => JSX.Element> = {
    1: FormPriceEditInCity,
    2: FormPriceEditMultidestiny,
    3: FormPriceEditBetweenCities,
    5: FormDetallesMoving
  };
  

  