import { TypeProperty } from "@/models/offert/offertModels";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { listDataAscensor } from "../shared/const/offertConst";
import LabelInputRequired from "./LabelInputRequired";
import { useState } from "react";
import { SelectsForm } from "../interfaces/interfaces";
import { ID_HOUSE } from "../shared/const/default";
import Input from "@/components/inputCustom/Input";
import { EventType } from "@/interfaces/globalInterface";
import { cleanInputNumber } from "@/utils/inputsUtils";

type Props = {
  form: SelectsForm;
  onChange: (value: Record<string, string>) => void;
  listTypeProperty: TypeProperty[];
  isCounterOffer?: boolean;
};

type InputOrSelectFloorProps = {
  value: string;
  onChange: (value: SelectChangeEvent | EventType["change"]) => void;
  isCounterOffer: boolean;
};

const InputOrSelectFloor = ({
  value,
  onChange,
  isCounterOffer,
}: InputOrSelectFloorProps) => {
  const handleChange = (event: EventType["change"]) => {
    const { target } = event;
    if (target.value.length <= 2) {
      target.value = cleanInputNumber(target.value);
      onChange(event);
    }
  };

  if (isCounterOffer) {
    return (
      <Input
        label={<LabelInputRequired str="Piso" />}
        name="floor"
        placeholder="Piso"
        value={value}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  return (
    <>
      <InputLabel id="Floor">
        <LabelInputRequired str="Piso" />
      </InputLabel>
      <Select
        labelId="Floor"
        id="Floor"
        name="floor"
        label="Piso *"
        placeholder="Seleccionar"
        value={value}
        onChange={onChange}
      >
        {[1, 2, 3, 4].map((item) => (
          <MenuItem value={item}>{item}</MenuItem>
        ))}
      </Select>
    </>
  );
};

const SelectsTypeProperties = (props: Props) => {
  const { form, listTypeProperty, onChange, isCounterOffer = false } = props;
  const [hiden, setHiden] = useState(true);

  const handleChange = ({
    target,
  }: SelectChangeEvent | EventType["change"]) => {
    const { value, name } = target;
    onChange({ name, value });

    const isHiden = name === "typeProperty" && Number(value) === ID_HOUSE;
    setHiden(isHiden);
  };

  return (
    <>
      <FormControl fullWidth sx={{ marginTop: "16px" }}>
        <InputLabel id="Tipo-de-inmueble">
          <LabelInputRequired str="Tipo de inmueble" />
        </InputLabel>
        <Select
          labelId="Tipo-de-inmueble"
          id="Tipo-de-inmueble"
          name="typeProperty"
          label="Tipo de inmueble *"
          value={form.typeProperty}
          onChange={handleChange}
        >
          {listTypeProperty.map(({ id, nombre }) => (
            <MenuItem value={`${id}`}>{nombre}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box paddingX="10px" display={hiden ? "none" : "block"}>
        <FormControl fullWidth sx={{ marginTop: "16px" }}>
          <InputOrSelectFloor
            value={form.floor}
            onChange={handleChange}
            isCounterOffer={isCounterOffer}
          />
        </FormControl>

        <FormControl fullWidth sx={{ marginTop: "16px" }}>
          <InputLabel id="ascensor">
            <LabelInputRequired str="Uso del ascensor" />
          </InputLabel>
          <Select
            labelId="ascensor"
            id="ascensor"
            placeholder="Seleccionar"
            name="ascensor"
            label="Uso del ascensor *"
            value={form.ascensor}
            onChange={handleChange}
          >
            {listDataAscensor.map((item) => (
              <MenuItem value={item.id}>{item.desciption}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default SelectsTypeProperties;
