import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import { maxAmauntLength } from "@/consts/noticeConsts";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import { useHandleCounterOffer } from "@/pages/noticeV2Negotation/shared/hooks/useHandleCounterOffer";
import {
  ButtonPeoplesV2,
  ButtonsCountV2,
  PeoplesContentV2,
} from "@/pages/noticeV2Negotation/shared/styles";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { Box, FormControl, Typography } from "@mui/material";

const styleMinusInput = {
  border: "1px solid #e0e0e0",
  backgroundColor: "#e0e0e0",
  color: "#a6a6a6",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
};

export const MovingNoticeDriver = () => {
  const { isLoadingNoticeV2, notice } = useGetData();
  const data = notice as NoticeV2NegType;
  const {
    handleOnChange,
    formMoving,
    setFormMonving,
    handleSubmit,
    errorPrice,
  } = useHandleCounterOffer();

  const handleDisabled = () => {
    let conditions = [formMoving.price];

    return conditions.some((condition) => !condition);
  };

  const handleMinusBtn = () => {
    formMoving.assistant > 1 &&
      setFormMonving({
        ...formMoving,
        assistant: formMoving.assistant - 1,
      });
  };

  const handlePlusBtn = () => {
    formMoving.assistant < maxAmauntLength &&
      setFormMonving({
        ...formMoving,
        assistant: formMoving.assistant + 1,
      });
  };

  const manageStylesAssistant = (valueCompare: number) => {
   return formMoving.assistant === valueCompare ? styleMinusInput : {}
  }

  return (
    <>
      <Box sx={{ display: "flex", mt: 2 }}>
        <Typography sx={{ fontWeight: 700 }}>Origen: </Typography>
        <Typography sx={{ ml: 1 }}>{data.origenTexto}</Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Typography sx={{ fontWeight: 700 }}>Destino: </Typography>
        <Typography sx={{ ml: 1 }}>{data.destinoTexto}</Typography>
      </Box>
      <PeoplesContentV2>
        <Typography variant="label2" color="GrayText">
          Ayudantes del servicio
        </Typography>
        <ButtonsCountV2>
          <ButtonPeoplesV2
            onClick={handleMinusBtn}
            disabled={formMoving.assistant === 0}
            sx={manageStylesAssistant(1)}
          >
            -
          </ButtonPeoplesV2>
          <Typography
            variant="label2"
            color="black"
            sx={{
              fontSize: "18px",
            }}
          >
            {formMoving.assistant}
          </Typography>
          <ButtonPeoplesV2
            onClick={handlePlusBtn}
            sx={manageStylesAssistant(maxAmauntLength)}
          >
            +
          </ButtonPeoplesV2>
        </ButtonsCountV2>
      </PeoplesContentV2>

      <Box sx={{ mt: 2 }}>
        <FormControl sx={{ marginY: "16px", width: "100%" }}>
          <Input
            id="standard-basic"
            name="price"
            label={<LabelInputRequired str="Precio por viaje (S/)" />}
            value={formMoving.price}
            variant="standard"
            placeholder={"Precio que cobras por viaje"}
            onChange={handleOnChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              required: true,
            }}
            error={!!errorPrice}
            helperText={errorPrice}
          />
        </FormControl>
      </Box>

      <ButtomLoader
        fullWidth
        variant="contained"
        isLoading={isLoadingNoticeV2}
        onClick={handleSubmit}
        disabled={handleDisabled() || isLoadingNoticeV2}
      >
        COTIZAR
      </ButtomLoader>
    </>
  );
};
