import { IFormContact } from "@/models/landing/contactFormApi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ListItems {
  id: string;
  nombre: string;
}

interface IContactForm {
  listMotivations: ListItems[] | [];
  listTypesUser: ListItems[] | [];
  contactForm: IFormContact | {};
  isLoading: boolean;
  error: any;
}

const initialState: IContactForm = {
  listMotivations: [],
  listTypesUser: [],
  contactForm: {},
  isLoading: false,
  error: null,
};

export const landingContactServicesSlice = createSlice({
  name: "landingContact",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
      state.isLoading = false;
    },
    setListTypesMotivations: (state, { payload }: PayloadAction<Array<any>>) => {
      state.listMotivations = payload;
      state.isLoading = false;
      state.error = null;
    },
    setListTypesUser: (state, { payload }: PayloadAction<Array<any>>) => {
      state.listTypesUser = payload;
      state.isLoading = false;
      state.error = null;
    },
    setContactForm: (state, { payload }: PayloadAction<Array<any>>) => {
      state.contactForm = payload;
      state.isLoading = false;
      state.error = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setError, setListTypesMotivations, setListTypesUser } = landingContactServicesSlice.actions;
