import BasicModal from "@/components/dialog/Notiffication";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import DatePicker from "@/components/form/datePicker.tsx/DatePicker";
import Input from "@/components/inputCustom/Input";
import { FORMAT_DATE_DDMMYYYY } from "@/consts/formatsDate";
import { districtsDeliveryText } from "@/consts/noticeConsts";
import { AddNewDestiny } from "@/pages/flowNoticeNegotation/components/AddNewDestiny";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { CardItem } from "@/pages/noticeV2/components/CardItem";
import { Box, FormControl, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useId } from "react";
import ModalMultidestiny from "./components/ModalMultidestiny";
import { InputDateText, MODAL_TYPE, SelectedDistrict } from "./configData";
import { useHandleFormRuteMultidestinyV2 } from "./hook/useHandleFormRuteMultidestinyV2";
import { ContainerV2 } from "./styles/formRuteMultidestinyStyles";

export type OpenModalType = { open: boolean; input: string };

const FormRuteMultidestinyV2 = () => {
  const id = useId();
  const {
    multiSelectDestiny,
    datePicker,
    setDatePicker,
    selectedCity,
    stateAccordion,
    searchList,
    currentModal,
    openModal,
    setOpenModal,
    city,
    handleDisabledButton,
    handleSubmit,
    handleChangeModal,
    handleSubmitModal,
    handleOnClickSearchInput,
    handleRemoveLocation,
    currentTypeModal,
  } = useHandleFormRuteMultidestinyV2();




  return (
    <ContainerV2 container>
      <ModalMultidestiny
        open={openModal}
        onClose={setOpenModal}
        onSubmit={handleSubmitModal}
        onChange={handleChangeModal}
        list={searchList}
        title={currentModal.title}
        inputProps={currentModal}
        stateAccordion={stateAccordion}
        nameTwo={currentTypeModal === MODAL_TYPE.city}
      />

      <form onSubmit={handleSubmit}>
        <DatePicker
          fullWidth
          initValue={datePicker}
          inputFormat={FORMAT_DATE_DDMMYYYY}
          label={<LabelInputRequired str={InputDateText.label}/>}
          onChange={setDatePicker}
          minDate={dayjs().add(1, "day")}
          maxDate={dayjs().add(1, "month")}
          renderInput={Input}
        />

        <FormControl sx={{ marginTop: "16px" }} fullWidth>
          <Input
            id={`${id}-city`}
            fullWidth
            name={city.input1.name}
            label={<LabelInputRequired str={city.input1.label} />}
            value={selectedCity?.nombre2 || selectedCity?.nombre}
            variant="standard"
            placeholder={city.input1.placeholder}
            onClick={() => handleOnClickSearchInput(MODAL_TYPE.city)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>

        <Typography variant="subtitle1" marginTop="16px" sx={{ mb: 2 }}>
          {districtsDeliveryText}
        </Typography>

        {!!multiSelectDestiny.length &&
          multiSelectDestiny.map((item) => (
            <Box onClick={()=> handleRemoveLocation(item as SelectedDistrict)}>
              <CardItem id={item.id} nombre={item.name} precio="" hideInput key={item.id} />
            </Box>
            
          ))}

        <Box
          onClick={() => {
            handleOnClickSearchInput(MODAL_TYPE.multiDestiny);
          }}
        >
          <AddNewDestiny title="Agregar distrito" />
        </Box>

        <Grid item sx={{ marginTop: "16px" }}>
          <ButtomLoader
            type="submit"
            buttomProps={{
              disabled: handleDisabledButton(),
            }}
          >
            SIGUIENTE
          </ButtomLoader>
        </Grid>
      </form>
      <BasicModal />
    </ContainerV2>
  );
};

export default FormRuteMultidestinyV2;
