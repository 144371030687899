import SkeletonDeclineMessage from "@/components/skeletons/SkeletonDeclineMessage";
import { BASE_URL_IMAGE } from "@/config/config";
import { actionsToNegotation } from "@/consts/actions";
import { DECLINE_OFFERT, ID_MOVING_FLOW } from "@/consts/defaultConsts";
import { NEGOTATION_CLIENT_PATH } from "@/consts/negotationFlowPath";
import { textRejectConfirmation } from "@/consts/noticeConsts";
import { REQUESTS_LANDING_PATH } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import {
  BoxButton,
  ButtonDecline,
  GridButton,
  GridImage,
  Text,
} from "@/pages/declineOffert/stylesDeclineOffer";
import { saveRequestNogotationByIdThunk } from "@/store/noticeNegotationRequestRedux/noticeNegRequesThunk";
import { getUrlWithoutLastPath } from "@/utils/helpers";
import { Box, Container, Grid } from "@mui/material";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IUser } from "../../../store/userRedux/userReduxInterface";
import { validIsClient } from "../utils/utilsNegotation";

const imageUrl = BASE_URL_IMAGE + DECLINE_OFFERT;

const declineAction: Record<string, any> = {
  oferta: {
    1: "",
    2: actionsToNegotation[14].id,
  },
  contraoferta: {
    1: actionsToNegotation[16].id,
    2: actionsToNegotation[15].id,
  },
};

const textOption: Record<string, any> = {
  oferta: {
    1: {
      1: "¡Haz retirado la oferta!",
      2: "¡Haz retirado la oferta!",
    },
    2: {
      1: "¡Haz retirado la oferta!",
      2: textRejectConfirmation,
    },
  },
  contraoferta: {
    1: {
      1: "¡Haz retirado la contraoferta!",
      2: textRejectConfirmation,
    },
    2: {
      1: "¡Haz retirado la contraoferta!",
      2: textRejectConfirmation,
    },
  },
};

const titleQuote = "¡Haz retirado la cotización!";

const userOption = {
  // cliente
  1: {
    text: "VER MIS SOLICITUDES",
    path: NEGOTATION_CLIENT_PATH,
  },
  // Transportista
  2: {
    text: "VER OTRAS SOLICITUDES",
    path: REQUESTS_LANDING_PATH,
  },
};

interface LocationState {
  isNavigationInFlow: boolean;
}

const DeclineNoticeRequest = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {
    user: { user },
    noticeNegotationRequest: { savedDataNogotation, isLoading },
  } = useAppSelector((state) => state);
  const clientId = savedDataNogotation?.solicitud.cliente.id as number;
  const isMoving =
    (savedDataNogotation?.solicitud?.tipoSolicitud?.id as number) ===
    ID_MOVING_FLOW;
  const { typeRequest = "", typeOffer = "" } = useParams();
  const typeUser = validIsClient(clientId, user as IUser) ? 1 : 2;
  const optionOffer =
    typeOffer === "co-contraoferta" ? "contraoferta" : typeOffer;
  const actionId = declineAction[optionOffer][typeUser];
  const body = { idAccion: actionId };
  const textToRender = textOption[optionOffer][typeUser];
  const pathToRedirect = userOption[typeUser];

  useEffect(() => {
    if ((location.state as LocationState)?.isNavigationInFlow) {
      dispatch(saveRequestNogotationByIdThunk(typeRequest, body));
    } else {
      const prevPath = getUrlWithoutLastPath(location.pathname);
      navigate(prevPath);
    }
    return () => {};
  }, []);

  if (isLoading) return <SkeletonDeclineMessage />;

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container spacing={2}>
        <GridImage xs={12}>
          <Box
            component="img"
            sx={{
              height: 353,
              width: 350,
            }}
            alt="Imagen de rechazo"
            src={imageUrl}
          />
          <Text sx={{ fontWeight: "bold" }}>
            {isMoving ? titleQuote : textToRender[1]}
          </Text>
          <Text>{textToRender[2]}</Text>
        </GridImage>
        <GridButton xs={12}>
          <BoxButton>
            <ButtonDecline
              fullWidth
              variant="text"
              onClick={() => {
                navigate(pathToRedirect.path, { replace: true });
              }}
            >
              {pathToRedirect.text}
            </ButtonDecline>
          </BoxButton>
        </GridButton>
      </Grid>
    </Container>
  );
};

export default DeclineNoticeRequest;
