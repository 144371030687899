import {
  agreementClosedText,
  optionOne,
  optionTwo,
} from '@/consts/noticeConsts';
import { CustomerListTypeRequestDTO } from '@/interfaces/customerRequestInterface';
import { ISolicitudViewDetailsResponse } from '@/models/customerRequest/requestDetailsVewResponse';
import { DataProfileDriverFormatReturn } from '@/pages/acceptanceOffert/adapters/AcceptanceAdapter';
import { ISolicitudNegociacion } from '@/services/interfaces/RequestNegResponseSuccess';
import { currencyFormat } from '@/utils/helpers';

const title = 'En la solicitud';
const yourOffer = 'Tu oferta';

export const changeTitleByNegRequest = (
  value: any,
  request: ISolicitudViewDetailsResponse
) => {
  const { flgIsOwner } = request;

  return flgIsOwner ? 'Contacta a tu transportista' : 'Contacta a tu cliente';
};

//   avatar request

export const dataProfileFormat = (
  savedOffert: ISolicitudViewDetailsResponse
): DataProfileDriverFormatReturn => {
  const client = savedOffert?.cliente;

  const dataProfile = {
    idUser: client?.id,
    userName: client?.nombre as string,
    urlProfile: client?.perfilUrl,
    rating: client?.calificacion,
    ratingText: client?.calificacionTexto || "",
    showPrice: false,
    showTwoAvatars: false,
    sx: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  return dataProfile;
};

export const dataDriverFormat = (
  savedOffert: ISolicitudViewDetailsResponse
): DataProfileDriverFormatReturn => {
  const driver = savedOffert.transportista;
  return {
    idUser: driver?.id,
    userName: driver?.nombre as string,
    urlProfile: driver?.perfilUrl,
    rating: driver?.calificacion,
    ratingText: driver?.calificacionTexto || "",
    showPrice: false,
    showTwoAvatars: false,
    sx: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
};


export const validUserNegRequest = (
  value: any,
  request: ISolicitudViewDetailsResponse
): boolean => {
  const { flgIsOwner } = request;
  return !!flgIsOwner;
};

export const getUrlAvatarProfileRequest = (
  value: any,
  request: ISolicitudViewDetailsResponse
) => {
  const isTransportista = validUserNegRequest(
    value,
    request
  );
  const { perfilUrl } = isTransportista
    ? request?.transportista
    : request?.cliente;
  return perfilUrl || '';
};

export const getImageTruckInRatingNegRequest = (
  value: any,
  request: ISolicitudViewDetailsResponse
) => {
  const isTransportista = validUserNegRequest(
    value,
    request
  );
  if (!isTransportista) return '';
  const { vehiculo } = request;

  return vehiculo.foto as string;
};

export const getUserInfoNegRequest = (
  value: any,
  request: ISolicitudViewDetailsResponse
) => {
  const isTransportista = validUserNegRequest(
    value,
    request
  );
  return isTransportista
    ? {
      ...request?.transportista,
      nombreempresa: request?.transportista?.nombreEmpresa || '',
    }
    : {
      ...request?.cliente,
      nombreempresa: request?.cliente?.nombreEmpresa || '',
    };
};

export const getNumberPhoneInRating = (
  value: any,
  request: ISolicitudViewDetailsResponse
) => {
  const isTransportista = validUserNegRequest(
    value,
    request
  );
  return isTransportista
    ? request?.transportista?.username
    : request?.cliente?.username;
};

export const getEmailInRating = (
  value: any,
  request: ISolicitudViewDetailsResponse
) => {
  const isTransportista = validUserNegRequest(
    value,
    request
  );
  return isTransportista
    ? request?.transportista?.email
    : request?.cliente?.email;
};

export const getWhatsappInRating = (
  value: any,
  request: ISolicitudViewDetailsResponse
) => {
  let whatsapp = request?.whatsapp;

  return whatsapp;
};

export const handleTypeMultiDestinyNegRequest = (
  data: ISolicitudNegociacion
) => {
  const newData = data.solicitud;
  const total = data.precioCalculadoContraOferta || data.precioCalculado;

  let result = {
    type: 'punto',
    amount: newData?.puntosRequeridos || 0,
    total: total,
  };

  if (newData?.flgHoraoPunto === optionOne) {
    result = {
      ...result,
      type: 'hora',
      amount: newData?.horasRequeridas || 0,
    };
  }

  return result;
};

export const handleTypeMultiDestinyNegRequestCounterOffert = (
  data: ISolicitudNegociacion
) => {
  const newData = data.solicitud;
  let result = {
    type: '',
    amount: '',
    priceType: 0,
    total: 0,
    flgIsOwner: data.flgIsOwner,
  };

  if (newData?.flgHoraoPunto === optionOne) {
    result = {
      type: 'hora',
      amount: (newData.horasRequeridas as number).toString(),
      priceType: data.precioContraoferta || data.precio,
      total:
        data.precioCalculadoContraOferta || (data?.precioCalculado as number),
      flgIsOwner: data.flgIsOwner,
    };
  } else if (newData?.flgHoraoPunto === optionTwo) {
    result = {
      type: 'punto',
      amount: (newData.puntosRequeridos as number).toString(),
      priceType: data.precioContraoferta || data.precio,
      total:
        data.precioCalculadoContraOferta || (data?.precioCalculado as number),
      flgIsOwner: data.flgIsOwner,
    };
  }

  return result;
};

type NoticeDataType = {
  type: string;
  amount: string;
  priceType: number;
  total: number;
};

export const handleDataToRenderNegRequest = ({
  noticeData,
  data,
}: {
  noticeData: NoticeDataType;
  data: ISolicitudNegociacion;
}) => {
  const newData = data.solicitud;
  const { precioCalculado } = newData;

  const typeMulti =
    noticeData.type.charAt(0).toUpperCase() + noticeData.type.slice(1);

  const subtitle = handleSubtitleText(newData);

  return [
    {
      columnTitleSx: { fontSize: '12px' },
      clumnSubtitleSx: { fontSize: '14px', color: '#757575' },
      title: {
        name: title,
        sx: { color: '#757575', fontSize: '14px' },
      },
      columnOne: {
        title: `Precio x ${noticeData.type}`,
        subtitle: `${currencyFormat(newData.precio)}`,
      },
      columnTwo: {
        title: `# ${typeMulti}s`,
        subtitle: subtitle,
      },
      columnThree: {
        title: `Precio total`,
        subtitle: `${currencyFormat(precioCalculado as number)}`,
      },
    },
    {
      columnTitleSx: { fontSize: '12px' },
      clumnSubtitleSx: { fontSize: '14px', fontWeight: 700 },
      title: {
        name: !!data.flgIsOwner ? agreementClosedText : yourOffer,
        sx: { fontWeight: 600 },
      },
      columnOne: {
        title: `Precio x ${noticeData.type}`,
        subtitle: `${currencyFormat(noticeData.priceType)}`,
      },
      columnTwo: {
        title: `# ${typeMulti}s`,
        subtitle: `${noticeData.amount}`,
      },
      columnThree: {
        title: `Precio total`,
        subtitle: `${currencyFormat(noticeData.total)}`,
      },
    },
  ];
};

export const handleSubtitleText = (newData: ISolicitudViewDetailsResponse) => {
  if (!!newData.puntosRequeridos) return newData.puntosRequeridos;

  return newData.horasRequeridas;
};

export const getFormatMetroNegRequest = (
  value: any,
  application: ISolicitudViewDetailsResponse | any
) => {
  const { metraje } = application;
  if (!metraje) return '';

  return `${metraje} m².`;
};

export const renderTitleAmbienteNegRequest = (
  value: any,
  application: ISolicitudViewDetailsResponse | any
) => {
  const { metraje, tipoInmueble } = application;
  // En departamento de 90 m².
  return `En ${tipoInmueble?.nombre?.toLowerCase()} de ${metraje} m².`;
};

// moving

export const renderTitleLongOriginNegRequest = (
  value: any,
  application: ISolicitudViewDetailsResponse | any
) => {
  const {
    tipoInmueble,
    pisoOrigen,
    flgAscensor,
    origenTexto2,
    origenTexto,
    referencia,
  } = application;
  let msgAscensor = "ascensor disponible para algunas cosas"
  let msgType = tipoInmueble;

  if (typeof msgType === 'string') {
    msgType = msgType;
  } else {
    msgType = msgType?.nombre || '';
  }


  // return desc;
  if (flgAscensor === optionOne) {
    msgAscensor = 'con ascensor'
  } else if (flgAscensor === optionTwo) {
    msgAscensor = 'sin ascensor'
  }

  if (flgAscensor) {
    msgType = `${msgType} en piso ${pisoOrigen} (${msgAscensor})`
  }
  // convertir en html y punto
  const referenciaText = referencia ? `(${referencia})` : '';

  const desc = `
  • ${origenTexto2 || origenTexto} ${referenciaText}</br>
  • ${msgType}</br>
  `

  return desc
};

export const renderTitleLongDestinyNegRequest = (
  value: any,
  application: ISolicitudViewDetailsResponse | any
) => {
  const {
    tipoInmuebleDestino,
    pisoDestino,
    flgAscensorDestino,
    destinoTexto2,
    destinoTexto,
    referenciaDestino,
  } = application;
  let msgAscensor = "ascensor disponible para algunas cosas"


  let msgType = tipoInmuebleDestino;
  
  if (typeof msgType === 'string') {
    msgType = msgType;
  } else {
    msgType = msgType?.nombre || '';
  }

  if (flgAscensorDestino === optionOne) {
    msgAscensor = 'con ascensor'
  } else if (flgAscensorDestino === optionTwo) {
    msgAscensor = 'sin ascensor'
  }

  if (flgAscensorDestino) {
    msgType = `${msgType} en piso ${pisoDestino} (${msgAscensor})`
  }

  // convertir en html y punto
  const referenciaText = referenciaDestino ? `(${referenciaDestino})` : '';
  const desc = `
  • ${destinoTexto2 || destinoTexto} ${referenciaText}</br>
  • ${msgType}</br>
  `
  return desc
};

export const getImagesNegRequest = (
  value: any,
  application: ISolicitudViewDetailsResponse
) => {
  const { fotos } = application;

  if (fotos.length === 0) return [];

  let photos = fotos.map((item: string, i: number) => ({
    id: i + 1,
    urlImagen: item,
  }));

  return photos;
};
