import { secondFontFamily } from "@/styles/theme";
import { Box, styled, SxProps, Theme, Typography } from "@mui/material";
import { FC } from "react";

export const SpanRequired = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.error.main};
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
    padding-left: 5px;
  `
);

export const TextStyles = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.error.main};
    font-family: ${secondFontFamily};
    font-size: 16px;
    font-weight: 600;
    line-height: inherit;
    color: rgba(0, 0, 0, 0.6);
  `
);

type Props = {
  str: string;
  color?: string;
  fontSize?: string;
  sx?: any;
  disabled?: boolean;
  required?: boolean;
  containerSx?: SxProps<Theme>;
};

const LabelRequired: FC<Props> = ({
  str,
  color,
  sx,
  fontSize,
  disabled,
  containerSx,
  required = true,
}) => {
  return (
    <Box sx={{ display: "flex", ...containerSx }}>
      <span style={sx && { ...sx, color: disabled && "#DBDBDB" }}>{str}</span>
      {required && (
        <SpanRequired
          sx={{ color: color && color, fontSize: fontSize && fontSize }}
          variant="overline"
        >
          *
        </SpanRequired>
      )}
    </Box>
  );
};

export default LabelRequired;
