import LabelRequired from "@/components/utils/LabelRequired";
import { PROFILE_MIS_VEHICULOS_PATH } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { DetailsCoreModel } from "@/models/notice/noticeModels";
import { ISelectedVehicle } from "@/store/userRedux/userReduxInterface";
import { setSelectedVehicle } from "@/store/userRedux/userSlice";
import { postVehiclesByUserThunk } from "@/store/userRedux/userThunks";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useId, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddNewCarBox } from "./styleSelectControl";

type Props = {
  showMaxLoad?: boolean;
  onChange?: (selected: {} | ISelectedVehicle) => void;
  data?: DetailsCoreModel;
};

export const SelectControl = (props: Props) => {
  const { showMaxLoad = false, onChange, data } = props;
  const [select, setselect] = useState("");
  const [isfirstRender, setIsfirstRender] = useState(true);
  const selectId = useId();

  const { vehicles, selectedVehicle } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const currentSelectedVehicle = selectedVehicle as ISelectedVehicle
  const newData = data as DetailsCoreModel

  useEffect(() => {
    handleInitLoad();
  }, []);

  useEffect(() => {
    const currentVehicle = newData?.vehiculo;
    if (!!currentVehicle?.id) {
      setselect(currentVehicle.id.toString());
    } else {
      setselect(currentSelectedVehicle?.id?.toString() || "");
    }
  }, []);

  useEffect(() => {
    const isEdition = !!newData?.vehiculo?.id;
    if (!isEdition) {
      handleIsEdition();
    }
  }, [selectedVehicle]);

  const handleIsEdition = () => {
    setselect(currentSelectedVehicle?.id?.toString() || "");

    if (!isfirstRender) {
      onChange && onChange(selectedVehicle);
    }
  };

  const handleInitLoad = () => {
    dispatch(
      postVehiclesByUserThunk({
        paginate: false,
      })
    )
      .then((res) => {
        const validate = res?.some(
          (vehi: any) => vehi?.id === currentSelectedVehicle?.id
        );
        let currentSelect = selectedVehicle;
        if (res?.length === 0) {
          currentSelect = {};
        }

        if (!validate && res?.length > 0) {
          currentSelect = res[0];
        }

        currentSelect = !!Object.keys(selectedVehicle).length
          ? selectedVehicle
          : res[0];

        dispatch(setSelectedVehicle(currentSelect || {}));
        setIsfirstRender(false);
      })
      .catch(() => setIsfirstRender(false));
  };

  const handleSelectChange = ({ target }: SelectChangeEvent) => {
    const { value } = target;
    const selected = handleGetSelectedVehicle(value);
    setselect(value as string);

    dispatch(setSelectedVehicle(selected));
  };

  const handleGetSelectedVehicle = (
    value: string
  ): ISelectedVehicle | Object => {
    const selectedVehicle = vehicles.find(
      (vehicle) => vehicle?.id === Number(value)
    );

    return selectedVehicle || {};
  };

  return (
    <FormControl>
      <InputLabel id={selectId}>
        <LabelRequired str="Tu vehículo" />
      </InputLabel>
      <Select
        labelId={selectId}
        value={select}
        onChange={handleSelectChange}
        name="vehicle"
        input={<OutlinedInput label="Tu vehículo d" />}
      >
        {vehicles.map(({ id, placa, tipovehiculo, cargamaxima }) => (
          <MenuItem value={id} key={`${id}-MenuItem`} sx={{ height: "45px" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {tipovehiculo?.nombre} {!!placa && `(${placa})`}
              {!!showMaxLoad && <MaxLoadItem maxLoad={cargamaxima} />}
            </Box>
          </MenuItem>
        ))}
        <AddNewVehicle />
      </Select>
    </FormControl>
  );
};

export const MaxLoadItem = ({ maxLoad }: { maxLoad?: number }) => {
  const formatter = new Intl.NumberFormat();

  if (!maxLoad) return <></>;

  return (
    <>
      <br />
      <Typography
        variant="caption"
        sx={{
          color: "borderGray.contrastText",
        }}
      >
        Capacidad máxima de {formatter.format(maxLoad)} kilos
      </Typography>
    </>
  );
};

export const AddNewVehicle = () => {
  const navigate = useNavigate();

  const hangleClick = () => {
    navigate(PROFILE_MIS_VEHICULOS_PATH);
  };

  return (
    <AddNewCarBox onClick={hangleClick}>
      <AddCircleIcon />
      <Typography sx={{ pl: 1 }} variant="subtitle1">
        Agregar nuevo vehiculo
      </Typography>
    </AddNewCarBox>
  );
};
