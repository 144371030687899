import { theme } from "@/styles/theme";
import {
  Container,
  Grid,
  Stack,
  experimental_sx as sx,
  styled,
} from "@mui/material";
import { Link } from "react-router-dom";

export const ContainerFooter = styled(Container)(
  sx({
    backgroundColor: "#382B27", //theme.palette.secondary.main,
    position: "relative",
    bottom: 0,
  })
);

export const ContainerUpper = styled(Grid)(
  sx({
    width: "100%",
    maxWidth: "1152px",
    marginLeft: "auto",
    marginRight: "auto",
    padding: {
      xs: "64px 16px 32px",
      sm: "64px 24px 32px",
      md: "64px 64px 32px",
    },
    gap: 0,
    justifyContent: {
      xs: "center",
      sm: "center",
      md: "center",
      lg: "space-between",
    },
  })
);

export const ContainerSocial = styled(Stack)(() => ({
  gap: 0,
  justifyContent: "flex-start",
  flexDirection: "row",
  flexWrap: "nowrap",
  "@media only screen and (max-width: 899px)": {
    justifyContent: "center",
  },
}));

export const CustomLink = styled("a")(
  sx({
    width: "100%",
    fontFamily: {
      xs: theme.typography.label1.fontFamily,
      sm: theme.typography.label1.fontFamily,
      md: theme.typography.label1.fontFamily,
      lg: theme.typography.body1.fontFamily,
    },
    fontWeight: "300",
    fontSize: theme.typography.label1.fontSize,
    lineHeight: theme.typography.label1.lineHeight,
    color: theme.palette.common.white,
    display: "block",
    textDecoration: "none",
    marginTop: "16px",
    textAlign: { xs: "center", sm: "center", md: "center", lg: "start" },
  })
);

export const InternalLink = styled(Link)(
  sx({
    width: "100%",
    fontFamily: theme.typography.body1.fontFamily,
    fontWeight: theme.typography.label1.fontWeight,
    fontSize: theme.typography.label1.fontSize,
    lineHeight: theme.typography.label1.lineHeight,
    color: theme.palette.common.white,
    display: "block",
    textDecoration: "none",
    marginTop: "16px",
    textAlign: { xs: "center", sm: "center", md: "center", lg: "start" },
  })
);

export const ContainerBrand = styled("div")(
  sx({
    marginLeft: { xs: "auto", sm: "auto", md: "auto", lg: "auto" },
    marginRight: { xs: "auto", sm: "auto", md: "auto", lg: "auto" },
    marginBottom: { xs: "16px", sm: "16px", md: "36px", lg: "36px" },
    justifyContent: {
      xs: "center",
      sm: "center",
      md: "center",
      lg: "flex-start",
    },
    display: "flex",
    alignItems: "center",
    width: "180px",
    height: "72px",

  })
);

export const socialMediaMobileSx = {
  display: { xs: "flex", sm: "flex", md: "flex", lg: "none" },
  mt: "32px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};
