import { ContainerList } from "../../noticeList/styleNoticeList";
import List, { ListPropsCustomerOffert } from "./List";

const ListChildren = ({ requestNegList, isLoading, linkFooter, showState }: ListPropsCustomerOffert) => {
  if (!requestNegList?.length) {
    return (
      <List
        requestNegList={requestNegList}
        isLoading={isLoading}
        linkFooter={linkFooter}
        showState={!!showState}
      />
    );
  }

  return (
    <ContainerList>
      <List
        requestNegList={requestNegList}
        isLoading={isLoading}
        linkFooter={linkFooter}
        showState={!!showState}
      />
    </ContainerList>
  );
};

export default ListChildren;
