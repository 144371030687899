import { IconButton } from "@mui/material";
import { ContainerSocial } from "../stylesFooter";
import { ConfigData } from "./condigData";

function MediaList({ list }: { list: ConfigData[] }) {
  return (
    <ContainerSocial>
      {list.map((item) => (
        <IconButton
          key={`IconButton-${item.href}`}
          href={item.href}
          target={item.target}
          style={item.style}
        >
          <i className={item.icon} />
        </IconButton>
      ))}
    </ContainerSocial>
  );
}

export default MediaList;
