import React from 'react'
import { ContentSection, SectionSubTitle, SectionTitle } from './style';
import { SxProps, Theme } from '@mui/material';


interface InfoLandProps {
    icon?: string;
    title: string;
    subtitle: string | JSX.Element;
    sx?: SxProps<Theme>;
    subtitle2?: string;
    button?: JSX.Element;
    height?: any;
    children?: React.ReactNode;
    gap?: string | number;
  }

const CustomInfoLand = ({
    icon,
    sx,
    children,
    title,
    button,
    subtitle,
    subtitle2,
    height = "",
    gap,
  }: InfoLandProps) => {
  return (
    <ContentSection sx={{ height: height, ...sx, gap  }}>
      {icon && <i className={icon} />}
      <SectionTitle>{title}</SectionTitle>
      {button && button}
      {subtitle && <SectionSubTitle>{subtitle}</SectionSubTitle>}
      {subtitle2 && <SectionSubTitle>{subtitle2}</SectionSubTitle>}
      {children && children}
    </ContentSection>
  )
}

export default CustomInfoLand