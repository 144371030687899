import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { StyleCustomInput } from "@/components/inputCustom/InputCustom";
import LabelRequired from "@/components/utils/LabelRequired";
import { ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import {
  districtsDeliveryText,
  textAceptarContactar,
  withoutPriceDefault,
} from "@/consts/noticeConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { BodyOfferMultiDestinationDTO } from "@/interfaces/offerInterface";
import { NoticeDetailMultiDestinyModel } from "@/models/notice/noticeModels";
import {
  flagPoint,
  maxHours,
  maxPoints,
  textHours,
  textPoint,
} from "@/pages/flowNoticeNegotation/shared/const/offertConst";
import { buidTextQuantityError } from "@/pages/flowNoticeNegotation/shared/utils/utilsMultidestiny";
import { saveOfferMultiDestinyThunk } from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferThunk";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { cleanValueNumber, currencyFormat } from "@/utils/helpers";
import { Box, FormControl, Typography } from "@mui/material";
import React, { useId, useState } from "react";
import { useNavigate } from "react-router-dom";

const PriceMultidestiny = () => {
  const { selectTypeNotice, idTypeNoticeNegotiation, isLoading } =
    useAppSelector((state) => state.noticeV2ClientAorCO);
  const notice = selectTypeNotice as NoticeDetailMultiDestinyModel;
  const price = notice?.precioxpunto || notice?.precioxhora;
  const isContraoferta = idTypeNoticeNegotiation === ID_MULTI_DESTINY;
  const { destinos } = selectTypeNotice as NoticeDetailMultiDestinyModel;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [form, setForm] = useState({
    offertPrice: "",
    pointsOrHours: "",
  });

  const [errorQuantity, setErrorQuantity] = useState("");
  const [errorApi, setErrorApi] = useState("");

  const id = useId();

  const handleChange = ({ target }: EventType["change"]) => {
    const { name, value } = target;
    const cleanValue = cleanValueNumber(value);

    setForm({ ...form, [name]: cleanValue });
  };

  const showsTextByTypeAviso = () => {
    return isContraoferta
      ? {
          button: "CONTRAOFERTAR",
          showInput: true,
        }
      : {
          button: textAceptarContactar,
          showInput: false,
        };
  };

  const textByTypePrice = () => {
    return notice.precioxpunto
      ? {
          price: `<strong>Precio por puntos (S/):</strong> ${notice.precioxpunto}`,
          input: "Precio por punto que ofreces",
          placeholder: "Ingresa un precio por punto",
          input2: "Cantidad de puntos",
          placeholder2: "Cantidad de paradas o lugares de entrega",
        }
      : {
          price: `<strong>Precio por hora (S/):</strong> ${notice.precioxhora}`,
          input: "Precio por hora que ofreces",
          placeholder: "Ingresa un precio por hora",
          input2: "Cantidad de horas",
          placeholder2: "Indica el mínimo de horas para tu servicio",
        };
  };

  const changePriceTheService = () => {
    const priceTotal1 = Number(price) * Number(form.pointsOrHours);
    const priceTotal2 = Number(form.offertPrice) * Number(form.pointsOrHours);
    let precio = 0;
    if (!isContraoferta && price && form.pointsOrHours) {
      precio = priceTotal1;
    }

    if (isContraoferta && form.offertPrice && form.pointsOrHours) {
      precio = priceTotal2;
    }

    return `${currencyFormat(precio)}` || withoutPriceDefault;
  };

  const validateButtonSubmit = () => {
    if (!isContraoferta && form.pointsOrHours) {
      return false;
    }

    if (isContraoferta && form.offertPrice && form.pointsOrHours) {
      return false;
    }

    return true;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorApi("");
    const hasError = handleValidFields();
    if (hasError) return;

    const typeNotice = notice.precioxpunto ? "puntos" : "horas";
    let body: BodyOfferMultiDestinationDTO = {
      [typeNotice]: Number(form.pointsOrHours),
    };

    if (isContraoferta) {
      body = {
        ...body,
        precio: Number(form.offertPrice),
      };
    }

    dispatch(saveOfferMultiDestinyThunk(body))
      .then(() => {
        dispatch(
          getNextRoute(navigate, {
            valueToReplace: notice.id?.toString(),
            valueToSearch: ":id",
          })
        );
      })
      .catch((e: Error) => {
        setErrorApi(e.message);
      });
  };

  const handleValidFields = () => {
    const hasErrorQuantity = handleValidErrorQuantity();

    return hasErrorQuantity;
  };

  const handleValidErrorQuantity = () => {
    const {
      minimumQuantity = 0,
      messageErrorQuantity,
      maxQuantity,
    } = handleGetVariablesErrorQuantity();
    const pointsOrHours = Number(form.pointsOrHours);
    const hasErrorMinQuantity = pointsOrHours < minimumQuantity;
    const hasErrorMaxQuantity = pointsOrHours > maxQuantity;

    if (hasErrorMinQuantity || hasErrorMaxQuantity) {
      setErrorQuantity(messageErrorQuantity);
    } else if (errorQuantity) {
      setErrorQuantity("");
    }

    return hasErrorMinQuantity || hasErrorMaxQuantity;
  };

  const handleGetVariablesErrorQuantity = () => {
    const isPoint = notice.flghoraopunto === flagPoint;
    const typeFLow = isPoint ? textPoint : textHours;

    let maxQuantity = maxHours;
    let minimumQuantity = notice.horasminimo || 0;
    if (isPoint) {
      maxQuantity = maxPoints;
      minimumQuantity = notice.puntosminimo || 0;
    }
    const messageErrorQuantity = buidTextQuantityError({
      typeFLow,
      max: maxQuantity,
      min: minimumQuantity,
    });

    return {
      minimumQuantity,
      maxQuantity,
      messageErrorQuantity,
    };
  };

  return (
    <>
      <StandardAlert
        severity="error"
        color="error"
        sx={{ marginY: "16px", display: errorApi ? "flex" : "none" }}
      >
        {errorApi}
      </StandardAlert>

      <Typography variant="body2" color="black">
        <strong>{districtsDeliveryText}:</strong>{" "}
        {destinos
          .filter((destiny) => destiny.selected)
          .map((destino) => destino.nombre)
          .join(", ")
          .replace(/,(?!.*,)/gim, " y") || "-"}
      </Typography>
      <Typography variant="body2" color="black">
        <div dangerouslySetInnerHTML={{ __html: textByTypePrice().price }} />
      </Typography>
      <form onSubmit={handleSubmit} autoComplete="off">
        <FormControl sx={{ marginTop: "16px", width: "100%" }}>
          <StyleCustomInput
            id={`${id}-destiny`}
            name="pointsOrHours"
            label={<LabelRequired str={textByTypePrice().input2} />}
            error={!!errorQuantity.length}
            value={form.pointsOrHours}
            variant="outlined"
            placeholder={textByTypePrice().placeholder2}
            onChange={handleChange}
            onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
              minLength: 1,
              maxLength: 4,
              required: true,
            }}
            helperText={errorQuantity}
          />
        </FormControl>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="body2"
            color="black"
            sx={{
              marginTop: "16px",
              marginBottom: "16px",
              fontWeight: "bold",
            }}
          >
            Precio del servicio:
          </Typography>

          <Typography
            color="black"
            sx={{
              marginTop: "16px",
              marginBottom: "16px",
              fontWeight: "none",
            }}
          >
            &nbsp; {changePriceTheService()}
          </Typography>
        </Box>
        <ButtomLoader
          type="submit"
          disabled={validateButtonSubmit()}
          isLoading={isLoading}
        >
          {showsTextByTypeAviso().button}
        </ButtomLoader>
      </form>
    </>
  );
};

export default PriceMultidestiny;
