import { Container, Grid } from '@mui/material'
import React from 'react'
import useFinishedLayoutHook from './hook'
import ButtomLoader from '@/components/form/buttonLoader/ButtomLoader';
import { SolicitudNegociacion } from '@/models/noticeNegotation/noticeNegotationModel';
import { ISolicitudNegociacion } from '@/services/interfaces/RequestNegResponseSuccess';
import PricesNegAccept from '../Prices';
import ArrowBack from '@/pages/viewService/city/components/ArrowBack';
import ButtonRedirectRating from '@/components/buttonRedirectRating/ButtonRedirectRating';
interface ILayoutProps {
    children: React.ReactNode;
    request: ISolicitudNegociacion;
}
const Layout = ({ children, request }: ILayoutProps) => {
    const {
        matches,
        navigateNegociations,
        hasBackNavigate,
        handleNavigationBack,
        showBtnCalification,
    } = useFinishedLayoutHook();
    
    return (
        <Container maxWidth={matches ? "xl" : "xs"} sx={{
            mt: "16px",
            marginBottom: matches ? 0 : 4,
        }}>
            <ArrowBack show={hasBackNavigate} onClick={handleNavigationBack} />
            <Grid
                container
                spacing={0}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    pl: matches ? 20 : 0,
                    pr: matches ? 20 : 0,
                }}
            >
                <Grid xs={matches ? 7 : 12}>{children}</Grid>

                <Grid xs={matches ? 5 : 12} sx={{
                    padding:
                        matches ?
                            "0px 16px 0px 16px"
                            :
                            "16px 0px"
                }}>
                    {request && <PricesNegAccept request={request} />}
                    {
                        showBtnCalification ?
                            <ButtonRedirectRating isOwner={request?.flgIsOwner} />
                            :
                            <ButtomLoader
                                fullWidth
                                variant="outlined"
                                onClick={() => navigateNegociations()}
                                sx={{
                                    mt: 5,
                                }}
                            >
                                VER NEGOCIACIONES
                            </ButtomLoader>
                    }
                </Grid>
            </Grid>
        </Container>
    )
}

export default Layout
