import useObserver from "@/hooks/useObserver";
import { useEffect, useRef } from "react";
import { ContainerMyNoticeList } from "../../noticeList/styleNoticeList";
import List, { ListProps } from "./List";

interface Props extends ListProps {
  paginateCallback?: () => void;
}
const ListChildren = ({ noticeList, isLoading, paginateCallback }: Props) => {
  const refObserver = useRef<any>(undefined);
  const firstRender = useRef<boolean>(true);
  const { isNearScreen } = useObserver({
    distance: "100px",
    externalRef: refObserver,
  });

  useEffect(() => {
    handlePaginateCallback();

    if (firstRender.current) {
      firstRender.current = false;
    }
    return () => {};
  }, [isNearScreen]);

  const handlePaginateCallback = () => {
    const rules = [
      !firstRender.current,
      !!noticeList.length,
      !isLoading,
      isNearScreen,
    ];

    if (rules.some((rule) => !rule)) return;

    paginateCallback && paginateCallback();
  };

  return (
    <ContainerMyNoticeList container>
      <List noticeList={noticeList} isLoading={isLoading} />
      <div ref={refObserver} />
    </ContainerMyNoticeList>
  );
};

export default ListChildren;
