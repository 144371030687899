import { theme } from '@/styles/theme';
import { experimental_sx as sx, styled, Typography } from '@mui/material';

export const ContentSectionCardList = styled('div')(
  sx({
    display: 'flex',
    width: '100%',
    maxWidth: '1152px',
    height: { xs: '230px', sm: '300px', md: '135px', lg: '80px', xl: '88px' },
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
  })
);

export const MatListContent = styled('div')(
  sx({
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: { xs: 'column', md: 'row' },
    gap: '16px',
    width: '100%',
  })
);

export const MatListItem = styled('div')(
  sx({
    display: 'flex',
    alignItems: 'center',
    flexDirection: { xs: 'row', md: 'column' },
    justifyContent: { md: 'center' },
    minHeight: { md: '244px', lg: '224px', xl: '204px' },
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '24px',
    padding: '16px',
    gap: '8px',
    width: '100%',
    height: { xs: '70px', md: '100%' },
    border: '2px solid transparent',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      cursor: 'pointer',
      border: { md: '2px solid #3F90E6' },
    },
    '&.active': {
      border: { md: '2px solid #3F90E6' },
    },
  })
);

export const MatListItemImg = styled('div')(
  sx({
    display: { md: 'flex' },
    width: { md: '100%' },
    alignItems: { md: 'center' },
    justifyContent: { md: 'center' },
  })
);

export const MatListItemContent = styled('div')(
  sx({
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: '2px', md: '8px' },
    width: '100%',
    alignItems: { md: 'center' },
    textAlign: { md: 'center' },
    height: { md: '100%' },
  })
);

export const MatListItemTitle = styled(Typography)(
  sx({
    minHeight: { xs: '24px', md: '49px' },
    fontFamily: {
      xs: theme.typography.label1.fontFamily,
      md: theme.typography.label1.fontFamily,
    },
    fontSize: {
      xs: '15px',
      md: theme.typography.label1.fontSize,
    },
    lineHeight: {
      xs: theme.typography.body1.lineHeight,
      md: theme.typography.label1.lineHeight,
    },
  })
);

export const MatListItemSubTitle = styled(Typography)(
  sx({
    fontFamily: theme.typography.body2.fontFamily,
    fontWeight: theme.typography.body2.fontWeight,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    color: theme.palette.text.secondary,
  })
);
