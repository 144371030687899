import { FormLoadEditBetweenCities } from './formLoadEditBetweenDestiny/FormLoadEditBetweenDestiny';
import { FormLoadEditInCity } from './formLoadEditInCity/FormLoadEditInCity';
import FormOriginEditMoving from './formLoadEditMoving/FormLoadEditMoving';
import { FormLoadEditMultidestiny } from './formLoadEditMultidestiny/FormLoadEditMultidestiny';

export const inputWeight = {
  label: 'Peso estimado (kilos)',
  placeholder: 'Ingresa el peso estimado de tu carga',
  name: 'pesoEstimado',
  type: 'number',
  autoComplete: 'off',
  fullWidth: true,
  InputLabelProps: {
    shrink: true,
  },
};

export const inputDescriptionLoad = {
  label: 'Descripción de la carga',
  placeholder:
    'Describe tu carga. Puedes indicar el alto y ancho, si es frágil, etc.',
  name: 'descriptionLoad',
  autoComplete: 'off',
  fullWidth: true,
  minRows: 3,
  maxRows: 3,
  multiline: true,
  InputLabelProps: {
    shrink: true,
  },
};

export const inputTypevehicle = {
  label: 'Tipo de vehículo',
  placeholder: 'Ingresa el tipo de vehículo',
  name: 'vehicle',
  autoComplete: 'off',
  fullWidth: true,
  InputLabelProps: {
    shrink: true,
  },
};

export const objectRenderComponent: Record<number, () => JSX.Element> = {
  1: FormLoadEditInCity,
  2: FormLoadEditMultidestiny,
  3: FormLoadEditBetweenCities,
  5: FormOriginEditMoving,
};
