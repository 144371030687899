import { EmptyNegotationProfileTransport } from "@/assets/svg";
import CardProfile, { TypeCard } from "@/components/cardProfile/CardProfile";
import EmptyListData from "@/components/emptyListData/EmptyListData";
import SkeletonInfoCardList from "@/components/skeletons/SkeletonInfoCardList";
import { agreementClosedText  } from "@/consts/noticeConsts";

import useObserver from "@/hooks/useObserver";
import {
  NoticeBussines,
  NoticesBussinesV2,
} from "@/models/notice/noticeListOffersModels";
import { deleteDomainUrl } from "@/utils/helpers";
import { Grid } from "@mui/material";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

export type ListProps = {
  noticeList: NoticesBussinesV2[];
  isLoading: boolean;
  linkFooter?: string;
  onLoadMore?: () => void;
  typeCard?: TypeCard;
};
const List = ({
  noticeList,
  isLoading,
  onLoadMore,
  typeCard = TypeCard.open,
}: ListProps) => {
  const navigate = useNavigate();

  const refObserver = useRef<any>(null);
  const { isNearScreen } = useObserver({
    distance: "100px",
    externalRef: refObserver,
  });

  useEffect(() => {
    if (!isLoading && noticeList.length && onLoadMore) {
      onLoadMore();
    }
  }, [isNearScreen]);

  const handleNavigate = (link: string, data: NoticesBussinesV2) => {
    navigate(deleteDomainUrl(link) || "", {
      state: { 
        hasBackNavigate: true, 
        showCalification: showRatingButton(data) 
      },
    });
  };

  if (!noticeList.length) return <EmptyListData title="No tienes negociaciones" 
  render={<EmptyNegotationProfileTransport/>}
  optionTwo
/>;

  const showRatingButton = (data: NoticesBussinesV2) => {
    const {
      estado
    } = data;
    return estado === agreementClosedText;
  }

  return (
    <>
      {noticeList && (
        <>
          {noticeList.map((data, index) => (
            <Grid item xs={12} sm={12} md={5.5} lg={3.5} key={index}>
              <CardProfile
                typeCard={typeCard}
                negotiation={data}
                onClickButton={(link)=>{
                  handleNavigate(link, data);
                }}
              />
            </Grid>
          ))}
          {isLoading && <SkeletonInfoCardList />}

          {!isLoading && <div ref={refObserver} />}
        </>
      )}
    </>
  );
};

export default List;
