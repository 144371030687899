import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { CREATE_CUSTOMER_REQUEST_PATH, MY_APPLICATIONS_PATH } from "@/pages/customerRequest/shared/consts/routes";
import { useAppSelector } from "@/hooks/useReducer";
import { createRequestSuccessDataLayer } from "@/services/dataLayer/createRequest";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { Container, Grid, Stack, styled, Typography } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { customerRequestObjectTypes } from "../../shared/consts/customerRequestConsts";
import { SolicitudCreadaData } from "@/models/customerRequest/customerRequestResponse";
import Icon from "@/utils/Icon";
import { ContainerInfo, TitleInfoDescription } from "./SuccessInCity/style";

const ContainerHeader = styled(Stack)(
  () => `
    
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    margin-bottom: 16px;
  `
);

const Success = () => {
  const navigate = useNavigate();

  const { customerRequest: { echoResponse, selectedTypeRequest: {
    id: idSelectTypeRequest
  } }, user: { user } } = useAppSelector((state) => state);

  const {
    titulo,

    solicitudCreada: {
      id: idSolicitud = 0,
      fValidez
    }
  } = echoResponse as SolicitudCreadaData;

  useEffect(() => {
    createRequestSuccessDataLayer((user as IUser).id, idSelectTypeRequest)
  }, [])


  const handleViewApplication = () => {
    const valueToReplace = customerRequestObjectTypes[idSelectTypeRequest];
    const url = MY_APPLICATIONS_PATH.replace(":id", idSolicitud?.toString() || "");
    navigate(url.replace(":typeRequest", valueToReplace), { replace: true });
  };

  const handleNavigateCreateRequest = () => {
    navigate(CREATE_CUSTOMER_REQUEST_PATH, { replace: true })
  }

  return (
    <Container maxWidth="xs" sx={{ marginTop: "16px" }}>
      <ContainerHeader>
        <Stack>
          <Icon name="icon-success" size="xl" color="success" />
        </Stack>
        <Stack marginTop="16px">
          <Typography variant="h6">{titulo}</Typography>
        </Stack>
      </ContainerHeader>

      <Outlet />
      <Container maxWidth="xs">
        <ContainerInfo container>
          <Grid item>
            <i className="icon-info--blue" />
          </Grid>
          <Grid item marginTop="8px">
            <TitleInfoDescription>
              Te enviaremos una notificación y un SMS cuando un transportista esté interesado en tu solicitud.
            </TitleInfoDescription>
          </Grid>
          <Grid item marginTop="16px">
            <Typography variant="label2">
              Válida hasta el {fValidez}.
            </Typography>
          </Grid>
        </ContainerInfo>
      </Container>
      <Grid
        container
        sx={{
          marginBottom: "16px",
        }}
      >
        <Grid item width="100%">
          <ButtomLoader onClick={handleViewApplication}>VER SOLICITUD</ButtomLoader>
          <ButtomLoader variant="outlined" onClick={handleNavigateCreateRequest}>
          Crear nueva solicitud
          </ButtomLoader>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Success;
