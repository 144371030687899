import { CLIENT_TYPE } from '@/consts/defaultConsts';
import {
  HOME_PAHT,
  NOT_AUTHORIZED,
  NO_ACCESS_DRIVER,
  PROFILE_NOTICE_OFFERT_LIST,
} from '@/consts/typeServicesPaths';
import { UsuarioAPI } from '@/models/userAuth/user';
import { RootState } from '@/store/store';
import { getRedirectPath } from '../redirectRules/redirectRules';
import { MiddlewarePaths } from '../router';
import { privateRuteWithLoginMiddleware } from './privateCommontMiddleware';

export const privateCreateRequestMiddleware = (state: RootState): string => {
  const user = state.user.user as UsuarioAPI;
  if (!user.tipousuario || user.tipousuario === CLIENT_TYPE) {
    return getRedirectPath(user);
  }

  return '';
};

export const privateCreateRequestListMiddleware = (
  state: RootState,
  { currentPath, pathname }: MiddlewarePaths
): string => {
  const redirectPath = privateRuteWithLoginMiddleware(state, {
    currentPath,
    pathname,
  });

  const currentRute = privateCreateRequestMiddleware(state);

  const user = state.user?.user as UsuarioAPI;

  if (redirectPath) {
    return redirectPath;
  } else if (currentRute) {
    return currentRute;
  } else if (user?.tipousuario !== CLIENT_TYPE) {
    if (PROFILE_NOTICE_OFFERT_LIST === currentPath) {
      return NOT_AUTHORIZED;
    }
    return NO_ACCESS_DRIVER;
  }

  return currentRute;
};
