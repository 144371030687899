import React from 'react'
import useNoticeCreatedHook from './hook'
import { ContainerSuccess } from './style'
import HeaderSuccess from './HeaderSuccess'
import InfoSuccess from './InfoSuccess'
import { Stack } from '@mui/material'
import ButtomLoader from '../components/buttonLoader/ButtomLoader'
import { Outlet } from 'react-router-dom'

const NoticeCreated = () => {
  const {
    echoResponse,
    handleNavigate,
    handleSeeNotice
  } = useNoticeCreatedHook()

  if (!Object.keys(echoResponse).length) return <></>

  return (
    <ContainerSuccess maxWidth="xs">
      <HeaderSuccess icon="icon-success" title={
        echoResponse?.titulo
      } />

      <Outlet />

      <InfoSuccess
        expireDate={echoResponse && echoResponse?.avisoscreados[0].fvalidez}
        time={null}
      />

      <Stack>
        <ButtomLoader onClick={handleSeeNotice}>
        CREA NUEVO AVISO
        </ButtomLoader>
      <Stack sx={{ marginTop: "16px" }}>
        <ButtomLoader variant="text" onClick={handleNavigate}>
        VER AVISO
        </ButtomLoader>
      </Stack>
      </Stack>
    </ContainerSuccess>
  )
}

export default NoticeCreated