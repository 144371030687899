import { ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import { configDetailsByTypeFlow } from "@/pages/customerRequest/application/utils/byTypes";
import { IconTypes } from "@/utils/Icon";
import { REQUEST_TYPE_MOVE_BETWEEN_CITIES, REQUEST_TYPE_MOVE_CITY, REQUEST_TYPE_MULTI_DESTINY_HOUR, REQUEST_TYPE_MULTI_DESTINY_POINT } from "@/consts/requestConsts";
import { optionOne, optionTwo } from "@/consts/noticeConsts";
import { ISolicitudeFilterData } from "@/models/customerRequest/requestListFilterDataResponse";
import { ICardFilterProps } from "@/components/CardFilter";

const renderTitleByTypeRequestCardFiltertList = (
    requestList: ISolicitudeFilterData,
) => {
    const {
        flgHoraoPunto,
        flgCiudadoEntreciudades,
        idTipoSolicitud
    } = requestList;
    let { title } = configDetailsByTypeFlow[Number(idTipoSolicitud)];
    if (!!flgHoraoPunto && idTipoSolicitud === ID_MULTI_DESTINY) {
        if (flgHoraoPunto === optionOne) {
            title = REQUEST_TYPE_MULTI_DESTINY_HOUR;
        } else {
            title = REQUEST_TYPE_MULTI_DESTINY_POINT;
        }
    }

    // 1 mudanza en la ciudad, 2 mudanza entre ciudades
    if (flgCiudadoEntreciudades === optionOne) {
        title = REQUEST_TYPE_MOVE_CITY;
    } else if (flgCiudadoEntreciudades === optionTwo) {
        title = REQUEST_TYPE_MOVE_BETWEEN_CITIES;
    }

    return title;
};


const renderIconByTypeRequestCardFiltertList = (
    requestList: ISolicitudeFilterData,
): IconTypes => {
    const {
        flgCiudadoEntreciudades,
        idTipoSolicitud
    } = requestList;
    let { icon } = configDetailsByTypeFlow[Number(idTipoSolicitud)];
    // 1 En la ciudad 2 Entre ciudades - OBLIGATORIO
    if (flgCiudadoEntreciudades === 1) {
        icon = "icon-moving"
    }

    return icon;
};


const renderTitleOrigenAndDestiny = (
    requestList: ISolicitudeFilterData,
) => {
    const IS_MULTIDESTINY = requestList.idTipoSolicitud === ID_MULTI_DESTINY;
    const TITLE_TEMPLATE = 'De $origin a $destiny';
    const { origenTexto, destinoTexto } = requestList;
    const title = TITLE_TEMPLATE.replace('$origin', origenTexto).replace(
        '$destiny',
        destinoTexto
    );
    
    if (IS_MULTIDESTINY) {
        return origenTexto;
    }
    
    return title;
};


export const adapterMyCardRequestData = (requestFilter: ISolicitudeFilterData): ICardFilterProps => {
    const {
        tipoVehiculo,
        pesoEstimado,
        precio,
        foto,
        nombre,
        idTipoSolicitud,
        flgPatrocinio,
        perfilFoto,
        precioDetalle,
        calificaciones,
        puntaje,
    } = requestFilter;

    let icon = renderIconByTypeRequestCardFiltertList(requestFilter);
    let title = renderTitleByTypeRequestCardFiltertList(requestFilter);
    let origin = renderTitleOrigenAndDestiny(requestFilter);


    return {
        imgHead: foto,
        imgProfile: perfilFoto,
        icon,
        title,
        origin,
        name: nombre,
        typeVehicle: tipoVehiculo,
        whight: pesoEstimado,
        price: precio,
        priceDetails: precioDetalle,
        patrocinio: flgPatrocinio,
        calificaciones: calificaciones,
        puntaje: puntaje,

    }

}