import HeaderSection from "@/components/headerSection/HeaderSection";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { createRequestLoadDataLayer } from "@/services/dataLayer/createRequest";
import { getPrevRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { Container, styled } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { customerRequestObjectTypes } from "../../shared/consts/customerRequestConsts";
import { customerRequestTitle } from "./configData";

const ContainerArea = styled(Container)(
  () => `
    padding: 16px 0;
  `
);

const Load = () => {
  const dispatch = useAppDispatch();
  const {
    customerRequest: { selectedTypeRequest },
    user: { user },
  } = useAppSelector((state) => state);

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedTypeRequest.id <= 0) {
      navigate(-1);
    }

    createRequestLoadDataLayer((user as IUser).id, selectedTypeRequest.id);

    return () => {};
  }, []);

  const handlePrevRute = () => {
    const valueToReplace = customerRequestObjectTypes[selectedTypeRequest.id];
    dispatch(getPrevRoute(navigate, { valueToSearch: ":typeRequest", valueToReplace }));
  };

  const { title, subTitle } = customerRequestTitle[selectedTypeRequest?.id];
  return (
    <Container maxWidth="xs">
      <HeaderSection icon="icon-arrow-left--primary" title={title} subTitle={subTitle} onClick={handlePrevRute} />
      <ContainerArea>
        <SectionIcon icon="icon-box" />
        <Outlet />
      </ContainerArea>
    </Container>
  );
};

export default Load;
