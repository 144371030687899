import DetailsTextV2 from '@/components/DetailsTextV2/Index';
import ConditionsListOffert from '@/components/conditionsList/ConditionsListOffert';
import TransporterRating from '@/components/userRating/TransporterRating';
import { descriptionLoad, districtsDeliveryText } from '@/consts/noticeConsts';
import { ISolicitudViewDetailsResponse } from '@/models/customerRequest/requestDetailsVewResponse';
import { UsuarioAPI } from '@/models/userAuth/user';
import { IUser } from '@/store/userRedux/userReduxInterface';
import { IconTypes } from '@/utils/Icon';
import { Typography } from '@mui/material';
import DetailsText from '../components/DetailsText';
import InlineTextDetails from '../components/InlineTextDetails';
import CustomAplication from './components/CustomAplication';
import DetailTextTitle from './components/DetailTextTitle';
import MultidestinyApplication from './components/MultidestinyApplication';
import {
  getConditionsInViewDetails,
  getPriceFormat,
  getTypeAcountMultiDestiny,
  getTypeMultidestinyName,
  getValueTypeAcountMultidestiny,
  renderIconByTypeRequest,
  renderRating,
  renderTitleByTypeRequest,
  renderTitleOrigenAndDestiny,
  renderTypeVehicle,
} from './utils/utilsApplication';

export const objectRenderComponent: Record<number, () => JSX.Element> = {
  1: CustomAplication,
  2: MultidestinyApplication,
  3: CustomAplication,
  4: CustomAplication,
  5: CustomAplication,
  6: CustomAplication,
};

// customAplication.tsx

interface ValuesToReplace {
  search: string;
  value: keyof ISolicitudViewDetailsResponse;
  defaultResult: string;
}

export interface ValidFormat {
  template: string;
  valuesToReplace: ValuesToReplace[];
}

interface ConfigDataBodyValid {
  property: keyof ISolicitudViewDetailsResponse;
  componentProp: string;
  defaultResult: boolean | string | number | Array<any> | Object;
  format?: ValidFormat;
  callback?: (
    value: any,
    offert: ISolicitudViewDetailsResponse,
    user: IUser | UsuarioAPI | {}
  ) => any;
  callbakcParams?: any;
}

// interface que puede recibir cualquier type y algunos opcionales
// para que no marque error al momento de hacer el destructuring
interface ConfigDataBodyProps {
  [key: string]: any;
  title?: string;
  icon?: IconTypes;
  show?: boolean;
  originDescription?: string;
  containerSx?: any;
  viewRating?: boolean;
  variant?: string;
  sx?: any;
  marginTop?: string;
  list?: any[];
  listServices?: any[];
  data?: any;
  origin?: string;
  children?: JSX.Element | JSX.Element[] | string;
}

export interface ConfigDataBody {
  title?: string;
  Component: any;
  props: ConfigDataBodyProps;
  valid?: ConfigDataBodyValid[];
  children?: JSX.Element | JSX.Element[] | string;
}

export interface ConfigDataApplication extends ConfigDataBody {}

// default config

export const configData: ConfigDataBody[] = [
  {
    Component: DetailTextTitle,
    props: {
      title: 'En la ciudad',
      icon: 'icon-box',
      show: true,
    },
    valid: [
      {
        property: 'tipoSolicitud',
        componentProp: 'title',
        defaultResult: '',
        callback: renderTitleByTypeRequest,
      },
      {
        property: 'tipoSolicitud',
        componentProp: 'icon',
        defaultResult: '',
        callback: renderIconByTypeRequest,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'h6',
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'title',
        defaultResult: '',
        callback: renderTitleOrigenAndDestiny,
      },
    ],
  },
  {
    Component: TransporterRating,
    props: {
      containerSx: { mt: '16px', mb: '5px' },
      viewRating: true,
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'data',
        defaultResult: {},
        callback: renderRating,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: 'Detalles del servicio',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha',
      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
    },
    valid: [
      {
        property: 'origenTexto2',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'origenTexto2',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referencia',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-dark',
      title: 'Destino',
    },
    valid: [
      {
        property: 'destinoTexto2',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'destinoTexto2',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  // icon-truck
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'tipoVehiculo',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'tipoVehiculo',
        componentProp: 'origen',
        defaultResult: '',
        callback: renderTypeVehicle,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: descriptionLoad,
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },

  {
    Component: Typography,
    props: {
      variant: 'body1',
      marginTop: '4px',
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
  {
    Component: InlineTextDetails,
    props: {
      icon: 'icon-kilos',
      title: 'Peso estimado: ',
    },
    valid: [
      {
        property: 'pesoEstimado',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'pesoEstimado',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },

  // condiciones de la solicitud
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '16px' },
    },
    valid: [
      {
        property: 'condiciones',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'condiciones',
        componentProp: 'listServices',
        defaultResult: [],
        callback: getConditionsInViewDetails,
      },
    ],
  },

  {
    Component: Typography,
    props: {
      variant: 'h5',
      sx: { textTransform: 'inherit', marginTop: '16px' },
    },
    valid: [
      {
        property: 'precio',
        componentProp: 'title',
        defaultResult: 0,
        callback: getPriceFormat,
      },
    ],
  },
];

export const multiDestinyConfigData: ConfigDataBody[] = [
  {
    Component: DetailTextTitle,
    props: {
      title: 'En la ciudad',
      icon: 'icon-box',
      show: true,
    },
    valid: [
      {
        property: 'tipoSolicitud',
        componentProp: 'title',
        defaultResult: '',
        callback: renderTitleByTypeRequest,
      },
      {
        property: 'tipoSolicitud',
        componentProp: 'icon',
        defaultResult: '',
        callback: renderIconByTypeRequest,
      },
    ],
  },
  {
    Component: TransporterRating,
    props: {
      containerSx: { mt: '16px', mb: '5px' },
      viewRating: true,
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'data',
        defaultResult: {},
        callback: renderRating,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: 'Detalles del servicio',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha',
      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: InlineTextDetails,
    props: {
      icon: 'icon-location-light',
      title: `${districtsDeliveryText}: `,
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'origenTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'tipoVehiculo',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'tipoVehiculo',
        componentProp: 'origen',
        defaultResult: '',
        callback: renderTypeVehicle,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: descriptionLoad,
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },

  {
    Component: Typography,
    props: {
      variant: 'body1',
      marginTop: '4px',
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
  {
    Component: InlineTextDetails,
    props: {
      icon: 'icon-kilos',
      title: 'Peso estimado: ',
    },
    valid: [
      {
        property: 'pesoEstimado',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'pesoEstimado',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },

  // condiciones de la solicitud
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '16px' },
    },
    valid: [
      {
        property: 'condiciones',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'condiciones',
        componentProp: 'listServices',
        defaultResult: [],
        callback: getConditionsInViewDetails,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      show: true,
      separate: false,
      titleSx: {
        fontSize: '22px',
      },
    },
    valid: [
      {
        property: 'precio',
        componentProp: 'title',
        defaultResult: '',
        callback: getPriceFormat,
      },
      {
        property: 'id',
        componentProp: 'origen',
        defaultResult: '',
        callback: getTypeMultidestinyName,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      show: true,
      separate: false,
      constainerSx: {
        marginTop: '0px',
      },
    },
    valid: [
      {
        property: 'id',
        componentProp: 'title',
        defaultResult: '',
        callback: getTypeAcountMultiDestiny,
      },
      {
        property: 'id',
        componentProp: 'origen',
        defaultResult: '',
        callback: getValueTypeAcountMultidestiny,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      show: true,
      title: 'Precio total',
      constainerSx: {
        marginTop: '0px',
      },
    },
    valid: [
      {
        property: 'precioCalculado',
        componentProp: 'origen',
        defaultResult: '',
        callback: getPriceFormat,
      },
    ],
  },
];

export const betweenCitiesconfigData: ConfigDataBody[] = [
  {
    Component: DetailTextTitle,
    props: {
      title: 'En la ciudad',
      icon: 'icon-box',
      show: true,
    },
    valid: [
      {
        property: 'tipoSolicitud',
        componentProp: 'title',
        defaultResult: '',
        callback: renderTitleByTypeRequest,
      },
      {
        property: 'tipoSolicitud',
        componentProp: 'icon',
        defaultResult: '',
        callback: renderIconByTypeRequest,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'h6',
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'title',
        defaultResult: '',
        callback: renderTitleOrigenAndDestiny,
      },
    ],
  },
  {
    Component: TransporterRating,
    props: {
      containerSx: { mt: '16px', mb: '5px' },
      viewRating: true,
    },
    valid: [
      {
        property: 'cliente',
        componentProp: 'data',
        defaultResult: {},
        callback: renderRating,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: 'Detalles del servicio',
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha',
      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'origenTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referencia',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-location-light',
      title: 'Destino',
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'destinoTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  // icon-truck
  {
    Component: DetailsText,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'tipoVehiculo',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'tipoVehiculo',
        componentProp: 'origen',
        defaultResult: '',
        callback: renderTypeVehicle,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: descriptionLoad,
      variant: 'subtitle1medium',
      sx: {
        marginTop: '16px',
      },
    },
  },

  {
    Component: Typography,
    props: {
      variant: 'body1',
      marginTop: '4px',
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
  {
    Component: InlineTextDetails,
    props: {
      icon: 'icon-kilos',
      title: 'Peso estimado: ',
    },
    valid: [
      {
        property: 'pesoEstimado',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'pesoEstimado',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },

  // condiciones de la solicitud
  {
    Component: ConditionsListOffert,
    props: {
      title: 'Condiciones del servicio:',
      style: { marginTop: '16px' },
    },
    valid: [
      {
        property: 'condiciones',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'condiciones',
        componentProp: 'listServices',
        defaultResult: [],
        callback: getConditionsInViewDetails,
      },
    ],
  },

  {
    Component: Typography,
    props: {
      variant: 'h5',
      sx: { textTransform: 'inherit', marginTop: '16px' },
    },
    valid: [
      {
        property: 'precio',
        componentProp: 'title',
        defaultResult: 0,
        callback: getPriceFormat,
      },
    ],
  },
];
