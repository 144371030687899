import { NoticeStatusAPI } from "@/models/notice/noticeModels";
import { INoticeStatusServices } from "@/services/interfaces/noticeInterfaces";

export const currentRuleService: Record<number, any> = {
  1: {
    price: {
      title: "Precio de punto a punto",
    },
  },
  2: {
    price: {
      title: "Precio por punto",
    },
  },
};

export interface ICityDataList {
  buttonsPrivateList: ButtonsPrivateList[];
  buttonsPublicList: ButtonsPublicList[];
}

export interface ButtonsPrivateList extends INoticeStatusServices {
  title: string;
  variant: "text" | "outlined" | "contained" | undefined;
  isDisabled: boolean;
  idAction: number;
  link?: string;
  concatLink?: boolean;
  flg: keyof NoticeStatusAPI;
  showFlg: keyof NoticeStatusAPI;
}

export interface ButtonsPublicList {
  title: string;
  variant: "text" | "outlined" | "contained" | undefined;
  isDisabled: boolean;
  idAction: number;
  concatLink?: boolean;
  link?: string;
  linkByTypeOffert?: LinkByTypeOffert[];
  flg: keyof NoticeStatusAPI;
  showFlg: keyof NoticeStatusAPI;
}

export interface LinkByTypeOffert {
  id: number;
  link: string;
}

export enum TypeStateNoticeEnum {
  "activate" = 1,
  "deactivate" = 2,
}

export const cityData: ICityDataList = {
  buttonsPrivateList: [
    {
      title: "EDITAR",
      variant: "outlined",
      isDisabled: false,
      idAction: 1,
      link: "/editar",
      concatLink: true,
      flg: "flgPermiteEditar",
      showFlg: "flgVerEditar",
    },
    {
      title: "RENOVAR",
      variant: "outlined",
      isDisabled: true,
      idAction: 2,
      link: "/editar-fecha",
      concatLink: true,
      flg: "flgPermiteRenovar",
      showFlg: "flgVerRenovar",
    },
    {
      title: "DESACTIVAR",
      variant: "text",
      isDisabled: false,
      idAction: 3,
      tipoestado: TypeStateNoticeEnum.deactivate,
      flg: "flgPermiteDesactivar",
      showFlg: "flgVerDesactivar",
    },
    {
      title: "ACTIVAR",
      variant: "text",
      isDisabled: false,
      idAction: 3,
      tipoestado: TypeStateNoticeEnum.activate,
      flg: "flgPermiteActivar",
      showFlg: "flgVerActivar",
    },
  ],
  buttonsPublicList: [
    {
      title: "ACEPTAR",
      variant: "contained",
      isDisabled: false,
      idAction: 4,
      flg: "flgPermiteAceptar",
      showFlg: "flgVerAceptar",
    },
    {
      title: "CONTRAOFERTAR",
      variant: "outlined",
      isDisabled: false,
      idAction: 5,
      flg: "flgPermiteContraofertar",
      showFlg: "flgVerContraofertar",
    },
  ],
};

type TBtnPrivate = "EDITAR" | "RENOVAR" | "DESACTIVAR" | "ACTIVAR";
type TBtnPublic = "ACEPTAR" | "CONTRAOFERTAR";

export const btnPrivateNumber: Record<TBtnPrivate, number> = {
  EDITAR: 1,
  RENOVAR: 2,
  DESACTIVAR: 3,
  ACTIVAR: 3,
};

export const btnPublicNumber: Record<TBtnPublic, number> = {
  ACEPTAR: 4,
  CONTRAOFERTAR: 5,
};
