import { ERROR_NEGOTATION_CLOSED } from "@/consts/textErrors";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppSelector } from "@/hooks/useReducer";
import { useEffect } from "react";
import { objectRenderComponent } from "./config";

const ReceiveRespose = () => {
  const { removeNotiffication } = useNotiffication();
  const {
    noticeNegotationRequest: { error, isLoading, savedDataNogotation },
  } = useAppSelector((state) => state);
  const negotationClosed = error === ERROR_NEGOTATION_CLOSED;

  const typeNegotationId = savedDataNogotation?.tipoSolicitudNegociacion
    ?.id as number;

  const typeOptionRenderId = !!typeNegotationId ? typeNegotationId : 0;

  useEffect(() => {
    if (negotationClosed) {
      removeNotiffication(false);
      return;
    }
  }, []);

  const Component = objectRenderComponent[typeOptionRenderId];
  if (!Component || isLoading) return <></>;

  return <Component/>;
};

export default ReceiveRespose;
