import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { useAppSelector } from "@/hooks/useReducer";
import { TipoVehiculo } from "@/models/vehicle/vehicleModel";
import { FC, useState, useEffect } from "react";
import HeaderModal from "../headerModal/HeaderModal";
import {
  ContainerButtom,
  ContainerModal,
  CustomDrawer,
} from "../stylesContainerFlow";
import VehicleList from "./components/VehicleList";

type Props = {
  onSelect: (selected: TipoVehiculo) => void;
  open: boolean;
  onClose: () => void;
  btnTittle?: string;
  defaultSelected?: TipoVehiculo;
};

const defaultInitSelected: TipoVehiculo = {
  id: 0,
  nombre: "",
  urlImagen: "",
};

const VehicleListModal: FC<Props> = ({ onSelect, open, onClose, btnTittle="SIGUIENTE", defaultSelected }) => {
  const [vehicleSelected, setVehicleSelected] =
    useState<TipoVehiculo>(defaultInitSelected);

  const { selectedVehicle } = useAppSelector((state) => state.vehicle);

  useEffect(() => {
    if(open && (defaultSelected && defaultSelected?.id > 0)) {
      setVehicleSelected(defaultSelected);
    }

    if (open && selectedVehicle?.id > 0) {
      setVehicleSelected(selectedVehicle);
    }

    return () => {
      setVehicleSelected(defaultInitSelected);
    };
  }, [open]);

  const handleOnSelect = (selected: TipoVehiculo) => {
    setVehicleSelected(selected);
    onSelect(selected);
  };

  const handleSubmit = () => {
    onSelect(vehicleSelected);
  };

  const handleDisabled = () => {
    return !vehicleSelected || vehicleSelected.id === 0;
  };

  return (
    <CustomDrawer open={open} onClose={onClose} anchor="right">
      <HeaderModal title="Elegir vehículo" onClick={onClose} />

      <ContainerModal>
        <VehicleList onSelect={handleOnSelect} selected={vehicleSelected} />
      </ContainerModal>

      <ContainerButtom>
        <ButtomLoader onClick={handleSubmit} disabled={handleDisabled()}>
          {btnTittle}
        </ButtomLoader>
      </ContainerButtom>
    </CustomDrawer>
  );
};

export default VehicleListModal;
