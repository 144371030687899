import { Box, Container, Skeleton, Stack } from "@mui/material";

const SkeletonDeclineMessage = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "70vh",
      }}
    >
      <Stack spacing={1} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <Skeleton variant="text" width={200} height={300}  />
        <Skeleton variant="rectangular" width={282} height={32} />
        <Box sx={{pt: "2px", pb: "2px"}}>
        <Stack spacing={1} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <Skeleton variant="rectangular" width={382} height={20} />
          <Skeleton variant="rectangular" width={382} height={20} />
          </Stack>
        </Box>
        <Skeleton variant="rounded" width={328} height={44} />
      </Stack>
    </Container>
  );
};

export default SkeletonDeclineMessage;
