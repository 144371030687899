import StandardAlert from "@/components/alerts/StandardAlert";
import FormPerfilTransportista from "@/components/form/formPerfilTransportista/FormPerfilTransportista";
import BannerModal from "@/components/modals/BannerModal/BannerModal";
import { CLIENT_TYPE, typesDocuments } from "@/consts/defaultConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import useResize from "@/hooks/useResize";
import { UsuarioAPI } from "@/models/userAuth/user";
import HeaderProfile from "@/pages/profile/headerProfile/HeaderProfile";
import { setErrorMsg, setSuccessMsg } from "@/store/userRedux/userSlice";
import {
  getUserByIdTunhk,
  updateUserThunk,
} from "@/store/userRedux/userThunks";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const Personal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [, isMobil] = useResize();
  const { user, successMsg, isLoading } = useAppSelector((state) => state.user);
  const [showAlert, setShowAlert] = useState(false);
  const [messaggeError, setMessaggeError] = useState("");

  const [dataIsEmpty, setDataIsEmpty] = useState(false);
  const [messageAlert, setMessageAlert] = useState({
    title: "",
    message: "",
    ok: "",
    cancel: "",
    onOk: () => {},
    onCancel: () => {},
  });

  const timerClean = useRef<any>(null);
  const timerClean2 = useRef<any>(null);
  const currentUser = user as UsuarioAPI;

  useEffect(() => {
    if (!!messaggeError) {
      timerClean.current = setTimeout(() => {
        setMessaggeError("");
        dispatch(setErrorMsg(""));
      }, 15000);
    }
    if (!!successMsg) {
      timerClean2.current = setTimeout(() => {
        dispatch(setSuccessMsg(""));
      }, 15000);
    }
    return () => {
      clearTimeout(timerClean.current);
      clearTimeout(timerClean2.current);
    };
  }, [messaggeError, successMsg]);

  useEffect(() => {
    if (Object.keys(user).length && !isLoading) {
      dispatch(getUserByIdTunhk(`${currentUser.id}`));
    }
  }, []);

  const onSubmit = (data: any) => {
    dispatch(updateUserThunk(data))
      .then(() => {
        if (isMobil) {
          navigate(-1);
        }
      })
      .catch((msg: Error) => setMessaggeError(msg.message));
  };

  const handleDataIsEmpty = (isEmpty: boolean) => {
    setDataIsEmpty(isEmpty);
  };

  const navigateBack = () => {
    if (dataIsEmpty) {
      setShowAlert(true);
      setMessageAlert({
        title: "Cambios sin guardar",
        message: "No se guardarán los cambios realizados.",
        ok: "DESCARTAR",
        cancel: "CERRAR",
        onOk: () => {
          navigate(-1);
        },
        onCancel: () => setShowAlert(false),
      });
      return;
    }
    navigate(-1);
  };

  const handleDisabledTypeDocument = () => {
    if (!currentUser.tipoDocumento && currentUser.tipousuario === CLIENT_TYPE) {
      return false;
    }

    return true;
  };

  const handleDisabledNumberDocument = () => {
    if (!currentUser.numDocumento && currentUser.tipousuario === CLIENT_TYPE) {
      return false;
    }

    return true;
  };

  const handleTitle = () => {
    if (currentUser.tipoDocumento?.id === typesDocuments.RUC.id) {
      return "Datos de Empresa";
    }

    return "Datos Personales";
  };

  return (
    <HeaderProfile title={handleTitle()} backAction={navigateBack}>
      {messaggeError && !isLoading && (
        <StandardAlert
          sx={{ marginBottom: "16px" }}
          severity="error"
          color="error"
          onClick={() => {
            dispatch(setErrorMsg(""));
          }}
        >
          {messaggeError}
        </StandardAlert>
      )}
      {successMsg && !isLoading && (
        <StandardAlert
          sx={{ marginBottom: "16px" }}
          severity="success"
          color="success"
          onClick={() => {
            dispatch(setSuccessMsg(""));
            setMessaggeError("");
          }}
        >
          {successMsg}
        </StandardAlert>
      )}
      <BannerModal
        open={showAlert}
        onClose={() => setShowAlert(false)}
        title={messageAlert.title}
        content={messageAlert.message}
        option1={{
          title: messageAlert.cancel,
          onClick: () => setShowAlert(false),
        }}
        option2={{
          title: messageAlert.ok,
          onClick: () => navigate(-1),
        }}
        color="org"
        sx={{
          width: "322px",
          height: "244px",
        }}
        sxAccion={{
          padding: "0px",
        }}
      />
      <FormPerfilTransportista
        disabledTypeDocument={handleDisabledTypeDocument()}
        disabledNumberDocument={handleDisabledNumberDocument()}
        onSubmit={onSubmit}
        dataIsEmpty={handleDataIsEmpty}
      />
    </HeaderProfile>
  );
};

export default Personal;
