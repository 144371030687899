import AlertInfoCard from "@/components/alerts/AlertInfoCard";
import HeaderSectionV2 from "@/components/headerSectionV2/HeaderSectionV2";
import SectionIconV2 from "@/components/sectionIconV2/SectionIconV2";
import { optionOne, optionTwo } from "@/consts/noticeConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { getPrevRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { Container } from "@mui/material";
import {
  Outlet,
  To,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { offertHeaderTitle } from "./config";
import { useEffect } from "react";
import { LocationDetailState } from "@/interfaces/detailNoticeInterface";
import { ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import { NoticeDetailMultiDestinyModel } from "@/models/notice/noticeModels";
import { preSelectDataByFilterThunk } from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferThunk";
import { setSelectData } from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferSlice";
import dayjs from "dayjs";
import { FORMAT_DATE_DDMMYYYY } from "@/consts/formatsDate";

const CargoDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { selectTypeNotice, idTypeNoticeNegotiation } = useAppSelector(
    (state) => state.noticeV2ClientAorCO
  );
  const { id = "" } = useParams();

  const state = location.state as LocationDetailState;

  useEffect(() => {
    const notice = selectTypeNotice as NoticeDetailMultiDestinyModel;
    if (notice?.tipoaviso.id === ID_MULTI_DESTINY) {
      dispatch(preSelectDataByFilterThunk(state));
    }
    if (state?.timeService) {
      const newDate = dayjs(state.timeService).format(FORMAT_DATE_DDMMYYYY);
      dispatch(setSelectData(newDate));
    }
  }, []);

  const handlePreviusRute = () => {
    if (!id) {
      navigate(-1 as To, { replace: true });
    } else {
      dispatch(
        getPrevRoute(navigate, {
          valueToReplace: id,
          valueToSearch: ":id",
        })
      );
    }
  };

  const isContraoferta = idTypeNoticeNegotiation === optionTwo;

  const handleGetMetaData = () => {
    let metaData = offertHeaderTitle[optionOne].header;
    const id = selectTypeNotice?.tipoaviso?.id;
    if (id) {
      const currentId = Number(id);
      metaData = offertHeaderTitle[currentId].header;
    }
    if (isContraoferta) {
      metaData = {
        ...metaData,
        subTitle: "Contraofertar",
      };
    }

    return metaData;
  };

  const { subTitle, title } = handleGetMetaData();

  return (
    <>
      <Container maxWidth="xs">
        <HeaderSectionV2
          icon="icon-arrow-left"
          title={title}
          subTitle={subTitle}
          onClick={handlePreviusRute}
          sx={{
            boxShadow: "none",
          }}
        />
        <Container
          sx={{
            padding: "16px 0",
          }}
        >
          <SectionIconV2 icon="icon-box" />
          {isContraoferta && (
            <AlertInfoCard description="Sólo puedes contraofertar una vez." />
          )}
          <Outlet />
        </Container>
      </Container>
    </>
  );
};

export default CargoDetails;
