import NotificationCard from "@/components/notificationCard/NotificationCard";
import SkeletonCustomGroup from "@/components/skeletons/SkeletonCustomGroup";
import useObserver from "@/hooks/useObserver";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import useResize from "@/hooks/useResize";
import { getListaNotificacionesThunk } from "@/store/userRedux/userThunks";
import { deleteDomainUrl } from "@/utils/helpers";
import {
  Container,
  List,
  ListItem,
  ListItemButton,
  styled,
  Typography,
} from "@mui/material";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const NotiContainer = styled(Container)(() => ({
  padding: "0px",
  maxWidth: "100%",
}));

const TitleNoti = styled(Typography)(() => ({
  display: "flex",
  alignItems: "center",
  margin: "14px 0px 16px 32px",
  "@media (min-width: 600px)": {
    margin: "14px auto 16px auto",
  },
}));

const Notifications = () => {
  const [_, isMovil] = useResize();

  const {
    notifications,
    isLoading,
    isLoadingPagination,
    currentPage,
    totalPages,
  } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const refObserver = useRef<any>(null);

  const { isNearScreen } = useObserver({
    distance: "100px",
    externalRef: refObserver,
  });

  useEffect(() => {
    dispatch(
      getListaNotificacionesThunk({
        page: 0,
        size: 10,
        ordenamiento: ["id", "desc"],
      })
    );

    return () => {};
  }, []);

  useEffect(() => {
    if (isNearScreen && !isLoadingPagination && currentPage < totalPages) {
      handlePaginate();
    }
  }, [isNearScreen]);

  const handlePaginate = () => {
    if (totalPages > 1) {
      dispatch(
        getListaNotificacionesThunk({
          page: currentPage + 1,
          size: 10,
          ordenamiento: ["id", "desc"],
        })
      );
    }
  };

  return (
    <NotiContainer maxWidth={isMovil ? "xl" : "md"} disableGutters>
      <TitleNoti variant="h6">Notificaciones</TitleNoti>
      {notifications && notifications.length === 0 && (
        <Typography variant="body1" sx={{ margin: "0px 32px" }}>
          Aún no tienes notificaciones
        </Typography>
      )}
      {notifications && !isLoading ? (
        <>
          <List>
            {notifications.map((noti, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  sx={{
                    margin: 0,
                    padding: 0,
                    width: "100%",
                  }}
                  onClick={() =>
                    navigate(deleteDomainUrl(noti.link), {
                      state: {
                        hasBackLinkOffert: true,
                      },
                    })
                  }
                >
                  <NotificationCard
                    key={index}
                    id={noti.id}
                    urlIcon={noti.urlIcon}
                    flgVisto={noti.flgVisto}
                    perfilUrl={noti.perfilUrl}
                    fechaRegistro={noti.fechaRegistro}
                    notificacion={noti.notificacion}
                    link={noti.link}
                  />
                </ListItemButton>
              </ListItem>
            ))}
            <div ref={refObserver} />
          </List>
        </>
      ) : (
        <SkeletonCustomGroup
          skeletonList={[
            { height: 80, width: "100%" },
            { height: 80, width: "100%" },
            { height: 80, width: "100%" },
            { height: 80, width: "100%" },
          ]}
          gap={2}
        />
      )}

      {isLoadingPagination && (
        <SkeletonCustomGroup
          skeletonList={[
            { height: 80, width: "100%" },
            { height: 80, width: "100%" },
            { height: 80, width: "100%" },
            { height: 80, width: "100%" },
          ]}
          gap={2}
        />
      )}
    </NotiContainer>
  );
};

export default Notifications;
