import { ConfigDataNegotation } from '@/pages/noticeRequestFlow/interface/noticeNegRequestInterface';

import CounterOfferResponse from './counterOfferResponse/CounterOfferResponse';
import { configDataCounterOfferBetweenCities } from './counterOfferResponse/counterOfferResponseConfig/betweenCitiesConfig';
import { configDataCounterOfferInCity } from './counterOfferResponse/counterOfferResponseConfig/inCityConfig';
import { configDataCounterOfferMoving } from './counterOfferResponse/counterOfferResponseConfig/movingConfig';
import { configDataCounterOfferMultidestiny } from './counterOfferResponse/counterOfferResponseConfig/multidestinyConfig';

import OfferResponse from './offerResponse/OfferResponse';
import { configDataBetweenCities } from './offerResponse/offerResponseConfig/betweenCitiesConfig';
import {
  configDataConditionsExpress,
  configDataExpress,
  configSecondDataExpress,
} from './offerResponse/offerResponseConfig/expressConfig';

import { configDataInCity } from './offerResponse/offerResponseConfig/inCityConfig';
import { configDataMultidestiny } from './offerResponse/offerResponseConfig/multidestinyConfig';

export const objectRenderComponent: Record<number, () => JSX.Element> = {
  1: OfferResponse,
  2: CounterOfferResponse,
  3: OfferResponse, // Este es para express
  4: CounterOfferResponse,
};

type ObjConfigNeg = Record<number, ConfigDataNegotation[]>;

type ObjConfigDataNegotation = Record<number, ObjConfigNeg>;

export const ConfigurationToRenderV2: ObjConfigDataNegotation = {
  1: {
    1: configDataInCity,
    2: configDataCounterOfferInCity,
  },
  2: {
    1: configDataMultidestiny,
    2: configDataCounterOfferMultidestiny,
  },
  3: {
    1: configDataBetweenCities,
    2: configDataCounterOfferBetweenCities,
  },

  5: {
    4: configDataCounterOfferMoving,
  },
};

export const ConfigurationToRender: Record<number, ObjConfigDataNegotation> = {
  1: {
    1: {
      1: configDataInCity,
    },
    2: {
      1: configDataCounterOfferInCity,
      /*  2: configDataConditionsCounterOfferInCity,
      3: configSecondDataCounterOfferInCity, */
    },
  },
  2: {
    1: {
      1: configDataMultidestiny,
      /*       2: configDataConditionsMultidestiny,
      3: configSecondDataMultidestiny, */
    },
    2: {
      1: configDataCounterOfferMultidestiny,
      /*       2: configDataConditionsCounterOfferMultidestiny,
      3: configSecondDataCounterOfferMultidestiny, */
    },
  },
  3: {
    1: {
      1: configDataBetweenCities,
      /*       2: configDataConditionsBetweenCities,
      3: configSecondDataBetweenCities, */
    },
    2: {
      1: configDataCounterOfferBetweenCities,
      /*       2: configDataConditionsCounterOfferBetweenCities,
      3: configSecondDataCounterOfferBetweenCities, */
    },
  },
  4: {
    3: {
      1: configDataExpress,
      2: configDataConditionsExpress,
      3: configSecondDataExpress,
    },
  },
  5: {
    1: {
      1: configDataCounterOfferMoving,
    },
  },
};
