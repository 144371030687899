import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ICurrentLocation {
  currentPath: string;
  step: number;
}

export interface RouteActionsFlowNotice {
  currentLocation: ICurrentLocation | {};
  routeFlow: string[];
}

const initialState: RouteActionsFlowNotice = {
  currentLocation: {},
  routeFlow: [],
};

export const routeActionsFlowNoticeV2Slice = createSlice({
  name: "routeActionsFlowV2",
  initialState,
  reducers: {
    setCurrentLocation: (
      state,
      { payload }: PayloadAction<RouteActionsFlowNotice["currentLocation"]>
    ) => {
      state.currentLocation = payload;
    },
    initRouteFlow: (
      state,
      { payload }: PayloadAction<RouteActionsFlowNotice["routeFlow"]>
    ) => {
      state.routeFlow = payload;
    },
    resetFlowNotice: (state) => {
      state.routeFlow = [];
      state.currentLocation = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentLocation, initRouteFlow, resetFlowNotice } =
  routeActionsFlowNoticeV2Slice.actions;
