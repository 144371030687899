import { configDetailTypeFlow } from "@/components/viewService/detailsService/detailTypeFlow/config";
import { ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import { Negotiation } from "@/models/offert/offertModels";
import { Box, Grid, Stack, styled, Typography } from "@mui/material";

const Title = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.text.secondary};
        font-weight: bold;
        margin-left: 8px;
        
      `
);

const ConatinerDetails = styled(Stack)(
  () => `
      justify-content: space-between;
      display: flex;
      align-items: center;
      flex-direction: row;
    `
);

const Caption = styled(Typography)(
  ({ theme }) => `
      font-size: 12px;
      font-weight: 100;
      color: ${theme.palette.borderGray.contrastText};
      `
);

export const getHourOrPOint = (negotation: any) => {
  const {
    solicitud: { horasrequeridas, flghoraopunto, precio, puntosrequeridos },
  } = negotation;

  let hourOrPoint = horasrequeridas;

  const handleGetIcon = () => {
    if (flghoraopunto === 2) {
      hourOrPoint = puntosrequeridos;
      return configDetailTypeFlow[2].iconPoints;
    }

    return configDetailTypeFlow[2].icon;
  };

  const type = flghoraopunto === 1 ? "horas" : "puntos";

  return (
    <ConatinerDetails>
      <Stack flexDirection="row">
        <i className={handleGetIcon()}></i>
        <Title variant="label1">
          N° {type}
          {": "}
        </Title>
      </Stack>
      <Stack flex={1} marginLeft="8px">
        <Typography variant="body1">
          {hourOrPoint} {/* (S/ {precio} x {type}) */}
        </Typography>
      </Stack>
    </ConatinerDetails>
  );
};

export const getTypeMultidestiny = (negotation: any) => {
  const {
    solicitud: { flghoraopunto },
  } = negotation;

  const handleGetIcon = () => {
    if (flghoraopunto === 2) {
      return configDetailTypeFlow[2].iconPoints;
    }

    return configDetailTypeFlow[2].icon;
  };

  const type = flghoraopunto === 1 ? "horas" : "puntos";

  return (
    <ConatinerDetails>
      <Stack flexDirection="row">
        <i className={handleGetIcon()}></i>
      </Stack>
      <Stack flex={1} marginLeft="8px">
        <Typography variant="body1" sx={{ color: "#757575", fontSize: "12px" }}>
          Multidestino por {type}
        </Typography>
      </Stack>
    </ConatinerDetails>
  );
};

export const DetailTypeFlows = ({ nombre, id, flghoraopunto, isForPoint = false }: any) => {
  let name = nombre;
  const handleGetIcon = () => {
    if (id === ID_MULTI_DESTINY && flghoraopunto === 2) {
      return configDetailTypeFlow[id].iconPoints;
    }

    return configDetailTypeFlow[id || 1].icon;
  };

  if (!!flghoraopunto) {
    if (flghoraopunto === 1) {
      name = "Multidestino por hora";
    } else {
      name = "Multidestino por punto";
    }
  }

  return (
    <Grid container gap={1}>
      <i className={handleGetIcon()} />
      <Caption variant="body2">{name}</Caption>
    </Grid>
  );
};

interface IpropsBoxSpace {
  show: boolean;
  sx?: any;
  title?: string;
}

export const BoxSpace = ({ show, sx, title }: IpropsBoxSpace) => {
  if (!show) return null;
  return (
    <Box
      sx={{
        ...sx,
      }}
    >
      {title}
    </Box>
  );
};


interface IPropsTypographyEmpty {
  sx?: any;
  variant?: any;
  title?: string;
  show?: boolean;
}

export const TypographyEmpty = ({ sx, variant, title, show }: IPropsTypographyEmpty) => {

  if (!show) return null;

  return (
    <Typography
      sx={{
        ...sx,
      }}
      variant={variant}
    >
      {title}
    </Typography>
  );
};