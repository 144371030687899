import { MY_PENDING_AGREEMENTS } from '@/consts/typeServicesPaths';
import { useLocation, useNavigate } from 'react-router-dom';
import { NOTICE_DRIVER_PATH } from '../shared/const/routes';
import { negotiationWithdrawnText, serviceRejectText } from '@/consts/noticeConsts';

const textRejectConfirmationClient =
  'Le avisamos al cliente que no aceptaste el servicio.';
const textRejectConfirmationDriver =
  'Le avisamos al transportista que no aceptaste el servicio.';
const textDeclineConfirmation = "Comunicaremos tu decisión a la otra parte."

const useRejectedMessageHook = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isDriver = pathname.startsWith(NOTICE_DRIVER_PATH);
  const isOffert = pathname.includes('oferta');
  const isDeclined = pathname.includes('/retiro-mensaje');  

  const handleRedirection = () => {
    navigate(MY_PENDING_AGREEMENTS);
  };
  const handleIsDriverText = () => {
    if(isDeclined) {
      return textDeclineConfirmation;
    }
    if(isDriver) {
      return textRejectConfirmationClient;
    }
    return textRejectConfirmationDriver;
  };

  const handleRejectText = () => {
    return isDeclined ? negotiationWithdrawnText : serviceRejectText;
  }
  return {
    handleRedirection,
    handleIsDriverText,
    handleRejectText,
  };
};

export default useRejectedMessageHook;
