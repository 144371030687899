import {
  REQUEST_TYPE_MOVE_BETWEEN_CITIES,
  REQUEST_TYPE_MOVE_CITY,
} from './requestConsts';

export const counterOfferId = 2;
export const offerId = 1;

export const textOriginTitle = 'Origen';
export const textDestinyTitle = 'Destino';

export const clientText = 'cliente';
export const driverText = 'transportista';

export const noResponseText = 'La negociación no obtuvo respuesta.';

export const textCongratulation = '¡Felicidades!';
export const textComunication =
  'En PegaCargo.com, creamos buenas oportunidades para clientes y transportistas. Todas las partes deben verificar y solicitar cualquier tipo de documentación que estimen conveniente por su seguridad.';
export const textDescriptionsSolicitud =
  'En PegaCargo.com, creamos buenas oportunidades para clientes y transportistas. Todas las partes deben verificar y solicitar cualquier tipo de documentación que estimen conveniente por su seguridad.';
export const closeAgreement = 'Tienes un acuerdo cerrado';
export const serviceRejectText = '¡Servicio rechazado!';
export const negotiationWithdrawnText = '¡Negociación retirada!';
export const counterOfferRejectText = 'Contraoferta rechazada';

export const districtsDeliveryText = 'Distritos de recojo/entrega';

export const showOtherNegTextBtn = 'ver otras negociaciones';

export const textRejectConfirmClient =
  'Le avisamos al cliente que no aceptaste el servicio.';
export const textRejectConfimDriver =
  'Le avisamos al transportista que no aceptaste el servicio.';
export const agreementClosedText = 'Acuerdo cerrado';
/* =======Textos Input de Precio======= */

//Labels:
export const textPriceLabel = 'Precio ofrecido (S/)';

//Placeholder:
export const textPricePlaceholder = 'Precio que ofreces';

/* =======Textos Input de Metraje======= */

//Labels:
export const textMetrajeLabel = 'Metraje de tu inmueble (m²)';

//Placeholder:
export const textMetrajePlaceholder = 'Metros cuadrados de tu casa/oficina';

//Textos Input de Fecha
export const textDateServiceLabel = 'Fecha del servicio';
export const textDepartureDateLabel = 'Fecha de salida';
export const textDepartureHourLabel = 'Hora de salida';
export const descriptionLoad = 'Descripción de la carga';

/* =======Textos Botones======= */
export const textEnviar = 'ENVIAR';
export const textRechazar = 'RECHAZAR';
export const textSiguiente = 'SIGUIENTE';
export const textAceptarContactar = 'ACEPTAR';
export const textSolicitarCotizacion = 'SOLICITAR COTIZACIÓN';
export const textContraofertar = 'CONTRAOFERTAR';
export const textRetirarOferta = 'RETIRAR OFERTA';
export const textRetirarCotizacion = 'RETIRAR COTIZACIÓN';
export const textRetirarContraoferta = 'RETIRAR CONTRAOFERTA';
export const textRetirarNegociacion = 'RETIRAR NEGOCIACIÓN';
export const textEditarCotizacion = 'EDITAR COTIZACIÓN';
export const textEditarContraoferta = 'EDITAR CONTRAOFERTA';
export const textRenovar = 'RENOVAR';
export const textGuardar = 'GUARDAR';

/* =======Textos length y otros======= */
export const maxLengthPrice = 7;
export const maxLengthMovingInCityPrice = 5;
export const maxLengthMovingBtnCityPrice = 6;
export const maxLengthReference = 200;
export const maxLengthMeters = 4;
export const maxLengthWeight = 5;
export const maxLengthAssistant = 2;
export const maxLengthBuildingFloor = 2;
export const BuildingFloorName = 'piso';
export const maxAmauntLength = 99;
export const maxMultidestinyRequest = 99999;
export const cocounterofferPriceMaxLength = 10;
export const minPriceMovingInCity = 50;
export const minPriceMovingBtnCity = 100;

/* =======Textos Alert======= */

export const textStateChangeAlert =
  'El estado de esta negociación ha cambiado.';
export const textCurrentState = 'Ver estado actual.';

/* =======Textos partes de URL'S======= */
export const mensajeEnviado = '/mensaje-enviado';

export const withoutPriceDefault = 'S/_______';

/* =======Textos Objetos vacios======= */

export const defaultLocation = {
  precio: 0,
  nombre: '',
  iddistrito: 0,
};

export const defaultInmueble = {
  idInmueble: '0',
  piso: '0',
  elevador: '0',
  metraje: '',
};

export const defaultMessageAlert = {
  title: '',
  message: '',
  ok: '',
  cancel: '',
};

/* =======Mensajes de error en inputs======= */

export const textNegativePrice = 'El precio no puede ser negativo.';
export const textCannotLowerPrice = `El precio es muy bajo para este servicio.`;
export const textCannotUpperPrice = `El precio es muy alto para este servicio.`;
export const textRejectConfirmation =
  'Comunicaremos tu decisión a la otra parte.';
export const textHoursError = 'El mínimo de horas debe ser 1 y máximo 24.';
export const textPointsError = 'El mínimo de puntos debe ser 2 y máximo 99.';

/* =======Opciones======= */

export const optionOne = 1;
export const optionTwo = 2;
export const optionThree = 3;
export const optionFour = 4;
export const optionFive = 5;
export const optionSix = 6;
export const optionSeven = 7;
export const optionEight = 8;
export const optionEleven = 11;

export const driverurl = 'mis-solicitudes';
export const hiddenPricenInRowCollapse = true;

export const maxLengthText = 35;

// ==================================================
//            Titulos de tipos de avisos
// ==================================================
export const NOTICE_TITLE_IN_CITY = 'Transporte en la ciudad';
export const NOTICE_TITLE_BETWEEN_CITIES = 'Transporte entre ciudades';
export const NOTICE_TITLE_MULTIDESTINY = 'Multidestino en la ciudad';
export const NOTICE_TITLE_MOVING_IN_CITY = REQUEST_TYPE_MOVE_CITY;
export const NOTICE_TITLE_MOVING_BETWEEN_CITIES =
  REQUEST_TYPE_MOVE_BETWEEN_CITIES;
export const NOTICE_TITLE_AUCTION_BETWEEN_CITIES = 'Remate entre ciudades';
