import { theme } from "@/styles/theme";
import {
  styled,
  TextField,
  TextFieldProps,
  experimental_sx as sx,
} from "@mui/material";

export const StyleCustomInput = styled(TextField)<TextFieldProps>(
  sx({
    width: "100%",
    "& .MuiFormLabel-root": {
      fontFamily: theme.typography.label3.fontFamily,
      fontSize: "16px",
      fontWeight: theme.typography.label3.fontWeight,
      lineHeight: theme.typography.label3.lineHeight,
      color: "rgba(0, 0, 0, 0.6)",
    },
    "& .MuiInputBase-input::placeholder": {
      fontFamily: theme.typography.placeholder.fontFamily,
      fontStyle: theme.typography.placeholder.fontStyle,
      fontSize: theme.typography.placeholder.fontSize,
      lineHeight: theme.typography.placeholder.lineHeight,
      fontWeight: theme.typography.placeholder.fontWeight,
      letterSpacing: theme.typography.placeholder.letterSpacing,
    },
    "& .MuiFormHelperText-root": {
      letterSpacing: 0,
    },
    
  })
);
