import { FormControl, Grid, Typography } from "@mui/material";


import ModalFilter from "@/components/Filter/components/ModalFilter/ModalFilter";
import DynamicComponent from "@/components/dinamycComponent/DinamycComponent";
import Input from "@/components/inputCustom/Input";
import LabelRequired from "@/components/utils/LabelRequired";
import { AddNewDestiny } from "@/pages/noticeV2/components/AddNewDestiny";
import { CardItem, HeaderBox } from "@/pages/noticeV2/components/CardItem";
import ButtomLoader from "@/pages/noticeV2/components/buttonLoader/ButtomLoader";
import { DistritoSugeridoData } from "@/services/noticeServices/noticeListSuggested/noticeListSuggestedTypes";
import { configInCity } from "../../../components/inputsConfig/inputsInCity";
import { ContainerGrid } from "../../../noticePlaceStyles";
import usePlaceMovingInCityHook from "./hook";
import { metaDataModalInCIty } from "../../hook";

const PlaceMovingInCity = () => {
  const {
    handleInitConfig,
    showModal,
    handleOnCloseModal,
    handleSubmitModal,
    handleOnSeletedModal,
    handleFilterByTerm,
    modalActiveWith,
    listLocation,
    districtsSuggested,
    handleInputsSuggested,
    DeleteSuggestedListItem,
    data,
    validateForm,
    onSubmit,
    isLoading,
    handleInputsCustomHourOrPoint,
    getValidAyudantes,
    validatePrecioOfDestinos,
    isRegister,
    formData,
    isCity,
  } = usePlaceMovingInCityHook()

  return (
    <ContainerGrid container>
      <ModalFilter
        open={showModal}
        onClose={handleOnCloseModal}
        onSubmit={handleSubmitModal}
        onSeleted={handleOnSeletedModal}
        onInputChange={handleFilterByTerm}
        modalActiveWith={modalActiveWith.name}
        list={listLocation}
        betweenDate={""}
        prevSelected={""}
        metaDataModal={metaDataModalInCIty}
        nameTwo={isCity}
      />

      <FormControl sx={{
        gap: "16px",
      }}>
        {configInCity.map((config) =>
          config.map((items, i) =>
            items.columns.map(
              (column) =>
                !(column?.hideRegister && isRegister) && (
                  <DynamicComponent key={i} {...handleInitConfig(column)} />
                )
            )
          )
        )}

        <Input
          label={
            <LabelRequired str={"Número de ayudantes considerados en el servicio"}
            />
          }
          name="ayudantes"
          value={data.ayudantes || ""}
          onChange={handleInputsCustomHourOrPoint}
          placeholder={`Cantidad de ayudantes que incluye tu precio`}
          onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            inputMode: "numeric",
            maxLength: 1,
          }}
          error={!getValidAyudantes(data.ayudantes).valid}
          helperText={getValidAyudantes(data.ayudantes).msg}
        />

      </FormControl>
      <HeaderBox />
      {(districtsSuggested && (districtsSuggested as DistritoSugeridoData)?.sugeridos) && (districtsSuggested as DistritoSugeridoData)?.sugeridos.map((item) => (
        <CardItem
          nombre={item.nombre}
          id={item.id}
          precio={data.avisodestinos?.find((destino) => destino.iddistrito === item.id)?.precio.toString() || ""}
          changeInput={handleInputsSuggested}
          onClick={DeleteSuggestedListItem}
          error={validatePrecioOfDestinos(item.id).valid}
          errorMsg={validatePrecioOfDestinos(item.id).message}
        />
      ))
      }
      {
        (districtsSuggested && (districtsSuggested as DistritoSugeridoData)?.sugeridos) ?
          <AddNewDestiny title="Agregar distrito" term={formData.city} />
          : <Typography variant="body2">
            Elige un distrito de origen para sugerirte destinos.
          </Typography>
      }

      <Grid item sx={{ marginTop: "16px" }}>
        <ButtomLoader
          onClick={onSubmit}
          buttomProps={{
            disabled: !validateForm() || isLoading,
          }}
          isLoading={isLoading}
        >
          CREAR AVISO
        </ButtomLoader>
      </Grid>
    </ContainerGrid>
  );
}

export default PlaceMovingInCity