import DynamicComponent from "@/pages/offerts/offertConfirmation/components/DynamicComponent";
import { vehicleIsActive } from "@/utils/helpers";
import { Grid } from "@mui/material";
import { DetailsCoreModel } from "../../../../models/notice/noticeModels";
import { ConfigDataIncity } from "./detailsOptionsInterface";
import { handleInitConfig } from "../../utils";

interface Props {
  detailsList: ConfigDataIncity[];
  notice: DetailsCoreModel;
}

const DetailOptionsList = ({ detailsList, notice }: Props) => {
  if (!detailsList || !detailsList.length || !notice) return <></>;

  if (!vehicleIsActive(notice?.vehiculo)) {
    return <></>;
  }

  return (
    <>
      {detailsList.map((detail, i) => {
        return (
          <Grid
            key={`${i}-detail`}
            container
            gap={1}
            alignItems="center"
            marginBottom="6px"
          >
            <DynamicComponent
              key={`detail-${i}`}
              {...detail}
              {...handleInitConfig(detail, notice)}
            />
          </Grid>
        );
      })}
    </>
  );
};

export default DetailOptionsList;
