import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import DatePicker from "@/components/form/datePicker.tsx/DatePicker";
import TimePicker from "@/components/form/timePicker.tsx/TimePicker";
import HeaderSection from "@/components/headerSection/HeaderSection";
import LayoutFlow from "@/components/LayoutFlow/LayoutFlow";
import BannerModal from "@/components/modals/BannerModal/BannerModal";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import { FORMAT_DATE_DDMMYYYY, FORMAT_TIME_HHMM, FORMAT_TIME_HHMMmm } from "@/consts/formatsDate";
import { SUCCESS_TEXT_EDIT_DATE } from "@/consts/textSuccess";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { DirtyTypeProps } from "@/interfaces/globalInterface";
import { NoticeResponseSaveFlowAPI } from "@/models/notice/noticeModels";
import { editNoticeDateThunk, renewNoticeThunk } from "@/store/noticeRedux/noticeThunks";
import { setMessageEdit } from "@/store/saveFlow/saveFlowSlice";
import { setSuccessMsg } from "@/store/userRedux/userSlice";
import { stringDDMMYYYToDate } from "@/utils/helpers";
import { Stack, styled } from "@mui/material";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { messageAlertData } from "./configData";
import { defaultMessageAlert, mensajeEnviado, textDepartureDateLabel, textDepartureHourLabel, textGuardar, textRenovar } from "@/consts/noticeConsts";

const StackPicker = styled(Stack)(
  () => `
    margin-top: 16px;
  `
);

const NoticeDateEdit = () => {
  const [timePicker, setTimePicker] = useState<Dayjs | null>(null);
  const [datePicker, setDatePicker] = useState<Dayjs | null>(null);
  const [errorDate, setErrorDate] = useState(undefined);
  const [errorTime, setErrorTime] = useState(undefined);
  const [dirtyFields, setDirtyFields] = useState<DirtyTypeProps>({ datePicker: { dirty: false } });
  const [messageError, setMessageError] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(defaultMessageAlert);

  const {
    saveFlowData: [data],
    notice: { isLoading },
  } = useAppSelector((state) => ({
    saveFlowData: state.saveFlowData.saveFlowData,
    notice: state.notice,
  }));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const regex = /\/\d+\/editar-fecha/;

  useEffect(() => {
    if (data?.fechafija) {
      setDatePicker(pathname.match(regex) ? stringDDMMYYYToDate(new Date().toLocaleDateString()) : stringDDMMYYYToDate(data.fechafija));
    }

    if (data?.hora) {
      setTimePicker(
        pathname.match(regex)
          ? dayjs(moment(new Date().toLocaleTimeString(), FORMAT_TIME_HHMMmm).toDate())
          : dayjs(moment(data?.hora, FORMAT_TIME_HHMMmm).toDate())
      );
    }
  }, []);

  const handleValidSubmit = () => {
    return errorDate || errorTime;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    messageError && setMessageError("");
    if (handleValidSubmit() || isLoading) return;

    const body = { timePicker, datePicker: datePicker as Dayjs };

    if (pathname.match(regex)) {
      dispatch(
        renewNoticeThunk(data.id, {
          fechafija: datePicker?.format(FORMAT_DATE_DDMMYYYY),
          // fomatTime sin el Am o Pm
          hora: timePicker?.format(FORMAT_TIME_HHMM) || "",
        })
      )
        .then((res) => {
          const [data] = res as NoticeResponseSaveFlowAPI[];
          dispatch(
            setSuccessMsg(
              `Tu servicio de ${data.origentexto} a ${data.destinotexto} se ha renovado y estará visible hasta el ${data?.fechafija}.`
            )
          );

          navigate(`${pathname?.split("/").slice(0, -1).join("/")}${mensajeEnviado}`, {state: {message: 'aviso renovado'}});
        })
        .catch((e: Error) => setMessageError(e?.message));
    } else {
      dispatch(editNoticeDateThunk(data.id, body))
        .then(handleSuccess)
        .catch((e: Error) => setMessageError(e?.message));
    }
  };

  const handleOnChange = (fieldName: "datePicker" | "timePicker") => (value: Dayjs | null) => {
    if (fieldName === "datePicker") {
      setDatePicker(value);
    } else {
      setTimePicker(value);
    }
    messageError && setMessageError("");
    setDirtyFields({ ...dirtyFields, [fieldName]: { dirty: true } });
  };

  const handleSuccess = () => {
    dispatch(setMessageEdit(SUCCESS_TEXT_EDIT_DATE));
    navigate(-1);
  };

 
  const changeTitleByPathname = () => {
    return pathname.match(regex)
      ? {
          title: "Renovar",
          button: textRenovar,
          labelFecha: data.hora ? `${textDepartureDateLabel} *` : "¿Cuándo? *",
        }
      : {
          title: "Editar",
          button: textGuardar,
          labelFecha: `${textDepartureDateLabel} *`,
        };
  };

  const handleBackNavigation = () => {
    const isDirtyForm = Object.values(dirtyFields).some(({ dirty }) => !!dirty); // Valida que si se cambio algo en el formulario!!
    if (pathname.match(regex) && isDirtyForm) {
      setMessageAlert(messageAlertData.renew);
      setShowAlert(true);
    } else if (!!pathname.includes("editar") && isDirtyForm) {
      // para la edicion del aviso
      setMessageAlert(messageAlertData.edit);
      setShowAlert(true);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
     <LayoutFlow>
      <HeaderSection
        icon="icon-arrow-left--primary"
        title="Fecha"
        subTitle={changeTitleByPathname()?.title}
        onClick={handleBackNavigation}
      />
      <>
        {messageError && (
          <StandardAlert sx={{ marginBottom: "16px" }} severity="error" color="error">
            {messageError}
          </StandardAlert>
        )}
      </>
      <BannerModal
        open={showAlert}
        onClose={() => setShowAlert(false)}
        title={messageAlert.title}
        content={messageAlert.message}
        option1={{
          title: messageAlert.cancel,
          onClick: () => setShowAlert(false),
        }}
        option2={{
          title: messageAlert.ok,
          onClick: () => navigate(-1),
        }}
        color="org"
        sx={{
          width: "322px",
        }}
        sxAccion={{
          padding: "0px",
        }}
      />
      <SectionIcon icon="icon-calendar--blue" />
      <form onSubmit={handleSubmit}>
        <StackPicker>
          <DatePicker
            initValue={datePicker}
            inputFormat={FORMAT_DATE_DDMMYYYY}
            label={changeTitleByPathname()?.labelFecha}
            onChange={handleOnChange("datePicker")}
            onError={setErrorDate}
            disablePast={!!pathname.match(regex)}
            minDate={dayjs().add(1, "day")}
          />
        </StackPicker>
        {timePicker && (
          <StackPicker>
            <TimePicker
              initValue={timePicker}
              inputFormat={FORMAT_TIME_HHMMmm}
              label={textDepartureHourLabel}
              onChange={handleOnChange("timePicker")}
              onError={setErrorTime}
            />
          </StackPicker>
        )}

        <ButtomLoader isLoading={isLoading} disabled={isLoading} type="submit">
          {changeTitleByPathname()?.button}
        </ButtomLoader>
      </form>
    </LayoutFlow></>
   
  );
};

export default NoticeDateEdit;
