import {
  ACCEPTANCE_COUNTER_OFFERT,
  ACCEPTANCE_OFFERT_CLIENT,
  COUNTER_OFFERT_FEE,
  CO_COUNTEROFFERT_CLIENT,
  DECLINE_OFFER,
  MESSAGE_AGREEMENT_OFFERT,
  MESSAGE_CONFIRMATION_CO_COUNTER_OFFER_CLIENT,
  AGREEMENT_MESSAGE_COUNTEROFFER_DRIVER,
  OFFERT_TO_ACCEPT,
} from '@/consts/acceptanceOffertPath';
import { actionAcceptance } from '../consts/actionAcceptance';
import { NOTICE_NEGOTATION_COUNTER_OFFER_DRIVER } from '@/pages/noticeV2Negotation/shared/const/routes';

//  Estas funciones se usan en GlobalButton.tsx para la navegacion dependiendo del boton que ejecute el evento

export const getDeclineOffertURL = (idOffert: string) => {
  const declineOffert_url = DECLINE_OFFER.replace(':idOffert', idOffert);

  return declineOffert_url;
};

export const getCo_counterOffertURL = (idOffert: string) => {
  const co_counterOffert_url = COUNTER_OFFERT_FEE.replace(
    ':idOffert',
    idOffert
  );

  return co_counterOffert_url;
};

export const getDriverAcceptOfferURL = (idOffert: string) => {
  const driverAcceptOffer_url = MESSAGE_AGREEMENT_OFFERT.replace(
    ':idOffert',
    idOffert
  );

  return driverAcceptOffer_url;
};

export const getDriverAcceptCounterOfferURL = (idOffert: string) => {
  const driverAcceptCounterOffer_url =
    AGREEMENT_MESSAGE_COUNTEROFFER_DRIVER.replace(':idOffert', idOffert);

  return driverAcceptCounterOffer_url;
};

export const getClientAcceptCounterOfferURL = (idOffert: string) => {
  const clientAcceptCounterOffer =
    MESSAGE_CONFIRMATION_CO_COUNTER_OFFER_CLIENT.replace(':idOffert', idOffert);

  return clientAcceptCounterOffer;
};

export const getFlagsDriverState = (savedOffert: any) => {
  //Flags del transportista
  const allowButtonStateDriver =
    savedOffert?.ultimaAvisoNegociacionAcciones.tipoAvisoNegociacionAccion
      .flgPermitirAceptarTransportista;
  const counterOfferButtonStateDriver =
    savedOffert?.ultimaAvisoNegociacionAcciones.tipoAvisoNegociacionAccion
      .flgPermitirContraofertarTransportista;
  const declineButtonStateDriver =
    savedOffert?.ultimaAvisoNegociacionAcciones.tipoAvisoNegociacionAccion
      .flgPermitirRechazarTransportista;

  return {
    allowButtonStateDriver,
    counterOfferButtonStateDriver,
    declineButtonStateDriver,
  };
};
export const getFlagsClientState = (savedOffert: any) => {
  //Flags del transportista
  const allowButtonStateClient =
    savedOffert?.ultimaAvisoNegociacionAcciones.tipoAvisoNegociacionAccion
      .flgPermitirAceptarCliente;
  const counterOfferButtonStateClient =
    savedOffert?.ultimaAvisoNegociacionAcciones.tipoAvisoNegociacionAccion
      .flgPermitirContraofertarCliente;
  const declineButtonStateClient =
    savedOffert?.ultimaAvisoNegociacionAcciones.tipoAvisoNegociacionAccion
      .flgPermitirRechazarCliente;

  return {
    allowButtonStateClient,
    counterOfferButtonStateClient,
    declineButtonStateClient,
  };
};

export const handleStateButtonWithPath = (
  { counterOfferButtonStateClient, counterOfferButtonStateDriver }: any,
  { setHiddenButton, setDisableAgreeButton, setDisableDeclineButton }: any,
  pathname: any,
  idState: any
) => {
  const pathnameReplace = pathname.slice(0, -3) + ':idOffert';

  if (
    counterOfferButtonStateDriver === 0 &&
    pathnameReplace === ACCEPTANCE_COUNTER_OFFERT
  ) {
    setHiddenButton(true);
  }

  if (
    counterOfferButtonStateClient === 0 &&
    pathnameReplace === CO_COUNTEROFFERT_CLIENT
  ) {
    setHiddenButton(true);
  }

  if (
    pathnameReplace === ACCEPTANCE_OFFERT_CLIENT &&
    idState === actionAcceptance[4].id
  ) {
    setHiddenButton(false);
  }

  if (
    pathnameReplace === NOTICE_NEGOTATION_COUNTER_OFFER_DRIVER &&
    idState === actionAcceptance[4].id
  ) {
    setHiddenButton(false);
  }

  if (
    pathnameReplace === ACCEPTANCE_COUNTER_OFFERT &&
    idState === actionAcceptance[5].id
  ) {
    setHiddenButton(false);
  }

  if (
    pathnameReplace === OFFERT_TO_ACCEPT &&
    idState === actionAcceptance[1].id
  ) {
    setHiddenButton(true);
  }

  if (
    pathnameReplace === OFFERT_TO_ACCEPT &&
    idState === actionAcceptance[5].id
  ) {
    setHiddenButton(true);
    setDisableAgreeButton(false);
    setDisableDeclineButton(false);
  }
};
