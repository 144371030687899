import useResize from "@/hooks/useResize";
import { Grow, GrowProps, Snackbar, styled } from "@mui/material";
import { FC } from "react";

const StyleSnackbar = styled(Snackbar)(
  ({ theme }) => `

  @media only screen and (min-width: ${theme.breakpoints.values.sm}px) {
    &.MuiSnackbar-root {
      top: 85px;
    }
  }
    
  `
);

const ContainerAlert = styled("div")(
  () => `
    background-color: #fff;
  `
);

function GrowTransition(props: GrowProps) {
  return <Grow {...props} />;
}

interface Props {
  open: boolean;
  children: JSX.Element | JSX.Element[] | string;
}

const CustomSnackbar: FC<Props> = ({ open, children }) => {
  const [, isMobile] = useResize();

  return (
    <StyleSnackbar
      open={open}
      TransitionComponent={GrowTransition}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
    >
      <ContainerAlert>{children}</ContainerAlert>
    </StyleSnackbar>
  );
};

export default CustomSnackbar;
