import { SUCCESS_CHANGE_PASSWORD } from "@/consts/textSuccess";
import { AUTH_LOGIN } from "@/consts/typeServicesPaths";

interface ConfigData {
  title: string;
  textButton: string;
  message: string;
  path: string;
}

export interface TypeConfirmationchange {
  changePassword: ConfigData;
  changePhone: ConfigData;
  calificacionConfirmacion: ConfigData;
}

export const configData: TypeConfirmationchange = {
  changePassword: {
    title: "¡Listo!",
    textButton: "INICIAR SESIÓN",
    message: SUCCESS_CHANGE_PASSWORD,
    path: AUTH_LOGIN,
  },
  changePhone: {
    title: "¡Listo!",
    textButton: "INICIAR SESIÓN",
    message: "Tu número de celular fue actualizado.",
    path: AUTH_LOGIN,
  },
  calificacionConfirmacion: {
    title: "Gracias por tu respuesta",
    textButton: "",
    message: "Tu calificación fue enviada.",
    path: "",
  },
};
