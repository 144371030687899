import { Grid, Stack, styled, Typography } from "@mui/material";

export const ContainerBody = styled(Grid)(
  () => `
    border: 1px solid #eeeeee;
    border-radius: 12px;
    padding-bottom: 8px;
  `
);

export const TitleBody = styled(Typography)(
  ({ theme }) => `
    text-align: center;
    font-size: ${theme.typography.label2.fontSize};
    line-height: ${theme.typography.label2.lineHeight};
    font-family: ${theme.typography.label2.fontFamily};
    font-weight: ${theme.typography.label2.fontWeight};
    color: rgba(0, 0, 0, 0.6);
  `
);

export const SectionHeaderFooter = styled(Grid)(
  () => `
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    padding: 8px;
  `
);

export const SectionBody = styled(SectionHeaderFooter)(
  ({ theme }) => `
    position: relative;
    border-top: none;
    &.multi:nth-of-type(odd):after, 
    &.simgle:nth-of-type(even):after {
      content: '';
      position: absolute;
      border-right: 1px dashed ${theme.palette.borderGray.main};
      right: 0;
      height: 50%;
    }
  `
);

export const BodyBox = styled(Stack)(
  () => `
    flex-direction: row;
    justify-content: center;
    align-items: center;
  gap: 8px;
  `
);

export const SubTitleBody = styled(Typography)(
  ({ theme }) => `
    text-align: center;
    margin-top: 4px;
    font-size: ${theme.typography.body1.fontSize};
    line-height: ${theme.typography.body1.lineHeight};
    font-family: ${theme.typography.body1.fontFamily};
    font-weight: ${theme.typography.body1.fontWeight};
    color: rgba(0, 0, 0, 0.87);
  `
);

export const SectionBodyMultiNotice = styled(BodyBox)(
  ({ theme }) => `
    margin: 0;
    padding: 8px 16px;
    width: 100%;
    border-top: 1px solid ${theme.palette.borderGray.main};
  `
);
