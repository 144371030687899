import { NoticeOfferMovingInCitySlice } from '@/interfaces/offerInterface';

export const getPreloadData = (
  currentData: NoticeOfferMovingInCitySlice,
  formFields: Record<string, string>
) => {
  const result = Object.entries(currentData).reduce(
    (acc: any, [key, value]) => {
      if (formFields.hasOwnProperty(key)) {
        if (!acc) {
          acc = { [key]: value };
        } else {
          acc = {
            ...acc,
            [key]: value,
          };
        }
      }
      return acc;
    },
    null
  );
  return result;
};
