import SuccessInCity from './SuccessInCity/Index';
import SuccessMoving from './SuccessMoving/Index';
import SuccessMulti from './SuccessMulti/Index';

export const objectRenderComponent: Record<number, () => JSX.Element> = {
  1: SuccessInCity,
  2: SuccessMulti,
  3: SuccessInCity,
  4: SuccessInCity,
  5: SuccessInCity,
};
