import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import {
  maxLengthPrice,
  maxLengthWeight,
  textCannotLowerPrice,
  textCannotUpperPrice,
  textContraofertar,
} from "@/consts/noticeConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { NoticeDetailAuctionModel } from "@/models/notice/noticeModels";
import { CardItem } from "@/pages/flowNoticeNegotation/components/CarItem";
import { labelTextStyle } from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import {
  getFirstStepNoticeType,
  getFirstStepNoticeTypeInterface,
} from "@/pages/flowNoticeNegotation/shared/adapters/clientAcceptOrCounterOfferAdapter";
import {
  fillSendDataNoticeThunk,
  saveOfferAuctionThunk,
} from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferThunk";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { cleanValueNumber, currencyFormat } from "@/utils/helpers";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { theme } from "@/styles/theme";
import { setError } from "@/store/noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferSlice";
import {
  ERROR_WEIGHT_LOAD_MAX,
  ERROR_WEIGHT_LOAD_MIN,
} from "@/consts/textErrors";

export const AuctionPriceCounterOffer = () => {
  const navigate = useNavigate();
  const {
    noticeV2ClientAorCO: {
      selectTypeNotice,
      selectedUbications,
      sendData: { auctionBetweenCities },
      isLoading,
      error,
    },
  } = useAppSelector((state) => state);
  const notice = selectTypeNotice as NoticeDetailAuctionModel;
  const tipoAvisoId = notice?.tipoaviso?.id;
  const dispatch = useAppDispatch();

  const [errorPrice, setErrorPrice] = useState("");
  const [errorWeight, setErrorWeight] = useState("");

  const [form, setForm] = useState({
    price: auctionBetweenCities?.price || 0,
    weight: auctionBetweenCities?.weight || 0,
  });

  useEffect(() => {
    if (error) {
      setErrorPrice(error);
    }

    return () => {
      dispatch(setError(""));
    };
  }, [error]);

  const handleOnChange = ({ target }: EventType["change"]) => {
    const { name, value } = target;

    const cleanValue = cleanValueNumber(value);

    if (name === "price" && value.length <= maxLengthPrice) {
      setForm({ ...form, price: Number(cleanValue) });
    } else if (name === "weight" && value.length <= maxLengthWeight) {
      setForm({ ...form, weight: Number(cleanValue) });
    }
  };

  const handleSaveInStorage = () => {
    const ruteToSendData = {
      key: getFirstStepNoticeType(tipoAvisoId),
      value: getFirstStepNoticeTypeInterface(tipoAvisoId, {
        ...auctionBetweenCities,
        ...form,
      }),
    };
    const values = {
      data: ruteToSendData,
      tipoAvisoId,
      activeLoading: false,
      noExecuteImg: true,
    };

    dispatch(fillSendDataNoticeThunk(values));
  };

  const handleValidPrice = () => {
    let message = "";
    if (form.price > notice.precio) {
      message = textCannotUpperPrice;
    } else if (form.price < notice.precio / 2) {
      message = textCannotLowerPrice;
    }
    setErrorPrice(message);

    return message;
  };

  const handleValidWeight = () => {
    const { weight } = form;
    const { pesomaximo } = notice;
    let message = "";
    if (weight < pesomaximo * 0.2) {
      message = ERROR_WEIGHT_LOAD_MIN;
    } else if (weight > pesomaximo) {
      message = ERROR_WEIGHT_LOAD_MAX;
    }
    setErrorWeight(message);

    return message;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSaveInStorage();
    setErrorPrice("");
    const hasErrorWeight = handleValidWeight();
    const hasErrorPrice = handleValidPrice();
    if (handleDisabled() || !!hasErrorWeight || !!hasErrorPrice) return;

    dispatch(saveOfferAuctionThunk()).then(() => {
      handleNextRute();
    });
  };

  const handleNextRute = () => {
    dispatch(
      getNextRoute(navigate, {
        valueToReplace: notice.id?.toString(),
        valueToSearch: ":id",
      })
    );
  };

  const handleDisabled = () => {
    let conditions = [form.price, form.weight];

    return conditions.some((condition) => !condition);
  };

  return (
    <>
      <Typography variant="body2" color="black">
        <strong>Origen:</strong> {selectedUbications?.origin?.nombre},{" "}
        {notice.origentexto} {/*TODO: Peru debe venir de back */}
      </Typography>
      <Typography variant="body2" color="black">
        <strong>Destino:</strong> {selectedUbications?.destiny?.nombre},{" "}
        {notice.destinotexto}
      </Typography>

      <StandardAlert
        severity="error"
        color="error"
        sx={{ marginY: "16px", display: error ? "flex" : "none" }}
      >
        {error}
      </StandardAlert>

      <form onSubmit={handleSubmit} autoComplete="off">
        <Box>
          <Box sx={{ mt: 2, mb: 2 }}>
            <CardItem
              name="weight"
              value={!!form.weight ? `${form.weight}` : ""}
              headTitle="Peso del remate"
              infoComponent={
                <Box>
                  <Typography sx={{ ...labelTextStyle, fontSize: "12px" }}>
                    Del aviso
                  </Typography>
                  <Typography>{notice.pesomaximo} kilos</Typography>
                </Box>
              }
              hideBtnClose
              placeholder="Peso de tu carga"
              title=""
              labelStr="Contraoferta (Kilos)"
              change={handleOnChange}
              error={!!errorWeight}
            />
            <Typography
              variant="caption"
              sx={{ display: !!errorWeight ? "block" : "none" }}
              color={theme.palette.error.main}
            >
              {errorWeight}
            </Typography>
          </Box>
          <Box sx={{ mt: 2, mb: 2 }}>
            <CardItem
              name="price"
              value={!!form.price ? `${form.price}` : ""}
              headTitle="Precio del remate"
              infoComponent={
                <Box>
                  <Typography sx={{ ...labelTextStyle, fontSize: "12px" }}>
                    Del aviso
                  </Typography>
                  <Typography>{currencyFormat(notice.precio)}</Typography>
                </Box>
              }
              hideBtnClose
              placeholder="Peso de tu carga"
              title=""
              labelStr="Contraoferta (S/)"
              change={handleOnChange}
              error={!!errorPrice}
            />
            <Typography
              variant="caption"
              sx={{ display: !!errorPrice ? "block" : "none" }}
              color={theme.palette.error.main}
            >
              {errorPrice}
            </Typography>
          </Box>
        </Box>

        <ButtomLoader
          type="submit"
          disabled={handleDisabled()}
          isLoading={isLoading}
        >
          {textContraofertar}
        </ButtomLoader>
      </form>
    </>
  );
};
