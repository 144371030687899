import { IMG_PATH } from "@/consts/defaultConsts";
import { NoticeProfile } from "@/models/notice/noticeListOffersModels";
import { characterCut, resizePhoto } from "@/utils/helpers";
import {
  Avatar,
  Grid,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TwoAvatars from "./TwoAvatars";
import { RatingStyles } from "./styledUserRating";

type Props = {
  urlProfile?: string;
  urlTruck?: string;
  showTwoAvatars?: boolean;
  userName?: string;
  containerSx?: SxProps<Theme>;
  data?: NoticeProfile;
};

const SinglerUserRating = (props : Props) => {
  const {
    urlProfile,
    userName,
    containerSx,
    urlTruck,
    showTwoAvatars = false,
    data,
  } = props
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const { avatarStyles, gridContainerStyles } = RatingStyles;
  const {
    urlProfile: urlV,
    userName: userN,
    urlVehicle: UriT,
    showTwoAvatars: showTwo,
  } = data || {};
  const imageAvatar = !!urlProfile
    ? urlProfile
    : (data as NoticeProfile).urlProfile;

  if (!data && !urlProfile) return <></>;

  return (
    <Grid
      container
      sx={{
        alignItems: "center",
        maxWidth: matches ? "65%" : "100%",
        ...containerSx,
      }}
    >
      <Grid item xs={showTwoAvatars || showTwo ? 3 : 2}>
        {showTwoAvatars || showTwo ? (
          <TwoAvatars
            primaryAvatar={
              resizePhoto({ photo: IMG_PATH + urlProfile }) || urlV || ""
            }
            secondAvatar={urlTruck || (UriT && UriT[0]?.direccion) || ""}
          />
        ) : (
          <Avatar
            src={resizePhoto({ photo: IMG_PATH + imageAvatar }) || urlV}
            sx={avatarStyles}
          />
        )}
      </Grid>
      <Grid item xs={9} sx={gridContainerStyles}>
        <Typography variant="h6">
          {characterCut(userName || userN || "", matches ? 35 : 25)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SinglerUserRating;
