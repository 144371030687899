import CardHorizont, { CardHorizonProps } from "@/components/cardHorizont/CardHorizont";

type Values = {
  link?: string;
  typeFLow?: string;
};

type Props = {
  list: CardHorizonProps[];
  onClick: (value: Values) => void;
  replaceNavigate?: boolean;
};

const ListOptions = ({ list, onClick, replaceNavigate }: Props) => {
  if (!list || !Array.isArray(list)) return <></>;

  return (
    <>
      {list.map((prop, i) => (
        <CardHorizont key={`${i}-card`} {...prop} onClick={onClick} replaceNavigate={replaceNavigate} />
      ))}
    </>
  );
};

export default ListOptions;
