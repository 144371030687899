interface StateProps {
  id: number;
  name: string;
}

export const actionAcceptance: Record<number, StateProps> = {
  1: {
    id: 1,
    name: 'Cliente - Acepta',
  },
  2: {
    id: 2,
    name: 'Cliente - Contraoferta',
  },
  3: {
    id: 3,
    name: 'Transportista - Acepta',
  },
  4: {
    id: 4,
    name: 'Transportista - Rechaza',
  },
  5: {
    id: 5,
    name: 'Transportista - Contraoferta',
  },
  6: {
    id: 6,
    name: 'Cliente - Acepta Contraoferta',
  },
  7: {
    id: 7,
    name: 'Cliente - Rechaza Contraoferta',
  },
  8: {
    id: 8,
    name: 'Sistema - Anula',
  },
};

interface CoCounterOfferFlowProps {
  id: number;
  label?: string;
  placeholder?: string;
  headerSectionTitle?: string;
  point?: {
    item: string;
    label: string;
    placeholder: string;
  };
  time?: {
    item?: string;
    label: string;
    placeholder: string;
  };
}

export const coCounterOfferFlow: Record<number, CoCounterOfferFlowProps> = {
  1: {
    id: 1,
    label: 'Precio ofrecido (S/)',
    placeholder: 'Precio que ofreces',
  },
  2: {
    id: 2,
    point: {
      item: 'Cantidad de puntos',
      label: 'Precio por punto que ofreces',
      placeholder: 'Ingresa un precio por punto',
    },
    time: {
      item: 'Cantidad de horas',
      label: 'Precio por hora que ofreces',
      placeholder: 'Ingresa un precio por hora',
    },
  },
  3: {
    id: 3,
    label: 'Precio ofrecido (S/)',
    placeholder: 'Precio que ofreces',
  },
  4: {
    id: 4,
    label: 'Precio ofrecido (S/)',
    placeholder: 'Precio que ofreces',
  },
  5: {
    id: 5,
    label: 'Precio por viaje (S/)',
    placeholder: 'Precio que cobras por viaje',
    headerSectionTitle: 'Cotizar',
  },
};

interface EditCounterOfferTitle {
  id: number;
  title: string;
  label: string;
  placeholder: string;
}

export const editCounterOfferTitle: EditCounterOfferTitle = {
  id: 3,
  title: 'Editar contraoferta',
  label: 'Precio ofrecido (S/)',
  placeholder: 'Precio que ofreces',
};

export const nombreContrarioState = {
  1: 'En Espera',
  2: 'Aceptado',
};

export const snackbarState = {
  1: 'Ya has respondido a esta oferta.',
  2: 'El cliente ha aceptado tu contraoferta', // cliente rechaza co-contraoferta
  3: 'Has rechazado la oferta del cliente',
  4: 'Has rechazado la contraoferta del cliente', // transportista rechaza
};
