import { ID_MULTI_DESTINY } from "@/consts/defaultConsts";
import { Grid, styled, Typography } from "@mui/material";
import { TypeVehicleAPI } from "../../../../models/vehicle/vehicleModel";
import { configDetailTypeFlow } from "./config";

const Caption = styled(Typography)(
  ({ theme }) => `
    font-size: 12px;
    font-weight: 100;
    color: ${theme.palette.borderGray.contrastText};
    `
);

interface Props extends Omit<TypeVehicleAPI, "urlImagen"> {
  isForPoint?: boolean;
  flghoraopunto?: number;
}

const DetailTypeFlow = ({ nombre, id, isForPoint = false, flghoraopunto }: Props) => {

  const handleName = () => {
    let result = nombre;
    if (flghoraopunto === 1) result = "Multidestino por hora";
    else if(flghoraopunto === 2) result = "Multidestino por punto";

    return result
  };
  
  const handleGetIcon = () => {
    if (isForPoint && id === ID_MULTI_DESTINY) {
   
      return configDetailTypeFlow[id].iconPoints;
    }

    return configDetailTypeFlow[id || 1].icon;
  };

  return (
    <Grid container gap={1}>
      <i className={handleGetIcon()} />
      <Caption variant="body2">{handleName()}</Caption>
    </Grid>
  );
};

export default DetailTypeFlow;
