import {
  ID_AUCTION_FLOW,
  ID_BETWEEN_CITIES,
  ID_IN_CITY,
  ID_MOVING_FLOW,
  ID_MULTI_DESTINY,
} from "@/consts/defaultConsts";
import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { currencyFormat } from "@/utils/helpers";
import { Box, Typography } from "@mui/material";
import { AuctionSuccessPrices } from "../containers/AuctionSuccessDetails/AuctionSuccessPrices";
import { MovingSuccessPrice } from "../containers/MovingSuccessDetails/MovingSuccessPrice";
import { MultidestinySucessPrice } from "../containers/MultidestinySuccessDetails/MultidestinySucessPrice";

export const SuccessPrice = () => {
  const { responseData } = useGetData();

  const isMoving = responseData?.idtipoaviso === ID_MOVING_FLOW;

  return (
    <>
      <Typography sx={{ fontWeight: 700, fontSize: "22px" }}>
        {isMoving ? "Precio cotizado: " : "Contraoferta: "}
        &nbsp;
        {currencyFormat(
          (responseData as NoticeV2NegType)?.preciocontraoferta as number
        )}
      </Typography>
    </>
  );
};

export const SuccessGlobalPrice = ({ data }: { data: NoticeV2NegType }) => {
  const Component =
    componentToRender[(data as NoticeV2NegType).idtipoaviso as number];
  if (!Component) return <></>;

  return (
    <Box sx={{ mt: 2 }}>
      <Component />
    </Box>
  );
};

export const componentToRender: Record<number, () => JSX.Element> = {
  [ID_IN_CITY]: SuccessPrice,
  [ID_BETWEEN_CITIES]: SuccessPrice,
  [ID_MULTI_DESTINY]: MultidestinySucessPrice,
  [ID_MOVING_FLOW]: MovingSuccessPrice,
  [ID_AUCTION_FLOW]: AuctionSuccessPrices,
};
