import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import { useHandleCounterOffer } from "@/pages/noticeV2Negotation/shared/hooks/useHandleCounterOffer";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { currencyFormat } from "@/utils/helpers";
import { Box, FormControl, Typography } from "@mui/material";

export const BtwnCitiesNoticeDriver = () => {
  const { isLoadingNoticeV2, notice } = useGetData();
  const data = notice as NoticeV2NegType;
  const {
    handleOnChange,
    handleDisabled,
    valueInput,
    handleSubmit,
    errorPrice,
  } = useHandleCounterOffer();

  return (
    <>
      <Box sx={{ display: "flex", mt: 2 }}>
        <Typography sx={{ fontWeight: 700 }}>Precio de tu aviso: </Typography>
        <Typography sx={{ ml: 1 }}>
          {currencyFormat(data.aviso.precio)}
        </Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Typography sx={{ fontWeight: 700 }}>Cliente ofrece: </Typography>
        <Typography sx={{ ml: 1 }}>{currencyFormat(data.precio)}</Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <FormControl sx={{ marginY: "16px", width: "100%" }}>
          <Input
            id="standard-basic"
            name="price"
            label={<LabelInputRequired str="Contraoferta (S/)" />}
            value={valueInput}
            variant="standard"
            placeholder={"Precio que ofreces"}
            onChange={handleOnChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              required: true,
            }}
            error={!!errorPrice}
            helperText={errorPrice}
          />
        </FormControl>
      </Box>

      <ButtomLoader
        fullWidth
        variant="contained"
        isLoading={isLoadingNoticeV2}
        onClick={handleSubmit}
        disabled={handleDisabled() || isLoadingNoticeV2}
      >
        CONTRAOFERTAR
      </ButtomLoader>
    </>
  );
};
