import { IMG_PATH, LG_IMG } from "@/consts/defaultConsts";
import { IMG_PATH_RESIZED, resizePhoto } from "@/utils/helpers";
import { Box, Modal, styled } from "@mui/material";
import { SyntheticEvent } from "react";

const MainModal = styled(Modal)(
  () => `
    &.MuiModal-root {
      background-color: rgba(0, 0, 0, .75);
    }
  `
);

const ContainerModal = styled(Box)(
  () => `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 720px;
    
    padding: 0;
    line-height: 0;
    outline: none;

    @media only screen and (max-width: 750px) {
      width: calc(100% - 40px);
    }
  `
);

const StyleIcon = styled("div")(
  () => `
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    right: -18px;
    top: -18px;
    padding: 8px;
  `
);

const Image = styled("img")(
  () => `
    border-radius: 8px;
    max-width: 720px;
    max-height: 540px;
    width: 100%;
  `
);

type Props = {
  imageUrl: string;
  onClose: () => void;
  alt?: string;
};

export const ImageModal = ({
  imageUrl,
  onClose,
  alt = "Detalle del vehiculo",
}: Props) => {


  const handleErrorImg = (e: SyntheticEvent<HTMLImageElement, Event>, imageUrl: string) => {
    (e.target as HTMLInputElement).src = resizePhoto({
      photo: imageUrl,
      newFormat: ".",
      imgPath: IMG_PATH_RESIZED,
      imgPathResized: IMG_PATH,
    }).replace(LG_IMG, ".");
  };

  return (
    <MainModal onClose={onClose} open={!!imageUrl}>
      <ContainerModal>
        <StyleIcon>
          <i className="icon-alert-close-warning" onClick={onClose}></i>
        </StyleIcon>
        <Image
          src={imageUrl}
          onError={(e) => {
            handleErrorImg(e, imageUrl);
          }}
          alt={alt}
        />
      </ContainerModal>
    </MainModal>
  );
};
