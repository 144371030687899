import { styled, Card, Typography, Grid } from "@mui/material";

export const Item = styled(Card)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  minHeight: "90px",
  width: "100%",
  padding: "0px 16px",
  boxSizing: "border-box",
  boxShadow: theme.palette.customShadow.main,
  borderRadius: "24px",
  gap: "8px",
  "@media only screen and (min-width: 899px)": {
    padding: "32px 16px",
    flexDirection: "column",
    minHeight: "316px",
  },
}));

export const ItemIcon = styled("i")(() => ({
  minWidth: "40px",
  "@media only screen and (min-width: 899px)": {
    minWidth: "72px",
  },
}));

export const ContainerText = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2px",
  "@media only screen and (min-width: 899px)": {
    padding: "16px 0px",
    gap: "8px",
  },
}));

export const ItemTitle = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.body1.fontFamily,
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.body1.fontWeight,
  lineHeight: theme.typography.body1.lineHeight,
  "@media only screen and (min-width: 899px)": {
    fontFamily: theme.typography.label1.fontFamily,
    fontSize: theme.typography.label1.fontSize,
    fontWeight: theme.typography.label1.fontWeight,
    lineHeight: theme.typography.label1.lineHeight,
    textAlign: "center",
    minHeight: "60px",
  },
}));

export const ItemSubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.body2.fontFamily,
  fontSize: theme.typography.body2.fontSize,
  fontWeight: theme.typography.body2.fontWeight,
  lineHeight: theme.typography.body2.lineHeight,
  color: "rgba(0, 0, 0, 0.6)",
}));
