import { ConfigDataAcceptance } from "@/pages/acceptanceOffert/interfaces/acceptanceInterface";
import { ConfigDataApplication } from "@/pages/customerRequest/application/config";
import { ConfigDataNegotation } from "@/pages/noticeRequestFlow/interface/noticeNegRequestInterface";
import { FC } from "react";
import { ConfigDataBodyOffert } from "./offertBodyConfirmation/configDataOffertBodyInCity";
import { ConfigDataBodyMultiDestinationOffer } from "../../../flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/childComponents/FormConfirmationDetails/configurations/configMultiDestination";
import { ConfigDataBodyMovingOffer } from "./offertBodyConfirmation/configBetweenCities";
import { ConfigDataBodyAuctionOffert } from "./offertBodyConfirmation/configDataAuction";

type Props =
  | ConfigDataBodyOffert
  | ConfigDataAcceptance
  | ConfigDataApplication
  | ConfigDataNegotation
  | ConfigDataBodyMultiDestinationOffer
  | ConfigDataBodyMovingOffer
  | ConfigDataBodyAuctionOffert;

const DynamicComponent: FC<Props> = ({ children, ...configData }) => {
  if (!configData) return <></>;

  const CustomComponent = configData.Component;

  if (configData?.title || configData.props?.title || children) {
    return (
      <CustomComponent {...configData.props}>
        {configData?.title || configData.props?.title || children}
      </CustomComponent>
    );
  }

  return <CustomComponent {...configData.props} />;
};

export default DynamicComponent;
