import { styled, Button } from "@mui/material";

export const StyledButtomLoader = styled(Button)(
  () => `
  border-radius: 44px;
  padding: 10px 16px;
  width: 100%;
  line-height: 24px;
  margin-top: 16px;
  `
);
