import React from "react";
import { styled, Typography, SxProps, Theme } from "@mui/material";

const Terms = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.text.disabled};

    & a {
      color: ${theme.palette.primary.main};
      text-decoration: none;
      font-weight: bold;
    }
  `
);

type Props = {
  sx?: SxProps<Theme>;
};

const ReCaptchaTerms = ({ sx }: Props) => {
  return (
    <Terms variant="caption" sx={sx}>
      Este sitio está protegido por reCAPTCHA y se aplican las{" "}
      <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
        Política de privacidad
      </a>{" "}
      y
      <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">
        {" "}
        Términos del servicio
      </a>{" "}
      de Google.
    </Terms>
  );
};

//Este sitio está protegido por reCAPTCHA y se aplican las
// Política de privacidad y Términos del servicio de Google.
export default ReCaptchaTerms;
