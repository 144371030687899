import { ButtonProps, CircularProgress } from "@mui/material";
import { FC } from "react";
import { StyledButtomLoader } from "./styleButtomLoader";

interface Props extends ButtonProps {
  isLoading?: boolean;
  sizeLoading?: string;
  children: JSX.Element | string;
  onClick?: (e: any) => void;
  buttomProps?: Object;
  tipoAvisoId?: number;
  mundanzaTexto?: string;
  contraofertaTexto?: string;
}

const mudanzaId = 5;

const ButtomLoader: FC<Props> = (props) => {
  const {
    color = "org",
    variant = "contained",
    isLoading = false,
    sizeLoading = "20px",
    children,
    onClick,
    tipoAvisoId,
    mundanzaTexto,
    contraofertaTexto,
    buttomProps,
    ...rest
  } = props;

  const handleClick = (e: any) => {
    onClick && onClick(e);
  };

  const validTextFlow = tipoAvisoId === mudanzaId && children === contraofertaTexto;

  return (
    <StyledButtomLoader disableRipple variant={variant} color={color} onClick={handleClick} {...buttomProps} {...rest}>
      <>{isLoading ? <CircularProgress color="inherit" size={sizeLoading} /> : <>{validTextFlow ? mundanzaTexto : children}</>}</>
    </StyledButtomLoader>
  );
};

export default ButtomLoader;
