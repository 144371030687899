import { Detalle, IRatingDetailConvert, IRatingUserHistory, IAboutRating, Detalles, Calificacione } from "@/models/ratingAgreement";
// retornar solo
export const detailsRatingAgreementAdapter = (detalles: Detalle[]) : IRatingDetailConvert[] => {
    return detalles.map(det => {
        return {
            idTipoCalificacion: det.idTipoCalificacion,
            flgCheck: det.flgCheck
        }
    } )
}
// IRatingUserHistory
// refactorizar la data para que sea leible
export const userHistoryRatingAdapter = (data: IAboutRating) : IRatingUserHistory => {
    let ratings: Detalles[] = [];
    const {
        subtitle1,
        subtitle2,
        detalle1,
        detalle2,
    } = data;

    if(subtitle1 && detalle1) {
        ratings.push({
            title: subtitle1,
            detalle: detalle1
        })
    }
    if(subtitle2 && detalle2) {
        ratings.push({
            title: subtitle2,
            detalle: detalle2
        })
    }


    return {
        title: data.title,
        perfil1: data.perfil1,
        puntaje: data.puntaje,
        nombreyapellidos: data.nombreyapellidos,
        descripcion: data.descripcion,
        calificaciontexto: data.calificaciontexto,
        ratings
    }
}

export const ratingDetailsToggleTrue = (data: Calificacione) : Calificacione => {
    const { detalle, puntaje } = data;
    // solo los que esten flgNegativo = 0
    const newDetalle = detalle.map(det => {
        return {
            ...det,
            flgCheck: det.flgNegativo === 0 ? 1 : 0
        }
    })
    return {
        ...data,
        detalle: puntaje === 0 ? newDetalle : detalle
    }
}