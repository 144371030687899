import { counterOfferId, offerId } from '@/consts/noticeConsts';
import { ResponseAuctionNoticeOffer } from '@/models/offert/offertModels';
import { currencyFormat } from '@/utils/helpers';

export const getFormatPrice = (
  _: number,
  value: any,
  offer: ResponseAuctionNoticeOffer
) => {
  const { precio, idtipoavisonegociacion } = offer;
  const formatedPrice = currencyFormat(precio);
  if (idtipoavisonegociacion === counterOfferId) {
    return `Contraoferta: ${formatedPrice}`;
  }

  return formatedPrice;
};

export const showDetailDescound = (
  _: number,
  value: any,
  offer: ResponseAuctionNoticeOffer
) => {
  return value === offerId;
};
