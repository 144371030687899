import { offerNoticeNegAuctionRouter } from './acceptOffer/auctionRouter';
import { offerNoticeNegBetweenCitieRouter } from './acceptOffer/betweenCitiesRouter';
import { offertNoticeNegInCityFlowRouter } from './acceptOffer/inCityFlowRouter';
import { offerNoticeNegMovingRouter } from './acceptOffer/movingRouter';
import { offerMultiDestinyNoticeNegFlowRouter } from './acceptOffer/multidestinyRouter';
import { counterOfferNoticeNegAuctionRouter } from './counterOffer/auctionRouter';
import { counterOfferNoticeNegBetweenCitieRouter } from './counterOffer/betweenCitiesRouter';
import { counterOffertNoticeNegInCityFlowRouter } from './counterOffer/inCityFlowRouter';
import { counterOfferNoticeNegMovingRouter } from './counterOffer/movingRouter';
import { counterOfferMultiDestinyNoticeNegFlowRouter } from './counterOffer/multidestinyRouter';

//=================================================================================================
//-----------------------CLIENTE ACEPTA O CONTRAOFERTA EL AVISO DEL TRANSPORTISTA------------------
//=================================================================================================

export const CLIENT_ACCEPT_OR_COUNTEROFFER = [
  //==============================================================
  //--------------------- -EN LA CIUDAD---------------------------
  //==============================================================

  ...offertNoticeNegInCityFlowRouter,
  ...counterOffertNoticeNegInCityFlowRouter,

  //==============================================================
  //--------------------- -ENTRE CIUDADES-------------------------
  //==============================================================
  ...offerNoticeNegBetweenCitieRouter,
  ...counterOfferNoticeNegBetweenCitieRouter,

  //==============================================================
  //-----------------------MULTIDESTINO---------------------------
  //==============================================================
  ...offerMultiDestinyNoticeNegFlowRouter,
  ...counterOfferMultiDestinyNoticeNegFlowRouter,

  //==============================================================
  //--------------------------MUDANZA-----------------------------
  //==============================================================
  ...offerNoticeNegMovingRouter,
  ...counterOfferNoticeNegMovingRouter,

  //==============================================================
  //--------------------------REMATE------------------------------
  //==============================================================
  ...counterOfferNoticeNegAuctionRouter,
  ...offerNoticeNegAuctionRouter,
];
