import { Stack, styled, Typography } from "@mui/material";


export const ConatinerDetails = styled(Stack)(
    ({ theme }) => {
        const { spacing } = theme;
        return {
            justifyContent: "space-between",
            display: "flex",
            alignItems: "flex-start",
            marginTop: spacing(2),
            marginBottom: spacing(2),
        }
    }
);

export const ButtonContactItem = styled("a")(
    ({ theme }) => {
        const { palette, typography } = theme;
        return {
            color: "white",
            fontWeight: "bold",
            fontSize: typography.body1.fontSize,
            lineHeight: typography.body1.lineHeight,
            textDecoration: "none",
            // button
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            padding: "0px 16px",
            borderRadius: "44px",
            height: "44px",
            textTransform: "uppercase",
            "&:hover": {
                opacity: 0.8,
            },
        }
    }
);