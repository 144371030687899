import { handleValidAcceptanceProps } from '../interfaces/acceptanceInterface';

export const handleValidAcceptance = (
  propsValidAcceptance: handleValidAcceptanceProps
) => {
  const {
    config: { valid, props },
    savedOffert,
    user,
  } = propsValidAcceptance;

  if (!valid || !savedOffert) return { props };

  const moreProps = valid.reduce((acc, el) => {
    let value = savedOffert[el.property];

    if (el?.callback) {
      value = el.callback(savedOffert, el?.callbakcParams || user);
    }

    if (typeof el.defaultResult === 'boolean') {
      acc = {
        ...acc,
        [el.componentProp]: !!value,
      };
    } else {
      acc = {
        ...acc,
        [el.componentProp]: value || el.defaultResult,
      };
    }
    return acc;
  }, {});

  return { props: { ...props, ...moreProps } };
};
