import { useLocation, useNavigate } from 'react-router-dom';
import {
  NOTICE_AGREEMENT_CONFIRMATION,
  NOTICE_AGREEMENT_MESSAGE,
} from '../shared/const/routes';
import { clientText, driverText } from '@/consts/noticeConsts';

const useAgreementMessageHook = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isDriver = pathname.includes('mis-avisos');
  const textToRender = isDriver ? clientText : driverText;

  const handleButton = () => {
    let path = pathname.replace(
      NOTICE_AGREEMENT_MESSAGE,
      NOTICE_AGREEMENT_CONFIRMATION
    );
    navigate(path, { replace: true });
  };
  return {
    handleButton,
    textToRender,
  };
};

export default useAgreementMessageHook;
