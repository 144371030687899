import { Detalles } from '@/models/ratingAgreement';
import { List, ListItem, ListItemButton, ListItemText, Rating, Typography } from "@mui/material";

interface IListRatingsProps {
    ratings: Detalles[];
}

const ListRatings = ({ ratings }: IListRatingsProps) => {
    if (ratings.length === 0) {
        return (
            <Typography variant="body2" sx={{ mt: 2 }}>
                Aún no tiene calificaciones
            </Typography>
        );
    }
    return <>
        {ratings.map(({
            detalle,
            title: titlePrincipal
        }) => (
            <>
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                    {titlePrincipal}
                </Typography>
                <List
                    sx={{
                        width: "100%",
                        padding: 0,
                        margin: "16px 2px",
                    }}
                >
                    {detalle.map(({
                        fecha,
                        puntaje,
                        title,
                    }, index) => (
                        <ListItem
                            disablePadding
                            sx={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.18)",
                                ".css-cveggr-MuiListItemIcon-root": {
                                    minWidth: "5px",
                                },
                                height: "97px",
                            }}
                            key={index}
                        >
                            <ListItemButton>
                                <ListItemText
                                    primary={
                                        <div>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontSize: 14,
                                                    alignItems: "center",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap"
                                                }}
                                            >
                                                {title}
                                            </Typography>
                                            <Rating name="half-rating" defaultValue={puntaje} readOnly precision={0.5} size="small" />
                                        </div>
                                    }
                                    secondary={
                                        <Typography variant="caption" sx={{ color: "#9E9E9E" }}>
                                            {fecha}
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </>
        ))
        }
    </>
}

export default ListRatings
