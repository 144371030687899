import ButtonsService from "@/components/viewService/buttonsService";
import Dscto from "@/components/viewService/dscto/Dscto";
import { ID_AUCTION_FLOW } from "@/consts/defaultConsts";
import {
  DetailsCoreModel,
  NoticeDetailAuctionModel,
} from "@/models/notice/noticeModels";
import { ButtonsDetails } from "@/pages/viewService/city/interface/CityInterface";
import { Grid, Stack, styled } from "@mui/material";
import { FC } from "react";
import Price from "./price/Price";

const ContainerGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const ContainerStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

type ContainerProps = {
  children: JSX.Element | JSX.Element[];
  typeContainer: "grid" | "stack";
};

const ContainerLastSection: FC<ContainerProps> = ({
  children,
  typeContainer,
}) => {
  return typeContainer === "grid" ? (
    <ContainerGrid item md={4} sx={{ pt: { xs: "16px", md: "32px" } }}>
      {children}
    </ContainerGrid>
  ) : (
    <ContainerStack sx={{ pt: { xs: 0, md: 4 } }}>{children}</ContainerStack>
  );
};

type Props = {
  data: DetailsCoreModel;
  buttonsItems: ButtonsDetails[];
  typeContainer?: ContainerProps["typeContainer"];
  children?: JSX.Element | JSX.Element[] | string;
};

const LastSectionButtons: FC<Props> = ({
  data,
  buttonsItems,
  typeContainer = "grid",
  children,
}) => {
  const handleGetDescountProps = () => {
    const newData: NoticeDetailAuctionModel = {
      fechafija: "",
      precioregular: 0,
      porcentajedescuento: 0,
      ...data,
    };

    return {
      before: `${newData?.precioregular}`,
      dscto: `${newData.porcentajedescuento}`,
      price: newData.precio.toString(),
    };
  };

  return (
    <ContainerLastSection typeContainer={typeContainer}>
      {data?.tipoaviso.id === ID_AUCTION_FLOW ? (
        <Dscto {...handleGetDescountProps()} />
      ) : (
        <Price data={data} />
      )}

      <>{children}</>

      <ButtonsService
        typeOffertId={data.tipoaviso.id}
        listButtons={buttonsItems}
      />
    </ContainerLastSection>
  );
};

export default LastSectionButtons;
