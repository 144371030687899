import DetailsTextV2 from '@/components/DetailsTextV2/Index';
import { ImagesNegV2 } from '@/pages/noticeV2Negotation/components/ImagesNegV2';
import { ConfigDataNegotationV2 } from '@/pages/noticeV2Negotation/shared/hooks/useNoticeConfigDataV2';
import { Typography } from '@mui/material';

import {
  getInfoVehicleNegV2,
  getMaximumCapacity,
} from '@/pages/noticeV2Negotation/shared/utils/utils';

export const generalSuccessConfigDataV2: ConfigDataNegotationV2[] = [
  {
    title: 'Detalles del servicio',
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
      sx: { marginTop: '10px', display: 'block' },
    },
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-calendar',
      title: 'Fecha',

      originDescription: '',
    },
    valid: [
      {
        property: 'fecha',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'fecha',
        componentProp: 'origen',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-light',
      title: 'Origen',
      containerSx: {
        alignItems: 'flex-start',
      },
    },
    valid: [
      {
        property: 'origenTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'origenTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referenciaOrigen',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-location-dark',
      title: 'Destino',
      containerSx: {
        alignItems: 'flex-start',
      },
    },
    valid: [
      {
        property: 'destinoTexto',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'destinoTexto',
        componentProp: 'origen',
        defaultResult: '',
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
      },
    ],
  },
  {
    Component: DetailsTextV2,
    props: {
      icon: 'icon-truck',
      title: 'Vehículo',
      containerSx: {
        alignItems: 'flex-start',
      },
      sxDescription: {
        color: '#757575',
        fontSize: '14px',
      },
      parent: false,
    },
    valid: [
      {
        property: 'aviso',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'aviso',
        componentProp: 'origen',
        defaultResult: '',
        callback: getInfoVehicleNegV2,
      },
      {
        property: 'aviso',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getMaximumCapacity,
      },
    ],
  },
  {
    title: 'Descripción de la carga',
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
      sx: { marginTop: '10px', display: 'block' },
    },
  },
  {
    Component: ImagesNegV2,
    props: {
      style: { marginTop: '13px' },
    },
    valid: [
      {
        property: 'fotos',
        componentProp: 'images',
        defaultResult: [],
      },
    ],
  },

  {
    Component: Typography,
    props: {
      sx: {
        marginTop: '10px',
        display: 'block',
        wordWrap: 'break-word',
        maxWidth: '320px',
        fontWeight: 'none',
      },
    },
    valid: [
      {
        property: 'descripcionCarga',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
];
