import { BASE_URL_IMAGE } from "@/config/config";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";

type Props = {
  onSeleted?: (value: any) => void;
  list: any[];
  selects: any[];
};

const CheckList = (props: Props) => {
  const { onSeleted, list, selects = [] } = props;

  const handleClick = (value: any) => () => {
    onSeleted && onSeleted(value);
  };

  const handleValidCheck = (currentValue: string) => {
    if (!Array.isArray(selects)) return false;
    const isChecked = selects.some(({ nombre }) => nombre === currentValue);

    return isChecked;
  };

  return (
    <>
      {list.map((item, i) => (
        <FormControlLabel
          key={`checkbox-${i}`}
          label={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
              }}
            >
              {item.urlicono && (
                <img
                  src={`${BASE_URL_IMAGE}${item.urlicono}`}
                  alt={item?.nombre}
                />
              )}
              <Typography variant="body2">{item?.nombre}</Typography>
            </Box>
          }
          control={
            <Checkbox
              color="warning"
              inputProps={{ "aria-label": item?.nombre }}
              onClick={handleClick({ ...item })}
              checked={handleValidCheck(item?.nombre)}
            />
          }
        />
      ))}
    </>
  );
};

export default CheckList;
