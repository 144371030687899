import {
  BodyNoticesActivesAndHistoryDTO,
  BodyOffertToCarriersListDTO,
} from '@/interfaces/noticeInterface';
import { ICustomerRequestOffertAPI } from '@/models/customerRequest/customerRequestOffertPaginateResponse';
import { INoticeOffertPaginateAPI } from '@/models/notice/noticeOffertPaginateResponse';
import {
  IAcuerdosporaceptar,
  ISetAgreementToAcceptBody,
} from '@/models/userAuth/agrementToAccept';

export type NoticeListBodyAdapter = {
  page: number;
  size?: number;
  opcionesnumero: 0 | 1; // 1 -> actives, 0 -> history
  isPaginate?: boolean;
};
export const noticeListBodyAdapter = ({
  page,
  opcionesnumero,
  size = 12,
  isPaginate,
}: NoticeListBodyAdapter): BodyNoticesActivesAndHistoryDTO => {
  const currentPage = isPaginate ? page + 1 : page;
  const body = {
    size,
    page: currentPage,
    ordenamiento: ['id', 'desc'],
    tiposFiltro: [
      {
        nombre: 'Abierto o Cerrado',
        opciones: [],
        opcionesnumero: [opcionesnumero],
      },
    ],
  };

  return body;
};

// My offers to carriers
export const myOffersToCarriersListAdapter = ({
  page,
  opcionesnumero,
  size = 12,
  isPaginate,
}: NoticeListBodyAdapter): BodyNoticesActivesAndHistoryDTO => {
  const currentPage = isPaginate ? page + 1 : page;
  const body: BodyNoticesActivesAndHistoryDTO = {
    size,
    page: currentPage,
    ordenamiento: ['id', 'desc'],
    tiposFiltro: [
      {
        nombre: 'Abierto o Cerrado',
        opciones: [],
        opcionesnumero: [opcionesnumero],
      },
    ],
  };

  return body;
};

interface MyRequestsToClient extends ISetAgreementToAcceptBody {
  error: boolean;
}
export const responseMyRequestsToClientsAdapter = (
  { solicitudPaginadoData }: ICustomerRequestOffertAPI,
  agreementToAccepts: IAcuerdosporaceptar[],
  currentPage: number
): MyRequestsToClient => {
  const { solicitudes = [] } = solicitudPaginadoData;
  const acuerdosporaceptar =
    currentPage === 0 ? solicitudes : [...agreementToAccepts, ...solicitudes];

  return {
    ...solicitudPaginadoData,
    acuerdosporaceptar,
    error: !solicitudes.length,
  };
};

export const myOffertsToDirversAdapter = (
  { avisonegociacionData }: INoticeOffertPaginateAPI,
  agreementToAccepts: IAcuerdosporaceptar[],
  currentPage: number
): MyRequestsToClient => {
  const { avisonegociaciones = [] } = avisonegociacionData;
  const acuerdosporaceptar =
    currentPage === 0
      ? avisonegociaciones
      : [...agreementToAccepts, ...avisonegociaciones];

  return {
    ...avisonegociacionData,
    acuerdosporaceptar,
    error: !avisonegociaciones.length,
  };
};
