import { ConfigDataNegotationV2 } from '@/pages/noticeV2Negotation/shared/hooks/useNoticeConfigDataV2';
import { generalConfigData } from '@/pages/noticeV2Negotation/shared/config/generalConfig';

export const detailsConfigAccept: ConfigDataNegotationV2[] = [
  ...generalConfigData,
];

export const detailsConfigCounterOffer: ConfigDataNegotationV2[] = [
  ...generalConfigData,
];
