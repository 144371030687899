import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { StyleCustomInput } from "@/components/inputCustom/InputCustom";
import LabelRequired from "@/components/utils/LabelRequired";
import { withoutPriceDefault } from "@/consts/noticeConsts";
import { Box, FormControl, Grid, Typography, styled } from "@mui/material";
import { useFormClientCounterOfferMultidestiny } from "./useFormClientCounterOfferMultidestiny";

const Container = styled(Grid)(
  () => `
      display: flex;
      flex-direction: column;  
      margin-top: 24px;
    `
);

export const FormCounterOfferMultiEdition = () => {
  const {
    isLoading,
    typeOfMultidestiny,
    preciocontraoferta,
    handleOnchange,
    handleDisabledEditCounterOfferButton,
    handleEditCounterOfferSubmit,
    amount,
  } = useFormClientCounterOfferMultidestiny();

  const handlePriceText = () => {
    return !preciocontraoferta
      ? withoutPriceDefault
      : Number(preciocontraoferta) * (amount as number);
  };

  return (
    <Container container>
      <form onSubmit={handleEditCounterOfferSubmit}>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: 900, fontSize: "14px" }}>
            Cantidad de {typeOfMultidestiny}s: &nbsp;{" "}
          </Typography>
          <Typography>{amount}</Typography>
        </Box>

        <FormControl sx={{ marginTop: 1, mb: 2, width: "100%" }}>
          <StyleCustomInput
            id={`$1-price`}
            name="price"
            label={
              <LabelRequired
                str={`Precio por ${typeOfMultidestiny}s que ofreces`}
              />
            }
            value={preciocontraoferta}
            variant="standard"
            placeholder={`Ingresa tu precio por ${typeOfMultidestiny}`}
            onChange={handleOnchange}
            onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()} //evita que se escriban caracteres no numéricos(+-.,)//evita que se escriban caracteres no numéricos(+-.,)
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
            }}
          />
        </FormControl>

        <Box sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: 900, fontSize: "16px" }}>
            Precio del servicio: &nbsp;{" "}
          </Typography>
          <Typography>{handlePriceText()}</Typography>
        </Box>

        <Grid item sx={{ marginTop: "16px" }}>
          <ButtomLoader
            isLoading={isLoading}
            type="submit"
            buttomProps={{
              disabled: handleDisabledEditCounterOfferButton() || isLoading,
            }}
          >
            ENVIAR
          </ButtomLoader>
        </Grid>
      </form>
    </Container>
  );
};
