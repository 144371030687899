import { ICardFilterProps } from "@/components/CardFilter";
import { ISolicitudeFilterData } from "@/models/customerRequest/requestListFilterDataResponse";
import { adapterMyCardRequestData } from "./Adapters/CardAdapterData";

const useRequestFilterHook = () => {

    const adapterCardFilter = (rqs: ISolicitudeFilterData): ICardFilterProps => {
        return adapterMyCardRequestData(rqs);
    }

    return {
        adapterCardFilter
    }
}

export default useRequestFilterHook;