import {
  DestinisAPI,
  NoticeResponseSaveFlowAPI,
} from '@/models/notice/noticeModels';
import {
  ISaveNegotiationMovingServices,
  ISaveNegotiationMultiDestinoServices,
  NegotiationPhotosProps,
  OffertNoticeConditions,
  SaveCounterOffertAuctionServices,
  SaveNegotiationServices,
  SaveOffertAuctionServices,
} from '@/services/interfaces/negotiationInterface';
import {
  IFile,
  IOffertInitialState,
  PhotosPreLoaded,
} from '@/store/offertRedux/offert.interface';
import {
  blobToFile,
  getMultiPromises,
  stringBase64ToBlob,
} from '@/utils/helpers';

import { BASE_URL_IMAGE_S3 } from '@/config/config';
import { EditPhotosType } from '@/interfaces/globalInterface';
import { PhotosVerified } from '@/models/noticeNegotation/noticeNegotiationImagesModel';
import {
  NoticeConditions,
  OffertConditions,
} from '@/models/offert/offertConditionsModels';
import { saveImagesNegotiationServices } from '@/services/noticeNegotationRequestServices';
import { saveImagesCustomerRequestServices } from '@/services/customerRequestServices';

export const defaultResponse = {
  foto1: undefined,
  foto2: undefined,
  foto3: undefined,
};

export const getPhotosOffertAdapter = (
  photos: Array<File | string | PhotosPreLoaded>
): NegotiationPhotosProps => {
  const files = prepareArrayPhotos(photos);

  const result = convertArrayToObjectPhotos(files);

  return { ...defaultResponse, ...result };
};

export const prepareArrayPhotos = (
  photos: Array<File | string | PhotosPreLoaded>
) => {
  let index = 0;
  return photos.map((photo) => {
    index++;
    if ((photo as PhotosPreLoaded)?.url) {
      const currentPhoto = photo as PhotosPreLoaded;
      const uniqueNumberPhoto = currentPhoto.name.length;

      const comparePositionPhoto = currentPhoto.name.substring(
        uniqueNumberPhoto - 1,
        uniqueNumberPhoto
      );

      if (index !== Number(comparePositionPhoto)) {
        index--;
      }

      return {
        [currentPhoto.name]: currentPhoto.url,
      };
    }

    return { [`foto${index}`]: photo };
  });
};

export const convertArrayToObjectPhotos = (
  files: {
    [x: string]: string | File | PhotosPreLoaded;
  }[]
) => {
  return files.reduce((acc, el) => {
    if (el) {
      acc = { ...acc, ...el };
    }
    return acc;
  }, {});
};

export const prepareListPhotos = (
  { foto1, foto2, foto3 }: Pick<PhotosVerified, 'foto1' | 'foto2' | 'foto3'>,
  namePhotos?: string[]
): PhotosPreLoaded[] => {
  const photos = { foto1, foto2, foto3 };
  const validPhotos = Object.values(photos)
    .filter((photo) => photo)
    .map((photo, index) => ({
      name: !!namePhotos?.length ? `${namePhotos[index]}` : `foto${index + 1}`,
      url: `${BASE_URL_IMAGE_S3}${photo}`,
    }));

  return validPhotos as [];
};

export const getBodyOffertPhotoAdapter = async (
  data: IFile[] | EditPhotosType[]
): Promise<NegotiationPhotosProps> => {
  const files = await setStructureBodyOffertPhotoAdapter(data);
  const buffert = await getMultiPromises(files);
  const result = getBodyOffertPhotoByBuffert(buffert);

  return result;
};

const setStructureBodyOffertPhotoAdapter = async (
  data: IFile[] | EditPhotosType[]
) => {
  const files = data.map(async (currentFile, i) => {
    const photoBlob = await stringBase64ToBlob(currentFile.blob);
    const name =
      (currentFile as IFile)?.name || (currentFile as EditPhotosType)?.file;
    const photo = blobToFile(photoBlob, name);

    return {
      [`foto${i + 1}`]: photo,
    };
  });

  return files;
};

const getBodyOffertPhotoByBuffert = (buffert: PromiseSettledResult<any>[]) => {
  const defaultResponse = {
    foto1: undefined,
    foto2: undefined,
    foto3: undefined,
  };
  const result = buffert.reduce((acc, el) => {
    if (el.status === 'fulfilled') {
      acc = { ...acc, ...el.value };
    }
    return acc;
  }, {});

  return { ...defaultResponse, ...result };
};

//"idTipoAvisoNegociacion": 1,
// 1 => aceptar
// 2 => contraofertar
export const getBodyOffertInCityByOffertStorage = (
  offertData: IOffertInitialState
): SaveNegotiationServices => {
  const {
    idTipoAvisoNegociacion,
    descripcionCarga,
    pesoEstimado,
    fecha,
    referenciaOrigen,
    referenciaDestino,
    selectedOrigin,
    selectedDestiny,
    precio,
    idVerifiedImages,
  } = offertData;

  const currentPrice =
    idTipoAvisoNegociacion === 2 ? Number(precio) : undefined;

  return {
    idTipoAvisoNegociacion,
    descripcionCarga,
    pesoEstimado,
    fecha,
    referenciaOrigen,
    referenciaDestino,
    idOrigen: selectedOrigin.iddistrito,
    idDestino: selectedDestiny.iddistrito,
    precio: currentPrice,
    idfotosverificadas: idVerifiedImages,
  };
};

// mulitdestino
const sortDestiny = (a: DestinisAPI) => {
  return {
    iddistrito: a.iddistrito,
    idzona: a.idzona,
  };
};
export const getBodyOffertMultiDestinyByOffertStorage = (
  offertData: IOffertInitialState
): ISaveNegotiationMultiDestinoServices => {
  const {
    idTipoAvisoNegociacion,
    descripcionCarga,
    pesoEstimado,
    fecha,
    referenciaOrigen,
    referenciaDestino,
    selectedOrigin,
    avisodestinos,
    precio,
    puntos,
    horas,
    idVerifiedImages,
  } = offertData;

  return {
    idTipoAvisoNegociacion,
    descripcionCarga,
    pesoEstimado,
    fecha,
    referenciaOrigen,
    referenciaDestino,
    precio: Number(precio),
    avisoorigenes: [sortDestiny(selectedOrigin)],
    avisodestinos: avisodestinos.map((currentDestiny) =>
      sortDestiny(currentDestiny)
    ),
    puntos: puntos === 0 ? null : Number(puntos),
    horas: puntos === 0 ? null : Number(horas),
    idfotosverificadas: idVerifiedImages,
  };
};

export const getBodyOffertAuctionAdapter = (
  offertData: IOffertInitialState,
  saveFlowData: NoticeResponseSaveFlowAPI[]
): SaveOffertAuctionServices => {
  const {
    idTipoAvisoNegociacion,
    descripcionCarga,
    pesoEstimado,
    idVerifiedImages,
  } = offertData;

  return {
    idTipoAvisoNegociacion,
    descripcionCarga,
    pesoEstimado,
    idOrigen: saveFlowData[0].idorigen,
    idDestino: saveFlowData[0].iddestino as number,
    fecha: saveFlowData[0].fechafija as string,
    idfotosverificadas: idVerifiedImages,
  };
};

export const getBodyCounterOffertAuctionAdapter = (
  offertData: IOffertInitialState,
  saveFlowData: NoticeResponseSaveFlowAPI[]
): SaveCounterOffertAuctionServices => {
  const {
    idTipoAvisoNegociacion,
    descripcionCarga,
    pesoEstimado,
    precio,
    idVerifiedImages,
  } = offertData;
  const price = Number(precio || 0);
  const { idDestino, idOrigen, ...conditionsAndLocation } =
    getCounterOffertAuctionConditionsNegotiations(
      offertData.conditions,
      offertData
    );

  const result = {
    idTipoAvisoNegociacion,
    descripcionCarga,
    pesoEstimado,
    precio: price,
    fecha: saveFlowData[0].fechafija as string,
    idOrigen: idOrigen || (saveFlowData[0].idorigen as number),
    idDestino: idDestino || (saveFlowData[0].iddestino as number),
    idfotosverificadas: idVerifiedImages,
    ...conditionsAndLocation,
  };

  return result;
};

type Result = {
  avisoNegociacionCondiciones: OffertNoticeConditions[];
  idOrigen?: number;
  referenciaOrigen?: string;
  idDestino?: number;
  referenciaDestino?: string;
};

const getCounterOffertAuctionConditionsNegotiations = (
  listConditions: NoticeConditions[],
  offertData: IOffertInitialState
) => {
  const conditions = listConditions.reduce(
    (acc: Result, { condicionesNegociacion }: NoticeConditions) => {
      const { id, isActive } = condicionesNegociacion;
      acc = {
        ...acc,
        avisoNegociacionCondiciones: getConditionsCounterOffertAuction(
          acc.avisoNegociacionCondiciones,
          id,
          isActive
        ),
      };

      if (isActive) {
        const location = getLocationByCondition(
          condicionesNegociacion,
          offertData
        );

        if (location) {
          acc = {
            ...acc,
            ...location,
          };
        }
      }

      return acc;
    },
    { avisoNegociacionCondiciones: [] }
  );

  return conditions;
};

/**
 * Devuelve un objeto con el id y referencia del origen o destino de la oferta, según la condición
 * @param {OffertConditions} condition - Condiciones de la oferta,
 * @param {IOffertInitialState} offertData - IOfferInitialState
 */
const getLocationByCondition = (
  condition: OffertConditions,
  offertData: IOffertInitialState
) => {
  if (condition.flgorigendestino < 1) return;

  const location: Record<number, any> = {
    1: {
      idOrigen: offertData.selectedOrigin.iddistrito,
      idDestino: offertData.selectedDestiny.iddistrito,
    },
    2: {
      idOrigen: offertData.selectedOrigin.iddistrito,
      referenciaOrigen: offertData.referenciaOrigen,
      idDestino: offertData.selectedDestiny.iddistrito,
      referenciaDestino: offertData.referenciaDestino,
    },
  };

  return location[condition.flgorigendestino];
};

/**
 * Toma un arreglo de objetos, un id y un booleano, y devuelve un nuevo arreglo de objetos con la misma
 * estructura que el anterior, pero con el valor booleano del objeto con el id dado cambiado al
 * booleano dado
 * @param {OffertNoticeConditions[]} prevConditions - Condiciones de aviso de oferta []
 * @param {number} id - número,
 * @param {boolean} isActive - booleano
 * @returns Una matriz de objetos.
 */
const getConditionsCounterOffertAuction = (
  prevConditions: OffertNoticeConditions[],
  id: number,
  isActive: boolean
) => {
  return [
    ...prevConditions,
    {
      idcondiciones: id,
      flgseleccion: Number(isActive),
    },
  ];
};
// moving
export const getBodyOffertMovingByOffertStorage = (
  offertData: IOffertInitialState
): ISaveNegotiationMovingServices => {
  const {
    idTipoAvisoNegociacion,
    fecha,
    ayudantes,
    selectedOrigin, // idOrigen
    referenciaOrigen,
    idInmuebleOrigen,
    flgAscensor,
    piso,
    pisoDestino,
    flgAscensorDestino,
    metraje,
    selectedDestiny, // idDestino
    referenciaDestino,
    idInmuebleDestino,
    idVerifiedImages,
  } = offertData;

  const conditions =
    idTipoAvisoNegociacion === 2
      ? getCounterOffertAuctionConditionsNegotiations(
          offertData.conditions,
          offertData
        )
      : {};

  return {
    idTipoAvisoNegociacion,
    fecha,
    ayudantes,
    idOrigen: selectedOrigin.iddistrito,
    referenciaOrigen,
    idInmuebleOrigen: Number(idInmuebleOrigen),
    flgAscensor: Number(flgAscensor) || null,
    piso: Number(piso) || null,
    pisoDestino: Number(pisoDestino) || null,
    flgAscensorDestino: Number(flgAscensorDestino) || null,

    metraje: Number(metraje),
    idDestino: selectedDestiny.iddistrito,
    referenciaDestino,
    idInmuebleDestino: Number(idInmuebleDestino),
    idfotosverificadas: idVerifiedImages,
    ...conditions,
  };
};

export const getListOffersAdapter = (
  id: number,
  opcionesnumero: number[],
  page: number = 0
) => {
  let body = {
    page,
    size: 12,
    ordenamiento: ['id', 'asc'],
    tiposFiltro: [
      {
        nombre: 'Id Avisos',
        opcionesnumero: [id],
      },
      {
        nombre: 'Abierto o Cerrado',
        opcionesnumero: opcionesnumero,
      },
    ],
  };
  return { body };
};

interface IResponseManagerImage {
  recordedPhotos: PhotosPreLoaded[];
  fotosverificadasId: number;
}


export const manageImageOnSave = async (
  idVerifiedImages: number,
  photosPreLoaded: PhotosPreLoaded[],
  images: File[] = []
): Promise<IResponseManagerImage> => {
   // Combine photosPreLoaded and images into a single array called photos
   const photos = getPhotosOffertAdapter([...photosPreLoaded, ...images]);

   // Filter out properties with undefined values
   const filteredPhotos = Object.fromEntries(
     Object.entries(photos).filter(([_, value]) => value !== undefined)
   );

   // Call saveImagesCustomerRequestServices to save images and get fotosverificadas
   const { fotosverificadas } = await saveImagesCustomerRequestServices(
    idVerifiedImages,
    filteredPhotos
  );
 
  // Get the keys of the filtered photos object
   const photoKeys = Object.keys(filteredPhotos);
 
   // Call prepareListPhotos with fotosverificadas and photoKeys
   const recordedPhotos = prepareListPhotos(fotosverificadas, photoKeys);
 
   // Return the result
   return { recordedPhotos, fotosverificadasId: fotosverificadas.id };
 };