import SkeletonInfoCard from "@/components/infoCard/SkeletonInfoCard";
import useObserver from "@/hooks/useObserver";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import {
  Box,
  Container,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { prepareUrlParams } from "@/adapters/filterAdapter";
import { prepareLinkNoticeOffersAdapter } from "@/adapters/routesAdapters";
import Filter from "@/components/Filter/Filter";
import { filterStyles } from "@/components/Filter/StyleFilter";
import { FormDataFilter } from "@/components/Filter/consts/filtersConst";
import NewInfoCard from "@/components/infoCard/NewInfoCard";
import { SHOW_AFTER } from "@/consts/advertisement";
import { NOTICE_TITLE_MULTIDESTINY } from "@/consts/noticeConsts";
import {
  HOME_PAHT,
  PROFILE_NOTICE_DINAMIC_PUBLIC_PATH,
} from "@/consts/typeServicesPaths";
import { NoticeData } from "@/models/filterNotice/filterNoticeModels";
import AdvertisementListCard from "@/pages/advertisement/advertisementListCard/AdvertisementListCard";
import Footer from "@/pages/footer/Footer";
import { TypeFilterData } from "@/store/FilterServices/filterReduxInterface";
import { setCleanHistoryList } from "@/store/advertisementRedux/advertisementSlice";
import { saveClickOfSponsoredNoticeThunk } from "@/store/advertisementRedux/advertisementThunk";
import {
  resetFilter,
  setTypeNoticeSelect,
} from "@/store/filterNoticeRedux/filterNoticeSlice";
import {
  getInitialFilterList,
  getListNoticeByFilterThunk,
} from "@/store/filterNoticeRedux/filterNoticeThunk";
import SortIcon from "@mui/icons-material/Sort";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import queryString from "query-string";
import SectionInfo from "./components/SectionInfo";
import {
  BoxSectionInfo,
  stackContainer,
  stackOrderByListStyles,
  wrapperProps,
} from "./styleServices";
import {
  prepareBody,
  prepareDataBeforeSendFilter,
} from "./utils/helpersServices";

type InitValues = Partial<FormDataFilter> | null;

const Services = () => {
  const {
    noticeList,
    currentPage,
    totalPages,
    isLoadingNotices,
    orderByList,
    typeNotice,
    typeNoticeSelecte,
    isLoadingTypeFilter,
  } = useAppSelector((state) => state.filterNotice);
  const { pathname, search } = useLocation();

  const [openOrder, setOpenOrder] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [initValues, setInitValues] = useState<InitValues>(null);
  const [firstRender, setFirstRender] = useState(true);
  const [_, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const refObserver = useRef<any>(null);

  const { isNearScreen } = useObserver({
    distance: "100px",
    externalRef: refObserver,
    hasDisconect: true,
  });

  useEffect(() => {
    dispatch(setCleanHistoryList());
    handleInitialComponente();
    return () => {
      dispatch(resetFilter());
      dispatch(setCleanHistoryList());
    };
  }, []);

  useEffect(() => {
    handleInit();
    if (!!typeNotice.length) {
      handleSetValueInit(typeNotice);
    }
  }, [typeNotice]);

  useEffect(() => {
    handleInitRequestList();
  }, [pathname, orderByList]);

  useEffect(() => {
    if (isNearScreen && !isLoadingNotices && currentPage < totalPages) {
      handlePaginate();
    }

    return () => {};
  }, [isNearScreen]);

  useEffect(() => {}, [initValues]);

  const handleInitialComponente = async () => {
    await dispatch(resetFilter());
    handleSetValueInit(typeNotice);
  };

  const handleSetValueInit = (typeNoticeList: TypeFilterData[]) => {
    const values = prepareBody(typeNoticeList, search, typeNoticeSelecte);
    if (!values) return;

    setInitValues(values);
    if (!!typeNotice.length && values?.typeNotice) {
      const selected = typeNotice.find(
        (type) => type.nombre === values.typeNotice
      );

      selected && dispatch(setTypeNoticeSelect(selected));
    }
  };

  const handleInit = async () => {
    const newData = prepareBody(typeNotice, search, typeNoticeSelecte);
    if (!typeNotice.length) {
      const value = await dispatch(getInitialFilterList());
      if (value?.orderBy[0].nombre) {
        setSelectedOrder(value?.orderBy[0].nombre || "");

        if (!!value?.filters?.typeNotice.length) {
          const typeNotice = value?.filters?.typeNotice.find(
            (notice) => notice.nombre === newData?.typeNotice
          );
          if (typeNotice) {
            dispatch(setTypeNoticeSelect(typeNotice));
          }
          handleInitRequestList(value?.orderBy[0].nombre);
        }
      }
    } else if (!!orderByList.length) {
      setSelectedOrder(orderByList[0].nombre);
    }
    firstRender && setFirstRender(false);
  };

  const handleInitRequestList = (order = "") => {
    const newData = prepareBody(typeNotice, search, typeNoticeSelecte);
    if (!!orderByList.length && !!newData) {
      const selectOrder = orderByList.length ? orderByList[0].nombre : order;
      dispatch(
        getListNoticeByFilterThunk(newData as FormDataFilter, {
          pathname,
          selectOrder,
        })
      );
    }
  };

  const handlePaginate = () => {
    const newData = prepareBody(typeNotice, search, typeNoticeSelecte);
    dispatch(
      getListNoticeByFilterThunk(newData as FormDataFilter, {
        isPaginate: true,
        pathname,
        selectOrder: selectedOrder,
      })
    );
  };

  const handlePrepareUrlParams = (values: FormDataFilter): any => {
    const prepareObject = prepareUrlParams(values, typeNoticeSelecte);

    return prepareObject;
  };

  const handleSubmit = (values: any) => {
    dispatch(
      getListNoticeByFilterThunk(values, {
        pathname,
        selectOrder: selectedOrder,
      })
    );
    const newParams = handlePrepareUrlParams(values);

    setSearchParams(newParams);

    dispatch(setCleanHistoryList());
  };

  const handleChangeOrder = ({ target }: SelectChangeEvent) => {
    setSelectedOrder(target.value as string);

    const newData = prepareDataBeforeSendFilter(search);
    if (newData) {
      const url = queryString.stringify(newData);

      setSearchParams(url);
      dispatch(
        getListNoticeByFilterThunk(newData as FormDataFilter, {
          pathname,
          selectOrder: selectedOrder,
        })
      );
    }
  };

  const handleSaveClickSponsored = (data: NoticeData) => {
    if (data?.flgpatrocinio) {
      dispatch(saveClickOfSponsoredNoticeThunk(data));
    }
  };

  const handleNavigationCard = (data: NoticeData) => {
    handleSaveClickSponsored(data);
    const stateParams = handleGetStateParams();

    const path = prepareLinkNoticeOffersAdapter(
      PROFILE_NOTICE_DINAMIC_PUBLIC_PATH,
      data.idtipoaviso,
      data.id
    );

    return { to: path, state: stateParams };
  };

  const handleGetStateParams = () => {
    const searchData = prepareDataBeforeSendFilter(search);
    const destiny = handleGetDestinyToStateNavigation(searchData);
    const stateParams = {
      hasBackNavigate: true,
      destiny,
      timeService: searchData?.timeService || "",
    };

    return stateParams;
  };

  const handleGetDestinyToStateNavigation = (
    searchData: FormDataFilter | null
  ) => {
    if (!searchData || searchData.typeNotice !== NOTICE_TITLE_MULTIDESTINY)
      return "";
    let destiny = `${searchData?.destiny}`;
    if (searchData?.origin) {
      const concat = !!destiny ? "," : "";
      destiny += `${concat}${searchData?.origin}`;
    }

    return destiny;
  };

  const handleOnChangeTypeNotice = (selected: TypeFilterData) => {
    navigate(`${HOME_PAHT}?typeNotice=${selected.nombre}`);
  };

  const handleShowOrderRow = () => {
    return !firstRender && !!noticeList.length && !isLoadingNotices;
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fafafa",
          minHeight: noticeList.length === 0 ? "0px" : "300px",
        }}
      >
        <Filter
          onSubmit={handleSubmit}
          filterStyles={filterStyles}
          show
          initValue={initValues as FormDataFilter}
          onChangeTypeNotice={handleOnChangeTypeNotice}
        />
        <Container maxWidth="xl" sx={{ marginTop: "24px" }}>
          <Stack
            sx={{
              ...stackContainer,
              display: handleShowOrderRow() ? "flex" : "none",
            }}
          >
            <Typography variant="subtitle1">
              Resultados de tu búsqueda
            </Typography>
            <Typography
              variant="body1"
              color="#757575"
              display="flex"
              onClick={() => setOpenOrder(true)}
              sx={{ position: "relative", cursor: "pointer" }}
            >
              <SortIcon color="inherit" /> {selectedOrder}
            </Typography>
            <Stack sx={stackOrderByListStyles}>
              <Select
                value={selectedOrder}
                open={openOrder}
                onClose={() => setOpenOrder(false)}
                variant="outlined"
                onChange={handleChangeOrder}
              >
                {orderByList.map((order, i) => (
                  <MenuItem value={order.nombre} key={`order-${i}`}>
                    {order.nombre}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Stack>
          <Grid container rowGap={2} columnSpacing={2} alignItems="center">
            {noticeList?.map((item, index) => (
              <AdvertisementListCard
                wrapperProps={wrapperProps}
                showAfter={SHOW_AFTER}
                totalLength={noticeList.length}
                index={index}
                key={`info-card-${index}`}
              >
                <NewInfoCard
                  data={item}
                  navigation={handleNavigationCard(item)}
                />
              </AdvertisementListCard>
            ))}
            {(isLoadingNotices || isLoadingTypeFilter) &&
              [...Array(6)].map((_, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <SkeletonInfoCard />
                </Grid>
              ))}

            <div ref={refObserver} />
            <Box sx={{ height: "40px", width: "100%" }}></Box>
          </Grid>
        </Container>
      </Box>
      <BoxSectionInfo>
        <SectionInfo
          isloading={isLoadingNotices || isLoadingTypeFilter}
          list={noticeList}
          firstRender={firstRender}
        />
      </BoxSectionInfo>
      <Footer />
    </>
  );
};

export default Services;
