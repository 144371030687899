import {
  COUNTEROFFER_SEND_SUCCESS_PATH,
  CO_COUNTEROFFER_SEND_PATH,
} from "@/consts/acceptanceOffertPath";
import { IMG_PATH, LG_IMG, MD_IMG } from "@/consts/defaultConsts";
import { PhotoNegotiation } from "@/models/offert/offertModels";
import { resizePhoto } from "@/utils/helpers";
import { styled } from "@mui/material";
import { CSSProperties, SyntheticEvent, useState } from "react";
import { useLocation } from "react-router-dom";
import { ImageModal } from "../modals/ImageModal/ImageModal";
import { Photo } from "@/store/noticeV2ClientAcceptCounterOffer/interfaces/dataResponseEnglishInterfaces";

const Image = styled("img")(
  () => `
  width: 60px;
  height: 60px;
  `
);

type Props = {
  images: Photo[];
  style?: CSSProperties;
  alt?: string;
};


const ImagesThumbnail = ({ images, style, alt = "Carga" }: Props) => {
  const [seletedImage, setSeletedImage] = useState("");
  const location = useLocation();
  const { pathname } = location;

  const handleIsSuccessPath = () => {
    let isNotSuccessPath = true;
    const isCoCounterOfferSuccess = pathname.endsWith(
      CO_COUNTEROFFER_SEND_PATH
    );
    const isCounterOfferSuccess = pathname.endsWith(
      COUNTEROFFER_SEND_SUCCESS_PATH
    );

    if (isCoCounterOfferSuccess || isCounterOfferSuccess) {
      isNotSuccessPath = false;
    }
    return isNotSuccessPath;
  };

  const handleRasizeImg = (image: string) => {
    if (!!image){
      return resizePhoto({
        photo: image,
      })
    }

    return  "";
  };

  const validateImg = (imgURL: string) => {
    let newUrl = imgURL;
    if (!imgURL) return "";

    if (imgURL.startsWith("http")) {
      newUrl = imgURL;
    } else {
      newUrl = resizePhoto({ photo: IMG_PATH + imgURL, newFormat: MD_IMG });
    }
    return newUrl;
  };

  const handleResizeImg = (imgUrl: string) => {
    const newImg = !!imgUrl
      ? resizePhoto({
          photo: imgUrl,
          newFormat: LG_IMG,
        })
      : "";

    setSeletedImage(newImg);
  };

  const handleError = (
    e: SyntheticEvent<HTMLImageElement, Event>,
    imageUrl: string
  ) => {
    (e.target as HTMLInputElement).src = IMG_PATH + imageUrl;
  };

  const handleOnClick = (img: string) => {
    handleIsSuccessPath() && handleResizeImg(img)
  }

  const handleCursorSx = () => {
    
    if(handleIsSuccessPath()) return  "pointer" 
    
    return ""
  }

  if (!images.length) return <></>;

  return (
    <>
      <ImageModal
        imageUrl={validateImg(seletedImage)}
        onClose={() => setSeletedImage("")}
      />

      {images.map((image, i) => (
        <Image
          key={image.id}
          src={handleRasizeImg(IMG_PATH + image?.imageUrl)}
          onError={(e) => {
            handleError(e, image?.imageUrl);
          }}
          alt={alt}
          style={style}
          sx={{ mr: 1, cursor: handleCursorSx() }}
          onClick={() => {handleOnClick(image?.imageUrl)}} 
        />
      ))}
    </>
  );
};

export default ImagesThumbnail;
