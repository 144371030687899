import {
  emptyDefaultObject,
  setDescriptionLoad,
  setImages,
  setIsRefrigerate,
  setLisConditions,
  setOriginReference,
  setSelectOrigin,
  setSelectTypevehicle,
  setWeightOfTheLoad,
} from '@/store/customerRequestRedux/customerRequestSlice';
import { setMultiSelectDestinyV2 } from '@/store/locationRedux/selectLocation';
import { AppDispatch } from '@/store/store';

import {
  REQUEST_MOVING_BETWEEN_CITIES_FLOW_PATHS,
  REQUEST_MOVING_IN_CITY_FLOW_PATHS,
} from '@/pages/customerRequest/shared/consts/routes';
import { IconTypes } from '@/utils/Icon';

export interface TypeOfFlow {
  id: number;
  title: string;
  subTitle: string;
  icon: IconTypes;
  question: string;
  firstBtn: {
    id: number;
    text: string;
    icon: IconTypes;
    pathArray?: string[];
  };
  secondBtn: {
    id: number;
    text: string;
    icon: IconTypes;
    pathArray?: string[];
  };
}

export const emptyObj: TypeOfFlow = {
  id: 0,
  title: '',
  subTitle: '',
  icon: 'icon-share-location',
  question: '',
  firstBtn: {
    id: 0,
    text: '',
    icon: 'icon-box',
    pathArray: [],
  },
  secondBtn: {
    id: 0,
    text: '',
    icon: 'icon-truck',
    pathArray: [],
  },
};

export const MovingObj: TypeOfFlow = {
  id: 4,
  title: 'Tipo de servicio',
  subTitle: 'Solicitar mudanza',
  icon: 'icon-share-location',
  question: '¿Dónde quieres solicitar el servicio de mudanza?',
  firstBtn: {
    id: 1,
    text: 'En la ciudad',
    icon: 'icon-truck__lateral',
    pathArray: REQUEST_MOVING_IN_CITY_FLOW_PATHS,
  },
  secondBtn: {
    id: 2,
    text: 'Entre ciudades',
    icon: 'icon-truck__moving',
    pathArray: REQUEST_MOVING_BETWEEN_CITIES_FLOW_PATHS,
  },
};

export const handleResetOriginAndDestiny = (dispatch: AppDispatch) => {
  dispatch(setSelectOrigin(emptyDefaultObject));
  dispatch(setOriginReference(''));
  dispatch(setDescriptionLoad(''));
  dispatch(setSelectTypevehicle(emptyDefaultObject));
  dispatch(setWeightOfTheLoad(0));
  dispatch(setImages([]));
  dispatch(setIsRefrigerate(false));
  dispatch(setLisConditions([]));
  dispatch(setMultiSelectDestinyV2([]));
};
