
declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export const createRequestSetTipeDataLayer = (idUsuario: number, tipoSolicitud: number) => {
  window.dataLayer.push({
    event: "create_request_type",
    idusuario: idUsuario,
    tiposolicitud: tipoSolicitud
  });
}

export const createRequestLoadRuteDataLayer = (idUsuario: number, tipoSolicitud: number) => {
  window.dataLayer.push({
    event: "create_request_route",
    idusuario: idUsuario,
    tiposolicitud: tipoSolicitud
  });
}

export const createRequestLoadDataLayer = (idUsuario: number, tipoSolicitud: number) => {
  window.dataLayer.push({
    event: "create_request_load",
    idusuario: idUsuario,
    tiposolicitud: tipoSolicitud
  });
}

export const createRequestLoadConditionsDataLayer = (idUsuario: number, tipoSolicitud: number) => {
  window.dataLayer.push({
    event: "create_request_conditions",
    idusuario: idUsuario,
    tiposolicitud: tipoSolicitud
  });
}

export const createRequestLoadPriceDataLayer = (idUsuario: number, tipoSolicitud: number) => {
  window.dataLayer.push({
    event: "create_request_fee",
    idusuario: idUsuario,
    tiposolicitud: tipoSolicitud
  });
}

export const createRequestSuccessDataLayer = (idUsuario: number, tipoSolicitud: number) => {
  window.dataLayer.push({
    event: "create_request",
    idusuario: idUsuario,
    tiposolicitud: tipoSolicitud
  });
}