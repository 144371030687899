import LabelRequired from "@/components/utils/LabelRequired";
import { FORMAT_DATE_DDMMYYYY } from "@/consts/formatsDate";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { setActiveButton } from "@/store/customerRequestRedux/customerRequestSlice";
import { uploadRuteRequestEdit } from "@/store/noticeNegotationRequestRedux/editNegRequestThunk";
import { Grid, styled, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import DatePicker from "@/components/form/datePicker.tsx/DatePicker";
import Input from "@/components/inputCustom/Input";
import { districtsDeliveryText } from "@/consts/noticeConsts";
import { RequestRute } from "@/models/noticeNegotation/noticeNegotationModel";

const Container = styled(Grid)(
  () => `
    display: flex;
    flex-direction: column;  
    margin-top: 24px;
  `
);

const FormRuteEditMultidestiny = () => {
  const { typeId = "" } = useParams();
  const navigate = useNavigate();
  const {
    customerRequest: { requestDetailsView, isLoading },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const [datePicker, setDatePicker] = useState<Dayjs | null>(
    dayjs().add(1, "day")
  );

  const [destiny, setDestiny] = useState("");

  useEffect(() => {
    if (requestDetailsView) {
      setDestiny(requestDetailsView?.destinoTexto);
      !!requestDetailsView?.fecha &&
        setDatePicker(dayjs(requestDetailsView?.fecha, FORMAT_DATE_DDMMYYYY));
    }
  }, []);

  const handleSubmit = (event: EventType["submit"]) => {
    event.preventDefault();
    const value = (datePicker as Dayjs ).format(FORMAT_DATE_DDMMYYYY)
    const body = { fecha: value } as RequestRute;

    dispatch(uploadRuteRequestEdit(typeId, body)).then(() => navigate(-1));
  };

  const handleCompareinputs = () => {
    const currentInputsValue = [datePicker];
    const dataToInputsValue = [
      dayjs(requestDetailsView?.fecha, FORMAT_DATE_DDMMYYYY),
    ];

    const result =
      JSON.stringify(currentInputsValue) === JSON.stringify(dataToInputsValue);
    return !result;
  };

  const handleDisabledButton = () => {
    const conditions = [datePicker];
    let result = true;

    const conditionsResult = conditions.some((condition) => !condition);
    const sameData = handleCompareinputs();
    dispatch(setActiveButton(sameData));

    if (sameData) {
      result = false;
      if (conditionsResult) {
        result = true;
      }
    }

    return result;
  };

  return (
    <Container container>
      <form onSubmit={handleSubmit}>
        <DatePicker
          fullWidth
          initValue={datePicker}
          inputFormat={FORMAT_DATE_DDMMYYYY}
          label={<LabelRequired str="Fecha del servicio" />}
          onChange={setDatePicker}
          disablePast={false}
          minDate={dayjs().add(1, "day")}
          renderInput={Input}
        />

        <Typography variant="body2" color="black" sx={{ mt: 2, mb: 0.5 }}>
          <strong>{districtsDeliveryText}</strong>
        </Typography>
        <Typography
          variant="body2"
          color="black"
          sx={{ wordWrap: "break-word" }}
        >
          {destiny}
        </Typography>

        <Grid item sx={{ marginTop: "16px" }}>
          <ButtomLoader
            type="submit"
            isLoading={isLoading}
            buttomProps={{
              disabled: handleDisabledButton() || isLoading,
            }}
          >
            GUARDAR
          </ButtomLoader>
        </Grid>
      </form>
    </Container>
  );
};

export default FormRuteEditMultidestiny;
