import { ResponsiveImage } from "@/interfaces/globalInterface";
import { styleCreatorMediaQuerys } from "@/utils/helpers";
import { styled } from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system";
import { CurveLines, HeaderBanner } from "./styleBannerLand";

interface BannerLandProps {
  urlImg: string;
  urlTexture?: string | boolean;
  positionXY?: ResponsiveStyleValue<[key: string] | string>;
  position?: string;
  height?: string;
  minHeight?: string;
  maxHeight?: string;
  children?: React.ReactNode;
  responsive?: ResponsiveImage[];
  BannerHeroStyle?: any
}

type Props = { responsive?: ResponsiveImage[]; image?: string };
const BannerHero = styled(HeaderBanner)<Props>(({ responsive = [], image = "" }) => ({
  backgroundImage: `url(${image})`,
  ...styleCreatorMediaQuerys(responsive),
}));

const BannerLand = ({
  urlImg,
  urlTexture = "./images/curve-lines.svg",
  positionXY = "center",
  position = "relative",
  minHeight = "146px",
  maxHeight = "166px",
  height = "",
  responsive,
  children,
  BannerHeroStyle
}: BannerLandProps) => {


  return (

    <BannerHero
      sx={{
        position: { position },
        backgroundPosition: positionXY,
        minHeight,
        maxHeight,
        height,
        ...BannerHeroStyle
      }}
      image={urlImg}
      responsive={responsive}
    >
      {/* {urlTexture && <CurveLines src={urlTexture.toString()} alt="CurveLines" />} */}
      {children}
    </BannerHero>
  );
};

export default BannerLand;
