import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { RequestConditions } from "@/models/customerRequest/customerRequestTypesConditions";
import { createRequestLoadConditionsDataLayer } from "@/services/dataLayer/createRequest";
import { updateCondition } from "@/store/customerRequestRedux/customerRequestSlice";
import { getListCustomerRequestTypeConditionsThunk, saveCustomerRequestMovingThunk } from "@/store/customerRequestRedux/customerRequestThunk";
import {
    getNextRoute,
    getPrevRoute,
} from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    customerRequestObjectTypes,
    titlesObj,
} from "../../shared/consts/customerRequestConsts";
import { customerRequestTitle } from "../Load/configData";
import { TYPE_REQUEST_PATH } from "../../shared/consts/routes";
import { ID_MOVING_FLOW } from "@/consts/defaultConsts";

const useConditionsHook = () => {
    const { typeRequest = "" } = useParams();
    const flowPath = typeRequest.replace(/\/+$/, "");
    const titleToRender = titlesObj[flowPath];
    const {
        customerRequest: { lisConditions, selectedTypeRequest, isLoading },
        user: { user },
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const customerTypeId = selectedTypeRequest?.id || 0;
    const isMoving = customerTypeId === ID_MOVING_FLOW;

    const { subTitle = titleToRender } =
        customerRequestTitle[customerTypeId];

    useEffect(() => {
        if (!lisConditions.length) {
            dispatch(getListCustomerRequestTypeConditionsThunk());
        }

        createRequestLoadConditionsDataLayer(
            (user as IUser).id,
            customerTypeId
        );

        return () => { };
    }, []);

    const handlePrepareList = (listItem: RequestConditions, isActive: number) => {
        const currentList = lisConditions.map((item) => {
            if (listItem.id === item.id) {
                return {
                    ...item,
                    isActive: !!isActive,
                };
            }

            return item;
        });

        return currentList;
    };

    const handleChange = (listItem: RequestConditions, isActive: number) => {
        const currentList = handlePrepareList(listItem, isActive);

        dispatch(updateCondition(currentList));
    };

    const handleNextRute = () => {
        if (isMoving) {
            if (isLoading) return;
            dispatch(saveCustomerRequestMovingThunk()).then((res) => {
               nextRouter();
            });
        } else {
            nextRouter();
        }
    };

    const nextRouter = () => {
        const valueToReplace = customerRequestObjectTypes[customerTypeId];
        dispatch(
            getNextRoute(navigate, { valueToSearch: TYPE_REQUEST_PATH, valueToReplace })
        );
    }

    const handlePrevRute = () => {
        const valueToReplace = customerRequestObjectTypes[customerTypeId];
        dispatch(
            getPrevRoute(navigate, { valueToSearch: TYPE_REQUEST_PATH, valueToReplace })
        );
    };

    return {
        lisConditions,
        subTitle,
        handleChange,
        handleNextRute,
        handlePrevRute,
        isMoving,
        isLoading,
    }
}


export default useConditionsHook;