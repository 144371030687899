import { maxLengthReference, maxLengthWeight } from "@/consts/noticeConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { customerRequestObjectTypes } from "@/pages/customerRequest/shared/consts/customerRequestConsts";
import { setPhotosPreLoaded } from "@/store/customerRequestRedux/customerRequestSlice";
import {
  getCustomerRequestRangeWeightThunk,
  getCustomerRequestTypesLoadThunk,
  saveDataLoadAndVehicleThunk,
} from "@/store/customerRequestRedux/customerRequestThunk";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { getVehicleListThunk } from "@/store/vehicle/vehicleThunk";
import { validateValueInput } from "@/utils/helpers";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { inputWeight } from "../FormLoadInCity/config";

export const useFormLoad = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [descriptionLoad, setDescriptionLoad] = useState("");
  const [isRefrigerate, setIsRefrigerate] = useState(false);
  const [images, setImages] = useState<File[]>([]);
  const [weight, setWeight] = useState("");

  const {
    vehicle: { vehicleList },
    customerRequest: {
      requestDetailsView,
      selectedTypevehicle,
      selectedTypeRequest,
      selectedTypeLoadRequest,
      selectedEstimatedWeightRequest,
      descriptionLoad: descriptionLoadStore,
      isRefrigerate: isRefrigerateStore,
      weightOfTheLoad,
      isLoading,
      errorImage,
      photosPreLoaded,
    },
  } = useAppSelector((state) => state);


  useEffect(() => {
    if (!vehicleList.length || !selectedTypevehicle.id) {
      dispatch(getVehicleListThunk(1));
    }
    handleInit();
  }, []);

  useEffect(() => {
    dispatch(getCustomerRequestTypesLoadThunk());
    dispatch(getCustomerRequestRangeWeightThunk());
  }, []);

  const handleInit = () => {
    setDescriptionLoad(descriptionLoadStore);
    setIsRefrigerate(isRefrigerateStore);
    !!weightOfTheLoad && setWeight(weightOfTheLoad.toString());
  };

  const handleChange = ({ target }: EventType["change"]) => {
    const { name, value } = target;

    const setFunc: Record<
      string,
      React.Dispatch<React.SetStateAction<string>>
    > = {
      pesoEstimado: setWeight,
      descriptionLoad: setDescriptionLoad,
    };

    if (name === inputWeight.name) {
      if (value.length <= maxLengthWeight) setFunc[name](value);
      else return;
    }

    if (value.length <= maxLengthReference) {
      const newValue = validateValueInput(value);
      setFunc[name](newValue);
    }
  };

  const handleSubmit = (event: EventType["submit"]) => {
    event.preventDefault();
    if (isLoading) return;

    const data = { descriptionLoad, isRefrigerate, images, weight };

    dispatch(saveDataLoadAndVehicleThunk(data)).then(() => {
      handleNextRute();
    });
  };

  const handleOnChangeImages = (
    images: File[],
    name?: string,
    deleted?: boolean
  ) => {
    if (!!name && !!deleted) {
      const newArrayPhotos = photosPreLoaded.filter(
        (item) => item.name !== name
      );
      dispatch(setPhotosPreLoaded(newArrayPhotos));
    }
    setImages(images);
  };

  const handleNextRute = () => {
    const valueToReplace = customerRequestObjectTypes[selectedTypeRequest.id];
    dispatch(
      getNextRoute(navigate, { valueToSearch: ":typeRequest", valueToReplace })
    );
  };

  const handleDisabledButton = () => {
    const conditions = [
      descriptionLoad,
      selectedTypeLoadRequest?.id,
      selectedEstimatedWeightRequest?.id,
      selectedTypevehicle?.id,
      !isLoading,
    ];

    return conditions.some((condition) => !condition);
  };

  return {
    dispatch,
    handleChange,
    handleSubmit,
    handleOnChangeImages,
    handleDisabledButton,
    requestDetailsView,
    selectedTypeLoadRequest,
    selectedEstimatedWeightRequest,
    selectedTypevehicle,
    errorImage,
    photosPreLoaded,
    isLoading,
    descriptionLoad,
  };
};
