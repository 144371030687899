import { OFFER_MULTI_DESTINY_FLOW_PATH } from '@/pages/flowNoticeNegotation/shared/const/routes/clientAccepOrCounteroffer';
import MultidestinyCargoDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/childComponents/FormCargoDetails/MultidestinyCargoDetails';
import RuteMultidestiny from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/childComponents/FormRuteDetails/RuteMultidestiny';
import PriceMultidestiny from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/childComponents/formPriceDetails/PriceMultidestiny';
import CargoDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/containers/CargoDetails/Index';
import ConfirmationDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/containers/ConfirmationDetails/Index';
import PriceDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/containers/PriceDetails/Index';
import RuteDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/containers/RuteDetails/Index';
import OfferBodyConfirmationMultiDestination from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/childComponents/FormConfirmationDetails/OfferBodyConfirmationMultiDestination';
import { privateOffertMiddleware } from '@/router/middleware/privateCreateServicesMiddleware';
import { RouterProps } from '@/router/router';
//todo
export const offerMultiDestinyNoticeNegFlowRouter: RouterProps[] = [
  {
    path: OFFER_MULTI_DESTINY_FLOW_PATH[0],
    Component: CargoDetails,
    requiredSession: true,
    middleware: privateOffertMiddleware,
    children: [
      {
        index: true,
        Component: MultidestinyCargoDetails,
      },
    ],
  },
  {
    path: OFFER_MULTI_DESTINY_FLOW_PATH[1],
    Component: RuteDetails,
    requiredSession: true,
    middleware: privateOffertMiddleware,
    children: [
      {
        index: true,
        Component: RuteMultidestiny,
      },
    ],
  },
  {
    path: OFFER_MULTI_DESTINY_FLOW_PATH[2],
    Component: PriceDetails,
    requiredSession: true,
    middleware: privateOffertMiddleware,
    children: [
      {
        index: true,
        Component: PriceMultidestiny,
      },
    ],
  },
  {
    path: OFFER_MULTI_DESTINY_FLOW_PATH[3],
    Component: ConfirmationDetails,
    requiredSession: true,
    middleware: privateOffertMiddleware,
    children: [
      {
        index: true,
        Component: OfferBodyConfirmationMultiDestination,
      },
    ],
  },
];
