import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { StyleCustomInput } from "@/components/form/customInput/styleCustomInput";
import ReCaptchaTerms from "@/components/reCaptchaTerms/ReCaptchaTerms";
import LabelRequired from "@/components/utils/LabelRequired";
import {
  ADS_CONFIRMATION_LANDING_PATH,
  ADS_CONFIRMATION_SPONSORED_LANDING_PATH,
  ADS_SOON_LANDING_PATH,
  ADS_SOON_SPONSORED_LANDING_PATH,
} from "@/consts/typeServicesPaths";
import useRecaptcha from "@/hooks/useRecaptcha";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import imageAdvertisersForm from "@/img/landing/advertisers/anunciantes.png";
import imageSponsoredForm from "@/img/landing/advertisers/sponsored.jpg";
import { EventType } from "@/interfaces/globalInterface";
import { IFormContact } from "@/models/landing/contactFormApi";
import Footer from "@/pages/footer/Footer";
import { setLoading } from "@/store/landingServices/landingContact/landingContactServiceSlice";
import { postFormContactSend } from "@/store/landingServices/landingContact/landingServicesThunk";
import { Container, Grid, Stack, styled, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BannerLand from "../../../components/bannerLand/BannerLand";
import { Form } from "../../../contact/styleContact";
import { SubTitleFormAds, TitleFormAds } from "../../styleAds";

const ContainerDegradado = styled("div")(() => ({
  backgroundColor: "#fafafa",
}));

const initialForm: IFormContact = {
  tipopagina: "a",
  nombreyapellido: "",
  cargo: "",
  email: "",
  celular: "",
  empresa: "",
};

const AdsSoon = () => {
  const [form, setForm] = useState<IFormContact>(initialForm);
  const [errorAPI, setErrorAPI] = useState("");

  const { handleGetTokenRecaptcha } = useRecaptcha("addSoom");

  const { isLoading } = useAppSelector((state) => state.landingContact);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(setLoading(false));

    !!errorAPI && setErrorAPI("");
    return () => {
      !!errorAPI && setErrorAPI("");
    };
  }, []);

  const handleBackgroundImg = () => {
    let backgroundImg = imageSponsoredForm;

    if (pathname.endsWith(ADS_SOON_LANDING_PATH)) {
      backgroundImg = imageAdvertisersForm;
    }

    return backgroundImg;
  };

  const handleTitleText = () => {
    let title = "Impulsa tus avisos de transporte con PegaCargo.com";

    if (pathname.endsWith(ADS_SOON_LANDING_PATH)) {
      title = "Potencia tu marca con PegaCargo.com";
    }

    return title;
  };

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;
    if (name === "celular") {
      setForm({ ...form, [name]: value.replace(/\D/g, "") });
    } else {
      setForm({ ...form, [name]: value });
    }

    !!errorAPI && setErrorAPI("");
  };

  const handleSubmit = async (event: EventType["submit"]) => {
    event.preventDefault();
    if (isLoading) return;

    const { tokenCaptcha, error } = await handleGetTokenRecaptcha();
    if (error) {
      setErrorAPI(error);
      return;
    }

    errorAPI && setErrorAPI("");

    let data = {
      formCompleted: { ...form, tokenCaptcha },
    };

    if (pathname.endsWith(ADS_SOON_LANDING_PATH)) {
      dispatch(postFormContactSend({ ...data }))
        .then(() => {
          navigate(ADS_CONFIRMATION_LANDING_PATH);
        })
        .catch((e: Error) => setErrorAPI(e.message));
    } else if (pathname.endsWith(ADS_SOON_SPONSORED_LANDING_PATH)) {
      data = { formCompleted: { ...form, tipopagina: "t", tokenCaptcha } };

      dispatch(postFormContactSend({ ...data }))
        .then(() => {
          navigate(ADS_CONFIRMATION_SPONSORED_LANDING_PATH);
        })
        .catch((e: Error) => setErrorAPI(e.message));
    }

    return;
  };

  const handleDisabled = () => {
    const conditions = [
      form.nombreyapellido === "",
      form.email === "",
      form.celular === "",
      form.empresa === "",
      errorAPI,
    ];

    return conditions.some((condition) => condition);
  };

  return (
    <ContainerDegradado>
      <Container
        maxWidth="xl"
        sx={{
          height: {
            xs: "716px",
            md: "100vh",
          },
          padding: "0px",
          paddingLeft: "0px !important",
          paddingRight: "0px !important",
        }}
      >
        <Grid
          container
          sx={{
            height: "100%",
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: {
                md: "none",
              },
            }}
          >
            <Stack
              sx={{
                background: "white",
                height: {
                  xs: "512px",
                  md: "100%",
                },
                padding: {
                  xs: "16px 16px",
                  md: "32px 24px",
                },
              }}
              gap="16px"
            >
              <TitleFormAds>{handleTitleText()}</TitleFormAds>
              {errorAPI && (
                <StandardAlert
                  sx={{ marginBottom: "16px" }}
                  severity="error"
                  color="error"
                >
                  {errorAPI}
                </StandardAlert>
              )}
              <SubTitleFormAds>
                Déjanos tus datos y nuestro equipo comercial se comunicará
                contigo.
              </SubTitleFormAds>
              <Form onSubmit={handleSubmit} autoComplete="off">
                <Stack gap="16px">
                  <StyleCustomInput
                    id="nombreyapellido"
                    name="nombreyapellido"
                    inputProps={{
                      minLength: 1,
                      maxLength: 50,
                      required: true,
                    }}
                    label={<LabelRequired str="Nombre y apellidos" />}
                    value={form.nombreyapellido}
                    variant="standard"
                    placeholder="Nombre y apellidos"
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                  />
                  <StyleCustomInput
                    id="empresa"
                    name="empresa"
                    inputProps={{
                      minLength: 1,
                      maxLength: 30,
                      required: true,
                    }}
                    label={<LabelRequired str="Empresa" />}
                    value={form.empresa}
                    variant="standard"
                    placeholder="Ingresa el nombre de tu empresa"
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                  />
                  <StyleCustomInput
                    id="cargo"
                    name="cargo"
                    inputProps={{
                      minLength: 1,
                      maxLength: 30,
                      required: true,
                    }}
                    label={<LabelRequired str="Cargo" />}
                    value={form.cargo}
                    variant="standard"
                    placeholder="Ingresa tu cargo"
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                  />
                  <StyleCustomInput
                    id="email"
                    name="email"
                    inputProps={{
                      minLength: 1,
                      maxLength: 35,
                      required: true,
                    }}
                    label={<LabelRequired str="Correo electrónico" />}
                    value={form.email}
                    variant="standard"
                    placeholder="Ingresa tu correo electrónico"
                    type="email"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                  />
                  <StyleCustomInput
                    id="celular"
                    name="celular"
                    inputProps={{
                      inputMode: "numeric",
                      minLength: 5,
                      maxLength: 9,
                      required: true,
                    }}
                    label={<LabelRequired str="Número de celular" />}
                    value={form.celular}
                    variant="standard"
                    placeholder="Ingresa tu teléfono"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                  />

                  <ReCaptchaTerms sx={{ marginTop: "8px" }} />

                  <ButtomLoader
                    type="submit"
                    disabled={handleDisabled()}
                    sx={{ width: "100%", height: "44px" }}
                    isLoading={isLoading}
                  >
                    Enviar
                  </ButtomLoader>
                </Stack>
              </Form>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: {
                xs: "none",
                md: "block",
              },
            }}
          >
            <BannerLand
              urlImg={handleBackgroundImg()}
              urlTexture={false}
              height="100%"
              minHeight="100%"
              maxHeight="100%"
            />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </ContainerDegradado>
  );
};

export default AdsSoon;
