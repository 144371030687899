import { FormDataFilter } from '@/components/Filter/consts/filtersConst';
import { FORMAT_DATE_YYYYMMDD_HYPHEN } from '@/consts/formatsDate';
import { TypeFilterBodyList } from '@/models/filterNotice/filterNoticeModels';
import { TipoVehiculoData } from '@/models/vehicle/vehicleModel';
import { TypeFilterData } from '@/store/FilterServices/filterReduxInterface';
import { GetListNoticeByFilterThunk } from '@/store/filterNoticeRedux/filterNoticeThunk';
import dayjs, { Dayjs } from 'dayjs';

interface GetListNoticeByFilterAdapter
  extends Omit<GetListNoticeByFilterThunk, 'selectOrder'> {
  page?: number;
  selectOrder: string[];
}

export const listNoticeBodyByFilterAdapter = (
  body: FormDataFilterBodyAdapter,
  typeNoticeSelecte: TypeFilterData,
  {
    isPaginate = false,
    page = 0,
    size = 12,
    selectOrder,
  }: GetListNoticeByFilterAdapter
) => {
  const currentPage = isPaginate ? page + 1 : page;
  const tiposFiltro = getFiltersAdapter(body, typeNoticeSelecte);
  const currentBody = {
    size,
    tiposFiltro,
    page: currentPage,
    ordenamiento: selectOrder,
  };

  return currentBody;
};

const getFiltersAdapter = (
  body: FormDataFilterBodyAdapter,
  typeNoticeSelecte: TypeFilterData
) => {
  const prepareBody = prepareBodyByTypeSearchAdapter(body, typeNoticeSelecte);

  const currentBody = listBodyNoticeAdapter(prepareBody);

  return currentBody;
};

const prepareBodyByTypeSearchAdapter = (
  body: FormDataFilterBodyAdapter,
  typeNoticeSelecte: TypeFilterData
) => {
  let newBody = { ...body };
  const searchCity = 'C'; // esto quiere decir que la busqueda sera por ciudades
  // typeNoticeSelecte?.inputfechas -> significa que esta busqueda tiene 2 fechas, inicio y fin
  if (
    typeNoticeSelecte?.busqueda === searchCity &&
    typeNoticeSelecte?.inputfechas
  ) {
    const { destiny, origin, timeService, betweenDate, ...rest } = newBody;
    newBody = {
      ...rest,
      city: origin || '',
      destinyCity: destiny,
      timeService: stringReverBetweenDate(betweenDate),
    };
  } else if (typeNoticeSelecte?.busqueda === searchCity) {
    const { destiny, origin, betweenDate, ...rest } = newBody;
    newBody = {
      ...rest,
      city: origin || '',
      destinyCity: destiny,
    };
  } else {
    const { timeService, ...rest } = newBody;
    if (typeof timeService === 'string') {
      newBody = {
        ...rest,
        timeService: timeService.split('/').join('-'),
      };
    }
  }

  return newBody;
};

interface FormDataFilterBodyAdapter
  extends Omit<FormDataFilter, 'timeService'> {
  typeNotice: string;
  timeService: Dayjs | string | string[];
}
const listBodyNoticeAdapter = (
  body: FormDataFilterBodyAdapter
): TypeFilterBodyList[] => {
  const dataLink: Record<
    keyof Omit<FormDataFilterBodyAdapter, 'betweenDate'>,
    string
  > = {
    destiny: 'Destino',
    origin: 'Origen',
    city: 'OrigenCiudad',
    destinyCity: 'DestinoCiudad',
    weightOfTheLoad: 'Peso de la carga',
    typeVehicle: 'Tipo de vehículo',
    timeService: 'Fecha de aviso',
    typeNotice: 'Tipo de aviso',
    typeServiceList: 'Tipo de servicio',
  };
  const defaultResult = {
    nombre: 'Tipo de estado',
    opciones: ['Activo'],
  };
  const typeFilters = Object.entries(body).reduce(
    (acc, [key, value]) => {
      if (!value) return acc;

      const keyLink = key as keyof Omit<
        FormDataFilterBodyAdapter,
        'betweenDate'
      >;

      acc = [
        ...acc,
        prepareBodyNoticeAdapter({
          keyLink: dataLink[keyLink],
          value,
          exemption: [
            dataLink.city,
            dataLink.destinyCity,
            dataLink.weightOfTheLoad,
          ],
        }),
      ];

      return acc;
    },
    [defaultResult]
  );

  return typeFilters;
};

type PrepareBodyNotice = {
  keyLink: string;
  value: string | Dayjs;
  exemption?: string[];
};
const prepareBodyNoticeAdapter = ({
  keyLink,
  value,
  exemption,
}: PrepareBodyNotice) => {
  let opciones = [''];
  if (value instanceof dayjs) {
    const currentValue = value as Dayjs;
    opciones = [currentValue?.format(FORMAT_DATE_YYYYMMDD_HYPHEN)];
  } else if (typeof value == 'string') {
    if ('Fecha de aviso' === keyLink && value.includes('/')) {
      opciones = [value.split('/').join('-')];
    } else if (
      !exemption?.some((exemp) => exemp === keyLink) &&
      !!value.includes(',')
    ) {
      opciones = value.split(',');
    } else {
      opciones = [value];
    }
  } else if (Array.isArray(value)) {
    opciones = value;
  }

  return {
    nombre: keyLink,
    opciones,
  };
};

const stringReverBetweenDate = (betweenDate?: string) => {
  if (!betweenDate) return '';
  let [startDate = '', endDate = ''] = betweenDate.split(' - ');
  startDate = reverBetweenDate(startDate);
  endDate = reverBetweenDate(endDate);

  return [startDate, endDate];
};

const reverBetweenDate = (currentDate: string) => {
  const [day, month, year] = currentDate.split('/');

  return `${year}-${month}-${day}`;
};

export const prepareHidrateTypeVehicleAdapter = ({
  tipoVehiculos,
}: TipoVehiculoData): TypeFilterData[] => {
  const newList = tipoVehiculos.map(({ nombre, urlImagen }) => {
    return {
      nombre: nombre,
      urlicono: urlImagen,
    };
  });

  return newList;
};
