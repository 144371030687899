import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import { currencyFormat } from "@/utils/helpers";
import React from "react";
import { useAppSelector } from "@/hooks/useReducer";
import { ID_EXPRESS_FLOW, ID_MOVING_FLOW } from "@/consts/defaultConsts";
import { optionOne, optionTwo } from "@/consts/noticeConsts";

type Props = {
  isCollapse: boolean;
  setisCollapse: React.Dispatch<React.SetStateAction<boolean>>;
  negotation: any;
  hiddenPricenInRowCollapse?: boolean;
  hiddenLineDivider?: boolean;
};

const RowCollapse = ({
  isCollapse,
  setisCollapse,
  negotation,
  hiddenPricenInRowCollapse = false,
  hiddenLineDivider = false,
}: Props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const mobile = useMediaQuery("(max-width:1030px)");

  const {
    noticeNegotationRequest: { savedDataNogotation },
  } = useAppSelector((state) => state);
  const typeRequest: boolean =
    savedDataNogotation?.solicitud?.tipoSolicitud.id === optionTwo ? true : false;
  const hourPoints: string =
    savedDataNogotation?.solicitud?.flgHoraoPunto === optionOne ? "hora" : "punto";

  const typeRequestFlow = savedDataNogotation?.solicitud?.tipoSolicitud.id;

  return (
    <>
      <Box sx={{ pl: 2 }}>
        {typeRequest  && (
          <Grid container spacing={2} sx={{ mt: 4, marginBottom: "-30px" }}>
            <Typography variant="body2" sx={{ color: "#757575", mt: "4px" }}>
              Precio por {hourPoints}
            </Typography>
          </Grid>
        )}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid
            xs={7}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              paddingTop: 2
            }}
          >
            {typeRequestFlow ===
            ID_MOVING_FLOW 
              ? ""
              : typeRequestFlow !== ID_EXPRESS_FLOW && (
                  <Typography variant="label1" sx={{fontSize: "22px"}}>
                    {currencyFormat(negotation.solicitud.precio)}
                  </Typography>
                )}
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                !matches && setisCollapse(!isCollapse);
              }}
            >
              {!matches && (
                <>
                  <Typography variant="label1" color="primary" sx={{ fontSize: mobile ? "14px" : "15px", pl: mobile ? "0px" : "16px" }}>
                    {isCollapse ? "Ocultar" : "Ver detalles"}
                  </Typography>

                  <KeyboardArrowUpIcon
                    color="primary"
                    sx={{
                      transform: isCollapse ? "rotate(0deg)" : "rotate(180deg)",
                    }}
                  />
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      {!hiddenLineDivider && (
        <>
          {!matches && (
            <Box
              sx={{ width: "100%", borderTop: "1px solid #E0E0E0", mt: "16px" }}
            ></Box>
          )}
        </>
      )}
    </>
  );
};

export default RowCollapse;
