import { Grid, styled, Typography } from "@mui/material";
import { formatDate } from "@/utils/helpers";
import { useAppSelector } from "@/hooks/useReducer";
import { ContainerInfo, TitleInfo, TitleInfoDescription } from "./style";
import { IAvisoCreadoData } from "@/services/noticeServices/noticeSendData/noticeSendDataTypesResponse";


const InfoSuccess = ({
  expireDate,
  time,
}: {
  expireDate: string | null;
  time?: string | null;
}) => {
  const { echoResponse } = useAppSelector((state) => state.noticeV2);
  const {
    avisoscreados: [{
      idtipoaviso,
      origentexto,
      destinotexto,
      placa,
      pesomaximo,
      fechafija,
      precio,
    }],
  } = echoResponse as IAvisoCreadoData;

  if (!echoResponse) return <></>;

  const handleFormatDate = () => {
    // if (idtipoaviso === 4) {
    //   return expireDate;
    // }
    // return formatDate(expireDate);
    return expireDate;
  };

  return (
    <ContainerInfo container>
      <Grid item>
        <i className="icon-info--blue" />
      </Grid>
      <Grid item>
        <TitleInfoDescription>
        Te enviaremos una notificación y un SMS cuando existan interesados.
        </TitleInfoDescription>
      </Grid>
      <Grid item>
        <TitleInfo>
        Válido hasta el {handleFormatDate()}
          {time && " - " + time}.
        </TitleInfo>
      </Grid>
    </ContainerInfo>
  );
};

export default InfoSuccess;
