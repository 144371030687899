import { NEGOTATION_CLIENT_PATH } from "@/consts/negotationFlowPath";
import {
  MY_PENDING_AGREEMENTS,
  NOTIFICATIONS_PATH,
  PROFILE_NOTICE_LIST_PATH,
  REQUESTS_LANDING_PATH,
  SERVICES_LANDING_PATH,
  SHARED_RIDE,
} from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { GetAdvertisementDTO } from "@/models/advertisement/advertisementModel";
import { setCleanHistoryPlinth } from "@/store/advertisementRedux/advertisementSlice";
import { getAdvertisementPlinthThunk } from "@/store/advertisementRedux/advertisementThunk";
import { Grid, styled, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import AdvertisementList from "./AdvertisementList";

const TIMER = 20000; // Equal 20seg!!
const MESSAGE_AGREEMENT_PATH_END = "acuerdo-mensaje";

const ContainerAdvertisement = styled(Grid)(
  () => `
    height: 125px;
    position: fixed; 
    bottom: 0;
    width: 100%;
    overflow: hidden;
    z-index: 2;
    
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.2px);
    -webkit-backdrop-filter: blur(6.2px);
  `
);

const Container = styled("div")(
  () => `
  height: 125px;
  margin-top: 40px;
  `
);

const WHITE_LIST = [
  `${SERVICES_LANDING_PATH}/`,
  `${REQUESTS_LANDING_PATH}/`,
  PROFILE_NOTICE_LIST_PATH,
  NEGOTATION_CLIENT_PATH,
  NOTIFICATIONS_PATH,
  MY_PENDING_AGREEMENTS,
  SHARED_RIDE,
];

const Advertisement = () => {
  const { isLoading, error, advertisementList } = useAppSelector(
    (state) => state.advertisement
  );
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const [isActiveTimer, setIsActiveTimer] = useState(false);
  const [numbersAds, setNumbersAds] = useState(1);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const isSizeLarge = useMediaQuery("(min-width:1032px)");
  const isSizeMidle = useMediaQuery("(min-width:665px)");
  const isSizeSmall = useMediaQuery("(max-width:664px)");

  const { pathname } = useLocation();

  const timer = useRef<NodeJS.Timer>();

  useEffect(() => {
    setIsActiveTimer(false);

    setNumbersAds(() => handleGetNumberOfAdvertisement());
  }, [isSizeLarge, isSizeMidle, isSizeSmall]);

  useEffect(() => {
    setIsActiveTimer(false);
    if (timer?.current) {
      clearInterval(timer?.current as NodeJS.Timeout);
    }

    handleValidShowAdvertisement();
    return () => {
      setIsActiveTimer(false);
      clearInterval(timer?.current as NodeJS.Timeout);
      if (WHITE_LIST.some((path) => !pathname.startsWith(path))) {
        dispatch(setCleanHistoryPlinth());
      }
    };
  }, [pathname]);

  useEffect(() => {
    if (isActiveTimer) {
      timer.current = setInterval(() => {
        handleValidShowAdvertisement();
      }, TIMER);
    } else {
      clearInterval(timer?.current as NodeJS.Timeout);
      handleValidShowAdvertisement();
    }
  }, [isActiveTimer, numbersAds]);

  const handleValidShowAdvertisement = () => {
    if (
      WHITE_LIST.some((path) => pathname.startsWith(path)) &&
      !pathname.endsWith(MESSAGE_AGREEMENT_PATH_END)
    ) {
      let cantidadAnuncios = numbersAds;
      if (isFirstRender) {
        cantidadAnuncios = handleGetNumberOfAdvertisement();
        setIsFirstRender(false);
      }

      !isLoading && handleGetAdvertisement(cantidadAnuncios);
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const handleGetNumberOfAdvertisement = () => {
    let numberAds = 1;
    if (isSizeSmall) {
      numberAds = 1;
    } else if (isSizeLarge) {
      numberAds = 3;
    } else if (isSizeMidle && !isSizeLarge) {
      numberAds = 2;
    }

    return numberAds;
  };

  const handleGetAdvertisement = (cantidadAnuncios: number) => {
    const body: Pick<
      GetAdvertisementDTO,
      "urlSolicitud" | "cantidadAnuncios" | "idTipoAnuncio"
    > = {
      cantidadAnuncios,
      urlSolicitud: pathname,
      idTipoAnuncio: 1,
    };
    dispatch(getAdvertisementPlinthThunk(body));
  };

  const handleRunChangeTime = () => {
    setIsActiveTimer(() => true);
  };

  const handleHiden = () => {
    return isLoading || error || !show;
  };

  return (
    <Container style={{ display: show ? "block" : "none" }}>
      <ContainerAdvertisement
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        spacing={1}
      >
        {handleHiden() ? (
          <> </>
        ) : (
          <AdvertisementList
            advertisementList={advertisementList}
            onLoad={handleRunChangeTime}
          />
        )}
      </ContainerAdvertisement>
    </Container>
  );
};

export default Advertisement;
