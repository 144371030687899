import { styled } from "@mui/material";

const ContainerList = styled("ul")(({ theme }) => ({
  marginBlockStart: "0px",
  paddingInlineStart: "26px",
  fontFamily: theme.typography.body2.fontFamily,
  fontSize: theme.typography.body2.fontSize,
  lineHeight: theme.typography.body2.lineHeight,
  color: " rgba(0, 0, 0, 0.6)",
  "& li": {
    marginBottom: "8px",
  },
}));

const ConditionsList = ({ listServices }: { listServices: any[] }) => {
  if (!listServices) return <></>;

  const handleShowMessage = (list: any) => {
    if (typeof list === "string") return list;

    if (list.hasOwnProperty("transportista")) {
      return list?.transportista;
    }

    return !!list.isActive ? list.creadorpositivo : list.creadornegativo;
  };

  return (
    <ContainerList>
      {listServices.map((list, i) => (
        <li key={i}>{handleShowMessage(list)}</li>
      ))}
    </ContainerList>
  );
};

export default ConditionsList;
