import { ConfigDataNegotation } from '@/pages/noticeRequestFlow/interface/noticeNegRequestInterface';
import { generalConfigMultidestiny } from '@/pages/requestNegotiation/shared/config/multidestinyConfig';

// ==================================================
//          CONFIGURACION MULTIDESTINO
// ==================================================

export const configDataMultidestiny: ConfigDataNegotation[] = [
  ...generalConfigMultidestiny,
];
