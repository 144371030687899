import React from 'react'
import useRedirectAppStoreHook from './hook'
import './styles.css'
const RedirectAppStore = () => {
    useRedirectAppStoreHook()

    return (
        // loading ciruclar en el centro, cargando
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
        }}>
            <div className="loader"></div>
        </div>

    )
}

export default RedirectAppStore
