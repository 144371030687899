import { CLIENT_TYPE, DRIVER_TYPE } from '@/consts/defaultConsts';
import {
  REGISTER_CLIENT,
  REGISTER_CLIENT_PROFILE,
  REGISTER_DRIVER,
  REGISTER_DRIVER_STEPS_DOCUMENT,
  REGISTER_DRIVER_STEPS_PROFILE,
  WELCOME_CLIENT,
} from '@/consts/typeServicesPaths';

export const objectNextPage = {
  [REGISTER_CLIENT_PROFILE]: WELCOME_CLIENT,
  [REGISTER_DRIVER_STEPS_PROFILE]: REGISTER_DRIVER_STEPS_DOCUMENT,
};

export const titleByPath: Record<string, string> = {
  [REGISTER_CLIENT]: 'Registro como usuario',
  [REGISTER_DRIVER]: 'Registro como transportista',
  [REGISTER_CLIENT_PROFILE]: 'Personaliza tu perfil ( opcional )',
  [REGISTER_DRIVER_STEPS_PROFILE]: 'Datos de tu cuenta',
};

export const disclamer = 'Foto de perfil';
export const disclamerRUC = 'Foto de perfil o logo de tu empresa';

export const titleByTipeUser: Record<
  string,
  { label: string; placeholder: string }[]
> = {
  [DRIVER_TYPE]: [
    {
      label: 'Acerca de ti',
      placeholder:
        'Información relevante como años de experiencia, referencias, certificaciones, etc.',
    },
    {
      label: 'Acerca de tu empresa',
      placeholder:
        'Información relevante como años de experiencia, referencias, certificaciones, etc.',
    },
  ],
  [CLIENT_TYPE]: [
    {
      label: 'Acerca de ti',
      placeholder:
        'Información relevante que te ayude a encontrar transportistas.',
    },
    {
      label: 'Acerca de tu empresa',
      placeholder:
        'Información relevante que te ayude a encontrar transportistas.',
    },
  ],
};
