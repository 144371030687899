import { SolicitudNegociacion } from '@/models/noticeNegotation/noticeNegotationModel';
import { dataProfileFormatRequestNeg } from '@/pages/noticeRequestFlow/adapters/noticeRequestFlowAdapters';
import { IUser } from '@/store/userRedux/userReduxInterface';
import { formatDate, searchPropertyInObject } from '@/utils/helpers';

export const validIsClientRequestNeg = (clientId: any, user: IUser) => {
  const { id } = user;
  let userId = !!id && id;

  return userId === clientId;
};

export const getValueInOffertRequestNeg = (
  offer: SolicitudNegociacion,
  aditionalsParams?: any
) => {
  const value = searchPropertyInObject(aditionalsParams, offer);

  return value;
};

export const getFormatSendDateRequestNeg = (data: SolicitudNegociacion) => {
  const {
    solicitud: { fecha },
  } = data;

  try {
    const result = `${formatDate(fecha)}`;
    return result;
  } catch (err) {
    return fecha;
  }
};

export const getVehicleValuesRequestNeg = (
  savedDataNegotation: SolicitudNegociacion,
  user: IUser
) => {
  const {
    vehiculo: { tipoVehiculo, placa },
  } = savedDataNegotation;

  const isDriver = validIsClientRequestNeg(
    savedDataNegotation.transportista.id,
    user
  );

  const title = `${tipoVehiculo}`;

  if (placa) {
    const placaNumber = isDriver ? `(${placa})` : '';
    return `${tipoVehiculo} ${placaNumber}`;
  }

  return title;
};

export const getVehicleValueNotificationRequestNeg = (
  savedDataNegotation: SolicitudNegociacion,
  user: IUser
) => {
  const {
    vehiculo: { tipoVehiculo, placa },
  } = savedDataNegotation;

  const title = !!tipoVehiculo && `${tipoVehiculo}  `;

  const isDriver = validIsClientRequestNeg(
    savedDataNegotation.transportista.id,
    user
  );
  if (placa) {
    const placaNumber = isDriver ? `(${placa})` : '';
    return `${tipoVehiculo} ${placaNumber}`;
  }

  return title;
};

export const getMaxWeightVehicleRequestNeg = (
  savedDataNegotation: SolicitudNegociacion
) => {
  const {
    vehiculo: { cargaMaxima },
  } = savedDataNegotation;

  const title = !!cargaMaxima && ` Capacidad máxima de ${cargaMaxima} kilos   `;

  return title;
};

export const getImagesRequestNeg = (data: SolicitudNegociacion) => {
  const {
    solicitud: { fotos },
  } = data;

  if (fotos.length === 0) return [];

  const value = fotos.map((item, i) => ({
    id: i + 1,
    urlImagen: item,
  }));

  return value;
};

export const getImageVehicleRequestNeg = (
  data: SolicitudNegociacion,
  user: IUser
) => {
  const {
    vehiculo: { foto },
  } = data;

  const isDriver = validIsClientRequestNeg(data.transportista.id, user);

  if (!foto || isDriver) return '';

  const value = foto;

  return value;
};

export const getDescriptionLoadRequestNeg = (data: SolicitudNegociacion) => {
  const {
    solicitud: { descripcionCarga },
  } = data;

  return !!descripcionCarga ? descripcionCarga : '';
};

export const getValueInNegRequestNeg = (data: SolicitudNegociacion) => {
  const {
    solicitud: { condiciones },
  } = data;

  if (condiciones.length === 0) return [];

  return condiciones;
};

export const getProfileDataRequestNeg = (data: SolicitudNegociacion) => {
  let typeUser = !!data.flgIsOwner;

  const value = dataProfileFormatRequestNeg(
    data as SolicitudNegociacion,
    typeUser
  );

  return value;
};

export const renderTitleLongOriginRequestNeg = (data: SolicitudNegociacion) => {
  const newData = data.solicitud;
  const { origenTexto, referencia, tipoInmueble, pisoOrigen, flgAscensor } =
    newData;

  const textPrisoOrigen = !!pisoOrigen
    ? ` en piso ${pisoOrigen} (${handleShowAscensorRequestNeg(
        flgAscensor as number
      )})`
    : '';

  return [
    ` ${origenTexto}${handleReferenciaRequestNeg(referencia)}.`,
    `${tipoInmueble?.nombre}${textPrisoOrigen}.`,
  ];
};

export const renderTitleLongDestinyRequestNeg = (
  negotiation: SolicitudNegociacion
) => {
  const newData = negotiation.solicitud;
  const {
    pisoDestino,
    flgAscensorDestino,
    destinoTexto,
    referenciaDestino,
    tipoInmuebleDestino,
  } = newData;

  const pisoDestinoText = !!pisoDestino
    ? ` en piso ${pisoDestino} (${handleShowAscensorRequestNeg(
        flgAscensorDestino as number
      )})`
    : '';

  return [
    ` ${destinoTexto}${handleReferenciaRequestNeg(referenciaDestino)}.`,
    `  ${tipoInmuebleDestino?.nombre}${pisoDestinoText}.`,
  ];
};

export const renderEnvirontmentRequestNeg = (
  negotiation: SolicitudNegociacion
) => {
  const newData = negotiation.solicitud;
  const environtment = newData.ambientes as {
    ambiente: string;
    cantidad: number;
  }[];
  if (environtment.length === 0) return [];

  const value = environtment.filter((item) => !!item.cantidad);

  const result = value.map((item) => `${item.ambiente}: ${item.cantidad}`);
  return result;
};

const handleShowAscensorRequestNeg = (flg: number) => {
  let value = 'ascensor disponible para algunas cosas';
  if (flg === 1) value = 'ascensor disponible';
  if (flg === 2) value = 'sin ascensor';

  return value;
};

export const handleReferenciaRequestNeg = (referencia: string) => {
  return referencia ? ` (${referencia})` : ` ${referencia}`;
};
