import { handleTypeMultidestinyOfferRequest } from "@/pages/requestNegotiation/shared/utils/multidenityPriceUtils";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { currencyFormat } from "@/utils/helpers";
import { Box, Typography, styled } from "@mui/material";


export const SpanStyles = styled("span")(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Source Sans Pro, Roboto",
  fontWeight: 400,
  maxWidth: "320px",
  letterSpacing: "0.00938em",
  marginLeft: "3px",
  wordWrap: "break-word"
}));

export const PriceMultidestinyOfferResponse = ({ data }: { data: NoticeV2NegType | any }) => {
  const render = handleTypeMultidestinyOfferRequest(data);

  return (
    <Box>
      <Typography sx={{ pt: 2, fontWeight: 700, fontSize: "22px" }}>
        {currencyFormat(data.precio)}

        <SpanStyles >
          Por {render.type}s
        </SpanStyles>
      </Typography>
      <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
        Cantidad de {render.type}:
        <SpanStyles >
          {render.amount}
        </SpanStyles>
      </Typography>
      <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
        Precio total:
        <SpanStyles >
          {currencyFormat(render.total || 0)}
        </SpanStyles>
      </Typography>
    </Box>
  );
};

