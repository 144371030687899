import React, { useEffect, useRef, useState } from "react";
import {
  ContainerButtom,
  ContainerModal,
  CustomDrawer,
} from "../stylesContainerFlow";
import HeaderModal from "../headerModal/HeaderModal";
import { MetadaValues, ModalFilterProps } from "@/interfaces/filterInterface";
import { FormControlInput } from "@/pages/noticeV2/NoticePlace/noticePlaceStyles";
import Input from "@/components/inputCustom/Input";
import useDebounce from "@/hooks/useDebounse";
import { Stack } from "@mui/material";
import ListChip from "@/components/Filter/components/ListChip";
import CheckList from "@/components/Filter/components/CheckList";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { EventType } from "@/interfaces/globalInterface";
import { cleanInputString } from "@/utils/inputsUtils";

interface ModalFilterMultiSelectProps<T>
  extends Omit<
    ModalFilterProps,
    | "list"
    | "betweenDate"
    | "isMultiSelect"
    | "typeNoticeSelected"
    | "metaDataModal"
    | "modalActiveWith"
  > {
  list: T[];
  debounceTimer?: number;
  metaDataModal: MetadaValues;
  preSelected: T[];
}

const ModalFilterMultiSelect = <T extends unknown>(
  props: ModalFilterMultiSelectProps<T>
) => {
  const {
    open,
    onClose,
    metaDataModal,
    list,
    onSeleted,
    onInputChange,
    onSubmit,
    debounceTimer = 500,
    preSelected = [],
  } = props;
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected]: any[] = useState([]);

  const inputSearchRef = useRef<HTMLInputElement>(null);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const inputValueDebounse = useDebounce(inputValue, debounceTimer);

  useEffect(() => {
    if (!open) {
      setInputValue("");
      setSelected([]);
    } else {
      setSelected(preSelected); // TODO: crear funcion de pre llenado

      timer.current = setTimeout(() => {
        inputSearchRef.current?.focus();
      }, 100);
    }
    return () => {
      clearTimeout(timer.current as NodeJS.Timeout);
    };
  }, [open]);

  useEffect(() => {

    onInputChange && onInputChange(inputValueDebounse);
  }, [inputValueDebounse]);

  const handleChange = ({ target }: EventType["change"]) => {
    const { value } = target;
    const cleanValue = cleanInputString(value);
    setInputValue(cleanValue);
  };

  const handleValidCheck = (currentValue: string) => {
    const isChecked = selected?.some(
      (preSelect: any) => preSelect?.nombre === currentValue
    );

    return isChecked;
  };

  const handleOnSeleted = (newValue: any) => {
    if (!newValue) return;

    if (handleValidCheck(newValue?.nombre)) {
      const newSelects = selected?.filter(
        (select: any) => select.nombre !== newValue.nombre
      );

      setSelected(newSelects);
    } else {
      setSelected((prev: any) => [...prev, newValue]);
    }

    onSeleted && onSeleted(newValue);
  };

  const handleDeleteItemInList = (list: any[]) => {
    setSelected(list);
  };

  const handleOnsubmit = () => {
    onSubmit(selected);
  };

  return (
    <CustomDrawer open={open} onClose={() => onClose(false)} anchor="right">
      <HeaderModal title={metaDataModal.title} onClick={() => onClose(false)} />
      <ContainerModal sx={{ position: "relative" }}>
        <FormControlInput>
          <Input
            name={metaDataModal.inputName}
            label={metaDataModal.inputLabel}
            placeholder={metaDataModal.inputPlaceholder}
            onChange={handleChange}
            value={inputValue}
            inputRef={inputSearchRef}
            autoComplete="off"
          />

          <Stack direction="row" spacing={1} marginTop={"8px"} flexWrap="wrap">
            <ListChip
              list={selected}
              propertyName="nombre"
              onDelete={handleDeleteItemInList}
            />
          </Stack>
          <CheckList
            list={list}
            onSeleted={handleOnSeleted}
            selects={selected}
          />
        </FormControlInput>
      </ContainerModal>
      <ContainerButtom>
        <ButtomLoader disabled={!selected} onClick={handleOnsubmit}>
          {metaDataModal.buttonText}
        </ButtomLoader>
      </ContainerButtom>
    </CustomDrawer>
  );
};

export default ModalFilterMultiSelect;
