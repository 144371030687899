import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { Box, FormControl, Grid, Typography, styled } from "@mui/material";
import { useCounterOfferMultidestiny } from "./useCounterOfferMultidestiny";

const Container = styled(Grid)(
  () => `
    display: flex;
    flex-direction: column;  
    margin-top: 24px;
  `
);


export const FormCounterOfferMultiDestinyEdition = () => {
  
  const {
    isLoading,
    noticeRequestData,
    isCounterOffer,
    typeOfMultidestiny,
    price,
    handleOnchange,
    handleDisabledEditCounterOfferButton,
    handleEditCounterOfferSubmit,

  } = useCounterOfferMultidestiny()
  
  return  (
    <Container container>
      <form onSubmit={handleEditCounterOfferSubmit}>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: 900, fontSize: "14px" }}>
            Cantidad de {typeOfMultidestiny}s: &nbsp;{" "}
          </Typography>
          <Typography>{noticeRequestData.amountHourOrPoint}</Typography>
        </Box>

        <FormControl sx={{ marginTop: 1, mb: 2, width: "100%" }}>
          <Input
            id={`$1-price`}
            name="price"
            label={
              <LabelInputRequired
                str={`Precio por ${typeOfMultidestiny}s que ofreces`}
              />
            }
            value={price}
            variant="standard"
            placeholder={`Ingresa tu precio por ${typeOfMultidestiny}`}
            onChange={handleOnchange}
            onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
            }}
          />
        </FormControl>

        <Box sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: 900, fontSize: "16px" }}>
            Precio del servicio: &nbsp;{" "}
          </Typography>
          <Typography>
            {Number(price) * (noticeRequestData.amountHourOrPoint as number)}{" "}
          </Typography>
        </Box>

        <Grid item sx={{ marginTop: "16px" }}>
          <ButtomLoader
            isLoading={isLoading}
            type="submit"
            disabled={handleDisabledEditCounterOfferButton() || isLoading}
          >
            {isCounterOffer ? "ENVIAR" : "SIGUIENTE"}
          </ButtomLoader>
        </Grid>
      </form>
    </Container>
  );
}
