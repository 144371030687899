import { useAppSelector } from "@/hooks/useReducer";
import useResize from "@/hooks/useResize";
import { RoleAPI, UsuarioAPI } from "@/models/userAuth/user";
import IconsSvg from "@/pages/detailNotice/components/IconsSvg";
import { ILinks } from "@/pages/profile/interfaces/profileInterface";
import Icon from "@/utils/Icon";
import {
  Collapse,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomListLinks } from "./styleListItemMulti";

interface IProps {
  data: ILinks;
}

const DOCUMENT_RUC = "4";

const ListItemMulti = ({ data }: IProps) => {
  const [, isMobil] = useResize();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useAppSelector((state) => state.user);
  const [show, setShow] = useState(true);

  const currentUser = user as UsuarioAPI;

  const handleNavigate = (path: string, subLinks?: ILinks[] | undefined) => {
    if (!isMobil && subLinks) {
      setShow(!show);
      return;
    }

    navigate(path);
  };

  const handleGetColorIcon = (subdata: ILinks) => {
    if (pathname === subdata.path) {
      return "info";
    }
  }

  const handleGetPrimaryName = (subdata: ILinks) => {
    const { nombre } = subdata;
    const nombre2 = subdata?.nombre2;
    const isDocumenRuc =
      currentUser.tipoDocumento?.id.toString() === DOCUMENT_RUC;
    if (isDocumenRuc && nombre2) {
      return nombre2;
    }

    return nombre;
  };

  const handleGetColor = (subdata: ILinks) => {
    return pathname === subdata.path ? "info.main" : "text.primary";
  };

  const handleGetListItemIcon = () => {
    if (!isMobil && data?.subLinks && show) {
      return "icon-small-arrow-up";
    }
    return "icon-small-arrow-right";
  };

  const handleGetBorderListItem = () => {
    if (!isMobil && pathname?.includes(data?.path)) {
      return "2px solid #00B3E3";
    }

    return "";
  };

  const handleValirSubLinks = (subdata: ILinks) => {
    const [role] = currentUser.role as RoleAPI[];

    return role?.nombre && subdata?.role?.includes(role?.nombre);
  };

  const listItemIcon = handleGetListItemIcon();

  return (
    <>
      <ListItem
        disablePadding
        sx={{
          ".css-cveggr-MuiListItemIcon-root": {
            minWidth: "5px",
          },
        }}
        onClick={() => handleNavigate(data?.path, data?.subLinks)}
      >
        <ListItemButton
          sx={{
            padding: isMobil ? "10px 0px" : "10px 10px",
            border: handleGetBorderListItem(),
            borderRadius: "8px",
            margin: "0px 0px 16px 0px",
            height: "56px",
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: "40px",
            }}
          >
            <Icon name={data.icon} size="md" />
          </ListItemIcon>
          <ListItemText primary={data.nombre} />
          {!isMobil && data.subLinks && (
            <ListItemIcon>
              <IconsSvg icon={listItemIcon} />
            </ListItemIcon>
          )}
          {isMobil && (
            <ListItemIcon>
              <i className="icon-small-arrow-right" />
            </ListItemIcon>
          )}
        </ListItemButton>
      </ListItem>
      {!isMobil && data?.subLinks && (
        <Collapse in={show} timeout="auto" unmountOnExit>
          <CustomListLinks>
            {data?.subLinks.map((subdata, index) => {
              if (handleValirSubLinks(subdata)) {
                return (
                  <ListItem
                    disablePadding
                    key={index}
                    sx={{
                      ".css-cveggr-MuiListItemIcon-root": {
                        minWidth: "5px",
                      },
                      pl: 4,
                      color: handleGetColor(subdata),
                      fontWeight: 300,
                    }}
                    onClick={() => handleNavigate(subdata.path)}
                  >
                    <ListItemButton
                      sx={{
                        padding: "10px 5px",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: "40px",
                        }}
                      >
                        {/* <IconsSvg icon={handleGetPrimaryIconSvg(subdata)} /> */}
                        <Icon name={subdata.icon} size="md" 
                          color={handleGetColorIcon(subdata)}
                        />
                      </ListItemIcon>
                      <ListItemText primary={handleGetPrimaryName(subdata)} />
                    </ListItemButton>
                  </ListItem>
                );
              }
            })}
          </CustomListLinks>
        </Collapse>
      )}
    </>
  );
};

export default ListItemMulti;
