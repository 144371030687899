import { CarrierModel, DetailsCoreModel } from '@/models/notice/noticeModels';
import { DataProfileDriverFormatReturn } from '../acceptanceOffert/adapters/AcceptanceAdapter';
import { ConfigDataIncity } from './components/detailOptionsList/detailsOptionsInterface';

export const getTransporterRatingFormat = (
  driver: CarrierModel
): DataProfileDriverFormatReturn => {
  return {
    idUser: driver?.id,
    userName: driver?.nombre,
    urlProfile: driver?.perfilUrl,
    rating: driver?.calificacion,
    ratingText: driver?.calificacionTexto,
    showPrice: false,
    showTwoAvatars: false,
    sx: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
};

export const handleInitConfig = (
  detail: ConfigDataIncity,
  notice: DetailsCoreModel
) => {
  const { valid, props } = detail;

  if (!valid || !notice) return { props };

  const moreProps = valid.reduce((acc, el) => {
    let value = notice[el.property];

    if (el?.callback) {
      value = el.callback(notice, props, el?.callbakcParams);
    }

    if (typeof el.defaultResult === 'boolean') {
      acc = {
        ...acc,
        [el.componentProp]: !!value,
      };
    } else {
      acc = {
        ...acc,
        [el.componentProp]: value || el.defaultResult,
      };
    }
    return acc;
  }, {});

  return { props: { ...props, ...moreProps } };
};

// Elimina las palabras duplicadas, de una cadena de string
export const cleanStringDuplicates = (
  str: string,
  separator = ','
): Array<string> => {
  if (!str) return [];

  const cleanString = new Set(str.split(separator));

  return Array.from(cleanString);
};

//convierte un array en un string!!
export const convertArrayToString = (arr: Array<string>, separator = ',') => {
  if (!arr.length) return '';

  return arr.join(separator);
};
