import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import {
  setMultiSelectDestinyV2,
  setMultiSelectOrigin,
} from "@/store/locationRedux/selectLocation";
import { Chip, Stack, Typography } from "@mui/material";
import { useEffect, useId, useRef, useState } from "react";

import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import { FormControlInput } from "@/pages/noticeV2/NoticePlace/noticePlaceStyles";
import { AccordionModalV2Props, SelectedDistrict } from "../../configData";
import {
  AccordionContainerV2,
  AccordionTitleV2,
  ContainerModalButtomMultiShopV2,
  ContainerModalMultiShopV2,
  StyledCheckboxV2,
} from "../../styles/formRuteMultidestinyStyles";

const AccordionModalV2 = ({
  listAccordion,
  modalActiveWith,
  onSubmit,
  inputProps,
  inputValue,
  handleOnInputChange,
}: AccordionModalV2Props) => {
  const dispatch = useAppDispatch();
  const {
    selectLocation: { multiSelectDestiny, multiSelectOrigin },
  } = useAppSelector((state) => state);

  const id = useId();
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const [selectedDistrict, setSelectedDistrict] = useState<
    SelectedDistrict[] | any[]
  >([]);

  useEffect(() => {
    if (modalActiveWith === "destiny") {
      setSelectedDistrict(multiSelectDestiny);
    } else {
      setSelectedDistrict(multiSelectOrigin);
    }
  }, [modalActiveWith]);

  const handleCheckboxAll =
    (district: SelectedDistrict) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
     
      if (event.target.checked) {
        const datos = {
          id: district.id,
          name: district.nombre2 ? district.nombre2 : district.nombre,
        };
        setSelectedDistrict(
          (prev) => [...(prev || []), datos] as SelectedDistrict[]
        );
      } else {
        handleRemovefromList(district);
      }
    };

  const handleRemovefromList = (district: SelectedDistrict) => {
    const datos = selectedDistrict.filter(
      (item: SelectedDistrict) => item.id !== district.id
    );
    setSelectedDistrict(datos as SelectedDistrict[]);
  };

  const findSelected = (value: any): boolean => {
    if (!Array.isArray(selectedDistrict)) return false;

    const isChecked = selectedDistrict.some(
      (selected) => selected.id === value.id
    );

    return isChecked;
  };

  const handleOnClickFather = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const handleOnSubmit = () => {
    switch (modalActiveWith) {
      case "origin":
        dispatch(setMultiSelectOrigin(selectedDistrict));
        break;
      case "destiny":
        dispatch(setMultiSelectDestinyV2(selectedDistrict));
        break;
      default:
        break;
    }
    onSubmit();
  };


  return (
    <>
      <ContainerModalMultiShopV2>
        <FormControlInput fullWidth>
          <Input
            id={id}
            inputRef={inputSearchRef}
            label={inputProps.label}
            name={inputProps.name}
            value={inputValue}
            variant="standard"
            placeholder={inputProps.placeholder}
            onChange={handleOnInputChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {!!selectedDistrict.length && (
            <Stack
              direction="row"
              spacing={1}
              marginTop={"8px"}
              flexWrap="wrap"
            >
              {selectedDistrict.map((item) => (
                <Chip
                  key={item.id}
                  label={item.name}
                  variant="outlined"
                  onDelete={() => handleRemovefromList(item)}
                  sx={{ marginBottom: "8px !important" }}
                />
              ))}
            </Stack>
          )}
        </FormControlInput>
        {listAccordion &&
          listAccordion?.map((item, i) => (
            <AccordionContainerV2 disableGutters elevation={0} key={i}>
              <AccordionTitleV2 expandIcon={<></>}>
                <StyledCheckboxV2
                  color="warning"
                  checked={findSelected(item)}
                  onChange={handleCheckboxAll(item)}
                  onClick={handleOnClickFather}
                />
                <Typography variant="body1" marginY="auto">
                  {!!item.nombre2 ? item.nombre2 : item.nombre}
                </Typography>
              </AccordionTitleV2>
            </AccordionContainerV2>
          ))}
      </ContainerModalMultiShopV2>
      <ContainerModalButtomMultiShopV2>
        <ButtomLoader
          buttomProps={{ disabled: !selectedDistrict?.length }}
          onClick={handleOnSubmit}
        >
          Guardar
        </ButtomLoader>
      </ContainerModalButtomMultiShopV2>
    </>
  );
};

export default AccordionModalV2;
