import { OFFER_MOVING_FLOW_PATH } from '@/pages/flowNoticeNegotation/shared/const/routes/clientAccepOrCounteroffer';
import FormDateDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/childComponents/formDateDetails/Index';
import FormDestinyDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/childComponents/FormDestinyDetails/Index';
import FormOriginDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/childComponents/FormOriginDetails/Index';
import ConfirmationDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/containers/ConfirmationDetails/Index';
import DateDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/containers/DateDetails/Index';
import RuteDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/containers/RuteDetails/Index';
import OfferBodyConfirmationMoving from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/childComponents/FormConfirmationDetails/OfferBodyConfirmationMoving';
import { privateOffertMiddleware } from '@/router/middleware/privateCreateServicesMiddleware';
import { RouterProps } from '@/router/router';

export const offerNoticeNegMovingRouter: RouterProps[] = [
  {
    path: OFFER_MOVING_FLOW_PATH[0],
    Component: DateDetails,
    requiredSession: true,
    middleware: privateOffertMiddleware,
    children: [
      {
        index: true,
        Component: FormDateDetails,
      },
    ],
  },
  {
    path: OFFER_MOVING_FLOW_PATH[1],
    Component: RuteDetails,
    requiredSession: true,
    middleware: privateOffertMiddleware,
    children: [
      {
        index: true,
        Component: FormOriginDetails,
      },
    ],
  },
  {
    path: OFFER_MOVING_FLOW_PATH[2],
    Component: RuteDetails,
    requiredSession: true,
    middleware: privateOffertMiddleware,
    children: [
      {
        index: true,
        Component: FormDestinyDetails,
      },
    ],
  },
  {
    path: OFFER_MOVING_FLOW_PATH[3],
    Component: ConfirmationDetails,
    requiredSession: true,
    middleware: privateOffertMiddleware,
    children: [
      {
        index: true,
        Component: OfferBodyConfirmationMoving,
      },
    ],
  },
];
