import { MetaDataResponseAPI } from '../mainAPI/mainAPIModels';
import { TiposFiltro } from '../noticeNegotation/noticeNegotationModel';
export interface IAgreementToAcceptAPI {
  metadata: Metadatum[];
  acuerdosporaceptarData: IAcuerdosporaceptarData;
}

export interface IAcuerdosporaceptarData {
  titulo: string;
  acuerdosporaceptar: IAcuerdosporaceptar[];
  totalItems: number;
  totalPages: number;
  currentPage: number;
}

export interface IAcuerdosporaceptar {
  id: number;
  estado: string;
  flgavisosolicitud: number;
  idtipo: number;
  titulo1: string;
  titulo2: string;
  tipovehiculo: string;
  pesoestimado: string;
  flgcargarefrigerada: string;
  titulo3: string;
  flghoraopunto: null;
  horasopuntos: null;
  precio: number;
  imagen: string;
  link: null;
  nombretipo: string;
  flgciudadoentreciudades: number;
  ayudantes: number;
}

export interface Metadatum {
  tipo: string;
  codigo: string;
  dato: string;
}

// custom type for the body of the request
export type ISetAgreementToAcceptBody = {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  acuerdosporaceptar: IAcuerdosporaceptar[];
};

// type
export type TAgreementToAcceptBody = {
  page: number;
  size: number;
  ordenamiento: Array<string>;
};

// offert type send
export type TOffertBodySend = {
  page: number;
  size: number;
  ordenamiento: Array<string>;
  tiposFiltro: TiposFiltro[];
};

// ==================================================
//                   My Negotiationes list
// ==================================================
export interface MyNegotiationesListAPI {
  metadata: MetaDataResponseAPI[];
  negociacionesPorUsuario: MyNegotiationesList;
}

export interface MyNegotiationesList {
  negociaciones: MyNegotiationes[];
  totalItems: number;
  totalPages: number;
  currentPage: number;
}

export interface MyNegotiationes {
  flgAvisoSolicitud: number;
  idTipo: number;
  fotoAvatar: string;
  fotoPrincipal: string;
  origenTexto: string;
  destinoTexto: string;
  colorEstado: string;
  estado: string;
  fecha: string;
  placa: string;
  peso: number;
  precio: number;
  link: string;
  flgHoraoPunto?: number;
  precioDetalle?: string;
  flgCiudadoEntreciudad?: number;
  precioRegular?: number;
  descripcionCarga?: string;
}

export enum TypeFilterEnum {
  abiertas = 'Abiertas',
  cerradas = 'Cerradas',
  vencidas = 'Vencidas',
}
export interface MyNegotationBodyDTO {
  page: number;
  size: number;
  ordenamiento: string[];
  tiposFiltro: TypefilterDTO[];
}

export interface TypefilterDTO {
  nombre: string;
  opciones: Array<TypeFilterEnum>;
}
