import { ANDROID_APP_URL, HUAWEI_APP_URL, IOS_APP_URL } from "@/consts/appStorePaths";
import { HOME_PAHT } from "@/consts/typeServicesPaths";
import AppGallery from "@/img/sharedRider/AppGallery.svg";
import AppStoreLogo from "@/img/sharedRider/AppStoreLogo.svg";
import GooglePlayLogo from "@/img/sharedRider/GooglePlayLogo.svg";
import BrandIcon from "@/pages/header/components/BrandIcon";
import { Grid, styled } from "@mui/material";
import { forwardRef } from "react";

const Container = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  padding: "16px 8px",
}));

const GridItem = styled(Grid)(
  () => `
  display: flex;
  align-items: center;
  justify-content: center;
`
);

type Ref = HTMLDivElement | null;

const HeaderSharedRide = forwardRef<Ref, any>((_, ref) => {
  return (
    <Container
      container
      spacing={2}
      justifyContent={"center"}
      columnGap={2}
      ref={ref}
    >
      <Grid xs={12} justifyContent={"center"} alignItems={"center"}>
        <BrandIcon
          route={HOME_PAHT}
          onClose={() => {}}
          icon={"logoBrand--headercom"}
          sx={{ display: "flex", justifyContent: "center" }}
        />
      </Grid>
      <GridItem xs={3} sm={4} md={2} alignContent={"center"}>
        <a
          href={ANDROID_APP_URL}
          target="_blank"
          rel="noreferrer"
        >
          <img src={GooglePlayLogo} alt="GooglePlayLogo" width="100%" />
        </a>
      </GridItem>
      <GridItem xs={3} sm={4} md={2}>
        <a
          href={IOS_APP_URL}
          target="_blank"
          rel="noreferrer"
        >
          <img src={AppStoreLogo} alt="AppStoreLogo" width="100%" />
        </a>
      </GridItem>
      <GridItem
        xs={3}
        sm={4}
        md={2}
        sx={{ marginTop: { sm: "5px", md: 0, lg: 0, xs: 0, xl: 0 } }}
      >
        <a
          href={HUAWEI_APP_URL}
          target="_blank"
          rel="noreferrer"
        >
          <img src={AppGallery} alt="AppGallery" width="100%" />
        </a>
      </GridItem>
    </Container>
  );
});

export default HeaderSharedRide;
