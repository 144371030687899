import { Grid, styled } from '@mui/material';

export const Container = styled(Grid)(
  () => `
      display: flex;
      flex-direction: column;  
      margin-top: 24px;
    `
);

export const Form = styled('form')(
  () => `
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 16px;
    `
);
