import { actionsToNoticeNegotation } from "@/consts/actions";
import { optionOne, optionThree, optionTwo } from "@/consts/noticeConsts";
import {
  nottifficationDeclineMsgV2,
  nottifficationPriceChangedV2,
} from "@/consts/notifficationsMessage";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { UltimaAvisoNegociacionAcciones } from "@/models/notice/noticeV2NegotationModels";
import { saveOfferByIdThunkV2 } from "@/store/noticeV2Negotiation/noticeV2NegotiationThunk";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { ActionDataLayerV2, typeNoticeName } from "../config";
import { nextUrlFormatV2 } from "../utils/utils";
import useGetData from "./useGetData";

export const useGlobalCoCounterOfferButtons = () => {
  const {
    user: { user },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const userId = (user as IUser).id;
  const { notice, typeNoticeNegotationId } = useGetData();
  const typeNoticeId: number = notice?.idtipoaviso as number;
  const nameTypeNotice = typeNoticeName[typeNoticeId];
  const { addNotiffication } = useNotiffication();
  const navigate: NavigateFunction = useNavigate();
  const { tipoAvisoNegociacionAccion } =
    notice?.ultimaAvisoNegociacionAcciones as UltimaAvisoNegociacionAcciones;
  const actionId = tipoAvisoNegociacionAccion?.id as number;

  const handleManageModalPriceChangedV2 = (functionOptional?: () => void) => {
    addNotiffication({
      ...nottifficationPriceChangedV2,
      hiddenSnackbar: true,
      hiddenModal: false,
      onSubmit: async () => {
        functionOptional && functionOptional();
      },
    });
  };

  const handleManageModalDeclineV2 = (): void => {
    let messageText = nottifficationDeclineMsgV2;

    if (typeNoticeNegotationId === optionTwo)
      messageText = nottifficationDeclineMsgV2;

    addNotiffication({
      ...messageText,
      hiddenSnackbar: true,
      hiddenModal: false,
      onSubmit: async () => {
        handleDeclineCoCounterOfferButtonV2();
      },
    });
  };

  const handleAcceptCoCounterOfferButtonV2 = () => {
    let actionButton = actionsToNoticeNegotation[6].id;
    const idAction = actionId;

    const data = {
      idOffer: notice?.id as number,
      idAccion: actionButton,
      precioactual: notice?.preciocontraoferta,
    };

    dispatch(saveOfferByIdThunkV2(data)).then((value) => {
      if (ActionDataLayerV2[typeNoticeId]) {
        const dataLayerFunction = ActionDataLayerV2[typeNoticeId][optionOne];
        dataLayerFunction(userId, nameTypeNotice as string);
      }

      if (!!value.flgpreciocambiado) {
        handleManageModalPriceChangedV2();
      } else {
        const path = nextUrlFormatV2(`${notice?.id}`, idAction, optionThree);
        navigate(path, {
          replace: true,
        });
      }
    });
  };

  const handleDeclineCoCounterOfferButtonV2 = (): void => {
    let newOption = optionTwo;
    let action = actionId;
    let actionButton = actionsToNoticeNegotation[7].id;

    const data = {
      idOffer: notice?.id as number,
      idAccion: actionButton,
      precioactual: notice?.preciocontraoferta,
    };

    dispatch(saveOfferByIdThunkV2(data)).then((value) => {
      if (!!value.flgpreciocambiado) {
        handleManageModalPriceChangedV2();
      } else {
        const path = nextUrlFormatV2(
          `${notice?.id as number}`,
          action,
          newOption
        );
        navigate(path, { replace: true });
      }
    });
  };

  return {
    handleAcceptCoCounterOfferButtonV2,
    handleDeclineCoCounterOfferButtonV2,
    handleManageModalDeclineV2,
  };
};
