import { FORMAT_DATE_DDMMYYYY } from '@/consts/formatsDate';
import { ITiposAmbientes } from '@/models/customerRequest/customerRequestResponse';
import { RequestConditions } from '@/models/customerRequest/customerRequestTypesConditions';
import {
  Conditions,
  RequestCondiciones,
  RequestEnvironment,
  RequestRute,
  Requestdestiny,
  Requestorigin,
} from '@/models/noticeNegotation/noticeNegotationModel';
import { ICustomerPhotosProps } from '@/services/interfaces/customerRequestInterface';
import { UploadLoadRequestEditProps } from '@/store/noticeNegotationRequestRedux/editNegRequestThunk';
import { Dayjs } from 'dayjs';
import { Ambiente } from '../../models/noticeNegotation/noticeNegotationModel';
import { NegotiationPhotosProps } from '@/services/interfaces/negotiationInterface';
import { optionOne } from '@/consts/noticeConsts';

export interface RequestRuteAdapter {
  datePicker: Dayjs | string;
  originReference: string;
  destinyReference: string;
  idOrigen?: number;
  idDestino?: number;
  origin: string;
  destiny: string;
}

export const convertDate = (date: Dayjs) => {
  const newDate = date.format(FORMAT_DATE_DDMMYYYY);

  return newDate;
};

export const requestRuteAdapter = (body: RequestRuteAdapter) => {
  const newDate = convertDate(body.datePicker as Dayjs);

  return {
    fecha: newDate,
    referencia: body.originReference,
    referenciaDestino: body.destinyReference,
  };
};

export const requestConditionsAdapter = (
  body: RequestConditions[]
): RequestCondiciones => {
  const result = body.map((item) => ({
    idCondicion: item?.idcondicion,
    flgSeleccion: item.isActive ? 1 : 0,
  }));

  const coditions = { condiciones: result as Conditions[] };

  return coditions;
};

interface CurrentForm {
  idInmueble: string;
  piso: string | null;
  elevador: string | null;
}

export const requestOriginMovingAdapter = (
  reference: string,
  currentForm: CurrentForm
): Requestorigin => {
  const idInmueble = Number(currentForm.idInmueble);
  const isHomeId = idInmueble === optionOne;

  const result = {
    referencia: reference,
    idInmueble: idInmueble,
    piso: isHomeId ? null : Number(currentForm.piso),
    flgAscensor: isHomeId ? null : Number(currentForm.elevador),
  };

  return result;
};

export const requestDestinyMovingAdapter = (
  reference: string,
  currentForm: CurrentForm
): Requestdestiny => {
  const idInmueble = Number(currentForm.idInmueble);
  const isHomeId = idInmueble === optionOne;
  const result = {
    referenciaDestino: reference,
    idInmuebleDestino: idInmueble,
    pisoDestino: isHomeId ? null : Number(currentForm.piso),
    flgAscensorDestino: isHomeId ? null : Number(currentForm.elevador),
  };

  return result;
};

export const requestEnvironmentAdapter = (
  ambientes: ITiposAmbientes[],
  metraje: number | string
): RequestEnvironment => {
  const evironment = ambientes.map((item) => ({
    idAmbiente: item.id,
    cantidad: item.cantidad,
  }));

  const ambientesData = evironment as Ambiente[];

  const result = {
    ambientes: ambientesData,
    metraje: Number(metraje),
  };

  return result as RequestEnvironment;
};

export interface RequestLoadAdapter extends ICustomerPhotosProps {
  descripcionCarga: string;
  idRangoPeso: number;
  idTipoVehiculo: number;
}

interface ImgsType {
  images: NegotiationPhotosProps;
}

type RequestAdapterType = Omit<UploadLoadRequestEditProps, 'images'> & ImgsType;

export const requestLoadAdapter = (
  data: RequestAdapterType
): RequestLoadAdapter => {
  const { images, descriptionLoadInput, idRangoPeso, idTypeVehicle } = data;

  return {
    descripcionCarga: descriptionLoadInput,
    idRangoPeso: idRangoPeso as number,
    idTipoVehiculo: idTypeVehicle,
    ...images,
  };
};
