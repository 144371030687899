import { useAppSelector } from "@/hooks/useReducer";
import { objectRenderComponent } from "./config";

const AgreementConfirmation = () => {
  const { noticeNegotationRequest: {savedDataNogotation}  } = useAppSelector((state) => state);




  const Component = objectRenderComponent[savedDataNogotation?.tipoSolicitudNegociacion.id as number];
  if (!Component) return <>error</>;

  return <Component />;

};


export default AgreementConfirmation