import ConditionsListOffert from '@/components/conditionsList/ConditionsListOffert';
import GenericComponent from '@/components/genericComponent/GenericComponent';
import UserRating from '@/components/userRating/UserRating';
import { descriptionLoad } from '@/consts/noticeConsts';
import DetailsTextMoving from '@/pages/customerRequest/application/components/DetailsTextMoving';
import { ConfigDataNegotation } from '@/pages/noticeRequestFlow/interface/noticeNegRequestInterface';
import {
  BoxSpace,
  DetailTypeFlows,
  TypographyEmpty,
} from '@/pages/noticeRequestFlow/utils/utilsComponents';
import {
  getCoCounterOffertFormatSendDate,
  getCounterOffertFormatSendDate,
  getDeclineCounterOffertFormatDate,
  getFormatSendDate,
  getMaxWeigth,
  getRefrigeratedLoad,
  getTitleOriginAndDestiny,
  getTitlePriceCoCounterOffer,
  getTitlePriceCounterOffer,
  getTitleTypeCounterOffert,
  getValueInOffert,
  getVehicleValueNotification,
  getVehicleValues,
  renderRatingTransporter,
} from '@/pages/noticeRequestFlow/utils/utilsNegotation';
import DetailsText from '@/pages/offerts/offertConfirmation/components/DetailsText';
import { Box, Typography } from '@mui/material';

// ==================================================
//          CONFIGURACION EN LA CIUDAD
// ==================================================

export const configDataClientCounterOfferOneInCity: ConfigDataNegotation[] = [
  {
    title: 'Tu solicitud',
    Component: Typography,
    props: {
      variant: 'body2',
      sx: { color: '#757575', mb: '4px' },
    },
  },
  {
    Component: DetailTypeFlows,
    props: {},
    valid: [
      {
        property: 'solicitud',
        componentProp: 'nombre',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'tipoSolicitud', 'nombre'],
      },
      {
        property: 'solicitud',
        componentProp: 'id',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'tipoSolicitud', 'id'],
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'title',
        defaultResult: '',
        callback: getTitleOriginAndDestiny,
      },
    ],
  },
];

export const configDataClientCounterOfferTwoInCity: ConfigDataNegotation[] = [
  {
    title: '',
    Component: Box,
    props: {
      variant: 'body2',
      sx: { width: '100%', mt: '16px' },
    },
  },

  {
    title: '',
    Component: Box,
    props: {
      variant: 'body2',
      sx: { width: '100%', mt: '16px' },
    },
  },
  {
    title: 'Detalles del acuerdo',
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
    },
  },

  {
    Component: DetailsTextMoving,
    props: {
      icon: 'icon-calendar--white',
      title: 'Fecha del servicio',
      originDescription: '',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'fecha'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getFormatSendDate,
        callbakcParams: ['solicitud', 'fecha'],
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-location-medium',
      title: 'Origen',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: false,
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'origentexto'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'origentexto'],
      },
      {
        property: 'referencia',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'referencia'],
      },
    ],
  },

  {
    Component: DetailsText,
    props: {
      icon: 'icon-location-medium',
      title: 'Destino',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'destinotexto'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'destinotexto'],
      },
      {
        property: 'referenciaDestino',
        componentProp: 'originDescription',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'referenciaDestino'],
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-car-medium',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getVehicleValues,
        callbakcParams: ['solicitud', 'tipoVehiculo', 'nombre'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getVehicleValueNotification,
      },
    ],
  },
  {
    title: '',
    Component: Box,
    props: {
      variant: 'body2',
      sx: { width: '100%', mt: '16px' },
    },
  },
  {
    title: descriptionLoad,
    Component: Typography,
    props: {
      variant: 'subtitle1medium',
    },
  },
  {
    Component: GenericComponent,
    props: {
      icon: '',
      title: '',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'descripcionCarga'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'descripcionCarga'],
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-balance-medium',
      title: 'Peso estimado',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getMaxWeigth,
        callbakcParams: ['solicitud', 'pesoestimado'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getMaxWeigth,
        callbakcParams: ['solicitud', 'pesoestimado'],
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-snowFlake-medium',
      title: 'Necesita refrigeración',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getValueInOffert,
        callbakcParams: ['solicitud', 'flgcargarefrigerada'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getRefrigeratedLoad,
        callbakcParams: ['solicitud', 'flgcargarefrigerada'],
      },
    ],
  },
];

export const cofigDataClientCounterOfferConditionsInCity: ConfigDataNegotation[] =
  [
    {
      Component: ConditionsListOffert,
      props: {
        title: 'Condiciones del servicio:',
        style: { marginTop: '22px' },
      },
      valid: [
        {
          property: 'solicitud',
          componentProp: 'show',
          defaultResult: false,
          callback: getValueInOffert,
          callbakcParams: ['solicitud', 'condiciones'],
        },
        {
          property: 'solicitud',
          componentProp: 'listServices',
          defaultResult: [],
          callback: getValueInOffert,
          callbakcParams: ['solicitud', 'condiciones'],
        },
      ],
    },
  ];

export const configDataClientCounterOfferThreeInCity: ConfigDataNegotation[] = [
  {
    Component: Typography,
    title: 'Contraoferta del transportista',
    props: {
      variant: 'body2',
      sx: { color: '#757575', mt: '4px' },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'title',
        defaultResult: '',
      },
    ],
  },
  {
    Component: UserRating,
    props: {
      containerSx: { mt: '16px', mb: '5px' },
      viewRating: true,
    },
    valid: [
      {
        property: 'transportista',
        componentProp: 'data',
        defaultResult: {},
        callback: renderRatingTransporter,
      },
    ],
  },
  {
    Component: DetailsText,
    props: {
      icon: 'icon-car-medium',
      title: 'Vehículo',
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'show',
        defaultResult: true,
        callback: getVehicleValues,
        callbakcParams: ['solicitud', 'tipoVehiculo', 'nombre'],
      },
      {
        property: 'solicitud',
        componentProp: 'origen',
        defaultResult: '',
        callback: getVehicleValues,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'h4',
      sx: { fontSize: '22px' },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'title',
        defaultResult: '',
        callback: getTitlePriceCounterOffer,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'body2',
      sx: { color: '#757575', mb: '4px' },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'title',
        defaultResult: '',
        callback: getCounterOffertFormatSendDate,
      },
    ],
  },
  {
    Component: BoxSpace,
    props: {
      variant: 'body2',
      sx: {
        width: '100%',
        borderTop: '1px solid #E0E0E0',
        mt: '16px',
        mb: '16px',
      },
      title: '',
    },
    valid: [
      {
        property: 'preciocontraoferta',
        componentProp: 'show',
        defaultResult: false,
      },
    ],
  },
  {
    Component: TypographyEmpty,
    props: {
      variant: 'body2',
      sx: { color: '#757575' },
      title: 'Contraoferta del cliente',
    },
    valid: [
      {
        property: 'preciocontraoferta',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'preciocontraoferta',
        componentProp: 'title',
        defaultResult: '',
        callback: getTitleTypeCounterOffert(1),
      },
    ],
  },
  {
    Component: TypographyEmpty,
    props: {
      variant: 'h4',
      sx: { fontSize: '22px' },
    },
    valid: [
      {
        property: 'preciocontraoferta',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'preciocontraofert',
        componentProp: 'title',
        defaultResult: '',
        callback: getTitlePriceCoCounterOffer,
      },
    ],
  },
  {
    Component: TypographyEmpty,
    props: {
      variant: 'body2',
      sx: { color: '#757575', mb: '4px' },
    },
    valid: [
      {
        property: 'preciocontraoferta',
        componentProp: 'show',
        defaultResult: false,
      },
      {
        property: 'preciocontraofert',
        componentProp: 'title',
        defaultResult: '',
        callback: getCoCounterOffertFormatSendDate,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: 'body2',
      sx: { color: 'red', mb: '4px' },
    },
    valid: [
      {
        property: 'solicitud',
        componentProp: 'title',
        defaultResult: '',
        callback: getDeclineCounterOffertFormatDate,
      },
    ],
  },
];

export const ConfigCounterOfferInCityClient = {
  1: configDataClientCounterOfferOneInCity,
  2: configDataClientCounterOfferTwoInCity,
  3: cofigDataClientCounterOfferConditionsInCity,
  4: configDataClientCounterOfferThreeInCity,
};
