import SkeletonViewService from '@/pages/loading/SkeletonViewService';
import FinishedLayout from '../components/FinishedLayout';
import useNegotationFinishedAcceptHook from './hook';
import { Stack } from '@mui/material';

const NegotationFinishedAccept = () => {
  const {
    isLoadingNoticeV2,
    notice,
    Component
  } = useNegotationFinishedAcceptHook();

  if (isLoadingNoticeV2 || !notice) return <SkeletonViewService />;

  return <FinishedLayout>
    <Stack
      sx={{
        wordWrap: "break-word",
      }}
    >
      <Component notice={notice} />
    </Stack>
  </FinishedLayout>
}

export default NegotationFinishedAccept