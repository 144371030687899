import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { useAppSelector } from "@/hooks/useReducer";
import { MY_PENDING_AGREEMENTS } from "@/pages/noticeV2Edition/shared/consts/urlPaths";
import { DataResponseEnglishTypeTwo } from "@/store/noticeV2ClientAcceptCounterOffer/interfaces/dataResponseEnglishInterfaces";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import DynamicComponent from "../../../../components/DynamicComponent";
import {
  ConfigDataNotice,
  ValidFormat,
} from "../../../../interfaces/interfaces";
import { configDataMoving } from "./configurations/configDataAuctionMoving";
import { configDataInCity } from "./configurations/configDataInCity";

const configToRender: Record<number, ConfigDataNotice[]> = {
  1: configDataInCity,
  2: configDataInCity,
  5: configDataMoving,
};

interface Props {
  hiddenButton?: boolean;
}

const FormConfirmationDetails = ({ hiddenButton }: Props) => {
  const navigate = useNavigate();
  const {
    noticeV2ClientAorCO: { dataResponse, idTypeNoticeNegotiation },
  } = useAppSelector((state) => state);
  const data = dataResponse as DataResponseEnglishTypeTwo;

  const handleGetConfig = () => {
    const configTypeFlow = configToRender[data.negotiationTypeId];

    if (!data) return configDataInCity;

    return configTypeFlow;
  };

  const selectdConfigData = useMemo(() => handleGetConfig(), []);

  const handleFormat = (currentValue: any, format?: ValidFormat) => {
    if (!format || !data) return currentValue;

    let { template, valuesToReplace } = format;

    const newValue = valuesToReplace.reduce((acc, el) => {
      const value = data[el.value]?.toString() || el.defaultResult;

      return acc.replace(el.search, value);
    }, template);

    return newValue;
  };

  const handleValid = ({ valid, props }: ConfigDataNotice) => {
    if (!valid || !data) return { props };

    const moreProps = valid.reduce((acc, el) => {
      let value = handleFormat(data[el.property], el?.format);

      if (el?.callback) {
        value = el.callback(idTypeNoticeNegotiation, value, data);
      }

      if (typeof el.defaultResult === "boolean") {
        acc = {
          ...acc,
          [el.componentProp]: !!value,
        };
      } else {
        acc = {
          ...acc,
          [el.componentProp]: value || el.defaultResult,
        };
      }
      return acc;
    }, {});

    return { props: { ...props, ...moreProps } };
  };

  return (
    <>
      {selectdConfigData.map((item, i) => (
        <DynamicComponent key={i} {...item} {...handleValid(item)} />
      ))}

      {!hiddenButton && (
        <ButtomLoader
          variant="contained"
          sx={{ marginTop: "20px", mb: "20px" }}
          onClick={() => navigate(MY_PENDING_AGREEMENTS)}
        >
          VER NEGOCIACIONES
        </ButtomLoader>
      )}
    </>
  );
};

export default FormConfirmationDetails;
