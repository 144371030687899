import LayoutFlow from "@/components/LayoutFlow/LayoutFlow";
import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import CustomRadio from "@/components/form/customRadio/CustomRadio";
import Input from "@/components/inputCustom/Input";
import SearchModal from "@/components/modals/searchModal/SearchModal";
import LabelRequired from "@/components/utils/LabelRequired";
import { BASE_URL_IMAGE } from "@/config/config";
import { AUTH_USER_TYPE } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { singUpStartDataLayer } from "@/services/dataLayer/auth";
import { getDistrictByTermThunk } from "@/store/locationRedux/districtsThunks";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { saveFormDataClientThunk } from "@/store/userRedux/userThunks";
import { cleanEmptySpaceInObject, getNextPage } from "@/utils/helpers";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from "react";
import { To, useLocation, useNavigate } from "react-router-dom";
import HeaderFlow from "../../components/HeaderFlow";
import {
  configByTYpeDocumentProfile,
  configByTypeDocument,
  modalProps,
  objectNextPage,
} from "../../consts/configDataClients";
import { RegisterClientFormData } from "../../interfaces/interfaces";
import { titleByPath } from "../ClientProfile/configDataClientProfile";
import { typesDocuments } from "@/consts/defaultConsts";
import { resetReturnPathThunk } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";

const ContainerRow = styled(Stack)(
  () => `
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    margin-top: 16px;
  `
);

const StyleLink = styled("a")(
  ({ theme }) => `
    color: ${theme.palette.info.main};
    text-decoration: none;
    font-family: ${theme.typography.label2.fontFamily};
    font-size: ${theme.typography.label2.fontSize};
    font-weight: ${theme.typography.label2.fontWeight};
    line-height: ${theme.typography.label2.lineHeight};
  `
);

const Form = styled("form")(
  () => `
    display: flex;
    flex-direction: column;
  `
);

const initialsValues: Omit<RegisterClientFormData, "tipousuario"> = {
  name: "",
  fullname: "",
  district: {
    id: 0,
    nombre: "",
  },
  numberDocument: "",
};

const pathClient = "/registro/clientes";

const Clients = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(initialsValues);
  const [termsAndconditions, setTermsAndconditions] = useState(false);
  const [showError, setShowError] = useState(false);
  const [flagCompany, setFlagCompany] = useState(1);
  const [errormsg, setErrormsg] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const {
    user: { user, error, isLoading },
    districts: { districts },
    routeFlowNotice,
  } = useAppSelector(({ user, districts, routeFlowNotice }) => ({
    user,
    districts,
    routeFlowNotice,
  }));
  const dispatch = useAppDispatch();
  const currentUser = user as IUser;
  const isRegisterClient = pathname.startsWith(pathClient);
  const CONFIG_DATA = isRegisterClient ? configByTYpeDocumentProfile[flagCompany] : configByTypeDocument[flagCompany];

  useEffect(() => {
    if (currentUser?.id) {
      singUpStartDataLayer(currentUser?.tipousuario, currentUser.id);
    }

    if (!currentUser.tipousuario) {
      navigate(AUTH_USER_TYPE, { replace: true });
    }

    return () => {
      dispatch(resetReturnPathThunk());
    };
  }, []);

  const handleActiveModalCity = () => {
    setShowModal(true);
  };

  const handleOnchange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = currentTarget;

    const { validates } = CONFIG_DATA[name];
    if (validates?.maxLength && value.length > validates.maxLength) {
      return;
    }

    setErrormsg("");
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const hadleValidErrorDocument = () => {
    let hasError = "";
    const regxDigits = /^\d+$/;
    const regxCharacters = /^[a-zA-Z0-9]+$/;
    const { numberDocument } = formData;
    const { validates } = configByTypeDocument[4].numberDocument;

    const isOnlyNumbersValid =
      flagCompany === typesDocuments.RUC.id && validates?.onliNumbers === true;
    const isOnlyCharactersValid = validates?.onliNumbers === false;
    const isNumberDocumentLengthValid =
      numberDocument.length <= validates.maxLength;
    const isNumberDocumentLengthInvalid =
      numberDocument.length < validates.minLength;

    if (isOnlyNumbersValid && !regxDigits.test(numberDocument)) {
      hasError = "Sólo puede incluir números.";
    } else if (isOnlyCharactersValid && !regxCharacters.test(numberDocument)) {
      hasError = "Sólo puede incluir números y letras.";
    } else if (!isNumberDocumentLengthValid) {
      hasError = `El número de documento debe tener ${validates.maxLength} caracteres`;
    } else if (isNumberDocumentLengthInvalid) {
      hasError = `El número de documento debe tener al menos ${validates.minLength} caracteres`;
    }

    return hasError;
  };

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    const { id, tipousuario } = currentUser;
    const body = { ...formData, id, tipousuario };
    event.preventDefault();

    if (isLoading) return;

    setShowError(false);
    const nextPage = getNextPage(location.pathname, objectNextPage) as To;

    if (flagCompany === typesDocuments.RUC.id) {
      const hasMessageErro = hadleValidErrorDocument();
      if (hasMessageErro) {
        setErrormsg(hasMessageErro);
        return;
      }
    }

    const cleanBody = cleanEmptySpaceInObject<RegisterClientFormData>(body);

    dispatch(saveFormDataClientThunk({ ...cleanBody, flagCompany }))
      .then(() => handleValidNextPage(nextPage))
      .catch(() => setShowError(true));
  };

  const handleValidNextPage = (nextPage: To) => {
    const path = nextPage;
    const { returnPath } = routeFlowNotice;
    if (!!returnPath.path) {
      navigate(returnPath.path, { replace: true, state: returnPath.state });
    } else {
      navigate(path, { replace: true });
    }
  };

  const handleOnChangeModal = (district: string) => {
    if (!!district.trim().length && showModal && district.length >= 3) {
      dispatch(getDistrictByTermThunk(district));
    }
  };

  const handleSelectedDistrict = (district: any) => {
    setFormData((prev) => ({ ...prev, district }));
    setShowModal(false);
  };

  const handleDisabled = () => {
    const { district, numberDocument, ...rest } = formData;
    const rules =
      flagCompany === typesDocuments.RUC.id
        ? { numberDocument, ...rest }
        : rest;
    return (
      isLoading ||
      district.id <= 0 ||
      !termsAndconditions ||
      Object.values(rules).some((value) => !value.length)
    );
  };

  const handleGetSearchModalProps = () => {
    if (flagCompany === typesDocuments.RUC.id) {
      return modalProps.company;
    }

    return modalProps.default;
  };

  const handleGetBack = () => {
    navigate(AUTH_USER_TYPE, { replace: true });
  };

  const handleChangeRadio = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    if (!!value.trim()) {
      setFlagCompany(Number(value));
      setFormData(initialsValues);
    }
  };

  const searchModalProps = useMemo(
    () => handleGetSearchModalProps(),
    [flagCompany]
  );

  return (
    <>
      <LayoutFlow>
        <>
          <HeaderFlow
            title={titleByPath[location.pathname]}
            customGoBack={handleGetBack}
          />

          {showError && (
            <StandardAlert
              sx={{ marginBottom: "16px" }}
              severity="error"
              color="error"
            >
              {error}
            </StandardAlert>
          )}
        </>
        <SearchModal
          open={showModal}
          onClose={(isOpen: boolean) => setShowModal(isOpen)}
          onSubmit={handleSelectedDistrict}
          onChange={handleOnChangeModal}
          list={districts}
          title={searchModalProps.title}
          inputProps={searchModalProps.inputProps}
        />

        <Form onSubmit={handleOnSubmit}>
          <ContainerRow order={CONFIG_DATA["numberDocument"].order}>
            <RadioGroup
              aria-labelledby="radio-buttons-group"
              name="Tipo de usuario"
              value={flagCompany}
              onChange={handleChangeRadio}
              sx={{ flexDirection: "row", gap: 2, mb: "16px" }}
            >
              <FormControlLabel
                value={1}
                control={<CustomRadio />}
                label="Persona"
              />
              <FormControlLabel
                value={4}
                control={<CustomRadio />}
                label="Empresa"
              />
            </RadioGroup>
          </ContainerRow>

          <ContainerRow
            order={CONFIG_DATA["numberDocument"].order}
            sx={{
              display: flagCompany === 4 ? "flex" : "none",
            }}
          >
            <FormControl variant="standard" sx={{ flex: 3 }}>
              <Input
                name="numberDocument"
                type={
                  CONFIG_DATA["numberDocument"].validates.onliNumbers
                    ? "number"
                    : "text"
                }
                fullWidth
                value={formData.numberDocument}
                onChange={handleOnchange}
                variant="standard"
                label={
                  <LabelRequired str={CONFIG_DATA["numberDocument"]?.label} />
                }
                placeholder={CONFIG_DATA["numberDocument"]?.placeholder}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errormsg}
                helperText={errormsg}
              />
            </FormControl>
          </ContainerRow>

          <ContainerRow order={CONFIG_DATA["name"].order}>
            <FormControl variant="standard" sx={{ flex: 1 }}>
              <Input
                name="name"
                type="text"
                fullWidth
                value={formData.name}
                onChange={handleOnchange}
                variant="standard"
                label={<LabelRequired str={CONFIG_DATA["name"]?.label} />}
                placeholder={CONFIG_DATA["name"]?.placeholder}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </ContainerRow>

          <ContainerRow order={CONFIG_DATA["fullname"].order}>
            <FormControl variant="standard" sx={{ flex: 1 }}>
              <Input
                name="fullname"
                type="text"
                fullWidth
                value={formData.fullname}
                onChange={handleOnchange}
                variant="standard"
                label={<LabelRequired str={CONFIG_DATA["fullname"]?.label} />}
                placeholder={CONFIG_DATA["fullname"]?.placeholder}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </ContainerRow>

          <ContainerRow order={CONFIG_DATA["district"].order}>
            <FormControl variant="standard" sx={{ flex: 1 }}>
              <Input
                name="district"
                type="text"
                fullWidth
                value={formData.district.nombre}
                onChange={handleActiveModalCity}
                onClick={handleActiveModalCity}
                variant="standard"
                label={<LabelRequired str={CONFIG_DATA["district"]?.label} />}
                placeholder={CONFIG_DATA["district"]?.placeholder}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </ContainerRow>

          <ContainerRow order={6}>
            <Checkbox
              checked={termsAndconditions}
              color="org"
              onChange={() => setTermsAndconditions(!termsAndconditions)}
            />
            <Typography variant="body2" marginLeft="7px">
              Acepto los{" "}
              <StyleLink
                href={`${BASE_URL_IMAGE}/Terminos-y-condiciones.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                términos y condiciones{" "}
              </StyleLink>
              y reconozco que leí la{" "}
              <StyleLink
                href={`${BASE_URL_IMAGE}/Politica-privacidad.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                política de privacidad.
              </StyleLink>
            </Typography>
          </ContainerRow>

          <ContainerRow order={7}>
            <FormControl variant="standard" sx={{ flex: 1 }}>
              <ButtomLoader
                type="submit"
                disabled={handleDisabled()}
                isLoading={isLoading}
              >
                {
                  isRegisterClient ?
                    "FINALIZAR"
                    : "SIGUIENTE"
                }
              </ButtomLoader>
            </FormControl>
          </ContainerRow>
        </Form>
      </LayoutFlow>
    </>
  );
};

export default Clients;
