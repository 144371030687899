import {
  MyNegotiationes,
  MyNegotiationesList,
} from '@/models/userAuth/agrementToAccept';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface NegotiationsList extends MyNegotiationesList {
  isLoading: boolean;
  error: any;
}

type InitialState = {
  actives: NegotiationsList;
  history: NegotiationsList;
  expired: NegotiationsList;
};

const initNegotationState = {
  currentPage: -1,
  totalItems: -1,
  totalPages: -1,
  negociaciones: [],
  error: null,
  isLoading: false,
};

const initialState: InitialState = {
  actives: initNegotationState,
  history: initNegotationState,
  expired: initNegotationState,
};

export const myListNegotiationsSlice = createSlice({
  name: 'myListNegotiationsSlice',
  initialState,
  reducers: {
    // actives
    setIsLoadingActive: (state, { payload }: PayloadAction<boolean>) => {
      state.actives.isLoading = payload;
    },
    setErrorActive: (state, { payload }: PayloadAction<any>) => {
      state.actives.error = payload;
    },
    setMyNegotationsActives: (
      state,
      { payload }: PayloadAction<MyNegotiationesList>
    ) => {
      state.actives = {
        ...payload,
        isLoading: false,
        error: null,
      };
    },
    updateListActives: (
      state,
      { payload }: PayloadAction<MyNegotiationesList>
    ) => {
      const { negociaciones = [], ...paginate } = payload;
      const newList = updateList(
        [...state.actives.negociaciones],
        negociaciones
      );
      state.actives = {
        ...state.actives,
        ...paginate,
        negociaciones: newList,
        isLoading: false,
        error: null,
      };

      return state;
    },

    // history
    setIsLoadingHistory: (state, { payload }: PayloadAction<boolean>) => {
      state.history.isLoading = payload;
    },
    setErrorHistory: (state, { payload }: PayloadAction<any>) => {
      state.history.error = payload;
    },
    setMyNegotationsHistory: (
      state,
      { payload }: PayloadAction<MyNegotiationesList>
    ) => {
      state.history = {
        ...payload,
        isLoading: false,
        error: null,
      };
    },
    updateListHistory: (
      state,
      { payload }: PayloadAction<MyNegotiationesList>
    ) => {
      const { negociaciones = [], ...paginate } = payload;
      const newList = updateList(
        [...state.history.negociaciones],
        negociaciones
      );
      state.history = {
        ...state.history,
        ...paginate,
        negociaciones: newList,
        isLoading: false,
        error: null,
      };

      return state;
    },

    // expired
    setIsLoadingExpired: (state, { payload }: PayloadAction<boolean>) => {
      state.expired.isLoading = payload;
    },
    setErrorExpired: (state, { payload }: PayloadAction<any>) => {
      state.expired.error = payload;
    },
    setMyNegotationsExpired: (
      state,
      { payload }: PayloadAction<MyNegotiationesList>
    ) => {
      state.expired = {
        ...payload,
        isLoading: false,
        error: null,
      };
    },
    updateListExpired: (
      state,
      { payload }: PayloadAction<MyNegotiationesList>
    ) => {
      const { negociaciones = [], ...paginate } = payload;
      const newList = updateList(
        [...state.expired.negociaciones],
        negociaciones
      );
      state.expired = {
        ...state.expired,
        ...paginate,
        negociaciones: newList,
        isLoading: false,
        error: null,
      };

      return state;
    },

    cleanMyListNegotiationsState: () => initialState,
  },
});

const updateList = (
  currentData: MyNegotiationes[],
  newData: MyNegotiationes[]
) => {
  const newList = [...currentData, ...newData];

  return newList;
};

// Action creators are generated for each case reducer function
export const {
  setIsLoadingActive,
  setErrorActive,
  setMyNegotationsActives,
  updateListActives,
  setIsLoadingHistory,
  setErrorHistory,
  setMyNegotationsHistory,
  setIsLoadingExpired,
  setErrorExpired,
  updateListExpired,
  setMyNegotationsExpired,
  updateListHistory,
  cleanMyListNegotiationsState,
} = myListNegotiationsSlice.actions;
