export const SUCCESS_TEXT_EDIT_VEHICLE =
  'Cambios del vehículo guardados con éxito.';

export const SUCCESS_TEXT_EDIT_PRICE = 'Precio modificado con éxito.';

export const SUCCESS_TEXT_EDIT_DATE = 'Fecha modificada con éxito.';

export const SUCCESS_TEXT_EDIT_CONDITIONS =
  'Condiciones del servicio  modificadas con éxito.';

export const SUCCESS_CHANGE_PASSWORD = 'Tu contraseña fue actualizada.';
