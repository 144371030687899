import { Radio, RadioProps } from "@mui/material";

const CustomRadio = (props: RadioProps) => {
  return (
    <Radio
      sx={({ palette }) => ({
        "&.Mui-checked": { color: palette.org.main },
      })}
      {...props}
    />
  );
};

export default CustomRadio;
