import { Box, IconButton, Stack, styled, Typography } from "@mui/material";
import { FC } from "react";

const Header = styled(Box)(
  () => `
    background-color: #382E2B;
    box-shadow: 0px -1px 0px #f6f6f6;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 14px 20px;
    justify-content: space-between;
  `
);

const Title = styled(Typography)(
  ({ theme }) => `
    color: white;
    font-size: ${theme.typography.h6.fontSize};
    line-height: ${theme.typography.h6.lineHeight};
    font-family: ${theme.typography.h6.fontFamily};
  `
);

const Subtitle = styled(Typography)(
  ({ theme }) => `
    color: white;
    font-size: ${theme.typography.body2.fontSize};
    line-height: ${theme.typography.body2.lineHeight};
    font-family: ${theme.typography.body2.fontFamily};
  `
);
interface Props {
  title: string;
  subTitle?: string;
  onClick?: () => void;
  selected?: boolean;
  onBack?: () => void;
}

const HeaderModal: FC<Props> = ({
  title,
  subTitle,
  onClick,
  selected,
  onBack,
}) => {
  return (
    <Header>
      <Stack>
        <Title color="white">
          {
            onBack && selected ? (
              <IconButton aria-label="back" onClick={onBack}>
                <i className="icon-arrow-left--primary" />
              </IconButton>
            )
              : null
          }
          {title}
        </Title>
        {subTitle && (
          <Subtitle>{subTitle}</Subtitle>
        )}
      </Stack>
      <IconButton onClick={onClick}>
        <i className="icon-close"></i>
      </IconButton>
    </Header>
  );
};

export default HeaderModal;
