import HeaderModal from "@/components/modals/headerModal/HeaderModal";
import { CustomDrawer } from "@/components/modals/stylesContainerFlow";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { NoticeProfile } from "@/models/notice/noticeListOffersModels";
import { IRatingUserHistory } from "@/models/ratingAgreement/UserHistoryRatingModel";
import { resetRatingAgreement } from "@/store/ratingAgreementRedux/ratingAgreementSlice";
import { getUserHistoryRatingDataThunk } from "@/store/ratingAgreementRedux/ratingAgreementThunk";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import TransporterRating from "../TransporterRating";
import ListRatings from "./ListRatings";
interface IHistoryRating {
  open: boolean;
  onClose: () => void;
  userData: NoticeProfile;
}
const HistoryRating = ({ open, onClose, userData }: IHistoryRating) => {
  const dispatch = useAppDispatch();
  const [adRatingData, setAdRatingData] = useState<IRatingUserHistory | null>(null);

  const { ratingHistoryUserData } = useAppSelector((state) => state.ratingAgreement);
  const [viewRating, setViewRating] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      getInitialData();
    }

    if (!open) {
      setViewRating(false);
    }

    return () => {
      dispatch(resetRatingAgreement());
      setAdRatingData(null);
      setViewRating(false);
    };
  }, [open]);

  useEffect(() => {
    if (!!ratingHistoryUserData) {
      setAdRatingData(ratingHistoryUserData);
    }
  }, [ratingHistoryUserData]);

  const getInitialData = () => {
    if (userData?.idUser) {
      dispatch(
        getUserHistoryRatingDataThunk(userData?.idUser)
      ).then(() => {
        setViewRating(true);
      }).catch(() => {
        onClose();
      });
    }
  };
  if (!adRatingData) {
    return null;
  }

  const {
    title,
    descripcion,
    calificaciontexto,
    nombreyapellidos,
    perfil1,
    puntaje,
    ratings
  } = adRatingData;

  return (
    <CustomDrawer open={viewRating} onClose={onClose} anchor="right">
      <HeaderModal title={title || ""} onClick={onClose} />
      <Box
        sx={{
          height: "100%",
          padding: "31px 16px",
          overflow: "auto",
        }}
      >
        <TransporterRating
          data={{
            idUser: userData.idUser || 0,
            urlProfile: perfil1 || "",
            userName: nombreyapellidos || "",
            showPrice: false,
            showTwoAvatars: false,
            rating: puntaje || 0,
            ratingText: calificaciontexto || "",
          }}
          colorName=""
          sm={2}
          viewHistory={false}
        />
        <Typography variant="body2" sx={{
          mt: 2,
          display: "-webkit-box",
          WebkitLineClamp: 5,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "normal",
          wordWrap: "break-word",
          lineBreak: "anywhere"
        }}>
          {descripcion || ""}
        </Typography>
        <ListRatings ratings={ratings} />
      </Box>
    </CustomDrawer>
  );
};

export default HistoryRating;
