import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import { customerRequestObjectTypes } from "@/pages/customerRequest/shared/consts/customerRequestConsts";
import { REQUEST_MAIN_PRECIO_POR_PUNTO_PATH } from "@/pages/customerRequest/shared/consts/routes";
import { saveCustomerMultidestinyRequestThunk } from "@/store/customerRequestRedux/customerRequestThunk";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { Dispatch, SetStateAction, useEffect, useId, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { maxLengthWeight, optionFive, optionOne, optionTwo, textCannotLowerPrice, textHoursError, textPointsError } from "@/consts/noticeConsts";
import {
  ReturnDataToShow,
  getDataToShowZoneAndDistrict,
} from "@/pages/createServices/fee/FeeMultiCity/utils";
import { replaceValue } from "@/utils/helpersActions";
import { handleErrorPrice, handleErrorSubmit } from "../../config";
import { MAX_PRICE_IN, MIN_PRICE_IN } from "@/pages/noticeV2/NoticePlace/placeContainers/hook";

export const useFormPriceMultidestiny = () => {
  const id = useId();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const path = replaceValue(
    REQUEST_MAIN_PRECIO_POR_PUNTO_PATH,
    ":typeRequest",
    customerRequestObjectTypes[2]
  );

  const [secondInputValue, setSecondInputValue] = useState("");
  const [firstInputValue, setFirstInputValue] = useState("");
  const [flaghoraopunto, setflaghoraopunto] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [error, setError] = useState("");
  const [error1, setError1] = useState("");
  const [destinySeleted, setDestinySeleted] = useState<ReturnDataToShow[]>([]);
  const [textDestiny, setTextDestiny] = useState("Ver más");
  const [visibleDestiny, setVisibleDestiny] = useState("none");
  const {
    selectLocation: { multiSelectDestiny },
    zones: { zones },
    customerRequest: { isLoading, selectedTypeRequest },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  useEffect(() => {
    handleSetDestiny();
    let flaghoraopuntoValue = optionTwo;

    setflaghoraopunto(flaghoraopuntoValue);
  }, []);

  useEffect(() => {
    setTotalPrice(Number(firstInputValue) * Number(secondInputValue));
  }, [firstInputValue, secondInputValue]);

  const handleOnchangeFirstInput = ({ currentTarget }: EventType["change"]) => {
    setError("");
    const { value } = currentTarget;
    const prepareValue = value.replace(/^(0+)/g, "").replace(/[^0-9]/g, "");

    setFirstInputValue(prepareValue);
  };

  const handleOnchangeSecondInput = ({
    currentTarget,
  }: EventType["change"]) => {
    setError1("");
    const { value } = currentTarget;
    const prepareValue = value.replace(/^(0+)/g, "").replace(/[^0-9]/g, "");

    if (prepareValue.length < maxLengthWeight) {
      setSecondInputValue(prepareValue);
    }
  };

  const handleSubmit = () => {
    if (isLoading || handleDisabledButton()) return;
    const price = handleErrorPrice(setError1, secondInputValue);
    const minimum = handleErrorSubmit(
      flaghoraopunto,
      setError,
      firstInputValue
    );

    if (price || minimum) return;

    dispatch(
      saveCustomerMultidestinyRequestThunk(
        totalPrice.toString(),
        firstInputValue,
        secondInputValue,
        flaghoraopunto
      )
    ).then(handleNextRute);
  };

  const handleNextRute = () => {
    const valueToReplace = customerRequestObjectTypes[selectedTypeRequest.id];
    dispatch(
      getNextRoute(navigate, { valueToSearch: ":typeRequest", valueToReplace })
    );
  };

  const handleSetDestiny = () => {
    const result = getDataToShowZoneAndDistrict(multiSelectDestiny, zones);
    setDestinySeleted(result);
  };

  const handleDisabledButton = () => {
    const conditions = [secondInputValue, firstInputValue, !isLoading, validateFormCustomHourOrPoint().valid, validateFormCustomHourOrPointPrice().valid];

    return conditions.some((condition) => !condition);
  };

  const changeRadioButtons = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setflaghoraopunto(Number(value));
  };

  const validateFormCustomHourOrPoint = () => {
    const inputValue = Number(firstInputValue);
    
    if (inputValue === 0) {
      return { valid: true, msg: '' };
    }
  
    if (flaghoraopunto === optionOne && (inputValue < optionOne || inputValue > 24)) {
      return { valid: false, msg: textHoursError };
    }
  
    if (flaghoraopunto === optionTwo && (inputValue < optionTwo || inputValue > 99)) {
      return { valid: false, msg: textPointsError };
    }
  
    return { valid: true, msg: '' };
  };

  const validateFormCustomHourOrPointPrice = () => {
    const inputValue = Number(secondInputValue);
  
    if (inputValue === 0) {
      return { valid: true, msg: '' };
    }
  
    if (inputValue < MIN_PRICE_IN || inputValue > MAX_PRICE_IN) {
      return { valid: false, msg: textCannotLowerPrice };
    }
  
    return { valid: true, msg: '' };
  };


  return {
    error,
    error1,
    totalPrice,
    isLoading,
    handleOnchangeFirstInput,
    firstInputValue,
    flaghoraopunto,
    secondInputValue,
    handleOnchangeSecondInput,
    handleSubmit,
    handleDisabledButton,
    changeRadioButtons,
    destinySeleted,
    textDestiny,
    setTextDestiny,
    visibleDestiny,
    setVisibleDestiny,
    validateFormCustomHourOrPoint,
    validateFormCustomHourOrPointPrice,
  };
};
