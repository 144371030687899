import { Stack, styled, Typography } from "@mui/material";

const TextLink = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.accent.main};
    font-weight: bold;
  `
);

type Props = {
  onClick?: () => void;
  description: string;
  textBold: string;
};

const SectionEnterPrice = ({ onClick, description, textBold }: Props) => {
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <Stack
      justifyContent="center"
      display="flex"
      marginTop="20px"
      sx={{ cursor: "pointer" }}
      onClick={handleClick}
    >
      <Typography align="center" variant="body2">
        {description}
      </Typography>
      <TextLink align="center" variant="body2">
        {textBold}
      </TextLink>
    </Stack>
  );
};

export default SectionEnterPrice;
