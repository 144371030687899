import { NoticeDetailAuctionModel } from "@/models/notice/noticeV2NegotationModels";
import { NOTICE_CO_COUNTEROFFER } from "@/pages/noticeV2Negotation/shared/const/routes";
import { CustomCardV2 } from "@/pages/noticeV2Negotation/shared/styles";
import { Box, Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { handleDataAuctionToRender } from "./config";

const AuctionCounterOfferPrice = ({
  data,
}: {
  data: NoticeDetailAuctionModel;
}) => {
  const location = useLocation();
  const { pathname } = location;
  const clienCounterOffer = pathname.includes(NOTICE_CO_COUNTEROFFER)

  const dataToRender = handleDataAuctionToRender(data, clienCounterOffer );

  return (
    <Box>
      {dataToRender.map((item, i) => (
        <CustomCardV2 sx={{ mt: 2 }} key={i}>
          <Typography sx={item.title.sx}>{item.title.text}</Typography>
          <Grid container>
            <Grid xs={6}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={item.sxSubtitleColum}>
                  {item.columnOne.subtitle}
                </Typography>
                <Typography sx={item.sxSubtitleValueColum}>
                  {item.columnOne.value}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={item.sxSubtitleColum}>
                  {item.columnTwo.subtitle}
                </Typography>
                <Typography sx={item.sxSubtitleValueColum}>
                  {item.columnTwo.value}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CustomCardV2>
      ))}
    </Box>
  );
};

export default AuctionCounterOfferPrice;
