import { theme } from '@/styles/theme';
import {
  IconButton,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  styled,
  experimental_sx as sx,
} from '@mui/material';

export const Form = styled('form')(
  () => `
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 16px;
    `
);

export const ContainerHeader = styled(Stack)(
  () => `
  display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      align-items: center;
      padding-bottom: 16px;
      margin-bottom: 16px;
    `
);

export const PeoplesContent = styled('div')(
  () => `
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: space-between;
        height: 64px;
        border-bottom: 1px solid #E2E2E2;
        padding: 14px 14px 14px 0;
    `
);

export const ButtonsCount = styled('div')(
  () => `
        display: flex;
        gap: 14px;
        align-items: center;
    `
);

export const ButtonPeoples = styled(IconButton)(
  () => `
        width: 36px;
        height: 36px;
        border: 1px solid #000000;
        color: #000000;
        font-size: 24px;
        align-items: center;
        justify-content: center;
    `
);

export const Title = styled(Typography)(
  ({ theme }) => `
      font-weight: bold;
      margin-left: 8px;
      
    `
);

export const ConatinerDetails = styled(Stack)(
  () => `
    justify-content: space-between;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
  `
);
