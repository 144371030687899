import { getPreguntasFrecuentesServices } from "@/services/landingServices";
import { AppDispatch } from "../../store";
import { setError, setListPreguntasFrecuentes, setLoading, } from "./landingFAQServiceSlice";

export const getListPreguntasFrecuentesThunk = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading(true));
        try {
            const { preguntasfrecuentesData } = await getPreguntasFrecuentesServices();
            dispatch(setListPreguntasFrecuentes(preguntasfrecuentesData.preguntasfrecuentestipo));
        } catch (error) {
            dispatch(setError(error));
        }
    };
};


