import { BASE_URL_SOCKET } from "@/config/config";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import useSocketIO from "@/hooks/useSokect2";
import {
  SharedRide as SharedRideModel,
  VehicleMovingSharedRideResponse,
} from "@/models/shareTravel/shareTravelModel";
import { setError } from "@/store/sharedRide/sharedRideSlice";
import { getShareTravelThunk } from "@/store/sharedRide/sharedRideThunk";
import { useJsApiLoader } from "@react-google-maps/api";
import React from "react";
import { useParams } from "react-router-dom";

const center = {
  lat: 0,
  lng: 0,
};
export const googlekey = "AIzaSyBRftj47HpHfJjjkNYoJKzX3EMT-xolD98";

type Param = {
  idSharedRide: string;
};

const textError = "Hubo un error al cargar el viaje";

const useHook = () => {
  const [vehicleCoords, setVehicleCoords] = React.useState(center);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googlekey,
  });

  const { sharedRide, error, isLoading } = useAppSelector(
    ({ sharedRide }) => sharedRide
  );
  const dispatch = useAppDispatch();

  const { connectSocket, on, emit } = useSocketIO({
    url: BASE_URL_SOCKET!,
  });

  const param = useParams<Param>();

  React.useEffect(() => {
    if (!param?.idSharedRide) {
      handleSetError();
    } else {
      handleGetShareTravel(param?.idSharedRide);
    }

    return () => {
      dispatch(setError(""));
      emit(
        "event_leave",
        "negociacion_seguimiento_" + sharedRide?.idNegociacion
      );
    };
  }, []);

  React.useEffect(() => {
    if (sharedRide && !isLoading) {
      handleConnectSocket();
    }

    return () => {};
  }, [sharedRide, isLoading]);

  const handleConnectSocket = () => {
    connectSocket();

    emit("event_join", "negociacion_seguimiento_" + sharedRide?.idNegociacion);

    on("new_message", handleSuscribeMoving);
  };

  const handleSuscribeMoving = (data: VehicleMovingSharedRideResponse) => {
    if (!data || data.typeData !== "listen_ubicacion_compartida") return;

    if (data.usuarioAppData.usuarioApp) {
      const { latitud, longitud } = data.usuarioAppData.usuarioApp;
      setVehicleCoords(() => ({
        lat: latitud,
        lng: longitud,
      }));
    }
  };

  const handleGetShareTravel = (idSharedRide: string) => {
    dispatch(getShareTravelThunk(idSharedRide)).then(
      (sharedRide: SharedRideModel) => {
        setVehicleCoords(() => ({
          lat: sharedRide?.coordenadaOrigenLatitud || 0,
          lng: sharedRide?.coordenadaOrigenLongitud || 0,
        }));
      }
    );
  };

  const onLoad = React.useCallback(function callback(map: any) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
  }, []);

  function handleSetError() {
    dispatch(setError(textError));
  }

  return {
    sharedRide,
    error,
    isLoading,
    isLoaded,
    onLoad,
    vehicleCoords,
  };
};

export default useHook;
