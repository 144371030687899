import { useAppSelector } from "@/hooks/useReducer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import { FC, Fragment, MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import HeaderItemLastSection from "./HeaderItemLastSection";
import {
  ButtonMenu,
  MenuHeader,
  MenuHeaderItem,
  Redirect,
  RedirectMenu,
} from "./styleHeader";
import { ListItems } from "./typeHeaderListITem";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { CLIENT_TYPE } from "@/consts/defaultConsts";

type Props = {
  onClose: (value: boolean) => void;
  listItems: ListItems[];
  setIsOpenLanguageModal: (value: boolean) => void;
};

const HeaderListItem: FC<Props> = (props) => {
  const { user } = useAppSelector((state) => state.user);

  const { onClose, listItems } = props;
  const [status, setStatus] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { t } = useTranslation();

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!Array.isArray(listItems) || !listItems.length) return <></>;

  return (
    <Grid container direction="row" justifyContent="space-between" gap={1}>
      <Grid
        item
        display="flex"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          marginTop: {
            lg: "10px",
            xl: "10px",
          },
        }}
      >
        {listItems.map((item, i) => {
          if (item.title === "AYUDA")
            return <Fragment key={`Fragment-${i}`}></Fragment>;
          return item.sub.length ? (
            <Grid item sx={item.sxPropos} key={`${i}-HeaderListItem`}>
              <ButtonMenu
                container
                onClick={handleClick}
                className={open || status ? "active" : ""}
              >
                <>{t(item.title || "")}</> <ExpandMoreIcon />
              </ButtonMenu>
              <MenuHeader
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {item.sub.map((sub, i) => (
                  <MenuHeaderItem
                    onClick={handleClose}
                    key={`subitem-${i}-HeaderListItem`}
                  >
                    <RedirectMenu
                      to={sub.route}
                      onClick={() => setStatus(true)}
                    >
                      {t(sub.title || "")}
                    </RedirectMenu>
                  </MenuHeaderItem>
                ))}
              </MenuHeader>
            </Grid>
          ) : (
            <Fragment key={`Fragment2-${i}`}>
              {item.id === 1 && (user as IUser).tipousuario === CLIENT_TYPE ? (
                <></>
              ) : (
                <Grid item sx={item.sxPropos} key={`header-list-item-${i}`}>
                  <Redirect
                    onClick={() => setStatus(false)}
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to={item.route}
                  >
                    {t(item.title || "")}
                  </Redirect>
                </Grid>
              )}
            </Fragment>
          );
        })}
      </Grid>

      <Grid
        item
        display="flex"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        gap={1}
        sx={{
          marginTop: {
            lg: "10px",
            xl: "10px",
          },
        }}
      >
        <HeaderItemLastSection
          hasUser={!!Object.keys(user).length}
          onClose={onClose}
          onStatus={setStatus}
        />

        {/*  <LanguageButtonWhite show={match} onClick={setIsOpenLanguageModal} /> */}
      </Grid>
    </Grid>
  );
};

export default HeaderListItem;
