import { useAppSelector } from "@/hooks/useReducer";
import {
  Avatar,
  Grid,
  Skeleton,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { useState } from "react";
import HistoryRating from "./historyRating/HistoryRating";
import { RatingStyles } from "./styledUserRating";
import TwoAvatars from "./TwoAvatars";
import { useLocation } from "react-router-dom";
import {
  QUALIFICATION_CLIENT_PATH,
QUALIFICATION_DRIVER_PATH
} from "@/consts/typeServicesPaths";
type Props = {
  idUser: number;
  urlProfile: string;
  urlTruck?: string;
  showTwoAvatars: boolean;
  userName: string;
  containerSx?: SxProps<Theme>;
  viewRating?: boolean;
  sx?: any;
};
const CustomAvatarRating = ({
  idUser,
  urlProfile,
  userName,
  containerSx,
  urlTruck,
  showTwoAvatars = false,
  viewRating,
  sx,
}: Props) => {
  const { avatarStyles, gridContainerStyles } = RatingStyles;
  const { isLoading } = useAppSelector((state) => state.ratingAgreement);
  const [openRating, setOpenRating] = useState<boolean>(false);
  const { pathname } = useLocation();

  const onOpenRating = () => {
    if (!viewRating) return;
    setOpenRating(true);
  };

  const onCloseRating = () => {
    setOpenRating(false);
  };

  const handleIsQualificationPath = () => {
    const isClientPath = pathname.includes(QUALIFICATION_CLIENT_PATH);
    const isDriverPath = pathname.includes(QUALIFICATION_DRIVER_PATH);
    const result = isClientPath || isDriverPath
    return !result
  };



  return (
    <>
      <Grid
        container
        sx={{ ...containerSx, cursor: viewRating ? "pointer" : "default" }}
        mb={2}
        mt={1}
        gap={1.5}
        onClick={onOpenRating}
      >
        <Grid
          item
          xs={showTwoAvatars ? 3 : 2.5}
          sx={sx}
          sm={showTwoAvatars ? 2.5 : 2}
        >
          {isLoading ? (
            <Skeleton variant="circular" width={48} height={48} />
          ) : showTwoAvatars ? (
            <TwoAvatars primaryAvatar={urlProfile} secondAvatar={urlTruck} />
          ) : (
            <Avatar src={urlProfile} sx={avatarStyles} />
          )}
        </Grid>
        <Grid
          item
          xs={showTwoAvatars ? 8 : 9}
          sx={{ ...gridContainerStyles, margin: "auto 0", height: "100%" }}
          sm={9}
        >
          {isLoading ? (
            <Skeleton variant="rectangular" width={220} height={64} />
          ) : (
            <Typography
              variant="h6"
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {userName}
            </Typography>
          )}
        </Grid>
      </Grid>
      {handleIsQualificationPath() && (
        <HistoryRating
          open={openRating}
          onClose={onCloseRating}
          userData={{
            idUser: idUser || 0,
            urlProfile: urlProfile || "",
            userName: userName || "",
            showPrice: false,
            showTwoAvatars: false,
            rating: 0,
            ratingText: "",
          }}
        />
      )}
    </>
  );
};

export default CustomAvatarRating;
