import { TipoVehiculo, VehicleRegister } from "@/models/vehicle/vehicleModel";
import { EditVehicleServicesProps } from "@/services/vehicleServices";
import { ISelectedVehicle } from "@/store/userRedux/userReduxInterface";
import { DataFormVehicle, RegisterVehicleImages } from "../vehicleInterface";

export interface RegisterVehicleProps
  extends DataFormVehicle,
    RegisterVehicleImages {
  selectedVehicle: TipoVehiculo;
  userId: number;
}

export const registerVehicleAdapter = (
  vehicle: RegisterVehicleProps
): VehicleRegister => {
  const refrigeracion = vehicle.isRefrigerate ? 1 : 0;
  return {
    idusuario: vehicle.userId,
    idTipoVehiculo: vehicle.selectedVehicle.id,
    placa: vehicle.vehiclePlate,
    cargaMaxima: Number(vehicle.maximumLoad),
    fotoFrontal: vehicle.imageFront as File,
    fotoLateral: vehicle.imageSide as File,
    fotoPosterior: vehicle.imageBack as File,
    refrigeracion,
  };
};

export const editVehicleAdapter = (
  vehicle: ISelectedVehicle
): EditVehicleServicesProps => {
  const newVehicle = {
    idvehiculo: vehicle.id,
    pesomaximo: vehicle.cargamaxima,
    flgcargarefrigerada: vehicle.flgrefrigerado,
  };

  return newVehicle;
};
