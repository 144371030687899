import { Container, Grid, Stack, styled, Typography } from "@mui/material";
import React, { FC } from "react";
import { LinkButton } from "../../clients/stylePageClients";
import { SxProps, Theme } from "@mui/material/styles";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";

export const ContentSectionNotice = styled(Stack)(
  () => `
    background: #FFF;
    display: flex;
    justify-content: center;
  `
);

export const ButtonRedirect = styled(ButtomLoader)(
  () => `
    line-height: 24px;
    font-size: 14px;
    text-transform: uppercase;
    padding: 1px 12px;
    border-radius: 44px;
    height: 44px;
  `
);

interface IPublicActionLand {
  title: string;
  description: string;
  path: string;
  textButton: string;
  iconCss: string;
  sxSection?: SxProps<Theme>;
  styleContainer?: SxProps<Theme>;
  iconMinHeigth?: string;
  maxWidth?: boolean;
}

const PublicActionLand: FC<IPublicActionLand> = ({
  title,
  description,
  path,
  textButton,
  iconCss,
  sxSection = {},
  styleContainer = {},
  maxWidth = true,
  iconMinHeigth,
}) => {
  return (
    <ContentSectionNotice
      sx={{
        padding: { xs: "36px 16px", sm: "32px 24px", lg: "64px" },
        ...sxSection,
      }}
    >
      <Container
        maxWidth={maxWidth ? "xl" : maxWidth}
        disableGutters
        sx={{ bgcolor: "white", ...styleContainer }}
      >
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={12}
            md={7.5}
            sx={{
              order: { xs: 1, sm: 1, md: 0, lg: 0 },
            }}
          >
            <Typography variant="h5low" mt={0} sx={{ fontWeight: "bold" }}>
              {title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginTop: {
                  xs: "16px",
                  sm: "32px",
                },
              }}
            >
              {description}
            </Typography>
            <LinkButton to={path}>
              <ButtonRedirect
                sx={{
                  marginTop: {
                    xs: "16px",
                    sm: "32px",
                  },
                  padding: "5px 32px",
                  width: { xs: "100%", sm: "100%", md: "auto" },
                }}
              >
                {textButton}
              </ButtonRedirect>
            </LinkButton>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4.5}
            sx={{
              backgroundPosition: "center",
              alignItems: {
                xs: "flex-start",
                md: "center",
              },
              display: "flex",
              justifyContent: "center",
              order: { xs: 0, sm: 0, md: 1, lg: 1 },
            }}
          >
            <i
              style={{ minHeight: iconMinHeigth ? iconMinHeigth : "0px" }}
              className={iconCss}
            />
          </Grid>
        </Grid>
      </Container>
    </ContentSectionNotice>
  );
};

export default PublicActionLand;
