import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import DatePicker from "@/components/form/datePicker.tsx/DatePicker";
import Input from "@/components/inputCustom/Input";
import SearchModal from "@/components/modals/searchModal/SearchModal";
import { MODAL_TYPE } from "@/components/modals/searchModalLocationOffert/configDataModal";
import { FORMAT_DATE_V2 } from "@/consts/formatsDate";
import { textAceptarContactar, textContraofertar } from "@/consts/noticeConsts";
import { COUNTER_OFFER_RUTE } from "@/consts/offertPath";
import { useAppSelector } from "@/hooks/useReducer";
import {
  DestinisAPI,
  NoticeDetailBetweenCitiesModel,
} from "@/models/notice/noticeModels";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { FormControl, InputAdornment, Typography } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import FormOffert from "../../../../../components/FormOffer";
import FormRuteInputs from "../../../../../components/FormRuteInputs";
import { configFormOffert } from "../configData";
import { useRuteBtnCities } from "./useBtnCities";
import dayjs from "dayjs";

const minDate = dayjs().add(1, "day");
const maxDate = dayjs().add(1, "month");

const RuteBetweenCities = () => {
  const { pathname } = useLocation();
  const isCounterOffer = pathname.endsWith(COUNTER_OFFER_RUTE);
  const {
    noticeV2ClientAorCO: { isLoading, selectedUbications, selectTypeNotice },
  } = useAppSelector((state) => state);

  const { origin, destiny, price } = configFormOffert;
  const notice = selectTypeNotice as NoticeDetailBetweenCitiesModel;
  const [errorDate, setErrorDate] = useState("");

  const {
    modalType,
    openModal,
    setOpenModal,
    errorPrice,
    modalTitle,
    searchList,
    form,
    handleInputClick,
    handleTitleSelected,
    handleSubmit,
    handleOnChange,
    handleSetValue,
    handleChangeModal,
    handleSubmitModal,
    handleChangeDate,
    handleDisabled,
  } = useRuteBtnCities();

  return (
    <div>
      <SearchModal
        open={openModal}
        onClose={setOpenModal}
        onSubmit={handleSubmitModal}
        onChange={handleChangeModal}
        list={searchList}
        title={modalTitle}
        inputProps={
          modalType === MODAL_TYPE.origin ? origin.modal : destiny.modal
        }
      />

      <DatePicker
        fullWidth
        variant="outlined"
        minDate={minDate}
        maxDate={maxDate}
        initValue={form.date}
        inputFormat={FORMAT_DATE_V2}
        label={<LabelInputRequired str="Fecha de salida" />}
        showStylesLabel
        onChange={handleChangeDate}
        onError={setErrorDate}
        renderInput={Input}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CalendarMonthOutlinedIcon />
            </InputAdornment>
          ),
          endAdornment: null,
        }}
      />
      {!!errorDate && (
        <Typography variant="caption" color="#d32f2f">
          {errorDate}
        </Typography>
      )}

      <FormOffert
        title={handleTitleSelected("Origen", notice?.origentexto || "")}
        input1={{
          ...origin.input1,
          value: handleSetValue(selectedUbications?.origin as DestinisAPI),
          show: true,
        }}
        input2={{
          ...origin.input2,
          value: form.originReference,
          show: true,
        }}
        onClick={handleInputClick}
        onChange={handleOnChange}
      />

      <FormOffert
        title={handleTitleSelected("Destino", notice?.destinotexto || "")}
        input1={{
          ...destiny.input1,
          label: destiny.input1.label,
          placeholder: destiny.input1.placeholder,
          value: handleSetValue(selectedUbications?.destiny as DestinisAPI),
          show: true,
        }}
        input2={{
          ...destiny.input2,
          value: form.destinyReference,
          show: true,
        }}
        onClick={handleInputClick}
        onChange={handleOnChange}
      />

      {isCounterOffer && (
        <FormRuteInputs
          title="Precio"
          titleValue=""
          subtitle="Del aviso"
          subtitleValue={notice.precio.toString()}
          input={{
            ...price.input,
            value: `${form?.price}` || "",
            show: true,
          }}
          onClick={() => {}}
          onChange={handleOnChange}
          multiline={false}
          errorPrice={errorPrice}
        />
      )}

      <FormControl fullWidth>
        <ButtomLoader
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={handleDisabled() || isLoading}
        >
          {isCounterOffer ? textContraofertar : textAceptarContactar}
        </ButtomLoader>
      </FormControl>
    </div>
  );
};

export default RuteBetweenCities;
