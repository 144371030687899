import { actionsToNegotation } from '@/consts/actions';
import { NoticeDetailAuctionModel } from '@/models/notice/noticeV2NegotationModels';
import { generalConfigData } from '@/pages/noticeV2Negotation/shared/config/generalConfig';
import { ConfigDataNegotationV2 } from '@/pages/noticeV2Negotation/shared/hooks/useNoticeConfigDataV2';
import { currencyFormat } from '@/utils/helpers';
import AuctionCounterOfferPrice from './AuctionCounterOfferPrice';
import AuctionOfferPrice from './AuctionOfferPrice';

export const priceAuctionComponentToRender: Record<
  number,
  ({ data }: { data: NoticeDetailAuctionModel }) => JSX.Element
> = {
  [actionsToNegotation[1].id]: AuctionOfferPrice,
  [actionsToNegotation[2].id]: AuctionCounterOfferPrice,
};

export const detailsConfigAccept: ConfigDataNegotationV2[] = [
  ...generalConfigData,
];

export const detailsConfigCounterOffer: ConfigDataNegotationV2[] = [
  ...generalConfigData,
];

export const handleDataAuctionToRender = (
  data: NoticeDetailAuctionModel,
  changeColor?: boolean
) => {
  return [
    {
      sxSubtitleColum: { fontSize: '12px', color: '#757575' },
      sxSubtitleValueColum: { fontSize: '16px', color: '#757575' },
      title: {
        text: !!data.flgIsOwner ? 'En tu aviso' : 'En el aviso',
        sx: { color: '#757575', fontSize: '16px' },
      },
      columnOne: {
        subtitle: 'Peso del remate',
        value: `${new Intl.NumberFormat().format(data.aviso.pesomaximo)} Kilos`,
      },
      columnTwo: {
        subtitle: 'Precio del remate',
        value: `${currencyFormat(data.aviso.precio)}`,
      },
    },
    {
      sxSubtitleColum: {
        fontSize: '12px',
        fontWeight: !!changeColor ? '' : 600,
        color: !!changeColor ? '#757575' : '',
      },
      sxSubtitleValueColum: {
        fontWeight: !!changeColor ? '' : 700,
        fontSize: '16px',
        color: !!changeColor ? '#757575' : '',
      },
      title: {
        text: !!data.flgIsOwner ? 'Cliente solicita' : 'Tu oferta',
        sx: {
          fontWeight: !!changeColor ? 'none' : 700,
          fontSize: '16px',
          color: !!changeColor ? '#757575' : '',
        },
      },
      columnOne: {
        subtitle: 'Peso del remate',
        value: `${new Intl.NumberFormat().format(data.pesoEstimado)} Kilos`,
      },
      columnTwo: {
        subtitle: 'Precio del remate',
        value: `${currencyFormat(data.precio)}`,
      },
    },
  ];
};

export const handleDataAuctionCoCounterOfferToRender = (
  data: NoticeDetailAuctionModel
) => {
  return [
    {
      sxSubtitleColum: { fontSize: '12px', color: '#757575' },
      sxSubtitleValueColum: { fontSize: '14px', color: '#757575' },
      title: {
        text: 'En el aviso',
        sx: { color: '#757575', fontSize: '14px' },
      },
      columnOne: {
        subtitle: 'Peso del remate',
        value: `${new Intl.NumberFormat().format(data.aviso.pesomaximo)} Kilos`,
      },
      columnTwo: {
        subtitle: 'Precio del remate',
        value: `${currencyFormat(data.aviso.precio)}`,
      },
    },
    {
      sxSubtitleColum: { fontSize: '12px', color: '#757575' },
      sxSubtitleValueColum: { fontSize: '14px', color: '#757575' },
      title: {
        text: 'Tu oferta',
        sx: { color: '#757575', fontSize: '14px' },
      },
      columnOne: {
        subtitle: 'Peso solicitado',
        value: `${new Intl.NumberFormat().format(data.pesoEstimado)} Kilos`,
      },
      columnTwo: {
        subtitle: 'Precio solicitado',
        value: `${currencyFormat(data.precio)}`,
      },
    },
    {
      sxSubtitleColum: { fontSize: '14px', fontWeight: 600 },
      sxSubtitleValueColum: { fontWeight: 700, fontSize: '16px' },
      title: {
        text: 'Contraoferta',
        sx: { fontWeight: 700, fontSize: '16px' },
      },
      columnOne: {
        subtitle: 'Peso solicitado',
        value: `${new Intl.NumberFormat().format(data.pesoEstimado)} Kilos`,
      },
      columnTwo: {
        subtitle: 'Precio del remate',
        value: `${currencyFormat(data.preciocontraoferta)}`,
      },
    },
  ];
};
