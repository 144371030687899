import {
  EDIT_OFFER_NEGOTATION_NOTICE_REQUEST,
  GET_V2_OFFERTS_AVISO,
  NEGOTIATION_GET_CONDITIONS_BY_ID,
  NEGOTIATION_GET_CONDITIONS_BY_IDS,
  NEGOTIATION_GET_DATA,
  NEGOTIATION_GET_TYPE_INMUEBLE,
  NEGOTIATION_SAVE_DATA,
  NEGOTIATION_SAVE_DATA_ACCEPT,
  NEGOTIATION_SAVE_PHOTO,
  NOTICE_LIST_OFFERT,
} from '@/consts/pathServices';
import { NoticeBussinesV2API } from '@/models/notice/noticeListOffersModels';
import { INoticeOffertPaginateAPI } from '@/models/notice/noticeOffertPaginateResponse';
import { OffertConditionsAPI } from '@/models/offert/offertConditionsModels';
import {
  NegotiationResponseAPI,
  TypePropertyAPI,
} from '@/models/offert/offertModels';
import { connection } from './connection';
import { NegotiationPhotosProps } from './interfaces/negotiationInterface';
import { HEADERS_MULTIPART } from './utils/consts';
import { setFormDataByObject } from './utils/utils';

const API = connection();

export const saveNegotiationPhotosServices = async (
  idNegotiation: string,
  body: NegotiationPhotosProps
): Promise<NegotiationResponseAPI> => {
  const path = NEGOTIATION_SAVE_PHOTO.replace(':idNegotiation', idNegotiation);
  const headers = HEADERS_MULTIPART;
  const formData = setFormDataByObject(body);

  const { data } = await API.post(path, formData, { headers });

  return data;
};

export const saveNegotiationServices = async <T>(
  idNotice: string,
  body: T
): Promise<NegotiationResponseAPI> => {
  const path = NEGOTIATION_SAVE_DATA.replace(':idNotice', idNotice);

  const { data } = await API.post(path, body);

  return data;
};

export const getOfferCondicionsByIdNotice = async (
  idFlow: string,
  idNotice?: string
): Promise<OffertConditionsAPI> => {
  let path = NEGOTIATION_GET_CONDITIONS_BY_ID.replace(':idNotice', idFlow);

  if (idNotice) {
    path = NEGOTIATION_GET_CONDITIONS_BY_IDS.replace(
      ':idNotice',
      idFlow
    ).replace(':idNegotation', idNotice);
  }

  const { data } = await API.get(path);

  return data;
};

export const getTypeInmuebleService = async (): Promise<TypePropertyAPI> => {
  const path = NEGOTIATION_GET_TYPE_INMUEBLE;
  const { data } = await API.get(path);
  return data;
};

export const getListOfferServices = async (
  idOffer: string
): Promise<NoticeBussinesV2API> => {
  const path = NOTICE_LIST_OFFERT.replace(':idOffer', idOffer);
  const { data } = await API.get(path);
  return data;
};

export const getOffertByIdServices = async (
  idOffert: string
): Promise<NegotiationResponseAPI> => {
  const path = NEGOTIATION_GET_DATA.replace(':idOffert', idOffert);
  const { data } = await API.get(path);

  return data;
};

export const saveOffertByIdServices = async (
  idOffert: string,
  body: any
): Promise<NegotiationResponseAPI> => {
  const path = NEGOTIATION_SAVE_DATA_ACCEPT.replace(':idOffert', idOffert);
  const { data } = await API.post(path, body);

  return data;
};
// GET_V2_OFFERTS_AVISO
export const getV2OffertsAvisoServices = async (
  body: any
): Promise<INoticeOffertPaginateAPI> => {
  const path = GET_V2_OFFERTS_AVISO;
  const { data } = await API.post(path, body);

  return data;
};

export interface BodyUploadPriceNegotationPrice {
  precio: number;
}

export const uploadPriceNoticeOfferNegotation = async (
  idNotice: string,
  body: BodyUploadPriceNegotationPrice
): Promise<any> => {
  const path = EDIT_OFFER_NEGOTATION_NOTICE_REQUEST.replace(
    ':idNotice',
    idNotice
  );
  const { data } = await API.put(path, body);

  return data;
};
