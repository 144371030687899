import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import React from "react";
import { ConfigDataNegotationV2 } from "./useNoticeConfigDataV2";
import { optionOne } from "@/consts/noticeConsts";

export type ConfigToRenderType = Record<number, ConfigDataNegotationV2[]>;

interface Props {
  notice: NoticeV2NegType;
  configToRender?: ConfigToRenderType ;
  detailsConfigToRender?: ConfigDataNegotationV2[]
}

export const useGetComponentNoticeNeg = (props: Props) => {
  const { notice, configToRender, detailsConfigToRender } = props;
  const typeNoticeNegotationId = notice?.idTipoAvisoNegociacion as number;

  const handleGetConfig = () => {
    if (!notice) return [];

    let result = configToRender && configToRender[typeNoticeNegotationId || optionOne];

    if(detailsConfigToRender) result = detailsConfigToRender

    if (!result) return [];

    return result;
  };

  const selectdConfigDataV2 = React.useMemo(() => handleGetConfig(), []);

  return { selectdConfigDataV2 };
};
