import { Card, CardActionArea, Link, Typography, styled } from '@mui/material';


export const CardMyRequestContainer = styled(Card)(({ theme }) => ({
  height: '334px',
  borderRadius: '8px',
  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.15)',
}));

export const CardTouch = styled(CardActionArea)(() => ({
  width: '100%',
  height: '100%',
}));

export const CardTitleType = styled(Typography)(({ theme }) => ({
  color: "#757575",
  lineHeight: '20px',
  fontSize: '12px',
  fontWeight: '400',
}));

export const CardOriginText = styled(Typography)(({ theme }) => ({
  // limite de 1 linea
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  lineBreak: 'anywhere',
}));

export const ButtonLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  fontFamily: theme.typography.h1.fontFamily,
  fontSize: theme.typography.label2.fontSize,
  fontWeight: theme.typography.label2.fontWeight,
  lineHeight: theme.typography.label2.lineHeight,
  color: theme.palette.warning.main,
}));


export const StateText = styled(Typography)(
  ({ theme }) => `
    font-weight: ${theme.typography.placeholder.fontWeight};
    line-height: ${theme.typography.label2.lineHeight};
  `
);