import BasicModal from "@/components/dialog/Notiffication";
import HeaderSection from "@/components/headerSection/HeaderSection";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import SnackbarNotification from "@/components/snackbar/Snackbar";
import { NEGOTATION_MOVING } from "@/consts/negotationFlowPath";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { Container, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import useUnsavedAndSavedChanges from "../../hooks/useUnsavedAndSavedChanges";
import { getPhotosNegotationThunk } from "@/store/customerRequestRedux/customerRequestThunk";
import { ICustumerRequestPhotos } from "@/models/customerRequest/customerRequestResponse";
import {
  setFotos,
  setLoading,
} from "@/store/customerRequestRedux/customerRequestSlice";
import { optionTwo } from "@/consts/noticeConsts";
import { ISolicitudViewDetailsResponse } from "@/models/customerRequest/requestDetailsVewResponse";

export interface HeaderSection {
  icon: string;
  iconSection: string;
  title: string;
  subTitle: string;
}

const headerSection: HeaderSection = {
  icon: "icon-arrow-left--primary",
  iconSection: "icon-box",
  title: "Carga y Vehículo",
  subTitle: "Editar",
};

const headerSectionMoving: HeaderSection = {
  icon: "icon-arrow-left--primary",
  iconSection: "icon-location-light",
  title: "Origen",
  subTitle: "Solicitar mudanza",
};

const headerSectionDestinyMoving: HeaderSection = {
  icon: "icon-arrow-left--primary",
  iconSection: "icon-location-light",
  title: "Destino",
  subTitle: "Solicitar mudanza",
};

const emptyHeaderSection: HeaderSection = {
  icon: "",
  iconSection: "",
  title: "",
  subTitle: "",
};

const ContainerArea = styled(Container)(
  () => `
      padding: 16px 0;
    `
);

const Load = () => {
  const dispatch = useAppDispatch();
  const {
    handleManageErrorAPISnackbar,
    handleSnackbarError,
    handleManageModalUnsavedChanges,
  } = useUnsavedAndSavedChanges();
  const navigate = useNavigate();
  const [textToRender, setTextToRender] = useState(emptyHeaderSection);
  const { typeRequest = "" } = useParams();
  const { customerRequest } = useAppSelector((state) => state);
  const photos = customerRequest?.savedData?.fotos as ICustumerRequestPhotos[];
  const activeButton = customerRequest.activeButton;
  const error = customerRequest.error;
  const data =
    customerRequest.requestDetailsView as ISolicitudViewDetailsResponse;

  useEffect(() => {
    let objTextToRender = headerSection;
    if (!!error) {
      handleManageErrorAPISnackbar();
      handleSnackbarError();
    }

    if (typeRequest === NEGOTATION_MOVING) {
      objTextToRender = headerSectionMoving;
      if (data.flgCiudadoEntreciudades === optionTwo) {
        objTextToRender = headerSectionDestinyMoving;
      }
    }
    setTextToRender(objTextToRender);

    initPhotos();
  }, [error]);

  const handlePrevRute = () => {
    if (activeButton) {
      !!error ? navigate(-1) : handleManageModalUnsavedChanges();
    } else {
      navigate(-1);
    }
  };

  const initPhotos = async () => {
    if (!!photos) {
      if (photos.length === 0) return;
    }

    dispatch(setLoading(true));

    const photosObj = !!photos
      ? await Promise.allSettled(
          photos.map(async (item) => {
            return await dispatch(getPhotosNegotationThunk(item.id.toString()));
          })
        )
      : [];

    const result = photosObj.map((item: any) => ({
      ...item.value.solicitudFoto,
    }));

    if (result) {
      dispatch(setFotos(result as ICustumerRequestPhotos[]));
      dispatch(setLoading(false));
    }

    return result;
  };

  return (
    <>
      <Container maxWidth="xs">
        <HeaderSection
          icon={textToRender.icon}
          title={textToRender.title}
          subTitle={textToRender.subTitle}
          onClick={handlePrevRute}
        />
        <ContainerArea>
          {!!error && <SnackbarNotification />}
          <SectionIcon icon={textToRender.iconSection} />
          <Outlet />
        </ContainerArea>
      </Container>
      <BasicModal />
    </>
  );
};

export default Load;
