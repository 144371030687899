import { useAppSelector } from "@/hooks/useReducer";
import { INoticeCreateListItem } from "../../NoticeList/types";
import PlaceAuctionBetweenCities from "./PlaceAuctionBetweenCities";
import PlaceBetweenCities from "./PlaceBetweenCities";
import { PlaceInCity } from "./PlaceInCity";
import PlaceMoving from "./PlaceMoving";
import PlaceMultiInCity from "./PlaceMultiInCity";
import { MetadaModalFilter } from "@/components/Filter/components/ModalFilter/metaDataConfig";
import { metaDataModals } from '../../../../components/Filter/components/ModalFilter/metaDataConfig';
import LabelRequired from "@/components/utils/LabelRequired";

const usePlaceContainersHook = () => {

    const {
        noticeV2: { selectNotice },
    } = useAppSelector((state) => state);

    const Component = objectRenderComponent[(selectNotice as INoticeCreateListItem)?.id];

    return {
        Component,
    }
}


export default usePlaceContainersHook;


const objectRenderComponent: Record<number, () => JSX.Element> = {
    1: PlaceInCity,
    2: PlaceMultiInCity,
    3: PlaceBetweenCities,
    4: PlaceAuctionBetweenCities,
    5: PlaceMoving,
};

export const MIN_PRICE_BW = 50;
export const MIN_PRICE_MV = 100;
export const MAX_PRICE_BW = 999999;

export const MIN_PRICE_IN = 5;
export const MAX_PRICE_IN = 99999;

// placeholder
export const placeholdersPlace: Record<number, any> = {
    1: {
        city: {
            label: LabelRequired({
                str: 'Ciudad',
            }),
            placeholder: 'Ciudad de salida',
        },
        origin: {
            label: LabelRequired({
                str: 'Origen',
            }),
            placeholder: 'Distrito de salida',
        },
    },
    2: {
        city: {
            label: LabelRequired({
                str: 'Ciudad',
            }),
            placeholder: 'Ciudad de salida',
        },
        origin: {
            label: LabelRequired({
                str: 'Origen',
            }),
            placeholder: 'Distrito de salida',
        },
    },
    3: {
        origin: {
            label: LabelRequired({
                str: 'Origen',
            }),
            placeholder: 'Ciudad de salida',
        },
    },
    4: {
        origin: {
            label: LabelRequired({
                str: 'Origen',
            }),
            placeholder: 'Ciudad de salida',
        },
        destiny: {
            label: LabelRequired({
                str: 'Destino',
            }),
            placeholder: 'Ciudad de destino',
        },
    },
    5: {
        city: {
            label: LabelRequired({
                str: 'Ciudad',
            }),
            placeholder: 'Ciudad de salida',
        },
        origin: {
            label: LabelRequired({
                str: 'Origen',
            }),
            placeholder: 'Distrito de salida',
        },
    },

};

// modal filter config add new district or city
export const metaDataModalAdd: MetadaModalFilter = {
    ...metaDataModals,
    city: {
        title: 'Ingresa tu destino',
        inputName: 'city',
        inputLabel: 'Destino',
        inputPlaceholder: 'Ciudad de destino',
        buttonText: 'ELEGIR',
    },
    destiny: {
        title: 'Ingresa tu destino',
        inputName: 'destiny',
        inputLabel: 'Destino',
        inputPlaceholder: 'Distrito de destino',
        buttonText: 'ELEGIR',
    },
}


// modal filter config  inCity, multiInCity, movingInCity
export const metaDataModalInCIty: MetadaModalFilter = {
    ...metaDataModals,
    origin: {
        title: 'Ingresa tu origen',
        inputName: 'origin',
        inputLabel: 'Origen',
        inputPlaceholder: 'Distrito de salida',
        buttonText: 'ELEGIR',
    },
    city: {
        title: 'Ingresa tu ciudad',
        inputName: 'city',
        inputLabel: 'Ciudad',
        inputPlaceholder: 'Ciudad de salida',
        buttonText: 'ELEGIR',
    },
}

// modal filter config  betweenCities, movingBetweenCities, remate
export const metaDataModalBetweenCities: MetadaModalFilter = {
    ...metaDataModals,
    origin: {
        title: 'Ingresa tu origen',
        inputName: 'origin',
        inputLabel: 'Origen',
        inputPlaceholder: 'Ciudad de salida',
        buttonText: 'ELEGIR',
    },
    destiny: {
        title: 'Ingresa tu destino',
        inputName: 'destiny',
        inputLabel: 'Destino',
        inputPlaceholder: 'Ciudad de destino',
        buttonText: 'ELEGIR',
    },
}       


// Error messages
export const ERROR_MESSAGES_LOW_PRICE = "El precio es muy bajo para este servicio."

// Error messages Auction
export const ERROR_MESSAGES_AUCTION = "El precio de remate no puede ser mayor al precio regular."
export const ERROR_MESSAGES_AUCTION_MIN = "Mínimo 20% menos del precio regular."
export const ERROR_MESSAGES_AUCTION_SAME = "El origen y destino no pueden ser iguales."

// Error messages duplicated
export const ERROR_MESSAGES_DUPLICATED = "Ya tienes un aviso activo a este destino."
export const ERROR_MESSAGES_DUPLICATED_MOVING = "Ya tienes un aviso activo a este destino con el mismo precio o ayudantes."
