import { prepareUrlParams } from "@/adapters/filterAdapter";
import Filter from "@/components/Filter/Filter";
import { tabHeigh } from "@/components/Filter/StyleFilter";
import {
  FormDataFilter,
  INITIAL_STATE,
} from "@/components/Filter/consts/filtersConst";
import { BASE_URL_IMAGE } from "@/config/config";
import { FRONT_PAGE_HERO_SM } from "@/consts/defaultConsts";
import { SERVICES_LANDING_PATH } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import Footer from "@/pages/footer/Footer";
import { TypeFilterData } from "@/store/FilterServices/filterReduxInterface";
import { setTypeNoticeSelect } from "@/store/filterNoticeRedux/filterNoticeSlice";
import {
  getInitialFilterList,
  hidrateTypeVehiclesThunk,
} from "@/store/filterNoticeRedux/filterNoticeThunk";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AdvertisingLandSection from "../components/AdvertisingLandSection";
import InfoAppSection from "../components/InfoAppSection";
import BannerLand from "../components/bannerLand/BannerLand";
import InfoSection from "./components/InfoSection";
import {
  HomeContainer,
  SubContainerHome,
  responsiveImagesHome,
} from "./stylesHome";

const Home = () => {
  const [initialData, setInitialData] = useState(INITIAL_STATE);
  const navigate = useNavigate();
  const { search } = useLocation();
  const [_, setSearchParams] = useSearchParams();
  const [isMultidestinyTap, setisMultidestinyTap] = useState(false);
  const {
    filterNotice: { typeNotice, typeNoticeSelecte },
    user: { user },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matchesTablett = useMediaQuery("(max-width:767px)");
  const mdMatch = useMediaQuery("(max-width:850px)");

  useEffect(() => {
    if (!search) {
      handleInit();
    }
  }, [search]);

  useEffect(() => {
    handleInit();
  }, [typeNotice]);

  const handleInit = async () => {
    const currentUser = user as IUser;
    let city = currentUser?.ciudad?.nombre2 || INITIAL_STATE.city;
    if (!typeNotice.length) {
      const filterData = await dispatch(getInitialFilterList());
      if (!!filterData?.filters?.typeNotice.length) {
        const selected = handleGetSelectedTypeNotice(
          filterData?.filters?.typeNotice
        );
        if (selected) {
          dispatch(setTypeNoticeSelect(selected));
          setInitialData((prev) => ({
            ...prev,
            city,
            typeNotice: selected.nombre,
          }));
        }
      }
    } else {
      const selected = handleGetSelectedTypeNotice(typeNotice);
      dispatch(setTypeNoticeSelect(selected || typeNotice[0]));
      setInitialData((prev) => ({
        ...prev,
        city,
        typeNotice: typeNotice[0].nombre,
      }));
      dispatch(hidrateTypeVehiclesThunk());
    }
  };

  const handleGetSelectedTypeNotice = (typeNoticeList: TypeFilterData[]) => {
    let typeNotice = "Transporte en la ciudad";
    if (!!search) {
      typeNotice = queryString.parse(search)?.typeNotice as string;
    }

    const selected = typeNoticeList.find(
      (notice) => notice.nombre === typeNotice
    );

    return selected;
  };

  const handleSubmit = (values: FormDataFilter) => {
    let valuesUrl = handlePrepareUrlParams(values);
    if (!typeNoticeSelecte?.inputfechas) {
      const { betweenDate, ...url } = valuesUrl;
      valuesUrl = url;
    }
    const result = queryString.stringify(valuesUrl, {
      skipEmptyString: true,
      skipNull: true,
      arrayFormatSeparator: "|",
    });

    navigate(`${SERVICES_LANDING_PATH}?${result}`);
  };

  const handlePrepareUrlParams = (values: FormDataFilter): any => {
    const prepareObject = prepareUrlParams(values, typeNoticeSelecte);

    return prepareObject;
  };

  const handleOnChangeTypeNotice = (selected: TypeFilterData) => {
    const typeNotice = selected.nombre;
    if (typeNotice === "Multidestino en la ciudad") {
      setisMultidestinyTap(true);
    } else {
      setisMultidestinyTap(false);
    }
    const url = queryString.stringify({ typeNotice });
    setSearchParams(url);
  };

  const handleMinHeightBox = () => {
    let value: { minHeight: string } | {} = { minHeight: "280px" };

    if (matchesTablett) value = {};
    if (mdMatch || isMultidestinyTap) value = { minHeight: "390px" };

    return value;
  };

  return (
    <HomeContainer>
      {!matches && (
        <BannerLand
          urlImg={`${BASE_URL_IMAGE}${FRONT_PAGE_HERO_SM}`}
          responsive={responsiveImagesHome}
          positionXY={{ xs: "center", md: "center" }}
          minHeight="166px"
        />
      )}

      <Box
        sx={{
          position: "relative",
          ...handleMinHeightBox(),
        }}
      >
        <SubContainerHome matches={matches}>
          <Filter
            show={matchesTablett}
            initialOpenFilter={matchesTablett}
            onSubmit={handleSubmit}
            initValue={initialData}
            onChangeTypeNotice={handleOnChangeTypeNotice}
            closeArrow={false}
            filterStyles={{
              section: {
                backgroundColor: matches ? "#fafafa" : "transparent",
                borderTop: "none",
                paddingTop: matchesTablett ? "" : "10px",
              },
              boxContainer: {
                top: matchesTablett ? 0 : "",
              },
              inputContainer: {
                marginTop: `${tabHeigh}px`,
              },
            }}
          />
        </SubContainerHome>
      </Box>

      {matches && <Box sx={{ height: matchesTablett ? "340px" : 190 }}></Box>}

      {matches ? (
        <>
          <Box style={{ width: "100%", marginBottom: "16px" }}>
            <AdvertisingLandSection />
          </Box>

          <Box>
            <InfoAppSection />
          </Box>
        </>
      ) : (
        <InfoSection />
      )}

      <Footer />
    </HomeContainer>
  );
};

export default Home;
