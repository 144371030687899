import {
  APPLICATION_DETAIL_PATH,
  CREATE_CUSTOMER_REQUEST_PATH,
  MY_APPLICATIONS_CUSTOMER_REQUEST_PATH,
  MY_APPLICATIONS_PATH,
  OFFERT_CUSTOMER_REQUEST_PATH,
} from '@/pages/customerRequest/shared/consts/routes';
import ListCustomerRequest from '@/pages/customerRequest/components/ListCustomerRequest/Index';
import { Application } from '@/pages/customerRequest/application/Application';
import BodyApplication from '@/pages/customerRequest/application/components/BodyApplication';
import CustomerRequestList from '@/pages/profile/pages/customerRequestList/CustomerRequestList';
import CustomerRequestOffert from '@/pages/profile/pages/customerRequestOffert/CustomerRequestOffert';
import { privateCreateRequestListMiddleware } from '@/router/middleware/privateCreateRequestMiddleware';
import { RouterProps } from '@/router/router';

export const publicCustomerRequestRouter: RouterProps[] = [
  {
    path: CREATE_CUSTOMER_REQUEST_PATH,
    Component: ListCustomerRequest,
    middleware: privateCreateRequestListMiddleware,
  },
  {
    path: MY_APPLICATIONS_PATH,
    Component: Application,
    children: [
      {
        index: true,
        Component: BodyApplication,
      },
    ],
  },
  {
    path: APPLICATION_DETAIL_PATH,
    Component: Application,
    children: [
      {
        index: true,
        Component: BodyApplication,
      },
    ],
  },
  {
    path: MY_APPLICATIONS_CUSTOMER_REQUEST_PATH,
    Component: CustomerRequestList,
    requiredSession: true,
  },
  {
    path: OFFERT_CUSTOMER_REQUEST_PATH,
    Component: CustomerRequestOffert,
    requiredSession: true,
  },
];
