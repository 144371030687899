import DetailsText from '@/pages/customerRequest/components/DetailsText';
import { currencyFormat } from '@/utils/helpers';
import React from 'react'

interface IMovingPriceProps {
    price: number;
    ayudantes: number;
    type: number;
  }

const MovingPrice = ({ price, ayudantes, type }: IMovingPriceProps) => {
  return (
    <>
      <DetailsText
                show
                titleSx={{
                    fontSize: '22px',
                }}
                title={`Cotizado en ${currencyFormat(price)}`}
                separate={false}
                origen="Por viaje"
            />
            <DetailsText
                show
                title={`Cantidad de ayudantes`}
                origen={`${ayudantes}`}
                constainerSx={{
                    marginTop: '0px',
                }}
            />
    </>
  )
}

export default MovingPrice
