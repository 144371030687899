import LayoutFlow from "@/components/LayoutFlow/LayoutFlow";
import { TitleSection } from "@/components/headerSection/styleHeaderSection";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { signUpStartDataLayer } from "@/services/dataLayer/auth";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { setTypeUser } from "@/store/userRedux/userSlice";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import ListOptions from "./components/ListOptions";
import { dataListOptions, description, title } from "./configData";

type UserTypeClick = {
  typeFLow?: string;
  link?: string;
};

const UserType = () => {
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const currentUser = user as IUser;
    if (currentUser?.id) {
      signUpStartDataLayer(currentUser?.id);
    }
  }, []);

  const handleClick = ({ typeFLow }: UserTypeClick) => {
    dispatch(setTypeUser(typeFLow || "T"));
  };

  return (
    <LayoutFlow>
      <TitleSection>{title}</TitleSection>
      <Typography variant="body1" marginTop="16px" marginBottom="16px">
        {description}
      </Typography>

      <ListOptions list={dataListOptions} onClick={handleClick} replaceNavigate={true} />
    </LayoutFlow>
  );
};

export default UserType;
