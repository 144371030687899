import useNotiffication from "@/hooks/useNotiffication";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

import { useNavigate } from "react-router-dom";
import { ButtonAccionModal, PillButton } from "../modals/BannerModal/stylesBannerModal";


interface IBasicModalProps {
  color?: "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | "org" | undefined;
  sx?: any;
  sxAccion?: any;
  goBack?: boolean
}

export default function BasicModal({ color = "org", goBack, sx, sxAccion }: IBasicModalProps) {
  const { notiffication, removeNotiffication } = useNotiffication();
  const navigate = useNavigate();
  const { title, btnLeft, btnRight, message } = React.useMemo(() => {
    let title = "";
    let btnLeft = "";
    let btnRight = "";
    let message = ""
    if (notiffication?.title) {
      title = notiffication?.title as string;
    }
    if (notiffication?.leftBtnText) {
      btnLeft= notiffication?.leftBtnText as string
    }
    if (notiffication?.RightBtnText) {
      btnRight = notiffication?.RightBtnText as string
    }
    if(notiffication?.message){
      message= notiffication?.message as string
    }
    return { title, btnLeft, btnRight, message };
  }, [notiffication]);

  const handleOK = () => {
    removeNotiffication(true);
  };
  const handleClose = () => {
    removeNotiffication(false);
    !!goBack && navigate(-1);
  };

  if (notiffication?.hiddenModal) return <></>;

  return (
    <Dialog
      maxWidth="xl"
      open={!!notiffication}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      sx={{
        width: {
          xs: "100%",
          sm: "397px",
        },
        height: "auto",
        alignItems: "center",
        justifyContent: "center",
        margin: "0 auto",
        "& .css-1r619l1-MuiPaper-root-MuiDialog-paper": {
          borderRadius: "2px",
          margin: {
            xs: "0 16px",
            sm: "0",
          },
          width: {
            xs: "100%",
            sm: "397px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pt: 4,
        }}
      >
        <Typography sx={{ fontWeight: "bold", minWidth: "162px", minHeight: "24px" }}>{title}</Typography>
        <IconButton sx={{ color: "#FF6700" }} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description" sx={{ minWidth: "200px", minHeight: "44px" }}>
          <Typography variant="body1" sx={{ color: "rgba(0, 0, 0, 0.87)" }}>
            {message}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "end",
          padding: "10px",
          gap: "10px",
          pb: 4,
        }}
      >
        {!!notiffication?.leftBtnText && (
          <ButtonAccionModal disableRipple color={color} onClick={handleClose}>
            {btnLeft}
          </ButtonAccionModal>
        )}
        {!!notiffication?.RightBtnText && (
          <PillButton disableRipple variant="contained" color={color} onClick={handleOK}>
            {btnRight}
          </PillButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
