import { FORMAT_DATE_DDMMYYYY } from '@/consts/formatsDate';
import { offerId } from '@/consts/noticeConsts';
import { ResponseMovingNoticeOffer } from '@/models/offert/offertModels';
import { currencyFormat } from '@/utils/helpers';
import dayjs from 'dayjs';

export const formatDate = (
  _: number,
  value: any,
  __: ResponseMovingNoticeOffer
) => {
  const newDate = dayjs(value).format(FORMAT_DATE_DDMMYYYY);

  return newDate;
};

export const getOriginLocationDetails = (
  _: number,
  value: any,
  notice: ResponseMovingNoticeOffer
) => {
  const { origenTexto, referenciaOrigen, inmuebleOrigen, flgAscensor, piso } =
    notice;
  const template1 = `${origenTexto} (${referenciaOrigen})`;
  const props = {
    property: inmuebleOrigen,
    flagAscensor: flgAscensor,
    floor: piso,
  };
  const template2 = buildTemplate2(props);

  return [template1, template2];
};

export const getDestinyLocationDetails = (
  _: number,
  value: any,
  notice: ResponseMovingNoticeOffer
) => {
  const {
    destinoTexto,
    referenciaDestino,
    inmuebleDestino,
    flgAscensorDestino,
    pisoDestino,
  } = notice;
  const template1 = `${destinoTexto} (${referenciaDestino})`;
  const props = {
    property: inmuebleDestino,
    flagAscensor: flgAscensorDestino,
    floor: pisoDestino,
  };
  const template2 = buildTemplate2(props);

  return [template1, template2];
};

const ascensorTemplateObject: Record<number, string> = {
  1: 'ascensor disponible',
  2: 'sin ascensor',
  3: 'ascensor disponible para algunas cosas',
};
type BuildTemplateProps = {
  property: string;
  flagAscensor: number;
  floor: number;
};
const buildTemplate2 = (props: BuildTemplateProps) => {
  const { property, flagAscensor, floor } = props;
  const destiny = 'casa';
  let template = property;

  if (template.toLocaleLowerCase() !== destiny) {
    const ascensor = ascensorTemplateObject[flagAscensor] || '';
    template += ` en piso ${floor} (${ascensor})`;
  }

  return template;
};

export const getformatPrice = (
  _: number,
  value: any,
  notice: ResponseMovingNoticeOffer
) => {
  const price = currencyFormat(notice.precio);

  return price;
};

export const getNumberOfAssistants = (
  _: number,
  value: any,
  notice: ResponseMovingNoticeOffer
) => {
  const { idtipoavisonegociacion, ayudantes } = notice;
  const template = 'Cantidad de ayudantes: :cant';
  if (idtipoavisonegociacion === offerId) {
    return template.replace(':cant', ayudantes.toString());
  }

  return '';
};

export const getListConditions = (
  _: number,
  value: any,
  notice: ResponseMovingNoticeOffer
) => {
  const listConditions = notice.avisoNegociacionCondiciones;
  return listConditions && listConditions.length;
};
