import { COUNTER_OFFER_AUCTION_FLOW_PATH } from '@/pages/flowNoticeNegotation/shared/const/routes/clientAccepOrCounteroffer';
import AuctionBtnCitiesCargoDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/childComponents/FormCargoDetails/AuctionBtnCitiesCargo';
import FormConfirmationDetailAuction from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/childComponents/FormConfirmationDetails/FormConfirmationDetailAuction';
import { AuctionPriceCounterOffer } from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/childComponents/formPriceDetails/AuctionPriceCounterOffer/Index';

import RuteAuction from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/childComponents/FormRuteDetails/RuteAuction/Index';
import CargoDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/containers/CargoDetails/Index';
import ConfirmationDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/containers/ConfirmationDetails/Index';
import PriceDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/containers/PriceDetails/Index';
import RuteDetails from '@/pages/flowNoticeNegotation/steps/aClientAcceptOrCounterOffer/containers/RuteDetails/Index';
import { privateOffertMiddleware } from '@/router/middleware/privateCreateServicesMiddleware';
import { RouterProps } from '@/router/router';

export const counterOfferNoticeNegAuctionRouter: RouterProps[] = [
  {
    path: COUNTER_OFFER_AUCTION_FLOW_PATH[0],
    Component: CargoDetails,
    requiredSession: true,
    middleware: privateOffertMiddleware,
    children: [
      {
        index: true,
        Component: AuctionBtnCitiesCargoDetails,
      },
    ],
  },
  {
    path: COUNTER_OFFER_AUCTION_FLOW_PATH[1],
    Component: RuteDetails,
    middleware: privateOffertMiddleware,
    children: [
      {
        index: true,
        Component: RuteAuction,
      },
    ],
  },
  {
    path: COUNTER_OFFER_AUCTION_FLOW_PATH[2],
    Component: PriceDetails,
    requiredSession: true,
    middleware: privateOffertMiddleware,
    children: [
      {
        index: true,
        Component: AuctionPriceCounterOffer,
      },
    ],
  },
  {
    path: COUNTER_OFFER_AUCTION_FLOW_PATH[3],
    Component: ConfirmationDetails,
    middleware: privateOffertMiddleware,
    children: [
      {
        index: true,
        Component: FormConfirmationDetailAuction,
      },
    ],
  },
];
