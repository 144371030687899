import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import { useGlobalCoCounterOfferButtons } from "@/pages/noticeV2Negotation/shared/hooks/useGlobalCoCounterOfferButtons";
import { ContainerButtonsV2 } from "../../counterOfferDetails/buttonsComponents/buttonsStyles";

export const CoCounterOfferButtons = () => {
  const { isLoadingNoticeV2, notice } = useGetData();
  const { handleAcceptCoCounterOfferButtonV2, handleManageModalDeclineV2 } =
    useGlobalCoCounterOfferButtons();

  const clientActionsActive =
    notice?.ultimaAvisoNegociacionAcciones.tipoAvisoNegociacionAccion.id === 5;

  return (
    <ContainerButtonsV2>
      <ButtomLoader
        fullWidth
        variant="contained"
        onClick={() =>
          clientActionsActive && handleAcceptCoCounterOfferButtonV2()
        }
        isLoading={isLoadingNoticeV2}
        disabled={isLoadingNoticeV2 || !clientActionsActive}
      >
        aceptar
      </ButtomLoader>

      <ButtomLoader
        fullWidth
        variant="text"
        type="submit"
        onClick={() => clientActionsActive && handleManageModalDeclineV2()}
        isLoading={isLoadingNoticeV2}
        disabled={isLoadingNoticeV2 || !clientActionsActive}
      >
        rechazar
      </ButtomLoader>
    </ContainerButtonsV2>
  );
};
