import {
  MY_PENDING_AGREEMENTS,
  REQUESTS_LANDING_PATH,
} from '@/consts/typeServicesPaths';
import { LocationState } from '@/interfaces/globalInterface';
import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useFinishedRejectLayoutHook = () => {
  const navigate = useNavigate();
  const [hasBackNavigate, setHasBackNavigate] = useState<boolean>(false);
  const location = useLocation();
  const { pathname } = location;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const isCounterOffer = pathname.includes('contraoferta');
  const isCoCounterOffer = pathname.includes('co-contraoferta');

  useEffect(() => {
    if ((location.state as LocationState)?.hasBackNavigate) {
      setHasBackNavigate(true);
    }
  }, [location.state]);

  const navigateNegociations = () => {
    if (isCoCounterOffer) {
      navigate(MY_PENDING_AGREEMENTS, {
        replace: true,
      });
      return;
    }
    navigate(REQUESTS_LANDING_PATH, { replace: true });
  };

  const handleHeaderProps = () => {
    if (isCounterOffer) {
      return rejectCounterOfferData;
    }

    return RejectAcceptData;
  };

  const handleNavigationBack = () => {
    navigate(-1);
  };

  return {
    theme,
    matches,
    navigateNegociations,
    handleHeaderProps,
    isCoCounterOffer,
    hasBackNavigate,
    handleNavigationBack,
  };
};

const RejectAcceptData = {
  title: '¡No aceptaron el servicio!',
  icon: 'icon-sentimentVeryDissatisfied',
};

const rejectCounterOfferData = {
  title: '¡No aceptaron el servicio!',
  icon: 'icon-sentimentVeryDissatisfied',
};

export default useFinishedRejectLayoutHook;
