import { nottifficationUnsavedChanges, snackbarErrorMesasage, snackbarSavedChanges } from "@/consts/notifficationsMessage";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch } from "@/hooks/useReducer";
import { resetError, setActiveButton, setActiveSnackbar } from "@/store/customerRequestRedux/customerRequestSlice";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const useUnsavedAndSavedChanges = () => {
  const dispatch = useAppDispatch();
  const { addNotiffication, removeNotiffication } = useNotiffication();
  const navigate = useNavigate();

  const timerRemoveNotiffication = useRef<ReturnType<typeof setTimeout> | null>(null);
  const timerRemoveNotiffication2 = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    return () => {
      clearTimeout(timerRemoveNotiffication.current as NodeJS.Timeout);
      clearTimeout(timerRemoveNotiffication2.current as NodeJS.Timeout);
    };
  }, []);

  const handleManageModalUnsavedChanges = (): void => {
    addNotiffication({
      title: nottifficationUnsavedChanges.TITLE,
      message: nottifficationUnsavedChanges.MESSAGE,
      leftBtnText: nottifficationUnsavedChanges.LEFT_BTN_TEXT,
      RightBtnText: nottifficationUnsavedChanges.RIGHT_BTN_TEXT,
      hiddenSnackbar: true,
      hiddenModal: false,
      onSubmit: async () => {
         dispatch(setActiveButton(false));
        navigate(-1);
      },
    });
  };

  const handleManageSanackbarSavedChanges = (): void => {
    addNotiffication({
      title: snackbarSavedChanges.TITLE,
      message: snackbarSavedChanges.MESSAGE,
      hiddenSnackbar: false,
      hiddenModal: true,
      hiddenClose: true,
      onSubmit: async () => {},
    });
  };

  const handleSnackbarSuccess = () => {
    dispatch(setActiveSnackbar(false));

    timerRemoveNotiffication.current = setTimeout(() => {
      removeNotiffication(false);
    }, 15000);
  };

  const handleManageErrorAPISnackbar = (): void => {
    addNotiffication({
      title: snackbarErrorMesasage.TITLE,
      message: snackbarErrorMesasage.MESSAGE,
      hiddenModal: true,
      hiddenSnackbar: false,
      hiddenClose: true,
      onSubmit: async () => {},
    });
  };

  const handleSnackbarError = () => {
    timerRemoveNotiffication2.current = setTimeout(() => {
      dispatch(resetError());
      removeNotiffication(false);
    }, 15000);
  };

  return {
    handleManageModalUnsavedChanges,
    handleManageSanackbarSavedChanges,
    handleSnackbarSuccess,
    handleManageErrorAPISnackbar,
    handleSnackbarError,
  };
};

export default useUnsavedAndSavedChanges;
