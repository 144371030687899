import { useAppDispatch } from "@/hooks/useReducer";
import { Advertisement } from "@/models/advertisement/advertisementModel";
import {
  setClickAdvertisementThunk,
  setShowAdvertisementThunk,
} from "@/store/advertisementRedux/advertisementThunk";
import { useLocation } from "react-router-dom";
import AdvertisementItem from "./AdvertisementItem";

type Props = {
  advertisementList: Advertisement[];
  onLoad?: () => void;
};

const AdvertisementList = ({ advertisementList, onLoad }: Props) => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const handleOnLoad = (item: Advertisement) => {
    dispatch(setShowAdvertisementThunk(item.id, pathname));
    onLoad && onLoad();
  };

  const handleOnClick = (item: Advertisement) => {
    dispatch(setClickAdvertisementThunk(item.id, pathname));
  };

  return (
    <>
      {advertisementList.map((advertisement, i) => (
        <AdvertisementItem
          key={`${i}-AdvertisementItem-${advertisement.id}`}
          advertisement={advertisement}
          index={i}
          onLoad={handleOnLoad}
          onClick={handleOnClick}
        />
      ))}
    </>
  );
};

export default AdvertisementList;
