import { CheckCircleIcon } from "@/assets/svg";
import {
  AGREEMENT_RATING,
  CONFIRMATION_PATH,
  HOME_PAHT,
} from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { logoutThunk } from "@/store/userRedux/userThunks";
import { Container, Typography, styled } from "@mui/material";
import { useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import IconsSvg from "../detailNotice/components/IconsSvg";
import { TypeConfirmationchange, configData } from "./configData";
import LinkButton from "@/components/linkButton/LinkButton";
import { setSuccessMsg } from "@/store/userRedux/userSlice";
import { MY_APPLICATIONS_PATH, TYPE_REQUEST_PATH } from "../customerRequest/shared/consts/routes";
import { customerRequestObjectTypes } from "../customerRequest/shared/consts/customerRequestConsts";
import { ISolicitudViewDetailsResponse } from "@/models/customerRequest/requestDetailsVewResponse";
import { ID_MULTI_DESTINY } from "@/consts/defaultConsts";

const ContainerSuccess = styled(Container)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: ${theme.palette.background.default};
    flex-direction: column;
  `
);
const Content = styled("div")(
  () => `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 360px;
    text-align: center;
  `
);

interface LocationState {
  type?: keyof TypeConfirmationchange;
  message?: string;
  nextRute?: string;
  textButton?: string;
}

const confirmationRequest = "volver-solicitar-confirmacion";
const calificacionConfirmacion = "calificacion-confirmacion";
const show_notice = "VER EL AVISO";
const textQualification = "Tu calificación fue enviada";

const ConfirmacionCambio = () => {
  const {
    customerRequest: { requestDetailsView },
  } = useAppSelector((state) => state);
  const { successMsg } = useAppSelector((state) => state.user);
  const [notice] = useAppSelector((state) => state.saveFlowData.saveFlowData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const isValidUrl = pathname.endsWith(confirmationRequest);
  const isCalificacion = pathname.endsWith(calificacionConfirmacion);
  const newPathname = MY_APPLICATIONS_PATH;
  const isAgreementQualification = pathname.endsWith(AGREEMENT_RATING);

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (!state) {
      navigate(HOME_PAHT, { replace: true });
    }
  }, []);

  useEffect(() => {
    if ((state as LocationState)?.type) {
      dispatch(logoutThunk());
      timer.current = setTimeout(() => {
        dispatch(setSuccessMsg(""));
        navigate(HOME_PAHT, { replace: true });
      }, 15000);
    }

    return () => {
      clearTimeout(timer.current as NodeJS.Timeout);
      dispatch(setSuccessMsg(""));
    };
  }, [pathname]);

  const changeTitlesAndPath = () => {
    if (!state) return configData.changePassword;

    const locationState = state as LocationState;
    const type = locationState?.type;
    const message = locationState?.message || "";
    if (pathname === CONFIRMATION_PATH) {
      if (type) {
        return configData[type];
      }
    }

    if (isValidUrl) {
      const {
        tipoSolicitud: { id: idTypeRequest },
      } = requestDetailsView as ISolicitudViewDetailsResponse;

      const valueToReplace = customerRequestObjectTypes[idTypeRequest];

      let msgForType = `Volviste a solicitar el servicio %c para el ${requestDetailsView?.fecha}.`

      if (idTypeRequest === ID_MULTI_DESTINY) {
        msgForType = msgForType?.replace("%c", `en ${requestDetailsView?.origenTexto}`)
      } else {
        msgForType = msgForType?.replace("%c", `de ${requestDetailsView?.origenTexto} a ${requestDetailsView?.destinoTexto}`)
      }

      return {
        title: "¡Listo!",
        path: `${newPathname
          ?.replace(TYPE_REQUEST_PATH, valueToReplace)
          .replace(":id", requestDetailsView?.id.toString() as string)}`,
        textButton: "VER LA SOLICITUD",
        message: msgForType,
      };
    }

    if (isCalificacion) {
      return configData.calificacionConfirmacion;
    }

    const nextRuteState = locationState?.nextRute;
    const textButtonState = locationState?.textButton;

    const cleanPathname = !!nextRuteState
      ? nextRuteState
      : `${pathname?.split("/").slice(0, -2).join("/")}/${notice.id}`;

    const textButton = !!textButtonState
      ? textButtonState
      : show_notice;

    return {
      title: "¡Listo!",
      path: nextRuteState || cleanPathname,
      textButton: textButton,
      message: message,
    };
  };

  const handleTextConfirmation = () => {
    if (isAgreementQualification) {
      return textQualification;
    }

    return successMsg || message;
  };

  const { message, path, textButton, title } = useMemo(
    () => changeTitlesAndPath(),
    [pathname]
  );

  return (
    <>
      <ContainerSuccess>
        <Content
          sx={{
            mt: 4,
            mb: 4,
          }}
        >
          <IconsSvg icon={CheckCircleIcon} isCustom />
          <Typography
            variant="h6"
            component="h6"
            sx={{ mt: 2, fontWeight: 700 }}
          >
            {title}
          </Typography>
          <Typography variant="body1" sx={{ mt: 1, mb: 2 }}>
            {handleTextConfirmation()}
          </Typography>
          {textButton && (
            <LinkButton to={path} replace>
              {textButton}
            </LinkButton>
          )}
        </Content>
      </ContainerSuccess>
    </>
  );
};

export default ConfirmacionCambio;
