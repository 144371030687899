import EmptyListData from "@/components/emptyListData/EmptyListData";
import SkeletonInfoCardList from "@/components/skeletons/SkeletonInfoCardList";

import CardNegInfo from "@/components/CardNegInfo";
import { agreementClosedText } from "@/consts/noticeConsts";
import { IRequestNegItemList } from "@/models/customerRequest/RequestNegListDataResponse";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { deleteDomainUrl } from "@/utils/helpers";
import { EmptyNegotationProfileClient } from "@/assets/svg";

export type ListPropsCustomerOffert = {
  requestNegList: IRequestNegItemList[];
  isLoading: boolean;
  linkFooter?: string;
  showState?: boolean;
};
const List = ({ requestNegList, isLoading }: ListPropsCustomerOffert) => {
  const navigate = useNavigate();
  if (isLoading) return <SkeletonInfoCardList />;

  if (!requestNegList.length)
    return <EmptyListData title="No tienes negociaciones" 
      render={<EmptyNegotationProfileClient/>}
      optionTwo
    />;
  const showRatingButton = (data: IRequestNegItemList) => {
    const {
      estado
    } = data;
    return estado === agreementClosedText;
  }

  const handleNavigate = (link: string, data: IRequestNegItemList) => {
    navigate(deleteDomainUrl(link) || "", {
      state: {
        hasBackNavigate: true,
        showCalification: showRatingButton(data)
      },
    });
  };
  return (
    <>
      {requestNegList && (
        <>
          {requestNegList.map((data, index) => (
            <Grid item key={index}>
              <CardNegInfo
                imgCart={data?.fotoVehiculo || ""}
                imgPerson={data?.foto || ""}
                name={data?.nombre || ""}
                qlt={data?.calificaciones || 0}
                points={data?.puntaje || 0}
                status={data?.estado || ""}
                price={data?.precio || 0}
                statusColor={data?.colorEstado || ""}
                onClick={() => handleNavigate(data?.link || "", data)}
              />
            </Grid>
          ))}
        </>
      )}
    </>
  );
};

export default List;
