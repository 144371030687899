import { NavigateFunction } from 'react-router-dom';
import { AppDispatch, RootState } from '../store';
import {
  IRouteFlowByIdTypeNoticeV2,
  getRoutesByIdTypeNoticeV2,
} from './config';
import {
  ICurrentLocation,
  initRouteFlow,
  resetFlowNotice,
  setCurrentLocation,
} from './routeActionsFlowNoticeV2Slice';
import { replaceValue } from '@/utils/helpersActions';

/**
 * Carga los path de el flujo de ofertas dependiendo del id que se le pase!!
 * "Esta función toma un idTypeFlow y devuelve una función que toma una función de envío y devuelve una
 * cadena".
 *
 * La función es un procesador thunk, que es una función que devuelve una función.
 *
 * La función interna toma una función de envío como parámetro y devuelve una cadena.
 *
 * La función externa toma un idTypeFlow y devuelve la función interna.
 *
 * La función interna es llamada por la función externa.
 *
 * La función interna llama a la función de envío, que se le pasa como un parámetro.
 *
 * La función de envío se llama con dos parámetros: initRouteFlow y setCurrentLocation.
 *
 * initRouteFlow es una función que toma un routeFlow como parámetro.
 *
 * setCurrentLocation es una función que toma una ruta actual y un paso como parámetros.
 *
 * La función interna devuelve una cadena.
 * @param {number} idTypeFlow - número
 * @returns Se devuelve la función initRouteFlowByIdNoticeThunk.
 */
export const initRouteFlowByIdNoticeV2Thunk = (
  idTypeFlow: number,
  nameReplace: string,
  typeNotice?: keyof IRouteFlowByIdTypeNoticeV2
) => {
  return (dispatch: AppDispatch) => {
    const routeFlow = getRoutesByIdTypeNoticeV2(
      idTypeFlow,
      nameReplace,
      typeNotice
    );
    const initRoute = routeFlow[0];
    dispatch(initRouteFlow(routeFlow));
    dispatch(setCurrentLocation({ currentPath: initRoute, step: 0 }));

    return initRoute;
  };
};

interface OptionsReplace {
  valueToReplace: string;
  valueToSearch: string;
  idToSearch?: string;
  idValueToReplace?: string;
}
export const getNextRouteV2 = (
  navigate: NavigateFunction,
  optionsReplace?: OptionsReplace
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const { routeFlow, currentLocation } =
      getCurrentFlowNoticeV2ByState(getState);

    const { step } = currentLocation;
    let nextRoute = routeFlow[step + 1];
    if (nextRoute) {
      dispatch(setCurrentLocation({ step: step + 1, currentPath: nextRoute }));
      if (optionsReplace) {
        nextRoute = replaceValue(
          nextRoute,
          optionsReplace.valueToSearch,
          optionsReplace.valueToReplace,
          optionsReplace.idToSearch,
          optionsReplace.idValueToReplace
        );
      }
      navigate(nextRoute, { replace: true });
    }
  };
};

const getCurrentFlowNoticeV2ByState = (getState: () => RootState) => {
  const { routeFlowNoticeV2 } = getState();
  const currentLocation = routeFlowNoticeV2.currentLocation as ICurrentLocation;
  const routeFlow = routeFlowNoticeV2.routeFlow;

  return { routeFlow, currentLocation };
};

export const getPrevRouteV2 = (
  navigate: NavigateFunction,
  optionsReplace?: OptionsReplace
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const { routeFlow, currentLocation } =
      getCurrentFlowNoticeV2ByState(getState);
    const { step } = currentLocation;

    let prevRoute = routeFlow[step - 1];

    if (prevRoute) {
      if (optionsReplace) {
        prevRoute = replaceValue(
          prevRoute,
          optionsReplace.valueToSearch,
          optionsReplace.valueToReplace
        );
      }

      dispatch(setCurrentLocation({ step: step - 1, currentPath: prevRoute }));

      navigate(prevRoute, { replace: true });
    } else {
      dispatch(resetFlowNotice());
      navigate(-1);
    }
  };
};
