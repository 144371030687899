import {
  NOTICE_AUCTION_PRIVATE_PATH,
  NOTICE_BETWEEN_CITIES_PRIVATE_PATH,
  NOTICE_IN_CITY_PRIVATE_PATH,
  NOTICE_MOVING_PRIVATE_PATH,
  NOTICE_MULTI_DESTINY_PRIVATE_PATH,
} from '@/consts/typeServicesPaths';
import DetailNotice from '@/pages/detailNotice';
import Details from '@/pages/detailNotice/components/details/Details';
import DetailsMultiDestiny from '@/pages/detailNotice/components/details/DetailsMultiDestiny';
import { RouterProps } from '@/router/router';

export const noticePrivateRouter: RouterProps[] = [
  {
    path: NOTICE_IN_CITY_PRIVATE_PATH,
    Component: DetailNotice,
    isFree: true,
    children: [
      {
        index: true,
        Component: Details,
      },
    ],
  },
  {
    path: NOTICE_AUCTION_PRIVATE_PATH,
    Component: DetailNotice,
    children: [
      {
        index: true,
        Component: Details,
      },
    ],
  },
  {
    path: NOTICE_MOVING_PRIVATE_PATH,
    Component: DetailNotice,
    isFree: true,
    children: [
      {
        index: true,
        Component: Details,
      },
    ],
  },
  {
    path: NOTICE_MULTI_DESTINY_PRIVATE_PATH,
    Component: DetailNotice,
    isFree: true,
    children: [
      {
        index: true,
        Component: DetailsMultiDestiny,
      },
    ],
  },
  {
    path: NOTICE_BETWEEN_CITIES_PRIVATE_PATH,
    Component: DetailNotice,
    isFree: true,
    children: [
      {
        index: true,
        Component: Details,
      },
    ],
  },
];
