import { NoticeNegotationRequestProps } from '@/pages/noticeRequestFlow/adapters/noticeRequestFlowAdapters';
import {
  BodyUploadPriceNegotationPrice,
  getNoticeResponseToClient,
  putNoticeResponseToDriver,
  putRemoveNoticeRequest,
  requestNegotationServices,
  uploadPriceNegotation,
} from '@/services/noticeNegotationRequestServices';
import { NoticeExpressNegotationRequestProps } from '../../pages/noticeRequestFlow/adapters/noticeRequestFlowAdapters';
import { getMessaggeError } from '../auth/utils';
import { AppDispatch } from '../store';
import {
  saveDataNegotation,
  setError,
  setLoading,
  setIsEdition,
  setDataRequestNegSuccess,
} from './noticeNegRequestSlice';

export const saveDriverNegRequestThunk = (
  idRequestType: string,
  body: NoticeNegotationRequestProps | NoticeExpressNegotationRequestProps
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      let { solicitudNegociacionData: {
        solicitudNegociacion
      } } = await requestNegotationServices(
        idRequestType,
        body
      );

      dispatch(
        setDataRequestNegSuccess({
          ...solicitudNegociacion,
          solicitud: {
            ...solicitudNegociacion.solicitud,
            vehiculo: solicitudNegociacion.vehiculo || {},
          }
        })
      );
      dispatch(setLoading(false));
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setLoading(false));
      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const updatePriceNegotationThunk = (
  idRequestType: string,
  body: BodyUploadPriceNegotationPrice
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const { solicitudNegociacionData } = await uploadPriceNegotation(
        idRequestType,
        body
      );

      dispatch(
        saveDataNegotation(solicitudNegociacionData.solicitudNegociacion)
      );
      dispatch(setIsEdition(false));
      dispatch(setLoading(false));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const getNegotationRequestThunk = (idRequestType: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const { solicitudNegociacionData } = await getNoticeResponseToClient(
        idRequestType
      );

      const { solicitudNegociacion } = solicitudNegociacionData;

      dispatch(saveDataNegotation(solicitudNegociacion));
      dispatch(setLoading(false));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

type BodyToNogotationByIdThunk = {
  idAccion: number;
  preciocontraoferta?: number;
  precioactual?: number;
};

export const saveRequestNogotationByIdThunk = (
  idRequestType: string,
  body: BodyToNogotationByIdThunk
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
      const { solicitudNegociacionData } = await putNoticeResponseToDriver(
        idRequestType,
        body
      );

      dispatch(
        saveDataNegotation(solicitudNegociacionData.solicitudNegociacion)
      );

      dispatch(setLoading(false));
      return solicitudNegociacionData.solicitudNegociacion;
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

// ==================================================================================================
//       THUNK PARA ELIMINAR SOLICITUD
// ==================================================================================================

export const removeNoticeRequest = (idRequestType: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
      const {
        solicitudData: { solicitud },
      } = await putRemoveNoticeRequest(idRequestType);

      dispatch(saveDataNegotation(solicitud));
      dispatch(setLoading(false));
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};
