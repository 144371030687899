import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import {
  ID_AUCTION_FLOW,
  ID_MOVING_FLOW,
  ID_MULTI_DESTINY,
} from "@/consts/defaultConsts";
import { optionOne, optionTwo } from "@/consts/noticeConsts";
import {
  DetailNoticeMoving,
  DetailsNegotationV2,
  NoticeDetailAuctionModel,
} from "@/models/notice/noticeV2NegotationModels";
import { priceAuctionComponentToRenderFinished } from "../../FinishedReject/views/AuctionReject/config";
import PriceMoving from "../../FinishedReject/views/MovingReject/PriceMoving";
import { priceMultidestinyComponentToRenderFinishedReject } from "../../FinishedReject/views/MultidestinyReject/config";
import { OfferPriceV2 } from "./components/OfferPriceV2";
import { CounterOfferPriceV2 } from "./components/CounterOfferPriceV2";
import { actionNegotationNameToId } from "@/pages/noticeV2Negotation/shared/config";
import CoCounterOffertPriceV2 from "./components/CoCounterOffertPriceV2";

interface Props {
  data: NoticeV2NegType;
}

const componentToRenderPrice: Record<
  number,
  ({ data }: { data: DetailsNegotationV2 }) => JSX.Element
> = {
  [actionNegotationNameToId["Transportista - Rechaza"]]: OfferPriceV2,
  [actionNegotationNameToId["Transportista - Rechaza Contraoferta"]]:
    CounterOfferPriceV2,
  [actionNegotationNameToId["Cliente - Rechaza Co-Contraoferta"]]:
    CoCounterOffertPriceV2,
};

const GlobalPriceFinishedReject = ({ data }: Props) => {
  const {
    idTipoAvisoNegociacion,
    idtipoaviso,
  } = data;

  if ((idtipoaviso as number) === ID_MOVING_FLOW)
    return <PriceMoving data={data as DetailNoticeMoving} />;

  if ((idtipoaviso as number) === ID_MULTI_DESTINY) {
    const PriceMultidestinyComponent =
      priceMultidestinyComponentToRenderFinishedReject[
        idTipoAvisoNegociacion || optionOne
      ];
    if (!PriceMultidestinyComponent) {
      return <></>;
    }
    return <PriceMultidestinyComponent data={data} />;
  }

  if ((idtipoaviso as number) === ID_AUCTION_FLOW) {
    const PriceAuctionComponent =
      priceAuctionComponentToRenderFinished[
        idTipoAvisoNegociacion || optionOne
      ];

    return <PriceAuctionComponent data={data as NoticeDetailAuctionModel} />;
  }

  const Component = componentToRenderPrice[idTipoAvisoNegociacion || optionOne];

  if (!Component) return <></>;

  return (
    <>
      <Component data={data as DetailsNegotationV2} />
    </>
  );
};

export default GlobalPriceFinishedReject;
