import { styled, Typography } from "@mui/material";
import { CSSProperties } from "react";
import ConditionsList from "./ConditionsList";

const Container = styled("div")(
  () => `
    background: rgba(33, 150, 243, 0.12);
    border-radius: 8px;
    padding: 16px;
  `
);

const Title = styled(Typography)(
  () => `
    margin-bottom: 5px; 
    display: block;
  `
);

type Props = {
  listServices: any[];
  title: string;
  show: boolean;
  style?: CSSProperties;
};

const ConditionsListOffert = ({
  listServices,
  title,
  style,
  show = true,
}: Props) => {
  if (!show) return <></>;
  return (
    <Container style={{ width: "100%", ...style }}>
      <Title variant="subtitle1medium"> {title} </Title>
      <ConditionsList listServices={listServices} />
    </Container>
  );
};

export default ConditionsListOffert;
