import { agreementClosedText } from "@/consts/noticeConsts";
import { DetailsNegotationV2 } from "@/models/notice/noticeV2NegotationModels";
import { currencyFormat } from "@/utils/helpers";
import { Typography } from "@mui/material";

export const OfferPriceV2 = ({ data }: { data: DetailsNegotationV2 }) => {
  const { precio, preciocontraoferta } = data;
  return (
    <Typography
      sx={{ pt: 2, fontWeight: 700, fontSize: "22px", textAlign: "revert" }}
    >
     {agreementClosedText}: {currencyFormat(
        preciocontraoferta || precio || 0
      )}
    </Typography>
  );
};
