import LayoutFlow from "@/components/LayoutFlow/LayoutFlow";
import SkeletonNoticeList from "@/pages/loading/SkeletonNoticeList";
import HeaderFlow from "@/pages/user/components/HeaderFlow";
import NoticeListItems from "./components/NoticeListItems";
import useNoticeListHook from "./hook";
import {
  ContainerHeader,
  ContainerItems,
  ContainerItemsRegister,
  ContainerNotice,
  SubTitle,
  Title,
} from "./style";

const NoticeList = () => {
  const {
    loading,
    header,
    noticeList,
    handleClick,
    isRegisterDriver,
    handleBackNavigate,
  } = useNoticeListHook();

  const ContainerBody = () => {
    if (!Object.keys(noticeList).length || loading) {
      return <SkeletonNoticeList />;
    } else {
      return (
        <ContainerItems>
          <NoticeListItems
            listItems={!loading ? noticeList : []}
            onClick={handleClick}
          />
        </ContainerItems>
      );
    }
  };

  return isRegisterDriver ? (
    <LayoutFlow>
      <HeaderFlow title="Primeros avisos" customGoBack={handleBackNavigate} />
      <SubTitle
        sx={{
          marginBottom: "16px",
        }}
        variant="body1"
      >
        {header.subTitle}
      </SubTitle>
      <ContainerItemsRegister>
        <NoticeListItems
          listItems={!loading ? noticeList : []}
          onClick={handleClick}
          isRegisterDriver={true}
        />
      </ContainerItemsRegister>
    </LayoutFlow>
  ) : (
    <ContainerNotice>
      <ContainerHeader>
        <Title variant="h6">{header.title}</Title>
        <SubTitle variant="body1">{header.subTitle}</SubTitle>
      </ContainerHeader>
      <ContainerBody />
    </ContainerNotice>
  );
};

export default NoticeList;
