import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { ERROR_NEGOTATION_CLOSED } from "@/consts/textErrors";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { defaultError } from "@/pages/noticeRequestFlow/consts/noticeRequestFlowConsts";
import { useButtonsFunctions } from "@/pages/noticeRequestFlow/hooks/useHandleGlobalButtons";
import { setError } from "@/store/noticeNegotationRequestRedux/noticeNegRequestSlice";
import { useEffect } from "react";
import {
  ContainerButtons
} from "./StylesGlobalButtonsNotifications";

const OfferGlobalButtons = () => {
  const {handleManageSnackbar, handleManageModalOffer, handleAcceptButton, handleDisabledButtons} = useButtonsFunctions()
  const {notiffication } = useNotiffication();
  const dispatch = useAppDispatch();
  const {error} = useAppSelector((state) => state.noticeNegotationRequest);
  const disableButtons = handleDisabledButtons()
  const negotationClosed = error === ERROR_NEGOTATION_CLOSED

  useEffect(() => {
    if (error && !negotationClosed) {
      handleManageSnackbar();
    }
  }, [error])

  useEffect(() => {
    if(!notiffication && !negotationClosed){
      dispatch(setError(defaultError));
    }
  }, [notiffication])
  


  return (
    <ContainerButtons>
      <ButtomLoader
        fullWidth
        variant="contained"
        onClick={handleAcceptButton}
        disabled={!disableButtons.disableAcceptButton || negotationClosed}
      >
        ACEPTAR
      </ButtomLoader>

      <ButtomLoader
        fullWidth
        variant="text"
        onClick={handleManageModalOffer}
        disabled={!disableButtons.disableDeclineButton || negotationClosed}
      >
        RECHAZAR
      </ButtomLoader>
    </ContainerButtons>
  );
};

export default OfferGlobalButtons;
