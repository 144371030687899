import InfoAppSection from "@/pages/landing/components/InfoAppSection";
import { useMediaQuery, useTheme } from "@mui/material";
import useCarrierHook from "../../hook";
import SectionApp from "../SectionApp";
import SectionCustom from "../SectionCustom";
import { textAPPDriver } from "../..";

const NotUserLand = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const { data, onClickButton } = useCarrierHook();

  return (
    <>
      <SectionCustom
        data={{
          ...data.section,
        }}
        onClickButton={onClickButton}
      />

      {matches ? <InfoAppSection text={textAPPDriver} /> : <SectionApp />}
    </>
  );
};

export default NotUserLand;
