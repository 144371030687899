// ==================================================
//         Textos de errores de formularios
// ==================================================
export const MIN_LOAD = 10;
export const TEXT_ERROR_MIN_LOAD = `El peso mínimo no puede ser menor a ${MIN_LOAD} kilos`;
export const TEXT_ERROR_MAX_LOAD =
  'El peso máximo no puede exceder el peso configurado.';

export const TEXT_ERROR_MAX_LOAD_V2 =
  'La capacidad a rematar no debe ser mayor a la capacidad del vehículo.';
// ==================================================
//         error de precios
// ==================================================
export const MIN_PRICE = 10;
export const TEXT_ERROR_MIN_PRICE = `El precio mínimo no puede ser menor a ${MIN_PRICE}.`;
export const TEXT_ERROR_MAX_PRICE =
  'El precio máximo no puede ser menor al mínimo.';
export const TEXT_ERROR_MAX_PRICE_LOAD =
  'El precio de remate no puede ser mayor al precio regular.';

export const TEXT_ERRER_MIN_PERCENTAGE = 'Mínimo 20% menos del precio regular.';
export const TEXT_ERROR_NO_BE_EQUAL =
  'El origen y destino no pueden ser iguales.';

export const TEXT_ERROR_MIN_FIVE_PERCENTAGE =
  'La capacidad a rematar debe ser mínimo 5% de la capacidad del vehículo.';
// ==================================================

// ==================================================
//                   Errors Images
// ==================================================
export const TEXT_ERROR_IMAGE_GENERIC =
  'Intenta con otro archivo que cumpla con el formato (ej. jpg, png) y tamaño permitido (hasta 10MB).';

export const DEFAULT_ERROR_LOAD_IMAGE = {
  message: 'Error: No se pudo cargar la imagen',
  name: 'undefined',
};
// ==================================================
//                   Erros Forms Offerts
// ==================================================
export const REQUIRED_DESCRIPTION_LOAD = 'Ingrese una descripción.';
export const REQUIRED_WEIGHT_LOAD = 'Ingresa el peso aproximado de la carga.';

export const MAX_CHARRACTERS = 200;

export const ERROR_DESCRIPTION_MAX_CHARACTER = `Máximo ${MAX_CHARRACTERS} caracteres.`;
export const ERROR_WEIGHT_LOAD_MAX_CHARACTER = `El peso máximo no puede exceder el peso del aviso (máx :MAX kilos).`;
export const ERROR_WEIGHT_LOAD_NEGATIVE = `El peso no puede ser negativo.`;

export const ERROR_WEIGHT_LOAD_MIN =
  'El peso del remate debe ser mínimo el 20% del peso del aviso.';
export const ERROR_WEIGHT_LOAD_MAX =
  'El peso del remate no debe ser mayor al peso del aviso.';
// ==================================================
//            Erros de la Negociacion solicitud
// ==================================================

export const ERROR_NEGOTATION_CLOSED = 'Negociación de la solicitud cerrada';
export const ERROR_NOTICE_CLOSED = 'La negociación ya está cerrada';

export const ERROR_IMAGE_INAPPROPIATE =
  'La foto que subiste tiene contenido inapropiado. Por favor usa otra.';

export const ERROR_EQUAL_DESTINY =
  'El origen y el destino no pueden ser iguales.';

// ==================================================
//               Errores de avisos
// ==================================================
export const ERROR_CANT_OFFER =
  'No es posible ofertar a este aviso porque te pertenece';
