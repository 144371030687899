import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { currencyFormat } from "@/utils/helpers";
import { Box, Typography } from "@mui/material";
import { CoCounterOfferPrice } from "./CoCounterOfferGlobalPrice";

export const GeneralCoCounterOfferPrice = () => {
    const { notice } = useGetData();
    const newData = notice as NoticeV2NegType

  return (
    <>
      
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontSize: "16px" }}>
          {`Precio del aviso:`}
        </Typography>
        <Typography sx={{ fontSize: "16px", ml: 1 }}>
          {`${currencyFormat(Number(newData?.aviso.precio))}`}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontSize: "16px" }}>
        {`Tu oferta:`}
        </Typography>
        <Typography sx={{ fontSize: "16px", ml: 1 }}>
          {currencyFormat(newData.precio as number)}
        </Typography>
      </Box>
      <CoCounterOfferPrice/>
    </>
  );
};
