import {
  translateDataToEnglish,
  translateDataToSpanish,
  validateSpanishData,
  validateEnglishData,
} from '@/pages/flowNoticeNegotation/shared/const/default';
import {
  DataResponseNoticeSpanish,
  Foto,
} from '@/services/noticeServices/NoticeClientAcceptOrCounterOffer/noticeServiceDataResponseType';
import {
  DataResponseEnglishTypeTwo,
  Photo,
} from '../interfaces/dataResponseEnglishInterfaces';

export enum TypeFlow {
  inCity,
  betweenCities,
  multidestiny,
  movingInCity,
  movingBetweenCities,
  quoteInCities,
}

export enum TypeNegotation {
  accept,
  counterOffer,
}

export interface AdapterOptions {
  typeflow: TypeFlow;
  typeNegotation: TypeNegotation;
}

interface translateToEnglish {
  id: number;
  negotiationNoticeId: number;
  imageUrl: string;
}
interface translateToSpanish {
  id: number;
  idAvisoNegociacion: number;
  urlImagen: string;
}

export enum translateTo {
  spanish,
  english,
}

interface OptionsProps {
  tranlateData: Record<string, string>;
  photoValuecompare: 'fotos' | 'photos';
  photosPropertyName: 'fotos' | 'photos';
  validateData: Record<string, string>;
  function: (
    photos: Photo[] | Foto[]
  ) => translateToEnglish[] | translateToSpanish[];
}

const translatePhotosToEnglish = (
  photos: Photo[] | Foto[]
): translateToEnglish[] => {
  const newPhotos = photos as Foto[];
  const value = newPhotos.map((item, i) => ({
    id: item.id,
    negotiationNoticeId: item.idAvisoNegociacion,
    imageUrl: item.urlImagen,
  }));

  return value;
};

const translatePhotosToSpanish = (
  photos: Photo[] | Foto[]
): translateToSpanish[] => {
  const newPhotos = photos as Photo[];
  const value = newPhotos.map((item, i) => ({
    id: item.id,
    idAvisoNegociacion: item.negotiationNoticeId,
    urlImagen: item.imageUrl,
  }));

  return value;
};

export const translateData = (
  data: DataResponseNoticeSpanish | DataResponseEnglishTypeTwo,
  translate: translateTo
) => {
  const newData: Record<string, any> = data;
  const properties = Object.keys(data) as string[];
  let options = optionsToSpanish as OptionsProps;

  if (translate === translateTo.english) {
    options = optionsToEnglish as OptionsProps;
  }

  const result = properties.reduce((acc, el) => {
    if (
      options.validateData[el] === options.photoValuecompare &&
      !!newData[el].length
    ) {
      const photosTranslated = options.function(
        newData[el] as Photo[] | Foto[]
      );
      acc = { ...acc, [options.photosPropertyName]: photosTranslated };
    } else {
      acc = { ...acc, [options.tranlateData[el]]: newData[el] };
    }

    return acc;
  }, {});

  return result;
};

const optionsToSpanish = {
  tranlateData: translateDataToSpanish,
  photoValuecompare: 'photos',
  photosPropertyName: 'fotos',
  validateData: validateEnglishData,
  function: translatePhotosToSpanish,
};

const optionsToEnglish = {
  tranlateData: translateDataToEnglish,
  photoValuecompare: 'fotos',
  photosPropertyName: 'photos',
  validateData: validateSpanishData,
  function: translatePhotosToEnglish,
};
