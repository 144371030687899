import { MY_PENDING_AGREEMENTS } from '@/consts/typeServicesPaths';
import useGetData from '@/pages/noticeV2Negotation/shared/hooks/useGetData';
import { NoticeV2NegType } from '@/store/noticeV2Negotiation/noticeV2NegotiationSlice';
import { useMediaQuery, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
const useRejectLayoutHook = () => {
  const navigate = useNavigate();
  const { notice } = useGetData();
  const { pathname } = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const isCounterOffer = pathname.includes('contraoferta');

  const { flgIsOwner } = notice as NoticeV2NegType;

  const handleHeaderProps = () => {
    if (isCounterOffer) {
      return rejectCounterOfferData;
    }

    return RejectAcceptData;
  };

  const handleNavigationBack = () => {
    navigate(-1);
  };
  const handleNavigation = () => {
    // home
    navigate('/', { replace: true });
  };

  const handleNevigateOwner = () => {
    navigate(MY_PENDING_AGREEMENTS, { replace: true });
  };

  return {
    theme,
    matches,
    handleHeaderProps,
    notice,
    handleNavigationBack,
    flgIsOwner,
    handleNavigation,
    handleNevigateOwner,
  };
};

const RejectAcceptData = {
  title: '¡No aceptaron el servicio!',
  icon: 'icon-sentimentVeryDissatisfied',
};

const rejectCounterOfferData = {
  title: '¡No aceptaron tu servicio!',
  icon: 'icon-sentimentVeryDissatisfied',
};

export default useRejectLayoutHook;
