import { useNavigate } from "react-router-dom";
import { HeaderSection, Icon, Title } from "./styleLoginRegister";

type Props = {
  title: string;
  arrowBack?: boolean;
  customGoBack?: () => void;
};

const HeaderFlow = (props: Props) => {
  const { title, arrowBack = true, customGoBack } = props;

  const navigate = useNavigate();

  const handleGoback = () => {
    if (customGoBack) {
      customGoBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <HeaderSection>
      {arrowBack ? (
        <Icon onClick={handleGoback}>
          <i className="icon-arrow-left--primary" />
        </Icon>
      ) : (
        <></>
      )}
      <Title>{title}</Title>
    </HeaderSection>
  );
};

export default HeaderFlow;
