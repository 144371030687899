import { NOTICE_NOT_FOUND } from "@/consts/defaultConsts";
import { HOME_PAHT } from "@/consts/typeServicesPaths";
import NotFound from "@/pages/notFound/NotFound";
import React, { Component, ErrorInfo } from "react";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <NotFound
          title="¡Ups algo salio mal!"
          description="Lo sentimos, algo salio mal."
          buttonText="Ir al inicio"
          buttonLink={HOME_PAHT}
          image={NOTICE_NOT_FOUND}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
