import { ContainerHeader } from "@/pages/flowNoticeNegotation/styles/styles";
import Icon, { IconColor, IconSize, IconTypes } from "@/utils/Icon";
import { Container, Stack, SxProps, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";

export type Props = {
  title: string;
  icon: IconTypes;
  iconColor?: IconColor;
  sxHeader?: SxProps;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  sxTitle?: SxProps;
};

const HeaderDetails = (props: Props) => {
  const {
    title,
    icon,
    iconColor = "info",
    sxHeader = {},
    maxWidth = "xs",
    sxTitle = {},
  } = props;

  return (
    <Container maxWidth={maxWidth} sx={{ marginTop: "16px" }}>
      <ContainerHeader
        sx={{
          ...sxHeader,
        }}
      >
        <Stack>
          <Icon name={icon as IconTypes} color={iconColor} size="xl" />
        </Stack>
        <Stack
          marginLeft="16px"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...sxTitle,
          }}
        >
          <Typography variant="h6">{title}</Typography>
        </Stack>
      </ContainerHeader>
    </Container>
  );
};

export default HeaderDetails;
