import { REQUESTS_LANDING_PATH } from '@/consts/typeServicesPaths';
import { NEGOTATION_CLIENT_PATH } from '../../../../consts/negotationFlowPath';
export const CUSTOMER_PATH = '/clientes';
export const CREATE_CUSTOMER_REQUEST_PATH = `${CUSTOMER_PATH}/crear-solicitud`;

export const MY_APPLICATIONS_CUSTOMER_REQUEST_PATH = NEGOTATION_CLIENT_PATH;
// :typeRequest
export const TYPE_REQUEST_PATH = `:typeRequest`;
export const CUSTOMER_TYPE_REQUEST_PATH = `${CREATE_CUSTOMER_REQUEST_PATH}/${TYPE_REQUEST_PATH}`;
// ==================================================
//                   Tipo de solicitudes
// ==================================================
export const REQUEST_IN_CITY = 'en-la-ciudad';
export const REQUEST_MULTI_DESTINY = 'multidestino';
export const REQUEST_BETWEEN_CITIES = 'entre-ciudades';
export const REQUEST_EXPRESS = 'express';
export const REQUEST_MOVING = 'mudanza';

export const APPLICATION_DETAIL_PATH = `${REQUESTS_LANDING_PATH}/${TYPE_REQUEST_PATH}/:id`;
export const MY_APPLICATIONS_PATH = `${MY_APPLICATIONS_CUSTOMER_REQUEST_PATH}/${TYPE_REQUEST_PATH}/:id`;
export const TYPE_FLOW = `${CUSTOMER_TYPE_REQUEST_PATH}/tipo`;
/* {URL} / mis-solicitudes / oferta / {id} / acuerdo-calificacion-transportista */
export const NEGOTATION_OFFERT_AGREEMENT_TRANSPORTER = `${MY_APPLICATIONS_CUSTOMER_REQUEST_PATH}/oferta/:idOffert/acuerdo-calificacion-transportista`;
/* {URL} / solicitudes / oferta / {id} / acuerdo-calificacion-cliente */
export const NEGOTATION_OFFERT_AGREEMENT_CLIENT = `${REQUESTS_LANDING_PATH}/oferta/:idOffert/acuerdo-calificacion-cliente`;

// calificacion-confirmacion
export const NEGOTATION_OFFERT_AGREEMENT_TRANSPORTER_CONFIRMATION = `${MY_APPLICATIONS_CUSTOMER_REQUEST_PATH}/oferta/:idOffert/calificacion-confirmacion`;
export const NEGOTATION_OFFERT_AGREEMENT_CLIENT_CONFIRMATION = `${REQUESTS_LANDING_PATH}/oferta/:idOffert/calificacion-confirmacion`;

// flujos de solicitudes

export const REQUEST_MAIN_RUTA_PATH = `${CUSTOMER_TYPE_REQUEST_PATH}/ruta`;
export const REQUEST_MAIN_CARGA_PATH = `${CUSTOMER_TYPE_REQUEST_PATH}/carga`;
export const REQUEST_MAIN_CONDICIONES_PATH = `${CUSTOMER_TYPE_REQUEST_PATH}/condiciones`;
export const REQUEST_MAIN_PRECIO_PATH = `${CUSTOMER_TYPE_REQUEST_PATH}/precio`;
export const REQUEST_MAIN_PRECIO_POR_HORA_PATH = `${CUSTOMER_TYPE_REQUEST_PATH}/precio-horas`;
export const REQUEST_MAIN_PRECIO_POR_PUNTO_PATH = `${CUSTOMER_TYPE_REQUEST_PATH}/precio-puntos`;
export const REQUEST_MAIN_SOLICITUD_CREADA_PATH = `${CUSTOMER_TYPE_REQUEST_PATH}/solicitud-creada`;
// moving
export const REQUEST_MAIN_FECHA_PATH = `${CUSTOMER_TYPE_REQUEST_PATH}/fecha`;
export const REQUEST_MAIN_MOVING_ORIGEN_PATH = `${CUSTOMER_TYPE_REQUEST_PATH}/origen`;
export const REQUEST_MAIN_MOVING_DESTINO_PATH = `${CUSTOMER_TYPE_REQUEST_PATH}/destino`;
export const REQUEST_MAIN_MOVING_DETALLES_PATH = `${CUSTOMER_TYPE_REQUEST_PATH}/detalles`;
// express
export const REQUEST_MAIN_EXPRESS_SEARCH_PATH = `${CUSTOMER_TYPE_REQUEST_PATH}/busqueda`;
export const REQUEST_MAIN_EXPRESS_CANCEL_PATH = `${CUSTOMER_TYPE_REQUEST_PATH}/solicitud-cancelada`;

export const REQUEST_IN_CITY_FLOW_PATHS = [
  REQUEST_MAIN_RUTA_PATH,
  REQUEST_MAIN_CARGA_PATH,
  REQUEST_MAIN_CONDICIONES_PATH,
  REQUEST_MAIN_PRECIO_PATH,
  REQUEST_MAIN_SOLICITUD_CREADA_PATH,
];

// Multidestinos

export const REQUEST_MULTIDESTINY_PATHS = [
  REQUEST_MAIN_RUTA_PATH,
  REQUEST_MAIN_CARGA_PATH,
  REQUEST_MAIN_CONDICIONES_PATH,
  REQUEST_MAIN_PRECIO_POR_HORA_PATH,
  REQUEST_MAIN_SOLICITUD_CREADA_PATH,
];

/* export const REQUEST_MULTIDESTINY_TOPOINT_FLOW_PATHS = [
  TYPE_FLOW,
  REQUEST_MAIN_RUTA_PATH,
  REQUEST_MAIN_CARGA_PATH,
  REQUEST_MAIN_CONDICIONES_PATH,
  REQUEST_MAIN_PRECIO_POR_PUNTO_PATH,
  REQUEST_MAIN_SOLICITUD_CREADA_PATH,
]; */

// Mudanza

export const REQUEST_MOVING_IN_CITY_FLOW_PATHS = [
  TYPE_FLOW,
  // REQUEST_MAIN_FECHA_PATH,
  REQUEST_MAIN_MOVING_ORIGEN_PATH,
  REQUEST_MAIN_MOVING_DETALLES_PATH,
  REQUEST_MAIN_MOVING_DESTINO_PATH,
  REQUEST_MAIN_CONDICIONES_PATH,
  REQUEST_MAIN_SOLICITUD_CREADA_PATH,
];

export const REQUEST_MOVING_BETWEEN_CITIES_FLOW_PATHS = [
  TYPE_FLOW,
  // REQUEST_MAIN_FECHA_PATH,
  REQUEST_MAIN_MOVING_ORIGEN_PATH,
  REQUEST_MAIN_MOVING_DETALLES_PATH,
  REQUEST_MAIN_MOVING_DESTINO_PATH,
  REQUEST_MAIN_CONDICIONES_PATH,
  REQUEST_MAIN_SOLICITUD_CREADA_PATH,
];

// Express

export const REQUEST_EXPRESS_FLOW_PATHS = [
  REQUEST_MAIN_RUTA_PATH,
  REQUEST_MAIN_CARGA_PATH,
  REQUEST_MAIN_EXPRESS_SEARCH_PATH,
];

// ==================================================
//                   Offerts
// ==================================================

export const OFFERT_CUSTOMER_REQUEST_PATH = `${MY_APPLICATIONS_CUSTOMER_REQUEST_PATH}/${TYPE_REQUEST_PATH}/:id/ofertas`;
export const OFFERT_CUSTOMER_REQUEST_HISTORY_PATH = `${MY_APPLICATIONS_CUSTOMER_REQUEST_PATH}/${TYPE_REQUEST_PATH}/:id/historial-ofertas`;

// ==================================================
//                   profile - mis solicitudes a clientes
// ==================================================

export const MY_OFFERTS_CUSTOMER_REQUEST_PATH = `${REQUESTS_LANDING_PATH}/mis-ofertas`;
