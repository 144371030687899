import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  BoxContainer,
  BoxInfo,
  ContainerQr,
  ContentBox,
  HeaderBox,
  ImgQrBox,
  SectionAppContainer,
  SectionOneBox,
  SectionTwoBox,
  TypographyInfo,
  TypographyTextExpress,
  TypographyTitleText,
} from "./sectionStyled";
import "./styles.css";
import QRAPP from "@/img/landing/qr.png";

const SectionApp = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matchesMiddle = useMediaQuery("(max-width:1290px)");
  const matches2 = useMediaQuery("(max-width:1088px)");
  const matches6 = useMediaQuery("(max-width:920px)");
  const matches7 = useMediaQuery("(max-width:768px)");

  const ImgResponsive = () => {
    if (matches6)
      return { image: "icon-app_phone_lg", width: 246, heigth: 260 };
    return { image: "icon-app_phone", width: 400, heigth: 416 };
  };

  return (
    <SectionAppContainer>
      <BoxContainer maxWidth={"xl"}>
        <SectionOneBox matches={matchesMiddle}>
          <HeaderBox matches={matches}>
            <TypographyTitleText>Descarga PegaCargo</TypographyTitleText>

            <TypographyTextExpress>Express</TypographyTextExpress>
          </HeaderBox>
          <ContentBox matches6={matches6} matches7={matches7}>
            <Typography sx={{ fontSize: "16px", marginTop: "8px" }}>
            Consigue clientes cerca a ti de manera inmediata.
            </Typography>
          </ContentBox>
          <ContainerQr matches={matchesMiddle}>
            <Box>
              <ImgQrBox>
                {/* <i
                  style={{ width: "150px", height: "150px" }}
                  className="app_qr"
                /> */}
                <img
                  width={"150"}
                  height={"150"}
                  src={QRAPP}
                  alt="qr de la tienda de aplicaciones"
                />
              </ImgQrBox>
            </Box>
            <BoxInfo

            >
              <TypographyInfo sx={{ marginBottom: "5px", fontSize: "16px" }}>
                Escanea el código QR con la cámara de tu celular.
              </TypographyInfo>

              <Box
                sx={{
                  display:
                    "flex" /* , flexDirection: matches2 ? "column" : "row" */,
                }}
              >
                <i
                  style={{ width: "136px", height: "40px", marginRight: "5px" }}
                  className="icon-google_play"
                />
                <i
                  style={{ width: "120px", height: "40px" }}
                  className="icon-app_store"
                />
               {/*  <i
                  style={{ width: "135px", height: "40px", marginLeft: "5px" }}
                  className="icon-app_gallery"
                /> */}
              </Box>
            </BoxInfo>
          </ContainerQr>
        </SectionOneBox>

        <SectionTwoBox matches2={matches2} matchesMiddle={matchesMiddle}>
          <i
            style={{
              width: ImgResponsive().width,
              height: ImgResponsive().heigth,
              marginLeft: "10px",
            }}
            className={ImgResponsive().image}
          />
        </SectionTwoBox>
      </BoxContainer>
    </SectionAppContainer>
  );
};

export default SectionApp;
