import VisibilityIcons from "@/pages/user/components/VisibilityIcons";
import { theme } from "@/styles/theme";
import {
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  styled,
  experimental_sx as sx,
} from "@mui/material";
import { useState } from "react";
import LabelRequired from "../utils/LabelRequired";

export const StyleCustomInput = styled(TextField)<TextFieldProps>(
  sx({
    width: "100%",
    "& .MuiFormLabel-root": {
      fontFamily: theme.typography.label3.fontFamily,
      fontSize: "16px",
      fontWeight: theme.typography.label3.fontWeight,
      lineHeight: theme.typography.label3.lineHeight,
      color: "rgba(0, 0, 0, 0.6)",
    },
    "& .MuiInputBase-input::placeholder": {
      fontFamily: theme.typography.placeholder.fontFamily,
      fontStyle: theme.typography.placeholder.fontStyle,
      fontSize: theme.typography.placeholder.fontSize,
      lineHeight: theme.typography.placeholder.lineHeight,
      fontWeight: theme.typography.placeholder.fontWeight,
      letterSpacing: theme.typography.placeholder.letterSpacing,
    },
    "& .MuiFormHelperText-root": {
      letterSpacing: 0,
    },
  })
);

interface IProps {
  label?: string;
  required?: boolean;
  name: string;
  placeholder?: string;
  errors?: any;
  type?: string;
  props?: any | undefined;
  inputMode?: {};
  sx?: SxProps<Theme>;
  value: any;
}

const InputCustom = ({
  required,
  name,
  placeholder,
  errors,
  type,
  sx,
  value,
  label = "",
  inputMode = {},
}: IProps) => {
  const [showPassword, setShowPassword] = useState(type);

  return (
    <>
      <StyleCustomInput
        label={required ? <LabelRequired str={label} /> : label}
        name={name}
        value={value}
        variant="standard"
        placeholder={placeholder}
        type={showPassword || "text"}
        autoComplete="off"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          ...inputMode,
        }}
        sx={sx}
      />
      {type === "password" && (
        <VisibilityIcons
          onChangeVisible={() =>
            setShowPassword(showPassword ? "" : "password")
          }
        />
      )}
    </>
  );
};

export default InputCustom;
