import { useAppSelector } from "@/hooks/useReducer";
import { DoneOutlineOutlined } from "@mui/icons-material";
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ItemProps } from "../../type";
import {
  CustomButton,
  CustomSecondTitle,
  GridContainerSection,
  stylesEdge,
} from "./sectionCustomStyles";

interface Props {
  onClickButton: (link: string) => void;
  items: ItemProps;
}

const Section = (props: Props) => {
  const { onClickButton } = props;
  const { icon, IconSvg, items, title, buttonText, rowReverse, btnLink } =
    props.items;
  const { user } = useAppSelector((state) => state.user);
  const isLogout = Object.keys(user).length === 0;
  const matches = useMediaQuery("(max-width:800px)");

  const handleBackground = (title: string) => {
    let value = {};

    if (title === "te están buscando") value = { backgroundColor: "#FAFAFA" };

    return value;
  };

  const hiddeSvg = () => {
    let value = true;
    if (matches && isLogout) value = false;
    return value;
  };

  const newStyles = handleBackground(title);
  return (
    <Grid container sx={{ ...newStyles }}>
      <Container maxWidth="xl">
        <GridContainerSection
          container
          direction={{
            xl: rowReverse ? "row-reverse" : "row",
            lg: rowReverse ? "row-reverse" : "row",
          }}
          gap={"24px"}
          flexWrap={{
            xs: "wrap",
            md: "wrap",
            xl: "nowrap",
            lg: "nowrap",
          }}
        >
          {hiddeSvg() && (
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                margin: "0px auto",
                width: {
                  xs: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "550px",
                },
              }}
            >
              <IconSvg />
            </Stack>
          )}

          <Stack direction={"column"} gap={"16px"} width="100%">
            {title && (
              <CustomSecondTitle
                variant="h5"
                sx={{ textAlign: { xs: "center", md: "left" } }}
              >
                {title}
              </CustomSecondTitle>
            )}
            {items &&
              items.map((item: string, i: number) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                  key={i}
                >
                  {icon && (
                    <DoneOutlineOutlined
                      sx={{
                        color: "#4CAF50",
                        fontSize: "20px",
                      }}
                    />
                  )}
                  <Typography variant="body1" color={"#424242"}>
                    {item}
                  </Typography>
                </Box>
              ))}
            <CustomButton
              variant="contained"
              color="org"
              sx={{
                width: {
                  xs: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "240px",
                },
              }}
              onClick={() => onClickButton && onClickButton(btnLink)}
            >
              {buttonText}
            </CustomButton>
          </Stack>
        </GridContainerSection>
      </Container>
    </Grid>
  );
};

export default Section;
