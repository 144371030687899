import { FORMAT_DATE_YYYYMMDD } from '@/consts/formatsDate';
import { FilterServicesSendProps } from '@/interfaces/noticeInterface';
import { UsuarioAPI } from '@/models/userAuth/user';
import {
  IOrdenamiento,
  ISelectCity,
  TiposFiltro,
} from '@/store/FilterServices/filterReduxInterface';
import dayjs from 'dayjs';
import queryString from 'query-string';
export const handleDeleteValue = async (
  value: string | string[],
  array: TiposFiltro[],
  isNombre?: boolean
): Promise<TiposFiltro[]> => {
  // search value in array on opciones property and return a new array without that value
  const ArrayCopy = structuredClone(array);
  let newArray = ArrayCopy.map((item: any) => {
    if (item.opciones.includes(value)) {
      const index = item.opciones.indexOf(value);
      item.opciones.splice(index, 1);
    }
    if (item.nombre === value && isNombre) {
      item.opciones = [];
    }
    return item;
  });
  //   if the object has no more options, delete it
  newArray = newArray.filter((item: any) => item.opciones.length > 0);

  return newArray;
};

export const handleAddAndDeleteValue = (
  value: string | string[],
  tipo: number,
  selected: string,
  array: TiposFiltro[]
): TiposFiltro[] => {
  const index = array.findIndex((item) => item.nombre === selected);

  if (tipo === 1 && typeof value === 'string') {
    if (index !== -1) {
      array[index].opciones = [value];
    } else {
      array.push({
        nombre: selected,
        opciones: [value],
      });
    }
  }
  if (tipo === 2 && typeof value === 'string') {
    if (index !== -1) {
      const indexOption = array[index].opciones.findIndex(
        (item) => item === value
      );
      if (indexOption !== -1) {
        array[index].opciones.splice(indexOption, 1);
      } else {
        array[index].opciones.push(value);
      }
    } else {
      array.push({
        nombre: selected,
        opciones: [value],
      });
    }
  }
  // array
  if (tipo === 3 && Array.isArray(value)) {
    if (index !== -1) {
      array[index].opciones = value;
    } else {
      array.push({
        nombre: selected,
        opciones: value,
      });
    }
  }

  if (array[index]?.opciones?.length === 0 && tipo === 2) {
    array.splice(index, 1);
  }

  return array;
};

export const convertArrayToObjectString = (array: TiposFiltro[]): string => {
  const filter = array.reduce((acc: any, item) => {
    acc[item.nombre] = item.opciones.join('|');
    return acc;
  }, {});
  return queryString.stringify(filter);
};

export const filterServicesSend = (data: FilterServicesSendProps) => {
  const { page, size, ordenamiento, tiposFiltro, city } = data;

  const ordenamientoArray = ordenamiento.opciones[0].split(',') as
    | string[]
    | [];
  let newFilter = structuredClone(tiposFiltro);

  let defaultFilter: TiposFiltro[] = [
    {
      nombre: 'Tipo de estado',
      opciones: ['Activo'],
    },
    {
      nombre: 'OrigenCiudad',
      opciones: [city?.nombre || 'Lima'],
    },
    {
      nombre: 'Fecha de aviso',
      opciones: [
        dayjs(new Date()).format('YYYY-MM-DD'),
        dayjs(new Date()).format('YYYY-MM-DD'),
      ],
    },
  ];

  // si en el newFilter existe uno de los defaultFilter, se elimina del defaultFilter para que no se envie , pero si no existe se agrega al newFilter
  defaultFilter.forEach((item) => {
    const index = newFilter.findIndex(
      (item2: any) => item2.nombre === item.nombre
    );
    if (index !== -1) {
      defaultFilter.splice(index, 1);
    } else {
      newFilter.push(item);
    }
  });

  return {
    page,
    size,
    ordenamiento: ordenamientoArray,
    tiposFiltro: newFilter,
  };
};

export const handleDate = (type: number): string[] => {
  // locale dayjs for spanish
  dayjs.locale('es');

  let fechaStart: any;
  let dateFin: any;
  if (type === 1) {
    fechaStart = dayjs(new Date());
    dateFin = dayjs(new Date());
  }
  if (type === 2) {
    fechaStart = dayjs(new Date()).add(1, 'day');
    dateFin = dayjs(new Date()).add(1, 'day');
  }
  if (type === 3) {
    // solo hasta el domingo de la semana actual
    fechaStart = dayjs(new Date());
    dateFin = dayjs(new Date()).endOf('weeks');
  }
  if (type === 4) {
    //  el dia lunes de la proxima semana.
    fechaStart = dayjs(new Date()).add(1, 'week').startOf('week');
    // // el domingo de la proxima semana
    dateFin = dayjs(new Date()).add(1, 'week').endOf('week');
  }
  if (type === 5 || type === 6) {
    fechaStart = dayjs(new Date());
    dateFin = dayjs(new Date()).endOf('month');
  }
  return [fechaStart, dateFin];
};

// [
//   "Fri, 11 Nov 2022 17:10:04 GMT",
//   "Thu, 01 Dec 2022 04:59:59 GMT"
// ]

export const handleDateRange = (date: string[]): string[] => {
  const dateStart = dayjs(date[0]).format(FORMAT_DATE_YYYYMMDD);
  const dateEnd = dayjs(date[1]).format(FORMAT_DATE_YYYYMMDD);
  return [dateStart, dateEnd];
};

export const defaultTipoAviso = (tipoAviso: string) => {
  return [
    {
      nombre: 'Tipo de aviso',
      opciones: [tipoAviso],
    },
  ];
};
