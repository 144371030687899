import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import LabelRequired from "@/components/utils/LabelRequired";

import { FormControl, Grid, styled } from "@mui/material";
import { useFormClientCounterOfferInCity } from "./useFormClientCounterOfferInCity";

const Container = styled(Grid)(
  () => `
    display: flex;
    flex-direction: column;  
    margin-top: 24px;
  `
);

export const FormClientCounterOfferInCityEdition = () => {
  const {
    isLoading,
    preciocontraoferta,
    handleOnchange,
    handleDisabledEditCounterOfferButton,
    handleEditCounterOfferSubmit,
  } = useFormClientCounterOfferInCity();

  return (
    <Container container>
      <form onSubmit={handleEditCounterOfferSubmit}>
        <FormControl sx={{ marginTop: "16px", width: "100%" }}>
          <Input
            id={`$1-price`}
            name="price"
            label={<LabelRequired str="Precio ofrecido (S/)" />}
            value={preciocontraoferta}
            variant="standard"
            placeholder="Ingresa tu precio"
            onChange={handleOnchange}
            onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
            }}
          />
        </FormControl>

        <Grid item xs={12} sx={{ marginTop: "16px" }}>
          <ButtomLoader
            type="submit"
            buttomProps={{
              disabled: handleDisabledEditCounterOfferButton() || isLoading,
            }}
            isLoading={isLoading}
          >
            ENVIAR
          </ButtomLoader>
        </Grid>
      </form>
    </Container>
  );
};
