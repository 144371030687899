import { NoticeConditions } from "@/models/notice/noticeModels";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IListOfServices {
  id: number;
  nombre: string;
  idcondiciones: number;
  creadorpositivo: string;
  creadornegativo: string;
  visitantepositivo: string;
  visitantenegativo: string;
  isActive: number;
}
export interface IncludedServices {
  listOfServices: IListOfServices[] | NoticeConditions[] | [];
}

const initialState: IncludedServices = {
  listOfServices: [],
};
export const includedServicesSlice = createSlice({
  name: "includedServices",
  initialState,
  reducers: {
    setListOfServices: (
      state,
      { payload }: PayloadAction<IListOfServices[] | NoticeConditions[]>
    ) => {
      state.listOfServices = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setListOfServices } = includedServicesSlice.actions;
