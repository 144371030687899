import useGetData from "@/pages/noticeV2Negotation/shared/hooks/useGetData";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { Box, Typography } from "@mui/material";
import PriceMultiCounterOffer from "../../../counterOfferDetails/containers/MultidestinyNoticeV2Neg/components/PriceMultiCounterOffer";
import CardPriceInput from "./CardPriceInput";
import { districtsDeliveryText } from "@/consts/noticeConsts";

export const MultidestinyNoticeDriver = () => {
  const { notice } = useGetData();

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Typography sx={{ fontWeight: 700, fontSize: "14px" }}>
          {districtsDeliveryText}:{" "}
          <span style={{ fontWeight: 400, fontSize: "14px" }}>
            {notice?.destinoTexto}
          </span>
        </Typography>
      </Box>
      <PriceMultiCounterOffer data={notice as NoticeV2NegType} />
      <CardPriceInput/>
    </>
  );
};
