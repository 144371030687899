import {
  REGISTER_CLIENT,
  REGISTER_CLIENT_PROFILE,
  REGISTER_DRIVER,
  REGISTER_DRIVER_STEPS,
  WELCOME_CLIENT,
} from '@/consts/typeServicesPaths';

const defaultTexts = {
  numberDocument: {
    label: 'Número de documento',
    placeholder: 'Número de documento',
    order: 1,
  },
  name: {
    label: 'Nombres',
    placeholder: 'Ingresa tus nombres',
    validates: {
      maxLength: 30,
    },
    order: 2,
  },
  fullname: {
    label: 'Apellidos',
    placeholder: 'Ingresa tus apellidos',
    validates: {
      maxLength: 30,
    },
    order: 3,
  },
  district: {
    label: 'Distrito donde vives',
    placeholder: 'Ingresa el distrito donde vives',
    order: 4,
  },
  email: {
    label: 'Correo electrónico',
    placeholder: 'Ingresa tu dirección de correo',
    type: 'email',
    validates: {
      maxLength: 100,
    },
    order: 5,
  },
};

export const configByTypeDocument: Record<number, any> = {
  0: {
    // DEFAULT
    ...defaultTexts,
    numberDocument: {
      ...defaultTexts.numberDocument,
      validates: {
        maxLength: 8,
        onliNumbers: true,
      },
    },
  },
  1: {
    // DNI
    ...defaultTexts,
    numberDocument: {
      ...defaultTexts.numberDocument,
      validates: {
        maxLength: 8,
        onliNumbers: true,
      },
    },
  },
  2: {
    // PASAPORTE
    ...defaultTexts,
    numberDocument: {
      ...defaultTexts.numberDocument,
      validates: {
        maxLength: 16,
        onliNumbers: false,
      },
    },
  },
  3: {
    // CARNET DE EXTRANJERIA
    ...defaultTexts,
    numberDocument: {
      ...defaultTexts.numberDocument,
      validates: {
        maxLength: 12,
        onliNumbers: false,
      },
    },
  },
  4: {
    // RUC
    ...defaultTexts,
    numberDocument: {
      ...defaultTexts.numberDocument,
      validates: {
        maxLength: 11,
        onliNumbers: true,
        minLength: 8,
      },
    },
    name: {
      ...defaultTexts.name,
      label: 'Nombre de la empresa',
      placeholder: 'Ingresa el nombre de la empresa',
      validates: {
        maxLength: 50,
      },
    },
    district: {
      label: '¿Dónde está ubicada?',
      placeholder: 'Distrito donde se ubica la empresa',
      order: 3,
    },
    fullname: {
      label: 'Nombres y apellidos',
      placeholder: 'Ingresa tus nombres y apellidos',
      order: 4,
      validates: {
        maxLength: 50,
      },
    },
    email: {
      label: 'Correo electrónico',
      placeholder: 'Ingresa tu dirección de correo',
      validates: {
        maxLength: 100,
      },
      order: 5,
    },
  },
};

export const configByTYpeDocumentProfile: Record<number, any> = {
  ...configByTypeDocument,
  4: {
    // RUC
    ...defaultTexts,
    ...configByTypeDocument[4],
    numberDocument: {
      ...defaultTexts.numberDocument,
      label: 'Número de RUC',
      placeholder: 'Ingresa el RUC de la empresa',
      validates: {
        maxLength: 11,
        onliNumbers: true,
        minLength: 8,
      },
    },
  },
};

export const objectNextPage = {
  [REGISTER_CLIENT]: WELCOME_CLIENT,
  [REGISTER_DRIVER]: REGISTER_DRIVER_STEPS,
};
export const modalProps = {
  default: {
    inputProps: {
      label: 'Distrito donde vives',
      name: 'district',
      placeholder: 'Ingresa un distrito',
    },
    title: 'Distrito donde vives',
  },
  company: {
    inputProps: {
      label: 'Distrito',
      name: 'district',
      placeholder: 'Ingresa el distrito de tu empresa',
    },
    title: 'Ubicación de la empresa',
  },
};
