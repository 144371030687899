import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { textAceptarContactar, textContraofertar, textRechazar } from "@/consts/noticeConsts";
import useNotiffication from "@/hooks/useNotiffication";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { defaultError } from "@/pages/noticeRequestFlow/consts/noticeRequestFlowConsts";
import { setError } from "@/store/noticeNegotationRequestRedux/noticeNegRequestSlice";
import { useEffect } from "react";
import { useGlobalButtons } from "../hooks/useGlobalButtons";
import { ButtonAccept, ButtonCounterOffer, ContainerButtons } from "./StylesGlobalButtonsNotifications";

const CounterOfferGlobalButtons = () => {
  const { handleManageSnackbar, handleManageModalCounterOffer, handleAcceptButton, handleDriverCounterOfferButton, handleDisabledButtons } =
    useGlobalButtons();
  const { notiffication } = useNotiffication();
  const dispatch = useAppDispatch();
  const { error, isLoading } = useAppSelector((state) => state.offert);
  const disableButtons = handleDisabledButtons();

  useEffect(() => {
    if (error) {
      handleManageSnackbar();
    }
  }, [error]);

  useEffect(() => {
    if (!notiffication) {
      dispatch(setError(defaultError));
    }
  }, [notiffication]);

  return (
    <ContainerButtons>
      <ButtonAccept fullWidth variant="contained" onClick={handleAcceptButton} disabled={!disableButtons.disableAcceptButton || isLoading}>
        {textAceptarContactar}
      </ButtonAccept>

      <ButtonCounterOffer
        fullWidth
        disabled={!disableButtons.disableCounterOfferButton || isLoading}
        onClick={handleDriverCounterOfferButton}
        sx={{ borderColor: "#E0E0E0" }}
      >
        {textContraofertar}
      </ButtonCounterOffer>

      <ButtomLoader
        fullWidth
        variant="text"
        type="submit"
        isLoading={isLoading}
        onClick={handleManageModalCounterOffer}
        disabled={!disableButtons.disableDeclineButton || isLoading}
      >
        {textRechazar}
      </ButtomLoader>
    </ContainerButtons>
  );
};

export default CounterOfferGlobalButtons;
