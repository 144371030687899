import StandardAlert from "@/components/alerts/StandardAlert";
import SkeletonCustomGroup from "@/components/skeletons/SkeletonCustomGroup";
import {
  PROFILE_CREAR_VEHICULO_PATH,
  PROFILE_VEHICULO_PATH,
} from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import {
  setSelectedVehicleList,
  setSuccessMsg,
} from "@/store/userRedux/userSlice";
import { getUserVehiclesThunk } from "@/store/userRedux/userThunks";
import { setVehicleDefault } from "@/store/vehicle/vehicleSlice";
import {
  Button,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeaderProfile from "../../headerProfile/HeaderProfile";
import { CustomListLinks } from "../../styleProfile";
import EmptyVehicles from "./EmptyVehicles";
import Icon from "@/utils/Icon";

const ButtonCard = styled(Button)(
  ({ theme }) => `
        line-height: 24px;
        font-size: 14px;
        text-transform: uppercase;
        padding: 0px 16px;
        border-radius: 36px;
        width: 100%;
        height: 28px;
        color: ${theme.palette.org.main};
        background-color: ${theme.palette.org.contrastText};
        border: 1px solid ${theme.palette.org.main};
        &:hover {
            background-color: ${theme.palette.org.dark};
        }
    `
);
const Vehicles = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { listVehicles, successMsg, isLoading } = useAppSelector(
    (state) => state.user
  );

  useEffect(() => {
    dispatch(setSelectedVehicleList({}));
    dispatch(getUserVehiclesThunk());
    dispatch(setVehicleDefault());
  }, []);

  useEffect(() => {
    if (successMsg && successMsg !== "") {
      setTimeout(() => {
        dispatch(setSuccessMsg(""));
      }, 15000);
    }
  }, [successMsg]);

  if (isLoading) {
    return (
      <SkeletonCustomGroup
        skeletonList={[
          { height: 80, width: "80%" },
          { height: 80, width: "80%" },
          { height: 80, width: "80%" },
          { height: 80, width: "80%" },
        ]}
        gap="16px"
      />
    );
  }

  return (
    <>
      <HeaderProfile
        title="Tus vehículos"
        sx={{
          width: "360px",
        }}
        button={
          <ButtonCard
            variant="outlined"
            color="org"
            onClick={() => {
              navigate(PROFILE_CREAR_VEHICULO_PATH[0]);
            }}
          >
            AGREGAR
          </ButtonCard>
        }
      >
        {successMsg && !isLoading && (
          <StandardAlert
            sx={{ marginBottom: "16px" }}
            severity="success"
            color="success"
            onClick={() => {
              dispatch(setSuccessMsg(""));
            }}
          >
            {successMsg}
          </StandardAlert>
        )}
        {listVehicles?.length > 0 ? (
          <CustomListLinks>
            {listVehicles?.map((value, index) => (
              <ListItem
                disablePadding
                key={index}
                sx={{
                  ".css-cveggr-MuiListItemIcon-root": {
                    minWidth: "5px",
                  },
                }}
              >
                <ListItemButton
                  onClick={() => {
                    dispatch(setSuccessMsg(""));
                    navigate(
                      PROFILE_VEHICULO_PATH.replace(":id", value.id.toString())
                    );
                  }}
                  sx={{
                    margin: "0px 0px 16px 0px",
                    height: "56px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "40px",
                    }}
                  >
                    <Icon name="icon-truck" size={24} />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${value?.tipovehiculo?.nombre} ${value?.placa ? `(${value?.placa})` : ""}`}
                    secondary={(value?.estado !== "COMPLETO"
                      ? value?.estado
                      : null
                    )?.toLowerCase()}
                    secondaryTypographyProps={{
                      color: "error.main",
                    }}
                  />
                  <ListItemIcon>
                    <i className="icon-small-arrow-right" />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))}
          </CustomListLinks>
        ) : (
          <EmptyVehicles />
        )}
      </HeaderProfile>
    </>
  );
};

export default Vehicles;
