import { NEGOTIATION_SAVE_DATA } from '@/consts/pathServices';
import { connection } from '@/services/connection';
import { DataResponseNoticeSpanish } from './noticeServiceDataResponseType';

const API = connection();

export const noticeSendDataForSave = async (
  body: DataResponseNoticeSpanish,
  id: string
): Promise<DataResponseNoticeSpanish> => {
  const { data } = await API.post(
    NEGOTIATION_SAVE_DATA.replace(':idNotice', id),
    body
  );

  return data.avisoNegociacionData.avisoNegociacion;
};

/**
 * La función " SavingNoticeOffer " es una función asincrónica que guarda un aviso de oferta con un
 * cuerpo y un ID de aviso determinados.
 * @param {T} body - El parámetro "cuerpo" son los datos que desea enviar en el cuerpo de la solicitud.
 * Puede ser de cualquier tipo (`T`).
 * @param {string} idNotice - El parámetro `idNotice` es una cadena que representa el ID de un aviso.
 * Se utiliza para reemplazar el marcador de posición `:idNotice` en la ruta `NEGOTIATION_SAVE_DATA`.
 * @returns una Promesa de tipo R.
 */
export const savingNoticeOffer = async <T, R>(
  body: T,
  idNotice: string
): Promise<R> => {
  const path = NEGOTIATION_SAVE_DATA.replace(':idNotice', idNotice);
  const { data } = await API.post(path, body);

  return data;
};
