import StandardAlert from "@/components/alerts/StandardAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import LoadMultiPhotos from "@/components/uploadMultiPhotos/LoadMultiPhotos";
import LabelRequired from "@/components/utils/LabelRequired";
import { useAppDispatch } from "@/hooks/useReducer";
import { emptyErrorImage } from "@/store/customerRequestRedux/customerRequestSlice";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FormControl, InputAdornment, Typography } from "@mui/material";
import { useId, useState } from "react";
import { inputDescriptionLoad, inputTypeWeight, inputTypevehicle } from "./config";

import { questionTitle } from "@/pages/customerRequest/shared/consts/customerRequestConsts";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import HandleDrawer, { ShowModalType } from "../components/HandleDrawer";
import { useFormLoad } from "../hook/useFormLoad";
import { Container, Form } from "../styles/FormLoadStyles";

const FormLoadMultidestiny = () => {
  const id = useId();
  const dispatch = useAppDispatch();

  const [showModal, setShowModal] = useState<ShowModalType>({
    open: false,
    type: "",
  });

  const {
    isLoading,
    errorImage,
    handleChange,
    handleDisabledButton,
    handleOnChangeImages,
    handleSubmit,
    descriptionLoad,
    selectedEstimatedWeightRequest,
    selectedTypeLoadRequest,
    selectedTypevehicle,
    photosPreLoaded,
  } = useFormLoad();

  return (
    <Container container>
      <>
        {!!errorImage && (
          <StandardAlert
            severity="error"
            color="error"
            onCloseAlert={() => dispatch(emptyErrorImage())}
          >
            {errorImage}
          </StandardAlert>
        )}
      </>

      <HandleDrawer showModal={showModal} setShowModal={setShowModal} />

      <Typography variant="body1">{questionTitle}</Typography>
      <Form onSubmit={handleSubmit}>
        <FormControl>
          <Input
            error={false}
            {...inputTypevehicle}
            placeholder="Selecciona el tipo de carga"
            label={<LabelInputRequired str="Tipo de carga" />}
            value={selectedTypeLoadRequest.nombre}
            variant="standard"
            onChange={() => setShowModal({ type: "typeLoad", open: true })}
            onClick={() => setShowModal({ type: "typeLoad", open: true })}
            helperText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <ArrowDropDownIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>

        <FormControl sx={{ marginTop: "16px" }} fullWidth>
          <Input
            id={`${id}-city`}
            {...inputDescriptionLoad}
            label={<LabelInputRequired str={inputDescriptionLoad.label} />}
            value={descriptionLoad}
            variant="standard"
            onChange={handleChange}
          />
        </FormControl>

        <LoadMultiPhotos
          initialPhotos={photosPreLoaded}
          onChangeFiles={handleOnChangeImages}
        />

        <FormControl>
          <Input
            error={false}
            {...inputTypevehicle}
            label={<LabelInputRequired str={inputTypevehicle.label} />}
            value={selectedTypevehicle.nombre}
            variant="standard"
            onChange={() => setShowModal({ type: "vehicle", open: true })}
            onClick={() => setShowModal({ type: "vehicle", open: true })}
            helperText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <ArrowDropDownIcon />{" "}
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl>
          <Input
            error={false}
            {...inputTypeWeight}
            label={<LabelInputRequired str="Rango de peso estimado" />}
            value={selectedEstimatedWeightRequest.peso}
            variant="standard"
            onChange={() => setShowModal({ type: "weight", open: true })}
            onClick={() => setShowModal({ type: "weight", open: true })}
            helperText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <ArrowDropDownIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <ButtomLoader
          type="submit"
          sx={{ marginTop: 0 }}
          buttomProps={{ disabled: handleDisabledButton() }}
          isLoading={isLoading}
        >
          SIGUIENTE
        </ButtomLoader>
      </Form>
    </Container>
  );
};

export default FormLoadMultidestiny;
