import IconsSvg from "@/pages/detailNotice/components/IconsSvg";
import Icon, { IconTypes } from "@/utils/Icon";
import { Theme } from "@emotion/react";
import { Box, Stack, styled, SxProps, Typography } from "@mui/material";
import { FC } from "react";

const Title = styled(Typography)(
  ({ theme }) => `
      color: ${theme.palette.text.secondary};
      font-weight: bold;
      display: inline-block;
      gap: 1em;
    `
);

const ConatinerDetails = styled(Stack)(
  ({ theme }) => `
    display: flex;
    margin-top: 10px;
    flex-direction: row;
    gap: 8px;
    font-family: Source Sans Pro;
  `
);

export type DetailsTextProps = {
  show: boolean;
  title: string;
  origen: string;
  icon: IconTypes;
  constainerSx?: SxProps<Theme>;
  separate?: boolean;
  titleSx?: SxProps<Theme>;
};
const InlineTextDetails: FC<DetailsTextProps> = ({
  show,
  title,
  origen,
  icon,
  constainerSx,
}) => {
  if (!show || !origen) return <></>;

  return (
    <ConatinerDetails>
      {icon && <Icon name={icon} size="md" />}
      <Title gap="8px">
        {title}
        <Typography
          variant="body1"
          component="span"
        >
          {origen}
        </Typography>
      </Title>
    </ConatinerDetails>
  );
}

export default InlineTextDetails
