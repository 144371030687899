import BasicAlert from "@/components/alerts/BasicAlert";
import CircularLoading from "@/components/utils/CircularLoading";
import { useAppSelector } from "@/hooks/useReducer";
import { List, ListItemButton, ListItemText, styled } from "@mui/material";
import ContainerIconTitle, { IconTitleProps } from "./ContainerIconTitle";

interface Props extends IconTitleProps {
  list?: ListProps[];
  onClick?: (vale: ListProps) => void;
  onliItems?: boolean;
  primaryShowKey?: "nombre" | "nombre2" | "detalle";
}

interface ListProps {
  nombre: string;
  nombre2?: string;
  id: number;
  idpais: number;
  detalle?: string;
}

const ListItem = styled(ListItemButton)(
  ({ theme }) => `
    &:hover {
      background-color: rgba(0,0,0,0.06);

      & .MuiListItemText-root .MuiTypography-root{
        color: ${theme.palette.error.main};
      }
    }

  `
);

const TextItem = styled(ListItemText)(
  ({ theme }) => `
    color: ${theme.palette.colorText.main};
    .MuiTypography-root.MuiTypography-body2 {
      color: ${theme.palette.text.disabled};
    }
  `
);

const AutoCompleteModal = ({
  title,
  icon,
  list,
  onClick,
  onliItems,
  primaryShowKey,
}: Props) => {
  const {
    cities: { isLoading: isLoadingCities },
    districts: { isLoading: isLoadingDistricts },
  } = useAppSelector((state) => state);

  const loading = isLoadingCities || isLoadingDistricts;

  if (loading) return <CircularLoading text="Buscando" />;

  const handleSelect = (select: ListProps) => {
    !!onClick && onClick(select);
  };

  const handleShowPropertyPrimaryText = (item: ListProps) => {
    if (primaryShowKey && primaryShowKey in item) {
      return item[primaryShowKey];
    }

    return item.nombre || "";
  };

  if (!Array.isArray(list) || !list.length) {
    return (
      <BasicAlert severity="warning">No encontramos resultados</BasicAlert>
    );
  }

  return (
    <>
      {!onliItems && <ContainerIconTitle title={title} icon={icon} />}
      <List>
        {list.map((listItem) => (
          <ListItem key={listItem.id} onClick={() => handleSelect(listItem)}>
            <TextItem
              primary={handleShowPropertyPrimaryText(listItem)}
              secondary={listItem.detalle || ""}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default AutoCompleteModal;
