import { prepareBodyAdvertisementListThunk } from '@/adapters/store/advertisementAdapter';
import {
  listNoticeBodyByFilterAdapter,
  prepareHidrateTypeVehicleAdapter,
} from '@/adapters/store/filterNoticeAdapter';
import { responseFilterListToReduxAdapter } from '@/components/Filter/adapter/filterAdapter';
import { FormDataFilter } from '@/components/Filter/consts/filtersConst';
import { FilterListOptions } from '@/components/Filter/interface/filterInterface';
import { ID_CITY_LIMA } from '@/config/config';
import { getFilterListServices } from '@/services/filterService';
import {
  getCitiesByPaisAndTermServices,
  getCitiesByTermServices,
  getDistrictByTermServices,
  getDistrictsByCityAndTermServices,
  getOnlyDistrictsByCityServices,
} from '@/services/locationServices';
import { filterNoticeServices } from '@/services/noticeServices';
import { removeAccents } from '@/utils/helpers';
import { getAdvertisementNoticeListThunk } from '../advertisementRedux/advertisementThunk';
import { AppDispatch, RootState } from '../store';
import { IUser } from '../userRedux/userReduxInterface';
import {
  hidrateTypeVehicles,
  resetPaginate,
  setError,
  setFilterListOptions,
  setListLocation,
  setListOfDistrict,
  setLoadingFilter,
  setLoadingNotices,
  setLoadingTypeFilter,
  setNoticeListPaginated,
  setTypeNoticeSelect,
  updateNoticeListPaginated,
} from './filterNoticeSlice';
import { getVehicleListServices } from '@/services/vehicleServices';

export const getCitiesByPaisFilterThunk = (term: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoadingFilter(true));
    try {
      const { user } = getState();

      const currentUser = user.user as IUser;
      let idpais = '1';
      if (currentUser?.ciudad?.idpais) {
        idpais = `${currentUser?.ciudad?.idpais}`;
      }

      const result = await getCitiesByPaisAndTermServices(idpais, term);

      dispatch(setListLocation(result?.ciudadData?.ciudades));
      return result?.ciudadData?.ciudades;
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

// internationalization
export const getCitiesInternationalizationFilterThunk = (term: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoadingFilter(true));
    try {
      const result = await getCitiesByTermServices(term);

      dispatch(setListLocation(result?.ciudadData?.ciudades));
      return result?.ciudadData?.ciudades;
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

// city -> "Lima, Peru"
export const getDistrictsFilterThunk = (termino: string, city: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoadingFilter(true));
    try {
      const {
        ciudadData: { ciudades },
      } = await getCitiesByTermServices(city);

      if (!!ciudades.length) {
        const id = `${ciudades[0].id}`;
        const { distritoData } = await getDistrictsByCityAndTermServices(
          id,
          termino
        );

        dispatch(setListLocation(distritoData?.distritos));
        return distritoData?.distritos;
      } else {
        const { distritoData } = await getDistrictByTermServices(termino);

        dispatch(setListLocation(distritoData?.distritos));
        return distritoData?.distritos;
      }
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

export const getInitialFilterList = () => {
  return async (
    dispatch: AppDispatch
  ): Promise<FilterListOptions | undefined> => {
    dispatch(setLoadingTypeFilter(true));
    try {
      const { filtroAvisoData } = await getFilterListServices();
      const result = responseFilterListToReduxAdapter(filtroAvisoData);

      dispatch(setFilterListOptions(result));
      dispatch(setLoadingTypeFilter(false));
      return result;
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

export const searchTypeVehicleFiltrerListThunk = (termino: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoadingFilter(true));
    try {
      const {
        filterNotice: { typeVehicles },
      } = getState();
      const result = filterInArrayString(termino, typeVehicles, 'nombre');

      dispatch(setListLocation(result));
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

export const searchWeightOfTheLoadThunk = (termino: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoadingFilter(true));
    try {
      const {
        filterNotice: { weightOfTheLoad },
      } = getState();

      const result = filterInArrayString(termino, weightOfTheLoad, 'nombre');

      dispatch(setListLocation(result));
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

export const searchDistrictInInternalListThunk = (term: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      filterNotice: { listOfDistrict },
    } = getState();
    const result = filterInArrayString(term, listOfDistrict, 'nombre');

    dispatch(setListLocation(result));
  };
};

const filterInArrayString = (
  term: string,
  arr: any[],
  propertyToSerach: string
) => {
  const cleanTerm = removeAccents(term).toLocaleLowerCase();
  const result = arr.filter((str) => {
    const cleanStr = removeAccents(str[propertyToSerach]).toLocaleLowerCase();
    return cleanStr.includes(cleanTerm);
  });

  return result;
};

export const selectTypeNoticeThunk = (typeNoticeSelected?: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { filterNotice } = getState();
    if (!typeNoticeSelected) {
      dispatch(setTypeNoticeSelect(filterNotice.typeNotice[0]));
    } else {
      const result = filterNotice.typeNotice.find(
        (notice) => notice.nombre === typeNoticeSelected
      );
      if (result) dispatch(setTypeNoticeSelect(result));
    }
  };
};

export const getDistrictListMultiSelectThunk = (term: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoadingFilter(true));

    try {
      const {
        user: { user },
      } = getState();
      const currentUser = user as IUser;

      let idCity = ID_CITY_LIMA; // lima
      if (Object.keys(currentUser) && currentUser?.ciudad) {
        idCity = currentUser?.ciudad?.id.toString();
        const { nombre2 = '' } = currentUser?.ciudad;
        if (term !== nombre2) {
          // validar si la ciudad es la misma que tiene el usuario
          const city = await getCitiesByTermServices(term);

          const [currentCity] = city?.ciudadData?.ciudades;
          idCity = `${currentCity?.id}`;
        } else {
          const city = await getCitiesByTermServices(nombre2);
          const [currentCity] = city?.ciudadData?.ciudades;
          idCity = `${currentCity?.id}`;
        }
      } else {
        const city = await getCitiesByTermServices(term);
        const [currentCity] = city?.ciudadData?.ciudades;
        idCity = `${currentCity?.id}`;
      }

      const result = await getOnlyDistrictsByCityServices(idCity);

      dispatch(setListOfDistrict(result?.distritoData?.distritos));
    } catch (error) {
      dispatch(setError(error));
    }
  };
};

export type GetListNoticeByFilterThunk = {
  isPaginate?: boolean;
  size?: number;
  pathname?: string; // el path donde se reliazo la peticion, esto se usa para agregar la publicidad!!
  selectOrder: string;
};
export const getListNoticeByFilterThunk = (
  body: FormDataFilter,
  paginate?: GetListNoticeByFilterThunk
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoadingNotices(true));
    try {
      const {
        filterNotice: { typeNoticeSelecte, currentPage, orderByList },
      } = getState();

      const typeNotice = typeNoticeSelecte.nombre;

      const order = orderByList.find(
        (order) => order.nombre === paginate?.selectOrder
      );

      if (!order?.opciones) {
        dispatch(setLoadingNotices(false));
        return;
      }

      const prepareBody = {
        ...body,
        typeNotice,
      };

      const preparePaginate = {
        ...paginate,
        page: paginate?.isPaginate ? currentPage : 0,
        selectOrder: order?.opciones,
      };

      const newBody = listNoticeBodyByFilterAdapter(
        prepareBody,
        typeNoticeSelecte,
        preparePaginate
      );

      !paginate?.isPaginate && dispatch(resetPaginate());

      const { avisoData } = await filterNoticeServices(newBody);

      if (!avisoData) return;
      if (paginate?.isPaginate) {
        dispatch(updateNoticeListPaginated(avisoData));
      } else {
        dispatch(setNoticeListPaginated(avisoData));
      }

      // ================ Ejecuta la peticion de publicidad, para luego insertar en el listado ====
      if (paginate?.pathname) {
        const typeList = 2;
        const advertisementBody = prepareBodyAdvertisementListThunk(
          paginate.pathname,
          avisoData?.aviso?.length || newBody.size,
          typeList //  el tipo 2, indica que la publicidad ira en los listados
        );

        dispatch(getAdvertisementNoticeListThunk(advertisementBody));
      }
    } catch (error) {
      dispatch(setError(error));
    } finally {
      dispatch(setLoadingNotices(false));
    }
  };
};

export const hidrateTypeVehiclesThunk = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const { tipoVehiculoData } = await getVehicleListServices(1);
      const listTypeVehicle =
        prepareHidrateTypeVehicleAdapter(tipoVehiculoData);

      dispatch(hidrateTypeVehicles(listTypeVehicle));
    } catch (error) {
      dispatch(setError(error));
    }
  };
};
