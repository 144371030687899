import InputCustom from "@/components/inputCustom/InputCustom";
import {
  Box,
  InputBaseComponentProps,
  Paper,
  SxProps,
  Theme,
  Typography,
  styled,
} from "@mui/material";

type InputType = {
  name: string;
  value: string;
  placeholder?: string;
  sx?: SxProps<Theme>;
  inputMode?: InputBaseComponentProps;
};

type RowType = {
  title1?: string | number | JSX.Element;
  title2?: string | number | JSX.Element;
  input?: InputType;
};

type Props = {
  containerSx?: SxProps<Theme>;
  elevation?: number;
  title: string;
  row1: RowType;
  row2: RowType;
};

const Container = styled(Paper)(
  () => `
    padding: 10px;
    border-radius: 8px; 
  `
);

const Row = styled(Box)(
  () => `  
    display: flex;
    flex-direction: row;
    padding-right: 15px;
    margin-top: 8px;
    align-items: center;
  `
);

const CardCounterOffer = (props: Props) => {
  const { containerSx, elevation = 6, title, row1, row2 } = props;
  return (
    <Container elevation={elevation} sx={containerSx}>
      <Box>
        <Typography variant="subtitle1">{title}</Typography>
      </Box>
      <Row>
        <Typography sx={{ flex: 1 }} variant="label3">
          {row1.title1}
        </Typography>
        <Typography sx={{ flex: 1 }} variant="label3">
          {row1.title2 || ""}
        </Typography>
      </Row>
      <Row>
        <Typography sx={{ flex: 1 }} variant="body1">
          {row2.title1}
        </Typography>
        {row2.title2 && (
          <Typography sx={{ flex: 1 }} variant="body1">
            {row2.title2}
          </Typography>
        )}
        {row2.input && <InputCustom sx={{ flex: 1 }} {...row2.input} />}
      </Row>
    </Container>
  );
};

export default CardCounterOffer;
