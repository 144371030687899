import { useAppSelector } from "@/hooks/useReducer";
import { NoticeRequestData } from "@/store/noticeNegotationRequestRedux/noticeNegRequestSlice";
import { Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";

const emptyData = {
  negotationId: 0,
  origin: "",
  destiny: "",
  price: 0,
  car: "",
};

const FormSuccessExpress = () => {
  const {
    noticeNegotationRequest: { isLoading, noticeRequestData, savedDataNogotation },
  } = useAppSelector((state) => state);
  const [data, setData] = useState<NoticeRequestData>(emptyData);

  const counterOfferPrice = savedDataNogotation?.precioContraoferta;
  const validPrice = !!savedDataNogotation?.precioContraoferta;
  const offerPrice = savedDataNogotation?.precio;
  const validType = savedDataNogotation?.tipoSolicitudNegociacion?.nombre === `Aceptación`;
  const time = savedDataNogotation?.tiempoestimado || 0;

  useEffect(() => {
    if (noticeRequestData) {
      setData(noticeRequestData);
    }
  }, []);
  return (
    <Grid container sx={{ mt: 2 }}>
      <Grid item xs={12} sx={{ display: "flex" }}>
        <Stack sx={{ mr: 1 }}>
          <i className="icon-tunder--black"></i>
        </Stack>
        <Typography variant="body2" sx={{ color: "#757575", fontSize: "12px" }}>
          Express
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" sx={{ fontSize: "18px" }}>
          De {savedDataNogotation?.solicitud?.origenTexto} a {savedDataNogotation?.solicitud?.destinoTexto}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex" }}>
        <Typography variant="body2" sx={{ color: "#757575", fontSize: "12px" }}>
          Tu {savedDataNogotation?.tipoSolicitudNegociacion?.nombre}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
        <Stack sx={{ mr: 1 }}>
          <i className="icon-car-medium"></i>
        </Stack>
        <Typography variant="h4" sx={{ fontSize: "14px", mr: 2 }}>
          Vehículo:
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "16px", color: "#757575" }}>
          {savedDataNogotation?.vehiculo?.tipoVehiculo} {savedDataNogotation?.vehiculo?.placa} 
        </Typography>
      </Grid>
      <Grid item xs={12}>
      <Typography variant="body2">
          Tiempo aprox. de llegada: {time} minutos.
        </Typography>
        <Typography variant="h4" sx={{ fontSize: "22px", mr: 2 }}>
          {!validType && <>{!validPrice ? `S/${offerPrice}` : `Contraoferta de S/${counterOfferPrice}`}</>}
        </Typography>
        <Typography variant="body2" sx={{ color: "#757575", fontSize: "12px" }}>
          Enviada el {savedDataNogotation?.fechaRegistro} a las {savedDataNogotation?.horaRegistro}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FormSuccessExpress;
