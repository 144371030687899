import { NOTICE_TYPE } from "@/consts/pathServices";
import { connection } from "@/services/connection";
import { INoticeListTipos } from "./NoticeListTypes";

const API = connection();

const getTypeNoticeV2Service = async (flgregistro?: boolean) : Promise<INoticeListTipos> => {
  const { data } = await API.get(`${NOTICE_TYPE}${
    flgregistro ? `?flgregistro=1` : ""
  }
  `);
  return data;
};


export { getTypeNoticeV2Service };