import {
  ID_AUCTION_FLOW,
  ID_BETWEEN_CITIES,
  ID_IN_CITY,
  ID_MOVING_FLOW,
  ID_MULTI_DESTINY,
} from '@/consts/defaultConsts';
import {
  DetailOfTheNoticeNegotationCoreAPIModel,
  DetailsNegotationV2,
} from '@/models/notice/noticeV2NegotationModels';
import {
  getNegotationByIdServices,
  saveOfferByIdServices,
} from '@/services/noticeV2Negotation';
import { getMessaggeError } from '../auth/utils';
import { AppDispatch } from '../store';
import {
  resetDetailsNoticeSlice,
  setDataAuctionNoticeNegotation,
  setDataBetweenCitiesNoticeNegotation,
  setDataInCityNoticeNegotation,
  setDataMovingNoticeNegotation,
  setDataMultiDestinyNoticeNegotation,
  setDataResponse,
  setError,
  setLoadingNoticeV2,
} from './noticeV2NegotiationSlice';

export interface DetailsNoticeInCitySaveIn {
  saveIn: number;
}

const objectSave: Record<DetailsNoticeInCitySaveIn['saveIn'], any> = {
  [ID_IN_CITY]: setDataInCityNoticeNegotation,
  [ID_BETWEEN_CITIES]: setDataBetweenCitiesNoticeNegotation,
  [ID_MULTI_DESTINY]: setDataMultiDestinyNoticeNegotation,
  [ID_MOVING_FLOW]: setDataMovingNoticeNegotation,
  [ID_AUCTION_FLOW]: setDataAuctionNoticeNegotation,
};

export const getNegotiationByIdThunk = (idOffer: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(resetDetailsNoticeSlice());
    dispatch(setLoadingNoticeV2(true));

    try {
      const id = idOffer.toString();
      const { avisoNegociacionData } = await getNegotationByIdServices<
        DetailOfTheNoticeNegotationCoreAPIModel<DetailsNegotationV2>
      >(id);
      const { avisoNegociacion } = avisoNegociacionData;

      const funcSave = objectSave[avisoNegociacion.idtipoaviso];

      dispatch(funcSave(avisoNegociacion));

      return avisoNegociacion;
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const saveOfferByIdThunkV2 = (props: any) => {
  return async (dispatch: AppDispatch) => {
    const { idOffer } = props;
    dispatch(setLoadingNoticeV2(true));

    try {
      const id = idOffer.toString();
      const body = {
        ...props,
      };
      delete body.idOffer;

      const { avisoNegociacionData } = await saveOfferByIdServices(id, body);

      dispatch(setDataResponse(avisoNegociacionData.avisoNegociacion));

      dispatch(setLoadingNoticeV2(false));
      return avisoNegociacionData.avisoNegociacion;
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setError(error));
      dispatch(setLoadingNoticeV2(false));
      throw new Error(error.message || undefined);
    }
  };
};
