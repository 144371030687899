import {
  saveOffertAuctionThunk,
  saveOffertInCityThunk,
  saveOffertMovingThunk,
  saveOffertMultiDestinoThunk,
} from '@/store/offertRedux/offert.thunk';

type Props = {
  title: string;
  subTitle: string;
};

export const configCounterOfferText = {
  title: '¡Servicio aceptado!',
  subTitle:
    'Te enviaremos un SMS y notificación cuando el transportista responda.',
};

export const configDataOffert: Record<number, Props> = {
  1: {
    title: '¡Servicio aceptado!',
    subTitle:
      'Te enviaremos un SMS y notificación cuando el transportista responda.',
  },
  2: {
    title: '¡Contraoferta enviada!',
    subTitle: 'Enviaremos un SMS y notificación cuando el cliente responda.',
  },
  3: {
    title: '¡Cotización enviada!',
    subTitle: 'Enviaremos un SMS y notificación cuando el cliente responda.',
  },
  4: {
    title: '¡Cotización enviada!',
    subTitle: 'Enviaremos un SMS y notificación cuando el cliente responda.',
  },
};

export const rulesDispatch: Record<number, any> = {
  1: saveOffertInCityThunk,
  2: saveOffertMultiDestinoThunk,
  3: saveOffertInCityThunk,
  4: saveOffertAuctionThunk,
  5: saveOffertMovingThunk,
};
