import {
  PROFILE_NOTICE_LIST_PATH,
  PROFILE_NOTICE_OFFERT_AGREEMENT_CLIENT,
  PROFILE_NOTICE_OFFERT_AGREEMENT_TRANSPORTER,
  REQUESTS_LANDING_PATH,
  SERVICES_LANDING_PATH,
} from "@/consts/typeServicesPaths";
import {
  MY_APPLICATIONS_CUSTOMER_REQUEST_PATH,
  NEGOTATION_OFFERT_AGREEMENT_CLIENT,
  NEGOTATION_OFFERT_AGREEMENT_TRANSPORTER,
} from "@/pages/customerRequest/shared/consts/routes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ButtomLoader from "../form/buttonLoader/ButtomLoader";

interface IProps {
  variant?: "text" | "outlined" | "contained";
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  isOwner?: number;
}

const ButtonRedirectRating = ({
  variant,
  color,
  size,
  disabled,
  isOwner,
}: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { idOffert = "", typeId = "" } = useParams();

  //   si es mis-avisos califica al cliente
  const isMyAviso = pathname?.startsWith(PROFILE_NOTICE_LIST_PATH);
  //   si es mis-solicitudes califica al transportista
  const isMySolicitud = pathname?.startsWith(
    MY_APPLICATIONS_CUSTOMER_REQUEST_PATH
  );
  //   si es avisos califica al transportista
  const isAviso = pathname?.startsWith(SERVICES_LANDING_PATH);
  //   si es solicitudes califica al cliente
  const isSolicitud = pathname?.startsWith(REQUESTS_LANDING_PATH);

  //

  const handleRedirect = () => {
    const isMyOffert = isOwner === 1;
    const redirectPath =
      isMySolicitud && isMyOffert
        ? NEGOTATION_OFFERT_AGREEMENT_TRANSPORTER.replace(
            ":idOffert",
            idOffert || typeId
          )
        : isSolicitud && !isMyOffert
        ? NEGOTATION_OFFERT_AGREEMENT_CLIENT.replace(
            ":idOffert",
            idOffert || typeId
          )
        : isMyAviso && isMyOffert
        ? PROFILE_NOTICE_OFFERT_AGREEMENT_CLIENT.replace(
            ":idOffert",
            idOffert || typeId
          )
        : isAviso && !isMyOffert
        ? PROFILE_NOTICE_OFFERT_AGREEMENT_TRANSPORTER.replace(
            ":idOffert",
            idOffert || typeId
          )
        : "";

    navigate(redirectPath);
  };

  const validateDisabled = () => {
    const isMyOffert = isOwner === 1;
    const pathvalidate =
      isMySolicitud && isMyOffert
        ? NEGOTATION_OFFERT_AGREEMENT_TRANSPORTER.replace(
            ":idOffert",
            idOffert || typeId
          )
        : isSolicitud && !isMyOffert
        ? NEGOTATION_OFFERT_AGREEMENT_CLIENT.replace(
            ":idOffert",
            idOffert || typeId
          )
        : isMyAviso && isMyOffert
        ? PROFILE_NOTICE_OFFERT_AGREEMENT_CLIENT.replace(
            ":idOffert",
            idOffert || typeId
          )
        : isAviso && !isMyOffert
        ? PROFILE_NOTICE_OFFERT_AGREEMENT_TRANSPORTER.replace(
            ":idOffert",
            idOffert || typeId
          )
        : "";

    return pathvalidate === "";
  };

  return (
    <ButtomLoader onClick={handleRedirect} disabled={validateDisabled()}>
      CALIFICAR SERVICIO
    </ButtomLoader>
  );
};

export default ButtonRedirectRating;
