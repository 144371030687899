import {
  AGREEMENTS_TO_ACCEPT,
  AUTH_RESET_PASSWORD_USER,
  AUTH_USER_REGISTER,
  AUTH_VALID_CODE,
  AUTH_VALID_SMS,
  AUTH_VALID_USER,
  CHANGE_PASSWORD,
  CHANGE_USER_PHONE,
  MY_NEGOTIATIONS_LIST,
  NOTIFICATION_LIST,
  TYPES_USERS,
  UPDATE_USER_DATA,
  USER_BY_ID_PATH,
  USER_CREATE_VEHICLE,
  USER_CREATE_VEHICLE_PHOTOS,
  USER_CREATE_VEHICLE_TARJETA,
  USER_FINALIZE_REGISTER,
  USER_REGISTER_FORM_DATA,
  USER_REGISTER_PHOTO_PROFILE,
  USER_TYPE_DOCUMENT,
  USER_UPLOAD_DOCUMENT_IDENTIFICATION_PATH,
  USER_VEHICLES,
  USER_VEHICLES_BY_ID,
  USER_VEHICLE_CREATE,
  USER_VEHICLE_UPDATE,
  USER_VEHICLE_UPDATE_PHOTO,
  USER_VEHICLE_UPDATE_PHOTOS,
  USER_VEHICLE_UPDATE_PHOTO_TARJETA,
} from '@/consts/pathServices';
import { PhotoProfileDTO } from '@/interfaces/userInterface';
import {
  IAgreementToAcceptAPI,
  MyNegotiationesListAPI,
  TAgreementToAcceptBody,
} from '@/models/userAuth/agrementToAccept';
import { INotificationAPI } from '@/models/userAuth/notification';
import { UserLoginResponseAPI, UserResponseAPI } from '@/models/userAuth/user';
import {
  TypeUserResponseAPI,
  ValidateUserResponseAPI,
} from '@/models/userAuth/userAuth';
import {
  ResponsePropertyCarAPI,
  ResponseVehicleAPI,
} from '@/models/vehicle/vehicleModel';
import { connection, instanceAuth } from '@/services/connection';
import { SaveFormDataUser } from '@/store/userRedux/userReduxInterface';
import { TNotificacionesBody } from '../models/userAuth/notification';
import { CreateVehicleDTO } from './interfaces/vehicleInterface';
import { HEADERS_MULTIPART } from './utils/consts';
import { setFormDataByObject } from './utils/utils';

export const getTypesUserServices = async () => {
  const API = connection(instanceAuth);
  const { data } = await API.get(TYPES_USERS);

  return data;
};

export const getUserByIdServices = async (
  id: string
): Promise<UserLoginResponseAPI> => {
  const path = USER_BY_ID_PATH.replace(':id', id);
  const API = connection();
  const { data } = await API.get(path);

  return data;
};

export const savePhotoProfileServices = async ({
  image,
  description,
}: PhotoProfileDTO): Promise<UserResponseAPI> => {
  const path = USER_REGISTER_PHOTO_PROFILE;
  const headers = HEADERS_MULTIPART;
  const formData = setFormDataByObject({
    foto: image,
    descripcion: description,
  });

  const API = connection(instanceAuth);
  const { data } = await API.put(path, formData, { headers });

  return data;
};

export const uploadDocumentIdentificationServices = async (
  image: string | File
): Promise<UserResponseAPI> => {
  const path = USER_UPLOAD_DOCUMENT_IDENTIFICATION_PATH;
  const headers = HEADERS_MULTIPART;
  const formData = setFormDataByObject({ documento: image });

  const API = connection(instanceAuth);
  const { data } = await API.put(path, formData, { headers });

  return data;
};

export const uploadPropertyCardServices = async (
  image: string | File,
  imagePosterior: string | File
): Promise<ResponsePropertyCarAPI> => {
  const path = USER_CREATE_VEHICLE_TARJETA;
  const headers = HEADERS_MULTIPART;
  const formData = setFormDataByObject({
    tarjeta: image,
    tarjetaPosterior: imagePosterior,
  });

  const API = connection(instanceAuth);
  const { data } = await API.put(path, formData, { headers });

  return data;
};

export const saveFormDataUserServices = async (
  body: SaveFormDataUser
): Promise<UserResponseAPI> => {
  const API = connection(instanceAuth);
  const { data } = await API.post(USER_REGISTER_FORM_DATA, body);

  return data;
};

export const endUserRegistrationServices =
  async (): Promise<UserResponseAPI> => {
    const API = connection(instanceAuth);
    const { data } = await API.put(USER_FINALIZE_REGISTER);

    return data;
  };

export const getTypeDocumentServices =
  async (): Promise<TypeUserResponseAPI> => {
    const API = connection(instanceAuth);
    const { data } = await API.get(USER_TYPE_DOCUMENT);

    return data;
  };

type UserExistProps = { username: string };
export const getUserExistServices = async (
  body: UserExistProps
): Promise<ValidateUserResponseAPI> => {
  const API = connection(instanceAuth);
  const { data } = await API.post(AUTH_VALID_USER, body);

  return data;
};

type SendCodeProps = { username: string };
export const sendCodeBySMSServices = async (
  body: SendCodeProps
): Promise<ValidateUserResponseAPI> => {
  const API = connection(instanceAuth);
  const { data } = await API.post(AUTH_VALID_SMS, body);

  return data;
};

type ValidateCode = { username: string; codigo: string };
export const validateCodeServices = async (
  body: ValidateCode
): Promise<ValidateUserResponseAPI> => {
  const API = connection(instanceAuth);
  const { data } = await API.post(AUTH_VALID_CODE, body);

  return data;
};

type RegisterUser = { username: string; password: string };
export const registerUserServices = async (
  body: RegisterUser
): Promise<ValidateUserResponseAPI> => {
  const API = connection(instanceAuth);
  const { data } = await API.post(AUTH_USER_REGISTER, body);

  return data;
};

type ChangePassword = { passwordActual: string; passwordNueva: string };
export const changePasswordServices = async (
  body: ChangePassword
): Promise<ValidateUserResponseAPI> => {
  const API = connection(instanceAuth);
  const { data } = await API.put(CHANGE_PASSWORD, body);

  return data;
};

// Reset Password
type ResetPassword = { username: string; nuevaPassword: string };
export const resetPasswordServices = async (
  body: ResetPassword
): Promise<ValidateUserResponseAPI> => {
  const API = connection(instanceAuth);
  const { data } = await API.put(AUTH_RESET_PASSWORD_USER, body);

  return data;
};

export const updateProfileDataServices = async (
  body: any
): Promise<UserResponseAPI> => {
  const path = UPDATE_USER_DATA;
  const headers = HEADERS_MULTIPART;
  const formData = setFormDataByObject(body);

  const API = connection(instanceAuth);
  const { data } = await API.put(path, formData, { headers });

  return data;
};

type ChangePhone = { nuevoNumero: string };
export const changeProfilePhoneServices = async (
  body: ChangePhone
): Promise<UserResponseAPI> => {
  const path = CHANGE_USER_PHONE;
  const API = connection(instanceAuth);
  const { data } = await API.put(path, body);

  return data;
};

export const getProfileVehicleServices = async () => {
  const path = USER_VEHICLES;
  const API = connection(instanceAuth);
  const { data } = await API.get(path);

  return data;
};

export const getProfileVehicleByIdServices = async (id: string) => {
  const path = USER_VEHICLES_BY_ID.replace(':id', id);
  const API = connection(instanceAuth);
  const { data } = await API.get(path);

  return data;
};

export const deleteVehicleServices = async (id: string) => {
  const path = USER_VEHICLES_BY_ID.replace(':id', id);
  const API = connection(instanceAuth);
  const { data } = await API.delete(path);

  return data;
};

// create vehicle
export const createVehicleRegisterServices = async (
  body: CreateVehicleDTO
): Promise<ResponseVehicleAPI> => {
  const path = USER_CREATE_VEHICLE;
  const API = connection(instanceAuth);
  const headers = HEADERS_MULTIPART;
  const params = setFormDataByObject(body);
  const { data } = await API.post(path, params, { headers });

  return data;
};

export const createVehicleServices = async (
  body: CreateVehicleDTO
): Promise<ResponseVehicleAPI> => {
  const path = USER_VEHICLE_CREATE;
  const API = connection(instanceAuth);
  const { data } = await API.post(path, body);
  return data;
};

// update vehicle
export const updateVehicleServices = async (
  body: CreateVehicleDTO,
  idVehicle: string
): Promise<ResponseVehicleAPI> => {
  const path = USER_VEHICLE_UPDATE.replace(':id', idVehicle);
  const API = connection(instanceAuth);
  const { data } = await API.put(path, body);

  return data;
};

// update photo vehicle
export const updatePhotosVehicleServices = async (body: any, id: string) => {
  const path = USER_VEHICLE_UPDATE_PHOTOS.replace(':id', id);
  const headers = HEADERS_MULTIPART;
  const formData = setFormDataByObject(body);

  const API = connection(instanceAuth);
  const { data } = await API.put(path, formData, { headers });

  return data;
};

export const updateVehiclePhotosService = async (body: any) => {
  const path = USER_CREATE_VEHICLE_PHOTOS;
  const headers = HEADERS_MULTIPART;
  const formData = setFormDataByObject(body);

  const API = connection(instanceAuth);
  const { data } = await API.put(path, formData, { headers });

  return data;
};

export const updatePhotoVehicleServices = async (
  body: any,
  id: string
): Promise<ResponseVehicleAPI> => {
  const path = USER_VEHICLE_UPDATE_PHOTO.replace(':id', id);

  const headers = HEADERS_MULTIPART;

  const formData = setFormDataByObject(body);
  const API = connection(instanceAuth);
  const { data } = await API.put(path, formData, { headers });

  return data;
};
// update photo vehicle tarjeta
export const updatePhotoVehicleTarjetaServices = async (
  body: any,
  id: string
) => {
  const path = USER_VEHICLE_UPDATE_PHOTO_TARJETA.replace(':id', id);

  const headers = HEADERS_MULTIPART;
  const formData = setFormDataByObject(body);
  const API = connection(instanceAuth);
  const { data } = await API.put(path, formData, { headers });

  return data;
};

export const postVehiclesByUserServices = async (body: any) => {
  const path = USER_VEHICLES;
  const API = connection(instanceAuth);
  const { data } = await API.post(path, body);

  return data;
};

// notificaciones
export const postNotificationsServices = async (
  body: TNotificacionesBody
): Promise<INotificationAPI> => {
  const path = NOTIFICATION_LIST;
  const API = connection(instanceAuth);
  const { data } = await API.post(path, body);

  return data;
};

// AGREEMENTS_TO_ACCEPT
export const getAgreementsToAccept = async (
  body: TAgreementToAcceptBody
): Promise<IAgreementToAcceptAPI> => {
  const path = AGREEMENTS_TO_ACCEPT;
  const API = connection(instanceAuth);
  const { data } = await API.post(path, body);

  return data;
};

// Negotations V2
export const getNegotationsListServices = async (
  body: any,
  signal?: AbortSignal
): Promise<MyNegotiationesListAPI> => {
  const path = MY_NEGOTIATIONS_LIST;
  const API = connection(instanceAuth);

  const { data } = await API.post(path, body, { signal });

  return data;
};
