import CustomRadio from "@/components/form/customRadio/CustomRadio";
import { BASE_URL_IMAGE } from "@/config/config";
import { useAppSelector } from "@/hooks/useReducer";
import { TipoVehiculo } from "@/models/vehicle/vehicleModel";
import { Stack, styled, Typography } from "@mui/material";

const ContainerList = styled(Stack)(
  () => `
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  cursor:pointer;
  display: flex;
  `
);

const Text = styled(Typography)(
  () => `
  flex: 1;
  margin-left: 16px;
  `
);

type Props = {
  onSelect: (selected: TipoVehiculo) => void;
  selected: TipoVehiculo;
};

const VehicleList = ({ onSelect, selected }: Props) => {
  const { vehicleList, isLoading } = useAppSelector((state) => state.vehicle);

  const handleOnSelect = (selected: TipoVehiculo) => {
    onSelect(selected);
  };

  return (
    <>
      {!isLoading &&
        vehicleList.map((vehicle: TipoVehiculo) => {
          return(
          <ContainerList
            key={vehicle.id}
            onClick={() => handleOnSelect(vehicle)}
          >
            <CustomRadio checked={selected.id === vehicle.id} />
            <img
              src={`${BASE_URL_IMAGE}${vehicle.urlImagen}`}
              alt={vehicle.nombre}
              style={{ width: 72, height: 72}}
            />
            <Text>{vehicle.nombre}</Text>
          </ContainerList>
        )})}
    </>
  );
};

export default VehicleList;
