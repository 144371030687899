import {
  Container,
  FormControl,
  ListItem,
  ListItemAvatar,
  styled,
  Typography,
  experimental_sx as sx,
  TextField,
  TextFieldProps,
  Grid,
  Box,
  Card,
} from '@mui/material';
import { theme } from '@/styles/theme';
import CloseIcon from '@mui/icons-material/Close';

export const ContainerGrid = styled(Grid)(
  () => `
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  `
);

export const SubTitleConditions = styled(Typography)(
  ({ theme }) => `
      font-family: ${theme.typography.h6.fontFamily};
      font-size: ${theme.typography.body1.fontSize};
      line-height: ${theme.typography.body2.lineHeight};
      margin-top: 16px;
    `
);

export const SuggestionsListItem = styled(ListItem)(
  () => `
      padding: 0;
      margin-top: 21px;
  
      & .MuiListItemText-root {
        margin: 0;
      }
    `
);

export const SuggestionsListItemAvatar = styled(ListItemAvatar)(
  () => `
      min-width: 30px
    `
);

export const ContainerArea = styled(Container)(
  () => `
      padding: 16px 0;
    `
);

export const CustomFormControl = styled(FormControl)(
  () => `
    width: 100%;
    height: 100%;
  `
);

export const FormControlInput = styled(FormControl)(
  () => `
  padding: 16px 16px 0 16px;
  `
);

export const BoxContainerInfo = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
}));

export const CustomCard = styled(Card)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minWidth: '275px',
  marginTop: 1,
  marginBottom: '8px',
  padding: '8px',
  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: '8px',
}));

export const CardItemBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const CustomCloseIcon = styled(CloseIcon)(() => ({
  cursor: 'pointer',
  color: 'borderGray.contrastText',
  width: '24px',
  height: '24px',
  marginLeft: '8px',
}));

export const HeaderBoxContainer = styled(Box)(() => ({
  marginTop: '16px',
  marginBottom: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  paddingRight: '42px',
}));

export const StyleCustomInput = styled(TextField)<TextFieldProps>(
  sx({
    width: '100%',
    '& .MuiFormLabel-root': {
      fontFamily: theme.typography.label3.fontFamily,
      fontSize: '16px',
      fontWeight: theme.typography.label3.fontWeight,
      lineHeight: theme.typography.label3.lineHeight,
      color: 'rgba(0, 0, 0, 0.6)',
    },
    '& .MuiInputBase-input::placeholder': {
      fontFamily: theme.typography.placeholder.fontFamily,
      fontStyle: theme.typography.placeholder.fontStyle,
      fontSize: theme.typography.placeholder.fontSize,
      lineHeight: theme.typography.placeholder.lineHeight,
      fontWeight: theme.typography.placeholder.fontWeight,
      letterSpacing: theme.typography.placeholder.letterSpacing,
    },
    '& .MuiFormHelperText-root': {
      letterSpacing: 0,
    },
  })
);

export const AddBtnContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.main,
  padding: '4px',
  paddingLeft: '15px',
  cursor: 'pointer',
  minHeight: '45px',
  borderRadius: '8px',
  marginTop: '16px',
  userSelect: 'none',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
}));

export const errorStyle = {
  // borders rojos y shadow roja
  border: '1px solid ' + theme.palette.error.main,
  boxShadow: 'none',
};
