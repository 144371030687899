type Input = {
    label: string;
    placeholder: string;
  };
  
  interface Multidestiny {
    field: string,
    firstInput: Input;
    secondInput: Input;
  }

export const EMPTY_MULTIDESTINY: Multidestiny = {
  field: "",
    firstInput: {
      label: "",
      placeholder: "",
    },
    secondInput: {
      label: "",
      placeholder: "",
    },
  };
  
  export const HOUR_MULTIDESTINY: Multidestiny = {
    field: "horasrequeridas",
    firstInput: {
      label: "Número de horas requeridas",
      placeholder: "Cantidad de horas, Ej. 5",
    },
    secondInput: {
      label: "Precio por hora (S/)",
      placeholder: "Precio de horas (S/)",
    },
  };
  
  export const POINT_MULTIDESTINY: Multidestiny = {
    field: "puntosrequeridos",
    firstInput: {
      label: "Número de puntos de entrega",
      placeholder: "Cantidad de puntos de entrega",
    },
    secondInput: {
      label: "Precio por punto de entrega (S/)",
      placeholder: "Lo que pagarías por punto o parada",
    },
  };
  