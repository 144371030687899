import {
  getListNoticeByIdServices,
  getListNoticeOffertPrivateServices,
} from '../../services/noticeServices';
import { getMessaggeError } from '../auth/utils';
import { AppDispatch } from '../store';

import {
  DetailOfTheNoticeCoreAPIModel,
  DetailsCoreModel,
} from '@/models/notice/noticeModels';
import {
  setDataAuction,
  setDataBetweenCities,
  setDataInCity,
  setDataMoving,
  setDataMultiDestiny,
  setError,
  setLoadingDetailsNotice,
  setUserMessage,
} from '../detailsNotice/detailsNoticeSlice';
import { saveDataFlow } from '../saveFlow/saveFlowSlice';
import {
  ID_AUCTION_FLOW,
  ID_BETWEEN_CITIES,
  ID_IN_CITY,
  ID_MOVING_FLOW,
  ID_MULTI_DESTINY,
} from '@/consts/defaultConsts';
import { getDataByMetadata } from '@/adapters/store/noticeStoreAdapter';

export interface DetailsNoticeInCitySaveIn {
  saveIn: number;
}

const objectSave: Record<DetailsNoticeInCitySaveIn['saveIn'], any> = {
  [ID_IN_CITY]: setDataInCity,
  [ID_BETWEEN_CITIES]: setDataBetweenCities,
  [ID_MULTI_DESTINY]: setDataMultiDestiny,
  [ID_MOVING_FLOW]: setDataMoving,
  [ID_AUCTION_FLOW]: setDataAuction,
};

export const getDetailsNoticeInCityByIdThunk = (idCity: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoadingDetailsNotice(true));
    try {
      const { avisoData, metadata } = await getListNoticeByIdServices<
        DetailOfTheNoticeCoreAPIModel<DetailsCoreModel>
      >(idCity);
      const [aviso] = avisoData.avisos;

      const funcSave = objectSave[aviso?.tipoaviso?.id];

      const { codigo, dato } = getDataByMetadata(metadata);
      if (codigo === '01') {
        dispatch(setUserMessage(dato));
      }

      dispatch(funcSave(aviso));

      return aviso;
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setError(error));

      const couseError = new Error(`${error?.status}`);

      throw new Error(error.message || undefined, { cause: couseError });
    }
  };
};

// DEPRECATE!!
export const getNoticeByIdThunk = (id: number | string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoadingDetailsNotice(true));
    try {
      const result = await getListNoticeByIdServices<any>(id.toString());

      dispatch(saveDataFlow(result?.avisoData?.avisos));
      return result;
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setError(error));

      const couseError = new Error(`${error?.status}`);

      throw new Error(error.message || undefined, { cause: couseError });
    }
  };
};

export const getNoticeByIdPrivateThunk = (id: number | string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoadingDetailsNotice(true));
    try {
      const { avisoData, metadata } = await getListNoticeOffertPrivateServices(
        id.toString()
      );
      const [aviso] = avisoData.avisos;

      const funcSave = objectSave[aviso?.tipoaviso?.id];

      const { codigo, dato } = getDataByMetadata(metadata);
      if (codigo === '01') {
        dispatch(setUserMessage(dato));
      }

      dispatch(funcSave(aviso));
      return avisoData;
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setError(error));

      const couseError = new Error(`${error?.status}`);

      throw new Error(error.message || undefined, { cause: couseError });
    }
  };
};
