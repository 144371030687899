import useAppRedirectHook from "@/hooks/useRedirectApp";
import { useEffect } from "react";

const useRedirectAppStoreHook = () => {
    const {
        methods: { handleRedirect },
    } = useAppRedirectHook()

    useEffect(() => {
        handleRedirect()
    }, []);

    return null;


};



export default useRedirectAppStoreHook;