import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import CustomRadio from "@/components/form/customRadio/CustomRadio";
import HeaderModal from "@/components/modals/headerModal/HeaderModal";
import {
  ContainerButtom,
  CustomDrawer,
} from "@/components/modals/stylesContainerFlow";
import { FormControl, FormControlLabel, RadioGroup } from "@mui/material";
import { useState } from "react";
import { ModalFilterChildrenProps } from "../../interface/filterInterface";

interface Props
  extends Omit<
    ModalFilterChildrenProps,
    "isMultiSelect" | "betweenDate" | "typeNoticeSelected" | "onInputChange"
  > {}

const ModalWeightOfTheLoad = (props: Props) => {
  const { open, onClose, onSeleted, onSubmit, list, metaData, prevSelected } =
    props;
  const [selected, setSelected] = useState("");

  const handleClick = (value: any) => () => {
    setSelected(value);
    onSeleted && onSeleted(value);
  };

  const handleOnsubmit = () => {
    onSubmit && onSubmit(selected);
  };

  return (
    <CustomDrawer open={open} onClose={() => onClose(false)} anchor="right">
      <>
        <HeaderModal title={metaData.title} onClick={() => onClose(false)} />
        <FormControl sx={{ padding: "16px 16px 0 16px" }}>
          <RadioGroup
            aria-labelledby="radio-weight-of-the-load"
            defaultValue={prevSelected || ""}
            name="radio-weight-of-the-load"
          >
            {list?.map(({ nombre, ...rest }, i) => (
              <FormControlLabel
                key={`list-weight${i}`}
                value={nombre}
                control={<CustomRadio />}
                label={nombre}
                sx={{ padding: "10px 0" }}
                onClick={handleClick({ nombre, ...rest })}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <ContainerButtom>
          <ButtomLoader disabled={!selected} onClick={handleOnsubmit}>
            {metaData.buttonText}
          </ButtomLoader>
        </ContainerButtom>
      </>
    </CustomDrawer>
  );
};

export default ModalWeightOfTheLoad;
