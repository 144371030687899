import TagsCustom from "@/components/TagsCustom";
import { TipoAviso } from "@/components/infoCard/styleInfoCard";
import { optionOne } from "@/consts/noticeConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { ISolicitudNegociacionNegList } from "@/models/customerRequest/RequestNegListDataResponse";
import { getListNegotiationThunk } from "@/store/customerRequestRedux/customerRequestFilterThunk";
import { theme } from "@/styles/theme";
import Icon, { IconTypes } from "@/utils/Icon";
import { Container, Grid, IconButton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ID_MULTI_DESTINY } from "../../../../consts/defaultConsts";
import {
  HeaderContainer,
  NoticeOffertStyles,
} from "../noticeOffert/styleNoticeOffert";
import ListChildren from "./components/ListChildren";


export interface IOffertState {
  id: string;
  title: string;
  icon: IconTypes;
  origin: string;
  destiny: string;
  date: string;
  typeSolicitudId: number;
}


 const CustomerRequestOffert = () => {
  const { containerStyles, currentInfoStyles } = NoticeOffertStyles;
  const {
    customerRequest: {
      negSolicitudList,
      isLoading,
    },
  } = useAppSelector((state) => state);

  const {
    abiertas,
    acuerdos,
    cerradas,
  } = negSolicitudList as ISolicitudNegociacionNegList;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    state
  } = useLocation();
  const { id = "" } = useParams();
  const {
    title,
    icon,
    origin,
    date,
    typeSolicitudId,
  } = state as IOffertState;


  useEffect(() => {
    dispatch(
      getListNegotiationThunk(id)
    );
    return () => { };
  }, []);


  return (
    <Container maxWidth="xl" sx={containerStyles}>
      <Grid container>
        <Grid item xs={12}>
          <HeaderContainer maxWidth={290}>
            <IconButton aria-label="back" onClick={() => navigate(-1)}>
              <i className={"icon-arrow-left--primary"} />
            </IconButton>
            <Typography variant="h6">Historial de negociaciones</Typography>
          </HeaderContainer>
        </Grid>

        <Grid item xs={12} sx={currentInfoStyles}>
          <TipoAviso variant="caption" >
            <Icon name={icon} size={20} />
            {title}
          </TipoAviso>
          <Typography variant="h6">
            {typeSolicitudId === ID_MULTI_DESTINY
              ? "Distritos de cobertura"
              : origin}
          </Typography>
          {
            typeSolicitudId === ID_MULTI_DESTINY && (
              <Typography variant="body2">
                {origin}
              </Typography>
            )
          }
          <Typography variant="body2" color={theme.typography.caption.color}>{date}</Typography>
        </Grid>

        <Grid item xs={12} mt="16px">
          <TagsCustom
            items={[
               {
                label: 'Por cerrar',
                value: 1,
                panel: <ListChildren
                  requestNegList={abiertas || []}
                  isLoading={isLoading}
                />
              }, 
             {
                label: 'Acuerdo',
                value: 2,
                panel: <ListChildren
                  requestNegList={acuerdos || []}
                  isLoading={isLoading}
                />
              }, 
              {
                label: 'Vencidas',
                value: 3,
                panel: <ListChildren
                  requestNegList={cerradas || []}
                  isLoading={isLoading}
                />
              },
            ]}
            arialLabel="Negociaciones de la solicitud"
            initialTag={optionOne}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CustomerRequestOffert