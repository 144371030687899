import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import { filterSlice } from './FilterServices/filterSlice';
import { advertisementSlice } from './advertisementRedux/advertisementSlice';
import { authUserSlice } from './auth/authUserSlice';
import { customerRequestSlice } from './customerRequestRedux/customerRequestSlice';
import { includedServicesSlice } from './includedServices/includedServicesSlice';
import { landingContactServicesSlice } from './landingServices/landingContact/landingContactServiceSlice';
import { landingFAQServicesSlice } from './landingServices/landingFAQ/landingFAQServiceSlice';
import { citiesSlice } from './locationRedux/citiesSlice';
import { districtsSlice } from './locationRedux/districtsSlice';
import { selectLocationSlice } from './locationRedux/selectLocation';
import { zonesSlice } from './locationRedux/zonesSlice';
import { noticeNegRequestSlice } from './noticeNegotationRequestRedux/noticeNegRequestSlice';
import { noticeSlice } from './noticeRedux/noticeSlice';
import { publicNoticeSlice } from './noticeRedux/publicNoticeSlice';
import { offertSlice } from './offertRedux/offert.slice';
import { ratingAgreementSlice } from './ratingAgreementRedux/ratingAgreementSlice';
import { routeActionsFlowNoticeSlice } from './routeActionsFlow/routeActionsFlowNoticeSlice';
import { saveFlowSlice } from './saveFlow/saveFlowSlice';
import { userSlice } from './userRedux/userSlice';
import { vehicleSlice } from './vehicle/vehicleSlice';
import { myNoticeSlice } from './noticeRedux/myNoticeSlice';
import { filterNoticeSlice } from './filterNoticeRedux/filterNoticeSlice';
import { routeActionsFlowNoticeV2Slice } from './routeActionsFlowV2/routeActionsFlowNoticeV2Slice';
import { noticeV2Slice } from './noticeV2Redux/noticeV2Slice';
import { noticeV2ClientAorCOSlice } from './noticeV2ClientAcceptCounterOffer/noticeClientAcceptOrCounterOfferSlice';
import { detailsNoticeSlice } from './detailsNotice/detailsNoticeSlice';
import { noticeV2Negotiation } from './noticeV2Negotiation/noticeV2NegotiationSlice';
import { offerListSlice } from './offerListRedux/offerListSlice';
import { myListNegotiationsSlice } from './myListNegotiationsRedux/myListNegotiationsSlice';
import { sharedRideSlice } from './sharedRide/sharedRideSlice';

export const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['user', 'vehicle'],
};

export const appReducer = combineReducers({
  notice: noticeSlice.reducer,
  publicNotice: publicNoticeSlice.reducer,
  user: userSlice.reducer,
  districts: districtsSlice.reducer,
  cities: citiesSlice.reducer,
  zones: zonesSlice.reducer,
  selectLocation: selectLocationSlice.reducer,
  includedServices: includedServicesSlice.reducer,
  saveFlowData: saveFlowSlice.reducer,
  routeFlowNotice: routeActionsFlowNoticeSlice.reducer,
  landingContact: landingContactServicesSlice.reducer,
  landingFAQ: landingFAQServicesSlice.reducer,
  auth: authUserSlice.reducer,
  filter: filterSlice.reducer,
  vehicle: vehicleSlice.reducer,
  offert: offertSlice.reducer,
  customerRequest: customerRequestSlice.reducer,
  noticeNegotationRequest: noticeNegRequestSlice.reducer,
  advertisement: advertisementSlice.reducer,
  ratingAgreement: ratingAgreementSlice.reducer,
  myNotice: myNoticeSlice.reducer,
  filterNotice: filterNoticeSlice.reducer,
  routeFlowNoticeV2: routeActionsFlowNoticeV2Slice.reducer,
  noticeV2: noticeV2Slice.reducer,
  noticeV2ClientAorCO: noticeV2ClientAorCOSlice.reducer,
  detailsNoticeSlice: detailsNoticeSlice.reducer,
  noticeV2NegotiationSlice: noticeV2Negotiation.reducer,
  offerList: offerListSlice.reducer,
  myListNegotiations: myListNegotiationsSlice.reducer,
  sharedRide: sharedRideSlice.reducer,
});

// estado inicial del redux, cuando se sale de un flujo!!
export const initialReducerFlow = {
  notice: noticeSlice.getInitialState(),
  publicNotice: publicNoticeSlice.getInitialState(),
  districts: districtsSlice.getInitialState(),
  cities: citiesSlice.getInitialState(),
  zones: zonesSlice.getInitialState(),
  selectLocation: selectLocationSlice.getInitialState(),
  includedServices: includedServicesSlice.getInitialState(),
  saveFlowData: saveFlowSlice.getInitialState(),
  auth: authUserSlice.getInitialState(),
  offert: offertSlice.getInitialState(),
  customerRequest: customerRequestSlice.getInitialState(),
  noticeNegotationRequest: noticeNegRequestSlice.getInitialState(),
  advertisement: advertisementSlice.getInitialState(),
  myNotice: myNoticeSlice.getInitialState(),
  detailsNoticeSlice: detailsNoticeSlice.getInitialState(),
  offerList: offerListSlice.getInitialState(),
  myListNegotiations: myListNegotiationsSlice.getInitialState(),
};

// estado inicial del redux, cuando se realiza un logout
export const initialReducerLogout = {
  publicNotice: publicNoticeSlice.getInitialState(),
  user: userSlice.getInitialState(),
  districts: districtsSlice.getInitialState(),
  cities: citiesSlice.getInitialState(),
  zones: zonesSlice.getInitialState(),
  selectLocation: selectLocationSlice.getInitialState(),
  includedServices: includedServicesSlice.getInitialState(),
  saveFlowData: saveFlowSlice.getInitialState(),
  routeFlowNotice: routeActionsFlowNoticeSlice.getInitialState(),
  landingContact: landingContactServicesSlice.getInitialState(),
  landingFAQ: landingFAQServicesSlice.getInitialState(),
  auth: authUserSlice.getInitialState(),
  filter: filterSlice.getInitialState(),
  vehicle: vehicleSlice.getInitialState(),
  offert: offertSlice.getInitialState(),
  customerRequest: customerRequestSlice.getInitialState(),
  noticeNegotationRequest: noticeNegRequestSlice.getInitialState(),
  advertisement: advertisementSlice.getInitialState(),
  ratingAgreement: ratingAgreementSlice.getInitialState(),
  myNotice: myNoticeSlice.getInitialState(),
  detailsNoticeSlice: detailsNoticeSlice.getInitialState(),
  offerList: offerListSlice.getInitialState(),
  myListNegotiations: myListNegotiationsSlice.getInitialState(),
  sharedRide: sharedRideSlice.getInitialState(),
};
