import { Box, Container, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import { IMG_PATH, LG_IMG, MD_IMG } from "@/consts/defaultConsts";
import { IPhotosVehicle } from "@/store/userRedux/userReduxInterface";
import { resizePhoto } from "@/utils/helpers";
import { SyntheticEvent, useState } from "react";
import Carousel from "react-material-ui-carousel";

import { NoticeDetailVehiclePhotosModel } from "@/models/notice/noticeModels";
import { ImageModal } from "@/components/modals/ImageModal/ImageModal";

interface Props {
  listCarrousel: IPhotosVehicle[] | NoticeDetailVehiclePhotosModel[];
  isSuccessDetails?: boolean;
}

const Img = ({ listCarrousel, isSuccessDetails = false }: Props) => {
  const [seletedImage, setSeletedImage] = useState("");

  const validateImg = (imgURL: string) => {

    let newUrl = imgURL;
    if (!imgURL) return "";

    if (imgURL.startsWith("http")) {
      newUrl = imgURL;
    } else {
      newUrl = resizePhoto({ photo: IMG_PATH + imgURL, newFormat: MD_IMG });
    }

    return newUrl;
  };

  const handleResizeImg = (imgUrl: string) => {
    const newImg = !!imgUrl
      ? resizePhoto({
          photo: imgUrl,
          newFormat: LG_IMG,
        })
      : "";

    setSeletedImage(newImg);
  };

  const handleError = (
    e: SyntheticEvent<HTMLImageElement, Event>,
    img: string
  ) => {
    (e.target as HTMLInputElement).src = img;
  };


  return (
    <>      
    <ImageModal
        imageUrl={validateImg(seletedImage)}
        onClose={() => setSeletedImage("")}
      />

      <Container
        disableGutters
        sx={{
      
          textAlign: "center",
          width: {
            xs: "100%",
            // desktop 360px
            sm: "360px",
            // tablet 768px
            md: "360x",
            // desktop 1024px
            lg: "360px",
            // desktop 1280px
            xl: "360px",
          },
        }}
      >
        <Carousel
          indicators={false}
          autoPlay={false}
          height={214}
          animation="slide"
        >
          {listCarrousel.map((item, i) => (
            <Card
              key={`${i}-Carousel`}
              sx={{
                position: "relative",
                borderRadius: 0,
                height: 214,
                width: "100%",
              }}
              onClick={() => setSeletedImage(item?.direccion)}
            >
              <CardMedia
                sx={{ objectFit: "contain", cursor: "pointer" }}
                component="img"
                width="100%"
                height="100%"
                image={validateImg(item.direccion)}
                alt={"vehiculo"}
              />
              <CardContent
                sx={{
                  position: "absolute",
                  bottom: "0px",
                  right: "0px",
                  p: "16px !important",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: "300px",
                    lineHeight: "20px",
                    backgroundColor: "white",
                    px: 2,
                    py: 1,
                    borderRadius: "4px",
                  }}
                >
                  {i + 1}/{listCarrousel.length}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Carousel>
      </Container>

      
    </>
  );
};

export default Img