import { Skeleton, Stack } from "@mui/material";

interface Props {
  arrayLength?: number;
  showHeader?: boolean;
}

const SkeletonEnvironment = ({ arrayLength = 3, showHeader = true }: Props) => {
  const emptyArray = Array.from(Array(arrayLength).keys());

  return (
    <Stack
      spacing={2}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      {showHeader && (
        <>
          <Skeleton variant="rectangular" width="100%" height={83} />

          <Skeleton variant="rectangular" width={72} height={72} />

          <Skeleton variant="rounded" width="100%" height={60} />
        </>
      )}
      {emptyArray.map((item) => (
        <Stack
          key={item}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Skeleton variant="rounded" width={170} height={40} />
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="circular" width={40} height={40} />
        </Stack>
      ))}
    </Stack>
  );
};

export default SkeletonEnvironment;
