export const MAIN_PATH = '';

// ================ USER PATHS ==================
export const USER_BY_ID_PATH = `${MAIN_PATH}usuario/:id`;
export const USER_REGISTER_PHOTO_PROFILE = `${MAIN_PATH}usuario/registro/perfil`;
export const USER_REGISTER_FORM_DATA = `${MAIN_PATH}usuario/registro/formulario`;
export const USER_TYPE_DOCUMENT = `${MAIN_PATH}tipodocumento`;
export const USER_UPLOAD_DOCUMENT_IDENTIFICATION_PATH = `${MAIN_PATH}usuario/registro/documento`;
export const USER_UPLOAD_PROPERTY_CARD = `${MAIN_PATH}vehiculos/tarjeta`;
export const USER_FINALIZE_REGISTER = `${MAIN_PATH}usuario/registro/finaliza`;

// ================ CHANGE PASSWORD ==================
export const CHANGE_PASSWORD = `${MAIN_PATH}usuario/cambiar/password`;
// ================ ACTUALIZAR DATOS ==================
export const UPDATE_USER_DATA = `${MAIN_PATH}usuario/actualizar/perfil`;
// ================ CHANGE PHONE ==================
export const CHANGE_USER_PHONE = `${MAIN_PATH}usuario/cambiar/numero`;

// ================ GET VEHICLES ==================
export const USER_VEHICLES = `${MAIN_PATH}vehiculos`;
export const USER_VEHICLES_BY_ID = `${USER_VEHICLES}/:id`;

// ================ POST VEHICLE ==================
export const USER_VEHICLE_CREATE = `${USER_VEHICLES}/crear`;
export const USER_CREATE_VEHICLE = `${USER_VEHICLES}/registro/general`;
export const USER_CREATE_VEHICLE_PHOTOS = `${USER_VEHICLES}/registro/fotos`;
export const USER_CREATE_VEHICLE_TARJETA = `${USER_VEHICLES}/registro/tarjeta`;

// ================ PUT VEHICLE ==================
export const USER_VEHICLE_UPDATE = `${USER_VEHICLES}/editar/:id`;

// ================ PUT PHOTO VEHICLE ==================
export const USER_VEHICLE_UPDATE_PHOTOS = `${USER_VEHICLES}/agregarFotos/:id`;
export const USER_VEHICLE_UPDATE_PHOTO = `${USER_VEHICLES}/agregarFoto/:id`;

// ================ PUT PHOTO VEHICLE TARJETA ==================
export const USER_VEHICLE_UPDATE_PHOTO_TARJETA = `${USER_VEHICLES}/tarjeta/:id`;

// ==================================================
//                   AUTH PAHS
// ==================================================
export const AUTH_TOKEN_PATH = 'oauth/token';
export const AUTH_VALID_USER = `${MAIN_PATH}auth/validar-usuario`;
export const AUTH_VALID_SMS = `${MAIN_PATH}verify-code/enviar-sms`;
export const AUTH_VALID_CODE = `${MAIN_PATH}verify-code/validar-codigo`;
export const AUTH_USER_REGISTER = `${MAIN_PATH}usuario/registro`;
export const AUTH_RESET_PASSWORD_USER = `${MAIN_PATH}auth/restablecer/password`;
// ==================================================
//                  NOTICE PATHS
// ==================================================

export const NOTICE_TYPE = `${MAIN_PATH}tipoaviso`;

export const NOTICE_LIST = `${MAIN_PATH}aviso`;
export const NOTICE_REGISTER_LIST = `${MAIN_PATH}registro/aviso`;
export const NOTICE_LIST_DISTRICT_SUGGESTED = `${MAIN_PATH}distrito/sugerido/byId/:id`;
export const NOTICE_LIST_CITY_SUGGESTED = `${MAIN_PATH}ciudad/sugerido/byId/:id`;
export const NOTICE_LIST_FILTER = `${MAIN_PATH}aviso-paginado`;
export const NOTICE_LIST_BY_ID = `${MAIN_PATH}aviso/:id`;
export const NOTICE_LIST_BY_ID_PRIV = `${MAIN_PATH}aviso-priv/:id`;
export const NOTICE_HISTORY_LISTING = `${MAIN_PATH}/avisos`;
export const NOTICE_EDIT_DATE = `${MAIN_PATH}aviso/fecha/:id`;
export const NOTICE_EDIT_CONDITIONS = `${MAIN_PATH}aviso/condiciones/:id`;
export const NOTICE_EDIT_PRICE = `${MAIN_PATH}aviso/precio/:id`;
export const NOTICE_RENEW_AVISO = `${MAIN_PATH}aviso/renovacion/:id`;
export const NOTICE_STATE_AVISO = `${MAIN_PATH}aviso/estado/:id`;
export const NOTICE_LIST_OFFERT = `${MAIN_PATH}avisonegociacion/byaviso/:idOffer`;
//
// ofertasactivas/{idAviso}
export const NOTICE_LIST_OFFERT_ACTIVE = `${MAIN_PATH}aviso/ofertasactivas/:id`;

// ==================================================
//                   LOCATION PAHTS
// ==================================================

export const LOCATION_DISTRICTS_BY_CITY = `${MAIN_PATH}distrito/byIdciudad/:id`;
export const LOCATION_DISTRICTS_BY_TERM = `${MAIN_PATH}distrito/byNombre/:term`;
export const LOCATION_CITY_BY_COUNTRY = `${MAIN_PATH}ciudad/byIdpais/:id`;
export const LOCATION_CITY_BY_PAIS_BY_TERM = `${MAIN_PATH}ciudad/byIdpais/:id/byNombre/:term`;

export const LOCATION_ZONAS_BY_CITY = `${MAIN_PATH}distrito/zonasbyIdciudad/:id`; // GET ZONAS BY CITY
export const LOCATION_CITY_BY_TERM = `${MAIN_PATH}ciudad/byNombre/:term`;
export const LOCATION_ONLY_DISTRICTS_BY_CITY = `${MAIN_PATH}distrito/distritosbyIdciudad/:id`;

export const LOCATION_DISTRICTS_BY_CITY_BY_TERM = `${MAIN_PATH}distrito/byIdCiudad/:id/byNombre/:term`;

// ==================================================
//                   CONTACT US PAHTS
// ==================================================

export const TYPES_USERS = `${MAIN_PATH}usuariotipos`;
export const TYPES_MOTIVATIONS = `${MAIN_PATH}contactanosmotivos`;
export const FORM_CONTACT_US = `${MAIN_PATH}contactanos`;

// ==================================================
//                   NOTIFICATION PAHTS
// ==================================================

export const NOTIFICATION_LIST = `${MAIN_PATH}verify-code/notificaciones`;

// ==================================================
//                   FAQ PAHTS
// ==================================================

export const FREQUENT_QUESTIONS = `${MAIN_PATH}preguntasfrecuentes`;

// ==================================================
//                   FILTERS PAHTS
// ==================================================

export const LIST_FILTERS = `${MAIN_PATH}aviso/filtro/:id`;
export const LIST_FILTERV2 = `${MAIN_PATH}aviso/filtro`;
// ==================================================
//                   Vehicle PAHTS
// ==================================================
export const VEHICLE_LIST = `${MAIN_PATH}tipovehiculo`;
export const VEHICLE_REGISTER = `${MAIN_PATH}vehiculos/registro`;
export const VEHICLE_EDIT = `${MAIN_PATH}aviso/vehiculo/:id`;

// ==================================================
//                Negociacion PAHTS
// ==================================================

export const NEGOTIATION_SAVE_PHOTO = `${MAIN_PATH}avisonegociacionFotos/:idNegotiation`;
export const NEGOTIATION_SAVE_DATA = `${MAIN_PATH}avisonegociacion/:idNotice`;
export const NEGOTIATION_GET_CONDITIONS_BY_ID = `${MAIN_PATH}avisonegociacion_condiciones/:idNotice`;
export const NEGOTIATION_GET_CONDITIONS_BY_IDS = `${MAIN_PATH}avisonegociacion_condiciones/:idNotice/:idNegotation`;
export const NEGOTIATION_GET_TYPE_INMUEBLE = `${MAIN_PATH}tiposinmuebles`;
export const NEGOTIATION_GET_DATA = `${MAIN_PATH}avisonegociacion/:idOffert`;
export const NEGOTIATION_SAVE_DATA_ACCEPT = `${MAIN_PATH}avisonegociacion_accion/:idOffer`;

export const MY_NEGOTIATIONS_LIST = `${MAIN_PATH}mis-negociaciones`;

// ================ SAVE IMAGES ======================
export const SAVE_IMAGES_NEGOTIATION_FLOW = `${MAIN_PATH}avisonegociacion/fotosverificadas/:idPhotosVerified`;

// ==================================================
//           Solicitud del cliente PATHS
// ==================================================
export const SAVE_CUSTOMER_REQUEST_PATH = `${MAIN_PATH}solicitud`;
export const SAVE_CUSTOMER_REQUEST_PHOTO_PATH = `${MAIN_PATH}solicitudFotos/:idRequest`;
export const CUSTOMER_REQUEST_TYPES_PATH = `${MAIN_PATH}tipos_solicitud`;
export const CUSTOMER_REQUEST_TYPES_LOAD_PATH = `${MAIN_PATH}tipos_carga`;
export const CUSTOMER_REQUEST_WEIGHT_RANGE_PATH = `${MAIN_PATH}tipos_rango_peso`;
export const CUSTOMER_REQUEST_TYPES_CONDITIONS_PATH = `${MAIN_PATH}condiciones_solicitud/:idRequestType`;
export const CUSTOMER_REQUEST_TYPES_AMBIENTES_PATH = `${MAIN_PATH}tipos_ambiente`;
//
export const CUSTOMER_REQUEST_FILTER_PATH = `${MAIN_PATH}solicitud/filtro/:idCity`;
export const CUSTOMER_REQUEST_LIST_FILTER_PATH = `${MAIN_PATH}filtro/solicitudes`;
//
export const CUSTOMER_REQUEST_LIST_BY_ID_PATH = `${MAIN_PATH}solicitud/:id`;
export const CUSTOMER_REQUEST_LIST_BY_ID_PRIV_PATH = `${MAIN_PATH}solicitud-priv/:id`;
//
export const CUSTOMER_REQUEST_MY_LIST_PATH = `${MAIN_PATH}solicitudes`;

export const CUSTOMER_REQUEST_SAVE_IMAGENS = `${MAIN_PATH}solicitud/fotosverificadas/:idVerified`;

// ==================================================
//            AVISOS PARA LA NEGOCIACION
// ==================================================

export const NOTICE_NEGOTATION_REQUEST_PATH = `${MAIN_PATH}solicitudnegociacion/:idRequestType`;

// ==================================================
//      TRANSPORTISTA RESPONDE AL CLIENTE
// ==================================================

export const NOTICE_NEGOTATION_RESPONSE_PATH = `${MAIN_PATH}solicitudnegociacion/:idRequestType`;

// ==================================================
//       CLIENTE RESPONDE AL TRANSPORTISTA
// ==================================================

export const NOTICE_NEGOTATION_CLIENT_RESPONSE_PATH = `${MAIN_PATH}solicitudnegociacion_accion/:idRequestType`;

// =====================================================================
//       URL PARA LA EDICIÓN DE LA SOLICITUD POR PARTE DEL CLIENTE
// =====================================================================

export const EDIT_CLIENT_NEGOTATION_RUTE = `${MAIN_PATH}solicitud/ruta/:idRequestType`;

export const EDIT_CLIENT_NEGOTATION_LOAD = `${MAIN_PATH}solicitud/carga/:idRequestType`;

export const EDIT_CLIENT_NEGOTATION_CONDITIONS = `${MAIN_PATH}solicitud/condiciones/:idRequestType`;

export const EDIT_CLIENT_NEGOTATION_PRICE = `${MAIN_PATH}solicitud/precio/:idRequestType`;

// ==============================================================================================
//       URL PARA LA EDICIÓN DE LA SOLICITUD POR PARTE DEL CLIENTE PERO EN EL FLUJO DE MUDANZA
// ==============================================================================================

export const EDIT_CLIENT_NEGOTATION_DATE = `${MAIN_PATH}solicitud/ruta/:idRequestType`; //TODO:

export const EDIT_CLIENT_NEGOTATION_ORIGIN = `${MAIN_PATH}solicitud/rutaorigen/:idRequestType`;

export const EDIT_CLIENT_NEGOTATION_DESTINY = `${MAIN_PATH}solicitud/rutadestino/:idRequestType`;

export const EDIT_CLIENT_NEGOTATION_ENVIRONMENT = `${MAIN_PATH}solicitud/ambientes/:idRequestType`;

// ==============================================================================================
//       URL PARA LA EDICIÓN DE LA SOLICITUD POR PARTE DEL CLIENTE PERO EN EL FLUJO DE MUDANZA
// ==============================================================================================

export const CLIENT_RENEW_NEGOTATION_REQUEST = `${MAIN_PATH}solicitud/renovacion/:idRequestType`;

// ==============================================================================================
//      URL PARA VALIDAR SI LA SOLICITUD TIENE OFERTAS O CONTRAOFERTAS
// ==============================================================================================

export const AMOUNT_NEGOTATION = `${MAIN_PATH}solicitud/negociaciones_cuantas`;

// ==============================================================================================
//      URL PARA LISTAR LAS OFERTAS DEL CLIENTE ACTIVAS Y CANCELADAS
// ==============================================================================================
export const LIST_OFFERS_CLIENT = `${MAIN_PATH}solicitudnegociacion/bysolicitud/:idRequest`;

// ==============================================================================================
//      URL PARA TRAER LAS FOTOS
// ==============================================================================================
export const GET_PHOTOS_CLIENT = `${MAIN_PATH}solicitud/foto/:idPhoto`;

// ==================================================
//                   Advertisement
// ==================================================
export const ADVERTISEMENT_LIST = `${MAIN_PATH}anuncios/mostrar`;
export const CLICK_ADVERTISEMENT = `${MAIN_PATH}anuncios/click`;
export const SHOW_ADVERTISEMENT = `${MAIN_PATH}anuncios/impresion`;

// ==================================================
//                  Sponsored
// ==================================================
export const SAVE_CLICK_OF_SPONSORED_NOTICE = `${MAIN_PATH}patrocinio/click`;

// ==================================================
//                   Acuerdos por aceptar
// ==================================================

export const AGREEMENTS_TO_ACCEPT = `${MAIN_PATH}acuerdosporaceptar`;
//                   Agreement
//     aviso: idAviso/0     solicitud: 0/idSolicitud
// ==================================================

export const GET_RATING_AGREEMENT_DATA = `${MAIN_PATH}calificacion/:idAviso/:idSolicitud`;
export const SEND_RATING_AGREEMENT_DATA = `${MAIN_PATH}calificacion`;
export const GET_USER_HISTORY_RATING_DATA = `${MAIN_PATH}calificacion/usuario_calificaciones/:idUser`;

// ==================================================
//                   v2 negociacion
// ==================================================

export const GET_V2_OFFERTS_AVISO = `${MAIN_PATH}v2/aviso/negociaciones`;
export const GET_V2_OFFERTS_SOLICITUD = `${MAIN_PATH}v2/solicitud/negociaciones`;

// ==============================================================================================
//       URL PARA LA ELIMINACIÓN DE LA SOLICITUD
// ==============================================================================================

export const REMOVE_NOTICE_REQUEST = `${MAIN_PATH}solicitud/cancelar/:idRequestType`;

// ==================================================
//      URL PARA LA EDICION DE LA NEGOCIACIÓN
// ==================================================

export const EDIT_NEGOTATION_NOTICE_REQUEST = `${MAIN_PATH}solicitudnegociacion/precio/:idRequestType`;

export const EDIT_OFFER_NEGOTATION_NOTICE_REQUEST = `${MAIN_PATH}avisonegociacion/precio/:idNotice`;

// ==================================================
//                 Share Travel Paths
// ==================================================

export const SHARE_RIDE = `${MAIN_PATH}viaje-compartido/ver/:idShare`;
// Sokect paths
export const SHARE_RIDE_SUSCRIBE_MOVING =
  '/user/:idTransportista/solicitudexpressnegociacion/:idNegociacion';
export const SHARE_RIDE_SUSCRIBE_CANCEL =
  '/user/:idNegociacion/viaje-compartido-negociacio';
