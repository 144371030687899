import React from "react";
import CheckList from "./CheckList";
import FilterDate from "./FilterDate";
import {
  TiposFiltro,
  TTypeOptions,
} from "@/store/FilterServices/filterReduxInterface";
import SearchList from "./SearchList";

interface Props {
  data: TiposFiltro;
  handleCheck: (
    value: string | string[],
    tipo: number,
    nombre?: string | string[]
  ) => void;
  checkArrFilters: TiposFiltro[];
  TipoAviso?: TTypeOptions | string;
}
const OriginOrDestini = ["Origen", "Destino"];
const DrawerTypes = ({
  data,
  handleCheck,
  checkArrFilters,
  TipoAviso,
}: Props) => {
  const cityChange = (tipo: string, nombre: string) => {
    switch (nombre) {
      case "Origen":
        return "city";
      case "Destino":
        return "city2";
      default:
        return "city";
    }
  };

  return (TipoAviso === "C" || TipoAviso === "DC") &&
    OriginOrDestini.includes(data.nombre) ? (
    <>
      <SearchList
        modalActiveWith={cityChange(TipoAviso, data?.nombre)}
        handleCheck={handleCheck}
        checkArrFilters={checkArrFilters}
        tipo={TipoAviso}
      />
    </>
  ) : (
    <>
      {data.tipoComponente === 1 || data.tipoComponente === 2 ? (
        <CheckList
          handleCheck={handleCheck}
          data={data}
          checkArrFilters={checkArrFilters}
        />
      ) : (
        <FilterDate
          handleCheck={handleCheck}
          data={data}
          checkArrFilters={checkArrFilters}
        />
      )}
    </>
  );
};

export default DrawerTypes;
