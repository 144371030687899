import { styled } from '@mui/material/styles';
import { Container, Grid, Stack, Typography } from '@mui/material';

export const ContainerNotice = styled(Container)(({ theme }) => ({
  maxWidth: '1152px !important',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '16px',
  ' @media only screen and (min-width: 768px)': {
    padding: '30px 16px',
  },
}));

export const ContainerHeader = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  maxWidth: '1120px',
}));

export const Title = styled(Typography)(
  ({ theme }) => `
    font-weigth: 700;
    font-size: 22px;
    color: ${theme.palette.text.secondary};
    
  `
);

export const SubTitle = styled(Typography)(
  ({ theme }) => `
    font-weight: ${theme.typography.body1.fontWeight};
    font-size: ${theme.typography.body1.fontSize};
    line-height: ${theme.typography.body1.lineHeight};
    color: ${theme.palette.text.secondary};
    font-family: ${theme.typography.body1.fontFamily};
  `
);

export const ContainerItems = styled(Stack)(() => ({
  gap: '16px',
  width: '100%',
  '@media only screen and (min-width: 899px)': {
    maxWidth: '1120px',
    padding: '32px 0px',
    flexDirection: 'row',
    alignSelf: 'center',
  },
}));
