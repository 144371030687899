import { ConfigDataBodyOffert } from "@/pages/offerts/offertConfirmation/components/offertBodyConfirmation/configDataOffertBodyInCity";
import {
  getFormatPrice,
  getFormatPriceMoving,
  getFormatSendDate,
  getFormatSendDateMoving,
  getTitleAvisoOffertIsOwnerCounterOffert,
  getTitleByPrice,
} from "@/pages/offerts/offertConfirmation/utils/utilsOffert";
import { Box, Typography } from "@mui/material";

export const configDataOfferSection: ConfigDataBodyOffert[] = [
  {
    title: "",
    Component: Box,
    props: {
      variant: "body2",
      sx: { width: "100%", borderTop: "1px solid #E0E0E0", mt: "16px" },
    },
  },
  {
    Component: Typography,
    props: {
      variant: "body2",
      sx: { color: "#6D6D6D", mt: "16px" },
      title: "Contraoferta del transportista", //TODO: Se cambia esta linea de "Tu contraoferta" a "contraoferta del transportista"
    },
    valid: [
      {
        property: "flgIsOwner",
        componentProp: "title",
        defaultResult: "Contraoferta del transportista",
        callback: getTitleAvisoOffertIsOwnerCounterOffert(1),
      },
    ],
  },
  {
    Component: Typography,
    props: {
      title: "Precio de punto a punto",
      variant: "body2",
    },
    valid: [
      {
        property: "precio",
        componentProp: "show",
        defaultResult: false,
      },
      {
        property: "precio",
        componentProp: "title",
        defaultResult: "",
        callback: getTitleByPrice,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      variant: "h5",
      sx: { textTransform: "inherit" },
    },
    valid: [
      {
        property: "preciocontraoferta",
        componentProp: "title",
        defaultResult: 0,
        callback: getFormatPrice,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      sx: { color: "rgba(0, 0, 0, 0.6)", fontSize: "12px", lineHeight: "20px" },
    },
    valid: [
      {
        property: "fechaContraTransportista",
        componentProp: "title",
        defaultResult: 0,
        callback: getFormatSendDate,
      },
    ],
  },
];

export const configDataMoving: ConfigDataBodyOffert[] = [
  {
    title: "",
    Component: Box,
    props: {
      variant: "body2",
      sx: { width: "100%", borderTop: "1px solid #E0E0E0", mt: "16px" },
    },
  },
  {
    Component: Typography,
    props: {
      variant: "body2",
      sx: { color: "#6D6D6D", mt: "16px" },
      title: "Cotización del transportista",
    } ,
    valid: [
      {
        property: "flgIsOwner",
        componentProp: "title",
        defaultResult: "Contraoferta del transportista",
        callback: getTitleAvisoOffertIsOwnerCounterOffert(1),
      },
    ], 
  },
  {
    title: "Por viaje",
    Component: Typography,
    props: {
      variant: "body2",
      sx: { color: "#212121", mt: "16px" },
    },
  },
  {
    Component: Typography,
    props: {
      variant: "h4",
      sx: { fontSize: "22px" },
    },
    valid: [
      {
        property: "preciocontraoferta",
        componentProp: "title",
        defaultResult: 0,
        callback: getFormatPriceMoving,
      },
    ],
  },
  {
    Component: Typography,
    props: {
      sx: { color: "rgba(0, 0, 0, 0.6)", fontSize: "12px", lineHeight: "20px" },
    },
    valid: [
      {
        property: "fechaContraTransportista",
        componentProp: "title",
        defaultResult: 0,
        callback: getFormatSendDateMoving,
      },
    ],
  },
];
