import { optionOne, optionTwo } from "@/consts/noticeConsts";
import { DetailsNegotationV2 } from "@/models/notice/noticeV2NegotationModels";
import { useGetComponentByStatusNeg } from "@/pages/noticeV2Negotation/shared/hooks/useGetComponentByStatusNeg";
import useNoticeConfigDataV2 from "@/pages/noticeV2Negotation/shared/hooks/useNoticeConfigDataV2";
import DynamicComponent from "@/pages/offerts/offertConfirmation/components/DynamicComponent";
import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import { Grid } from "@mui/material";
import { detailsConfigAccept, detailsConfigCounterOffer } from "./config";

interface Props {
  notice: NoticeV2NegType;
}

const configToRender = {
  [optionOne]: detailsConfigAccept,
  [optionTwo]: detailsConfigCounterOffer,
};

const AuctionReject = ({ notice }: Props) => {
  const { selectdConfigDataV2 } = useGetComponentByStatusNeg({
    notice,
    configToRender,
  });

  const { handleInitConfig } = useNoticeConfigDataV2(
    notice as DetailsNegotationV2
  );

  return (
    <>
      {selectdConfigDataV2.map((detail, i) => {
        return (
          <Grid
            key={`${i}-detail`}
            container
            gap={1}
            alignItems="center"
            marginBottom="6px"
          >
            <DynamicComponent
              key={`detail-${i}`}
              {...detail}
              {...handleInitConfig(detail)}
            />
          </Grid>
        );
      })}
    </>
  );
};

export default AuctionReject;