import {
  NoticeResponseAPI,
  NoticeResponseHistoryApi,
} from '@/models/notice/noticeModels';
import { connection } from '@/services/connection';
import {
  NOTICE_EDIT_CONDITIONS,
  NOTICE_EDIT_DATE,
  NOTICE_EDIT_PRICE,
  NOTICE_HISTORY_LISTING,
  NOTICE_LIST,
  NOTICE_LIST_BY_ID,
  NOTICE_LIST_BY_ID_PRIV,
  NOTICE_LIST_FILTER,
  NOTICE_LIST_OFFERT_ACTIVE,
  NOTICE_RENEW_AVISO,
  NOTICE_STATE_AVISO,
  NOTICE_TYPE,
} from '../consts/pathServices';
import {
  EditNoticeConditionsServices,
  INoticeRenewServices,
  INoticeStatusServices,
  NoticeEditPriceServices,
} from './interfaces/noticeInterfaces';
import { BodyNoticesActivesAndHistoryDTO } from '@/interfaces/noticeInterface';
import {
  FilterListAPI,
  FilterNoticeServices,
} from '@/models/filterNotice/filterNoticeModels';

const API = connection();

export const getTypeNoticeServices = async () => {
  const { data } = await API.get(NOTICE_TYPE);

  return data?.avisotipoData?.avisotipos;
};

export const getListNoticeServices = async () => {
  const { data } = await API.get(NOTICE_LIST);

  return data;
};

export const getListNoticeByIdServices = async <T>(id: string): Promise<T> => {
  const path = NOTICE_LIST_BY_ID.replace(':id', id);
  const { data } = await API.get(path);

  return data;
};

export const getListNoticeOffertPrivateServices = async (
  id: string
): Promise<NoticeResponseAPI> => {
  const path = NOTICE_LIST_BY_ID_PRIV.replace(':id', id);
  const { data } = await API.get(path);

  return data;
};

export const createNoticeServices = async (body: any) => {
  const { data } = await API.post(NOTICE_LIST, body);

  return data;
};

export const filterNoticeServices = async (
  body: FilterNoticeServices
): Promise<FilterListAPI> => {
  const { data } = await API.post(NOTICE_LIST_FILTER, body);
  return data;
};

export const getNoticesActivesAndHistoryServices = async <T>(
  body: BodyNoticesActivesAndHistoryDTO
): Promise<T> => {
  const { data } = await API.post(NOTICE_HISTORY_LISTING, body);

  return data;
};

export type EditNoticeDate = { fechafija: string; hora?: string };
export const editNoticeDateServices = async (
  idNotice: string,
  body: EditNoticeDate
): Promise<NoticeResponseAPI> => {
  const path = NOTICE_EDIT_DATE.replace(':id', idNotice);
  const { data } = await API.put(path, body);

  return data;
};

export const editNoticeConditionsServices = async (
  idNotice: string,
  body: EditNoticeConditionsServices
) => {
  const path = NOTICE_EDIT_CONDITIONS.replace(':id', idNotice);
  const { data } = await API.put(path, body);

  return data;
};

export const editNoticePriceServices = async (
  idNotice: string,
  body: NoticeEditPriceServices
): Promise<NoticeResponseAPI> => {
  const path = NOTICE_EDIT_PRICE.replace(':id', idNotice);
  const { data } = await API.put(path, body);

  return data;
};

export const renewNoticeServices = async (
  idNotice: string,
  body?: INoticeRenewServices
) => {
  const path = NOTICE_RENEW_AVISO.replace(':id', idNotice);
  const { data } = await API.put(path, body);

  return data;
};

export const changeStatusNoticeServices = async (
  idNotice: string,
  body: INoticeStatusServices
): Promise<NoticeResponseAPI> => {
  const path = NOTICE_STATE_AVISO.replace(':id', idNotice);
  const { data } = await API.put(path, body);

  return data;
};

// offert active
export const getOffertActiveByIdServices = async (idNotice: string) => {
  const path = NOTICE_LIST_OFFERT_ACTIVE.replace(':id', idNotice);
  const { data } = await API.get(path);

  return data;
};
