import { useAppSelector } from "@/hooks/useReducer";
import {
  BodyBox,
  ContainerBody,
  SectionBody,
  SectionBodyMultiNotice,
  SectionHeaderFooter,
  SubTitleBody,
  TitleBody,
} from "./styleBodySuccess";
import { IAvisoCreadoData } from "@/services/noticeServices/noticeSendData/noticeSendDataTypesResponse";
import { Box } from "@mui/material";
import Icon from "@/utils/Icon";

const BodySuccessMultiHandP = () => {
  const { echoResponse } = useAppSelector((state) => state.noticeV2);
  const {
    avisoscreados: saveFlowData,
  } = echoResponse as IAvisoCreadoData;

  return (
    <ContainerBody
      container
      columns={{ xs: 2 }}
      rowSpacing={{ xs: 0 }}
      columnSpacing={{ xs: 0 }}
      justifyContent="center"
      justifyItems="center"
    >
      <SectionHeaderFooter
        item
        xs={2}
        borderBottom={() => ({
          borderBottom: `1px solid #eeeeee`,
        })}
      >
        <TitleBody> Distritos de cobertura </TitleBody>
        <SubTitleBody>{saveFlowData[0].destinotexto} </SubTitleBody>
      </SectionHeaderFooter>
      {saveFlowData.map((noticeData, i) => <>
      <SectionBodyMultiNotice
        borderBottom={() => ({
          borderTop: "none !important",
        })}
      >
            {noticeData?.flghoraopunto === 1 ? (
              <>
                <SectionBody item xs={1} className="multi">
                  <BodyBox>
                    <Icon name="icon-money" size="md" />
                    <TitleBody>Precio x hora</TitleBody>
                  </BodyBox>
                  <SubTitleBody>S/ {noticeData?.precio}</SubTitleBody>
                </SectionBody>
                <SectionBody item xs={1} className="multi">
                  <BodyBox>
                    <Icon name="icon-timer" size="md" />
                    <TitleBody>Mínimo horas</TitleBody>
                  </BodyBox>
                  <SubTitleBody>{noticeData?.horasminimo}</SubTitleBody>
                </SectionBody>
              </>
            ) : ( 
              <>
                <SectionBody item xs={1} className="multi">
                  <BodyBox>
                    <Icon name="icon-money" size="md" />
                    <TitleBody>Precio x punto</TitleBody>
                  </BodyBox>
                  <SubTitleBody>S/ {noticeData?.precio}</SubTitleBody>
                </SectionBody>
                <SectionBody item xs={1} className="multi">
                  <BodyBox>
                    <Icon name="icon-timeline" size="md" />
                    <TitleBody>Mínimo puntos</TitleBody>
                  </BodyBox>
                  <SubTitleBody>{noticeData?.puntosminimo}</SubTitleBody>
                </SectionBody>
              </>
            )}
          </SectionBodyMultiNotice>
          <SectionBodyMultiNotice>
            <SectionBody item xs={1} className="multi">
              <BodyBox>
              <Icon name="icon-truck" size="md" />
                <TitleBody>Vehículo</TitleBody>
              </BodyBox>
              <SubTitleBody>{noticeData?.placa}</SubTitleBody>
            </SectionBody>

            <SectionBody item xs={1} className="multi">
              <BodyBox>
              <Icon name="icon-money" size="md" />
                <TitleBody>Precio del servicio</TitleBody>
              </BodyBox>
              <SubTitleBody>S/ {noticeData?.preciocalculado}</SubTitleBody>
            </SectionBody>
          </SectionBodyMultiNotice>
        </>
        
      )}

      {/* <SectionHeaderFooter item xs={2}>
        <BodyBox>
          <TitleBody> Destino</TitleBody>
        </BodyBox>
        <SubTitleBody>{saveFlowData[0]?.destinotexto}</SubTitleBody>
      </SectionHeaderFooter> */}
    </ContainerBody>
  );
};

export default BodySuccessMultiHandP;
