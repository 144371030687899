import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";

export const ConditionsCard = styled(Container)(
  () => `
    max-width: 512px !important;
    padding:16px;
    background-color:rgba(33,150,243,0.12);  
    border-radius:4px;
    margin-left: initial;
    `
);
