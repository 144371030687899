import HeaderSection from "@/components/headerSection/HeaderSection";
import useResize from "@/hooks/useResize";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ContainerHeaderProfile } from "./styleHeaderProfile";

interface IHeaderProfileProps {
  title: string;
  children: React.ReactNode;
  sx?: any;
  button?: any;
  viewIcon?: boolean;
  backAction?: any;
}

const HeaderProfile = ({
  title,
  children,
  sx,
  button,
  viewIcon = false,
  backAction,
}: IHeaderProfileProps) => {
  const navigate = useNavigate();
  const [, isMobil] = useResize();
  const NavigateBack = () => {
    navigate(-1);
  };
  return (
    <ContainerHeaderProfile
      sx={{
        ...sx,
        width: isMobil ? "100%" : "360px",
      }}
    >
      <HeaderSection
        icon={
          viewIcon
            ? "icon-arrow-left--primary"
            : (isMobil && "icon-arrow-left--primary") || ""
        }
        title={title}
        onClick={backAction || NavigateBack}
        sx={{
          boxShadow: "none",
        }}
        buttonAction={button}
      />
      {children}
    </ContainerHeaderProfile>
  );
};

export default HeaderProfile;
