import { useAppSelector } from "@/hooks/useReducer";
import { Grid, useMediaQuery } from "@mui/material";
import { ItemProps, SectionProps } from "../../type";
import Section from "./Section";
import { CustomTitle, stylesEdge } from "./sectionCustomStyles";

interface SectionCustomProps {
  data: SectionProps;
  onClickButton: (link: string) => void;
}

const SectionCustom = ({ data, onClickButton }: SectionCustomProps) => {
  const { user } = useAppSelector((state) => state.user);
  const { title, items } = data;
  const isLogout = Object.keys(user).length === 0;

  return (
    <Grid
      container
      paddingTop={isLogout ? stylesEdge.paddingTop : {}}
      paddingBottom={isLogout ? stylesEdge.paddingBottom : {}}
    >
      {/* titulo */}
      {title && (
        <Grid item xs={12} md={12}>
          <CustomTitle sx={{ fontSize: "22px" }}>{title}</CustomTitle>
        </Grid>
      )}
      {items &&
        items.map((props: ItemProps, i: number) => {
          return (
            <Section key={i} items={props} onClickButton={onClickButton} />
          );
        })}
    </Grid>
  );
};

export default SectionCustom;
