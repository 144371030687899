import { ImagesContent } from "@/pages/requestEdition/containers/formLoad/formLoadStyles";


const SkeletonEditNegImg = () => {
  return (

      <ImagesContent>
         <ImagesContent  sx={{ height: "60px", width: "60px", borderRadius: "4px", bg: "#C5C5C5" }}/>
         <ImagesContent  sx={{ height: "60px", width: "60px", borderRadius: "4px", bg: "#C5C5C5" }}/>
         <ImagesContent  sx={{ height: "60px", width: "60px", borderRadius: "4px", bg: "#C5C5C5" }}/>
      </ImagesContent>

  );
};

export default SkeletonEditNegImg;
