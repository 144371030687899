import { NoticeV2NegType } from "@/store/noticeV2Negotiation/noticeV2NegotiationSlice";
import React from "react";
import { ConfigDataNegotationV2 } from "./useNoticeConfigDataV2";

export type ConfigToRenderType = Record<number, ConfigDataNegotationV2[]>;

interface Props {
  notice: NoticeV2NegType;
  configToRender: ConfigToRenderType;
}

export const useGetComponentByStatusNeg = (props: Props) => {
  const { notice, configToRender } = props;
  const { idTipoAvisoNegociacion } = notice;

  const handleGetConfig = () => {
    if (!notice) return [];
    
    const actionId = idTipoAvisoNegociacion as number;

    const result = configToRender[actionId];

    if (!result) return [];

    return result;
  };

  const selectdConfigDataV2 = React.useMemo(() => handleGetConfig(), []);

  return { selectdConfigDataV2 };
};