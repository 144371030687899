export const MetterIcon = (props: any) => {
  const { color, height,  width } = props.style;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1443_47038)">
        <path
          d="M11.4341 12.7571C11.6074 12.5208 11.7412 12.2846 11.9381 12.1034C12.6311 11.4498 13.7415 11.5994 14.2612 12.3948C14.5132 12.7807 14.6314 13.2059 14.6314 13.6548C14.6392 14.7731 14.6392 15.8913 14.6314 17.0174C14.6314 17.4033 14.3794 17.6317 14.0092 17.6317C13.6234 17.6317 13.3792 17.4033 13.3792 17.0174C13.3792 16.0094 13.3792 15.0093 13.3792 14.0013C13.3792 13.8044 13.3635 13.6076 13.3162 13.4264C13.2217 13.0799 12.9697 12.8909 12.6154 12.8437C12.2846 12.7964 12.0405 12.9618 11.8672 13.2296C11.7176 13.4737 11.6152 13.7336 11.6231 14.0249C11.6231 15.0014 11.6231 15.9779 11.6231 16.9623C11.6231 17.4506 11.2845 17.7183 10.812 17.6081C10.5285 17.5451 10.3631 17.3403 10.3631 17.0174C10.3631 16.0016 10.3631 14.9778 10.3631 13.9619C10.3631 13.7729 10.3395 13.5682 10.2765 13.3871C10.1662 13.0484 9.89849 12.8673 9.54411 12.8358C9.21336 12.8122 8.97711 12.9854 8.81961 13.2611C8.66999 13.5131 8.59124 13.7808 8.59124 14.0879C8.59911 15.0566 8.59124 16.0331 8.59124 17.0017C8.59124 17.3876 8.34711 17.6317 7.96124 17.6317C7.59111 17.6317 7.33124 17.3954 7.33124 17.0174C7.33124 15.4897 7.33124 13.9541 7.33124 12.4263C7.33124 12.1428 7.47299 11.9538 7.71711 11.8672C7.99274 11.7727 8.32349 11.8436 8.47311 12.0562C8.53611 12.1507 8.56761 12.2767 8.59124 12.3948C8.60699 12.4657 8.59124 12.5444 8.62274 12.6389C8.93774 12.0404 9.38661 11.6703 10.0796 11.7097C10.7726 11.7491 11.19 12.1664 11.442 12.7649L11.4341 12.7571Z"
          fill="black"
          fill-opacity="0.54"
        />
        <path
          d="M16.5292 12.387C16.7104 12.387 16.8915 12.387 17.0647 12.387C17.3719 12.387 17.5766 12.576 17.5766 12.8437C17.5766 13.1115 17.3797 13.2926 17.0647 13.3005C16.5529 13.3005 16.041 13.3005 15.5291 13.3005C15.1196 13.3005 14.907 12.954 15.0802 12.5838C15.1432 12.45 15.2377 12.3318 15.3401 12.2295C15.6709 11.8908 16.0095 11.568 16.3481 11.2372C16.4032 11.1821 16.4741 11.1348 16.4977 11.0718C16.5292 10.9537 16.5765 10.8041 16.5371 10.7017C16.482 10.5521 16.3087 10.5363 16.167 10.5915C16.0567 10.6387 15.9622 10.7332 15.8677 10.8198C15.6709 11.0088 15.3795 11.0246 15.1905 10.8592C14.9936 10.686 14.97 10.4418 15.1432 10.2056C15.6079 9.56772 16.7497 9.50472 17.2852 10.0796C17.6475 10.4733 17.6947 11.0246 17.364 11.4498C17.1514 11.7255 16.8836 11.9538 16.6395 12.2058C16.6001 12.2452 16.5607 12.2925 16.5214 12.3318C16.5214 12.3476 16.5292 12.3712 16.5371 12.387H16.5292Z"
          fill={color}
          fill-opacity="0.54"
        />
        <path
          d="M20.8054 20.8211H4.09464V10.5521L12.45 4.07886L20.8054 10.5521V20.8211Z"
          stroke={color}
          stroke-opacity="0.54"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_1443_47038">
          <rect
            width={width}
            height={height}
            fill={color}
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
