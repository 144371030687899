import SkeletonGroup from "@/components/skeletons/SkeletonGroup";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import * as React from "react";
import CustomGoogleMap from "./components/CustomGoogleMap";
import ErrorSharedRide from "./components/ErrorSharedRide";
import HeaderSharedRide from "./components/HeaderSharedRide";
import SharedRideFloatCard from "./components/card/SharedRideFloatCard";
import useHook from "./hook";

const ContainerSkeleton = styled(Grid)(
  () => `
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex: 1;
  `
);

const SharedRide = () => {
  const { error, isLoading, isLoaded, sharedRide, onLoad, vehicleCoords } =
    useHook();
  const refContainer = React.useRef<HTMLDivElement | null>(null);

  const height = (refContainer.current?.clientHeight || 0) + 116;

  if (isLoading || !isLoaded) {
    return (
      <ContainerSkeleton>
        <SkeletonGroup list={15} width={"100%"} height={height} />
      </ContainerSkeleton>
    );
  }

  if (error || !sharedRide?.usuario) {
    return (
      <>
        <HeaderSharedRide />

        <ErrorSharedRide />
      </>
    );
  }

  const user = sharedRide?.usuario;
  const originCoords = {
    lat: sharedRide?.coordenadaOrigenLatitud,
    lng: sharedRide?.coordenadaOrigenLongitud,
  };
  const destinyCoords = {
    lat: sharedRide?.coordenadaDestinoLatitud,
    lng: sharedRide?.coordenadaDestinoLongitud,
  };

  return (
    <>
      <HeaderSharedRide ref={refContainer} />

      <Grid xs={12}>
        <div style={{ position: "relative" }}>
          <CustomGoogleMap
            height={height}
            vehicleCoords={vehicleCoords}
            originCoords={originCoords}
            destinyCoords={destinyCoords}
            onLoad={onLoad}
            isLoaded={isLoaded}
          />

          <SharedRideFloatCard
            vehicle={sharedRide?.vehiculo}
            user={user}
            originAddress={sharedRide.direccionOrigen}
            destinyAddress={sharedRide.direccionDestino}
          />
        </div>
      </Grid>
    </>
  );
};

export default SharedRide;
