import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  ListAviso,
  NoticeResponseSaveFlowAPI,
} from '@/models/notice/noticeModels';
import { Dayjs } from 'dayjs';

interface FixedDateProps {
  fixedDate: Dayjs | null;
  isFixedDate?: number | null;
  HoursMins?: Dayjs | null;
}

export interface SelectNotice {
  id: number;
  icon: string;
  title: string;
  subtitle: string;
  link: string;
}

export interface NoticeState extends FixedDateProps {
  list: Array<any>;
  selectedNotice: SelectNotice | {};
  avisos: NoticeResponseSaveFlowAPI[];
  currentPage: number;
  totalItems: number;
  totalPages: number;
  isLoading: boolean;
  isLoadingIndex: boolean;
  isLoadingHistory: boolean;
  error: any;
  selectNotice: SelectNotice | {};
  history: ListAviso[];
  activeNotices: ListAviso[];
  dirtyPrice: boolean;
}

const initialState: NoticeState = {
  list: [],
  selectedNotice: {},
  selectNotice: {},
  avisos: [],
  history: [],
  isLoadingHistory: false,
  activeNotices: [],
  currentPage: 0,
  totalItems: 0,
  totalPages: 0,
  fixedDate: null, // Esta variables para el manejo de la fecha fija en el flujo de (OFERTAS ENTRE CIUDADES)
  isFixedDate: null,
  HoursMins: null, // Esta variable es para el manejo de la hora y minutos en el flujo de (REMATE ENTRE CIUDADES)
  isLoading: false,
  isLoadingIndex: false,
  error: null,
  dirtyPrice: false,
};

export const noticeSlice = createSlice({
  name: 'notice',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setLoadingIndex: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingIndex = payload;
    },
    setLoadingHistory: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingHistory = payload;
    },
    setList: (state, { payload }: PayloadAction<Array<any>>) => {
      state.list = payload;
      state.isLoading = false;
      state.error = null;
    },
    setSelectNotice: (state, { payload }: PayloadAction<SelectNotice>) => {
      state.selectNotice = payload;
    },
    selectedNotice: (state, { payload }: PayloadAction<Object>) => {
      state.selectedNotice = payload;
    },
    setError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
      state.isLoading = false;
    },
    setFixedDate: (state, { payload }: PayloadAction<FixedDateProps>) => {
      state.fixedDate = payload.fixedDate;
      state.isFixedDate = payload.isFixedDate;
    },
    setFixedDateTime: (state, { payload }: PayloadAction<FixedDateProps>) => {
      state.fixedDate = payload.fixedDate;
      state.HoursMins = payload.HoursMins;
    },
    // avisos
    setAvisos: (state, { payload }: PayloadAction<Array<any>>) => {
      state.avisos = payload;
      state.isLoadingIndex = false;
    },
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.currentPage = payload;
    },
    setTotalItems: (state, { payload }: PayloadAction<number>) => {
      state.totalItems = payload;
    },
    setTotalPages: (state, { payload }: PayloadAction<number>) => {
      state.totalPages = payload;
    },
    setIterableAvisos: (state, { payload }: PayloadAction<Array<any>>) => {
      // payload is not iterable
      state.avisos = [...state.avisos, ...payload];
      state.isLoading = false;
    },
    setActiveNotices: (state, { payload }: PayloadAction<ListAviso[]>) => {
      state.activeNotices = payload;
    },
    setHistory: (state, { payload }: PayloadAction<ListAviso[]>) => {
      state.history = payload;
    },
    setDirtyPrice: (state, { payload }: PayloadAction<boolean>) => {
      state.dirtyPrice = payload;
    },
    resetLogout: (state) => {
      const { list, avisos, selectNotice } = state;

      state = {
        ...initialState,
        list,
        avisos,
        selectNotice,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setList,
  setError,
  selectedNotice,
  setCurrentPage,
  setFixedDate,
  setFixedDateTime,
  setAvisos,
  setTotalItems,
  setTotalPages,
  setIterableAvisos,
  setLoadingIndex,
  setSelectNotice,
  setHistory,
  setActiveNotices,
  setLoadingHistory,
  setDirtyPrice,
  resetLogout,
} = noticeSlice.actions;
