import {
  AUTH_CREATE_PASSWORD,
  AUTH_LOGIN,
  AUTH_PASSWORD,
  AUTH_RECOVER_PASSWORD,
  AUTH_RESET_PASSWORD,
  AUTH_USER_TYPE,
  AUTH_VALIDATION,
} from '@/consts/typeServicesPaths';

import Login from '@/pages/user/login/Login';
import CreatePassword from '@/pages/user/password/CreatePassword';
import Password from '@/pages/user/password/Password';
import ResetPassword from '@/pages/user/reset-password/ResetPassword';
import UserType from '@/pages/user/user-type/UserType';
import Validation from '@/pages/user/validation/Validation';
import { RouterProps } from '@/router/router';

export const loginFlowRouter: RouterProps[] = [
  {
    path: AUTH_LOGIN,
    Component: Login,
    requiredSession: false,
  },
  {
    path: AUTH_PASSWORD,
    Component: Password,
  },
  {
    path: AUTH_RECOVER_PASSWORD,
    Component: Validation,
    requiredSession: false,
  },
  {
    path: AUTH_VALIDATION,
    Component: Validation,
    requiredSession: false,
  },
  {
    path: AUTH_CREATE_PASSWORD,
    Component: CreatePassword,
  },
  {
    path: AUTH_RESET_PASSWORD,
    Component: ResetPassword,
    requiredSession: false,
  },
  {
    path: AUTH_USER_TYPE,
    Component: UserType,
    requiredSession: true,
  },
];
