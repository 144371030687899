import { prepareBodyAdvertisementListThunk } from '@/adapters/store/advertisementAdapter';
import {
  filterCustomerRequestServiceSend,
  getMyCustomerRequestServiceSend,
} from '@/adapters/store/customerRequestAdapter';
import { GetListNoticeFilterThunkProps } from '@/interfaces/noticeInterface';
import {
  filterCustomerRequestServices,
  getCustomerRequestMyListServices,
  getListOffersClientService,
} from '@/services/customerRequestServices';
import {
  IOrdenamiento,
  ISelectCity,
  TiposFiltro,
} from '../FilterServices/filterReduxInterface';
import { getAdvertisementRequestListThunk } from '../advertisementRedux/advertisementThunk';
import { getMessaggeError } from '../auth/utils';
import { AppDispatch, RootState } from '../store';
import {
  setCustomerDataFilter,
  setError,
  setIsLoadingPagination,
  setLoading,
  setMyRequestListDataAction,
  setNegSolicitudList,
  setNumAbiertas,
  setNumRechazadas,
} from './customerRequestSlice';
import { IMyRequestListDataResponse, setMyRequestListDataFilter } from '@/models/customerRequest/MyRequestListDataResponse';
import { optionOne } from '@/consts/noticeConsts';

interface ICustomerRequestFilter {
  page: number;
  size: number;
  ordenamiento: IOrdenamiento;
  tiposFiltro: TiposFiltro[];
  tag?: number;
}

interface ListCustomRequestFilterThunkProps
  extends Omit<GetListNoticeFilterThunkProps, 'date' | 'urlSolicitud'> {}
export const getListCustomRequestFilterThunk = (
  data: ListCustomRequestFilterThunkProps
) => {
  const { page, size, ordenamiento, tiposFiltro, pathname } = data;
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    if (page === 0) {
      dispatch(setLoading(true));
    } else {
      dispatch(setIsLoadingPagination(true));
    }
    try {
      const {
        customerRequest: { solicitudes },
        filter: { selectedCity },
      } = getState();
      const filtros = [...tiposFiltro];

      // entrar solo si se selecciono una ciudad y si no existe en la lista de filtros.
      if (
        Object.keys(selectedCity).length &&
        !filtros.find((item) => item.nombre === 'OrigenCiudad')
      ) {
        // agregar la ciudad seleccionada a la lista de opciones
        filtros.push({
          nombre: 'OrigenCiudad',
          opciones: [(selectedCity as ISelectCity).nombre],
        });
      }

      const body = filterCustomerRequestServiceSend({
        page,
        size,
        ordenamiento,
        tiposFiltro: filtros,
      });

      let apiLisData = await filterCustomerRequestServices(body);

      if (!apiLisData?.solicitudData?.solicitudes) {
        apiLisData.solicitudData.solicitudes = [];
      }

      if (!apiLisData?.solicitudData.solicitudes) {
        dispatch(setLoading(false));
        dispatch(setIsLoadingPagination(false));
        return;
      }

      dispatch(
        setCustomerDataFilter({
          currentPage: apiLisData?.solicitudData.currentPage,
          totalItems: apiLisData?.solicitudData.totalItems,
          totalPages: apiLisData?.solicitudData.totalPages,
          solicitudes:
            (apiLisData?.solicitudData.currentPage === 0
              ? apiLisData?.solicitudData.solicitudes
              : [...solicitudes, ...apiLisData?.solicitudData.solicitudes]) ||
            [],
        })
      );

      // ================ Ejecuta la peticion de publicidad, para luego insertar en el listado ====
      if (pathname) {
        const advertisementBody = prepareBodyAdvertisementListThunk(
          pathname,
          apiLisData.solicitudData.solicitudes.length || size
        );
        dispatch(getAdvertisementRequestListThunk(advertisementBody));
      }
    } catch (err: any) {
      const error = getMessaggeError(err);

      dispatch(setIsLoadingPagination(false));
      dispatch(setLoading(false));
      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

export const getMyListCustomersRequestThunk = (
  page: number,
  size: number,
  ordenamiento: IOrdenamiento,
  tiposFiltro: TiposFiltro[],
  option: number,
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    if (page === 0) {
      dispatch(setLoading(true));
    } else {
      dispatch(setIsLoadingPagination(true));
    }
    try {
      const {
        customerRequest: { 
          myRequestListData
         },
      } = getState();
      let {solicitudData: {
        currentPage,
        solicitudes,
        totalItems,
        totalPages,
      }} = await getCustomerRequestMyListServices(
        getMyCustomerRequestServiceSend(page, size, ordenamiento, tiposFiltro)
      );
      if (!solicitudes) {
        solicitudes = [];
      }

      dispatch(
        setMyRequestListDataAction({
          currentPage: currentPage,
          totalItems: totalItems,
          totalPages: totalPages,
          myRequestListData: 
            (currentPage === 0
              ? solicitudes
              : [...myRequestListData, ...solicitudes]) ||
            [],
        })
      );
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setIsLoadingPagination(false));
      dispatch(setLoading(false));
      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};

// ==================================================================================================
//       THUNK PARA LISTAR LAS OFERTAS DE UNA SOLICITUD
// ==================================================================================================

export const getListNegotiationThunk = (idRequest: string) => {
  return async (dispatch: AppDispatch) => {
      dispatch(setLoading(true));
    try {
      const {
        solicitudNegociacionData: {
          solicitudNegociacion
        }
      } = await getListOffersClientService(idRequest);

      dispatch(setNegSolicitudList(solicitudNegociacion));
    } catch (err: any) {
      const error = getMessaggeError(err);
      dispatch(setIsLoadingPagination(false));
      dispatch(setLoading(false));
      dispatch(setError(error));
      throw new Error(error.message || undefined);
    }
  };
};
