import { Typography, styled } from '@mui/material';

export const Title = styled(Typography)(
  () => `
  font-size: 34px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
    `
);

export const SubTitle = styled(Typography)(
  () => `
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    `
);

export const Information = styled(Typography)(
  () => `
    font-size: 16px;
    font-weight: 305;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 16px
    `
);
