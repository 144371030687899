import BasicModal from "@/components/dialog/Notiffication";
import { useAppSelector } from "@/hooks/useReducer";
import { useMediaQuery, useTheme } from "@mui/material";

import { ID_MOVING_FLOW } from "@/consts/defaultConsts";
import { NEGOTATION_COUNTEROFFER } from "@/consts/negotationFlowPath";
import { ERROR_NEGOTATION_CLOSED } from "@/consts/textErrors";
import { REQUESTS_LANDING_PATH } from "@/consts/typeServicesPaths";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import { ConfigDataNegotation } from "@/pages/noticeRequestFlow/interface/noticeNegRequestInterface";
import DynamicComponent from "@/pages/offerts/offertConfirmation/components/DynamicComponent";
import { useEffect, useId, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfigurationToRenderV2 } from "../config";
import { dataNogotationAdapter } from "../counterOfferResponse/counterOfferAdapter";
import { OfferResponseLayout } from "./OfferResponseLayout";
interface LocationState {
  hasBackNavigate?: boolean;
  hasBackLinkOffert?: boolean;
}

const OfferResponse = () => {
  const {
    noticeNegotationRequest: { error, savedDataNogotation },
    user: { user },
  } = useAppSelector((state) => state);
  let location = useLocation();
  const locationState = location.state as LocationState;
  const { pathname } = location;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const navigate = useNavigate();
  const currentId = useId();
  const [hasBackNavigate, setHasBackNavigate] = useState(false);
  const [hasBackLinkOffert, setHasBackLinkOffert] = useState(false);
  const [offerText, setOfferText] = useState("");

  const isDriver = pathname.startsWith(REQUESTS_LANDING_PATH);
  const textTypeNeg = pathname.includes(NEGOTATION_COUNTEROFFER)
    ? "contraoferta"
    : "oferta";
  const negotationClosed = error === ERROR_NEGOTATION_CLOSED;

  const { isOwner, typeRequest, tipoSolicitud, tipoSolicitudNegociacionId } =
    dataNogotationAdapter(savedDataNogotation as SolicitudNegociacion);

  const textStatusAlert: Record<number, string> = {
    1: `El cliente aún no responde tu ${textTypeNeg}`,
    2: `El transportista aún no responde tu ${textTypeNeg}`,
  };

  const textToRender = textStatusAlert[isDriver ? 1 : 2];

  useEffect(() => {
    let defaultofferText = "Oferta del transportista";

    if (locationState?.hasBackNavigate && !locationState?.hasBackLinkOffert) {
      setHasBackNavigate(true);
    }

    if (locationState?.hasBackLinkOffert && !locationState?.hasBackNavigate) {
      setHasBackLinkOffert(true);
    }

    if (typeRequest === ID_MOVING_FLOW) {
      defaultofferText = "Cotización del transportista";
    }

    setOfferText(defaultofferText);
  }, []);

  const handleRenderConfiguration = () => {
    const valueExists =
      !!tipoSolicitud && !!tipoSolicitudNegociacionId && savedDataNogotation;

    if (valueExists) {
      const value =
        ConfigurationToRenderV2[tipoSolicitud][tipoSolicitudNegociacionId];

      return value;
    } else {
      return [];
    }
  };

  const valueArray = useMemo(
    () => handleRenderConfiguration(),
    [savedDataNogotation]
  );

  const handleValid = ({ valid, props }: ConfigDataNegotation) => {
    if (!valid || !savedDataNogotation) return { props };

    const moreProps = valid.reduce((acc, el) => {
      let value = (savedDataNogotation as any)[el.property];

      if (el?.callback) {
        value = el.callback(savedDataNogotation, el?.callbakcParams || user);
      }

      if (typeof el.defaultResult === "boolean") {
        acc = {
          ...acc,
          [el.componentProp]: !!value,
        };
      } else {
        acc = {
          ...acc,
          [el.componentProp]: value || el.defaultResult,
        };
      }
      return acc;
    }, {});

    return { props: { ...props, ...moreProps } };
  };

  const handleInitConfig = (config: ConfigDataNegotation) => {
    let moreProps = handleValid(config);

    return { ...moreProps };
  };

  return (
    <>
      <OfferResponseLayout>
        {valueArray.map((item, i) => (
          <DynamicComponent
            key={`${currentId}-${i}`}
            {...item}
            {...handleInitConfig(item)}
          />
        ))}
      </OfferResponseLayout>
      <BasicModal />
    </>
  );
};

export default OfferResponse;
