import { FC } from "react";
import { Skeleton, SkeletonProps, SxProps, Theme } from "@mui/material";

interface Props extends SkeletonProps {
  borderRadius?: number;
  height?: string | number | undefined;
  sx?: SxProps<Theme> | undefined;
}

const SkeletonHeader: FC<Props> = ({ sx, height }) => {
  return <Skeleton variant="rounded" height={height} sx={sx} />;
};

export default SkeletonHeader;
