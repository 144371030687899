import { useAppSelector } from "@/hooks/useReducer";
import { SolicitudCreadaData } from "@/models/customerRequest/customerRequestResponse";
import {
  BodyBox,
  ContainerBody,
  SectionBody,
  SectionHeaderFooter,
  SubTitleBody,
  TitleBody,
} from "@/pages/createServices/success/bodySucesstype/styleBodySuccess";
import Icon from "@/utils/Icon";
import { currencyFormat } from "@/utils/helpers";
import { Container } from "@mui/material";
import { SectionBodyMulti } from "./style";


const SuccessInCity = () => {

  const { echoResponse } = useAppSelector((state) => state.customerRequest);
  const {
    solicitudCreada: {
      destinoTexto,
      fecha,
      precio,
      origenTexto,
    }
  } = echoResponse as SolicitudCreadaData;

  return (
    <Container maxWidth="xs">
      <ContainerBody
        container
        columns={{ xs: 2 }}
        rowSpacing={{ xs: 0 }}
        columnSpacing={{ xs: 0 }}
        justifyContent="center"
        justifyItems="center"
      >
        {/* incity, entre ciudades, mudanza  */}
        <SectionBodyMulti
        >
          <SectionBody item xs={1} className="multi">
            <BodyBox>
              <Icon name="icon-location-light" size="md" />
              <TitleBody>Origen</TitleBody>
            </BodyBox>
            <SubTitleBody>{origenTexto}</SubTitleBody>
          </SectionBody>
          <SectionBody item xs={1} className="multi">
            <BodyBox>
              <Icon name="icon-location-dark" size="md" />
              <TitleBody>Destino</TitleBody>
            </BodyBox>
            <SubTitleBody>{destinoTexto}</SubTitleBody>
          </SectionBody>
        </SectionBodyMulti>
        <SectionHeaderFooter
          item
          xs={2}
          borderBottom={() => ({
            borderBottom: precio ? "1px solid #eeeeee" : "none",
          })}
        >
          <BodyBox>
            <Icon name="icon-calendar" size="md" />
            <TitleBody>Fecha del servicio</TitleBody>
          </BodyBox>
          <SubTitleBody>{fecha}</SubTitleBody>
        </SectionHeaderFooter>
        {
          precio && (

            <SectionBody item xs={2} className="simgle">
              <BodyBox>
                <Icon name="icon-money" size="md" />
                <TitleBody>Precio ofrecido</TitleBody>
              </BodyBox>
              <SubTitleBody>
                {currencyFormat(precio || 0)}
              </SubTitleBody>
            </SectionBody>
          )
        }
      </ContainerBody>
    </Container>
  );
};

export default SuccessInCity
