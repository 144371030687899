import { CREATE_CUSTOMER_REQUEST_PATH } from '@/pages/customerRequest/shared/consts/routes';
import {
  NO_ACCESS_CLIENT_IMAGE,
  NO_ACCESS_DRIVER_IMAGE,
} from '@/consts/defaultConsts';
import {
  NO_ACCESS_CLIENT,
  NO_ACCESS_DRIVER,
  SERVICES_LIST_DRIVER,
} from '@/consts/typeServicesPaths';

type ConfigPage = {
  imageSrc: string;
  title: string;
  description: string;
  description2: string;
  buttonText: string;
  buttonLink: string;
};

export const configNoAccessByRol: Record<string, ConfigPage> = {
  [NO_ACCESS_CLIENT]: {
    imageSrc: NO_ACCESS_CLIENT_IMAGE,
    title: 'Lo sentimos',
    description:
      'Sólo los transportistas pueden crear avisos de transporte, sin embargo puedes solicitar un servicio cuando lo necesites.',
    description2: 'Si necesitas ayuda o tienes dudas, contáctanos ',
    buttonText: 'crea tu solicitud',
    buttonLink: CREATE_CUSTOMER_REQUEST_PATH,
  },
  [NO_ACCESS_DRIVER]: {
    imageSrc: NO_ACCESS_DRIVER_IMAGE,
    title: 'Lo sentimos',
    description:
      'Por el momento, sólo puedes ofrecer transporte. Estamos trabajando para darte la opción de solicitar un servicio.',
    description2: 'Si necesitas ayuda o tienes dudas, contáctanos ',
    buttonText: 'crea tu aviso',
    buttonLink: SERVICES_LIST_DRIVER,
  },
};
