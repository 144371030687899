import { Box, Card, CardActionArea, Typography, styled } from '@mui/material';

export const CardFilterContent = styled(Card)(({ theme }) => ({
  height: '332px',
  width: '100%',
  borderRadius: '8px',
  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.15)',
}));

export const CardTouchFilter = styled(CardActionArea)(() => ({
  width: '100%',
  height: '100%',
}));

export const CardFilterHeader = styled(Box)(({ theme }) => ({
  position: 'relative',
}));

export const CardFilterOriginText = styled(Typography)(({ theme }) => ({
  // limite de 1 linea
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  lineBreak: 'anywhere',
}));
