import { thirdFontFamily } from '@/styles/theme';
import { Button, Grid, Typography, styled } from '@mui/material';

export const CustomTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: '700',
  fontFamily: thirdFontFamily,
}));

export const CustomSecondTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '700',
  fontFamily: thirdFontFamily,
  textTransform: 'uppercase',
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: '44px',
  gap: '16px',
  padding: '0px 16px',
  height: '44px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textTransform: 'uppercase',
  fontFamily: thirdFontFamily,
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '36px',
  fontStyle: 'normal',
  textAlign: 'center',
}));

export const GridContainerSection = styled(Grid)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '32px auto',
}));

export const stylesEdge = {
  paddingTop: {
    xs: '32px',
    md: '32px',
    xl: '64px',
    lg: '64px',
  },
  paddingBottom: {
    xs: '32px',
    md: '32px',
    xl: '64px',
    lg: '64px',
  },
};
