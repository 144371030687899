export const configDetailTypeFlow: Record<number, any> = {
  1: {
    icon: 'icon-build-mini',
  }, // TODO: FALTA EL 2
  2: {
    icon: 'icon-watch-dense',
    iconPoints: 'icon-deliveries-dense',
  },
  3: {
    icon: 'icon-inter-city--white',
  },
  4: {
    icon: 'icon-percentaje-medium',
  },
  5: {
    icon: 'icon-car-medium',
  },
};

export const configDetailTypeNoticeFlow: Record<number, any> = {
  1: {
    icon: 'icon-build-mini',
  },
  2: {
    icon: 'icon-watch-dense',
    iconPoints: 'icon-deliveries-dense',
  },
  3: {
    icon: 'icon-inter-city--white',
  },
  4: {
    icon: 'icon-percentaje-medium',
  },
  5: {
    icon: 'icon-car-medium',
  },
};

export const configDataTitles: Record<number, any> = {
  1: {
    title: 'En la ciudad',
  },
  2: {
    title: 'Multidestino',
  },
  3: {
    title: 'Entre ciudades',
  },
  4: {
    title: 'Servicio express',
  },
  5: {
    title: 'Mudanza',
  },
};
