import { useAppSelector } from "@/hooks/useReducer";
import { useNavigate } from "react-router-dom";
import { ConfigDataNegotation } from "../interface/noticeNegRequestInterface";



const useNoticeConfigData = () => {
  const navigate = useNavigate();
  const {
    noticeNegotationRequest: { savedDataNogotation },
    user: { user },
  } = useAppSelector((state) => state);

  const handleValid = ({ valid, props }: ConfigDataNegotation) => {
    if (!valid || !savedDataNogotation) return { props };

    const moreProps = valid.reduce((acc, el) => {
      let value = (savedDataNogotation as any)[el.property];

      if (el?.callback) {
        value = el.callback(savedDataNogotation, el?.callbakcParams || user);
      }

      if (typeof el.defaultResult === "boolean") {
        acc = {
          ...acc,
          [el.componentProp]: !!value,
        };
      } else {
        acc = {
          ...acc,
          [el.componentProp]: value || el.defaultResult,
        };
      }
      return acc;
    }, {});

    return { props: { ...props, ...moreProps } };
  };

  const handleInitConfig = (config: ConfigDataNegotation) => {
    let moreProps = handleValid(config);

    return { ...moreProps };
  };

  const handleNavigationBack = () => {
    navigate(-1);
  };

 

  return {
    handleValid,
    handleInitConfig,
    handleNavigationBack,
  };
};

export default useNoticeConfigData;
