import queryString from 'query-string';

import { FormDataFilter } from '@/components/Filter/consts/filtersConst';
import { TypeFilterData } from '@/store/FilterServices/filterReduxInterface';
import { REQUEST_TYPE_MOVE_BETWEEN_CITIES } from '@/consts/requestConsts';

export const prepareJoinDataUrl = (
  newData: FormDataFilter,
  selectType: TypeFilterData
) => {
  const actionsTypes = [
    'Transporte entre ciudades',
    REQUEST_TYPE_MOVE_BETWEEN_CITIES,
    'Remate entre ciudades',
  ];
  let cleanData = { ...newData };
  if (actionsTypes.some((type) => type === selectType.nombre)) {
    cleanData = {
      ...cleanData,
      origin: joinData(cleanData.origin),
      destiny: joinData(cleanData.destiny),
    };
  }

  return cleanData;
};

export const joinData = (data: any | any[], separator = ',') => {
  if (!Array.isArray(data)) return data;

  return data.join(separator);
};

export const prepareDataBeforeSendFilter = (
  search: string
): FormDataFilter | null => {
  if (!search) return null;
  const data = queryString.parse(search);
  let city = data?.city;
  if (Array.isArray(data?.city)) {
    city = data?.city?.join(',');
  }
  const newData = {
    ...data,
    city,
  };

  return newData as FormDataFilter;
};

export const prepareBody = (
  typeNoticeList: TypeFilterData[],
  search: string,
  typeNoticeSelecte: TypeFilterData
) => {
  const newData = prepareDataBeforeSendFilter(search);

  if (!newData || !typeNoticeList.length) return;

  const typeNoticeSelected = typeNoticeList.find(
    (type) => type.nombre === newData.typeNotice
  );
  if (
    !!typeNoticeList.length &&
    typeNoticeSelected &&
    typeNoticeSelected?.inputfechas
  ) {
    let { city, ...values } = newData;
    values = {
      ...values,
      destiny: joinData(values?.destiny),
      origin: joinData(values?.origin),
    };

    return values;
  } else if (typeNoticeSelected && !typeNoticeSelected?.inputfechas) {
    const data = prepareJoinDataUrl(newData, typeNoticeSelected);
    return data;
  }
};
