import { Stack, SxProps } from '@mui/material';
import useButtonContactHook from './hook';
import { ButtonContactItem } from './style';
import { theme } from '@/styles/theme';
export interface ListContacts {
    title: string;
    contact: string;
    whatsapp?: string;
    type: "phone" | "mail" | "whatsapp";
    backgroundColor?: string;
}

export type Props = {
    contacts: ListContacts[];
    direction?: "column" | "row";
    sxContainer?: SxProps;
};


const ButtonContact = ({
    contacts,
    direction = "row",
    sxContainer,
}: Props) => {

    const {
        handlePrepareLink
    } = useButtonContactHook()

    return (
        <Stack
            direction={direction}
            sx={sxContainer}
            width="100%"
            mt={2}
            gap="16px"
        >
            {contacts.map(({
                title,
                contact,
                type,
                whatsapp,
                backgroundColor = theme.palette.org.main
            }, i) => (
                <ButtonContactItem href={handlePrepareLink(
                    type,
                    contact,
                    whatsapp || ""
                )} target={type === "whatsapp" ? "_blank" : "_self"}
                    sx={{
                        backgroundColor,
                    }}
                >
                    {title}
                </ButtonContactItem>
            ))}
        </Stack>
    )
}

export default ButtonContact