import FormRuteEditBetweenCities from "./formRuteEditBetweenDestiny/FormRuteEditBetweenDestiny";
import FormRuteEditInCity from "./formRuteEditInCity/FormRuteEditInCity";
import formRuteEditMoving from "./formRuteEditMoving/FormRuteEditMoving";
import FormRuteEditMultidestiny from "./formRuteEditMultidestiny/FormRuteEditMultidestiny";



export const objectRenderComponent: Record<number, () => JSX.Element> = {
    1: FormRuteEditInCity,
    2: FormRuteEditMultidestiny,
    3: FormRuteEditBetweenCities,
    5: formRuteEditMoving
  };
  


 export const input1 = {
    name: "origin-reference",
    label: "Referencia de la ubicación",
    placeholder: "Lugar, parque o av cercana a la dirección",
  }
  
  export const input2 = {
    name: "destiny-reference",
    label: "Referencia de la ubicación",
    placeholder: "Lugar, parque o av cercana a la dirección",
  }
  