import { MyNotice, PaginationMyNotices } from '@/models/notice/noticeModels';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const defaultValues: Paginate = {
  currentPage: 0,
  totalItems: 0,
  totalPages: 0,
  isLoading: false,
  error: null,
  listAvisos: [],
};

interface Paginate {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  isLoading: boolean;
  error: any;
  listAvisos: MyNotice[];
}

interface PayloadList extends PayloadAction<PaginationMyNotices> {}

interface InitialState {
  historyNotices: Paginate;
  activeNotices: Paginate;
}

const initialState: InitialState = {
  historyNotices: defaultValues,
  activeNotices: defaultValues,
};

export const myNoticeSlice = createSlice({
  name: 'myNotice',
  initialState,
  reducers: {
    setLoadingHistory: (state, { payload }: PayloadAction<boolean>) => {
      state.historyNotices.isLoading = payload;
    },
    setErrorHistory: (state, { payload }: PayloadAction<any>) => {
      // TODO: Definir el tipo del error
      state.historyNotices.error = payload;
    },
    setListHistory: (
      state,
      { payload }: PayloadAction<PaginationMyNotices>
    ) => {
      const { avisos: listAvisos, ...restPayload } = payload;

      state.historyNotices = {
        ...state.historyNotices,
        listAvisos,
        ...restPayload,
      };
    },
    updateListHistory: (
      state,
      { payload }: PayloadAction<PaginationMyNotices>
    ) => {
      const newList = updateList(state.historyNotices.listAvisos, payload);

      state.historyNotices = {
        ...state.historyNotices,
        ...newList,
      };
    },
    resetHistory: (state) => {
      state.historyNotices = initialState.historyNotices;
    },
    // actives
    setLoadingActive: (state, { payload }: PayloadAction<boolean>) => {
      state.activeNotices.isLoading = payload;
    },
    setErrorActive: (state, { payload }: PayloadAction<any>) => {
      state.activeNotices.error = payload;
    },
    setListActive: (state, { payload }: PayloadAction<PaginationMyNotices>) => {
      const { avisos: listAvisos, ...restPayload } = payload;

      state.activeNotices = {
        ...state.activeNotices,
        listAvisos,
        ...restPayload,
      };
    },
    updateListActive: (
      state,
      { payload }: PayloadAction<PaginationMyNotices>
    ) => {
      const newList = updateList(state.activeNotices.listAvisos, payload);

      state.activeNotices = {
        ...state.activeNotices,
        ...newList,
      };
    },
    resetActive: (state) => {
      state.activeNotices = initialState.activeNotices;
    },
  },
});

const updateList = (
  listAvisos: Paginate['listAvisos'],
  payload: PayloadList['payload']
) => {
  const { avisos, ...restPayload } = payload;
  const newListActive = [...listAvisos, ...avisos];

  return {
    ...restPayload,
    listAvisos: newListActive,
  };
};

export const {
  setLoadingHistory,
  setErrorHistory,
  setListHistory,
  resetHistory,
  setLoadingActive,
  setErrorActive,
  setListActive,
  resetActive,
  updateListHistory,
  updateListActive,
} = myNoticeSlice.actions;
