import { requestOriginMovingAdapter } from "@/adapters/store/editNegotationRequestAdapter";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import DatePicker from "@/components/form/datePicker.tsx/DatePicker";
import Input from "@/components/inputCustom/Input";
import { FORMAT_DATE_DDMMYYYY } from "@/consts/formatsDate";
import { optionOne, optionTwo } from "@/consts/noticeConsts";
import { useAppDispatch } from "@/hooks/useReducer";
import {
  ISolicitudMovingBetweenCities,
  ISolicitudMovingInCity,
} from "@/models/customerRequest/requestDetailsVewResponse";
import FormRequest from "@/pages/customerRequest/createRequest/Rute/FormOriginOrDestiny/FormRequest";
import useFechaCustomHook from "@/pages/customerRequest/createRequest/Rute/FormOriginOrDestiny/hookFecha";
import useOriginOrDestinyHook from "@/pages/customerRequest/createRequest/Rute/FormOriginOrDestiny/hookOriginOrDestiny";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import {
  setActiveButton,
  setLoading,
  setSelectOrigin,
} from "@/store/customerRequestRedux/customerRequestSlice";
import { uploadOriginInMovingRequestEdit } from "@/store/noticeNegotationRequestRedux/editNegRequestThunk";
import { ITipoInmuebles } from "@/store/offertRedux/offert.interface";
import Icon from "@/utils/Icon";
import { validateValueInput } from "@/utils/helpers";
import { Box, FormControl, InputAdornment, Typography } from "@mui/material";
import dayjs from "dayjs";
import { ChangeEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { adapterConfigInmueble } from "./configData";

const FormOriginEditMoving = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { datePicker, setDatePicker } = useFechaCustomHook();

  const {
    isLoading,
    reference,
    setReference,
    referenceDestiny,
    formRequest,
    setFormRequest,
    selectedCity,
    selectedCityDestiny,
    selectedOrigin,
    selectedDestiny,
    origin,
    destiny,
    handleChange,
    handleInputClickModal,
    customerRequest,
    tipoInmuebles,
    inCity,
    showDestiny,
    requestDetailsView,
  } = useOriginOrDestinyHook();

  const {
    id,
    idOrigen,
    origenTexto,
    origenTexto2,
    idTipoInmueble,
    piso,
    flgAscensorOrigen,
    fecha,
    referencia,
    flgCiudadoEntreciudades,
  } = requestDetailsView as ISolicitudMovingInCity &
    ISolicitudMovingBetweenCities;

  useEffect(() => {
    dispatch(setLoading(false));
    if (requestDetailsView) {
      const idInmueble = `${idTipoInmueble}`;
      const newPiso = `${piso as number}`;
      const elevador = `${flgAscensorOrigen}`;

      setDatePicker(dayjs(fecha, FORMAT_DATE_DDMMYYYY));
      setReference(referencia);
      dispatch(
        setSelectOrigin({
          id: idOrigen,
          nombre:
            flgCiudadoEntreciudades === optionTwo ? origenTexto2 : origenTexto,
        })
      );
      setFormRequest({ idInmueble, piso: newPiso, elevador });
    }
  }, []);

  const handleSubmit = () => {
    const body = {
      ...requestOriginMovingAdapter(reference, currentForm),
      fecha: dayjs(datePicker).format(FORMAT_DATE_DDMMYYYY),
    };

    dispatch(uploadOriginInMovingRequestEdit(`${id}`, body)).then(() => {
      dispatch(setLoading(false));
      navigate(-1);
    });
  };

  const handleOnChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = target;

    const newValue = validateValueInput(value);

    setReference(newValue);
  };

  const currentForm = {
    idInmueble: formRequest.idInmueble.toString(),
    piso:
      Number(formRequest.idInmueble) === optionOne
        ? null
        : formRequest.piso.toString(),
    elevador:
      Number(formRequest.idInmueble) === optionOne
        ? null
        : formRequest.elevador.toString(),
  };

  const formReq = () => {
    const { idInmueble, piso, elevador } = formRequest;
    const IsHomePlace = Number(idInmueble) === optionOne;

    return {
      idInmueble: idInmueble.toString(),
      piso: IsHomePlace ? null : piso,
      elevador: Number(idInmueble) === optionOne ? null : elevador,
    };
  };

  const handleCompareinputs = () => {
    const InmuebleCasa = idTipoInmueble === optionOne;
    const inmueble = {
      idInmueble: `${idTipoInmueble}`,
      piso: InmuebleCasa ? null : `${piso}`,
      elevador: InmuebleCasa ? null : `${flgAscensorOrigen}`,
    };

    const currentInputsValue = [datePicker, reference, formReq()];
    const dataValue = [
      dayjs(fecha, FORMAT_DATE_DDMMYYYY),
      referencia,
      inmueble,
    ];

    const result =
      JSON.stringify(currentInputsValue) === JSON.stringify(dataValue);

    return !result;
  };

  const isNumber = (value: string) => {
    return Number(value) !== 0 && Number(value) > 0;
  };

  const handleDisabledButton = () => {
    const isValid =
      Number(currentForm.idInmueble) === optionOne
        ? [currentForm.idInmueble]
        : [currentForm.idInmueble, currentForm.piso, currentForm.elevador];
    const isInvalid = isValid.some((item) => !isNumber(item as string));

    const conditions = [reference, ...isValid];
    let result = true;

    const conditionsResult = conditions.some((condition) => !condition);
    const sameData = handleCompareinputs();
    dispatch(setActiveButton(sameData));

    if (sameData) {
      result = false;
      if (conditionsResult) {
        result = true;
      }
      if (isInvalid) {
        result = true;
      }
    }

    return result;
  };

  const handleInputsFormRequestDestiny = () => {
    const nombre = selectedCityDestiny?.nombre;
    const nombre2 = selectedCityDestiny?.nombre2;
    const originName = selectedOrigin?.nombre;
    const destinyNameTwo = selectedDestiny?.nombre2;
    const destinyName = selectedDestiny?.nombre;

    return {
      inputOne: {
        ...destiny.input1,
        value: nombre2 || nombre || (inCity && originName) || "",
        show: !inCity,
      },
      inputTwo: {
        ...destiny.input2,
        value: destinyNameTwo || destinyName || "",
        show: true,
      },
      inputThree: {
        ...destiny.input3,
        value: referenceDestiny,
        show: true,
      },
    };
  };

  const { inputOne, inputThree, inputTwo } = handleInputsFormRequestDestiny();

  const handleInputsFormRequestOrigin = () => {
    const nombre = selectedCity?.nombre;
    const nombre2 = selectedCity?.nombre2;
    const originName = selectedOrigin?.nombre;
    const originNameTwo = selectedOrigin?.nombre2;

    return {
      input1: {
        ...origin.input1,
        value: nombre2 || nombre || "",
        show: false,
      },
      input2: {
        ...origin.input2,
        value: originNameTwo || originName || "",
        show: false,
      },
      input3: {
        ...origin.input3,
        value: reference,
        show: true,
      },
    };
  };

  const { input1, input2, input3 } = handleInputsFormRequestOrigin();

  const handleConfigInmueble = () => {
    return {
      ...adapterConfigInmueble(
        customerRequest,
        formRequest,
        handleChange,
        tipoInmuebles as ITipoInmuebles[]
      ),
    };
  };

  return (
    <>
      <DatePicker
        initValue={datePicker}
        inputFormat={FORMAT_DATE_DDMMYYYY}
        label={<LabelInputRequired str="Fecha de mudanza" />}
        onChange={setDatePicker}
        minDate={dayjs().add(1, "day")}
        maxDate={dayjs().add(1, "month")}
        renderInput={Input}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon name="icon-calendar" size="md" />
            </InputAdornment>
          ),
        }}
      />

      <Box sx={{ display: "flex", mt: 2, mb: 2 }}>
        <Typography sx={{ fontWeight: 700 }}>Origen:</Typography>
        <Typography sx={{ ml: 1 }}>{selectedOrigin?.nombre}</Typography>
      </Box>

      <FormRequest
        input1={input1}
        input2={input2}
        input3={input3}
        onClick={handleInputClickModal}
        onChange={handleOnChange}
        {...handleConfigInmueble()}
      />

      {showDestiny() && (
        <FormRequest
          activeDisabledInput={true}
          input1={inputOne}
          input2={inputTwo}
          input3={inputThree}
          onClick={handleInputClickModal}
          onChange={handleOnChange}
          {...handleConfigInmueble()}
        />
      )}

      <FormControl fullWidth>
        <ButtomLoader
          onClick={() => handleSubmit()}
          disabled={handleDisabledButton() || isLoading}
          isLoading={isLoading}
        >
          Guardar
        </ButtomLoader>
      </FormControl>
    </>
  );
};

export default FormOriginEditMoving;
