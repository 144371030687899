import { NoticeV2NegType } from '@/store/noticeV2Negotiation/noticeV2NegotiationSlice';
import { ID_AUCTION_FLOW, ID_MOVING_FLOW, ID_MULTI_DESTINY } from '@/consts/defaultConsts';
import { optionOne, optionTwo } from '@/consts/noticeConsts';
import { DetailNoticeMoving, DetailsNegotationV2, NoticeDetailAuctionModel } from '@/models/notice/noticeV2NegotationModels';
import { priceAuctionComponentToRenderFinished } from '../../FinishedAccept/views/AuctionAccept/config';
import PriceMoving from '../../FinishedAccept/views/MovingAccept/PriceMoving';
import { priceMultidestinyComponentToRenderFinishedAccept } from '../../FinishedAccept/views/MultidestinyAccept/config';
import { OfferPriceV2 } from './components/OfferPriceV2';

interface Props {
    data: NoticeV2NegType;
}

const componentToRenderPrice: Record<
    number,
    ({ data }: { data: DetailsNegotationV2 }) => JSX.Element
> = {
    [optionOne]: OfferPriceV2,
    [optionTwo]: OfferPriceV2,
};

const GlobalPriceFinished = ({ data }: Props) => {
    const { idTipoAvisoNegociacion, flgIsOwner } = data

    if ((data?.idtipoaviso as number) === ID_MOVING_FLOW)
        return <PriceMoving data={data as DetailNoticeMoving} />;

    if ((data?.idtipoaviso as number) === ID_MULTI_DESTINY) {
        const PriceMultidestinyComponent =
            priceMultidestinyComponentToRenderFinishedAccept[(flgIsOwner ? idTipoAvisoNegociacion : 1)];
        return <PriceMultidestinyComponent data={data} />;
    }

    if ((data?.idtipoaviso as number) === ID_AUCTION_FLOW) {
        const PriceAuctionComponent = priceAuctionComponentToRenderFinished[idTipoAvisoNegociacion || optionOne];
        return <PriceAuctionComponent data={data as NoticeDetailAuctionModel} />;
    }

    const Component = componentToRenderPrice[idTipoAvisoNegociacion || optionOne];

    if (!Component) return <></>;

    return (
        <>
            <Component data={data as DetailsNegotationV2} />
        </>
    );
}

export default GlobalPriceFinished