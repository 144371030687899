import CustomTags from "@/components/customTags/CustomTags";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import { SERVICES_LIST_DRIVER } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { resetActive, resetHistory } from "@/store/noticeRedux/myNoticeSlice";
import {
  getListActivesThunk,
  getListHistoryThunk,
} from "@/store/noticeRedux/noticeThunks";
import { Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ListChildren from "./components/ListChildren";
import { ContainerTags, HeaderContainer } from "./styleNoticeList";

const NoticeList = () => {
  const [currentTag, setCurrentTag] = useState(1);
  const [firstRender, setFirstRender] = useState(true);
  const { historyNotices, activeNotices } = useAppSelector(
    (state) => state.myNotice
  );
  const dispatch = useAppDispatch();

  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(resetActive());
      dispatch(resetHistory());
    };
  }, []);

  useEffect(() => {
    handleCurrentTag();
  }, [search]);

  useEffect(() => {
    handleChangeList(currentTag);
  }, [currentTag]);

  const handleChangeList = (currentTag = 1) => {
    if (currentTag === 1 && !activeNotices.listAvisos.length) {
      dispatch(getListActivesThunk({ page: 0 }));
    } else if (currentTag === 2 && !historyNotices.listAvisos.length) {
      dispatch(getListHistoryThunk({ page: 0 }));
    }

    if (firstRender) {
      setFirstRender(false);
    }
  };

  const handlePaginateActives = () => {
    const { currentPage, totalPages } = activeNotices;
    if (!firstRender && currentPage < totalPages - 1) {
      dispatch(getListActivesThunk({ isPaginate: true }));
    }
  };

  const handlePaginateHistory = () => {
    const { currentPage, totalPages } = historyNotices;
    if (!firstRender && currentPage < totalPages - 1) {
      dispatch(getListHistoryThunk({ isPaginate: true }));
    }
  };

  const handleCurrentTag = () => {
    const id = search[search.length - 1];
    if (!id || isNaN(Number(id))) return undefined;
    const tag = Number(id);

    setCurrentTag(tag);
  };

  const handleOnChangeTag = (activeTag: number) => {
    navigate(`${pathname}?tag=${activeTag}`);
  };

  const handleClickCreate = () => {
    navigate(SERVICES_LIST_DRIVER);
  };

  return (
    <Container maxWidth="xl" sx={{ marginTop: "41px" }}>
      <Grid container>
        <Grid item xs={12}>
          <HeaderContainer maxWidth={301}>
            <Typography variant="h6">Mis avisos</Typography>
            <ButtomLoader
              variant="outlined"
              sx={{ maxWidth: "80px", marginTop: "0", padding: "1px 16px 0" }}
              onClick={handleClickCreate}
            >
              crear
            </ButtomLoader>
          </HeaderContainer>
        </Grid>
        <Grid item xs={12}>
          <ContainerTags>
            <CustomTags
              label1="ACTIVAS"
              label2="HISTORIAL"
              onChange={handleOnChangeTag}
              initialTag={currentTag}
              childrenTag1={
                <ListChildren
                  noticeList={activeNotices.listAvisos}
                  isLoading={activeNotices.isLoading}
                  paginateCallback={handlePaginateActives}
                />
              }
              childrenTag2={
                <ListChildren
                  noticeList={historyNotices.listAvisos}
                  isLoading={historyNotices.isLoading}
                  paginateCallback={handlePaginateHistory}
                />
              }
            />
          </ContainerTags>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NoticeList;
