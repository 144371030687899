import {
  Button,
  styled,
  Typography,
  experimental_sx as sx,
} from "@mui/material";
import { Link } from "react-router-dom";

// list cards for item selected
export const CardsContent = styled("div")(
  sx({
    display: { xs: "none", md: "flex" },
    width: "100%",
    maxWidth: "1152px",
    height: "100%",
    flexDirection: "column",
    gap: "32px",
    margin: "0 auto",
    padding: "0px 32px",
  })
);

export const CardsItems = styled("div")(
  sx({
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: { xs: "32px", md: "64px" },
    marginBottom: "20px",
  })
);

export const LinkButton = styled(Link)(
  ({ theme }) => `
        text-decoration: none;
        width: 97px;
    `
);

export const ButtonCard = styled(Button)(
  ({ theme }) => `
        line-height: 24px;
        font-size: 14px;
        text-transform: uppercase;
        padding: 1px 12px;
        border-radius: 36px;
        color: ${theme.palette.org.main};
        background-color: ${theme.palette.org.contrastText};
        border: 1px solid ${theme.palette.org.main};
        &:hover {
            background-color: ${theme.palette.org.dark};
        }
    `
);

export const SectionSubTitle = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.primary.contrastText};
        font-size: 16px;
        line-height: 24px;
        font-weight: 305;
        font-style: normal;
        @media only screen and (min-width: 768px){
            font-size: 18px;
            line-height: 32px;
            font-weight: 370;
        }
    `
);

export const ContentSectionCardList = styled("div")(
  sx({
    display: "flex",
    width: "100%",
    maxWidth: "1152px",
    height: { xs: "230px", sm: "300px", md: "135px", lg: "80px", xl: "88px" },
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    flexDirection: "column",
  })
);

export const MatListContent = styled("div")(
  ({ theme }) => `
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        gap: 16px;
        transform: translateY(-140px);
        @media only screen and (min-width: 768px){
            flex-direction: row;
            transform: translateY(-85px);
        }
    `
);

export const MatListItem = styled("div")(
  ({ theme }) => `
        display: flex;
        align-items: center;
        padding:16px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 24px;
        gap: 8px;
        width: 100%;
        height: 70px;
        background-color: #FFFFFF;
        @media only screen and (min-width: 768px){ 
            height: 198px;
            flex-direction: column;
            border: 2px solid #00B3E3; 
            width: 217.6px;
            justify-content: center;
        }
    `
);

export const MatListItemContent = styled("div")(
  ({ theme }) => `
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: 100%;
        @media only screen and (min-width: 768px){
            gap: 8px;
            align-items: center;
            text-align: center;
        }
    `
);
