import { useAppSelector } from "@/hooks/useReducer";
import { ISolicitudViewDetailsResponse } from "@/models/customerRequest/requestDetailsVewResponse";
import { objectRenderComponent } from "../config";

const BodyApplication = () => {
  const { requestDetailsView } = useAppSelector(
    (state) => state.customerRequest
  );
  if (!requestDetailsView) return <></>;

  const { tipoSolicitud } = requestDetailsView as ISolicitudViewDetailsResponse;

  const Component = objectRenderComponent[tipoSolicitud?.id as number];
  if (!Component) return <></>;

  return <Component />;
};

export default BodyApplication;
