import BasicModal from "@/components/dialog/Notiffication";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import { useGetRequestData } from "@/pages/requestNegotiation/shared/hooks/useGetRequestData";

import { currencyFormat } from "@/utils/helpers";
import { Box, FormControl, Grid, Typography, styled } from "@mui/material";
import { FormClientCounterOfferInCityEdition } from "./FormClientCounterOfferInCityEdition";
import { useFormClientCounterOfferInCity } from "./useFormClientCounterOfferInCity";

const Container = styled(Grid)(
  () => `
    display: flex;
    flex-direction: column;  
    margin-top: 24px;
  `
);

const FormClientCounterOfferInCity = () => {
  const {
    isLoading,
    isEdition,
    errorPrice,
    preciocontraoferta,
    handleSubmit,
    handleDisabledButton,
    handleOnchange,
  } = useFormClientCounterOfferInCity();

  const { data } = useGetRequestData();

  if (isEdition)
    return ( <FormClientCounterOfferInCityEdition/>
    );

  return (
    <Container container>
      <Box sx={{ display: "flex" }}>
        <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
          Precio de tu solicitud:
        </Typography>
        <Typography sx={{ ml: 1 }}>
          {currencyFormat(data?.solicitud.precio as number)}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", mb: 2 }}>
        <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
          Transportista ofrece:
        </Typography>
        <Typography sx={{ ml: 1 }}>
          {currencyFormat(data?.precio as number)}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <FormControl sx={{ marginTop: "16px", width: "100%" }}>
          <Input
            id={`$1-price`}
            name="price"
            label={<LabelInputRequired str="Precio ofrecido (S/)" />}
            value={preciocontraoferta}
            variant="standard"
            placeholder="Ingresa tu precio"
            onChange={handleOnchange}
            onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              inputMode: "numeric",
            }}
            error={!!errorPrice}
            helperText={errorPrice}
          />
        </FormControl>

        <Grid item xs={12} sx={{ marginTop: "16px" }}>
          <ButtomLoader
            type="submit"
            buttomProps={{
              disabled: handleDisabledButton() || isLoading,
            }}
            isLoading={isLoading}
          >
            ENVIAR
          </ButtomLoader>
        </Grid>
      </form>
      <BasicModal goBack />
    </Container>
  );
};

export default FormClientCounterOfferInCity;
