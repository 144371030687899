import HeaderSection from "@/components/headerSection/HeaderSection";
import SectionIcon from "@/components/sectionIcon/SectionIcon";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { getPrevRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { Container, styled } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { customerRequestObjectTypes } from "../../shared/consts/customerRequestConsts";
const ContainerArea = styled(Container)(
  () => `
      padding: 16px 0;
    `
);
const Detalles = () => {
    const dispatch = useAppDispatch();
  const { selectedTypeRequest } = useAppSelector((state) => state.customerRequest);

  const navigate = useNavigate();

  const handlePrevRute = () => {
    const valueToReplace = customerRequestObjectTypes[selectedTypeRequest.id];
    dispatch(getPrevRoute(navigate, { valueToSearch: ":typeRequest", valueToReplace }));
  };
  return (
    <Container maxWidth="xs">
      <HeaderSection icon="icon-arrow-left--primary" title="Cantidad de ambientes" subTitle="Solicitar mudanza" onClick={handlePrevRute} />
      <ContainerArea>
        <SectionIcon icon="icon-box" />
        <Outlet />
      </ContainerArea>
    </Container>
  )
}

export default Detalles