import BasicModal from "@/components/dialog/Notiffication";
import HeaderSection from "@/components/headerSection/HeaderSection";
import SkeletonCustomGroup from "@/components/skeletons/SkeletonCustomGroup";
import UserRating from "@/components/userRating/UserRating";
import {
  ACCEPTANCE_COUNTER_OFFERT,
  CO_COUNTEROFFERT_CLIENT,
  NOTICE_COUNTEROFFER,
  NOTICE_OFFER,
} from "@/consts/acceptanceOffertPath";
import { DRIVER_TYPE, NOTICE_NOT_FOUND } from "@/consts/defaultConsts";
import { NEGOTATION_REJECTION_CONFIRMATION } from "@/consts/negotationFlowPath";
import {
  optionOne,
  optionThree,
  optionTwo,
  textCurrentState,
  textStateChangeAlert,
} from "@/consts/noticeConsts";
import { ERROR_NOTICE_CLOSED } from "@/consts/textErrors";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { Negotiation } from "@/models/offert/offertModels";
import NotFound from "@/pages/notFound/NotFound";
import DynamicComponent from "@/pages/offerts/offertConfirmation/components/DynamicComponent";
import OffertBodyConfirmationInCity from "@/pages/offerts/offertConfirmation/components/offertBodyConfirmation/OffertBodyConfirmationInCity";
import { resetError } from "@/store/offertRedux/offert.slice";
import { getOffertByIdThunk } from "@/store/offertRedux/offert.thunk";
import { IUser } from "@/store/userRedux/userReduxInterface";
import {
  compareUrls,
  deleteDomainUrl,
  getErrorButtonLinkNotFound,
  validateAuthorizationByCode,
} from "@/utils/helpers";
import {
  Alert,
  Box,
  Collapse,
  Container,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useId, useMemo, useState } from "react";
import {
  Link as ReactLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  acceptanceAdapter,
  dataProfileFormat,
} from "../adapters/AcceptanceAdapter";
import CounterOffertSection from "../components/CounterOffertSection";
import {
  EditNoticeCounterOfferButtons,
  EditNoticeOfferButtons,
} from "../../noticeV2Negotation/components/EditNoticeButtons";
import RowCollapse from "../components/RowCollapse";
import GlobalButtonsPrincipal from "../globalButtons/GlobalButtonsPrincipal";
import {
  ConfigDataAcceptance,
  handleValidAcceptanceProps,
} from "../interfaces/acceptanceInterface";
import { handleValidDriveRender } from "../utils/utilsAcceptance";
import {
  configDataAcceptance,
  configDataAcceptanceAuction,
  configDataAcceptanceOfferMovingDriver,
  configDataCoCounterOffer,
  configDataCocounterOfferAgreement,
  handleTextAlert,
  typeTextObjToRender,
} from "./configDataAcceptance";
import { handleValidAcceptance } from "../utils/helpersAcceptance";

interface LocationState {
  hasBackNavigate?: boolean;
  hasBackLinkOffert?: boolean;
}

const optionButtonsToRender: Record<number, () => JSX.Element> = {
  1: EditNoticeOfferButtons,
  2: EditNoticeCounterOfferButtons,
  3: EditNoticeOfferButtons,
};

const Acceptance = () => {
  const {
    offert: { error, errorCode, savedOffert, isLoading },
    user: { user },
  } = useAppSelector((state) => state);

  const currentId = useId();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { idOffert = "" } = useParams();
  const { pathname, state } = useLocation();

  const locationState = state as LocationState;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const [isCollapse, setisCollapse] = useState(false);
  const [hasBackNavigate, setHasBackNavigate] = useState(false);
  const [hasBackLinkOffert, setHasBackLinkOffert] = useState(false);
  const [onCloseAlert, setOnCloseAlert] = useState(false);

  const isDriver = (user as IUser).tipousuario === DRIVER_TYPE;
  const isOffer = pathname.includes(NOTICE_OFFER);
  const pathnameReplace = pathname.replace(idOffert, ":idOffert");
  const textTypeNeg = pathname.includes(NOTICE_COUNTEROFFER)
    ? "contraoferta"
    : "oferta";

  const {
    typeFlow,
    isAuction,
    isMoving,
    isDriverCounterOffer,
    validSnackbarDriver,
    validSnackbarClient,
  } = acceptanceAdapter(savedOffert as Negotiation);

  const clientOrDriver = isDriver ? validSnackbarDriver : validSnackbarClient;

  const textToRender = handleTextAlert(textTypeNeg, isDriver, typeFlow);
  const flgAbierto = savedOffert?.ultimaAvisoNegociacionAcciones
    ?.tipoAvisoNegociacionAccion?.estado?.flgAbierto as number;

  const negotationClosed = error === ERROR_NOTICE_CLOSED;

  useEffect(() => {
    dispatch(getOffertByIdThunk(Number(idOffert)));

    if (locationState?.hasBackNavigate && !locationState?.hasBackLinkOffert) {
      setHasBackNavigate(true);
    }

    if (locationState?.hasBackLinkOffert && !locationState?.hasBackNavigate) {
      setHasBackLinkOffert(true);
    }

    return () => {
      dispatch(resetError());
    };
  }, []);

  useEffect(() => {
    validateAuthorizationByCode({ errorCode, navigate });
  }, [errorCode]);

  const handleNavigationBack = () => {
    navigate(-1);
  };

  const textTypeToRender = () => {
    const firstOption = isDriver ? optionTwo : optionOne;
    const secondOption = isOffer ? optionOne : optionTwo;
    const thirdOption = isOffer ? optionOne : optionTwo;

    return typeTextObjToRender[firstOption][secondOption][thirdOption];
  };

  const handleButtonComponent = () => {
    let option = savedOffert?.tipoAvisoNegociacion?.id as number;

    if (!isDriver && isMoving) {
      option = optionThree;
    }

    return option;
  };

  const ButtonToRender = optionButtonsToRender[handleButtonComponent()];

  const validDriverRender = handleValidDriveRender(
    savedOffert as Negotiation,
    isMoving
  );

  const handleGetIsRejectOffert = () => {
    if (
      pathnameReplace === CO_COUNTEROFFERT_CLIENT ||
      pathnameReplace === ACCEPTANCE_COUNTER_OFFERT
    ) {
      let configToReturn = configDataCocounterOfferAgreement;

      if (isMoving && isDriverCounterOffer) {
        configToReturn = configDataCoCounterOffer;
      }

      return configToReturn;
    }

    if (validDriverRender) {
      return configDataAcceptanceOfferMovingDriver;
    }

    if (isAuction) {
      return configDataAcceptanceAuction;
    }

    return configDataAcceptance || [];
  };

  const selectconfigData = useMemo(
    () => handleGetIsRejectOffert(),
    [savedOffert]
  );

  const handleInitConfig = (config: ConfigDataAcceptance) => {
    const configuration = {
      config,
      savedOffert,
      user,
    } as handleValidAcceptanceProps;

    let moreProps = handleValidAcceptance(configuration);

    return { ...moreProps };
  };

  const handleNegotationClosed = () => {
    const nextPath = pathname + NEGOTATION_REJECTION_CONFIRMATION;

    return nextPath;
  };

  if (!isLoading && !!errorCode) {
    return (
      <NotFound
        title="¡Lo sentimos!"
        description="La negociación no está disponible"
        buttonText="VER MIS NEGOCIACIONES"
        buttonLink={getErrorButtonLinkNotFound(user as IUser)}
        image={NOTICE_NOT_FOUND}
      />
    );
  }

  if (isLoading || !savedOffert) {
    return (
      <Box sx={{ marginTop: "35px" }}>
        <SkeletonCustomGroup
          isContainer
          skeletonList={[
            { height: 104, width: 328 },
            { height: 52, width: 328 },
            { height: 433, width: 328 },
          ]}
          gap="16px"
        />
      </Box>
    );
  }

  return (
    <>
      <Container maxWidth={matches ? "xl" : "xs"} sx={{ marginTop: "35px" }}>
        {(hasBackNavigate || hasBackLinkOffert) && (
          <HeaderSection
            icon="icon-arrow-left--primary"
            title="Oferta"
            onClick={() => handleNavigationBack()}
            sx={{
              boxShadow: "none",
              display: "flex",
              justifyContent: "start",
            }}
          />
        )}
        {(savedOffert?.redirect &&
          compareUrls(savedOffert?.redirect, pathname)) ||
        negotationClosed ? (
          <Alert
            sx={{ mb: 3, p: 2 }}
            severity={negotationClosed ? "error" : "warning"}
          >
            {textStateChangeAlert}{" "}
            <Link
              component={ReactLink}
              color={negotationClosed ? "error" : "rgba(237,108,2,1)"}
              fontWeight={600}
              to={
                negotationClosed
                  ? handleNegotationClosed()
                  : deleteDomainUrl(savedOffert?.redirect || "")
              }
              replace
            >
              {textCurrentState}
            </Link>
          </Alert>
        ) : (
          clientOrDriver &&
          !onCloseAlert && (
            <Alert
              onClose={() => {
                setOnCloseAlert(true);
              }}
              sx={{ mb: 3, mt: 3, p: 2 }}
              severity="info"
            >
              {textToRender}
            </Alert>
          )
        )}

        <Grid container>
          <Grid xs={matches ? 4 : 12} sx={{ p: matches ? 2 : 0 }}>
            <Collapse collapsedSize="68px" in={matches ? true : isCollapse}>
              {selectconfigData.map((item, i) => (
                <DynamicComponent
                  key={`${currentId}-${i}`}
                  {...item}
                  {...handleInitConfig(item)}
                />
              ))}
            </Collapse>

            <RowCollapse
              isCollapse={isCollapse}
              setisCollapse={setisCollapse}
              offert={savedOffert}
            />
          </Grid>

          <Grid
            xs={matches ? 8 : 12}
            sx={
              matches
                ? { border: "1px solid #EFEFEF", borderRadius: "16px", p: 4 }
                : {}
            }
          >
            <Grid container>
              <Grid xs={matches ? 6 : 12}>
                <Typography
                  variant="body2"
                  sx={{ color: "#757575", mb: "4px", mt: 2 }}
                >
                  {textTypeToRender()}
                </Typography>

                {isDriver && (
                  <UserRating data={dataProfileFormat(savedOffert)} />
                )}

                <Box sx={{ mt: 2 }}></Box>

                <OffertBodyConfirmationInCity hiddenButton />

                <CounterOffertSection />
              </Grid>
              <Grid xs={matches ? 6 : 12}>
                {clientOrDriver && !!flgAbierto ? (
                  <ButtonToRender />
                ) : (
                  <GlobalButtonsPrincipal />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <BasicModal />
    </>
  );
};

export default Acceptance;
