import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import Input from "@/components/inputCustom/Input";
import { maxLengthPrice, textCannotLowerPrice } from "@/consts/noticeConsts";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { EventType } from "@/interfaces/globalInterface";
import SectionOriginDestiny from "@/pages/createServices/fee/SectionOriginDestiny";
import { customerRequestObjectTypes } from "@/pages/customerRequest/shared/consts/customerRequestConsts";
import LabelInputRequired from "@/pages/flowNoticeNegotation/components/LabelInputRequired";
import {
  saveCustomerBetweenCitiesRequestThunk
} from "@/store/customerRequestRedux/customerRequestThunk";
import { getNextRoute } from "@/store/routeActionsFlow/routeActionsFlowNoticeThunk";
import { FormControl } from "@mui/material";
import { useId, useState } from "react";
import { useNavigate } from "react-router-dom";

const FormPriceBetweenCities = () => {
  const [price, setPrice] = useState("");

  const {
    selectedCity,
    selectedOrigin,
    selectedCityDestiny,
    selectedDistrictDestiny,
    isLoading,
    selectedTypeRequest,
  } = useAppSelector((state) => state.customerRequest);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const id = useId();

  const handleOnchange = ({ currentTarget }: EventType["change"]) => {
    const { value } = currentTarget;
    const prepareValue = value.replace(/^(0+)/g, "").replace(/[^0-9]/g, "");

    if (prepareValue.length <= maxLengthPrice) {
      setPrice(prepareValue);
    }
  };

  const handleSubmit = () => {
    if (isLoading) return;

    dispatch(saveCustomerBetweenCitiesRequestThunk(price)).then(handleNextRute);
  };

  const handleNextRute = () => {
    const valueToReplace = customerRequestObjectTypes[selectedTypeRequest.id];
    dispatch(
      getNextRoute(navigate, { valueToSearch: ":typeRequest", valueToReplace })
    );
  };

  const handleDisabledButton = () => {
    const conditions = [price, !isLoading, validForm().isValid];

    return conditions.some((condition) => !condition);
  };

  const validForm = () => {
    return {
      isValid: Number(price) >= 50 || !price,
      message: textCannotLowerPrice,
    };
  }

  const deleteEmpty = (str: string) => {
    return str
      .split(', ')
      .filter(item => item !== '' && !item.includes(','))
      .join(', ');
  }

  const textContactShowTitle = (destiny?: boolean) => {
    if (destiny) {
      return `${deleteEmpty(`${selectedDistrictDestiny.nombre}, ${selectedCityDestiny?.pais}`)}`;
    }
    return `${deleteEmpty(`${selectedOrigin.nombre}, ${selectedCity?.pais}`)}`;
  }



  return (
    <>
      <SectionOriginDestiny
        destiny={textContactShowTitle(true)}
        origin={textContactShowTitle()}
      />
      <FormControl sx={{ marginTop: "16px", width: "100%" }}>
        <Input
          id={`${id}-price`}
          name="price"
          label={<LabelInputRequired str="Ofrece tu precio (S/)" />}
          value={price}
          variant="standard"
          placeholder="Lo que ofreces por este servicio"
          onChange={handleOnchange}
          onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            inputMode: "numeric",
            maxLength: 6,
          }}
          error={!validForm().isValid}
          helperText={!validForm().isValid && validForm().message}
        />
      </FormControl>
      <ButtomLoader
        onClick={handleSubmit}
        sx={{ marginTop: "24px" }}
        isLoading={isLoading}
        disabled={handleDisabledButton()}
      >
        Solicitar
      </ButtomLoader>
    </>
  );
};

export default FormPriceBetweenCities;
