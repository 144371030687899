import { actionsToNoticeNegotation } from '@/consts/actions';
import {
  ID_AUCTION_FLOW,
  ID_BETWEEN_CITIES,
  ID_IN_CITY,
  ID_MOVING_FLOW,
  ID_MULTI_DESTINY,
} from '@/consts/defaultConsts';
import {
  acceptCounterOfferClient,
  acceptNoticeClient,
  cocounterNoticeDriver,
  cocounterOfferNoticeDataLayer,
} from '@/services/dataLayer/createNoticeNeg';

export type ActionButtonV2 = {
  1: number;
  2?: number;
  3: number;
};

export const actionButtonNoticeV2: Record<number, ActionButtonV2> = {
  // Si el cliente acepta el aviso del transportista:
  1: {
    1: actionsToNoticeNegotation[3].id, // El transportista acepta
    3: actionsToNoticeNegotation[4].id, // El transportista rechaza
  },

  // Si el cliente contraoferta el aviso del transportista:
  2: {
    1: actionsToNoticeNegotation[11].id, // El transportista acepta
    2: actionsToNoticeNegotation[5].id, // El transportista contraoferta
    3: actionsToNoticeNegotation[12].id, // El transportista rechaza
  },

  // Si el transportista contraoferta la contraoferta del cliente:

  3: {
    1: actionsToNoticeNegotation[6].id, // El cliente acepta
    3: actionsToNoticeNegotation[7].id, // El cliente rechaza
  },
};

export const ActionDataLayerV2: Record<
  number,
  Record<number, (idUsuario: number, noticeType: string) => void>
> = {
  1: {
    1: acceptNoticeClient,
  },
  2: {
    1: acceptCounterOfferClient,
    2: cocounterOfferNoticeDataLayer,
  },
  5: {
    1: cocounterNoticeDriver,
  },
};

export const typeNoticeName: Record<number, string> = {
  [ID_IN_CITY]: 'en-la-ciudad',
  [ID_BETWEEN_CITIES]: 'entre-ciudades',
  [ID_MULTI_DESTINY]: 'multidestino',
  [ID_MOVING_FLOW]: 'mudanza',
  [ID_AUCTION_FLOW]: 'remate',
};

// tipos de estados accion de la negociacion                            |Sistema - Anula Oferta

// crear el type de la base de datos
export type ActionNoticeNegotiation =
  | 'Transportista - Acepta'
  | 'Transportista - Rechaza'
  | 'Transportista - Contraoferta'
  | 'Cliente - Acepta Co-Contraoferta'
  | 'Cliente - Rechaza Co-Contraoferta'
  | 'Transportista - Acepta Contraoferta'
  | 'Cliente - Acepta'
  | 'Transportista - Rechaza Contraoferta'
  | 'Cliente - Retira Oferta'
  | 'Cliente - Retira Contraoferta'
  | 'Cliente - Contraoferta'
  | 'Transportista - Retira Co-contraoferta'
  | 'Sistema - Anula Co-Contraoferta'
  | 'Sistema - Anula Contraoferta'
  | 'Sistema - Anula Co-Contraoferta2'
  | 'Sistema - Anula Oferta'
  | 'Sistema - Anula Contraoferta2'
  | 'Sistema - Anula Oferta2';

// crear una forma de usar extraer el id por el nombre de la accion
export const actionNegotationNameToId: Record<ActionNoticeNegotiation, number> =
  {
    'Transportista - Acepta': 3,
    'Transportista - Rechaza': 4,
    'Transportista - Contraoferta': 5,
    'Cliente - Acepta Co-Contraoferta': 6,
    'Cliente - Rechaza Co-Contraoferta': 7,
    'Transportista - Acepta Contraoferta': 11,
    'Cliente - Acepta': 1,
    'Transportista - Rechaza Contraoferta': 12,
    'Cliente - Retira Oferta': 13,
    'Cliente - Retira Contraoferta': 14,
    'Cliente - Contraoferta': 2,
    'Transportista - Retira Co-contraoferta': 15,
    'Sistema - Anula Co-Contraoferta': 16,
    'Sistema - Anula Contraoferta': 17,
    'Sistema - Anula Co-Contraoferta2': 18,
    'Sistema - Anula Oferta': 8,
    'Sistema - Anula Contraoferta2': 9,
    'Sistema - Anula Oferta2': 10,
  };


  export const declineActionNotice: Record<string, any> = {
    oferta: {
      1: actionsToNoticeNegotation[13].id,
      2: "",
    },
    contraoferta: {
      1: actionsToNoticeNegotation[14].id,
      2: actionsToNoticeNegotation[15].id,
    },
  };