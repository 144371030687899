import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import DatePicker from "@/components/form/datePicker.tsx/DatePicker";
import Input from "@/components/inputCustom/Input";
import { InputAdornment, InputProps } from "@mui/material";
import dayjs from "dayjs";
import { FORMAT_DATE_FILTER } from "../consts/filtersConst";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import CallSplitOutlinedIcon from "@mui/icons-material/CallSplitOutlined";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import {
  renderInputBetweenDate,
  renderInputTimeService,
  resizeButtonColumsProps,
  resizeButtonProps,
  resizeInputsProps,
} from "./callbacks";

const initDate = dayjs().add(1, "day");
const maxDate = dayjs().add(1, "month");

export interface Props {
  fullWidth?: boolean;
  icon?: string;
  label: string;
  name: any;
  placeholder?: string;
  type?: string;
  InputProps?: Partial<InputProps>;
  minDate?: dayjs.Dayjs;
  renderInput?: any;
  inputFormat?: string;
}

// interface ConfigInCity {
//   props:
// }

export const configInCity = [
  [
    {
      styleRow: {
        marginTop: "16px",
      },
      columns: [
        {
          columnsProps: {
            lg: 4,
            md: 4,
            xs: 12,
            sx: {
              paddingRight: "15px",
              marginTop: { xs: "8px", sm: "8px", md: 0, lg: 0 },
            },
            propertyLink: "inputciudad",
            callback: resizeInputsProps,
          },
          component: Input,
          props: {
            fullWidth: true,
            icon: "icon-buildings--black",
            label: "Ciudad",
            placeholder: "Ciudad",
            name: "city",
          },
        },
      ],
    },
  ],
  [
    {
      styleRow: {
        marginTop: "16px",
      },
      columns: [
        {
          columnsProps: {
            lg: 4,
            md: 4,
            xs: 12,
            sx: { paddingRight: "15px" },
            callback: resizeInputsProps,
          },
          component: Input,
          props: {
            fullWidth: true,
            icon: "icon-location-black",
            label: "Origen",
            placeholder: "Origen",
            name: "origin",
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <FmdGoodOutlinedIcon />
                </InputAdornment>
              ),
            },
          },
        },
        {
          columnsProps: {
            lg: 4,
            md: 4,
            xs: 12,
            sx: { paddingRight: "15px" },
            callback: resizeInputsProps,
          },
          component: Input,
          props: {
            fullWidth: true,
            icon: "icon-location-black",
            label: "Destino",
            placeholder: "Destino",
            name: "destiny",
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <FmdGoodIcon />
                </InputAdornment>
              ),
            },
          },
        },
        {
          columnsProps: {
            lg: 4,
            md: 4,
            xs: 12,
            sx: { paddingRight: "15px" },
            callback: renderInputBetweenDate,
          },
          component: Input,
          props: {
            fullWidth: true,
            label: "Fechas del servicio",
            name: "betweenDate",
            inputFormat: FORMAT_DATE_FILTER,
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarMonthOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: null,
            },
          },
        },
        {
          columnsProps: {
            lg: 4,
            md: 4,
            xs: 12,
            sx: { paddingRight: "15px" },
            callback: renderInputTimeService,
          },
          component: DatePicker,
          props: {
            fullWidth: true,
            initValue: initDate,
            label: "Fecha del servicio",
            name: "timeService",
            minDate: initDate,
            maxDate: maxDate,
            icon: "",
            renderInput: Input,
            inputFormat: FORMAT_DATE_FILTER,
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarMonthOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: null,
            },
          },
        },
      ],
    },
  ],
  [
    {
      styleRow: {
        marginTop: "16px",
      },
      columns: [
        {
          columnsProps: {
            lg: 4,
            md: 4,
            xs: 12,
            sx: { paddingRight: "15px" },
            callback: resizeInputsProps,
          },
          component: Input,
          props: {
            fullWidth: true,
            icon: "icon-truck--input",
            label: "Tipo de vehículo",
            placeholder: "Tipo de vehículo",
            name: "typeVehicle",
            inputProps: {
              sx: { textOverflow: "ellipsis" },
            },
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <LocalShippingOutlinedIcon />
                </InputAdornment>
              ),
            },
          },
        },
        {
          columnsProps: {
            lg: 4,
            md: 4,
            xs: 12,
            sx: { paddingRight: "15px" },
            callback: resizeInputsProps,
          },
          component: Input,
          props: {
            fullWidth: true,
            icon: "icon-load--input",
            label: "Peso de la carga",
            placeholder: "Peso de la carga",
            name: "weightOfTheLoad",
            propertyLink: "inputpeso",
          },
        },
        {
          columnsProps: {
            lg: 4,
            md: 4,
            xs: 12,
            sx: { paddingRight: "15px" },
            propertyLink: "inputhoraopunto",
            callback: resizeInputsProps,
          },
          component: Input,
          props: {
            fullWidth: true,
            icon: "",
            label: "Modalidad del servicio",
            placeholder: "Por hora o punto",
            name: "typeServiceList",
            InputProps: {
              startAdornment: (
                <InputAdornment position="start" sx={{ rotate: "90deg" }}>
                  <CallSplitOutlinedIcon />
                </InputAdornment>
              ),
            },
          },
        },
        {
          columnsProps: {
            lg: 4,
            callback: resizeButtonColumsProps,
          },
          component: ButtomLoader,
          children: "buscar transportistas",
          props: {
            type: "button",
            label: "",
            name: "",
            callback: resizeButtonProps,
          },
        },
      ],
    },
  ],
];
