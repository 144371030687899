import { useAppSelector } from "@/hooks/useReducer";
import { IAvisoCreadoData } from "@/services/noticeServices/noticeSendData/noticeSendDataTypesResponse";
import Icon from "@/utils/Icon";
import {
  BodyBox,
  ContainerBody,
  SectionBody,
  SectionHeaderFooter,
  SubTitleBody,
  TitleBody,
} from "./styleBodySuccess";

const BodySuccessIntercitySale = () => {
  const { echoResponse } = useAppSelector((state) => state.noticeV2);
  const {
    avisoscreados: [{
      origentexto,
      destinotexto,
      placa,
      pesomaximo,
      fechafija,
      precio,
    }],
  } = echoResponse as IAvisoCreadoData;

  return (
    <ContainerBody
      container
      columns={{ xs: 2 }}
      rowSpacing={{ xs: 0 }}
      columnSpacing={{ xs: 0 }}
      justifyContent="center"
      justifyItems="center"
    >
      <SectionHeaderFooter
        item
        xs={2}
        borderBottom={({ palette }) => ({
          borderBottom: `1px solid ${palette.borderGray.main}`,
        })}
      >
        <TitleBody > Origen </TitleBody>
        <SubTitleBody>{origentexto}</SubTitleBody>
      </SectionHeaderFooter>
      
      <SectionBody item xs={12}
        borderBottom={({ palette }) => ({
          borderBottom: `1px solid ${palette.borderGray.main}`,
        })}>
        <BodyBox>
          <Icon name="icon-calendar" size="sm" />
          <TitleBody>Fecha de salida</TitleBody>
        </BodyBox>
        <SubTitleBody>
          {fechafija}
        </SubTitleBody>
      </SectionBody>

      <SectionBody item xs={12}
        borderBottom={({ palette }) => ({
          borderBottom: `1px solid ${palette.borderGray.main}`,
        })}>
        <BodyBox>
          <Icon name="icon-kilos" size="md" />
          <TitleBody>Capacidad disponible</TitleBody>
        </BodyBox> 
        <SubTitleBody>
          {pesomaximo} kilos
        </SubTitleBody>
      </SectionBody>
      <SectionBody item xs={1} className="simgle">
        <BodyBox>
        <Icon name="icon-truck" size="md" />
          <TitleBody>Vehículo</TitleBody>
        </BodyBox>
        <SubTitleBody>{placa}</SubTitleBody>
      </SectionBody>
      <SectionBody item xs={1} className="simgle">
        <BodyBox>
        <Icon name="icon-money" size="md" />
          <TitleBody>Precio</TitleBody>
        </BodyBox>
        <SubTitleBody>S/ {precio}</SubTitleBody>
      </SectionBody>
      
      <SectionHeaderFooter
        item
        xs={2}
        borderTop={({ palette }) => ({
            borderTop: `1px solid ${palette.borderGray.main}`,
        })}
      >
        <TitleBody >Destino</TitleBody>
        <SubTitleBody>{destinotexto}</SubTitleBody>
      </SectionHeaderFooter>

    </ContainerBody>
  );
};

export default BodySuccessIntercitySale;
