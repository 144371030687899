import ButtonRedirectRating from "@/components/buttonRedirectRating/ButtonRedirectRating";
import BasicModal from "@/components/dialog/Notiffication";
import SnackbarNotification from "@/components/snackbar/Snackbar";
import UserRating from "@/components/userRating/UserRating";
import { hiddenPricenInRowCollapse, optionTwo } from "@/consts/noticeConsts";
import { SolicitudNegociacion } from "@/models/noticeNegotation/noticeNegotationModel";
import { dataProfileFormatRequestNeg } from "@/pages/noticeRequestFlow/adapters/noticeRequestFlowAdapters";
import Photos from "@/pages/noticeRequestFlow/components/Photos";
import RowCollapse from "@/pages/noticeRequestFlow/components/RowCollapse";
import useNoticeConfigData from "@/pages/noticeRequestFlow/hooks/useNoticeConfigData";
import DynamicComponent from "@/pages/offerts/offertConfirmation/components/DynamicComponent";
import ArrowBack from "@/pages/viewService/city/components/ArrowBack";
import { Collapse, Container, Grid } from "@mui/material";
import { useEffect, useId, useState } from "react";
import { useLocation } from "react-router-dom";
import useAgreementConfirmation from "../../../hooks/useAgreementConfirmation";

interface LocationState {
  
  hasBackLinkOffert?: boolean;
  hasBackNavigate?: boolean;
  showCalification?: boolean;
}

const AgreementConfirmationCounterOffer = () => {
  const [hasBackNavigate, setHasBackNavigate] = useState(false);
  const [showBtnCalification, setShowBtnCalification] = useState(false);
  const [isCollapse, setisCollapse] = useState(true);
  const hiddenLineDivider = true;

  const { handleInitConfig, handleNavigationBack } = useNoticeConfigData();
  const {
    matches,
    showTwoAvatars,
    savedDataNogotation,
    selectOneconfigData,
    selectConditionconfigData,
    selectThreeconfigData,
    selectTwoconfigData,
    setConfigToRender,
    isOwner,
  } = useAgreementConfirmation();

  const { state } = useLocation();
  const currentId = useId();
  const locationState = state as LocationState;



  useEffect(() => {
    if (locationState?.hasBackLinkOffert) {
      setHasBackNavigate(true);
    }
    if (locationState?.showCalification) {
      setShowBtnCalification(true);
    }
    setConfigToRender(optionTwo);
  }, []);

  return (
    <>
      <Container maxWidth={matches ? "xl" : "xs"} sx={{ marginTop: "35px" }}>
        <SnackbarNotification />
        <ArrowBack show={hasBackNavigate} onClick={handleNavigationBack} />

        <Grid container>
          <Grid item xs={matches ? 6 : 12} sx={{ p: matches ? 2 : 0 }}>
            <Collapse collapsedSize="68px" in={matches ? true : isCollapse}>
              {selectOneconfigData.map((item, i) => (
                <DynamicComponent key={`${currentId}-${i}`} {...item} {...handleInitConfig(item)} />
              ))}

              <UserRating data={dataProfileFormatRequestNeg(savedDataNogotation as SolicitudNegociacion, showTwoAvatars)} />

              {selectTwoconfigData.map((item, i) => (
                <DynamicComponent key={`${currentId}-${i}`} {...item} {...handleInitConfig(item)} />
              ))}

              <br />
              <Photos />

              {!matches &&
                selectConditionconfigData.map((item, i) => (
                  <DynamicComponent key={`${currentId}-${i}`} {...item} {...handleInitConfig(item)} />
                ))}
            </Collapse>

            <RowCollapse
              hiddenPricenInRowCollapse={hiddenPricenInRowCollapse}
              isCollapse={isCollapse}
              setisCollapse={setisCollapse}
              negotation={savedDataNogotation}
              hiddenLineDivider={hiddenLineDivider}
            />
          </Grid>

          <Grid item xs={matches ? 6 : 12} sx={matches ? { border: "1px solid #EFEFEF", borderRadius: "16px", p: 4 } : {}}>
            <Grid container>
              <Grid item xs={matches ? 12 : 12}>
                {matches &&
                  selectConditionconfigData.map((item, i) => (
                    <DynamicComponent key={`${currentId}-${i}`} {...item} {...handleInitConfig(item)} />
                  ))}

                <br />

                {selectThreeconfigData.map((item, i) => (
                  <DynamicComponent key={`${currentId}-${i}`} {...item} {...handleInitConfig(item)} />
                ))}
                {showBtnCalification && <ButtonRedirectRating isOwner={isOwner} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <BasicModal />
    </>
  );
};

export default AgreementConfirmationCounterOffer;
