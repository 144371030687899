import { RatingStyles } from '@/components/userRating/styledUserRating';
import { useAppSelector } from '@/hooks/useReducer';
import { ISolicitudViewDetailsResponse } from '@/models/customerRequest/requestDetailsVewResponse';
import { UsuarioAPI } from '@/models/userAuth/user';
import { ISolicitudNegociacion } from '@/services/interfaces/RequestNegResponseSuccess';
import { useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { dataDriverFormat, dataProfileFormat } from '../../utils/bodyUtils';

const useSingleUorTFinishedHook = () => {
  const [openRating, setOpenRating] = useState<boolean>(false);

  const {
    user: { user },
    noticeNegotationRequest: { savedDataNogotation },
  } = useAppSelector((state) => state);
  let { flgIsOwner, solicitud, transportista } =
    savedDataNogotation as ISolicitudNegociacion;

  solicitud = {
    ...solicitud,
    transportista,
  };

  const theme = useTheme();
  const { pathname } = useLocation();
  const { avatarStyles, gridContainerStyles, gradeTextStyles } = RatingStyles;
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const onOpenRating = (viewRating?: boolean) => {
    if (!viewRating) return;
    setOpenRating(true);
  };

  const onCloseRating = () => {
    setOpenRating(false);
  };

  const handleData = () => {
    if ((user as UsuarioAPI).tipousuario === 'C') {
      return dataDriverFormat(solicitud as ISolicitudViewDetailsResponse);
    }
    return dataProfileFormat(solicitud as ISolicitudViewDetailsResponse);
  };

  return {
    avatarStyles,
    gridContainerStyles,
    gradeTextStyles,
    matches,
    pathname,
    onOpenRating,
    onCloseRating,
    openRating,
    handleData,
    flgIsOwner,
  };
};

export default useSingleUorTFinishedHook;
