import { IAvisoCreadoData } from "@/services/noticeServices/noticeSendData/noticeSendDataTypesResponse";
import { useAppSelector } from "../../../../hooks/useReducer";
import BodySuccessMultiNotice from "./BodySuccessMultiNotice";
import BodySuccessUnitNotice from "./BodySuccessUnitNotice";

const BodySuccessInCity = () => {
  // const savedData = useAppSelector((state) => state.saveFlowData);
  const {
    echoResponse,
  } = useAppSelector((state) => state.noticeV2);

  const isMulti = (echoResponse as IAvisoCreadoData)?.avisoscreados.length > 1;

  return isMulti ? (
    <BodySuccessMultiNotice createdData={(echoResponse as IAvisoCreadoData)?.avisoscreados} />
  ) : (
    <BodySuccessUnitNotice createdData={(echoResponse as IAvisoCreadoData).avisoscreados}/>
  );
};

export default BodySuccessInCity;
