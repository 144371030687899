import LayoutFlow from "@/components/LayoutFlow/LayoutFlow";
import ListSteps, { ListStepsProps } from "@/components/ListSteps/ListSteps";
import StandardAlert from "@/components/alerts/StandardAlert";
import HeaderSection from "@/components/headerSection/HeaderSection";
import SnackbarNotification from "@/components/snackbar/Snackbar";
import { ID_AUCTION_FLOW, ID_BETWEEN_CITIES, ID_MOVING_FLOW } from "@/consts/defaultConsts";
import { NOTICE_EDIT_DATE_IN_CITY } from "@/consts/typeServicesPaths";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { DetailsCoreModel } from "@/models/notice/noticeModels";
import { UsuarioAPI } from "@/models/userAuth/user";
import {
  MY_APPLICATIONS_CUSTOMER_REQUEST_PATH,
  REQUEST_MOVING,
} from "@/pages/customerRequest/shared/consts/routes";
import useDetailNotice from "@/pages/detailNotice/hook";
import useUnsavedChanges from "@/pages/noticeRequestFlow/hooks/useUnsavedAndSavedChanges";
import { getInmueblesThunk } from "@/store/offertRedux/offert.thunk";
import { setMessageEdit } from "@/store/saveFlow/saveFlowSlice";
import {
  setEmptyVehicle,
  setSelectedVehicle,
} from "@/store/userRedux/userSlice";
import { vehicleIsActive } from "@/utils/helpers";
import { Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  TypeTitleRequest,
  generalConfig,
  listSteps,
  typeTitleRequest,
} from "./configDataNoticeEdit";

import {
  optionFive,
  optionFour,
  optionOne,
  optionThree,
  optionTwo,
} from "@/consts/noticeConsts";

const NoticeEdit = () => {
  const { handleManageSanackbarSavedChanges, handleSnackbarSuccess } =
    useUnsavedChanges();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const {
    saveFlowData: {
      saveFlowData: [data],
      messageEdit,
    },
    customerRequest,
    user: { user },
  } = useAppSelector((state) => state);

  const {
    detailsNoticeSlice: { isLoading },
  } = useAppSelector((state) => state);
  const { handleGetCurrentDetailNotice } = useDetailNotice();

  const notice: DetailsCoreModel = useMemo(
    () => handleGetCurrentDetailNotice(),
    [isLoading]
  );

  const validPathName = pathname?.startsWith(
    MY_APPLICATIONS_CUSTOMER_REQUEST_PATH
  );

  const activeSnackbar = customerRequest?.activeSnackbar;
  const error = customerRequest.error;
  const [listItemsSteps, setListItemsSteps] = useState(generalConfig[1]);
  const [title, setTitle] = useState(
    `Aviso ${notice.tipoaviso.nombre.toLocaleLowerCase()}`
  );

  const typeRequestId = customerRequest.savedData?.tipoSolicitud.id;
  const typeFlowText = notice.tipoaviso.nombre
    .toLowerCase()
    .replaceAll(" ", "-");

    
  useEffect(() => {
    let typeConfig = generalConfig[optionOne];

    if (notice.tipoaviso.id === ID_MOVING_FLOW) {
      typeConfig = generalConfig[optionFour];
    }

    if (notice.tipoaviso.id === ID_AUCTION_FLOW) {
      typeConfig = generalConfig[optionFive];
    }

    if (notice.tipoaviso.id === ID_BETWEEN_CITIES) {
      typeConfig = generalConfig[optionFour];
    }

    setListItemsSteps(typeConfig);
  }, []);

  useEffect(() => {
    dispatch(getInmueblesThunk());

    if (validPathName) {
      let typeConfig = generalConfig[optionTwo];

      if (typeRequestId === ID_MOVING_FLOW) {
        typeConfig = generalConfig[optionThree];
      }

      setListItemsSteps(typeConfig);

      setTitle(
        `Solicitud: ${(typeTitleRequest as TypeTitleRequest)[typeFlowText]}`
      );
    } else {
      if (!notice) {
        handleGoBack();
      }
      if (!notice.fvalidez) {
        handleRemoveDateInListItems();
      }
      handleSelectCurrentVehicle();
    }
    if (activeSnackbar) {
      handleManageSanackbarSavedChanges();
      handleSnackbarSuccess();
    }
  }, []);

  const handleSelectCurrentVehicle = () => {
    const idusuario = (user as UsuarioAPI)?.id;
    const cargamaxima = data?.pesomaximo;
    const flgrefrigerado = data?.flgcargarefrigerada;
    if (data) {
      if (!vehicleIsActive(data.vehiculo)) {
        dispatch(setEmptyVehicle());
      } else {
        dispatch(
          setSelectedVehicle({
            ...data.vehiculo,
            idusuario,
            cargamaxima,
            flgrefrigerado,
          })
        );
      }
    }
  };

  const handleRemoveDateInListItems = () => {
    if (
      listItemsSteps[0].link.slice(1) ===
      NOTICE_EDIT_DATE_IN_CITY.split("/").at(-1)
    ) {
      setListItemsSteps(listSteps.slice(1));
    }
  };

  const handleClickStep = (item: ListStepsProps) => {
    if (typeFlowText === REQUEST_MOVING) {
      dispatch(setMessageEdit(""));
      navigate(`${location.pathname}${item.link}`);
    } else {
      dispatch(setMessageEdit(""));
      navigate(`${location.pathname}${item.link}`);
    }
  };

  const handleGoBack = () => {
    dispatch(setMessageEdit(""));
    navigate(-1);
  };

  return (
    <LayoutFlow>
      <HeaderSection
        icon="icon-arrow-left--primary"
        title={title}
        subTitle="Editar"
        onClick={handleGoBack}
      />
      {!!error ? (
        <></>
      ) : (
        <>
          <SnackbarNotification />
        </>
      )}
      <>
        {messageEdit && (
          <StandardAlert
            sx={{ marginBottom: "16px" }}
            severity="success"
            color="success"
          >
            <Typography variant="body1">{messageEdit}</Typography>
          </StandardAlert>
        )}
      </>
      <ListSteps
        list={listItemsSteps}
        onClick={handleClickStep}
        showCheckBox={false}
      />
    </LayoutFlow>
  );
};

export default NoticeEdit;
