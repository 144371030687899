import { thirdFontFamily } from '@/styles/theme';
import { Box, Button, Grid, Typography, styled } from '@mui/material';

export const ContainerAds = styled(Grid)(
  ({ matches }: { matches: boolean }) => ({
    minHeight: '400px',
    height: '100%',
    dosplay: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FF6700',
    borderRadius: matches ? '' : '24px',
  })
);

export const BoxContainer = styled(Box)(
  ({ matches }: { matches: boolean }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: matches ? 'center' : 'flex-start',
  })
);

export const TypographyTitle = styled(Typography)(() => ({
  color: 'white',
  fontWeight: '700',
}));

export const TypographyExpress = styled(Typography)(() => ({
  color: '#054A91',
  fontSize: '48px',
  fontWeight: '700',
  marginLeft: '5px',
  fontFamily: 'Blackrush',
  marginTop: '4px',
}));

export const TextBoxContainer = styled(Box)(
  ({ matches6, matches7 }: { matches6: boolean; matches7: boolean }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: matches6 ? 'center' : 'flex-start',
    paddingX: matches7 ? '16px' : '0px',
  })
);

export const TypographyText = styled(Typography)(() => ({
  fontSize: '16px',
  marginTop: '8px',
}));

export const BoxBtn = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  paddingX: '16px',
  paddingLeft: '32px',
  paddingRight: '16px',
}));

export const ButtonStyled = styled('a')(() => ({
  color: '#382E2B',
  width: '100%',
  fontWeight: '700',
  backgroundColor: 'white',
  borderRadius: '44px',
  marginTop: '16px',
  textDecoration: 'none',
  padding: '8px 16px',
  textAlign: 'center',
  textTransform: 'uppercase',
  fontFamily: thirdFontFamily,
  fontSize: '16px',
}));

export const SectionTwoContainer = styled(Grid)(
  ({ matches }: { matches: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: matches ? '0px' : '16px',
  })
);

export const BoxcontainerSectionTwo = styled(Box)(() => ({
  width: 150,
  height: 150,
}));

export const SectionTwoGrid = styled(Grid)(
  ({ matches }: { matches: boolean }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginBottom: matches ? '32px' : '0px',
  })
);

export const ContainerMobile = styled(Grid)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '16px',
}));

export const SubContainerMobile = styled(Grid)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
}));

export const BoxAppStoresIcons = styled(Grid)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
}));

export const GridContainer = styled(Grid)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
