import BasicAlert from "@/components/alerts/BasicAlert";
import ButtomLoader from "@/components/form/buttonLoader/ButtomLoader";
import AutoCompleteModal from "@/components/form/formServicesArea/AutoCompleteModal";
import Input from "@/components/inputCustom/Input";
import HeaderModal from "@/components/modals/headerModal/HeaderModal";
import {
  ContainerButtom,
  ContainerModal,
  CustomDrawer,
} from "@/components/modals/stylesContainerFlow";
import { ID_CITY_LIMA } from "@/config/config";
import useDebounce from "@/hooks/useDebounse";
import { useAppDispatch, useAppSelector } from "@/hooks/useReducer";
import { getDistrictsByCityAndTermThunkV2 } from "@/store/locationRedux/districtsThunks";
import { SelectCity } from "@/store/locationRedux/selectLocation";
import useId from "@mui/material/utils/useId";
import {
  ChangeEvent,
  FC,
  useEffect,
  useRef,
  useState
} from "react";
import { MODAL_TYPE, ModalMultidestinyPropsV2 } from "../configData";
import { CustomFormControlV2, FormControlInputV2 } from "../styles/formRuteMultidestinyStyles";
import AccordionModalV2 from "./AccordionV2/AccordionV2";


const ModalMultidestiny: FC<ModalMultidestinyPropsV2> = ({
  onChange,
  onSubmit,
  open,
  onClose,
  list,
  inputProps,
  title,
  stateAccordion,
  nameTwo,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState(null);
  const dispatch = useAppDispatch();
  const id = useId();
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const inputValueDebounse = useDebounce(inputValue, 500);

  const {
    customerRequest: { selectedCity },
    districts: { districts },
  } = useAppSelector((state) => state);

  useEffect(() => {
    if (!open) {
      handleCleanValues();
    } else {
      timer.current = setTimeout(() => {
        inputSearchRef.current?.focus();
      }, 100);
    }

    return () => {
      handleCleanValues();
      clearTimeout(timer.current as NodeJS.Timeout);
    };
  }, [open]);

  useEffect(() => {
    onChange(inputValueDebounse);
  }, [inputValueDebounse]);

  const handleCleanValues = () => {
    setInputValue("");
    setSelected(null);
  };

  useEffect(() => {
    if (!!Object.keys(selectedCity).length) {
      const idSearch = (selectedCity as SelectCity)?.id;

      dispatch(getDistrictsByCityAndTermThunkV2(`${idSearch || ID_CITY_LIMA}`));
    }
  }, [selected, selectedCity]);

  const handleOnInputChange = async ({
    currentTarget,
  }: ChangeEvent<HTMLInputElement>) => {
    const { value } = currentTarget;

     setInputValue(value.replace(/[^A-Za-z ÁÉÍÓÚáéíóúÑñ,]/g, ""));
    if (selected) {
      setSelected(null);
    } 
  };

  const handleSelected = (value: any) => {
    setSelected(value);
    setInputValue(value?.nombre || "");
  };

  const handleOnSubmit = () => {
    onSubmit(selected);
  };

  const minValue = inputValue.length < 3;

  if (stateAccordion === MODAL_TYPE.multiDestiny)
    return (
      <CustomDrawer open={open} onClose={() => onClose(false)} anchor="right">
        <HeaderModal title={title} onClick={() => onClose(false)} />  
        <AccordionModalV2
          listAccordion={districts}
          modalActiveWith={"destiny"}
          onSubmit={() => onClose(false)}
          inputProps={inputProps}
          inputValue={inputValue}
          handleOnInputChange={handleOnInputChange}
        />
      </CustomDrawer>
    );

  return (
    <CustomDrawer open={open} onClose={() => onClose(false)} anchor="right">
      <HeaderModal title={title} onClick={() => onClose(false)} />
      <FormControlInputV2 fullWidth>
        <Input
          id={id}
          inputRef={inputSearchRef}
          label={inputProps.label}
          name={inputProps.name}
          value={inputValue}
          variant="standard"
          placeholder={inputProps.placeholder}
          onChange={handleOnInputChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControlInputV2>
      <ContainerModal>
        <CustomFormControlV2>
          {minValue || selected ? (
            <BasicAlert
              color="warning"
              action={false}
              open={inputValue.length > 0 && !selected}
            >
              Ingresa al menos 3 letras para ver resultados
            </BasicAlert>
          ) : (
            <>
              <AutoCompleteModal
                title=""
                icon=""
                list={list}
                onClick={handleSelected}
                onliItems={true}
                primaryShowKey={nameTwo ? "nombre2" : "nombre"}
              />
            </>
          )}
        </CustomFormControlV2>
        <ContainerButtom>
          <ButtomLoader
            onClick={handleOnSubmit}
            disabled={!selected}
            type="submit"
          >
            Guardar
          </ButtomLoader>
        </ContainerButtom>
      </ContainerModal>
    </CustomDrawer>
  );
};

export default ModalMultidestiny;
