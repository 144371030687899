import { DRIVER_TYPE } from "@/consts/defaultConsts";
import { DRIVER_CREATE_NOTICE_TYPES } from "@/consts/noticeV2Paths";
import { REQUESTS_LANDING_PATH } from "@/consts/typeServicesPaths";
import { useAppSelector } from "@/hooks/useReducer";
import { UsuarioAPI } from "@/models/userAuth/user";
import Footer from "@/pages/footer/Footer";
import { IUser } from "@/store/userRedux/userReduxInterface";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import AdvertisingLandSection from "../components/AdvertisingLandSection";
import CustomBanner from "../components/CustomBanner";
import InfoAppSection from "../components/InfoAppSection";
import InfoSection from "../home/components/InfoSection";
import NotUserLand from "./components/NotUserLand";
import "./createNoticeImg.css";
import useCarrierHook from "./hook";
import {
  BoxContainer,
  BoxStyled,
  BoxStyledContainer,
  BtnStyled,
  ImgBox,
  TypographySectionStyled,
  TypographyTextStyled,
} from "./styles";

export const textAPPDriver =
  "Consigue clientes cerca a ti de manera inmediata.";

const Carrier = () => {
  const {
    user: { user },
  } = useAppSelector((state) => state);
  const isDriver = (user as IUser).tipousuario === DRIVER_TYPE;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const { data, onClickButton } = useCarrierHook();

  return (
    <Box sx={{ backgroundColor: "#fafafa" }}>
      <CustomBanner
        urlImg={data?.banner?.urlImg || ""}
        responsive={data.banner.responsive || []}
        positionXY={{ xs: "center", md: "center" }}
        minHeight="166px"
        infoLand={{
          ...data.banner,
          onClickButton,
        }}
      ></CustomBanner>

      {isDriver && (
        <>
          {matches ? (
            <>
              <Box style={{ width: "100%", marginBottom: "16px" }}>
                <AdvertisingLandSection
                  title="Encuentra nuevos clientes"
                  text="Revisa las solicitudes de transporte de carga creadas por los clientes."
                  textButton="Ver solicitudes"
                  image="driver_person"
                  imgWidth="170px"
                  imgHeight="210px"
                />
              </Box>

              <Box>
                <InfoAppSection text={textAPPDriver} />
              </Box>
            </>
          ) : (
            <Box sx={{ marginTop: "46px" }}>
              <InfoSection
                title="Encuentra nuevos clientes"
                urlButton={REQUESTS_LANDING_PATH}
                textInfoApp={textAPPDriver}
                text="Revisa las solicitudes de transporte de carga creadas por los clientes."
                textButton="Ver solicitudes"
                image="driver_person"
                imgWidth="170px"
                imgHeight="210px"
              />
            </Box>
          )}

          <BoxStyled matches={matches}>
            <BoxContainer maxWidth="lg" matches={matches}>
              <ImgBox matches={matches}>
                <i
                  style={{ width: "248px", height: "223px", marginLeft: "5px" }}
                  className="createNoticeImge"
                />
              </ImgBox>
              <BoxStyledContainer matches={matches}>
                <TypographySectionStyled matches={matches}>
                  Sigue creando avisos
                </TypographySectionStyled>
                <TypographyTextStyled matches={matches}>
                  Crea la cantidad necesaria de avisos relacionados con los
                  servicios de transporte de carga que ofreces dentro y fuera de
                  la ciudad.
                </TypographyTextStyled>
                <Link to={DRIVER_CREATE_NOTICE_TYPES}>
                  <BtnStyled disableRipple>CREAR AVISOS</BtnStyled>
                </Link>
              </BoxStyledContainer>
            </BoxContainer>
          </BoxStyled>
        </>
      )}

      {!(user as UsuarioAPI).id && <NotUserLand />}

      <Footer />
    </Box>
  );
};

export default Carrier;
