import { ProfileBusinessV2Svg, ProfileUserV2Svg } from '@/assets/svg';
import { CLIENT_TYPE, typesDocuments } from '@/consts/defaultConsts';
import { ID_NOTICE_AUCTION } from '@/consts/noticeV2Paths';
import {
  REGISTER_CLIENT,
  REGISTER_CLIENT_PROFILE,
} from '@/consts/typeServicesPaths';
import { useAppDispatch, useAppSelector } from '@/hooks/useReducer';
import { DirtyTypeProps } from '@/interfaces/globalInterface';
import { UsuarioAPI, UsuarioTipoDocumento } from '@/models/userAuth/user';
import { signUpStepsDataLayer } from '@/services/dataLayer/auth';
import { setError } from '@/store/userRedux/userSlice';
import { savePhotoProfileThunk } from '@/store/userRedux/userThunks';
import { getNextPage } from '@/utils/helpers';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';
import {
  disclamer,
  disclamerRUC,
  objectNextPage,
  titleByPath,
  titleByTipeUser,
} from './configDataClientProfile';

const useClientProfileHook = () => {
  const [image, setImage] = useState<string | File>('');
  const [description, setDescription] = useState('');
  const [hasError, setHasError] = useState(false);
  const [dirtyFields, setDirtyFields] = useState<DirtyTypeProps>({
    profilePicture: { dirty: false },
  });
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const {
    user: { isLoading, user, error },
    routeFlowNotice,
  } = useAppSelector(({ user, routeFlowNotice }) => ({
    user,
    routeFlowNotice,
  }));
  const dispatch = useAppDispatch();

  const isPageClient = location.pathname === REGISTER_CLIENT_PROFILE;

  const currentUser = user as UsuarioAPI;

  useEffect(() => {
    if (currentUser.id) {
      signUpStepsDataLayer(currentUser.tipousuario, currentUser.id);
    }
  }, []);

  useEffect(() => {
    if (currentUser?.descripcion) {
      setDescription(currentUser?.descripcion || '');
    }

    return () => {
      setDescription('');
      setImage('');
      error && dispatch(setError(''));
    };
  }, [user]);

  useEffect(() => {
    if (hasError) {
      setHasError(false);
    }

    error && dispatch(setError(''));
  }, [image]);

  const handleOnSelectImage = (img: string | File) => {
    setImage(img);

    setDirtyFields((prev) => ({ ...prev, profilePicture: { dirty: true } }));
  };

  const handleOnError = () => {
    setHasError(true);
  };

  const handleOnchange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = currentTarget;
    setDescription(value);
    if (hasError) {
      setHasError(false);
    }

    setDirtyFields((prev) => ({ ...prev, [name]: { dirty: true } }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLoading) return;

    if (isPageClient && !image && !description) {
      handleValidNextRoute(true);
    } else {
      dispatch(
        savePhotoProfileThunk({
          image: image || currentUser?.perfilUrl,
          description,
        })
      )
        .then(() => handleValidNextRoute())
        .catch(() => setHasError(true));
    }
  };

  const handleValidNextRoute = (hasReplace = false) => {
    const { returnPath } = routeFlowNotice;

    if (currentUser?.tipousuario === CLIENT_TYPE && !!returnPath.path) {
      navigate(returnPath.path, { replace: true, state: returnPath.state });
    } else {
      handleGoNextPage(hasReplace);
    }
  };

  const handleGoNextPage = (hasReplace = false) => {
    const nextPage = getNextPage(location.pathname, objectNextPage);
    navigate(nextPage as To, { replace: hasReplace });
  };

  const handleDisabled = () => {
    if (currentUser?.perfilUrl) {
      return false;
    }
    if (isPageClient) {
      return isLoading;
    }

    return hasError || !image || isLoading;
  };

  const handleOnBlur = () => {
    setDescription((prev) => prev.trim());
  };

  const handleShowImageDefault = useCallback(() => {
    if (currentUser?.perfilUrl) {
      return currentUser?.perfilUrl;
    } else if (currentUser?.tipoDocumento?.id === ID_NOTICE_AUCTION) {
      return ProfileBusinessV2Svg;
    }
    return ProfileUserV2Svg;
  }, [user]);

  const handleShowTitle = () => {
    return titleByPath[location.pathname];
  };

  const handleDescription = () => {
    if (currentUser?.tipoDocumento?.nombre === typesDocuments.RUC.name) {
      return disclamerRUC;
    }

    return disclamer;
  };

  const handleBackNavigation = () => {
    const isDirtyForm = Object.values(dirtyFields).some(({ dirty }) => !!dirty);
    if (isDirtyForm) {
      setShowAlert(true);
    } else {
      handleGetBack();
    }
  };

  const handleGetBack = () => {
    const path = isPageClient ? REGISTER_CLIENT : -1;
    navigate(path as To);
  };

  const handleGetInputText = () => {
    if (currentUser?.tipousuario) {
      const { tipousuario, tipoDocumento } = currentUser;
      const nombre = (tipoDocumento as UsuarioTipoDocumento)?.nombre;
      const [userClient, userCompany] = titleByTipeUser[tipousuario];
      if (nombre === typesDocuments.RUC.name) {
        return userCompany;
      }

      return userClient;
    }
    return { label: '', placeholder: '' };
  };

  return {
    description,
    hasError,
    showAlert,
    isLoading,
    error,
    handleOnSelectImage,
    handleOnError,
    handleOnchange,
    handleSubmit,
    handleDisabled,
    handleOnBlur,
    handleShowImageDefault,
    handleShowTitle,
    handleDescription,
    handleBackNavigation,
    handleGetBack,
    setShowAlert,
    isPageClient,
    handleGetInputText,
    user: currentUser,
  };
};

export default useClientProfileHook;
